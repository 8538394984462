import { ReactElement, useEffect } from "react";
import { registerLocale } from "react-datepicker";
import { Controller, useFormContext } from "react-hook-form";
import { prepareDictionarySearchSelect } from "@ps/utils";
import en from "date-fns/locale/en-GB";
import dayjs from "dayjs";
import {
  SearchSelect,
  Button,
  Toggle,
  Modal2,
  ModalContent,
  ModalFooter,
  Datepicker2,
} from "@ps/ui-components";
import {
  PROJECT_TEAM,
  PROJECT_TEAM_MODAL,
} from "../../../../../shared/data-cy";
import { EditModalProps } from "./types";
import ControlledInput from "../../../common/controlledInput";
import {
  START_DATE,
  FINISH_DATE,
  NOTICE_PERIOD,
  ENGAGEMENT,
  RATE_PER_USER,
  CURRENCY,
  NEW_PROJECT,
  STATEMENT_OF_WORK,
  RATE,
  AMOUNT,
  currencyDict,
  engagementDict,
  DATE_FORMAT,
  PROJECT_FEEDBACK,
  projectMemberFeedbacks,
  UNSPECIFIED_END,
} from "../../../../constants";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { matchIconToFeedback } from "../../../../utils";
import { BASE_PATH } from "./constants";
import { getValueData } from "./utils";
import UserAvatar from "./userAvatar";
import { useTeamContext } from "../../context";
import { TeamMemberModel } from "../../../../models";

const datePickerFormat = "DD/MM/YYYY";

const locale = "en";
registerLocale(locale, en);

const PROSPECT_TEAM_USER_MODAL_RATE = "prospect-team-user-modal-rate";

const EditModal = ({
  isOpen,
  mainProfessionName,
  normalizedSeniority,
  onAddUserClick,
  onClose,
  onRestoreClick,
  onSaveClick,
  user,
  userDeleted,
}: EditModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const isUser = user?.statementOfWork !== undefined;
  const { teamView, editUserId, focusId, setFocusId } = useTeamContext();
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    watch,
    clearErrors,
  } = useFormContext();

  useEffect(() => {
    if (isOpen) setFocusId(PROSPECT_TEAM_USER_MODAL_RATE);
  }, [isOpen]);

  useEffect(() => {
    if (!editUserId.length) reset(getValues());
  }, [editUserId]);

  const feedbacksValueLabel = projectMemberFeedbacks.map(
    (feedback: string) => ({
      value: feedback,
      label: (
        <div className="flex items-center gap-3">
          {matchIconToFeedback(feedback)}
          <span>{t(`${BASE_PATH}.feedbacks.${feedback}`)}</span>
        </div>
      ),
    }),
  );

  const onSubmit = async (data: TeamMemberModel): Promise<void> =>
    teamView ? onSaveClick(data) : onAddUserClick(data);

  return (
    <Modal2
      dataCy={PROJECT_TEAM_MODAL}
      isOpen={isOpen}
      onClose={onClose}
      withCloseIcon
      withYScroll={false}
      width="w-3/12"
      height="h-auto"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="overflow-y-hidden">
        <ModalContent additionalClassName="overflow-y-hidden">
          <div className="flex gap-x-4 mb-2">
            <UserAvatar avatar={user?.avatar} />
            <div className="flex flex-col">
              <span className="text-neutralPrimary-40">
                {mainProfessionName}
              </span>
              <span>{normalizedSeniority}</span>
            </div>
          </div>
          <div className="font-bold">{`${user?.firstName} ${user?.lastName}`}</div>
          <div className="flex flex-col gap-y-2 mt-7">
            <div className="flex justify-between items-center border-t-2 pt-2">
              <span className="font-bold">{t(`${BASE_PATH}.${RATE}`)}</span>
              <div className="w-48">
                <ControlledInput
                  autoFocus={focusId === PROSPECT_TEAM_USER_MODAL_RATE}
                  name={RATE_PER_USER}
                  controllerPath={`${RATE}.${AMOUNT}`}
                  basePath={NEW_PROJECT}
                  translatePath={BASE_PATH}
                  placeholder={RATE}
                  isRequired
                  min={0}
                  noLabel
                  defaultValue={
                    isUser && !userDeleted
                      ? user?.rates?.[user?.rates.length - 1]?.amount
                      : ""
                  }
                  type="number"
                  manualControl={control}
                  additionalValidation={(value) =>
                    value < 0
                      ? t(`${BASE_PATH}.valueGreater`, {
                          field: t(`${BASE_PATH}.${RATE_PER_USER}`),
                        })
                      : true
                  }
                />
              </div>
            </div>

            <div className="flex justify-between items-center">
              <span className="font-bold">{t(`${BASE_PATH}.${CURRENCY}`)}</span>
              <div className="w-48">
                <Controller
                  control={control}
                  name={`${RATE}.${CURRENCY}`}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <SearchSelect
                      dataCy={CURRENCY}
                      width="w-full"
                      fitMenuWidth
                      options={prepareDictionarySearchSelect(
                        currencyDict,
                        "id",
                        "name",
                        "name",
                        "name",
                      )}
                      defaultValue={
                        isUser && !userDeleted
                          ? getValueData(
                              user?.rates?.[user?.rates.length - 1]?.currency ||
                                value,
                              currencyDict,
                            )
                          : ""
                      }
                      placeholder={`${t(`${BASE_PATH}.perHour`)}*`}
                      searchable={false}
                      onChange={onChange}
                      error={!!error}
                      message={error?.message}
                    />
                  )}
                  rules={{
                    validate: (value) =>
                      !value ? `${t(`${BASE_PATH}.isRequired`)}` : true,
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="font-bold">
                {t(`${BASE_PATH}.${START_DATE}`)}
              </span>
              <div data-cy={`${NEW_PROJECT}_${START_DATE}`}>
                <Controller
                  name={`${STATEMENT_OF_WORK}.${START_DATE}`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div
                      className="flex flex-col w-48"
                      data-cy={`${BASE_PATH}_${START_DATE}`}
                    >
                      <Datepicker2
                        dataCy={`${BASE_PATH}_${START_DATE}`}
                        selected={value || null}
                        onChange={onChange}
                        error={error?.message || ""}
                        timeView={["day"]}
                        dateFormat={datePickerFormat}
                      />
                    </div>
                  )}
                  rules={{
                    validate: (value) => {
                      if (!value) return `${t(`${BASE_PATH}.isRequired`)}`;
                      if (!dayjs(value)?.isValid())
                        return `${t(`${BASE_PATH}.wrongDateFormat`)}`;
                      return true;
                    },
                  }}
                />
              </div>
            </div>

            <div className="flex justify-between items-center">
              <span className="font-bold">
                {t(`${BASE_PATH}.${FINISH_DATE}`)}
              </span>

              <Controller
                name={`${STATEMENT_OF_WORK}.${FINISH_DATE}`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div
                    className="flex flex-col w-48"
                    data-cy={`${BASE_PATH}_${FINISH_DATE}`}
                  >
                    <Datepicker2
                      dataCy={`${BASE_PATH}_${FINISH_DATE}`}
                      dateFormat={datePickerFormat}
                      disabled={watch(UNSPECIFIED_END)}
                      error={error?.message || ""}
                      onChange={onChange}
                      selected={value || null}
                      timeView={["day"]}
                    />
                  </div>
                )}
                rules={{
                  validate: (value) => {
                    const fromDate = getValues(
                      `${STATEMENT_OF_WORK}.${START_DATE}`,
                    );
                    if (!value && !watch(UNSPECIFIED_END))
                      return `${t(`${BASE_PATH}.isRequired`)}`;
                    if (
                      value &&
                      fromDate &&
                      dayjs(fromDate).isAfter(dayjs(value).toDate())
                    )
                      return `${t("project.errors.noEarlierDate", {
                        date: dayjs(fromDate).format(DATE_FORMAT),
                      })}`;
                    if (!dayjs(value)?.isValid() && !watch(UNSPECIFIED_END))
                      return `${t(`${BASE_PATH}.wrongDateFormat`)}`;
                    if (watch(UNSPECIFIED_END)) return true;
                    return true;
                  },
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <span className="font-bold">
                {t(`${BASE_PATH}.${UNSPECIFIED_END}`)}
              </span>
              <Controller
                name={UNSPECIFIED_END}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Toggle
                    dataCy={`${BASE_PATH}_${UNSPECIFIED_END}`}
                    checked={value}
                    onChange={() => {
                      const next = !value;
                      onChange(next);
                      if (next)
                        clearErrors(`${STATEMENT_OF_WORK}.${FINISH_DATE}`);
                      setValue(`${STATEMENT_OF_WORK}.${FINISH_DATE}`, null);
                    }}
                  />
                )}
              />
            </div>
            <div className="flex justify-between items-center">
              <span className="font-bold">
                {t(`${BASE_PATH}.${NOTICE_PERIOD}`)}
              </span>
              <div className="w-48">
                <ControlledInput
                  name={NOTICE_PERIOD}
                  controllerPath={`${STATEMENT_OF_WORK}.${NOTICE_PERIOD}`}
                  basePath={NEW_PROJECT}
                  translatePath={BASE_PATH}
                  placeholder="dd"
                  noLabel
                  defaultValue={
                    isUser && !userDeleted
                      ? user?.statementOfWork?.noticePeriod
                      : ""
                  }
                  isRequired
                  type="number"
                  min={0}
                  manualControl={control}
                  additionalValidation={(value) => {
                    return value < 0
                      ? t(`${BASE_PATH}.valueGreater`, {
                          field: t(`${BASE_PATH}.${NOTICE_PERIOD}`),
                        })
                      : true;
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="font-bold">
                {t(`${BASE_PATH}.${ENGAGEMENT}`)}
              </span>
              <div>
                <Controller
                  control={control}
                  name={`${STATEMENT_OF_WORK}.${ENGAGEMENT}`}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <SearchSelect
                      width="w-48"
                      dataCy={ENGAGEMENT}
                      options={prepareDictionarySearchSelect(
                        engagementDict,
                        "id",
                        "name",
                        "name",
                        "name",
                      )}
                      defaultValue={
                        isUser && !userDeleted
                          ? getValueData(
                              watch(`${STATEMENT_OF_WORK}.${ENGAGEMENT}`) ||
                                value,
                              engagementDict,
                            )
                          : ""
                      }
                      placeholder={`${t(`${BASE_PATH}.${ENGAGEMENT}`)}`}
                      searchable={false}
                      onChange={onChange}
                      error={!!error}
                      message={error?.message}
                    />
                  )}
                  rules={{
                    validate: (value) =>
                      !value ? `${t(`${BASE_PATH}.isRequired`)}` : true,
                  }}
                />
              </div>
            </div>
            {!userDeleted && teamView ? (
              <div className="flex flex-col gap-y-3 mt-2">
                <span className="font-bold">
                  {t(`${BASE_PATH}.${PROJECT_FEEDBACK}`)}
                </span>
                <Controller
                  control={control}
                  name={PROJECT_FEEDBACK}
                  render={({ field: { value, onChange } }) => (
                    <SearchSelect
                      dataCy={PROJECT_FEEDBACK}
                      fitMenuWidth
                      clearable
                      width="w-full"
                      options={feedbacksValueLabel}
                      defaultValue={
                        isUser && user?.feedback
                          ? feedbacksValueLabel.find(
                              (item) =>
                                item.value === watch(`${PROJECT_FEEDBACK}`),
                            )
                          : ""
                      }
                      value={feedbacksValueLabel.find(
                        (item: { value: string }) => item.value === value,
                      )}
                      placeholder={t(`${BASE_PATH}.${PROJECT_FEEDBACK}`)}
                      searchable={false}
                      onChange={(newFeedback) => {
                        onChange(newFeedback ? newFeedback.value : null);
                      }}
                    />
                  )}
                />
              </div>
            ) : null}
          </div>
        </ModalContent>
        <ModalFooter>
          <div className="flex gap-4 justify-end mt-5 w-full pt-10">
            <Button
              dataCy={`${PROJECT_TEAM}_cancel`}
              variant="secondary"
              onClick={onClose}
            >
              {t(`${BASE_PATH}.cancel`)}
            </Button>
            {userDeleted ? (
              <Button
                dataCy={`${PROJECT_TEAM}_restore`}
                onClick={onRestoreClick}
              >
                {t(`${BASE_PATH}.restore`)}
              </Button>
            ) : (
              <Button dataCy={`${PROJECT_TEAM}_update`} type="submit">
                {t(`${BASE_PATH}.${teamView ? "save" : "addUser"}`)}
              </Button>
            )}
          </div>
        </ModalFooter>
      </form>
    </Modal2>
  );
};

export default EditModal;
