import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import {
  CandidateMLAPIService,
  ClientAPIService,
  DictionariesAPIService,
  FolderAPIService,
  hhReducer,
  ProfileAPIService,
  ProjectAPIService,
  ProspectAPIService,
  SkillsAPIService,
  UserAPIService,
} from "@ps/hh";
import { TimesheetAPIService, timeTrackerReducer } from "@ps/time-tracker";
import { notificationsReducer } from "@ps/notifications";
import { alertModalReducer } from "@ps/alert-modal";
import ActionTypes from "store/actionTypes";
import menuReducer from "store/menu/menuReducer";
import userReducer from "store/login/userReducer";
import permissionsReducer from "./permissions/permissionsReducer";
import { IAction } from "./types";
import { IS_PRODUCTION } from "../shared/constants";
import errorReducer from "./error/errorReducer";
import { tenantMetadataReducer } from "../tenant-domain/store";
import TenantAPIService from "../tenant-domain/api/tenant-api";
import { isTenantMetadataFetchingReducer } from "../tenant-domain/store/tenantMetadataReducer";

const reducer = combineReducers({
  permissions: permissionsReducer,
  login: userReducer,
  hh: hhReducer,
  notifications: notificationsReducer,
  "time-tracker": timeTrackerReducer,
  isFormModified: alertModalReducer,
  isMenuVisible: menuReducer,
  error: errorReducer,
  tenantMetadata: tenantMetadataReducer,
  requestStatus: combineReducers({
    isTenantMetadataFetching: isTenantMetadataFetchingReducer,
  }),
});

const actionTypeEnumToString = (
  action: IAction<number | AnyAction>,
): string | AnyAction =>
  typeof action.type === "number" && ActionTypes[action.type]
    ? {
        type: ActionTypes[action.type],
        payload: action.payload,
        typeNumber: action.type,
      }
    : action;

const middlewares = [thunkMiddleware];

if (!IS_PRODUCTION) {
  const logger = createLogger({ actionTransformer: actionTypeEnumToString });
  middlewares.push(logger);
}

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
);
ProfileAPIService.setStore(store);
UserAPIService.setStore(store);
DictionariesAPIService.setStore(store);
TimesheetAPIService.setStore(store);
ClientAPIService.setStore(store);
ProspectAPIService.setStore(store);
ProjectAPIService.setStore(store);
CandidateMLAPIService.setStore(store);
FolderAPIService.setStore(store);
SkillsAPIService.setStore(store);
TenantAPIService.setStore(store);

// eslint-disable-next-line no-undef
export type AppState = ReturnType<typeof reducer>;
export default store;
