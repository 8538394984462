import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { ReactComponent as ProjectsIcon } from "../../../../images/profile-summary/project.svg";
import {
  Other,
  ProjectName,
  Roles,
  TechStack,
  TimeRange,
} from "./projectElements";
import { PROFILE_SUMMARY_PROJECTS } from "../../../../shared/data-cy";
import { ProjectDetailsModalProps } from "../../types";
import styles from "./styles.module.css";
import Modal, { ModalContent } from "../../../modal";
import { CardHeader } from "../../../expanded-card";

const ProjectDetailsModal = ({
  project,
  isOpen,
  onClose,
  projectRolesDict,
  technologyDict,
}: ProjectDetailsModalProps): ReactElement => (
  <Modal
    additionalClassName={classJoin(styles.modal, "overflow-hidden flex")}
    additionalContentClassName="flex flex-col gap-4 h-auto"
    dataCy={`${PROFILE_SUMMARY_PROJECTS}_projectDetails`}
    height="h-auto"
    isOpen={isOpen}
    onClose={onClose}
    width="w-10/12"
    withCloseIcon
    withOutsideClick
    withYScroll={false}
  >
    <ModalContent additionalClassName="flex flex-col gap-4" height="h-auto">
      <CardHeader
        additionalClassName="py-6"
        dataCy={`${PROFILE_SUMMARY_PROJECTS}_singleProject`}
        Icon={ProjectsIcon}
        separatorHeight="h-24"
      >
        <div className="flex flex-col gap-2">
          <Roles
            projectRolesDict={projectRolesDict}
            roles={project.projectRoles}
          />
          <ProjectName name={project.name} />
          <div className="flex gap-32 items-baseline">
            <TimeRange
              startDate={project.startDate}
              endDate={project.endDate}
            />
            <div className="hidden md:flex">
              <TechStack
                technologyDict={technologyDict}
                techStack={project.techStack}
              />
            </div>
          </div>
        </div>
      </CardHeader>

      <div className="flex md:hidden pb-6">
        <TechStack
          technologyDict={technologyDict}
          techStack={project.techStack}
        />
      </div>

      <Other
        className={classJoin("grid gap-6 pr-4", styles.modalOtherContent)}
        description={project.description}
        responsibility={project.responsibility}
      />
    </ModalContent>
  </Modal>
);

export default ProjectDetailsModal;
