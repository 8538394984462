import { ReactElement, MouseEvent } from "react";
import { classJoin } from "@ps/utils";
import { ScaleTileProps } from "./types";
import { ReactComponent as RemoveImg } from "../../images/remove-scale-tile.svg";
import styles from "./styled.module.css";
import Icon from "../icon";
import { SCALE_TILE } from "../../shared/data-cy";
import { focusVisibleStyles } from "../../shared/styles";

const ScaleTile = ({
  additionalClassName,
  canRemove,
  children,
  color,
  dataCy,
  iconUrl,
  onClick,
  onRemove,
  disabled,
}: ScaleTileProps): ReactElement => (
  <div className={classJoin("relative group", additionalClassName)}>
    {onRemove && canRemove && (
      <RemoveImg
        className={classJoin(
          "text-error-50 fill-current absolute opacity-0 group-hover:opacity-100 cursor-pointer",
          styles.remove,
        )}
        onClick={onRemove}
      />
    )}
    <button
      disabled={disabled}
      data-cy={`${SCALE_TILE}_${dataCy}`}
      type="button"
      onClick={(event: MouseEvent<HTMLButtonElement>) => {
        if (onClick) onClick(event);
      }}
      className={classJoin(
        "h-7 w-7 rounded-md border border-neutralSecondary-60",
        focusVisibleStyles,
        onClick ? "cursor-pointer" : "cursor-auto",
      )}
      style={{ backgroundColor: color }}
    >
      {(iconUrl && <Icon alt="" dataCy={dataCy} src={iconUrl} />) ||
        children ||
        ""}
    </button>
  </div>
);

export default ScaleTile;
