import { AnyAction } from "redux";
import ActionTypes from "./actionTypes";

const alertModalReducer = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case ActionTypes.SET_ALERT_MODAL:
      return true;
    case ActionTypes.CLEAR_ALERT_MODAL:
      return false;
    default:
      return state;
  }
};

export default alertModalReducer;
