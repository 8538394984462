import { ReactElement } from "react";
import { Label, Tag, URLButton } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { DATE_FORMAT } from "../../../common/constants";
import { ArticlesCardProps } from "../types";
import { TECH_STACK } from "../../projects/constants";
import { EXPERIENCE_ACCOMPLISHMENT_VIEW } from "../../../../../../shared/data-cy";
import { ARTICLES, DATE, TITLE, URL_PATH } from "../constants";
import { DictionaryEntryModel } from "../../../../../../dictionaries";
import accomplishmentsGrid from "../../../common/styles";

const ArticlesCard = ({
  title,
  url,
  date,
  techStack,
  isDeleting,
}: ArticlesCardProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const BASE_PATH = `${EXPERIENCE_ACCOMPLISHMENT_VIEW}_${ARTICLES}`;

  const translate = (field: string): string =>
    t(`profile.experience.cards.articles.${field}`);

  return (
    <div className="w-full flex flex-col p-6">
      <div className={accomplishmentsGrid}>
        <Label text={translate(TITLE)} dataCy={`${BASE_PATH}_${TITLE}`}>
          {title}
        </Label>
        {url && (
          <Label
            text={translate(URL_PATH)}
            dataCy={`${BASE_PATH}_${URL_PATH}`}
            additionalClassName="gap-1"
          >
            <URLButton
              dataCy={`${BASE_PATH}_${URL_PATH}`}
              url={url}
              disabled={isDeleting}
            />
          </Label>
        )}
        <Label text={translate(DATE)} dataCy={`${BASE_PATH}_${DATE}`}>
          {date?.format(DATE_FORMAT)}
        </Label>
      </div>
      <Label dataCy={`${BASE_PATH}_${TECH_STACK}`} text={translate(TECH_STACK)}>
        <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
          {techStack?.map((tech: DictionaryEntryModel) => (
            <Tag name={tech?.name} dataCy={tech?.id} key={tech?.id} />
          ))}
        </div>
      </Label>
    </div>
  );
};

export default ArticlesCard;
