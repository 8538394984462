/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ArchiveFlag
 */
export interface ArchiveFlag {
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveFlag
     */
    'archived': boolean;
}
/**
 * 
 * @export
 * @interface ClientAccesses
 */
export interface ClientAccesses {
    /**
     * 
     * @type {boolean}
     * @memberof ClientAccesses
     */
    'update': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientAccesses
     */
    'delete': boolean;
}
/**
 * 
 * @export
 * @interface CreateClient
 */
export interface CreateClient {
    /**
     * 
     * @type {string}
     * @memberof CreateClient
     */
    'clientName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClient
     */
    'contactPersonName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClient
     */
    'contactPersonEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClient
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClient
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClient
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateClient
     */
    'domains': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateClientResponse
 */
export interface CreateClientResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateClientResponse
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface GetClient
 */
export interface GetClient {
    /**
     * 
     * @type {string}
     * @memberof GetClient
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetClient
     */
    'archived': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetClient
     */
    'clientName': string;
    /**
     * 
     * @type {string}
     * @memberof GetClient
     */
    'contactPersonName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClient
     */
    'contactPersonEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClient
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClient
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClient
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetClient
     */
    'domains': Array<string>;
    /**
     * 
     * @type {ClientAccesses}
     * @memberof GetClient
     */
    'accesses': ClientAccesses;
}
/**
 * 
 * @export
 * @interface GetClientSummary
 */
export interface GetClientSummary {
    /**
     * 
     * @type {string}
     * @memberof GetClientSummary
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetClientSummary
     */
    'archived': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetClientSummary
     */
    'clientName': string;
    /**
     * 
     * @type {string}
     * @memberof GetClientSummary
     */
    'lastUpdate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetClientSummary
     */
    'numberOfActiveProspects': number;
    /**
     * 
     * @type {number}
     * @memberof GetClientSummary
     */
    'numberOfActiveProjects': number;
    /**
     * 
     * @type {number}
     * @memberof GetClientSummary
     */
    'numberOfActiveFolders': number;
}
/**
 * 
 * @export
 * @interface UpdateClient
 */
export interface UpdateClient {
    /**
     * 
     * @type {string}
     * @memberof UpdateClient
     */
    'clientName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClient
     */
    'contactPersonName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClient
     */
    'contactPersonEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClient
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClient
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClient
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateClient
     */
    'domains': Array<string>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new Client
         * @param {CreateClient} createClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClient: async (createClient: CreateClient, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClient' is not null or undefined
            assertParamExists('addClient', 'createClient', createClient)
            const localVarPath = `/client/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteClient', 'id', id)
            const localVarPath = `/client/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch client by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClientById', 'id', id)
            const localVarPath = `/client/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/client/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all clients summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientsSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/client/clients/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set Archived Flag of Client
         * @param {string} id 
         * @param {ArchiveFlag} archiveFlag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArchived: async (id: string, archiveFlag: ArchiveFlag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setArchived', 'id', id)
            // verify required parameter 'archiveFlag' is not null or undefined
            assertParamExists('setArchived', 'archiveFlag', archiveFlag)
            const localVarPath = `/client/clients/{id}/archived`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archiveFlag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Client
         * @param {string} id 
         * @param {UpdateClient} updateClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient: async (id: string, updateClient: UpdateClient, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClient', 'id', id)
            // verify required parameter 'updateClient' is not null or undefined
            assertParamExists('updateClient', 'updateClient', updateClient)
            const localVarPath = `/client/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Create new Client
         * @param {CreateClient} createClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addClient(createClient: CreateClient, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addClient(createClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch client by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetClient>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all clients summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientsSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetClientSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientsSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set Archived Flag of Client
         * @param {string} id 
         * @param {ArchiveFlag} archiveFlag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setArchived(id: string, archiveFlag: ArchiveFlag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setArchived(id, archiveFlag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Client
         * @param {string} id 
         * @param {UpdateClient} updateClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClient(id: string, updateClient: UpdateClient, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClient(id, updateClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Create new Client
         * @param {CreateClient} createClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClient(createClient: CreateClient, options?: any): AxiosPromise<CreateClientResponse> {
            return localVarFp.addClient(createClient, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch client by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientById(id: string, options?: any): AxiosPromise<GetClient> {
            return localVarFp.getClientById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients(options?: any): AxiosPromise<Array<GetClient>> {
            return localVarFp.getClients(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all clients summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientsSummary(options?: any): AxiosPromise<Array<GetClientSummary>> {
            return localVarFp.getClientsSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * Set Archived Flag of Client
         * @param {string} id 
         * @param {ArchiveFlag} archiveFlag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArchived(id: string, archiveFlag: ArchiveFlag, options?: any): AxiosPromise<void> {
            return localVarFp.setArchived(id, archiveFlag, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Client
         * @param {string} id 
         * @param {UpdateClient} updateClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient(id: string, updateClient: UpdateClient, options?: any): AxiosPromise<void> {
            return localVarFp.updateClient(id, updateClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Create new Client
     * @param {CreateClient} createClient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addClient(createClient: CreateClient, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addClient(createClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Client
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteClient(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch client by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClientById(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getClientById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClients(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getClients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all clients summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClientsSummary(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getClientsSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set Archived Flag of Client
     * @param {string} id 
     * @param {ArchiveFlag} archiveFlag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setArchived(id: string, archiveFlag: ArchiveFlag, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setArchived(id, archiveFlag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Client
     * @param {string} id 
     * @param {UpdateClient} updateClient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateClient(id: string, updateClient: UpdateClient, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateClient(id, updateClient, options).then((request) => request(this.axios, this.basePath));
    }
}


