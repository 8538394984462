import { ReactElement, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { useParams } from "react-router-dom";
import { useMappedStateSelector } from "../../../hooks";
import { fetchProspectsList } from "../../../prospect-domain";
import ProspectsListWrapper from "../../../prospect-domain/ui/common/prospectsListWrapper";
import {
  ProspectSummaryMapState,
  ProspectSummaryMapStateReturn,
} from "../types";

const ClientsProspectsList = (): ReactElement => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    fetchProspectsList(dispatch, params.id);
  }, [params.id]);

  const mapState = (
    state: ProspectSummaryMapState,
  ): ProspectSummaryMapStateReturn => ({
    activeProspects: state?.prospects?.prospectsList || [],
  });

  const { activeProspects } = useMappedStateSelector(mapState);

  return (
    <div className="flex flex-col gap-8 h-full overflow-hidden">
      <ProspectsListWrapper prospects={activeProspects} clientId={params?.id} />
    </div>
  );
};

export default ClientsProspectsList;
