import { ReactElement } from "react";
import { ReactComponent as DevopsIcon } from "../../../images/skills-matrix/devops.svg";
import { ReactComponent as DataBaseIcon } from "../../../images/skills-matrix/data.svg";
import { ReactComponent as TesterIcon } from "../../../images/skills-matrix/qa.svg";
import { ReactComponent as FrontendIcon } from "../../../images/skills-matrix/fe.svg";
import { ReactComponent as ScalaIcon } from "../../../images/skills-matrix/scala.svg";
import { ProperIconProps } from "../types";

const tabIconStyles = "fill-current";

// TODO fix me - temporary icons; in the future should me delivered by icons library
const ProperIcon = ({ professionId }: ProperIconProps): ReactElement => {
  switch (professionId) {
    case "devops":
      return <DevopsIcon className={tabIconStyles} />;
    case "data":
      return <DataBaseIcon className={tabIconStyles} />;
    case "quality_assurance":
      return <TesterIcon className={tabIconStyles} />;
    case "frontend":
      return <FrontendIcon className={tabIconStyles} />;
    case "scala":
      return <ScalaIcon className={tabIconStyles} />;
    default:
      return <DevopsIcon className={tabIconStyles} />;
  }
};

export default ProperIcon;
