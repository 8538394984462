import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { clearRedirection, RedirectAlertModal } from "@ps/alert-modal";
import {
  EXPERIENCE,
  PERSONAL_INFO,
  PROFILE_WIZARD,
  SKILLS,
  SUMMARY,
} from "../../shared/routes";
import ProfileContainer, { Experience, Overview, Skills, Summary } from "../ui";
import OCRWizard from "../ui/OCRWizard";
import { useMappedStateSelector } from "../../hooks";
import { OperativeDomainModel } from "../../../index";
import { SPECIALIST } from "../../shared/constants";

const ProfileRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  const { state: redirectState } = useLocation<{
    section: string | undefined;
  }>();
  const dispatch = useDispatch();

  const mapState = (state: {
    profiles: { myProfile: OperativeDomainModel };
  }): { myProfile: OperativeDomainModel } => ({
    myProfile: state?.profiles?.myProfile,
  });

  const { myProfile } = useMappedStateSelector(mapState);

  return (
    <ProfileContainer>
      <RedirectAlertModal
        dispatch={dispatch}
        clearRedirection={clearRedirection}
      />
      <Switch>
        <Route path={`${path}/${PERSONAL_INFO}`}>
          <Overview />
        </Route>
        <Route path={`${path}/${EXPERIENCE}`}>
          <Experience />
        </Route>
        <Route path={`${path}/${SKILLS}`}>
          <Skills />
        </Route>
        <Route path={`${path}/${SUMMARY}`}>
          <Summary />
        </Route>
        <Route path={`${path}/${PROFILE_WIZARD}`}>
          <OCRWizard />
        </Route>
        {redirectState?.section === PERSONAL_INFO ? (
          <Route>
            <Redirect to={`${path}/${PERSONAL_INFO}`} />
          </Route>
        ) : (
          <Route>
            {myProfile?.profile === SPECIALIST ? (
              <Redirect to={`${path}/${SUMMARY}`} />
            ) : (
              <Redirect to={`${path}/${PERSONAL_INFO}`} />
            )}
          </Route>
        )}
      </Switch>
    </ProfileContainer>
  );
};

export default ProfileRoutes;
