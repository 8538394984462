import { ClientModel } from "../../../../client-domain";

export const getClientData = (
  value: string,
  clients: ClientModel[],
): { id: string; value: string; label: string } => {
  return {
    id: value,
    value,
    label:
      clients.find((item: ClientModel): boolean => item.id === value)
        ?.clientName || "",
  };
};
