/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Folder API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const Acceptance = {
    Rejected: 'rejected',
    InProgress: 'inProgress',
    Approved: 'approved'
} as const;

export type Acceptance = typeof Acceptance[keyof typeof Acceptance];


/**
 * 
 * @export
 * @interface AddCandidatesResponse
 */
export interface AddCandidatesResponse {
    /**
     * ids of successfully added candidates
     * @type {Array<string>}
     * @memberof AddCandidatesResponse
     */
    'ok': Array<string>;
    /**
     * ids of already added candidates
     * @type {Array<string>}
     * @memberof AddCandidatesResponse
     */
    'conflict': Array<string>;
    /**
     * ids of not found candidates
     * @type {Array<string>}
     * @memberof AddCandidatesResponse
     */
    'notfound': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateCandidate
 */
export interface CreateCandidate {
    /**
     * 
     * @type {string}
     * @memberof CreateCandidate
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface CreateFolder
 */
export interface CreateFolder {
    /**
     * 
     * @type {string}
     * @memberof CreateFolder
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFolder
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateFolderResponse
 */
export interface CreateFolderResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateFolderResponse
     */
    'id': string;
    /**
     * A 4-digit security number
     * @type {string}
     * @memberof CreateFolderResponse
     */
    'pin'?: string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface GetCandidate
 */
export interface GetCandidate {
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'seniority'?: string;
    /**
     * 
     * @type {Array<GetStatus>}
     * @memberof GetCandidate
     */
    'status': Array<GetStatus>;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'avatar'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCandidate
     */
    'professions': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'timeZone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCandidate
     */
    'favorited': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetCandidate
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface GetFolder
 */
export interface GetFolder {
    /**
     * 
     * @type {string}
     * @memberof GetFolder
     */
    'id': string;
    /**
     * A 4-digit security number
     * @type {string}
     * @memberof GetFolder
     */
    'pin'?: string;
    /**
     * 
     * @type {Array<GetCandidate>}
     * @memberof GetFolder
     */
    'candidates': Array<GetCandidate>;
    /**
     * A date after which the Folder will not be accessible anymore
     * @type {string}
     * @memberof GetFolder
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetFolder
     */
    'lastUpdate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetFolder
     */
    'anonymized': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetFolder
     */
    'archived': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFolder
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFolder
     */
    'clientId': string;
}
/**
 * The status of a Candidate, can be either \'rejected\' , \'inProgress\' or \'approved\'. Feedback needs to be provided only in the case of rejection
 * @export
 * @interface GetStatus
 */
export interface GetStatus {
    /**
     * 
     * @type {Acceptance}
     * @memberof GetStatus
     */
    'acceptance': Acceptance;
    /**
     * 
     * @type {string}
     * @memberof GetStatus
     */
    'feedback'?: string;
    /**
     * Date and time when the status was set
     * @type {string}
     * @memberof GetStatus
     */
    'setAt': string;
}
/**
 * 
 * @export
 * @interface UpdateCandidate
 */
export interface UpdateCandidate {
    /**
     * 
     * @type {string}
     * @memberof UpdateCandidate
     */
    'overriddenSeniority': string;
}
/**
 * 
 * @export
 * @interface UpdateFolder
 */
export interface UpdateFolder {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFolder
     */
    'anonymize': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateFolder
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFolder
     */
    'clientId': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a Candidates to a Folder with given id
         * @param {string} folderId 
         * @param {Array<CreateCandidate>} createCandidate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCandidates: async (folderId: string, createCandidate: Array<CreateCandidate>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('addCandidates', 'folderId', folderId)
            // verify required parameter 'createCandidate' is not null or undefined
            assertParamExists('addCandidates', 'createCandidate', createCandidate)
            const localVarPath = `/folder/folders/{folderId}/candidates`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Archive a Folder with given id
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveFolder: async (folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('archiveFolder', 'folderId', folderId)
            const localVarPath = `/folder/folders/{folderId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an empty Folder with a generated PIN and id
         * @param {CreateFolder} createFolder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder: async (createFolder: CreateFolder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFolder' is not null or undefined
            assertParamExists('createFolder', 'createFolder', createFolder)
            const localVarPath = `/folder/folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFolder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Soft delete a Candidate with given userId from a Folder with given id
         * @param {string} folderId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidate: async (folderId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('deleteCandidate', 'folderId', folderId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteCandidate', 'userId', userId)
            const localVarPath = `/folder/folders/{folderId}/candidates/{userId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all Folders
         * @param {string} [clientId] ClientId assigned to the folder
         * @param {boolean} [archived] Archived flag
         * @param {string} [candidateId] CandidateId in the folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFolders: async (clientId?: string, archived?: boolean, candidateId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/folder/folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (candidateId !== undefined) {
                localVarQueryParameter['candidateId'] = candidateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch Folder by its id
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder: async (folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('getFolder', 'folderId', folderId)
            const localVarPath = `/folder/folders/{folderId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unarchive a Folder with given id
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveFolder: async (folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('unarchiveFolder', 'folderId', folderId)
            const localVarPath = `/folder/folders/{folderId}/unarchive`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Candidate with given userId in a Folder with given id
         * @param {string} folderId 
         * @param {string} userId 
         * @param {UpdateCandidate} updateCandidate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCandidate: async (folderId: string, userId: string, updateCandidate: UpdateCandidate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('updateCandidate', 'folderId', folderId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateCandidate', 'userId', userId)
            // verify required parameter 'updateCandidate' is not null or undefined
            assertParamExists('updateCandidate', 'updateCandidate', updateCandidate)
            const localVarPath = `/folder/folders/{folderId}/candidates/{userId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCandidate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Folder with given id
         * @param {string} folderId 
         * @param {UpdateFolder} updateFolder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolder: async (folderId: string, updateFolder: UpdateFolder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('updateFolder', 'folderId', folderId)
            // verify required parameter 'updateFolder' is not null or undefined
            assertParamExists('updateFolder', 'updateFolder', updateFolder)
            const localVarPath = `/folder/folders/{folderId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFolder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a Candidates to a Folder with given id
         * @param {string} folderId 
         * @param {Array<CreateCandidate>} createCandidate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCandidates(folderId: string, createCandidate: Array<CreateCandidate>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCandidatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCandidates(folderId, createCandidate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Archive a Folder with given id
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveFolder(folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveFolder(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an empty Folder with a generated PIN and id
         * @param {CreateFolder} createFolder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFolder(createFolder: CreateFolder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFolderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFolder(createFolder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Soft delete a Candidate with given userId from a Folder with given id
         * @param {string} folderId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCandidate(folderId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCandidate(folderId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all Folders
         * @param {string} [clientId] ClientId assigned to the folder
         * @param {boolean} [archived] Archived flag
         * @param {string} [candidateId] CandidateId in the folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFolders(clientId?: string, archived?: boolean, candidateId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetFolder>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFolders(clientId, archived, candidateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch Folder by its id
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolder(folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolder(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unarchive a Folder with given id
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unarchiveFolder(folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unarchiveFolder(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a Candidate with given userId in a Folder with given id
         * @param {string} folderId 
         * @param {string} userId 
         * @param {UpdateCandidate} updateCandidate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCandidate(folderId: string, userId: string, updateCandidate: UpdateCandidate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCandidate(folderId, userId, updateCandidate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Folder with given id
         * @param {string} folderId 
         * @param {UpdateFolder} updateFolder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFolder(folderId: string, updateFolder: UpdateFolder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFolder(folderId, updateFolder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Add a Candidates to a Folder with given id
         * @param {string} folderId 
         * @param {Array<CreateCandidate>} createCandidate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCandidates(folderId: string, createCandidate: Array<CreateCandidate>, options?: any): AxiosPromise<AddCandidatesResponse> {
            return localVarFp.addCandidates(folderId, createCandidate, options).then((request) => request(axios, basePath));
        },
        /**
         * Archive a Folder with given id
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveFolder(folderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.archiveFolder(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an empty Folder with a generated PIN and id
         * @param {CreateFolder} createFolder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder(createFolder: CreateFolder, options?: any): AxiosPromise<CreateFolderResponse> {
            return localVarFp.createFolder(createFolder, options).then((request) => request(axios, basePath));
        },
        /**
         * Soft delete a Candidate with given userId from a Folder with given id
         * @param {string} folderId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidate(folderId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCandidate(folderId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all Folders
         * @param {string} [clientId] ClientId assigned to the folder
         * @param {boolean} [archived] Archived flag
         * @param {string} [candidateId] CandidateId in the folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFolders(clientId?: string, archived?: boolean, candidateId?: string, options?: any): AxiosPromise<Array<GetFolder>> {
            return localVarFp.getAllFolders(clientId, archived, candidateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch Folder by its id
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder(folderId: string, options?: any): AxiosPromise<GetFolder> {
            return localVarFp.getFolder(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Unarchive a Folder with given id
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveFolder(folderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unarchiveFolder(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Candidate with given userId in a Folder with given id
         * @param {string} folderId 
         * @param {string} userId 
         * @param {UpdateCandidate} updateCandidate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCandidate(folderId: string, userId: string, updateCandidate: UpdateCandidate, options?: any): AxiosPromise<void> {
            return localVarFp.updateCandidate(folderId, userId, updateCandidate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Folder with given id
         * @param {string} folderId 
         * @param {UpdateFolder} updateFolder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolder(folderId: string, updateFolder: UpdateFolder, options?: any): AxiosPromise<void> {
            return localVarFp.updateFolder(folderId, updateFolder, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Add a Candidates to a Folder with given id
     * @param {string} folderId 
     * @param {Array<CreateCandidate>} createCandidate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addCandidates(folderId: string, createCandidate: Array<CreateCandidate>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addCandidates(folderId, createCandidate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Archive a Folder with given id
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public archiveFolder(folderId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).archiveFolder(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an empty Folder with a generated PIN and id
     * @param {CreateFolder} createFolder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createFolder(createFolder: CreateFolder, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createFolder(createFolder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Soft delete a Candidate with given userId from a Folder with given id
     * @param {string} folderId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCandidate(folderId: string, userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteCandidate(folderId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all Folders
     * @param {string} [clientId] ClientId assigned to the folder
     * @param {boolean} [archived] Archived flag
     * @param {string} [candidateId] CandidateId in the folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllFolders(clientId?: string, archived?: boolean, candidateId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllFolders(clientId, archived, candidateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch Folder by its id
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFolder(folderId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFolder(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unarchive a Folder with given id
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unarchiveFolder(folderId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).unarchiveFolder(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Candidate with given userId in a Folder with given id
     * @param {string} folderId 
     * @param {string} userId 
     * @param {UpdateCandidate} updateCandidate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCandidate(folderId: string, userId: string, updateCandidate: UpdateCandidate, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCandidate(folderId, userId, updateCandidate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Folder with given id
     * @param {string} folderId 
     * @param {UpdateFolder} updateFolder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateFolder(folderId: string, updateFolder: UpdateFolder, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateFolder(folderId, updateFolder, options).then((request) => request(this.axios, this.basePath));
    }
}


