const BASE_MAIN = "main";
export const MAIN_MIDDLE_SECTION = `${BASE_MAIN}_middle_section`;
export const MAIN_MIDDLE_CONTENT = "user_middle_content";
export const MAIN_PROFILE = `${BASE_MAIN}_overview`;
export const MAIN_PROFILE_LABEL = `${MAIN_PROFILE}_label`;
export const MAIN_PROFILE_CONTENT = `${MAIN_PROFILE}_content`;
export const MAIN_PROFILE_EDIT = `${MAIN_PROFILE}_edit`;
export const MAIN_OCR_UPLOADER = `${MAIN_PROFILE}_ocr_uploader`;
export const MAIN_EDIT_IMAGE = `${MAIN_PROFILE}_edit_image`;
export const MAIN_DETAILS_SECTION = `${MAIN_PROFILE}_details_section`;
export const MAIN_OTHERS_SECTION = `${MAIN_PROFILE}_others_section`;
export const MAIN_PROFILE_EDIT_MODE = `${MAIN_PROFILE}_edit_mode`;
export const MAIN_PROFILE_EDIT_SAVE = `${MAIN_PROFILE}_edit_save`;
export const MAIN_PROFILE_EDIT_CANCEL = `${MAIN_PROFILE}_edit_cancel`;
export const MAIN_WORKSPACE_INFO = `${BASE_MAIN}_workspace_info`;
export const MAIN_WORKSPACE_PROJECTS = `${BASE_MAIN}_projects`;
export const MAIN_WORKSPACE_HOLIDAYS = `${BASE_MAIN}_holidays`;
export const MAIN_SHARE = `${BASE_MAIN}_share`;
export const MAIN_VIEW_MORE = `${BASE_MAIN}_view_more`;
export const CHOOSE_PROFILE_SCREEN = "choose_profile_screen";
export const PREFIX_EXPIRATION_DATE = "expirationDate";
export const PREFIX_BIRTHDAY_DATE = "birthDayDate";

export const MAIN_DETAILS_SHORT_BIO = `${MAIN_DETAILS_SECTION}_short_bio`;

const BASE_USER = "user";
export const PROFILES_LIST = `${BASE_USER}_profiles_list`;
export const PROFILES_LIST_ICON = `${BASE_USER}_profiles_list_icon`;
export const PROFILES_LIST_MORE = `${BASE_USER}_profiles_list_more`;

export const EXPERIENCE_PROJECTS = "experience_projects";
export const EXPERIENCE_PROJECTS_VIEW = `${EXPERIENCE_PROJECTS}_view`;
export const EXPERIENCE_PROJECTS_EDIT = `${EXPERIENCE_PROJECTS}_edit`;

export const EXPERIENCE_EDUCATIONS = "experience_educations";
export const EXPERIENCE_EDUCATIONS_VIEW = `${EXPERIENCE_EDUCATIONS}_view`;
export const EXPERIENCE_EDUCATIONS_EDIT = `${EXPERIENCE_EDUCATIONS}_edit`;

export const EXPERIENCE_ACCOMPLISHMENT = "experience_accomplishment";
export const EXPERIENCE_ACCOMPLISHMENT_VIEW = `${EXPERIENCE_ACCOMPLISHMENT}_view`;
export const EXPERIENCE_ACCOMPLISHMENT_EDIT = `${EXPERIENCE_ACCOMPLISHMENT}_edit`;

export const SKILLS_MATRIX = "skills_matrix";
export const SKILLS_MATRIX_ENJOYMENT = `${SKILLS_MATRIX}_enjoyment`;
export const SKILLS_MATRIX_KNOWLEDGE = `${SKILLS_MATRIX}_knowledge`;
export const SKILLS_MATRIX_ADD_SKILL_BUTTON = `${SKILLS_MATRIX}_add_skill`;
export const SKILLS_MATRIX_INFO_BUTTON = `${SKILLS_MATRIX}_info`;
export const SKILLS_MATRIX_INFO_CARD = `${SKILLS_MATRIX}_info`;
export const SKILLS_MATRIX_SPACE_FILLER = `${SKILLS_MATRIX}_space_filler`;

export const PROFILE_SUMMARY = "profile_summary";
export const PROFILE_SUMMARY_BASE_INFO = `${PROFILE_SUMMARY}_baseInfo`;
export const PROFILE_SUMMARY_EXPERIENCE = `${PROFILE_SUMMARY}_experience`;

export const PAGE_404_HH = "page_404_hh";

export const PROSPECTS_LIST = "prospects_list";

export const PROSPECTS_PROPOSAL_FOLDER = "prospects_proposal_folder";

export const PROPOSAL_PUBLIC_FOLDER = "proposal-public-folder";

export const PROJECTS_LIST = "projects_list";
export const PROJECT_TEAM = "projectTeam";
export const PROJECT_TEAM_MODAL = `${PROJECT_TEAM}_modal`;

export const SKILLS_MATRIX_EXPERTISE = `${SKILLS_MATRIX}_expertise`;
export const SKILLS_MATRIX_EXPERTISE_KNOWLEDGE = `${SKILLS_MATRIX_EXPERTISE}_knowledge_scale`;
export const SKILLS_MATRIX_EXPERTISE_ENJOYMENT = `${SKILLS_MATRIX_EXPERTISE}_enjoyment_scale`;
export const SKILLS_MATRIX_EXPERTISE_DIFFICULTY = `${SKILLS_MATRIX_EXPERTISE}_difficulty`;
export const SKILLS_MATRIX_EXPERTISE_TEMPLATE = `${SKILLS_MATRIX_EXPERTISE}_template`;

export const CONTRACTORS_FINANCE = "contactors_finance";
export const PROJECTS_FINANCE = "projects_finance";

export const FOLDERS_LIST = "folders_list";
export const FOLDER = "folder";
export const TEAM = "team";
export const TEAM_LIST = `${TEAM}_list`;
export const TEAM_LIST_PROFILE_SUMMARY = `${TEAM_LIST}_profileSummary`;

export const CLIENTS_LIST = "clients_list";
export const CLIENT = "client";
export const CLIENT_NAME = "clientName";
export const ACTIVE_PROSPECTS = "activeProspects";
export const ACTIVE_PROJECTS = "activeProjects";
export const ACTIVE_FOLDERS = "activeFolders";

export const OCR = "ocr";
export const OCR_UPLOADER = "ocrUploader";
export const WIZARD_CONTENT_WRAPPER = "wizardContentWrapper";
export const BENCH = "bench";
export const SKILLS_SEARCH = "skills_search";

export const HOLIDAYS = "holidays";
export const HOLIDAY = "holiday";
export const HOLIDAY_TYPE = `${HOLIDAY}_type`;
export const CONFIRM_MODAL = "confirm_modal";
export const TEAM_GENERATOR = "teamGenerator";
