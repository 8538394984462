import {
  ReactElement,
  KeyboardEvent,
  useState,
  useContext,
  useEffect,
  ChangeEvent,
} from "react";
import { AnyAction } from "redux";
import { Controller, FieldError, useForm } from "react-hook-form";
import { useDispatch } from "redux-react-hook";
import {
  Button2,
  Toggle,
  ToggleSelect,
  PermissionContext,
  Label,
  Tooltip,
  CopyLinkIconWithTooltip,
  Empty,
  Input,
  SearchSelect,
  LinkButton,
} from "@ps/ui-components";
import { classJoin, prepareDictionarySearchSelect } from "@ps/utils";
import { ProposalColumnProps } from "./types";
import {
  ACTIVE,
  ADD_RESUME,
  ARCHIVED,
  CLIENT,
  CLIENT_ID,
  CLIENT_NAME,
  FOLDER,
  FOLDERS,
  FOLDER_NAME,
} from "../../../constants";
import { ReactComponent as EditIcon } from "../../../../images/profile-summary/edit.svg";
import { ReactComponent as AddIcon } from "../../../../images/profile-summary/add.svg";
import { useTranslationWithNamespace, useMediaQuery } from "../../../../hooks";
import { FolderService, fetchFolder } from "../../../index";
import { Keys, filterArchivedClients } from "../../../../shared";
import CustomLabel from "../../../../prospect-domain/ui/prospectDetails/sections/folder/customLabel";
import { PROSPECT_FULL_ACCESS } from "../../../../shared/permissions";
import { getClientData } from "../../../../shared/utils/clients-utils";
import styles from "../styles.module.css";

const BASE_PATH = "folders.list";

const ProposalColumn = ({
  folder,
  client,
  clientsList,
  onlyActiveCandidates,
  handleResumesAdd,
}: ProposalColumnProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const matches = useMediaQuery("(min-width: 1280px)");

  const translate = (field: string, isRequired = true) =>
    `${t(`folders.list.${field}`)}${isRequired ? "*" : ""}`;

  const dispatch = useDispatch();
  const { hasPermission } = useContext(PermissionContext);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState<FieldError | undefined>();

  const { handleSubmit, control } = useForm({
    defaultValues: { folder, client },
  });

  const [anon, setAnon] = useState<boolean>();

  const toggleArchiveStatus = async () => {
    if (folder?.archived) {
      await FolderService.unarchiveFolder(folder?.id);
    } else {
      await FolderService.archiveFolder(folder.id);
    }
    await fetchFolder(folder.id, dispatch);
  };

  const onSubmit = async (data): Promise<AnyAction | void> => {
    if (
      folder?.clientId === data?.folder?.clientId &&
      folder?.folderName === data?.folder?.folderName
    ) {
      setEdit(false);
    }
    await FolderService.updateFolder(folder.id, { ...data, ...folder });
    await fetchFolder(folder.id, dispatch);
  };

  const handleAnonymizeCheck = async (anonymized: boolean): Promise<void> => {
    await FolderService.updateFolder(folder?.id, { anonymized, ...folder });
    await fetchFolder(folder.id, dispatch);
  };

  useEffect(() => {
    if (anon) handleAnonymizeCheck(anon);
  }, [anon]);

  const link = `${process?.env?.REACT_APP_PUBLIC_S3_PREFIX}${
    window.location.host.split(".")?.[0]
  }${process?.env?.REACT_APP_PUBLIC_S3_SUFFIX}${folder.id}`;

  const errorMaxLengthMessage = `${t("folders.errors.cantBeMoreChars", {
    number: 100,
  })}`;

  if (edit)
    return (
      <div
        className={classJoin(
          styles.proposalColumn,
          "flex flex-col gap-5 rounded-lg w-3/12 shadow-lg px-4 pt-6 pb-8 h-fit",
        )}
      >
        <Label
          dataCy={`${FOLDER}_${FOLDER_NAME}`}
          text={translate(FOLDER_NAME, true)}
        >
          <Controller
            name={`${FOLDER}.${FOLDER_NAME}`}
            render={({
              field: { value, onChange },
              fieldState: { error: fieldError },
            }) => {
              setError(fieldError);
              return (
                <Input
                  dataCy={`${FOLDER}_${FOLDER_NAME}_nameInput`}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onChange(event.target.value)
                  }
                  autoFocus
                  defaultValue={value}
                  error={!!error}
                  message={error?.message || ""}
                  additionalContainerClass="overflow-hidden truncate w-full mr-2"
                />
              );
            }}
            rules={{
              required: {
                value: true,
                message: t(`${FOLDERS}.errors.cantBeEmpty`),
              },
              validate: (value) => {
                if (!value?.trim().length)
                  return t(`${FOLDERS}.errors.cantBeEmpty`);
                if (value?.length > 100) return errorMaxLengthMessage;
                return true;
              },
            }}
            control={control}
          />
        </Label>
        <Label
          dataCy={`${FOLDER}_${CLIENT_NAME}`}
          text={translate(CLIENT, true)}
        >
          <Controller
            render={({ field: { onChange, value } }) => (
              <SearchSelect
                additionalClassName="w-full"
                dataCy={CLIENT_NAME}
                options={prepareDictionarySearchSelect(
                  filterArchivedClients(clientsList),
                  "id",
                  "id",
                  "clientName",
                  "clientName",
                )}
                width="w-full"
                defaultValue={getClientData(value, clientsList)}
                onChange={onChange}
                searchable
              />
            )}
            name={`${FOLDER}.${CLIENT_ID}`}
            control={control}
          />
        </Label>
        <div className="flex gap-x-4 justify-between mt-60">
          <LinkButton
            dataCy={`${FOLDER}_cancel`}
            additionalClassName="mt-auto underline"
            padding="p-0"
            variant="secondary"
            onClick={() => setEdit(false)}
          >
            {t(`folders.actions.cancel`)}
          </LinkButton>
          <Button2 dataCy={`${FOLDER}_save`} onClick={handleSubmit(onSubmit)}>
            {t(`folders.actions.save`)}
          </Button2>
        </div>
      </div>
    );

  return (
    <div className="flex flex-col gap-5 rounded-lg w-3/12 shadow-lg px-4 pt-6 pb-8 h-fit">
      <>
        <div className="flex items-start justify-between">
          <ToggleSelect
            dataCy={`${FOLDER}_archive_toggle`}
            checked={!folder?.archived}
            onChange={toggleArchiveStatus}
            activeLabel={t(`${BASE_PATH}.${ACTIVE}`)}
            negationLabel={t(`${BASE_PATH}.${ARCHIVED}`)}
          />
          {!folder.archived && (
            <Tooltip
              placement="top"
              content={<span>{t(`${BASE_PATH}.actions.edit`)}</span>}
              isDisabled={!matches}
            >
              <div
                className="flex items-center gap-4 border px-4 py-1 rounded-md text-primary-50 hover:text-primary-60 h-9"
                role="button"
                tabIndex={0}
                data-cy={`${FOLDER}_edit`}
                onClick={() => setEdit(true)}
                onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === Keys.ENTER) {
                    setEdit(true);
                  }
                }}
              >
                {!matches && t(`${BASE_PATH}.actions.edit`)}
                <EditIcon />
              </div>
            </Tooltip>
          )}
        </div>
        {!folder.archived && (
          <Button2
            dataCy={`${FOLDER}-${ADD_RESUME}`}
            onClick={handleResumesAdd}
            variant="secondary"
            width="w-full"
            additionalClass="whitespace-nowrap"
          >
            <span className="flex items-center justify-center gap-2 flex-no-wrap mx-2">
              <AddIcon className="fill-current" />
              <span>{t(`${BASE_PATH}.${ADD_RESUME}`)}</span>
            </span>
          </Button2>
        )}
      </>
      <CustomLabel
        dataCy={`${FOLDER}-resumes`}
        label={t(`${BASE_PATH}.resumes`)}
      >
        <div className="bg-other-toxicGreen w-fit px-2 rounded-xl font-semibold">
          {onlyActiveCandidates?.length}
        </div>
      </CustomLabel>
      <CustomLabel dataCy={`${FOLDER}_client`} label={t(`${BASE_PATH}.client`)}>
        {client?.clientName}
      </CustomLabel>
      <CustomLabel
        dataCy={`${FOLDER}-lastUpdate`}
        label={t(`${BASE_PATH}.lastUpdate`)}
      >
        {folder?.lastUpdate?.format("DD.MM.YYYY")}
      </CustomLabel>
      {!folder.archived && (
        <CustomLabel
          dataCy={`${FOLDER}-anonymized`}
          label={t(`${BASE_PATH}.anonymized`)}
        >
          <Toggle
            checked={folder.anonymized}
            onChange={() => setAnon((a) => !a)}
            dataCy={`${FOLDER}-anonymized`}
            additionalClassName={classJoin(
              !hasPermission(PROSPECT_FULL_ACCESS) &&
                "pointer-events-none opacity-25",
            )}
          />
        </CustomLabel>
      )}
      <div className="flex flex-col mt-6 w-full">
        <CustomLabel
          dataCy={`${FOLDER}-pin`}
          label={t(`${BASE_PATH}.foldersPIN`)}
        >
          {folder?.pin ? (
            <div className="flex items-center w-full justify-between">
              <span className="ml-5">{folder?.pin}</span>
              <CopyLinkIconWithTooltip
                dataCy={`${FOLDER}_pin-copy`}
                valueToCopy={folder?.pin}
              />
            </div>
          ) : (
            <Empty />
          )}
        </CustomLabel>
        <Label
          dataCy={`${FOLDER}-address`}
          text={t(`${BASE_PATH}.folderUrl`)}
          labelFontWeight="font-bold"
          labelFontSize="text-base"
        >
          <div className="flex items-center mt-2 w-full justify-between relative gap-2">
            <Tooltip
              placement="left"
              content={
                <span className="text-sm whitespace-nowrap">{link}</span>
              }
              isInteractive
              additionalChildrenClassName="w-full"
            >
              <a href={link} rel="noreferrer noopener" target="_blank">
                <Button2
                  dataCy={`${FOLDER}-address`}
                  borderRadius="rounded-lg"
                  width="w-full"
                  paddingX="px-8"
                  additionalClass="whitespace-nowrap"
                >
                  {t(`${BASE_PATH}.goToFolder`)}
                </Button2>
              </a>
            </Tooltip>
            {folder?.archived ? (
              <></>
            ) : (
              <CopyLinkIconWithTooltip
                dataCy={`${FOLDER}_url-copy`}
                valueToCopy={link}
              />
            )}
          </div>
        </Label>
      </div>
    </div>
  );
};

export default ProposalColumn;
