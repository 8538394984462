import { ReactElement, KeyboardEvent, useEffect } from "react";
import { Button } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
// eslint-disable-next-line prodigal-son/no-use-mapped-state
import { useMappedState } from "redux-react-hook";
import { useFormContext } from "react-hook-form";
import {
  MAIN_PROFILE_EDIT_CANCEL,
  MAIN_PROFILE_EDIT_SAVE,
} from "../../../../shared/data-cy";
import { BasicInfo, BasicInfoOperative } from "./basic-info";
import Bio from "./bio";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import Work from "./work";
import Languages from "./languages";
import {
  EditModeMapState,
  EditModeMapStateReturn,
  EditModeProps,
} from "./types";
import {
  AVAILABILITY,
  BASIC_INFO,
  BIO,
  GOALS_CURRENT_YEAR,
  LANGUAGES,
  WORK,
} from "../constants";
import Availability from "./availability";
import DevelopmentGoals from "./developmentGoals";
import { SPECIALIST } from "../../../../shared/constants";
import { Keys } from "../../../../shared";

const EditMode = ({
  className,
  section,
  toggleEditMode,
  profile,
  setLastFocusID,
  lastFocusID,
}: EditModeProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const mapState = (state: EditModeMapState): EditModeMapStateReturn => ({
    myProfile: state?.profiles?.myProfile,
    login: state.login,
  });

  // eslint-disable-next-line prodigal-son/no-use-mapped-state
  const { login } = useMappedState(mapState);
  const { myProfile } = useMappedStateSelector(mapState);
  const { clearErrors, reset } = useFormContext();

  const allSections = {
    [BASIC_INFO]: (props) =>
      props.profile === SPECIALIST || profile?.profile === SPECIALIST ? (
        <BasicInfo
          {...props}
          firstName={login?.firstName || profile?.firstName}
          lastName={login?.secondName || profile?.lastName}
          setLastFocusID={setLastFocusID}
          lastFocusID={lastFocusID}
        />
      ) : (
        <BasicInfoOperative
          {...props}
          firstName={login?.firstName || profile?.firstName}
          lastName={login?.secondName || profile?.lastName}
          setLastFocusID={setLastFocusID}
          lastFocusID={lastFocusID}
        />
      ),
    [BIO]: Bio,
    [AVAILABILITY]: Availability,
    [WORK]: Work,
    [LANGUAGES]: Languages,
    [GOALS_CURRENT_YEAR]: DevelopmentGoals,
  };

  const CurrentSection = allSections[section];

  const abortForm = (): void => {
    toggleEditMode("");
    reset();
    clearErrors();
  };

  useEffect(() => {
    return () => abortForm();
  }, []);

  return (
    <div
      className={classJoin("flex flex-col gap-6 w-full", className)}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === Keys.ESC) abortForm();
      }}
      role="presentation"
    >
      <CurrentSection {...(myProfile || profile)} />
      <div className="flex gap-6">
        <Button dataCy={`${MAIN_PROFILE_EDIT_SAVE}_${section}`} type="submit">
          {t("profile.overview.actions.save")}
        </Button>
        <Button
          dataCy={`${MAIN_PROFILE_EDIT_CANCEL}_${section}`}
          onClick={abortForm}
          variant="secondary"
        >
          {t("profile.overview.actions.cancel")}
        </Button>
      </div>
    </div>
  );
};
export default EditMode;
