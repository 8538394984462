import { ReactElement, useEffect, useState } from "react";
import { Location } from "history";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import { useMappedStateSelector, useTranslationWithNamespace } from "@ps/utils";
import { Button, Modal, ModalFooter } from "@ps/ui-components";
import { toggleEditMode } from "@ps/hh";
import { StateProps, RedirectAlertModalProps } from "./types";
import {
  NAMESPACE,
  MODAL_REDIRECT_ALERT,
  MODAL_REDIRECT_ALERT_CANCEL,
  MODAL_REDIRECT_ALERT_CONFIRM,
} from "../constants";

const RedirectAlertModal = ({
  dispatch,
  clearRedirection,
  text,
}: RedirectAlertModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace(NAMESPACE);
  const mapState = (state: StateProps) => ({
    isFormModified: state.isFormModified,
  });

  const { isFormModified } = useMappedStateSelector(mapState, "");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const showModal = (nextLocation: Location) => {
    setIsModalOpen(true);
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
    dispatch(toggleEditMode(""));
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location
      setShouldUnload(true);
      history.push(lastLocation.pathname);
      dispatch(clearRedirection());
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const alertUser = (e: BeforeUnloadEvent) => {
      if (shouldUnload || isFormModified) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [isFormModified, shouldUnload]);

  return (
    <>
      <Prompt
        when={!!isFormModified}
        message={(nextLocation) => handleBlockedRoute(nextLocation as Location)}
      />
      <Modal
        dataCy={MODAL_REDIRECT_ALERT}
        isOpen={isModalOpen}
        onClose={closeModal}
        height="h-auto"
        withYScroll={false}
      >
        <div className="pb-8">{text || t("preventRedirect.message")}</div>
        <ModalFooter>
          <Button
            dataCy={MODAL_REDIRECT_ALERT_CONFIRM}
            onClick={handleConfirmNavigationClick}
          >
            {t("buttons.confirm")}
          </Button>
          <Button
            dataCy={MODAL_REDIRECT_ALERT_CANCEL}
            onClick={closeModal}
            variant="secondary"
          >
            {t("buttons.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RedirectAlertModal;
