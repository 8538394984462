import { AnyAction, combineReducers } from "redux";
import ActionTypes from "./actionTypes";
import { ProjectModel } from "../models";

export const projectReducer = (
  state: ProjectModel | Record<string, unknown> = {},
  action: AnyAction,
): ProjectModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_PROJECT_START:
      return state;
    case ActionTypes.FETCH_PROJECT_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_PROJECT_ERROR:
      return {};
    default:
      return state;
  }
};

export const projectsListReducer = (
  state: ProjectModel[] | [] = [],
  action: AnyAction,
): ProjectModel[] | [] => {
  switch (action.type) {
    case ActionTypes.FETCH_PROJECTS_START:
      return state;
    case ActionTypes.FETCH_PROJECTS_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_PROJECTS_ERROR:
      return [];
    default:
      return state;
  }
};

export const isFetchingProjectDetails = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_PROJECT_START:
      return true;
    case ActionTypes.FETCH_PROJECT_SUCCESS:
    case ActionTypes.FETCH_PROJECT_ERROR:
      return false;
    default:
      return state;
  }
};

export const myProjectsReducer = (
  state: ProjectModel[] | [] = [],
  action: AnyAction,
): ProjectModel[] | [] => {
  switch (action.type) {
    case ActionTypes.FETCH_MY_PROJECTS_START:
      return state;
    case ActionTypes.FETCH_MY_PROJECTS_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_MY_PROJECTS_ERROR:
      return [];
    default:
      return state;
  }
};

export const isFetchingProjectsList = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_PROJECTS_START:
      return true;
    case ActionTypes.FETCH_PROJECTS_SUCCESS:
    case ActionTypes.FETCH_PROJECTS_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  myProjects: myProjectsReducer,
  project: projectReducer,
  projectsList: projectsListReducer,
});
