import { ReactElement } from "react";
import { Label } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { AvailabilityProps } from "../types";
import {
  AVAILABILITY,
  NATIONALITY,
  PASSPORTS,
  RESIDENCE,
  VISAS,
} from "../../constants";
import { MAIN_PROFILE_EDIT_MODE } from "../../../../../shared/data-cy";
import Empty from "../../empty";
import DocumentsList from "./documentsList";

const BASE_PATH = `${MAIN_PROFILE_EDIT_MODE}_${AVAILABILITY}`;
const BASE_TRANSLATION_PATH = "profile.overview";

const columnStyles = "flex flex-col gap-6 h-fit";

const Availability = ({
  title,
  profileData,
}: AvailabilityProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="grid grid-cols-2 w-full">
      <div className={columnStyles}>
        <Label
          dataCy={`${BASE_PATH}_${RESIDENCE}`}
          key={`${BASE_PATH}_${RESIDENCE}`}
          text={t(`${BASE_TRANSLATION_PATH}.${AVAILABILITY}.${RESIDENCE}`)}
        >
          {typeof profileData?.[RESIDENCE] !== "string" &&
          profileData?.[RESIDENCE]?.label ? (
            profileData?.[RESIDENCE]?.label
          ) : (
            <Empty section={title} />
          )}
        </Label>

        <DocumentsList
          items={profileData?.passports}
          field={PASSPORTS}
          basePath={BASE_PATH}
          translationPath={BASE_TRANSLATION_PATH}
        />
      </div>
      <div className={columnStyles}>
        <Label
          dataCy={`${BASE_PATH}_${NATIONALITY}`}
          key={`${BASE_PATH}_${NATIONALITY}`}
          text={t(`${BASE_TRANSLATION_PATH}.${AVAILABILITY}.${NATIONALITY}`)}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {profileData?.[NATIONALITY]?.length ? (
            typeof profileData?.[NATIONALITY] === "string" ? (
              profileData[NATIONALITY]
            ) : (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              profileData?.[NATIONALITY]?.reduce(
                (acc, obj) => [...acc, obj.label],
                [],
              ).join(", ")
            )
          ) : (
            <Empty section={title} />
          )}
        </Label>
        <DocumentsList
          items={profileData?.visas}
          field={VISAS}
          basePath={BASE_PATH}
          translationPath={BASE_TRANSLATION_PATH}
        />
      </div>
    </div>
  );
};

export default Availability;
