import { ReactElement, useState, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { useMappedStateSelector } from "../../../../../hooks";
import {
  fetchFolder,
  FolderService,
  CandidateDeleteModal,
  CandidateCard,
} from "../../../../../folder-domain";
import {
  ResumePanelProps,
  ResumesPanelMapState,
  ResumesPanelMapStateReturn,
} from "./types";
import { CandidateModel } from "../../../../../folder-domain/models/types";
import { fetchProfilesSummaryList } from "../../../../../profile-domain";

const ResumesPanel = ({
  onlyActiveCandidates,
  archived,
}: ResumePanelProps): ReactElement => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState("");
  const [candidateEditingSeniority, setCandidateEditingSeniority] =
    useState("");

  const mapState = (
    state: ResumesPanelMapState,
  ): ResumesPanelMapStateReturn => ({
    folder: state?.folders?.folder || {},
  });

  useEffect(() => {
    fetchProfilesSummaryList(dispatch);
  }, []);

  const { folder } = useMappedStateSelector(mapState);

  const candidateInfo = onlyActiveCandidates?.find(
    (candidate: CandidateModel) => candidate.userId === isModalVisible,
  );

  const handleOnRemoveCandidate = async () => {
    await FolderService.removeCandidate(folder.id, isModalVisible);
    await fetchFolder(folder.id, dispatch);
    setIsModalVisible("");
  };

  const handleOnUpdateCandidateSeniority = async (
    candidate: CandidateModel,
    newValue: string,
  ) => {
    await FolderService.updateCandidate(folder.id, candidate.userId, newValue);
    await fetchFolder(folder.id, dispatch);
    setCandidateEditingSeniority("");
  };

  return (
    <>
      <div className="grid gap-4 grid-cols-3 grid-flow-rows w-full">
        {onlyActiveCandidates?.map((candidate: CandidateModel) => (
          <CandidateCard
            key={candidate.userId}
            candidate={candidate}
            candidateStatus={candidate.latestStatus?.acceptance || ""}
            handleOnUpdateCandidateSeniority={handleOnUpdateCandidateSeniority}
            setCandidateEditingSeniority={setCandidateEditingSeniority}
            candidateEditingSeniority={candidateEditingSeniority}
            setCandidateDeleteModalVisible={setIsModalVisible}
            containerRef={undefined}
            isFolderArchived={folder.archived}
            isProspectArchived={archived}
            folderId={folder.id}
          />
        ))}
      </div>
      <CandidateDeleteModal
        isOpen={!!isModalVisible}
        onClose={() => setIsModalVisible("")}
        avatar={candidateInfo?.avatar}
        firstName={candidateInfo?.firstName}
        lastName={candidateInfo?.lastName}
        handleOnRemoveCandidate={handleOnRemoveCandidate}
      />
    </>
  );
};

export default ResumesPanel;
