export const PROSPECT_FULL_ACCESS = "prospect:full-access";
export const PROSPECT_LIST_ALL = "prospect:list-all";
export const PROSPECT_VIEW_RATES = "prospect:view-rates";
export const CLIENTS_MANAGE = "clients:manage";
export const CLIENTS_LIST = "clients:list";
export const MATRIX_FULFILLMENTS = "matrix:fulfillments";
export const MATRIX_MANAGE_TEMPLATES = "matrix:manage-templates";
export const PROJECT_FULL_ACCESS = "project:full-access";
export const PROJECT_LIST_ALL = "project:list-all";
export const PROJECT_EDIT_TIMESHEETS = "project:edit-timesheets";
export const CLIENTS_LIST_ALL = "clients:list-all";
export const USERS_LIST_ALL = "users:list-all";
export const USERS_ASSIGN_ROLES = "users:assign-roles";
export const FINANCES_MANAGE_FINANCES = "finances:manage-finances";

export const accesses = {
  ADD_NOTES: "addNotes",
  MANAGE_NEEDS: "manageNeeds",
  MANAGE_DISCOVERY_TEAM: "manageDiscoveryTeam",
  ARCHIVE_PROSPECT: "archive",
  UPDATE_PROSPECT: "update",
  UPDATE_NOTE: "update",
  DELETE_NOTE: "delete",
};
