import {
  useState,
  useEffect,
  useRef,
  KeyboardEvent,
  ReactElement,
} from "react";
import { classJoin } from "@ps/utils";
import useClickOutside from "../dropdown-legacy/useClickOutside";
import Keys from "../../shared/keys";
import { FadeProps } from "./types";

const Fade = ({
  open,
  handleOpen,
  handleClose,
  clickElement,
  clickElementWrapperClassName,
  contentWrapperClassName,
  children,
  tabIndex = 0,
  disabled,
}: FadeProps): ReactElement => {
  const [render, setRender] = useState(open);

  const clickerRef = useRef<HTMLDivElement>(null);

  useClickOutside(handleClose, clickerRef);

  useEffect(() => {
    if (open) setRender(true);
  }, [open]);

  const onAnimationEnd = (): void => {
    if (!open) setRender(false);
  };

  const pointerEvents =
    open || disabled ? "pointer-events-none" : "pointer-events-auto";

  const childrenAnimation = open
    ? "animate-fade-in-scale"
    : "animate-fade-out-scale";

  const handleKeyDownOnClicker = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === Keys.ENTER && handleOpen) handleOpen();
    if (e.key === Keys.ENTER && open) handleClose();
    if (e.key === Keys.ESC) handleClose();
  };

  const handleKeyDownOnFade = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === Keys.ESC) handleClose();
  };

  return (
    <div className="relative">
      <div
        role="button"
        onClick={handleOpen}
        className={classJoin(clickElementWrapperClassName, pointerEvents)}
        tabIndex={tabIndex}
        onKeyDown={handleKeyDownOnClicker}
      >
        {clickElement}
      </div>
      {render && (
        <div
          ref={clickerRef}
          className={classJoin(
            childrenAnimation,
            contentWrapperClassName,
            "absolute",
          )}
          onAnimationEnd={onAnimationEnd}
          onKeyDown={handleKeyDownOnFade}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Fade;
