import { KeyboardEvent, ReactElement } from "react";
import { uid } from "react-uid";
import Checkbox from "../../checkbox";
import CircleButton from "../../circle-button";
import { AmountFilterOptionProps } from "./types";

const AmountFilterOption = ({
  autoFocus,
  activeNumber,
  dataCy,
  isChecked,
  item,
  onClick,
  onKeyDown,
  withAmount,
  filterName,
}: AmountFilterOptionProps): ReactElement => (
  <button
    autoFocus={autoFocus}
    key={uid(item)}
    className="flex gap-2 items-center"
    onClick={() => onClick(isChecked, item.id, filterName)}
    onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) =>
      onKeyDown(e, isChecked, item.id, filterName)
    }
    tabIndex={0}
  >
    <Checkbox dataCy={item.id} checked={isChecked} tabIndex={-1} />
    <span className="capitalize">{item.name}</span>
    {withAmount ? (
      <CircleButton
        activeNumber={activeNumber}
        width="w-6"
        height="w-6"
        textSize="text-sm font-semibold"
        nonActiveStyles="border-primary-80 text-primary-50 bg-secondary-95"
        dataCy={dataCy}
      />
    ) : (
      <></>
    )}
  </button>
);

export default AmountFilterOption;
