import { ReactElement } from "react";
import { ReactComponent as AddGroupImage } from "../../../../../../../images/add-matrix-group.svg";
import { AddNewGroupButton } from "../buttons";
import { useTranslationWithNamespace } from "../../../../../../../hooks";
import { useEditMatrixContext } from "../../context";
import { EmptyGroupsProps } from "../types";

const EmptyGroups = ({ addNewGroup }: EmptyGroupsProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { editProfession, editGroups } = useEditMatrixContext();

  return (
    <div className="flex items-center justify-center h-initial w-full gap-16">
      <div className="flex flex-col justify-start">
        <span className="text-2xl text-neutralPrimary-20">
          {t("expertise.matrix.editMatrix.noGroup")}
        </span>
        <span className="pt-2 pb-8 text-neutralPrimary-20">
          {t("expertise.matrix.editMatrix.noGroupDescription")}
        </span>
        <AddNewGroupButton
          onClick={addNewGroup}
          disabled={!!editProfession || !!editGroups?.length}
        />
      </div>
      <AddGroupImage />
    </div>
  );
};

export default EmptyGroups;
