import { returnObjectIds } from "@ps/utils";
import {
  CreateNoteModel,
  NeedModel,
  NoteModel,
  DiscoveryTeamModel,
  ProspectDetailsModel,
  NoteAPIModel,
  NeedAPIModel,
  DiscoveryTeamMemberAPIModel,
  CreateProspectRequestAPIModel,
  UpdateProspectRequestAPIModel,
} from "../types";

const prepareDomains = (domains) => {
  if (!domains) return [];
  if (domains.length && typeof domains[0] === "string") return domains;
  return domains.map((item) => item?.id);
};

const prepareClientId = (client: string | { id: string }): string =>
  typeof client === "string" ? client : client?.id;

const prepareTechOwner = (
  techOwner?: string | { userId: string },
): string | undefined => {
  if (!techOwner) return undefined;
  return typeof techOwner === "string" ? techOwner : techOwner.userId;
};

const prepareProspectData = (
  data: ProspectDetailsModel,
): CreateProspectRequestAPIModel | UpdateProspectRequestAPIModel => ({
  bd: data?.bd?.userId,
  clientId: prepareClientId(data?.clientName),
  contactPersonEmail: data?.contactPersonEmail,
  contactPersonName: data?.contactPersonName,
  domains: prepareDomains(data?.domains),
  note: data?.note,
  phoneNumber: data?.phoneNumber,
  pm: data?.pm?.userId,
  projectCity: data?.prospectCity,
  projectCountry: data?.prospectCountry?.value,
  projectName: data.prospectName,
  techOwner: prepareTechOwner(data.techOwner),
});

export const createProspectToAPIModel = (
  data: ProspectDetailsModel,
): CreateProspectRequestAPIModel => prepareProspectData(data);

export const updateProspectToAPIModel = (
  data: ProspectDetailsModel,
): UpdateProspectRequestAPIModel => prepareProspectData(data);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const note = (data: CreateNoteModel | NoteModel): NoteAPIModel | any => ({
  title: data.title,
  priority: data.priority || "low",
  body: data.description,
});

export const createNoteToAPIModel = (data: CreateNoteModel): NoteAPIModel =>
  note(data);

export const updateNoteToAPIModel = (data: NoteModel): NoteAPIModel => ({
  ...note(data),
  id: data.id,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const need = (data: NeedModel | any): NeedAPIModel | any => ({
  profession: data.profession.id || data.profession,
  seniority: data.seniority.id || data.seniority,
  quantity: parseInt(data.quantity.id, 10) || parseInt(data.quantity, 10),
  expectedRate:
    data?.expectedRate &&
    data.expectedRate.rate &&
    data.expectedRate.rateUnit &&
    data.expectedRate.currency &&
    data.expectedRate.engagement
      ? {
          rate: parseFloat(data.expectedRate.rate),
          rateSpan: data.expectedRate.rateUnit.id || data.expectedRate.rateUnit,
          currency: data.expectedRate.currency.id || data.expectedRate.currency,
          engagementSpan:
            data.expectedRate.engagement.id || data.expectedRate.engagement,
        }
      : undefined,
  mustHaveTechStack: returnObjectIds(data.mustHaveTechStack),
  niceToHaveTechStack: returnObjectIds(data.niceToHaveTechStack),
  workingTime:
    data?.workingTime &&
    data.workingTime.zone?.value &&
    data.workingTime.coreTime.start &&
    data.workingTime.coreTime.end
      ? {
          coreTime: {
            start: data.workingTime.coreTime.start,
            end: data.workingTime.coreTime.end,
          },
          overlap: data.workingTime?.overlap
            ? parseInt(data.workingTime.overlap.id, 10) ||
              parseInt(data.workingTime.overlap, 10)
            : undefined,

          zone: data.workingTime.zone.value,
        }
      : undefined,
  blacklistedCountries: data?.blacklistedCountries || [],
  expectedLanguage: data?.expectedLanguage?.id || data?.expectedLanguage,
});

export const createNeedToAPIModel = (data: NeedModel): NeedAPIModel =>
  need(data);

export const updateNeedToAPIModel = (data: NeedModel): NeedAPIModel => ({
  ...need(data),
  id: data.id,
});

const discoveryTeam = (
  data: DiscoveryTeamModel,
): DiscoveryTeamMemberAPIModel => ({
  userId: data.userId,
  // TODO - separated data.name to two fields
  firstName: data.name.split(" ")[0],
  lastName: data.name.split(" ")[1],
  avatarUrl: data?.avatarUrl || undefined,
  professions: data?.professions || [],
});

export const addUserToDiscoveryTeamToAPIModel = (
  data: DiscoveryTeamModel,
): DiscoveryTeamMemberAPIModel => discoveryTeam(data);
