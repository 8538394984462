import { ReactElement, useEffect } from "react";
import { LoadingMainPage } from "@ps/ui-components";
import { useDispatch } from "redux-react-hook";
import { fetchProspectsList } from "../../store";
import { useMappedStateSelector } from "../../../hooks";
import ProspectsListWrapper from "../common/prospectsListWrapper";
import { AllProspectsMapState, AllProspectsMapStateReturn } from "./types";

const AllProspects = (): ReactElement => {
  const dispatch = useDispatch();

  const mapState = (
    state: AllProspectsMapState,
  ): AllProspectsMapStateReturn => ({
    isLoading: state?.requestStatus?.isFetchingProspectsList,
    prospectsList: state?.prospects?.prospectsList || [],
  });

  const { prospectsList, isLoading } = useMappedStateSelector(mapState);

  useEffect(() => {
    fetchProspectsList(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading)
    return <LoadingMainPage additionalClassName="bg-neutralPrimary-85" />;
  return <ProspectsListWrapper prospects={prospectsList} />;
};

export default AllProspects;
