import { useState, ReactElement } from "react";
import { useDispatch } from "redux-react-hook";
import { Button, Modal, ModalFooter, ModalHeader } from "@ps/ui-components";
import { useHistory } from "react-router-dom";
import {
  CreateProspectContainerProps,
  CreateProspectModalMapState,
  CreateProspectModalMapStateReturn,
  NewProspectModalDataProps,
} from "./types";
import { fetchClients } from "../../../../client-domain";
import { ADD_NEW_PROSPECT, DOMAINS } from "../../../constants";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import ProspectContent from "./prospectContent";
import { ProspectService } from "../../../services";
import { fetchProspectsList } from "../../../store";
import AddNewClientModal from "../../../../client-domain/ui/addNewClientModal";
import { ReactComponent as ProspectIcon } from "../../../../images/prospect/prospect.svg";

const errorsInitialState = {
  prospectName: false,
  clientName: false,
  domains: false,
};

const CreateProspectModal = ({
  onClose,
  isOpen,
  defaultClientId,
}: CreateProspectContainerProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentID, setCurrentID] = useState("");

  const mapState = (
    state: CreateProspectModalMapState,
  ): CreateProspectModalMapStateReturn => ({
    clients: state.clients.clientsList || [],
    domainDict: state.dictionaries.domain || [],
  });

  const { clients, domainDict } = useMappedStateSelector(mapState);

  const [isNewClientModal, setIsNewClientModal] = useState(false);
  const [prospectData, setProspectData] = useState<NewProspectModalDataProps>({
    prospectName: "",
    clientName: defaultClientId || "",
    domains: [],
  });
  const [error, setError] = useState(errorsInitialState);

  const handleOnClose = () => {
    if (!isNewClientModal) {
      setError(errorsInitialState);
      setProspectData({
        prospectName: "",
        clientName: "",
        domains: [],
      });
      onClose();
    }
  };
  const handleOnSubmit = async () => {
    const newError: {
      prospectName?: boolean;
      clientName?: boolean;
      domains?: boolean;
    } = {};

    if (!prospectData.clientName) {
      newError.clientName = true;
    }

    if (
      !prospectData.prospectName ||
      !prospectData.prospectName.trim().length
    ) {
      newError.prospectName = true;
    }

    if (!prospectData.domains.length) {
      newError.domains = true;
    }

    if (Object.keys(newError).length > 0) {
      setError((prev) => ({ ...prev, ...newError }));
      return newError;
    }

    if (!isNewClientModal) {
      const newProspectId = await ProspectService.createProspect({
        ...prospectData,
        [DOMAINS]: prospectData.domains,
      });

      if (defaultClientId) {
        await fetchProspectsList(dispatch, defaultClientId);
        history.push(`/prospect/${newProspectId}`);
      }

      if (newProspectId && typeof newProspectId === "string") {
        handleOnClose();
        if (!defaultClientId) {
          history.push(`/prospect/${newProspectId}`);
        }
      }
    }

    return null;
  };

  const handleOnNewClientClose = (newClientId = "") => {
    if (newClientId && typeof newClientId === "string") {
      setProspectData((prev) => ({ ...prev, clientName: newClientId }));
      setError((prev) => ({ ...prev, clientName: false }));
    }

    setIsNewClientModal(false);
  };

  return isNewClientModal ? (
    <AddNewClientModal
      fetchClients={() => fetchClients(dispatch)}
      isOpen={isNewClientModal}
      onClose={handleOnNewClientClose}
    />
  ) : (
    <Modal
      isOpen={isOpen}
      dataCy={ADD_NEW_PROSPECT}
      onClose={handleOnClose}
      width="w-1/3"
      height="h-auto"
      withYScroll={false}
      withCloseIcon
    >
      <ModalHeader
        Icon={<ProspectIcon />}
        title={t("projects.prospect.addNewModalTitle")}
        additionalClassName="pb-6"
        fontSize="text-xl"
      />
      <ProspectContent
        addNewClient={() => setIsNewClientModal(true)}
        clients={clients}
        currentID={currentID}
        data={prospectData}
        defaultClientId={defaultClientId}
        error={error}
        domainDict={domainDict}
        setCurrentID={setCurrentID}
        setData={setProspectData}
        setError={setError}
      />
      <ModalFooter className="flex gap-x-6 mt-14">
        <Button
          dataCy="cancel"
          variant="secondary"
          width="w-1/2"
          onClick={handleOnClose}
        >
          {t("projects.prospect.actions.cancel")}
        </Button>
        <Button
          dataCy="save"
          type="submit"
          width="w-1/2"
          onClick={handleOnSubmit}
        >
          {t("projects.prospect.actions.create")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateProspectModal;
