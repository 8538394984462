import React, { KeyboardEvent, ReactElement, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch } from "redux-react-hook";
import {
  Input,
  SearchSelect,
  TimeTrackerInput,
  Tooltip,
} from "@ps/ui-components";
import { classJoin, HHMMToMinutes, minutesToHHMM } from "@ps/utils";
import { ReactComponent as DeleteIcon } from "../../../../../images/timesheet/timelog/delete-entry-icon.svg";
import { ReactComponent as MoneyIcon } from "../../../../../images/timesheet/timelog/money-icon.svg";
import { ReactComponent as ClockIcon } from "../../../../../images/timesheet/timelog/clock-icon.svg";
import { useTranslationWithNamespace } from "../../../../../hooks";
import {
  NOTE_INPUT,
  SELECT_RATE,
  TIME_INPUT,
} from "../../../../../shared/data-cy";
import { rates } from "../../common/constants";
import { appendTimesheetEntry } from "../../../../../store";
import { focusVisibleStyles, Keys } from "../../../../../shared";
import { TimeEntriesService } from "../../../../../services";
import { IValueLabel } from "../../types";
import { AdvancedViewRowProps } from "./types";

const BASE_PATH = "timesheet.timelog.advancedView";

const deleteIconStyles: string = classJoin.template`
flex items-center justify-self-end p-2 border border-neutralSecondary-60
rounded-lg text-neutralSecondary-60 cursor-pointer
`;

const NOTE_CHARS_LIMIT = 300;

const AdvancedViewRow = ({
  data,
  day,
  disabled,
  entry,
  fetchData,
  newEntry,
  projectEntries,
  projectId,
  setNewEntry,
  setOpenedProjectAdvancedView,
  timeFrame,
  autoFocus,
}: AdvancedViewRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const [newNote, setNewNote] = useState("");
  const [error, setError] = useState(false);
  const [noteError, setNoteError] = useState("");

  const startOfWeek: Dayjs = timeFrame?.[0];
  const endOfWeek: Dayjs = timeFrame?.[1];

  const createTimeEntry = async (newTime: string): Promise<void> => {
    if (newTime === "00:00") return;
    if (projectId && day) {
      await TimeEntriesService.createProjectTimeEntry({
        typeId: projectId,
        day,
        minutes: HHMMToMinutes(newTime),
        rate: 10,
      });
      fetchData(startOfWeek, endOfWeek);
    }
  };

  const handleUpdateTimeInEntry = async (newTime: string): Promise<void> => {
    const currentEntry = entry;
    if (!currentEntry) return;
    if (newTime === currentEntry.time) return;
    if (newTime === "00:00" || newTime === "") {
      setError(true);
      return;
    }
    await TimeEntriesService.updateProjectTimeEntry(entry.id, {
      typeId: entry.typeId,
      day: dayjs(entry.date),
      minutes: HHMMToMinutes(newTime),
      rate: entry.rate,
      note: entry.note,
    });

    fetchData(startOfWeek, endOfWeek);
    setError(false);
  };

  const handleUpdateRateInEntry = async (newRate: {
    value: number;
    label: string;
  }): Promise<void> => {
    if (!entry) return;
    const rateToInt = newRate.value;
    if (rateToInt === entry.rate) return;

    await TimeEntriesService.updateProjectTimeEntry(entry.id, {
      typeId: entry.typeId,
      day: dayjs(entry.date),
      minutes: entry.minutes,
      rate: rateToInt,
      note: entry.note,
    });

    fetchData(startOfWeek, endOfWeek);
  };

  const handleOnChangeNote = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setNewNote(event.target.value);
  };

  const handleUpdateNoteInEntry = async (): Promise<void> => {
    if (!entry) return;
    if (newNote === entry.note) return;
    await TimeEntriesService.updateProjectTimeEntry(entry.id, {
      typeId: entry.typeId,
      day: dayjs(entry.date),
      minutes: entry.minutes,
      rate: entry.rate,
      note: newNote,
    });

    fetchData(startOfWeek, endOfWeek);
  };

  const handleRemoveEntry = async (): Promise<void> => {
    if (!entry) return;
    if (data.length && data.includes(entry)) {
      await TimeEntriesService.removeTimesheetEntry(entry.id);

      await fetchData(startOfWeek, endOfWeek);
      if (Object.values(projectEntries).flat().length === 1) {
        dispatch(appendTimesheetEntry({ id: entry?.typeId }));
        setOpenedProjectAdvancedView({ projectId: "", date: "" });
      }
    }
  };

  const handleOnBlur = (value: string): void => {
    if (newEntry && value !== "00:00") {
      createTimeEntry(value);
    }
    handleUpdateTimeInEntry(value);
  };

  const ratesValueLabel = rates.map((rate: string) => ({
    value: parseInt(rate, 10),
    label: `x${parseInt(rate, 10) / 10}`,
  }));

  const onSaveNoteData = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.target.value?.length > NOTE_CHARS_LIMIT)
      setNoteError(
        t(`${BASE_PATH}.errorMaxCharsNote`, { count: NOTE_CHARS_LIMIT }),
      );
    else handleUpdateNoteInEntry();
  };

  const renderNoteInput = (): ReactElement => (
    <Input
      additionalClass="text-neutralPrimary-30 placeholder-neutralSecondary-60"
      additionalContainerClass="w-full"
      width="w-full"
      dataCy={NOTE_INPUT}
      defaultValue={entry?.note || ""}
      disabled={newEntry || disabled}
      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        onSaveNoteData(e);
      }}
      onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
        setNoteError("");
        handleOnChangeNote(e);
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        !disabled && handleOnChangeNote(event)
      }
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (disabled || e.key !== Keys.ENTER) return;
        onSaveNoteData(e);
      }}
      placeholder={t(`${BASE_PATH}.note`)}
      error={!!noteError}
      message={noteError}
    />
  );

  const renderRateSelect = (): ReactElement => (
    <SearchSelect
      dataCy={SELECT_RATE}
      defaultValue={ratesValueLabel.find(
        (rate: IValueLabel) => rate.value === entry?.rate,
      )}
      disabled={newEntry || disabled}
      onChange={(nevValue: { value: number; label: string }) =>
        !disabled && handleUpdateRateInEntry(nevValue)
      }
      options={ratesValueLabel}
      placeholder={`x${
        (entry && entry.rate / 10) || parseInt(rates[0], 10) / 10
      }`}
      searchable={false}
      width="w-24"
    />
  );

  return (
    <div className="flex items-start whitespace-nowrap gap-8 w-full">
      <div className="flex items-start gap-4">
        <ClockIcon className="text-primary-50 fill-current mt-2" />
        <TimeTrackerInput
          autoFocus={autoFocus}
          dataCy={TIME_INPUT}
          disabled={disabled}
          error={error}
          message={t(`${BASE_PATH}.addTimeEntry`)}
          onBlur={(newValue: string) => {
            if (!disabled && (entry || newValue)) handleOnBlur(newValue);
          }}
          placeholder="HHMM"
          value={entry && minutesToHHMM(entry.minutes)}
        />
      </div>

      {newEntry ? (
        <>
          <Tooltip
            placement="top"
            content={<span>{t(`${BASE_PATH}.newRowTooltip`)}</span>}
          >
            <div className="flex items-center gap-4 relative">
              <MoneyIcon className="text-primary-50 fill-current" />
              {renderRateSelect()}
            </div>
          </Tooltip>
          <Tooltip
            placement="top"
            content={<span>{t(`${BASE_PATH}.newRowTooltip`)}</span>}
            additionalChildrenClassName="w-full"
          >
            {renderNoteInput()}
          </Tooltip>
        </>
      ) : (
        <>
          <div className="flex items-center gap-4 relative">
            <Tooltip
              placement="top"
              content={<span>{t(`${BASE_PATH}.rateTooltip`)}</span>}
            >
              <MoneyIcon className="text-primary-50 fill-current" />
            </Tooltip>
            {renderRateSelect()}
          </div>
          {renderNoteInput()}
        </>
      )}
      <div
        className={classJoin(
          deleteIconStyles,
          focusVisibleStyles,
          disabled && "disabled opacity-20 pointer-events-none",
        )}
        onClick={() => !disabled && handleRemoveEntry()}
        role="button"
        tabIndex={0}
        onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
          if (event.key === Keys.ENTER && !disabled) {
            newEntry && setNewEntry
              ? setNewEntry((active) => !active)
              : handleRemoveEntry();
          }
        }}
      >
        <DeleteIcon className="text-neutralPrimary-30 fill-current" />
      </div>
    </div>
  );
};

export default AdvancedViewRow;
