import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { classJoin } from "@ps/utils";
import { CardHeaderProps } from "./types";
import { CARD_HEADER } from "../../shared/data-cy";

const CardHeader = ({
  additionalClassName = "",
  children,
  dataCy,
  Icon,
  iconColor,
  separatorHeight = "h-11",
  svgCurrent = "fill",
  width = "w-auto",
}: CardHeaderProps): ReactElement => {
  // TODO refactor if all summary view update
  const { pathname } = useLocation();

  const isSummary = pathname.includes("summary");

  return (
    <div
      className={classJoin(
        "flex gap-2 items-center h-auto",
        width,
        additionalClassName,
      )}
      data-cy={`${CARD_HEADER}_${dataCy}`}
    >
      <Icon
        className={classJoin(
          `${svgCurrent}-current w-10`,
          iconColor || "text-neutralSecondary-60",
        )}
      />
      {!isSummary && (
        <div
          className={classJoin("bg-neutralSecondary-60 w-px", separatorHeight)}
        />
      )}
      <>{children}</>
    </div>
  );
};

export default CardHeader;
