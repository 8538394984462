import { ReactElement, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { classJoin } from "@ps/utils";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import TeamInfo from "./teamInfo";
import DiagramChart from "./diagramChart";
import TeamList from "./teamList";
import { GeneratedElementProps } from "./types";
import { addingCandidatesToFolder } from "../../../../../../folder-domain/store";
import { ProfilesSummaryListModel } from "../../../../../../profile-domain/models";
import { checkUserIds } from "./utils";

const TRANSLATION_PATH = "projects.prospect.proposalFolder.teamGenerator";

const GeneratedElement = ({
  professionDict,
  seniorityDict,
  generatedTeam,
  index,
  folder,
}: GeneratedElementProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();

  const candidatesIds: string[] = folder.candidates.map(
    ({ userId }: { userId: string }) => userId,
  );
  const [addedIds, setAddedIds] = useState(candidatesIds);
  const candidatesAreAdded = checkUserIds(addedIds, generatedTeam.team);

  const handleAddTeamToFolder = async (): Promise<void> => {
    const { team } = generatedTeam;
    const teamIds = team.map(({ userId }) => userId);
    setAddedIds((prev) => [...prev, ...teamIds]);

    const simplifiedTeam = team.map(
      ({ userId, seniority }: ProfilesSummaryListModel) => ({
        userId,
        overriddenSeniority: seniority || "",
      }),
    );

    if (simplifiedTeam) {
      await addingCandidatesToFolder(folder?.id, simplifiedTeam, dispatch);
    }
  };

  return (
    <div
      className={classJoin(
        "flex border-b-2 pb-9 justify-between",
        index > 0 && "pt-9",
      )}
    >
      <div className="flex-col flex gap-y-4 w-3/12">
        <h3 className="text-3xl font-semibold text-neutralPrimary-20">
          {t(`${TRANSLATION_PATH}.team`, {
            number: generatedTeam.orderIndex,
          })}
        </h3>
        <TeamInfo
          scores={generatedTeam.scores}
          orderIndex={generatedTeam.orderIndex}
        />
      </div>
      <div className="w-4/12">
        <DiagramChart scores={generatedTeam.scores} />
      </div>
      <div className="w-5/12">
        <TeamList
          areCandidatesAdded={candidatesAreAdded}
          onAddTeamToFolder={handleAddTeamToFolder}
          team={generatedTeam.team}
          professionDict={professionDict}
          seniorityDict={seniorityDict}
        />
      </div>
    </div>
  );
};

export default GeneratedElement;
