import { ReactElement } from "react";
import { Modal, ModalContent, Button } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../hooks";
import { ReactComponent as SaveIcon } from "../../../../images/finance/holidays/save-graphic.svg";
import { HOLIDAYS_USER, BASE_TRANSLATION_PATH } from "../constants";
import { SaveModalProps } from "./types";

const SaveModal = ({
  isOpen,
  onClose,
  onSave,
  onBack,
}: SaveModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Modal
      dataCy={HOLIDAYS_USER}
      isOpen={isOpen}
      onClose={onClose}
      width="w-max"
      height="h-auto"
      withCloseIcon
    >
      <ModalContent additionalClassName="flex items-center gap-12 px-12">
        <SaveIcon />
        <div className="flex flex-col gap-2 whitespace-nowrap">
          <span className="text-lg font-semibold">
            {t(`${BASE_TRANSLATION_PATH}.saveModal.sure`)}
          </span>
          <span>{t(`${BASE_TRANSLATION_PATH}.saveModal.info`)}</span>
          <div className="flex items-center gap-3 mt-5">
            <Button dataCy={`${HOLIDAYS_USER}_save`} onClick={onSave}>
              {t(`${BASE_TRANSLATION_PATH}.saveModal.save`)}
            </Button>
            <Button
              dataCy={`${HOLIDAYS_USER}_backToList`}
              variant="primaryNegative"
              onClick={onBack}
            >
              {t(`${BASE_TRANSLATION_PATH}.saveModal.backToList`)}
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default SaveModal;
