import { ReactElement, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { classJoin } from "@ps/utils";
import { PROGRESS_BAR_STAGE_PREFIX } from "../../../shared/data-cy";
import { StageProps } from "../types";
import { useThemeMode } from "../../theme-mode-provider";
import styles from "../styles.module.css";
import ConditionalTooltip from "../../conditional-tooltip";

const wrapperStyle = classJoin.props<{ color: string }>`
rounded-full border flex items-center
justify-center h-6 w-6 flex-shrink-0 p-0.5
${({ color }) => `border-${color}`}
`;

const circleStyle = classJoin.props<{ color: string }>`
rounded-full flex items-center justify-center
h-full w-full flex-shrink-0 p-0.5
${({ color }) => `bg-${color}`}
`;

const checkIconAnimation = {
  checked: { opacity: 1, transition: { duration: 0.3, delay: 0.3 } },
  notChecked: { opacity: 0, transition: { duration: 0.3 } },
};

const transitionDuration = "0.3s ease 0.3s";

const Stage = ({
  checked = false,
  dataCy,
  index,
  isLast,
  label = "",
  singleFragmentWidth,
}: StageProps): ReactElement => {
  const { isHighContrast } = useThemeMode();
  const checkedColor = isHighContrast ? "primary-80" : "primary-50";
  const uncheckedColor = isHighContrast ? "primary-30" : "neutralSecondary-80";

  const color = checked ? checkedColor : uncheckedColor;
  const stageRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLSpanElement>(null);

  const [stageWidth, setStageWidth] = useState<number | undefined>(undefined);
  const [isTooltipEnabled, setIsTooltipEnabled] = useState<boolean>(false);

  useEffect((): void => {
    if (stageRef?.current) {
      const { width } = stageRef?.current?.getBoundingClientRect();
      setStageWidth(width);
    }

    if (labelRef?.current) {
      const labelLengthExcceded =
        labelRef.current.scrollWidth > labelRef.current.offsetWidth;
      setIsTooltipEnabled(labelLengthExcceded);
    }
  }, [stageRef, labelRef.current]);

  return (
    <div ref={stageRef} className="flex flex-col items-center relative h-16">
      <div
        key={`${PROGRESS_BAR_STAGE_PREFIX}-${dataCy}-wrapper`}
        className={wrapperStyle({ color })}
        data-cy={`${PROGRESS_BAR_STAGE_PREFIX}-${dataCy}`}
        style={{
          transition: classJoin("border-color", transitionDuration),
        }}
      >
        <div
          className={circleStyle({ color })}
          style={{
            transition: classJoin("background-color", transitionDuration),
          }}
        >
          <svg
            width="10"
            height="8"
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              key={`${PROGRESS_BAR_STAGE_PREFIX}-${dataCy}`}
              d="M9.5 1.49992L3.5 7.49992L0.75 4.74992L1.455 4.04492L3.5 6.08492L8.795 0.794922L9.5 1.49992Z"
              fill="white"
              animate={
                checked
                  ? checkIconAnimation.checked
                  : checkIconAnimation.notChecked
              }
            />
          </svg>
        </div>
      </div>
      {label && (
        <ConditionalTooltip content={label} enabled={isTooltipEnabled}>
          <span
            ref={labelRef}
            className={classJoin(
              "whitespace-nowrap absolute font-semibold bottom-1 truncate cursor-default text-neutralPrimary-20",

              !index && styles.firstStepLabel,
              isLast && styles.lastStepLabel,
              index && !isLast && "text-center",
            )}
            style={{
              width: singleFragmentWidth,
              left:
                index && !isLast && stageWidth && singleFragmentWidth
                  ? -singleFragmentWidth / 2 + stageWidth / 2
                  : undefined,
            }}
          >
            {label}
          </span>
        </ConditionalTooltip>
      )}
    </div>
  );
};

export default Stage;
