import { ReactElement } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { TitleComponentProps } from "../types";
import SectionIcon from "./sectionIcon";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "20px",
  },
  image: {
    marginRight: "10px",
    width: "20px",
    height: "20px",
  },
  textContainer: {
    marginLeft: "10px",
    textTransform: "uppercase",
    fontSize: "22px",
    fontFamily: "PoppinsBold",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "20px",
  },
});

const TitleComponent = ({
  text,
  icon,
  isBlind,
}: TitleComponentProps): ReactElement => (
  <View style={styles.container} wrap={false}>
    <View
      style={{
        ...styles.iconContainer,
        borderRight: isBlind ? "3px solid #B4B8BF" : "3px solid #091B76",
      }}
    >
      <SectionIcon icon={icon} isBlind={isBlind} />
    </View>
    <Text
      style={{
        ...styles.textContainer,
        color: isBlind ? "#6B7482" : "#091B76",
      }}
    >
      {text}
    </Text>
  </View>
);

export default TitleComponent;
