import { ReactElement, ChangeEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Input, Label, SearchSelect } from "@ps/ui-components";
import countries from "i18n-iso-countries";
import {
  ADD_NEW_CLIENT,
  CONTACT_PERSON_EMAIL,
  CONTACT_PERSON_NAME,
  EMAIL_REGEXP_PATTERN,
  PHONE_NUMBER,
  PROSPECT_CITY,
  PROSPECT_COUNTRY,
} from "../../../../constants";
import { ClientModel } from "../../../../../client-domain";
import ControlledInput from "../../../common/controlledInput";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import {
  BaseClientsInfoMapState,
  BaseClientsInfoMapStateReturn,
} from "./types";

const BASE_PATH = ADD_NEW_CLIENT;
const TRANSLATE_PATH = "projects.prospect.details";

const BaseClientsInfo = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { control } = useFormContext();

  const mapState = (
    state: BaseClientsInfoMapState,
  ): BaseClientsInfoMapStateReturn => ({
    clients: state.clients?.clientsList || [],
  });

  const { clients } = useMappedStateSelector(mapState);

  const checkClientName = (name: string): ClientModel =>
    clients.find((client: ClientModel) => client.clientName === name);

  const allCountries = countries.getNames("en", { select: "official" });

  const isBlankOrClientExistsOrMoreChars = (
    value: string,
    maxChars = 100,
    clientValidation = false,
  ) => {
    if (clientValidation && value && checkClientName(value))
      return `${t("projects.prospect.errors.clientExists")}`;
    if (value && !value.trim().length)
      return `${t("projects.errors.cantBeBlank")}`;
    if (value && value.length > maxChars)
      return `${t("projects.errors.cantBeMoreThan", { number: maxChars })}`;
    return true;
  };

  return (
    <>
      <ControlledInput
        name={CONTACT_PERSON_NAME}
        basePath={BASE_PATH}
        translatePath={TRANSLATE_PATH}
        placeholder={CONTACT_PERSON_NAME}
        additionalValidation={(value) =>
          isBlankOrClientExistsOrMoreChars(value)
        }
      />
      <ControlledInput
        name={PHONE_NUMBER}
        basePath={BASE_PATH}
        translatePath={TRANSLATE_PATH}
        placeholder={PHONE_NUMBER}
        additionalValidation={(value) =>
          isBlankOrClientExistsOrMoreChars(value, 40)
        }
      />
      <Label
        dataCy={`${BASE_PATH}_${CONTACT_PERSON_EMAIL}`}
        text={t(`${TRANSLATE_PATH}.${CONTACT_PERSON_EMAIL}`)}
        labelFontWeight="font-bold"
        labelFontSize="text-lg"
        labelColor="text-neutralPrimary-20"
      >
        <Controller
          name={CONTACT_PERSON_EMAIL}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              dataCy={`${BASE_PATH}_${CONTACT_PERSON_EMAIL}`}
              defaultValue={value}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onChange(event.target.value)
              }
              placeholder={t(`${TRANSLATE_PATH}.${CONTACT_PERSON_EMAIL}`)}
              error={!!error}
              message={error?.message || ""}
            />
          )}
          rules={{
            validate: (value) => isBlankOrClientExistsOrMoreChars(value),
            required: false,
            pattern: {
              value: EMAIL_REGEXP_PATTERN,
              message: t("projects.errors.emailPatternNotMatch"),
            },
          }}
        />
      </Label>
      <Label
        text={t(`${TRANSLATE_PATH}.${PROSPECT_COUNTRY}`)}
        dataCy={`${BASE_PATH}_${PROSPECT_COUNTRY}`}
        labelFontWeight="font-bold"
        labelFontSize="text-lg"
        labelColor="text-neutralPrimary-20"
      >
        <Controller
          control={control}
          name={PROSPECT_COUNTRY}
          render={({ field: { onChange, value } }) => (
            <SearchSelect
              dataCy={`${BASE_PATH}_${PROSPECT_COUNTRY}`}
              defaultValue={value}
              onChange={onChange}
              width="w-full"
              options={Object.entries(allCountries).map(([key, name]) => ({
                label: name,
                value: key,
              }))}
              clearable
              placeholder={t(`${TRANSLATE_PATH}.${PROSPECT_COUNTRY}`)}
              menuPosition="fixed"
            />
          )}
        />
      </Label>
      <ControlledInput
        name={PROSPECT_CITY}
        basePath={BASE_PATH}
        translatePath={TRANSLATE_PATH}
        placeholder={PROSPECT_CITY}
        additionalValidation={(value) =>
          isBlankOrClientExistsOrMoreChars(value)
        }
      />
    </>
  );
};

export default BaseClientsInfo;
