import { ReactElement, useState, KeyboardEvent, useEffect } from "react";
import { uid } from "react-uid";
import { useDispatch } from "redux-react-hook";
import { useFormContext } from "react-hook-form";
import { Input, Toggle } from "@ps/ui-components";
import { setRedirection } from "@ps/alert-modal";
import { removeDiacriticsFromString, classJoin } from "@ps/utils";
import { ReactComponent as ExpanderPath } from "../../../../../images/prospect/arch.svg";
import { ReactComponent as GlobeIcon } from "../../../../../images/prospect/globe.svg";
import { Keys, focusVisibleStyles } from "../../../../../shared";
import { RegionsHiddenLayerProps } from "./types";
import { useTranslationWithNamespace } from "../../../../../hooks";
import {
  NEEDS,
  BLACKLISTED_COUNTRIES,
  REGIONS,
  SEARCH,
} from "../../../../constants";
import countries from "../countries.json";
import { CountryProps, ContinentProps } from "../../types";

const TRANSLATE_PATH = "projects.prospect.needs";

const RegionsHiddenLayer = ({
  basePath,
  onExpandClick,
  index,
  bannedRegions,
  setBannedRegions,
}: RegionsHiddenLayerProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { setValue } = useFormContext();
  const dispatch = useDispatch();

  const [activeContinent, setActiveContinent] = useState("AFRICA");
  const [searchValue, setSearchValue] = useState<string>("");

  const filteredCountries: ContinentProps[] = countries
    ?.filter((continent: ContinentProps) => continent.code !== "AN")
    .sort((a: ContinentProps, b: ContinentProps): number =>
      a.name < b.name ? -1 : 1,
    );

  const [countriesFrom, setCountriesFrom] = useState<string>(
    filteredCountries?.[0]?.name,
  );

  const countriesWithPreference: ContinentProps[] = filteredCountries?.map(
    (continent: ContinentProps): ContinentProps => {
      const countryCodes = continent.countries.map(
        (item: CountryProps): string => item.code,
      );
      return {
        code: continent.code,
        countries: continent.countries.map(
          (country: CountryProps): CountryProps => ({
            name: country.name,
            code: country.code,
            preference: !bannedRegions?.includes(country.code),
          }),
        ),
        name: continent.name,
        preference: !countryCodes.every((element: string): boolean =>
          bannedRegions?.includes(element),
        ),
      };
    },
  );

  const sortCountries = (regions: [] | CountryProps[]) =>
    regions?.sort((a: CountryProps, b: CountryProps): number =>
      a.name < b.name ? -1 : 1,
    );

  const handleContinentChange = (continent: ContinentProps) => (): void => {
    if (continent?.preference) {
      setBannedRegions((prev: string[]): string[] => [
        ...prev,
        ...continent.countries.map((item: CountryProps): string => item.code),
      ]);
    } else {
      const countryCodes = continent.countries.map(
        (item: CountryProps): string => item.code,
      );
      const markAllAsPreferred = bannedRegions
        ?.filter((item: string): boolean => !countryCodes.includes(item))
        .flat(1);

      setBannedRegions(markAllAsPreferred);
    }
  };

  const handleCountryChange = (country: CountryProps) => (): void => {
    if (bannedRegions?.includes(country.code)) {
      setBannedRegions((prev: string[]): string[] =>
        prev.filter((item: string): boolean => item !== country.code),
      );
    } else {
      setBannedRegions((prev: string[]): string[] => [...prev, country.code]);
    }
  };

  const saveChanges = (): void => {
    setValue(`${NEEDS}.${index}.${BLACKLISTED_COUNTRIES}`, bannedRegions);
    dispatch(setRedirection());
  };

  useEffect(() => {
    saveChanges();
  }, [bannedRegions]);

  const onlyCountries: CountryProps[] = countriesWithPreference
    ?.map((continent: ContinentProps): CountryProps[] => continent.countries)
    .flat();

  const filteredCountriesToDisplay = (): CountryProps[] => {
    if (searchValue.trim().length) {
      return onlyCountries.filter((country: CountryProps): boolean =>
        removeDiacriticsFromString(country.name.toLowerCase()).includes(
          removeDiacriticsFromString(searchValue.trim().toLowerCase()),
        ),
      );
    }
    return countriesWithPreference
      ?.map((continent: ContinentProps): CountryProps[] =>
        continent?.name === countriesFrom
          ? sortCountries(continent?.countries || [])
          : [],
      )
      .flat();
  };

  const countedCountriesFromContinent = (
    continent: ContinentProps,
  ): number | undefined => {
    const filteredContinent = countriesWithPreference.find(
      ({ code }: ContinentProps) => code === continent.code,
    );

    const filteredPreferencedCountries = filteredContinent?.countries.filter(
      (c: CountryProps) => !c.preference,
    );

    if (filteredPreferencedCountries)
      return filteredPreferencedCountries?.length;
    return undefined;
  };

  return (
    <div data-cy={REGIONS} className="flex py-6 h-full w-full">
      <div className="flex items-center w-full justify-center">
        <div className="flex flex-col w-full ml-24 h-full text-neutralPrimary-20 bg-neutralPrimary-100 rounded-2.5xl p-4">
          <div className="flex items-center justify-between mb-4">
            <span className="font-semibold text-lg">
              {t(`${TRANSLATE_PATH}.preferredCountries`)}
            </span>
            <Input
              dataCy={SEARCH}
              placeholder={t(`${TRANSLATE_PATH}.${SEARCH}`)}
              width="w-64"
              isSearch
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(event.target.value)
              }
              value={searchValue}
            />
          </div>
          <div className="grid grid-cols-2 grid-rows-3 grid-flow-row-dense gap-2 w-full">
            {countriesWithPreference?.map(
              (continent: ContinentProps): ReactElement => (
                <div
                  key={uid(continent.code)}
                  className="flex gap-2 items-center font-semibold"
                  onClick={() => {
                    setSearchValue("");
                    setCountriesFrom(continent.name);
                    setActiveContinent(continent.code);
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === Keys.ENTER) {
                      setSearchValue("");
                      setCountriesFrom(continent.name);
                      setActiveContinent(continent.code);
                    }
                  }}
                >
                  <Toggle
                    dataCy={continent.code}
                    checked={!continent?.preference}
                    onChange={handleContinentChange(continent)}
                    variant="secondary"
                  />
                  <span
                    className={classJoin(
                      activeContinent === continent.code && "text-primary-50",
                    )}
                  >
                    {continent.name}
                  </span>
                  {countedCountriesFromContinent(continent) ? (
                    <div className="rounded-full bg-error-50 flex items-center justify-center text-sm w-6 h-6 text-white">
                      {countedCountriesFromContinent(continent)}
                    </div>
                  ) : null}
                </div>
              ),
            )}
          </div>
          <div className="border-b border-neutralSecondary-41 w-full rounded-lg my-6" />
          <ul className="columns-2 w-full overflow-y-auto">
            {filteredCountriesToDisplay().map(
              (country: CountryProps): ReactElement => (
                <li
                  key={uid(country.code)}
                  className="flex gap-x-2 items-center font-semibold h-10"
                >
                  <Toggle
                    dataCy={country.code}
                    checked={!country?.preference}
                    onChange={handleCountryChange(country)}
                    variant="secondary"
                  />
                  <span
                    className={classJoin(
                      !country.preference && "text-error-50",
                    )}
                  >
                    {country.name}
                  </span>
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
      <div className="relative h-full w-14 flex items-center">
        <ExpanderPath
          className={classJoin(
            focusVisibleStyles,
            "h-96 left-0 overflow-x-hidden absolute text-neutralSecondary-90",
          )}
        />
        <GlobeIcon
          role="button"
          tabIndex={0}
          data-cy={`${basePath}_expand`}
          onClick={onExpandClick}
          onKeyDown={(e: KeyboardEvent<SVGSVGElement>) => {
            if (e.key === Keys.ENTER) {
              onExpandClick();
            }
          }}
          className="text-neutralSecondary-20 hover:text-primary-50 absolute h-14 w-14 -right-11 cursor-pointer outline-none"
        />
        <div
          className="rounded-full bg-error-50 absolute -right-11 flex items-center justify-center
         transform -translate-y-5 w-7 h-7 text-white"
        >
          {bannedRegions && bannedRegions.length}
        </div>
      </div>
    </div>
  );
};

export default RegionsHiddenLayer;
