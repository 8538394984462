import { useState, ReactElement, ChangeEvent } from "react";
import { Input, Label, SearchSelectPicker } from "@ps/ui-components";
import { checkUrl, prepareTechStackDictionaryValues } from "@ps/utils";
import { motion } from "framer-motion";
import { Controller, useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { FormSectionProps } from "../../education/types";
import { TECH_STACK } from "../../projects/constants";
import { checkHasEmptyFields } from "../../../helpers";
import { EXPERIENCE_ACCOMPLISHMENT_EDIT } from "../../../../../../shared/data-cy";
import {
  EVENT_NAME,
  TALKS,
  TITLE,
  URL_PATH,
  VIDEO_URL,
  URL_REGEXP_PATTERN,
  PLACEHOLDER,
} from "../constants";
import { ITechStackProposal } from "../../../types";
import styles from "../../styles.module.css";
import { FULL_TECH_SEARCH_MAX } from "../../../../../../shared/constants";

const MAX_LENGTH_NAME = 100;

const TalksForm = ({
  fullTextSearch,
  index,
  sourcePath = "talks",
  technologyDict,
}: FormSectionProps): ReactElement => {
  const { control, getValues, watch, register, getFieldState, formState } =
    useFormContext();
  const { t } = useTranslationWithNamespace();
  const [searchedProposals, setSearchedProposals] = useState<
    ITechStackProposal[] | []
  >([]);

  const BASE_PATH = `${EXPERIENCE_ACCOMPLISHMENT_EDIT}_${TALKS}`;

  const translate = (field: string, isRequired = true): string =>
    `${t(`profile.experience.cards.talks.${field}`)}${isRequired ? "*" : ""}`;

  const getFormFieldName = (fieldName: string): string =>
    `${sourcePath}.${index}.${fieldName}`;

  const fieldEventName = getFieldState(getFormFieldName(EVENT_NAME), formState);
  const fieldTitle = getFieldState(getFormFieldName(TITLE), formState);
  const fieldUrlPath = getFieldState(getFormFieldName(URL_PATH), formState);
  const videoPath = getFieldState(getFormFieldName(VIDEO_URL), formState);

  const hasEmptyFields = checkHasEmptyFields(
    getValues([EVENT_NAME, TITLE, TECH_STACK]),
  );

  const errorEmptyMessage = `${t("profile.errors.cantBeEmpty")}`;
  const errorBlankMessage = `${t("profile.errors.cantBeBlank")}`;
  const errorMaxLengthMessage = `${t("profile.errors.cantBeMoreChars", {
    number: MAX_LENGTH_NAME,
  })}`;

  const isEmptyOrBlank = (value: string): string | boolean => {
    if (!value.length && hasEmptyFields) return errorEmptyMessage;
    if (!value.trim().length) return errorBlankMessage;
    if (value.length > MAX_LENGTH_NAME) return errorMaxLengthMessage;
    return true;
  };

  const handleOnUrlChange = (event: ChangeEvent<HTMLInputElement>): string =>
    event.target.value.length
      ? checkUrl(event.target.value)
      : event.target.value;

  return (
    <div className="flex flex-col gap-y-5" key={uid(`${TALKS}-${index}`)}>
      <div className="flex flex-row gap-x-5">
        <Label
          dataCy={`${BASE_PATH}_${EVENT_NAME}`}
          text={translate(EVENT_NAME)}
        >
          <Input
            dataCy={`${BASE_PATH}_${EVENT_NAME}`}
            width="w-full"
            autoFocus
            value={watch(getFormFieldName(EVENT_NAME)) || ""}
            error={!!fieldEventName?.error}
            message={fieldEventName?.error?.message || ""}
            placeholder={translate(`${EVENT_NAME}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(EVENT_NAME), {
              validate: (value) => isEmptyOrBlank(value),
            })}
          />
        </Label>
        <Label dataCy={`${BASE_PATH}_${TITLE}`} text={translate(TITLE)}>
          <Input
            dataCy={`${BASE_PATH}_${TITLE}`}
            width="w-full"
            value={watch(getFormFieldName(TITLE)) || ""}
            error={!!fieldTitle?.error}
            message={fieldTitle?.error?.message || ""}
            placeholder={translate(`${TITLE}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(TITLE), {
              validate: (value) => isEmptyOrBlank(value),
            })}
          />
        </Label>
      </div>
      <div className="flex gap-x-5">
        <Label
          dataCy={`${BASE_PATH}_${URL_PATH}`}
          text={translate(URL_PATH, false)}
        >
          <Input
            dataCy={`${BASE_PATH}_${URL_PATH}`}
            width="w-full"
            value={watch(getFormFieldName(URL_PATH)) || ""}
            error={!!fieldUrlPath?.error}
            message={fieldUrlPath?.error?.message || ""}
            placeholder={translate(`${URL_PATH}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(URL_PATH), {
              required: false,
              pattern: {
                value: URL_REGEXP_PATTERN,
                message: `${t("profile.experience.errors.urlPatternNotMatch")}`,
              },
              onChange: handleOnUrlChange,
            })}
          />
        </Label>
        <Label
          dataCy={`${BASE_PATH}_${VIDEO_URL}`}
          text={translate(VIDEO_URL, false)}
        >
          <Input
            dataCy={`${BASE_PATH}_${VIDEO_URL}`}
            width="w-full"
            value={watch(getFormFieldName(VIDEO_URL)) || ""}
            error={!!videoPath?.error}
            message={videoPath?.error?.message || ""}
            placeholder={translate(`${VIDEO_URL}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(VIDEO_URL), {
              required: false,
              pattern: {
                value: URL_REGEXP_PATTERN,
                message: `${t("profile.experience.errors.urlPatternNotMatch")}`,
              },
              onChange: handleOnUrlChange,
            })}
          />
        </Label>
      </div>
      <div className="w-1/2 pr-2.5">
        <motion.div>
          <Label
            text={translate(TECH_STACK)}
            dataCy={`${BASE_PATH}_${TECH_STACK}`}
          >
            <Controller
              name={getFormFieldName(TECH_STACK)}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SearchSelectPicker
                  additionalTagsWrapClassName={styles.techWrapperOther}
                  dataCy={TECH_STACK}
                  defaultSelected={prepareTechStackDictionaryValues(
                    value,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    technologyDict,
                  )}
                  onTagSelected={onChange}
                  proposalTags={searchedProposals}
                  onInputChange={(searchValue) => {
                    const result =
                      fullTextSearch &&
                      fullTextSearch(searchValue, FULL_TECH_SEARCH_MAX);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setSearchedProposals(result);
                  }}
                  error={!!error}
                  message={error?.message}
                />
              )}
              rules={{
                validate: (value) =>
                  !value.length && hasEmptyFields ? errorEmptyMessage : true,
              }}
            />
          </Label>
        </motion.div>
      </div>
    </div>
  );
};

export default TalksForm;
