const HHMMToMinutes = (HHMM: string): number => {
  if (!HHMM.includes(":")) {
    const hoursPart = parseInt(HHMM.substr(0, 2), 10);
    const hoursPartCut = hoursPart > 24 ? 24 : hoursPart;
    const minutesPart = parseInt(HHMM.substr(2, 4), 10);
    const formatMinutesTo60Seconds = minutesPart > 59 ? 59 : minutesPart;
    const minutesPartCut = hoursPartCut === 24 ? 0 : formatMinutesTo60Seconds;
    return hoursPartCut * 60 + minutesPartCut;
  }
  const hoursPart = parseInt(HHMM.split(":")[0], 10);
  const hoursPartCut = hoursPart > 24 ? 24 : hoursPart;
  const minutesPart = parseInt(HHMM.split(":")[1], 10);
  const formatMinutesTo60Seconds = minutesPart > 59 ? 59 : minutesPart;
  const minutesPartCut = hoursPartCut === 24 ? 0 : formatMinutesTo60Seconds;
  return hoursPartCut * 60 + minutesPartCut;
};

export default HHMMToMinutes;
