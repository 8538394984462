import { handleError } from "@ps/api-utils";
import APIService from "../api/tenant-api";

const TenantService = (() => {
  const getTenantMetadata = async (): Promise<any> => {
    try {
      const response = await APIService.API.getTenantMetadata();
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    getTenantMetadata,
  };
})();

export default TenantService;
