import { ReactElement } from "react";

const TabsPlaceholder = (): ReactElement => (
  <div
    className="bg-neutralPrimary-100 border-b border-neutralSecondary-60"
    style={{ height: "29px" }}
  />
);

export default TabsPlaceholder;
