import { ClientModel } from "../../client-domain";
import { ClientIdValueLabel } from "../../folder-domain";

export const filterArchivedClients = (clients: ClientModel[]): ClientModel[] =>
  clients.filter((client: ClientModel): boolean => !client.archived);

export const getClientData = (
  value: ClientIdValueLabel | string,
  clients: ClientModel[],
): ClientIdValueLabel => {
  if (typeof value === "string")
    return {
      id: value,
      value,
      label:
        clients.find((item: ClientModel): boolean => item.id === value)
          ?.clientName || "",
    };
  return value;
};
