export const BENCH = "bench";

export const SEARCH = "search";

export const ON_PROJECT = "onProject";
export const WITHOUT_PROJECT = "withoutProject";
export const EXCEPT_ON_PROJECT = "exceptOnProject";
export const UNHAPPY_DEV = "unhappyDev";
export const LOW_PROFIT = "lowProfitability";
export const RISK_OF_CLOSURE = "riskOfClosure";
export const OTHER_REASONS = "otherReasons";

export const AVAILABILITY = "availability";
export const NAME = "name";
export const PROFESSION = "profession";
export const SENIORITY = "seniority";
export const LOCATION = "location";
export const PROJECTS = "projects";
export const PROJECT = "project";
export const FOLDER_STATUS = "folderStatus";
export const ROLES = "roles";
export const FIRST_ROLE = "firstRole";
export const REASON = "reason";
export const DAYS = "daysOnBench";

export const FOLDERS_STATUSES = "foldersStatuses";
export const FOLDER_NAME = "folderName";
export const STATUS = "status";
export const NOTE = "note";
export const IN_PROGRESS = "inProgress";
export const APPROVED = "approved";
export const REJECTED = "rejected";
export const ALL_USERS = "allUsers";
export const AVAILABLE = "available";
export const BUSY = "busy";

export const ASC = "asc";
export const DESC = "desc";

export const selectedSorterInitialState = {
  order: ASC,
  name: AVAILABILITY,
};

export const selectedFiltersInitialState = {
  [NAME]: [],
  [PROFESSION]: [],
  [SENIORITY]: [],
  [LOCATION]: [],
  [ROLES]: [],
  [REASON]: [],
};

export const initialCheckersState = {
  [AVAILABLE]: true,
  [BUSY]: true,
};
