import dayjs from "dayjs";
import { ProjectModel } from "@ps/hh";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { TIME_FORMAT_DD_MM_YYYY } from "../../../shared/constants";
import prepareProjectsWithColours from "../../../shared/utils/prepareProjectsWithColors";
import {
  UseChartDataMapState,
  UserChartDataMapStateReturn,
  MappedBarChartEntry,
  UseChartDataReturn,
} from "../chart/types";
import { IDateAsName } from "../types";
import { ReportModel, ReportEntryModel } from "../../../models";
import { YEAR_MONTHS } from "../constants";

const useChartData = (
  timeFrame: Date[],
  isYearView = false,
): UseChartDataReturn => {
  const { t } = useTranslationWithNamespace();

  const mapState = (
    state: UseChartDataMapState,
  ): UserChartDataMapStateReturn => ({
    projectsList: state?.projectsList || [],
    report: state?.reports?.report || [],
  });

  const { projectsList, report } = useMappedStateSelector(mapState) as {
    projectsList: ProjectModel[];
    report: ReportModel[];
  };

  const timeFrameDifference: number =
    dayjs(timeFrame?.[1]).diff(dayjs(timeFrame?.[0]), "day") + 1;

  const timeFrameDates: IDateAsName[] = isYearView
    ? YEAR_MONTHS.map((month: string) => ({
        name: t(`reports.months.${month}`),
      }))
    : Array(timeFrameDifference)
        .fill(0)
        .map(
          (_, index: number): IDateAsName => ({
            name: dayjs(timeFrame?.[0])
              .add(index, "day")
              .format(TIME_FORMAT_DD_MM_YYYY),
          }),
        );

  const projectIds: string[] = report.map(
    (item: ReportModel): string => item.projectId,
  );
  const uniqueProjectIds: string[] = [...new Set(projectIds)];

  const entriesToSingleArray: MappedBarChartEntry[] = report
    .map((reportEntry: ReportModel) => [
      ...reportEntry?.entries?.map(
        (entry: ReportEntryModel): MappedBarChartEntry => ({
          projectId: reportEntry.projectId,
          entryId: entry.id,
          minutes: entry.minutes,
          date: isYearView
            ? dayjs(entry.date).format("MMM")
            : dayjs(entry.date).format(TIME_FORMAT_DD_MM_YYYY),
        }),
      ),
    ])
    .flat();

  const formattedDataForChart: IDateAsName[] = entriesToSingleArray.reduce(
    (acc: IDateAsName[], entry: MappedBarChartEntry) =>
      acc.map(
        (item: IDateAsName): IDateAsName =>
          item.name === entry.date
            ? {
                ...item,
                [entry.projectId]:
                  (item?.[entry.projectId] || 0) + entry.minutes / 60,
              }
            : item,
      ),
    timeFrameDates,
  );

  const projectColors: {
    [projectId: string]: string;
  } = prepareProjectsWithColours(uniqueProjectIds, projectsList);

  const projectsWith0Minutes: Record<string, number> = uniqueProjectIds.reduce(
    (
      acc: Record<string, number>,
      projectId: string,
    ): Record<string, number> => ({
      ...acc,
      [projectId]: 0,
    }),
    {},
  );
  const projectsWithTotalMinutes: Record<string, number> =
    entriesToSingleArray?.reduce(
      (
        acc: Record<string, number>,
        entry: MappedBarChartEntry,
      ): Record<string, number> => ({
        ...acc,
        [entry.projectId]: (acc?.[entry.projectId] || 0) + entry.minutes,
      }),
      projectsWith0Minutes,
    );

  const totalTimeInMinutes: number = Object.entries(
    projectsWithTotalMinutes,
  )?.reduce(
    (acc: number, entry: [string, unknown]): number =>
      acc + (entry?.[1] as number),
    0,
  );

  return {
    totalTimeInMinutes,
    uniqueProjectIds,
    projectColors,
    projectsWithTotalMinutes,
    formattedDataForChart,
  };
};

export default useChartData;
