import { ReactElement, useEffect } from "react";
import { Datepicker2, LinkButton, SearchSelect } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import countries from "i18n-iso-countries";
import { CURRENT_LANGUAGE_CODE } from "../../../../../i18n/constants";
import { AVAILABILITY, COUNTRY, EXPIRATION_DATE, VISAS } from "../../constants";
import LabelWrapper from "./labelWrapper";
import { BASE_PATH } from "./constants";
import styles from "./styles.module.css";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { DocumentsListProps } from "../types";

const DocumentsList = ({
  data,
  documentType,
}: DocumentsListProps): ReactElement => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  const { t } = useTranslationWithNamespace();

  useEffect(() => {
    if (!data?.fields?.length) {
      data.append({
        country: "",
        expirationDate: undefined,
      });
    }
  }, [data]);

  const allCountries = countries.getNames(CURRENT_LANGUAGE_CODE, {
    select: "official",
  });

  return (
    <div>
      {data.fields.map((item, index) => (
        <div
          className={classJoin(
            "flex items-start w-full gap-4 mb-4",
            styles.documentsGrid,
          )}
          key={`${BASE_PATH}_${documentType}_${item.id}`}
          data-cy={`${BASE_PATH}_${documentType}`}
        >
          <LabelWrapper
            field={documentType}
            className="w-full grow"
            tooltipInformation={
              documentType === VISAS
                ? `profile.overview.${AVAILABILITY}.visaTooltip`
                : ""
            }
          >
            <Controller
              render={({ field: { value, onChange } }) => (
                <SearchSelect
                  additionalClassName="w-full"
                  dataCy={`${BASE_PATH}_${documentType}`}
                  defaultValue={value}
                  onChange={onChange}
                  width="w-full"
                  options={Object.entries(allCountries)
                    .map(([key, name]) => ({
                      label: name,
                      value: key,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  error={errors?.[documentType]?.[index]?.[COUNTRY]}
                  clearable
                  message={
                    errors?.[documentType]?.[index]?.[COUNTRY]?.message || ""
                  }
                  placeholder={t(
                    `profile.overview.${AVAILABILITY}.documentPlaceholder`,
                  )}
                />
              )}
              name={`${documentType}.${index}.${COUNTRY}`}
              control={control}
              rules={{
                validate: (value) =>
                  !value &&
                  getValues(`${documentType}.${index}.${EXPIRATION_DATE}`)
                    ? `${t("profile.errors.cantBeEmpty")}`
                    : true,
              }}
            />
          </LabelWrapper>

          <LabelWrapper field={EXPIRATION_DATE} className="w-full">
            <Controller
              render={({ field: { value, onChange } }) => (
                <Datepicker2
                  isInputClearable
                  dataCy={`${documentType}_${EXPIRATION_DATE}`}
                  selected={value ? dayjs(value) : null}
                  onChange={onChange}
                  error={
                    errors?.[documentType]?.[index]?.[EXPIRATION_DATE]
                      ?.message || ""
                  }
                  timeView={["day"]}
                />
              )}
              name={`${documentType}.${index}.${EXPIRATION_DATE}`}
              control={control}
            />
          </LabelWrapper>
          <LinkButton
            type="button"
            additionalClassName={classJoin(index ? "pt-6" : "invisible")}
            dataCy={`REMOVE_${documentType}`}
            onClick={() => data.remove(index)}
          >
            x
          </LinkButton>
        </div>
      ))}
      <LinkButton
        dataCy={`ADD_${documentType}`}
        type="button"
        onClick={() => {
          data.append({
            country: "",
            expirationDate: undefined,
          });
        }}
      >
        {`+ ${t(`profile.overview.${AVAILABILITY}.add`)}`}
      </LinkButton>
    </div>
  );
};

export default DocumentsList;
