import { ReactElement } from "react";
import { View, StyleSheet, Text, Image } from "@react-pdf/renderer";
import { minutesToHHMM, colorFormatters } from "@ps/utils";
import {
  groupDataBasedOnParameter,
  countTotalInGroup,
  DONUT_COLORS,
} from "@ps/time-tracker";
import { useTranslationWithNamespace } from "../../../hooks";
import { FlattenReportEntry } from "../types";
import { LabelWithDot } from "../components";
import { SectionContentProps } from "./types";
import { PROJECT_NAME, NO_DESCRIPTION } from "../constants";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    fontSize: "12px",
    alignItems: "center",
  },
  text: {
    marginBottom: "10px",
    fontFamily: "PoppinsBold",
  },
  image: {
    width: "150px",
    height: "120px",
    alignSelf: "center",
  },
});

const SectionContent = ({
  entries,
  field,
  image,
}: SectionContentProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const groupedData: Record<string, FlattenReportEntry[]> =
    groupDataBasedOnParameter(entries, field);

  return (
    <View style={{ padding: "12px", ...styles.container }}>
      <View>
        {Object.keys(groupedData).map(
          (key: string, index: number): JSX.Element => {
            const group: FlattenReportEntry[] = groupedData?.[key];
            const text: string = group?.[0]?.[field];

            return (
              <View key={key} style={styles.container}>
                {field === PROJECT_NAME ? (
                  <Text
                    style={{
                      color: colorFormatters.prepareRGBObjectToHEX(
                        group?.[0]?.projectColor,
                      ),
                      width: "248px",
                      ...styles.text,
                    }}
                  >
                    {text}
                  </Text>
                ) : (
                  // TODO hardcoded fade color for every entry
                  <LabelWithDot
                    color={
                      DONUT_COLORS[
                        index > 9 ? Math.floor(Math.random() * 10) : index
                      ]
                    }
                    text={text || t(`report.${NO_DESCRIPTION}`)}
                    additionalStyles={{ width: "248px", ...styles.text }}
                  />
                )}
                <Text style={{ width: "128px", ...styles.text }}>
                  {minutesToHHMM(countTotalInGroup(group))}
                </Text>
              </View>
            );
          },
        )}
      </View>
      {image ? <Image src={image} style={styles.image} /> : <></>}
    </View>
  );
};

export default SectionContent;
