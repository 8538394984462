import dayjs, { Dayjs } from "dayjs";
import React, {
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { classJoin } from "@ps/utils";
import { ReactComponent as ArrowLeft } from "../../images/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../images/arrowRight.svg";
import Dropdown from "../dropdown/dropdown";
import DayDisplayMode from "./components/DayDisplayMode";
import MonthDisplayMode from "./components/MonthDisplayMode";
import TopDateDisplay from "./components/TopDateDisplay";
import YearDisplayMode from "./components/YearDisplayMode";
import useSelectDate from "./helpers/state/useSelectDate";
import { InputType } from "./helpers/state/useSelectDateTypes";
import DatepickerInput from "./components/DatepickerInput";
import {
  CalendarDisplayModes,
  DatepickerArrow,
  DatepickerInputProps,
  DatepickerMode,
  DatepickerPickerType,
  DatepickerProps,
  DisplayModeProps,
  PresetRangeValue,
} from "./types";
import { DATEPICKER_PREFIX } from "../../shared/data-cy";
import PresetRanges from "./components/PresetRanges";
import DateFormats from "./helpers/dateFormats";
import QuarterDisplayMode from "./components/QuarterDisplayMode";
import { useThemeMode } from "../theme-mode-provider";
import WeekDisplayMode from "./components/WeekDisplayMode";
import { Keys } from "../../shared";

const Datepicker = ({
  defaultSelected,
  monthToOpenOn,
  mode,
  presetRanges,
  disabledDate,
  dateFormatTemplate: passedDateFormatTemplate,
  dataCy,
  onSelected,
  picker = "day",
  closeDropdownAfterSelection = false,
  overrideInput,
  error,
  message,
  disabled,
  width = "w-40",
  autoFocus,
}: DatepickerProps): ReactElement => {
  const prevSelectedDates = useRef<string | undefined>(undefined);
  const [currentDisplayedMonth, setDisplayedMonth] = useState<Dayjs>(
    monthToOpenOn ?? dayjs(),
  );
  const { isLightTheme } = useThemeMode();

  const { SelectedDatesActions, state } = useSelectDate({
    mode: mode as DatepickerMode,
    defaultDates: defaultSelected,
    updateDisplayedMonth: setDisplayedMonth,
    disabledDate,
    onClear: () => onSelected?.(undefined),
    clearRange: () => onSelected?.(undefined),
  });

  const initialDisplayMode: Record<DatepickerPickerType, CalendarDisplayModes> =
    {
      day: CalendarDisplayModes.DAYS,
      week: CalendarDisplayModes.WEEKS,
      month: CalendarDisplayModes.MONTHS,
      quarter: CalendarDisplayModes.QUARTERS,
      year: CalendarDisplayModes.YEARS,
    };

  const [currentDisplayMode, setDisplayMode] = useState(
    initialDisplayMode[picker],
  );

  const initialDateFormatTemplate: Record<DatepickerPickerType, DateFormats> = {
    day: DateFormats.DEFAULT_DAY_FORMAT,
    week: DateFormats.DEFAULT_DAY_FORMAT,
    month: DateFormats.DEFAULT_MONTH_FORMAT,
    quarter: DateFormats.DEFAULT_QUARTER_FORMAT,
    year: DateFormats.DEFAULT_YEAR_FORMAT,
  };

  const dateFormatTemplate =
    passedDateFormatTemplate ?? initialDateFormatTemplate[picker];

  const [showCalendar, setShowCalendar] = useState(false);

  const openCalendar = () => setShowCalendar(true);

  const closeCalendar = useCallback(() => {
    setShowCalendar(false);
    SelectedDatesActions.changeInput(InputType.NONE);
  }, [setShowCalendar, SelectedDatesActions]);

  type ChangeTimeFunction = (time: Dayjs) => Dayjs;
  const nextMonth = (currentMonth: Dayjs) => currentMonth.add(1, "month");
  const prevMonth = (currentMonth: Dayjs) => currentMonth.subtract(1, "month");
  const nextYear = (currentYear: Dayjs) => currentYear.add(1, "year");
  const prevYear = (currentYear: Dayjs) => currentYear.subtract(1, "year");
  const nextDecade = (currentDecade: Dayjs) => currentDecade.add(10, "years");
  const prevDecade = (currentDecade: Dayjs) =>
    currentDecade.subtract(10, "years");

  const changeTimeBasedOnDisplayMode: Record<
    CalendarDisplayModes,
    { right: ChangeTimeFunction; left: ChangeTimeFunction }
  > = {
    [CalendarDisplayModes.DAYS]: { right: nextMonth, left: prevMonth },
    [CalendarDisplayModes.WEEKS]: { right: nextMonth, left: prevMonth },
    [CalendarDisplayModes.MONTHS]: { right: nextYear, left: prevYear },
    [CalendarDisplayModes.QUARTERS]: { right: nextYear, left: prevYear },
    [CalendarDisplayModes.YEARS]: { right: nextDecade, left: prevDecade },
  };
  const handleArrowClick = (arrow: DatepickerArrow) => () =>
    setDisplayedMonth(changeTimeBasedOnDisplayMode[currentDisplayMode][arrow]);

  const handlePresetClick = (value: PresetRangeValue) => () => {
    if (Array.isArray(value)) {
      SelectedDatesActions.selectFirst(value[0]);
      SelectedDatesActions.selectSecond(value[1]);
    } else {
      SelectedDatesActions.selectFirst(value);
    }
  };

  const isArrowDisabled =
    currentDisplayMode === CalendarDisplayModes.MONTHS && picker !== "month";

  const calendarDisplayModesComponents = {
    [CalendarDisplayModes.DAYS]: DayDisplayMode,
    [CalendarDisplayModes.WEEKS]: WeekDisplayMode,
    [CalendarDisplayModes.MONTHS]: MonthDisplayMode,
    [CalendarDisplayModes.QUARTERS]: QuarterDisplayMode,
    [CalendarDisplayModes.YEARS]: YearDisplayMode,
  };

  const displayModeProps: DisplayModeProps = {
    currentDisplayedMonth,
    disabledDate,
    mode: mode as DatepickerMode,
    state,
    setDisplayedMonth,
    setDisplayMode,
    selectedDatesActions: SelectedDatesActions,
    dataCy,
    picker,
    closeCalendar,
  };

  const isRangePicker = mode === DatepickerMode.RANGE_PICKER;
  const shouldDisplayRangePicker =
    isRangePicker && currentDisplayMode === CalendarDisplayModes.DAYS;

  useEffect(() => {
    const selectedDates = state.selected;
    if (isRangePicker) {
      if (selectedDates?.[0] && selectedDates?.[1]) {
        const isRangeHaveChanged =
          prevSelectedDates.current !== JSON.stringify(selectedDates);
        if (isRangeHaveChanged) {
          (onSelected as (range: [Dayjs, Dayjs]) => void)?.(selectedDates);
          prevSelectedDates.current = JSON.stringify(selectedDates);
          if (closeDropdownAfterSelection) closeCalendar();
        }
      }
    } else if (selectedDates?.[0]) {
      (onSelected as (day: Dayjs) => void)?.(selectedDates[0]);
      prevSelectedDates.current = JSON.stringify(selectedDates);
      if (closeDropdownAfterSelection) closeCalendar();
    }
  }, [
    state,
    isRangePicker,
    onSelected,
    closeDropdownAfterSelection,
    closeCalendar,
    prevSelectedDates,
  ]);

  const arrowButtonCommon = classJoin(
    classJoin.template`
w-6 h-6 rounded-md text-xs flex items-center justify-center
bg-primary-50 text-primary-100 hover:opacity-60 active:opacity-100
`,
    isLightTheme && "opacity-30",
  );

  const datepickerInputProps: DatepickerInputProps = {
    mode: mode as DatepickerMode,
    onChangeFirst: SelectedDatesActions.selectFirst,
    onChangeSecond: SelectedDatesActions.selectSecond,
    onClickClear: SelectedDatesActions.clear,
    clearRange: SelectedDatesActions.clearRange,
    onClickInput: openCalendar,
    dateFormatTemplate,
    value: state.selected,
    onFocusDate: setDisplayedMonth,
    currentInput: state.input,
    onChangeInput: SelectedDatesActions.changeInput,
    dataCy,
    error,
    message,
    disabled,
    showCalendar,
    width,
    autoFocus,
  };

  return (
    <Dropdown
      show={showCalendar}
      onClickOutside={closeCalendar}
      width={shouldDisplayRangePicker ? "w-160" : "w-80"}
      overlay={
        <div
          tabIndex={0}
          role="button"
          data-cy={`${DATEPICKER_PREFIX}-overlay-${dataCy}`}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === Keys.ESC || event.key === Keys.ENTER) {
              event.preventDefault();
              closeCalendar();
              event.stopPropagation();
            }
          }}
        >
          <div className="h-min bg-neutralPrimary-100 flex flex-col">
            <div className="flex h-full p-2.5 pb-0 flex-row justify-between mb-3 items-center bg-neutralPrimary-100">
              <button
                className={arrowButtonCommon}
                onClick={handleArrowClick(DatepickerArrow.LEFT)}
              >
                <ArrowLeft />
              </button>
              <TopDateDisplay
                currentDisplayedMonth={currentDisplayedMonth}
                currentDisplayMode={currentDisplayMode}
                setDisplayMode={setDisplayMode}
              />
              {shouldDisplayRangePicker && (
                <>
                  {/* Button used as a placeholder */}
                  <button
                    className={`${arrowButtonCommon} invisible`}
                    onClick={handleArrowClick(DatepickerArrow.LEFT)}
                    disabled={isArrowDisabled}
                  >
                    <ArrowLeft />
                  </button>
                  <TopDateDisplay
                    currentDisplayedMonth={currentDisplayedMonth.add(
                      1,
                      "month",
                    )}
                    currentDisplayMode={currentDisplayMode}
                    setDisplayMode={setDisplayMode}
                  />
                </>
              )}
              <button
                className={arrowButtonCommon}
                onClick={handleArrowClick(DatepickerArrow.RIGHT)}
                tabIndex={0}
              >
                <ArrowRight />
              </button>
            </div>
            <div className="flex flex-row" id="calendar">
              {React.createElement(
                calendarDisplayModesComponents[currentDisplayMode],
                displayModeProps,
              )}
              {shouldDisplayRangePicker &&
                React.createElement(
                  calendarDisplayModesComponents[currentDisplayMode],
                  {
                    ...displayModeProps,
                    currentDisplayedMonth: currentDisplayedMonth.add(
                      1,
                      "month",
                    ),
                  },
                )}
            </div>
            {(presetRanges?.length ?? 0) > 0 && (
              <PresetRanges
                presetRanges={presetRanges}
                onClick={handlePresetClick}
                dataCy={dataCy}
              />
            )}
          </div>
        </div>
      }
    >
      {overrideInput ? (
        overrideInput(datepickerInputProps)
      ) : (
        <DatepickerInput {...datepickerInputProps} />
      )}
    </Dropdown>
  );
};

export default Datepicker;
