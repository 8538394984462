import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { clearRedirection, RedirectAlertModal } from "@ps/alert-modal";
import { Folders, OtherSummary } from "../ui";
import FolderView from "../ui/folderView";

const FoldersRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  return (
    <>
      <RedirectAlertModal
        dispatch={dispatch}
        clearRedirection={clearRedirection}
      />
      <Switch>
        <Route exact path={path}>
          <Folders />
        </Route>
        <Route path={`${path}/:id/:profileId`}>
          <OtherSummary />
        </Route>
        <Route path={`${path}/:id`}>
          <FolderView />
        </Route>
      </Switch>
    </>
  );
};

export default FoldersRoutes;
