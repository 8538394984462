import { ReactElement } from "react";
import { ThinLabelProps } from "./types";

const ThinLabel = ({
  label,
  children,
  dataCy,
}: ThinLabelProps): ReactElement => {
  return (
    <div
      data-cy={dataCy}
      className="flex justify-between text-neutralPrimary-20 w-full"
    >
      <span>{label}</span>
      <div className="font-semibold text-right">{children}</div>
    </div>
  );
};
export default ThinLabel;
