import { uid } from "react-uid";
import { ReactElement } from "react";
import { classJoin, useMappedStateSelector } from "@ps/utils";
import { Card } from "@ps/ui-components";
import styles from "./styles.module.css";
import { PERMISSIONS } from "../../../shared/data-cy";
import { LEFT, MIDDLE, permissionsMatrix } from "../../../shared/constants";
import { useTranslationWithNamespace } from "../../../hooks";
import PermissionsGroup from "./permissionsGroup";

const PermissionsLoadingGrid = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const mapState = (state) => ({
    allRoles: state?.allRoles || [],
  });

  const { allRoles } = useMappedStateSelector(mapState, "hh");

  const rolesSkeleton = new Array(allRoles.length || 14)
    .fill(0)
    .map((role, index) => ({ itemId: `role-${index}`, permissionsMatrix }));

  return (
    <Card
      dataCy={`${PERMISSIONS}_table_loading`}
      label={t("permissions.title")}
      additionalCardClassName={classJoin(
        styles.container,
        "border-t-4 border-neutralSecondary-90",
      )}
    >
      <div className="border-r-4 border-primary-71">
        <div className="h-16" />
        {permissionsMatrix.map((group) => (
          <PermissionsGroup key={group.itemId} group={group} column={LEFT} />
        ))}
      </div>
      <div className="w-full flex flex-col overflow-scroll opacity-50">
        <div className="grid grid-flow-col h-16">
          {rolesSkeleton.map((role) => (
            <div
              className={classJoin(
                " border-r-2 h-full flex items-center justify-center",
                styles.singleMiddleCell,
              )}
              key={role.itemId}
            >
              <div
                className={classJoin("w-20 h-8 rounded-lg", styles.skeleton)}
              />
            </div>
          ))}
          <div
            className={classJoin(
              "h-16 flex justify-center items-center",
              styles.lastColumnContainer,
            )}
          >
            <div className="w-11 h-11 text-4xl items-center justify-center flex p-0 bg-neutralSecondary-60 rounded-lg opacity-50">
              <span className="self-baseline">+</span>
            </div>
          </div>
        </div>
        <div className="grid grid-flow-col">
          {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            rolesSkeleton.map((_) => (
              <div className="flex flex-col">
                {permissionsMatrix.map((item) => (
                  <div
                    className={classJoin(
                      "flex flex-col",
                      styles.singleMiddleCell,
                    )}
                    key={`${MIDDLE}-${item.itemId}`}
                  >
                    <div
                      className={classJoin(
                        `flex w-full border-t border-b border-primary-71
              items-center justify-center bg-neutralSecondary-90`,
                        styles.baseSingleCell,
                      )}
                    >
                      <div
                        className={classJoin(
                          styles.skeleton,
                          "w-4 h-4 rounded",
                        )}
                      />
                    </div>
                    <div className={classJoin("flex flex-col border-r-2")}>
                      {item.permissions.map((permission, index) => (
                        <div
                          className={classJoin(
                            "justify-center",
                            styles.baseSingleCell,
                          )}
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${permission.itemId}-${index}`}
                        >
                          <div
                            className={classJoin(
                              styles.skeleton,
                              "w-4 h-4 rounded",
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))
          }
          <div className="flex flex-col">
            {permissionsMatrix.map((item) => (
              <div
                className={classJoin("flex flex-col", styles.singleMiddleCell)}
                key={`${MIDDLE}-${item.itemId}`}
              >
                <div
                  className={classJoin(
                    `flex w-full border-t border-b
                    border-primary-71
                    bg-neutralSecondary-90 border-r`,
                    styles.baseSingleCell,
                  )}
                />
                <div className={classJoin("flex flex-col border-r-2")}>
                  {item.permissions.map((_, index) => (
                    <div className={styles.baseSingleCell} key={uid(index)} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PermissionsLoadingGrid;
