import { classJoin } from "@ps/utils";
import { ReactElement } from "react";
import { MODAL_FOOTER_PREFIX } from "../../shared/data-cy-2";
import { ModalFooterProps } from "./types";

const ModalFooter = ({
  additionalClassName,
  children,
  className,
  dataCy,
}: ModalFooterProps): ReactElement => (
  <div
    className={classJoin(
      className || "flex justify-end gap-x-3",
      additionalClassName,
    )}
    data-cy={`${MODAL_FOOTER_PREFIX}_${dataCy}`}
  >
    {children}
  </div>
);

export default ModalFooter;
