import {
  KeyboardEvent,
  ReactElement,
  useState,
  useRef,
  useEffect,
} from "react";
import { classJoin } from "@ps/utils";
import Fade from "../fade";
import ToggleOption from "./toggleOption";
import { Keys } from "../../shared";
import { ToggleSelectProps } from "./types";
import { ReactComponent as ArrowDown } from "../../images/arrowDown.svg";

const wrapperStyle = `group select-none flex justify-between items-center gap-x-4 px-5 py-1 
border rounded-full p-2 hover:text-neutralPrimary-20 hover:border-neutralPrimary-40 transition-all`;

const arrowStyle =
  "transition-all, text-neutralPrimary-40 group-hover:text-neutralPrimary-20";

const ToggleSelect = ({
  dataCy,
  checked,
  onChange,
  activeLabel,
  negationLabel,
  width = "w-44",
  disabled,
}: ToggleSelectProps): ReactElement => {
  const clickElementRef = useRef<HTMLDivElement>(null);
  const label = checked ? activeLabel : negationLabel;
  const [open, setOpen] = useState(false);
  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  useEffect(() => {
    if (!clickElementRef.current) return;
    const clickEl = clickElementRef.current.parentElement as HTMLElement;
    if (disabled) {
      clickEl.style.cursor = "default";
    } else clickEl.style.cursor = "inherit";
  }, [disabled]);

  const handleActiveClick = (): void => {
    if (checked) return;
    onChange();
    handleClose();
  };

  const handleNegationClick = (): void => {
    if (!checked) return;
    onChange();
    handleClose();
  };

  const handleKeyDownOnActive = (e: KeyboardEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    if (e.key === Keys.ENTER) handleActiveClick();
    if (open && e.key === Keys.ESC) handleClose();
  };

  const handleKeyDownOnNegation = (e: KeyboardEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    if (e.key === Keys.ENTER) handleNegationClick();
    if (open && e.key === Keys.ESC) handleClose();
  };

  const statusStyle = !checked ? "bg-gray-200" : "bg-other-toxicGreen";

  return (
    <Fade
      open={!disabled && open}
      handleOpen={handleOpen}
      handleClose={handleClose}
      tabIndex={-1}
      clickElement={
        <div
          role="button"
          ref={clickElementRef}
          data-cy={dataCy}
          onClick={handleOpen}
          tabIndex={0}
          className={classJoin(
            wrapperStyle,
            width,
            disabled
              ? "pointer-events-none bg-transparent text-neutralPrimary-20 relative"
              : "cursor-pointer bg-neutralPrimary-100 text-neutralPrimary-40",
          )}
        >
          <div className={classJoin(statusStyle, "h-4 w-4 rounded-full")} />
          <span className="font-semibold">{label}</span>
          <div className={classJoin(arrowStyle)}>
            {!disabled && <ArrowDown />}
          </div>
        </div>
      }
      contentWrapperClassName={`-top-1 z-50 ${width}`}
      clickElementWrapperClassName={width}
    >
      <div
        className="flex flex-col px-5 py-2 bg-neutralPrimary-100 border
       text-neutralPrimary-40 rounded-3xl hover:border-neutralPrimary-40 transition-colors"
      >
        <ToggleOption
          label={activeLabel}
          onClick={handleActiveClick}
          onKeyDown={handleKeyDownOnActive}
          active={checked}
        />
        <ToggleOption
          label={negationLabel}
          onClick={handleNegationClick}
          onKeyDown={handleKeyDownOnNegation}
          active={!checked}
        />
      </div>
    </Fade>
  );
};

export default ToggleSelect;
