export const hasAnyFieldValue = (data: any): boolean => {
  if (!data) return true;

  const result = Object.entries(data).filter(([_, value]) => {
    if (!Array.isArray(value) && value !== null && typeof value === "object") {
      return hasAnyFieldValue(value);
    }
    return !!value;
  });
  return !!result.length;
};

export const validateWorkingHours = (
  currentValue: string | null,
  emptyMsg: string,
  wrongFormatMsg: string,
): string | boolean => {
  if (!currentValue) {
    return emptyMsg;
  }

  if (
    currentValue.length &&
    !/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/.test(currentValue)
  ) {
    return wrongFormatMsg;
  }

  if (currentValue.length !== 5) return wrongFormatMsg;
  return true;
};
