import { useDispatch } from "redux-react-hook";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ReactElement } from "react";
import { clearRedirection } from "@ps/alert-modal";
import { isAPIError } from "@ps/api-utils";
import { NEEDS } from "../../../constants";
import NeedsModal from "./edit/needsModal";
import NeedView from "./view/need";
import { fetchProspect, ProspectService } from "../../../index";
import { toggleEditMode } from "../../../../store";
import { deleteNeed } from "../../../services";
import SectionArrayWrapper from "./sectionArrayWrapper";
import { useMappedStateSelector } from "../../../../hooks";
import { NeedsMapState, NeedsMapStateReturn } from "./types";
import {
  DICTIONARIES,
  fetchDictionaryStart,
  fetchDictionarySuccess,
  fetchDictionaryError,
  DictionariesService,
  PreparedDictionaryEntry,
  NewDictionaryEntry,
} from "../../../../dictionaries";

const Needs = (): ReactElement => {
  const methods = useForm();
  const params = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const mapState = (state: NeedsMapState): NeedsMapStateReturn => ({
    technologyDict: state?.dictionaries?.technology || [],
  });

  const { technologyDict } = useMappedStateSelector(mapState);

  const mapTechnologies = async (
    techStack: (PreparedDictionaryEntry | NewDictionaryEntry)[] | [],
  ): Promise<(PreparedDictionaryEntry | NewDictionaryEntry)[]> => {
    if (!techStack.length) return [];
    const preparedTechnologies = await Promise.allSettled(
      techStack.map(
        (technology: PreparedDictionaryEntry | NewDictionaryEntry) =>
          DictionariesService.addDictionaryEntry(
            technology,
            DICTIONARIES.TECHNOLOGY,
          ),
      ),
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return preparedTechnologies.map((item) => item.value);
  };

  const onSubmit = async (data) => {
    const mappedMustHaveTechStack = await mapTechnologies(
      data.mustHaveTechStack,
    );
    const mappedNiceToHaveTechStack = await mapTechnologies(
      data.niceToHaveTechStack,
    );
    const mappedNeedData = {
      ...data,
      mustHaveTechStack: mappedMustHaveTechStack,
      niceToHaveTechStack: mappedNiceToHaveTechStack,
    };
    if (mappedNeedData?.itemId)
      await ProspectService.updateNeed(params.id, data.itemId, mappedNeedData);
    else await ProspectService.createNeed(params.id, mappedNeedData);
    // Redux do not update immediately state, so there was a need to pass response after dictionary fetch
    if (
      data?.mustHaveTechStack?.find(
        (tech: PreparedDictionaryEntry) => tech?.isNew,
      ) ||
      data?.niceToHaveTechStack?.find(
        (tech: PreparedDictionaryEntry) => tech?.isNew,
      )
    ) {
      dispatch(fetchDictionaryStart());
      const response = await DictionariesService.fetchDictionary(
        DICTIONARIES.TECHNOLOGY,
      );
      if (isAPIError(response)) {
        dispatch(fetchDictionaryError(response));
      } else {
        dispatch(fetchDictionarySuccess(response, DICTIONARIES.TECHNOLOGY));
        dispatch(toggleEditMode(""));
        dispatch(clearRedirection());
        await fetchProspect(params.id, dispatch, response);
      }
    } else {
      dispatch(toggleEditMode(""));
      dispatch(clearRedirection());
      await fetchProspect(params.id, dispatch, technologyDict);
    }
  };

  const onRemove = async (noteId: string) => {
    await deleteNeed(params.id, noteId);
    await fetchProspect(params.id, dispatch, technologyDict);
  };

  return (
    <FormProvider {...methods}>
      <form className="w-full h-full">
        <div className="grid gap-4 -mx-10" key={params.id}>
          <SectionArrayWrapper
            name={NEEDS}
            View={NeedView}
            Edit={NeedsModal}
            section={NEEDS}
            onSubmit={onSubmit}
            onRemove={onRemove}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default Needs;
