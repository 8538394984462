import { useEffect, useState, ReactElement } from "react";
import { useDispatch } from "redux-react-hook";
import { Scrollbars } from "react-custom-scrollbars-2";
import { fetchUsers, UserDomainModel } from "@ps/hh";
import {
  LoadingMainPage,
  ScrollableWrapper,
  TableLabel,
} from "@ps/ui-components";
import {
  classJoin,
  sortNames,
  useMappedStateSelector,
  removeDiacriticsFromString,
  divideArrayByField,
} from "@ps/utils";
import { ACCESS_GROUP, EMAIL, NAME, STATUS } from "../../shared/constants";
import { useTranslationWithNamespace } from "../../hooks";
import styles from "./styles.module.css";
import SearchRow from "./searchRow";
import UserRow from "./userRow";
import { MembersContext } from "./context";
import { MembersMapState, MembersMapStateReturn } from "./types";

const Members = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [isEdit, setIsEdit] = useState<string | null>(null);
  const [isCheckedArchive, setIsCheckedArchive] = useState(false);
  const [sorterParameters, setSorterParameters] = useState({
    order: "asc",
    name: "firstName",
  });

  useEffect(() => {
    fetchUsers(dispatch);
  }, []);

  const mapState = (state: MembersMapState): MembersMapStateReturn => ({
    users: state?.users || [],
    isFetching:
      !!state.requestStatus.isFetchingUsers ||
      !!state.requestStatus.isFetchingAllRoles,
  });

  const { isFetching, users } = useMappedStateSelector(mapState, "hh");

  const [sortedList, setSortedList] = useState<UserDomainModel[]>(users);

  useEffect(() => {
    setSortedList(users);
  }, [users]);

  const { negatives: onlyArchivedUsers, positives: onlyActiveUsers } =
    divideArrayByField(sortedList, "enabled");

  const handleOnSearch = (
    newValue: string,
    allUsers: UserDomainModel[],
  ): UserDomainModel[] => {
    if (!newValue) return allUsers;
    return allUsers.filter(
      (user: UserDomainModel): boolean =>
        removeDiacriticsFromString(
          `${user?.firstName?.toLowerCase()} ${user?.secondName?.toLowerCase()}` ||
            "",
        ).includes(removeDiacriticsFromString(newValue.toLowerCase())) ||
        removeDiacriticsFromString(
          `${user?.secondName?.toLowerCase()} ${user?.firstName?.toLowerCase()}` ||
            "",
        ).includes(removeDiacriticsFromString(newValue.toLowerCase())),
    );
  };

  const filteredUsersList: UserDomainModel[] = handleOnSearch(
    searchedValue,
    isCheckedArchive ? onlyArchivedUsers : onlyActiveUsers,
  );

  return (
    <MembersContext.Provider value={{ isEdit, setIsEdit }}>
      {isFetching ? (
        <LoadingMainPage additionalClassName="bg-neutralPrimary-85" />
      ) : (
        <div className="gap-6 w-full h-full">
          <ScrollableWrapper value={110}>
            <SearchRow
              value={searchedValue}
              searchValue={setSearchedValue}
              setIsCheckedArchive={setIsCheckedArchive}
              isCheckedArchive={isCheckedArchive}
              usersListLength={{
                archived: onlyArchivedUsers?.length,
                active: onlyActiveUsers?.length,
              }}
            />
            <div
              className={classJoin(
                styles.gridContainer,
                `border border-neutralPrimary-30 bg-neutralSecondary-41
                bg-opacity-20 rounded-lg h-10 items-center mb-2`,
              )}
            >
              <TableLabel
                dataCy={NAME}
                sorting={{
                  byName: NAME,
                  onClick: () => {
                    const order =
                      sorterParameters?.order === "desc" ? "asc" : "desc";
                    setSortedList((prev) =>
                      sortNames(prev, "firstName", order),
                    );
                    setSorterParameters({
                      name: "firstName",
                      order,
                    });
                  },
                  isActive: sorterParameters?.name === "firstName",
                }}
              >
                {t(`members.${NAME}`)}
              </TableLabel>
              <TableLabel dataCy={EMAIL}>{t(`members.${EMAIL}`)}</TableLabel>
              <TableLabel dataCy={ACCESS_GROUP}>
                {t(`members.${ACCESS_GROUP}`)}
              </TableLabel>
              <TableLabel dataCy={STATUS}>{t(`members.${STATUS}`)}</TableLabel>
              <span className="invisible" />
            </div>
            <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
              <div className="flex flex-col w-full">
                {filteredUsersList?.map(
                  (user: UserDomainModel): ReactElement => (
                    <div className="mb-2" key={user.id}>
                      <UserRow data={user} />
                    </div>
                  ),
                )}
              </div>
            </Scrollbars>
          </ScrollableWrapper>
        </div>
      )}
    </MembersContext.Provider>
  );
};
export default Members;
