export const BASE_TRANSLATION_PATH = "finance.holidaysTemplates";

export const HOLIDAYS_TEMPLATES = "holidaysTemplates";
export const TEMPLATE = "template";
export const HOLIDAY = "holiday";
export const ADD_TEMPLATE = "addTemplate";
export const HEADER_BAR = "headerBar";
export const ADD_HOLIDAY = "addHoliday";
export const TEMPLATES_LIST = "templatesList";
export const HOLIDAYS_LIST = "holidaysList";
export const CATEGORY_NAME = "categoryName";
export const LIMIT = "limit";
export const PAYMENT_PERCENTAGE = "paymentPercentage";
export const NO_LIMIT = "noLimit";
export const DAYS = "days";
export const PARTIAL_DAY_OFF = "allowPartialDayOff";
export const ERRORS = "errors";
export const CONFIRM_MODAL = "confirmModal";
export const DELETE_MODAL = "deleteModal";
export const BOTTOM_INFO = "bottomInfo";

export const SAVE = "save";
export const CANCEL = "cancel";
export const CREATE = "create";
export const DELETE = "delete";
export const EDIT = "edit";
export const ERASE = "erase";

export const TEMP_TEMPLATE_ID = "tempTemplateId";
export const TEMP_HOLIDAY_ID = "tempHolidayId";

export const NO_LIMIT_THRESHOLD = 366;

export const newHolidaysTemplate = {
  id: TEMP_TEMPLATE_ID,
  name: "",
  holidays: [],
};

export const newHoliday = {
  holidaysId: TEMP_HOLIDAY_ID,
  isMainHoliday: false,
  name: "",
  noLimit: false,
  numberOfDaysOff: 0,
  payRatePercentage: 0,
  allowPartialDayOff: false,
};
