import { ReactElement, useRef } from "react";
import { classJoin } from "@ps/utils";
import { motion, useSpring } from "framer-motion";
import Tippy from "@tippyjs/react";
import { DropdownProps } from "./types";
import { useThemeMode } from "../theme-mode-provider";
import { useFontSizeMode } from "../font-size-provider";
import styles from "./styles.module.css";
import { getFallbackPlacement } from "./utils";

const contentWrapperStyle = classJoin.template`
rounded-md bg-neutralPrimary-100 mt-1 mb-1
flex flex-col justify-start text-neutralPrimary-20
border border-neutralSecondary-60
`;

const Dropdown = ({
  children,
  isDisabled,
  onClickOutside,
  overlay,
  overlayStyles,
  placement = "bottom",
  show,
  width,
  withArrow = false,
}: DropdownProps): ReactElement => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const { ThemeModeWrapper } = useThemeMode();
  const { FontSizeModeWrapper } = useFontSizeMode();
  const springConfig = { damping: 15, stiffness: 300 };
  const initialScale = 0.9;
  const opacity = useSpring(0, springConfig);
  const scale = useSpring(initialScale, springConfig);

  const onMount = () => {
    scale.set(1);
    opacity.set(1);
  };

  const onHide = ({ unmount }: { unmount: () => void }) => {
    const cleanup = scale.onChange((value) => {
      if (value <= initialScale) {
        cleanup();
        unmount();
      }
    });
    scale.set(initialScale);
    opacity.set(0);
  };

  return (
    <Tippy
      disabled={isDisabled}
      offset={[0, 0]}
      visible={show}
      onClickOutside={onClickOutside}
      render={(attrs) => (
        <FontSizeModeWrapper>
          <div
            className={classJoin(width, "overflow-hidden")}
            style={{
              width: width
                ? undefined
                : spanRef.current?.getBoundingClientRect().width,
              fontSize: "var(--font-size-base)",
            }}
            {...attrs}
            role="tooltip"
            ref={overlayRef}
          >
            <ThemeModeWrapper>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <motion.div
                className={classJoin(
                  overlayStyles || contentWrapperStyle,
                  "w-full",
                )}
                style={{
                  scale,
                  opacity,
                }}
              >
                {overlay}
                {withArrow && (
                  <div className={styles.arrowUp} data-popper-arrow />
                )}
              </motion.div>
            </ThemeModeWrapper>
          </div>
        </FontSizeModeWrapper>
      )}
      animation
      onMount={onMount}
      onHide={onHide}
      placement={`${placement}-start`}
      hideOnClick={show === undefined ? true : undefined}
      interactive
      trigger={show === undefined ? "click" : undefined}
      popperOptions={{
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: getFallbackPlacement(`${placement}-start`),
              flipOnUpdate: true,
            },
          },
        ],
      }}
    >
      <span ref={spanRef}>{children}</span>
    </Tippy>
  );
};

export default Dropdown;
