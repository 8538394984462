import { getNameFromDictionary, removeDiacriticsFromString } from "@ps/utils";
import { HolidaysUserModel } from "../../models";
import { Filters, IFilterOption, UsersCheckers } from "../types";
import { ACTIVE, INACTIVE, PROFESSION, SENIORITY } from "./constants";
import { DictionaryModel } from "../../../../index";

const statusFilters = {
  [ACTIVE]: (user: HolidaysUserModel) => user.enabled,
  [INACTIVE]: (user: HolidaysUserModel) => !user.enabled,
};

export const removeFilterDuplicates = (
  items: IFilterOption[],
): IFilterOption[] =>
  items.reduce(
    (acc: IFilterOption[], item: IFilterOption) =>
      acc.some((item2: IFilterOption) => item2.id === item.id)
        ? acc
        : [...acc, item],
    [],
  );

export const filterTeamByStatus = (
  teamUsers: HolidaysUserModel[],
  teamCheckers: UsersCheckers,
): HolidaysUserModel[] => {
  const selected = Object.entries(teamCheckers)
    .filter(([, value]: [string, boolean]) => value)
    .map(([key]: [string, boolean]) => statusFilters[key]);

  return teamUsers.filter((u: HolidaysUserModel) =>
    selected.some((f: (user: HolidaysUserModel) => void) => f(u)),
  );
};

export function filterUsers(
  usersByStatus: HolidaysUserModel[],
  filters: Filters,
): HolidaysUserModel[] {
  const filteredUsers: HolidaysUserModel[] = usersByStatus.filter(
    (holidayUser: HolidaysUserModel): boolean =>
      Object.entries(filters).every(
        ([key, values]) =>
          (values as string[]).length === 0 ||
          (values as string[]).includes(holidayUser[key]),
      ),
  );
  return filteredUsers;
}

export const prepareProfessionFilterOptions = (
  users: HolidaysUserModel[],
  dictionary: DictionaryModel,
): IFilterOption[] => {
  const temp = JSON.parse(JSON.stringify(users));
  const result: IFilterOption[] = temp
    .filter((user: HolidaysUserModel) => user[PROFESSION])
    .map(
      (item: HolidaysUserModel): IFilterOption => ({
        name: getNameFromDictionary(dictionary, item[PROFESSION]),
        id: item[PROFESSION] || "",
      }),
    );

  return removeFilterDuplicates(result);
};

export const prepareSeniorityFilterOptions = (
  users: HolidaysUserModel[],
  dictionary: DictionaryModel,
): IFilterOption[] => {
  const temp = JSON.parse(JSON.stringify(users));
  const result: IFilterOption[] = temp
    .filter((user: HolidaysUserModel) => user[SENIORITY])
    .map(
      (item: HolidaysUserModel): IFilterOption => ({
        name: getNameFromDictionary(dictionary, item[SENIORITY]),
        id: item[SENIORITY] || "",
      }),
    );

  return removeFilterDuplicates(result);
};

export const getCheckedNames = (
  filterOptions: IFilterOption[],
  filters: Filters,
  name: string,
): string[] =>
  filterOptions.reduce(
    (acc: string[], item: IFilterOption): string[] =>
      filters[name]?.some(({ id }: IFilterOption) => id === item.id)
        ? [...acc, item.name]
        : acc,
    [],
  );

export const filterUsersBySearch = (
  data: HolidaysUserModel[],
  searchValue: string,
): HolidaysUserModel[] =>
  data?.filter(
    (baseProfile: HolidaysUserModel): boolean =>
      removeDiacriticsFromString(baseProfile.name.toLowerCase()).includes(
        removeDiacriticsFromString(searchValue.toLowerCase().trim()),
      ) ||
      removeDiacriticsFromString(
        baseProfile.name.split(" ").reverse().join(" ").toLowerCase(),
      ).includes(removeDiacriticsFromString(searchValue.toLowerCase().trim())),
  );

export const countOptionFilterAmount = (
  optionId: string,
  fieldName: string,
  users: HolidaysUserModel[],
  nestedName?: string,
): number =>
  nestedName
    ? users?.filter(
        (user: HolidaysUserModel): boolean =>
          user[fieldName]?.[nestedName] === optionId,
      )?.length
    : users?.filter(
        (user: HolidaysUserModel): boolean => user[fieldName] === optionId,
      )?.length;

export const selectFilterOptions = (
  data: IFilterOption[],
  searchValue: string,
): IFilterOption[] => {
  if (!searchValue) return data;
  return data.filter((item: IFilterOption) =>
    removeDiacriticsFromString(item.name?.toLowerCase()).includes(
      removeDiacriticsFromString(searchValue.toLowerCase()),
    ),
  );
};
