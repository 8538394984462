/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Timetracker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ClientFilter
 */
export interface ClientFilter {
    /**
     * 
     * @type {string}
     * @memberof ClientFilter
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientFilter
     */
    'name'?: string;
}
/**
 * Each RGB value needs to be between 0 and 255
 * @export
 * @interface Color
 */
export interface Color {
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'red': number;
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'green': number;
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'blue': number;
}
/**
 * 
 * @export
 * @interface CreateHolidayTimeEntry
 */
export interface CreateHolidayTimeEntry extends CreateTimeEntry {
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof CreateHolidayTimeEntry
     */
    'dayFrom': string;
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof CreateHolidayTimeEntry
     */
    'dayTo': string;
}
/**
 * 
 * @export
 * @interface CreateHolidayTimeEntryAllOf
 */
export interface CreateHolidayTimeEntryAllOf {
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof CreateHolidayTimeEntryAllOf
     */
    'dayFrom': string;
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof CreateHolidayTimeEntryAllOf
     */
    'dayTo': string;
}
/**
 * 
 * @export
 * @interface CreateProjectTimeEntry
 */
export interface CreateProjectTimeEntry extends CreateTimeEntry {
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof CreateProjectTimeEntry
     */
    'day': string;
    /**
     * Pay rate multiplied by 10, eg. 15 == 1.5
     * @type {number}
     * @memberof CreateProjectTimeEntry
     */
    'rate': number;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectTimeEntry
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface CreateProjectTimeEntryAllOf
 */
export interface CreateProjectTimeEntryAllOf {
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof CreateProjectTimeEntryAllOf
     */
    'day': string;
    /**
     * Pay rate multiplied by 10, eg. 15 == 1.5
     * @type {number}
     * @memberof CreateProjectTimeEntryAllOf
     */
    'rate': number;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectTimeEntryAllOf
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface CreateTimeEntry
 */
export interface CreateTimeEntry {
    /**
     * Discriminator for time entries types (either \'project\' or \'holiday\') please refer to CreateTimeEntry.yaml for descriptive documentation of this type as Swagger UI doesn\'t handle \'discriminator\' properly
     * @type {string}
     * @memberof CreateTimeEntry
     */
    'eventType': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTimeEntry
     */
    'eventTypeId': string;
    /**
     * Worktime in minutes
     * @type {number}
     * @memberof CreateTimeEntry
     */
    'minutes': number;
}
/**
 * 
 * @export
 * @interface CreateTimeEntryResponse
 */
export interface CreateTimeEntryResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTimeEntryResponse
     */
    'timeEntriesIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface GetReport2
 */
export interface GetReport2 {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetReport2
     */
    'users': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetReport2
     */
    'projects': Array<string>;
}
/**
 * 
 * @export
 * @interface GetReportFilters
 */
export interface GetReportFilters {
    /**
     * 
     * @type {Array<UserFilter>}
     * @memberof GetReportFilters
     */
    'users': Array<UserFilter>;
    /**
     * 
     * @type {Array<ProjectFilter>}
     * @memberof GetReportFilters
     */
    'projects': Array<ProjectFilter>;
    /**
     * 
     * @type {Array<ClientFilter>}
     * @memberof GetReportFilters
     */
    'clients': Array<ClientFilter>;
}
/**
 * 
 * @export
 * @interface HistoryHolidayTimeEntry
 */
export interface HistoryHolidayTimeEntry {
    /**
     * 
     * @type {Array<string>}
     * @memberof HistoryHolidayTimeEntry
     */
    'timeEntriesIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof HistoryHolidayTimeEntry
     */
    'holidayId': string;
    /**
     * 
     * @type {string}
     * @memberof HistoryHolidayTimeEntry
     */
    'holidayName': string;
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof HistoryHolidayTimeEntry
     */
    'firstDay': string;
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof HistoryHolidayTimeEntry
     */
    'lastDay': string;
    /**
     * 
     * @type {number}
     * @memberof HistoryHolidayTimeEntry
     */
    'amountOfDays': number;
}
/**
 * 
 * @export
 * @interface HolidayTimeEntry
 */
export interface HolidayTimeEntry extends TimeEntry {
}
/**
 * 
 * @export
 * @interface ProjectFilter
 */
export interface ProjectFilter {
    /**
     * 
     * @type {string}
     * @memberof ProjectFilter
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFilter
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProjectTimeEntry
 */
export interface ProjectTimeEntry extends TimeEntry {
    /**
     * 
     * @type {string}
     * @memberof ProjectTimeEntry
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface ProjectTimeEntryAllOf
 */
export interface ProjectTimeEntryAllOf {
    /**
     * 
     * @type {string}
     * @memberof ProjectTimeEntryAllOf
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'clientName'?: string;
    /**
     * 
     * @type {Color}
     * @memberof Report
     */
    'projectColor'?: Color;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'userFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'userLastName'?: string;
    /**
     * 
     * @type {Array<ReportEntry>}
     * @memberof Report
     */
    'entries': Array<ReportEntry>;
}
/**
 * 
 * @export
 * @interface ReportEntry
 */
export interface ReportEntry {
    /**
     * 
     * @type {string}
     * @memberof ReportEntry
     */
    'id': string;
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof ReportEntry
     */
    'day': string;
    /**
     * Worktime in minutes
     * @type {number}
     * @memberof ReportEntry
     */
    'minutes': number;
    /**
     * Pay rate multiplied by 10, eg. 15 == 1.5
     * @type {number}
     * @memberof ReportEntry
     */
    'rate': number;
    /**
     * 
     * @type {string}
     * @memberof ReportEntry
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface TimeEntry
 */
export interface TimeEntry {
    /**
     * 
     * @type {string}
     * @memberof TimeEntry
     */
    'id': string;
    /**
     * Time entry type discriminator either \'project\' or \'holiday\'
     * @type {string}
     * @memberof TimeEntry
     */
    'eventType': string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntry
     */
    'eventTypeId': string;
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof TimeEntry
     */
    'day': string;
    /**
     * Worktime in minutes
     * @type {number}
     * @memberof TimeEntry
     */
    'minutes': number;
    /**
     * Pay rate multiplied by 10, eg. 15 == 1.5
     * @type {number}
     * @memberof TimeEntry
     */
    'rate': number;
}
/**
 * 
 * @export
 * @interface UpdateHistoryTimeEntries
 */
export interface UpdateHistoryTimeEntries {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateHistoryTimeEntries
     */
    'ids': Array<string>;
    /**
     * 
     * @type {CreateHolidayTimeEntry}
     * @memberof UpdateHistoryTimeEntries
     */
    'createHolidayTimeEntry': CreateHolidayTimeEntry;
}
/**
 * 
 * @export
 * @interface UpdateProjectTimeEntry
 */
export interface UpdateProjectTimeEntry extends UpdateTimeEntry {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectTimeEntry
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface UpdateTimeEntry
 */
export interface UpdateTimeEntry {
    /**
     * Discriminator for time entries types (either \'project\' or \'holiday\') please refer to UpdateTimeEntry.yaml for descriptive documentation of this type as Swagger UI doesn\'t handle \'discriminator\' properly
     * @type {string}
     * @memberof UpdateTimeEntry
     */
    'eventType': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTimeEntry
     */
    'eventTypeId': string;
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof UpdateTimeEntry
     */
    'day': string;
    /**
     * Worktime in minutes
     * @type {number}
     * @memberof UpdateTimeEntry
     */
    'minutes': number;
    /**
     * Pay rate multiplied by 10, eg. 15 == 1.5
     * @type {number}
     * @memberof UpdateTimeEntry
     */
    'rate': number;
}
/**
 * 
 * @export
 * @interface UserDayOff
 */
export interface UserDayOff {
    /**
     * 
     * @type {string}
     * @memberof UserDayOff
     */
    'holidayId': string;
    /**
     * 
     * @type {string}
     * @memberof UserDayOff
     */
    'holidayName': string;
    /**
     * 
     * @type {number}
     * @memberof UserDayOff
     */
    'usedDaysOff': number;
    /**
     * 
     * @type {number}
     * @memberof UserDayOff
     */
    'unusedDaysOff': number;
}
/**
 * 
 * @export
 * @interface UserFilter
 */
export interface UserFilter {
    /**
     * 
     * @type {string}
     * @memberof UserFilter
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserFilter
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFilter
     */
    'lastName'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new TimeEntry
         * @param {CreateTimeEntry} createTimeEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTimeEntry: async (createTimeEntry: CreateTimeEntry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTimeEntry' is not null or undefined
            assertParamExists('addTimeEntry', 'createTimeEntry', createTimeEntry)
            const localVarPath = `/time_tracker/time_entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTimeEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new TimeEntry
         * @param {string} userId 
         * @param {CreateHolidayTimeEntry} createHolidayTimeEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserHolidaysTimeEntry: async (userId: string, createHolidayTimeEntry: CreateHolidayTimeEntry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addUserHolidaysTimeEntry', 'userId', userId)
            // verify required parameter 'createHolidayTimeEntry' is not null or undefined
            assertParamExists('addUserHolidaysTimeEntry', 'createHolidayTimeEntry', createHolidayTimeEntry)
            const localVarPath = `/time_tracker/users/{userId}/time_entries`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHolidayTimeEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Copy previous week\'s time entries
         * @param {string} from Starting date (Monday) of the week to copy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPreviousWeekTimeEntries: async (from: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('copyPreviousWeekTimeEntries', 'from', from)
            const localVarPath = `/time_tracker/me/time_entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete History TimeEntries
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthedUserHistoryTimeEntries: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteAuthedUserHistoryTimeEntries', 'requestBody', requestBody)
            const localVarPath = `/time_tracker/me/holidays/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete TimeEntry
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeEntry: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTimeEntry', 'id', id)
            const localVarPath = `/time_tracker/time_entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete History TimeEntries
         * @param {string} userId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserHistoryTimeEntries: async (userId: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserHistoryTimeEntries', 'userId', userId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteUserHistoryTimeEntries', 'requestBody', requestBody)
            const localVarPath = `/time_tracker/users/{userId}/holidays/history/delete`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user\'s days off
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUserDaysOff: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/time_tracker/me/holidays/daysOff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user\'s history of taken holidays time entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUserHolidaysHistory: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/time_tracker/me/holidays/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get TimeEntries in day range
         * @param {string} from Range starting day to parse TimeEntries within
         * @param {string} to Range starting day to parse TimeEntries within
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUserTimeEntriesInDayRange: async (from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getAuthedUserTimeEntriesInDayRange', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getAuthedUserTimeEntriesInDayRange', 'to', to)
            const localVarPath = `/time_tracker/me/time_entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get report matching given filter in day range
         * @param {string} from Range starting day to parse TimeEntries within
         * @param {string} to Range starting day to parse TimeEntries within
         * @param {string} [users] List of userIds separated with a comma
         * @param {string} [projects] List of projectIds separated with a comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (from: string, to: string, users?: string, projects?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getReport', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getReport', 'to', to)
            const localVarPath = `/time_tracker/reports/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (projects !== undefined) {
                localVarQueryParameter['projects'] = projects;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get report matching given filter in day range using body
         * @param {string} from Range starting day to parse TimeEntries within
         * @param {string} to Range starting day to parse TimeEntries within
         * @param {GetReport2} getReport2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport2: async (from: string, to: string, getReport2: GetReport2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getReport2', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getReport2', 'to', to)
            // verify required parameter 'getReport2' is not null or undefined
            assertParamExists('getReport2', 'getReport2', getReport2)
            const localVarPath = `/time_tracker/reports/report2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getReport2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of users, projects and clients matching given filter
         * @param {string} [users] List of userIds separated with a comma
         * @param {string} [projects] List of projectIds separated with a comma
         * @param {string} [clients] List of clientIds separated with a comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFilters: async (users?: string, projects?: string, clients?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/time_tracker/reports/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (projects !== undefined) {
                localVarQueryParameter['projects'] = projects;
            }

            if (clients !== undefined) {
                localVarQueryParameter['clients'] = clients;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get TimeEntries in day range
         * @param {string} from Range starting day to parse TimeEntries within
         * @param {string} to Range starting day to parse TimeEntries within
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeEntriesInDayRange: async (from: string, to: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getTimeEntriesInDayRange', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getTimeEntriesInDayRange', 'to', to)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTimeEntriesInDayRange', 'userId', userId)
            const localVarPath = `/time_tracker/users/{userId}/time_entries`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user\'s history of taken holidays time entries
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserHolidaysHistory: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserHolidaysHistory', 'userId', userId)
            const localVarPath = `/time_tracker/users/{userId}/holidays/history`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upsert TimeEntry (if time entry didn\'t exist - it will be inserted)
         * @param {string} id 
         * @param {UpdateTimeEntry} updateTimeEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeEntry: async (id: string, updateTimeEntry: UpdateTimeEntry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTimeEntry', 'id', id)
            // verify required parameter 'updateTimeEntry' is not null or undefined
            assertParamExists('updateTimeEntry', 'updateTimeEntry', updateTimeEntry)
            const localVarPath = `/time_tracker/time_entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTimeEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update History TimeEntries
         * @param {string} userId 
         * @param {UpdateHistoryTimeEntries} updateHistoryTimeEntries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserHistoryTimeEntries: async (userId: string, updateHistoryTimeEntries: UpdateHistoryTimeEntries, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserHistoryTimeEntries', 'userId', userId)
            // verify required parameter 'updateHistoryTimeEntries' is not null or undefined
            assertParamExists('updateUserHistoryTimeEntries', 'updateHistoryTimeEntries', updateHistoryTimeEntries)
            const localVarPath = `/time_tracker/users/{userId}/holidays/history/update`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHistoryTimeEntries, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Create new TimeEntry
         * @param {CreateTimeEntry} createTimeEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTimeEntry(createTimeEntry: CreateTimeEntry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTimeEntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTimeEntry(createTimeEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new TimeEntry
         * @param {string} userId 
         * @param {CreateHolidayTimeEntry} createHolidayTimeEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserHolidaysTimeEntry(userId: string, createHolidayTimeEntry: CreateHolidayTimeEntry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTimeEntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserHolidaysTimeEntry(userId, createHolidayTimeEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Copy previous week\'s time entries
         * @param {string} from Starting date (Monday) of the week to copy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyPreviousWeekTimeEntries(from: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyPreviousWeekTimeEntries(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete History TimeEntries
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAuthedUserHistoryTimeEntries(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAuthedUserHistoryTimeEntries(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete TimeEntry
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTimeEntry(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTimeEntry(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete History TimeEntries
         * @param {string} userId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserHistoryTimeEntries(userId: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserHistoryTimeEntries(userId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user\'s days off
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthedUserDaysOff(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDayOff>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthedUserDaysOff(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user\'s history of taken holidays time entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthedUserHolidaysHistory(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoryHolidayTimeEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthedUserHolidaysHistory(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get TimeEntries in day range
         * @param {string} from Range starting day to parse TimeEntries within
         * @param {string} to Range starting day to parse TimeEntries within
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthedUserTimeEntriesInDayRange(from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthedUserTimeEntriesInDayRange(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get report matching given filter in day range
         * @param {string} from Range starting day to parse TimeEntries within
         * @param {string} to Range starting day to parse TimeEntries within
         * @param {string} [users] List of userIds separated with a comma
         * @param {string} [projects] List of projectIds separated with a comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(from: string, to: string, users?: string, projects?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Report>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(from, to, users, projects, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get report matching given filter in day range using body
         * @param {string} from Range starting day to parse TimeEntries within
         * @param {string} to Range starting day to parse TimeEntries within
         * @param {GetReport2} getReport2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport2(from: string, to: string, getReport2: GetReport2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Report>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport2(from, to, getReport2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of users, projects and clients matching given filter
         * @param {string} [users] List of userIds separated with a comma
         * @param {string} [projects] List of projectIds separated with a comma
         * @param {string} [clients] List of clientIds separated with a comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportFilters(users?: string, projects?: string, clients?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReportFilters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportFilters(users, projects, clients, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get TimeEntries in day range
         * @param {string} from Range starting day to parse TimeEntries within
         * @param {string} to Range starting day to parse TimeEntries within
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeEntriesInDayRange(from: string, to: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeEntriesInDayRange(from, to, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user\'s history of taken holidays time entries
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserHolidaysHistory(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoryHolidayTimeEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserHolidaysHistory(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upsert TimeEntry (if time entry didn\'t exist - it will be inserted)
         * @param {string} id 
         * @param {UpdateTimeEntry} updateTimeEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimeEntry(id: string, updateTimeEntry: UpdateTimeEntry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimeEntry(id, updateTimeEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update History TimeEntries
         * @param {string} userId 
         * @param {UpdateHistoryTimeEntries} updateHistoryTimeEntries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserHistoryTimeEntries(userId: string, updateHistoryTimeEntries: UpdateHistoryTimeEntries, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserHistoryTimeEntries(userId, updateHistoryTimeEntries, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Create new TimeEntry
         * @param {CreateTimeEntry} createTimeEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTimeEntry(createTimeEntry: CreateTimeEntry, options?: any): AxiosPromise<CreateTimeEntryResponse> {
            return localVarFp.addTimeEntry(createTimeEntry, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new TimeEntry
         * @param {string} userId 
         * @param {CreateHolidayTimeEntry} createHolidayTimeEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserHolidaysTimeEntry(userId: string, createHolidayTimeEntry: CreateHolidayTimeEntry, options?: any): AxiosPromise<CreateTimeEntryResponse> {
            return localVarFp.addUserHolidaysTimeEntry(userId, createHolidayTimeEntry, options).then((request) => request(axios, basePath));
        },
        /**
         * Copy previous week\'s time entries
         * @param {string} from Starting date (Monday) of the week to copy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPreviousWeekTimeEntries(from: string, options?: any): AxiosPromise<void> {
            return localVarFp.copyPreviousWeekTimeEntries(from, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete History TimeEntries
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthedUserHistoryTimeEntries(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAuthedUserHistoryTimeEntries(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete TimeEntry
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeEntry(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTimeEntry(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete History TimeEntries
         * @param {string} userId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserHistoryTimeEntries(userId: string, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserHistoryTimeEntries(userId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user\'s days off
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUserDaysOff(options?: any): AxiosPromise<Array<UserDayOff>> {
            return localVarFp.getAuthedUserDaysOff(options).then((request) => request(axios, basePath));
        },
        /**
         * Get user\'s history of taken holidays time entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUserHolidaysHistory(options?: any): AxiosPromise<Array<HistoryHolidayTimeEntry>> {
            return localVarFp.getAuthedUserHolidaysHistory(options).then((request) => request(axios, basePath));
        },
        /**
         * Get TimeEntries in day range
         * @param {string} from Range starting day to parse TimeEntries within
         * @param {string} to Range starting day to parse TimeEntries within
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUserTimeEntriesInDayRange(from: string, to: string, options?: any): AxiosPromise<Array<TimeEntry>> {
            return localVarFp.getAuthedUserTimeEntriesInDayRange(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * Get report matching given filter in day range
         * @param {string} from Range starting day to parse TimeEntries within
         * @param {string} to Range starting day to parse TimeEntries within
         * @param {string} [users] List of userIds separated with a comma
         * @param {string} [projects] List of projectIds separated with a comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(from: string, to: string, users?: string, projects?: string, options?: any): AxiosPromise<Array<Report>> {
            return localVarFp.getReport(from, to, users, projects, options).then((request) => request(axios, basePath));
        },
        /**
         * Get report matching given filter in day range using body
         * @param {string} from Range starting day to parse TimeEntries within
         * @param {string} to Range starting day to parse TimeEntries within
         * @param {GetReport2} getReport2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport2(from: string, to: string, getReport2: GetReport2, options?: any): AxiosPromise<Array<Report>> {
            return localVarFp.getReport2(from, to, getReport2, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of users, projects and clients matching given filter
         * @param {string} [users] List of userIds separated with a comma
         * @param {string} [projects] List of projectIds separated with a comma
         * @param {string} [clients] List of clientIds separated with a comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFilters(users?: string, projects?: string, clients?: string, options?: any): AxiosPromise<GetReportFilters> {
            return localVarFp.getReportFilters(users, projects, clients, options).then((request) => request(axios, basePath));
        },
        /**
         * Get TimeEntries in day range
         * @param {string} from Range starting day to parse TimeEntries within
         * @param {string} to Range starting day to parse TimeEntries within
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeEntriesInDayRange(from: string, to: string, userId: string, options?: any): AxiosPromise<Array<TimeEntry>> {
            return localVarFp.getTimeEntriesInDayRange(from, to, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user\'s history of taken holidays time entries
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserHolidaysHistory(userId: string, options?: any): AxiosPromise<Array<HistoryHolidayTimeEntry>> {
            return localVarFp.getUserHolidaysHistory(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Upsert TimeEntry (if time entry didn\'t exist - it will be inserted)
         * @param {string} id 
         * @param {UpdateTimeEntry} updateTimeEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeEntry(id: string, updateTimeEntry: UpdateTimeEntry, options?: any): AxiosPromise<void> {
            return localVarFp.updateTimeEntry(id, updateTimeEntry, options).then((request) => request(axios, basePath));
        },
        /**
         * Update History TimeEntries
         * @param {string} userId 
         * @param {UpdateHistoryTimeEntries} updateHistoryTimeEntries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserHistoryTimeEntries(userId: string, updateHistoryTimeEntries: UpdateHistoryTimeEntries, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserHistoryTimeEntries(userId, updateHistoryTimeEntries, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Create new TimeEntry
     * @param {CreateTimeEntry} createTimeEntry 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addTimeEntry(createTimeEntry: CreateTimeEntry, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addTimeEntry(createTimeEntry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new TimeEntry
     * @param {string} userId 
     * @param {CreateHolidayTimeEntry} createHolidayTimeEntry 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addUserHolidaysTimeEntry(userId: string, createHolidayTimeEntry: CreateHolidayTimeEntry, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addUserHolidaysTimeEntry(userId, createHolidayTimeEntry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Copy previous week\'s time entries
     * @param {string} from Starting date (Monday) of the week to copy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public copyPreviousWeekTimeEntries(from: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).copyPreviousWeekTimeEntries(from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete History TimeEntries
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAuthedUserHistoryTimeEntries(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteAuthedUserHistoryTimeEntries(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete TimeEntry
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTimeEntry(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteTimeEntry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete History TimeEntries
     * @param {string} userId 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUserHistoryTimeEntries(userId: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteUserHistoryTimeEntries(userId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user\'s days off
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuthedUserDaysOff(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuthedUserDaysOff(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user\'s history of taken holidays time entries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuthedUserHolidaysHistory(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuthedUserHolidaysHistory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get TimeEntries in day range
     * @param {string} from Range starting day to parse TimeEntries within
     * @param {string} to Range starting day to parse TimeEntries within
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuthedUserTimeEntriesInDayRange(from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuthedUserTimeEntriesInDayRange(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get report matching given filter in day range
     * @param {string} from Range starting day to parse TimeEntries within
     * @param {string} to Range starting day to parse TimeEntries within
     * @param {string} [users] List of userIds separated with a comma
     * @param {string} [projects] List of projectIds separated with a comma
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getReport(from: string, to: string, users?: string, projects?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getReport(from, to, users, projects, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get report matching given filter in day range using body
     * @param {string} from Range starting day to parse TimeEntries within
     * @param {string} to Range starting day to parse TimeEntries within
     * @param {GetReport2} getReport2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getReport2(from: string, to: string, getReport2: GetReport2, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getReport2(from, to, getReport2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of users, projects and clients matching given filter
     * @param {string} [users] List of userIds separated with a comma
     * @param {string} [projects] List of projectIds separated with a comma
     * @param {string} [clients] List of clientIds separated with a comma
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getReportFilters(users?: string, projects?: string, clients?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getReportFilters(users, projects, clients, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get TimeEntries in day range
     * @param {string} from Range starting day to parse TimeEntries within
     * @param {string} to Range starting day to parse TimeEntries within
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTimeEntriesInDayRange(from: string, to: string, userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTimeEntriesInDayRange(from, to, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user\'s history of taken holidays time entries
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserHolidaysHistory(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserHolidaysHistory(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upsert TimeEntry (if time entry didn\'t exist - it will be inserted)
     * @param {string} id 
     * @param {UpdateTimeEntry} updateTimeEntry 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateTimeEntry(id: string, updateTimeEntry: UpdateTimeEntry, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateTimeEntry(id, updateTimeEntry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update History TimeEntries
     * @param {string} userId 
     * @param {UpdateHistoryTimeEntries} updateHistoryTimeEntries 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserHistoryTimeEntries(userId: string, updateHistoryTimeEntries: UpdateHistoryTimeEntries, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUserHistoryTimeEntries(userId, updateHistoryTimeEntries, options).then((request) => request(this.axios, this.basePath));
    }
}


