import { ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { classJoin } from "@ps/utils";
import ProgressBar from "../progress-bar";
import { SectionFormProps } from "./types";
import SectionTabs from "../section-tabs";
import { SECTION_FORM_PREFIX } from "../../shared/data-cy";
import SectionFormContext from "./context";

const SectionForm = <T,>({
  action,
  additionalClassName,
  dataCy,
  defaultSelectedSection,
  disabledTabs,
  headerChildren,
  isLoading = false,
  onTabClick,
  showProgressBar = false,
  tabs = [],
  tabsDescription,
  withOverflow = true,
}: SectionFormProps<T>): ReactElement => {
  const [currentPercent, setCurrentPercent] = useState(0);
  const [selectedSection, setSelectedSection] = useState(
    defaultSelectedSection,
  );
  const { state: redirectState } = useLocation<{
    title: string | undefined;
  }>();
  const history = useHistory();

  useEffect(() => {
    setSelectedSection(defaultSelectedSection);
  }, [defaultSelectedSection]);

  const mappedTabs = tabs.map((tab) => ({
    ...tab,
    onClick: (tabKey: T) => {
      setSelectedSection(tabKey);
      setCurrentPercent(0);
      if (redirectState)
        history.replace({ ...history.location, state: undefined });
    },
  }));

  const selectedSectionIndex = mappedTabs.findIndex(
    ({ tabKey }) => tabKey === selectedSection,
  );

  const handleUpdatePercent = (percent: number) =>
    setCurrentPercent(Math.max(Math.min(percent, 100), 0));

  return (
    <div
      className={classJoin(
        "flex flex-col h-full w-full max-h-full pb-8",
        additionalClassName,
      )}
      data-cy={`${SECTION_FORM_PREFIX}-${dataCy}`}
    >
      <div className="w-full flex flex-row justify-between pb-3">
        <div className="w-64 font-bold text-neutralPrimary-20">
          {tabsDescription}
        </div>
        {showProgressBar && (
          <div className="pl-4 flex flex-row w-full">
            <ProgressBar
              totalSteps={mappedTabs.length}
              step={
                mappedTabs.findIndex(
                  ({ tabKey }) => tabKey === selectedSection,
                ) + 1
              }
              percent={currentPercent}
              dataCy={dataCy}
            />
            <div className="flex flex-row ml-20 gap-x-4">{action}</div>
          </div>
        )}
      </div>
      <div className="w-full h-full flex flex-row">
        <div className="w-64 mr-2 h-full">
          {headerChildren}
          <SectionTabs
            disabledTabs={disabledTabs}
            isLoading={isLoading}
            onTabClick={onTabClick}
            selected={selectedSection}
            tabs={mappedTabs}
          />
        </div>
        <div
          className={classJoin(
            "h-full w-full",
            withOverflow ? "overflow-auto" : "",
          )}
        >
          <SectionFormContext.Provider
            value={{ updatePercent: handleUpdatePercent }}
          >
            {mappedTabs[selectedSectionIndex]?.child}
          </SectionFormContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
