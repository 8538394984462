import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { clearRedirection, RedirectAlertModal } from "@ps/alert-modal";
import { ALL_PROJECTS, MY_PROJECTS } from "../../shared/routes";
import ProjectContainer, { AllProjects } from "../ui";
import NewProject from "../ui/new-project";

const ProjectRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  return (
    <ProjectContainer>
      <RedirectAlertModal
        dispatch={dispatch}
        clearRedirection={clearRedirection}
      />
      <Switch>
        <Route path={`${path}/${ALL_PROJECTS}`}>
          <AllProjects />
        </Route>
        {/* TODO temporary hidden because of HH2-2728 */}
        {/* <Route path={`${path}/${MY_PROJECTS}`}> */}
        {/*  <MyProjects /> */}
        {/* </Route> */}
        {/* TODO temporary redirect because of HH2-2728 */}
        <Route path={`${path}/:${MY_PROJECTS}`}>
          <Redirect to={`${path}/${ALL_PROJECTS}`} />
        </Route>
        <Route path={`${path}/:id`}>
          <NewProject />
        </Route>
        <Route>
          <Redirect to={`${path}/${ALL_PROJECTS}`} />
        </Route>
      </Switch>
    </ProjectContainer>
  );
};

export default ProjectRoutes;
