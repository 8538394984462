import {
  Checkbox,
  Datepicker2,
  Input,
  Label,
  Textarea,
} from "@ps/ui-components";
import dayjs from "dayjs";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { DATE_FORMAT } from "../../../../../../shared/constants";
import { EXPERIENCE_EDUCATIONS_EDIT } from "../../../../../../shared/data-cy";
import { checkHasEmptyFields } from "../../../helpers";
import {
  DEGREE,
  DESCRIPTION,
  END_DATE,
  FIELD_OF_STUDY,
  PLACEHOLDER,
  SCHOOL_NAME,
  START_DATE,
} from "../constants";
import { FormSectionProps } from "../types";

const MAX_LENGTH = 4000;

const DegreeForm = ({
  index,
  isOcr,
  sourcePath = "degrees",
}: FormSectionProps): ReactElement => {
  const {
    control,
    getValues,
    setValue,
    register,
    watch,
    formState,
    getFieldState,
  } = useFormContext();
  const { t } = useTranslationWithNamespace();

  const getFormFieldName = (fieldName: string) =>
    `${sourcePath}.${index}.${fieldName}`;

  useEffect(() => {
    setValue(getFormFieldName("education"), "degree");
  }, [index]);

  const [checkedOngoing, setCheckedOngoing] = useState(
    !getValues(getFormFieldName(END_DATE)),
  );

  const schoolName = getFieldState(getFormFieldName(SCHOOL_NAME), formState);
  const fieldOfStudy = getFieldState(
    getFormFieldName(FIELD_OF_STUDY),
    formState,
  );
  const degree = getFieldState(getFormFieldName(DEGREE), formState);

  const translate = (field: string, isRequired = true) =>
    `${t(`profile.experience.cards.degree.${field}`)}${isRequired ? "*" : ""}`;

  const errorEmptyMessage = `${t("profile.errors.cantBeEmpty")}`;
  const errorBlankMessage = `${t("profile.errors.cantBeBlank")}`;
  const errorMaxChars = `${t("profile.errors.cantBeMoreChars", {
    number: MAX_LENGTH,
  })}`;

  const BASE_PATH = `${EXPERIENCE_EDUCATIONS_EDIT}_${DEGREE}`;

  const hasEmptyFields = checkHasEmptyFields(
    getValues([SCHOOL_NAME, FIELD_OF_STUDY, DEGREE, START_DATE, DESCRIPTION]),
  );

  const isEmptyOrBlank = (value: string, maxChars = false) => {
    if (!value?.length && hasEmptyFields) return errorEmptyMessage;
    if (!value?.trim().length) return errorBlankMessage;
    if (maxChars && value && value?.length > MAX_LENGTH) return errorMaxChars;
    return true;
  };

  return (
    <div className="flex flex-col gap-y-7" key={uid(`${DEGREE}-${index}`)}>
      <div className="flex flex-row w-1/2 pr-2.5">
        <Label
          dataCy={`${BASE_PATH}_${SCHOOL_NAME}`}
          text={translate(SCHOOL_NAME)}
        >
          <Input
            dataCy={`${BASE_PATH}_${SCHOOL_NAME}_input`}
            width="w-full"
            autoFocus
            value={watch(getFormFieldName(SCHOOL_NAME)) || ""}
            error={!!schoolName?.error}
            message={schoolName?.error?.message || ""}
            placeholder={translate(`${SCHOOL_NAME}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(SCHOOL_NAME), {
              validate: (value) => {
                if (isOcr) return true;
                return isEmptyOrBlank(value);
              },
            })}
          />
        </Label>
      </div>
      <div className="flex flex-row gap-x-5">
        <Label
          dataCy={`${BASE_PATH}_${FIELD_OF_STUDY}`}
          text={translate(FIELD_OF_STUDY)}
        >
          <Input
            dataCy={`${BASE_PATH}_${FIELD_OF_STUDY}_input`}
            width="w-full"
            value={watch(getFormFieldName(FIELD_OF_STUDY)) || ""}
            error={!!fieldOfStudy?.error}
            message={fieldOfStudy?.error?.message || ""}
            placeholder={translate(`${FIELD_OF_STUDY}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(FIELD_OF_STUDY), {
              validate: (value) => {
                if (isOcr) return true;
                return isEmptyOrBlank(value);
              },
            })}
          />
        </Label>
        <Label dataCy={`${BASE_PATH}_${DEGREE}`} text={translate(DEGREE)}>
          <Input
            dataCy={`${BASE_PATH}_${DEGREE}_input`}
            width="w-full"
            value={watch(getFormFieldName(DEGREE)) || ""}
            error={!!degree?.error}
            message={degree?.error?.message || ""}
            placeholder={translate(`${DEGREE}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(DEGREE), {
              validate: (value) => {
                if (isOcr) return true;
                return isEmptyOrBlank(value);
              },
            })}
          />
        </Label>
      </div>
      <div className="flex flex-row items-start gap-x-5">
        <Label
          dataCy={`${BASE_PATH}_${START_DATE}`}
          text={translate(START_DATE)}
        >
          <Controller
            name={getFormFieldName(START_DATE)}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Datepicker2
                dataCy={`${BASE_PATH}_${START_DATE}_input`}
                error={error?.message}
                onChange={onChange}
                selected={value ? dayjs(value) : null}
                dateFormat="MM/YYYY"
                timeView={["month", "year"]}
                type="month"
              />
            )}
            rules={{
              validate: isOcr
                ? undefined
                : (value) => {
                    if (!value && hasEmptyFields) return errorEmptyMessage;
                    if (value && dayjs().isBefore(value))
                      return `${t("profile.errors.noFutureDate")}`;
                    return true;
                  },
            }}
          />
        </Label>
        <Label
          dataCy={`${BASE_PATH}_${END_DATE}`}
          text={translate(END_DATE, false)}
        >
          <Controller
            name={getFormFieldName(END_DATE)}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Datepicker2
                dataCy={`${BASE_PATH}_${END_DATE}_INPUT`}
                error={error?.message}
                onChange={(newDate) => {
                  onChange(newDate);
                  setCheckedOngoing(false);
                }}
                selected={value ? dayjs(value) : null}
                disabled={checkedOngoing}
                dateFormat="MM/YYYY"
                timeView={["month", "year"]}
                type="month"
              />
            )}
            rules={{
              validate: isOcr
                ? undefined
                : (value) => {
                    const fromDate = getValues(getFormFieldName(START_DATE));
                    return value && fromDate && fromDate.isAfter(value)
                      ? `${t("profile.errors.noEarlierDate", {
                          date: fromDate.format(DATE_FORMAT),
                        })}`
                      : true;
                  },
            }}
          />
        </Label>
        <div className="flex items-center gap-2 mt-7">
          <Checkbox
            dataCy={`${BASE_PATH}_${END_DATE}`}
            checked={checkedOngoing}
            onChecked={() => {
              setCheckedOngoing((prev) => !prev);
              setValue(getFormFieldName(END_DATE), undefined);
            }}
          />
          <span className="whitespace-nowrap text-neutralPrimary-30 font-semibold">
            {translate("ongoing", false)}
          </span>
        </div>
      </div>
      <div className="h-40">
        <Label
          text={translate(DESCRIPTION, false)}
          dataCy={`${BASE_PATH}_${DESCRIPTION}`}
        >
          <Controller
            name={getFormFieldName(DESCRIPTION)}
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <Textarea
                dataCy={`${BASE_PATH}_${DESCRIPTION}_input`}
                maxLength={MAX_LENGTH}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  onChange(event.target.value)
                }
                value={watch(getFormFieldName(DESCRIPTION)) || ""}
                error={!!error}
                message={error?.message}
                placeholder={translate(`${DESCRIPTION}${PLACEHOLDER}`, false)}
              />
            )}
          />
        </Label>
      </div>
    </div>
  );
};

export default DegreeForm;
