import { ReactElement } from "react";
import { Button2, LinkButton, ModalMainText2 } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import { Uploader } from "./components";
import { useTranslationWithNamespace } from "../../../hooks";
import { BUTTON, INVITING_MODAL } from "../../../shared/data-cy";
import { ReactComponent as AddMemberIcon } from "../../../images/members/add-user.svg";
import { ReactComponent as AddIcon } from "../../../images/members/add.svg";
import { ReactComponent as MagnetImage } from "../../../images/members/magnet.svg";
import { StartScreenProps } from "./types";
import { MAX_FILES_UPLOAD } from "./utils";

const TRANSLATION_PATH = "members.inviting";

const StartScreen = ({
  additionalClassName,
  fields,
  MARGIN,
  onAddNewClick,
  onCloseClick,
  onFilesSelected,
  width = "w-full",
}: StartScreenProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div
      className={classJoin(
        "flex flex-col gap-8 h-full",
        width,
        additionalClassName,
      )}
    >
      <ModalMainText2
        dataCy={INVITING_MODAL}
        additionalClassName={`px-${MARGIN} pt-${MARGIN}`}
      >
        <div className="flex items-center gap-2">
          <AddMemberIcon className="fill-current h-8" />
          <span>{t(`${TRANSLATION_PATH}.addNewMembers`)}</span>
        </div>
      </ModalMainText2>
      <span className={`px-${MARGIN}`}>
        {t(`${TRANSLATION_PATH}.info`, { count: MAX_FILES_UPLOAD })}
      </span>
      <Uploader
        disabled={fields?.length >= MAX_FILES_UPLOAD}
        onFilesSelected={onFilesSelected}
      />
      <span className={classJoin(`px-${MARGIN}`, "text-center")}>
        {t(`${TRANSLATION_PATH}.infoOrFill`)}
      </span>
      <Button2
        additionalClass="gap-2 flex items-center self-center"
        dataCy={`${BUTTON}-${INVITING_MODAL}_addMember`}
        width="w-1/2"
        variant="secondary"
        type="button"
        disabled={fields?.length >= MAX_FILES_UPLOAD}
        onClick={onAddNewClick}
      >
        <AddIcon className="fill-current h-6" />
        {t(`${TRANSLATION_PATH}.addMember`)}
      </Button2>
      <MagnetImage className="self-center w-full h-auto pt-10" />

      <LinkButton
        dataCy={`${BUTTON}-${INVITING_MODAL}_cancel`}
        additionalClassName={classJoin(
          "mt-auto underline",
          onCloseClick ? "visible" : "pointer-events-none invisible",
        )}
        padding={`p-${MARGIN}`}
        variant="secondary"
        onClick={() => onCloseClick && onCloseClick()}
      >
        {t("actions.cancel")}
      </LinkButton>
    </div>
  );
};

export default StartScreen;
