enum ActionTypes {
  FETCH_SKILLS_SEARCH_QUERY_RESULT_START = "FETCH_SKILLS_SEARCH_QUERY_RESULT_START",
  FETCH_SKILLS_SEARCH_QUERY_RESULT_SUCCESS = "FETCH_SKILLS_SEARCH_QUERY_RESULT_SUCCESS",
  FETCH_SKILLS_SEARCH_QUERY_RESULT_ERROR = "FETCH_SKILLS_SEARCH_QUERY_RESULT_ERROR",
  CLEAR_SKILLS_SEARCH_QUERY = "CLEAR_SKILLS_SEARCH_QUERY",

  ADD_TEAM_USERS_FILTER = "ADD_TEAM_USERS_FILTER",
  REMOVE_TEAM_USERS_FILTER = "REMOVE_TEAM_USERS_FILTER",
  RESET_TEAM_USERS_FILTERS = "RESET_TEAM_USERS_FILTERS",

  SET_TEAM_USERS_SORTER = "SET_TEAM_USERS_SORTER",

  ADD_POSTPONED_USER = "ADD_POSTPONED_USER",
  REMOVE_POSTPONED_USER = "REMOVE_POSTPONED_USER",
  RESET_POSTPONED_USERS = "RESET_POSTPONED_USERS",
}

export default ActionTypes;
