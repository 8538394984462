import { ReactElement, forwardRef } from "react";
import Tab from "./tab";
import { TabType, Tabs2Props } from "./types";

const Tabs2 = forwardRef<HTMLDivElement, Tabs2Props>(
  ({ tabs, currentValue, onTabClick, onTabKeyDown }, ref): ReactElement => (
    <div ref={ref} className="flex">
      {tabs.map((t: TabType) => (
        <Tab
          key={t.value}
          label={t.label}
          value={t.value}
          onClick={onTabClick}
          onKeyDown={onTabKeyDown}
          active={t.value === currentValue}
        />
      ))}
    </div>
  ),
);
export default Tabs2;
