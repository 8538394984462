import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { uid } from "react-uid";
import { useThemeMode } from "../theme-mode-provider";
import { ReactComponent as StarIcon } from "../../images/star.svg";
import styles from "./styles.module.css";
import { RatingProps } from "./types";
import { RATING_PREFIX } from "../../shared/data-cy";

const Rating = ({
  dataCy,
  Icon,
  onClick,
  readOnly = false,
  selected,
  totalAmount,
}: RatingProps): ReactElement => {
  const { isHighContrast } = useThemeMode();
  const RateIcon = Icon || StarIcon;

  const getIconStyles = (index: number): string => {
    if (isHighContrast)
      return selected && index < selected
        ? "text-primary-50"
        : "text-primary-30";
    return selected && index < selected
      ? "text-primary-50"
      : "text-neutralSecondary-40";
  };

  return (
    <div
      className={classJoin("flex gap-1", readOnly ? "" : styles.container)}
      data-cy={`${RATING_PREFIX}_${dataCy}`}
    >
      {Array.from({ length: totalAmount }).map((item, index) => (
        <RateIcon
          key={uid(index)}
          className={classJoin(
            "fill-current",
            getIconStyles(index),
            readOnly ? "" : "hover:opacity-50",
          )}
          onClick={() => onClick && onClick(index + 1)}
        />
      ))}
    </div>
  );
};

export default Rating;
