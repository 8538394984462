import { useState, ReactElement, useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Label, LinkButton, SearchSelect } from "@ps/ui-components";
import ISOLanguages from "iso-639-1";
import {
  AVAILABILITY,
  LANGUAGE,
  LANGUAGES,
  LEVEL,
  languageLevelsDict,
  availableLanguages,
} from "../constants";
import { useTranslationWithNamespace } from "../../../../hooks";
import { ValueLabelProps } from "./types";
import classJoin from "@ps/utils/src/classJoin";

const NEW_ITEM = {
  language: "",
  level: "",
};

const TRANSLATION_PATH = "profile.overview.languages";

const Languages = (): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const BASE_PATH = `overview_edit_${LANGUAGES}`;
  const errorMessage = `${t("profile.errors.cantBeEmpty")}`;

  const [editIndexes, setEditIndexes] = useState<(number | string)[]>([]);

  const {
    control,
    formState: { errors },
    getValues,
    clearErrors,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: LANGUAGES,
  });

  useEffect(() => {
    append(NEW_ITEM);
    setEditIndexes([0]);
  }, []);

  const getLanguageWithCodes = (): ValueLabelProps[] => {
    const allLanguageCodes = ISOLanguages.getAllCodes();
    const allLanguageNames = ISOLanguages.getAllNames();
    const allLanguages = allLanguageCodes.map(
      (code: string, index: number) => ({
        label: allLanguageNames[index],
        value: code,
      }),
    );
    const filteredLanguagesList = allLanguages.filter(
      (language: ValueLabelProps) =>
        availableLanguages.includes(language.value),
    );
    return filteredLanguagesList;
  };

  const languagesList = getLanguageWithCodes() || [];

  const onRemove = (index: number) => remove(index);

  const languageLevelsValueLabel = languageLevelsDict.map((level: string) => ({
    value: level,
    label: t(`profile.overview.languages.${level}`),
  }));

  const fieldsOnlyCodes = fields.map(
    (field: Record<string, string>) => field.language,
  );

  const validateIsEmpty = (
    dataField: { language: string; level: string; id: string }[],
  ): boolean => dataField?.some((item) => item?.language && item?.level);

  return (
    <div className="flex justify-between w-full flex-col">
      {fields?.map((item, index, arr) => (
        <div className="flex gap-8 w-full py-2 items-center" key={item.id}>
          <Label
            text={`${t(`${TRANSLATION_PATH}.language`)}*`}
            dataCy={`${BASE_PATH}_${LANGUAGE}_${index}`}
            additionalClassName="w-full"
          >
            <Controller
              control={control}
              name={`languages.${index}.${LANGUAGE}`}
              render={({ field: { onChange, value } }) => (
                <SearchSelect
                  dataCy={`${BASE_PATH}_${LANGUAGE}_${index}`}
                  width="w-full"
                  options={languagesList.filter(
                    (language: ValueLabelProps) =>
                      !fieldsOnlyCodes.includes(language.value.toLowerCase()) ||
                      value === language.value.toLowerCase(),
                  )}
                  value={languagesList.find(
                    (element: ValueLabelProps) => element.value === value,
                  )}
                  placeholder={t(`${TRANSLATION_PATH}.languagePlaceholder`)}
                  onChange={(newValue) => {
                    onChange(newValue.value);
                    clearErrors(`languages.${index}.${LANGUAGE}`);
                  }}
                  error={errors?.[LANGUAGES]?.[index]}
                  message={errors?.[LANGUAGES]?.[index]?.language?.message}
                  isMenuOpenTop
                  autoFocus={!index}
                />
              )}
              rules={{
                validate: (value) => {
                  console.log("ARRAY: ", JSON.stringify(arr));
                  console.log(
                    "getValues: ",
                    JSON.stringify(getValues("languages")),
                  );
                  if (!value.length && getValues(LANGUAGES)[0]?.level)
                    return errorMessage;
                  if (!validateIsEmpty(getValues("languages")))
                    return `${t("profile.overview.errors.cantBeEmpty")}`;
                  return true;
                },
              }}
            />
          </Label>
          <Label
            text={`${t(`${TRANSLATION_PATH}.level`)}*`}
            dataCy={`${BASE_PATH}_${LEVEL}_${index}`}
            additionalClassName="w-full"
          >
            <Controller
              control={control}
              name={`languages.${index}.${LEVEL}`}
              render={({ field: { onChange, value } }) => (
                <SearchSelect
                  dataCy={`${BASE_PATH}_${LEVEL}_${index}`}
                  width="w-full"
                  options={languageLevelsValueLabel}
                  value={languageLevelsValueLabel.find(
                    (level) => level.value === value,
                  )}
                  placeholder={t(`${TRANSLATION_PATH}.levelPlaceholder`)}
                  onChange={(newValue) => {
                    onChange(newValue.value);
                    clearErrors(`languages.${index}.${LEVEL}`);
                  }}
                  error={errors?.[LANGUAGES]?.[index]}
                  message={errors?.[LANGUAGES]?.[index]?.level?.message}
                  searchable={false}
                  isMenuOpenTop
                />
              )}
              rules={{
                validate: (value) => {
                  if (!value.length && getValues(LANGUAGES)[0]?.language)
                    return errorMessage;
                  if (!validateIsEmpty(getValues("languages")))
                    return `${t("profile.overview.errors.cantBeEmpty")}`;
                  return true;
                },
              }}
            />
          </Label>

          <LinkButton
            type="button"
            dataCy={`${BASE_PATH}_${LANGUAGES}_REMOVE`}
            onClick={() => onRemove(index)}
            additionalClassName={classJoin(
              "mt-5",
              !index ? "invisible pointer-events-none" : "",
            )}
            disabled={!index}
          >
            x
          </LinkButton>
        </div>
      ))}
      <LinkButton
        autoFocus
        additionalClassName="w-min"
        type="button"
        dataCy={`${BASE_PATH}_${LANGUAGES}_ADD`}
        onClick={() => {
          append(NEW_ITEM);
          setEditIndexes([...editIndexes, fields.length]);
        }}
      >
        {`+ ${t(`profile.overview.${AVAILABILITY}.add`)}`}
      </LinkButton>
    </div>
  );
};

export default Languages;
