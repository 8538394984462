import { LinkButton } from "@ps/ui-components";
import { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import { OCR } from "../../../shared/data-cy";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { ReactComponent as AdditionalInfoEndGraphic } from "../../../images/ocr/additional-info-end-graphic.svg";
import { TRANSLATION_PATH } from "./constants";
import OcrFinalModal from "./uploader/ocrFinalModal";
import { fetchMyProfile } from "../../store";
import { useDispatch } from "redux-react-hook";
import {
  OtherSummaryReturn,
  OtherSummaryState,
} from "../../../folder-domain/ui/types";
import {
  AdditionalInfoEndCardReturn,
  AdditionalInfoEndCardState,
} from "./types";

const AdditionalInfoEndCard = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const history = useHistory();
  const [isFinalModalVisible, setIsFinalModalVisible] = useState(false);
  const dispatch = useDispatch();

  const mapState = (
    state: AdditionalInfoEndCardState,
  ): AdditionalInfoEndCardReturn => ({
    technologyDict: state.dictionaries.technology,
  });

  const { technologyDict } = useMappedStateSelector(mapState);

  const handleOnCloseClick = async () => {
    setIsFinalModalVisible(false);
    history.push("/profile/skills");
    await fetchMyProfile(dispatch, technologyDict);
  };

  return (
    <div className="flex justify-center gap-12 mb-28 mt-16">
      <AdditionalInfoEndGraphic />
      <div className="w-1/3 flex flex-col justify-end">
        <p className="font-bold text-lg">
          {t(`${TRANSLATION_PATH}.addMoreHeader`)}
        </p>
        <p>{t(`${TRANSLATION_PATH}.addMoreContent`)}</p>
        <LinkButton
          dataCy={`goto-matrix-${OCR}`}
          additionalClassName="border-2 border-rounded w-min border-primary-50 mt-4"
          padding="p-2"
          onClick={() => {
            setIsFinalModalVisible(true);
          }}
        >
          {t(`${TRANSLATION_PATH}.closeWizard`)}
        </LinkButton>
      </div>
      <OcrFinalModal
        isOpen={isFinalModalVisible}
        onClose={handleOnCloseClick}
      />
    </div>
  );
};

export default AdditionalInfoEndCard;
