import { createContext, useContext } from "react";
import { SKILLS_MATRIX_MODES } from "../../shared/consts";

export const MatrixContext = createContext<{
  mode?: string;
  isShowingInfoCard: boolean;
  setIsShowingInfoCard: () => void;
}>({
  mode: SKILLS_MATRIX_MODES.VIEW,
  isShowingInfoCard: true,
  setIsShowingInfoCard: (item: boolean) => {
    // do sth
  },
});

export const useMatrixContext = (): {
  mode?: string;
  isShowingInfoCard: boolean;
  setIsShowingInfoCard: (item: boolean) => void;
} => {
  const context = useContext(MatrixContext);
  if (!context) {
    throw new Error(
      "Matrix elements compound components cannot be rendered outside the Modal component",
    );
  }
  return context;
};
