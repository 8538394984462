import { ReactElement, MouseEvent } from "react";
import { Button } from "@ps/ui-components";
import { ReactComponent as GroupIcon } from "../../../../../../../images/new-group.svg";
import { AddNewGroupButtonProps } from "../../types";
import { useTranslationWithNamespace } from "../../../../../../../hooks";
import { SKILLS_MATRIX_EXPERTISE } from "../../../../../../../shared/data-cy";

const AddNewGroupButton = ({
  onClick,
  disabled,
}: AddNewGroupButtonProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Button
      additionalClass="flex gap-2 items-center justify-center mb-8"
      dataCy={`${SKILLS_MATRIX_EXPERTISE}_add`}
      disabled={disabled}
      onClick={(event: MouseEvent<HTMLButtonElement>) =>
        onClick && onClick(event)
      }
      type="button"
    >
      <GroupIcon className="fill-current" />
      {t("expertise.matrix.editMatrix.addGroup")}
    </Button>
  );
};

export default AddNewGroupButton;
