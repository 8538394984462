import { ReactElement } from "react";
import { uid } from "react-uid";
import { TimeTrackerLabel } from "@ps/ui-components";
import { WEEK_DAY } from "../../../../shared/data-cy";
import { TIME_FORMAT_DDD_MMM_DD } from "../../../../shared/constants";
import { DatesProps } from "./types";
import styles from "../styles.module.css";

const Dates = ({ date, dayAmount }: DatesProps): ReactElement => (
  <div className={styles.timeEntriesContainer}>
    {[...Array(dayAmount)].map(
      (_, index: number): ReactElement => (
        <TimeTrackerLabel
          text={date.add(index, "day").format(TIME_FORMAT_DDD_MMM_DD)}
          dataCy={`${WEEK_DAY}_${index + 1}`}
          key={uid(date.add(index, "day").format(TIME_FORMAT_DDD_MMM_DD))}
        />
      ),
    )}
  </div>
);

export default Dates;
