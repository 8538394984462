import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import styles from "../styles.module.css";
import { rowStyles } from "./constants";

const EmptyColumn = (): ReactElement => (
  <tr
    className={classJoin(
      "grid grid-cols-1 divide-y items-end w-full w-min-auto",
      styles.cell,
    )}
  >
    <td className={classJoin(rowStyles, "justify-end")} />
    <td className={classJoin(rowStyles, "border-primary-70 justify-end")} />
    <td className={classJoin(rowStyles, "border-primary-70 justify-end")} />
    <td className={classJoin(rowStyles, "border-primary-70 justify-end")} />
  </tr>
);

export default EmptyColumn;
