export const checkHex = (color: string): boolean => {
  return /^(#)((?:[A-Fa-f0-9]{3}){1,2})$/.test(color);
};

export const checkRGB = (color: string): boolean => {
  return /^(rgb)[(]\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*(?:,\s*([\d.]+)\s*)?[)]$/.test(
    color,
  );
};

export const RGBToHex = (rgb: string): string => {
  const sep = rgb.indexOf(",") > -1 ? "," : " ";
  const newRgb = rgb.substr(4).split(")")[0].split(sep);

  let r = (+newRgb[0]).toString(16);
  let g = (+newRgb[1]).toString(16);
  let b = (+newRgb[2]).toString(16);

  if (r.length === 1) r = `0${r}`;
  if (g.length === 1) g = `0${g}`;
  if (b.length === 1) b = `0${b}`;

  return `#${r}${g}${b}`;
};

export const checkColor = (color: string): string => {
  if (checkHex(color)) return color;
  if (checkRGB(color)) return RGBToHex(color);
  return "";
};
