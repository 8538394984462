import { ReactElement, useEffect, useState, KeyboardEvent } from "react";
import countries from "i18n-iso-countries";
import { classJoin } from "@ps/utils";
import {
  AmountFilterOption,
  FilterDropdown,
  TableLabel,
} from "@ps/ui-components";
import { ReactComponent as FaceIcon } from "../../../../../images/project/face-id.svg";
import styles from "../styles.module.css";
import {
  FilterItem,
  SortBarMapState,
  SortBarMapStateReturn,
  SortBarProps,
} from "./types";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import { selectFilterOptions } from "../../../../../project-domain/ui/new-project/sections/team/utils";
import Keys from "../../../../../shared/keys";
import {
  DISCOVERY_TEAM,
  LOCATION,
  PROFESSION,
  PROFESSIONS,
  PROJECTS,
  PROSPECT,
  SENIORITY,
  USER_NAME,
  RESIDENCE,
} from "../../../../constants";
import {
  prepareLocationFilterOptions,
  prepareProfessionFilterOptions,
  prepareSeniorityFilterOptions,
  getCheckedNames,
  countOptionFilterAmount,
  formatFilterTooltipName,
} from "../utils";
import { CURRENT_LANGUAGE_CODE } from "../../../../../i18n/constants";
import { TEAM_LIST } from "../../../../../shared/data-cy";

const DROPDOWN_ID = "filter-dropdown";
const TRANSLATE_PATH = `${PROJECTS}.${PROSPECT}.${DISCOVERY_TEAM}`;

const SortBar = ({
  users,
  filters,
  updateFilters,
}: SortBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const translate = (field: string) => t(`${TRANSLATE_PATH}.${field}`);
  const [searchValue, setSearchValue] = useState("");
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [currentIDFocused, setCurrentIDFocused] = useState("");

  useEffect(() => {
    if (showFilterOptions) document.getElementById(DROPDOWN_ID)?.focus();
  }, [showFilterOptions]);

  const mapState = (state: SortBarMapState): SortBarMapStateReturn => ({
    professionsDict: state.dictionaries?.profession || [],
    seniorityDict: state?.dictionaries?.seniority || [],
    operativeProfessionsDict: state?.dictionaries.operative_profession || [],
  });

  const { professionsDict, seniorityDict, operativeProfessionsDict } =
    useMappedStateSelector(mapState);

  const professionFiltersOptions = prepareProfessionFilterOptions(users, [
    ...professionsDict,
    ...operativeProfessionsDict,
  ]);

  const seniorityFiltersOptions = prepareSeniorityFilterOptions(
    users,
    seniorityDict,
  );

  const allCountries = countries.getNames(CURRENT_LANGUAGE_CODE, {
    select: "official",
  });

  const locationFiltersOptions = prepareLocationFilterOptions(
    users,
    allCountries,
  );

  const renderLabel = (
    filterName: string,
    translationName?: string,
    filterOptions: FilterItem[] | undefined = undefined,
    withAmount = false,
    nestedName = "",
  ): ReactElement => (
    <TableLabel
      dataCy={translationName || filterName}
      setSearchValue={setSearchValue}
      filtering={
        filterOptions
          ? {
              checked: formatFilterTooltipName(filters, filterName, {
                [PROFESSIONS]: professionsDict,
                [SENIORITY]: seniorityDict,
              }),
              dropdownOverlay: (
                <FilterDropdown
                  dataCy={`${PROSPECT}-teamGenerator-${filterName}`}
                  DROPDOWN_ID={DROPDOWN_ID}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setShowFilterOptions={setShowFilterOptions}
                  filterOptions={filterOptions}
                >
                  {filterOptions.map((item: FilterItem, index: number) => {
                    const isChecked = filters[filterName]?.some(
                      (itemId: string) => itemId === item.id,
                    );
                    const autoFocusId = `${filterName}-${index}`;
                    return (
                      <AmountFilterOption
                        key={item.id}
                        autoFocus={autoFocusId === currentIDFocused}
                        activeNumber={countOptionFilterAmount(
                          item.id,
                          filterName,
                          users,
                          nestedName,
                        )}
                        item={item}
                        isChecked={isChecked}
                        filterName={filterName}
                        withAmount={withAmount}
                        dataCy={TEAM_LIST}
                        onClick={(
                          isCurrenChecked: boolean,
                          itemId: string,
                          currentFilterName: string,
                        ) => {
                          updateFilters(
                            isCurrenChecked,
                            itemId,
                            currentFilterName,
                          );
                          setCurrentIDFocused(autoFocusId);
                        }}
                        onKeyDown={(
                          e: KeyboardEvent<HTMLButtonElement>,
                          isCurrenChecked: boolean,
                          itemId: string,
                          currentFilterName: string,
                        ) => {
                          if (e.key === Keys.SPACE) {
                            e.preventDefault();
                            updateFilters(
                              isCurrenChecked,
                              itemId,
                              currentFilterName,
                            );
                            setCurrentIDFocused(autoFocusId);
                          }
                        }}
                      />
                    );
                  })}
                </FilterDropdown>
              ),
            }
          : undefined
      }
    >
      {translate(translationName || filterName)}
    </TableLabel>
  );

  return (
    <div
      className={classJoin(
        styles.usersGrid,
        "w-full bg-neutralSecondary-41 bg-opacity-10 rounded-t-2xl h-16 items-center px-6",
      )}
    >
      <div className="flex gap-x-9 items-center pl-3">
        <FaceIcon className="text-neutralPrimary-30" />
        {renderLabel(USER_NAME)}
      </div>
      {renderLabel(
        PROFESSIONS,
        PROFESSION,
        selectFilterOptions(professionFiltersOptions, searchValue),
      )}
      {renderLabel(
        SENIORITY,
        undefined,
        selectFilterOptions(seniorityFiltersOptions, searchValue),
        true,
      )}
      {renderLabel(
        RESIDENCE,
        LOCATION,
        selectFilterOptions(locationFiltersOptions, searchValue),
      )}
    </div>
  );
};

export default SortBar;
