import { ReactElement } from "react";
import { ToggleOptionProps } from "./types";
import { ReactComponent as ActiveIcon } from "../../images/success-icon.svg";

const ToggleOption = ({
  label,
  onClick,
  onKeyDown,
  active,
}: ToggleOptionProps): ReactElement => {
  return (
    <div
      role="button"
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={0}
      className="flex items-center justify-between p-2 gap-x-4 hover:text-neutralPrimary-20 cursor-pointer"
    >
      {label}
      {active && <ActiveIcon />}
    </div>
  );
};

export default ToggleOption;
