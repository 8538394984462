/* eslint @typescript-eslint/no-explicit-any: 0 */
import { ReactElement, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import styles from "./styles.module.css";
import { PERMISSIONS } from "../../../shared/data-cy";
import { MIDDLE } from "../../../shared/constants";
import PermissionsCheckbox from "./permissionsCheckbox";
import PermissionsContext from "../context";
import { CheckboxesGroupProps } from "./types";

const CheckboxesGroup = ({
  permissionsList,
  column = MIDDLE,
  roleId = 0,
  isDisabled,
  isActive,
}: CheckboxesGroupProps): ReactElement => {
  const { control, setValue, handleSubmit, getValues } = useFormContext();
  const isMiddle = column === MIDDLE;
  const { onSubmit } = useContext(PermissionsContext);

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // TODO fix types connected with permissions
  const renderPermissionsCells = (data: any, id: number): ReactElement => {
    if (
      data &&
      Object.keys(data).length === 0 &&
      Object.getPrototypeOf(data) === Object.prototype
    )
      return <></>;

    return data?.permissions?.map((firstStepData, firstStepID) => {
      if (!firstStepData?.permissions) {
        return (
          <PermissionsCheckbox
            arrPath={`roles.[${roleId}].permissions.[${id}]`}
            isDisabled={isDisabled}
            isMiddle={isMiddle}
            key={firstStepData.id}
            name={`roles.[${roleId}].permissions.[${id}].permissions.[${firstStepID}]`}
            roleId={roleId}
          />
        );
      }

      return firstStepData?.permissions.map(
        (secondStepData, secondStepID, arr) => (
          <PermissionsCheckbox
            isDisabled={!isDisabled}
            key={secondStepData.id}
            name={`roles.[${roleId}].permissions.[${id}].permissions.[${firstStepID}].permissions.[${secondStepID}]`}
            isMiddle={isMiddle}
            arrPath={`roles.[${roleId}].permissions.[${id}].permissions.[${firstStepID}]`}
            groupPath={`roles.[${roleId}].permissions.[${id}]`}
            additionalClassName={classJoin(
              secondStepID + 1 === arr.length ? "border-b" : "",
            )}
          />
        ),
      );
    });
  };

  const handleOnCheckAllClick = (data, index, isChecked) => {
    data.permissions.map((firstStepData, firstStepID) =>
      !firstStepData?.permissions
        ? setValue(
            `roles.[${roleId}].permissions.[${index}].permissions.[${firstStepID}].checked`,
            isChecked,
          )
        : firstStepData.permissions.map((secondStepData, secondStepID) =>
            setValue(
              `roles.[${roleId}].permissions.[${index}].permissions.[${firstStepID}].permissions.[${secondStepID}].checked`,
              isChecked,
            ),
          ),
    );
    handleSubmit(onSubmit(roleId, getValues("roles")));
  };
  return (
    <div
      className={classJoin(
        "flex flex-col w-full",
        isActive ? styles.activeShadow : "",
      )}
    >
      {permissionsList.map((item, index) => (
        <div
          className={
            isActive ? styles.singleMiddleCellActive : styles.singleMiddleCell
          }
          key={`${MIDDLE}-${item.itemId}`}
        >
          <div
            className={classJoin(
              `flex w-full border-t border-b border-primary-70
              items-center justify-center bg-primary-85 bg-opacity-30`,
              column === "right" ? "border-r" : "",
              styles.baseSingleCell,
            )}
          >
            {isMiddle && (
              <Controller
                name={`roles.[${roleId}].permissions.[${index}].checked`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    dataCy={`${PERMISSIONS}_header`}
                    checked={value}
                    disabled={isDisabled}
                    onChecked={(isChecked) => {
                      onChange(isChecked);
                      handleOnCheckAllClick(item, index, isChecked);
                    }}
                  />
                )}
              />
            )}
          </div>
          <div
            className={classJoin(
              "flex flex-col",
              isMiddle ? "" : styles.lastColumnContainer,
            )}
          >
            {renderPermissionsCells(item, index)}
          </div>
        </div>
      ))}
    </div>
  );
};
export default CheckboxesGroup;
