import { classJoin } from "@ps/utils";
import { ReactElement } from "react";
import { ReadonlyAreaProps } from "./types";
import { READONLY_AREA_PREFIX } from "../../shared/data-cy";
import { useThemeMode } from "../theme-mode-provider";
import CopyValue from "../copy-link-icon-with-tooltip";

const bodyStyle = classJoin.template`
  px-3 pt-3 mb-4 border rounded-md whitespace-pre-wrap
  text-neutralPrimary-30 border-neutralPrimary-30 
  bg-neutralPrimary-100 overflow-y-auto flex flex-col justify-between`;

const ReadonlyArea = ({
  title,
  bodyText,
  additionalClasses,
  width = "w-1/6",
  height = "h-80",
  dataCy,
}: ReadonlyAreaProps): ReactElement => {
  const wrapperStyle = classJoin(additionalClasses, width);

  const { isHighContrast } = useThemeMode();
  const titleTextColor = isHighContrast
    ? "text-neutralSecondary-20"
    : "text-neutralPrimary-20";

  return (
    <div className={wrapperStyle} data-cy={`${READONLY_AREA_PREFIX}-${dataCy}`}>
      {title && (
        <h1
          className={classJoin(
            titleTextColor,
            "text-center font-bold mb-8 uppercase",
          )}
        >
          {title}
        </h1>
      )}
      <div className={classJoin(bodyStyle, height)}>
        {bodyText}
        {bodyText && (
          <div className="w-full py-3 bg-neutralPrimary-100 sticky mt-6 bottom-0 flex justify-end">
            <CopyValue valueToCopy={bodyText} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReadonlyArea;
