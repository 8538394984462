import {
  ProfessionTemplateModel,
  GetDomainTemplateAPIModel,
  GetProfessionTemplateAPIModel,
  GetProfessionFulfillmentAPIModel,
  FulfillmentModel,
  FulfillmentConverterModel,
  ProfessionTemplateConverterModel,
} from "../types";
import {
  sortScoreAscending,
  alphaSortSkillsInProfession,
  domainsToGroups,
  scaleColorToRGBObject,
} from "./helpers";

export const domainTemplateFromAPI = (
  data: GetDomainTemplateAPIModel,
  domainGroup: string,
): ProfessionTemplateModel => ({
  groups: domainsToGroups(data.domains, domainGroup),
  enjoymentScale: data.enjoymentScale
    .map(scaleColorToRGBObject)
    .sort(sortScoreAscending),
  knowledgeScale: data.knowledgeScale
    .map(scaleColorToRGBObject)
    .sort(sortScoreAscending),
});

export const professionTemplateFromAPI = (
  data: GetProfessionTemplateAPIModel,
  professionId: string,
): ProfessionTemplateConverterModel => ({
  professionId,
  template: {
    professionId,
    groups: alphaSortSkillsInProfession(data?.groups),
    knowledgeScale: data.knowledgeScale
      .map(scaleColorToRGBObject)
      .sort(sortScoreAscending),
    enjoymentScale: data.enjoymentScale
      .map(scaleColorToRGBObject)
      .sort(sortScoreAscending),
  },
});

export const fulfillmentFromAPI =
  (professionId: string) =>
  (
    fulfillment: GetProfessionFulfillmentAPIModel,
  ): FulfillmentConverterModel => ({
    professionId,
    fulfillment: {
      [professionId]: fulfillment.ratings as FulfillmentModel,
    },
  });
