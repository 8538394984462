export const prepareChildrenToRender = <T>(
  data: T[][],
  indexToCompare: number,
  child: T,
): T[][] =>
  data.map((item: T[], index: number) =>
    index === indexToCompare ? [...data[index], child] : data[index],
  );

export const updateRowPillsAmount = (
  data: number[],
  indexToCompare: number,
): number[] =>
  data.map((amount: number, amountIndex: number) =>
    amountIndex === indexToCompare ? amount + 1 : amount,
  );
