import { KeyboardEvent, ReactElement, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { classJoin } from "@ps/utils";
// import { ReactComponent as PrevIcon } from "../../../../../images/timesheet/timelog/prev-icon.svg";
// import { ReactComponent as NextIcon } from "../../../../../images/timesheet/timelog/next-icon.svg";
import { ReactComponent as AddNewIcon } from "../../../../../images/timesheet/timelog/add-new-icon.svg";
import { AdvancedViewProps } from "./types";
import { ProjectTimeEntryModel } from "../../../../../models";
import {
  TIME_FORMAT_DDD_MMM_DD,
  TIME_FORMAT_YYYY_MM_DD,
} from "../../../../../shared/constants";
import { useTranslationWithNamespace } from "../../../../../hooks";
import AdvancedViewRow from "./advancedViewRow";
import { focusVisibleStyles, Keys } from "../../../../../shared";

const BASE_PATH = "timesheet.timelog.advancedView";
// const prevNextStyles = classJoin(
//   "flex items-center h-full cursor-pointer p-0.5 rounded-md",
//   focusVisibleStyles,
// );
// const prevNextIconsStyles = "w-4 h-4 mt-1";

const AdvancedView = ({
  fetchData,
  isDisabled,
  openedProjectAdvancedView,
  projectEntries,
  setOpenedProjectAdvancedView,
  timeFrame,
}: AdvancedViewProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const data =
    projectEntries?.[
      dayjs(openedProjectAdvancedView.date).format(TIME_FORMAT_YYYY_MM_DD)
    ];

  const [newEntry, setNewEntry] = useState(false);
  const addButtonVisible = data && data.length;

  useEffect(() => {
    if (data && data.length) {
      setNewEntry(false);
    } else {
      setNewEntry(true);
    }
  }, [data]);

  const inputDate: Dayjs = dayjs(openedProjectAdvancedView.date);
  const inputProject: string = openedProjectAdvancedView.projectId;

  // Temporary hidden - HH2-1687
  // const handleOnClickPrevDay = (): void => {
  //   if (inputDate.isSame(timeFrame[0], "day")) return;
  //   setOpenedProjectAdvancedView({
  //     projectId: inputProject,
  //     date: inputDate.subtract(1, "day").format(TIME_FORMAT_YYYY_MM_DD),
  //   });
  // };

  // const handleOnClickNextDay = (): void => {
  //   if (inputDate.isSame(timeFrame[1], "day")) return;
  //   if (inputDate.isAfter(dayjs().add(6, "day"))) return;
  //   setOpenedProjectAdvancedView({
  //     projectId: inputProject,
  //     date: inputDate.add(1, "day").format(TIME_FORMAT_YYYY_MM_DD),
  //   });
  // };

  return (
    <div className="col-span-full px-6 pb-6">
      <hr className="flex p-5" />
      <div className="flex gap-10">
        <div className="flex flex-col space-y-4 justify-start">
          <span className="text-primary-50 whitespace-nowrap mx-2.5 my-1.5 justify-self-start">
            {inputDate.format(TIME_FORMAT_DDD_MMM_DD)}
          </span>
          {/* <div className="flex space-x-2">
            <span
              className={classJoin("text-neutralPrimary-30", prevNextStyles)}
              onClick={handleOnClickPrevDay}
              role="button"
              tabIndex={0}
              onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
                if (event.key === Keys.ENTER) handleOnClickPrevDay();
              }}
            >
              <PrevIcon className={prevNextIconsStyles} />
              {t(`${BASE_PATH}.prev`)}
            </span>
            <span
              className={classJoin("text-primary-50", prevNextStyles)}
              onClick={handleOnClickNextDay}
              role="button"
              tabIndex={0}
              onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
                if (event.key === Keys.ENTER) handleOnClickNextDay();
              }}
            >
              {t(`${BASE_PATH}.next`)}
              <NextIcon className={prevNextIconsStyles} />
            </span>
          </div> */}
        </div>
        <div className="flex flex-grow flex-col space-y-6">
          {data?.map(
            (entry: ProjectTimeEntryModel, index: number): ReactElement => (
              <AdvancedViewRow
                autoFocus={index === 0}
                data={data}
                disabled={isDisabled}
                entry={entry}
                fetchData={fetchData}
                key={entry.id}
                projectEntries={projectEntries}
                setOpenedProjectAdvancedView={setOpenedProjectAdvancedView}
                timeFrame={timeFrame}
              />
            ),
          )}
          {newEntry && (
            <AdvancedViewRow
              data={data}
              setOpenedProjectAdvancedView={setOpenedProjectAdvancedView}
              fetchData={fetchData}
              timeFrame={timeFrame}
              newEntry={newEntry}
              setNewEntry={setNewEntry}
              day={inputDate}
              projectId={inputProject}
              projectEntries={projectEntries}
            />
          )}
          {addButtonVisible && (
            <>
              <div
                className={classJoin(
                  "flex text-primary-50 items-center space-x-2 cursor-pointer w-min whitespace-nowrap p-0.5 rounded-md",
                  focusVisibleStyles,
                  isDisabled && "pointer-event-none opacity-20",
                )}
                onClick={() => !isDisabled && setNewEntry(true)}
                role="button"
                tabIndex={0}
                onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
                  if (event.key === Keys.ENTER && !isDisabled)
                    setNewEntry((oldState: boolean) => !oldState);
                }}
              >
                <AddNewIcon />
                <span>{t(`${BASE_PATH}.new`)}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdvancedView;
