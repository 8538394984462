import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { classJoin } from "@ps/utils";
import ThemeModeContext from "./context";
import createThemeModePortal from "./createThemeModePortal";
import getInitialTheme from "./getInitialTheme";
import {
  Theme,
  THEME_MODE_LOCAL_STORAGE,
  THEME_MODE_PROVIDER_ID,
  ThemeModeContextProps,
  ThemeModeProviderProps,
} from "./types";
import styles from "./styles.module.css";

const ThemeModeProvider = ({
  additionalClassname,
  children,
}: ThemeModeProviderProps): ReactElement => {
  const [currentTheme, setCurrentTheme] = useState(getInitialTheme());
  const ref = useRef<HTMLDivElement>(null);

  const toggleTheme = () =>
    setCurrentTheme((current) => {
      switch (current) {
        case Theme.LIGHT:
          return Theme.DARK;
        case Theme.DARK:
          return Theme.LIGHT;
        case Theme.HIGH_CONTRAST:
          return Theme.HIGH_CONTRAST;
        default:
          return current;
      }
    });

  const createPortal = useCallback(
    (
      portalChildren: ReactElement | ReactElement[],
      container: Element,
      key?: string,
    ) =>
      createThemeModePortal({
        theme: currentTheme,
        children: portalChildren,
        container,
        key,
        className:
          currentTheme === Theme.HIGH_CONTRAST ? styles.appContainer : "",
      }),
    [currentTheme],
  );

  const providerValue: ThemeModeContextProps = {
    theme: currentTheme,
    set: setCurrentTheme,
    toggle: toggleTheme,
    providerRef: ref,
    createPortal,
  };

  useEffect(() => {
    localStorage.setItem(THEME_MODE_LOCAL_STORAGE, currentTheme);
  }, [currentTheme]);

  return (
    <ThemeModeContext.Provider value={providerValue}>
      <div
        ref={ref}
        id={THEME_MODE_PROVIDER_ID}
        data-color-theme={currentTheme}
        className={classJoin(
          "h-full",
          additionalClassname,
          currentTheme === Theme.HIGH_CONTRAST ? styles.appContainer : "",
        )}
      >
        {children}
      </div>
    </ThemeModeContext.Provider>
  );
};

export default ThemeModeProvider;
