import { AxiosResponse } from "axios";
import {
  AddNewSkillFormRecord,
  FulfillmentModel,
  UpdateDomainFulfillmentAPIModel,
} from "../types";

export const domainFulfillmentToAPI = (
  newDictEntries: AddNewSkillFormRecord[],
  currentFulfillment: FulfillmentModel,
  addedTechnologies: PromiseSettledResult<
    AxiosResponse<
      {
        id: number;
      },
      unknown
    >
  >[],
): UpdateDomainFulfillmentAPIModel => ({
  ratings: {
    ...currentFulfillment,
    ...addedTechnologies.reduce((acc, value, index) => {
      if (value.status === "rejected") return acc;
      return {
        ...acc,
        [value.value.data.id]: {
          knowledge: newDictEntries[index].knowledge,
          enjoyment: newDictEntries[index].enjoyment,
        },
      };
    }, {}),
  },
});
