import { Dayjs } from "dayjs";
import { SelectedDates } from "../state/useSelectDateTypes";

export const isSelectedDate = (selected: SelectedDates, day: Dayjs): boolean =>
  (selected?.filter((date) => date?.isSame(day, "day")).length ?? 0) > 0;

export const getSelectedStyle = (selected: SelectedDates, day: Dayjs): string =>
  isSelectedDate(selected, day)
    ? "bg-primary-50 text-primary-100"
    : "bg-transparent";
