import { useEffect, useState, ReactElement } from "react";
import { SectionForm } from "@ps/ui-components";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import {
  useFullTextSearch,
  mapTechnologyWithOnlyAlphanumeric,
} from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import { setRedirection, clearRedirection } from "@ps/alert-modal";
import { useDispatch } from "redux-react-hook";
import tabs from "./tabs";
import {
  ExperienceSections,
  IExperience,
  ISubmitData,
  ExperienceMapState,
  ExperienceMapStateReturn,
  SectionFormTab,
} from "./types";
import ExperienceContext from "./context";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { OperativeDomainModel, SpecialistDomainModel } from "../../models";
import { MyProfileService } from "../../services";
import { EXPERIENCE_PROJECTS } from "../../../shared/data-cy";
import {
  DICTIONARIES,
  DictionariesService,
  fetchDictionaryStart,
  fetchDictionarySuccess,
  fetchDictionaryError,
  PreparedDictionaryEntry,
  NewDictionaryEntry,
} from "../../../dictionaries";
import { TECH_STACK } from "./sections/projects/constants";
import { DEGREES, COURSES } from "./sections/education/constants";
import {
  CONTRIBUTIONS,
  AWARDS,
  CERTIFICATES,
  ARTICLES,
  TALKS,
  TUTORS,
} from "./sections/accomplishment/constants";
import { fetchMyProfile } from "../../store";
import useFocusEdit from "../../hooks/useFocusEdit";

const Experience = (): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const { state: redirectState } = useLocation<{
    title: string | undefined;
    section: string | undefined;
  }>();

  const history = useHistory();

  const [defaultSection, setDefaultSection] = useState<ExperienceSections>();

  const dispatch = useDispatch();
  const methods = useForm<SpecialistDomainModel | OperativeDomainModel>();
  const [isEditMode, setIsEditMode] = useState(false);
  const setEditMode = () => setIsEditMode(true);
  const [deletingMode, setDeletingMode] = useState("");
  const [focusElement, setFocusElement] = useState("");

  useFocusEdit(focusElement);

  const cancelEditMode = () => {
    setIsEditMode(false);
    dispatch(clearRedirection());
  };

  const translatedTabs = tabs.map((tab) => ({ ...tab, title: t(tab.title) }));

  const mapState = (state: ExperienceMapState): ExperienceMapStateReturn => ({
    myProfile: state.profiles.myProfile,
    technologyDict: state.dictionaries.technology,
    editMode: state.editMode,
  });

  const { myProfile, technologyDict, editMode } =
    useMappedStateSelector(mapState);

  const { isDirty } = methods.formState;

  useEffect(() => {
    if (isDirty && isEditMode) dispatch(setRedirection());
  }, [isDirty, isEditMode]);

  const { search } = useFullTextSearch(
    technologyDict,
    mapTechnologyWithOnlyAlphanumeric,
  );

  const mapTechnologies = async (
    experience: IExperience,
  ): Promise<(PreparedDictionaryEntry | NewDictionaryEntry)[]> => {
    if (typeof experience?.[TECH_STACK] !== "undefined") {
      const temp = await Promise.allSettled(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        experience[TECH_STACK].map((techStack) =>
          DictionariesService.addDictionaryEntry(
            techStack,
            DICTIONARIES.TECHNOLOGY,
          ),
        ),
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return temp.map((item) => item.value);
    }
    return [];
  };

  const mapExperiences = async (
    experiences: IExperience[],
  ): Promise<IExperience[]> => {
    const result = await Promise.allSettled(
      experiences.map(async (experience) => {
        return mapTechnologies(experience).then((response) => ({
          ...experience,
          techStack: response,
        }));
      }),
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return result.map((item) => item.value);
  };

  const prepareTechStack = async (formData: ISubmitData) => {
    const tempData: ISubmitData = JSON.parse(JSON.stringify(formData));
    const dataPromises = await Promise.allSettled(
      Object.entries(tempData).map(([experiencesKey, experiencesArray]) =>
        mapExperiences(experiencesArray).then((response) => ({
          [experiencesKey]: response,
        })),
      ),
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return dataPromises.map((item) => item.value);
  };

  const prepareDataObject = (data) =>
    data.reduce((acc, item) => ({ ...acc, ...item }), {});

  const onSubmit = async (data) => {
    const preparedData = await prepareTechStack(data);
    const dataToSend = prepareDataObject(preparedData);

    await MyProfileService.updateProfile({
      ...myProfile,
      experience: {
        ...myProfile.experience,
        ...dataToSend,
      },
    });
    // Redux do not update immediately state, so there was a need to pass response after dictionary fetch
    dispatch(fetchDictionaryStart());
    const response = await DictionariesService.fetchDictionary(
      DICTIONARIES.TECHNOLOGY,
    );
    if (isAPIError(response)) {
      dispatch(fetchDictionaryError(response));
    } else {
      dispatch(fetchDictionarySuccess(response, DICTIONARIES.TECHNOLOGY));
      await fetchMyProfile(dispatch, response);
      setIsEditMode(false);
      dispatch(clearRedirection());
      methods.reset();
    }
  };

  const getTabsIds = (activeTab: string): string[] | [] => {
    switch (activeTab) {
      case DEGREES:
      case COURSES:
        return translatedTabs
          .filter((item) => item?.tabKey !== ExperienceSections.EDUCATION)
          .map((tab) => tab.tabKey);
      case CONTRIBUTIONS:
      case AWARDS:
      case CERTIFICATES:
      case ARTICLES:
      case TALKS:
      case TUTORS:
        return translatedTabs
          .filter((item) => item?.tabKey !== ExperienceSections.ACCOMPLISHMENT)
          .map((tab) => tab.tabKey);
      default:
        return translatedTabs
          .filter((item) => item?.tabKey !== activeTab)
          .map((tab) => tab.tabKey);
    }
  };

  useEffect(() => {
    if (!redirectState) {
      setDefaultSection(ExperienceSections.PROJECTS);
      return () => history.replace({ ...history.location, state: undefined });
    }

    const { title, section } = redirectState;
    const tabEntry = tabs.find(
      (tab: SectionFormTab<ExperienceSections>) =>
        (tab.fields && tab.fields.includes(title ?? "")) ||
        tab.tabKey === section,
    );
    setDefaultSection(tabEntry?.tabKey);

    return () => history.replace({ ...history.location, state: undefined });
  }, []);

  const handleTabFocus = () => setFocusElement(defaultSection as string);

  return (
    <FormProvider {...methods}>
      <ExperienceContext.Provider
        value={{
          isEditMode,
          cancelEditMode,
          setEditMode,
          onSubmit,
          fullTextSearch: search,
          setDeletingMode,
          deletingMode,
          setFocusElement,
          handleTabFocus,
        }}
      >
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="w-full h-full"
        >
          <SectionForm
            dataCy={EXPERIENCE_PROJECTS}
            tabs={translatedTabs}
            defaultSelectedSection={defaultSection}
            disabledTabs={
              editMode || deletingMode
                ? getTabsIds(editMode || deletingMode)
                : []
            }
          />
        </form>
      </ExperienceContext.Provider>
    </FormProvider>
  );
};
export default Experience;
