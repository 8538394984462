import { ReactElement, KeyboardEvent } from "react";
import { motion } from "framer-motion";
import { classJoin } from "@ps/utils";
import { ReactComponent as ArrowLeftIcon } from "../../images/arrowLeft.svg";
import LoadingTab from "./loadingTab";
import Tab from "./tab";
import { SectionTabsProps } from "./types";
import { focusVisibleStyles, Keys } from "../../shared";

const SectionTabs = <T,>({
  disabledTabs,
  collapsed = false,
  onClickCollapse,
  isLoading,
  onTabClick,
  selected,
  tabs = [],
  defaultWidth = "225px",
  additionalClassName,
}: SectionTabsProps<T>): ReactElement => {
  if (isLoading)
    return (
      <div>
        {new Array(4).fill(0).map(() => (
          <LoadingTab />
        ))}
      </div>
    );

  return (
    <motion.div
      animate={{
        width: collapsed ? "136px" : defaultWidth,
        transition: {
          duration: 0.5,
          type: "spring",
        },
      }}
      className={classJoin(
        "rounded-md h-full overflow-auto relative",
        onClickCollapse ? "pr-3" : "",
        additionalClassName,
      )}
    >
      {tabs.map((tab) => (
        <Tab<T>
          {...tab}
          selected={
            selected === tab.tabKey && !disabledTabs?.includes(tab.tabKey)
          }
          collapsed={collapsed}
          key={`${tab.tabKey}`}
          disabled={disabledTabs?.includes(tab.tabKey)}
          onTabClick={onTabClick}
        />
      ))}
      {onClickCollapse ? (
        <div
          className={classJoin(
            "w-8 h-8 rounded-full bg-neutralPrimary-100 absolute top-5 right-0 cursor-pointer",
            focusVisibleStyles,
          )}
          onClick={onClickCollapse}
          role="button"
          tabIndex={0}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === Keys.ENTER) {
              onClickCollapse();
            }
          }}
        >
          <ArrowLeftIcon
            className={classJoin(
              "text-primary-50 w-5 h-5 absolute top-1.5 right-1.5",
              collapsed ? "transform duration-100 rotate-180" : "",
            )}
          />
        </div>
      ) : (
        <></>
      )}
    </motion.div>
  );
};

export default SectionTabs;
