import { ReactElement } from "react";
import { Modal, ModalContent } from "@ps/ui-components";
import { ReactComponent as UploadFailedIcon } from "../../../../images/ocr/sad-path.svg";
import { OcrUploadModalProps } from "./types";
import { useTranslationWithNamespace } from "../../../../hooks";
import { OCR_UPLOADER } from "../../../../shared/data-cy";

const customErrorLabelStatusDict = {};

const OcrUploadFailModal = ({
  isOpen,
  onClose,
  message = "",
  status,
}: OcrUploadModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const customErrorMessage =
    customErrorLabelStatusDict[status] && t(customErrorLabelStatusDict[status]);

  return (
    <Modal
      withCloseIcon
      withOutsideClick
      dataCy={`${OCR_UPLOADER}_failModal`}
      isOpen={isOpen}
      onClose={onClose}
      width="w-1/3"
      height="h-auto"
    >
      <ModalContent
        height="h-auto"
        withOverflow={false}
        className="flex flex-col items-center gap-3"
      >
        <UploadFailedIcon />
        <h1 className="text-lg font-bold whitespace-pre-line">
          {t("ocr.uploadFailedHeader")}
        </h1>
        <p className="text-base mb-1 whitespace-pre-line">
          {customErrorMessage || message || t("ocr.uploadFailedBody")}
        </p>
      </ModalContent>
    </Modal>
  );
};

export default OcrUploadFailModal;
