import { ReactElement } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
} from "recharts";
import styles from "./styles.module.css";
import { Scores } from "./types";
import { useTranslationWithNamespace } from "../../../../../../hooks";

const TRANSLATION_PATH = "projects.prospect.proposalFolder.teamGenerator";

const DiagramChart = ({ scores }: Scores): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const customTick = ({ payload, x, y, textAnchor, stroke, radius }) => {
    return (
      <g className="recharts-layer recharts-polar-angle-axis-tick">
        <text
          radius={radius}
          stroke={stroke}
          x={x}
          y={y}
          className="fill-current text-neutralPrimary-30"
          textAnchor={textAnchor}
        >
          <tspan className="text-sm" x={x} dy="5px">
            {payload.value}
          </tspan>
        </text>
      </g>
    );
  };

  const data = Object.entries(scores).map(([key, value]) => ({
    subject: t(`${TRANSLATION_PATH}.${key}`).toUpperCase(),
    A: Math.round(value * 100),
    fullMark: 100,
  }));

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      className={styles.background}
    >
      <RadarChart cx="50%" cy="50%" outerRadius="60%" data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="subject" tick={customTick} />
        <Radar
          name="generator"
          dataKey="A"
          className="fill-current text-other-toxicGreen stroke-current stroke-other-toxicGreen"
          fillOpacity={0.6}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default DiagramChart;
