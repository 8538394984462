import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { TableLabel } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../hooks";
import { DURATION, TITLE } from "../constants";
import styles from "./styles.module.css";

const TableHeader = (): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div
      className={classJoin(
        `h-16
        bg-neutralSecondary-41
        bg-opacity-20 rounded-t-lg text-neutralPrimary-40 mt-5`,
        styles.gridContainerGroupedBy,
      )}
    >
      <TableLabel dataCy={TITLE} additionalClassname="uppercase">
        {t(`reports.${TITLE}`)}
      </TableLabel>
      <TableLabel dataCy={DURATION} additionalClassname="uppercase">
        {t(`reports.${DURATION}`)}
      </TableLabel>
      <span />
    </div>
  );
};

export default TableHeader;
