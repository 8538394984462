import { AnyAction, combineReducers } from "redux";
import ActionTypes from "./actionTypes";
import { BenchUsersModel } from "../models";
import { ISelectedFilters, ISelectedSorter } from "./types";
import {
  selectedFiltersInitialState,
  selectedSorterInitialState,
} from "../constants";

const benchUsersReducer = (
  state: BenchUsersModel = [],
  action: AnyAction,
): BenchUsersModel => {
  switch (action.type) {
    case ActionTypes.FETCH_BENCH_USERS_START:
      return state;
    case ActionTypes.FETCH_BENCH_USERS_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_BENCH_USERS_ERROR:
      return [];
    default:
      return state;
  }
};

const filtersReducer = (
  state: ISelectedFilters = selectedFiltersInitialState,
  action: AnyAction,
): ISelectedFilters => {
  const optionId: string = action?.payload?.optionId;
  const filterName: string = action?.payload?.filterName;
  switch (action.type) {
    case ActionTypes.ADD_BENCH_USERS_FILTER:
      return {
        ...state,
        [filterName]: [...state[filterName], optionId],
      };
    case ActionTypes.REMOVE_BENCH_USERS_FILTER:
      return {
        ...state,
        [filterName]: state[filterName].filter(
          (item: string): boolean => item !== optionId,
        ),
      };
    case ActionTypes.RESET_BENCH_USERS_FILTERS:
      return selectedFiltersInitialState;
    default:
      return state;
  }
};

const sorterReducer = (
  state: ISelectedSorter = selectedSorterInitialState,
  action: AnyAction,
): ISelectedSorter => {
  switch (action.type) {
    case ActionTypes.SET_BENCH_USERS_SORTER:
      return action.payload;
    default:
      return state;
  }
};

const openedFoldersStatusesReducer = (
  state: string[] = [],
  action: AnyAction,
): string[] => {
  switch (action.type) {
    case ActionTypes.ADD_OPENED_FOLDER_STATUS:
      return [...state, action.payload];
    case ActionTypes.REMOVE_OPENED_FOLDER_STATUS:
      return state.filter(
        (openedFolder: string): boolean => openedFolder !== action.payload,
      );
    case ActionTypes.RESET_OPENED_FOLDER_STATUSES:
      return [];
    default:
      return state;
  }
};

export const isFetchingBenchUsers = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_BENCH_USERS_START:
      return true;
    case ActionTypes.FETCH_BENCH_USERS_SUCCESS:
    case ActionTypes.FETCH_BENCH_USERS_ERROR:
      return false;
    default:
      return state;
  }
};

export const benchUserIdReducer = (
  state: string = "",
  action: AnyAction,
): string => {
  switch (action.type) {
    case ActionTypes.SET_BENCH_USER_ID:
      return action.payload;
    case ActionTypes.RESET_BENCH_USER_ID:
      return "";
    default:
      return state;
  }
};

export const benchReducer = combineReducers({
  users: benchUsersReducer,
  filters: filtersReducer,
  sorter: sorterReducer,
  openedFoldersStatuses: openedFoldersStatusesReducer,
  benchUserId: benchUserIdReducer,
});
