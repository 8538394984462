export const filterDataForRemove = <T>(
  name: string,
  data: T[],
  removeId: number,
): Record<string, unknown> => ({
  [name]: data.filter((_, id) => id !== removeId),
});

export const getError = (
  field: string,
  section: string,
  index?: number,
  errors = {},
): string =>
  typeof index === "number" ? errors?.[section]?.[index]?.[field] : undefined;

export const checkHasEmptyFields = (fields: string[]): boolean =>
  fields.some((field) => !field);
