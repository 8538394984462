import { ReactElement } from "react";
import { Button2, Modal2, ModalMainText2 } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../hooks";
import { INVITING_MODAL, MODAL } from "../../../../shared/data-cy";
import styles from "../styles.module.css";
import { ReactComponent as CancelingImage } from "../../../../images/members/canceling.svg";
import { ConfirmDeletingModalProps } from "../types";

const TRANSLATION_PATH = "members.inviting";

const ConfirmDeletingModal = ({
  isOpen,
  onClose,
  onConfirm,
}: ConfirmDeletingModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Modal2
      isOpen={isOpen}
      dataCy={`${MODAL}-${INVITING_MODAL}_deleteModal`}
      onClose={onClose}
      additionalContentClassName="flex justify-center h-full gap-8 py-6 px-9"
      padding="0"
      background="bg-neutralSecondary-90"
      withCloseIcon
      width={styles.cancelingModalWidth}
    >
      <CancelingImage className={styles.cancelingModalImage} />
      <div className="flex justify-center h-full flex-col gap-8 p-6">
        <div className="flex flex-col gap-8 h-full w-full">
          <ModalMainText2 dataCy={INVITING_MODAL}>
            <div className="flex items-center gap-2">
              <span>{t(`${TRANSLATION_PATH}.deletingHeader`)}</span>
            </div>
          </ModalMainText2>
        </div>
        <div className="flex items-center gap-4">
          <Button2
            dataCy={`${INVITING_MODAL}_delete_cancel`}
            width="w-1/2"
            variant="secondary"
            type="button"
            onClick={onClose}
          >
            {t("actions.cancel")}
          </Button2>
          <Button2
            dataCy={`${INVITING_MODAL}_delete_confirm`}
            width="w-1/2"
            variant="primary"
            type="button"
            onClick={onConfirm}
          >
            {t("actions.confirm")}
          </Button2>
        </div>
      </div>
    </Modal2>
  );
};

export default ConfirmDeletingModal;
