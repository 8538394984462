import { ReactElement } from "react";
import { uid } from "react-uid";
import dayjs from "dayjs";
import { classJoin, minutesToHHMM } from "@ps/utils";
import { TimeTrackerLabel } from "@ps/ui-components";
import {
  TOTAL_TIME_COUNTED_ROW,
  TOTAL_LABEL,
  TOTAL_DAY,
} from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import { SummaryProps } from "./types";
import styles from "../styles.module.css";

const BASE_PATH = "timesheet.timelog";

const Summary = ({
  dayAmount,
  data,
  startDate,
  isAdvancedView,
}: SummaryProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const entriesValues = data && Object.values(data);
  const onlyEntries = entriesValues?.map((item) => Object.values(item)).flat();
  const flattenAllEntries = onlyEntries.flat();
  const totalAll = flattenAllEntries.reduce((a, b) => a + b.minutes, 0);

  const advancedViewDate = dayjs(isAdvancedView.date);

  const displayTotalFromDay = (index: number): string => {
    const specificDay =
      onlyEntries &&
      onlyEntries
        .filter((item) => item[0].date.isSame(startDate.day(index + 1)))
        .flat();
    const sumTotalFromDay: number = specificDay.reduce(
      (a, b) => a + b.minutes,
      0,
    );
    return minutesToHHMM(sumTotalFromDay);
  };

  return (
    <div
      className={classJoin(
        styles.headerContainer,
        "border-neutralPrimary-30 bg-neutralSecondary-41 bg-opacity-20",
      )}
    >
      <TimeTrackerLabel
        text={t(`${BASE_PATH}.sections.total`)}
        dataCy={TOTAL_LABEL}
        additionalClassName="justify-self-start ml-5"
      />
      <div className={styles.timeEntriesContainer}>
        {[...Array(dayAmount)].map(
          (_, index: number): ReactElement => (
            <TimeTrackerLabel
              key={uid(index)}
              text={displayTotalFromDay(index)}
              additionalClassName={classJoin(
                advancedViewDate.isSame(startDate.day(index + 1), "day")
                  ? "font-semibold"
                  : "",
              )}
              dataCy={`${TOTAL_DAY}-${index}`}
            />
          ),
        )}
      </div>
      <TimeTrackerLabel
        text={minutesToHHMM(totalAll)}
        additionalClassName="font-semibold"
        dataCy={TOTAL_TIME_COUNTED_ROW}
      />
    </div>
  );
};

export default Summary;
