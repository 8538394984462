const translationEN = {
  resume: {
    seniority: "Seniority",
    profession: "Profession",
    role: "Role",
    experience: "Experience",
    languages: "Languages",
    profile: "Profile",
    mainSkills: "Main skills",
    workExperience: "Work experience",
    techStack: "Tech stack:",
    contributions: "Contributions",
    certificates: "Certificates",
    education: "Education",
    allSkills: "All skills",
    domains: "Domains",
    years_one: "{{ count }} year",
    years_other: "{{ count }} years",
    months_one: "{{ count }} month",
    months_other: "{{ count }} months",
    languagesLevels: {
      Elementary: "Elementary proficiency",
      Limited: "Limited working proficiency",
      Professional: "Professional working proficiency",
      FullyProfessional: "Full professional proficiency",
      Native: "Native or bilingual proficiency",
    },
    skillsLegend: {
      level1:
        "I've heard about it but have never dealt with it (or have basic, non-practical knowledge)",
      level2:
        "I've played with the code/documentation or/and did a pet project, but haven't work with it in production",
      level3: "I've been working with it in production context (small scale)",
      level4: "I've been working with it in production context (large scale)",
      level5:
        "I've extensive experience with it in many different large-scale productions",
    },
  },
  report: {
    summary: "Summary",
    report: "report",
    total: "Total:",
    projects: "Projects",
    users: "Users",
    note: "Description",
    table: "Table",
    noDescription: "No description",
    duration: "Duration",
    title: "Title",
  },
};

export default translationEN;
