import { ReactElement, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import SECTIONS from "./sectionsList";
import SectionWrapper from "../../common";
import {
  ARTICLES,
  AWARDS,
  CERTIFICATES,
  CONTRIBUTIONS,
  TALKS,
  TUTORS,
} from "./constants";
import { useMappedStateSelector } from "../../../../../hooks";
import { AccomplishmentMapState, AccomplishmentMapStateReturn } from "./types";

const Accomplishment = (): ReactElement => {
  const { reset, control } = useFormContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const contributionsArray = useFieldArray({
    control,
    name: CONTRIBUTIONS,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const awardsArray = useFieldArray({
    control,
    name: AWARDS,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const certificatesArray = useFieldArray({
    control,
    name: CERTIFICATES,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const articlesArray = useFieldArray({
    control,
    name: ARTICLES,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const talksArray = useFieldArray({
    control,
    name: TALKS,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const tutorsArray = useFieldArray({
    control,
    name: TUTORS,
  });

  const mapState = (
    state: AccomplishmentMapState,
  ): AccomplishmentMapStateReturn => ({
    contributions: state?.profiles?.myProfile?.experience?.contributions || [],
    awards: state?.profiles?.myProfile?.experience?.awards || [],
    certificates: state?.profiles?.myProfile?.experience?.certificates || [],
    articles: state?.profiles?.myProfile?.experience?.articles || [],
    talks: state?.profiles?.myProfile?.experience?.talks || [],
    tutors: state?.profiles?.myProfile?.experience?.tutors || [],
  });

  const { contributions, awards, certificates, articles, talks, tutors } =
    useMappedStateSelector(mapState);

  useEffect(() => {
    reset({ contributions, awards, certificates, articles, talks, tutors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributions, awards, certificates, articles, talks, tutors]);

  return (
    <div className="grid gap-4">
      {SECTIONS.map((section, index) => (
        <SectionWrapper
          key={section.name}
          index={index}
          {...section}
          // eslint-disable-next-line no-eval
          arrayData={eval(`${section.name}Array`)}
        />
      ))}
    </div>
  );
};

export default Accomplishment;
