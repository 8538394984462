import { ReactElement, useEffect, useState, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { AnyAction } from "redux";
import { useDispatch } from "redux-react-hook";
import { uid } from "react-uid";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  LinkButton,
  LoadingMainPage,
  TableHeader,
  TableSearchBar,
  Input,
  ScrollableWrapper,
} from "@ps/ui-components";
import { sortArray, sortObjectArrayWithLocalCompare } from "@ps/utils";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { TEAM_LIST } from "../../../shared/data-cy";
import { USERS_LIST } from "../../../shared/routes";
import { ReactComponent as FunnelIcon } from "../../../images/funnel.svg";
import { DictionaryEntryModel } from "../../../dictionaries";
import { ProfilesSummaryListModel } from "../../../profile-domain";
import {
  NAME,
  PROFESSION,
  SENIORITY,
  STATUS,
  TEAM_HEADER,
  LOCATION,
} from "../../constants";
import {
  resetTeamUsersByPropertyFilters,
  modifyPostponedUsersList,
} from "../../store";
import {
  profileConverter,
  filterTeamUsersBySearch,
  getFilterOptions,
} from "../utils";
import {
  IFilters,
  IProfileReturn,
  UsersListState,
  UsersListStateReturn,
  IOption,
} from "./types";
import { filtersInitialState } from "./constants";
import HeaderLabel from "./headerLabel";
import Row from "./row";
import PostponedList from "./postponedList";
import styles from "./styles.module.css";

const BASE_TRANSLATION_PATH = "team.usersList";

const UsersList = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const history = useHistory();
  const dispatch = useDispatch();

  const [opened, setOpened] = useState<[] | string[]>([]);
  const [filters, setFilters] = useState<IFilters>(filtersInitialState);
  const [searchValue, setSearchValue] = useState<string>("");

  const mapState = (state: UsersListState): UsersListStateReturn => ({
    professionDictionaries:
      [
        ...(state?.dictionaries?.profession || []),
        ...(state?.dictionaries?.operative_profession || []),
      ] || [],
    profiles: state.profiles.profilesSummaryList,
    seniorityDict: state.dictionaries.seniority,
    technologyDict: state.dictionaries.technology,
    selectedFilters: state.teamUsers.filters,
    sorter: state.teamUsers.sorter,
    postponedUsersList: state.teamUsers.postponedUsersList,
  });

  const {
    profiles,
    seniorityDict,
    professionDictionaries,
    sorter,
    selectedFilters,
    postponedUsersList,
  } = useMappedStateSelector(mapState);

  const convertedData: IProfileReturn[] = profiles
    ?.filter(
      (el: ProfilesSummaryListModel): string | undefined =>
        el.firstName || el.lastName,
    )
    ?.map(
      (item: ProfilesSummaryListModel): IProfileReturn =>
        profileConverter(item),
    );

  const [profilesList, setProfilesList] =
    useState<IProfileReturn[]>(convertedData);
  const [baseProfilesList, setBaseProfilesList] =
    useState<IProfileReturn[]>(convertedData);

  const sortedData: IProfileReturn[] = sortObjectArrayWithLocalCompare(
    convertedData,
    sorter.name,
    sorter.order,
  );

  const filteredData: IProfileReturn[] = sortedData?.filter(
    (item: IProfileReturn) =>
      Object.entries(selectedFilters).every(([key, value]) => {
        const filterValue = value as string[] | undefined;
        return (
          !filterValue?.length ||
          (filterValue && filterValue.includes(item[key]))
        );
      }),
  );

  const handleFiltersClear = (): void => {
    resetTeamUsersByPropertyFilters(dispatch);
    setProfilesList(baseProfilesList);
    setSearchValue("");
  };

  useEffect(() => handleFiltersClear(), []);

  useEffect(() => {
    if (convertedData?.length) {
      setProfilesList(filteredData);
      setBaseProfilesList(sortedData);
    }
  }, [profiles, sorter, selectedFilters]);

  useEffect(() => {
    if (
      seniorityDict?.length &&
      professionDictionaries?.length &&
      baseProfilesList?.length &&
      (!filters.seniority || !filters.profession || !baseProfilesList)
    ) {
      setFilters(() => ({
        ...filters,
        [STATUS]: getFilterOptions(baseProfilesList, STATUS).map(
          (status: string): IOption => ({
            id: status,
            name: status,
          }),
        ),
        [PROFESSION]: professionDictionaries
          .filter((dictEntry: DictionaryEntryModel): boolean =>
            getFilterOptions(baseProfilesList, PROFESSION).includes(
              dictEntry.id,
            ),
          )
          .map(
            (item: DictionaryEntryModel): IOption => ({
              id: item.id,
              name: item.name,
            }),
          ),
        [SENIORITY]: sortArray(seniorityDict, "name", "desc")
          .filter((dictEntry: DictionaryEntryModel): boolean =>
            getFilterOptions(baseProfilesList, SENIORITY).includes(
              dictEntry.id,
            ),
          )
          .map(
            (item: DictionaryEntryModel): IOption => ({
              id: item.id,
              name: item.name,
            }),
          ),
        [NAME]: baseProfilesList.map(
          (item: IProfileReturn): IOption => ({
            id: item.name,
            name: item.name,
          }),
        ),
        [LOCATION]: getFilterOptions(baseProfilesList, LOCATION).map(
          (item: string): IOption => ({
            id: item,
            name: item,
          }),
        ),
      }));
    }
  }, [seniorityDict, professionDictionaries, baseProfilesList]);

  const filteredProfilesBySearch: IProfileReturn[] = filterTeamUsersBySearch(
    profilesList,
    searchValue,
  );

  if (!profiles?.length)
    return (
      <LoadingMainPage additionalClassName="bg-neutralPrimary-85 w-full" />
    );

  return (
    <div className="flex gap-8 items-start h-full">
      {postponedUsersList.length ? (
        <PostponedList
          postponedUsers={postponedUsersList}
          onShowMoreClick={(userId: string): void =>
            history.push(`/team/${USERS_LIST}/${userId}`)
          }
          onRemoveClick={(user: IProfileReturn): void => {
            modifyPostponedUsersList(dispatch, "remove", user);
          }}
          watchingProfile=""
        />
      ) : (
        <></>
      )}
      <div className="text-neutralPrimary-20 flex flex-col gap-2 pb-10 w-full h-full">
        <TableSearchBar
          dataCy={TEAM_LIST}
          additionalClassName="flex items-center gap-8 justify-between mb-2"
        >
          <Input
            dataCy={TEAM_LIST}
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              setSearchValue(event.target.value)
            }
            value={searchValue}
            placeholder={t(`${BASE_TRANSLATION_PATH}.search`)}
            isSearch
            width="w-80"
          />
          <div className="flex items-center gap-8">
            <LinkButton
              dataCy={TEAM_LIST}
              prefixIcon={<FunnelIcon className="fill-current" />}
              disabled={
                !Object.entries(selectedFilters).some(
                  ([_, value]) => (value as string[])?.length, // eslint-disable-line @typescript-eslint/no-unused-vars
                )
              }
              onClick={handleFiltersClear}
            >
              {t(`${BASE_TRANSLATION_PATH}.clearFilters`)}
            </LinkButton>
          </div>
        </TableSearchBar>
        <ScrollableWrapper>
          <TableHeader columnsStyles={styles.tableColumns} dataCy={TEAM_HEADER}>
            <HeaderLabel
              canSort
              filterOptions={filters[STATUS]}
              name={STATUS}
              profiles={convertedData}
            >
              {t(`${BASE_TRANSLATION_PATH}.${STATUS}`)}
            </HeaderLabel>
            <HeaderLabel
              canSort
              filterOptions={filters[NAME]}
              name={NAME}
              withSearch
              profiles={convertedData}
            >
              {t(`${BASE_TRANSLATION_PATH}.${NAME}`)}
            </HeaderLabel>
            <HeaderLabel
              canSort
              filterOptions={filters[PROFESSION]}
              name={PROFESSION}
              profiles={convertedData}
            >
              {t(`${BASE_TRANSLATION_PATH}.${PROFESSION}`)}
            </HeaderLabel>
            <HeaderLabel
              canSort
              filterOptions={filters[SENIORITY]}
              name={SENIORITY}
              profiles={convertedData}
            >
              {t(`${BASE_TRANSLATION_PATH}.${SENIORITY}`)}
            </HeaderLabel>
            <HeaderLabel
              canSort
              filterOptions={filters[LOCATION]}
              name={LOCATION}
              profiles={convertedData}
            >
              {t(`${BASE_TRANSLATION_PATH}.${LOCATION}`)}
            </HeaderLabel>
            <span />
            <span />
          </TableHeader>
          <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
            <div className="flex flex-col w-full">
              {filteredProfilesBySearch?.map(
                (profile: IProfileReturn): ReactElement => (
                  <div className="mb-2" key={uid(profile)}>
                    <Row
                      data={profile}
                      handleOnOpen={(userId: string): void =>
                        setOpened((prev: string[]): string[] => [
                          ...prev,
                          userId,
                        ])
                      }
                      handleOnClose={(userId: string): void =>
                        setOpened(
                          opened.filter(
                            (item: string): boolean => item !== userId,
                          ),
                        )
                      }
                      handleOnProfileDetailsOpen={(): void =>
                        history.push(`/team/${USERS_LIST}/${profile.userId}`)
                      }
                      handleOnPostponeRemoveClick={(
                        removedUser: IProfileReturn,
                      ): AnyAction =>
                        modifyPostponedUsersList(
                          dispatch,
                          "remove",
                          removedUser,
                        )
                      }
                      handleOnPostponeAddClick={(
                        newUser: IProfileReturn,
                      ): AnyAction =>
                        modifyPostponedUsersList(dispatch, "add", newUser)
                      }
                      isPostponed={postponedUsersList.some(
                        (item: IProfileReturn): boolean =>
                          item.userId === profile.userId,
                      )}
                      isOpen={opened.some(
                        (id: string): boolean => id === profile.userId,
                      )}
                    />
                  </div>
                ),
              )}
            </div>
          </Scrollbars>
        </ScrollableWrapper>
      </div>
    </div>
  );
};

export default UsersList;
