import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../../hooks";
import { ReactComponent as UploadErrorIcon } from "../../../images/error-notification-icon.svg";
import UploadState from "./UploadState";

interface ErrorUploadStateProps {
  multiple?: boolean;
  message?: string;
}

const ErrorUploadState = ({
  multiple,
  message,
}: ErrorUploadStateProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <UploadState
      color="error"
      icon={UploadErrorIcon}
      text={message || t("uploader.uploadError", { files: multiple ? 2 : 1 })}
    />
  );
};

export default ErrorUploadState;
