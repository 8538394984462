import { AxiosResponse } from "axios";
import APIService from "../api/prospect-api";
import { addUserToDiscoveryTeamToAPIModel } from "../models/converters/toAPIModel";
import { DiscoveryTeamModel } from "../models";
import { handleError } from "../../shared/utils/service-utils";

export const addUserToDiscoveryTeam = async (
  prospectId: string,
  data: DiscoveryTeamModel,
): Promise<AxiosResponse<void>> => {
  try {
    const result = await APIService.API.upsertDiscoveryTeamMember(
      prospectId,
      addUserToDiscoveryTeamToAPIModel(data),
    );
    return result;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteUserFromDiscoveryTeam = async (
  prospectId: string,
  userId: string,
): Promise<AxiosResponse<void>> => {
  try {
    const result = await APIService.API.deleteDiscoveryTeamMember(
      prospectId,
      userId,
    );
    return result;
  } catch (error) {
    return handleError(error);
  }
};
