import { ReactElement, KeyboardEvent } from "react";
import dayjs from "dayjs";
import { classJoin } from "@ps/utils";
import { NotesItemProps } from "./types";
import styles from "./styles.module.css";
import { Keys } from "../../../../../shared";

const NotesItem = ({
  activeNote,
  note,
  index,
  onClickNote,
  notesQuantity,
  PriorityIcon,
  dataCy,
}: NotesItemProps): ReactElement => (
  <>
    <div
      data-cy={dataCy}
      className={classJoin(
        activeNote.index === index && "bg-neutralPrimary-50 bg-opacity-10",
        index === 0 ? "pt-6 rounded-tl-2xl" : "p-6",
        "p-6 pr-10 flex flex-col gap-2 cursor-pointer",
      )}
      onClick={onClickNote}
      onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === Keys.ENTER) onClickNote();
      }}
      tabIndex={0}
      role="button"
    >
      <div className="inline-block font-semibold">
        <span className="inline-block align-middle pr-4">{note.title}</span>
        <span className="inline-block h-4 w-4 align-middle">
          {PriorityIcon && PriorityIcon}
        </span>
      </div>
      <div className="flex gap-x-2">
        <span className="text-neutralPrimary-30">
          {note?.createDate ? dayjs(note.createDate).format("DD.MM.YYYY") : ""}
        </span>
        <span className={classJoin(styles.truncate, "text-neutralPrimary-40")}>
          {note.description}
        </span>
      </div>
    </div>
    {index !== notesQuantity - 1 && (
      <div className="bg-neutralSecondary-41 h-0.5 bg-opacity-20" />
    )}
  </>
);

export default NotesItem;
