import { LoginDataFromKeycloak, LoginDataModel } from "./types";

const fromKeycloakToModel = (data: LoginDataFromKeycloak): LoginDataModel => ({
  email: data.email,
  emailVerified: data.email_verified,
  secondName: data.family_name,
  firstName: data.given_name,
  fullName: data.name,
  preferredUsername: data.preferred_username,
  sub: data.sub,
});

export default fromKeycloakToModel;
