import { ReactElement, useEffect, useState } from "react";
import { sortArray } from "@ps/utils";
import { Button, Sorter } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import GeneratedElement from "./generatedElement";
import { TeamGeneratorProps, TeamsWithDetails } from "./types";
import { sumQuantity } from "../utils";
import EmptySpecialists from "../emptySpecialists";
import { TEAM_GENERATOR } from "../../../../../../shared/data-cy";
import { ReactComponent as CloseIcon } from "../../../../../../images/close.svg";
import {
  ASC,
  DESC,
  ENJOYMENT,
  LANGUAGE_ADAPTABILITY,
  TIME_ADAPTABILITY,
  SENIORITY,
  COST,
  SKILL,
} from "../../../../../constants";

const TRANSLATION_PATH = "projects.prospect.proposalFolder.teamGenerator";

const TeamGenerator = ({
  seniorityDict,
  professionDict,
  prospectNeeds,
  teamsToDisplay,
  setTeamsToDisplay,
  onClose,
  folder,
}: TeamGeneratorProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const [activeSorter, setActiveSorter] = useState({
    name: SKILL,
    order: DESC,
  });

  const names = [
    {
      name: SKILL,
      label: t(`${TRANSLATION_PATH}.${SKILL}`),
      isNestedField: true,
    },
    {
      name: ENJOYMENT,
      label: t(`${TRANSLATION_PATH}.${ENJOYMENT}`),
      isNestedField: true,
    },
    {
      name: LANGUAGE_ADAPTABILITY,
      label: t(`${TRANSLATION_PATH}.${LANGUAGE_ADAPTABILITY}`),
      isNestedField: true,
    },
    {
      name: TIME_ADAPTABILITY,
      label: t(`${TRANSLATION_PATH}.${TIME_ADAPTABILITY}`),
      isNestedField: true,
    },
    {
      name: SENIORITY,
      label: t(`${TRANSLATION_PATH}.${SENIORITY}`),
      isNestedField: true,
    },
    {
      name: COST,
      label: t(`${TRANSLATION_PATH}.${COST}`),
      isNestedField: true,
    },
  ];

  const orders = [
    { order: ASC, label: t(`${TRANSLATION_PATH}.${ASC}`) },
    { order: DESC, label: t(`${TRANSLATION_PATH}.${DESC}`) },
  ];

  useEffect(() => {
    const sortedList = sortArray(
      teamsToDisplay,
      activeSorter.name as keyof TeamsWithDetails,
      activeSorter.order,
      "scores",
    );
    setTeamsToDisplay(sortedList);
  }, [activeSorter.order, activeSorter.name]);

  const handleOnClickSorter = (fieldName: string, order: string): void => {
    setActiveSorter({ name: fieldName, order });
  };

  const teamsInfo = t(`${TRANSLATION_PATH}.teams`, {
    quantity: teamsToDisplay?.length,
  });
  const neededPositions = t(`${TRANSLATION_PATH}.neededPositions`, {
    quantity: sumQuantity(prospectNeeds),
  });

  return (
    <div className="flex flex-col w-full relative">
      <div className="absolute right-0 -top-20 flex gap-x-4 items-center">
        {teamsToDisplay.length ? (
          <Sorter
            names={names}
            orders={orders}
            activeSorter={activeSorter}
            handleSorterClick={handleOnClickSorter}
          />
        ) : null}
        <Button
          dataCy={`${TEAM_GENERATOR}_closeAdd`}
          onClick={onClose}
          additionalClass="flex items-center gap-2"
          variant="secondary"
          width="w-34"
        >
          {t(`${TRANSLATION_PATH}.close`)}
          <CloseIcon className="fill-current" />
        </Button>
      </div>

      {teamsToDisplay.length ? (
        <>
          <div className="flex gap-x-1.5 mb-8 text-lg font-semibold">
            <span className="text-primary-50">{teamsInfo}</span>
            <span className="font-normal text-neutralPrimary-20">
              {t(`${TRANSLATION_PATH}.haveGenerated`)}
            </span>
            <span className="text-primary-50">{neededPositions}</span>
          </div>
          {teamsToDisplay.map((team: TeamsWithDetails, index: number) => (
            <GeneratedElement
              key={team.id}
              generatedTeam={team}
              professionDict={professionDict}
              seniorityDict={seniorityDict}
              index={index}
              folder={folder}
            />
          ))}
        </>
      ) : (
        <EmptySpecialists />
      )}
    </div>
  );
};

export default TeamGenerator;
