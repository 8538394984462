import { ReactElement } from "react";
import dayjs from "dayjs";
import {
  Card,
  StackedBarChart,
  TimeTrackerProjectName,
} from "@ps/ui-components";
import { minutesToHHMM } from "@ps/utils";
import { ProjectModel } from "@ps/hh";
import { useMappedStateSelector } from "../../../hooks";
import { ChartProps, ChartMapState } from "./types";
import { STACKED_BAR_CHART } from "../constants";
import useChartData from "../hooks/useChartData";

const Chart = ({ timeFrame }: ChartProps): ReactElement => {
  const isYearView: boolean =
    dayjs(timeFrame?.[1]).diff(dayjs(timeFrame?.[0]), "month") + 1 === 12;

  const {
    uniqueProjectIds,
    projectColors,
    projectsWithTotalMinutes,
    formattedDataForChart,
  } = useChartData(timeFrame, isYearView);

  const mapState = (state: ChartMapState): ChartMapState => ({
    projectsList: state?.projectsList || [],
  });

  const { projectsList } = useMappedStateSelector(mapState);

  return (
    <div className="flex flex-col gap-3 select-none mt-4">
      <div className="flex flex-wrap ">
        {Object.entries(projectsWithTotalMinutes).map(
          (item: [string, unknown]): ReactElement => {
            const foundProject: ProjectModel = projectsList?.find(
              (project: ProjectModel): boolean => project.id === item?.[0],
            );

            return (
              <div className="flex flex-col gap-1 w-56 py-4" key={item?.[0]}>
                <TimeTrackerProjectName
                  additionalClassName="pr-4"
                  color={foundProject?.details?.color}
                  dataCy={STACKED_BAR_CHART}
                  label={foundProject?.details?.name}
                />
                <span className="font-bold text-neutralPrimary-20">
                  {minutesToHHMM(item?.[1] as number)}
                </span>
              </div>
            );
          },
        )}
      </div>
      <hr className="my-8" />
      <StackedBarChart
        dataCy={STACKED_BAR_CHART}
        allProjects={projectsList}
        keys={uniqueProjectIds}
        // eslint-disable-next-line
        // @ts-ignore
        data={formattedDataForChart}
        colors={projectColors}
        isYear={isYearView}
      />
    </div>
  );
};

export default Chart;
