import {
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";

const TimeLogContext = createContext({
  enableAddProject: {} as boolean,
  setEnableAddProject: {} as Dispatch<SetStateAction<boolean>>,
});

export const TimeLogContextProvider: FC = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement => {
  const [enableAddProject, setEnableAddProject] = useState(false);

  const value = { enableAddProject, setEnableAddProject };

  return (
    <TimeLogContext.Provider value={value}>{children}</TimeLogContext.Provider>
  );
};

export default TimeLogContext;
