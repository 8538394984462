import { KeyboardEvent, ReactElement } from "react";
import { classJoin, getNameFromDictionary } from "@ps/utils";
import { NeedModel } from "../../../../models";
import { Keys } from "../../../../../shared";
import { NeedsOptionsContentProps } from "./types";
import { sumQuantity } from "./utils";
import { useTranslationWithNamespace } from "../../../../../hooks";

const BASE_PATH = "projects.prospect.proposalFolder";

const entryStyle =
  "flex flex-row items-center gap-3 w-full h-full px-3.5 py-1.5 cursor-pointer first:rounded-t-inherit last:rounded-b-inherit";

const NeedsOptionsContent = ({
  needs,
  professionDict,
  seniorityDict,
  onMLClick,
  onTeamGenerateClick,
  singleElementClassName,
}: NeedsOptionsContentProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const seniority = (needSeniority: string): string => {
    const seniorityName = getNameFromDictionary(seniorityDict, needSeniority);
    const normalizedSeniority =
      seniorityName.charAt(0).toUpperCase() + seniorityName.slice(1);
    return normalizedSeniority;
  };

  return (
    <div className="flex  items-center flex-col rounded text-neutralPrimary-20 border border-primary-50 pb-2 bg-neutralPrimary-85">
      {sumQuantity(needs) > 1 && (
        <div
          data-cy="generateTeam"
          onClick={onTeamGenerateClick}
          className={classJoin(singleElementClassName, entryStyle, "py-3.5")}
          tabIndex={0}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === Keys.ENTER) onTeamGenerateClick();
          }}
          role="button"
        >
          <span className="font-semibold">
            {t(`${BASE_PATH}.generateTeam`)}
          </span>
          <span>({sumQuantity(needs)})</span>
        </div>
      )}
      <span
        className={classJoin(
          "uppercase text-sm text-left w-full pl-3.5 pb-1.5 bg-neutralPrimary-85 text-neutralPrimary-30",
          needs.length === 1 && "pt-2",
        )}
      >
        {t(`${BASE_PATH}.specificRequirements`)}
      </span>
      {needs?.map(
        (singleNeed: NeedModel): ReactElement => (
          <div
            data-cy={`generate_${singleNeed.profession}`}
            key={singleNeed.itemId}
            onClick={() => onMLClick(singleNeed.itemId)}
            className={classJoin(singleElementClassName, entryStyle)}
            tabIndex={0}
            onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
              if (event.key === Keys.ENTER) onMLClick(singleNeed.itemId);
            }}
            role="button"
          >
            <span className="font-semibold">
              {getNameFromDictionary(professionDict, singleNeed.profession)}
            </span>
            <span>
              {seniority(singleNeed.seniority)} ({singleNeed.quantity})
            </span>
          </div>
        ),
      )}
    </div>
  );
};

export default NeedsOptionsContent;
