import { AnyAction, Dispatch } from "redux";
import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import ActionTypes from "./actionTypes";
import { UserService } from "../services";
import { NewRoleModel, UpdatedRoleModel } from "../models";
import { IAction } from "./types";

export const fetchUsersStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_USERS_START);
export const fetchUsersSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_USERS_SUCCESS, payload);
export const fetchUsersError = (payload: IAction<string> | string): AnyAction =>
  actionCreator(ActionTypes.FETCH_USERS_ERROR, payload);

export const fetchUsers = async (
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchUsersStart());
  const result = await UserService.fetchUsersList();
  if (isAPIError(result)) {
    dispatch(fetchUsersError(result));
  } else {
    dispatch(fetchUsersSuccess(result));
  }
};

export const fetchUserDataStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_USER_DATA_START);
export const fetchUserDataSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_USER_DATA_SUCCESS, payload);
export const fetchUserDataError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_USER_DATA_ERROR, payload);

export const fetchUserData = async (
  userId: string,
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchUserDataStart());
  const result = await UserService.fetchUserData(userId);
  if (isAPIError(result)) {
    dispatch(fetchUserDataError(result));
  } else {
    dispatch(fetchUserDataSuccess(result));
  }
};

export const fetchAllRolesStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_ALL_ROLES_START);
export const fetchAllRolesSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_ALL_ROLES_SUCCESS, payload);
export const fetchAllRolesError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_ALL_ROLES_ERROR, payload);

export const fetchAllRoles = async (
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchAllRolesStart());
  const result = await UserService.fetchAllUsersRoles();
  if (isAPIError(result)) {
    dispatch(fetchAllRolesError(result));
  } else {
    dispatch(fetchAllRolesSuccess(result));
  }
};

export const createRoleStart = (): AnyAction =>
  actionCreator(ActionTypes.CREATE_ROLE_START);
export const createRoleSuccess = (): AnyAction =>
  actionCreator(ActionTypes.CREATE_ROLE_SUCCESS);
export const createRoleError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.CREATE_ROLE_ERROR, payload);

export const createRole = async (
  dispatch: Dispatch,
  data: NewRoleModel,
): Promise<AnyAction | void> => {
  dispatch(createRoleStart());
  const result = await UserService.createRole(data);
  if (isAPIError(result)) {
    dispatch(createRoleError(result));
  } else {
    dispatch(createRoleSuccess());
  }
};

export const updateRoleStart = (): AnyAction =>
  actionCreator(ActionTypes.UPDATE_ROLE_START);
export const updateRoleSuccess = (): AnyAction =>
  actionCreator(ActionTypes.UPDATE_ROLE_SUCCESS);
export const updateRoleError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.UPDATE_ROLE_ERROR, payload);

export const updateRole = async (
  dispatch: Dispatch,
  roleId: string,
  data: UpdatedRoleModel,
): Promise<AnyAction | void> => {
  dispatch(updateRoleStart());
  const result = await UserService.updateRole(roleId, data);
  if (isAPIError(result)) {
    dispatch(updateRoleError(result));
  } else {
    dispatch(updateRoleSuccess());
  }
};

export const deleteRoleStart = (): AnyAction =>
  actionCreator(ActionTypes.DELETE_ROLE_START);
export const deleteRoleSuccess = (): AnyAction =>
  actionCreator(ActionTypes.DELETE_ROLE_SUCCESS);
export const deleteRoleError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.DELETE_ROLE_ERROR, payload);

export const deleteRole = async (
  dispatch: Dispatch,
  roleId: string,
): Promise<AnyAction | void> => {
  dispatch(deleteRoleStart());
  const result = await UserService.deleteRole(roleId);
  if (isAPIError(result)) {
    dispatch(deleteRoleError(result));
  } else {
    dispatch(deleteRoleSuccess());
  }
};

export const createUsersStart = (): AnyAction =>
  actionCreator(ActionTypes.CREATE_USERS_START);
export const createUsersSuccess = (): AnyAction =>
  actionCreator(ActionTypes.CREATE_USERS_SUCCESS);
export const createUsersError = (): AnyAction =>
  actionCreator(ActionTypes.CREATE_USERS_ERROR);
