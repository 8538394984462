import dayjs from "dayjs";
import {
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  LAST_WEEK,
  THIS_MONTH,
  LAST_MONTH,
  THIS_YEAR,
  LAST_YEAR,
  YESTERDAY_DATE,
  THIS_WEEK_START_DATE,
  THIS_WEEK_END_DATE,
  LAST_WEEK_START_DATE,
  LAST_WEEK_END_DATE,
  THIS_MONTH_START_DATE,
  THIS_MONTH_END_DATE,
  LAST_MONTH_START_DATE,
  LAST_MONTH_END_DATE,
  THIS_YEAR_START_DATE,
  THIS_YEAR_END_DATE,
  LAST_YEAR_START_DATE,
  LAST_YEAR_END_DATE,
} from "../constants";

const setDateFromPreset = (preset: string): Date[] => {
  const firstDayOfWeek = dayjs().startOf("week");
  const lastDayOfWeek = dayjs().endOf("week");

  switch (preset) {
    case TODAY:
      return [new Date(), new Date()];
    case YESTERDAY:
      return [YESTERDAY_DATE.toDate(), YESTERDAY_DATE.toDate()];
    case THIS_WEEK:
      return [THIS_WEEK_START_DATE.toDate(), THIS_WEEK_END_DATE.toDate()];
    case LAST_WEEK:
      return [LAST_WEEK_START_DATE.toDate(), LAST_WEEK_END_DATE.toDate()];
    case THIS_MONTH:
      return [THIS_MONTH_START_DATE.toDate(), THIS_MONTH_END_DATE.toDate()];
    case LAST_MONTH:
      return [LAST_MONTH_START_DATE.toDate(), LAST_MONTH_END_DATE.toDate()];
    case THIS_YEAR:
      return [THIS_YEAR_START_DATE.toDate(), THIS_YEAR_END_DATE.toDate()];
    case LAST_YEAR:
      return [LAST_YEAR_START_DATE.toDate(), LAST_YEAR_END_DATE.toDate()];
    default:
      return [firstDayOfWeek.day(1).toDate(), lastDayOfWeek.day(7).toDate()];
  }
};

export default setDateFromPreset;
