export const CLIENTS = "clients";
export const CLIENT_NAME = "clientName";
export const CONTACT_PERSON_NAME = "contactPersonName";
export const CONTACT_PERSON_EMAIL = "contactPersonEmail";
export const COUNTRY = "country";
export const CITY = "city";
export const PHONE_NUMBER = "phoneNumber";
export const DOMAINS = "domains";

export const ACTIVE_PROSPECTS = "activeProspects";
export const ACTIVE_FOLDERS = "activeFolders";
export const ACTIVE_PROJECTS = "activeProjects";
export const LAST_UPDATE = "lastUpdate";
export const STATUS = "status";
export const ACTIVE = "active";
export const ARCHIVED = "archived";
export const ASC = "asc";
export const DESC = "desc";

export const PROSPECTS = "prospects";
export const PROJECTS = "projects";
export const FOLDERS = "folders";
export const DETAILS = "details";

export const ADD_NEW_CLIENT = "addNewClient";

export const EMAIL_REGEXP_PATTERN = new RegExp(
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const MORE = "more";
export const SEE_ALL = "seeAll";

export const FOLDER_NAME = "folderName";
export const FOLDER_URL = "folderUrl";
export const FOLDERS_PIN = "foldersPIN";
export const RESUMES = "resumes";

export const PROJECT_NAME = "projectName";
export const PM_NAME = "pmName";
export const BD_NAME = "bdName";
export const CREATION_DATE = "creationDate";
export const USERS_IN_PROJECT = "usersInProject";

export const PROSPECT_NAME = "prospectName";
export const DAYS_PASSED = "daysPassed";

export const NUMBER_OF_ACTIVE_FOLDERS = "numberOfActiveFolders";
export const NUMBER_OF_ACTIVE_PROSPECTS = "numberOfActiveProspects";
export const NUMBER_OF_ACTIVE_PROJECTS = "numberOfActiveProjects";

export const CLIENT_DETAILS = "clientDetails";

export const CLIENT_COUNTRY = "clientCountry";
export const CLIENT_CITY = "clientCity";
export const PHONE = "phone";

export const ADD = "add";

export const ROW_DATE_FORMAT = "DD.MM.YYYY";

export const initialCheckersState = {
  [ACTIVE]: true,
  [ARCHIVED]: false,
};
