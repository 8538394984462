import { KeyboardEvent, ReactElement, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { classJoin } from "@ps/utils";
import { Icon, Input, TableTab, useThemeMode } from "@ps/ui-components";
import { SKILLS_MATRIX_EXPERTISE } from "../../../../../../../shared/data-cy";
import { useEditMatrixContext } from "../../context";
import { ProperIcon } from "../../../../../common";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../../../hooks";
import { ConfirmDeleteProfessionModal } from "../modals";
import { TabMapState, TabMapStateReturn, TabProps } from "./types";
import { DictionaryEntryModel } from "../../../../../../../dictionaries";
import { Keys } from "../../../../../../../shared";

const selectedStyle = (isHighContrast: boolean) => ({
  wrapper: "bg-primary-50 border-primary-50 mb-2 p-3 rounded-md",
  name: "font-semibold text-primary-100",
  title: "font-semibold text-primary-100",
  icon: {
    wrapper: classJoin(
      isHighContrast
        ? "bg-primary-95 text-primary-30"
        : "primary-70 bg-opacity-30",
    ),
    svg: isHighContrast ? "bg-primary-95 rounded-md" : "text-primary-100",
  },
});

const notSelectedStyle = {
  wrapper:
    "bg-neutralPrimary-100 border-neutralSecondary-60 mb-2 p-3 rounded-md",
  name: "font-semibold text-neutralPrimary-20",
  icon: {
    wrapper: "neutralPrimary-85",
    svg: "text-primary-50",
  },
};

const disabledStyle = "opacity-50 cursor-default pointer-event-none";

const Tab = ({
  cantRemove,
  disabled,
  index,
  isActive,
  onActiveTabClick,
  onRemove,
  tab,
  onSubmit,
  hideButtons,
}: TabProps): ReactElement => {
  const { isHighContrast } = useThemeMode();
  const { control, handleSubmit, reset } = useFormContext();
  const { t } = useTranslationWithNamespace();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { name, itemId = "" } = tab;
  const appliedStyle = isActive
    ? selectedStyle(isHighContrast)
    : notSelectedStyle;

  const { editProfession, setEditProfession, setIsNotificationVisible } =
    useEditMatrixContext();
  const isEditing = editProfession === itemId;

  const mapState = (state: TabMapState): TabMapStateReturn => ({
    professionDict: state.dictionaries?.profession || [],
  });

  const { professionDict } = useMappedStateSelector(mapState);

  const handleOnCancelClick = (): void => {
    setEditProfession("");
    reset();
  };

  const handleOnRemoveClick = (): void => {
    if (cantRemove || editProfession === itemId) {
      setIsNotificationVisible(true);
      setTimeout(() => setIsNotificationVisible(false), 5000);
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <Controller
        name={`tabs[${index}].name`}
        control={control}
        rules={{
          required: {
            value: true,
            message: `${t("expertise.errors.isRequired")}`,
          },
          validate: (value) => {
            if (
              professionDict
                ?.filter((_, valueIndex) => valueIndex !== index)
                .some(
                  (profession: DictionaryEntryModel) =>
                    isEditing &&
                    profession.name.replaceAll(" ", "").toLowerCase() ===
                      value.replaceAll(" ", "").toLowerCase().trim(),
                )
            )
              return t("expertise.errors.professionNameExists");
            return true;
          },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TableTab
            dataCy={`${SKILLS_MATRIX_EXPERTISE}-template_${itemId}`}
            additionalClass={classJoin(
              appliedStyle.wrapper,
              "duration-300",
              disabled && disabledStyle,
            )}
            isEditing={isEditing}
            onClick={!disabled ? () => onActiveTabClick?.(itemId) : undefined}
            editButton={{
              variant: "secondary",
              additionalClass: "bg-primary-100",
              onClick: () => !disabled && setEditProfession(itemId),
              disabled,
            }}
            cancelButton={{
              variant: "secondary",
              additionalClass: "bg-primary-100",
              onClick: () => !disabled && handleOnCancelClick(),
            }}
            deleteButton={{
              variant: "secondary",
              additionalClass: "bg-primary-100",
              onClick: handleOnRemoveClick,
              disabled: disabled || isEditing,
            }}
            hideButtons={hideButtons}
          >
            <div className="flex flex-row items-center gap-3 truncate">
              <div className={classJoin(appliedStyle.icon.svg, "duration-200")}>
                <Icon
                  backgroundColor={classJoin(
                    appliedStyle.icon.wrapper,
                    "duration-300",
                  )}
                  dataCy={name}
                  size="11"
                  icon={<ProperIcon professionId={itemId} />}
                />
              </div>
              {isEditing ? (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <Input
                  autoFocus
                  dataCy={`${SKILLS_MATRIX_EXPERTISE}_profession-name`}
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange(e.target.value);
                  }}
                  defaultValue={value}
                  error={!!error}
                  message={error?.message}
                  onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === Keys.ENTER && onSubmit) onSubmit();
                  }}
                />
              ) : (
                <span className={classJoin(appliedStyle.name, "truncate")}>
                  {value}
                </span>
              )}
            </div>
          </TableTab>
        )}
      />

      <ConfirmDeleteProfessionModal
        isOpen={isModalOpen}
        name={name}
        onCancel={() => setIsModalOpen(false)}
        onDelete={() => {
          setIsModalOpen(false);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          handleSubmit(onRemove(itemId, index));
        }}
      />
    </>
  );
};

export default Tab;
