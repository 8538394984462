import { AnyAction, combineReducers } from "redux";
import ActionTypes from "./actionTypes";
import {
  OperativeDomainModel,
  ProfileSummaryModel,
  SpecialistDomainModel,
  OcrModel,
} from "../models";
import { IOtherProfilesSkillsDomainsReducer } from "./types";
import { mockEmptyOcredModel } from "../services/mockedOCR";

export const myProfileReducer = (
  state:
    | OperativeDomainModel
    | SpecialistDomainModel
    | Record<string, unknown> = {},
  action: AnyAction,
): Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_MY_PROFILE_START:
      return state;
    case ActionTypes.FETCH_MY_PROFILE_SUCCESS:
      return { ...state, ...action.payload };
    case ActionTypes.FETCH_MY_PROFILE_ERROR:
      return {};
    default:
      return state;
  }
};

export const profileReducer = (
  state:
    | OperativeDomainModel
    | SpecialistDomainModel
    | Record<string, unknown> = {},
  action: AnyAction,
): Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_PROFILE_START:
      return state;
    case ActionTypes.FETCH_PROFILE_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_PROFILE_ERROR:
      return {};
    default:
      return state;
  }
};

export const profilesSummaryListReducer = (
  state: ProfileSummaryModel[] | [] = [],
  action: AnyAction,
): ProfileSummaryModel[] | [] => {
  switch (action.type) {
    case ActionTypes.FETCH_PROFILES_START:
      return state;
    case ActionTypes.FETCH_PROFILES_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_PROFILES_ERROR:
      return [];
    default:
      return state;
  }
};

const otherProfilesSkillsDomainsReducer = (
  state: IOtherProfilesSkillsDomainsReducer = {},
  action: AnyAction,
): IOtherProfilesSkillsDomainsReducer => {
  switch (action.type) {
    case ActionTypes.FETCH_OTHER_PROFILE_START:
      return state;
    case ActionTypes.FETCH_OTHER_PROFILE_SUCCESS:
      return { ...state, [action?.payload?.userId]: action?.payload?.data };
    case ActionTypes.FETCH_OTHER_PROFILE_ERROR:
      return state;
    default:
      return {};
  }
};

const ocrProcessingReducer = (
  state: OcrModel | Record<string, unknown> = mockEmptyOcredModel,
  action: AnyAction,
): OcrModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.OCR_PROCESSING_START:
      return state;
    case ActionTypes.OCR_PROCESSING_SUCCESS:
      return action.payload;
    case ActionTypes.OCR_PROCESSING_ERROR:
      return {};
    default:
      return state;
  }
};

export const isFetchingSpecialistProfiles = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_SPECIALIST_PROFILES_START:
      return true;
    case ActionTypes.FETCH_SPECIALIST_PROFILES_SUCCESS:
    case ActionTypes.FETCH_SPECIALIST_PROFILE_ERROR:
      return false;
    default:
      return state;
  }
};

export const isFetchingProfiles = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_PROFILES_START:
      return true;
    case ActionTypes.FETCH_PROFILES_SUCCESS:
    case ActionTypes.FETCH_PROFILES_ERROR:
      return false;
    default:
      return state;
  }
};

export const profilesReducer = combineReducers({
  myProfile: myProfileReducer,
  profile: profileReducer,
  profilesSummaryList: profilesSummaryListReducer,
  otherProfiles: otherProfilesSkillsDomainsReducer,
  ocrResults: ocrProcessingReducer,
});
