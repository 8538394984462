import {
  DataProps,
  FolderEditDetailsModel,
  FolderModel,
  UpdateFolderAPIModel,
} from "../types";

export const folderAnonymizationToModel = (
  folder: FolderModel,
): FolderEditDetailsModel => ({
  clientId: folder.clientId,
  folderName: folder.folderName,
  anonymized: !folder.anonymized,
});

export const folderFieldsUpdateToModel = (
  data: DataProps,
): FolderEditDetailsModel => ({
  clientId: data?.folder?.clientId?.id
    ? JSON.parse(JSON.stringify(data?.folder?.clientId?.id))
    : data?.folder?.clientId,
  folderName: data?.folder?.folderName,
  anonymized: data?.folder?.anonymized,
});

const folderDetails = (data: FolderEditDetailsModel): UpdateFolderAPIModel => ({
  clientId: data?.clientId,
  name: data?.folderName,
  anonymize: data?.anonymized,
});

export const updateFolderToAPIModel = (
  data: FolderEditDetailsModel,
): UpdateFolderAPIModel => folderDetails(data);
