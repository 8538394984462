import { ReactElement, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch } from "redux-react-hook";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { ClientService, ProjectService, UserService } from "@ps/hh";
import {
  Button,
  Card,
  LoadingAnyPage,
  TimeTrackerTimeNavigator,
} from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../hooks";
import {
  CLIENTS,
  PROJECTS,
  TIME_FORMAT_YYYY_MM_DD,
  USERS,
} from "../../shared/constants";
import { TIME_NAVIGATOR } from "../../shared/data-cy";
import {
  ALL_CLIENT_SELECTED,
  ALL_PROJECT_SELECTED,
  ALL_USER_SELECTED,
  APPLY_BUTTON,
  emptyValueLabel,
  firstGroupSelect,
  secondGroupSelect,
  STACKED_BAR_CHART,
} from "./constants";
import {
  IClientOption,
  IProjectOption,
  IUserOption,
  ReportsMapState,
  ReportsMapStateReturn,
} from "./types";
import StartScreen from "./graphics/startScreen";
import NoResults from "./graphics/noResults";
import Chart from "./chart/chart";
import Table from "./table/table";
import { clearReport, fetchReport, fetchReportSuccess } from "../../store";
import { ReportsModel } from "../../models";
import {
  prepareClientOptions,
  prepareProjectOptions,
  prepareUserOptions,
} from "./utils";
import { ReactComponent as FiltersIcon } from "../../images/filters.svg";
import {
  ClientsFilter,
  PillsRow,
  ProjectsFilter,
  UsersFilter,
} from "./filters";
import DownloadFiles from "./common/downloadFiles";
import { updateReportsGroupBy } from "../../store/actions";
import { prepareGroupOptionsToSearchSelect } from "./table/helpers";

const Reports = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const [searchUserValue, setSearchUserValue] = useState("");
  const [searchClientValue, setSearchClientValue] = useState("");
  const [searchProjectValue, setSearchProjectValue] = useState("");
  const [focusedElementID, setFocusedElementID] = useState("");

  const methods = useForm({
    defaultValues: { ALL_USERS_SELECTED: false },
  });

  const mapState = (state: ReportsMapState): ReportsMapStateReturn => ({
    report: state?.reports?.report || [],
    isFetchingReport: state.requestStatus.isFetchingReport,
  });

  const { report, isFetchingReport } = useMappedStateSelector(mapState) as {
    report: ReportsModel;
    isFetchingReport: boolean;
  };

  useEffect(() => {
    dispatch(
      updateReportsGroupBy({
        group0: prepareGroupOptionsToSearchSelect(firstGroupSelect, t)[0],
        group1: prepareGroupOptionsToSearchSelect(secondGroupSelect, t)[0],
        group2: emptyValueLabel,
      }),
    );
  }, []);

  const usersArrayFields = useFieldArray({
    control: methods.control,
    name: USERS,
  });

  const clientsArrayFields = useFieldArray({
    control: methods.control,
    name: CLIENTS,
  });

  const projectsArrayFields = useFieldArray({
    control: methods.control,
    name: PROJECTS,
  });

  const filtersSelectedListArrayFields = useFieldArray({
    control: methods.control,
    name: "filtersSelectedList",
  });

  const filtersSelectedListArrayFieldsLength: number =
    filtersSelectedListArrayFields?.fields?.length || 0;

  const [timeFrame, setTimeFrame] = useState<Date[]>([
    dayjs().startOf("week").day(1).toDate(),
    dayjs().startOf("week").add(7, "day").toDate(),
  ]);

  const fetchSelectLists = async () => {
    const users = await UserService.fetchUsersList();
    const projects = await ProjectService.getProjects();
    const clients = await ClientService.fetchClients();
    methods.reset({
      users: prepareUserOptions(users),
      projects: prepareProjectOptions(projects),
      clients: prepareClientOptions(clients),
    });
  };

  useEffect(() => {
    fetchSelectLists();
    return () => {
      dispatch(clearReport());
    };
  }, []);

  const from: string = dayjs(timeFrame?.[0]).format(TIME_FORMAT_YYYY_MM_DD);
  const to: string = dayjs(timeFrame?.[1]).format(TIME_FORMAT_YYYY_MM_DD);

  const onSubmit = async (data: {
    users: IUserOption[];
    projects: IProjectOption[];
    clients: IClientOption[];
  }): Promise<void> => {
    const usersData: string[] = data.users
      .filter((item: IUserOption) => item.checked)
      .map((item: IUserOption) => item.itemId);
    const projectsData: string[] = data.projects
      .filter((item: IProjectOption) => item.checked)
      .map((item: IProjectOption) => item.itemId);
    const clientsData: string[] = data.clients
      .filter((item: IClientOption) => item.checked)
      .map((item: IClientOption) => item.itemId);

    let allProjects: string[] = projectsData;
    if (clientsData.length) {
      const projectsByClients: string[] = data.projects.reduce(
        (acc: string[], project: IProjectOption) => {
          return clientsData.indexOf(project.clientId) > -1
            ? [...acc, project.itemId]
            : acc;
        },
        [],
      );

      const checkedOnlyClients =
        !usersData?.length && !projectsData?.length && clientsData?.length;

      if (checkedOnlyClients && !projectsByClients?.length) {
        dispatch(fetchReportSuccess([]));
        return;
      }

      allProjects = [...new Set([...projectsByClients, ...projectsData])];
    }

    await fetchReport(dispatch, from, to, usersData, allProjects);
  };

  const handleOnTimeChange = (newTimeFrame) => {
    setTimeFrame(newTimeFrame);
    methods.setValue("timeFrame", setTimeFrame, { shouldDirty: true });
  };

  const renderReportContent = (): ReactElement => {
    if (isFetchingReport) return <LoadingAnyPage additionalClassName="mt-32" />;
    if (report.length)
      return (
        <div className="mt-4">
          <Card
            dataCy={STACKED_BAR_CHART}
            additionalCardClassName="h-auto w-full p-8 shadow-md"
          >
            <Chart timeFrame={timeFrame} />
            <Table />
          </Card>
        </div>
      );
    if (!report.length && methods.formState.isSubmitted) return <NoResults />;
    return <StartScreen timeFrame={timeFrame} />;
  };

  const selectedUsersLength = usersArrayFields.fields
    .map((item: IClientOption | Record<"id", string>, index: number) =>
      methods.watch(`${USERS}.[${index}]`),
    )
    ?.filter((item) => !!item.checked)?.length;

  const selectedClientsLength = clientsArrayFields.fields
    .map((item: IClientOption | Record<"id", string>, index: number) =>
      methods.watch(`${CLIENTS}.[${index}]`),
    )
    ?.filter((item) => !!item.checked)?.length;

  const selectedProjectsLength = projectsArrayFields.fields
    .map((item: IProjectOption | Record<"id", string>, index: number) =>
      methods.watch(`${PROJECTS}.[${index}]`),
    )
    ?.filter((item) => !!item.checked)?.length;

  const applyDisabled = () => {
    const { isDirty, dirtyFields } = methods.formState;
    const checkedFields = !(
      !selectedUsersLength &&
      !selectedClientsLength &&
      !selectedProjectsLength
    );

    return (
      !filtersSelectedListArrayFieldsLength ||
      (!(
        isDirty &&
        Object.entries(dirtyFields).some(([key]) => key !== "timeFrame")
      ) &&
        !checkedFields)
    );
  };

  const handleOnClearAllClick = () => {
    dispatch(clearReport());
    methods.reset({
      [USERS]: methods.getValues(USERS).map((item: IUserOption) => ({
        ...item,
        checked: false,
      })),
      [PROJECTS]: methods.getValues(PROJECTS).map((item: IProjectOption) => ({
        ...item,
        checked: false,
      })),
      [CLIENTS]: methods.getValues(CLIENTS).map((item: IClientOption) => ({
        ...item,
        checked: false,
      })),
      filtersSelectedList: [],
      [ALL_USER_SELECTED]: false,
      [ALL_PROJECT_SELECTED]: false,
      [ALL_CLIENT_SELECTED]: false,
    });
    setSearchUserValue("");
    setSearchClientValue("");
    setSearchProjectValue("");
    document?.getElementById("reports-last-filter")?.focus();
  };

  return (
    <section className="flex flex-col">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex gap-2.5 items-start flex-row text-neutralPrimary-20 w-full">
            <div className="flex gap-4 flex-col w-full">
              <div className="flex gap-2.5 items-center">
                <FiltersIcon className="fill-current" />
                <span>{t("reports.filters")}</span>
                <UsersFilter
                  filtersSelectedList={filtersSelectedListArrayFields}
                  searchValue={searchUserValue}
                  selectedLength={selectedUsersLength}
                  setSearchValue={setSearchUserValue}
                  usersList={usersArrayFields.fields}
                />
                <ClientsFilter
                  clientsList={clientsArrayFields.fields}
                  filtersSelectedList={filtersSelectedListArrayFields}
                  searchValue={searchClientValue}
                  selectedLength={selectedClientsLength}
                  setSearchValue={setSearchClientValue}
                />
                <ProjectsFilter
                  id="reports-last-filter"
                  filtersSelectedList={filtersSelectedListArrayFields}
                  projectsList={projectsArrayFields.fields}
                  searchValue={searchProjectValue}
                  selectedLength={selectedProjectsLength}
                  setSearchValue={setSearchProjectValue}
                />
                <Button
                  additionalClass={applyDisabled() ? "invisible" : "visible"}
                  dataCy={APPLY_BUTTON}
                  height="h-8.5"
                  type="submit"
                >
                  {t(`reports.${APPLY_BUTTON}`)}
                </Button>
                <Button
                  additionalClass={
                    filtersSelectedListArrayFieldsLength ? "block" : "hidden"
                  }
                  dataCy="clearAll"
                  height="h-8.5"
                  onClick={handleOnClearAllClick}
                  type="button"
                  variant="secondary"
                >
                  {t(`actions.clearAll`)}
                </Button>
                <div className="flex flex-col gap-2.5 items-end ml-auto">
                  <TimeTrackerTimeNavigator
                    arrowButtonType="button"
                    dataCy={TIME_NAVIGATOR}
                    enabledPresets
                    focusedElementID={focusedElementID}
                    manualChoice
                    maxDate={dayjs().endOf("year")}
                    minDate={dayjs().subtract(1, "year").startOf("year")}
                    setFocusedElementID={setFocusedElementID}
                    setTimeFrame={handleOnTimeChange}
                    timeFrame={timeFrame}
                  />
                  <DownloadFiles timeFrame={timeFrame} />
                </div>
              </div>
              <PillsRow
                arrayFields={filtersSelectedListArrayFields}
                timeFrame={timeFrame}
              />
            </div>
          </div>
        </form>
      </FormProvider>
      {renderReportContent()}
    </section>
  );
};

export default Reports;
