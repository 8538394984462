import { ReactElement } from "react";
import { Button } from "@ps/ui-components";
import { useHistory } from "react-router-dom";
import { ADD_PROJECT_BUTTON } from "../../../shared/data-cy";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { ReactComponent as EmptySectionIcon } from "../../../images/emptyTimeTracker.svg";
import { ReactComponent as AddProjectIcon } from "../../../images/timesheet/timelog/add-project-icon.svg";
import ProjectRow from "./projectRow";
import Chart from "./chart";
import {
  CircleChartColumnMapState,
  CircleChartColumnMapStateReturn,
} from "./types";

const emptyIconStyles = "w-full h-64";

const CircleChartColumn = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const history = useHistory();

  const mapState = (
    state: CircleChartColumnMapState,
  ): CircleChartColumnMapStateReturn => ({
    projectsTimeEntries: state?.timesheet?.project || {},
  });

  const { projectsTimeEntries } = useMappedStateSelector(mapState);

  const timesheetProjectIds: string[] = Object.keys(projectsTimeEntries);

  return (
    <div className="2xl:w-5/12 w-full flex flex-col gap-16 items-center">
      <Chart />
      <div className="w-full flex flex-col gap-5 2xl:h-96 h-full mb-3 overflow-auto">
        {timesheetProjectIds.length ? (
          timesheetProjectIds.map(
            (id: string): ReactElement => (
              <ProjectRow key={id} projectId={id} />
            ),
          )
        ) : (
          <div className="flex flex-col 2xl:flex-row items-center gap-8">
            <EmptySectionIcon className={emptyIconStyles} />
            <div className="flex flex-col">
              <span className="text-neutralPrimary-20 text-xl whitespace-nowrap">
                {t("noInformationHeader")}
              </span>
              <span className="text-neutralPrimary-20 text-xl whitespace-nowrap">
                {t("addProjectHeader")}
              </span>
              <Button
                dataCy={ADD_PROJECT_BUTTON}
                additionalClass="mt-8 flex flex-row gap-4 items-center"
                onClick={() => history.push("/time-tracker/timesheet")}
              >
                <AddProjectIcon />
                {t("timesheet.timelog.sections.addProject")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CircleChartColumn;
