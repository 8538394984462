/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ArchiveProjectRequest
 */
export interface ArchiveProjectRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveProjectRequest
     */
    'archive': boolean;
}
/**
 * Each RGB value needs to be between 0 and 255
 * @export
 * @interface Color
 */
export interface Color {
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'red': number;
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'green': number;
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'blue': number;
}
/**
 * 
 * @export
 * @interface ContactPerson
 */
export interface ContactPerson {
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface CreateProjectRequest
 */
export interface CreateProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'note'?: string;
    /**
     * 
     * @type {ContactPerson}
     * @memberof CreateProjectRequest
     */
    'contactPerson': ContactPerson;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'clientId'?: string;
    /**
     * 
     * @type {InvoiceDetails}
     * @memberof CreateProjectRequest
     */
    'invoiceDetails': InvoiceDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProjectRequest
     */
    'techStack': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProjectRequest
     */
    'domains': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'projectManager'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'businessDeveloper'?: string;
    /**
     * 
     * @type {Color}
     * @memberof CreateProjectRequest
     */
    'color'?: Color;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProjectRequest
     */
    'public': boolean;
}
/**
 * 
 * @export
 * @interface CreateProjectResponse
 */
export interface CreateProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectResponse
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CreateRate
 */
export interface CreateRate {
    /**
     * 
     * @type {number}
     * @memberof CreateRate
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateRate
     */
    'currency': string;
}
/**
 * 
 * @export
 * @interface CreateTeamMember
 */
export interface CreateTeamMember {
    /**
     * 
     * @type {string}
     * @memberof CreateTeamMember
     */
    'userId': string;
    /**
     * 
     * @type {StatementOfWork}
     * @memberof CreateTeamMember
     */
    'statementOfWork': StatementOfWork;
    /**
     * 
     * @type {CreateRate}
     * @memberof CreateTeamMember
     */
    'rate': CreateRate;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface GetProject
 */
export interface GetProject {
    /**
     * 
     * @type {string}
     * @memberof GetProject
     */
    'id': string;
    /**
     * 
     * @type {GetProjectDetails}
     * @memberof GetProject
     */
    'details': GetProjectDetails;
    /**
     * 
     * @type {Array<GetTeamMember>}
     * @memberof GetProject
     */
    'team': Array<GetTeamMember>;
    /**
     * 
     * @type {string}
     * @memberof GetProject
     */
    'creationDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProject
     */
    'public': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProject
     */
    'deleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProject
     */
    'archived': boolean;
    /**
     * 
     * @type {ProjectAccesses}
     * @memberof GetProject
     */
    'accesses': ProjectAccesses;
}
/**
 * 
 * @export
 * @interface GetProjectDetails
 */
export interface GetProjectDetails {
    /**
     * 
     * @type {string}
     * @memberof GetProjectDetails
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDetails
     */
    'note'?: string;
    /**
     * 
     * @type {ContactPerson}
     * @memberof GetProjectDetails
     */
    'contactPerson': ContactPerson;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDetails
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDetails
     */
    'clientName'?: string;
    /**
     * 
     * @type {InvoiceDetails}
     * @memberof GetProjectDetails
     */
    'invoiceDetails': InvoiceDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProjectDetails
     */
    'techStack': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProjectDetails
     */
    'domains': Array<string>;
    /**
     * 
     * @type {OperativePerson}
     * @memberof GetProjectDetails
     */
    'projectManager'?: OperativePerson;
    /**
     * 
     * @type {OperativePerson}
     * @memberof GetProjectDetails
     */
    'businessDeveloper'?: OperativePerson;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof GetProjectDetails
     */
    'status': ProjectStatus;
    /**
     * 
     * @type {Color}
     * @memberof GetProjectDetails
     */
    'color'?: Color;
}
/**
 * 
 * @export
 * @interface GetProjectDetailsSummary
 */
export interface GetProjectDetailsSummary {
    /**
     * 
     * @type {string}
     * @memberof GetProjectDetailsSummary
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDetailsSummary
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDetailsSummary
     */
    'clientName'?: string;
    /**
     * 
     * @type {OperativePerson}
     * @memberof GetProjectDetailsSummary
     */
    'projectManager'?: OperativePerson;
    /**
     * 
     * @type {OperativePerson}
     * @memberof GetProjectDetailsSummary
     */
    'businessDeveloper'?: OperativePerson;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof GetProjectDetailsSummary
     */
    'status': ProjectStatus;
    /**
     * 
     * @type {Color}
     * @memberof GetProjectDetailsSummary
     */
    'color'?: Color;
}
/**
 * 
 * @export
 * @interface GetProjectSummary
 */
export interface GetProjectSummary {
    /**
     * 
     * @type {string}
     * @memberof GetProjectSummary
     */
    'id': string;
    /**
     * 
     * @type {GetProjectDetailsSummary}
     * @memberof GetProjectSummary
     */
    'details': GetProjectDetailsSummary;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProjectSummary
     */
    'team': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetProjectSummary
     */
    'creationDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectSummary
     */
    'public': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectSummary
     */
    'deleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectSummary
     */
    'archived': boolean;
}
/**
 * 
 * @export
 * @interface GetRate
 */
export interface GetRate {
    /**
     * 
     * @type {string}
     * @memberof GetRate
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof GetRate
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof GetRate
     */
    'currency': string;
}
/**
 * 
 * @export
 * @interface GetTeamMember
 */
export interface GetTeamMember {
    /**
     * 
     * @type {string}
     * @memberof GetTeamMember
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetTeamMember
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTeamMember
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTeamMember
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTeamMember
     */
    'countryOfResidence'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTeamMember
     */
    'professions': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetTeamMember
     */
    'seniority'?: string;
    /**
     * 
     * @type {StatementOfWork}
     * @memberof GetTeamMember
     */
    'statementOfWork': StatementOfWork;
    /**
     * List of all past SOW entries
     * @type {Array<StatementOfWork>}
     * @memberof GetTeamMember
     */
    'archivedStatementsOfWork'?: Array<StatementOfWork>;
    /**
     * List of all the pay rates user had in this project
     * @type {Array<GetRate>}
     * @memberof GetTeamMember
     */
    'rates': Array<GetRate>;
    /**
     * User is rejected (soft deleted) if reason provided
     * @type {string}
     * @memberof GetTeamMember
     */
    'rejectionReason'?: string;
    /**
     * 
     * @type {ProjectFeedback}
     * @memberof GetTeamMember
     */
    'projectFeedback'?: ProjectFeedback;
    /**
     * 
     * @type {boolean}
     * @memberof GetTeamMember
     */
    'enabled': boolean;
}
/**
 * Will be set only for private projects
 * @export
 * @interface GetUserProjectMembershipDetails
 */
export interface GetUserProjectMembershipDetails {
    /**
     * 
     * @type {StatementOfWork}
     * @memberof GetUserProjectMembershipDetails
     */
    'statementOfWork': StatementOfWork;
    /**
     * List of all past SOW entries
     * @type {Array<StatementOfWork>}
     * @memberof GetUserProjectMembershipDetails
     */
    'archivedStatementsOfWork': Array<StatementOfWork>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserProjectMembershipDetails
     */
    'team': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserProjectMembershipDetails
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface GetUserProjectSummary
 */
export interface GetUserProjectSummary {
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectSummary
     */
    'id': string;
    /**
     * 
     * @type {GetProjectDetailsSummary}
     * @memberof GetUserProjectSummary
     */
    'details': GetProjectDetailsSummary;
    /**
     * 
     * @type {GetUserProjectMembershipDetails}
     * @memberof GetUserProjectSummary
     */
    'membershipDetails'?: GetUserProjectMembershipDetails;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectSummary
     */
    'creationDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserProjectSummary
     */
    'public': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserProjectSummary
     */
    'deleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserProjectSummary
     */
    'archived': boolean;
}
/**
 * 
 * @export
 * @interface InvoiceDetails
 */
export interface InvoiceDetails {
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetails
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetails
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetails
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetails
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetails
     */
    'taxNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetails
     */
    'accountNumber'?: string;
}
/**
 * 
 * @export
 * @interface OperativePerson
 */
export interface OperativePerson {
    /**
     * 
     * @type {string}
     * @memberof OperativePerson
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof OperativePerson
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperativePerson
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface ProjectAccesses
 */
export interface ProjectAccesses {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectAccesses
     */
    'update': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectAccesses
     */
    'archive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectAccesses
     */
    'manageTeam': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectFeedback = {
    UnhappyDev: 'unhappyDev',
    LowProfitability: 'lowProfitability',
    RiskOfClosure: 'riskOfClosure',
    OtherReasons: 'otherReasons'
} as const;

export type ProjectFeedback = typeof ProjectFeedback[keyof typeof ProjectFeedback];


/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectStatus = {
    Active: 'active',
    Finished: 'finished',
    OnHold: 'onHold'
} as const;

export type ProjectStatus = typeof ProjectStatus[keyof typeof ProjectStatus];


/**
 * 
 * @export
 * @interface StatementOfWork
 */
export interface StatementOfWork {
    /**
     * 
     * @type {string}
     * @memberof StatementOfWork
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StatementOfWork
     */
    'endDate'?: string;
    /**
     * Notice period in days
     * @type {number}
     * @memberof StatementOfWork
     */
    'noticePeriod'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatementOfWork
     */
    'engagement'?: string;
}
/**
 * 
 * @export
 * @interface UpdateProjectRequest
 */
export interface UpdateProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequest
     */
    'note'?: string;
    /**
     * 
     * @type {ContactPerson}
     * @memberof UpdateProjectRequest
     */
    'contactPerson': ContactPerson;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequest
     */
    'clientId'?: string;
    /**
     * 
     * @type {InvoiceDetails}
     * @memberof UpdateProjectRequest
     */
    'invoiceDetails': InvoiceDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProjectRequest
     */
    'techStack': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProjectRequest
     */
    'domains': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequest
     */
    'projectManager'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequest
     */
    'businessDeveloper'?: string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof UpdateProjectRequest
     */
    'status': ProjectStatus;
    /**
     * 
     * @type {Color}
     * @memberof UpdateProjectRequest
     */
    'color'?: Color;
}
/**
 * 
 * @export
 * @interface UpdateTeamMember
 */
export interface UpdateTeamMember {
    /**
     * 
     * @type {StatementOfWork}
     * @memberof UpdateTeamMember
     */
    'statementOfWork': StatementOfWork;
    /**
     * 
     * @type {CreateRate}
     * @memberof UpdateTeamMember
     */
    'rate': CreateRate;
    /**
     * 
     * @type {ProjectFeedback}
     * @memberof UpdateTeamMember
     */
    'projectFeedback'?: ProjectFeedback;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new Project
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject: async (createProjectRequest: CreateProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProjectRequest' is not null or undefined
            assertParamExists('addProject', 'createProjectRequest', createProjectRequest)
            const localVarPath = `/project/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add Project team member
         * @param {string} id 
         * @param {CreateTeamMember} createTeamMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectTeamMember: async (id: string, createTeamMember: CreateTeamMember, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addProjectTeamMember', 'id', id)
            // verify required parameter 'createTeamMember' is not null or undefined
            assertParamExists('addProjectTeamMember', 'createTeamMember', createTeamMember)
            const localVarPath = `/project/projects/{id}/team`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTeamMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Soft Delete Project
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProject', 'id', id)
            const localVarPath = `/project/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Soft Delete Project team member
         * @param {string} id 
         * @param {string} userId 
         * @param {string} xRejectReason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectTeamMember: async (id: string, userId: string, xRejectReason: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjectTeamMember', 'id', id)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteProjectTeamMember', 'userId', userId)
            // verify required parameter 'xRejectReason' is not null or undefined
            assertParamExists('deleteProjectTeamMember', 'xRejectReason', xRejectReason)
            const localVarPath = `/project/projects/{id}/team/{userId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xRejectReason != null) {
                localVarHeaderParameter['x-reject-reason'] = String(xRejectReason);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Projects in which the user is a team member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUserProjectSummaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/me/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of user\'s pinned projects to copy for the next week
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPinnedProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/pinned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Project by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProject', 'id', id)
            const localVarPath = `/project/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Projects Summaries
         * @param {string} [clientId] ClientId assigned to the projects
         * @param {boolean} [deleted] Deleted flag
         * @param {boolean} [archived] Archived flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsSummaries: async (clientId?: string, deleted?: boolean, archived?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pin project for the current user
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinProject: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('pinProject', 'projectId', projectId)
            const localVarPath = `/project/projects/{projectId}/pin`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set Archived Flag of a Project
         * @param {string} projectId 
         * @param {ArchiveProjectRequest} archiveProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArchived: async (projectId: string, archiveProjectRequest: ArchiveProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('setArchived', 'projectId', projectId)
            // verify required parameter 'archiveProjectRequest' is not null or undefined
            assertParamExists('setArchived', 'archiveProjectRequest', archiveProjectRequest)
            const localVarPath = `/project/projects/{projectId}/archive`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archiveProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unpin project for the current user
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpinProject: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('unpinProject', 'projectId', projectId)
            const localVarPath = `/project/projects/{projectId}/pin`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Project
         * @param {string} id 
         * @param {UpdateProjectRequest} updateProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (id: string, updateProjectRequest: UpdateProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProject', 'id', id)
            // verify required parameter 'updateProjectRequest' is not null or undefined
            assertParamExists('updateProject', 'updateProjectRequest', updateProjectRequest)
            const localVarPath = `/project/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Project team member
         * @param {string} id 
         * @param {string} userId 
         * @param {UpdateTeamMember} updateTeamMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectTeamMember: async (id: string, userId: string, updateTeamMember: UpdateTeamMember, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProjectTeamMember', 'id', id)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateProjectTeamMember', 'userId', userId)
            // verify required parameter 'updateTeamMember' is not null or undefined
            assertParamExists('updateProjectTeamMember', 'updateTeamMember', updateTeamMember)
            const localVarPath = `/project/projects/{id}/team/{userId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Create new Project
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProject(createProjectRequest: CreateProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProject(createProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add Project team member
         * @param {string} id 
         * @param {CreateTeamMember} createTeamMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectTeamMember(id: string, createTeamMember: CreateTeamMember, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectTeamMember(id, createTeamMember, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Soft Delete Project
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Soft Delete Project team member
         * @param {string} id 
         * @param {string} userId 
         * @param {string} xRejectReason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectTeamMember(id: string, userId: string, xRejectReason: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectTeamMember(id, userId, xRejectReason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Projects in which the user is a team member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthedUserProjectSummaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetUserProjectSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthedUserProjectSummaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list of user\'s pinned projects to copy for the next week
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPinnedProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPinnedProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Project by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Projects Summaries
         * @param {string} [clientId] ClientId assigned to the projects
         * @param {boolean} [deleted] Deleted flag
         * @param {boolean} [archived] Archived flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsSummaries(clientId?: string, deleted?: boolean, archived?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetProjectSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsSummaries(clientId, deleted, archived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Pin project for the current user
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinProject(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pinProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set Archived Flag of a Project
         * @param {string} projectId 
         * @param {ArchiveProjectRequest} archiveProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setArchived(projectId: string, archiveProjectRequest: ArchiveProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setArchived(projectId, archiveProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unpin project for the current user
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpinProject(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpinProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Project
         * @param {string} id 
         * @param {UpdateProjectRequest} updateProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(id: string, updateProjectRequest: UpdateProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(id, updateProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Project team member
         * @param {string} id 
         * @param {string} userId 
         * @param {UpdateTeamMember} updateTeamMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectTeamMember(id: string, userId: string, updateTeamMember: UpdateTeamMember, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectTeamMember(id, userId, updateTeamMember, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Create new Project
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject(createProjectRequest: CreateProjectRequest, options?: any): AxiosPromise<CreateProjectResponse> {
            return localVarFp.addProject(createProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Add Project team member
         * @param {string} id 
         * @param {CreateTeamMember} createTeamMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectTeamMember(id: string, createTeamMember: CreateTeamMember, options?: any): AxiosPromise<void> {
            return localVarFp.addProjectTeamMember(id, createTeamMember, options).then((request) => request(axios, basePath));
        },
        /**
         * Soft Delete Project
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Soft Delete Project team member
         * @param {string} id 
         * @param {string} userId 
         * @param {string} xRejectReason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectTeamMember(id: string, userId: string, xRejectReason: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectTeamMember(id, userId, xRejectReason, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Projects in which the user is a team member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUserProjectSummaries(options?: any): AxiosPromise<Array<GetUserProjectSummary>> {
            return localVarFp.getAuthedUserProjectSummaries(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of user\'s pinned projects to copy for the next week
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPinnedProjects(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getPinnedProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Project by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(id: string, options?: any): AxiosPromise<GetProject> {
            return localVarFp.getProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Projects Summaries
         * @param {string} [clientId] ClientId assigned to the projects
         * @param {boolean} [deleted] Deleted flag
         * @param {boolean} [archived] Archived flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsSummaries(clientId?: string, deleted?: boolean, archived?: boolean, options?: any): AxiosPromise<Array<GetProjectSummary>> {
            return localVarFp.getProjectsSummaries(clientId, deleted, archived, options).then((request) => request(axios, basePath));
        },
        /**
         * Pin project for the current user
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinProject(projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.pinProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Set Archived Flag of a Project
         * @param {string} projectId 
         * @param {ArchiveProjectRequest} archiveProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArchived(projectId: string, archiveProjectRequest: ArchiveProjectRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setArchived(projectId, archiveProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Unpin project for the current user
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpinProject(projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unpinProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Project
         * @param {string} id 
         * @param {UpdateProjectRequest} updateProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(id: string, updateProjectRequest: UpdateProjectRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateProject(id, updateProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Project team member
         * @param {string} id 
         * @param {string} userId 
         * @param {UpdateTeamMember} updateTeamMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectTeamMember(id: string, userId: string, updateTeamMember: UpdateTeamMember, options?: any): AxiosPromise<void> {
            return localVarFp.updateProjectTeamMember(id, userId, updateTeamMember, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Create new Project
     * @param {CreateProjectRequest} createProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addProject(createProjectRequest: CreateProjectRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addProject(createProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add Project team member
     * @param {string} id 
     * @param {CreateTeamMember} createTeamMember 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addProjectTeamMember(id: string, createTeamMember: CreateTeamMember, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addProjectTeamMember(id, createTeamMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Soft Delete Project
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProject(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Soft Delete Project team member
     * @param {string} id 
     * @param {string} userId 
     * @param {string} xRejectReason 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProjectTeamMember(id: string, userId: string, xRejectReason: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteProjectTeamMember(id, userId, xRejectReason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Projects in which the user is a team member
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuthedUserProjectSummaries(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuthedUserProjectSummaries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of user\'s pinned projects to copy for the next week
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPinnedProjects(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPinnedProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Project by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProject(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Projects Summaries
     * @param {string} [clientId] ClientId assigned to the projects
     * @param {boolean} [deleted] Deleted flag
     * @param {boolean} [archived] Archived flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectsSummaries(clientId?: string, deleted?: boolean, archived?: boolean, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProjectsSummaries(clientId, deleted, archived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pin project for the current user
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pinProject(projectId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).pinProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set Archived Flag of a Project
     * @param {string} projectId 
     * @param {ArchiveProjectRequest} archiveProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setArchived(projectId: string, archiveProjectRequest: ArchiveProjectRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setArchived(projectId, archiveProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unpin project for the current user
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unpinProject(projectId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).unpinProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Project
     * @param {string} id 
     * @param {UpdateProjectRequest} updateProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProject(id: string, updateProjectRequest: UpdateProjectRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateProject(id, updateProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Project team member
     * @param {string} id 
     * @param {string} userId 
     * @param {UpdateTeamMember} updateTeamMember 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProjectTeamMember(id: string, userId: string, updateTeamMember: UpdateTeamMember, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateProjectTeamMember(id, userId, updateTeamMember, options).then((request) => request(this.axios, this.basePath));
    }
}


