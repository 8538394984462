import { AnyAction } from "redux";
import ActionTypes from "store/actionTypes";

const userReducer = (
  state = {},
  action: AnyAction,
): Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.LOGIN_START:
      return state;
    case ActionTypes.LOGIN_SUCCESS:
      return { ...state, ...action.payload };
    case ActionTypes.LOGIN_ERROR:
      return { user: {} };
    case ActionTypes.LOGOUT_START:
      return state;
    case ActionTypes.LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
};

export default userReducer;
