import { motion } from "framer-motion";
import { ReactElement } from "react";
import { classJoin } from "@ps/utils";

const baseStyle = classJoin.template`
absolute max-w-full max-h-full
overflow-hidden pl-40
`;

const Background = (): ReactElement => {
  return (
    <>
      <div className={classJoin("top-0 right-0 h-full", baseStyle)}>
        <motion.div
          animate={{
            scale: [1.2, 1, 1.2],
            rotate: [0, 2, 0],
          }}
          transition={{
            duration: 15,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          }}
        >
          <svg
            width="395"
            height="274"
            viewBox="0 0 395 274"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d={`M49.4172 81.1703C15.0496 71.0843 0 42.8436 0 0H395V267.446C322.2 286.884 307.371 260.743 
                290.547 219.855C272.674 176.421 249.186 139.42 216.238 119.312C161.49 85.8984 93.5433 94.1202 49.4172 81.1703Z`}
              fill="white"
              fillOpacity="0.1"
            />
          </svg>
        </motion.div>
      </div>
      <div className={classJoin("top-0 right-0 h-full", baseStyle)}>
        <motion.div
          animate={{
            scale: [1, 1.2, 1],
            rotate: [0, 1, 0],
          }}
          transition={{
            duration: 15,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          }}
        >
          <svg
            width="260"
            height="274"
            viewBox="0 0 260 274"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d={`M0.719238 125.12C0.719223 63.7449 17.0397 22.9201 27.7737 0H260V228.953C230.001 254.14 181.136 280.587 
                122.283 271.702C51.7756 261.059 0.719256 192.374 0.719238 125.12Z`}
              fill="white"
              fillOpacity="0.1"
            />
          </svg>
        </motion.div>
      </div>
      <div className={classJoin("bottom-0 left-0", baseStyle)}>
        <motion.div
          animate={{
            scale: [1, 0.9, 1],
            rotate: [0, 6, 0],
            opacity: [1, 0.7, 1],
          }}
          transition={{
            duration: 15,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          }}
        >
          <svg
            width="843"
            height="341"
            viewBox="0 0 1343 341"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="40.8852"
              cy="324.637"
              r="15.0659"
              stroke="white"
              strokeOpacity="0.3"
              strokeWidth="5"
            />
            <circle
              cx="313.127"
              cy="294.685"
              r="9.12704"
              stroke="white"
              strokeOpacity="0.3"
              strokeWidth="5"
            />
            <circle
              cx="613.367"
              cy="133.576"
              r="9.12704"
              stroke="white"
              strokeOpacity="0.3"
              strokeWidth="5"
            />
            <circle
              cx="10.1681"
              cy="60.8731"
              r="9.12704"
              stroke="white"
              strokeOpacity="0.3"
              strokeWidth="2"
            />
            <circle
              cx="1332"
              cy="10.4559"
              r="9.12704"
              stroke="white"
              strokeOpacity="0.3"
              strokeWidth="5"
            />
            <circle
              cx="470.642"
              cy="198.317"
              r="14.1294"
              stroke="white"
              strokeOpacity="0.3"
              strokeWidth="5"
            />
            <circle
              cx="623.494"
              cy="276.478"
              r="7.08036"
              stroke="white"
              strokeOpacity="0.3"
              strokeWidth="5"
            />
            <circle
              cx="842.363"
              cy="316.557"
              r="7.08036"
              stroke="white"
              strokeOpacity="0.3"
              strokeWidth="5"
            />
          </svg>
        </motion.div>
      </div>
    </>
  );
};

export default Background;
