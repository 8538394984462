import { ReactElement } from "react";
import { Textarea, Label, InfoTooltip } from "@ps/ui-components";
import { useFormContext } from "react-hook-form";
import { MAIN_DETAILS_SHORT_BIO } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import { SHORT_INFORMATION } from "../constants";

const MAX_LENGTH = 4000;
const TRANSLATION_PATH = "profile.overview.bio";

const Bio = (): ReactElement => {
  const { register, formState, getFieldState, watch } = useFormContext();
  const { t } = useTranslationWithNamespace();
  const { error } = getFieldState(SHORT_INFORMATION, formState);

  return (
    <div className="w-full h-full">
      <Label
        dataCy={SHORT_INFORMATION}
        text={
          <div className="flex items-center gap-2">
            <span>{t(`${TRANSLATION_PATH}.${SHORT_INFORMATION}`)}</span>
            <InfoTooltip
              tooltipContent={t(`${TRANSLATION_PATH}.tooltip`)}
              dataCy={SHORT_INFORMATION}
            />
          </div>
        }
      >
        <div>
          <Textarea
            autoFocus
            dataCy={MAIN_DETAILS_SHORT_BIO}
            error={!!error}
            maxLength={MAX_LENGTH}
            message={error?.message}
            placeholder={t(`${TRANSLATION_PATH}.placeholder`)}
            resize
            value={watch(SHORT_INFORMATION) || ""}
            {...register(SHORT_INFORMATION, {
              validate: (value) => {
                if (value && !value.trim().length)
                  return `${t(`${TRANSLATION_PATH}.cantBeBlank`)}`;
                if (value && value.length > MAX_LENGTH)
                  return `${t("profile.errors.cantBeMoreChars", {
                    number: MAX_LENGTH,
                  })}`;
                return true;
              },
            })}
          />
        </div>
      </Label>
    </div>
  );
};

export default Bio;
