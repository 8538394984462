import { useState, ReactElement } from "react";
import dayjs from "dayjs";
import {
  Datepicker2,
  Input,
  Label,
  SearchSelectPicker,
  Textarea,
} from "@ps/ui-components";
import { prepareTechStackDictionaryValues } from "@ps/utils";
import { motion } from "framer-motion";
import { Controller, useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { FormSectionProps } from "../../education/types";
import { EXPERIENCE_ACCOMPLISHMENT_EDIT } from "../../../../../../shared/data-cy";
import {
  AWARD_NAME,
  AWARDS,
  DATE,
  DESCRIPTION,
  REWARDER,
  PLACEHOLDER,
} from "../constants";
import { TECH_STACK } from "../../projects/constants";
import { checkHasEmptyFields } from "../../../helpers";
import { ITechStackProposal } from "../../../types";
import styles from "../../styles.module.css";
import { FULL_TECH_SEARCH_MAX } from "../../../../../../shared/constants";

const MAX_LENGTH_DESCRIPTION = 4000;
const MAX_LENGTH_NAME = 100;

const AwardsForm = ({
  fullTextSearch,
  index,
  sourcePath = "awards",
  technologyDict,
}: FormSectionProps): ReactElement => {
  const { control, getValues, watch, register, getFieldState, formState } =
    useFormContext();
  const { t } = useTranslationWithNamespace();
  const [searchedProposals, setSearchedProposals] = useState<
    ITechStackProposal[] | []
  >([]);

  const BASE_PATH = `${EXPERIENCE_ACCOMPLISHMENT_EDIT}_${AWARDS}`;

  const translate = (field: string, isRequired = true): string =>
    `${t(`profile.experience.cards.awards.${field}`)}${isRequired ? "*" : ""}`;

  const getFormFieldName = (fieldName: string): string =>
    `${sourcePath}.${index}.${fieldName}`;

  const fieldAwardName = getFieldState(getFormFieldName(AWARD_NAME), formState);
  const fieldRewarder = getFieldState(getFormFieldName(REWARDER), formState);
  const fieldDescription = getFieldState(
    getFormFieldName(DESCRIPTION),
    formState,
  );

  const hasEmptyFields = checkHasEmptyFields(
    getValues([AWARD_NAME, REWARDER, DATE, TECH_STACK]),
  );

  const errorEmptyMessage = `${t("profile.errors.cantBeEmpty")}`;
  const errorBlankMessage = `${t("profile.errors.cantBeBlank")}`;
  const errorMaxLengthMessage = `${t("profile.errors.cantBeMoreChars", {
    number: MAX_LENGTH_NAME,
  })}`;

  const isEmptyOrBlank = (value: string): string | boolean => {
    if (!value?.length && hasEmptyFields) return errorEmptyMessage;
    if (!value?.trim().length) return errorBlankMessage;
    if (value?.length > MAX_LENGTH_NAME) return errorMaxLengthMessage;
    return true;
  };

  return (
    <div className="flex flex-col gap-y-5" key={uid(`${AWARDS}-${index}`)}>
      <div className="flex flex-row gap-x-5">
        <Label
          dataCy={`${BASE_PATH}_${AWARD_NAME}`}
          text={translate(AWARD_NAME)}
        >
          <Input
            dataCy={`${BASE_PATH}_${AWARD_NAME}`}
            width="w-full"
            autoFocus
            value={watch(getFormFieldName(AWARD_NAME)) || ""}
            error={!!fieldAwardName?.error}
            message={fieldAwardName?.error?.message || ""}
            placeholder={translate(`${AWARD_NAME}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(AWARD_NAME), {
              validate: (value) => isEmptyOrBlank(value),
            })}
          />
        </Label>
        <Label dataCy={`${BASE_PATH}_${REWARDER}`} text={translate(REWARDER)}>
          <Input
            dataCy={`${BASE_PATH}_${REWARDER}`}
            width="w-full"
            value={watch(getFormFieldName(REWARDER)) || ""}
            error={!!fieldRewarder?.error}
            message={fieldRewarder?.error?.message || ""}
            placeholder={translate(`${REWARDER}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(REWARDER), {
              validate: (value) => isEmptyOrBlank(value),
            })}
          />
        </Label>
      </div>
      <div className="flex flex-row gap-x-5 w-full">
        <div className="w-full">
          <Label
            dataCy={`${BASE_PATH}_${DESCRIPTION}`}
            text={translate(DESCRIPTION, false)}
          >
            <Textarea
              dataCy={`${BASE_PATH}_${DESCRIPTION}`}
              width="w-full"
              maxLength={MAX_LENGTH_DESCRIPTION}
              value={watch(getFormFieldName(DESCRIPTION)) || ""}
              error={!!fieldDescription?.error}
              message={fieldDescription?.error?.message || ""}
              placeholder={translate(`${DESCRIPTION}${PLACEHOLDER}`, false)}
              {...register(getFormFieldName(DESCRIPTION), {
                validate: (value) =>
                  value && value.length > MAX_LENGTH_DESCRIPTION
                    ? `${t("profile.errors.cantBeMoreChars", {
                        number: MAX_LENGTH_DESCRIPTION,
                      })}`
                    : true,
              })}
            />
          </Label>
        </div>
        <Label text={translate(DATE)} dataCy={`${BASE_PATH}_${DATE}`}>
          <Controller
            name={getFormFieldName(DATE)}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Datepicker2
                dataCy={`${BASE_PATH}_${DATE}`}
                error={error?.message}
                onChange={onChange}
                selected={value ? dayjs(value) : null}
                dateFormat="MM/YYYY"
                timeView={["month", "year"]}
                type="month"
              />
            )}
            rules={{
              validate: (value) => {
                if (!value && hasEmptyFields) return errorEmptyMessage;
                if (value && dayjs().isBefore(value))
                  return `${t("profile.errors.noFutureDate")}`;
                return true;
              },
            }}
          />
        </Label>
      </div>
      <div className="w-1/2">
        <motion.div>
          <Label
            text={translate(TECH_STACK)}
            dataCy={`${BASE_PATH}_${TECH_STACK}`}
          >
            <Controller
              name={getFormFieldName(TECH_STACK)}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SearchSelectPicker
                  dataCy={TECH_STACK}
                  additionalTagsWrapClassName={styles.techWrapperOther}
                  defaultSelected={prepareTechStackDictionaryValues(
                    value,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    technologyDict,
                  )}
                  onTagSelected={onChange}
                  proposalTags={searchedProposals}
                  onInputChange={(searchValue) => {
                    const result = fullTextSearch(
                      searchValue,
                      FULL_TECH_SEARCH_MAX,
                    );
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setSearchedProposals(result);
                  }}
                  error={!!error}
                  message={error?.message}
                />
              )}
              rules={{
                validate: (value) =>
                  !value.length && hasEmptyFields ? errorEmptyMessage : true,
              }}
            />
          </Label>
        </motion.div>
      </div>
    </div>
  );
};

export default AwardsForm;
