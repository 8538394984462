import { ReactElement, useState, useEffect } from "react";
import dayjs from "dayjs";
import { Scrollbars } from "react-custom-scrollbars-2";
import { EmptyList, ScrollableWrapper } from "@ps/ui-components";
import { divideArrayByField, sortArray } from "@ps/utils";
import {
  useMappedStateSelector,
  useSessionStorage,
  useTranslationWithNamespace,
} from "../../../hooks";
import ProspectRow from "./prospectRow";
import { ReactComponent as EmptyImage } from "../../../images/prospect/emptyProspectsList.svg";
import { SingleProspectListModel } from "../../models";
import { filterProspectsByFields, filtersToPills } from "../../utils";
import SortBar from "./sortBar";
import { SessionStorage } from "../../../shared";
import SearchBar from "./searchBar";
import {
  SorterParametersProps,
  IMappedProspectListElement,
  ProspectsListMapStateReturn,
  ProspectsListMapState,
  ProspectsListWrapperProps,
  IFilters,
  FilterItem,
  PillData,
  ProspectCheckers,
} from "./types";
import {
  ACTIVE,
  ARCHIVED,
  ASC,
  CREATION_DATE,
  PROSPECTS,
  initialCheckersState,
  initialFiltersState,
} from "./constants";
import PillsRow from "./pillsRow";
import {
  SESSION_SORTERS,
  initialSessionSortersState,
} from "../../../shared/constants";

const ProspectsListWrapper = ({
  prospects,
  clientId,
}: ProspectsListWrapperProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const mapState = ({
    clients,
    users,
  }: ProspectsListMapState): ProspectsListMapStateReturn => ({
    clients: clients?.clientsList || [],
    users,
  });

  const { clients, users } = useMappedStateSelector(mapState);

  const mappedProspectsList: IMappedProspectListElement[] = prospects?.map(
    (item: SingleProspectListModel): IMappedProspectListElement => ({
      ...item,
      daysPassed: item?.creationDate
        ? dayjs()
            .add(1, "day")
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .diff(
              item.creationDate.hour(0).minute(0).second(0).millisecond(0),
              "day",
            )
        : null,
      details: {
        ...item.details,
        projectManager:
          `${item.details.pm?.firstName} ${item.details.pm?.lastName}` || null,
        businessDeveloper:
          `${item.details.bd?.firstName} ${item.details.bd?.lastName}` || null,
      },
    }),
  );

  const [sortedProspectsList, setSortedProspectsList] =
    useState<IMappedProspectListElement[]>(mappedProspectsList);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filters, setFilters] = useState<IFilters>(initialFiltersState);
  const [pills, setPills] = useState<PillData[]>([]);
  const [sorterParameters, setSorterParameters] =
    useState<SorterParametersProps>({
      order: ASC,
      name: CREATION_DATE,
    });

  const [storageSorter, setStorageSorter] = useSessionStorage<SessionStorage>(
    SESSION_SORTERS,
    initialSessionSortersState,
  );

  const [prospectsCheckers, setProspectsChechers] =
    useState<ProspectCheckers>(initialCheckersState);

  const { negatives: onlyActiveProspects, positives: onlyArchivedProspects } =
    divideArrayByField(mappedProspectsList, "archived");

  const statusFilters = {
    [ACTIVE]: (prospect: IMappedProspectListElement) => !prospect.archived,
    [ARCHIVED]: (prospect: IMappedProspectListElement) => prospect.archived,
  };

  const filterProspectsByStatus = (
    prospectsList: IMappedProspectListElement[],
    prospectsChechers: ProspectCheckers,
  ): IMappedProspectListElement[] =>
    prospectsList.filter((p: IMappedProspectListElement) =>
      Object.entries(prospectsChechers)
        .filter(([, value]) => value)
        .map(([key]) => statusFilters[key])
        .some((f: (prospect: IMappedProspectListElement) => void) => f(p)),
    );

  const prepareProperProspectsList = (
    search: string,
    prospectsFilters: IFilters,
  ): void => {
    const { prospects: prospectsSorter } = storageSorter.sessionSorters;
    const properSorter: {
      name: string;
      order: string;
      nestedFieldName?: string;
    } = prospectsSorter?.name?.length ? prospectsSorter : sorterParameters;

    const resultByStatus = filterProspectsByStatus(
      mappedProspectsList,
      prospectsCheckers,
    );

    const resultByFilter = filterProspectsByFields(
      resultByStatus,
      search,
      prospectsFilters,
    );

    const resultBySorter = sortArray(
      resultByFilter,
      properSorter?.name as keyof IMappedProspectListElement,
      properSorter?.order,
      properSorter?.nestedFieldName as keyof IMappedProspectListElement,
    );
    setSortedProspectsList(resultBySorter);
  };

  const handleOnClickSorter = (
    fieldName: string,
    order: string,
    isNestedField?: boolean,
  ): void => {
    const nestedFieldName = isNestedField ? "details" : undefined;

    setStorageSorter((prevData) => ({
      ...prevData,
      sessionSorters: {
        ...prevData.sessionSorters,
        [PROSPECTS]: {
          name: fieldName,
          order,
          nestedFieldName,
        },
      },
    }));

    setSorterParameters({ name: fieldName, order });
  };

  const handleOnFilterClick = (
    isChecked: boolean,
    filterItem: FilterItem,
    filterName: string,
  ): void => {
    const prevFilters = JSON.parse(JSON.stringify(filters));

    const newFiltersState: IFilters = isChecked
      ? {
          ...prevFilters,
          [filterName]: prevFilters[filterName]?.filter(
            (item: FilterItem) => item.id !== filterItem.id,
          ),
        }
      : {
          ...prevFilters,
          [filterName]: [...prevFilters[filterName], filterItem],
        };

    setFilters(newFiltersState);
    setPills(filtersToPills(newFiltersState));
    prepareProperProspectsList(searchValue, newFiltersState);
  };

  useEffect(() => {
    prepareProperProspectsList(searchValue, filters);
  }, [
    searchValue,
    prospects,
    clients,
    users,
    prospectsCheckers,
    sorterParameters.name,
    sorterParameters.order,
  ]);

  const handleOnClearFilters = (): void => {
    setFilters(initialFiltersState);
    setPills([]);
    prepareProperProspectsList(searchValue, initialFiltersState);
  };

  return (
    <div className="flex flex-col gap-y-8 w-full h-full">
      <SearchBar
        setSearchValue={setSearchValue}
        prospectsListLength={{
          archived: onlyArchivedProspects?.length,
          active: onlyActiveProspects?.length,
        }}
        clientId={clientId}
        sorterParameters={
          storageSorter?.sessionSorters?.prospects?.name?.length
            ? storageSorter?.sessionSorters?.prospects
            : sorterParameters
        }
        onSorterClick={handleOnClickSorter}
        prospectsCheckers={prospectsCheckers}
        setProspectsCheckers={setProspectsChechers}
        clearFilters={handleOnClearFilters}
        disabledClearFilters={
          !Object.values(filters).some((item: string[]) => item?.length)
        }
      />
      {pills.length ? (
        <PillsRow pills={pills} onRemovePillClick={handleOnFilterClick} />
      ) : (
        <></>
      )}
      <ScrollableWrapper>
        <div className="w-full h-full">
          {sortedProspectsList?.length ? (
            <SortBar
              clientId={clientId}
              prospects={filterProspectsByStatus(
                mappedProspectsList,
                prospectsCheckers,
              )}
              updateFilters={handleOnFilterClick}
              filters={filters}
            />
          ) : (
            <></>
          )}
          {sortedProspectsList?.length ? (
            <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
              <div className="flex flex-col w-full h-full">
                {sortedProspectsList.map(
                  (prospect: IMappedProspectListElement) => (
                    <ProspectRow key={prospect.id} prospect={prospect} />
                  ),
                )}
              </div>
            </Scrollbars>
          ) : (
            <div className="w-full flex items-center justify-center mt-16">
              <EmptyList
                searchValue={searchValue}
                text={t(`projects.prospect.noProspectsTitle`)}
                Image={<EmptyImage />}
              />
            </div>
          )}
        </div>
      </ScrollableWrapper>
    </div>
  );
};

export default ProspectsListWrapper;
