export const BASE_TRANSLATION_PATH = "finance.holidays";
export const HOLIDAYS = "holidays";
export const HOLIDAYS_TEMPLATE = "holidaysTemplate";
export const SEARCH = "search";
export const NAME = "name";
export const PROFESSIONS = "professions";
export const TEMPLATE_NAME = "templateName";
export const USED_MAIN_HOLIDAYS = "usedMainHolidays";
export const STATUS = "status";

export const selectedSorterInitialState = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  order: "asc" as "asc",
  name: TEMPLATE_NAME,
};

export const selectedFiltersInitialState = {
  [TEMPLATE_NAME]: [],
};
