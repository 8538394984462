export const BIO = "bio";
export const BASIC_INFO = "basicInfo";
export const AVAILABILITY = "availability";
export const WORK = "work";
export const LANGUAGES = "languages";
export const FIRST_NAME = "firstName";
export const SECOND_NAME = "secondName";
export const LAST_NAME = "lastName";
export const SENIORITY = "seniority";
export const DATE_OF_BIRTH = "dateOfBirth";
export const PROFESSIONS = "professions";
export const MAIN_PROFESSION = "mainProfession";
export const EXPIRATION_DATE = "expirationDate";
export const EXPERIENCE_AMOUNT = "experienceAmount";
export const PASSPORTS = "passports";
export const VISAS = "visas";
export const CAREER_SPAN = "careerSpan";
export const COUNTRY = "country";
export const RESIDENCE = "residence";
export const NATIONALITY = "nationality";
export const START_DATE = "startDate";
export const ZONE = "zone";
export const WORKING_HOURS = "workingHours";
export const COMFORTABLE = "comfortable";
export const POSSIBLE = "possible";
export const COMFORTABLE_HOURS = "comfortableHours";
export const POSSIBLE_HOURS = "possibleHours";
export const WORKING_HOURS_ZONE = `workingHours_${ZONE}`;
export const ROLE = "role";
export const SHORT_INFORMATION = "shortInformation";
export const LANGUAGE = "language";
export const LEVEL = "level";
export const IMAGE = "image";
export const NOTE = "note";
export const TECHNOLOGIES = "technologies";
export const SKILLS = "skills";
export const GOALS = "goals";
export const GOALS_PREVIOUS_YEAR = "goalsPreviousYear";
export const GOALS_CURRENT_YEAR = "goalsCurrentYear";
export const TOOLTIP_CONTENT = "tooltipContent";
export const ROLES = "roles";
export const GAP_MONTHS = "gapMonths";
export const CURRENT_YEAR = new Date().getFullYear().toString();
export const PREVIOUS_YEAR = (new Date().getFullYear() - 1).toString();

export const languageLevelsDict = [
  "Elementary",
  "Limited",
  "Professional",
  "FullyProfessional",
  "Native",
];

export const DETAILS_SPECIALIST_ITEMS = [
  {
    title: BIO,
    items: ["shortInformation"],
  },
  {
    title: BASIC_INFO,
    items: [
      FIRST_NAME,
      SECOND_NAME,
      DATE_OF_BIRTH,
      MAIN_PROFESSION,
      PROFESSIONS,
      SENIORITY,
      ROLE,
      EXPERIENCE_AMOUNT,
    ],
  },
  {
    title: AVAILABILITY,
    items: [PASSPORTS, VISAS, RESIDENCE, NATIONALITY],
  },
  {
    title: WORK,
    items: [START_DATE, COMFORTABLE_HOURS, POSSIBLE_HOURS],
  },
  {
    title: LANGUAGES,
    items: [],
  },
  {
    title: GOALS_CURRENT_YEAR,
    items: [NOTE, SKILLS],
  },
  {
    title: GOALS_PREVIOUS_YEAR,
    items: [NOTE, SKILLS],
  },
];

export const DETAILS_OPERATIVE_ITEMS = [
  {
    title: BIO,
    items: ["shortInformation"],
  },
  {
    title: BASIC_INFO,
    items: [FIRST_NAME, SECOND_NAME, PROFESSIONS],
  },
  {
    title: WORK,
    items: [START_DATE, COMFORTABLE_HOURS, POSSIBLE_HOURS],
  },
  {
    title: GOALS_CURRENT_YEAR,
    items: [NOTE, TECHNOLOGIES],
  },
  {
    title: GOALS_PREVIOUS_YEAR,
    items: [NOTE, TECHNOLOGIES],
  },
];

export const availableLanguages = [
  "af",
  "sq",
  "am",
  "ar",
  "hy",
  "az",
  "eu",
  "be",
  "bn",
  "bs",
  "bg",
  "ca",
  "co",
  "hr",
  "cs",
  "da",
  "nl",
  "en",
  "eo",
  "et",
  "fi",
  "fr",
  "fy",
  "gl",
  "ka",
  "de",
  "el",
  "gu",
  "ht",
  "ha",
  "he",
  "hi",
  "hu",
  "is",
  "ig",
  "id",
  "ga",
  "it",
  "ja",
  "jv",
  "kn",
  "kk",
  "km",
  "rw",
  "ko",
  "ku",
  "ky",
  "lo",
  "lv",
  "lt",
  "lb",
  "mk",
  "mg",
  "ms",
  "ml",
  "mt",
  "mi",
  "mr",
  "mn",
  "my",
  "ne",
  "no",
  "ny",
  "or",
  "ps",
  "fa",
  "pl",
  "pt",
  "pa",
  "ro",
  "ru",
  "sm",
  "gd",
  "sr",
  "st",
  "sn",
  "sd",
  "si",
  "sk",
  "sl",
  "so",
  "es",
  "su",
  "sw",
  "sv",
  "tl",
  "tg",
  "ta",
  "tt",
  "te",
  "th",
  "tr",
  "tk",
  "uk",
  "ur",
  "ug",
  "uz",
  "vi",
  "cy",
  "xh",
  "yi",
  "yo",
  "zu",
];
