import {
  ChangeEvent,
  ClipboardEvent,
  forwardRef,
  KeyboardEvent,
  ReactElement,
  useState,
} from "react";
import { classJoin } from "@ps/utils";
import { TEXTAREA_PREFIX } from "../../shared/data-cy";
import styles from "./styles.module.css";
import { TextareaProps } from "./types";

const baseStyle = classJoin.template`
rounded-md h-full w-full
bg-neutralPrimary-100 text-neutralPrimary-20
border focus:outline-none p-2
`;

const Textarea = forwardRef<HTMLInputElement, TextareaProps>(
  (
    {
      dataCy,
      defaultValue,
      disabled,
      height = "h-full",
      maxLength,
      name,
      onChange,
      placeholder,
      readOnly = false,
      value,
      width = "w-full",
      message,
      error,
      resize = false,
      additionalClassName,
      ...props
    },
    ref,
  ): ReactElement => {
    const [currentValue, setCurrentValue] = useState(
      value || defaultValue || "",
    );

    const handleOnChange = (
      event:
        | ChangeEvent<HTMLTextAreaElement>
        | KeyboardEvent<HTMLTextAreaElement>
        | ClipboardEvent<HTMLTextAreaElement>,
    ) => {
      if (onChange) {
        // @ts-ignore
        onChange(event);
      }

      const target = event.target as HTMLTextAreaElement;
      setCurrentValue(target?.value);
    };

    return (
      <div
        className={classJoin("flex flex-col", width, height)}
        data-cy={`${TEXTAREA_PREFIX}-${dataCy}`}
        ref={ref}
      >
        <textarea
          className={classJoin(
            baseStyle,
            styles.textarea,
            additionalClassName,
            error
              ? "border-error-50 placeholder-error-50"
              : "border-neutralSecondary-60 placeholder-neutralSecondary-41 focus:border-secondary-50",
            resize ? "min-h-40 max-h-screen" : "resize-none",
          )}
          defaultValue={defaultValue}
          disabled={disabled}
          name={name}
          onChange={handleOnChange}
          onKeyUp={handleOnChange}
          onPaste={handleOnChange}
          placeholder={placeholder}
          readOnly={readOnly}
          value={value}
          {...props}
        />
        <div className="flex">
          {error && (
            <span className="mt-1 text-xs text-error-50">{message ?? ""}</span>
          )}
          {maxLength && (
            <div
              className={classJoin(
                "text-sm ml-auto font-normal",
                error ? "text-error-50" : "text-neutralPrimary-30",
              )}
            >
              {currentValue?.length || 0}/{maxLength}
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default Textarea;
