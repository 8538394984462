import { uid } from "react-uid";
import { ReactElement, useEffect, useRef, useState } from "react";
import { classJoin } from "@ps/utils";
import { ConditionalTooltip, ScalePicker } from "@ps/ui-components";
import styles from "./skeletonStyle.module.css";
import { useMatrixContext } from "./context";
import iconForScale from "../../mock/iconForScale";
import {
  SKILLS_MATRIX_ENJOYMENT,
  SKILLS_MATRIX_KNOWLEDGE,
} from "../../../shared/data-cy";
import { MatrixSkillsRowProps } from "./types";
import { SKILLS_MATRIX_MODES } from "../../shared/consts";

const MatrixSkillsRow = ({
  enjoymentScale,
  fulfillment,
  handleOnSubmit,
  index,
  isNew,
  knowledgeScale,
  searchedValue,
  technologyId,
  technologyName,
}: MatrixSkillsRowProps): ReactElement => {
  const { mode } = useMatrixContext();
  const labelRef = useRef(null);
  const containerRef = useRef(null);
  const [isTechnologyEllipsis, setIsTechnologyEllipsis] = useState(false);
  const { isShowingInfoCard } = useMatrixContext();

  useEffect(() => {
    if (labelRef?.current && containerRef?.current) {
      setIsTechnologyEllipsis(
        labelRef?.current?.scrollWidth >= labelRef?.current?.offsetWidth + 5,
      );
    }
  }, [labelRef, containerRef, isShowingInfoCard]);

  const getHighlightedText = (name = ""): ReactElement => {
    const trimmedSearchValue = searchedValue?.trim();

    if (!trimmedSearchValue?.length) {
      return (
        <span ref={labelRef} className="truncate w-full">
          {name}
        </span>
      );
    }

    const escapedValue = trimmedSearchValue.replace(
      /[.*+?^${}()|[\]\\]/g,
      "\\$&",
    );
    const parts = name.split(new RegExp(`(${escapedValue})`, "gi"));

    return (
      <>
        {parts.map((part: string, idx: number) =>
          part.toLowerCase() === trimmedSearchValue.toLowerCase() ? (
            <span
              className="font-bold text-primary-50 truncate w-full"
              ref={labelRef}
              key={uid(idx)}
            >
              {part}
            </span>
          ) : (
            <span
              className="text-neutralPrimary-20 truncate w-full"
              ref={labelRef}
              key={uid(idx)}
            >
              {part}
            </span>
          ),
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col" key={technologyId}>
      <div
        className={classJoin(
          "text-neutralPrimary-20 border-b border-neutralSecondary-60",
          styles.matrixUserRow,
        )}
        key={technologyId}
      >
        <div
          className="truncate px-3 py-2 flex flex-row items-center relative"
          ref={containerRef}
        >
          <ConditionalTooltip
            content={technologyName}
            enabled={isTechnologyEllipsis}
            additionalChildrenClassName="w-full truncate"
          >
            <>{getHighlightedText(technologyName)}</>
          </ConditionalTooltip>
        </div>
        <div className="py-2 flex flex-row justify-center items-center border-r border-l border-neutralSecondary-60">
          <ScalePicker
            scale={knowledgeScale?.map((scale) => ({
              ...scale,
              iconUrl: scale.iconUrl,
            }))}
            disabled={mode !== SKILLS_MATRIX_MODES.USER_EDIT}
            onChange={(newValue) =>
              handleOnSubmit({
                id: technologyId,
                knowledge: newValue,
                enjoyment: fulfillment?.[technologyId || ""]?.enjoyment,
                isNew,
              })
            }
            defaultValue={fulfillment?.[technologyId || ""]?.knowledge}
            firstScaleEmpty
            dataCy={`${SKILLS_MATRIX_KNOWLEDGE}_${index}`}
          />
        </div>
        <div className="py-2 flex flex-row justify-center items-center">
          <ScalePicker
            scale={enjoymentScale?.map((scale, scaleId) => ({
              ...scale,
              iconUrl: undefined,
              icon: iconForScale[scaleId],
            }))}
            disabled={mode !== SKILLS_MATRIX_MODES.USER_EDIT}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={(newValue) =>
              handleOnSubmit({
                id: technologyId,
                knowledge: fulfillment?.[technologyId || ""]?.knowledge,
                enjoyment: newValue,
                isNew,
              })
            }
            defaultValue={fulfillment?.[technologyId || ""]?.enjoyment}
            singlePickMode
            dataCy={`${SKILLS_MATRIX_ENJOYMENT}_${index}`}
          />
        </div>
      </div>
    </div>
  );
};

export default MatrixSkillsRow;
