import { ReactElement } from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { SectionTitleProps } from "./types";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
  },
  text: {
    marginLeft: "10px",
    textTransform: "uppercase",
    fontSize: "16px",
    color: "#091B76",
    fontFamily: "PoppinsBold",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "32px",
    borderRight: "3px solid #091B76",
  },
  icon: {
    width: "22px",
    height: "auto",
    marginRight: "11px",
  },
});

const SectionTitle = ({ icon, text }: SectionTitleProps): ReactElement => (
  <View style={styles.container}>
    <View style={styles.iconContainer}>
      <Image style={styles.icon} src={icon} />
    </View>
    <Text style={styles.text}>{text}</Text>
  </View>
);

export default SectionTitle;
