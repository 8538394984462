import {
  ARTICLES,
  AWARDS,
  CERTIFICATES,
  CONTRIBUTIONS,
  TALKS,
  TUTORS,
} from "../sections/accomplishment/constants";
import { PROJECTS } from "../sections/projects/constants";
import {
  COURSE,
  COURSES,
  DEGREE,
  DEGREES,
} from "../sections/education/constants";

export const DATE_FORMAT = "MM/YYYY";

// eslint-disable-next-line import/prefer-default-export
export const NEW_ITEMS = {
  [ARTICLES]: {
    date: undefined,
    techStack: [],
    title: "",
    url: "",
  },
  [TALKS]: {
    eventName: "",
    techStack: [],
    title: "",
    url: "",
  },
  [CONTRIBUTIONS]: {
    endDate: undefined,
    name: "",
    startDate: undefined,
    techStack: [],
    url: "",
  },
  [TUTORS]: {
    date: undefined,
    description: "",
    techStack: [],
    title: "",
  },
  [AWARDS]: {
    awardName: "",
    date: undefined,
    rewarder: "",
    techStack: [],
    url: "",
  },
  [CERTIFICATES]: {
    expirationDate: undefined,
    issuedSate: undefined,
    techStack: [],
    title: "",
    url: "",
  },
  [PROJECTS]: {
    domain: "",
    startDate: undefined,
    endDate: undefined,
    description: "",
    responsibility: "",
    projectRoles: [],
    techStack: [],
  },
  [DEGREES]: {
    education: DEGREE,
    startDate: undefined,
    endDate: undefined,
    description: "",
    schoolName: "",
    fieldOfStudy: "",
    degree: "",
  },
  [COURSES]: {
    education: COURSE,
    startDate: undefined,
    endDate: undefined,
    description: "",
    trainer: "",
    courseName: "",
  },
};
