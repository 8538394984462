import { ReactElement } from "react";
import { View, Text } from "@react-pdf/renderer";
import { useTranslationWithNamespace } from "../../../hooks";
import { ExperienceProps } from "../types";
import { SectionIcon } from "../components";
import { EXPERIENCE_TIME } from "../../../shared/constants";
import { sharedOutlineStyles } from "../sharedStyles/sharedStyles";

const Experience = ({ experience, isBlind }: ExperienceProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <View style={sharedOutlineStyles.container}>
      <View style={sharedOutlineStyles.iconContainer}>
        <SectionIcon icon={EXPERIENCE_TIME} isBlind={isBlind} />
      </View>
      <View style={sharedOutlineStyles.textContainer}>
        <Text style={sharedOutlineStyles.label}>{t("resume.experience")}</Text>
        <Text style={sharedOutlineStyles.text}>
          {experience.months > 0
            ? `${t("resume.years", {
                count: experience.years,
              })} ${t("resume.months", { count: experience.months })}`
            : `${t("resume.years", {
                count: experience.years,
              })}`}
        </Text>
      </View>
    </View>
  );
};

export default Experience;
