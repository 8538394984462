import { getItemFromDictionary, ISOToDayJs, UTCToDayjs } from "@ps/utils";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import { getName } from "i18n-iso-countries";
import {
  OperativeAPIModel,
  OperativeDomainModel,
  SpecialistAPIModel,
  SpecialistDomainModel,
} from "../types";
import {
  normalizeSocials,
  prepareCareerPauseModel,
  prepareDocumentsToArray,
  prepareNationalitiesToDisplay,
  prepareResidenceToDisplay,
  prepareWorkingHoursToDisplay,
  sortArrayByDate,
  sortArrayByDates,
  transformGoals,
} from "../helpers";
import { SPECIALIST } from "../../../shared/constants";
import { DictionaryModel } from "../../../dictionaries";

dayjs.extend(timezone);

const BaseModel = (
  data: OperativeAPIModel,
  technologyDict?: DictionaryModel,
): OperativeDomainModel => ({
  avatar: data?.avatar,
  shortInformation: data?.bio,
  id: data.userId,
  languages: data.languages,
  nationality: data?.nationality
    ? prepareNationalitiesToDisplay(data?.nationality, getName)
    : [],
  passports: prepareDocumentsToArray(data?.passports, "toDayjs", getName),
  professions: data?.professions
    ? {
        main: data?.professions?.main,
        secondary: data?.professions?.secondary,
      }
    : null,
  profile: data.profile,
  residence: data.countryOfResidence
    ? prepareResidenceToDisplay(data.countryOfResidence, getName)
    : null,
  seniority: data?.seniority || "",
  social: {
    twitter: normalizeSocials(data?.social.twitter),
    linkedIn: normalizeSocials(data?.social.linkedIn),
    github: normalizeSocials(data?.social.github),
  },
  updatedAt: data?.workingHours?.zone
    ? dayjs.tz(UTCToDayjs(data?.updatedAt), data.workingHours.zone)
    : UTCToDayjs(data?.updatedAt),
  workingHours: data?.workingHours
    ? prepareWorkingHoursToDisplay(data.workingHours)
    : null,
  visas: prepareDocumentsToArray(data.visas, "toDayjs", getName),
  goals: transformGoals(data.goals, technologyDict) || {},
});

const SpecialistModel = (
  data: SpecialistAPIModel,
  technologyDict: DictionaryModel,
): SpecialistDomainModel => {
  const baseModel = BaseModel(data, technologyDict);

  return {
    ...baseModel,
    experience: {
      projects: sortArrayByDates(
        data?.experience?.projects.map((item) => ({
          name: item?.name || null,
          description: item.desc,
          domain: item.domain,
          endDate: ISOToDayJs(item.endDate),
          projectRoles: item.projectRoles,
          responsibility: item.responsibility,
          startDate: ISOToDayJs(item.startDate),
          techStack: item.techStack.length
            ? item.techStack.map((tech: string) =>
                getItemFromDictionary(technologyDict, tech),
              )
            : [],
        })) || [],
      ),
      courses: sortArrayByDates(
        data?.experience?.educations
          .filter((item) => item.education === "course")
          .map((course) => ({
            startDate: ISOToDayJs(course.startDate),
            endDate: ISOToDayJs(course.endDate),
            description: course.desc,
            education: course.education,
            trainer: course.trainer,
            courseName: course.courseName,
          })) || [],
      ),
      degrees: sortArrayByDates(
        data?.experience?.educations
          .filter((item) => item.education === "degree")
          .map((degree) => ({
            startDate: ISOToDayJs(degree.startDate),
            endDate: ISOToDayJs(degree.endDate),
            description: degree.desc,
            schoolName: degree.schoolName,
            fieldOfStudy: degree.fieldOfStudy,
            degree: degree.degree,
          })) || [],
      ),
      articles: sortArrayByDate(
        data?.experience?.articles.map((item) => ({
          date: ISOToDayJs(item.publicationDate),
          techStack: item.techStack.length
            ? item.techStack.map((tech: string) =>
                getItemFromDictionary(technologyDict, tech),
              )
            : [],
          title: item.title,
          url: item.url,
        })) || [],
      ),
      talks:
        data?.experience.talks.map((item) => ({
          eventName: item.eventName,
          techStack: item.techStack.length
            ? item.techStack.map((tech: string) =>
                getItemFromDictionary(technologyDict, tech),
              )
            : [],
          title: item.title,
          url: item.url,
          videoUrl: item.videoUrl,
        })) || [],
      contributions: sortArrayByDates(
        data?.experience.contributions.map((item) => ({
          endDate: ISOToDayJs(item.endDate),
          name: item.name,
          startDate: ISOToDayJs(item.startDate),
          techStack: item.techStack.length
            ? item.techStack.map((tech: string) =>
                getItemFromDictionary(technologyDict, tech),
              )
            : [],
          url: item.url,
        })) || [],
      ),
      tutors: sortArrayByDate(
        data?.experience?.tutors.map((item) => ({
          date: ISOToDayJs(item.date),
          description: item.desc,
          techStack: item.techStack.length
            ? item.techStack.map((tech: string) =>
                getItemFromDictionary(technologyDict, tech),
              )
            : [],
          title: item.title,
        })) || [],
      ),
      awards: sortArrayByDate(
        data?.experience.awards.map((item) => ({
          awardName: item.awardName,
          date: ISOToDayJs(item.date),
          rewarder: item.rewarder,
          techStack: item.techStack.length
            ? item.techStack.map((tech: string) =>
                getItemFromDictionary(technologyDict, tech),
              )
            : [],
          description: item.description,
        })) || [],
      ),
      certificates: sortArrayByDates(
        data?.experience.certificates.map((item) => ({
          expirationDate: ISOToDayJs(item.expirationDate),
          issuedDate: ISOToDayJs(item.issuedDate),
          techStack: item.techStack.length
            ? item.techStack.map((tech: string) =>
                getItemFromDictionary(technologyDict, tech),
              )
            : [],
          title: item.title,
          url: item.url,
        })) || [],
        "issuedDate",
        "expirationDate",
      ),
    },
    roles: data.projectRoles || [],
    careerSpan: data?.careerSpan
      ? {
          startDate: ISOToDayJs(data?.careerSpan?.startDate),
          gapMonths: prepareCareerPauseModel(data?.careerSpan?.gapMonths),
        }
      : null,
  };
};

const fromAPIModel = (
  data: SpecialistAPIModel | OperativeAPIModel,
  technologyDict: DictionaryModel | [],
): Promise<OperativeDomainModel | SpecialistDomainModel> => {
  console.log("2b response: ", data?.profile, data?.profile === SPECIALIST);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return data?.profile === SPECIALIST
    ? SpecialistModel(data as SpecialistAPIModel, technologyDict)
    : BaseModel(data, technologyDict);
};

export default fromAPIModel;
