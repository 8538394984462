export const hasPermissions = (
  allPermissionsList: string[],
  permissions: string | string[],
): boolean => {
  if (!permissions) return true;

  if (
    Array.isArray(permissions) &&
    permissions.length &&
    permissions.find((item: string) => allPermissionsList.includes(item))
  )
    return true;

  return (
    typeof permissions === "string" && allPermissionsList.includes(permissions)
  );
};
