import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import { motion } from "framer-motion";
import Card from "../card";
import { ReactComponent as DownArrow } from "../../images/expanded-card-down-arrow.svg";
import { ReactComponent as UpArrow } from "../../images/expanded-card-up-arrow.svg";
import { ReactComponent as RightArrow } from "../../images/expanded-card-right-arrow.svg";
import { ReactComponent as LeftArrow } from "../../images/expanded-card-left-arrow.svg";
import { EXPANDED_CARD_PREFIX } from "../../shared/data-cy";
import { ExpandedCardProps } from "./types";
import { useTranslationWithNamespace } from "../../hooks";
import { focusVisibleStyles, Keys } from "../../shared";

const ExpandedCard = ({
  additionalCardClassName,
  additionalClassName,
  additionalContentClassName,
  children,
  dataCy,
  expandDirection = "",
  header,
  isOpen,
  onClick,
  openLabel,
  closeLabel,
  withAnimation = true,
}: ExpandedCardProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const className = classJoin(
    expandDirection === "down" && "flex, flex-col",
    expandDirection === "right" && "flex flex-row justify-between",
  );

  const toggleElementStyles = `w-auto px-2 h-auto text-neutralPrimary-40 font-semibold 
    flex items-center justify-center py-1 gap-1 hover:opacity-90 select-none`;

  return (
    <motion.div layout={!!withAnimation} className="h-full">
      <Card
        dataCy={`${EXPANDED_CARD_PREFIX}_${dataCy}`}
        additionalClassName={classJoin(additionalClassName, "h-full")}
        additionalCardClassName={classJoin(additionalCardClassName, className)}
      >
        <div className={additionalContentClassName}>
          {header && header}
          {children}
        </div>
        {expandDirection === "right" ? (
          <div
            onClick={onClick}
            role="button"
            tabIndex={0}
            onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
              if (event.key === Keys.ENTER) onClick();
            }}
            className={classJoin(
              isOpen ? "bg-primary-20" : "bg-primary-50",
              toggleElementStyles,
              "rounded-r-md",
              focusVisibleStyles,
            )}
          >
            {isOpen ? <LeftArrow /> : <RightArrow />}
          </div>
        ) : (
          <></>
        )}
        {expandDirection === "down" ? (
          <div
            data-cy={`${dataCy}-more-less`}
            onClick={onClick}
            role="button"
            tabIndex={0}
            onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
              if (event.key === Keys.ENTER) onClick();
            }}
            className={classJoin(
              isOpen ? "bg-opacity-50" : "bg-opacity-100",
              toggleElementStyles,
              "rounded-md border border-neutralPrimary-40",
              focusVisibleStyles,
            )}
          >
            <span>
              {isOpen ? closeLabel || t("expandedCard.close") : openLabel}
            </span>
            {isOpen ? (
              <UpArrow className="mt-0.5" />
            ) : (
              <DownArrow className="mt-0.5" />
            )}
          </div>
        ) : (
          <></>
        )}
      </Card>
    </motion.div>
  );
};

export default ExpandedCard;
