enum ActionTypes {
  FETCH_TIMESHEET_START = "FETCH_TIMESHEET_START",
  FETCH_TIMESHEET_SUCCESS = "FETCH_TIMESHEET_SUCCESS",
  FETCH_TIMESHEET_ERROR = "FETCH_TIMESHEET_ERROR",

  FETCH_LAST_WEEK_TIMESHEET_START = "FETCH_LAST_WEEK_TIMESHEET_START",
  FETCH_LAST_WEEK_TIMESHEET_SUCCESS = "FETCH_LAST_WEEK_TIMESHEET_SUCCESS",
  FETCH_LAST_WEEK_TIMESHEET_ERROR = "FETCH_LAST_WEEK_TIMESHEET_ERROR",

  APPEND_TIMESHEET_ENTRY = "APPEND_TIMESHEET_ENTRY",
  REMOVE_TIMESHEET_ENTRY = "REMOVE_TIMESHEET_ENTRY",

  FETCH_PINNED_PROJECTS_START = "FETCH_PINNED_PROJECTS_START",
  FETCH_PINNED_PROJECTS_SUCCESS = "FETCH_PINNED_PROJECTS_SUCCESS",
  FETCH_PINNED_PROJECTS_ERROR = "FETCH_PINNED_PROJECTS_ERROR",

  FETCH_PROJECTS_LIST_START = "FETCH_PROJECTS_LIST_START",
  FETCH_PROJECTS_LIST_SUCCESS = "FETCH_PROJECTS_LIST_SUCCESS",
  FETCH_PROJECTS_LIST_ERROR = "FETCH_PROJECTS_LIST_ERROR",

  FETCH_USER_HOLIDAYS_TEMPLATE_START = "FETCH_USER_HOLIDAYS_TEMPLATE_START",
  FETCH_USER_HOLIDAYS_TEMPLATE_SUCCESS = "FETCH_USER_HOLIDAYS_TEMPLATE_SUCCESS",
  FETCH_USER_HOLIDAYS_TEMPLATE_ERROR = "FETCH_USER_HOLIDAYS_TEMPLATE_ERROR",

  FETCH_REPORT_FILTERS_START = "FETCH_REPORT_FILTERS_START",
  FETCH_REPORT_FILTERS_SUCCESS = "FETCH_REPORT_FILTERS_SUCCESS",
  FETCH_REPORT_FILTERS_ERROR = "FETCH_REPORT_FILTERS_ERROR",

  FETCH_REPORT_START = "FETCH_REPORT_START",
  FETCH_REPORT_SUCCESS = "FETCH_REPORT_SUCCESS",
  FETCH_REPORT_ERROR = "FETCH_REPORT_ERROR",
  CLEAR_REPORT = "CLEAR_REPORT",

  FETCH_HOLIDAYS_HISTORY_START = "FETCH_HOLIDAYS_HISTORY_START",
  FETCH_HOLIDAYS_HISTORY_SUCCESS = "FETCH_HOLIDAYS_HISTORY_SUCCESS",
  FETCH_HOLIDAYS_HISTORY_ERROR = "FETCH_HOLIDAYS_HISTORY_ERROR",

  FETCH_DAYS_OFF_START = "FETCH_DAYS_OFF_START",
  FETCH_DAYS_OFF_START_SUCCESS = "FETCH_DAYS_OFF_START_SUCCESS",
  FETCH_DAYS_OFF_START_ERROR = "FETCH_DAYS_OFF_START_ERROR",

  UPDATE_REPORTS_GROUP_BY = "UPDATE_REPORTS_GROUP_BY",
  CLEAR_REPORTS_GROUP_BY = "CLEAR_REPORTS_GROUP_BY",

  UPDATE_ACTIVE_SORTER = "UPDATE_ACTIVE_SORTER",
}

export default ActionTypes;
