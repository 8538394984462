import { KeyboardEvent, ReactElement, useState } from "react";
import { Avatar, Button2, Empty, Icon } from "@ps/ui-components";
import { classJoin, getNameFromDictionary, setSeniorityColor } from "@ps/utils";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import {
  SpecialistRowMapState,
  SpecialistRowMapStateReturn,
  SpecialistRowProps,
} from "./types";
import styles from "../styles.module.css";
import { SPECIALIST, SPECIALISTS } from "../../../../constants";
import { ReactComponent as NoAvatarIcon } from "../../../../../images/no-avatar.svg";
import { ReactComponent as InfoIcon } from "../../../../../images/prospect/info.svg";
import { Keys } from "../../../../../shared";
import SpecialistModal from "./specialistModal";
import { convertToPercentage } from "./teamGenerator/utils";

const TRANSLATE_PATH = "projects.prospect.proposalFolder.userDetails";

const SpecialistRow = ({
  user,
  onUserAdd,
  isAdded,
}: SpecialistRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const mapState = (
    state: SpecialistRowMapState,
  ): SpecialistRowMapStateReturn => ({
    professionsDict: state.dictionaries?.profession || [],
    technologyDict: state?.dictionaries?.technology || [],
    seniorityDict: state?.dictionaries?.seniority || [],
    roleDict: state.dictionaries?.project_role || [],
    currentProfile:
      state.folders.folderProposalProfiles?.[user?.userId] || null,
  });

  const { professionsDict, seniorityDict, roleDict, currentProfile } =
    useMappedStateSelector(mapState);

  const [userId, setUserId] = useState("");

  const userName = `${user?.firstName} ${user?.lastName}`;

  const mainProfessionName = getNameFromDictionary(
    professionsDict,
    user?.mainProfession,
  );

  const seniorityName = getNameFromDictionary(seniorityDict, user?.seniority);

  const normalizedSeniority =
    seniorityName.charAt(0).toUpperCase() + seniorityName.slice(1);

  return (
    <>
      <div
        data-cy={`${SPECIALISTS}_list`}
        className={classJoin(
          styles.specialistsGrid,
          "w-full text-neutralPrimary-20 items-center py-6",
        )}
      >
        <div className="flex gap-x-6 items-center">
          {user?.avatar ? (
            <Avatar
              image={user?.avatar}
              dataCy={`${SPECIALISTS}_icon`}
              width={11}
              height={11}
            />
          ) : (
            <Icon
              icon={<NoAvatarIcon className="text-neutralSecondary-60" />}
              dataCy={`${SPECIALISTS}_icon`}
              width="11"
              height="11"
            />
          )}
          <span className="font-semibold">{userName}</span>
        </div>
        {user?.mainProfession ? <span>{mainProfessionName}</span> : <Empty />}
        {user?.seniority ? (
          <div className="flex items-center flex-wrap text-neutralPrimary-20">
            <span className={`${setSeniorityColor(user?.seniority)}`}>
              {user?.seniority ? normalizedSeniority : <Empty />}
            </span>
          </div>
        ) : (
          <Empty />
        )}
        {user?.score ? (
          <span className="text-primary-50 font-semibold">
            {convertToPercentage(user.score)}
          </span>
        ) : (
          <>
            {user?.residence?.label ? (
              `${user.residence.label}, ${user.residence.value}`
            ) : (
              <Empty />
            )}
          </>
        )}

        <div
          data-cy={`${SPECIALISTS}_info`}
          onClick={() => setUserId(user.userId)}
          tabIndex={0}
          role="button"
          onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === Keys.ENTER) setUserId(user.userId);
          }}
          className="flex ml-2 justify-center items-center h-8 w-8 p-1 border rounded-md cursor-pointer hover:border-neutralPrimary-20"
        >
          <InfoIcon className="text-primary-50" />
        </div>
        <div className="flex justify-end items-center w-full">
          {isAdded ? (
            <Button2
              dataCy={`${SPECIALISTS}_added`}
              onClick={() => onUserAdd(user?.userId)}
              variant="primary"
            >
              {t(`${TRANSLATE_PATH}.actions.added`)}
            </Button2>
          ) : (
            <Button2
              dataCy={`${SPECIALISTS}_addUser`}
              onClick={() => onUserAdd(user?.userId)}
              variant="secondary"
            >
              + {t(`${TRANSLATE_PATH}.actions.addToFolder`)}
            </Button2>
          )}
        </div>
      </div>
      <div className="bg-neutralSecondary-41 h-0.5 bg-opacity-20" />
      <SpecialistModal
        isOpen={userId === user.userId}
        onClose={() => setUserId("")}
        normalizedSeniority={normalizedSeniority}
        mainProfessionName={mainProfessionName}
        userId={userId}
        userName={userName}
        roleDict={roleDict}
        currentProfile={currentProfile}
      />
    </>
  );
};

export default SpecialistRow;
