import { ReactElement } from "react";
import { classJoin, getNameFromDictionary } from "@ps/utils";
import { PROFILE_SUMMARY_SKILLS } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import { CardHeader } from "../../../expanded-card";
import SkillBar from "../../../skill-bar";
import Label from "../../../label";
import { renderSectionIcon } from "../../helpers";
import { ProfessionSkillsProps, SingleSkill } from "./types";

const BASE_PATH = "profileSummary";

const ProfessionSkills = ({
  additionalClassName,
  maxScale,
  professionId,
  professionsDict,
  professionSkills,
  renderIcon,
  technologyDict,
}: ProfessionSkillsProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <div
      className={classJoin(
        "flex flex-col gap-4 justify-center",
        additionalClassName,
      )}
    >
      {professionId && (
        <CardHeader
          Icon={renderIcon(professionId)}
          dataCy={`${PROFILE_SUMMARY_SKILLS}${professionId}`}
          iconColor="text-neutralSecondary-60"
        >
          <span className="flex flex-col gap-2">
            {t(`${BASE_PATH}.skillsName`, {
              name: getNameFromDictionary(professionsDict, professionId),
            })}
          </span>
        </CardHeader>
      )}
      <div className="flex flex-row flex-wrap gap-8 py-8">
        {professionSkills
          ?.sort((a: SingleSkill, b: SingleSkill) =>
            a?.score > b.score ? -1 : 1,
          )
          .map((skill) => (
            <div key={skill.technologyId} className="w-36 sm:w-48">
              <Label
                dataCy={skill.technologyId}
                additionalClassName="font-normal"
                labelSiblings={
                  skill.experienceOccurrences?.length ? (
                    skill.experienceOccurrences.map((item: string) => {
                      const Icon = renderSectionIcon(item);
                      return (
                        <Icon
                          key={item}
                          className="fill-current text-primary-50 h-4 w-auto"
                        />
                      );
                    })
                  ) : (
                    <></>
                  )
                }
                text={getNameFromDictionary(technologyDict, skill.technologyId)}
              >
                <SkillBar
                  value={skill.score}
                  total={maxScale}
                  dataCy={skill.technologyId}
                  color={skill.color}
                  withLegend
                />
              </Label>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProfessionSkills;
