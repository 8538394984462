import { forwardRef } from "react";
import { classJoin } from "@ps/utils";
import { useThemeMode } from "../theme-mode-provider";
import { ButtonProps, ButtonVariants } from "./types";
import { BUTTON_PREFIX } from "../../shared/data-cy";

const variants = (isHighContrast: boolean): Record<ButtonVariants, string> => ({
  primary: classJoin(
    isHighContrast
      ? "text-primary-95 hover:bg-primary-10 disabled:opacity-60"
      : "text-primary-100 disabled:text-neutralPrimary-40",
    classJoin.template`
    bg-primary-50 disabled:bg-neutralSecondary-80 
    hover:bg-primary-10 active:bg-primary-20
    focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-50 focus-visible:ring-opacity-50`,
  ),
  successPrimary: classJoin.template`
    bg-success-50 border-success-50 hover:bg-success-30 text-primary-100
    focus:outline-none focus-visible:ring-2 focus-visible:ring-success-70`,
  errorPrimary: classJoin.template`
    bg-error-50 border-error-50 hover:bg-error-30 text-primary-100
    focus:outline-none focus-visible:ring-2 focus-visible:ring-error-70`,
  secondary: classJoin.template`
    text-neutralPrimary-30 border-neutralPrimary-40
    hover:text-neutralSecondary-60 hover:border-neutralSecondary-60
    disabled:text-neutralPrimary-85 disabled:border-neutralPrimary-85
    active:text-neutralPrimary-30 active:border-neutralPrimary-30
    focus:outline-none focus-visible:ring-2 focus-visible:ring-neutralSecondary-60 focus-visible:ring-opacity-50`,
  primaryNegative: classJoin(
    isHighContrast
      ? "active:bg-secondary-80 active:text-primary-95"
      : "hover:bg-secondary-80 active:bg-secondary-60",
    classJoin.template`
    text-primary-50 bg-primary-100 border-primary-50
    disabled:bg-neutralSecondary-80 disabled:text-neutralSecondary-30
    focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-100 focus-visible:ring-opacity-50`,
  ),
  secondaryNegative: classJoin.template`
    text-primary-100 bg-primary-50 border-neutralSecondary-60
    hover:text-neutralPrimary-20 hover:border-neutralPrimary-20
    disabled:text-neutralSecondary-30 disabled:border-neutralSecondary-80
    active:text-neutralSecondary-41 active:border-neutralSecondary-41
    focus:outline-none focus-visible:ring-2 focus-visible:ring-neutralSecondary-60 focus-visible:ring-opacity-50`,
  onAction: classJoin.template`
    text-primary-50 bg-primary-100
    hover:bg-primary-100 active:bg-primary-100
    disabled:bg-neutralSecondary-80 disabled:text-neutralSecondary-30
    focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-50 focus-visible:ring-opacity-50`,
  onActionSecondary: classJoin.template`
    text-primary-100 border-primary-100
    disabled:text-neutralSecondary-30 disabled:border-neutralSecondary-80
    focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-100 focus-visible:ring-opacity-50`,
});

const errors: {
  secondary: string;
  onAction: string;
  onActionSecondary: string;
  primary: string;
} = {
  primary:
    "text-primary-100 hover:bg-error-40 bg-error-50 select-none pointer-events-none",
  secondary:
    "text-error-50 bg-primary-100 hover:border-error-40 border-error-50 select-none pointer-events-none",
  onAction:
    "text-error-50 bg-error-50 border-error-50 select-none pointer-events-none",
  onActionSecondary:
    "text-error-50 bg-white border-error-50 select-none pointer-events-none",
};

const baseStyle = classJoin.template`
flex flex-row justify-center items-center
disabled:pointer-events-none select-none
`;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      autoFocus = false,
      additionalClass = "",
      dataCy,
      disabled = false,
      hasError = false,
      children,
      onClick,
      onPointerEnter,
      onPointerLeave,
      type = "button",
      variant = "primary",
      width = "w-max",
      height = "h-10",
      paddingX = "px-3.5",
      borderRadius = "rounded-md",
      border,
      id,
      ...rest
    },
    ref,
  ) => {
    const { isHighContrast } = useThemeMode();
    const classes = hasError
      ? // @ts-ignore
        errors[variant] || variants.primary
      : variants(isHighContrast)[variant] || variants(isHighContrast).primary;

    return (
      <button
        autoFocus={autoFocus}
        id={id}
        {...rest}
        className={classJoin(
          baseStyle,
          classes,
          width,
          height,
          paddingX,
          borderRadius,
          variant === "primary" || border ? border : "border",
          additionalClass,
        )}
        disabled={disabled}
        data-cy={`${BUTTON_PREFIX}-${dataCy}`}
        onClick={hasError ? undefined : onClick}
        onPointerEnter={hasError ? undefined : onPointerEnter}
        onPointerLeave={hasError ? undefined : onPointerLeave}
        type={type}
        ref={ref}
      >
        {children}
      </button>
    );
  },
);

export default Button;
