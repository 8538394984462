export const CLIENTS_SELECT = "clients";
export const USERS_SELECT = "users";
export const PROJECTS_SELECT = "projects";
export const APPLY_BUTTON = "apply";
export const CLEAR_ALL_BUTTON = "clearAll";

export const STACKED_BAR_CHART = "barChartReports";

export const YEAR_MONTHS = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

export const GROUP_SELECT = "groupBy";

export const emptyValueLabel = { value: "", label: "" };

export const firstGroupSelect = ["projectName", "clientName", "userName"];
export const secondGroupSelect = [
  "userName",
  "clientName",
  "projectName",
  "note",
  "rate",
];
export const thirdGroupSelect = ["note", "projectName", "userName", "rate"];

export const TITLE = "title";
export const DURATION = "duration";

export const DONUT_PROJECTS = "donutProjects";
export const DONUT_USERS = "donutUsers";

export const DONUT_COLORS = [
  "#394BA1",
  "#4A61C9",
  "#7285DA",
  "#8796DE",
  "#A9B4E8",
  "#BAC3EC",
  "#CCD2F1",
  "#DDE0F5",
  "#EEF0FA",
];

export const reportDayFormat = "DD-MM-YYYY";

export const ALL_USER_SELECTED = "allUserSelected";
export const ALL_PROJECT_SELECTED = "allProjectSelected";
export const ALL_CLIENT_SELECTED = "allClientSelected";
