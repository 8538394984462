import { ReactElement, useContext, ContextType } from "react";
import ThemeModeContext from "./context";
import { THEME_MODE_PROVIDER_ID, Theme } from "./types";

// @ts-ignore
const useThemeMode = (): ContextType => {
  const themeMode = useContext(ThemeModeContext);
  const isLightTheme = themeMode.theme === Theme.LIGHT;
  const isDarkTheme = !isLightTheme;
  const isHighContrast = themeMode.theme === Theme.HIGH_CONTRAST;

  const ThemeModeWrapper = ({
    children,
  }: {
    children: ReactElement | ReactElement[];
  }): ReactElement => {
    return (
      <div data-color-theme={themeMode.theme} className="h-auto w-auto">
        {children}
      </div>
    );
  };

  return {
    ...themeMode,
    providerId: THEME_MODE_PROVIDER_ID,
    isLightTheme,
    isDarkTheme,
    isHighContrast,
    ThemeModeWrapper,
  };
};

export default useThemeMode;
