import { TFunction } from "i18next";

export const validate = (
  sizeLimitMB: number,
  extension: string,
  file: File,
  t: TFunction,
): string[] => {
  const MB = 1_000_000;
  const errors: string[] = [];

  if (/\s/.test(file.name)) {
    errors.push(t("ocr.uploadNameWhitespace"));
  }

  if (file.size / MB > sizeLimitMB) {
    errors.push(
      t("ocr.validation.toBig", { size: `${(file.size / MB).toFixed(2)}` }),
    );
  }

  if (!file.name.endsWith(`.${extension}`)) {
    errors.push(
      t("ocr.validation.wrongType", { extension: extension.toUpperCase() }),
    );
  }

  return errors;
};
