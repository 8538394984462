/* eslint-disable jsx-a11y/label-has-associated-control */
import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import styles from "./styles.module.css";
import { TOGGLE_PREFIX } from "../../shared/data-cy";
import { ToggleProps } from "./types";
import { focusVisibleStyles, Keys } from "../../shared";

const Toggle = ({
  dataCy,
  checked,
  onChange,
  additionalClassName,
  variant,
}: ToggleProps): ReactElement => (
  <div
    className={classJoin(styles.container, focusVisibleStyles)}
    role="button"
    tabIndex={0}
    onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === Keys.ENTER) onChange();
    }}
  >
    <label
      data-cy={`${TOGGLE_PREFIX}-${dataCy}`}
      className={classJoin(styles.switch, additionalClassName)}
      htmlFor={`${TOGGLE_PREFIX}-${dataCy}`}
    >
      <input
        id={`${TOGGLE_PREFIX}-${dataCy}`}
        type="checkbox"
        className={classJoin(styles.input, "hidden")}
        checked={checked}
        onChange={onChange}
      />
      <span
        className={classJoin(
          variant === "secondary" ? styles.slider2 : styles.slider,
          styles.round,
        )}
      />
    </label>
  </div>
);

export default Toggle;
