import { ReactElement } from "react";
import { Avatar, Icon } from "@ps/ui-components";
import { PROFILE_SUMMARY_BASE_INFO } from "@ps/ui-components/src/shared/data-cy";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { BasicInfoProps } from "../types";
import { ReactComponent as NoAvatarIcon } from "../../../../images/no-avatar.svg";
import { ReactComponent as LocationIcon } from "../../../../images/location.svg";
import { useTranslationWithNamespace } from "../../../../hooks";
import OcrUploader from "../../OCRWizard/uploader";
import { SPECIALIST } from "../../../../shared/constants";

dayjs.extend(timezone);

const BasicInfo = ({
  lastName,
  firstName,
  avatar,
  bio,
  residence,
  updatedAt,
  timeRanges,
  profile,
}: BasicInfoProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const renderAvatar = (): ReactElement =>
    avatar ? (
      <Avatar
        image={avatar}
        dataCy={PROFILE_SUMMARY_BASE_INFO}
        width={52}
        height={52}
      />
    ) : (
      <div>
        <Icon
          icon={
            <NoAvatarIcon className="fill-current text-neutralSecondary-60 w-full h-full" />
          }
          dataCy={PROFILE_SUMMARY_BASE_INFO}
          width="52"
          height="52"
        />
      </div>
    );

  const renderLastUpdate = (): ReactElement | null => {
    const formatDate = (date: Dayjs): string =>
      date.format("DD.MM.YYYY, HH:mm");

    return updatedAt ? (
      <div className="mb-3 text-neutralSecondary-41 text-sm flex items-center">
        {t("profile.lastUpdate", {
          date: timeRanges?.zone
            ? formatDate(dayjs(updatedAt).tz(timeRanges.zone.value))
            : formatDate(dayjs(updatedAt)),
        })}
      </div>
    ) : null;
  };

  return (
    <div className="border-r-2 w-9/12 flex text-neutralPrimary-20">
      {renderAvatar()}
      <div className="flex flex-col items-start w-full px-4">
        <div className="flex justify-between w-full">
          <div className="flex gap-x-4 items-center">
            <span className="font-semibold text-xl">{`${firstName} ${lastName}`}</span>
            {residence && (
              <span className="flex items-center gap-1">
                <LocationIcon className="text-primary-50 fill-current" />
                {residence.label}
              </span>
            )}
          </div>
          <span>{renderLastUpdate()}</span>
        </div>
        <div className="py-4 w-full">
          {bio?.length ? (
            <span className="text-neutralPrimary-20 w-9/12">{bio}</span>
          ) : (
            <>{profile === SPECIALIST && <OcrUploader />}</>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
