import { ReactElement, useContext, useEffect, useRef } from "react";
import { classJoin } from "@ps/utils";
import { SelectContext as SelectContextType, SelectOptionProps } from "./types";
import { SelectContext } from "./select";
import { SELECT_PREFIX } from "../../shared/data-cy";
import Checkbox from "../checkbox";
import doNothing from "./helpers/nothing";

const selectOptionStyle = classJoin.template`
rounded-md text-neutralPrimary-30
p-2 focus:outline-none flex flex-row gap-x-3 items-center
hover:bg-primary-70 hover:bg-opacity-30 capitalize
`;

const selectedOptionStyle = "bg-primary-70 bg-opacity-30 capitalize";

const Option = <T,>({
  additionalClassName = "",
  children,
  dataCy,
  key,
  value,
}: SelectOptionProps<T>): ReactElement => {
  const { selectedOptions, onClick, selectRef, currentSelection, isMultiple } =
    useContext<SelectContextType<T>>(SelectContext);

  const optionOnClick = () => {
    onClick?.(value, children);
    selectRef?.current?.focus();
  };

  const isThisHighlited = currentSelection === value;

  const isThisSelected = selectedOptions
    ?.map((option) => option.value)
    .includes(value);

  const isSelectedStyle =
    !isMultiple && currentSelection
      ? isThisHighlited
      : isThisSelected || isThisHighlited;

  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isThisSelected) {
      const isOverflowing =
        (elementRef.current?.parentElement?.scrollHeight ?? 0) >
        (elementRef.current?.parentElement?.clientHeight ?? 0);
      if (isOverflowing) {
        elementRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  }, [elementRef, isThisSelected]);

  return (
    <div
      key={key}
      ref={elementRef}
      className={classJoin(
        selectOptionStyle,
        isSelectedStyle && selectedOptionStyle,
        additionalClassName,
      )}
      data-cy={`${SELECT_PREFIX}-${dataCy}-${value}`}
      role="button"
      tabIndex={0}
      onKeyDown={doNothing}
      onClick={optionOnClick}
    >
      {isMultiple && (
        <Checkbox
          checked={isThisSelected}
          dataCy={`${SELECT_PREFIX}-${dataCy}-${value}`}
        />
      )}
      {children}
    </div>
  );
};

export default Option;
