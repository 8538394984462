import { AnyAction, Dispatch } from "redux";
import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import SkillsService from "../../services/skills";
import { DOMAINS_PROFESSION_ID } from "../../shared/consts";
import ActionTypes from "./actionTypes";
import { FulfillmentModel } from "../../models";
import {
  IProfessionId,
  SetDomainTemplateErrorActionObj,
  SetDomainTemplateSuccessActionObj,
  SetFulfillmentSuccessActionObj,
  SetProfessionTemplateSuccessActionObj,
} from "./types";

export const setDomainTemplateStartAction = (): AnyAction =>
  actionCreator(ActionTypes.SET_DOMAIN_TEMPLATE_START);
export const setDomainTemplateSuccessAction = (
  payload: SetDomainTemplateSuccessActionObj,
): AnyAction => actionCreator(ActionTypes.SET_DOMAIN_TEMPLATE_SUCCESS, payload);
export const setDomainTemplateErrorAction = (
  payload: SetDomainTemplateErrorActionObj,
): AnyAction => actionCreator(ActionTypes.SET_DOMAIN_TEMPLATE_ERROR, payload);

export const setProfessionTemplateStartAction = (
  payload: IProfessionId,
): AnyAction =>
  actionCreator(ActionTypes.SET_PROFESSION_TEMPLATE_START, payload);
export const setProfessionTemplateSuccessAction = (
  payload: SetProfessionTemplateSuccessActionObj,
): AnyAction =>
  actionCreator(ActionTypes.SET_PROFESSION_TEMPLATE_SUCCESS, payload);
export const setProfessionTemplateErrorAction = (
  payload: IProfessionId,
): AnyAction =>
  actionCreator(ActionTypes.SET_PROFESSION_TEMPLATE_ERROR, payload);

export const fetchTemplates = async (
  professionId: string,
  dispatch: Dispatch,
  t: (translation: string) => string,
): Promise<AnyAction | void> => {
  if (professionId === DOMAINS_PROFESSION_ID) {
    dispatch(setDomainTemplateStartAction());
    const response = await SkillsService.getDomainTemplate(
      t("profile.skills.legend.domains"),
      true,
    );
    if (isAPIError(response)) {
      dispatch(setDomainTemplateErrorAction(response));
    } else {
      dispatch(setDomainTemplateSuccessAction(response));
    }
  } else {
    dispatch(setProfessionTemplateStartAction({ professionId }));
    const response = await SkillsService.getProfessionTemplate(
      professionId,
      true,
    );
    if (isAPIError(response)) {
      dispatch(setProfessionTemplateErrorAction({ professionId }));
    } else {
      dispatch(setProfessionTemplateSuccessAction(response));
    }
  }
};

export const setFulfillmentStartAction = (payload: IProfessionId): AnyAction =>
  actionCreator(ActionTypes.SET_FULFILLMENT_START, payload);
export const setFulfillmentSuccessAction = (
  payload: SetFulfillmentSuccessActionObj,
): AnyAction => actionCreator(ActionTypes.SET_FULFILLMENT_SUCCESS, payload);
export const setFulfillmentErrorAction = (payload: IProfessionId): AnyAction =>
  actionCreator(ActionTypes.SET_FULFILLMENT_ERROR, payload);

export const fetchFulfillments = async (
  professionId: string,
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(setFulfillmentStartAction({ professionId }));
  const response =
    professionId === DOMAINS_PROFESSION_ID
      ? await SkillsService.getFulfillmentForDomain(true)
      : await SkillsService.getFulfillmentForProfession(professionId, true);

  if (isAPIError(response)) {
    dispatch(setFulfillmentErrorAction({ professionId }));
  } else {
    dispatch(setFulfillmentSuccessAction(response));
  }
};

export const updateFulfillments = async (
  professionId: string,
  dispatch: Dispatch,
  dataToUpdate: FulfillmentModel,
  dataToStore?: FulfillmentModel,
): Promise<AnyAction | void> => {
  dispatch(setFulfillmentStartAction({ professionId }));
  const response = await SkillsService.updateFulfillments(
    professionId,
    [],
    dataToUpdate,
  );
  if (isAPIError(response)) {
    dispatch(setFulfillmentErrorAction({ professionId }));
  } else {
    dispatch(
      setFulfillmentSuccessAction({
        // eslint-disable-next-line
        // @ts-ignore
        professionId,
        fulfillment: { [professionId]: dataToStore || dataToUpdate },
      }),
    );
  }
};
