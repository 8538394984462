import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import Card from "../../../card";
import { PROFILE_SUMMARY_PROJECTS } from "../../../../shared/data-cy";
import { CardHeader } from "../../../expanded-card";
import { useTranslationWithNamespace } from "../../../../hooks";
import { renderSectionIcon } from "../../helpers";
import { EmptyExperienceProps } from "../../types";
import { CURRENT_YEAR } from "../constants";
import LinkButton from "../../../link";

const BASE_PATH = "profileSummary";

const EmptyExperience = ({
  renderIcon = renderSectionIcon,
  title,
  redirectPath,
}: EmptyExperienceProps): ReactElement => {
  const history = useHistory();
  const { t } = useTranslationWithNamespace();

  const titleWithYear = t(`${BASE_PATH}.${title}`, { year: CURRENT_YEAR });

  const redirect = (currentTitle: string) => (): void => {
    history.push({
      pathname: redirectPath,
      state: {
        title: currentTitle,
      },
    });
  };

  return (
    <div className="w-auto h-36">
      <Card
        dataCy={`${PROFILE_SUMMARY_PROJECTS}_experience_empty_${title}`}
        additionalCardClassName="flex items-center justify-between p-6 shadow-lg"
        totalHeight="h-full"
      >
        <CardHeader
          Icon={renderIcon(title)}
          dataCy={`${PROFILE_SUMMARY_PROJECTS}_skills`}
        >
          <span className="font-extrabold">{titleWithYear}</span>
        </CardHeader>
        <div className="flex items-center gap-2">
          <LinkButton
            dataCy={`${PROFILE_SUMMARY_PROJECTS}_experience_empty_${title}`}
            disabled={!redirect}
            onClick={redirect(title)}
          >
            {`+ ${t(`${BASE_PATH}.add`)}`}
          </LinkButton>
        </div>
      </Card>
    </div>
  );
};

export default EmptyExperience;
