import {
  ReactElement,
  useRef,
  useState,
  MouseEvent,
  KeyboardEvent,
} from "react";
import { motion } from "framer-motion";
import { classJoin } from "@ps/utils";
import { ReactComponent as CloseIcon } from "../../images/close-notification-icon.svg";
import { AnimationVariant, TagProps } from "./types";
import { TAG_PREFIX } from "../../shared/data-cy";
import { Keys } from "../../shared";

const Tag = ({
  name,
  dataCy,
  onClick,
  disableDelete,
  disabled,
  secondary,
  additionalClassName,
}: TagProps): ReactElement => {
  const [isHover, setIsHover] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLSpanElement>(null);

  const handlePointerEnter = () => {
    setIsHover(true);
  };
  const handlePointerLeave = () => setIsHover(false);

  const wrapperWidth = wrapperRef.current?.getBoundingClientRect().width;
  const iconBoundingRect = iconRef.current?.getBoundingClientRect();

  const textAnimation = {
    [AnimationVariant.WHEN_HOVER]: { x: -(wrapperWidth ?? 100) },
    [AnimationVariant.WHEN_NOT_HOVER]: { x: 0 },
  };

  const closeIconAnimation = {
    [AnimationVariant.WHEN_HOVER]: {
      x: -(wrapperWidth ?? 0) / 2 + (iconBoundingRect?.width ?? 0) / 2,
      scale: 1.5,
    },
    [AnimationVariant.WHEN_NOT_HOVER]: { x: 0, scale: 1.2 },
  };

  const backgroundColor = secondary
    ? "bg-neutralPrimary-30 bg-opacity-30 border border-neutralPrimary-30"
    : "bg-primary-50";

  const textColor = secondary
    ? "text-neutralPrimary-30"
    : "text-neutralPrimary-100";

  const buttonColorIfDisabled = disabled
    ? "bg-neutralSecondary-80"
    : backgroundColor;
  const buttonColor = isHover ? "bg-error-50" : buttonColorIfDisabled;
  const buttonCursor =
    onClick && !disabled
      ? "cursor-pointer focus-visible:ring-2 focus-visible:ring-secondary-50"
      : "pointer-events-none";
  const textColorIfDisabled = disabled ? "text-neutralSecondary-30" : textColor;

  const shouldDisplayDeleteIcon = onClick && !disableDelete;
  const shouldAnimate = !(disableDelete || disabled);

  return (
    <div
      className={classJoin(
        "rounded-md py-0.5 px-2 overflow-hidden flex-shrink-0 focus:outline-none",
        buttonColor,
        textColorIfDisabled,
        buttonCursor,
        additionalClassName,
      )}
      onPointerEnter={shouldAnimate ? handlePointerEnter : undefined}
      onPointerLeave={shouldAnimate ? handlePointerLeave : undefined}
      style={{ transition: "background-color ease 0.2s" }}
      onClick={(event: MouseEvent<HTMLDivElement>) => {
        if (!disabled) onClick?.(event);
      }}
      data-cy={`${TAG_PREFIX}-${dataCy}`}
      role={onClick ? "button" : "presentation"}
      tabIndex={onClick ? 0 : undefined}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === Keys.ENTER || event.code === Keys.SPACE)
          onClick?.(event);
      }}
    >
      <div className="flex flex-row items-center" ref={wrapperRef}>
        <motion.span
          className="font-semibold"
          animate={
            isHover
              ? AnimationVariant.WHEN_HOVER
              : AnimationVariant.WHEN_NOT_HOVER
          }
          variants={textAnimation}
        >
          <>{name}</>
        </motion.span>
        {shouldDisplayDeleteIcon && (
          <motion.span
            className="ml-4"
            animate={
              isHover
                ? AnimationVariant.WHEN_HOVER
                : AnimationVariant.WHEN_NOT_HOVER
            }
            variants={closeIconAnimation}
            ref={iconRef}
          >
            <CloseIcon
              className={`fill-current ${textColor}`}
              style={{ scale: 1.2 }}
            />
          </motion.span>
        )}
      </div>
    </div>
  );
};

export default Tag;
