import { OperativeAPIModel, SpecialistAPIModel } from "../types";
import { OPERATIVE, SPECIALIST } from "../../../shared/constants";

const BaseModel = (): OperativeAPIModel => ({
  bio: undefined,
  user_id: null,
  countryOfResidence: undefined,
  languages: [],
  nationality: [],
  passports: [],
  professions: {
    main: "",
    secondary: [],
  },
  profile: OPERATIVE,
  seniority: "",
  workingHours: undefined,
  avatar: undefined,
  social: { twitter: [], linkedIn: [], github: [] },
  visas: [],
  goals: {},
});

const SpecialistModel = (): SpecialistAPIModel => {
  const baseModel = BaseModel();
  return {
    ...baseModel,
    profile: SPECIALIST,
    experience: {
      projects: [],
      educations: [],
      articles: [],
      talks: [],
      contributions: [],
      tutors: [],
      awards: [],
      certificates: [],
    },
    projectRoles: [],
    careerSpan: undefined,
  };
};

const newProfileModel = (
  profile: string,
): OperativeAPIModel | SpecialistAPIModel =>
  profile === SPECIALIST ? SpecialistModel() : BaseModel();

export default newProfileModel;
