import { ReactElement } from "react";
import CustomLabel from "../customLabel";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { TEAM_GENERATOR } from "../../../../../../shared/data-cy";
import { Scores } from "./types";
import { convertToPercentage, determineLevel } from "./utils";

const TRANSLATION_PATH = "projects.prospect.proposalFolder.teamGenerator";

const TeamInfo = ({ scores, orderIndex }: Scores): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const teamInfo = [
    {
      id: 1,
      label: t(`${TRANSLATION_PATH}.skillScore`),
      value: convertToPercentage(scores.skill),
      dataCy: `${TEAM_GENERATOR}_team${orderIndex}_skillScore`,
    },
    {
      id: 2,
      label: t(`${TRANSLATION_PATH}.enjoymentScore`),
      value: convertToPercentage(scores.enjoyment),
      dataCy: `${TEAM_GENERATOR}_team${orderIndex}_enjoymentScore`,
    },
    {
      id: 3,
      label: t(`${TRANSLATION_PATH}.seniorityScore`),
      value: convertToPercentage(scores.seniority),
      dataCy: `${TEAM_GENERATOR}_team${orderIndex}_seniorityScore`,
    },
    {
      id: 4,
      label: t(`${TRANSLATION_PATH}.languageAdapt`),
      value: t(
        `${TRANSLATION_PATH}.${determineLevel(scores.languageAdaptability)}`,
      ),
      dataCy: `${TEAM_GENERATOR}_team${orderIndex}_languageAdaptability`,
    },
    {
      id: 5,
      label: t(`${TRANSLATION_PATH}.timeAdapt`),
      value: t(
        `${TRANSLATION_PATH}.${determineLevel(scores.timeAdaptability)}`,
      ),
      dataCy: `${TEAM_GENERATOR}_team${orderIndex}_timeAdaptability`,
    },
    {
      id: 6,
      label: t(`${TRANSLATION_PATH}.cost`),
      value: t(`${TRANSLATION_PATH}.${determineLevel(scores.cost)}`),
      dataCy: `${TEAM_GENERATOR}_team${orderIndex}_cost`,
    },
  ];

  return (
    <div className="flex flex-col gap-y-4 border-r pt-4 pr-6 pb-8">
      {teamInfo.map(({ id, label, value, dataCy }) => (
        <CustomLabel key={id} dataCy={dataCy} label={label} dark>
          <span
            data-cy={`${dataCy}_${value}`}
            className="flex font-bold w-full text-primary-60 justify-end"
          >
            {value}
          </span>
        </CustomLabel>
      ))}
    </div>
  );
};

export default TeamInfo;
