import { ReactElement, useEffect } from "react";
import { Button, Fade, Tooltip } from "@ps/ui-components";
import { PROSPECT_FOLDER_TEAM_ML } from "../../../../shared/data-cy";
import { ReactComponent as RunMLIcon } from "../../../../../images/folder/cpu.svg";
import { ReactComponent as DownIcon } from "../../../../../images/folder/down.svg";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { RunMLDropdownProps } from "./types";
import NeedsOptionsContent from "./needsOptionContend";

const RunMLDropdown = ({
  needs,
  onMLClick,
  professionDict,
  seniorityDict,
  onTeamGenerateClick,
  open,
  handleOpen,
  handleClose,
}: RunMLDropdownProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  useEffect(() => {
    return () => handleClose();
  }, []);

  return (
    <Tooltip
      content={t("projects.prospect.proposalFolder.addReqsFirst")}
      isDisabled={!!needs?.length}
      placement="top"
      verticalOffset={-8}
    >
      <Fade
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        disabled={!needs.length}
        contentWrapperClassName="w-full z-50"
        clickElement={
          <Button
            dataCy={PROSPECT_FOLDER_TEAM_ML}
            type="button"
            disabled={!needs?.length}
            width="w-full"
            height="h-12"
          >
            <span className="flex items-center justify-center gap-2 flex-no-wrap mx-2">
              <RunMLIcon className="fill-current" />
              {t(`projects.prospect.proposalFolder.useML`)}
              {needs?.length ? <DownIcon className="fill-current" /> : <></>}
            </span>
          </Button>
        }
      >
        <NeedsOptionsContent
          needs={needs}
          onMLClick={onMLClick}
          onTeamGenerateClick={onTeamGenerateClick}
          professionDict={professionDict}
          seniorityDict={seniorityDict}
          singleElementClassName="bg-neutralPrimary-85 hover:bg-neutralPrimary-50"
        />
      </Fade>
    </Tooltip>
  );
};

export default RunMLDropdown;
