import { Dayjs } from "dayjs";

export enum SelectedDatesActionTypes {
  HOVER = "hover",
  HOVER_LEAVE = "hoverLeave",
  SELECT = "select",
  SELECT_FIRST = "selectFirst",
  FORCE_SELECT_FIRST = "forceSelectFirst",
  SELECT_SECOND = "selectSecond",
  FORCE_SELECT_SECOND = "forceSelectSecond",
  CLEAR = "clear",
  CLEAR_RANGE_START = "clearRangeStart",
  CLEAR_RANGE_END = "clearRangeEnd",
}
export enum SelectedDatesRangeActionTypes {
  SELECT_RANGE = "selectRange",
  FORCE_SELECT_RANGE = "forceSelectRange",
}
export enum InputActionTypes {
  CHANGE_INPUT = "changeInput",
}

export enum InputType {
  FIRST = "first",
  SECOND = "second",
  NONE = "none",
}
export interface SelectedDatesHelperActions {
  onPointerEnter: (date: Dayjs) => () => void;
  onPointerLeave: () => void;
  onClick: (date: Dayjs) => () => void;
  selectFirst: (date: Dayjs) => void;
  forceSelectFirst: (date: Dayjs) => void;
  selectSecond: (date: Dayjs) => void;
  forceSelectSecond: (date: Dayjs) => void;
  selectRange: (range: [Dayjs, Dayjs]) => void;
  forceSelectRange: (range: [Dayjs, Dayjs]) => void;
  clear: () => void;
  clearRange: (actionType: SelectedDatesActionTypes) => void;
  changeInput: (input: InputType) => void;
}

export type Action =
  | { type: SelectedDatesActionTypes; payload: Dayjs }
  | { type: InputActionTypes.CHANGE_INPUT; payload: InputType }
  | { type: SelectedDatesRangeActionTypes; payload: [Dayjs, Dayjs] };

export type SelectedDates =
  | [Dayjs, Dayjs]
  | [Dayjs, undefined]
  | [undefined, Dayjs]
  | [undefined, undefined]
  | undefined;

export type State = {
  selected?: SelectedDates;
  hover?: Dayjs;
  input: InputType;
};

export type ReducerType = (prevState: State, action: Action) => State;
