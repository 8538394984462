import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { Button, Card, Empty, Tooltip, useThemeMode } from "@ps/ui-components";
import { classJoin, getNameFromDictionary, setSeniorityColor } from "@ps/utils";
import styles from "./styles.module.css";
import { UserRowProps } from "../types";
import UserAvatar from "./userAvatar";
import { useMediaQuery, useTranslationWithNamespace } from "../../../hooks";
import { ACTIVE, INACTIVE, FINANCES, EDIT } from "./constants";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import UserModal from "./userModal";
import { fetchHolidaysUserDetails } from "../../store";

const BASE_PATH = "finance.finances";

const UserRow = ({
  isEditEnable,
  professionDict,
  seniorityDict,
  user,
}: UserRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const matches = useMediaQuery("(max-width: 1280px)");
  const dispatch = useDispatch();
  const translate = (field: string) => t(`${BASE_PATH}.${field}`);
  const { isHighContrast } = useThemeMode();

  const [editId, setEditId] = useState("");

  const profession = getNameFromDictionary(professionDict, user.profession);
  const seniorityName = getNameFromDictionary(seniorityDict, user?.seniority);

  const normalizedSeniority =
    seniorityName.charAt(0).toUpperCase() + seniorityName.slice(1);

  const statusStyle = !user.enabled ? "bg-gray-200" : "bg-other-toxicGreen";

  const showStatus = (enabled: boolean): ReactElement => {
    return (
      <div className="flex gap-x-3 items-center">
        <div
          className={classJoin(statusStyle, "h-4 w-4 rounded-full uppercase")}
        />
        <span className="uppercase font-semibold">
          {!enabled ? translate(INACTIVE) : translate(ACTIVE)}
        </span>
      </div>
    );
  };

  useEffect(() => {
    if (editId) {
      fetchHolidaysUserDetails(dispatch, editId);
    }
  }, [editId]);

  const renderSalary = (salary?: number) =>
    salary ? (
      <div className="flex w-min justify-center items-center border rounded-lg px-3">
        {"\u20AC"}
        {salary.toFixed(2)}
      </div>
    ) : (
      <div className="flex items-center">
        <Empty />
      </div>
    );

  return (
    <>
      <Card
        dataCy={`${FINANCES}_list_user`}
        additionalClassName={classJoin(
          isHighContrast && "border border-neutralSecondary-40 rounded-lg mb-1",
          "items-center",
        )}
        additionalCardClassName={classJoin(
          styles.financesGrid,
          !user.enabled && "bg-neutralSecondary-90 opacity-50",
          "p-6 bg-neutralPrimary-100 border border-neutralSecondary-90 text-neutralPrimary-20",
        )}
      >
        <div className="flex items-center gap-8">
          <UserAvatar avatar={user?.avatar ? user.avatar : ""} />
          <span className="font-bold">{user?.name}</span>
        </div>
        <div className="flex items-center">{profession || <Empty />}</div>
        <div className="flex items-center flex-wrap">
          <span className={`${setSeniorityColor(user?.seniority)}`}>
            {normalizedSeniority || <Empty />}
          </span>
        </div>
        {renderSalary(user.salary?.monthly)}
        {renderSalary(user.salary?.perHour)}
        {showStatus(user.enabled)}
        {isEditEnable ? (
          <Tooltip
            placement="top"
            content={<span>{t(`finance.${FINANCES}.edit`)}</span>}
            isDisabled={!matches}
          >
            <Button
              onClick={() => setEditId(user.id)}
              variant="secondary"
              additionalClass={classJoin(
                !user.enabled && "pointer-events-none",
                "flex gap-x-2 text-primary-50",
              )}
              width={!matches ? "inherit" : "w-12"}
              dataCy={`${FINANCES}_${EDIT}`}
            >
              {!matches && translate(EDIT)}
              <EditIcon />
            </Button>
          </Tooltip>
        ) : (
          <span />
        )}
        {isEditEnable && editId === user.id ? (
          <UserModal
            isOpen={editId === user.id}
            onClose={() => setEditId("")}
            user={user}
            seniority={normalizedSeniority}
            profession={profession}
          />
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default UserRow;
