import {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
} from "react";
import { Button, Card, Input } from "@ps/ui-components";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { classJoin, useMappedStateSelector, sortArray } from "@ps/utils";
import { useDispatch } from "redux-react-hook";
import { deleteRole, fetchAllRoles, RoleModel } from "@ps/hh";
import { clearRedirection } from "@ps/alert-modal";
import styles from "./styles.module.css";
import PermissionsGroup from "./permissionsGroup";
import CheckboxesGroup from "./checkboxesGroup";
import { PERMISSIONS, PERMISSIONS_ROLE } from "../../../shared/data-cy";
import {
  LEFT,
  permissionsMatrix,
  RIGHT,
  ROLES,
} from "../../../shared/constants";
import { useTranslationWithNamespace } from "../../../hooks";
import ConfirmDeleteRoleModal from "./confirmDeleteRoleModal";
import RoleDropdownMenu from "./roleDropdownMenu";
import PermissionsContext from "../context";
import { IRoleData } from "./types";
import EmptyRoles from "./emptyRoles";
import { Keys } from "../../../shared";

const BASE_PATH = "permissions";

const PermissionsGrid = (): ReactElement => {
  const { control, reset, handleSubmit, getValues, trigger, clearErrors } =
    useFormContext();
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const {
    isAddingNew,
    onSubmit,
    roleIndexInEdit,
    setAddingNew,
    setRoleIndexInEdit,
  } = useContext(PermissionsContext);

  const matrixNode = useRef<HTMLDivElement>(null);
  const mapState = (state: { allRoles: string[] }): { allRoles: string[] } => ({
    allRoles: state?.allRoles || [],
  });

  const { allRoles } = useMappedStateSelector(mapState, "hh");

  const [roleToRemove, setRoleToRemove] = useState<null | IRoleData>(null);

  const rolesArray = useFieldArray({
    control,
    name: ROLES,
  });

  const prepareData = (rolePermissions, pMatrix, roleId) =>
    pMatrix?.map((singleMatrix) => ({
      ...singleMatrix,
      permissions: singleMatrix.permissions.map((singlePermission) =>
        !singlePermission?.additionalId
          ? {
              ...singlePermission,
              checked: rolePermissions.includes(singlePermission.itemId),
              roleId,
            }
          : prepareData(rolePermissions, singleMatrix.permissions, roleId),
      ),
    }));

  useEffect(() => {
    reset({
      [ROLES]: sortArray(allRoles, "createdAt", "desc").map(
        (role: RoleModel) => ({
          ...role,
          permissions: prepareData(
            role.permissions,
            permissionsMatrix,
            role.itemId,
          ),
        }),
      ),
    });
  }, []);

  const handleOnAppendNewRole = async () => {
    rolesArray.append({
      name: `${t(`${BASE_PATH}.newRole`)} ${rolesArray.fields.length + 1}`,
      permissions: permissionsMatrix,
    });
    setRoleIndexInEdit(rolesArray.fields.length);
    setAddingNew(true);
  };

  const handleOnDeleteRole = async (roleId: string): Promise<void> => {
    if (!roleId) return;
    await deleteRole(dispatch, roleId).then(() => {
      setRoleToRemove(null);
      setRoleIndexInEdit(null);
    });
    await fetchAllRoles(dispatch);
    setRoleIndexInEdit(null);
  };

  const handleOnCancelClick = (): void => {
    if (isAddingNew) {
      rolesArray.remove(rolesArray.fields.length - 1);
      setAddingNew(false);
      dispatch(clearRedirection());
    }
    setRoleIndexInEdit(null);
    reset();
  };

  const isColumnDisabled = (currentId: number): boolean =>
    !!(roleIndexInEdit !== currentId && roleIndexInEdit);

  const isActiveColumn = (currentId: number): boolean =>
    roleIndexInEdit === currentId;

  const triggerValidation = (index: number): void => {
    trigger(`${ROLES}.${index}.name`);
  };

  const checkIfRoleExists = (newValue: string): boolean =>
    allRoles?.some(
      (item: RoleModel): boolean =>
        roleIndexInEdit &&
        item?.name?.toLowerCase() === newValue.toLowerCase().trim(),
    );

  return (
    <Card
      dataCy={`${PERMISSIONS}_table`}
      label={t(`${BASE_PATH}.title`)}
      additionalCardClassName={classJoin(
        styles.container,
        "border-t-4 border-neutralSecondary-90 mb-12",
      )}
    >
      <div className="border-r-4 border-primary-71">
        <div className="h-16" />
        {permissionsMatrix.map((group) => (
          <PermissionsGroup key={group.itemId} group={group} column={LEFT} />
        ))}
      </div>
      {rolesArray.fields?.length ? (
        <div
          className="w-full flex flex-col overflow-x-scroll"
          ref={matrixNode}
        >
          <div className="flex h-16">
            {rolesArray.fields.map(
              (role: Record<"id", string>, index: number): ReactElement => (
                <div
                  className={classJoin(
                    "border-r-2 h-full flex items-center justify-center",
                    isColumnDisabled(index)
                      ? "opacity-25 pointer-events-none"
                      : "opacity-100",
                    isActiveColumn(index)
                      ? classJoin(
                          styles.activeShadow,
                          styles.singleMiddleCellActive,
                        )
                      : styles.singleMiddleCell,
                  )}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  key={role.itemId}
                >
                  {index === roleIndexInEdit ? (
                    <Controller
                      control={control}
                      name={`${ROLES}.${index}.name`}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <Input
                          additionalContainerClass="w-full mx-4"
                          width="w-full"
                          dataCy={PERMISSIONS_ROLE}
                          defaultValue={value}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            onChange(event.target.value);
                            clearErrors();
                          }}
                          autoFocus
                          error={!!error}
                          message={error?.message}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              triggerValidation(index);
                              if (
                                value &&
                                value.length <= 30 &&
                                !checkIfRoleExists(value)
                              ) {
                                handleSubmit(onSubmit(index, getValues(ROLES)));
                              }
                            }
                          }}
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: `${t("errors.cantBeEmpty")}`,
                        },
                        maxLength: {
                          value: 30,
                          message: t(`${BASE_PATH}.noLongerThan`, {
                            number: 30,
                          }),
                        },
                        validate: (value: string) => {
                          if (checkIfRoleExists(value))
                            return `${t("errors.alreadyExists")}`;
                          return true;
                        },
                      }}
                    />
                  ) : (
                    <span className="w-full text-center truncate mx-4">
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      {role?.name}
                    </span>
                  )}
                  <RoleDropdownMenu
                    isInEdit={index === roleIndexInEdit}
                    onSaveClick={handleSubmit((data) =>
                      onSubmit(index, data.roles),
                    )}
                    onDeleteClick={() => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      setRoleToRemove(role);
                    }}
                    onEditClick={() => setRoleIndexInEdit(index)}
                    onCancelClick={handleOnCancelClick}
                    isDisabled={index !== roleIndexInEdit && roleIndexInEdit}
                    isAddingNew={isAddingNew}
                  />
                </div>
              ),
            )}
            <div
              className={classJoin(
                "h-16 flex justify-center items-center",
                styles.lastColumnContainer,
              )}
            >
              <Button
                dataCy={`${PERMISSIONS}_table`}
                additionalClass="text-4xl items-center justify-center flex p-0"
                height="h-11"
                width="w-11"
                onClick={handleOnAppendNewRole}
                disabled={typeof roleIndexInEdit === "number"}
              >
                <span className="self-baseline" style={{ fontSize: "2rem" }}>
                  +
                </span>
              </Button>
            </div>
          </div>
          <div className="flex items-start">
            {rolesArray.fields.map(
              (item: Record<"id", string>, id: number): ReactElement => (
                <CheckboxesGroup
                  key={item.id}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  permissionsList={item.permissions}
                  roleId={id}
                  isDisabled={isColumnDisabled(id)}
                  isActive={isActiveColumn(id)}
                />
              ),
            )}
            <CheckboxesGroup
              permissionsList={permissionsMatrix}
              column={RIGHT}
              isDisabled={false}
              isActive={false}
            />
          </div>
        </div>
      ) : (
        <EmptyRoles addFirstRole={handleOnAppendNewRole} />
      )}
      <ConfirmDeleteRoleModal
        handleOnDeleteRole={() =>
          roleToRemove && handleOnDeleteRole(roleToRemove?.itemId)
        }
        isOpen={!!roleToRemove}
        onCancel={() => setRoleToRemove(null)}
        roleName={roleToRemove?.name}
      />
    </Card>
  );
};

export default PermissionsGrid;
