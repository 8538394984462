import { motion } from "framer-motion";
import { ReactElement } from "react";
import { SWITCH_PREFIX } from "../../shared/data-cy";
import SwitchProps from "./types";
import styles from "./styles.module.css";

const Switch = ({
  onChange,
  checked,
  disabled,
  dataCy,
}: SwitchProps): ReactElement => {
  const handleOnClick = () => onChange?.(!checked);

  return (
    <motion.div
      className={`border border-neutralSecondary-60 h-4.5 w-12 overflow-hidden
        rounded-full flex flex-row
         ${
           checked
             ? "bg-primary-50 bg-opacity-30 justify-end"
             : "bg-transparent justify-start"
         }
      ${
        disabled
          ? "pointer-events-none bg-neutralSecondary-80 bg-opacity-30 opacity-60"
          : "cursor-pointer"
      }
      `}
      role="button"
      onKeyDown={() => {
        // do nothing
      }}
      tabIndex={0}
      onClick={disabled ? undefined : handleOnClick}
      data-cy={`${SWITCH_PREFIX}-${dataCy}`}
    >
      <motion.div
        layout
        className={`w-6 h-stretch ${
          disabled ? "bg-neutralSecondary-80" : "bg-primary-50"
        } ${styles.switch_border_radius}`}
      />
    </motion.div>
  );
};

export default Switch;
