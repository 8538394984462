import { ReactElement, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import {
  removeDiacriticsFromString,
  sortArray,
  useFullTextSearch,
} from "@ps/utils";
import {
  LoadingMainPage,
  MiddleContent,
  MiddleSection,
  TabsPlaceholder,
} from "@ps/ui-components";
import { fetchFolder } from "../store";
import {
  useMappedStateSelector,
  useSessionStorage,
  useTranslationWithNamespace,
} from "../../hooks";
import { SessionStorage } from "../../shared";
import {
  FolderMapState,
  FolderMapStateReturn,
  SorterParametersProps,
} from "./types";
import { ClientModel, fetchClients } from "../../client-domain";
import {
  ASC,
  COUNTRY,
  FOLDER,
  FOLDERS,
  PROFESSIONS,
  PROPOSAL_FOLDER,
  SPECIALIST,
} from "../constants";
import Keys from "../../shared/keys";
import ResumesPanel from "../../prospect-domain/ui/prospectDetails/sections/folder/resumesPanel";
import SearchBar from "../../prospect-domain/ui/prospectDetails/sections/folder/searchBar";
import Specialists from "../../prospect-domain/ui/prospectDetails/sections/folder/specialists";
import {
  SESSION_SORTERS,
  initialSessionSortersState,
} from "../../shared/constants";
import { CandidateModel } from "../models/types";
import ProposalColumn from "./folders-list/resumes/proposalColumn";
import { UserProps } from "./folders-list/resumes/types";
import { ProfilesSummaryListModel } from "../../profile-domain";
import { ProfileService } from "../../profile-domain/services";
import NoResumes from "./folders-list/resumes/noResumes";

const FolderView = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslationWithNamespace();
  const [users, setUsers] = useState<UserProps[]>([]);
  const [onlyActiveCandidates, setOnlyActiveCandidates] = useState<
    CandidateModel[]
  >([]);

  const [searchValue, setSearchValue] = useState("");
  const [sorterParameters, setSorterParameters] =
    useState<SorterParametersProps>({
      order: ASC,
      name: PROFESSIONS,
    });

  const [storageSorter, setStorageSorter] = useSessionStorage<SessionStorage>(
    SESSION_SORTERS,
    initialSessionSortersState,
  );

  const [proposalView, setProposalView] = useState(false);

  const mapState = (state: FolderMapState): FolderMapStateReturn => ({
    folder: state?.folders?.folder || {},
    isFetchingFolder: state.requestStatus.isFetchingFolder,
    clientsList: state?.clients?.clientsList || [],
  });

  const { folder, isFetchingFolder, clientsList } =
    useMappedStateSelector(mapState);

  const client = clientsList?.find(
    (c: ClientModel) => c.id === folder?.clientId,
  );

  const candidates: CandidateModel[] = folder?.candidates?.filter(
    (candidate: CandidateModel): boolean => !candidate.deleted,
  );

  const { search } = useFullTextSearch(candidates || [], undefined, {
    keys: ["firstName", "lastName"],
  });

  const prepareTeamList = (searchingValue: string): void => {
    const { proposalFolder: teamSorter } = storageSorter.sessionSorters;

    const properSorter: {
      name: string;
      order: string;
      nestedFieldName?: string;
    } = teamSorter?.name?.length ? teamSorter : sorterParameters;

    const bySearch = searchingValue
      ? (search(
          removeDiacriticsFromString(searchValue.toLowerCase()),
        ).reverse() as CandidateModel[])
      : candidates;

    const resultBySorter = sortArray(
      bySearch,
      properSorter.name as keyof CandidateModel,
      properSorter.order,
      properSorter.nestedFieldName as keyof CandidateModel,
    );

    setOnlyActiveCandidates(resultBySorter);
  };

  useEffect(() => {
    if (folder.candidates) prepareTeamList(searchValue);
  }, [searchValue, sorterParameters.name, sorterParameters.order, folder]);

  useEffect(() => {
    fetchFolder(id, dispatch);
    fetchClients(dispatch);
  }, [proposalView]);

  const handleOnClickSorter = (
    fieldName: string,
    order: string,
    isNestedField?: boolean,
  ): void => {
    const nestedFieldName = isNestedField ? COUNTRY : undefined;

    setStorageSorter((prevData) => ({
      ...prevData,
      sessionSorters: {
        ...prevData.sessionSorters,
        [PROPOSAL_FOLDER]: {
          name: fieldName,
          order,
          nestedFieldName,
        },
      },
    }));

    setSorterParameters({ name: fieldName, order });
  };

  const handleOnResumeAdd = async () => {
    await ProfileService.fetchProfilesList().then((data) => {
      const onlySpecialists = data.filter(
        (user: ProfilesSummaryListModel) => user.profile === SPECIALIST,
      );
      setUsers(onlySpecialists);
      setProposalView(true);
    });
  };

  if (isFetchingFolder)
    return (
      <MiddleContent dataCy={FOLDERS}>
        <LoadingMainPage />
      </MiddleContent>
    );

  return (
    <MiddleSection dataCy={FOLDERS}>
      <TabsPlaceholder />
      <MiddleContent dataCy={FOLDERS}>
        <div>
          <span
            data-cy={`${FOLDERS}_back-btn`}
            role="button"
            tabIndex={0}
            onClick={() => history.goBack()}
            onKeyDown={(event) => {
              if (event.key === Keys.ENTER) history.goBack();
            }}
            className="text-neutralPrimary-30 cursor-pointer select-none hover:text-neutralPrimary-20"
          >
            {`<`} {t(`${FOLDERS}.back`)}
          </span>
          <h2 className="text-4xl text-neutralPrimary-20 mt-6 mb-8 font-bold w-9/12 break-all">
            {folder.folderName}
          </h2>
        </div>
        <div className="flex justify-end items-center w-full mb-4 relative">
          {!proposalView && (
            <div className="absolute -top-12 right-8">
              <SearchBar
                dataCy={FOLDER}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                sorterParameters={
                  storageSorter.sessionSorters?.proposalFolder?.name?.length
                    ? storageSorter.sessionSorters.proposalFolder
                    : sorterParameters
                }
                onSorterClick={handleOnClickSorter}
              />
            </div>
          )}
        </div>
        <div className="bg-neutralPrimary-100 -mx-10 px-10 py-6">
          {!proposalView ? (
            <div className="flex gap-x-6">
              <div className="w-full">
                {!onlyActiveCandidates.length && <NoResumes />}
                <ResumesPanel
                  archived={folder?.archived}
                  onlyActiveCandidates={onlyActiveCandidates}
                />
              </div>
              <ProposalColumn
                folder={folder}
                client={client}
                clientsList={clientsList}
                onlyActiveCandidates={onlyActiveCandidates}
                handleResumesAdd={handleOnResumeAdd}
              />
            </div>
          ) : (
            <Specialists
              users={users}
              folderId={folder?.id}
              onCancel={() => setProposalView(false)}
            />
          )}
        </div>
      </MiddleContent>
    </MiddleSection>
  );
};

export default FolderView;
