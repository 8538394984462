import { ReactElement, MouseEvent } from "react";
import { ActiveSelect, Button, SearchInput, Sorter } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../hooks";
import {
  ACTIVE,
  ASC,
  DESC,
  INACTIVE,
  NAME,
  PROFESSION,
  SENIORITY,
  FINANCES,
  MONTHLY,
  HOURLY,
  PER_HOUR,
} from "./constants";
import { SearchBarProps } from "../types";

const BASE_PATH = "finance.finances.searchBar";

const SearchBar = ({
  searchValue,
  setSearchValue,
  sorterParameters,
  onSorterClick,
  activeAmount,
  inactiveAmount,
  usersCheckers,
  setUsersCheckers,
  handleOnCleanFilters,
  clearFiltersDisabled,
}: SearchBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const options = [
    {
      id: ACTIVE,
      label: t(`${BASE_PATH}.showActive`),
      amount: activeAmount,
      checked: usersCheckers.active,
    },
    {
      id: INACTIVE,
      label: t(`${BASE_PATH}.showInactive`),
      amount: inactiveAmount,
      checked: usersCheckers.inactive,
    },
  ];

  const names = [
    { name: NAME, label: t(`${BASE_PATH}.${NAME}`) },
    {
      name: PROFESSION,
      label: t(`${BASE_PATH}.${PROFESSION}`),
    },
    { name: SENIORITY, label: t(`${BASE_PATH}.${SENIORITY}`) },
    { name: MONTHLY, label: t(`${BASE_PATH}.salary`), isNestedField: true },
    { name: PER_HOUR, label: t(`${BASE_PATH}.rate`), isNestedField: true },
  ];

  const orders = [
    { order: ASC, label: t(`${BASE_PATH}.${ASC}`) },
    { order: DESC, label: t(`${BASE_PATH}.${DESC}`) },
  ];

  const handleOptionClick = (e: MouseEvent<HTMLDivElement>): void => {
    const { id } = e.currentTarget;
    setUsersCheckers((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className="flex justify-between items-center">
      <ActiveSelect
        label={t(`${BASE_PATH}.activeUsers`)}
        activeAmount={activeAmount}
        options={options}
        onOptionClick={handleOptionClick}
      />
      <div className="flex gap-4 items-center">
        {!clearFiltersDisabled && (
          <Button
            variant="secondary"
            dataCy={`${FINANCES}_clear`}
            onClick={handleOnCleanFilters}
          >
            {t("actions.clearFilters")}
          </Button>
        )}
        <SearchInput
          dataCy={`${FINANCES}_search`}
          value={searchValue}
          onChange={setSearchValue}
          placeholder={t(`${BASE_PATH}.search`)}
        />
        <Sorter
          activeSorter={sorterParameters}
          names={names}
          orders={orders}
          handleSorterClick={onSorterClick}
        />
      </div>
    </div>
  );
};

export default SearchBar;
