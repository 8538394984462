import { ReactElement } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { classJoin } from "@ps/utils";
import {
  ScrollableWrapper,
  TableHeader,
  TableLabel,
  useThemeMode,
  Button,
} from "@ps/ui-components";
import { ReactComponent as IconFolderMultipleOutline } from "../../../../images/finance/folder-multiple-outline.svg";
import { ReactComponent as EmptyHolidaysGraphic } from "../../../../images/finance/holidays/emptyHolidaysGraphic.svg";
import { ReactComponent as EmptyHolidaysGraphicHighContrast } from "../../../../images/finance/holidays/emptyHolidaysGraphicHighContrast.svg";
import { useTranslationWithNamespace } from "../../../../hooks";
import { HolidaysItemModel } from "../../../models";
import {
  BASE_TRANSLATION_PATH,
  HOLIDAYS_TEMPLATES,
  CATEGORY_NAME,
  HOLIDAYS_LIST,
  LIMIT,
  PAYMENT_PERCENTAGE,
  PARTIAL_DAY_OFF,
  HEADER_BAR,
  ADD_HOLIDAY,
} from "../../constants";
import styles from "./styles.module.css";
import HolidayRow from "./row";
import { HolidaysListProps } from "./types";

const HolidaysList = ({
  holidays,
  deleteNewHoliday,
  onAddHoliday,
}: HolidaysListProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();

  const translate = (field: string): string =>
    t(`${BASE_TRANSLATION_PATH}.${HOLIDAYS_LIST}.${field}`);

  return (
    <ScrollableWrapper>
      <div className="mb-3">
        <TableHeader
          dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAYS_LIST}`}
          columnsStyles={classJoin(
            styles.tableColumns,
            holidays.length ? "" : "opacity-40",
          )}
        >
          <div />
          <TableLabel
            dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAYS_LIST}_${CATEGORY_NAME}`}
          >
            {translate(CATEGORY_NAME)}
          </TableLabel>
          <TableLabel
            dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAYS_LIST}_${LIMIT}`}
          >
            {translate(LIMIT)}
          </TableLabel>
          <TableLabel
            dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAYS_LIST}_${PAYMENT_PERCENTAGE}`}
          >
            {translate(PAYMENT_PERCENTAGE)}
          </TableLabel>
          <TableLabel
            dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAYS_LIST}_${PARTIAL_DAY_OFF}`}
          >
            {translate(PARTIAL_DAY_OFF)}
          </TableLabel>
        </TableHeader>
      </div>

      <Scrollbars autoHide className="w-full h-full">
        <div className="flex flex-col w-full h-full gap-1">
          {holidays.length ? (
            holidays.map(
              (holiday: HolidaysItemModel, index: number): ReactElement => (
                <HolidayRow
                  key={holiday.holidaysId}
                  index={index}
                  holiday={holiday}
                  deleteNewHoliday={deleteNewHoliday}
                />
              ),
            )
          ) : (
            <div className="flex items-center gap-5 justify-center h-full">
              {isHighContrast ? (
                <EmptyHolidaysGraphicHighContrast />
              ) : (
                <EmptyHolidaysGraphic />
              )}
              {onAddHoliday ? (
                <div className="flex flex-col gap-2 text-neutralPrimary-20">
                  <span className="font-semibold text-lg">
                    {translate("addFirst")}
                  </span>
                  <span className="mb-6">{translate("hereYouCanAdd")}</span>
                  <Button
                    dataCy={`${HOLIDAYS_TEMPLATES}_${ADD_HOLIDAY}`}
                    onClick={onAddHoliday}
                  >
                    <IconFolderMultipleOutline className="fill-current mr-3" />
                    <span>
                      {t(
                        `${BASE_TRANSLATION_PATH}.${HEADER_BAR}.${ADD_HOLIDAY}`,
                      )}
                    </span>
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </Scrollbars>
    </ScrollableWrapper>
  );
};

export default HolidaysList;
