import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import { CircleButtonProps } from "./types";
import { focusVisibleStyles, Keys } from "../../shared";
import { CIRCLE_BUTTON } from "../../shared/data-cy";

const CircleButton = ({
  activeNumber,
  activeStyles,
  additionalStyles,
  dataCy,
  height = "h-12",
  isActive,
  nonActiveStyles,
  onClick,
  textSize = "text-xl",
  width = "w-12",
}: CircleButtonProps): ReactElement => (
  <div
    data-cy={`${CIRCLE_BUTTON}_${dataCy}`}
    role={onClick ? "button" : undefined}
    className={classJoin(
      height,
      width,
      textSize,
      "rounded-full border-2 flex justify-center items-center",
      focusVisibleStyles,
      isActive ? activeStyles : nonActiveStyles,
      onClick ? "cursor-pointer" : "pointer-events-none",
      additionalStyles,
    )}
    onClick={onClick}
    tabIndex={onClick ? 0 : undefined}
    onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === Keys.ENTER && onClick) onClick(event);
    }}
  >
    {activeNumber}
  </div>
);

export default CircleButton;
