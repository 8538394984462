import { INormalizedDictionaryEntry } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const scoreByLength = (
  searchedValue = "",
  dictionary: INormalizedDictionaryEntry[],
  weight = 2,
) => {
  if (!searchedValue) return [];
  const normalizedSearchedValue = searchedValue
    .replace(/[\W_]+/g, "")
    .toLowerCase();
  const result = dictionary
    .map((item, index) => ({
      item,
      refIndex: index,
      score: item?.normalizedName?.includes(normalizedSearchedValue)
        ? (normalizedSearchedValue.length / item?.normalizedName.length) *
          weight
        : 0,
    }))
    .filter((el) => !!el.score);
  return result;
};
