import { AnyAction } from "redux";
import { actionCreator } from "@ps/utils";
import ActionTypes from "store/actionTypes";
import { IAction } from "../types";

export const loginStart = (): AnyAction =>
  actionCreator(ActionTypes.LOGIN_START);
export const loginSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.LOGIN_SUCCESS, payload);
export const loginError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.LOGIN_ERROR, payload);

export const logoutStart = (): AnyAction =>
  actionCreator(ActionTypes.LOGOUT_START);
export const logoutSuccess = (): AnyAction =>
  actionCreator(ActionTypes.LOGIN_SUCCESS);
export const logoutError = (): AnyAction =>
  actionCreator(ActionTypes.LOGOUT_ERROR);
