import { Controller, useFormContext } from "react-hook-form";
import { ReactElement } from "react";
import { uid } from "react-uid";
import { ColorPicker, ScaleTile } from "@ps/ui-components";
import { colorFormatters } from "@ps/utils";
import { ColorPickerTilePanelProps } from "./types";

const ColorPickerTilePanel = ({
  baseName,
  fields,
  label = "",
  onRemove,
  withoutFirst,
}: ColorPickerTilePanelProps): ReactElement => {
  const { control, reset } = useFormContext();

  const handleOnClose = (
    newColor: { red: number; green: number; blue: number } | null,
    index: number,
  ) => {
    const newSettings = fields?.map((element, elIndex) =>
      index === elIndex ? { ...element, color: newColor } : element,
    );

    // set new value on close color picker modal
    reset({
      [baseName]: newSettings,
    });
  };

  return (
    <div className="flex flex-col py-8 gap-3">
      <span className="text-neutralPrimary-30">{label}</span>
      <div className="flex gap-2 items-center">
        {fields.map((item, index) => (
          <Controller
            key={uid(item)}
            name={`${baseName}.[${index}].color`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <ColorPicker
                color={
                  typeof value === "string"
                    ? value
                    : colorFormatters.prepareRGBObjectToHEX(value)
                }
                companyColors={["#7788e3", "#5066d9", "#2f49d1"]}
                dataCy={baseName}
                onChange={(newValue) => onChange(newValue)}
                onClose={(newColor) =>
                  handleOnClose(colorFormatters.hexToRGBObject(newColor), index)
                }
                saveOnOutsideClick
              >
                <ScaleTile
                  additionalClassName={
                    withoutFirst && index === 0 ? "hidden" : ""
                  }
                  color={
                    typeof value === "string"
                      ? value
                      : colorFormatters.prepareRGBObjectToHEX(value)
                  }
                  dataCy={baseName}
                  iconUrl={item?.iconUrl}
                  // TODO temporary limited because of hh2-2698
                  // onRemove={() => onRemove(index)}
                  // canRemove={
                  //   index === fields.length - 1 &&
                  //   ((withoutFirst && index !== 1) ||
                  //     (!withoutFirst && index !== 0))
                  // }
                />
              </ColorPicker>
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorPickerTilePanel;
