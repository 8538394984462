import { useState, ReactElement } from "react";
import dayjs from "dayjs";
import {
  Datepicker2,
  // Button,
  Input,
  Label,
  SearchSelectPicker,
} from "@ps/ui-components";
import { checkUrl, prepareTechStackDictionaryValues } from "@ps/utils";
import { Controller, useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { FormSectionProps } from "../../education/types";
import { TECH_STACK } from "../../projects/constants";
import { checkHasEmptyFields } from "../../../helpers";
import {
  CERTIFICATES,
  EXPIRATION_DATE,
  ISSUED_DATE,
  TITLE,
  URL_PATH,
  URL_REGEXP_PATTERN,
  PLACEHOLDER,
} from "../constants";
import { EXPERIENCE_ACCOMPLISHMENT_EDIT } from "../../../../../../shared/data-cy";
import { ITechStackProposal } from "../../../types";
import {
  DATE_FORMAT,
  FULL_TECH_SEARCH_MAX,
} from "../../../../../../shared/constants";
import styles from "../../styles.module.css";

const MAX_LENGTH_NAME = 100;

const CertificatesForm = ({
  fullTextSearch,
  index,
  sourcePath = "certificates",
  technologyDict,
}: FormSectionProps): ReactElement => {
  const { control, getValues, watch, register, getFieldState, formState } =
    useFormContext();
  const { t } = useTranslationWithNamespace();
  const BASE_PATH = `${EXPERIENCE_ACCOMPLISHMENT_EDIT}_${CERTIFICATES}`;

  const [searchedProposals, setSearchedProposals] = useState<
    ITechStackProposal[] | []
  >([]);

  const translate = (field: string, isRequired = true): string =>
    `${t(`profile.experience.cards.certificates.${field}`)}${
      isRequired ? "*" : ""
    }`;

  const getFormFieldName = (fieldName: string): string =>
    `${sourcePath}.${index}.${fieldName}`;

  const fieldTitle = getFieldState(getFormFieldName(TITLE), formState);
  const fieldUrlPath = getFieldState(getFormFieldName(URL_PATH), formState);

  const hasEmptyFields = checkHasEmptyFields(
    getValues([TITLE, ISSUED_DATE, TECH_STACK]),
  );

  const errorEmptyMessage = `${t("profile.errors.cantBeEmpty")}`;
  const errorBlankMessage = `${t("profile.errors.cantBeBlank")}`;
  const errorMaxLengthMessage = `${t("profile.errors.cantBeMoreChars", {
    number: MAX_LENGTH_NAME,
  })}`;

  return (
    <div
      className="flex flex-col gap-y-5"
      key={uid(`${CERTIFICATES}-${index}`)}
    >
      <div className="flex flex-row gap-x-5 w-2/3">
        <Label dataCy={`${BASE_PATH}_${TITLE}`} text={translate(TITLE)}>
          <Input
            dataCy={`${BASE_PATH}_${TITLE}`}
            width="w-full"
            autoFocus
            value={watch(getFormFieldName(TITLE)) || ""}
            error={!!fieldTitle?.error}
            message={fieldTitle?.error?.message || ""}
            placeholder={translate(`${TITLE}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(TITLE), {
              validate: (value) => {
                if (!value?.length && hasEmptyFields) return errorEmptyMessage;
                if (!value?.trim().length) return errorBlankMessage;
                if (value?.length > MAX_LENGTH_NAME)
                  return errorMaxLengthMessage;
                return true;
              },
            })}
          />
        </Label>
        {/* <Label
          dataCy="pdf"
          text={translate("pdf", false)}
        >
          <Button dataCy="upload" additionalClass="h-8.5">
            <span className="text-primary-100 flex flex-row items-center">
              <UploadIcon className="mr-2" />
              {t("profile.experience.actions.upload")}
            </span>
          </Button>
        </Label> */}
      </div>
      <div className="flex flex-row gap-x-5 w-full pr-5">
        <div className="w-1/2">
          <Label
            dataCy={`${BASE_PATH}_${URL_PATH}`}
            text={translate(URL_PATH, false)}
          >
            <Input
              dataCy={`${BASE_PATH}_${URL_PATH}`}
              width="w-full"
              value={watch(getFormFieldName(URL_PATH)) || ""}
              error={!!fieldUrlPath?.error}
              message={fieldUrlPath?.error?.message || ""}
              placeholder={translate(`${URL_PATH}${PLACEHOLDER}`, false)}
              {...register(getFormFieldName(URL_PATH), {
                required: false,
                pattern: {
                  value: URL_REGEXP_PATTERN,
                  message: `${t(
                    "profile.experience.errors.urlPatternNotMatch",
                  )}`,
                },
                onChange: (event) =>
                  event.target.value.length
                    ? checkUrl(event.target.value)
                    : event.target.value,
              })}
            />
          </Label>
        </div>
        <div className="flex flex-row gap-x-5">
          <Label
            dataCy={`${BASE_PATH}_${ISSUED_DATE}`}
            text={translate(ISSUED_DATE)}
          >
            <Controller
              name={getFormFieldName(ISSUED_DATE)}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Datepicker2
                  dataCy={`${BASE_PATH}_${ISSUED_DATE}`}
                  error={error?.message}
                  onChange={onChange}
                  selected={value ? dayjs(value) : null}
                  dateFormat="MM/YYYY"
                  timeView={["month", "year"]}
                  type="month"
                />
              )}
              rules={{
                validate: (value) => {
                  if (!value && hasEmptyFields) return errorEmptyMessage;
                  if (value && dayjs().isBefore(value))
                    return `${t("profile.errors.noFutureDate")}`;
                  return true;
                },
              }}
            />
          </Label>
          <Label
            dataCy={`${BASE_PATH}_${EXPIRATION_DATE}`}
            text={translate(EXPIRATION_DATE, false)}
          >
            <Controller
              name={getFormFieldName(EXPIRATION_DATE)}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Datepicker2
                  dataCy={`${BASE_PATH}_${EXPIRATION_DATE}`}
                  error={error?.message}
                  onChange={onChange}
                  selected={value ? dayjs(value) : null}
                  dateFormat="MM/YYYY"
                  timeView={["month", "year"]}
                  type="month"
                />
              )}
              rules={{
                validate: (value) => {
                  const issuedDate = getValues(getFormFieldName(ISSUED_DATE));
                  return value && issuedDate.isAfter(value)
                    ? `${t("profile.errors.noEarlierDate", {
                        date: issuedDate.format(DATE_FORMAT),
                      })}`
                    : true;
                },
              }}
            />
          </Label>
        </div>
      </div>
      <div className="w-1/2 pr-2.5">
        <Label
          text={translate(TECH_STACK)}
          dataCy={`${BASE_PATH}_${TECH_STACK}`}
        >
          <Controller
            name={getFormFieldName(TECH_STACK)}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SearchSelectPicker
                dataCy={TECH_STACK}
                additionalTagsWrapClassName={styles.techWrapperOther}
                defaultSelected={prepareTechStackDictionaryValues(
                  value,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  technologyDict,
                )}
                onTagSelected={onChange}
                proposalTags={searchedProposals}
                onInputChange={(searchValue) => {
                  const result =
                    fullTextSearch &&
                    fullTextSearch(searchValue, FULL_TECH_SEARCH_MAX);
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setSearchedProposals(result);
                }}
                error={!!error}
                message={error?.message}
              />
            )}
            rules={{
              validate: (value) =>
                !value.length && hasEmptyFields ? errorEmptyMessage : true,
            }}
          />
        </Label>
      </div>
    </div>
  );
};

export default CertificatesForm;
