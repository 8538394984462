import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { IconProps } from "./types";
import { ReactComponent as ProfileIcon } from "../../images/avatar-icon.svg";
import { ICON_PREFIX } from "../../shared/data-cy";
import { useThemeMode } from "../theme-mode-provider";

const wrapperStyleGenerator = classJoin.props<
  Pick<
    IconProps,
    "width" | "size" | "height" | "backgroundColor" | "cursorType"
  >
>`
rounded-md flex items-center justify-center overflow-hidden
${({ width, size }) => `w-${width ?? size}`}
${({ size, height }) => `h-${height ?? size}`}
${({ backgroundColor }) => `bg-${backgroundColor}`}
${({ cursorType }) => `cursor-${cursorType}`}
`;

const Icon = ({
  mode,
  backgroundColor = "neutralPrimary-85",
  icon,
  src,
  alt,
  dataCy,
  size = "14",
  width,
  height,
  defaultIcon,
  invertColorsWhenDark,
  onClick,
  cursorType = "default",
}: IconProps): ReactElement => {
  const { isDarkTheme } = useThemeMode();
  const darkModeStyle =
    isDarkTheme && invertColorsWhenDark ? "filter invert" : "";

  const wrapperStyle = wrapperStyleGenerator({
    width,
    height,
    size,
    backgroundColor,
    cursorType,
  });

  if (mode === "avatar" || src || alt) {
    const innerComponent = !src ? (
      defaultIcon ?? (
        <ProfileIcon
          className="text-primary-50"
          data-cy={`${ICON_PREFIX}-${dataCy}-default-avatar`}
        />
      )
    ) : (
      <img
        src={src}
        alt={alt}
        className={classJoin("h-full w-full object-cover", darkModeStyle)}
      />
    );

    return (
      <div className={wrapperStyle} data-cy={`${ICON_PREFIX}-${dataCy}`}>
        {innerComponent}
      </div>
    );
  }

  return (
    <div
      onClick={onClick}
      className={wrapperStyle}
      data-cy={`${ICON_PREFIX}-${dataCy}`}
      role="presentation"
    >
      {icon}
    </div>
  );
};

export default Icon;
