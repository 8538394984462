import { Link, useLocation } from "react-router-dom";
import { ReactElement } from "react";
import { changeSeparator, classJoin } from "@ps/utils";
import { useThemeMode } from "../theme-mode-provider";
import { NavLinkProps } from "./types";
import { NAV_LINK } from "../../shared/data-cy";
import { getTabStatusStyles } from "./utils";
import NavIcon from "./navIcon";
import TooltipContainer from "./tooltipContainer";
import { Keys } from "../../shared";

const NavLink = ({
  Icon,
  label,
  parentPath,
  path,
  isMenuOpened,
  onItemEnter,
  defaultFocusID,
}: NavLinkProps): ReactElement => {
  const { pathname } = useLocation();
  const { isHighContrast } = useThemeMode();
  const innerLinkStyle = parentPath ? "px-10 capitalize" : "uppercase";
  const pathTo = parentPath ? `/${parentPath}/${path}` : `/${path}`;

  const isActive: boolean = pathname.includes(
    parentPath ? `/${parentPath}/${path}` : `/${path}`,
  );

  const labelToDisplay: string = changeSeparator(label);

  return (
    <TooltipContainer isMenuOpened={isMenuOpened} label={labelToDisplay}>
      <Link
        id={`navbar_${path}`}
        onKeyDown={(e) => {
          if (e.key === Keys.ENTER && defaultFocusID && onItemEnter) {
            onItemEnter(defaultFocusID);
          }
        }}
        tabIndex={0}
        role="button"
        className={classJoin(
          isMenuOpened ? "grid grid-cols-6 gap-0.5" : "flex justify-center",
          "items-center select-none h-12 px-6 mx-3 my-2 rounded-lg z-0",
          getTabStatusStyles(isActive, isHighContrast),
          innerLinkStyle,
        )}
        data-cy={`${NAV_LINK}-${pathTo}`}
        to={pathTo}
      >
        <NavIcon Icon={Icon} isActive={isActive} />
        {isMenuOpened ? (
          <span className="col-span-5">{labelToDisplay}</span>
        ) : (
          <></>
        )}
      </Link>
    </TooltipContainer>
  );
};

export default NavLink;
