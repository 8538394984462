import { ReactElement } from "react";
import LinkButton from "../../../link";
import { ReactComponent as SkillsIcon } from "../../../../images/profile-summary/skills.svg";
import { PROFILE_SUMMARY_SKILLS } from "../../../../shared/data-cy";
import { CardHeader } from "../../../expanded-card";
import { ReactComponent as EditIcon } from "../../../../images/edit-icon.svg";
import { useTranslationWithNamespace } from "../../../../hooks";
import { EmptySkillsProps } from "./types";
import Card from "../../../card";

const BASE_PATH = "profileSummary";

const EmptySkills = ({ onEdit }: EmptySkillsProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Card
      dataCy={PROFILE_SUMMARY_SKILLS}
      additionalCardClassName="h-96 flex justify-between items-start"
    >
      <CardHeader
        additionalClassName="p-6"
        dataCy={`${PROFILE_SUMMARY_SKILLS}_skills`}
        Icon={SkillsIcon}
      >
        <span>{t(`${BASE_PATH}.skills`)}</span>
      </CardHeader>
      <LinkButton
        dataCy={`${PROFILE_SUMMARY_SKILLS}_summary_edit`}
        onClick={onEdit}
        prefixIcon={<EditIcon />}
        additionalClassName="right-0 pr-4 pt-4"
      >
        {t(`${BASE_PATH}.edit`)}
      </LinkButton>
    </Card>
  );
};

export default EmptySkills;
