import { ReactElement } from "react";
// eslint-disable-next-line prodigal-son/no-use-mapped-state
import { useMappedState } from "redux-react-hook";
import Bio from "./bio";
import Availability from "./availability";
import Languages from "./languages";
import {
  AVAILABILITY,
  BASIC_INFO,
  BIO,
  CURRENT_YEAR,
  GOALS_CURRENT_YEAR,
  GOALS_PREVIOUS_YEAR,
  LANGUAGES,
  PREVIOUS_YEAR,
  WORK,
} from "../constants";
import {
  ViewModeMapState,
  ViewModeMapStateReturn,
  ViewModeProps,
} from "./types";
import Work from "./work";
import BasicInfo from "./basicInfo";
import { useMappedStateSelector } from "../../../../hooks";
import DevelopmentGoals from "./developmentGoals";

const ViewMode = ({ section, profile }: ViewModeProps): ReactElement => {
  const mapState = (state: ViewModeMapState): ViewModeMapStateReturn => ({
    login: state.login,
    myProfile: state?.profiles?.myProfile,
  });

  // eslint-disable-next-line prodigal-son/no-use-mapped-state
  const { login } = useMappedState(mapState);
  const { myProfile } = useMappedStateSelector(mapState);

  switch (section.title) {
    case BIO:
      return <Bio {...(profile || myProfile)} title={section.title} />;
    case BASIC_INFO:
      return (
        <BasicInfo
          {...(profile || myProfile)}
          mainProfession={
            myProfile?.professions?.main || profile?.professions?.main
          }
          professions={
            myProfile?.professions?.secondary || profile?.professions?.secondary
          }
          fields={section.items}
          title={section.title}
          firstName={login?.firstName || profile?.firstName}
          lastName={login?.secondName || profile?.lastName}
        />
      );

    case WORK:
      return <Work {...(profile || myProfile)} title={section.title} />;
    case AVAILABILITY:
      return (
        <Availability
          title={section.title}
          profileData={profile || myProfile}
        />
      );
    case LANGUAGES:
      return <Languages {...(profile || myProfile)} title={section.title} />;
    case GOALS_CURRENT_YEAR:
      return (
        <DevelopmentGoals
          {...(profile || myProfile)}
          title={section.title}
          year={CURRENT_YEAR}
        />
      );
    case GOALS_PREVIOUS_YEAR:
      return (
        <DevelopmentGoals
          {...(profile || myProfile)}
          title={section.title}
          year={PREVIOUS_YEAR}
        />
      );
    default:
      return <></>;
  }
};
export default ViewMode;
