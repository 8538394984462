import { ReactElement, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { classJoin } from "@ps/utils";
import { ReactComponent as NoAvatarIcon } from "../../../images/profile-summary/no-avatar.svg";
import { ReactComponent as Location } from "../../../images/profile-summary/location.svg";
import { ReactComponent as RightArrowIcon } from "../../../images/profile-summary/rightArrow.svg";
import { BaseInfoProps } from "../types";
import { PROFILE_SUMMARY_BASE_INFO } from "../../../shared/data-cy";
import Icon from "../../icon";
import { useTranslationWithNamespace } from "../../../hooks";
import Avatar from "../../avatar";

dayjs.extend(timezone);

const padding = "p-8";

const shortBioLimit = 650;

const BaseInfo = ({
  avatar,
  bio,
  residence,
  userName,
  lastUpdate,
  timeZone,
}: BaseInfoProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [isBioExpanded, setIsBioExpanded] = useState<boolean>(false);

  const displayBio = (actualBio?: string): string => {
    if (!actualBio) return "";
    return actualBio.length > shortBioLimit && !isBioExpanded
      ? actualBio.substring(0, shortBioLimit)
      : actualBio;
  };

  const renderAvatar = (): ReactElement =>
    avatar ? (
      <Avatar
        image={avatar}
        dataCy={PROFILE_SUMMARY_BASE_INFO}
        width={40}
        height={40}
      />
    ) : (
      <div>
        <Icon
          icon={
            <NoAvatarIcon className="fill-current text-neutralSecondary-60 w-full h-full" />
          }
          dataCy={PROFILE_SUMMARY_BASE_INFO}
          width="40"
          height="40"
        />
      </div>
    );

  const renderNameAndLocation = (additionalClassName = ""): ReactElement => (
    <div className={classJoin("flex", additionalClassName)}>
      <span className="font-semibold text-lg pr-4">{userName}</span>
      {residence && (
        <span className="flex items-center gap-1">
          <Location className="text-primary-50 fill-current" />
          {residence}
        </span>
      )}
    </div>
  );

  const renderBio = (): ReactElement => (
    <>
      <p className="pt-3 text-justify whitespace-pre-wrap">
        {displayBio(bio ?? "")}
      </p>
      {bio && bio.length >= shortBioLimit && (
        <div
          className="flex items-center gap-1 text-primary-50 text-sm cursor-pointer font-normal pt-2"
          onClick={() => setIsBioExpanded((prev) => !prev)}
        >
          <span>
            {isBioExpanded
              ? t("profileSummary.seeLess")
              : t("profileSummary.seeMore")}
          </span>
          <RightArrowIcon
            className={classJoin(
              "mt-1",
              isBioExpanded && "transform duration-200 rotate-180",
            )}
          />
        </div>
      )}
    </>
  );

  const renderLastUpdate = (): ReactElement => {
    const formatDate = (date: Dayjs): string =>
      date.format("DD.MM.YYYY, HH:mm");

    return lastUpdate ? (
      <div className="mb-3 text-neutralSecondary-41 text-sm">
        {t("profileSummary.lastUpdate", {
          date: timeZone
            ? formatDate(dayjs(lastUpdate).tz(timeZone.value))
            : formatDate(dayjs(lastUpdate)),
        })}
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div className="flex flex-col h-auto w-full md:w-full">
      <div
        className={classJoin(
          "gap-4 flex-col md:flex-row hidden sm:flex",
          padding,
        )}
      >
        {renderAvatar()}
        <div>
          {renderLastUpdate()}
          {renderNameAndLocation("items-center pb-2")}
          {renderBio()}
        </div>
      </div>
      <div className={classJoin("flex sm:hidden flex-col", padding)}>
        <div className="flex gap-4 items-center">
          {renderAvatar()}
          {renderNameAndLocation("flex-col items-start gap-2")}
        </div>
        {renderBio()}
      </div>
    </div>
  );
};

export default BaseInfo;
