import { ReactElement } from "react";
import { MiddleSectionProps } from "./types";
import { MIDDLE_SECTION_PREFIX } from "../../shared/data-cy";

const MiddleSection = ({
  children,
  dataCy,
}: MiddleSectionProps): ReactElement => (
  <div
    data-cy={`${MIDDLE_SECTION_PREFIX}-${dataCy}`}
    className="middle-section"
  >
    {children}
  </div>
);

export default MiddleSection;
