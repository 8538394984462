import {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { AnyAction } from "redux";
import { useDispatch } from "redux-react-hook";
import { getNameFromDictionary } from "@ps/utils";
import { Checkbox, TableLabel, CircleButton, Input } from "@ps/ui-components";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../hooks";
import { matchIconToFeedback } from "../../../project-domain";
import { REASON, NAME, ROLES } from "../../constants";
import { BenchUserModel } from "../../models";
import { filterBenchUsersByProperty } from "../../store";
import { LabelProps, LabelMapState, LabelMapStateReturn } from "./types";
import { BENCH } from "../../../shared/data-cy";
import Keys from "../../../shared/keys";

const Label = ({
  benchUsers,
  dict,
  field,
  filterOptions = undefined,
  setSearchValue,
  searchValue,
}: LabelProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const DROPDOWN_ID = "filter-dropdown";

  const mapState = (state: LabelMapState): LabelMapStateReturn => ({
    filters: state.bench.filters,
  });

  const { filters } = useMappedStateSelector(mapState);

  useEffect(() => {
    if (showFilterOptions) document.getElementById(DROPDOWN_ID)?.focus();
  }, [showFilterOptions]);

  const countBenchUsersWithSpecificOption = (optionId: string): number => {
    const filteredBenchUsers: BenchUserModel[] =
      benchUsers?.filter((user: BenchUserModel): boolean =>
        field === ROLES
          ? user[field].includes(optionId)
          : user[field] === optionId,
      ) || [];
    return filteredBenchUsers?.length;
  };

  return (
    <TableLabel
      popupStyle={field === REASON ? "right-0" : "left-0"}
      setSearchValue={setSearchValue}
      dataCy={`${BENCH}_${field}`}
      filtering={
        filterOptions
          ? {
              checked: filters?.[field]?.map((item: string): string =>
                field === REASON ? t(`project.team.feedbacks.${item}`) : item,
              ),
              dropdownOverlay: (
                <div
                  className="flex flex-col p-4 gap-4 h-auto overflow-y-auto"
                  id={DROPDOWN_ID}
                  style={{ maxHeight: "50vh" }}
                  tabIndex={-1}
                >
                  <Input
                    autoFocus
                    isSearch
                    dataCy={BENCH}
                    defaultValue={searchValue}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                      setSearchValue(event.target.value?.toLowerCase())
                    }
                    // eslint-disable-next-line
                    // @ts-ignore
                    onKeyDown={(
                      event: KeyboardEvent<HTMLInputElement>,
                    ): void => {
                      if (event.key === Keys.ESC) setShowFilterOptions(false);
                    }}
                  />
                  {!filterOptions.length && (
                    <span>
                      {t(`${BENCH}.sortBar.noResults`, {
                        searchValue,
                      })}
                    </span>
                  )}
                  {filterOptions?.map((option: string): ReactElement => {
                    const isChecked: boolean = filters[field]?.includes(option);

                    return (
                      <div
                        className="flex items-center gap-2 justify-between"
                        key={option}
                        tabIndex={0}
                        role="button"
                        onClick={(): AnyAction =>
                          filterBenchUsersByProperty(
                            dispatch,
                            field,
                            option,
                            isChecked,
                          )
                        }
                        onKeyDown={(
                          event: KeyboardEvent<HTMLInputElement>,
                        ): void => {
                          if (event?.code === Keys.SPACE) {
                            event.preventDefault();
                            filterBenchUsersByProperty(
                              dispatch,
                              field,
                              option,
                              isChecked,
                            );
                          }
                        }}
                      >
                        <div className="flex items-center gap-2">
                          <Checkbox
                            dataCy={`${BENCH}_filter_${option}`}
                            checked={isChecked}
                            tabIndex={-1}
                          />
                          {field === REASON ? (
                            <div className="flex items-center gap-2">
                              {matchIconToFeedback(option)}
                              {t(`project.team.feedbacks.${option}`)}
                            </div>
                          ) : (
                            <span className="capitalize">
                              {dict
                                ? getNameFromDictionary(dict, option)
                                : option}
                            </span>
                          )}
                        </div>
                        {field !== NAME ? (
                          <CircleButton
                            activeNumber={countBenchUsersWithSpecificOption(
                              option,
                            )}
                            width="w-6"
                            height="w-6"
                            textSize="text-sm font-semibold"
                            nonActiveStyles="border-primary-80 text-primary-50 bg-secondary-95"
                            dataCy={BENCH}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </div>
              ),
            }
          : undefined
      }
    >
      {t(`${BENCH}.${field}`)}
    </TableLabel>
  );
};

export default Label;
