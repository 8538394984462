import { removeDiacriticsFromString } from "@ps/utils";
import { FolderModel } from "../models";
import { FilterItem, Filters, IMappedFolderListElement } from "../ui/types";
import { CLIENT_NAME } from "../constants";
import {
  compareNormalizedStrings,
  removeFilterDuplicates,
} from "../../prospect-domain/utils/filterProspectsList";

export const filterFoldersList = (
  allFolders: IMappedFolderListElement[],
  searchValue: string,
): IMappedFolderListElement[] | [] =>
  allFolders?.length
    ? allFolders.filter((folder: FolderModel) =>
        removeDiacriticsFromString(
          folder?.folderName?.toLowerCase() || "",
        ).includes(removeDiacriticsFromString(searchValue.toLowerCase())),
      )
    : [];

export const filterFoldersByFields = (
  folders: IMappedFolderListElement[],
  searchValue: string,
  filters?: Filters,
): IMappedFolderListElement[] => {
  const preparedToFilter: IMappedFolderListElement[] = !searchValue?.length
    ? folders
    : folders.filter((item: IMappedFolderListElement) =>
        compareNormalizedStrings(item?.folderName, searchValue),
      );

  if (!filters) return preparedToFilter;

  let result: IMappedFolderListElement[] = preparedToFilter;

  if (filters[CLIENT_NAME]?.length && result.length) {
    result = result.filter((singleFolder: IMappedFolderListElement) =>
      filters[CLIENT_NAME].some(
        ({ id }: FilterItem) => id === singleFolder.clientName,
      ),
    );
  }

  return result;
};

export const prepareClientFilterOptions = (
  users: IMappedFolderListElement[],
): FilterItem[] => {
  const temp = JSON.parse(JSON.stringify(users));
  const result: FilterItem[] = temp
    .filter((folder: IMappedFolderListElement) => folder[CLIENT_NAME])
    .map(
      (item: IMappedFolderListElement): FilterItem => ({
        name: item[CLIENT_NAME],
        id: item[CLIENT_NAME] || "",
      }),
    );

  return removeFilterDuplicates(result);
};
