import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import styles from "../styles.module.css";

const ProgressBar = (): ReactElement => {
  return (
    <div className="w-full h-2 rounded-md bg-neutralPrimary-100 flex items-center p-1">
      <div
        className={classJoin(
          styles.progressBar,
          "bg-other-toxicGreen h-2 rounded-md flex items-center",
        )}
      >
        <div
          className={classJoin(
            styles.overlay,
            "h-full bg-neutralPrimary-100 rounded-md h-2",
          )}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
