import { OperativeDomainModel } from "../../models";
import {
  PreparedDictionaryEntry,
  NewDictionaryEntry,
  DictionaryModel,
} from "../../../dictionaries";
import { ReactElement } from "react";

export enum ExperienceSections {
  PROJECTS = "projects",
  EDUCATION = "education",
  ACCOMPLISHMENT = "accomplishment",
}

export interface RouterProps {
  selectedSection: ExperienceSections;
}

export interface IExperience {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;

  techStack?: (PreparedDictionaryEntry | NewDictionaryEntry)[];
}

export interface ISubmitData {
  [key: string]: IExperience[];
}

export interface ITechStackProposal {
  deleted?: boolean;
  desc?: null;
  iconUrl?: null;
  id: string;
  name: string;
  normalizedName: string;
}

export interface Tag<IdType> {
  id: IdType;
  name: string;
}

export interface ExperienceMapState {
  profiles: { myProfile: OperativeDomainModel };
  dictionaries: { technology: DictionaryModel | [] };
  editMode: string;
}

export interface ExperienceMapStateReturn {
  myProfile: OperativeDomainModel;
  technologyDict: DictionaryModel | [];
  editMode: string;
}

export interface SectionFormTab<T> {
  icon?: ReactElement;
  title: string;
  children?: ReactElement;
  collapsed?: boolean;
  tabKey: T;
  child: ReactElement | ReactElement[];
  disabled?: boolean;
  fields?: string[];
  id: string;
}
