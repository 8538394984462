// eslint-disable-next-line prodigal-son/no-use-mapped-state
import { useMappedState } from "redux-react-hook";
import { useCallback } from "react";

const getSelectedState = <State, Result>(
  state: State,
  getResultState: (state: State | State[keyof State]) => Result,
  selector: keyof State,
): Result => {
  const preparedState = state[selector] ? state[selector] : state;
  return getResultState(preparedState);
};

// TODO how automatically detect module and set selector????!
function useMappedStateSelector(
  getMyMapState: (state: any) => any,
  selector = "user",
) {
  // eslint-disable-next-line prodigal-son/no-use-mapped-state
  return useMappedState(
    useCallback(
      (state) => getSelectedState(state, getMyMapState, selector),
      [getMyMapState, selector],
    ),
  );
}

export default useMappedStateSelector;
