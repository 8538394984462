import { ChangeEvent, KeyboardEvent, ReactElement, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { Controller, useFormContext } from "react-hook-form";
import countries from "i18n-iso-countries";
import { clearRedirection } from "@ps/alert-modal";
import {
  classJoin,
  mapTechnologyWithOnlyAlphanumeric,
  prepareDictionarySearchSelect,
  prepareTechStackDictionaryValues,
  useFullTextSearch,
} from "@ps/utils";
import {
  Button,
  ColorPicker,
  Input,
  Textarea,
  Label,
  SearchSelect,
  SearchSelectPicker,
  ScrollableWrapper,
} from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import ControlledInput from "../../../common/controlledInput";
import {
  ACCOUNT_NUMBER,
  ADD,
  ADDRESS,
  BD,
  BUSINESS_DEVELOPER,
  CLIENT_ID,
  CLIENT_NAME,
  COLOR,
  COMPANY_NAME,
  CONTACT_PERSON,
  COUNTRY,
  DOMAIN,
  EMAIL,
  EMAIL_REGEXP_PATTERN,
  FIRST_NAME,
  INVOICE_DETAILS,
  NEW_PROJECT,
  PHONE_NUMBER,
  PM,
  PROJECT_MANAGER,
  PROJECT_NAME,
  projectDefaultColors,
  TAX_NUMBER,
  TECH_STACK,
  ZIP_CODE,
  GENERAL_NOTE,
  GENERAL_NOTE_PLACEHOLDER,
  DISABLED_ERROR,
} from "../../../../constants";
import { getClientData } from "../../../../../shared/utils/clients-utils";
import { fetchClients } from "../../../../../client-domain";
import AddNewClientModal from "../../../../../client-domain/ui/addNewClientModal";
import { ReactComponent as ErrorIcon } from "../../../../../images/error.svg";
import {
  focusVisibleStyles,
  inputBaseStyle,
  Keys,
  wrapperStyle,
  filterArchivedUsers,
  mapUsersToValueLabel,
  filterArchivedClients,
} from "../../../../../shared";
import { ReactComponent as AddNewColorIcon } from "../../../../../images/project/add-new-color.svg";
import {
  EditDetailsMapState,
  EditDetailsMapStateReturn,
  ITechStackProposal,
} from "./types";
import { toggleEditMode } from "../../../../../store";
import { ReactComponent as LockIcon } from "../../../../../images/project/lock.svg";
import { UserDomainModel } from "../../../../../../index";

const TRANSLATE_PATH = "project.details";

const EditDetails = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const { control, getValues, setValue, reset } = useFormContext();

  const [isAddClientVisible, setIsAddClientVisible] = useState(false);

  const mapState = (state: EditDetailsMapState): EditDetailsMapStateReturn => ({
    domainDict: state.dictionaries?.domain || [],
    technologyDict: state.dictionaries?.technology || [],
    clients: state.clients?.clientsList || [],
    users: state.users || [],
    isPublic: state.projects.project.public,
    details: state.projects.project.details,
  });

  const { clients, domainDict, technologyDict, users, isPublic, details } =
    useMappedStateSelector(mapState);

  const [searchedProposals, setSearchedProposals] = useState<
    ITechStackProposal[] | []
  >([]);

  const { search } = useFullTextSearch(
    technologyDict,
    mapTechnologyWithOnlyAlphanumeric,
  );

  const getUserData = (value) =>
    value && value.userId
      ? {
          userId: value.userId,
          value: value.userId,
          label: `${
            users.find((item) => item.id === value.userId)?.firstName
          } ${users.find((item) => item.id === value.userId)?.secondName}`,
        }
      : value;

  const allCountries = countries.getNames("en", { select: "official" });

  const isBlankOrMoreChars = (value: string, maxChars = 100) => {
    if (value && !value.trim().length)
      return `${t("projects.errors.cantBeBlank")}`;
    if (value && value.length > maxChars)
      return `${t("project.errors.cantBeMoreThan", { number: maxChars })}`;
    return true;
  };

  const handleOnEsc = () => {
    reset();
    dispatch(toggleEditMode(""));
    dispatch(clearRedirection());
  };

  const [isColorPickerActive, setIsColorPickerActive] = useState(false);

  const mappedUsersValueLabel = mapUsersToValueLabel(
    filterArchivedUsers(users),
  );

  return (
    <section
      className="w-full h-full p-9 flex pb-16 relative"
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (
          event.key === Keys.ESC &&
          !isAddClientVisible &&
          !isColorPickerActive
        )
          handleOnEsc();
      }}
      role="presentation"
    >
      <div className="flex flex-col gap-y-8 w-9/12 pr-9">
        <div className="flex gap-x-6 w-full">
          <Label
            dataCy={`${TRANSLATE_PATH}_${PROJECT_NAME}`}
            labelFontWeight="font-bold"
            labelFontSize="text-lg"
            labelColor="text-neutralPrimary-20"
            text={`${t(`${TRANSLATE_PATH}.${PROJECT_NAME}`)}*`}
          >
            <div className="flex items-center w-full gap-2">
              {!isPublic && (
                <div>
                  <LockIcon className="mr-3" fill={`${details?.color}`} />
                </div>
              )}
              <Controller
                name={PROJECT_NAME}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className={classJoin(wrapperStyle, "w-full")}>
                    <input
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChange(event.target.value)
                      }
                      defaultValue={value}
                      className={classJoin(
                        inputBaseStyle,
                        error
                          ? "border-error-50 focus:border-error-50 placeholder-error-50"
                          : "border-neutralSecondary-60 placeholder-neutralSecondary-41 focus:border-secondary-50",
                      )}
                      autoFocus
                      placeholder={`${t(`${TRANSLATE_PATH}.${PROJECT_NAME}`)}*`}
                    />
                    <span className="mt-1 text-xs text-error-50">
                      {error?.message ?? ""}
                    </span>
                    <span className="absolute right-2 top-0 transform translate-y-1/3">
                      {error && (
                        <ErrorIcon className="text-error-50 fill-current" />
                      )}
                    </span>
                  </div>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("projects.errors.cantBeEmpty"),
                  },
                  validate: (value) => isBlankOrMoreChars(value),
                }}
              />
            </div>
          </Label>
          <Label
            dataCy={`${TRANSLATE_PATH}_${COLOR}`}
            text={t(`${TRANSLATE_PATH}.${COLOR}`)}
            labelFontWeight="font-bold"
            labelFontSize="text-lg"
            labelColor="text-neutralPrimary-20"
          >
            <Controller
              name={COLOR}
              control={control}
              render={({ field: { onChange, value } }) => (
                <ColorPicker
                  dataCy={`${TRANSLATE_PATH}_${COLOR}`}
                  color={value || ""}
                  onChange={onChange}
                  companyColors={["#7788e3", "#5066d9", "#2f49d1"]}
                  defaultColors={projectDefaultColors}
                  setIsColorPickerActive={setIsColorPickerActive}
                >
                  <span
                    tabIndex={0}
                    role="button"
                    className={classJoin(
                      `border border-dashed flex justify-center
                   items-center w-10 h-10 rounded-full cursor-pointer`,
                      focusVisibleStyles,
                    )}
                    style={{
                      backgroundColor: getValues(COLOR) || value,
                    }}
                  >
                    <AddNewColorIcon className="stroke-current text-neutralSecondary-60" />
                  </span>
                </ColorPicker>
              )}
            />
          </Label>
        </div>

        <Label
          dataCy={`${NEW_PROJECT}-note`}
          text={`${t(`${TRANSLATE_PATH}.${GENERAL_NOTE}`)}*`}
          labelFontWeight="font-bold"
          labelFontSize="text-lg"
          labelColor="text-neutralPrimary-20"
        >
          <Controller
            name="note"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Textarea
                dataCy={`${TRANSLATE_PATH}-${GENERAL_NOTE}`}
                height="h-28"
                placeholder={`${t(
                  `${TRANSLATE_PATH}.${GENERAL_NOTE_PLACEHOLDER}`,
                )}`}
                additionalClassName=":placeholder: text-base"
                error={!!error}
                message={error?.message}
                onChange={onChange}
                defaultValue={value}
              />
            )}
            rules={{
              required: {
                value: true,
                message: `${t("project.errors.cantBeEmpty")}`,
              },
            }}
          />
        </Label>

        <div className="flex gap-x-6">
          <Label
            text={t(`${TRANSLATE_PATH}.${DOMAIN}`)}
            dataCy={`${NEW_PROJECT}_${DOMAIN}`}
            labelFontWeight="font-bold"
            labelFontSize="text-lg"
            labelColor="text-neutralPrimary-20"
          >
            <Controller
              control={control}
              name={DOMAIN}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SearchSelect
                  multiple
                  dataCy={`${TRANSLATE_PATH}_${DOMAIN}`}
                  width="w-full"
                  height={value ? "auto" : "2.2rem"}
                  options={prepareDictionarySearchSelect(
                    domainDict,
                    "id",
                    "id",
                    "name",
                    "name",
                  )}
                  defaultValue={
                    value && value.length && typeof value[0] === "string"
                      ? value.map((item) => ({
                          id: item,
                          value: item,
                          label: domainDict.find((domain) => domain.id === item)
                            ?.name,
                        }))
                      : value
                  }
                  onChange={onChange}
                  error={!!error}
                  message={error?.message}
                  placeholder={t(`${TRANSLATE_PATH}.${DOMAIN}`)}
                />
              )}
            />
          </Label>
          <Label
            text={t(`${TRANSLATE_PATH}.${PM}`)}
            dataCy={`${NEW_PROJECT}_${PM}`}
            labelFontWeight="font-bold"
            labelFontSize="text-lg"
            labelColor="text-neutralPrimary-20"
          >
            <Controller
              control={control}
              name={PROJECT_MANAGER}
              rules={{
                validate: (value) => {
                  if (value) {
                    const user = users.find(
                      (u: UserDomainModel) => u.id === value.userId,
                    );
                    if (!user.enabled)
                      return t(`${TRANSLATE_PATH}.${DISABLED_ERROR}`);
                  }

                  return true;
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SearchSelect
                  dataCy={PROJECT_MANAGER}
                  width="w-full"
                  height="2.4rem"
                  clearable
                  options={mappedUsersValueLabel}
                  defaultValue={getUserData(value)}
                  onChange={onChange}
                  error={!!error}
                  message={error?.message}
                  placeholder={t(`${TRANSLATE_PATH}.${PM}`)}
                  isMenuOpenTop
                />
              )}
            />
          </Label>
          <Label
            text={t(`${TRANSLATE_PATH}.${BD}`)}
            dataCy={`${NEW_PROJECT}_${BD}`}
            labelFontWeight="font-bold"
            labelFontSize="text-lg"
            labelColor="text-neutralPrimary-20"
          >
            <Controller
              control={control}
              name={BUSINESS_DEVELOPER}
              rules={{
                validate: (value) => {
                  if (value) {
                    const user = users.find(
                      (u: UserDomainModel) => u.id === value.userId,
                    );
                    if (!user.enabled)
                      return t(`${TRANSLATE_PATH}.${DISABLED_ERROR}`);
                  }

                  return true;
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SearchSelect
                  dataCy={BUSINESS_DEVELOPER}
                  width="w-full"
                  height="2.4rem"
                  clearable
                  options={mappedUsersValueLabel}
                  defaultValue={getUserData(value)}
                  onChange={onChange}
                  error={!!error}
                  message={error?.message}
                  placeholder={t(`${TRANSLATE_PATH}.${BD}`)}
                  isMenuOpenTop
                />
              )}
            />
          </Label>
        </div>
        <Label
          text={t(`${TRANSLATE_PATH}.${TECH_STACK}`)}
          dataCy={`${NEW_PROJECT}_${TECH_STACK}`}
          labelFontWeight="font-bold"
          labelFontSize="text-lg"
          labelColor="text-neutralPrimary-20"
        >
          <Controller
            name={TECH_STACK}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SearchSelectPicker
                dataCy={`${NEW_PROJECT}_${TECH_STACK}`}
                defaultSelected={prepareTechStackDictionaryValues(
                  value,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  technologyDict,
                )}
                onTagSelected={onChange}
                proposalTags={searchedProposals}
                onInputChange={(searchValue) => {
                  const result = search(searchValue);
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setSearchedProposals(result);
                }}
                error={!!error}
                message={error?.message}
              />
            )}
          />
        </Label>
      </div>

      <div className="flex flex-col overflow-auto bg-neutralPrimary-100 w-3/12 right-8 p-9 -top-12 absolute rounded-2xl shadow">
        <ScrollableWrapper>
          <div className="flex flex-col gap-y-3">
            <div className="flex gap-x-6 items-center">
              <Label
                dataCy={`${TRANSLATE_PATH}_${CLIENT_NAME}`}
                text={`${t(`${TRANSLATE_PATH}.${CLIENT_NAME}`)}*`}
                labelFontWeight="font-bold"
                labelFontSize="text-lg"
                labelColor="text-neutralPrimary-20"
              >
                <Controller
                  name={CLIENT_ID}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <SearchSelect
                      dataCy={CLIENT_NAME}
                      width="w-full"
                      options={prepareDictionarySearchSelect(
                        filterArchivedClients(clients),
                        "id",
                        "id",
                        "clientName",
                        "clientName",
                      )}
                      clearable
                      value={getClientData(value, clients)}
                      onChange={onChange}
                      error={!!error}
                      message={error?.message}
                      placeholder={`${t(`${TRANSLATE_PATH}.${CLIENT_NAME}`)}*`}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: t("projects.errors.cantBeEmpty"),
                    },
                  }}
                />
              </Label>
              <Button
                dataCy={ADD}
                additionalClass="mt-8 "
                onClick={() => setIsAddClientVisible(true)}
              >
                {`+${t(`${TRANSLATE_PATH}.${ADD}`)}`}
              </Button>
              <AddNewClientModal
                fetchClients={() => fetchClients(dispatch)}
                isOpen={isAddClientVisible}
                onClose={(newClientId?: string) => {
                  if (newClientId) {
                    setValue(CLIENT_ID, newClientId);
                  }
                  setIsAddClientVisible(false);
                }}
              />
            </div>
            <Label
              dataCy={`${TRANSLATE_PATH}_${CONTACT_PERSON}_${FIRST_NAME}`}
              text={t(`${TRANSLATE_PATH}.${CONTACT_PERSON}.${FIRST_NAME}`)}
              labelFontWeight="font-bold"
              labelFontSize="text-lg"
              labelColor="text-neutralPrimary-20"
            >
              <Controller
                name={`${CONTACT_PERSON}.${FIRST_NAME}`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className={wrapperStyle}>
                    <input
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChange(event.target.value)
                      }
                      defaultValue={value}
                      className={classJoin(
                        "w-full",
                        inputBaseStyle,
                        error
                          ? "border-error-50 focus:border-error-50 placeholder-error-50"
                          : "border-neutralSecondary-60 placeholder-neutralSecondary-41 focus:border-secondary-50",
                      )}
                      placeholder={t(
                        `${TRANSLATE_PATH}.${CONTACT_PERSON}.${FIRST_NAME}`,
                      )}
                    />
                    <span className="mt-1 text-xs text-error-50">
                      {error?.message ?? ""}
                    </span>
                    <span className="absolute right-2 top-0 transform translate-y-1/3">
                      {error && (
                        <ErrorIcon className="text-error-50 fill-current" />
                      )}
                    </span>
                  </div>
                )}
                rules={{
                  validate: (value) => isBlankOrMoreChars(value),
                }}
              />
            </Label>
            <ControlledInput
              additionalClassName="p-3"
              name={`${CONTACT_PERSON}.${PHONE_NUMBER}`}
              basePath={NEW_PROJECT}
              translatePath={TRANSLATE_PATH}
              placeholder={`${CONTACT_PERSON}.${PHONE_NUMBER}`}
              additionalValidation={(value) => isBlankOrMoreChars(value)}
              defaultValue={
                details?.contactPerson?.phoneNumber
                  ? details?.contactPerson?.phoneNumber
                  : ""
              }
            />
            <Label
              dataCy={`${TRANSLATE_PATH}_${CONTACT_PERSON}_${EMAIL}`}
              text={t(`${TRANSLATE_PATH}.${CONTACT_PERSON}.${EMAIL}`)}
              labelFontWeight="font-bold"
              labelFontSize="text-lg"
              labelColor="text-neutralPrimary-20"
            >
              <Controller
                name={`${CONTACT_PERSON}.${EMAIL}`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    dataCy={`${TRANSLATE_PATH}_${CONTACT_PERSON}_${EMAIL}`}
                    defaultValue={value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      onChange(event.target.value)
                    }
                    placeholder={t(
                      `${TRANSLATE_PATH}.${CONTACT_PERSON}.${EMAIL}`,
                    )}
                    message={error?.message || ""}
                    error={!!error}
                    additionalClass="p-3"
                  />
                )}
                rules={{
                  required: false,
                  pattern: {
                    value: EMAIL_REGEXP_PATTERN,
                    message: t(`${TRANSLATE_PATH}.emailPatternNotMatch`),
                  },
                }}
              />
            </Label>
            <ControlledInput
              name={`${INVOICE_DETAILS}.${COMPANY_NAME}`}
              basePath={NEW_PROJECT}
              translatePath={TRANSLATE_PATH}
              placeholder={`${INVOICE_DETAILS}.${COMPANY_NAME}`}
              additionalValidation={(value) => isBlankOrMoreChars(value)}
              additionalClassName="p-3"
              defaultValue={
                details?.invoiceDetails?.companyName
                  ? details?.invoiceDetails?.companyName
                  : ""
              }
            />
            <ControlledInput
              name={`${INVOICE_DETAILS}.${ADDRESS}`}
              basePath={NEW_PROJECT}
              translatePath={TRANSLATE_PATH}
              placeholder={`${INVOICE_DETAILS}.${ADDRESS}`}
              additionalValidation={(value) => isBlankOrMoreChars(value, 300)}
              additionalClassName="p-3"
              defaultValue={
                details?.invoiceDetails?.address
                  ? details?.invoiceDetails?.address
                  : ""
              }
            />
            <ControlledInput
              name={`${INVOICE_DETAILS}.${ZIP_CODE}`}
              basePath={NEW_PROJECT}
              translatePath={TRANSLATE_PATH}
              placeholder={`${INVOICE_DETAILS}.${ZIP_CODE}`}
              additionalValidation={(value) => isBlankOrMoreChars(value)}
              additionalClassName="p-3"
              defaultValue={
                details?.invoiceDetails?.zipCode
                  ? details?.invoiceDetails?.zipCode
                  : ""
              }
            />
            <Label
              text={t(`${TRANSLATE_PATH}.${INVOICE_DETAILS}.${COUNTRY}`)}
              dataCy={`${NEW_PROJECT}_${INVOICE_DETAILS}_${COUNTRY}`}
              labelFontWeight="font-bold"
              labelFontSize="text-lg"
              labelColor="text-neutralPrimary-20"
            >
              <Controller
                control={control}
                name={`${INVOICE_DETAILS}.${COUNTRY}`}
                render={({ field: { onChange, value } }) => (
                  <SearchSelect
                    dataCy={`${NEW_PROJECT}_${INVOICE_DETAILS}_${COUNTRY}`}
                    defaultValue={value}
                    onChange={onChange}
                    width="w-full"
                    menuPosition="fixed"
                    clearable
                    options={Object.entries(allCountries).map(
                      ([key, name]) => ({
                        label: name,
                        value: key,
                      }),
                    )}
                    placeholder={t(
                      `${TRANSLATE_PATH}.${INVOICE_DETAILS}.${COUNTRY}`,
                    )}
                  />
                )}
              />
            </Label>
            <ControlledInput
              name={`${INVOICE_DETAILS}.${TAX_NUMBER}`}
              basePath={NEW_PROJECT}
              translatePath={TRANSLATE_PATH}
              placeholder={`${INVOICE_DETAILS}.${TAX_NUMBER}`}
              additionalValidation={(value) => isBlankOrMoreChars(value)}
              additionalClassName="p-3"
              defaultValue={
                details?.invoiceDetails?.taxNumber
                  ? details?.invoiceDetails?.taxNumber
                  : ""
              }
            />
            <ControlledInput
              name={`${INVOICE_DETAILS}.${ACCOUNT_NUMBER}`}
              basePath={NEW_PROJECT}
              translatePath={TRANSLATE_PATH}
              placeholder={`${INVOICE_DETAILS}.${ACCOUNT_NUMBER}`}
              additionalValidation={(value) => isBlankOrMoreChars(value)}
              additionalClassName="p-3"
              defaultValue={
                details?.invoiceDetails?.accountNumber
                  ? details?.invoiceDetails?.accountNumber
                  : ""
              }
            />
          </div>
        </ScrollableWrapper>
      </div>
    </section>
  );
};
export default EditDetails;
