import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { uid } from "react-uid";
import styles from "../skeletonStyle.module.css";

const skeletonStyle = classJoin(
  "w-full h-12 bg-neutralPrimary-100 rounded-md mb-4",
  "flex items-center pl-4",
  styles.skeleton,
);

const LoadingProfessionMatrixCard = (): ReactElement => (
  <div className="flex flex-col">
    {new Array(4).fill(0).map((_, index) => (
      <div className={skeletonStyle} key={uid(index)}>
        <div
          className="h-4 bg-neutralPrimary-85 rounded-md"
          style={{ width: `${Math.random() * 8 + 2}rem` }}
        />
      </div>
    ))}
  </div>
);

export default LoadingProfessionMatrixCard;
