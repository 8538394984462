import { ReactElement } from "react";
import { Input, SearchSelect } from "@ps/ui-components";
import {
  getNameFromDictionary,
  prepareDictionarySearchSelect,
} from "@ps/utils";
import { Controller, useFormContext } from "react-hook-form";
import { MAIN_PROFILE_EDIT_MODE } from "../../../../../shared/data-cy";
import {
  BASIC_INFO,
  FIRST_NAME,
  LAST_NAME,
  PROFESSIONS,
} from "../../constants";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import {
  BasicInfoOperativeMapState,
  BasicInfoOperativeMapStateReturn,
  EditProps,
  ValueLabelProps,
} from "../types";
import LabelWrapper from "../labelWrapper";

const TRANSLATION_PATH = `profile.overview.${BASIC_INFO}`;

const BasicInfoOperative = ({
  firstName,
  lastName,
}: EditProps): ReactElement => {
  const { control } = useFormContext();
  const { t } = useTranslationWithNamespace();

  const mapState = (
    state: BasicInfoOperativeMapState,
  ): BasicInfoOperativeMapStateReturn => ({
    operativeProfessionsDict: state.dictionaries?.operative_profession || [],
  });

  const { operativeProfessionsDict } = useMappedStateSelector(mapState);

  const professionsDictForSearchSelect = prepareDictionarySearchSelect(
    operativeProfessionsDict,
    undefined,
    "id",
    "name",
    "name",
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-6 w-full">
        <LabelWrapper
          translationPath={TRANSLATION_PATH}
          overviewElementPath={BASIC_INFO}
          field={FIRST_NAME}
          className="w-1/2"
        >
          <Input
            dataCy={`${MAIN_PROFILE_EDIT_MODE}_${FIRST_NAME}`}
            defaultValue={firstName || ""}
            disabled
            name={FIRST_NAME}
          />
        </LabelWrapper>
        <LabelWrapper
          translationPath={TRANSLATION_PATH}
          overviewElementPath={BASIC_INFO}
          field={LAST_NAME}
          className="w-1/2"
        >
          <Input
            dataCy={`${MAIN_PROFILE_EDIT_MODE}_${LAST_NAME}`}
            defaultValue={lastName || ""}
            disabled
            name={LAST_NAME}
          />
        </LabelWrapper>
      </div>
      <div className="flex gap-6 w-full">
        <LabelWrapper
          translationPath={TRANSLATION_PATH}
          overviewElementPath={BASIC_INFO}
          field="operativeProfessions"
          className="w-full"
        >
          <Controller
            render={({ field: { onChange, value } }) => (
              <SearchSelect
                dataCy={`${MAIN_PROFILE_EDIT_MODE}_${PROFESSIONS}`}
                onChange={onChange}
                width="w-full"
                defaultValue={value?.map((item: ValueLabelProps | string) =>
                  typeof item === "string"
                    ? {
                        value: item,
                        label: getNameFromDictionary(
                          operativeProfessionsDict,
                          item,
                        ),
                      }
                    : item,
                )}
                autoFocus
                placeholder={t(
                  `${TRANSLATION_PATH}.operativeProfessionsPlaceholder`,
                )}
                options={professionsDictForSearchSelect}
                multiple
                searchable={false}
                height="auto"
              />
            )}
            name={`${PROFESSIONS}.secondary`}
            control={control}
          />
        </LabelWrapper>
      </div>
    </div>
  );
};

export default BasicInfoOperative;
