import {
  Datepicker2,
  Input,
  Label,
  SearchSelect,
  SearchSelectPicker,
  Textarea,
} from "@ps/ui-components";
import {
  prepareDictionarySearchSelect,
  prepareTechStackDictionaryValues,
} from "@ps/utils";
import dayjs from "dayjs";
import { ChangeEvent, ReactElement, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import { DictionaryEntryModel } from "../../../../../dictionaries";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import {
  DATE_FORMAT,
  FULL_TECH_SEARCH_MAX,
} from "../../../../../shared/constants";
import { EXPERIENCE_PROJECTS_EDIT } from "../../../../../shared/data-cy";
import { checkHasEmptyFields } from "../../helpers";
import { ITechStackProposal } from "../../types";
import {
  DESCRIPTION,
  DOMAIN,
  END_DATE,
  NAME,
  PLACEHOLDER,
  PROJECTS,
  PROJECT_ROLES,
  RESPONSIBILITY,
  START_DATE,
  TECH_STACK,
} from "./constants";
import {
  ProjectFormMapState,
  ProjectFormMapStateReturn,
  ProjectFormProps,
} from "./types";
import { RoleAPIModel } from "../../../../../user-domain";
import styles from "../styles.module.css";

const MAX_LENGTH_DESCRIPTION = 4000;
const MAX_LENGTH_RESPONSIBILITIES = 300;
const MAX_LENGTH_PROJECT_NAME = 100;

const ProjectForm = ({
  index,
  sourcePath = "projects",
  isOcr = false,
  fullTextSearch,
}: ProjectFormProps): ReactElement => {
  const { control, getValues, watch, register, getFieldState, formState } =
    useFormContext();
  const { t } = useTranslationWithNamespace();

  const getFormFieldName = (fieldName: string): string =>
    `${sourcePath}.${index}.${fieldName}`;

  const fieldName = getFieldState(getFormFieldName(NAME), formState);

  const [searchedProposals, setSearchedProposals] = useState<
    ITechStackProposal[] | []
  >([]);

  const translate = (field: string, isRequired = true): string =>
    `${t(`profile.experience.cards.project.${field}`)}${isRequired ? "*" : ""}`;

  const mapState = (state: ProjectFormMapState): ProjectFormMapStateReturn => ({
    projectRolesDict: state.dictionaries.project_role,
    technologyDict: state.dictionaries.technology,
    domainDict: state.dictionaries.domain,
  });

  const { projectRolesDict, domainDict, technologyDict } =
    useMappedStateSelector(mapState);

  const hasEmptyFields = checkHasEmptyFields(
    getValues([
      DOMAIN,
      START_DATE,
      RESPONSIBILITY,
      DESCRIPTION,
      PROJECT_ROLES,
      TECH_STACK,
    ]),
  );

  const errorEmptyMessage = `${t("profile.errors.cantBeEmpty")}`;
  const errorBlankMessage = `${t("profile.errors.cantBeBlank")}`;
  const errorMaxChars = (maxLength: number): string =>
    `${t("profile.errors.cantBeMoreChars", {
      number: maxLength,
    })}`;

  const isEmptyOrBlank = (
    value: string,
    maxLength?: number,
  ): string | boolean => {
    if (!value?.length && hasEmptyFields) return errorEmptyMessage;
    if (!value?.trim().length) return errorBlankMessage;
    if (value && maxLength && value?.length > maxLength)
      return errorMaxChars(maxLength);
    return true;
  };

  const domainDictForSearchSelect = domainDict
    ?.sort((a: DictionaryEntryModel, b: DictionaryEntryModel) =>
      a.name > b.name &&
      a.name !== "Non Specified" &&
      b.name !== "Non Specified"
        ? 1
        : -1,
    )
    ?.map((domain: DictionaryEntryModel) => ({
      value: domain.id,
      label: domain.name,
    }));

  return (
    <div className="flex flex-col gap-y-5" key={uid(`${PROJECTS}-${index}`)}>
      <Label text={translate(NAME, false)} dataCy={NAME}>
        <Input
          autoFocus
          dataCy={NAME}
          error={!!fieldName?.error}
          message={fieldName?.error?.message || ""}
          placeholder={translate(NAME, false)}
          value={watch(getFormFieldName(NAME)) || ""}
          width="w-1/2"
          {...register(getFormFieldName(NAME), {
            validate: (value) => {
              if (isOcr) return true;
              if (value && !value?.trim().length) return errorBlankMessage;
              if (value && value?.length > MAX_LENGTH_PROJECT_NAME)
                return errorMaxChars(MAX_LENGTH_PROJECT_NAME);
              return true;
            },
          })}
        />
      </Label>
      <div className="flex flex-row gap-x-5">
        <Label
          text={translate("domain")}
          dataCy={`${EXPERIENCE_PROJECTS_EDIT}_${DOMAIN}`}
          additionalClassName="w-full"
        >
          <Controller
            control={control}
            name={getFormFieldName(DOMAIN)}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <SearchSelect
                dataCy={`${EXPERIENCE_PROJECTS_EDIT}_${DOMAIN}`}
                width="w-full"
                options={domainDictForSearchSelect}
                value={domainDictForSearchSelect?.find(
                  (domain: { value: string; label: string }) =>
                    domain.value === watch(getFormFieldName(DOMAIN)),
                )}
                error={!!error}
                message={error?.message}
                onChange={(newValue) => onChange(newValue?.value)}
                placeholder={translate(`${DOMAIN}${PLACEHOLDER}`, false)}
              />
            )}
            rules={
              isOcr
                ? undefined
                : {
                    validate: (value) =>
                      !value && hasEmptyFields ? errorEmptyMessage : true,
                  }
            }
          />
        </Label>
        <div className="flex flex-row ml-5">
          <Label
            dataCy={START_DATE}
            text={translate(START_DATE)}
            additionalClassName="mr-3"
          >
            <Controller
              name={getFormFieldName(START_DATE)}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Datepicker2
                    dataCy={START_DATE}
                    error={error?.message}
                    onChange={onChange}
                    selected={value ? dayjs(value) : null}
                    dateFormat="MM/YYYY"
                    timeView={["month", "year"]}
                    type="month"
                  />
                );
              }}
              rules={
                isOcr
                  ? undefined
                  : {
                      validate: (value) => {
                        if (!value && hasEmptyFields) return errorEmptyMessage;
                        if (value && dayjs().isBefore(value)) {
                          return `${t("profile.errors.noFutureDate")}`;
                        }
                        return true;
                      },
                    }
              }
            />
          </Label>
          <Label dataCy={END_DATE} text={translate(END_DATE, false)}>
            <Controller
              name={getFormFieldName(END_DATE)}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Datepicker2
                  dataCy={END_DATE}
                  error={error?.message}
                  onChange={onChange}
                  selected={value ? dayjs(value) : null}
                  dateFormat="MM/YYYY"
                  timeView={["month", "year"]}
                  type="month"
                />
              )}
              rules={
                isOcr
                  ? undefined
                  : {
                      validate: (value) => {
                        const fromDate = getValues(
                          getFormFieldName(START_DATE),
                        );
                        return value && fromDate && fromDate.isAfter(value)
                          ? `${t("profile.errors.noEarlierDate", {
                              date: fromDate.format(DATE_FORMAT),
                            })}`
                          : true;
                      },
                    }
              }
            />
          </Label>
        </div>
      </div>
      <div className="w-full flex flex-col gap-y-5">
        <div className="flex flex-row gap-x-5">
          <div className="w-3/5 h-40">
            <Label text={translate(DESCRIPTION)} dataCy={DESCRIPTION}>
              <Controller
                name={getFormFieldName(DESCRIPTION)}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Textarea
                    dataCy={DESCRIPTION}
                    maxLength={MAX_LENGTH_DESCRIPTION}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                      onChange(event.target.value)
                    }
                    value={watch(getFormFieldName(DESCRIPTION))}
                    error={!!error}
                    message={error?.message}
                    placeholder={translate(
                      `${DESCRIPTION}${PLACEHOLDER}`,
                      false,
                    )}
                  />
                )}
                rules={
                  isOcr
                    ? undefined
                    : {
                        validate: (value) =>
                          isEmptyOrBlank(value, MAX_LENGTH_DESCRIPTION),
                      }
                }
              />
            </Label>
          </div>
          <div className="w-2/5">
            <Label text={translate(RESPONSIBILITY)} dataCy={RESPONSIBILITY}>
              <Controller
                name={getFormFieldName(RESPONSIBILITY)}
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <Textarea
                    dataCy={RESPONSIBILITY}
                    maxLength={MAX_LENGTH_RESPONSIBILITIES}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                      onChange(event.target.value)
                    }
                    defaultValue={watch(getFormFieldName(RESPONSIBILITY))}
                    error={!!error}
                    message={error?.message}
                    placeholder={translate(
                      `${RESPONSIBILITY}${PLACEHOLDER}`,
                      false,
                    )}
                  />
                )}
                rules={
                  isOcr
                    ? undefined
                    : {
                        validate: (value) =>
                          isEmptyOrBlank(value, MAX_LENGTH_RESPONSIBILITIES),
                      }
                }
              />
            </Label>
          </div>
        </div>
        <div className="flex flex-row gap-x-5">
          <div className="w-3/5">
            <Label text={translate(PROJECT_ROLES)} dataCy={PROJECT_ROLES}>
              <Controller
                name={getFormFieldName(PROJECT_ROLES)}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelect
                    dataCy={PROJECT_ROLES}
                    width="w-full"
                    onChange={onChange}
                    defaultValue={
                      value && value.length && typeof value[0] === "string"
                        ? value.map((item: string) => ({
                            id: item,
                            value: item,
                            label: projectRolesDict?.find(
                              (role: RoleAPIModel) => role.id === item,
                            )?.name,
                          }))
                        : value
                    }
                    multiple
                    searchable={false}
                    options={prepareDictionarySearchSelect(
                      projectRolesDict,
                      undefined,
                      "id",
                      "name",
                      "name",
                    )}
                    height="auto"
                    error={!!error}
                    message={error?.message}
                    placeholder={translate(
                      `${PROJECT_ROLES}${PLACEHOLDER}`,
                      false,
                    )}
                  />
                )}
                rules={
                  isOcr
                    ? undefined
                    : {
                        validate: (value) =>
                          !value?.length && hasEmptyFields
                            ? errorEmptyMessage
                            : true,
                      }
                }
              />
            </Label>
          </div>
          <div className="w-2/5">
            <Label text={translate(TECH_STACK)} dataCy={TECH_STACK}>
              <Controller
                name={getFormFieldName(TECH_STACK)}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelectPicker
                    dataCy={TECH_STACK}
                    additionalTagsWrapClassName={styles.techWrapper}
                    defaultSelected={prepareTechStackDictionaryValues(
                      value,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      technologyDict,
                    )}
                    onTagSelected={onChange}
                    proposalTags={searchedProposals}
                    onInputChange={(searchValue) => {
                      const result = fullTextSearch(
                        searchValue,
                        FULL_TECH_SEARCH_MAX,
                      );
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      setSearchedProposals(result);
                    }}
                    error={!!error}
                    message={error?.message}
                  />
                )}
                rules={
                  isOcr
                    ? undefined
                    : {
                        validate: (value) =>
                          !value?.length && hasEmptyFields
                            ? errorEmptyMessage
                            : true,
                      }
                }
              />
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectForm;

function trigger(arg0: string) {
  throw new Error("Function not implemented.");
}
