import { KeyboardEvent, ReactElement } from "react";
import { classJoin } from "@ps/utils";
import Checkbox from "../checkbox";
import { SearchFilterCheckboxOptionProps } from "./types";
import { Keys } from "../../shared";

const SearchFilterCheckboxOption = ({
  additionalClassName,
  checked,
  dataCy,
  isNeutral,
  name,
  onClick,
}: SearchFilterCheckboxOptionProps): ReactElement => (
  <div
    role="button"
    tabIndex={0}
    className={classJoin(
      "flex items-center gap-3 cursor-pointer",
      additionalClassName,
    )}
    onClick={() => onClick(checked)}
    onKeyDown={(event: KeyboardEvent<HTMLDivElement>): void => {
      if (event.key === Keys.ENTER && onClick) onClick(checked);
    }}
  >
    <Checkbox
      checked={checked}
      dataCy={`${dataCy}_${name}`}
      isNeutral={isNeutral}
    />
    <span>{name}</span>
  </div>
);

export default SearchFilterCheckboxOption;
