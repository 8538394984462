import APIService from "../api/project-api";
import {
  createProjectToAPIModel,
  ProjectDetailsModel,
  projectFromAPIToModel,
  projectsSummaryFromAPIToModel,
  updateProjectToAPIModel,
} from "../models";
import { handleError } from "../../shared";
import { DictionaryModel } from "../../dictionaries";

const ProjectService = (() => {
  const createProject = async (data: ProjectDetailsModel) => {
    try {
      const result = await APIService.API.addProject(
        createProjectToAPIModel(data),
      );
      return result?.data?.id;
    } catch (error) {
      return handleError(error);
    }
  };

  const getProject = async (id: string, technologyDict?: DictionaryModel) => {
    try {
      const result = await APIService.API.getProject(id);
      if (result?.data)
        return projectFromAPIToModel(result.data, technologyDict || []);
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const getProjects = async (clientId?: string) => {
    try {
      const result = await APIService.API.getProjectsSummaries(clientId);
      if (result?.data) {
        return projectsSummaryFromAPIToModel(result.data);
      }
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const updateProject = async (id: string, data: ProjectDetailsModel) => {
    try {
      return await APIService.API.updateProject(
        id,
        updateProjectToAPIModel(data),
      );
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchPinnedProjects = async () => {
    try {
      const result = await APIService.API.getPinnedProjects();
      if (result?.data) return result.data;
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const pinProject = async (projectId: string) => {
    try {
      return APIService.API.pinProject(projectId);
    } catch (error) {
      return handleError(error);
    }
  };

  const unPinProject = async (projectId: string) => {
    try {
      return APIService.API.unpinProject(projectId);
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchMyProjects = async () => {
    try {
      const result = await APIService.API.getAuthedUserProjectSummaries();
      if (result?.data) return projectsSummaryFromAPIToModel(result.data);
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const archiveProject = async (projectId: string, isArchived: boolean) => {
    try {
      await APIService.API.setArchived(projectId, { archive: isArchived });
      return true;
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    createProject,
    fetchMyProjects,
    fetchPinnedProjects,
    getProject,
    getProjects,
    pinProject,
    unPinProject,
    updateProject,
    archiveProject,
  };
})();

export default ProjectService;
