import { COURSES, DEGREES } from "./constants";
import { ReactComponent as EducationIcon } from "../../../../../images/education.svg";
import { ReactComponent as CoursesIcon } from "../../../../../images/courses.svg";
import CourseForm from "./forms/courseForm";
import DegreeForm from "./forms/degreeForm";
import DegreeCard from "./cards/degreeCard";
import CourseCard from "./cards/courseCard";

const sections = [
  {
    name: DEGREES,
    View: DegreeCard,
    Edit: DegreeForm,
    Icon: EducationIcon,
  },
  {
    name: COURSES,
    View: CourseCard,
    Edit: CourseForm,
    Icon: CoursesIcon,
  },
];

export default sections;
