import { ComponentType, ReactElement, useState } from "react";
import { uid } from "react-uid";
import {
  ArticlesTemplate,
  AwardsTemplate,
  CertificatesTemplate,
  ContributionTemplate,
  CourseTemplate,
  DegreeTemplate,
  GoalsTemplate,
  TalksTemplate,
  TutorTemplate,
} from "./templates";
import { OtherExperienceProps } from "../../types";
import ExperienceExpandedCard from "./experienceExpandedCard";
import { renderSectionIcon } from "../../helpers";
import {
  ARTICLES,
  AWARDS,
  CERTIFICATES,
  CONTRIBUTIONS,
  COURSES,
  DEGREES,
  TALKS,
  TUTORS,
  GOALS,
} from "../constants";

const OtherExperience = ({
  experience,
  renderIcon = renderSectionIcon,
  technologyDict,
}: OtherExperienceProps): ReactElement => {
  const [openedSection, setOpenedSection] = useState("");

  const properTemplate = (section: string): ComponentType<any> => {
    switch (section) {
      case TALKS:
        return TalksTemplate;
      case CERTIFICATES:
        return CertificatesTemplate;
      case TUTORS:
        return TutorTemplate;
      case ARTICLES:
        return ArticlesTemplate;
      case DEGREES:
        return DegreeTemplate;
      case COURSES:
        return CourseTemplate;
      case AWARDS:
        return AwardsTemplate;
      case GOALS:
        return GoalsTemplate;
      case CONTRIBUTIONS:
      default:
        return ContributionTemplate;
    }
  };

  return (
    <section className="flex md:flex-wrap flex-col md:flex-row gap-6 h-full">
      {experience ? (
        Object.entries(experience).map(([key, value]) => (
          <ExperienceExpandedCard
            data={value}
            key={uid(value)}
            onClick={setOpenedSection}
            openedSection={openedSection}
            renderIcon={renderIcon}
            technologyDict={technologyDict}
            Template={properTemplate(key)}
            title={key}
          />
        ))
      ) : (
        <></>
      )}
    </section>
  );
};
export default OtherExperience;
