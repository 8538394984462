// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";
import {
  GeneratedTeams,
  NormalizedTeams,
  GeneratedTeam,
  TeamsWithDetails,
} from "./types";
import { ProfilesSummaryListModel } from "../../../../../../profile-domain";

export const getEmployeesAndScores = (
  data: GeneratedTeams,
): NormalizedTeams => {
  if (data.length) {
    const normalizedData = data.map((entry: GeneratedTeam) => {
      const employeesIds = entry.needs.flatMap((need) => need?.employeesIds);
      const uniqueIds = [...new Set(employeesIds)];
      const highestSkillScore = Math.max(entry.scores.skill);

      return {
        id: uuidv4(),
        team: uniqueIds,
        scores: entry.scores,
        highestSkillScore,
      };
    });

    const sortedBySkill = normalizedData.sort(
      (a, b) => b.highestSkillScore - a.highestSkillScore,
    );

    const withOrderIndex: NormalizedTeams = sortedBySkill.map(
      ({ id, team, scores }, index) => ({
        id,
        team,
        scores,
        orderIndex: index + 1,
      }),
    );

    const withoutZeroSkillScore = withOrderIndex.filter(
      ({ scores }) => scores.skill !== 0,
    );

    return withoutZeroSkillScore;
  }

  return [];
};

export const mergeTeamsWithSpecialists = (
  teams: NormalizedTeams,
  specialists: ProfilesSummaryListModel[],
): TeamsWithDetails[] => {
  const userMap = new Map(specialists.map((user) => [user.userId, user]));
  const mergedData = teams.map((teamMember) => ({
    id: teamMember.id,
    team: teamMember.team.map((userId) => userMap.get(userId)),
    scores: teamMember.scores,
    orderIndex: teamMember.orderIndex,
  }));
  return mergedData as TeamsWithDetails[];
};

export const convertToPercentage = (decimalValue: number): string => {
  const percentage = decimalValue * 100;
  return `${percentage.toFixed()}%`;
};

export const determineLevel = (value: number): string =>
  // eslint-disable-next-line no-nested-ternary
  value >= 0 && value <= 0.33 ? "low" : value <= 0.66 ? "medium" : "high";

export const checkUserIds = (ids: string[], team: TeamsWithDetails["team"]) => {
  if (!ids.length) {
    return false;
  }

  const candidatesIds = ids.flatMap((userId) => userId);
  const teamIds = team.map(({ userId }: { userId: string }) => userId);
  return teamIds.every((id) => candidatesIds.includes(id));
};
