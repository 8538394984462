import { ReactElement } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import {
  Label,
  Empty,
  LinkButton,
  Card,
  Button,
  Tooltip,
  hasPermissions,
} from "@ps/ui-components";
import { classJoin, getNameFromDictionary, setSeniorityColor } from "@ps/utils";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../hooks";
import { focusVisibleStyles } from "../../../shared";
import { ReactComponent as RejectedIcon } from "../../../images/bench/rejected.svg";
import { ReactComponent as ApprovedIcon } from "../../../images/bench/approved.svg";
import { ReactComponent as PendingIcon } from "../../../images/bench/pending.svg";
import { ReactComponent as NoProjectIcon } from "../../../images/bench/no-project.svg";
import { ReactComponent as InfinityIcon } from "../../../images/project/loop.svg";
import { ReactComponent as MoreIcon } from "../../../images/project/more2.svg";
import { ReactComponent as PartTimeIcon } from "../../../images/bench/part-time.svg";
import { ReactComponent as FullTimeIcon } from "../../../images/bench/full-time.svg";

import styles from "../styles.module.css";
import {
  BENCH,
  AVAILABILITY,
  NAME,
  PROFESSION,
  SENIORITY,
  FOLDER_STATUS,
  DAYS,
  IN_PROGRESS,
  APPROVED,
  REJECTED,
  PROJECT,
  REASON,
} from "../../constants";
import { matchIconToFeedback } from "../../../project-domain";
import { BenchUserFolderModel } from "../../models";
import {
  setOpenedFoldersStatuses,
  setBenchUserId,
  resetBenchUserId,
} from "../../store";
import { RowProps, RowMapState, RowMapStateReturn } from "./types";
import StatusIconWithTooltip from "./statusIconWithTooltip";
import { IS_PRODUCTION } from "../../../shared/constants";
import FoldersModal from "../foldersModal";
import {
  PROJECT_FULL_ACCESS,
  PROJECT_LIST_ALL,
  USERS_LIST_ALL,
} from "../../../shared/permissions";
import { BenchUserProjectsModel } from "../types";

const Row = ({ user }: RowProps): ReactElement => {
  const history = useHistory();
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();

  const mapState = (state: RowMapState): RowMapStateReturn => ({
    allPermissions: state.permissions || [],
    benchUserId: state?.bench?.benchUserId,
    openedFoldersStatuses: state?.bench?.openedFoldersStatuses,
    professionDict: state?.dictionaries?.profession,
    seniorityDict: state?.dictionaries?.seniority,
  });

  const { openedFoldersStatuses, benchUserId, seniorityDict, professionDict } =
    useMappedStateSelector(mapState);

  const { allPermissions } = useMappedStateSelector(mapState, "");

  const countTotalStatus = (status: string): number =>
    user?.folders?.filter(
      (folder: BenchUserFolderModel): boolean => folder.status === status,
    )?.length;

  const handleSetOpenedFolderStatus = (): void => {
    setOpenedFoldersStatuses(
      dispatch,
      user.userId,
      !openedFoldersStatuses.includes(user.userId),
    );
    dispatch(setBenchUserId(user.userId));
  };

  const normalizedSeniority = (seniorty: string): string => {
    const seniorityName = getNameFromDictionary(seniorityDict, seniorty);
    return seniorityName.charAt(0).toUpperCase() + seniorityName.slice(1);
  };

  const actualProjects = user.projects.filter(
    (p: BenchUserProjectsModel) =>
      p.endDate === null || dayjs(p.endDate).isAfter(dayjs()),
  );

  const isAvailableToday = dayjs(user.availability)?.isSame(dayjs());
  const isAvailableFuture = dayjs(user.availability)?.isBefore(dayjs());

  return (
    <>
      <Card
        dataCy={BENCH}
        additionalCardClassName="flex flex-col mb-1 justify-center"
        backgroundColor={
          isAvailableToday || isAvailableFuture
            ? "bg-neutralPrimary-100"
            : "bg-neutralSecondary-90"
        }
      >
        <div
          className={classJoin(
            IS_PRODUCTION ? styles.tableColumnsProduction : styles.tableColumns,
            "rounded-lg px-6 py-6 shadow-none items-center",
          )}
        >
          {user?.availability === "" ? (
            <InfinityIcon data-cy={`${BENCH}_noLimit`} className="h-full" />
          ) : (
            <Label
              dataCy={AVAILABILITY}
              additionalClassName="items-center"
              verticalAlignment
            >
              {isAvailableToday || isAvailableFuture
                ? t(`${BENCH}.now`)
                : dayjs(user.availability)?.format("DD/MM/YYYY")}
            </Label>
          )}
          <Label dataCy={NAME} verticalAlignment>
            <LinkButton
              dataCy={NAME}
              whiteSpaceNoWrap={false}
              additionalClassName="hover:underline text-left"
              onClick={() => history.push(`/team/${BENCH}/${user.userId}`)}
            >
              {user?.name}
            </LinkButton>
          </Label>
          <Label dataCy={PROFESSION} fontWeight="font-normal" verticalAlignment>
            {user?.profession ? (
              getNameFromDictionary(professionDict, user.profession)
            ) : (
              <Empty />
            )}
          </Label>
          <Label dataCy={SENIORITY} fontWeight="font-normal" verticalAlignment>
            {user?.seniority ? (
              <span className={`${setSeniorityColor(user.seniority)}`}>
                {normalizedSeniority(user.seniority)}
              </span>
            ) : (
              <Empty />
            )}
          </Label>
          {actualProjects.length ? (
            <div className="flex flex-col gap-y-4 justify-center">
              {actualProjects.map((p: BenchUserProjectsModel) => (
                <div className="flex item-center gap-x-4">
                  {p.engagement === "Full time" && (
                    <FullTimeIcon data-cy={`${BENCH}_fullTime`} />
                  )}
                  {p.engagement === "Part time" && (
                    <PartTimeIcon data-cy={`${BENCH}_partTime`} />
                  )}
                  <Tooltip
                    additionalChildrenClassName="truncate w-full block"
                    content={<span>{p.projectName ?? ""}</span>}
                    placement="top"
                    verticalOffset={-1}
                  >
                    <LinkButton
                      dataCy={`${BENCH}_${PROJECT}`}
                      additionalClassName="truncate w-full hover:underline text-left"
                      onClick={() => history.push(`/${PROJECT}/${p.id}`)}
                      display="block"
                      disabled={
                        !(
                          hasPermissions(allPermissions, USERS_LIST_ALL) &&
                          hasPermissions(allPermissions, [
                            PROJECT_FULL_ACCESS,
                            PROJECT_LIST_ALL,
                          ])
                        )
                      }
                    >
                      {p.projectName ?? ""}
                    </LinkButton>
                  </Tooltip>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center gap-x-4">
              <NoProjectIcon /> {t(`${BENCH}.noProject`)}
            </div>
          )}
          <Label dataCy={FOLDER_STATUS} verticalAlignment>
            <div
              className={classJoin(
                "flex items-center gap-1 text-neutralSecondary-60 text-lg w-max rounded-lg",
                focusVisibleStyles,
              )}
            >
              <StatusIconWithTooltip
                dataCy={`${BENCH}_${IN_PROGRESS}`}
                status={IN_PROGRESS}
                totalNumber={countTotalStatus(IN_PROGRESS)}
                Icon={PendingIcon}
                activeColor="text-primary-60"
              />
              <StatusIconWithTooltip
                dataCy={`${BENCH}_${APPROVED}`}
                status={APPROVED}
                totalNumber={countTotalStatus(APPROVED)}
                Icon={ApprovedIcon}
                activeColor="text-success-50"
              />
              <StatusIconWithTooltip
                dataCy={`${BENCH}_${REJECTED}`}
                status={REJECTED}
                totalNumber={countTotalStatus(REJECTED)}
                Icon={RejectedIcon}
                activeColor="text-error-50"
              />
            </div>
          </Label>
          <Label
            dataCy={`${BENCH}_${REASON}_${user?.reason}`}
            additionalClassName="w-min mt-1"
            verticalAlignment
          >
            {user.reason.length ? (
              <Tooltip
                placement="top"
                content={
                  <span>{t(`project.team.feedbacks.${user.reason}`)}</span>
                }
              >
                {matchIconToFeedback(user.reason)}
              </Tooltip>
            ) : (
              <NoProjectIcon />
            )}
          </Label>

          {IS_PRODUCTION ? null : (
            <>
              {typeof user?.daysOnBench === "number" ? (
                <Label dataCy={DAYS} verticalAlignment>
                  {user.daysOnBench.toString()}
                </Label>
              ) : (
                <Empty />
              )}
            </>
          )}
          <div className="flex justify-end">
            <Button
              dataCy={BENCH}
              border="none"
              paddingX="px-0"
              variant="secondary"
              onClick={handleSetOpenedFolderStatus}
              disabled={!user.folders?.length}
              additionalClass="self-center place-self-end"
            >
              <MoreIcon />
            </Button>
          </div>
        </div>
      </Card>
      <FoldersModal
        isOpen={user.userId === benchUserId}
        onClose={() => dispatch(resetBenchUserId())}
        folders={user.folders}
        name={user.name}
        benchUserId={user.userId}
      />
    </>
  );
};

export default Row;
