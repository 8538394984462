import {
  ReactElement,
  useEffect,
  useState,
  MouseEvent,
  KeyboardEvent,
  useLayoutEffect,
  useContext,
} from "react";
import { useDispatch } from "redux-react-hook";
import { Tabs2, ToggleSelect, PermissionContext } from "@ps/ui-components";
import { useHistory, useParams } from "react-router-dom";
import { classJoin } from "@ps/utils";
import {
  ACTIVE,
  ARCHIVED,
  DETAILS,
  DISCOVERY_TEAM,
  NOTES,
  PROJECT,
  PROPOSAL_FOLDER,
  REQUIREMENTS,
} from "../../constants";
import {
  ProspectDetailsMapState,
  ProspectDetailsMapStateReturn,
} from "./types";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import Details from "./sections/details";
import Needs from "./sections/needs";
import Notes from "./sections/notes";
import Folder from "./sections/folder";
import DiscoveryTeam from "./sections/discovery-team";
import { Keys } from "../../../shared";
import { PROSPECT_FULL_ACCESS } from "../../../shared/permissions";
import { fetchProspect } from "../../store";
import { ProspectService } from "../../services";
import { toggleEditMode } from "../../../store";
import { PROSPECT } from "../../shared/data-cy";

const BASE_PATH = "projects.prospect.tabs";

const ProspectDetails = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { hasPermission } = useContext(PermissionContext);

  const mapState = (
    state: ProspectDetailsMapState,
  ): ProspectDetailsMapStateReturn => ({
    editMode: state.editMode,
    technologyDict: state?.dictionaries?.technology || [],
    prospect: state?.prospects?.prospect,
  });

  const { editMode, technologyDict, prospect } =
    useMappedStateSelector(mapState);

  useLayoutEffect(() => {
    if (technologyDict.length)
      fetchProspect(params.id, dispatch, technologyDict);
  }, [technologyDict, params.id]);

  useEffect(() => {
    return () => {
      toggleEditMode("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    {
      label: t(`${BASE_PATH}.${DETAILS}`),
      value: DETAILS,
    },
    {
      label: t(`${BASE_PATH}.${REQUIREMENTS}`),
      value: REQUIREMENTS,
    },
    {
      label: t(`${BASE_PATH}.${NOTES}`),
      value: NOTES,
    },
    {
      label: t(`${BASE_PATH}.${DISCOVERY_TEAM}`),
      value: DISCOVERY_TEAM,
    },
    {
      label: t(`${BASE_PATH}.${PROPOSAL_FOLDER}`),
      value: PROPOSAL_FOLDER,
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  const renderContent = (): ReactElement => {
    switch (currentTab) {
      case DETAILS:
        return <Details />;
      case REQUIREMENTS:
        return <Needs />;
      case NOTES:
        return <Notes />;
      case DISCOVERY_TEAM:
        return <DiscoveryTeam />;
      case PROPOSAL_FOLDER:
        return <Folder />;
      default:
        return <Details />;
    }
  };

  const toggleArchiveStatus = async () => {
    await ProspectService.archiveProspect(params.id, !prospect.archived);
    fetchProspect(params.id, dispatch, technologyDict);
  };

  const handleTabClick = (e: MouseEvent<HTMLDivElement>): void =>
    setCurrentTab(e.currentTarget.id);

  const handleTabKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === Keys.ENTER) setCurrentTab(e.currentTarget.id);
  };

  const isDetails = currentTab === DETAILS;

  return (
    <div>
      {!editMode.length && (
        <span
          role="button"
          tabIndex={0}
          onClick={() => history.goBack()}
          onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
            if (event.key === Keys.ENTER) {
              history.goBack();
            }
          }}
          className="text-neutralPrimary-30 cursor-pointer select-none hover:text-neutralPrimary-20"
        >
          {`<`} {t(`${PROJECT}.back`)}
        </span>
      )}
      <div className="flex items-center gap-x-5">
        <h2
          className="text-4xl text-neutralPrimary-20 mt-6 mb-8 font-bold"
          data-cy={`${PROSPECT}-details_name`}
        >
          {prospect.details?.prospectName}
        </h2>
      </div>
      <div className="flex justify-between w-9/12 pr-14">
        <div className={classJoin(editMode.length && "pointer-events-none")}>
          <Tabs2
            currentValue={currentTab}
            tabs={tabs}
            onTabClick={handleTabClick}
            onTabKeyDown={handleTabKeyDown}
          />
        </div>

        {isDetails && (
          <ToggleSelect
            dataCy={`${BASE_PATH}.toggle`}
            checked={!prospect?.archived}
            activeLabel={t(`${PROJECT}.toggle.${ACTIVE}`)}
            negationLabel={t(`${PROJECT}.toggle.${ARCHIVED}`)}
            onChange={toggleArchiveStatus}
            disabled={!hasPermission(PROSPECT_FULL_ACCESS)}
          />
        )}
      </div>
      {renderContent()}
    </div>
  );
};

export default ProspectDetails;
