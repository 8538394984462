import { ReactElement } from "react";
import { ReactComponent as HateIcon } from "../../images/enjoyment-scale/0.svg";
import { ReactComponent as UnlikeIcon } from "../../images/enjoyment-scale/1.svg";
import { ReactComponent as NeutralIcon } from "../../images/enjoyment-scale/2.svg";
import { ReactComponent as LikeIcon } from "../../images/enjoyment-scale/3.svg";
import { ReactComponent as LoveIcon } from "../../images/enjoyment-scale/5.svg";

const iconForScale: Record<number, ReactElement> = {
  0: <HateIcon />,
  1: <UnlikeIcon />,
  2: <NeutralIcon />,
  3: <LikeIcon />,
  4: <HateIcon className="transform rotate-180" />,
  5: <LoveIcon />,
};

export default iconForScale;
