const searchSelectStyles = (
  error = false,
  height = "auto",
  isMenuOpenTop = false,
  menuWidth = "100%",
  isWideNameList = false,
  searchable = false,
): Record<string, unknown> => {
  return {
    singleValue: (): Record<string, unknown> => ({
      color: error
        ? "rgb(var(--color-error-50))"
        : "rgb(var(--color-text-primary-51))",
      backgroundColor: "rgb(var(--color-neutral-primary-100))",
      fontSize: "var(--font-size-base)",
      lineHeight: "1.5rem",
      textTransform: "capitalize",
      fontWeight: 500,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      position: "absolute",
      width: searchable ? "calc(100% - 5px)" : "",
    }),
    multiValue: (): Record<string, unknown> => ({
      display: "flex",
      borderRadius: "0.375rem",
      color: "rgb(var(--color-neutral-primary-30))",
      fontSize: "var(--font-size-base)",
      lineHeight: "1.5rem",
      fontWeight: 400,
      textTransform: "capitalize",
    }),
    multiValueLabel: (): Record<string, unknown> => ({
      backgroundColor: "rgb(var(--color-primary-50))",
      color: "rgb(var(--color-primary-100))",
      paddingLeft: "5px",
      paddingRight: "5px",
      borderTopLeftRadius: "0.375rem",
      borderBottomLeftRadius: "0.375rem",
      fontSize: "var(--font-size-base)",
      lineHeight: "1.5rem",
      fontWeight: 400,
      textTransform: "capitalize",
    }),
    valueContainer: (
      provided: Record<string, unknown>,
    ): Record<string, unknown> => ({
      ...provided,
      display: "flex",
      flexWrap: "wrap",
      gap: "5px",
    }),
    multiValueRemove: (
      provided: Record<string, unknown>,
    ): Record<string, unknown> => ({
      ...provided,
      backgroundColor: "rgb(var(--color-primary-70))",
      borderTopRightRadius: "0.375rem",
      borderBottomRightRadius: "0.375rem",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      color: "rgb(var(--color-primary-50))",
      marginRight: "5px",
      ":hover": {
        backgroundColor: "rgb(var(--color-primary-70), .5)",
      },
    }),
    placeholder: (): Record<string, unknown> => ({
      color: error
        ? "rgb(var(--color-error-50))"
        : "rgb(var(--color-neutral-secondary-41))",
      fontSize: "var(--font-size-base)",
      lineHeight: "1.5rem",
      fontWeight: 400,
      textTransform: "capitalize",
    }),
    input: (): Record<string, unknown> => ({
      color: "rgb(var(--color-neutral-primary-30))",
      zIndex: searchable ? 1 : 0,
    }),
    control: (
      _: Record<string, unknown>,
      { isDisabled }: Record<string, unknown>,
    ): Record<string, unknown> => ({
      position: "relative",
      display: "flex",
      alignItems: "center",
      backgroundColor: "rgb(var(--color-neutral-primary-100))",
      opacity: isDisabled ? "0.3" : 1,
      height,
      minHeight: "2.188rem",
      minWidth: isWideNameList ? "21rem" : "auto",
      border: error
        ? "1px solid rgb(var(--color-error-50))"
        : "1px solid rgb(var(--color-neutral-secondary-60))",
      padding: "2px",
      borderRadius: "0.375rem",
      outline: "none",
      ":focus-within": {
        border: "1px solid rgb(var(--color-secondary-50))",
      },
    }),
    indicatorSeparator: (): Record<string, unknown> => ({
      display: "none",
    }),
    container: (): Record<string, unknown> => ({}),
    indicatorsContainer: (): Record<string, unknown> => ({
      display: "flex",
      alignItems: "center",
      marginBottom: "2px",
    }),
    clearIndicator: (): Record<string, unknown> => ({
      color: error
        ? "rgb(var(--color-error-50))"
        : "rgb(var(--color-neutral-primary-30))",
    }),
    dropdownIndicator: (): Record<string, unknown> => ({
      color: error
        ? "rgb(var(--color-error-50))"
        : "rgb(var(--color-neutral-primary-30))",
    }),
    menuPortal: (provided: Record<string, unknown>) => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: () => ({
      width: `${menuWidth}`,
      position: "absolute",
      marginTop: "4px",
      border: "1px solid rgb(var(--color-neutral-secondary-60))",
      borderRadius: "0.375rem",
      backgroundColor: "rgb(var(--color-neutral-primary-100))",
      overflow: "auto",
      maxHeight: "15rem",
      fontSize: "var(--font-size-base)",
      lineHeight: "1.5rem",
      fontWeight: 400,
      zIndex: "9999 !important",
      textTransform: "capitalize",
      bottom: isMenuOpenTop ? "65%" : "",
      top: "auto",
    }),
    menuList: (): Record<string, unknown> => ({
      overflow: "auto",
      maxHeight: "14rem",
    }),
    option: (
      _: Record<string, unknown>,
      { isFocused, isSelected }: Record<string, unknown>,
    ) => ({
      display: "flex",
      alignItems: "center",
      padding: "0.25rem 0.5rem",
      height: "100%",
      width: "100%",
      borderRadius: "0.375rem",
      textTransform: "capitalize",
      wordBreak: "break-word",
      backgroundColor:
        isFocused || isSelected
          ? "rgb(var(--color-primary-70), .3)"
          : "rgb(var(--color-neutral-primary-100))",
      ":active": {
        backgroundColor: "rgb(var(--color-primary-70), .1)",
        color: "rgb(var(--color-neutral-primary-30))",
      },
      ":hover": {
        backgroundColor: "rgb(var(--color-primary-70), .6)",
        color: "rgb(var(--color-neutral-primary-30))",
        fontSize: "var(--font-size-base)",
        lineHeight: "1.5rem",
        fontWeight: 400,
      },
    }),
  };
};

export default searchSelectStyles;
