import { ReactElement } from "react";
import { uid } from "react-uid";
import { classJoin, getNameFromDictionary } from "@ps/utils";
import dayjs from "dayjs";
import styles from "../../styles.module.css";
import { useTranslationWithNamespace } from "../../../../hooks";
import { PROFILE_SUMMARY_BASE_INFO } from "../../../../shared/data-cy";
import {
  DatesRangeProps,
  DomainProps,
  OtherProps,
  ProjectNameProps,
  RolesProps,
  TechStackProps,
  TechStackModel,
} from "../../types";
import { countTimeRange } from "./helpers";
import { ReactComponent as QuestionMarkIcon } from "../../../../images/question-mark.svg";
import Tooltip from "../../../tooltip";
import Label from "../../../label";
import Chip from "../../../chip";

const BASE_PATH = "profileSummary";

const ProjectName = ({ name }: ProjectNameProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return name ? (
    <span className="font-semibold">{name}</span>
  ) : (
    <div className="flex items-center gap-2">
      <Tooltip
        placement="top"
        content={<div>{t(`${BASE_PATH}.hiddenReason`)}</div>}
        backgroundColor="neutralPrimary-100"
        additionalClassName="border border-neutralSecondary-60 w-80"
      >
        <div className="flex gap-x-4">
          <span className="font-semibold filter blur-sm">
            {t(`${BASE_PATH}.defaultProjectName`)}
          </span>
          <div className="p-1 rounded-full flex items-center justify-center bg-primary-70 w-6 h-6">
            <QuestionMarkIcon className="text-white w-3 h-3" />
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

const Roles = ({ roles, projectRolesDict }: RolesProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <div className="flex gap-2">
      <Label
        additionalClassName="flex"
        labelFontWeight="font-bold"
        labelColor="text-neutralPrimary-20"
        fontWeight="font-normal"
        dataCy={`${PROFILE_SUMMARY_BASE_INFO}_roles`}
        text={t(`${BASE_PATH}.projectRoles`)}
      >
        <>
          {roles.map((item: string, index: number) => (
            <span className="text-sm text-primary-50" key={item}>
              {getNameFromDictionary(projectRolesDict, item.trim())}
              {index !== roles.length - 1 && ", "}
            </span>
          ))}
        </>
      </Label>
    </div>
  );
};

const TimeRange = ({
  startDate,
  endDate,
  className = "text-neutralSecondary-41",
}: DatesRangeProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const { years, months } = countTimeRange(startDate, endDate);

  const formattedStartDate = dayjs.isDayjs(startDate)
    ? startDate.format("MM.YYYY")
    : "";
  const formattedEndDate = dayjs.isDayjs(endDate)
    ? endDate.format("MM.YYYY")
    : "";

  return (
    <span className={className}>{`${formattedStartDate} - ${
      formattedEndDate || t(`${BASE_PATH}.ongoing`)
    } (${
      years
        ? `${t(`${BASE_PATH}.years`, {
            count: years,
          })}${months ? " " : ""}`
        : ""
    }${
      months
        ? t(`${BASE_PATH}.months`, {
            count: months,
          })
        : ""
    })`}</span>
  );
};

const TechStack = ({
  showTwoRows = false,
  technologyDict,
  techStack,
  section,
  isGoalsCard,
}: TechStackProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const prepareText = () => {
    if (isGoalsCard && !techStack.length) return "";
    if (isGoalsCard) return t(`${BASE_PATH}.skills`);
    return t(`${BASE_PATH}.techStack`);
  };

  return (
    <Label
      dataCy={`${PROFILE_SUMMARY_BASE_INFO}_techStack`}
      labelFontWeight="font-bold"
      labelColor="text-neutralPrimary-20"
      text={prepareText()}
    >
      <div
        className={classJoin(
          "flex flex-wrap gap-2",
          showTwoRows ? styles.singleProjectTechContainer : "h-auto",
        )}
      >
        {techStack?.map((item: TechStackModel) => (
          <Chip
            additionalClassName={classJoin(
              "w-auto h-fit",
              showTwoRows ? "truncate" : "",
            )}
            dataCy={`${PROFILE_SUMMARY_BASE_INFO}_techStack`}
            key={uid({ item, section })}
            label={getNameFromDictionary(technologyDict, item.technologyId)}
            tailwindColors="text-neutralPrimary-100 border-neutralSecondary-41  bg-neutralSecondary-41 text-sm"
          />
        ))}
      </div>
    </Label>
  );
};

const Other = ({
  className = "flex flex-col md:flex-row gap-6",
  description,
  responsibility,
}: OtherProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className={className}>
      <Label
        labelFontWeight="font-bold"
        labelColor="text-neutralPrimary-20"
        dataCy={`${PROFILE_SUMMARY_BASE_INFO}_description`}
        text={t(`${BASE_PATH}.projectDescription`)}
      >
        <p className="text-justify text-sm font-normal whitespace-pre-wrap">
          {description}
        </p>
      </Label>

      <Label
        labelFontWeight="font-bold -mt-1"
        labelColor="text-neutralPrimary-20"
        dataCy={`${PROFILE_SUMMARY_BASE_INFO}_responsibility`}
        text={t(`${BASE_PATH}.projectResponsibilities`)}
      >
        <p className="text-justify text-sm font-normal whitespace-pre-wrap">
          {responsibility}
        </p>
      </Label>
    </div>
  );
};

const Domain = ({ domainDict, domain }: DomainProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <Label
      labelFontWeight="font-bold"
      labelColor="text-neutralPrimary-20"
      dataCy={`${PROFILE_SUMMARY_BASE_INFO}_domain`}
      text={t(`${BASE_PATH}.domain`)}
    >
      <Chip
        dataCy={`${PROFILE_SUMMARY_BASE_INFO}_techStack`}
        label={getNameFromDictionary(domainDict, domain)}
        tailwindColors="text-neutralPrimary-100 border-neutralSecondary-41 bg-neutralSecondary-41 text-sm"
      />
    </Label>
  );
};

export { ProjectName, Roles, TimeRange, TechStack, Other, Domain };
export default TimeRange;
