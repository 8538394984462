import React, { ReactElement, useState, KeyboardEvent, useEffect } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import Label from "../label";
import { COLOR_PICKER_PREFIX } from "../../shared/data-cy";
import { ColorPickerProps, IColor, ColorTileProps } from "./types";
import { useTranslationWithNamespace } from "../../hooks";
import "./styles.css";
import Dropdown from "../dropdown";
import { focusVisibleStyles, Keys } from "../../shared";

const ColorTile = ({ color, setColor }: ColorTileProps): ReactElement => (
  <div
    className={classJoin("w-8 h-8 rounded-lg", focusVisibleStyles)}
    onClick={() => setColor(color)}
    role="button"
    tabIndex={0}
    onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === Keys.ENTER) setColor(color);
    }}
    style={{ backgroundColor: color }}
  />
);

const ColorPicker = ({
  additionalClassName,
  children,
  color,
  companyColors,
  dataCy,
  onClose,
  saveOnOutsideClick = false,
  onChange,
  defaultColors,
  setIsColorPickerActive,
}: ColorPickerProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [currentColor, setCurrentColor] = useState<IColor | undefined>(color);

  useEffect(() => {
    setIsColorPickerActive && setIsColorPickerActive(isVisible);
  }, [isVisible]);

  const handleOnClickOutside = (): void => {
    setIsVisible(false);
    onChange(currentColor);
    onClose && onClose(currentColor);
  };

  const handleOnChange = (newColor: string): void => {
    setCurrentColor(newColor);
    !saveOnOutsideClick && onChange(newColor);
  };

  return (
    <Dropdown
      show={isVisible}
      onClickOutside={() => handleOnClickOutside()}
      width="w-0"
      overlay={
        <div
          data-cy={`${COLOR_PICKER_PREFIX}-${dataCy}`}
          className={classJoin(additionalClassName, "absolute")}
        >
          <section className="colorPicker p-5 bg-neutralPrimary-100 rounded-lg border border-neutralSecondary-60 flex flex-col gap-4">
            <HexColorPicker color={currentColor} onChange={handleOnChange} />
            <Label
              dataCy={dataCy}
              text={t("colorPicker.hex")}
              additionalClassName="colorPicker-hex"
            >
              <div className="flex flex-row">
                #
                <HexColorInput
                  color={currentColor}
                  onChange={handleOnChange}
                  className={focusVisibleStyles}
                />
              </div>
            </Label>
            {companyColors?.length && (
              <>
                <div className="border-b-2 border-neutralSecondary-60" />
                <Label dataCy={dataCy} text={t("colorPicker.companyColors")}>
                  <div className="flex flex-wrap gap-2">
                    {companyColors.map((companyColor: string) => (
                      <ColorTile
                        color={companyColor}
                        setColor={handleOnChange}
                        key={uid(companyColor)}
                      />
                    ))}
                  </div>
                </Label>
              </>
            )}
            {defaultColors?.length && (
              <>
                <div className="border-b-2 border-neutralSecondary-60" />
                <Label dataCy={dataCy} text={t("colorPicker.defaultColors")}>
                  <div className="flex flex-wrap gap-2">
                    {defaultColors.map((defaultColor: string) => (
                      <ColorTile
                        color={defaultColor}
                        setColor={handleOnChange}
                        key={uid(defaultColor)}
                      />
                    ))}
                  </div>
                </Label>
              </>
            )}
          </section>
        </div>
      }
    >
      {React.cloneElement(children, {
        onClick: () => {
          setIsVisible((prev) => !prev);
        },
        onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => {
          if (event.key === Keys.ENTER) {
            setIsVisible((prev) => !prev);
          } else if (event.key === Keys.ESC) {
            handleOnClickOutside();
          }
        },
      })}
    </Dropdown>
  );
};

export default ColorPicker;
