import { UserDomainModel, ClientModel, ProjectSummaryModel } from "@ps/hh";
import {
  IClientOption,
  IProjectOption,
  IUserOption,
  IValueLabel,
} from "./types";
import { CLIENT, PROJECT, USER } from "../../shared/constants";

export const mapValueLabelObjectsArrayToValues = (
  arr: IValueLabel[],
): string[] => arr.map((item: IValueLabel) => item.value);

export const prepareUserOptions = (data: UserDomainModel[]): IUserOption[] => {
  if (!data) return [];
  const result = data.map((item: UserDomainModel, index: number) => ({
    archived: false,
    checked: false,
    idInArray: index,
    itemId: item.id,
    name: `${item.firstName || "-"} ${item.secondName || "-"}`,
    type: USER,
  }));
  return result;
};

export const prepareProjectOptions = (
  data: ProjectSummaryModel[],
): IProjectOption[] => {
  if (!data) return [];
  const result = data
    ?.sort((a: ProjectSummaryModel, b: ProjectSummaryModel) =>
      (a?.details?.clientName || "") > (b?.details.clientName || "") ? 1 : -1,
    )
    .map((item: ProjectSummaryModel, index: number) => ({
      archived: item.archived,
      checked: false,
      clientId: item.details.clientId || "",
      clientName: item.details.clientName || "",
      idInArray: index,
      itemId: item.id,
      name: item.details.name,
      type: PROJECT,
    }));
  return result;
};

export const prepareClientOptions = (data: ClientModel[]): IClientOption[] => {
  if (!data) return [];
  const result = data.map((item: ClientModel, index: number) => ({
    archived: item.archived,
    checked: false,
    idInArray: index,
    itemId: item.id,
    name: item.clientName,
    type: CLIENT,
  }));
  return result;
};
