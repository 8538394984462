import dayjs, { Dayjs } from "dayjs";
import { ReactElement } from "react";
import DateFormats from "../helpers/dateFormats";
import getTimeUnitButtonStyle from "../helpers/style/getTimeUnitButtonStyle";
import {
  CalendarDisplayModes,
  DatepickerMode,
  DatepickerPickerEnum,
  DisplayModeProps,
} from "../types";
import styles from "../styles.module.css";
import { Keys } from "../../../shared";

const MonthDisplayMode = ({
  setDisplayedMonth,
  currentDisplayedMonth,
  setDisplayMode,
  state,
  selectedDatesActions,
  picker,
  mode,
  disabledDate,
}: DisplayModeProps): ReactElement => {
  const MONTHS_ARRAY = new Array(12)
    .fill(0)
    .map((_, index) => dayjs(new Date(currentDisplayedMonth.year(), index, 1)));

  const handleMonthClick = (month: Dayjs) => {
    setDisplayedMonth?.(month);
    if (picker === DatepickerPickerEnum.MONTH) {
      selectedDatesActions?.onClick(month.startOf("month"))();
    } else {
      setDisplayMode?.(CalendarDisplayModes.DAYS);
    }
  };

  const hoverBorderCommon =
    "border-t border-b border-primary-50 border-opacity-30 border-dashed";
  const getButtonStyle = getTimeUnitButtonStyle({
    stylesSelected: {
      notSelected: "bg-transparent text-neutralPrimary-30",
      selectedInBetween: "bg-primary-50 bg-opacity-30 text-primary-100",
      selectedFirstInRow:
        "bg-primary-50 text-primary-100 rounded-l-md bg-opacity-100",
      selectedLastInRow:
        "bg-primary-50 text-primary-100 rounded-r-md bg-opacity-100",
    },
    stylesHover: {
      hoverInBetween: `${hoverBorderCommon} bg-opacity-50`,
      nextUnitAfterHoverSecondInput: "rounded-l-md",
      prevUnitBeforeHoverFirstInput: "rounded-r-md",
      hoverFirstInRow: `${hoverBorderCommon} rounded-l-md border-l bg-opacity-70`,
      hoverLastInRow: `${hoverBorderCommon} rounded-r-md border-r bg-opacity-70`,
    },
    compareMode: DatepickerPickerEnum.MONTH,
  })({
    selectedDates: state.selected,
    hoveringOverDate:
      mode === DatepickerMode.RANGE_PICKER &&
      picker === DatepickerPickerEnum.MONTH
        ? state.hover
        : undefined,
    currentInput: state.input,
  });

  const rangeStart = state.selected?.[0];
  const rangeEnd = state.selected?.[1];

  const hoverStyles =
    "hover:bg-primary-50 hover:bg-opacity-80 hover:text-primary-100";
  const hoverStylesIfSingleMode =
    mode === DatepickerMode.DATE_PICKER || !rangeStart
      ? `${hoverStyles} hover:rounded-md`
      : hoverStyles;

  return (
    <div
      className={`grid w-full h-min ${styles.month_grid} grid-cols-3 gap-y-5 px-2 mb-2 justify-items-center`}
      onPointerLeave={selectedDatesActions?.onPointerLeave}
    >
      {MONTHS_ARRAY.map((month) => (
        <div
          className="flex items-center justify-center min-h-full w-full"
          key={month.month()}
        >
          <button
            className={`flex items-center justify-center border-t border-b
            border-neutralPrimary-100 ${hoverStylesIfSingleMode} ${getButtonStyle(
              month,
            )} w-full ${
              rangeStart && !rangeEnd && rangeStart.isSame(month, "month")
                ? "bg-primary-50 text-primary-100"
                : ""
            }  cursor-pointer`}
            onClick={() => handleMonthClick(month)}
            onKeyDown={(e) => {
              if (e.key === Keys.ENTER) {
                handleMonthClick(month);
              }
            }}
            onPointerEnter={selectedDatesActions?.onPointerEnter(month)}
            disabled={
              picker === DatepickerPickerEnum.MONTH &&
              disabledDate?.(month, state.selected)
            }
          >
            <span
              className={`flex items-center justify-center rounded-md focus:outline-none
              px-2 py-0.5 w-full h-full disabled:pointer-events-none disabled:text-neutralSecondary-30`}
            >
              {month.format(DateFormats.MONTH).toUpperCase()}
            </span>
          </button>
        </div>
      ))}
    </div>
  );
};

export default MonthDisplayMode;
