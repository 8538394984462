import { ReactElement, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Icon } from "@ps/ui-components";
import { SKILLS_MATRIX } from "../../../../../../../shared/data-cy";

const style = {
  wrapper:
    "bg-neutralPrimary-100 border-neutralPrimary-100 mb-2 p-3 rounded-md",
  title: "h-3 bg-neutralPrimary-85 rounded-md",
  icon: {
    wrapper: "primary-70 bg-opacity-30",
    svg: "text-primary-100",
  },
};

const generateWidth = (): number => Math.round(Math.random() * 70) + 30;

const LoadingTab = (): ReactElement => {
  const [currentWidth, setCurrentWidth] = useState(generateWidth());

  useEffect(() => {
    const interval = setInterval(() => setCurrentWidth(generateWidth()), 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`${style.wrapper} duration-300`}
      data-cy={`${SKILLS_MATRIX}-template_section-form`}
    >
      <div className="flex flex-row items-center">
        <span className={`${style.icon.svg} duration-200 mr-2`}>
          <Icon icon={<></>} dataCy="icon" size="11" />
        </span>
        <motion.div
          layout
          className={style.title}
          style={{ width: `${currentWidth}%` }}
          transition={{ duration: 2 }}
        />
      </div>
    </div>
  );
};

export default LoadingTab;
