import { ReactElement } from "react";
import { View, Text } from "@react-pdf/renderer";
import { useTranslationWithNamespace } from "../../../hooks";
import { SectionIcon } from "../components";
import { SeniorityProps } from "../types";
import { OUTLINE } from "../../../shared/constants";
import { sharedOutlineStyles } from "../sharedStyles/sharedStyles";

const Seniority = ({ seniority, isBlind }: SeniorityProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <View style={sharedOutlineStyles.container}>
      <View style={sharedOutlineStyles.iconContainer}>
        <SectionIcon icon={OUTLINE} isBlind={isBlind} />
      </View>
      <View style={sharedOutlineStyles.textContainer}>
        <Text style={sharedOutlineStyles.label}>{t("resume.seniority")}</Text>
        <Text style={sharedOutlineStyles.text}>{seniority}</Text>
      </View>
    </View>
  );
};

export default Seniority;
