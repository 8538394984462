import { ChangeEvent, forwardRef, useState } from "react";
import { classJoin } from "@ps/utils";
import InputBase from "./inputBase";
import { InputProps } from "./types";
import { INPUT_PREFIX } from "../../shared/data-cy";
import { ReactComponent as ErrorIcon } from "../../images/error-icon.svg";
import { ReactComponent as SuccessIcon } from "../../images/success-icon.svg";
import { ReactComponent as Magnifier } from "../../images/magnifier.svg";

const wrapperStyle = classJoin.template`
focus-within:text-neutralPrimary-20
flex flex-col
relative text-neutralPrimary-20
`;
const infoTextStyle = "mt-1 text-xs";
const successTextStyle = "text-success-60";
const errorTextStyle = "text-error-50";
const iconStyle = (translate: string) =>
  `absolute right-2 top-0 transform translate-y-${translate}`;
const inputBaseWhenIcon = "pr-8";

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      displayIcon = true,
      message,
      success,
      error,
      additionalClass,
      additionalContainerClass,
      dataCy,
      isSearch,
      onBlur,
      onFocus,
      ...inputBaseProps
    },
    ref,
  ) => {
    const [isInputFocused, setIsInputFocused] = useState(false);
    const handleInputFocus = (e: ChangeEvent<HTMLInputElement>): void => {
      onFocus && onFocus(e);
      setIsInputFocused(true);
    };
    const handleInputBlur = (e: ChangeEvent<HTMLInputElement>): void => {
      onBlur && onBlur(e);
      setIsInputFocused(false);
    };

    const successStyle = success ? successTextStyle : undefined;
    const errorStyle = error ? errorTextStyle : undefined;
    const optionalStyle = successStyle ?? errorStyle ?? "";

    const shouldDisplayIcon =
      !isInputFocused && displayIcon && (success || error);

    return (
      <div
        className={classJoin(wrapperStyle, additionalContainerClass)}
        data-cy={`${INPUT_PREFIX}-${dataCy}-wrapper`}
        ref={ref}
      >
        {isSearch && (
          <Magnifier className="text-neutralSecondary-41 fill-current absolute left-2 top-0 transform translate-y-2.5" />
        )}
        <InputBase
          {...inputBaseProps}
          success={success}
          error={error}
          additionalClass={classJoin(
            additionalClass,
            shouldDisplayIcon && inputBaseWhenIcon,
          )}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          dataCy={dataCy}
          isSearch={isSearch}
        />
        {(success || error) && (
          <span className={classJoin(infoTextStyle, optionalStyle)}>
            {message ?? ""}
          </span>
        )}
        {shouldDisplayIcon && (
          <span
            className={classJoin(
              optionalStyle,
              iconStyle(success ? "1/2" : "1/3"),
            )}
          >
            {success ? (
              <SuccessIcon
                className="text-success-60 fill-current"
                data-cy="svg-success-icon"
              />
            ) : (
              <ErrorIcon
                className="text-error-50 fill-current"
                data-cy="svg-error-icon"
              />
            )}
          </span>
        )}
      </div>
    );
  },
);

export default Input;
