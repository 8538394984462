import { Dayjs } from "dayjs";
import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import constructDataCy from "../helpers/dataCy";
import { PresetRange, PresetRangeValue } from "../types";
import { DATEPICKER_PRESET_RANGE_PREFIX } from "../../../shared/data-cy";
import { useThemeMode } from "../../theme-mode-provider";

const presetsWrapperStyle = `
border-t border-neutralSecondary-60 flex flex-row w-full flex-start
px-2.5 py-1 mt-3 items-center bg-neutralPrimary-100 gap-x-2
`;

interface PresetRangeProps {
  presetRanges?: PresetRange<Dayjs | [Dayjs, Dayjs]>[];
  onClick?: (value: PresetRangeValue) => () => void;
  dataCy: string;
}

const PresetRanges = ({
  presetRanges,
  onClick,
  dataCy,
}: PresetRangeProps): ReactElement => {
  const applyDataCy = constructDataCy(dataCy);
  const { isLightTheme } = useThemeMode();

  const presetStyle = classJoin(
    "bg-primary-50 rounded-md text-primary-100 px-2 py-0.5 font-normal cursor-pointer focus:outline-none",
    isLightTheme ? "bg-opacity-30" : "",
  );

  return (
    <>
      {presetRanges && (
        <div className={presetsWrapperStyle}>
          {presetRanges.map(({ displayName, value }) => (
            <button
              onClick={onClick?.(value)}
              className={presetStyle}
              key={displayName}
              data-cy={applyDataCy(
                DATEPICKER_PRESET_RANGE_PREFIX,
                "presetRange",
                displayName,
              )}
            >
              {displayName}
            </button>
          ))}
        </div>
      )}
    </>
  );
};

export default PresetRanges;
