import { THEME_MODE_LOCAL_STORAGE, Theme } from "./types";

const getSavedThemeFromLocalStorage = () => {
  const usersSelection = localStorage.getItem(THEME_MODE_LOCAL_STORAGE);

  switch (usersSelection) {
    case Theme.LIGHT:
      return Theme.LIGHT;
    case Theme.DARK:
      return Theme.DARK;
    case Theme.HIGH_CONTRAST:
      return Theme.HIGH_CONTRAST;
    default:
      return undefined;
  }
};

const getInitialTheme = (): Theme => {
  const DEFAULT_THEME_IF_NOT_PREFERRED = Theme.LIGHT;

  const usersSelection = getSavedThemeFromLocalStorage();

  if (usersSelection) return usersSelection;

  const prefersDarkTheme = window.matchMedia(
    "(prefers-color-scheme: dark)",
  ).matches;
  const prefersLightTheme = window.matchMedia(
    "(prefers-color-scheme: light)",
  ).matches;
  const prefersHighContrastTheme = window.matchMedia(
    "(prefers-color-scheme: highContrast)",
  ).matches;

  if (prefersDarkTheme) return Theme.DARK;
  if (prefersLightTheme) return Theme.LIGHT;
  if (prefersHighContrastTheme) return Theme.HIGH_CONTRAST;
  return DEFAULT_THEME_IF_NOT_PREFERRED;
};

export default getInitialTheme;
