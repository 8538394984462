import { useState, ReactElement } from "react";
import dayjs from "dayjs";
import {
  Datepicker2,
  Input,
  Label,
  SearchSelectPicker,
  Textarea,
} from "@ps/ui-components";
import { prepareTechStackDictionaryValues } from "@ps/utils";
import { Controller, useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { FormSectionProps } from "../../education/types";
import { TECH_STACK } from "../../projects/constants";
import { checkHasEmptyFields } from "../../../helpers";
import { EXPERIENCE_ACCOMPLISHMENT_EDIT } from "../../../../../../shared/data-cy";
import {
  DATE,
  DESCRIPTION,
  TITLE,
  TUTORS,
  PLACEHOLDER,
  AWARD_NAME,
} from "../constants";
import { ITechStackProposal } from "../../../types";
import styles from "../../styles.module.css";
import { FULL_TECH_SEARCH_MAX } from "../../../../../../shared/constants";

const MAX_LENGTH_DESCRIPTION = 4000;
const MAX_LENGTH_NAME = 100;

const TutorForm = ({
  fullTextSearch,
  index,
  sourcePath = "tutors",
  technologyDict,
}: FormSectionProps): ReactElement => {
  const { control, getValues, getFieldState, formState, watch, register } =
    useFormContext();
  const { t } = useTranslationWithNamespace();

  const [searchedProposals, setSearchedProposals] = useState<
    ITechStackProposal[] | []
  >([]);

  const BASE_PATH = `${EXPERIENCE_ACCOMPLISHMENT_EDIT}_${TUTORS}`;

  const translate = (field: string, isRequired = true): string =>
    `${t(`profile.experience.cards.tutor.${field}`)}${isRequired ? "*" : ""}`;

  const getFormFieldName = (fieldName: string): string =>
    `${sourcePath}.${index}.${fieldName}`;

  const fieldTitle = getFieldState(getFormFieldName(AWARD_NAME), formState);
  const fieldDescription = getFieldState(
    getFormFieldName(DESCRIPTION),
    formState,
  );

  const hasEmptyFields = checkHasEmptyFields(
    getValues([TITLE, DATE, DESCRIPTION, TECH_STACK]),
  );

  const errorEmptyMessage = `${t("profile.errors.cantBeEmpty")}`;
  const errorBlankMessage = `${t("profile.errors.cantBeBlank")}`;
  const errorMaxChars = (maxChars: number): string =>
    `${t("profile.errors.cantBeMoreChars", {
      number: maxChars,
    })}`;

  const isEmptyOrBlank = (
    value: string,
    maxChars: number,
  ): string | boolean => {
    if (!value?.length && hasEmptyFields) return errorEmptyMessage;
    if (!value?.trim().length) return errorBlankMessage;
    if (maxChars && value && value.length > maxChars)
      return errorMaxChars(maxChars);
    return true;
  };

  return (
    <div className="flex flex-col gap-y-5" key={uid(`${TUTORS}-${index}`)}>
      <div className="flex flex-row gap-x-5">
        <Label dataCy={`${BASE_PATH}_${TITLE}`} text={translate(TITLE)}>
          <Input
            dataCy={`${BASE_PATH}_${TITLE}`}
            width="w-full"
            autoFocus
            value={watch(getFormFieldName(TITLE)) || ""}
            error={!!fieldTitle?.error}
            message={fieldTitle?.error?.message || ""}
            placeholder={translate(`${TITLE}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(TITLE), {
              validate: (value) => isEmptyOrBlank(value, MAX_LENGTH_NAME),
            })}
          />
        </Label>
        <Label dataCy={`${BASE_PATH}_${DATE}`} text={translate(DATE)}>
          <Controller
            name={getFormFieldName(DATE)}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Datepicker2
                dataCy={`${BASE_PATH}_${DATE}`}
                error={error?.message}
                onChange={onChange}
                selected={value ? dayjs(value) : null}
                dateFormat="MM/YYYY"
                timeView={["month", "year"]}
                type="month"
              />
            )}
            rules={{
              validate: (value) => {
                if (!value && hasEmptyFields) return errorEmptyMessage;
                if (value && dayjs().isBefore(value))
                  return `${t("profile.errors.noFutureDate")}`;
                return true;
              },
            }}
          />
        </Label>
      </div>
      <div className="h-40">
        <Label
          dataCy={`${BASE_PATH}_${DESCRIPTION}`}
          text={translate(DESCRIPTION)}
        >
          <Textarea
            dataCy={`${BASE_PATH}_${DESCRIPTION}`}
            maxLength={MAX_LENGTH_DESCRIPTION}
            value={watch(getFormFieldName(DESCRIPTION)) || ""}
            error={!!fieldDescription?.error}
            message={fieldDescription?.error?.message || ""}
            placeholder={translate(`${DESCRIPTION}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(DESCRIPTION), {
              validate: (value) =>
                isEmptyOrBlank(value, MAX_LENGTH_DESCRIPTION),
            })}
          />
        </Label>
      </div>
      <div className="w-1/2 pr-2.5">
        <Label
          text={translate(TECH_STACK)}
          dataCy={`${BASE_PATH}_${TECH_STACK}`}
        >
          <Controller
            name={getFormFieldName(TECH_STACK)}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SearchSelectPicker
                dataCy={TECH_STACK}
                additionalTagsWrapClassName={styles.techWrapperOther}
                defaultSelected={prepareTechStackDictionaryValues(
                  value,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  technologyDict,
                )}
                onTagSelected={onChange}
                proposalTags={searchedProposals}
                onInputChange={(searchValue) => {
                  const result =
                    fullTextSearch &&
                    fullTextSearch(searchValue, FULL_TECH_SEARCH_MAX);
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setSearchedProposals(result);
                }}
                error={!!error}
                message={error?.message}
              />
            )}
            rules={{
              validate: (value) =>
                !value?.length && hasEmptyFields ? errorEmptyMessage : true,
            }}
          />
        </Label>
      </div>
    </div>
  );
};

export default TutorForm;
