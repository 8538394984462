import { ReactElement } from "react";
import { View, StyleSheet, Text, Image } from "@react-pdf/renderer";
import {
  groupDataBasedOnParameter,
  countTotalInGroup,
  FlattenEntry,
} from "@ps/time-tracker";
import { colorFormatters, minutesToHHMM } from "@ps/utils";
import { useTranslationWithNamespace } from "../../hooks";
import { FlattenReportEntry, TableProps } from "./types";

import lockIcon from "../../images/report/lock.png";

const styles = StyleSheet.create({
  container: {
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    width: "100%",
    fontFamily: "PoppinsLight",
  },
  header: {
    backgroundColor: "#E4E8ED",
    borderTopRightRadius: "40px",
    borderTopLeftRadius: "40px",
    textTransform: "capitalize",
    color: "#6B7482",
    fontSize: "12px",
    width: "100%",
    display: "flex",
    paddingLeft: "20px",
    paddingRight: "5px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "40px",
    fontFamily: "PoppinsBold",
  },
  renderRowContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    height: "46px",
    borderBottom: "1px solid #E5E5E5",
    display: "flex",
    paddingLeft: "20px",
    paddingRight: "5px",
    width: "100%",
    flexDirection: "row",
  },
  otherRow: { display: "flex", alignItems: "center", flexDirection: "row" },
  dot: {
    borderRadius: "50%",
    width: "6px",
    height: "6px",
    marginRight: "8px",
  },
  firstRow: {},
  secondRow: {
    backgroundColor: "#F6F6F6",
    paddingLeft: "50px",
  },
  thirdRow: {
    backgroundColor: "#EFEFEF",
    paddingLeft: "80px",
  },

  projectNameRow: {
    display: "flex",
    alignItems: "center",
    gap: "32px",
    height: "64px",
    flexDirection: "row",
    width: "85%",
  },
});

const Table = ({ data, groupNames }: TableProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const tableGroupNames = Object.entries(groupNames)
    ?.map(([_, value]) => value.label)
    ?.filter((el: string) => !!el);

  const renderProjectIcon = (group: FlattenReportEntry): ReactElement => {
    return group?.isPublic ? (
      <View
        style={{
          backgroundColor: colorFormatters.prepareRGBObjectToHEX(
            group.projectColor,
          ),
          ...styles.dot,
        }}
      />
    ) : (
      <Image
        style={{
          height: "12px",
          paddingRight: "16px",
        }}
        src={lockIcon}
      />
    );
  };

  const renderRows = (
    group: FlattenEntry[],
    groupNumber: 0 | 1 | 2,
    rowName: string,
  ): ReactElement => (
    <View
      style={{
        ...styles.renderRowContainer,
        ...styles?.[rowName],
      }}
    >
      {groupNames?.[`group${groupNumber}`]?.value === "projectName" ? (
        <View style={styles.projectNameRow}>
          <View
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              flexDirection: "row",
            }}
          >
            {renderProjectIcon(group?.[0])}
            <Text style={{ paddingRight: "16px", fontFamily: "Poppins" }}>
              {group?.[0]?.projectName || ""}
            </Text>
          </View>
          <Text style={{ color: "#6B7482" }}>
            {group?.[0]?.clientName || ""}
          </Text>
        </View>
      ) : (
        <View style={styles.otherRow}>
          <Text style={{ textAlign: "left" }}>
            {groupNames?.[`group${groupNumber}`]?.value === "rate"
              ? `x${
                  parseInt(
                    group?.[0]?.[groupNames?.[`group${groupNumber}`]?.value],
                    10,
                  ) / 10
                }`
              : group?.[0]?.[groupNames?.[`group${groupNumber}`]?.value] || (
                  <Text>{t("report.noDescription")}</Text>
                )}
          </Text>
        </View>
      )}
      <Text style={{ width: "15%" }}>
        {minutesToHHMM(countTotalInGroup(group))}
      </Text>
    </View>
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={{ width: "85%" }}>{tableGroupNames.join("/")}</Text>
        <Text style={{ width: "15%" }}>{t("report.duration")}</Text>
      </View>
      {Object.keys(data)?.map((itemName: string): ReactElement => {
        const group: FlattenEntry[] = data[itemName];
        return (
          <View key={itemName}>
            {renderRows(group, 0, "firstRow")}
            <View>
              {Object.keys(
                groupDataBasedOnParameter(group, groupNames?.group1.value),
              ).map((firstNestedItemName: string): ReactElement => {
                const firstNestedGroup: FlattenEntry[] =
                  groupDataBasedOnParameter(group, groupNames?.group1.value)[
                    firstNestedItemName
                  ];

                return (
                  <View key={firstNestedItemName}>
                    {renderRows(firstNestedGroup, 1, "secondRow")}
                    <View>
                      {Object.keys(
                        groupDataBasedOnParameter(
                          firstNestedGroup,
                          groupNames?.group2.value,
                        ),
                      ).map((secondNestedItemName: string): ReactElement => {
                        const secondNestedGroup: FlattenEntry[] =
                          groupDataBasedOnParameter(
                            firstNestedGroup,
                            groupNames?.group2.value,
                          )[secondNestedItemName];

                        return groupNames?.group2.value ? (
                          <View key={secondNestedItemName}>
                            {renderRows(secondNestedGroup, 2, "thirdRow")}
                          </View>
                        ) : (
                          <Text />
                        );
                      })}
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default Table;
