import { ReactElement, KeyboardEvent } from "react";
import { RestrictedWrapper } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../hooks";
import {
  PROSPECT_FOLDER,
  PROSPECT_FOLDER_TEAM_MANUALLY,
  PROSPECT_FOLDER_TEAM_ML,
} from "../../../../shared/data-cy";
import { ReactComponent as Puzzle } from "../../../../../images/choose-proposal-folder-options/ilu-puzzle.svg";
import Keys from "../../../../../shared/keys";
import { MLQuestionScreenProps } from "./types";
import { PROSPECT_FULL_ACCESS } from "../../../../../shared/permissions";

const BASE_PATH = "projects.prospect.proposalFolder";

const MLQuestionScreen = ({
  onClickLeft,
  onMLClick,
}: MLQuestionScreenProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <div
      className="relative w-full h-full items-start flex rounded-3xl bg-primary-50"
      data-cy={PROSPECT_FOLDER}
    >
      <div className="h-full w-full">
        <Puzzle className="h-full w-max" />
      </div>
      <div className="flex flex-col h-full w-full text-neutralPrimary-100 items-end absolute right-8 top-10 text-4xl gap-y-2">
        <RestrictedWrapper permissions={PROSPECT_FULL_ACCESS}>
          <span
            data-cy={PROSPECT_FOLDER_TEAM_ML}
            role="button"
            className="hover:underline cursor-pointer font-bold"
            tabIndex={0}
            onClick={onMLClick}
            onKeyDown={(e: KeyboardEvent<HTMLSpanElement>) => {
              if (e.key === Keys.ENTER) {
                onMLClick();
              }
            }}
          >
            {t(`${BASE_PATH}.run`)}
          </span>
          <span>{t(`${BASE_PATH}.teamMatching`)}</span>
          <span>{t(`${BASE_PATH}.process`)}</span>
          <span className="text-3xl my-4">{t(`${BASE_PATH}.or`)}</span>
          <span
            data-cy={PROSPECT_FOLDER_TEAM_MANUALLY}
            role="button"
            className="hover:underline cursor-pointer font-bold"
            tabIndex={0}
            onClick={onClickLeft}
            onKeyDown={(e: KeyboardEvent<HTMLSpanElement>) => {
              if (e.key === Keys.ENTER) {
                onClickLeft();
              }
            }}
          >
            {t(`${BASE_PATH}.addTeam`)}
          </span>
          <span>{t(`${BASE_PATH}.manually`)}</span>
        </RestrictedWrapper>
      </div>
    </div>
  );
};

export default MLQuestionScreen;
