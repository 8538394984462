import { ReactElement } from "react";
import { RestrictedRoute } from "@ps/app";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { hhPermissions } from "@ps/hh";
import { useDispatch } from "redux-react-hook";
import { hasPermissions } from "@ps/ui-components";
import { clearRedirection, RedirectAlertModal } from "@ps/alert-modal";
import { ACCESSES, MEMBERS } from "../shared/routes";
import PermissionsContainer from "../ui/permissionsContainer";
import Permissions from "../ui/permissions";
import Members from "../ui/members";
import { permissionsPermissions } from "../../index";
import {
  PermissionRoutesMapState,
  PermissionRoutesMapStateReturn,
} from "./types";
import { useMappedStateSelector } from "../hooks";

const PermissionsRoutes = (): ReactElement => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const mapState = (
    state: PermissionRoutesMapState,
  ): PermissionRoutesMapStateReturn => ({
    allPermissions: state.permissions || [],
  });

  const { allPermissions } = useMappedStateSelector(mapState, "");

  return (
    <PermissionsContainer>
      <RedirectAlertModal
        dispatch={dispatch}
        clearRedirection={clearRedirection}
      />
      <Switch>
        <RestrictedRoute
          path={`${path}/${MEMBERS}`}
          permissions={[
            hhPermissions.USERS_LIST_ALL,
            hhPermissions.USERS_ASSIGN_ROLES,
          ]}
        >
          <Members />
        </RestrictedRoute>
        <RestrictedRoute
          path={`${path}/${ACCESSES}`}
          permissions={[permissionsPermissions.ROLES_MANAGE]}
        >
          <Permissions />
        </RestrictedRoute>
        <Route path={path}>
          <Redirect
            to={`${path}/${
              hasPermissions(allPermissions, hhPermissions.USERS_LIST_ALL) ||
              hasPermissions(allPermissions, hhPermissions.USERS_ASSIGN_ROLES)
                ? MEMBERS
                : ACCESSES
            }`}
          />
        </Route>
      </Switch>
    </PermissionsContainer>
  );
};

export default PermissionsRoutes;
