import { RefObject, useEffect, useRef } from "react";
import { Keys } from "../../shared";

export default function useClickOutside(
  func: () => void,
  omit?: RefObject<HTMLDivElement>,
  keyCode = Keys.ESC,
): [RefObject<HTMLDivElement>] {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !ref.current?.contains(event.target as Node) &&
        !omit?.current?.contains(event.target as Node)
      ) {
        func();
      }
    };

    const handleOnKeyDownOutside = (event: KeyboardEvent) => {
      if (
        !ref.current?.contains(event.target as Node) &&
        !omit?.current?.contains(event.target as Node) &&
        event.code === keyCode
      ) {
        event.preventDefault();
        event.stopPropagation();
        func();
      }
    };
    document.addEventListener("mousedown", handleClickOutside, true);
    document.addEventListener("keydown", handleOnKeyDownOutside, true);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
      document.addEventListener("keydown", handleOnKeyDownOutside, true);
    };
  }, [ref, func, omit]);
  return [ref];
}
