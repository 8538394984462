import { ReactElement } from "react";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import { TagProps } from "../../components/search-select-picker/types";

const getHighlightedText = (
  item: TagProps,
  index: number,
  trimmedSearchValue: string,
  currentSelection: number,
  handleOnAppendTag: (newTag: TagProps) => void,
): ReactElement => {
  const escapedValue = trimmedSearchValue.replace(
    /[.*+?^${}()|[\]\\]/g,
    "\\$&",
  );
  const parts = item?.name.split(new RegExp(`(${escapedValue})`, "gi"));

  return (
    <span
      className={classJoin(
        `hover:bg-primary-70 hover:bg-opacity-30
        rounded-md px-2 py-1 cursor-pointer`,
        currentSelection === index ? "bg-primary-70 bg-opacity-30" : "",
      )}
      key={item.id}
      onClick={() => handleOnAppendTag(item)}
      role="presentation"
    >
      {parts.map((part: string, idx: number) =>
        part.toLowerCase() === trimmedSearchValue.toLowerCase() ? (
          <span className="font-bold text-primary-50" key={uid(idx)}>
            {part}
          </span>
        ) : (
          <span className="text-neutralPrimary-20" key={uid(idx)}>
            {part}
          </span>
        ),
      )}
    </span>
  );
};

export default getHighlightedText;
