import { KeyboardEvent, useState, ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { Dropdown } from "@ps/ui-components";
import { ReactComponent as DotsIcon } from "../../../images/dropdown-dots.svg";
import { useTranslationWithNamespace } from "../../../hooks";
import { RoleDropdownMenuProps, ActionButtonProps } from "./types";
import { focusVisibleStyles, Keys } from "../../../shared";

const ActionButton = ({
  handleOnClick,
  field,
}: ActionButtonProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <span
      role="button"
      tabIndex={0}
      onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
        if (event.key === Keys.ENTER) handleOnClick();
      }}
      className={classJoin(
        "hover:text-primary-50 cursor-default",
        focusVisibleStyles,
      )}
      onClick={handleOnClick}
    >
      {t(`actions.${field}`)}
    </span>
  );
};

const RoleDropdownMenu = ({
  isInEdit,
  onSaveClick,
  onDeleteClick,
  onEditClick,
  onCancelClick,
  isDisabled,
  isAddingNew,
}: RoleDropdownMenuProps): ReactElement => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const renderDots = () => (
    <div
      className={classJoin(
        isDisabled ? "opacity-50 event-pointer-events-none" : "",
        "w-max flex ml-auto mr-3",
        focusVisibleStyles,
      )}
      role={isDisabled ? undefined : "button"}
      tabIndex={isDisabled ? undefined : 0}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === Keys.ENTER) setShowDropdown((prev) => !prev);
      }}
      onClick={() => setShowDropdown(true)}
    >
      <DotsIcon
        className={classJoin(
          "fill-current",
          isInEdit ? "text-primary-50" : "text-neutralSecondary-41",
        )}
      />
    </div>
  );

  return isDisabled ? (
    renderDots()
  ) : (
    <Dropdown
      show={showDropdown}
      onClickOutside={() => setShowDropdown(false)}
      withArrow
      width="w-auto"
      overlay={
        <div className="flex flex-col gap-4 px-5 py-3 ">
          {isInEdit ? (
            <ActionButton
              field="save"
              handleOnClick={() => {
                onSaveClick();
                setShowDropdown(false);
              }}
            />
          ) : (
            <ActionButton
              field="edit"
              handleOnClick={() => {
                onEditClick();
                setShowDropdown(false);
              }}
            />
          )}
          <ActionButton
            field="cancel"
            handleOnClick={() => {
              onCancelClick();
              setShowDropdown(false);
            }}
          />
          {!isAddingNew && (
            <ActionButton
              field="delete"
              handleOnClick={() => {
                onDeleteClick();
                setShowDropdown(false);
              }}
            />
          )}
        </div>
      }
    >
      {renderDots()}
    </Dropdown>
  );
};

export default RoleDropdownMenu;
