import { colorFormatters } from "@ps/utils";
import { ProjectModel } from "@ps/hh";

const prepareProjectsWithColours = (
  idsList: string[] | [] = [],
  projectsList: ProjectModel[] = [],
): { [projectId: string]: string } => {
  let result = {};
  idsList.forEach((projectId: string) => {
    const currentProjectFromList = projectsList.find(
      (item: ProjectModel) => item?.id === projectId,
    );

    if (currentProjectFromList) {
      const {
        details: { color },
      } = currentProjectFromList;

      result = {
        ...result,
        [projectId]: colorFormatters.hexToRgbString(color),
      };
    }
  });
  return result;
};

export default prepareProjectsWithColours;
