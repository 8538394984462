import dayjs from "dayjs";

export const TODAY = "today";
export const YESTERDAY = "yesterday";
export const THIS_WEEK = "thisWeek";
export const LAST_WEEK = "lastWeek";
export const THIS_MONTH = "thisMonth";
export const LAST_MONTH = "lastMonth";
export const THIS_YEAR = "thisYear";
export const LAST_YEAR = "lastYear";

export const defaultPresets = [
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  LAST_WEEK,
  THIS_MONTH,
  LAST_MONTH,
  THIS_YEAR,
  LAST_YEAR,
];

export const TODAY_DATE = dayjs();
export const YESTERDAY_DATE = dayjs().subtract(1, "day");
export const THIS_WEEK_START_DATE = dayjs().startOf("week").day(1);
export const THIS_WEEK_END_DATE = dayjs().startOf("week").day(7);
export const LAST_WEEK_START_DATE = dayjs()
  .startOf("week")
  .day(1)
  .subtract(1, "week");
export const LAST_WEEK_END_DATE = dayjs()
  .startOf("week")
  .day(7)
  .subtract(1, "week");
export const THIS_MONTH_START_DATE = dayjs().startOf("month");
export const THIS_MONTH_END_DATE = dayjs().endOf("month");
export const LAST_MONTH_START_DATE = dayjs()
  .subtract(1, "month")
  .startOf("month");
export const LAST_MONTH_END_DATE = dayjs().subtract(1, "month").endOf("month");
export const THIS_YEAR_START_DATE = dayjs().startOf("year");
export const THIS_YEAR_END_DATE = dayjs().endOf("year");
export const LAST_YEAR_START_DATE = dayjs().subtract(1, "year").startOf("year");
export const LAST_YEAR_END_DATE = dayjs().subtract(1, "year").endOf("year");
