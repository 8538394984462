import { ReactElement } from "react";
import { Button } from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { ReactComponent as IconTextSnippet } from "../../../images/finance/text-snippet.svg";
import { ReactComponent as IconFolderMultipleOutline } from "../../../images/finance/folder-multiple-outline.svg";
import {
  ADD_TEMPLATE,
  BASE_TRANSLATION_PATH,
  HEADER_BAR,
  HOLIDAYS_TEMPLATES,
  ADD_HOLIDAY,
} from "../constants";
import { HeaderBarMapState, HeaderBarProps } from "./types";

const HeaderBar = ({
  onAddTemplate,
  onAddHoliday,
}: HeaderBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const mapState = (state: HeaderBarMapState): HeaderBarMapState => ({
    editMode: state?.editMode,
  });

  const { editMode } = useMappedStateSelector(mapState);

  return (
    <div className="flex gap-8 justify-between mb-2 w-full">
      <Button
        dataCy={`${HOLIDAYS_TEMPLATES}_${HEADER_BAR}_${ADD_TEMPLATE}`}
        disabled={!!editMode}
        onClick={onAddTemplate}
      >
        <IconTextSnippet className="fill-current mr-3" />
        <span>{t(`${BASE_TRANSLATION_PATH}.${ADD_TEMPLATE}`)}</span>
      </Button>

      {onAddHoliday ? (
        <Button
          dataCy={`${HOLIDAYS_TEMPLATES}_${HEADER_BAR}_${ADD_HOLIDAY}`}
          disabled={!!editMode}
          onClick={onAddHoliday}
        >
          <IconFolderMultipleOutline className="fill-current mr-3" />
          <span>
            {t(`${BASE_TRANSLATION_PATH}.${HEADER_BAR}.${ADD_HOLIDAY}`)}
          </span>
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default HeaderBar;
