import {
  useContext,
  useEffect,
  useState,
  ReactElement,
  useRef,
  KeyboardEvent,
} from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "redux-react-hook";
import { Button, Card, LinkButton, Timeline } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import { uid } from "react-uid";
import { SECTION_DISPLAY_CARD_PREFIX } from "@ps/ui-components/src/shared/data-cy";
import { EXPERIENCE_ACCOMPLISHMENT_VIEW } from "../../../../shared/data-cy";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { NEW_ITEMS } from "./constants";
import ExperienceContext from "../context";
import {
  SectionWrapperProps,
  SectionWrapperMapState,
  SectionWrapperMapStateReturn,
} from "./types";
import { ReactComponent as EditIcon } from "../../../../images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../images/delete.svg";
import { ReactComponent as AddNewIcon } from "../../../../images/add-new.svg";
import ConfirmRemove from "./confirmRemove";
import { filterDataForRemove } from "../helpers";
import { toggleEditMode } from "../../../../store";
import { Keys } from "../../../../shared";

const SectionWrapper = ({
  name,
  Edit,
  Icon,
  View,
  arrayData,
  index: sectionIndex,
}: SectionWrapperProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const {
    onSubmit,
    isEditMode,
    setEditMode,
    cancelEditMode,
    fullTextSearch,
    setDeletingMode,
    deletingMode,
    setFocusElement,
    handleTabFocus,
  } = useContext(ExperienceContext);
  const editFormRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const [editIndex, setEditIndex] = useState<undefined | number>();
  const [removeIndex, setRemoveIndex] = useState<undefined | number>();

  const focusId = (type: "edit" | "delete" | "add", index: number) => {
    let id = "";

    // eslint-disable-next-line default-case
    switch (type) {
      case "edit":
        id = `edit-${index}-${name}`;
        break;
      case "delete":
        id = `delete-${index}-${name}`;
        break;
      case "add":
        id = `add-${index}-${name}`;
        break;
    }

    return id;
  };

  const scrollEditForm = () => {
    editFormRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  const mapState = (
    state: SectionWrapperMapState,
  ): SectionWrapperMapStateReturn => ({
    editMode: state.editMode,
    technologyDict: state?.dictionaries?.technology,
    data: state?.profiles?.myProfile?.experience?.[name] || [],
    isFormModified: state.isFormModified,
  });

  const { editMode, technologyDict, data } = useMappedStateSelector(mapState);
  const { isFormModified } = useMappedStateSelector(mapState, "");

  useEffect(() => {
    if (editMode) scrollEditForm();
  }, [editMode]);

  useEffect(() => {
    setFocusElement("");
  }, []);

  const { clearErrors, reset } = useFormContext();

  const handleOnEdit = (section: string, index: number | undefined) => {
    dispatch(toggleEditMode(section));
    setEditIndex(index);
    setEditMode();
    clearErrors();
    reset();
  };

  const handleOnAdd = (section: string) => {
    handleOnEdit(section, arrayData.fields.length);
    arrayData.append(NEW_ITEMS[name]);
    setFocusElement(focusId("add", sectionIndex));
  };

  const handleOnCancel = () => {
    handleOnEdit("", undefined);
    cancelEditMode();
    reset();
    clearErrors();
    if (data.length < arrayData.fields.length) {
      arrayData.remove(arrayData.fields.length - 1);
    }
  };

  const handleOnRemove = (currentIndex: number) => {
    arrayData.remove(currentIndex);
    setRemoveIndex(undefined);
    onSubmit(filterDataForRemove(name, arrayData.fields, currentIndex));
    if (arrayData.fields.length === 1) {
      handleTabFocus();
    } else setFocusElement(focusId("delete", currentIndex - 1));
    setDeletingMode("");
  };

  const handleOnDelete = (currentIndex: number) => {
    setRemoveIndex(currentIndex);
    setDeletingMode(name);
    setFocusElement(focusId("delete", currentIndex));
  };

  useEffect(() => {
    return () => {
      handleOnEdit("", undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEditMode) handleOnEdit("", undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  return (
    <Card
      additionalCardClassName="py-5 px-8 mb-5"
      dataCy={`${SECTION_DISPLAY_CARD_PREFIX}-${name}`}
    >
      {Icon && (
        <div className="flex gap-x-2 items-center text-2xl font-bold mb-5 capitalize">
          <Icon icon={Icon} size="8" data-cy="education" />
          {name}
        </div>
      )}
      {editMode === name ? (
        <div
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === Keys.ESC) handleOnCancel();
          }}
          role="presentation"
        >
          <Edit
            fullTextSearch={fullTextSearch}
            index={editIndex}
            technologyDict={technologyDict}
          />
          <div className="flex flex-col gap-y-5 ml-4 my-5" ref={editFormRef}>
            <div className="flex flex-row gap-x-2">
              <Button dataCy="save" type="submit">
                {t("profile.experience.actions.save")}
              </Button>
              <Button
                dataCy="cancel"
                variant="secondary"
                onClick={handleOnCancel}
              >
                {t("profile.experience.actions.cancel")}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Timeline
            dataCy={`${EXPERIENCE_ACCOMPLISHMENT_VIEW}_${name}`}
            offset={3}
          >
            {data.map((item, index: number) => (
              <div
                className={classJoin(
                  "flex items-start relative",
                  index === removeIndex && "min-h-64",
                )}
                key={uid(item, index)}
              >
                <View
                  {...item}
                  technologyDict={technologyDict}
                  isDeleting={index === removeIndex}
                />
                <div className="flex items-center gap-2">
                  <LinkButton
                    id={focusId("edit", index)}
                    disabled={
                      isFormModified || index === removeIndex || deletingMode
                    }
                    dataCy={`${EXPERIENCE_ACCOMPLISHMENT_VIEW}_${name}_editButton`}
                    prefixIcon={<EditIcon className="fill-current" />}
                    onClick={() => {
                      handleOnEdit(name, index);
                      setFocusElement(focusId("edit", index));
                    }}
                    additionalClassName="flex items-end gap-1"
                  >
                    {t("profile.experience.actions.editSection")}
                  </LinkButton>
                  <LinkButton
                    id={focusId("delete", index)}
                    disabled={
                      isFormModified || index === removeIndex || deletingMode
                    }
                    dataCy={`${EXPERIENCE_ACCOMPLISHMENT_VIEW}_${name}_deleteButton`}
                    prefixIcon={<DeleteIcon className="fill-current" />}
                    onClick={() => handleOnDelete(index)}
                    additionalClassName="flex items-end gap-1"
                  >
                    {t("profile.experience.actions.deleteSection")}
                  </LinkButton>
                </div>
                {index === removeIndex && (
                  <ConfirmRemove
                    onCancel={() => {
                      setRemoveIndex(undefined);
                      setDeletingMode("");
                    }}
                    onRemove={() => handleOnRemove(index)}
                  />
                )}
              </div>
            ))}
          </Timeline>
          <LinkButton
            id={focusId("add", sectionIndex)}
            disabled={isFormModified || deletingMode}
            dataCy={`${EXPERIENCE_ACCOMPLISHMENT_VIEW}_${name}_addNew`}
            prefixIcon={
              <AddNewIcon className="text-primary-51 fill-current w-6 h-6" />
            }
            onClick={() => handleOnAdd(name)}
            additionalClassName="p-2.5 pl-1.5 flex gap-1"
          >
            {t("profile.experience.actions.addNew")}
          </LinkButton>
        </>
      )}
    </Card>
  );
};
export default SectionWrapper;
