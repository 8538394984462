import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { classJoin } from "@ps/utils";
import Background from "./background";
import { TileProps } from "./types";

const tileStyle = classJoin.template`
relative bg-gradient-to-r from-primary-20 to-primary-50
w-full h-full flex flex-col justify-center
`;

const buttonStyle = classJoin.template`
text-2xl border border-primary-100
text-primary-100 rounded-md py-4 w-52
mr-5 hover:bg-primary-50 hover:border-primary-50
`;

const Tile = ({ title, buttonInfo, Icon, path }: TileProps): ReactElement => {
  const history = useHistory();

  return (
    <div className={tileStyle}>
      <Background />
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="absolute top-1/2 right-5 transform -translate-y-2/4"
        >
          <Icon className="2xl:w-96 2xl:h-96 xl:w-max xl:h-max" />
        </motion.div>
      </AnimatePresence>
      <div className="ml-20 z-10">
        <span className="text-5xl text-white">{title}</span>
        <div className="w-min h-min flex flex-row mt-10">
          <button
            className={buttonStyle}
            onClick={() => path && history.push(path)}
          >
            {buttonInfo}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tile;
