import { AnyAction, combineReducers } from "redux";
import { HolidaysItemModel } from "@ps/hh";
import ActionTypes from "../actions/actionTypes";
import { DaysOffModel, HolidaysUserHistoryModel } from "./types";

const userHolidaysTemplateReducer = (
  state: HolidaysItemModel[] = [],
  action: AnyAction,
): HolidaysItemModel[] => {
  switch (action.type) {
    case ActionTypes.FETCH_USER_HOLIDAYS_TEMPLATE_START:
      return state;
    case ActionTypes.FETCH_USER_HOLIDAYS_TEMPLATE_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_USER_HOLIDAYS_TEMPLATE_ERROR:
      return [];
    default:
      return state;
  }
};

const holidaysHistoryReducer = (
  state: HolidaysUserHistoryModel | Record<string, unknown> = {},
  action: AnyAction,
): HolidaysUserHistoryModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_HOLIDAYS_HISTORY_START:
      return state;
    case ActionTypes.FETCH_HOLIDAYS_HISTORY_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_HOLIDAYS_HISTORY_ERROR:
      return {};
    default:
      return state;
  }
};

const daysOffReducer = (
  state: DaysOffModel[] | Record<string, unknown> = [],
  action: AnyAction,
): DaysOffModel[] | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_DAYS_OFF_START:
      return state;
    case ActionTypes.FETCH_DAYS_OFF_START_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_DAYS_OFF_START_ERROR:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  userHolidaysTemplate: userHolidaysTemplateReducer,
  holidaysHistory: holidaysHistoryReducer,
  daysOff: daysOffReducer,
});
