import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import Card from "../card";
import { useThemeMode } from "../theme-mode-provider";
import { TABLE_ROW } from "../../shared/data-cy";
import styles from "./styles.module.css";
import { TableRowProps } from "./types";

const TableRow = ({
  additionalClassName,
  children,
  columnsStyles,
  dataCy,
}: TableRowProps): ReactElement => {
  const { isHighContrast } = useThemeMode();

  return (
    <Card
      dataCy={`${TABLE_ROW}_${dataCy}`}
      additionalCardClassName={classJoin(
        styles.tableGrid,
        columnsStyles,
        "rounded-lg px-8 py-6",
        isHighContrast ? "border border-neutralSecondary-40" : "",
      )}
      additionalClassName={additionalClassName}
    >
      {children}
    </Card>
  );
};

export default TableRow;
