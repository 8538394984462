import { ReactElement } from "react";
import { Avatar, Icon } from "@ps/ui-components";
import { ReactComponent as NoAvatarIcon } from "../../../images/no-avatar.svg";
import { FINANCES } from "./constants";
import { UserAvatarProps } from "../types";

const UserAvatar = ({ avatar }: UserAvatarProps): ReactElement =>
  avatar ? (
    <Avatar image={avatar} dataCy={`${FINANCES}_icon`} width={11} height={11} />
  ) : (
    <Icon
      icon={<NoAvatarIcon className="text-neutralSecondary-60" />}
      dataCy={`${FINANCES}_icon`}
      width="11"
      height="11"
    />
  );

export default UserAvatar;
