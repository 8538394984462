import { Card } from "@ps/ui-components";
import { ReactElement, KeyboardEvent } from "react";
import ActionButtons from "../../../common/actionButtons";
import { CardContainerProps } from "./types";
import { KNOWLEDGE_SCALE, ENJOYMENT_SCALE } from "../../../../../shared/consts";
import { Keys } from "../../../../../../shared";

const CardContainer = ({
  title = "",
  children,
  onCancel,
}: CardContainerProps): ReactElement => (
  <Card
    label={title}
    additionalCardClassName="py-4 px-8 flex flex-col"
    dataCy={`${KNOWLEDGE_SCALE}-${ENJOYMENT_SCALE}`}
  >
    <div
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === Keys.ESC) onCancel();
      }}
      className="flex flex-col gap-y-2"
      role="presentation"
    >
      {children}
    </div>
    <ActionButtons onCancel={onCancel} isEditMode />
  </Card>
);

export default CardContainer;
