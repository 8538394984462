import { UTCToDayjs } from "@ps/utils";
import { getName } from "i18n-iso-countries";
import dayjs from "dayjs";
import {
  CandidateModel,
  FolderAPIModel,
  FolderModel,
  GetCandidateAPIModel,
  GetStatusModel,
  StatusModel,
} from "../types";
import { PrepareCountryToDisplayReturn } from "../../../client-domain/models/types";
import { CURRENT_LANGUAGE_CODE } from "../../../i18n/constants";

const prepareCountryToDisplay = (
  country: string,
): PrepareCountryToDisplayReturn => ({
  label: getName(country, CURRENT_LANGUAGE_CODE, { select: "official" }),
  value: country,
});

const prepareStatus = (status: GetStatusModel): StatusModel => ({
  acceptance: status.acceptance,
  feedback: status.feedback,
  setAt: status.setAt ? dayjs(status.setAt) : undefined,
});

const fromAPIToModel = (data: FolderAPIModel): FolderModel => ({
  id: data.id,
  folderName: data?.name || "",
  clientId: data.clientId,
  pin: data?.pin || "",
  candidates: data.candidates.map(
    (candidate: GetCandidateAPIModel): CandidateModel => ({
      userId: candidate.userId,
      createdAt: candidate.createdAt ? dayjs(candidate.createdAt) : "",
      seniority: candidate.seniority,
      latestStatus: candidate.status?.length
        ? prepareStatus(candidate.status?.[candidate.status?.length - 1])
        : "",
      status: candidate.status?.length
        ? candidate.status.map(
            (item: GetStatusModel): StatusModel => prepareStatus(item),
          )
        : [],
      firstName: candidate.firstName,
      lastName: candidate.lastName,
      avatar: candidate.avatar,
      professions: candidate.professions,
      country: prepareCountryToDisplay(candidate.country || ""),
      timeZone: candidate.timeZone,
      favorited: candidate.favorited,
      deleted: candidate.deleted,
    }),
  ),
  expirationDate: UTCToDayjs(data.expirationDate || ""),
  lastUpdate: UTCToDayjs(data.lastUpdate || ""),
  anonymized: data.anonymized,
  archived: data.archived,
});

const foldersListFromAPIToModel = (folders: FolderAPIModel[]): FolderModel[] =>
  folders?.map((folder) => fromAPIToModel(folder));

export { fromAPIToModel, foldersListFromAPIToModel };
