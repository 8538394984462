import { createContext, useContext } from "react";
import { ContextProps } from "./types";

export const ModalContext = createContext<ContextProps>({});

export const useModalContext = (): ContextProps => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      "Modal elements compound components cannot be rendered outside the Modal component",
    );
  }
  return context;
};
