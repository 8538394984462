import { useEffect, useState } from "react";
import Fuse from "fuse.js";
import {
  prepareOutput,
  scoreByLength,
  sortResult,
  sumScores,
} from "../full-text-search";
import { INormalizedDictionaryEntry } from "../full-text-search/types";
import usePrevious from "./use-previous-state";

const weight = 2;

function useFullTextSearch(
  data: unknown[],
  mapper?: (dict: unknown[]) => void,
  options = {},
): { search: (str: string) => unknown[] } {
  const prevDataValue = usePrevious(data);
  const [dictionary, setDictionary] = useState<
    INormalizedDictionaryEntry[] | []
  >([]);

  useEffect(() => {
    const isNewData = JSON.stringify(data) !== JSON.stringify(prevDataValue);
    if (data?.length && isNewData) {
      let currentDictionary = JSON.parse(JSON.stringify(data));
      if (mapper) currentDictionary = mapper(currentDictionary);
      setDictionary(currentDictionary);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      setDictionary([]);
    };
  }, []);

  const fuseOptions = {
    // isCaseSensitive: false,
    includeScore: true,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    // threshold: 0.6,
    // distance: 100,
    // useExtendedSearch: false,
    ignoreLocation: false,
    ignoreFieldNorm: false,
    fieldNormWeight: 2,
    keys: ["normalizedName"], // TODO add aliases when BE will be ready
    ...options,
  };

  const fuse = new Fuse(dictionary, fuseOptions);
  const search = (searchedValue: string, resultLength?: number) => {
    if (!dictionary?.length) return [];
    const fuseResult = fuse.search(searchedValue.toLowerCase());
    const lengthSearch = scoreByLength(searchedValue, dictionary, weight);
    const result = sumScores(fuseResult, lengthSearch);
    const sortedResult = sortResult(result);

    return resultLength
      ? prepareOutput(sortedResult.slice(0, resultLength))
      : prepareOutput(sortedResult);
  };

  return {
    search,
  };
}

export default useFullTextSearch;
