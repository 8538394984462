import { minutesToHHMM } from "@ps/utils";
import {
  ProjectTimeEntryModel,
  ProjectTimeEntriesModel,
} from "../../../models";
import { IRatesMinutes } from "./types";

export const getRatesMinutes = (
  timeEntries: ProjectTimeEntriesModel,
): IRatesMinutes[] => {
  const dataArr = timeEntries?.flat();
  const result: IRatesMinutes[] = [];
  if (!dataArr?.length) return result;
  dataArr.reduce(
    (
      res: IRatesMinutes | Record<string, unknown>,
      value: ProjectTimeEntryModel,
    ) => {
      if (!res?.[value.rate]) {
        res[value.rate] = { rate: value.rate, minutes: 0 };
        result.push(res[value.rate]);
      }
      res[value.rate].minutes += value.minutes;
      return res;
    },
    {},
  );
  return result;
};

export const displayTotalFromProject = (
  timeEntries: ProjectTimeEntriesModel,
): string => {
  const sumTotal: number = timeEntries
    ?.flat()
    .reduce((a: number, b: ProjectTimeEntryModel) => a + b.minutes, 0);
  return minutesToHHMM(sumTotal);
};

export const dataFromTimesheet = (
  timesheetValues: ProjectTimeEntriesModel,
): { projectId: string; value: number }[] => {
  const onlyEntries: ProjectTimeEntriesModel[] = timesheetValues.map((entry) =>
    Object.values(entry).flat(),
  );
  const result: { projectId: string; value: number }[] = [];
  onlyEntries.map((entry: ProjectTimeEntriesModel) =>
    entry.reduce((res, value: ProjectTimeEntryModel) => {
      if (!res[value.typeId]) {
        res[value.typeId] = { projectId: value.typeId, value: 0 };
        result.push(res[value.typeId]);
      }
      res[value.typeId].value += value.minutes;
      return res;
    }, {}),
  );
  return result;
};
