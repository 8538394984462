import { useEffect, ReactElement } from "react";
import { useDispatch } from "redux-react-hook";
import { useFieldArray, useFormContext } from "react-hook-form";
import ProjectCard from "./projectCard";
import ProjectForm from "./projectForm";
import SectionWrapper from "../../common";
import { useMappedStateSelector } from "../../../../../hooks";
import { PROJECTS } from "./constants";
import { toggleEditMode } from "../../../../../store";
import { ProjectsMapState, ProjectsMapStateReturn } from "./types";

const Projects = (): ReactElement => {
  const dispatch = useDispatch();
  const { control, reset } = useFormContext();

  const projectsArray = useFieldArray({
    control,
    name: PROJECTS,
  });

  const mapState = (state: ProjectsMapState): ProjectsMapStateReturn => ({
    projects: state?.profiles?.myProfile?.experience?.projects || [],
  });

  const { projects } = useMappedStateSelector(mapState);
  const handleOnEdit = (section: string) => dispatch(toggleEditMode(section));

  useEffect(() => {
    reset({ projects });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    return () => {
      handleOnEdit("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid gap-4">
      <SectionWrapper
        name={PROJECTS}
        Edit={ProjectForm}
        View={ProjectCard}
        arrayData={projectsArray}
      />
    </div>
  );
};

export default Projects;
