import { ReactElement, useState, useEffect } from "react";
import { LoadingMainPage } from "@ps/ui-components";
import { useParams } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { useMappedStateSelector } from "../../../../../hooks";
import ProposalColumn from "./proposalColumn";
import { fetchFolder } from "../../../../../folder-domain";
import CandidateMLService, {
  CandidateMLModel,
  CandidatesMLResponseModel,
} from "../../../../../candidate-ml-domain";
import {
  ProfileService,
  ProfilesSummaryListModel,
} from "../../../../../profile-domain";
import { UserProps, FolderMapState, FolderMapStateReturn } from "./types";
import { SPECIALIST } from "../../../../constants";
import LoadingCandidates from "./loadingCandidates";
import Specialists from "./specialists";
import Candidates from "./candidates";
import TeamGenerator from "./teamGenerator/teamGenerator";
import { generateNeeds } from "../../../../services";
import { NeedModel } from "../../../../models";
import { GenerateTeamNeed, TeamResult } from "../../../../api/openapi-client";
import {
  getEmployeesAndScores,
  mergeTeamsWithSpecialists,
} from "./teamGenerator/utils";
import { TeamsWithDetails } from "./teamGenerator/types";

const Folder = (): ReactElement => {
  const dispatch = useDispatch();
  const [isProposalView, setProposalView] = useState(false);
  const [isGeneratorView, setGeneratorView] = useState(false);
  const [users, setUsers] = useState<UserProps[] | CandidateMLModel[] | []>([]);
  const [teamsToDisplay, setTeamsToDisplay] = useState<TeamsWithDetails[]>([]);

  const [isLoadingML, setIsLoadingML] = useState(false);
  const [isLoadingGenerator, setIsLoadingGenerator] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [openMl, setOpenMl] = useState(false);

  const mapState = (state: FolderMapState): FolderMapStateReturn => ({
    prospect: state?.prospects?.prospect || {},
    folder: state?.folders?.folder || {},
    isFetchingFolder: state.requestStatus.isFetchingFolder,
    professionDict: state.dictionaries.profession,
    seniorityDict: state.dictionaries.seniority,
  });

  const { prospect, folder, isFetchingFolder, professionDict, seniorityDict } =
    useMappedStateSelector(mapState);

  useEffect(() => {
    fetchFolder(prospect.folder, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProposalView, isGeneratorView]);

  const handleOnManuallyClick = async () => {
    await ProfileService.fetchProfilesList().then((data) => {
      const onlySpecialists = data.filter(
        (user: ProfilesSummaryListModel) => user.profile === SPECIALIST,
      );
      setUsers(onlySpecialists);
      setProposalView(true);
    });
  };

  const handleOnMLClick = async (needId: string): Promise<void> => {
    setIsLoadingML(true);
    await CandidateMLService.fetchCandidatesMLByNeedID(id, needId).then(
      (data: CandidatesMLResponseModel) => {
        setIsLoadingML(false);

        setUsers(
          data?.candidates.sort(
            (a: CandidateMLModel, b: CandidateMLModel): number =>
              b.score - a.score,
          ),
        );
        setProposalView(true);
      },
    );
  };

  const handleOnTeamGenerate = async (): Promise<void> => {
    setIsLoadingGenerator(true);
    const needsToGenerate: GenerateTeamNeed[] = prospect.needs.map(
      (n: NeedModel) => ({ needId: n.itemId, slots: n.quantity }),
    );

    const teamResult = await generateNeeds(id, needsToGenerate);
    const employeesAndScores = getEmployeesAndScores(
      teamResult as TeamResult[],
    );

    if (employeesAndScores) {
      const specialists = await ProfileService.fetchProfilesList().then(
        (data) =>
          mergeTeamsWithSpecialists(
            employeesAndScores,
            data?.filter(
              (user: ProfilesSummaryListModel) => user.profile === SPECIALIST,
            ),
          ),
      );

      setTeamsToDisplay(specialists);
    }
    setGeneratorView(true);
    setIsLoadingGenerator(false);
  };

  return (
    <div className="h-full bg-neutralPrimary-100 -mx-10 p-8 relative">
      <div className="flex gap-x-6">
        {!isProposalView && !isGeneratorView ? (
          <>
            {isLoadingML || isLoadingGenerator ? (
              <LoadingCandidates />
            ) : (
              <>
                {isFetchingFolder ? (
                  <LoadingMainPage additionalClassName="bg-neutralPrimary-100 w-full h-full" />
                ) : (
                  <>
                    <Candidates
                      folder={folder}
                      needs={prospect.needs}
                      professionDict={professionDict}
                      seniorityDict={seniorityDict}
                      onOpenMLClick={() => setOpenMl(true)}
                      onManuallyClick={handleOnManuallyClick}
                      prospectArchived={prospect.archived}
                    />
                    <ProposalColumn
                      prospect={prospect}
                      onMLClick={handleOnMLClick}
                      onTeamGenerateClick={handleOnTeamGenerate}
                      onManuallyClick={handleOnManuallyClick}
                      seniorityDict={seniorityDict}
                      professionDict={professionDict}
                      openML={openMl}
                      handleOpenML={() => setOpenMl(true)}
                      handleCloseML={() => setOpenMl(false)}
                    />
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {isProposalView && (
              <Specialists
                onCancel={() => setProposalView(false)}
                folderId={prospect.folder}
                users={users as UserProps[]}
              />
            )}
            {isGeneratorView && (
              <TeamGenerator
                seniorityDict={seniorityDict}
                professionDict={professionDict}
                prospectNeeds={prospect.needs}
                teamsToDisplay={teamsToDisplay}
                setTeamsToDisplay={setTeamsToDisplay}
                onClose={() => setGeneratorView(false)}
                folder={folder}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Folder;
