import { ReactElement } from "react";
import { TimeTrackerLabel } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import { PROJECT_LABEL, TOTAL_LABEL } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import { HeaderProps } from "./types";
import styles from "../styles.module.css";

import Dates from "./dates";

const BASE_PATH = "timesheet.timelog";

const Header = ({ date, dayAmount }: HeaderProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className={classJoin(styles.headerContainer, "border-neutralPrimary-30")}>
      <TimeTrackerLabel
        text={t(`${BASE_PATH}.sections.event`)}
        dataCy={PROJECT_LABEL}
        additionalClassName="justify-self-start ml-5"
      />
      <Dates date={date} dayAmount={dayAmount} />
      <TimeTrackerLabel
        text={t(`${BASE_PATH}.sections.total`)}
        dataCy={TOTAL_LABEL}
      />
    </div>
  );
};

export default Header;
