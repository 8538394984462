import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { classJoin } from "@ps/utils";
import { Page404Props } from "./types";
import { ReactComponent as IconsClient404 } from "../../images/page-404-client-icon.svg";
import { ReactComponent as IconsUser404 } from "../../images/page-404-user-icon.svg";
import { ReactComponent as IconsAdmin404 } from "../../images/page-404-admin-icon.svg";
import { PAGE_404_PREFIX } from "../../shared/data-cy";
import { useTranslationWithNamespace } from "../../hooks";
import Button from "../button";
import PageBackground from "../page-background";
import styles from "./styles.module.css";

const Page404 = ({
  dataCy,
  variant = "client",
  path,
}: Page404Props): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const history = useHistory();

  const renderClientVariant = (): ReactElement => {
    const iconStyles = classJoin("absolute top-16 left-1/4", styles.zindex);
    return (
      <div>
        <IconsClient404 className={iconStyles} />
        <div className="absolute flex flex-col justify-center whitespace-nowrap items-center bottom-1/4 right-96 mr-16 mb-4">
          <span
            className={classJoin(
              "text-5xl text-white font-semibold",
              styles.zindex,
            )}
          >
            {t("pages.notFound")}
          </span>
          <Button
            dataCy={dataCy}
            paddingX="px-16"
            additionalClass={classJoin(
              "py-8 text-lg mt-8 border border-white",
              styles.zindex,
            )}
          >
            {t("pages.home")}
          </Button>
        </div>
      </div>
    );
  };

  const renderUserOrAdminIcon = (): ReactElement =>
    variant === "user" ? (
      <IconsUser404
        className={classJoin("absolute top-32 right-48", styles.zindex)}
      />
    ) : (
      <IconsAdmin404
        className={classJoin("absolute top-1 right-16", styles.zindex)}
      />
    );

  return (
    <div
      data-cy={`${PAGE_404_PREFIX}-${dataCy}`}
      className="h-screen w-screen absolute inset-0 overflow-hidden"
    >
      <PageBackground />
      {variant === "client" ? (
        renderClientVariant()
      ) : (
        <div>
          {renderUserOrAdminIcon()}
          <div className="absolute flex flex-col top-1/3 left-96 gap-y-5 w-96">
            <span
              className={classJoin(
                "text-9xl text-white font-black",
                styles.zindex,
              )}
            >
              404
            </span>
            <span
              className={classJoin(
                "text-5xl text-white font-semibold",
                styles.zindex,
              )}
            >
              {t("pages.notFound")}
            </span>
            <Button
              dataCy={dataCy}
              paddingX="px-16"
              additionalClass={classJoin("py-8 text-lg mt-10", styles.zindex)}
              onClick={() => history.push(path)}
            >
              {t("pages.home")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Page404;
