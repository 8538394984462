import React, { ReactElement } from "react";
import { Tooltip } from "@ps/ui-components";
import { IconWithTooltipProps } from "./types";
import { ReactComponent as BlockIcon } from "../../../../images/timesheet/timelog/block.svg";
import { ReactComponent as DeletedFromProjectIcon } from "../../../../images/timesheet/timelog/deleted-from-project.svg";
import { ReactComponent as ArchivedIcon } from "../../../../images/timesheet/timelog/archived.svg";
import { useTranslationWithNamespace } from "../../../../hooks";

const ICON_STYLES =
  "fill-current h-6 w-auto text-neutralPrimary-20 opacity-30 block";

const IconWithTooltip = ({
  SOWContent,
  type,
}: IconWithTooltipProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  if (!type) return <></>;

  const isArchived = type === "archived";
  const isDeleted = type === "deleted";

  const prepareProperMessage = (): string => {
    if (isArchived) return t("timesheet.timelog.archivedProject");
    if (isDeleted) return t("timesheet.timelog.deletedFromProject");
    return SOWContent;
  };

  const renderIcon = (): ReactElement => {
    if (isArchived) return <ArchivedIcon className={ICON_STYLES} />;
    if (isDeleted) return <DeletedFromProjectIcon className={ICON_STYLES} />;
    return <BlockIcon className={ICON_STYLES} />;
  };

  return (
    <Tooltip
      verticalOffset={8}
      placement="top"
      content={<span>{prepareProperMessage()}</span>}
    >
      {renderIcon()}
    </Tooltip>
  );
};

export default IconWithTooltip;
