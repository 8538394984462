import { ReactElement } from "react";
import { SearchSelect } from "@ps/ui-components";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import countries from "i18n-iso-countries";
import { CURRENT_LANGUAGE_CODE } from "../../../../../i18n/constants";
import {
  AVAILABILITY,
  NATIONALITY,
  PASSPORTS,
  RESIDENCE,
  VISAS,
} from "../../constants";
import { BASE_PATH } from "./constants";
import LabelWrapper from "./labelWrapper";
import DocumentsList from "./documentsList";
import { useTranslationWithNamespace } from "../../../../../hooks";

const TRANSLATION_PATH = `profile.overview.${AVAILABILITY}`;
const columnStyles = "flex flex-col gap-6 h-fit";

const Availability = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { control, watch } = useFormContext();

  const passportsArray = useFieldArray({
    control,
    name: PASSPORTS,
  });

  const visasArray = useFieldArray({
    control,
    name: VISAS,
  });

  const allCountries = countries.getNames(CURRENT_LANGUAGE_CODE, {
    select: "official",
  });

  return (
    <div className="grid grid-cols-2 gap-4 w-full">
      <div className={columnStyles}>
        <LabelWrapper className="w-full" field={RESIDENCE} isRequired>
          <Controller
            render={({ field: { onChange }, fieldState: { error } }) => (
              <SearchSelect
                dataCy={`${BASE_PATH}_${RESIDENCE}`}
                value={watch(RESIDENCE)}
                onChange={onChange}
                height="auto"
                width="w-full"
                clearable
                autoFocus
                error={error}
                message={error?.message || ""}
                options={Object.entries(allCountries)
                  .map(([key, name]) => ({
                    label: name,
                    value: key,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                placeholder={t(`${TRANSLATION_PATH}.residencePlaceholder`)}
              />
            )}
            name={RESIDENCE}
            control={control}
            rules={{
              validate: (value) =>
                !value ? `${t("profile.overview.errors.cantBeEmpty")}` : true,
            }}
          />
        </LabelWrapper>
        <DocumentsList data={passportsArray} documentType={PASSPORTS} />
      </div>
      <div className={columnStyles}>
        <LabelWrapper className="w-full h-auto" field={NATIONALITY}>
          <Controller
            render={({ field: { onChange } }) => (
              <SearchSelect
                dataCy={`${BASE_PATH}_${NATIONALITY}`}
                value={watch(NATIONALITY)}
                multiple
                onChange={onChange}
                height="auto"
                width="w-full"
                options={Object.entries(allCountries)
                  .map(([key, name]) => ({
                    label: name,
                    value: key,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                placeholder={t(`${TRANSLATION_PATH}.nationalityPlaceholder`)}
              />
            )}
            name={NATIONALITY}
            control={control}
          />
        </LabelWrapper>
        <DocumentsList data={visasArray} documentType={VISAS} />
      </div>
    </div>
  );
};

export default Availability;
