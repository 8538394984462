import { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Label,
  TableHeader,
  TableLabel,
  Input,
  Button,
} from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import { useTranslationWithNamespace } from "../../../../hooks";
import { HolidaysItemModel } from "../../../models";
import {
  BASE_TRANSLATION_PATH,
  HOLIDAYS_USER,
  HOLIDAY_TYPE,
  TEMPLATE,
  MODIFICATION,
  USER_TOTAL,
  USED,
  UNUSED,
  HOLIDAYS,
  NO_LIMIT_THRESHOLD,
} from "../constants";
import styles from "./styles.module.css";
import { ModificationTableProps } from "./types";

const ModificationTable = ({
  holidaysArray,
  onSaveClick,
}: ModificationTableProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { control, reset, formState } = useFormContext();

  const noLimit = t(`${BASE_TRANSLATION_PATH}.noLimit`);

  return (
    <div>
      <TableHeader dataCy={HOLIDAYS_USER} columnsStyles={styles.tableColumns}>
        <TableLabel dataCy={`${HOLIDAYS_USER}_${HOLIDAY_TYPE}`}>
          {t(`${BASE_TRANSLATION_PATH}.${HOLIDAY_TYPE}`)}
        </TableLabel>
        <TableLabel dataCy={`${HOLIDAYS_USER}_${TEMPLATE}`}>
          {t(`${BASE_TRANSLATION_PATH}.${TEMPLATE}`)}
        </TableLabel>
        <TableLabel dataCy={`${HOLIDAYS_USER}_${MODIFICATION}`}>
          {t(`${BASE_TRANSLATION_PATH}.${MODIFICATION}`)}
        </TableLabel>
        <TableLabel dataCy={`${HOLIDAYS_USER}_${USER_TOTAL}`}>
          {t(`${BASE_TRANSLATION_PATH}.${USER_TOTAL}`)}
        </TableLabel>
        <TableLabel dataCy={`${HOLIDAYS_USER}_${USED}`}>
          {t(`${BASE_TRANSLATION_PATH}.${USED}`)}
        </TableLabel>
        <TableLabel dataCy={`${HOLIDAYS_USER}_${UNUSED}`}>
          {t(`${BASE_TRANSLATION_PATH}.${UNUSED}`)}
        </TableLabel>
      </TableHeader>
      {holidaysArray?.map(
        (holiday: HolidaysItemModel, index: number): ReactElement => {
          const userTotal = holiday.total + holiday.modification;
          const unused = (userTotal - holiday.used).toString();
          const isNoLimit = holiday.total >= NO_LIMIT_THRESHOLD;
          const opacity = isNoLimit ? "opacity-40" : "";

          return (
            <div
              className={classJoin(
                "grid border-b border-neutralSecondary-60 px-8 py-6 last:border-0",
                styles.tableColumns,
              )}
              key={holiday.id}
            >
              <Label
                dataCy={`${HOLIDAYS_USER}_${HOLIDAY_TYPE}`}
                additionalClassName={opacity}
              >
                {holiday.name}
              </Label>
              <Label
                dataCy={`${HOLIDAYS_USER}_${TEMPLATE}`}
                additionalClassName={opacity}
              >
                {isNoLimit ? noLimit : holiday.total.toString()}
              </Label>
              <Controller
                control={control}
                name={`${HOLIDAYS}.${index}.${MODIFICATION}`}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <Input
                    dataCy={`${HOLIDAYS_USER}_${MODIFICATION}`}
                    type="number"
                    disabled={isNoLimit}
                    onChange={onChange}
                    value={value}
                    additionalContainerClass="w-1/2"
                    min={0}
                    error={!!error}
                    message={error?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: `${t(
                      `${BASE_TRANSLATION_PATH}.errors.cantBeEmpty`,
                    )}`,
                  },
                }}
              />
              <Label
                dataCy={`${HOLIDAYS_USER}_${USER_TOTAL}`}
                additionalClassName={opacity}
              >
                {isNoLimit ? noLimit : userTotal.toString()}
              </Label>
              <Label
                dataCy={`${HOLIDAYS_USER}_${USED}`}
                additionalClassName={opacity}
              >
                {holiday.used.toString()}
              </Label>
              <Label
                dataCy={`${HOLIDAYS_USER}_${UNUSED}`}
                additionalClassName={opacity}
              >
                {isNoLimit ? noLimit : unused}
              </Label>
            </div>
          );
        },
      )}
      <div className="flex items-center gap-4 justify-end py-8">
        <Button
          dataCy={`${HOLIDAYS_USER}_${USER_TOTAL}`}
          onClick={onSaveClick}
          disabled={!formState.isDirty}
        >
          {t(`${BASE_TRANSLATION_PATH}.save`)}
        </Button>
        <Button
          dataCy={`${HOLIDAYS_USER}_${USER_TOTAL}`}
          onClick={reset}
          disabled={!formState.isDirty}
          variant="secondary"
        >
          {t(`${BASE_TRANSLATION_PATH}.cancel`)}
        </Button>
      </div>
    </div>
  );
};

export default ModificationTable;
