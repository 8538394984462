import { ReactElement } from "react";
import dayjs, { Dayjs } from "dayjs";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { TimeRangeProps } from "../types";

const styles = StyleSheet.create({
  timeRange: {
    fontSize: "10px",
    fontWeight: "thin",
  },
  container: {
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
  },
});

const TimeRange = ({ times, isBlind }: TimeRangeProps): ReactElement => (
  <View style={styles.container}>
    {times.map(
      (time: Dayjs | undefined, index: number): JSX.Element => (
        <Text
          style={{
            ...styles.timeRange,
            color: isBlind ? "#DE3933" : "#2A2D33",
          }}
          key={index}
        >{`${time ? dayjs(time).format("MM.YYYY") : "present"} ${
          index ? " " : "- "
        }`}</Text>
      ),
    )}
  </View>
);
export default TimeRange;
