import { ReactElement, MouseEvent, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { ActiveSelect, Button, SearchInput, Sorter } from "@ps/ui-components";
import { IS_PRODUCTION } from "../../shared/constants";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../hooks";
import {
  ALL_USERS,
  AVAILABLE,
  BUSY,
  BENCH,
  SEARCH,
  ASC,
  DESC,
  AVAILABILITY,
  NAME,
  PROFESSION,
  DAYS,
  REASON,
  SENIORITY,
} from "../constants";
import { resetBenchUsersByPropertyFilters } from "../store";
import {
  SearchRowProps,
  SearchRowMapState,
  SearchRowMapStateReturn,
} from "./types";

const SearchRow = ({
  searchValue,
  onSearchValueChange,
  availableAmount,
  busyAmount,
  allAmount,
  benchCheckers,
  setBenchCheckers,
  handleSorterClick,
  activeSorter,
}: SearchRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();

  const mapState = (state: SearchRowMapState): SearchRowMapStateReturn => ({
    filters: state.bench?.filters || [],
  });

  const { filters } = useMappedStateSelector(mapState);

  useEffect(() => {
    resetBenchUsersByPropertyFilters(dispatch);
  }, []);

  const names = [
    {
      name: AVAILABILITY,
      label: t(`${BENCH}.${AVAILABILITY}`),
    },
    {
      name: NAME,
      label: t(`${BENCH}.${NAME}`),
    },
    {
      name: PROFESSION,
      label: t(`${BENCH}.${PROFESSION}`),
    },
    {
      name: SENIORITY,
      label: t(`${BENCH}.${SENIORITY}`),
    },
    {
      name: REASON,
      label: t(`${BENCH}.${REASON}`),
    },
    {
      name: DAYS,
      label: t(`${BENCH}.${DAYS}`),
    },
  ];

  const options = [
    {
      id: AVAILABLE,
      label: t(`${BENCH}.${AVAILABLE}`),
      amount: availableAmount,
      checked: benchCheckers.available,
    },
    {
      id: BUSY,
      label: t(`${BENCH}.${BUSY}`),
      amount: busyAmount,
      checked: benchCheckers.busy,
    },
  ];

  const orders = [
    { order: ASC, label: t(`${BENCH}.${ASC}`) },
    { order: DESC, label: t(`${BENCH}.${DESC}`) },
  ];

  const handleOptionClick = (e: MouseEvent<HTMLDivElement>): void => {
    const { id } = e.currentTarget;
    setBenchCheckers((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const namesToggledDays = IS_PRODUCTION
    ? names.filter((n: { name: string }) => n.name !== DAYS)
    : names;

  return (
    <div className="flex justify-end items-center gap-x-4">
      {!filters.location.length &&
      !filters.name.length &&
      !filters.profession.length &&
      !filters.reason.length &&
      !filters.roles.length &&
      !filters.seniority.length ? null : (
        <Button
          dataCy={`${BENCH}_clearFilters`}
          onClick={() => resetBenchUsersByPropertyFilters(dispatch)}
          variant="secondary"
        >
          {t(`${BENCH}.clearFilters`)}
        </Button>
      )}
      <SearchInput
        dataCy={`${BENCH}_${SEARCH}`}
        placeholder={t(`${BENCH}.search`)}
        value={searchValue}
        onChange={onSearchValueChange}
      />
      <Sorter
        activeSorter={activeSorter}
        orders={orders}
        names={namesToggledDays}
        handleSorterClick={handleSorterClick}
      />
      <ActiveSelect
        dataCy={`${BENCH}_activeUsers`}
        label={t(`${BENCH}.${ALL_USERS}`)}
        activeAmount={allAmount}
        onOptionClick={handleOptionClick}
        options={options}
      />
    </div>
  );
};

export default SearchRow;
