/* eslint @typescript-eslint/no-explicit-any: 0 */
import { IClientOption, IProjectOption, IUserOption } from "../types";
import { IAnyOption } from "./types";

export const updateSelectedAllFiltersList = (
  isSelectAll: boolean,
  fieldName: string,
  fields: IAnyOption[] | Record<"id", string>[],
  reset: <T>(values?: T, options?: Record<string, boolean>) => void,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  prevValues: any,
  baseList:
    | IUserOption[]
    | IProjectOption[]
    | IClientOption[]
    | Record<"id", string>[],
): void => {
  if (isSelectAll) {
    // eslint-disable-next-line
    // @ts-ignore
    const result = fields.filter((item: IAnyOption) => item.type !== fieldName);
    reset({ ...prevValues, filtersSelectedList: result });
  } else {
    let withoutUsersData = [];
    if (fields?.length) {
      // eslint-disable-next-line
      // @ts-ignore
      withoutUsersData = fields?.filter(
        (item: IUserOption | IProjectOption | IClientOption) =>
          item.type !== fieldName,
      );
    }
    const result = [...withoutUsersData, ...baseList];
    reset({ ...prevValues, filtersSelectedList: result });
  }
};

const omitUncheckedItem = (
  fields: IAnyOption[] | Record<"id", string>[],
  id: string,
  // eslint-disable-next-line
  // @ts-ignore
): IAnyOption[] => fields?.filter((single: IAnyOption) => single.itemId !== id);

export const updatePillsList = (
  newValue: boolean,
  item: IAnyOption,
  reset: <T>(values?: T, options?: Record<string, boolean>) => void,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  prevValues: any,
  fields: IAnyOption[] | Record<"id", string>[],
  append: (item: IAnyOption) => void,
): void => {
  if (newValue) {
    reset({
      ...prevValues,
      filtersSelectedList: omitUncheckedItem(fields, item.itemId),
    });
  } else {
    append(item);
  }
};
