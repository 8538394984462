import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import {
  Button,
  ConditionalTooltip,
  Input,
  ScaleTile,
} from "@ps/ui-components";
import { Controller, useFormContext } from "react-hook-form";
import { ReactComponent as InfoIcon } from "../../../../../../../images/skills-info.svg";
import { ReactComponent as DragAndDropIcon } from "../../../../../../../images/drag-n-drop.svg";
import { useTranslationWithNamespace } from "../../../../../../../hooks";
import {
  SKILLS_MATRIX_EXPERTISE_DIFFICULTY,
  SKILLS_MATRIX_EXPERTISE_TEMPLATE,
} from "../../../../../../../shared/data-cy";
import { SkillAPIModel } from "../../../../../../models";
import ConfirmRemoveRow from "./confirmRemoveRow";
import { EditPanelButtons } from "../buttons";
import { TechnologiesTableRowProps } from "../types";
import { DOMAINS_PROFESSION_ID } from "../../../../../../shared/consts";
import styles from "./styles.module.css";

const TechnologiesTableRow = ({
  displayTechnologyName,
  groupPathName,
  index,
  isEdit,
  onCancelClick,
  onEditClick,
  onRemove,
  professionId,
  professionSkills,
  provided,
  technology: { technologyId, desc, isNew },
}: TechnologiesTableRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
  const [isRemoveRowVisible, setIsRemoveRowVisible] = useState<boolean>(false);
  const [isEllipsis, setIsEllipsis] = useState(false);
  const labelRef = useRef<HTMLSpanElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const checkEllipsis = () => {
      if (labelRef?.current && containerRef?.current) {
        setIsEllipsis(
          labelRef.current.scrollWidth >= labelRef.current.offsetWidth + 3,
        );
      }
    };

    checkEllipsis();

    const handleResize = () => {
      checkEllipsis();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [labelRef, containerRef]);

  const methods = useFormContext();
  const closeDeleteMode = (): void => setIsDeleteMode(false);

  const inputStateValue: string = methods.getValues(
    `${groupPathName}.technologyId`,
  );

  const handleDelete = (): void => {
    if (isDeleteMode) {
      closeDeleteMode();
    }
  };

  const handleValidateTechnologyName = useCallback(
    (value: string) => {
      const notValid = professionSkills
        ?.filter((_, valueIndex) => valueIndex !== index)
        .some(
          (item: SkillAPIModel) =>
            item.technologyId?.replaceAll(" ", "")?.toLowerCase() ===
            value.replaceAll(" ", "")?.toLowerCase(),
        );
      if (notValid) {
        return t("expertise.errors.technologyNameExists");
      }
      if (!value.length || /(new_[0-9]+_[0-9]+.[0-9]+)/.test(value)) {
        return t("expertise.errors.cantBeEmpty");
      }
      return true;
    },
    [professionSkills],
  );

  return (
    <div
      className="flex flex-col"
      key={technologyId}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
    >
      <div
        className={classJoin(
          styles.tableRowContainer,
          "relative w-full bg-neutralPrimary-100 text-neutralPrimary-20",
          index !== professionSkills.length - 1 &&
            "border-b border-neutralSecondary-60",
        )}
      >
        {isDeleteMode && (
          <div
            className={classJoin(
              "absolute top-0 left-0 bg-primary-50",
              "bg-opacity-90 w-full h-full text-primary-100 z-10 flex flex-row",
              "items-center px-5 gap-x-5",
            )}
          >
            <span className="text-xl font-bold flex flex-row gap-x-3 items-center">
              <span className="bg-primary-100 rounded-full p-1.5 flex-shrink-0">
                <InfoIcon
                  data-cy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_info_icon`}
                  className="text-primary-50 fill-current"
                  height="14"
                  width="14"
                />
              </span>
              {t("profile.skills.form.deleteQuestion")}
            </span>
            <span className="flex flex-row gap-x-3">
              <Button
                dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_delete`}
                variant="onAction"
                height="h-8.5"
                onClick={handleDelete}
              >
                {t("profile.skills.buttons.delete")}
              </Button>
              <Button
                dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_cancel`}
                variant="onActionSecondary"
                onClick={closeDeleteMode}
                height="h-8.5"
              >
                {t("profile.skills.buttons.cancel")}
              </Button>
            </span>
          </div>
        )}
        <div
          ref={containerRef}
          className="truncate items-center px-4 py-2 flex flex-row gap-2"
        >
          {isEdit ? (
            <Controller
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Input
                  autoFocus
                  dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_cells_name`}
                  defaultValue={
                    inputStateValue === technologyId
                      ? displayTechnologyName(value, "")
                      : inputStateValue
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                  }}
                  placeholder={`+ ${t(
                    "expertise.matrix.editMatrix.addNewTechnology",
                  )}`}
                  error={!!error}
                  message={error?.message || ""}
                />
              )}
              name={`${groupPathName}.technologyId`}
              rules={{
                required: {
                  value: true,
                  message: "",
                },
                validate: (value) => {
                  return handleValidateTechnologyName(value);
                },
              }}
            />
          ) : (
            <ConditionalTooltip
              content={displayTechnologyName(technologyId, desc)}
              enabled={isEllipsis}
              additionalChildrenClassName="w-full"
            >
              <span ref={labelRef} className="select-none truncate block">
                {displayTechnologyName(technologyId, desc)}
              </span>
            </ConditionalTooltip>
          )}
        </div>
        <div
          className={classJoin(
            "gap-1 px-4 py-2 flex flex-row border-l border-neutralSecondary-60 items-center",
          )}
        >
          {isEdit ? (
            <Controller
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Input
                  dataCy={`${SKILLS_MATRIX_EXPERTISE_DIFFICULTY}_${index}`}
                  width="w-40"
                  min="1"
                  max="8"
                  type="number"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const newValue = parseInt(event.target.value, 10);
                    return newValue >= 1 && newValue <= 8
                      ? onChange(newValue)
                      : onChange(null);
                  }}
                  defaultValue={value}
                  placeholder={`+ ${t(
                    "expertise.matrix.editMatrix.difficulty",
                  )} (1-8)`}
                  error={!!error}
                  message={error?.message || ""}
                />
              )}
              name={`${groupPathName}.difficulty`}
              rules={{
                validate: (value) =>
                  value && !/[1-8]/.test(value)
                    ? `${t("expertise.errors.wrongDifficultyLevel")}`
                    : true,
              }}
            />
          ) : (
            [...Array(8)].map((_, tileIndex) => (
              <ScaleTile
                dataCy={`${SKILLS_MATRIX_EXPERTISE_DIFFICULTY}_${index}`}
                color={
                  parseInt(
                    methods.getValues(`${groupPathName}.difficulty`),
                    10,
                  ) ===
                  tileIndex + 1
                    ? "rgb(var(--color-primary-70))"
                    : undefined
                }
                key={uid(tileIndex)}
                disabled
              >
                <span className="text-neutralSecondary-60">
                  {tileIndex + 1}
                </span>
              </ScaleTile>
            ))
          )}
        </div>
        <div className="py-2 pr-3 flex flex-row justify-end items-center">
          <EditPanelButtons
            dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_matrixSkillsCell`}
            isEditMode={isEdit}
            variant="secondary"
            onCancelClick={onCancelClick}
            onEditClick={onEditClick}
            onRemoveClick={() => setIsRemoveRowVisible(true)}
            cantCancel={isNew}
          />
          {professionId !== DOMAINS_PROFESSION_ID && (
            <div {...provided?.dragHandleProps}>
              {isEdit ? (
                <DragAndDropIcon
                  className={classJoin.template`
                ml-7 fill-current w-5 h-5 opacity-10
                cursor-default pointer-event-none
                focus:outline-none`}
                />
              ) : (
                <DragAndDropIcon className="ml-7 fill-current w-5 h-5 text-neutralSecondary-41 focus:outline-none" />
              )}
            </div>
          )}
        </div>
      </div>
      {isRemoveRowVisible && (
        <ConfirmRemoveRow
          onCancelClick={() => setIsRemoveRowVisible(false)}
          onRemove={onRemove}
        />
      )}
    </div>
  );
};

export default TechnologiesTableRow;
