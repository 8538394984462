import { ReactElement } from "react";
import { languageCodeToCountryCode } from "@ps/utils";
import flag from "country-code-emoji";
import ISOLanguages from "iso-639-1";
import { uid } from "react-uid";
import { ReactComponent as FlagIcon } from "../../../images/profile-summary/flag.svg";
import { LanguagesProps } from "../types";
import { PROFILE_SUMMARY_LANGUAGES } from "../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../hooks";
import { CardHeader } from "../../expanded-card";
import Rating from "../../rating";

const BASE_PATH = "profileSummary";

const Languages = ({
  languages,
  languageLevelsDict,
}: LanguagesProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const getSelectedNumber = (item: string): number =>
    languageLevelsDict.reduce(
      (result, level, index) => (level === item ? index + 1 : result),
      1,
    );

  return (
    <div className="p-6 flex flex-col sm:flex-row sm:items-center gap-8 sm:gap-16">
      <CardHeader Icon={FlagIcon} dataCy={PROFILE_SUMMARY_LANGUAGES}>
        <span>{t(`${BASE_PATH}.languages`)}</span>
      </CardHeader>
      <div className="grid grid-flow-row 2xl:grid-cols-5 gap-8 pl-11 sm:p-0 md:grid-cols-3 grid-cols-1 sm:gap-16">
        {languages?.map((item) => (
          <div className="flex gap-1 flex-col" key={uid(item)}>
            <div className="flex gap-1 items-center">
              {`${flag(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                languageCodeToCountryCode(item.language),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
              )} ${ISOLanguages.getName(item.language)}`}
              <Rating
                dataCy={`${PROFILE_SUMMARY_LANGUAGES}_${item.language}`}
                totalAmount={5}
                selected={getSelectedNumber(item.level)}
                readOnly
              />
            </div>
            <span className="text-neutralSecondary-41 pl-6">
              {t(`${BASE_PATH}.languagesSection.${item.level}`)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Languages;
