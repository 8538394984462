import { ReactElement } from "react";
import {
  Card,
  SummaryBaseInfo,
  SummaryLanguages,
  SummaryOtherExperience,
  SummaryProjects,
  SummarySkills,
  SummaryTimeline,
} from "@ps/ui-components";
import { TEAM_LIST_PROFILE_SUMMARY } from "../../../shared/data-cy";
import { useMappedStateSelector } from "../../../hooks";
import { languageLevelsDict } from "../overview/constants";
import ExperienceSection from "../summary/experienceSection";
import {
  OtherProfileSummaryReturn,
  OtherProfileSummaryState,
  OtherProfileSummaryProps,
} from "./types";

const OtherProfileSummary = ({
  avatar,
  currentProfile,
}: OtherProfileSummaryProps): ReactElement => {
  const mapState = (
    state: OtherProfileSummaryState,
  ): OtherProfileSummaryReturn => ({
    professionDict: state.dictionaries.profession,
    seniorityDict: state.dictionaries.seniority,
    technologyDict: state.dictionaries.technology,
    roleDict: state.dictionaries.project_role,
    domainsDict: state.dictionaries.domain,
  });

  const {
    seniorityDict,
    professionDict,
    technologyDict,
    roleDict,
    domainsDict,
  } = useMappedStateSelector(mapState);

  return (
    <section
      data-cy={TEAM_LIST_PROFILE_SUMMARY}
      className="flex flex-col gap-8 px-4 pb-16 bg-transparent"
    >
      <Card
        dataCy={`${TEAM_LIST_PROFILE_SUMMARY}_header`}
        additionalCardClassName="flex w-full flex-col"
        additionalClassName="relative"
      >
        <div className="flex flex-col md:flex-row">
          <SummaryBaseInfo
            avatar={avatar}
            bio={currentProfile?.bio}
            lastUpdate={currentProfile?.updatedAt}
            residence={currentProfile?.residence?.label}
            userName={
              currentProfile?.firstName
                ? `${currentProfile?.firstName} ${currentProfile?.lastName}`
                : ""
            }
            timeZone={currentProfile?.timeRanges?.zone}
          />
          {currentProfile?.timeRanges && (
            <SummaryTimeline {...currentProfile.timeRanges} />
          )}
        </div>
        <ExperienceSection
          {...currentProfile}
          date={roleDict}
          seniorityDict={seniorityDict}
          professionsDict={professionDict}
          roleDict={roleDict}
        />
      </Card>
      {currentProfile?.languages?.length ? (
        <Card
          additionalClassName="relative"
          dataCy={`${TEAM_LIST_PROFILE_SUMMARY}_languages`}
        >
          <SummaryLanguages
            languages={currentProfile?.languages}
            languageLevelsDict={languageLevelsDict}
          />
        </Card>
      ) : (
        <></>
      )}
      {currentProfile?.skills?.best?.length ||
      currentProfile?.skills?.byProfession?.length ? (
        <SummarySkills
          domains={currentProfile?.domains}
          domainsDict={domainsDict}
          mainProfession={currentProfile?.professions?.main}
          professionsDict={professionDict}
          skills={currentProfile?.skills}
          technologyDict={technologyDict}
        />
      ) : (
        <></>
      )}
      {currentProfile?.experience?.projects?.length ? (
        <SummaryProjects
          projectRolesDict={roleDict}
          projects={currentProfile?.experience?.projects}
          technologyDict={technologyDict}
          domainsDict={domainsDict}
        />
      ) : (
        <></>
      )}
      {currentProfile ? (
        <SummaryOtherExperience
          experience={Object.entries(currentProfile?.experience)
            ?.filter(([section]) => section !== "projects")
            .reduce(
              (acc, [key, value]) =>
                value?.length
                  ? {
                      ...acc,
                      [key]: value,
                    }
                  : acc,
              {},
            )}
          technologyDict={technologyDict}
        />
      ) : null}
    </section>
  );
};

export default OtherProfileSummary;
