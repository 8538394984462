export type PossibleArgs = string | boolean | undefined | number;

const isTruthy = <T>(val: T | undefined): val is T => (val ? true : false);
const toString = (val: unknown): string => `${val}`;

const classJoinCommon = (...args: PossibleArgs[]): string =>
  args.filter(isTruthy).map(toString).join(" ") as string;

type PropsFunc<T> = (props: T) => string;

const classJoinTemplate = (styles: TemplateStringsArray): string =>
  styles
    .map((entry) => entry.replace(/ /g, "\n").trim().split("\n"))
    .flat()
    ?.filter((entry) => entry !== "")
    .join(" ");

const classJoinWithProps =
  <T>(styles: TemplateStringsArray, ...funcs: PropsFunc<T>[]) =>
  (props: T) => {
    const passedStyles = classJoinTemplate(styles);

    const functions = funcs.map((func) => func(props)).join(" ");

    return [passedStyles, functions].filter(isTruthy).join(" ");
  };

export default Object.assign(classJoinCommon, {
  props: classJoinWithProps,
  template: classJoinTemplate,
});
