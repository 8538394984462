import { ReactElement, useState } from "react";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { motion } from "framer-motion";
import {
  Datepicker2,
  Input,
  Label,
  SearchSelectPicker,
} from "@ps/ui-components";
import { checkUrl, prepareTechStackDictionaryValues } from "@ps/utils";
import { uid } from "react-uid";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { FormSectionProps } from "../../education/types";
import { EXPERIENCE_ACCOMPLISHMENT_EDIT } from "../../../../../../shared/data-cy";
import {
  ARTICLES,
  DATE,
  PLACEHOLDER,
  TITLE,
  URL_PATH,
  URL_REGEXP_PATTERN,
} from "../constants";
import { TECH_STACK } from "../../projects/constants";
import { checkHasEmptyFields } from "../../../helpers";
import { ITechStackProposal } from "../../../types";
import styles from "../../styles.module.css";
import { FULL_TECH_SEARCH_MAX } from "../../../../../../shared/constants";

const MAX_LENGTH_NAME = 100;

const ArticlesForm = ({
  index,
  technologyDict,
  isOcr,
  sourcePath = "articles",
  fullTextSearch,
}: FormSectionProps): ReactElement => {
  const { control, getValues, watch, getFieldState, formState, register } =
    useFormContext();
  const { t } = useTranslationWithNamespace();
  const [searchedProposals, setSearchedProposals] = useState<
    ITechStackProposal[] | []
  >([]);

  const BASE_PATH = `${EXPERIENCE_ACCOMPLISHMENT_EDIT}_${ARTICLES}`;

  const translate = (field: string, isRequired = true): string =>
    `${t(`profile.experience.cards.articles.${field}`)}${
      isRequired ? "*" : ""
    }`;

  const getFormFieldName = (fieldName: string): string =>
    `${sourcePath}.${index}.${fieldName}`;

  const fieldTitle = getFieldState(getFormFieldName(TITLE), formState);
  const fieldUrlPath = getFieldState(getFormFieldName(URL_PATH), formState);

  const hasEmptyFields = checkHasEmptyFields(
    getValues([TITLE, DATE, TECH_STACK]),
  );

  const errorEmptyMessage = `${t("profile.errors.cantBeEmpty")}`;
  const errorBlankMessage = `${t("profile.errors.cantBeBlank")}`;
  const errorMaxLengthMessage = `${t("profile.errors.cantBeMoreChars", {
    number: MAX_LENGTH_NAME,
  })}`;

  return (
    <div className="flex flex-col gap-y-5" key={uid(`${ARTICLES}-${index}`)}>
      <div className="flex flex-row gap-x-5">
        <Label dataCy={`${BASE_PATH}_${TITLE}`} text={translate(TITLE)}>
          <Input
            autoFocus
            dataCy={`${BASE_PATH}_${TITLE}`}
            error={!!fieldTitle?.error}
            message={fieldTitle?.error?.message || ""}
            placeholder={translate(`${TITLE}${PLACEHOLDER}`, false)}
            value={watch(getFormFieldName(TITLE)) || ""}
            width="w-full"
            {...register(getFormFieldName(TITLE), {
              validate: (value) => {
                if (isOcr) return true;
                if (!value?.length && hasEmptyFields) return errorEmptyMessage;
                if (!value?.trim().length) return errorBlankMessage;
                if (value?.length > MAX_LENGTH_NAME)
                  return errorMaxLengthMessage;
                return true;
              },
            })}
          />
        </Label>
        <Label dataCy={`${BASE_PATH}_${DATE}`} text={translate(DATE)}>
          <Controller
            name={getFormFieldName(DATE)}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Datepicker2
                dataCy={`${BASE_PATH}_${DATE}`}
                error={error?.message}
                onChange={onChange}
                selected={value ? dayjs(value) : null}
                dateFormat="MM/YYYY"
                timeView={["month", "year"]}
                type="month"
              />
            )}
            rules={
              isOcr
                ? undefined
                : {
                    validate: (value) => {
                      if (!value && hasEmptyFields) return errorEmptyMessage;
                      if (value && dayjs().isBefore(value))
                        return `${t("profile.errors.noFutureDate")}`;
                      return true;
                    },
                  }
            }
          />
        </Label>
      </div>
      <div className="flex w-full gap-x-5">
        <Label
          dataCy={`${BASE_PATH}_${URL_PATH}`}
          text={translate(URL_PATH, false)}
        >
          <div className="flex flex-row gap-x-5 justify-start items-center">
            <div className="w-2/3">
              <Input
                dataCy={`${BASE_PATH}_${URL_PATH}`}
                width="w-full"
                value={watch(getFormFieldName(URL_PATH)) || ""}
                error={!!fieldUrlPath?.error}
                message={fieldUrlPath?.error?.message || ""}
                placeholder={translate(`${URL_PATH}${PLACEHOLDER}`, false)}
                {...register(getFormFieldName(URL_PATH), {
                  validate: (value) => {
                    if (isOcr) return true;
                    if (!value?.length) return true;
                  },
                  pattern: {
                    value: URL_REGEXP_PATTERN,
                    message: `${t(
                      "profile.experience.errors.urlPatternNotMatch",
                    )}`,
                  },
                  onChange: (event) =>
                    !event.target.value?.length
                      ? ""
                      : checkUrl(event.target.value),
                })}
              />
            </div>
          </div>
        </Label>
        {/* <Label
          dataCy={`${BASE_PATH}_${URL_PATH}`}
          text={translate("pdf", false)}
        >
          <Button dataCy="upload" additionalClass="h-8.5">
            <span className="text-primary-100 flex flex-row items-center">
              <UploadIcon className="mr-2" />
              {t("profile.experience.actions.upload")}
            </span>
          </Button>
        </Label> */}
      </div>
      <div className="w-1/2 pr-2.5">
        <motion.div>
          <Label
            text={translate(TECH_STACK)}
            dataCy={`${BASE_PATH}_${TECH_STACK}`}
          >
            <Controller
              name={getFormFieldName(TECH_STACK)}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SearchSelectPicker
                  dataCy={TECH_STACK}
                  additionalTagsWrapClassName={styles.techWrapperOther}
                  defaultSelected={prepareTechStackDictionaryValues(
                    value,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    technologyDict,
                  )}
                  onTagSelected={onChange}
                  proposalTags={searchedProposals}
                  onInputChange={(searchValue) => {
                    const result =
                      fullTextSearch &&
                      fullTextSearch(searchValue, FULL_TECH_SEARCH_MAX);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setSearchedProposals(result);
                  }}
                  error={!!error}
                  message={error?.message}
                />
              )}
              rules={
                isOcr
                  ? undefined
                  : {
                      validate: (value) =>
                        !value?.length && hasEmptyFields
                          ? errorEmptyMessage
                          : true,
                    }
              }
            />
          </Label>
        </motion.div>
      </div>
    </div>
  );
};
export default ArticlesForm;
