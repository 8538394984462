import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import { AnyAction, Dispatch } from "redux";
import ActionTypes from "./actionTypes";
import { ReportAPIModel, ReportFiltersAPIModel } from "../../models";
import { ReportsService } from "../../services";
import { IAction } from "./types";
import { GroupNames, ISorter } from "../reducers/types";

export const fetchReportFiltersStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_REPORT_FILTERS_START);
export const fetchReportFiltersSuccess = (
  payload: ReportFiltersAPIModel,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_REPORT_FILTERS_SUCCESS, payload);
export const fetchReportFiltersError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_REPORT_FILTERS_ERROR, payload);

export const fetchReportFilters = async (
  dispatch: Dispatch,
  users?: string[],
  projects?: string[],
  clients?: string[],
): Promise<AnyAction | void> => {
  dispatch(fetchReportFiltersStart());
  const result = await ReportsService.fetchReportFilters(
    users,
    projects,
    clients,
  );
  if (isAPIError(result)) {
    dispatch(fetchReportFiltersError(result));
  } else {
    dispatch(fetchReportFiltersSuccess(result));
  }
};

export const fetchReportStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_REPORT_START);
export const fetchReportSuccess = (payload: ReportAPIModel): AnyAction =>
  actionCreator(ActionTypes.FETCH_REPORT_SUCCESS, payload);
export const fetchReportError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_REPORT_ERROR, payload);
export const clearReport = (): AnyAction =>
  actionCreator(ActionTypes.CLEAR_REPORT);

export const fetchReport = async (
  dispatch: Dispatch,
  from: string,
  to: string,
  users?: string[],
  projects?: string[],
): Promise<AnyAction | void> => {
  dispatch(fetchReportStart());
  const result = await ReportsService.fetchReport(from, to, users, projects);
  if (isAPIError(result)) {
    dispatch(fetchReportError(result));
  } else {
    dispatch(fetchReportSuccess(result));
  }
};

export const updateReportsGroupBy = (payload: GroupNames): AnyAction =>
  actionCreator(ActionTypes.UPDATE_REPORTS_GROUP_BY, payload);
export const clearReportsGroupBy = (): AnyAction =>
  actionCreator(ActionTypes.CLEAR_REPORTS_GROUP_BY);

export const updateActiveSorter = (payload: ISorter): AnyAction =>
  actionCreator(ActionTypes.UPDATE_ACTIVE_SORTER, payload);
