import dayjs from "dayjs";
import {
  ProjectTimeEntriesModel,
  ProjectTimeEntryModel,
  TimesheetModel,
  BarChartDataModel,
} from "../models";
import {
  TIME_FORMAT_YYYY_MM_DD,
  TIME_FORMAT_DD_MM_YYYY,
} from "../shared/constants";

const entriesGroupedByDate = (
  entries: Record<string, ProjectTimeEntriesModel>,
  entry: ProjectTimeEntryModel,
): { [x: string]: ProjectTimeEntryModel[] } => {
  const date = dayjs(entry.date).format(TIME_FORMAT_YYYY_MM_DD);
  return { ...entries, [date]: [...(entries[date] ?? []), entry] };
};

export const entriesGroupedByType = (
  entries: TimesheetModel,
): Record<string, TimesheetModel> =>
  entries.reduce((acc, entry) => {
    (acc[entry.type] = acc[entry.type] || []).push(entry);
    return acc;
  }, {});

export const entriesGroupedByTypeId = (
  entries: TimesheetModel,
): Record<string, unknown> =>
  entries.reduce(
    (acc, entry) => ({
      ...acc,
      [entry.typeId]: entriesGroupedByDate(acc[entry.typeId] ?? {}, entry),
    }),
    {},
  );

export const entriesBarChartGroup = (
  timesheet: ProjectTimeEntriesModel,
  from: string,
  to: string,
): BarChartDataModel => {
  const daysNumber = dayjs(to).diff(dayjs(from), "day");
  const datesArr = [...Array(daysNumber + 1)].map((_, index) => ({
    name: dayjs(from).add(index, "day").format(TIME_FORMAT_DD_MM_YYYY),
  }));

  const data = JSON.parse(JSON.stringify(timesheet));

  const projectIds = data.map((item: ProjectTimeEntryModel) => item.typeId);
  const uniqueProjectIds = [...new Set(projectIds)];

  const formattedData = data.reduce(
    (acc, entry: ProjectTimeEntryModel) =>
      acc.map((item) =>
        item.name === dayjs(entry.date).format(TIME_FORMAT_DD_MM_YYYY)
          ? {
              ...item,
              [entry.typeId]: (item?.[entry.typeId] || 0) + entry.minutes / 60,
            }
          : item,
      ),
    datesArr,
  );

  return { entries: formattedData, projectIds: uniqueProjectIds };
};
