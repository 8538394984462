import { AnyAction, combineReducers } from "redux";
import ActionTypes from "./actionTypes";
import { ISelectedFilters, ISelectedSorter, IProfileReturn } from "./types";
import {
  selectedFiltersInitialState,
  selectedSorterInitialState,
} from "../ui/users-list/constants";
import { SkillsSearchQueryResultModel } from "../models/types";

const skillsSearchQueryResultReducer = (
  state: SkillsSearchQueryResultModel | Record<string, unknown> = {},
  action: AnyAction,
): SkillsSearchQueryResultModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_SKILLS_SEARCH_QUERY_RESULT_START:
      return state;
    case ActionTypes.FETCH_SKILLS_SEARCH_QUERY_RESULT_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_SKILLS_SEARCH_QUERY_RESULT_ERROR:
    case ActionTypes.CLEAR_SKILLS_SEARCH_QUERY:
      return {};
    default:
      return state;
  }
};

export const isFetchingSkillsSearchQueryResult = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_SKILLS_SEARCH_QUERY_RESULT_START:
      return true;
    case ActionTypes.FETCH_SKILLS_SEARCH_QUERY_RESULT_SUCCESS:
    case ActionTypes.FETCH_SKILLS_SEARCH_QUERY_RESULT_ERROR:
      return false;
    default:
      return state;
  }
};

const filtersReducer = (
  state: ISelectedFilters = selectedFiltersInitialState,
  action: AnyAction,
): ISelectedFilters => {
  const optionId: string = action?.payload?.optionId;
  const filterName: string = action?.payload?.filterName;
  switch (action.type) {
    case ActionTypes.ADD_TEAM_USERS_FILTER:
      return {
        ...state,
        [filterName]: [...state[filterName], optionId],
      };
    case ActionTypes.REMOVE_TEAM_USERS_FILTER:
      return {
        ...state,
        [filterName]: state[filterName].filter(
          (item: string): boolean => item !== optionId,
        ),
      };
    case ActionTypes.RESET_TEAM_USERS_FILTERS:
      return selectedFiltersInitialState;
    default:
      return state;
  }
};

const sorterReducer = (
  state: ISelectedSorter = selectedSorterInitialState,
  action: AnyAction,
): ISelectedSorter => {
  switch (action.type) {
    case ActionTypes.SET_TEAM_USERS_SORTER:
      return action.payload;
    default:
      return state;
  }
};

const postponedUsersListReducer = (
  state: IProfileReturn[] = [],
  action: AnyAction,
): IProfileReturn[] => {
  switch (action.type) {
    case ActionTypes.ADD_POSTPONED_USER:
      return [...state, action.payload];
    case ActionTypes.REMOVE_POSTPONED_USER:
      return state.filter(
        (user: IProfileReturn): boolean =>
          user.userId !== action.payload.userId,
      );
    case ActionTypes.RESET_POSTPONED_USERS:
      return [];
    default:
      return state;
  }
};

export const teamUsersReducer = combineReducers({
  filters: filtersReducer,
  sorter: sorterReducer,
  postponedUsersList: postponedUsersListReducer,
});

export default skillsSearchQueryResultReducer;
