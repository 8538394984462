import { classJoin } from "@ps/utils";
import { ReactElement } from "react";
import { SubheaderProps } from "./types";

const Subheader = ({
  additionalClassName,
  fontSize = "text-lg",
  title,
  Icon,
}: SubheaderProps): ReactElement => (
  <div
    className={classJoin(
      "flex justify-start gap-2 items-center w-full",
      additionalClassName,
    )}
  >
    <div className="flex gap-2 items-center w-full h-full">
      <div className="h-5 w-5">{Icon}</div>
      <h3
        className={`${fontSize} text-base font-semibold text-neutralPrimary-20`}
      >
        {title}
      </h3>
    </div>
  </div>
);

export default Subheader;
