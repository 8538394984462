import { ReactElement } from "react";
import { CardHeader, Label, InfoTooltip } from "@ps/ui-components";
import { ReactComponent as RoleIcon } from "../../../../images/profile-summary/role.svg";
import { PROFILE_SUMMARY_EXPERIENCE } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import { RolesProps } from "./types";

const BASE_PATH = "profile.summary";

const Roles = ({ roles }: RolesProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <CardHeader
      dataCy={`${PROFILE_SUMMARY_EXPERIENCE}_role`}
      Icon={RoleIcon}
      iconColor="text-primary-50"
    >
      <Label
        dataCy={`${PROFILE_SUMMARY_EXPERIENCE}_role`}
        text={t(`${BASE_PATH}.roles`)}
      >
        {roles.map((role) => (
          <div className="flex font-semibold items-center" key={role}>
            <span className="capitalize pr-1">{role}</span>
            <InfoTooltip
              // temporary hardcoded roles in i18n TO FIX
              tooltipContent={t(
                `profile.overview.basicInfo.${role.trim()}Tooltip`,
              )}
              dataCy={`${PROFILE_SUMMARY_EXPERIENCE}_possibleHours`}
              iconColor="bg-primary-50"
            />
          </div>
        ))}
      </Label>
    </CardHeader>
  );
};

export default Roles;
