import { ReactElement, useContext, ContextType } from "react";
import FontSizeContext from "./context";
import { FONT_SIZE_PROVIDER_ID, FontSize } from "./types";

// @ts-ignore
const useFontSizeMode = (): ContextType => {
  const fontSizeMode = useContext(FontSizeContext);
  const isDefaultFont = fontSizeMode.fontSize === FontSize.DEFAULT;
  const isSmallFont = fontSizeMode.fontSize === FontSize.SMALL;
  const isLargeFont = fontSizeMode.fontSize === FontSize.LARGE;

  const FontSizeModeWrapper = ({
    children,
  }: {
    children: ReactElement | ReactElement[];
  }): ReactElement => (
    <div data-font-size={fontSizeMode.fontSize} className="h-auto w-auto">
      {children}
    </div>
  );

  return {
    ...fontSizeMode,
    providerId: FONT_SIZE_PROVIDER_ID,
    isDefaultFont,
    isSmallFont,
    isLargeFont,
    FontSizeModeWrapper,
  };
};

export default useFontSizeMode;
