import { ReactElement } from "react";
import { Label, InfoTooltip } from "@ps/ui-components";
import { AVAILABILITY } from "../../constants";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { BASE_PATH } from "./constants";
import { LabelWrapperProps } from "../types";

const LabelWrapper = ({
  field,
  children,
  className,
  tooltipInformation,
  isRequired = false,
}: LabelWrapperProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const id = `${BASE_PATH}_${field}`;
  return (
    <Label
      additionalClassName={className}
      dataCy={id}
      key={id}
      text={
        <div className="flex items-center gap-2">
          <span>{`${t(`profile.overview.${AVAILABILITY}.${field}`)}${
            isRequired ? "*" : ""
          }`}</span>
          {tooltipInformation && (
            <InfoTooltip tooltipContent={t(tooltipInformation)} dataCy={id} />
          )}
        </div>
      }
    >
      {children}
    </Label>
  );
};

export default LabelWrapper;
