import { UTCToDayjs } from "@ps/utils";
import { getName } from "i18n-iso-countries";
import {
  ClientAPIModel,
  ClientReturnModel,
  ClientSummaryAPIModel,
  ClientSummaryReturnModel,
  PrepareCountryToDisplayReturn,
} from "../types";
import { CURRENT_LANGUAGE_CODE } from "../../../i18n/constants";

const clientsSummaryFromAPIToModel = (
  clients: ClientSummaryAPIModel[],
): ClientSummaryReturnModel[] =>
  clients.map(
    (client: ClientSummaryAPIModel): ClientSummaryReturnModel => ({
      id: client.id,
      archived: client?.archived,
      clientName: client?.clientName,
      lastUpdate: UTCToDayjs(client?.lastUpdate || ""),
      numberOfActiveProspects: client?.numberOfActiveProspects,
      numberOfActiveProjects: client?.numberOfActiveProjects,
      numberOfActiveFolders: client?.numberOfActiveFolders,
    }),
  );

const prepareCountryToDisplay = (
  country: string,
): PrepareCountryToDisplayReturn => ({
  label: getName(country, CURRENT_LANGUAGE_CODE, { select: "official" }),
  value: country,
});

const clientSummaryFromAPIToModel = (
  client: ClientAPIModel,
): ClientReturnModel => ({
  id: client.id,
  archived: client.archived,
  clientName: client.clientName,
  contactPersonName: client.contactPersonName,
  contactPersonEmail: client.contactPersonEmail,
  country: client?.country ? prepareCountryToDisplay(client?.country) : "",
  city: client.city,
  phoneNumber: client.phoneNumber,
  domains: client?.domains,
});

export { clientsSummaryFromAPIToModel, clientSummaryFromAPIToModel };
