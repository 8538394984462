import { removeDiacriticsFromString } from "@ps/utils";
import { CandidateModel } from "../../../models/types";
import { ExtendedUserProps, UserProps } from "./types";

const removeSavedCandidates = (
  users: ExtendedUserProps[],
  candidates: CandidateModel[],
): ExtendedUserProps[] =>
  users.reduce(
    (acc: ExtendedUserProps[], user: ExtendedUserProps): ExtendedUserProps[] =>
      candidates.some(
        (candidate: CandidateModel): boolean =>
          candidate.userId === user.userId,
      )
        ? acc
        : [...acc, user],
    [],
  );

export const prepareExtendedUsersList = (
  users: UserProps[],
  candidates: CandidateModel[],
): ExtendedUserProps[] => {
  const extendedUsers = users.map(
    (single: UserProps): ExtendedUserProps => ({
      ...single,
      noForeignCharsFullName: removeDiacriticsFromString(
        `${single?.firstName} ${single?.lastName}`?.toLowerCase() || "",
      ),
    }),
  );
  return removeSavedCandidates(extendedUsers, candidates);
};

export const prepareActiveCandidatesList = (
  candidatesList: CandidateModel[],
): CandidateModel[] =>
  candidatesList?.filter(
    (candidate: CandidateModel): boolean => !candidate?.deleted,
  );
