import { ReactElement } from "react";
import { uid } from "react-uid";
import { G, Line, Rect, StyleSheet, Svg, Text } from "@react-pdf/renderer";
import {
  countChartScale,
  countChartTotalHeight,
  countColumnTextTransformation,
  countColumnXStartPosition,
  countScaleHeight,
} from "./utils";
import { BarChartProps, ISingleProject, ISingleReport } from "./types";

const styles = StyleSheet.create({
  scale: {
    opacity: 0.1,
  },
  scaleNumber: {
    fontSize: "9",
    fill: "#6B7482",
    textAnchor: "end",
  },
  columnDesc: {
    fill: "#6B7482",
    fontSize: "7",
  },
});

const BarChart = ({
  data,
  isMonthly = true,
  chartTotalWidth = 550,
  chartMaxHeight = 300,
}: BarChartProps): ReactElement => {
  if (!data?.length) return <></>;

  const leftMargin = 40;
  const chartWidthMargin = chartTotalWidth - leftMargin;

  const highestColumn = Math.max(
    ...data.map((element: ISingleReport): number => element.totalHours),
  );
  const columnsNumber = data.length;

  const singleColumnSpaceWidth = Number(
    (chartWidthMargin / columnsNumber).toFixed(2),
  );
  const columnWidthPercentage = 40; //percentage width amount

  const scaleParts =
    isMonthly && highestColumn > 12 ? 20 : countChartScale(highestColumn);

  const chartTotalHeight = countChartTotalHeight(chartMaxHeight, highestColumn);

  const scaleAmount = chartTotalHeight / scaleParts;

  return (
    <Svg
      viewBox={`0, -${
        highestColumn < 1 ? scaleAmount - 20 : scaleAmount
      }, ${chartTotalWidth}, ${chartTotalHeight + 100}`}
    >
      {Array(Math.round(scaleParts) + 2)
        .fill(0)
        .map(
          (_, index: number): ReactElement => (
            <G key={`scale${index}`}>
              <Line
                x1={leftMargin}
                x2={chartTotalWidth}
                y1={chartTotalHeight - scaleAmount * index}
                y2={chartTotalHeight - scaleAmount * index}
                strokeWidth={1}
                stroke="gray"
                style={styles.scale}
              />
              <Text
                x={leftMargin - 10}
                y={chartTotalHeight - scaleAmount * index}
                style={styles.scaleNumber}
              >
                {isMonthly && highestColumn > 12
                  ? `${
                      Number((highestColumn / scaleParts).toFixed(0)) * index
                    }:00`
                  : `${index}:00`}
              </Text>
            </G>
          ),
        )}
      <G transform={`translate(${leftMargin}, 0)`}>
        {data.map(
          (singleDay: ISingleReport, singleDayIndex: number): ReactElement => {
            let pos = 0;
            return (
              <G
                key={uid(singleDay)}
                transform={`translate(${countColumnXStartPosition(
                  singleColumnSpaceWidth,
                  singleDayIndex,
                  columnWidthPercentage,
                )}, 0)`}
              >
                {singleDay?.projects?.map(
                  (project: ISingleProject): ReactElement => {
                    const scaledHeight = countScaleHeight(
                      highestColumn,
                      chartTotalHeight,
                      project.hours,
                    );
                    pos += scaledHeight;
                    return (
                      <Rect
                        key={`rect-${project.projectId}`}
                        width={
                          (columnWidthPercentage * singleColumnSpaceWidth) / 100
                        }
                        height={scaledHeight}
                        fill={project.color}
                        x={0}
                        y={chartTotalHeight - pos}
                      />
                    );
                  },
                )}
                <Text
                  style={styles.columnDesc}
                  transform={countColumnTextTransformation(
                    columnsNumber,
                    chartTotalHeight,
                    singleColumnSpaceWidth,
                    columnWidthPercentage,
                    isMonthly,
                  )}
                >
                  {singleDay.date.format(
                    isMonthly ? "MMM, YYYY" : "dd, DD-MM-YYYY",
                  )}
                </Text>
              </G>
            );
          },
        )}
      </G>
    </Svg>
  );
};

export default BarChart;
