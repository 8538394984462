import dayjs, { Dayjs } from "dayjs";
import { getNameFromDictionary, removeDiacriticsFromString } from "@ps/utils";
import { PROFESSIONS, SENIORITY, LOCATION } from "../../../../constants";
import {
  IExtendedTeamMemberModel,
  IFilterOption,
  IListFilters,
  OptionsProps,
} from "./types";
import { TeamMemberModel } from "../../../../models";
import {
  DictionaryEntryAPIModel,
  DictionaryModel,
} from "../../../../../dictionaries";
import { removeFilterDuplicates } from "../../../common/utils";
import { FilterItem } from "../../../../../prospect-domain/ui/common/types";

export const extendedUsers = (
  users: TeamMemberModel[],
): IExtendedTeamMemberModel[] =>
  users.map((user: TeamMemberModel) => ({
    ...user,
    noForeignCharsFullName: `${user.firstName} ${user.lastName}`,
  }));

export const filterUsers = (
  users: IExtendedTeamMemberModel[] = [],
  searchValue: string,
  filters: IListFilters,
  search: (str: string) => unknown[],
): IExtendedTeamMemberModel[] => {
  const preparedToFilter = !searchValue?.length
    ? users
    : (search(
        removeDiacriticsFromString(searchValue.toLowerCase()),
      ).reverse() as IExtendedTeamMemberModel[]) || [];

  if (
    !filters ||
    !Object.values(filters).filter((item: string[]) => item.length)?.length
  )
    return preparedToFilter;

  let result = preparedToFilter;

  if (filters[PROFESSIONS]?.length && result.length) {
    result = result.filter((user: IExtendedTeamMemberModel) =>
      filters?.[PROFESSIONS]?.some(
        (item: string) => item === user.professions?.main,
      ),
    );
  }

  if (filters[SENIORITY]?.length && result.length) {
    result = result.filter((user: IExtendedTeamMemberModel) =>
      filters[SENIORITY].some((item) => item === user.seniority),
    );
  }

  if (filters[LOCATION]?.length && result.length) {
    result = result.filter((user: IExtendedTeamMemberModel) =>
      filters[LOCATION].some((item) => item === user[LOCATION]?.value),
    );
  }

  return result;
};

export const prepareProfessionFilterOptions = (
  users: IExtendedTeamMemberModel[],
  dictionary: DictionaryModel,
): IFilterOption[] => {
  const temp = JSON.parse(JSON.stringify(users));
  const result: IFilterOption[] = temp
    .filter((user: IExtendedTeamMemberModel) => user[PROFESSIONS]?.main)
    .map(
      (item: IExtendedTeamMemberModel): IFilterOption => ({
        name: getNameFromDictionary(dictionary, item[PROFESSIONS]?.main),
        id: item[PROFESSIONS]?.main || "",
      }),
    );

  return removeFilterDuplicates(result);
};

export const prepareSeniorityFilterOptions = (
  users: IExtendedTeamMemberModel[],
  dictionary: DictionaryModel,
): IFilterOption[] => {
  const temp = JSON.parse(JSON.stringify(users));
  const result: IFilterOption[] = temp
    .filter((user: IExtendedTeamMemberModel) => user[SENIORITY])
    .map(
      (item: IExtendedTeamMemberModel): IFilterOption => ({
        name: getNameFromDictionary(dictionary, item[SENIORITY]),
        id: item[SENIORITY] || "",
      }),
    );

  return removeFilterDuplicates(result);
};

export const prepareLocationFilterOptions = (
  users: IExtendedTeamMemberModel[],
  allCountries: { [key: string]: string },
): IFilterOption[] => {
  const temp = JSON.parse(JSON.stringify(users));

  const result: IFilterOption[] = temp
    .map(
      (item: IExtendedTeamMemberModel): IFilterOption => ({
        name: item[LOCATION]?.value ? allCountries[item[LOCATION].value] : "",
        id: item[LOCATION]?.label
          ? Object.keys(allCountries).find(
              (key: string) => allCountries[key] === item[LOCATION]?.label,
            )
          : "",
      }),
    )
    .filter((item: IFilterOption) => item.id.length);
  return removeFilterDuplicates(result);
};

export const selectFilterOptions = (
  data: IFilterOption[],
  searchValue: string,
): IFilterOption[] => {
  if (!searchValue) return data;
  return data.filter((item: IFilterOption) =>
    removeDiacriticsFromString(item.name?.toLowerCase()).includes(
      removeDiacriticsFromString(searchValue.toLowerCase()),
    ),
  );
};

export const getCheckedNames = (
  filters: IListFilters,
  name: string,
): string[] => filters?.[name]?.map((item: FilterItem) => item.name);

export const countOptionFilterAmount = (
  optionId: string,
  fieldName: string,
  users: IExtendedTeamMemberModel[],
  nestedName?: string,
): number =>
  nestedName
    ? users?.filter(
        (user: IExtendedTeamMemberModel): boolean =>
          user[fieldName]?.[nestedName] === optionId,
      )?.length
    : users?.filter(
        (user: IExtendedTeamMemberModel): boolean =>
          user[fieldName] === optionId,
      )?.length;

export const getValueData = (
  value: string | null | undefined | OptionsProps,
  dict: DictionaryModel,
):
  | { id: string; value: string; label: string }
  | string
  | null
  | undefined
  | OptionsProps => {
  const currentValue = typeof value === "string" ? value : value?.label;
  return value && currentValue
    ? {
        id: dict.find(
          (item: DictionaryEntryAPIModel) => item.name === currentValue,
        )?.id,
        value: currentValue,
        label: currentValue,
      }
    : value;
};

export const normalizedDate = (
  date: string | Dayjs | undefined | null,
): string => dayjs(date).format("DD.MM.YYYY");

export const checkInfinityYear = (
  date: string | Dayjs | undefined | null,
): boolean => {
  const finishDate = dayjs(date).toDate();
  const finishYear = +finishDate.getFullYear();

  const substractedYears = finishYear - dayjs().year();
  return substractedYears > 99;
};
