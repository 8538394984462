import { ReactElement, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { PieChart, Pie, Cell, Label } from "recharts";
import dayjs from "dayjs";
import { toPng } from "html-to-image";
import { LinkButton } from "@ps/ui-components";
import { minutesToHHMM, colorFormatters, classJoin } from "@ps/utils";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import {
  TotalRowMapState,
  TotalRowMapStateReturn,
  TotalRowProps,
  IDonutEntry,
} from "./types";
import { handleError } from "../../../shared";
import { REPORTS } from "../../../shared/constants";
import { ReportsModel } from "../../../models";
import { DONUT_COLORS, DONUT_USERS, DONUT_PROJECTS } from "../constants";
import {
  flatEntriesData,
  groupDataBasedOnParameter,
  countTotalInGroup,
} from "../table/helpers";
import useExcelReport from "../hooks/useExcelRaport";
import generatePdfDocument from "./generatePDFDocument";
import { ReactComponent as PDFIcon } from "../../../images/reports/pdf.svg";
import { ReactComponent as XLCSIcon } from "../../../images/reports/xlcs.svg";
import useChartData from "../hooks/useChartData";
import { FlattenEntry } from "../table/types";
import styles from "./styles.module.css";

const DownlodFiles = ({ timeFrame }: TotalRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [images, setImages] = useState<{ section: string; image: string }[]>(
    [],
  );

  const mapState = (state: TotalRowMapState): TotalRowMapStateReturn => ({
    activeSorter: state?.reports?.activeSorter,
    groupedBy: state?.reports?.groupedBy,
    report: state?.reports?.report || [],
    projectList: state.projectsList,
  });

  const { report, groupedBy, activeSorter, projectList } =
    useMappedStateSelector(mapState) as {
      report: ReportsModel;
    };

  const [extendedReport, setExtendedReport] = useState([]);

  const extendReport = (reportData = [], projectsData = []) =>
    reportData.reduce((acc, singleReportItem) => {
      const foundElement = projectsData.find(
        (item) => item.id === singleReportItem.projectId,
      );
      if (singleReportItem.projectId === foundElement?.id)
        return [...acc, { ...singleReportItem, isPublic: foundElement.public }];
      return acc;
    }, []);

  useEffect(() => {
    if (report?.length && projectList?.length) {
      setExtendedReport(extendReport(report, projectList));
    }
  }, [report, projectList]);

  const { exportExcel } = useExcelReport(extendedReport, timeFrame);

  const groupedData: Record<string, FlattenEntry[]> = groupDataBasedOnParameter(
    flatEntriesData(extendedReport),
    groupedBy?.group0?.value,
  );
  const sortedGroupedData: Record<string, FlattenEntry[]> = Object.keys(
    groupedData,
  )
    .sort()
    .reduce(
      (acc: Record<string, FlattenEntry[]>, entry: string) => ({
        ...acc,
        [entry]: groupedData[entry],
      }),
      {},
    );

  const isYearView: boolean =
    dayjs(timeFrame?.[1]).diff(dayjs(timeFrame?.[0]), "month") + 1 === 12;

  const { totalTimeInMinutes } = useChartData(timeFrame, isYearView);

  let projectsDonutChart: HTMLElement | null =
    document.getElementById(DONUT_PROJECTS);
  let usersDonutChart: HTMLElement | null =
    document.getElementById(DONUT_USERS);

  useEffect(() => {
    projectsDonutChart = document.getElementById(DONUT_PROJECTS);
    usersDonutChart = document.getElementById(DONUT_USERS);
  }, [extendedReport]);

  const handleCaptureClick = async (): Promise<void> => {
    if (!projectsDonutChart) return;
    await toPng(projectsDonutChart, { quality: 1.0 })
      .then((dataUrl: string): void => {
        setImages((prev) => [...prev, { section: "projects", image: dataUrl }]);
      })
      .catch((error) => handleError(error));
    if (!usersDonutChart) return;
    await toPng(usersDonutChart, { quality: 1.0 })
      .then((dataUrl: string): void => {
        setImages((prev) => [...prev, { section: "users", image: dataUrl }]);
      })
      .catch((error) => handleError(error));
  };

  useEffect(() => {
    handleCaptureClick();
  }, [extendedReport]);

  const groupedProjects = groupDataBasedOnParameter(
    flatEntriesData(extendedReport),
    "projectName",
  );
  const projectsDataForDonut = Object.keys(groupedProjects)?.map(
    (entry: string): IDonutEntry => ({
      name: entry,
      value: countTotalInGroup(groupedProjects?.[entry]),
      color: groupedProjects?.[entry]?.[0]?.projectColor,
    }),
  );

  const groupedUsers = groupDataBasedOnParameter(
    flatEntriesData(extendedReport),
    "userName",
  );

  const usersDataForDonut = Object.keys(groupedUsers)?.map(
    (entry: string): IDonutEntry => ({
      name: entry,
      value: countTotalInGroup(groupedUsers?.[entry]),
    }),
  );

  const renderDonutChart = (
    children: JSX.Element,
    id: "donutProjects" | "donutUsers",
    data: IDonutEntry[],
  ): ReactElement => (
    <PieChart width={250} height={200} id={id}>
      <Pie
        data={data}
        cx={120}
        cy={100}
        innerRadius={60}
        cornerRadius={40}
        outerRadius={80}
        fill="#8884d8"
        stroke="none"
        isAnimationActive={false}
        paddingAngle={5}
        dataKey="value"
      >
        {children}
        <Label position="center" className="text-2xl font-bold">
          {minutesToHHMM(totalTimeInMinutes)}
        </Label>
      </Pie>
    </PieChart>
  );

  return (
    <>
      <div
        className={classJoin(
          "flex gap-5 font-semibold pt-1.5",
          extendedReport?.length && timeFrame?.length
            ? "opacity-100"
            : "opacity-0",
        )}
      >
        {extendedReport?.length ? (
          <>
            <LinkButton
              dataCy={REPORTS}
              onClick={() =>
                generatePdfDocument(
                  timeFrame,
                  totalTimeInMinutes,
                  extendedReport,
                  images,
                  groupedBy,
                  activeSorter,
                  sortedGroupedData,
                )
              }
              prefixIcon={<PDFIcon className="fill-current text-primary-50" />}
            >
              {`${t("reports.export")} PDF`}
            </LinkButton>
            <LinkButton
              dataCy={REPORTS}
              onClick={() => exportExcel()}
              prefixIcon={<XLCSIcon className="fill-current text-primary-50" />}
            >
              {`${t("reports.export")} XCLS`}
            </LinkButton>
          </>
        ) : (
          <></>
        )}
      </div>

      {/* invisible donut charts for PDF generator */}
      {extendedReport?.length ? (
        createPortal(
          <div
            className={classJoin(
              "flex justify-around items-center absolute overflow-hidden top-auto",
              styles.donutChartContainer,
            )}
          >
            {renderDonutChart(
              <>
                {projectsDataForDonut.map(
                  (entry: IDonutEntry): JSX.Element => (
                    <Cell
                      key={entry.name}
                      fill={colorFormatters.prepareRGBObjectToHEX(entry?.color)}
                    />
                  ),
                )}
              </>,
              DONUT_PROJECTS,
              projectsDataForDonut,
            )}
            {renderDonutChart(
              <>
                {usersDataForDonut.map(
                  (entry: IDonutEntry, index: number): JSX.Element => (
                    <Cell
                      key={entry.name}
                      fill={
                        DONUT_COLORS[
                          index > 9 ? Math.floor(Math.random() * 10) : index
                        ]
                      }
                    />
                  ),
                )}
              </>,
              DONUT_USERS,
              usersDataForDonut,
            )}
          </div>,
          document.body,
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default DownlodFiles;
