import { ReactElement, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { classJoin } from "@ps/utils";
import { useThemeMode } from "../theme-mode-provider";
import Background from "./background";
import QuestionScreenProps from "./types";
import { BUTTON_PREFIX, QUESTION_SCREEN_PREFIX } from "../../shared/data-cy";

enum Images {
  DEFAULT = "default",
  LEFT = "left",
  RIGHT = "right",
}

const QuestionScreen = ({
  question,
  leftButtonText,
  rightButtonText,
  defaultImage,
  hoverOnLeftImage,
  hoverOnRightImage,
  dataCy,
  onClickLeft,
  onClickRight,
  lightMode = true,
}: QuestionScreenProps): ReactElement => {
  const [currentImage, setCurrentImage] = useState(Images.DEFAULT);
  const { isHighContrast } = useThemeMode();

  const getProperImage = () => {
    switch (currentImage) {
      case Images.LEFT:
        return hoverOnLeftImage;
      case Images.RIGHT:
        return hoverOnRightImage;
      default:
        return defaultImage;
    }
  };

  const getGradientColors = (): string => {
    if (isHighContrast) return "from-primary-95 to-primary-95";
    return lightMode
      ? "from-primary-70 to-primary-60"
      : "from-primary-20 to-primary-50";
  };

  const getButtonStyles = (): string => {
    const base =
      "text-2xl border rounded-md p-4 w-max mr-5 hover:bg-primary-50 hover:border-primary-50";
    if (isHighContrast)
      return classJoin(
        base,
        "border-primary-10 text-primary-10 hover:text-primary-95",
      );
    return classJoin(base, "border-primary-100 text-primary-100");
  };

  return (
    <div
      className={classJoin(
        "relative bg-gradient-to-r w-full h-full flex flex-col justify-center",
        getGradientColors(),
      )}
      data-cy={`${QUESTION_SCREEN_PREFIX}-${dataCy}`}
    >
      <Background />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={currentImage}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="absolute top-1/2 right-10 transform -translate-y-2/4"
        >
          <>{getProperImage()}</>
        </motion.div>
      </AnimatePresence>
      <div className="ml-20 z-10">
        <span className="text-5xl text-white">{question}</span>
        <div
          className="w-min h-min flex flex-row mt-20"
          onPointerLeave={() => setCurrentImage(Images.DEFAULT)}
        >
          <button
            className={getButtonStyles()}
            onPointerEnter={() => setCurrentImage(Images.LEFT)}
            onClick={onClickLeft}
            data-cy={`${BUTTON_PREFIX}-${QUESTION_SCREEN_PREFIX}-${Images.LEFT}-${dataCy}`}
          >
            {leftButtonText}
          </button>
          <button
            className={getButtonStyles()}
            onPointerEnter={() => setCurrentImage(Images.RIGHT)}
            onClick={onClickRight}
            data-cy={`${BUTTON_PREFIX}-${QUESTION_SCREEN_PREFIX}-${Images.RIGHT}-${dataCy}`}
          >
            {rightButtonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionScreen;
