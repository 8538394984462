import { useState, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import Fade from "../fade";
import { ReactComponent as ArrowDown } from "../../images/arrowDown.svg";
import { ReactComponent as ActiveIcon } from "../../images/success-icon.svg";
import { useTranslationWithNamespace } from "../../hooks";
import { Keys } from "../../shared";
import { SorterProps, Name, Order } from "./types";

const spanStyle = `font-semibold select-none 
hover:text-neutralPrimary-20 pl-2 flex gap-x-2 items-center cursor-pointer`;

const Sorter = ({
  activeSorter,
  names,
  orders,
  handleSorterClick,
  width = "w-fit",
}: SorterProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [isOpen, setOpen] = useState(false);

  const [sorter, setSorter] = useState({
    name: activeSorter.name,
    order: activeSorter.order,
    isNestedField: false,
  });

  const handleNameClick =
    (name: string, isNestedField: boolean) => (): void => {
      setSorter((prev) => ({
        order: prev.order,
        name,
        isNestedField,
      }));
      handleSorterClick(name, sorter.order, isNestedField);
    };

  const handleOrderClick = (order: string) => (): void => {
    setSorter((prev) => ({
      isNestedField: prev.isNestedField,
      name: prev.name,
      order,
    }));
    handleSorterClick(sorter.name, order, sorter.isNestedField);
  };

  const handleToggle = (): void => setOpen((o) => !o);
  const handleClose = (): void => setOpen(false);

  const currentSorter: Name | undefined = names.find(
    (n) => n.name === activeSorter.name,
  );

  const handleKeyDownOnName =
    (name: string, isNestedField: boolean) =>
    (e: KeyboardEvent<HTMLDivElement>): void => {
      if (e.key === Keys.ENTER)
        handleSorterClick(name, sorter.order, isNestedField);
    };

  const handleKeyDownOnOrder =
    (order: string) =>
    (e: KeyboardEvent<HTMLDivElement>): void => {
      if (e.key === Keys.ENTER)
        handleSorterClick(sorter.name, order, sorter.isNestedField);
    };

  return (
    <div className="flex justify-between">
      <span className="text-neutralPrimary-40">{t("sorter.sortBy")}:</span>
      <Fade
        open={isOpen}
        handleClose={handleClose}
        handleOpen={handleToggle}
        contentWrapperClassName="flex flex-col bg-neutralSecondary-60 w-44 rounded-lg z-50 top-8 right-0"
        clickElementWrapperClassName={`${width} transition-width`}
        clickElement={
          <span
            className={classJoin(
              spanStyle,
              isOpen ? "text-neutralPrimary-20" : "text-neutralPrimary-40",
            )}
          >
            {currentSorter?.label}
            <ArrowDown />
          </span>
        }
      >
        <div className="flex gap-y-2 flex-col pt-4 pb-3 px-4 border-b border-neutralPrimary-40 text-black">
          <span>{t("sorter.sortBy")}</span>
          {names.map(({ name, label, isNestedField }: Name) => (
            <div
              key={name}
              role="button"
              tabIndex={0}
              className={classJoin(
                "flex items-center justify-between cursor-pointer",
                activeSorter.name === name
                  ? "pointer-events-none"
                  : "pointer-events-auto",
              )}
              // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
              onClick={handleNameClick(name, isNestedField!)}
              // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
              onKeyDown={handleKeyDownOnName(name, isNestedField!)}
            >
              <span className="font-bold select-none">{label}</span>
              {activeSorter.name === name && <ActiveIcon />}
            </div>
          ))}
        </div>

        <div className="flex gap-y-2 flex-col px-4 pt-3 pb-4 text-black">
          <span>{t("sorter.order")}</span>
          {orders.map(({ order, label }: Order) => (
            <div
              key={order}
              role="button"
              tabIndex={0}
              className={classJoin(
                "flex items-center justify-between cursor-pointer",
                activeSorter.order === order
                  ? "pointer-events-none"
                  : "pointer-events-auto",
              )}
              onClick={handleOrderClick(order)}
              onKeyDown={handleKeyDownOnOrder(order)}
            >
              <span className="font-bold select-none">{label}</span>
              {activeSorter.order === order && <ActiveIcon />}
            </div>
          ))}
        </div>
      </Fade>
    </div>
  );
};

export default Sorter;
