import { FC, ReactElement } from "react";
import PermissionContext from "./context";
import { PermissionProviderProps } from "./types";
import { hasPermissions } from "./utils";

const PermissionProvider: FC<PermissionProviderProps> = ({
  children,
  allPermissionsList,
}): ReactElement => (
  <PermissionContext.Provider
    value={{
      hasPermission: (permissions) =>
        hasPermissions(allPermissionsList, permissions),
    }}
  >
    {children}
  </PermissionContext.Provider>
);

export default PermissionProvider;
