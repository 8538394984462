import { KeyboardEvent, ReactElement, useEffect, useState } from "react";
import { classJoin } from "@ps/utils";
import {
  FilterDropdown,
  SimpleFilterOption,
  TableLabel,
} from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../hooks";
import { FilterItem, IFilterOption, SortBarProps } from "./types";
import {
  NAME,
  CLIENT_NAME,
  CREATION_DATE,
  PROJECT_MANAGER,
  BUSINESS_DEVELOPER,
  PROJECT_NAME,
  PM_NAME,
  BD_NAME,
  USERS_IN_PROJECT,
  STATUS,
  PM,
  BD,
} from "../../constants";
import styles from "./styles.module.css";
import { PROJECTS_LIST } from "../../../shared/data-cy";
import {
  getCheckedNames,
  prepareClientFilterOptions,
  prepareUserFilterOptions,
  selectFilterOptions,
} from "./utils";
import { Keys } from "../../../shared";

const DROPDOWN_ID = "filter-dropdown";

const SortBar = ({
  clientId,
  filters,
  projects,
  updateFilters,
}: SortBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const translate = (field: string) => t(`project.list.${field}`);
  const [searchValue, setSearchValue] = useState("");
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [currentIDFocused, setCurrentIDFocused] = useState("");

  useEffect(() => {
    if (showFilterOptions) document.getElementById(DROPDOWN_ID)?.focus();
  }, [showFilterOptions]);

  const clientFiltersOptions: IFilterOption[] =
    prepareClientFilterOptions(projects) || [];

  const PMFiltersOptions =
    prepareUserFilterOptions(projects, PROJECT_MANAGER) || [];

  const BDFiltersOptions =
    prepareUserFilterOptions(projects, BUSINESS_DEVELOPER) || [];

  const renderLabel = (
    fieldName: string,
    translationName?: string,
    filterOptions: undefined | IFilterOption[] = undefined,
    filterName?: string | undefined,
  ): ReactElement => (
    <TableLabel
      dataCy={translationName || fieldName}
      setSearchValue={setSearchValue}
      filtering={
        filterOptions
          ? {
              checked: getCheckedNames(filters, filterName || fieldName),
              dropdownOverlay: (
                <FilterDropdown
                  dataCy={`${PROJECTS_LIST}-${filterName}`}
                  DROPDOWN_ID={DROPDOWN_ID}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setShowFilterOptions={setShowFilterOptions}
                  filterOptions={filterOptions}
                >
                  {filterOptions.map(
                    (item: { id: string; name: string }, index: number) => {
                      const isChecked = filters[filterName || fieldName]?.some(
                        ({ id }: FilterItem) => id === item.id,
                      );
                      const autoFocusId = `${filterName}-${index}`;

                      return (
                        <SimpleFilterOption
                          key={item.id}
                          autoFocus={autoFocusId === currentIDFocused}
                          item={item}
                          isChecked={isChecked}
                          onKeyDown={(
                            event: KeyboardEvent<HTMLButtonElement>,
                            clickedIsChecked: boolean,
                            currentItem: FilterItem,
                          ): void => {
                            if (event.code === Keys.SPACE) {
                              event.preventDefault();
                              updateFilters(
                                clickedIsChecked,
                                currentItem,
                                filterName || fieldName,
                              );
                              setCurrentIDFocused(autoFocusId);
                            }
                          }}
                          onClick={(
                            clickedIsChecked: boolean,
                            currentItem: FilterItem,
                          ) => {
                            updateFilters(
                              clickedIsChecked,
                              currentItem,
                              filterName || fieldName,
                            );
                            setCurrentIDFocused(autoFocusId);
                          }}
                        />
                      );
                    },
                  )}
                </FilterDropdown>
              ),
            }
          : undefined
      }
    >
      {translate(translationName || fieldName)}
    </TableLabel>
  );

  return (
    <div
      className={classJoin(
        styles.gridContainer,
        "2xl:gap-4 gap-2 bg-neutralSecondary-41 bg-opacity-10 rounded-t-2xl h-16 items-center",
        clientId ? "" : "mt-3",
      )}
    >
      {renderLabel(NAME, PROJECT_NAME)}
      {renderLabel(
        CLIENT_NAME,
        undefined,
        selectFilterOptions(clientFiltersOptions, searchValue),
      )}
      {renderLabel(
        PROJECT_MANAGER,
        PM_NAME,
        selectFilterOptions(PMFiltersOptions, searchValue),
        PM,
      )}
      {renderLabel(
        BUSINESS_DEVELOPER,
        BD_NAME,
        selectFilterOptions(BDFiltersOptions, searchValue),
        BD,
      )}
      {renderLabel(CREATION_DATE, CREATION_DATE)}
      <div className="flex items-center gap-6 w-full h-full whitespace-nowrap col-span-1">
        <span className="text-neutralPrimary-40 select-none uppercase">
          {translate(USERS_IN_PROJECT)}
        </span>
      </div>
      <TableLabel dataCy={STATUS}>{translate(STATUS)}</TableLabel>
      <span />
    </div>
  );
};

export default SortBar;
