import { Button } from "@ps/ui-components";
import { ReactElement } from "react";
import { ReactComponent as InfoIcon } from "../../../../images/info.svg";
import { useTranslationWithNamespace } from "../../../../hooks";
import { ConfirmRemoveProps } from "./types";

const ConfirmRemove = ({
  onCancel,
  onRemove,
}: ConfirmRemoveProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div
      className={`absolute border border-neutralSecondary-60 bg-neutralPrimary-100
        w-full h-full rounded-2xl bg-opacity-90 flex items-center 
        justify-center flex-col gap-2`}
    >
      <InfoIcon />
      <span className="text-neutralPrimary-20 font-semibold">
        {t("profile.experience.actions.sectionDeleting")}
      </span>
      <span className="text-neutralPrimary-20 text-sm">
        {t("profile.experience.actions.doYouWantDelete")}
      </span>
      <div className="flex gap-4 ">
        <Button dataCy="delete" onClick={onRemove}>
          {t("profile.experience.actions.delete")}
        </Button>
        <Button dataCy="cancel" variant="secondary" onClick={onCancel}>
          {t("profile.experience.actions.cancel")}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmRemove;
