import { ReactElement } from "react";
import { Subheader } from "@ps/ui-components";
import Link from "@ps/ui-components/src/components/link";
import { OtherSummaryHeaderProps } from "../types";
import { ReactComponent as EditIcon } from "../../../../images/profile-summary/edit.svg";
import { ReactComponent as AddIcon } from "../../../../images/profile-summary/add.svg";
import { useTranslationWithNamespace, useMediaQuery } from "../../../../hooks";

const OtherSummaryHeader = ({
  title,
  Icon,
  filled,
  withButton,
  onButtonClick,
  additionalTitle,
}: OtherSummaryHeaderProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const properTitle = additionalTitle
    ? additionalTitle.toLowerCase()
    : title.toLowerCase();

  const matches = useMediaQuery("(max-width: 1280px)");

  return (
    <div className="flex items-center justify-between">
      <Subheader title={title} Icon={Icon} />
      {withButton && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          withBorder
          dataCy="summary-edit"
          suffixIcon={filled ? <EditIcon /> : <AddIcon />}
          onClick={onButtonClick}
        >
          {filled
            ? `${t("profile.overview.actions.edit")} ${
                matches ? "" : properTitle
              }`
            : `${t("profile.overview.actions.add")} ${
                matches ? "" : properTitle
              }`}
        </Link>
      )}
    </div>
  );
};

export default OtherSummaryHeader;
