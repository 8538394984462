import { FIELD_CASES } from "../constants";
import styles from "./styles.module.css";

const setSeniorityColor = (field?: string): string => {
  switch (field) {
    case FIELD_CASES.SENIOR:
      return styles.senior;
    case FIELD_CASES.REGULAR_PLUS:
      return styles.regularPlus;
    case FIELD_CASES.REGULAR:
      return styles.regular;
    case FIELD_CASES.JUNIOR_PLUS:
      return styles.juniorPlus;
    case FIELD_CASES.JUNIOR:
      return styles.junior;
    default:
      return "text-primary-50 font-bold";
  }
};

export default setSeniorityColor;
