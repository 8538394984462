import { ReactElement } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Button,
  CopyLinkIconWithTooltip,
  Empty,
  Label,
  useThemeMode,
  Tooltip,
  MoreLinkButton,
  Card,
  hasPermissions,
} from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import {
  FolderRowProps,
  FolderRowMapState,
  FolderRowMapStateReturn,
} from "./types";
import {
  ACTIVE,
  ANONYMISE,
  CLIENT_NAME,
  FOLDER_NAME,
  FOLDER_URL,
  FOLDERS_PIN,
  LAST_UPDATE,
  MORE,
  RESUMES,
  ARCHIVED,
  STATUS,
  FOLDERS,
  FOLDER,
} from "../../constants";
import { FOLDERS_LIST } from "../../../shared/data-cy";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { ClientModel } from "../../../client-domain";
import styles from "./styles.module.css";
import { CandidateModel } from "../../models/types";
import { hhPermissions } from "../../../../index";

const BASE_PATH = "folders";

const FolderRow = ({
  folder,
  clientIsArchived,
}: FolderRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();
  const history = useHistory();
  const { pathname } = useLocation();
  const currentClientPath = `/clients/${folder?.clientId}`;

  const onlyActiveCandidates = folder?.candidates?.filter(
    (candidate: CandidateModel) => !candidate.deleted,
  );

  const mapState = (state: FolderRowMapState): FolderRowMapStateReturn => ({
    clientsList: state.clients?.clientsList || [],
    isOpened: state.folders?.openedFolders?.some((item) => item === folder.id),
    permissions: state.permissions || [],
  });

  const { clientsList } = useMappedStateSelector(mapState);
  const { permissions } = useMappedStateSelector(mapState, "");

  const hasClientPermissions = hasPermissions(permissions, [
    hhPermissions.CLIENTS_MANAGE,
    hhPermissions.CLIENTS_LIST,
    hhPermissions.CLIENTS_LIST_ALL,
    hhPermissions.PROSPECT_FULL_ACCESS,
  ]);

  const client: ClientModel = clientsList.find(
    (item: ClientModel): boolean => item.id === folder.clientId,
  );

  const statusStyle = folder.archived ? "bg-gray-200" : "bg-other-toxicGreen";

  const showStatus = (archived: boolean): ReactElement => {
    return (
      <div className="flex gap-x-3 items-center">
        <div className={classJoin(statusStyle, "h-4 w-4 rounded-full")} />
        <span className="uppercase font-semibold">
          {archived
            ? t(`${BASE_PATH}.list.${ARCHIVED}`)
            : t(`${BASE_PATH}.list.${ACTIVE}`)}
        </span>
      </div>
    );
  };

  const link = `${process?.env?.REACT_APP_PUBLIC_S3_PREFIX}${
    window.location.host.split(".")[0]
  }${process?.env?.REACT_APP_PUBLIC_S3_SUFFIX}${folder?.id}`;

  const stylesWhenArchived =
    folder?.archived || clientIsArchived ? "opacity-40" : "";

  const copyContainerStyles = classJoin("flex items-center gap-3");

  const handleMoreClick = (): void => {
    history.push(`/${FOLDERS}/${folder.id}`);
  };

  const rowContent = (
    <>
      <Label
        dataCy={`${FOLDERS_LIST}_${FOLDER_NAME}`}
        additionalClassName={classJoin(stylesWhenArchived)}
        verticalAlignment
      >
        {folder.folderName ? (
          <Link
            to={`/${FOLDERS}/${folder.id}`}
            className="text-primary-50 hover:opacity-70"
          >
            {folder.folderName}
          </Link>
        ) : (
          <Empty />
        )}
      </Label>
      <div>
        <Label
          dataCy={`${FOLDERS_LIST}_${CLIENT_NAME}`}
          additionalClassName={stylesWhenArchived}
          verticalAlignment
          fontWeight="font-base"
        >
          {client.clientName &&
          hasClientPermissions &&
          pathname !== currentClientPath ? (
            <Link to={currentClientPath} className="text-primary-50">
              {client.clientName}
            </Link>
          ) : (
            <></>
          )}
          {client.clientName &&
          hasClientPermissions &&
          pathname === currentClientPath ? (
            <span>{client.clientName}</span>
          ) : (
            <></>
          )}
          {!client.clientName ? <Empty /> : <></>}
        </Label>
      </div>
      <div className={copyContainerStyles}>
        <Tooltip
          placement="top"
          content={<span className="text-sm whitespace-nowrap">{link}</span>}
          isInteractive
        >
          <a href={link} rel="noreferrer noopener" target="_blank">
            <Button
              dataCy={`${FOLDERS_LIST}_${FOLDER_URL}`}
              borderRadius="rounded-lg"
            >
              {t(`${BASE_PATH}.list.goToFolder`)}
            </Button>
          </a>
        </Tooltip>
        {folder?.archived ? (
          <></>
        ) : (
          <CopyLinkIconWithTooltip
            dataCy={`${FOLDERS_LIST}_${FOLDER_URL}-btn`}
            valueToCopy={link}
          />
        )}
      </div>
      <Label
        dataCy={`${FOLDERS_LIST}_${FOLDERS_PIN}`}
        additionalClassName={stylesWhenArchived}
        verticalAlignment
      >
        {folder?.pin ? (
          <div className={copyContainerStyles}>
            {folder.pin}
            <CopyLinkIconWithTooltip
              dataCy={`${FOLDERS_LIST}_${FOLDERS_PIN}-btn`}
              valueToCopy={folder?.pin}
            />
          </div>
        ) : (
          <Empty />
        )}
      </Label>
      <Label
        additionalClassName={classJoin(stylesWhenArchived)}
        dataCy={`${FOLDERS_LIST}_${ANONYMISE}`}
        verticalAlignment
        fontWeight="font-normal"
      >
        {folder?.anonymized ? (
          <span>{t(`${BASE_PATH}.yes`)}</span>
        ) : (
          <span>{t(`${BASE_PATH}.no`)}</span>
        )}
      </Label>
      <Label
        dataCy={`${FOLDERS_LIST}_${LAST_UPDATE}`}
        additionalClassName={stylesWhenArchived}
        verticalAlignment
      >
        {folder?.lastUpdate && folder.lastUpdate.format("DD.MM.YYYY")}
      </Label>
      <Label
        dataCy={`${FOLDERS_LIST}_${RESUMES}`}
        additionalClassName={stylesWhenArchived}
        verticalAlignment
      >
        {(onlyActiveCandidates?.length).toString()}
      </Label>
      <Label
        dataCy={`${FOLDERS_LIST}_${STATUS}`}
        additionalClassName={classJoin(stylesWhenArchived)}
        verticalAlignment
      >
        {showStatus(folder?.archived)}
      </Label>
      <MoreLinkButton
        onClick={handleMoreClick}
        dataCy={`${FOLDERS_LIST}_${MORE}`}
        additionalClassName="self-center place-self-end"
      />
    </>
  );

  return (
    <div>
      <Card
        dataCy={`${FOLDERS_LIST}_${FOLDER}`}
        additionalClassName="items-center"
        additionalCardClassName={classJoin(
          styles.gridContainer,
          "2xl:gap-4 gap-2 mb-1 py-10 top-1/2",
          isHighContrast && "border border-neutralSecondary-40",
        )}
      >
        {rowContent}
      </Card>
    </div>
  );
};

export default FolderRow;
