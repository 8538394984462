import dayjs from "dayjs";
import { DATE_FORMAT } from "../../shared/constants";
import { OcrModel } from "../models";

export const mockFullOcredModel: OcrModel = {
  bio: "To ja",
  techStack: ["JavaScript", "MongoDB"],
  projects: [
    {
      domain: "Team Management 1",
      startDate: dayjs(),
      description: "1 I made a team assembling app",
      techStack: [
        {
          id: "threejs",
          name: "Three.js",
          iconUrl: undefined,
          desc: undefined,
          deleted: false,
          itemId: "threejs",
        },
        {
          id: "bigdata",
          name: "Big Data",
          iconUrl: undefined,
          desc: undefined,
          deleted: false,
          itemId: "bigdata",
        },
      ],
      sourceText: `1 Banking
        ${dayjs().format(DATE_FORMAT)}
        I made a team assembling app
        JavaScript
        MongoDB
        `,
    },
    {
      domain: "Team Management",
      startDate: dayjs().add(2, "month"),
      description: "2 I made a team assembling app",
      techStack: [
        {
          id: "javascript",
          name: "JavaScript",
          iconUrl: undefined,
          desc: undefined,
          deleted: false,
          itemId: "javascript",
        },
        {
          id: "bigdata",
          name: "Big Data",
          iconUrl: undefined,
          desc: undefined,
          deleted: false,
          itemId: "bigdata",
        },
      ],
      sourceText: `2 Banking
        ${dayjs().format(DATE_FORMAT)}
        I made a team assembling app
        JavaScript
        MongoDB
        `,
    },
  ],
  education: [
    {
      startDate: dayjs(),
      description: "Informatyka Praktyczna",
      sourceText: `${dayjs().format(DATE_FORMAT)}
        Informatyka Praktyczna
        `,
    },
    {
      startDate: dayjs(),
      endDate: dayjs().add(7, "day"),
      description: "Kurs &quot;Informatyka inflacja nie tyka&quot;",
      sourceText: `${dayjs().format(DATE_FORMAT)}
        ${dayjs().add(7, "day")}
        Kurs &quot;Informatyka inflacja nie tyka&quot;`,
    },
  ],
  accomplishments: [
    {
      date: dayjs().subtract(1, "day"),
      description: "1 Wstałem na daily",
      sourceText: `${dayjs().subtract(1, "day")}
        Wstałem na daily`,
    },
    {
      date: dayjs().subtract(1, "day"),
      description: "2 Wstałem na daily",
      sourceText: `${dayjs().subtract(1, "day")}
        Wstałem na daily`,
    },
  ],
};

export const mockEmptyOcredModel: OcrModel = {
  techStack: [],
  projects: [],
  educations: [],
  accomplishments: [],
};
