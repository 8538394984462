import { AxiosResponse } from "axios";
import { DICTIONARIES, DictionariesService } from "../../dictionaries";
import APIService from "../api/skills-api";
import {
  domainFulfillmentToAPI,
  domainTemplateFromAPI,
  domainTemplateToAPI,
  fulfillmentFromAPI,
  FulfillmentModel,
  NewTechnologyRecord,
  professionTemplateFromAPI,
  professionTemplateToAPI,
  UpdateDomainTemplateAPIModel,
  UpdateProfessionTemplateAPIModel,
} from "../models";
import { DOMAINS_PROFESSION_ID } from "../shared/consts";
import { handleError } from "../../shared";

const SkillsService = (() => {
  const updateFulfillments = async (
    professionId: string,
    newDictEntries: NewTechnologyRecord[],
    currentFulfillment: FulfillmentModel,
  ) => {
    try {
      const isUpdatingDomain = professionId === DOMAINS_PROFESSION_ID;
      const dictionary = isUpdatingDomain
        ? DICTIONARIES.DOMAIN
        : DICTIONARIES.TECHNOLOGY;
      const addedTechnologies = await Promise.allSettled(
        newDictEntries.map(
          ({ name }) =>
            DictionariesService.createDictionaryEntry(dictionary, {
              name,
              desc: name,
            }) as Promise<AxiosResponse<{ id: number }>>,
        ),
      );
      if (isUpdatingDomain)
        return APIService.API.updateAuthedUserDomainMatrixFulfillment(
          domainFulfillmentToAPI(
            newDictEntries,
            currentFulfillment,
            addedTechnologies,
          ),
        );
      return APIService.API.partiallyUpdateAuthedUserSkillMatrixFulfillment(
        professionId,
        { ratings: currentFulfillment },
      );
    } catch (error) {
      return handleError(error);
    }
  };

  const getFulfillmentForProfession = async (
    professionId: string,
    silentMode = false,
  ) => {
    try {
      const result = await APIService.API.getAuthedUserSkillMatrixFulfillment(
        professionId,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        { silentMode },
      );
      if (result?.data) return fulfillmentFromAPI(professionId)(result.data);
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const getFulfillmentForDomain = async (silentMode = false) => {
    try {
      const result = await APIService.API.getAuthedUserDomainMatrixFulfillment({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        silentMode,
      });
      if (result?.data)
        return fulfillmentFromAPI(DOMAINS_PROFESSION_ID)({
          professionId: DOMAINS_PROFESSION_ID,
          ...result.data,
        });
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const getDomainTemplate = async (domainGroup: string, silentMode = false) => {
    try {
      const result = await APIService.API.getDomainMatrixTemplate({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        silentMode,
      });
      if (result?.data) return domainTemplateFromAPI(result.data, domainGroup);
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const getProfessionTemplate = async (
    professionId: string,
    silentMode = false,
  ) => {
    try {
      const result = await APIService.API.getSkillMatrixTemplate(professionId, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        silentMode,
      });
      if (result?.data)
        return professionTemplateFromAPI(result?.data, professionId);
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const updateDomainTemplate = async (data: UpdateDomainTemplateAPIModel) => {
    try {
      return APIService.API.updateDomainMatrixTemplate(
        domainTemplateToAPI(data),
      );
    } catch (e) {
      return handleError(e);
    }
  };

  const updateProfessionTemplate = async (
    professionId: string,
    data: UpdateProfessionTemplateAPIModel,
  ) => {
    try {
      return APIService.API.updateSkillMatrixTemplate(
        professionId,
        professionTemplateToAPI(data),
      );
    } catch (e) {
      return handleError(e);
    }
  };

  return {
    updateFulfillments,
    getFulfillmentForProfession,
    getFulfillmentForDomain,
    getDomainTemplate,
    getProfessionTemplate,
    updateDomainTemplate,
    updateProfessionTemplate,
  };
})();

export default SkillsService;
