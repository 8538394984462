import { removeDiacriticsFromString } from "@ps/utils";
import {
  LOCATION,
  PROFESSION,
  SENIORITY,
} from "../../../../../../folder-domain/constants";
import { IModalFilters } from "./types";
import { PROFESSIONS } from "../../../../../constants";
import { ProfilesSummaryListModel } from "../../../../../../profile-domain";
import { IExtendedTeamMemberModel } from "../types";

export const prepareFilteredUsers = (
  profiles: ProfilesSummaryListModel[],
  searchValue: string,
  filters: IModalFilters,
  search: (str: string) => unknown[],
): ProfilesSummaryListModel[] => {
  const temp = JSON.parse(JSON.stringify(profiles));

  const preparedToFilter = !searchValue?.length
    ? temp
    : search(removeDiacriticsFromString(searchValue.toLowerCase())).reverse();

  if (!filters || !Object.values(filters).filter((item) => item)?.length) {
    return preparedToFilter;
  }

  let result = preparedToFilter;

  if (filters[LOCATION] && result.length) {
    result = result.filter(
      (profile: ProfilesSummaryListModel) =>
        profile?.residence?.value === filters[LOCATION]?.value,
    );
  }

  if (filters[SENIORITY] && result.length) {
    result = result.filter(
      (profile: ProfilesSummaryListModel) =>
        profile?.[SENIORITY] === filters[SENIORITY]?.value,
    );
  }

  if (filters[PROFESSION] && result.length) {
    result = result.filter(
      (profile: ProfilesSummaryListModel) =>
        profile?.[PROFESSIONS]?.[0] === filters[PROFESSION]?.value,
    );
  }
  return result;
};

export const removeIntersections = (
  mainList: ProfilesSummaryListModel[],
  compareList: IExtendedTeamMemberModel[],
): ProfilesSummaryListModel[] =>
  mainList?.reduce(
    (acc: ProfilesSummaryListModel[], mainItem: ProfilesSummaryListModel) =>
      compareList.some(
        (compareItem: IExtendedTeamMemberModel) =>
          mainItem.userId === compareItem.userId,
      )
        ? acc
        : [...acc, mainItem],
    [],
  );
