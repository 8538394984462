/* eslint @typescript-eslint/no-explicit-any: 0 */
import axios, { AxiosError } from "axios";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const handleError = (error: any): string | any => {
  if (axios.isAxiosError(error)) {
    const serverError = error as AxiosError;
    if (serverError && serverError?.response) {
      return serverError.response;
    }
  }
  return { error: `Something went wrong! Details: ${error}` };
};
// eslint-disable-next-line import/prefer-default-export
export { handleError };
