import { ReactElement, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { useMappedStateSelector } from "../../../hooks";
import { fetchProjectsList } from "../../store";
import { AllProjectsMapState, AllProjectsMapStateReturn } from "./types";
import { ProjectsListWrapper } from "../common";
import { LoadingMainPage } from "@ps/ui-components";

const AllProjects = (): ReactElement => {
  const dispatch = useDispatch();

  const mapState = (state: AllProjectsMapState): AllProjectsMapStateReturn => ({
    projectsList: state.projects.projectsList,
    isLoading: state?.requestStatus?.isFetchingProjectsList,
  });

  const { projectsList, isLoading } = useMappedStateSelector(mapState);

  useEffect(() => {
    fetchProjectsList(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading)
    return <LoadingMainPage additionalClassName="bg-neutralPrimary-85" />;
  return <ProjectsListWrapper projectsList={projectsList} />;
};

export default AllProjects;
