import { Dayjs } from "dayjs";
import { ReactElement } from "react";
import DateFormats from "../helpers/dateFormats";
import { getEndOfDecade, getStartOfDecade } from "../helpers/time/getDecade";
import { CalendarDisplayModes } from "../types";

const buttonStyle = `
text-neutralPrimary-20 font-normal hover:text-primary-50 hover:text-opacity-80 
`;

interface TopDateDisplayProps {
  currentDisplayMode: CalendarDisplayModes;
  currentDisplayedMonth?: Dayjs;
  setDisplayMode: (displayMode: CalendarDisplayModes) => void;
}

const TopDateDisplay = ({
  currentDisplayMode,
  currentDisplayedMonth,
  setDisplayMode,
}: TopDateDisplayProps): ReactElement => {
  const setDisplayModeMonths = () =>
    setDisplayMode(CalendarDisplayModes.MONTHS);
  const setDisplayModeYears = () => setDisplayMode(CalendarDisplayModes.YEARS);

  switch (currentDisplayMode) {
    case CalendarDisplayModes.DAYS:
    case CalendarDisplayModes.WEEKS:
      return (
        <span>
          <button
            className={`${buttonStyle} mr-1`}
            onClick={setDisplayModeMonths}
          >
            {currentDisplayedMonth
              ?.format(DateFormats.MONTH_FULL)
              .toUpperCase()}
          </button>
          <button className={buttonStyle} onClick={setDisplayModeYears}>
            {currentDisplayedMonth?.format(DateFormats.YEAR)}
          </button>
        </span>
      );
    case CalendarDisplayModes.MONTHS:
    case CalendarDisplayModes.QUARTERS:
      return (
        <button className={buttonStyle} onClick={setDisplayModeYears}>
          {currentDisplayedMonth?.format(DateFormats.YEAR)}
        </button>
      );
    case CalendarDisplayModes.YEARS:
      return (
        <button className={buttonStyle} onClick={setDisplayModeYears}>
          {`${getStartOfDecade(currentDisplayedMonth).format(
            DateFormats.YEAR,
          )}-${getEndOfDecade(currentDisplayedMonth).format(DateFormats.YEAR)}`}
        </button>
      );
    default:
      return <></>;
  }
};

export default TopDateDisplay;
