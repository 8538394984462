import { AnyAction } from "redux";
import { actionCreator } from "@ps/utils";
import ActionTypes from "./actionTypes";

export const startUploadCVList = (): AnyAction =>
  actionCreator(ActionTypes.START_UPLOAD_CV_LIST);

export const successUploadCVList = (): AnyAction =>
  actionCreator(ActionTypes.SUCCESS_UPLOAD_CV_LIST);

export const errorUploadCVList = (): AnyAction =>
  actionCreator(ActionTypes.END_UPLOAD_CV_LIST);
