export const NAMESPACE = "pdf-generator";

export const OUTLINE = "outline";
export const ROLE = "role";
export const EXPERIENCE_TIME = "experienceTime";
export const BRIEFCASE = "briefcase";
export const LANGUAGES = "languages";
export const PROFILE = "profile";
export const SKILLS = "skills";
export const WORK_EXPERIENCE = "workExperience";
export const CONTRIBUTIONS = "contributions";
export const CERTIFICATES = "certificates";
export const EDUCATION = "education";

export const DATE_FORMAT = "DD.MM.YYYY";
