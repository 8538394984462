import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../shared/constants";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useTranslationWithNamespace(namespace = NAMESPACE): any {
  const { t } = useTranslation(namespace);
  return { t };
}

export default useTranslationWithNamespace;
