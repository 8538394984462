import { useState, useCallback, ReactElement } from "react";
import Fade from "../fade";
import ClickElement from "./clickElement";
import Row from "./row";
import { ActiveSelectProps, Options } from "./types";

const ActiveSelect = ({
  options,
  activeAmount,
  label,
  onOptionClick,
  width,
  dataCy,
}: ActiveSelectProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const [rect, setRect] = useState<DOMRect>();

  const clickElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        setRect(node.getBoundingClientRect());
      }
    },
    [setRect],
  );

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);
  return (
    <Fade
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
      clickElement={
        <ClickElement
          open={open}
          label={label}
          width={width}
          dataCy={dataCy}
          ref={clickElementRef}
          activeAmount={activeAmount}
        />
      }
      contentWrapperClassName="top-10 z-50"
    >
      <div
        style={{ width: `${rect?.width}px` }}
        className="bg-neutralPrimary-100 flex flex-col gap-y-4 rounded-lg border border-neutralPrimary-40 pl-5 pr-4 py-4 text-neutralPrimary-20"
      >
        {options.map((o: Options) => (
          <Row
            id={o.id}
            key={o.id}
            label={o.label}
            amount={o.amount}
            checked={o.checked}
            onClick={onOptionClick}
          />
        ))}
      </div>
    </Fade>
  );
};

export default ActiveSelect;
