import { ReactElement, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { EmptyList, ScrollableWrapper } from "@ps/ui-components";
import { sortArray } from "@ps/utils";
import SearchBar from "./searchBar";
import ProjectRow from "./projectRow";
import SortBar from "./sortBar";
import PillsRow from "./pillsRow";
import {
  IFilters,
  ExtendedProjectModel,
  SorterParametersProps,
  ProjectsListWrapperProps,
  ProjectCheckers,
  FilterItem,
  PillData,
} from "./types";
import {
  extendProjects,
  filterProjects,
  filterProjectsByStatus,
  filtersToPills,
} from "./utils";
import { SessionStorage } from "../../../shared";
import {
  ASC,
  CREATION_DATE,
  DETAILS,
  PROJECT,
  PROJECTS,
  initialCheckersState,
  initialFiltersState,
} from "../../constants";
import { useSessionStorage, useTranslationWithNamespace } from "../../../hooks";
import {
  SESSION_SORTERS,
  initialSessionSortersState,
} from "../../../shared/constants";
import { ReactComponent as EmptyImage } from "../../../images/project/emptyList.svg";

const ProjectsListWrapper = ({
  projectsList,
  clientId,
}: ProjectsListWrapperProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const extendedProjects = extendProjects(projectsList);
  const [manipulatedProjects, setManipulatedProjects] = useState<
    ExtendedProjectModel[]
  >(extendProjects(projectsList));

  const [sorterParameters, setSorterParameters] =
    useState<SorterParametersProps>({
      order: ASC,
      name: CREATION_DATE,
    });

  const [storageSorter, setStorageSorter] = useSessionStorage<SessionStorage>(
    SESSION_SORTERS,
    initialSessionSortersState,
  );

  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState<IFilters>(initialFiltersState);
  const [pills, setPills] = useState<PillData[]>([]);

  const [projectsCheckers, setProjectsCheckers] =
    useState<ProjectCheckers>(initialCheckersState);

  const prepareProperProjectsList = (
    search: string,
    projectFilters: IFilters,
  ): void => {
    const { projects: projectsSorter } = storageSorter.sessionSorters;
    const properSorter: {
      name: string;
      order: string;
      nestedFieldName?: string;
    } = projectsSorter?.name?.length ? projectsSorter : sorterParameters;

    const resultByStatus = filterProjectsByStatus(
      extendedProjects,
      projectsCheckers,
    );
    const resultByFilter = filterProjects(
      resultByStatus,
      search,
      projectFilters,
    );
    const resultBySorter = sortArray(
      resultByFilter,
      properSorter?.name as keyof ExtendedProjectModel,
      properSorter?.order,
      properSorter?.nestedFieldName as keyof ExtendedProjectModel,
    );

    setManipulatedProjects(resultBySorter);
  };

  useEffect(() => {
    prepareProperProjectsList(searchValue, filters);
  }, [
    searchValue,
    projectsList,
    projectsCheckers,
    sorterParameters.name,
    sorterParameters.order,
  ]);

  const handleOnFilterClick = (
    isChecked: boolean,
    filterItem: FilterItem,
    filterName: string,
  ): void => {
    const prevFilters = JSON.parse(JSON.stringify(filters));

    const newFiltersState: IFilters = isChecked
      ? {
          ...prevFilters,
          [filterName]: prevFilters[filterName]?.filter(
            (item: FilterItem) => item.id !== filterItem.id,
          ),
        }
      : {
          ...prevFilters,
          [filterName]: [...prevFilters[filterName], filterItem],
        };

    setFilters(newFiltersState);
    setPills(filtersToPills(newFiltersState));
    prepareProperProjectsList(searchValue, newFiltersState);
  };

  const handleOnSearchValue = (newValue: string): void => {
    setSearchValue(newValue);
    prepareProperProjectsList(newValue, filters);
  };

  const handleOnClickSorter = (
    fieldName: string,
    order: string,
    isNestedField?: boolean,
  ): void => {
    const nestedFieldName = isNestedField ? DETAILS : undefined;

    setStorageSorter((prevData) => ({
      ...prevData,
      sessionSorters: {
        ...prevData.sessionSorters,
        [PROJECTS]: {
          name: fieldName,
          order,
          nestedFieldName,
        },
      },
    }));

    setSorterParameters({ name: fieldName, order });
  };

  const handleOnClearFilters = (): void => {
    setFilters(initialFiltersState);
    setPills([]);
    prepareProperProjectsList(searchValue, initialFiltersState);
  };

  const archiveAmount = extendedProjects.filter(
    (item: ExtendedProjectModel) => item.archived,
  ).length;

  const privateAmount = extendedProjects.filter(
    (item: ExtendedProjectModel) => !item.public && !item.archived,
  ).length;

  const publicAmount = extendedProjects.filter(
    (item: ExtendedProjectModel) => item.public && !item.archived,
  ).length;

  const activeAmount = publicAmount + privateAmount;

  return (
    <div className="flex flex-col gap-y-8 w-full h-full">
      <SearchBar
        clientId={clientId}
        searchValue={searchValue}
        setSearchValue={handleOnSearchValue}
        activeAmount={activeAmount}
        archivedAmount={archiveAmount}
        privateAmount={privateAmount}
        publicAmount={publicAmount}
        clearFilters={handleOnClearFilters}
        sorterParameters={
          storageSorter?.sessionSorters?.projects?.name?.length
            ? storageSorter?.sessionSorters?.projects
            : sorterParameters
        }
        onSorterClick={handleOnClickSorter}
        projectCheckers={projectsCheckers}
        setProjectCheckers={setProjectsCheckers}
        disabledClearFilters={
          !Object.values(filters).some((item: string[]) => item?.length)
        }
      />
      {pills.length ? (
        <PillsRow pills={pills} onRemovePillClick={handleOnFilterClick} />
      ) : null}
      <ScrollableWrapper>
        {manipulatedProjects?.length ? (
          <div className="w-full h-full">
            <SortBar
              clientId={clientId}
              filters={filters}
              projects={filterProjectsByStatus(
                extendedProjects,
                projectsCheckers,
              )}
              updateFilters={handleOnFilterClick}
            />
            <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
              <div className="grid">
                <div className="flex flex-col w-full">
                  {manipulatedProjects.map((project: ExtendedProjectModel) => (
                    <ProjectRow key={project.id} project={project} />
                  ))}
                </div>
              </div>
            </Scrollbars>
          </div>
        ) : (
          <EmptyList
            searchValue={searchValue}
            text={t(`${PROJECT}.list.noProjectsTitle`)}
            Image={<EmptyImage />}
          />
        )}
      </ScrollableWrapper>
    </div>
  );
};

export default ProjectsListWrapper;
