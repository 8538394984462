import {
  LOCATION,
  PROFESSION,
  PROFESSIONS,
  SENIORITY,
  ACTIVE,
  INACTIVE,
} from "../../../../constants";

export const modalFiltersInitialState = {
  [LOCATION]: null,
  [PROFESSION]: null,
  [SENIORITY]: null,
};

export const initialFiltersState = {
  [PROFESSIONS]: [],
  [SENIORITY]: [],
  [LOCATION]: [],
};

export const initialCheckersState = {
  [ACTIVE]: true,
  [INACTIVE]: false,
};

export const BASE_PATH = "project.team";
