/* eslint @typescript-eslint/no-explicit-any: 0 */
import { TFunction } from "react-i18next";
import {
  BASE_TRANSLATION_PATH,
  ERRORS,
  HOLIDAYS_LIST,
  NO_LIMIT_THRESHOLD,
} from "../constants";
import { ValidatePayRatePercentageReturn } from "./types";

const BASE_PATH = `${BASE_TRANSLATION_PATH}.${HOLIDAYS_LIST}`;

export const checkIfStringUniqInArrayOfObjects = <T>(
  indexOfValue: number,
  value: string,
  key: string,
  additionalPredicate = true,
  array: Array<T>,
): boolean =>
  array
    .filter((_: T, index: number): boolean => indexOfValue !== index)
    .some(
      (mappedValues: T): boolean =>
        mappedValues[key]?.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase().trim() && additionalPredicate,
    ) && additionalPredicate;

export const validateNumberOfDaysOff = (
  value: string,
  watch: (val: string) => unknown,
  t: TFunction<string>,
): boolean | string => {
  if (watch("holiday.noLimit")) return true;

  const numberValue = parseInt(value, 10);

  if (!value) {
    return t(`${BASE_TRANSLATION_PATH}.${HOLIDAYS_LIST}.${ERRORS}.isRequired`);
  }

  if (!Number.isNaN(numberValue) && value?.toString()?.indexOf(".") !== -1)
    return t(`${BASE_TRANSLATION_PATH}.${HOLIDAYS_LIST}.${ERRORS}.onlyWhole`, {
      from: 1,
      to: NO_LIMIT_THRESHOLD,
    });

  if (numberValue > NO_LIMIT_THRESHOLD)
    return t(
      `${BASE_TRANSLATION_PATH}.${HOLIDAYS_LIST}.${ERRORS}.daysOff.length`,
      { degreeOf: "less", length: NO_LIMIT_THRESHOLD },
    );

  if (numberValue < 1)
    return t(
      `${BASE_TRANSLATION_PATH}.${HOLIDAYS_LIST}.${ERRORS}.daysOff.length`,
      { degreeOf: "more", length: "0" },
    );

  return true;
};

export const validatePayRatePercentage = (
  t: TFunction,
): ValidatePayRatePercentageReturn => ({
  required: {
    value: true,
    message: t(`${BASE_PATH}.${ERRORS}.isRequired`),
  },
  max: {
    value: 100,
    message: t(`${BASE_PATH}.${ERRORS}.rate.value`, {
      degreeOf: t(`${BASE_PATH}.less`),
      percentage: 100,
    }),
  },
  min: {
    value: 0,
    message: t(`${BASE_PATH}.${ERRORS}.rate.value`, {
      degreeOf: t(`${BASE_PATH}.more`),
      percentage: 0,
    }),
  },
  validate: (value: string) => {
    if (
      value &&
      !Number.isNaN(parseInt(value, 10)) &&
      value?.toString()?.indexOf(".") !== -1
    )
      return t(
        `${BASE_TRANSLATION_PATH}.${HOLIDAYS_LIST}.${ERRORS}.onlyWhole`,
        { from: 0, to: 100 },
      );
    return true;
  },
});
