import axios from "axios";
import APIService from "../api/profile-api";
import { handleError } from "../../shared";
import {
  fromAPIModel,
  fromOtherProfileFulfillmentToModel,
  fromPreviewFulfillmentToModel,
  profilesSummaryListModel,
} from "../models";
import { DictionaryModel } from "../../dictionaries";

const ProfileService = (() => {
  const fetchProfile = async (id: string) => {
    const response = await APIService.API.getProfile(id);

    if (response.data) return fromAPIModel(response.data, []);
    return {};
  };

  const fetchProfilesList = async () => {
    try {
      const response = await APIService.API.getProfileSummaries();
      if (response?.data) return profilesSummaryListModel(response.data);
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchSpecialistProfilesList = async () => {
    try {
      const response = await APIService.API.getSpecialistsProfileSummaries();
      if (response?.data) return profilesSummaryListModel(response.data);
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchProfileWithFulfillment = async (
    userId: string,
    technologyDict: DictionaryModel,
  ) => {
    try {
      const profileResponse = await APIService.API.getProfile(userId);
      const fulfillmentResponse =
        await APIService.API.getProfilePreviewFulfillments(userId);

      if (profileResponse?.data && fulfillmentResponse?.data)
        return {
          userId,
          data: {
            ...fromOtherProfileFulfillmentToModel(
              profileResponse.data,
              technologyDict,
            ),
            ...fromPreviewFulfillmentToModel(
              fulfillmentResponse.data,
              profileResponse?.data?.professions?.main || "",
            ),
          },
        };
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchBase64Avatar = async (url: string) => {
    try {
      const response = await axios.get(url, {
        responseType: "arraybuffer",
        params: {
          d:
            Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) +
            1000000000,
        },
      });
      const imageBase64 = Buffer.from(response.data).toString("base64");
      return `data:${response.headers["content-type"]};base64, ${imageBase64}`;
    } catch (error) {
      handleError(error);
    }
    return undefined;
  };

  return {
    fetchBase64Avatar,
    fetchProfile,
    fetchProfilesList,
    fetchProfileWithFulfillment,
    fetchSpecialistProfilesList,
  };
})();

export default ProfileService;
