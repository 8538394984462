import { ReactElement } from "react";
import { Empty2 } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../hooks";
import OtherSummaryHeader from "./otherSummaryHeader";
import { ReactComponent as SkillsIcon } from "../../../../images/profile-summary/skills.svg";
import { ExpProps } from "../types";

const Skills = ({
  children,
  filled,
  onAddOrEditClick,
}: ExpProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="w-full flex flex-col gap-y-4">
      <OtherSummaryHeader
        title={t("profile.skills.skills")}
        Icon={<SkillsIcon className="text-neutralSecondary-41" />}
        withButton
        filled={filled}
        onButtonClick={onAddOrEditClick}
      />
      {filled ? (
        children
      ) : (
        <Empty2
          section={t("profile.skills.skills")}
          onClick={onAddOrEditClick}
        />
      )}
    </div>
  );
};

export default Skills;
