export const PROSPECT = "prospect";
export const FOLDER = "folder";
export const USER_CARD = "userCard";
export const PROSPECT_FOLDER = `${PROSPECT}_${FOLDER}`;
export const PROSPECT_FOLDER_USERS_MODAL = `${PROSPECT_FOLDER}_usersModal`;
export const PROSPECT_FOLDER_USER_CARD = `${PROSPECT_FOLDER}_${USER_CARD}`;
export const PROSPECT_FOLDER_TEAM_MANUALLY = `${PROSPECT_FOLDER}__team_manually`;
export const PROSPECT_FOLDER_TEAM_ML = `${PROSPECT_FOLDER}_team_ml`;
export const FOLDER_CANDIDATE_AVATAR = "folder_candidate_icon";
export const FOLDER_CANDIDATE_COUNTRY = "folder_candidate_country";
export const FOLDER_CANDIDATE_PROFESSION = "folder_candidate_profession";
export const FOLDER_CANDIDATE_SENIORITY = "folder_candidate_seniority";
export const FOLDER_CANDIDATE_REMOVE_MODAL = "folder_candidate_remove_modal";
export const FOLDER_CANDIDATE_REMOVE_BUTTON = "folder_candidate_remove_button";
export const EDIT_NOTE = `${PROSPECT}_edit_note`;
export const DELETE_NOTE = `${PROSPECT}_delete_note`;
