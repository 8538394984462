export const FOLDER_ADD_RESUMES_MODAL = "folder_add_resumes_modal";
export const FOLDER_CANDIDATE = "folder_candidate";
export const FOLDER_CANDIDATE_AVATAR = "folder_candidate_icon";
export const FOLDER_CANDIDATE_COUNTRY = "folder_candidate_country";
export const FOLDER_CANDIDATE_PROFESSION = "folder_candidate_profession";
export const FOLDER_CANDIDATE_REMOVE_BUTTON = "folder_candidate_remove_button";
export const FOLDER_CANDIDATE_REMOVE_MODAL = "folder_candidate_remove_modal";
export const FOLDER_CANDIDATE_SENIORITY = "folder_candidate_seniority";
export const FOLDER_USER_CARD = "folder_user_card";
export const FOLDER_USERS_MODAL = "folder_user_card";
export const FOLDER_CANDIATES = "folder_candidates";
export const AVATAR = "avatar";
