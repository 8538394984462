import { ReactElement } from "react";
import { Button, Modal, ModalContent, useThemeMode } from "@ps/ui-components";
import { ReactComponent as SaveGraphic } from "../../../images/finance/holidays/saveModalGraphic.svg";
import { ReactComponent as SaveGraphicHighContrast } from "../../../images/finance/holidays/saveModalGraphicHighContrast.svg";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import {
  BASE_TRANSLATION_PATH,
  HOLIDAYS_TEMPLATES,
  CONFIRM_MODAL,
  TEMP_TEMPLATE_ID,
  SAVE,
  CANCEL,
  CREATE,
  TEMP_HOLIDAY_ID,
} from "../constants";
import { ConfirmModalProps, ConfirmModalMapState } from "./types";

const ConfirmModal = ({
  isOpen,
  onCancel,
  onSave,
}: ConfirmModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();

  const mapState = (state: ConfirmModalMapState): ConfirmModalMapState => ({
    editMode: state?.editMode,
  });

  const { editMode } = useMappedStateSelector(mapState);

  const translate = (field: string): string =>
    t(`${BASE_TRANSLATION_PATH}.${CONFIRM_MODAL}.${field}`);

  const isCreatingNewData: boolean =
    editMode &&
    (editMode.includes(TEMP_TEMPLATE_ID) || editMode.includes(TEMP_HOLIDAY_ID));

  return (
    <Modal
      dataCy={`${HOLIDAYS_TEMPLATES}_${CONFIRM_MODAL}`}
      isOpen={isOpen}
      onClose={onCancel}
      height="h-auto"
      width="w-max"
      withCloseIcon
      withOutsideClick
    >
      <ModalContent additionalClassName="flex flex-row gap-16 py-8 w-full">
        {isHighContrast ? (
          <SaveGraphicHighContrast className="w-1/3" />
        ) : (
          <SaveGraphic className="w-1/3" />
        )}
        <div className="flex flex-col gap-2 justify-center items-start pt-2">
          <span className="text-2xl font-semibold whitespace-nowrap">
            {translate(isCreatingNewData ? "createTitle" : "saveTitle")}
          </span>

          {isCreatingNewData ? (
            <></>
          ) : (
            <span className="text-sm">{translate("secondaryTextSave")}</span>
          )}

          <div className="flex gap-4 pt-2">
            <Button
              dataCy={`${HOLIDAYS_TEMPLATES}_${CONFIRM_MODAL}_${SAVE}`}
              onClick={onSave}
            >
              {translate(isCreatingNewData ? CREATE : SAVE)}
            </Button>

            <Button
              dataCy={`${HOLIDAYS_TEMPLATES}_${CONFIRM_MODAL}_${CANCEL}`}
              variant="secondary"
              onClick={onCancel}
            >
              {translate(CANCEL)}
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
