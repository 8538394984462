export const ADD_NEW_PROSPECT = "addNewProspect";
export const DETAILS = "details";
export const NEEDS = "needs";
export const REQUIREMENTS = "requirements";
export const NOTES = "notes";
export const DISCOVERY_TEAM = "discoveryTeam";
export const PROPOSAL_FOLDER = "proposalFolder";

export const PROSPECT = "prospect";
export const PROSPECT_EDIT = `${PROSPECT}_edit`;
export const PROSPECT_VIEW = `${PROSPECT}_view`;
export const PROSPECT_REQUIREMENT = `${PROSPECT}Requirement`;

export const PROSPECT_NAME = "prospectName";
export const GENERAL_NOTE = "generalNote";
export const CLIENTS_NAME = "clientsName";
export const CONTRACTORS_INFO = "contractorsInfo";
export const RESPONSIBILITIES = "responsibilities";
export const RESIDENCE = "residence";

export const CLIENT_LABEL = "clientLabel";
export const CLIENT_PLACEHOLDER = "clientPlaceholder";
export const CLIENT_NAME = "clientName";
export const CONTACT_PERSON_NAME = "contactPersonName";
export const CONTACT_PERSON_EMAIL = "contactPersonEmail";
export const PROSPECT_COUNTRY = "prospectCountry";
export const PROSPECT_CITY = "prospectCity";
export const PHONE_NUMBER = "phoneNumber";
export const DOMAINS = "domains";

export const PM = "pm";
export const BD = "bd";
export const PROJECT_MANAGER = "projectManager";
export const BUSINESS_DEVELOPER = "businessDeveloper";
export const TECH_OWNER = "techOwner";
export const NOTE = "note";

export const ADD_NEW_CLIENT = "addNewClient";
export const ADD = "add";
export const CREATE_NEW_CLIENT = "createNewClient";
export const UPDATE_PROSPECT = "updateProspect";
export const PROJECT_STATUS = "projectStatus";
export const CREATE = "create";

export const PROFESSION = "profession";
export const PROFESSIONS = "professions";
export const SENIORITY = "seniority";
export const DEMAND = "demand";
export const QUANTITY = "quantity";
export const CURRENCY = "currency";
export const TIME_UNIT = "timeUnit";
export const EXPECTED_RATE = "expectedRate";
export const RATE_UNIT = "rateUnit";
export const RATE = "rate";
export const ENGAGEMENT = "engagement";
export const MUST_HAVE_TECH_STACK = "mustHaveTechStack";
export const NICE_TO_HAVE_TECH_STACK = "niceToHaveTechStack";
export const REGIONS = "regions";
export const OVERLAP = "overlap";
export const COMFORTABLE_HOURS = "comfortableHours";
export const POSSIBLE_HOURS = "possibleHours";
export const WORKING_TIME = "workingTime";
export const CORE_TIME = "coreTime";
export const START = "start";
export const END = "end";
export const ZONE = "zone";
export const REQUIREMENT = "requirement";
export const BLACKLISTED_COUNTRIES = "blacklistedCountries";
export const COUNTRIES = "countries";
export const CONTINENTS = "continents";
export const EXPECTED_LANGUAGE = "expectedLanguage";

export const AUSTRALIA = "OC";
export const NORTH_AMERICA = "NA";
export const SOUTH_AMERICA = "SA";
export const AFRICA = "AF";
export const ASIA = "AS";
export const EUROPE = "EU";

export const PRIORITY = "priority";
export const TITLE = "title";
export const DESCRIPTION = "description";
export const NOTES_VIEW = `${NOTES}_view`;
export const NOTES_EDIT = `${NOTES}_edit`;

export const SEARCH = "search";
export const USER_ICON = "userIcon";
export const WORKING_HOURS = "workingHours";
export const TIME_ZONE = "timeZone";
export const WORKING_STATUS = "workingStatus";
export const ROLE = "role";
export const EXPERIENCE = "experience";
export const SKILLS_COVERAGE = "skillsCoverage";
export const LOCATION = "location";
export const LANGUAGES = "languages";
export const WORK = "work";
export const AVATAR = "avatar";

export const SPECIALIST = "specialist";

export const DISABLED_ERROR = "disabledError";

export const FILTERS = "filters";
export const PILL = "pill";
export const PROJECT = "project";
export const PROJECTS = "projects";
export const ACTIVE = "active";
export const ARCHIVED = "archived";
export const ASC = "asc";
export const DESC = "desc";
export const MAIN = "mainProfession";
export const USER_NAME = "userName";
export const VALUE = "value";
export const COUNTRY = "country";
export const SPECIALISTS = "specialists";
export const INFO = "info";
export const AVAILABILITY = "availability";
export const PASSPORTS = "passports";
export const VISAS = "visas";
export const PASSPORT = "passport";
export const EXPIRATION_DATE = "expirationDate";
export const NATIONALITY = "nationality";
export const SKILL = "skill";
export const ENJOYMENT = "enjoyment";
export const LANGUAGE_ADAPTABILITY = "languageAdaptability";
export const TIME_ADAPTABILITY = "timeAdaptability";
export const COST = "cost";
export const SCORE = "score";

export const NEW_ITEMS = {
  [NOTES]: {
    id: "",
    title: "",
    priority: "",
    creatorId: "",
    createDate: undefined,
    updateDate: undefined,
    description: "",
  },
  [NEEDS]: {
    profession: "",
    seniority: "",
    quantity: null,
    expectedRate: {
      rate: null,
      rateSpan: "",
      currency: "",
      engagementSpan: "",
    },
    mustHaveTechStack: [],
    niceToHaveTechStack: [],
    workingTime: {
      coreTime: {
        start: "",
        end: "",
      },
      overlap: null,
      zone: "",
    },
    expectedLanguage: "en",
  },
};

export const engagementDict = [
  { id: "full", name: "full time", deleted: false },
  { id: "part", name: "part time", deleted: false },
];

export const rateUnitsDict = [
  { id: "hour", name: "hour", deleted: false },
  { id: "day", name: "day", deleted: false },
  { id: "week", name: "week", deleted: false },
  { id: "month", name: "month", deleted: false },
  { id: "year", name: "year", deleted: false },
];

export const currencyDict = [
  { id: "GBP", name: "GBP", deleted: false },
  { id: "USD", name: "USD", deleted: false },
  { id: "PLN", name: "PLN", deleted: false },
  { id: "EUR", name: "EUR", deleted: false },
];

export const EMAIL_REGEXP_PATTERN = new RegExp(
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const initialFiltersState = {
  [PROFESSIONS]: [],
  [SENIORITY]: [],
  [RESIDENCE]: [],
};
