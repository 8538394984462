import { ReactElement, Suspense } from "react";
import { useMappedState } from "redux-react-hook";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import {
  Clients,
  SkillsMatrix,
  Finance,
  Folders,
  hhPermissions,
  Profile,
  Project,
  Prospects,
  Team,
} from "@ps/hh";
import { Page404, PermissionProvider } from "@ps/ui-components";
import { TimeTracker } from "@ps/time-tracker";
import { NotificationsContainer } from "@ps/notifications";
import { Permissions, permissionsPermissions } from "@ps/permissions";
import AppContainer from "app/main";
import Auth from "app/auth";
import { PAGE_404_APP } from "./shared/data-cy";
import KeycloakProvider from "./app/auth/keycloakProvider";
import RestrictedRoute from "./shared/components/restrictedRoute";
import { AppMapState, AppMapStateReturn } from "./types";
import { ENABLED_FEATURES } from "./tenant-domain";

const App = (): ReactElement => {
  const mapState = (state: AppMapState): AppMapStateReturn => ({
    isVisibleForTenant: state.tenantMetadata?.enabledFeatures?.includes(
      ENABLED_FEATURES.FRONTEND_SHOW_ALL_MODULES,
    ),
    isFetchingData: state.requestStatus?.isTenantMetadataFetching,
    permissions: state?.permissions,
  });

  const { permissions, isVisibleForTenant, isFetchingData } =
    useMappedState(mapState);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router
        getUserConfirmation={(message, callback) => {
          /* eslint-disable no-alert */
          const allowTransition = window.confirm(message);
          callback(allowTransition);
        }}
      >
        <KeycloakProvider>
          <NotificationsContainer />
          <Auth>
            <PermissionProvider allPermissionsList={permissions}>
              <AppContainer>
                <Switch>
                  <Route exact path="/">
                    <Redirect push to="/profile/personal-info" />
                  </Route>
                  <Route path="/main/:section?/:slug?">
                    <Redirect to="/profile/personal-info" />
                  </Route>
                  <Route path="/profile">
                    <Profile />
                  </Route>
                  <RestrictedRoute
                    path="/team"
                    permissions={[
                      hhPermissions.USERS_LIST_ALL,
                      hhPermissions.MATRIX_FULFILLMENTS,
                    ]}
                  >
                    <Team />
                  </RestrictedRoute>
                  <RestrictedRoute
                    path="/prospect"
                    permissions={[
                      hhPermissions.PROSPECT_FULL_ACCESS,
                      hhPermissions.PROSPECT_LIST_ALL,
                    ]}
                  >
                    <Prospects />
                  </RestrictedRoute>
                  <RestrictedRoute
                    path="/members"
                    permissions={[
                      permissionsPermissions.ROLES_MANAGE,
                      hhPermissions.USERS_LIST_ALL,
                      hhPermissions.USERS_ASSIGN_ROLES,
                    ]}
                  >
                    <Permissions />
                  </RestrictedRoute>
                  <RestrictedRoute
                    path="/skills-matrix"
                    permissions={[hhPermissions.MATRIX_MANAGE_TEMPLATES]}
                  >
                    <SkillsMatrix />
                  </RestrictedRoute>
                  <RestrictedRoute
                    isFetchingData={isFetchingData}
                    isVisibleForTenant={isVisibleForTenant}
                    path="/finance"
                    permissions={hhPermissions.FINANCES_MANAGE_FINANCES}
                  >
                    <Finance />
                  </RestrictedRoute>
                  <RestrictedRoute
                    path="/project"
                    permissions={[
                      hhPermissions.PROJECT_LIST_ALL,
                      hhPermissions.PROJECT_FULL_ACCESS,
                    ]}
                  >
                    <Project />
                  </RestrictedRoute>
                  <RestrictedRoute
                    path="/clients"
                    permissions={[
                      hhPermissions.CLIENTS_MANAGE,
                      hhPermissions.CLIENTS_LIST,
                      hhPermissions.CLIENTS_LIST_ALL,
                      hhPermissions.PROSPECT_FULL_ACCESS,
                    ]}
                  >
                    <Clients />
                  </RestrictedRoute>
                  <RestrictedRoute
                    path="/folders"
                    permissions={[hhPermissions.PROSPECT_FULL_ACCESS]}
                  >
                    <Folders />
                  </RestrictedRoute>
                  <RestrictedRoute
                    isFetchingData={isFetchingData}
                    isVisibleForTenant={isVisibleForTenant}
                    path="/time-tracker"
                  >
                    <TimeTracker />
                  </RestrictedRoute>
                  <Route>
                    <Page404 dataCy={PAGE_404_APP} variant="user" path="/" />
                  </Route>
                </Switch>
              </AppContainer>
            </PermissionProvider>
          </Auth>
        </KeycloakProvider>
      </Router>
    </Suspense>
  );
};

export default App;
