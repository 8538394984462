import {
  ReactElement,
  useState,
  MouseEvent,
  KeyboardEvent,
  ChangeEvent,
  FocusEvent,
  useEffect,
  useRef,
} from "react";
import { classJoin } from "@ps/utils";
import { TimeTrackerInputProps } from "./types";
import { addZeroToTimeValue, getTimeValue } from "./helpers/utils";
import {
  TIME_TRACKER_INPUT_PREFIX,
  TIME_TRACKER_INPUT_ICON_PREFIX,
} from "../../shared/data-cy";
import { Keys } from "../../shared";

const borderStyles = classJoin.template`
text-neutralPrimary-20
focus-within:border-secondary-50 disabled:opacity-30
`;

const timeTrackerDivStyle = classJoin.template`
flex flex-row rounded-lg 
overflow-hidden group 
`;

const timeTrackerInputStyle = classJoin.template`
w-full bg-neutralPrimary-100 text-center
focus:outline-none px-1 py-1 
`;

const TimeTrackerInput = ({
  additionalContainerClass = "",
  autoFocus = false,
  dataCy,
  disabled = false,
  error,
  Icon,
  iconColor,
  isAdvancedView,
  message,
  inputWidth,
  onBlur,
  onClick,
  placeholder,
  tabIndex = 0,
  value: overrideValue,
  onFocus,
}: TimeTrackerInputProps): ReactElement => {
  const [value, setValue] = useState("");
  const [shouldCallBlur, setShouldCallBlur] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => setIsDisabled(disabled), [disabled]);

  useEffect(() => {
    if (isDisabled) {
      setTimeout(() => {
        setIsDisabled(false);
        inputRef?.current?.focus();
      }, 1000);
    }
  }, [isDisabled]);

  useEffect(() => {
    if (overrideValue || overrideValue === "") {
      setValue(addZeroToTimeValue(overrideValue));
    }
  }, [overrideValue]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = getTimeValue(value, e.target.value);
    if (newValue) setValue(newValue);
  };

  const handleInputBlur = (): void => {
    const valueWithZero = addZeroToTimeValue(value);
    onBlur?.(valueWithZero);
    setValue(valueWithZero);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    const inputSelection = document.getSelection()?.toString();
    if (e.key === Keys.BACKSPACE && value.length === 1) setValue("");
    if (e.key === Keys.BACKSPACE && inputSelection?.length === 5) setValue("");
    if (e.key === Keys.ENTER || isAdvancedView) {
      handleInputBlur();
      setShouldCallBlur(false);
      setIsDisabled(true);
    }
    if (e.key === Keys.DELETE) setValue("");
  };

  const handleOnClick = (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    onClick?.(event);
    setValue(addZeroToTimeValue(value));
  };

  const handleEnterDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === Keys.ENTER) {
      setValue(addZeroToTimeValue(value));
      handleOnClick(e);
    }
  };

  const handleInputFocus = (e: FocusEvent<HTMLInputElement>): void => {
    e.target.select();
    onFocus && onFocus();
  };

  return (
    <div
      className={classJoin("flex flex-col gap-y-0.5", additionalContainerClass)}
    >
      <div
        className={classJoin(
          timeTrackerDivStyle,
          inputWidth || "w-28",
          isAdvancedView ? "border-primary-50" : borderStyles,
          error ? "border border-error-50" : "border",
        )}
      >
        {isAdvancedView ? (
          <div
            className={classJoin(
              timeTrackerInputStyle,
              "border-primary-50 text-primary-50 opacity-40",
            )}
            data-cy={`${TIME_TRACKER_INPUT_PREFIX}-${dataCy}`}
            role="presentation"
            onClick={handleOnClick}
          >
            {value || "00:00"}
          </div>
        ) : (
          !iconColor && (
            <input
              ref={inputRef}
              tabIndex={tabIndex}
              autoFocus={autoFocus}
              className={classJoin(
                timeTrackerInputStyle,
                borderStyles,
                Icon ? "border-r" : "",
                error
                  ? "placeholder-error-50"
                  : "placeholder-neutralSecondary-41",
              )}
              data-cy={`${TIME_TRACKER_INPUT_PREFIX}-${dataCy}`}
              disabled={isDisabled}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
              value={value}
              onBlur={() => {
                if (shouldCallBlur) handleInputBlur();
                else setTimeout(() => setShouldCallBlur(true), 100);
              }}
            />
          )
        )}
        {iconColor && !isAdvancedView && (
          <div
            className={classJoin(
              timeTrackerInputStyle,
              borderStyles,
              "border-r",
              disabled ? "opacity-30" : "",
              error ? "border border-error-50" : "border-neutralSecondary-60",
            )}
            data-cy={`${TIME_TRACKER_INPUT_PREFIX}-${dataCy}`}
            role="presentation"
            onClick={handleOnClick}
          >
            {value || placeholder}
          </div>
        )}
        {Icon && (
          <div
            className={classJoin(
              "w-min px-1.5 flex flex-col items-center justify-center",
              disabled && !value.length ? "pointer-events-none opacity-30" : "",
              isAdvancedView ? "bg-primary-50" : "bg-neutralPrimary-100",
            )}
            data-cy={`${TIME_TRACKER_INPUT_ICON_PREFIX}-${dataCy}`}
            onClick={handleOnClick}
            tabIndex={disabled ? undefined : tabIndex}
            role={disabled ? undefined : "button"}
            onKeyDown={handleEnterDown}
          >
            <Icon
              className={classJoin(
                "fill-current h-6 w-4",
                isAdvancedView
                  ? "text-neutralPrimary-100"
                  : "text-neutralSecondary-60 hover:text-neutralPrimary-30",
              )}
              style={{ color: iconColor && !isAdvancedView ? iconColor : "" }}
            />
          </div>
        )}
      </div>
      {error && <span className="text-error-50 text-xs">{message ?? ""}</span>}
    </div>
  );
};

export default TimeTrackerInput;
