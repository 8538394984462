import { motion, AnimatePresence } from "framer-motion";
import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { PROGRESS_BAR_PROGRESS_PREFIX } from "../../../shared/data-cy";
import { useThemeMode } from "../../theme-mode-provider";
import { BarProps } from "../types";

const Bar = ({ width = 0, dataCy, isNext }: BarProps): ReactElement => {
  const hasWidth = width !== 0;

  const { isHighContrast } = useThemeMode();
  const checkedColor = isHighContrast ? "bg-primary-80" : "bg-primary-50";
  const uncheckedColor = isHighContrast
    ? "bg-primary-30"
    : "bg-neutralSecondary-80";
  return (
    <div
      className={classJoin(
        uncheckedColor,
        "relative h-1.5 w-full rounded-md overflow-hidden mx-1",
      )}
      data-cy={`${PROGRESS_BAR_PROGRESS_PREFIX}-${dataCy}`}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <AnimatePresence>
        {hasWidth && (
          <motion.div
            key={`${PROGRESS_BAR_PROGRESS_PREFIX}-${dataCy}`}
            className={classJoin(
              checkedColor,
              "absolute left-0 top-0 h-1.5 rounded-md",
            )}
            animate={{
              width: `${width}%`,
              transition: {
                ease: "easeInOut",
                duration: 0.4,
                delay: isNext && hasWidth ? 0.3 : 0,
              },
            }}
            exit={{
              width: 0,
              transition: {
                ease: "easeInOut",
                duration: 0.4,
              },
            }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Bar;
