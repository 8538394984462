import React, { ReactElement, useEffect, useRef, useState } from "react";
import { classJoin } from "@ps/utils";
import dayjs, { Dayjs } from "dayjs";
import { InputBase } from "../../input";
import { DatepickerInputProps, DatepickerMode } from "../types";
import { Keys } from "../../../shared";
import DatepickerInputButton from "./DatepickerInputButton";
import { DATEPICKER_PREFIX } from "../../../shared/data-cy";
import {
  InputType,
  SelectedDatesActionTypes,
} from "../helpers/state/useSelectDateTypes";

const commonDualInputStyle = `
w-36 focus:outline-none p-2 pl-1 text-neutralPrimary-20 placeholder-neutralPrimary-30
placeholder-opacity-30 bg-transparent
`;
const currentInputStyle = `
border-b-2 border-opacity-30 border-primary-50
`;

const DatepickerInput = React.forwardRef<HTMLDivElement, DatepickerInputProps>(
  (
    {
      mode,
      onChangeFirst,
      onChangeSecond,
      onClickInput,
      onClickClear,
      clearRange,
      dateFormatTemplate,
      value,
      onFocusDate,
      currentInput,
      onChangeInput,
      dataCy,
      error,
      message,
      disabled,
      showCalendar,
      width,
      autoFocus,
    },
    ref,
  ): ReactElement => {
    const iconButtonNode = useRef(null);
    const rangeStart = value?.[0];
    const rangeEnd = value?.[1];

    const [firstInputValue, setFirstInputValue] = useState(
      rangeStart?.format(dateFormatTemplate) ?? "",
    );
    const [secondInputValue, setSecondInputValue] = useState(
      rangeEnd?.format(dateFormatTemplate) ?? "",
    );

    const [wasBackspaceClicked, setWasBackspaceClicked] = useState(false);

    useEffect(() => {
      if (!firstInputValue?.trim().length && wasBackspaceClicked) {
        if (mode === "range-picker") {
          clearRange(SelectedDatesActionTypes.CLEAR_RANGE_START);
          setWasBackspaceClicked(false);
        } else {
          onClickClear();
          setWasBackspaceClicked(false);
        }
      }
    }, [firstInputValue]);

    useEffect(() => {
      if (!secondInputValue?.trim().length && wasBackspaceClicked) {
        clearRange(SelectedDatesActionTypes.CLEAR_RANGE_END);
        setWasBackspaceClicked(false);
      }
    }, [secondInputValue]);

    useEffect(() => {
      setFirstInputValue(rangeStart?.format(dateFormatTemplate) ?? "");
    }, [rangeStart, dateFormatTemplate]);

    useEffect(() => {
      setSecondInputValue(rangeEnd?.format(dateFormatTemplate) ?? "");
    }, [rangeEnd, dateFormatTemplate]);

    const resetFirstInput = () =>
      setFirstInputValue(rangeStart?.format(dateFormatTemplate) ?? "");
    const resetSecondInput = () =>
      setSecondInputValue(rangeEnd?.format(dateFormatTemplate) ?? "");

    const handleInputChange =
      (func: (val: string) => void) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        func(event.target.value);
      };

    const handleOnClickInput = (input: InputType) => () => {
      onClickInput();
      onChangeInput?.(input);
    };

    const handleConvertOnBlur =
      (
        success: typeof onChangeFirst,
        fail: typeof resetFirstInput,
        check?: Dayjs,
      ) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const eventValue = event.target.value;
        const dateConverted = dayjs(eventValue, dateFormatTemplate, true);
        if (
          dateConverted.isValid() &&
          eventValue !== check?.format(dateFormatTemplate)
        ) {
          success(dateConverted);
        } else {
          fail();
        }
      };

    const handleConvertOnEnter =
      (success: typeof onChangeFirst, fail: typeof resetFirstInput) =>
      (inputValue?: string) =>
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === Keys.ENTER) {
          const dateConverted = dayjs(inputValue, dateFormatTemplate, true);
          if (dateConverted.isValid()) {
            success(dateConverted);
          } else {
            fail();
          }
        } else if (event.key === Keys.BACKSPACE) {
          setWasBackspaceClicked(true);
        }
      };

    const handleInputFocus = (val: Dayjs | undefined, input: InputType) => {
      if (!val) return;
      const shouldSubtratctMonth =
        input === InputType.SECOND && !rangeStart?.isSame(val, "month");
      const dateToDisplay = shouldSubtratctMonth
        ? val.subtract(1, "month")
        : val;
      onFocusDate?.(dateToDisplay);
    };

    if (mode === DatepickerMode.DATE_PICKER) {
      return (
        <div className="flex flex-col">
          <div
            className="relative flex flex-col text-neutralPrimary-20 focus-within:text-neutralPrimary-20 w-min"
            ref={ref}
            data-cy={`${DATEPICKER_PREFIX}-${dataCy}`}
          >
            <InputBase
              tabIndex={0}
              width={width}
              dataCy={`${DATEPICKER_PREFIX}-${dataCy}-date-input`}
              value={firstInputValue}
              onClick={handleOnClickInput(InputType.FIRST)}
              additionalClass="pr-8 placeholder-opacity-30"
              placeholder={dateFormatTemplate}
              onChange={handleInputChange(setFirstInputValue)}
              error={error}
              onBlur={handleConvertOnBlur(
                onChangeFirst,
                resetFirstInput,
                rangeStart,
              )}
              onKeyDown={handleConvertOnEnter(
                onChangeFirst,
                resetFirstInput,
              )(firstInputValue)}
              onFocus={() => handleInputFocus(rangeStart, InputType.FIRST)}
              autoFocus={autoFocus}
              disabled={disabled}
            />
            <DatepickerInputButton
              id="datepicker-input-button-first-1"
              ref={ref}
              shouldAllowReset={!!value}
              onClickReset={onClickClear}
              onClickCalendar={() => {
                onClickInput();
                onChangeInput?.(InputType.FIRST);
              }}
              dataCy={dataCy}
              disabled={disabled}
            />
          </div>
          {error && !showCalendar && (
            <span className="mt-1 text-xs text-error-50">{message ?? ""}</span>
          )}
        </div>
      );
    }

    return (
      <div className="flex flex-col">
        <div
          className="relative flex flex-row text-neutralPrimary-20 focus-within:text-neutralPrimary-20 w-min"
          ref={ref}
          data-cy={`${DATEPICKER_PREFIX}-${dataCy}`}
        >
          <div className="flex flex-row rounded-md h-8.5 p-1 border border-neutralSecondary-60 bg-neutralPrimary-100 pr-5">
            <input
              value={firstInputValue}
              className={classJoin(
                commonDualInputStyle,
                currentInput === InputType.FIRST ? currentInputStyle : "",
              )}
              placeholder={dateFormatTemplate}
              onClick={handleOnClickInput(InputType.FIRST)}
              onChange={handleInputChange(setFirstInputValue)}
              onBlur={handleConvertOnBlur(
                onChangeFirst,
                resetFirstInput,
                rangeStart,
              )}
              onFocus={() => {
                handleInputFocus(rangeStart, InputType.FIRST);
                onChangeInput?.(InputType.FIRST);
              }}
              onKeyDown={handleConvertOnEnter(
                onChangeFirst,
                resetFirstInput,
              )(firstInputValue)}
              data-cy={`${DATEPICKER_PREFIX}-${dataCy}-date-input-first`}
            />
            <input
              value={secondInputValue}
              className={classJoin(
                commonDualInputStyle,
                currentInput === InputType.SECOND ? currentInputStyle : "",
                "pr-5",
              )}
              placeholder={dateFormatTemplate}
              onClick={handleOnClickInput(InputType.SECOND)}
              onChange={handleInputChange(setSecondInputValue)}
              onBlur={handleConvertOnBlur(
                onChangeSecond,
                resetSecondInput,
                rangeEnd,
              )}
              onFocus={() => {
                handleInputFocus(rangeEnd, InputType.SECOND);
                onChangeInput?.(InputType.SECOND);
              }}
              onKeyDown={handleConvertOnEnter(
                onChangeSecond,
                resetSecondInput,
              )(secondInputValue)}
              data-cy={`${DATEPICKER_PREFIX}-${dataCy}-date-input-second`}
            />
          </div>
          <DatepickerInputButton
            shouldAllowReset={!!value}
            onClickReset={onClickClear}
            onClickCalendar={() => {
              onClickInput();
              onChangeInput?.(InputType.FIRST);
            }}
            dataCy={dataCy}
          />
        </div>
        {error && !showCalendar && (
          <span className="mt-1 text-xs text-error-50">{message ?? ""}</span>
        )}
      </div>
    );
  },
);

export default DatepickerInput;
