import {
  useState,
  ReactElement,
  KeyboardEvent,
  useEffect,
  ChangeEvent,
} from "react";
import { classJoin } from "@ps/utils";
import Dropdown from "../dropdown";
import InputBase from "../input/inputBase";
import {
  SKILLS_SEARCH_PICKER_PREFIX,
  SKILLS_SEARCH_PICKER_INPUT_PREFIX,
} from "../../shared/data-cy";
import { Keys } from "../../shared";
import { useTranslationWithNamespace } from "../../hooks";
import { SkillsSearchPickerProps, TagProps } from "./types";
import { filteredProposalTags } from "../../shared/utils/searchPickerUtils";
import getHighlightedText from "../../shared/utils/highlightTextUtil";
import SelectedSkill from "./selectedSkill";

const wrapperStyle = classJoin.template`
focus-within:text-neutralPrimary-20 w-full
flex flex-col relative text-neutralPrimary-20
`;

const inputBaseStyle = classJoin.template`
rounded-md h-8.5 bg-neutralPrimary-100
text-neutralPrimary-20 border focus:outline-none p-2
`;

const SkillsSearchPicker = ({
  additionalAmountClassName = "text-primary-100 bg-primary-50",
  autoFocus = false,
  dataCy,
  error,
  maxProposalTagsNumber = 10,
  message,
  onInputChange,
  onRemove,
  onSelected,
  placeholder,
  proposalTags,
  selected,
  skillColourClass,
  skillColourRemoveClass,
  width = "w-80",
  pillHeight,
}: SkillsSearchPickerProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [currentSelection, setCurrentSelection] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [clickedHiddenSkills, setClickedHiddenSkills] =
    useState<boolean>(false);

  useEffect(() => {
    onInputChange?.(searchValue);
  }, [searchValue]);

  const handleOnAppendTag = (newTag: TagProps | Record<"id", string>[]) => {
    onSelected(newTag);
    setSearchValue("");
    setClickedHiddenSkills(true);
  };

  const trimmedSearchValue = searchValue.trim();

  const handleInputChange = (value: string) => {
    if (value && !/^[a-zA-Z0-9. # _+-]+$/.test(value)) return;
    setCurrentSelection(0);
    setSearchValue(value);
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (
      currentSelection === maxProposalTagsNumber - 1 &&
      event.key === Keys.ARROW_DOWN
    )
      setCurrentSelection(-1);
    if (event.key === Keys.ARROW_DOWN)
      setCurrentSelection((prevIndex: number | null) =>
        prevIndex !== null ? prevIndex + 1 : 0,
      );
    else if (
      event.key === Keys.ARROW_UP &&
      currentSelection &&
      currentSelection > -1
    )
      setCurrentSelection((prevIndex: number) =>
        prevIndex !== 0 ? prevIndex - 1 : 0,
      );
    else if (event.key === Keys.ENTER && searchValue) {
      event.preventDefault();
      event.stopPropagation();
      if (
        currentSelection !==
        filteredProposalTags(proposalTags, selected)?.length
      )
        handleOnAppendTag(
          filteredProposalTags(proposalTags, selected)?.[currentSelection],
        );
      setCurrentSelection(0);
    }
  };

  const showSkills = () => (
    <div
      role="presentation"
      onClick={() => {
        setSearchValue("");
        setClickedHiddenSkills((prev) => !prev);
      }}
      className={classJoin(
        "rounded-full cursor-pointer absolute w-6 flex justify-center items-center top-1.5 right-3",
        additionalAmountClassName,
      )}
    >
      {selected?.length}
    </div>
  );

  const showProperOverlay = () =>
    clickedHiddenSkills ? (
      <div className="flex flex-row flex-wrap gap-2 p-2">
        {selected.map(({ id, name }: TagProps, index: number) => (
          <SelectedSkill
            height={pillHeight}
            name={name}
            key={`${id}-${name}`}
            colourClass={skillColourClass}
            colourRemoveClass={skillColourRemoveClass}
            onClick={() => onRemove(index)}
          />
        ))}
      </div>
    ) : (
      <div className="flex flex-col gap-1 bg-neutralPrimary-100 rounded-md">
        {filteredProposalTags(proposalTags, selected)
          ?.slice(0, maxProposalTagsNumber)
          ?.map((item: TagProps, index: number) =>
            getHighlightedText(
              item,
              index,
              trimmedSearchValue,
              currentSelection,
              handleOnAppendTag,
            ),
          )}
      </div>
    );

  return (
    <div
      className={classJoin("flex flex-col", width)}
      data-cy={`${SKILLS_SEARCH_PICKER_PREFIX}_${dataCy}`}
    >
      <Dropdown
        show={!!trimmedSearchValue.length || clickedHiddenSkills}
        onClickOutside={() => {
          setSearchValue("");
          setClickedHiddenSkills(false);
        }}
        overlay={showProperOverlay()}
      >
        <div className={wrapperStyle}>
          <InputBase
            maxLength={40}
            dataCy={SKILLS_SEARCH_PICKER_INPUT_PREFIX}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleInputChange(event.target.value);
              setClickedHiddenSkills(false);
            }}
            value={searchValue}
            placeholder={placeholder || t("searchSelectPicker.placeholder")}
            className={classJoin(
              inputBaseStyle,
              selected?.length ? "pr-12" : "",
              error
                ? "border-error-50 placeholder-error-50"
                : "border-neutralSecondary-60 placeholder-neutralSecondary-41 focus:border-secondary-50",
            )}
            onKeyDown={handleOnKeyDown}
            autoFocus={autoFocus}
          />
          {selected?.length ? showSkills() : <></>}
        </div>
      </Dropdown>
      {error &&
      (!trimmedSearchValue.length || !clickedHiddenSkills) &&
      !selected.length ? (
        <span className="text-xs text-error-50 mt-1">{message ?? ""}</span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SkillsSearchPicker;
