import { ReactElement, useState, MouseEvent, useEffect } from "react";
import {
  Button,
  RestrictedWrapper,
  Sorter,
  SearchInput,
  ActiveSelect,
} from "@ps/ui-components";
import CreateProjectModal from "./create-project-modal";
import { PROJECT_FULL_ACCESS } from "../../../shared/permissions";
import { ACTIVE_PROJECTS, PROJECTS_LIST } from "../../../shared/data-cy";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { ReactComponent as NewProjectIcon } from "../../../images/project/add-project.svg";
import {
  SearchBarMapState,
  SearchBarMapStateReturn,
  SearchBarProps,
} from "./types";
import {
  PROJECT_NAME,
  CLIENT,
  CLIENT_NAME,
  CREATION_DATE,
  PROJECT_MANAGER,
  BUSINESS_DEVELOPER,
  ASC,
  DESC,
  PRIVATE,
  PUBLIC,
  ARCHIVED,
  BD,
  PM,
} from "../../constants";

const BASE_PATH = "project.searchBar";

const SearchBar = ({
  activeAmount,
  archivedAmount,
  publicAmount,
  privateAmount,
  clearFilters,
  clientId,
  disabledClearFilters,
  searchValue,
  setSearchValue,
  sorterParameters,
  onSorterClick,
  projectCheckers,
  setProjectCheckers,
}: SearchBarProps): ReactElement => {
  const mapState = (state: SearchBarMapState): SearchBarMapStateReturn => ({
    projects: state.projects.projectsList,
    clientSummary: state.clients.clientSummary,
  });

  const { projects, clientSummary } = useMappedStateSelector(mapState);

  const { t } = useTranslationWithNamespace();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (clientSummary?.archived)
      setProjectCheckers({ public: false, private: false, archived: true });
  }, []);

  const names = [
    {
      name: PROJECT_NAME,
      label: t(`${BASE_PATH}.${PROJECT_NAME}`),
      isNestedField: true,
    },
    {
      name: CLIENT_NAME,
      label: t(`${BASE_PATH}.${CLIENT}`),
      isNestedField: true,
    },
    {
      name: PM,
      label: t(`${BASE_PATH}.${PROJECT_MANAGER}`),
      isNestedField: true,
    },
    {
      name: BD,
      label: t(`${BASE_PATH}.${BUSINESS_DEVELOPER}`),
      isNestedField: true,
    },
    {
      name: CREATION_DATE,
      label: t(`${BASE_PATH}.${CREATION_DATE}`),
      isNestedField: false,
    },
  ];

  const orders = [
    { order: ASC, label: t(`${BASE_PATH}.${ASC}`) },
    { order: DESC, label: t(`${BASE_PATH}.${DESC}`) },
  ];

  const options = [
    {
      id: PRIVATE,
      label: t(`${BASE_PATH}.showPrivate`),
      amount: privateAmount,
      checked: projectCheckers.private,
    },
    {
      id: PUBLIC,
      label: t(`${BASE_PATH}.showOpen`),
      amount: publicAmount,
      checked: projectCheckers.public,
    },
    {
      id: ARCHIVED,
      label: t(`${BASE_PATH}.showArchived`),
      amount: archivedAmount,
      checked: projectCheckers.archived,
    },
  ];

  const handleOptionClick = (e: MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget;
    setProjectCheckers((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-4">
        <RestrictedWrapper permissions={PROJECT_FULL_ACCESS}>
          <div>
            {!clientSummary?.archived && (
              <Button
                dataCy={`${PROJECTS_LIST}_add_new`}
                additionalClass="flex items-center gap-2"
                onClick={(): void => setIsModalOpen(true)}
              >
                <NewProjectIcon className="fill-current" />
                {t(`${BASE_PATH}.newProject`)}
              </Button>
            )}
            <CreateProjectModal
              isOpen={isModalOpen}
              onClose={(): void => setIsModalOpen(false)}
              projects={projects}
              defaultClientId={clientId}
            />
          </div>
        </RestrictedWrapper>
        <ActiveSelect
          dataCy={`${PROJECTS_LIST}_${ACTIVE_PROJECTS}`}
          label={t(`${BASE_PATH}.activeProjects`)}
          activeAmount={activeAmount}
          options={options}
          onOptionClick={handleOptionClick}
        />
        {!disabledClearFilters && (
          <Button
            variant="secondary"
            dataCy={PROJECTS_LIST}
            onClick={clearFilters}
          >
            {t("actions.clearFilters")}
          </Button>
        )}
      </div>
      <div className="flex items-center justify-end gap-4">
        <SearchInput
          value={searchValue}
          onChange={setSearchValue}
          dataCy={`${PROJECTS_LIST}_add_new`}
          placeholder={t(`${BASE_PATH}.search`)}
        />
        <Sorter
          activeSorter={sorterParameters}
          names={names}
          orders={orders}
          handleSorterClick={onSorterClick}
        />
      </div>
    </div>
  );
};

export default SearchBar;
