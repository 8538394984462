enum ActionTypes {
  FETCH_HOLIDAYS_ITEM_START = "FETCH_HOLIDAYS_ITEM_START",
  FETCH_HOLIDAYS_ITEM_SUCCESS = "FETCH_HOLIDAYS_ITEM_SUCCESS",
  FETCH_HOLIDAYS_ITEM_ERROR = "FETCH_HOLIDAYS_ITEM_ERROR",

  FETCH_HOLIDAYS_TEMPLATE_START = "FETCH_TEMPLATE_START",
  FETCH_HOLIDAYS_TEMPLATE_SUCCESS = "FETCH_HOLIDAYS_TEMPLATE_SUCCESS",
  FETCH_HOLIDAYS_TEMPLATE_ERROR = "FETCH_HOLIDAYS_TEMPLATE_ERROR",

  FETCH_HOLIDAYS_TEMPLATES_START = "FETCH_HOLIDAYS_TEMPLATES_START",
  FETCH_HOLIDAYS_TEMPLATES_SUCCESS = "FETCH_HOLIDAYS_TEMPLATES_SUCCESS",
  FETCH_HOLIDAYS_TEMPLATES_ERROR = "FETCH_HOLIDAYS_TEMPLATES_ERROR",
}

export default ActionTypes;
