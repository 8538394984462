import { ReactElement, useState, MouseEvent, useEffect } from "react";
import {
  ActiveSelect,
  Button,
  RestrictedWrapper,
  SearchInput,
  Sorter,
} from "@ps/ui-components";
import { SearchBarProps } from "../types";
import CreateFolderModal from "../create-folder-modal";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../hooks";
import { ReactComponent as NewProspectIcon } from "../../../images/folder.svg";
import { PROSPECT_FULL_ACCESS } from "../../../shared/permissions";
import { ACTIVE_FOLDERS, FOLDERS_LIST } from "../../../shared/data-cy";
import {
  ACTIVE,
  ARCHIVED,
  ASC,
  CLIENT,
  CLIENT_NAME,
  DESC,
  FOLDER,
  FOLDER_NAME,
  LAST_UPDATE,
} from "../../constants";
import { SearchBarMapState, SearchBarMapStateReturn } from "./types";

const BASE_PATH = "folders";

const SearchBar = ({
  clientId,
  activeAmount,
  archivedAmount,
  foldersCheckers,
  setFoldersCheckers,
  searchValue,
  setSearchValue,
  sorterParameters,
  onSorterClick,
  clearFilters,
  disabledClearFilters,
}: SearchBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentID, setCurrentID] = useState("");

  const mapState = (state: SearchBarMapState): SearchBarMapStateReturn => ({
    clientSummary: state.clients.clientSummary,
  });

  const { clientSummary } = useMappedStateSelector(mapState);

  useEffect(() => {
    if (clientSummary?.archived)
      setFoldersCheckers({ active: false, archived: true });
  }, []);

  const options = [
    {
      id: ACTIVE,
      label: t(`${BASE_PATH}.showActive`),
      amount: activeAmount,
      checked: foldersCheckers.active,
    },
    {
      id: ARCHIVED,
      label: t(`${BASE_PATH}.showArchived`),
      amount: archivedAmount,
      checked: foldersCheckers.archived,
    },
  ];

  const handleOptionClick = (e: MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget;
    setFoldersCheckers((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const names = [
    {
      name: FOLDER_NAME,
      label: t(`${BASE_PATH}.list.${FOLDER_NAME}`),
    },
    {
      name: CLIENT_NAME,
      label: t(`${BASE_PATH}.list.${CLIENT}`),
      isNestedField: true,
    },
    {
      name: LAST_UPDATE,
      label: t(`${BASE_PATH}.list.${LAST_UPDATE}`),
      isNestedField: false,
    },
  ];

  const orders = [
    { order: ASC, label: t(`${BASE_PATH}.${ASC}`) },
    { order: DESC, label: t(`${BASE_PATH}.${DESC}`) },
  ];

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-4">
        <RestrictedWrapper permissions={PROSPECT_FULL_ACCESS}>
          <div>
            {!clientSummary?.archived && (
              <Button
                dataCy={`${FOLDERS_LIST}_add_new`}
                additionalClass="flex items-center gap-2"
                onClick={(): void => setIsModalOpen(true)}
              >
                <NewProspectIcon className="fill-current" />
                {t(`${BASE_PATH}.newFolder`)}
              </Button>
            )}
            <CreateFolderModal
              isOpen={isModalOpen}
              onClose={(): void => setIsModalOpen(false)}
              currentID={currentID}
              setCurrentID={setCurrentID}
              defaultClientId={clientId}
            />
          </div>
        </RestrictedWrapper>
        <ActiveSelect
          dataCy={`${FOLDERS_LIST}_${ACTIVE_FOLDERS}`}
          label={t(`${BASE_PATH}.activeFolders`)}
          activeAmount={activeAmount}
          options={options}
          onOptionClick={handleOptionClick}
        />
        {!disabledClearFilters && (
          <Button
            variant="secondary"
            dataCy={`${BASE_PATH}.clearFilters`}
            onClick={clearFilters}
          >
            {t("actions.clearFilters")}
          </Button>
        )}
      </div>
      <div className="flex items-center justify-end gap-4">
        <SearchInput
          dataCy={`${FOLDER}_search`}
          value={searchValue}
          onChange={setSearchValue}
          placeholder={t(`${BASE_PATH}.searchFolders`)}
        />
        <Sorter
          activeSorter={sorterParameters}
          names={names}
          orders={orders}
          handleSorterClick={onSorterClick}
        />
      </div>
    </div>
  );
};

export default SearchBar;
