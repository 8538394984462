export const AWARDS = "awards";
export const COURSES = "courses";
export const DEGREES = "degrees";
export const ARTICLES = "articles";
export const TALKS = "talks";
export const CONTRIBUTIONS = "contributions";
export const TUTORS = "tutors";
export const CERTIFICATES = "certificates";
export const DOMAINS = "domains";
export const LANGUAGES = "languages";
export const GOALS = "goals";
export const GOALS_CURRENT_YEAR = "goalsCurrentYear";

export const LIST = "list";
export const TILE = "tile";

export const DATE_FORMAT_MM_YYYY = "MM.YYYY";
export const CURRENT_YEAR = new Date().getFullYear().toString();
export const PREVIOUS_YEAR = (new Date().getFullYear() - 1).toString();
