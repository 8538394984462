import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../../hooks";

const MySchedule = (): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="w-full bg-primary-50 text-white h-56 flex justify-center items-center text-2xl">
      {t("timesheet.mySchedule.title")}
    </div>
  );
};

export default MySchedule;
