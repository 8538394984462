import { ReactElement } from "react";
import { Tooltip } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import { useTranslationWithNamespace } from "../../../hooks";
import { BENCH, FOLDERS_STATUSES } from "../../constants";
import { StatusIconWithTooltipProps } from "./types";

const StatusIconWithTooltip = ({
  status,
  totalNumber,
  activeColor,
  Icon,
  dataCy,
}: StatusIconWithTooltipProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Tooltip
      placement="top"
      content={<span>{t(`${BENCH}.${FOLDERS_STATUSES}.${status}`)}</span>}
    >
      <div
        data-cy={dataCy}
        className={classJoin(
          "flex items-center gap-2 px-1.5 py-1",
          totalNumber ? `${activeColor} border rounded-lg` : "",
        )}
      >
        <Icon className="w-5 h-5" />
        <span>{totalNumber}</span>
      </div>
    </Tooltip>
  );
};

export default StatusIconWithTooltip;
