enum DateFormats {
  DEFAULT_DAY_FORMAT = "DD/MM/YYYY",
  DEFAULT_MONTH_FORMAT = "MM/YYYY",
  DEFAULT_QUARTER_FORMAT = "[Q]Q YYYY",
  DEFAULT_YEAR_FORMAT = "YYYY",
  MONTH = "MMM",
  MONTH_FULL = "MMMM",
  YEAR = "YYYY",
}
export default DateFormats;
