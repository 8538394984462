import { dayJSToISOValueKnown } from "@ps/utils";
import { PROJECT, HOLIDAY } from "../../shared/constants";
import {
  ProjectNewTimeEntryAPIModel,
  ProjectNewTimeEntryModel,
  ProjectUpdatedTimeEntryAPIModel,
  ProjectUpdatedTimeEntryModel,
  HolidayNewTimeEntryAPIModel,
  HolidayNewTimeEntryModel,
} from "../timeEntriesTypes";

const newProjectTimeEntrytoAPIModel = (
  newEntry: ProjectNewTimeEntryModel,
): ProjectNewTimeEntryAPIModel => ({
  eventType: PROJECT,
  eventTypeId: newEntry.typeId,
  day: dayJSToISOValueKnown(newEntry.day),
  minutes: newEntry.minutes,
  rate: newEntry.rate,
  note: newEntry.note,
});

const updatedProjectTimeEntryToAPIModel = (
  updatedEntry: ProjectUpdatedTimeEntryModel,
): ProjectUpdatedTimeEntryAPIModel => ({
  eventType: PROJECT,
  eventTypeId: updatedEntry.typeId,
  day: dayJSToISOValueKnown(updatedEntry.day),
  minutes: updatedEntry.minutes,
  rate: updatedEntry.rate,
  note: updatedEntry.note || "",
});

const newHolidayTimeEntryToAPIModel = (
  newEntry: HolidayNewTimeEntryModel,
): HolidayNewTimeEntryAPIModel => ({
  eventType: HOLIDAY,
  eventTypeId: newEntry.typeId.id,
  minutes: newEntry.duration?.value,
  rate: newEntry.rate,
  dayFrom: dayJSToISOValueKnown(newEntry.dateFrom),
  dayTo: dayJSToISOValueKnown(newEntry.dateTo),
});

const toAPIModel = {
  newProjectTimeEntrytoAPIModel,
  updatedProjectTimeEntryToAPIModel,
  newHolidayTimeEntryToAPIModel,
};

export default toAPIModel;
