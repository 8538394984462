import { SetStateAction } from "react";
import { AnyAction, Dispatch } from "redux";
import { isAPIError } from "@ps/api-utils";
import { actionCreator } from "@ps/utils";
import ActionTypes from "./actionTypes";
import { IAction } from "../../store/types";
import { TenantService } from "../index";
import { TenantMetadataModel } from "../models/types";

export const fetchTenantMetadataStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_TENANT_FEATURE_FLAGS_START);

export const fetchTenantMetadataSuccess = (
  payload: IAction<TenantMetadataModel>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_TENANT_METADATA_SUCCESS, payload);

export const fetchTenantMetadataError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_TENANT_METADATA_ERROR, payload);

export const fetchTenantMetadata = async (
  dispatch: Dispatch,
): Promise<SetStateAction<TenantMetadataModel>> => {
  dispatch(fetchTenantMetadataStart());
  const result = await TenantService.getTenantMetadata();
  if (isAPIError(result)) {
    dispatch(fetchTenantMetadataError(result));
    return result;
  }
  dispatch(fetchTenantMetadataSuccess(result));
  return result;
};
