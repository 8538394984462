export default {
  main: {
    sections: {
      members: "Members",
      usersProfile: "User's profile",
      projects: "Projects",
      workspaceInfo: "Workspace info",
      myProfile: "My profile",
      ai: "AI",
      skillsMatrix: "Skills matrix",
      financialInfo: "Financial info",
      seeMore: "See more",
    },
    profile: {
      empty: "empty",
      sections: {
        bio: "Bio",
        basicInfo: "Experience",
        availability: "Travel documents",
        work: "Work hours",
        languages: "Languages",
        goalsCurrentYear: "Development goals {{year}}",
        goalsPreviousYear: "Development goals {{year}}",
      },
      basicInfo: {
        lastUpdate: "Last update",
        seniority: "Seniority",
        dateOfBirth: "Date of birth",
        profession: "Profession",
        role: "Role",
      },
      availability: {
        passports: "Passport",
        expirationDate: "Expiration date",
        visas: "Visa",
        residence: "Residence",
      },
      work: {
        comfortableHours: "Comfortable hours",
        possibleHours: "Possible hours",
      },
    },
    actions: {
      edit: "edit",
      more: "more",
      remove: "remove",
      share: "share",
      viewMore: "view more",
      add: "add",
    },
  },
  profile: {
    other: {
      present: "present",
    },
    tabs: {
      personal_info: "personal info",
      experience: "experience",
      skills: "skills",
      summary: "summary",
    },
    lastUpdate: "Last update: {{ date }}",
    overview: {
      empty: "empty",
      errors: {
        wrongHoursFormat: "Fix time format, from 00:00 to 23:59",
        cantBeEmpty: "Field is required",
        startDateEmpty: "Start career date cannot be empty",
        wrongDateFormat: "Wrong date format",
      },
      details: {
        bio: "Bio",
        basicInfo: "Experience",
        availability: "Travel Documents",
        work: "Work hours",
        languages: "Languages",
        goalsCurrentYear: "Development Goals {{year}}",
        goalsPreviousYear: "Development Goals {{year}}",
      },
      bio: {
        shortInformation: "Short Information",
        placeholder: "Add short bio",
        empty: "No information here for now. Add your bio!",
        tooltip: `Shortly: who you are, how you've gained your skills experience, what is your work ethic, "brand story" of yourself`,
        cantBeBlank: "Remove the blank space from the bio",
      },
      personalInfo: {
        info: "Info",
        personalInfo: "Personal Info",
      },
      basicInfo: {
        firstName: "First Name",
        lastName: "Last Name",
        seniority: "Seniority",
        mainProfession: "Main profession",
        mainProfessionPlaceholder: "Your main profession",
        professions: "Other professions",
        otherProfessionsPlaceholder: "Other professions",
        operativeProfessions: "Professions",
        operativeProfessionsPlaceholder: "Professions",
        role: "Preferred roles",
        rolePlaceholder: "your role",
        seniorityPlaceholder: "Your seniority",
        startDate: "Career start date",
        careerStartTooltip: "Enter the date you started working in IT",
        careerSpan: "Career gap",
        gapTooltip: `If you had any breaks in the duration of your professional career, 
        please fill the career gap box with the aggregate number of years and/or months of this period`,
        experienceAmount: "Overall experience",
        years_one: "{{ count }} year",
        years_other: "{{ count }} years",
        months_one: "{{ count }} month",
        months_other: "{{ count }} months",
        placeholderYears: "Years",
        placeholderMonths: "Months",
        ProgrammerTooltip: `The leading competence: Analytical and critical thinking. 
        Focuses on building and delivering good, efficient software. 
        In their tasks, the main focus is on problem-solving, analysis, and performance of various job development. 
        Thanks to their specialization and knowledge of the best industry practices, provide the best quality software.
        Can work alone or in a team, projects with a small number of tasks requiring communication and cooperation 
        between team members or on the business-developer line do not negatively affect their motivation levels.`,
        LeaderTooltip: `The leading competence: Leadership.
        Strong focus on the team and the goal of the project, 
        taking responsibility for the technical results delivered by the team and “big picture thinking”. 
        Focus on communicating stakeholders’ requirements and expectations and their reflection in the final product, 
        ensuring an effective flow of communication between various interest groups. Focus on avoiding/eliminating tech debt.
        Leads team by example, building a culture of trust in the team and being a point of tech reference for team members.`,
        ConsultantTooltip: `The leading competence: Effective communication.
        Strong technical plus people&communication focus. Area of expertise includes consulting 
        at the level of project strategy/business needs of the project, its design and implementation plan, 
        in close cooperation with developers/business/clients. Works in various environments and various teams, 
        in which takes leadership and manages the work/development strategy. 
        Strong focus is on tech as well as on the business side of the projects.`,
        MentorTooltip: `The leading competence: Knowledge sharing.
        Strong focus on sharing knowledge, experience, and good practices with other team members. 
        Acting as a guide, coach, and buddy, when needed. Make sure their mentee has the full potential to develop your potential.
        Focus on listening, observing, advising, taking control when needed, and giving space when needed. 
        Sharing best practices in the area.`,
        SpeakerTooltip: `The leading competence: Effective communication.
        Strong focus on the representation of the organization through speeches and sharing knowledge outside the organization. 
        Strongly developed ability to present, communicate and effectively transfer knowledge and experiences. 
        Focus on expertise in a given topic and openness to engaging in dialogue/discussion.`,
      },
      availability: {
        passports: "Passport",
        expirationDate: "Expiration date",
        visas: "Visa",
        nationality: "Nationality",
        residencePlaceholder: "Your residence",
        nationalityPlaceholder: "Your nationality",
        residence: "Residence",
        add: "Add",
        visaTooltip: "Add your visas necessary for business travel",
        documentPlaceholder: "Choose country",
      },
      work: {
        hours: "hours",
        workingHours: "Working hours",
        startDate: "Start date",
        comfortableHours: "Comfortable hours",
        possibleHours: "Possible hours",
        zone: "Time zone",
        comfortableHoursTooltip:
          "Comfortable hours are the working hours you find the most convenient taking into account your daily routine",
        possibleHoursTooltip: `Possible hours are the less convenient working hours possible for you taking into account 
          e.g. projects from clients located in other timezones than yours`,
        zonePlaceholder: "Choose your time zone",
      },
      languages: {
        empty: "You can add here languages you speak",
        language: "Language",
        level: "Level",
        Elementary: "Elementary proficiency",
        Limited: "Limited working proficiency",
        Professional: "Professional working proficiency",
        FullyProfessional: "Full professional proficiency",
        Native: "Native or bilingual proficiency",
        languagePlaceholder: "Choose language",
        levelPlaceholder: "Choose level",
      },
      actions: {
        edit: "edit",
        download: "download",
        changeImage: "Change Image",
        save: "Save",
        cancel: "Cancel",
        delete: "Delete",
        confirm: "Confirm",
        add: "add",
      },
      socialNetworks: {
        linkedin: "LinkedIn",
        twitter: "Twitter",
        gitHub: "GitHub",
      },
      goalsCurrentYear: {
        empty:
          "There is no information about your goals and development plans.",
        note: "Goals",
        skills: "Skills",
        cantBeBlank: "Remove the blank space from the goals",
        goalsPlaceholder: "Describe your development goals for this year",
        tooltipContent:
          "Here you can search for a skill or create a new skill.",
      },
      goalsPreviousYear: {
        empty:
          "There is no information about your goals and development plans.",
        note: "Goals",
        skills: "Skills",
        cantBeBlank: "Remove the blank space from the goals",
        goalsPlaceholder: "Describe your development goals for this year",
      },
    },
    experience: {
      errors: {
        urlPatternNotMatch: "Fix URL pattern, eg. https://www.name.com",
      },
      actions: {
        addNew: "Add new",
        addDegree: "Add degree",
        editSection: "edit",
        duplicateSection: "duplicate",
        deleteSection: "delete",
        addCourse: "Add course",
        save: "Save",
        cancel: "Cancel",
        upload: "Upload",
        remove: "remove",
        delete: "Delete",
        sectionDeleting: "Section deleting",
        doYouWantDelete: "Do you really want to delete this section?",
      },
      sections: {
        projects: "Projects",
        education: "Education",
        accomplishment: "Accomplishment",
        inner: {
          degree: "Degree",
          degrees: "Degrees",
          courses: "Courses",
          contributions: "Contributions",
          awards: "Awards",
          certificates: "Certificates",
          articles: "Articles",
          talks: "Talks",
          tutors: "Tutors",
        },
      },
      cards: {
        project: {
          name: "Project name",
          domain: "Domain",
          domainPlaceholder: "Domain name",
          startDate: "From",
          endDate: "To",
          description: "Project description",
          descriptionPlaceholder: "Describe project you participated in",
          responsibility: "Responsibility",
          responsibilityPlaceholder: "Describe your responsiblities",
          projectRoles: "Project role",
          projectRolesPlaceholder: "Choose project roles",
          techStack: "Tech stack",
        },
        course: {
          trainer: "Trainer",
          by: "by",
          trainerPlaceholder: "Trainer name",
          courseName: "Course name",
          startDate: "From",
          endDate: "To",
          description: "Description",
          descriptionPlaceholder: "Type here information about course",
          ongoing: "Ongoing",
        },
        degree: {
          schoolName: "School Name",
          schoolNamePlaceholder: "Your school/university name",
          fieldOfStudy: "Field of study",
          fieldOfStudyPlaceholder: "Your faculty",
          degree: "Degree",
          degreePlaceholder: "Your degree",
          startDate: "From",
          endDate: "To",
          description: "Description",
          descriptionPlaceholder: "Type here information about your degree",
          ongoing: "Ongoing",
        },
        articles: {
          title: "Title",
          titlePlaceholder: "Your article name",
          url: "Publication URL",
          urlPlaceholder: "Article URL",
          date: "Publication date",
          techStack: "Tech stack",
          pdf: "PDF",
        },
        awards: {
          awardName: "Name",
          awardNamePlaceholder: "Award name",
          rewarder: "Issued by",
          rewarderPlaceholder: "Issed by",
          date: "Issue date",
          description: "Description",
          descriptionPlaceholder: "Type information about your award",
          techStack: "Tech stack",
        },
        certificates: {
          title: "Title",
          titlePlaceholder: "Certificate name",
          url: "Certificate URL",
          urlPlaceholder: "Certificate URL",
          pdf: "PDF",
          issuedDate: "Issue date",
          expirationDate: "Expiration date",
          techStack: "Tech stack",
        },
        contribution: {
          name: "Name",
          namePlaceholder: "Contribution name",
          url: "Contribution URL",
          urlPlaceholder: "Contribution URL",
          startDate: "Start date",
          endDate: "End date",
          techStack: "Tech stack",
        },
        talks: {
          eventName: "Event name",
          eventNamePlaceholder: "Event name",
          title: "Title",
          titlePlaceholder: "Talk title",
          date: "Date",
          url: "Event URL",
          urlPlaceholder: "Event URL",
          techStack: "Tech stack",
          videoUrl: "Video URL",
          videoUrlPlaceholder: "Video URL",
        },
        tutor: {
          title: "Title",
          titlePlaceholder: "Event title",
          date: "Date",
          description: "Description",
          descriptionPlaceholder: "Type here information about event/your role",
          techStack: "Tech stack",
        },
      },
    },
    skills: {
      skills: "Skills",
      buttons: {
        save: "Save",
        cancel: "Cancel",
        addTechnology: "Add technology",
        addDomain: "Add domain",
        delete: "Delete",
      },
      legend: {
        technology: "Technology",
        domains: "Domains",
        scale: "Knowledge level",
        enjoymentLevel: "Enjoyment level",
        fulfillments: "Fulfillments",
        difficulties: "Difficulties",
      },
      info: {
        informationTitle: "Info",
        additionally: "Additionally",
        description: `As we would like to know your technology enjoyment, 
          please let us know about it by clicking the enjoyment icon according to the scale below.`,
      },
      noData: {
        noTechnology: "Unknown",
      },
      form: {
        required: "Required",
        deleteQuestion: "Are you sure you want to delete?",
      },
      wantToAddNewTech:
        "Do you want to add a new technology, named: <1>{{ newTechnology }}</1>?",
      addNewTech: "Add",
      areLookingTech: "Are you looking for one of the technologies below?",
      other: "Other",
      searchPlaceholder: "Search skill",
      yourTechnologies: "Your technologies",
      error: "Error",
    },
    summary: {
      section: {
        tutors: "Tutorials",
        degrees: "Degrees",
      },
      roles: "Preferred roles",
      seniority: "Seniority",
      experience: "Experience",
      joinDate: "{{name}} join date",
      professions: "Professions",
      profession: "Profession",
      edit: "Edit",
      totalExperience: {
        years_one: "{{ count }} year",
        years_other: "{{ count }} years",
        months_one: "{{ count}} month",
        months_other: "{{ count }} months",
      },
      languages: "Languages",
    },
    errors: {
      cantBeEmpty: "Field is required",
      cantBeBlank: "Remove the blank space from the text field",
      noFutureDate: "Date cannot be future",
      noEarlierDate: "Date cannot be earlier than {{ date }}",
      cantBeMoreChars:
        "Max number of characters is exceeded, reduce it to {{ number }} characters",
    },
  },
  team: {
    tabs: {
      users_list: "Users list",
      skills_search: "Skills search",
      bench: "Bench",
    },
    usersList: {
      title: "Users list",
      status: "Type",
      name: "Name",
      profession: "Profession",
      seniority: "Seniority",
      location: "Location",
      engagement: "Engagement",
      more: "more",
      less: "less",
      clearFilters: "Clear filters",
      clearUsers: "Clear users",
      backToList: "back to the list",
      generatePdf: "Generate PDF (Full)",
      generatePdfTooltip: "CV with all personal information",
      anonymous: "Generate PDF (Anonymous)",
      anonymousTooltip: "CV without a surname",
      blind: "Generate PDF (Blind)",
      blindTooltip: "CV without a name, surname, photo, and project names",
      showCreatedProfiles: "Show created profiles",
      search: "Search",
    },
    skillsSearch: {
      profession: "Profession",
      mustHave: "Must-have",
      niceHave: "Nice-have",
      mustHaveSkills: "Type Must-have skills",
      niceToHaveSkills: "Type Nice-to-have skills",
      search: "Search",
      available: "Show created users",
      clear: "Clear filters",
      required: "Field is required",
      findTeamMembers: "Find your team members with particular skills",
      startScreenDescription:
        "Here you can find needed team members for your project with particular skills.",
      filterUsers: "Filter users",
      noInformation: "There are no information with this data.",
      tryAgain: "Please, choose another data and try again.",
      showLess: "Show less",
      showMore: "Show more",
    },
  },
  projects: {
    prospect: {
      newProspect: "Create New Prospect",
      searchProspects: "Search",
      detailsHeader: "Details",
      activeProspects: "Active prospects",
      showActive: "Show active",
      showArchived: "Show archived",
      noProspectsTitle: "There are no prospects to display",
      startWorkingTitle: "Start working and add your first prospect",
      addNewModalTitle: "Create New Prospect",
      name: "Prospect",
      client: "Client",
      pm: "PM",
      bd: "BD",
      creationDate: "Creation Date",
      asc: "Ascending",
      desc: "Descending",
      filters: "Filters",
      pill: {
        bd: "BD",
        pm: "PM",
        clientName: "Client",
        showLess: "Show less",
        showMore: "Show more",
      },
      list: {
        prospectName: "Prospect name",
        clientName: "Client",
        PMname: "PM",
        BDname: "BD",
        creationDate: "Creation date",
        daysPassed: "Days passed",
        status: "Status",
        toProject: "to project",
        more: "more",
        empty: "empty",
        active: "Active",
        archived: "Archived",
        actions: {
          toProject: "to project",
          edit: "Edit",
          archive: "Archive",
          delete: "Delete",
          unarchive: "Unarchive",
        },
      },
      sortBar: {
        byName: "By name",
        byDate: "By date",
        byStatus: "By status",
      },

      tabs: {
        details: "Details",
        requirements: "Requirements",
        notes: "Notes",
        discoveryTeam: "Discovery team",
        proposalFolder: "Proposed team",
      },
      details: {
        prospectName: "Prospect name",
        generalNote: "General notes",
        clientName: "Client name",
        contactPersonName: "Contact person",
        contactPersonEmail: "Email",
        client: "Client",
        prospectCountry: "Country of prospect",
        prospectCity: "City of prospect",
        domains: "Domains",
        phoneNumber: "Phone number",
        pm: "Project Manager",
        bd: "Bussiness Development",
        techOwner: "Tech owner",
        note: "General notes",
        projectStatus: "Project status",
        isRequired: "Field is required",
        empty: "empty",
        prospectNamePlaceholder: "Type name of your new Prospect",
        clientPlaceholder: "Choose Client",
        disabledError:
          "This user is disabled. Choose other from the list or remove it with x button.",
      },
      needs: {
        profession: "Profession",
        prospectRequirement: "Prospect Requirement",
        seniority: "Seniority",
        quantity: "Quantity",
        rate: "Expected rate",
        rateUnit: "Rate unit",
        currency: "Currency",
        engagement: "Engagement",
        expectedLanguage: "Language",
        mustHaveTechStack: "Must-have tech stack",
        niceToHaveTechStack: "Nice-to-have tech stack",
        workingTime: "Working hours",
        regions: "Regions",
        zone: "Time zone",
        overlap: "Time overlap",
        sameOverlap: "Overlap",
        preferredCountries: "Preferred countries",
        saveYourChoice: "Save your choice",
        selectAll: "Select all",
        blacklistedCountries: "Non-preferred countries",
        seeAll: "See all",
        noData: "No data",
        collapse: "Collapse",
        search: "Search country",
        demand: "Demand",
        full: "Full time",
        part: "Part time",
        doYouWantToRemoveNeed: "Do you really want to delete this requirement?",
        noReqs: "There are no requirements added",
        defineReq: "Define the first requirement by choosing",
        addReq: "Add new requirement",
        rateMustBeHigherThan0: "Number must be higher than 0",
      },
      notes: {
        title: "Title",
        titlePlaceholder: "Type your note title",
        priority: "Priority",
        description: "Note",
        descriptionPlaceholder: "Type your note",
        doYouWantToRemoveNote: "Do you really want to delete this note?",
        deleteAllNotes: "Delete all your notes?",
        doYouReallyWantTo:
          "Do you really want to delete all your notes for this prospect?",
        noNotes: "There are no notes added",
        startCreating: "Create first note by choosing",
        newNote: "New note",
        addNewNote: "Add new note",
      },
      actions: {
        add: "Add",
        createNewClient: "Create New Client",
        addNew: "Add new",
        cancel: "Cancel",
        close: "Close",
        save: "Save",
        clear: "Clear",
        deleteAll: "Delete all",
        delete: "Delete",
        back: "Back",
        create: "Create",
        edit: "Edit",
        duplicate: "Duplicate",
      },
      proposalFolder: {
        chooseProfession: "Choose profession",
        chooseSeniority: "Choose seniority",
        chooseLocation: "Location",
        name: "Folder name",
        address: "Folder URL",
        PIN: "PIN",
        goToFolder: "Go to folder",
        resumes: "Resumes",
        lastUpdate: "Last update",
        active: "Active",
        anonymized: "Anonymized",
        resumesNumber: "Resumes: {{ number }}",
        run: "Run AI-powered",
        teamMatching: "team matching",
        process: "process",
        or: "or",
        addTeam: "Add Team",
        manually: "manually",
        useML: "Run Team Generator",
        addReqsFirst: "Add requirements first",
        addTeamManually: "Add team manually",
        generateTeam: "Generate entire team",
        specificRequirements: "Specific requirements",
        teamGenerating: "Your team is being generated right now",
        close: "Close",
        matchScore: "Match score",
        noResults: "Sorry, we could not find any candidates.",
        noSearchResults: `No results for "{{ searchValue }}"`,
        tryAgain: "Try again later.",
        chooseCandidates: "Choose your candidates",
        goBack: "Go back",
        folderDescription: "Here you can add candidates to your team",
        search: "Search",
        yes: "Yes",
        no: "No",
        loading: "Loading ....",
        loadingWait: "Please, wait a minute.",
        loadingInformation: "Searching best candidates for you",
        prospectRequirements: "Prospect requirements",
        seniority: "Seniority",
        profession: "Profession",
        location: "Location",
        userName: "User name",
        info: "Info",
        score: "Score",
        asc: "Ascending",
        desc: "Descending",
        teamGenerator: {
          team: "Team #{{ number }}",
          teams: "{{ quantity }} teams",
          haveGenerated: "have been generated, filing the",
          neededPositions: "{{ quantity }} needed positions",
          skillScore: "Skill Score",
          enjoymentScore: "Enjoyment Score",
          seniorityScore: "Seniortity Score",
          languageAdapt: "Language Adaptability",
          timeAdapt: "Time Adaptability",
          skill: "Skill",
          enjoyment: "Enjoyment",
          languageAdaptability: "Language",
          seniority: "Seniority",
          timeAdaptability: "Time",
          cost: "Cost",
          low: "Low",
          medium: "Medium",
          high: "High",
          close: "Close",
          asc: "Ascending",
          desc: "Descending",
          addToFolder: "+ Add to folder",
          added: "Added",
          additionalProfession: "Additional profession: ",
          additionalProfessions: "Additional professions: ",
        },
        socialMedia: {
          linkedIn: "LinkedIn",
          gitHub: "GitHub",
          twitter: "twitter",
        },
        userDetails: {
          experience: "Experience",
          skillsCoverage: "Skills coverage",
          location: "Location",
          timeZone: "Time zone",
          overlap: "Overlap",
          languages: "Languages",
          nationality: "Nationality",
          passports: "Passports",
          visas: "Visas",
          availability: {
            header: "Travel documents",
            passport: "Passport",
            visa: "Visa",
            expirationDate: "Expiration date",
          },
          comfortableHours: "Comfortable hours",
          residence: "Residence",
          work: "Working hours",
          possibleHours: "Possible hours",
          seniority: "Seniority",
          profession: "Profession",
          role: "Preferred roles",
          experienceAmount: "Overall experience",
          years_one: "{{ count }} year",
          years_other: "{{ count }} years",
          months_one: "{{ count }} month",
          months_other: "{{ count }} months",
          info: "Info",
          actions: {
            addToFolder: "Add to folder",
            added: "Added",
          },
        },
        copied: "Copied",
      },
      discoveryTeam: {
        search: "Search",
        chooseUsers: "Choose users",
        more: "more",
        workingHours: "Working hours",
        timeZone: "Time zone",
        workingStatus: "Working status",
        userName: "User name",
        profession: "Profession",
        seniority: "Seniority",
        location: "Location",
        asc: "Ascending",
        desc: "Descending",
        inTeam: "In Discovery Team",
        add: "Add to team",
        noUsers: "There are no users added to the Discovery team",
      },
      errors: {
        clientExists: "Client name already exists",
      },
    },
    client: {
      clientName: "Client name",
      clientPlaceholder: "Type name of new Client",
      contactPersonName: "Contact person name",
      contactPersonEmail: "Contact person email",
      country: "Client country",
      city: "Client city",
      phoneNumber: "Phone number",
      domain: "Domain",
      addNewModalTitle: "Add new client",
      delete: "Delete",
      domains: "Domains",
    },
    errors: {
      cantBeEmpty: "Field is required",
      wrongHoursFormat: "Fix time pattern, eg. 11:00, 09:00",
      cantBeBlank: "Remove the blank space from the text field",
      cantBeMoreThan: "Field must contain less than {{ number }} characters",
      emailPatternNotMatch: "Fix email pattern, eg. name@name.com",
      cantBeMoreChars:
        "Max number of characters is exceeded, reduce it to {{ number }} characters",
    },
  },
  clients: {
    seeAll: "See all ({{ number }})",
    addNewModalTitle: "Create New Client",
    searchClients: "Search",
    activeClients: "Active clients",
    archivedClients: "Archived clients",
    active: "Active",
    archived: "Archived",
    showActive: "Show active",
    showArchived: "Show archived",
    asc: "Ascending",
    desc: "Descending",
    clientName: "Client name",
    activeProspects: "Prospects",
    activeFolders: "Folders",
    activeProjects: "Projects",
    lastUpdate: "Last update",
    status: "Status",
    folders: "Folders",
    prospects: "Prospects",
    projects: "Projects",
    clientDetails: "Details",
    back: "back to list",
    contactPersonName: "Contact person name",
    contactPersonEmail: "Contact person email",
    clientCountry: "Client country",
    clientCity: "Client city",
    phoneNumber: "Phone number",
    domains: "Domains",
    noClients: "There are no clients to display",
    cantArchive:
      "You can’t archive client with active folders, prospects or projects",
    sortBar: {
      clientName: "Client name",
      activeProspects: "Active prospects",
      activeFolders: "Active folders",
      activeProjects: "Active projects",
      lastUpdate: "Last update",
      status: "Status",
    },
    details: {},
    edit: "edit",
    save: "Save",
    cancel: "Cancel",
    errors: {
      cantBeEmpty: "Field is required",
      emailPatternNotMatch: "Fix email pattern, eg. name@name.com",
    },
    more: "more",
  },
  expertise: {
    matrix: {
      tabs: {
        settings: "Settings",
        edit_matrix: "Edit matrix",
        overview: "Overview",
      },
      settings: {
        title: "Matrix customization and information",
        enjoyment: "Enjoyment level",
        scale: "Knowledge level",
        defineEnjoyment: "Define enjoyment level",
        defineScale: "Define knowledge level",
        colourPalette: "Colour palette",
        description: "Description",
        content: "Content...",
      },
      editMatrix: {
        saveYourMatrix: "Save your changes in skills matrix.",
        createMatrix: "Create your skills matrix",
        sureToDelete: "Are you sure you want to delete this item?",
        deleteGroup: "Delete group '{{ name }}'",
        deleteGroupDescription:
          "Do you really want to delete '{{ name }}' group?",
        addGroup: "Add new group",
        addProfession: "Add profession",
        addNewTechnology: "Add new technology",
        technologyDefaultValue: "Technology",
        addTechnology: "Add technology",
        difficulty: "Difficulty",
        newTechnologyGroup: "New technology group",
        companyMatrixSkills: "Company skills matrix",
        companiesMatrix: "Skills Matrix",
        sureToDeleteProfession:
          "Are you sure about deleting profession '{{name}}'?",
        deleteProfessionDesc: `This profession is available in many places in the application, it is possible that users are using it. 
          By deleting a profession you remove the possibility of using it. 
          The operation is irreversible!`,
        cantRemoveProfession: `You cannot remove this profession.\nTo delete a profession, you must first delete all groups belonging to it.
          \nThis operation is not reversible!`,
        noGroup: "There are no groups here",
        noGroupDescription: "Press button to add a new one",
      },
    },
    actions: {
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
    },
    errors: {
      isRequired: "Field is required",
      groupNameExists: "Group name exists in this profession",
      professionNameExists: "Profession name exists",
      technologyNameExists: "Technology name exists in this group",
      cantBeEmpty: "Field is required",
    },
  },
  settings: {},
  chooseProfileScreen: "What is your role in the  working process?",
  prospects: {
    tabs: {
      all_prospects: "All Prospects",
      my_prospects: "My Prospects",
    },
  },
  folders: {
    searchFolders: "Search",
    newFolder: "Create New Folder",
    activeFolders: "Active folders",
    archivedFolders: "Archived folders",
    showActive: "Show active",
    showArchived: "Show archived",
    folderName: "Folder name",
    addClient: "Add",
    noCandidates: "There are no candidates in folder.",
    addCandidates: "Add users to the folder.",
    asc: "Ascending",
    desc: "Descending",
    client: "Client",
    chooseClient: "Choose client",
    folderPlaceholder: "Type name of your new Folder",
    back: "back to list",
    list: {
      client: "Client",
      clientName: "Client name",
      folderName: "Folder name",
      anonymized: "Anonymized",
      folderUrl: "Folder URL",
      foldersPIN: "Folder PIN",
      resumes: "Resumes",
      lastUpdate: "Last update",
      active: "Active",
      archived: "Archived",
      status: "Status",
      more: "more",
      anonymise: "Anonymise",
      addResume: "Add resume",
      noFoldersTitle: "There are no folders to display",
      approved: "Interested",
      rejected: "Not interested",
      profileSent: "Profile sent",
      actions: {
        edit: "Edit",
        archive: "Archive",
        delete: "Delete",
        unarchive: "Unarchive",
      },
      copied: "Copied",
      goToFolder: "Go to folder",
    },
    yes: "Yes",
    no: "No",
    sortBar: {
      byName: "By name",
      byDate: "By date",
      byStatus: "By status",
    },
    creationDate: "creationDate",
    actions: {
      save: "Save",
      cancel: "Cancel",
    },
    errors: {
      required: "Field is required",
      cantBeEmpty: "Field is required",
      cantBeMoreChars: "Field must contain max {{ number }} characters",
    },
    rejectConfirm: "Are you sure you want to remove candidate?",
    remove: "Remove",
  },
  project: {
    tabs: {
      all_projects: "All projects",
      my_projects: "My projects",
    },
    back: "back to list",
    filters: "Filters",
    pill: {
      bd: "BD",
      pm: "PM",
      clientName: "Client",
      showLess: "Show less",
      showMore: "Show more",
    },
    searchBar: {
      search: "Search",
      newProject: "Create New Project",
      activeProjects: "Active projects",
      archivedProjects: "Archived projects",
      showPrivate: "Show private",
      showOpen: "Show open",
      showArchived: "Show archived",
      creationDate: "Creation Date",
      client: "Client",
      name: "Name",
      projectManager: "PM",
      businessDeveloper: "BD",
      asc: "Ascending",
      desc: "Descending",
    },
    sortBar: {
      noResults: `No results for "{{ searchValue }}"`,
    },
    list: {
      anyArchivedProjects: "You have any archived items",
      noProjectsFound: "No projects found",
      name: "Project name",
      clientName: "Client",
      pmName: "PM",
      bdName: "BD",
      creationDate: "Creation date",
      status: "Status",
      usersInProject: "Users",
      more: "more",
      empty: "empty",
      active: "Active",
      archived: "Archived",
      disabled: "Disabled",
      noProjectsTitle: "There are no projects to display",
      error: "Error",

      //
    },
    toggle: {
      active: "Active",
      archived: "Archived",
    },
    newProjectTabs: {
      details: "Details",
      team: "Team",
      fileRepository: "Files repository",
    },
    details: {
      createProjectModalHeader: "Create New Project",
      toggleDescription: "Possibility to log time in Time Tracker",
      private: "Users added to the project",
      openProject: "Open Project",
      allUsers: "(all users have access in the Time Tracker)",
      color: "Change project color",
      add: "Add",
      name: "Name",
      projectNamePlaceholder: "Type name of your new Project",
      contactPerson: {
        firstName: "Contact person",
        email: "Email",
        phoneNumber: "Phone number",
      },
      clientLabel: "Client",
      clientPlaceholder: "Choose Client",
      clientName: "Client name",
      generalNote: "General Note",
      generalNotePlaceholder: "Describe the general purpose of the Project",
      invoiceDetails: {
        header: "Invoice details",
        companyName: "Company name",
        country: "Country",
        zipCode: "ZIP code",
        address: "Address",
        taxNumber: "Tax number / VAT number",
        accountNumber: "Account number for transfers",
      },
      techStack: "Technologies used in project",
      domains: "Project domains",
      pm: "Project Manager",
      bd: "Business Development",
      empty: "empty",
      isRequired: "Field is required",
      lessThan: "Field must contain less than {{ number }} characters",
      emailPatternNotMatch: "Fix email pattern, eg. name@name.com",
      disabledError:
        "This user is disabled. Choose other from the list or remove it with x button.",
    },
    team: {
      noResults: "No results. Try again, please.",
      chooseProfession: "Profession",
      chooseSeniority: "Seniority",
      chooseLocation: "Location",
      emptyActive: "There are no users to display.",
      emptyInactive:
        "There is no inactive user on this list. To deactivate user you have to visit active users list.",
      search: "Search",
      searching: "Searching for the best candidates for you project",
      addUser: "Add User",
      inactiveUsers: "Inactive users",
      location: "Location",
      deactivateUser: "Deactivate user",
      restoreUser: "Restore user",
      edit: "Edit",
      activeUsers: "Active users",
      wait: "Please, wait a minute...",
      name: "Name",
      userName: "User name",
      profession: "Profession",
      seniority: "Seniority",
      asc: "Ascending",
      desc: "Descending",
      startDate: "Start date",
      finishDate: "End date",
      noticePeriod: "Notice period",
      engagement: "Engagement",
      ratePerUser: "Rate per user",
      currency: "Currency",
      rate: "Rate",
      status: "Status",
      addToProject: "Add to project",
      updateMember: "Update member",
      dd: "DD",
      isRequired: "Field is required",
      valueGreater: "{{ field }} must be greater than 0",
      perHour: "Per hour",
      empty: "empty",
      day_0: "0 days",
      day_one: "{{ count }} day",
      day_other: "{{ count }} days",
      deleteUser: "Remove from the team",
      reason: "Removal reason",
      delete: "Remove",
      rejectError: "Choose rejection reason",
      rejected: "Rejected: {{ rejectReason }}",
      restore: "Restore",
      save: "Save",
      cancel: "Cancel",
      showActive: "Show active",
      showInactive: "Show inactive",
      active: "Active",
      inactive: "Inactive",
      disabled: "Disabled",
      close: "Close",
      unspecifiedEnd: "Unspecified end",
      changeConditions: "Change conditions",
      fullTime: "Full time",
      partTime: "Part time",
      wrongDateFormat: "Wrong date format",
      feedbacks: {
        unhappyDev: "Unhappy dev",
        lowProfitability: "Low profitability",
        riskOfClosure: "Risk of closure of contract",
        withoutProject: "Without project",
        onProject: "On project",
        otherReasons: "Other reasons",
        exceptOnProject: "Except on project",
      },
      feedback: "Project feedback",
      options: {
        option0: "Client's decision - Not enough experience, knowledge",
        option1: "Client's decision - Lack of communication, teamwork",
        option2: "Client's Decision - SoW came to an end",
        option3: "Client's Decision - Budget restrictions",
        option4: "Client's Decision - Force Majeure",
        option5: "Our decision - Small revenue",
        option6: "Our decision - Developer not happy with tech-stack",
        option7: "Our decision - Developer not happy with domain",
        option8: "Our decision - Developer not happy with team",
        option9: "Our Decision - Force Majeure",
      },
    },
    errors: {
      cantBeEmpty: "Field is required",
      cantBeBlank: "Remove the blank space from the text field",
      cantBeMoreThan: "Field must contain less than {{ number }} characters",
      noEarlierDate: "Date cannot be earlier than {{ date }}",
      nameExists: "Project with this name already exists",
    },
  },
  finance: {
    tabs: {
      finances: "finances",
      holidays: "holidays",
      holidays_templates: "holidays templates",
    },
    finances: {
      active: "Active",
      inactive: "Inactive",
      edit: "Edit",
      noUsers: "There are no users to display",
      searchBar: {
        activeUsers: "Active users",
        showActive: "Show active",
        showInactive: "Show inactive",
        search: "Search",
        asc: "Ascending",
        desc: "Descending",
        name: "Name",
        seniority: "Serniority",
        profession: "Profession",
        monthly: "Monthly",
        hourly: "Hourly",
        salary: "Salary",
        rate: "Rate",
      },
      sortBar: {
        noResults: `No results for "{{ searchValue }}"`,
        userName: "User name",
        seniority: "Serniority",
        profession: "Profession",
        monthlySalary: "Monthly Salary",
        hourlyRate: "Hourly rate",
        status: "Status",
      },
      modal: {
        monthlySalary: "Monthly Salary",
        currency: "Currency",
        changeDate: "Change Rate Date",
        higherThan: "Number must be higher than 0",
        lowerThan: "Number must be lower than 2147483648",
      },
    },
    addHolidayModal: {
      add: "Add holiday",
      new: "New holiday",
      choose: "Choose leave type",
      dateFrom: "Date from",
      dateTo: "Date to",
      duration: "Duration",
      allDay: "All day",
      halfDay: "Half day",
      errors: {
        cantBeEmpty: "Field is required",
        dateCannotBeEarlier: "Date cannot be earlier than {{ date }}",
        dateCannotBeLater: "Date cannot be later than {{ date }}",
      },
    },
    holidays: {
      search: "Search",
      clearFilters: "Clear filters",
      name: "Name",
      professions: "Professions",
      templateName: "Template",
      usedMainHolidays: "Used/main holidays",
      status: "Status",
      archived: "Archived",
      active: "Active",
      activeUsers: "Active users",
      archivedUsers: "Inactive users",
      more: "more",
    },
    holidaysUser: {
      name: "Name",
      seniority: "Seniority",
      profession: "Profession",
      status: "Status",
      backToList: "back to the list",
      active: "Active",
      holidaysTemplate: "Holidays template",
      noLimit: "No limit",
      chooseTemplate: "Choose template for user",
      noTemplate: "No template chosen",
      addNewTemplate: "Choose holiday template to start manage user holidays",
      holidayType: "Holiday type",
      template: "Template",
      modification: "Modification",
      userTotal: "User total",
      used: "Used",
      unused: "Unused",
      saveChanges: "Save your changes for {{ user }}?",
      save: "Save",
      cancel: "Cancel",
      saveModal: {
        sure: "Are you sure you want to save changes and leave?",
        info: "This template will change for all people in the company",
        backToList: "Back to the list",
        save: "Save",
      },
      chooseTemplateModal: {
        archivedUsers: "Archived users",
        activeUsers: "Active users",
        areYouSure: "Are you sure?",
        yourChoice: "Your choice is:",
        changingWarning:
          "Changing this template will be not possible after confirmation.",
      },
      errors: {
        cantBeEmpty: "Field is required",
        greaterThan: "Modification must be greater than {{ number }}",
      },
      history: {
        dates: "Dates",
        holidayType: "Holiday type",
        amountOfDays: "Amount of days",
        addLeave: "Add leave",
        days: "{{ count }} day",
        days_other: "{{ count }} days",
      },
    },
    holidaysTemplates: {
      addTemplate: "Add template",
      noInfoHere: "There are no information here.",
      youCanAddHolidays: "You can add here holiday templates for users",
      errorNotification: {
        error: "Error!",
        cantRemove: `You cannot remove this template.\nTo delete a template, you must first delete all holidays belonging to it.
        \nThis operation is not reversible!`,
      },
      headerBar: {
        addHoliday: "Add holiday",
      },
      templatesList: {
        template: "template",
        templateName: "Template Name",
        errors: {
          isRequired: "Field is required",
          isUnique: "Template name already exists.",
        },
      },
      holidaysList: {
        categoryName: "Category name",
        limit: "Limit",
        paymentPercentage: "% of payment",
        noLimit: "No limit",
        holiday: "holiday",
        allowPartialDayOff: "Partial day-off",
        days: "Days",
        less: "less",
        more: "more",
        addFirst: "Add first holiday.",
        hereYouCanAdd:
          "Here you can add different types of leave from work to the template.",
        cannotDelete: "You can't delete the main holiday",
        errors: {
          isRequired: "Field is required",
          name: {
            isUnique: "Holiday name already exists.",
          },
          daysOff: {
            length: "Limit must be {{ degreeOf }} than {{ length }}.",
          },
          rate: {
            value:
              "Rate must be equal or {{ degreeOf }} than {{ percentage }}%.",
          },
          onlyWhole: "Only whole numbers from {{ from }} to {{ to }}",
        },
      },
      bottomInfo: {
        saveChanges: 'Save your changes in template "{{ name }}"?',
        createData: "Create {{ type }}?",
        save: "Save",
        create: "Create",
        cancel: "Cancel",
      },
      confirmModal: {
        saveTitle: "Are you sure you want to save it?",
        createTitle: "Are you sure you want to create it?",
        secondaryTextSave:
          "This template will change for each person in the company",
        save: "Save",
        create: "Create",
        cancel: "Cancel",
      },
      deleteModal: {
        deleteTitle: 'Delete {{ type }} "{{ name }}"?',
        secondaryText: "Do you really want to delete this {{ type }}?",
        delete: "Delete",
        cancel: "Cancel",
      },
    },
    // contractors: {
    //   searchBar: {
    //     search: "Search",
    //     includeArchive: "Show archived",
    //     addNew: "Add new contractor",
    //   },
    //   sortBar: {
    //     byName: "By name",
    //     byDate: "By date",
    //     byStatus: "By status",
    //   },
    //   list: {
    //     profession: "Profession",
    //     agreementType: "Agreement type",
    //     userStatus: "Status",
    //     salary: "Salary",
    //     salaryUpdate: "Salary update",
    //     holidays: "Holidays",
    //     startDate: "Start date",
    //     noticePeriod: "Notice period",
    //     status: "Status",
    //     empty: "empty",
    //     days: "days",
    //   },
    //   contractorPage: {
    //     allContractors: "All contractors",
    //     totalResults: "Total results",
    //     yearly: "yearly",
    //     income: "Income",
    //     expenses: "Expenses",
    //     totalCalculations: "Total calculations",
    //     exportData: "Export data",
    //     project: "Project",
    //     client: "Client",
    //     projectCurrency: "Project currency",
    //     projectRate: "Project rate",
    //     salaryUpdate: "Salary update",
    //     startDate: "Start date",
    //     clientsMoney: "Clients money",
    //     notes: "Notes",
    //     editedBy: "Edited by:",
    //   },
    // },
    projects: {
      searchBar: {
        search: "Search",
        includeArchive: "Show archived",
      },
    },
  },
  bench: {
    search: "Search",
    availability: "Availability",
    available: "Available",
    busy: "Busy",
    allUsers: "All users",
    now: "Now",
    name: "Name",
    profession: "Profession",
    seniority: "Seniority",
    location: "Location",
    folderStatus: "Folder status",
    roles: "Roles",
    reason: "Reason",
    daysOnBench: "Days",
    clearFilters: "Clear filters",
    asc: "Ascending",
    desc: "Descending",
    projects: "Projects",
    noProject: "No project",
    foldersStatuses: {
      folderName: "Folder name",
      status: "Status",
      note: "Note",
      inProgress: "Profile sent",
      approved: "Interested",
      rejected: "Not interested",
    },
    sortBar: {
      noResults: `No results for "{{ searchValue }}"`,
    },
  },
  ocr: {
    uploadFailedHeader: "Uploading failed :(",
    uploadFailedBody: "It's a problem with your file or something else",
    uploadNameWhitespace: "File name can't contain white spaces.",
    uploadWaitingHeader: "Please, wait a minute......",
    uploadWaitingBody: "We are processing your CV",
    upload: {
      message: "PDF Upload/drag-and-drop file or",
      browse: "browse computer",
      requirements: "File must be no larger than {{ size }} MB",
      warning:
        "Uploading the new CV clears the data already saved in the profile",
      beta: "BETA VERSION",
    },
    final: {
      header:
        "Now the time has come to fill in the data concerning your skills!",
      content:
        "As it is the most important part of your profile please do not skip it.",
      go: "Go!",
    },
    validation: {
      toBig:
        "Your file is {{ size }} MB, it's too big. Make it smaller and upload again.",
      wrongType:
        "Your file has wrong type. Convert it to {{ extension }} and upload again.",
    },
    next: "Next",
    back: "Back",
    wizard: {
      labels: {
        bio: "Bio",
        experience: "Experience",
        availability: "Travel documents",
        workingHours: "Work hours",
        languages: "Languages",
        goalsCurrentYear: "Development goals {{year}}",
        goalsPreviousYear: "Development goals {{year}}",
        projects: "Projects",
        education: "Education",
        accomplishments: "Accomplishments",
        additionalInfo: "Additional info",
        goals: "Goals",
      },
      education: {
        sectionName: "Section name",
        degree: "Degree",
        course: "Course",
      },
      save: "Save",
      clear: "Clear",
      textFromCV: "Text from CV",
      unsaved: "You have unsaved changes",
      addSection: "You can add section",
      addMoreHeader: "You can add adittional info to your profile.",
      addMoreContent:
        "Choose the appropriate section and add info. Or if you have nothing to add just click button “Close wizard”",
      closeWizard: "Close wizard",
    },
  },
  actions: {
    clearFilters: "Clear filters",
    close: "Close",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    submit: "Submit",
    confirm: "Confirm",
  },
};
