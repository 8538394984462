import { ReactElement, KeyboardEvent, MouseEvent } from "react";
import { uid } from "react-uid";
import { AnyAction } from "redux";
import { useDispatch } from "redux-react-hook";
import { motion } from "framer-motion";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  classJoin,
  getNameFromDictionary,
  getNamesFromDictionary,
} from "@ps/utils";
import { Avatar, Icon, ScrollableWrapper, LinkButton } from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { TEAM_LIST } from "../../../shared/data-cy";
import { resetPostponedUsersList } from "../../store";
import {
  PostponedListProps,
  PostponedListState,
  PostponedListStateReturn,
  IProfileReturn,
} from "./types";
import { ReactComponent as NoAvatarIcon } from "../../../images/no-avatar.svg";
import { ReactComponent as RemoveUserIcon } from "../../../images/remove.svg";
import styles from "./styles.module.css";
import { Keys } from "../../../shared";

const BASE_TRANSLATION_PATH = "team.usersList";

const PostponedList = ({
  postponedUsers,
  onRemoveClick,
  onShowMoreClick,
  watchingProfile,
}: PostponedListProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();

  const mapState = (state: PostponedListState): PostponedListStateReturn => ({
    professionDict:
      [
        ...(state?.dictionaries?.profession || []),
        ...(state?.dictionaries?.operative_profession || []),
      ] || [],
    seniorityDict: state.dictionaries.seniority,
    postponedUsersList: state.teamUsers.postponedUsersList,
  });

  const { professionDict, seniorityDict, postponedUsersList } =
    useMappedStateSelector(mapState);

  const preparedProfessions = (userData: IProfileReturn): string[] | [] =>
    [
      getNameFromDictionary(professionDict, userData?.mainProfession),
      getNamesFromDictionary(userData?.professions || [], professionDict),
    ].filter((item: string): boolean => !!item);

  return (
    <div className="w-80 h-full">
      <LinkButton
        additionalClassName="self-end"
        dataCy={TEAM_LIST}
        disabled={!postponedUsersList?.length}
        onClick={(): AnyAction => resetPostponedUsersList(dispatch)}
      >
        {t(`${BASE_TRANSLATION_PATH}.clearUsers`)}
      </LinkButton>
      <ScrollableWrapper value={45}>
        <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
          <motion.div layout>
            <div className="flex flex-col max-w-80">
              {postponedUsers?.map(
                (user: IProfileReturn): ReactElement => (
                  <div
                    onClick={(): void => onShowMoreClick(user.userId)}
                    className={classJoin(
                      styles.postponedCardGrid,
                      "grid w-inherit",
                      "bg-neutralPrimary-100",
                      "py-6 px-5 first:rounded-t-md last:rounded-b-md hover:opacity-80",
                      !watchingProfile || watchingProfile === user.userId
                        ? "bg-neutralPrimary-100"
                        : "bg-neutralPrimary-85 bg-opacity-50",
                    )}
                    key={uid(user)}
                    role="presentation"
                  >
                    {user?.avatar ? (
                      <Avatar
                        image={user?.avatar}
                        dataCy={TEAM_LIST}
                        width={16}
                        height={16}
                      />
                    ) : (
                      <Icon
                        icon={
                          <NoAvatarIcon className="fill-current text-neutralSecondary-60" />
                        }
                        dataCy={TEAM_LIST}
                        width="16"
                        height="16"
                      />
                    )}
                    <div className="flex flex-col pl-8 pr-2 select-none text-neutralPrimary-20">
                      <span className="font-semibold pb-2">{user.name}</span>
                      <span className="text-xs">
                        {preparedProfessions(user).join(", ")}
                      </span>

                      <span className="text-xs text-success-50">
                        {getNameFromDictionary(seniorityDict, user.seniority)}
                      </span>
                    </div>
                    <RemoveUserIcon
                      className="fill-current text-primary-50 w-4 h-4 mt-1 justify-self-end"
                      onClick={(event: MouseEvent<SVGSVGElement>): void => {
                        event.preventDefault();
                        event.stopPropagation();
                        onRemoveClick(user);
                      }}
                      role="button"
                      tabIndex={0}
                      onKeyDown={(
                        event: KeyboardEvent<SVGSVGElement>,
                      ): void => {
                        if (event.key === Keys.ENTER) {
                          event.preventDefault();
                          event.stopPropagation();
                          onRemoveClick(user);
                        }
                      }}
                    />
                  </div>
                ),
              )}
            </div>
          </motion.div>
        </Scrollbars>
      </ScrollableWrapper>
    </div>
  );
};

export default PostponedList;
