import {
  userFromAPItoModel,
  usersListFromAPItoModel,
  allRolesFromAPIToModel,
} from "./fromAPItoModel";
import { newRoleToAPIModel, updatedRoleToAPIModel } from "./toAPIModel";

export default {
  userFromAPItoModel,
  usersListFromAPItoModel,
  newRoleToAPIModel,
  updatedRoleToAPIModel,
  allRolesFromAPIToModel,
};
