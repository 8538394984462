import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { Button, Modal, ModalContent } from "@ps/ui-components";
import {
  SKILLS_MATRIX_EXPERTISE,
  SKILLS_MATRIX_EXPERTISE_TEMPLATE,
} from "../../../../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../../../../hooks";
import styles from "../../styles.module.css";
import { ReactComponent as SkullImage } from "../../../../../../../images/skull-alert.svg";
import { DeleteModalProps } from "../../types";

const ConfirmDeleteProfessionModal = ({
  onDelete,
  onCancel,
  isOpen,
  name,
}: DeleteModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Modal
      dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_delete_profession`}
      isOpen={isOpen}
      onClose={onCancel}
      height="h-auto"
      width="w-auto"
      withCloseIcon
    >
      <ModalContent
        additionalClassName={classJoin(
          styles.removeModalContent,
          "flex flex-row items-center justify-center py-8 px-0 gap-12",
        )}
      >
        <SkullImage className="w-full" />
        <div className="flex flex-col gap-2 justify-center items-center pt-2">
          <span className="text-2xl font-semibold">
            {t("expertise.matrix.editMatrix.sureToDeleteProfession", { name })}
          </span>
          <span className="text-sm">
            {t("expertise.matrix.editMatrix.deleteProfessionDesc")}
          </span>
          <div className="flex gap-4 pt-2">
            <Button
              dataCy={`${SKILLS_MATRIX_EXPERTISE}_delete`}
              onClick={onDelete}
            >
              {t("expertise.actions.delete")}
            </Button>
            <Button
              dataCy={`${SKILLS_MATRIX_EXPERTISE}_cancel`}
              variant="secondary"
              onClick={onCancel}
            >
              {t("expertise.actions.cancel")}
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmDeleteProfessionModal;
