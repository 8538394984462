import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import { FontSizeSwitch } from "../font-size-provider";
import { TopSectionProps } from "./types";
import { ReactComponent as BellIcon } from "../../images/bell.svg";
import { ReactComponent as LineIcon } from "../../images/line.svg";
import { ReactComponent as EyeIcon } from "../../images/contrast-eye.svg";
import { focusVisibleStyles, Keys } from "../../shared";

const TopSection = ({
  avatar,
  children,
  onClickContrast,
  isNotifyBellVisible,
}: TopSectionProps): ReactElement => (
  <div className="text-neutralSecondary-80 header-section bg-neutralPrimary-100 justify-end mr-10">
    <div className="flex relative items-center">
      <FontSizeSwitch
        additionalClassName="mr-3 mt-1"
        textColor="text-neutralPrimary-20"
      />
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
          if (event.key === Keys.ENTER) onClickContrast();
        }}
        className={classJoin("w-8 h-8 mr-12 rounded-md", focusVisibleStyles)}
      >
        <EyeIcon
          className="w-8 h-8 text-neutralPrimary-20 cursor-pointer"
          onClick={onClickContrast}
        />
      </div>
      {isNotifyBellVisible && (
        <>
          <BellIcon className="h-full" />
          <LineIcon className="h-full mr-5 ml-5" />
        </>
      )}
      {avatar}
    </div>
    {children && children}
  </div>
);

export default TopSection;
