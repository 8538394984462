import { ReactElement, useState, useLayoutEffect } from "react";
import { Label, Tag, Empty as EmptyField } from "@ps/ui-components";
import { NOTE, SKILLS, TECHNOLOGIES } from "../constants";
import { useTranslationWithNamespace } from "../../../../hooks";
import Empty from "../empty";
import { GoalsProps } from "./types";
import { DictionaryEntryModel } from "../../../../dictionaries";
import { SingleGoalDataModel } from "../../../models/types";

const BASE_PATH = `profile.overview`;

const DevelopmentGoals = ({ year, goals, title }: GoalsProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const [goal, setGoal] = useState<SingleGoalDataModel>();

  useLayoutEffect(() => {
    if (goals) setGoal(goals[`${year}`]);
    return () => setGoal(undefined);
  }, [goals]);

  return (
    <div className="flex flex-col gap-6 h-fit">
      {!!goal?.note || goal?.technologies?.length ? (
        <>
          <Label
            dataCy={`${title}-${NOTE}`}
            text={t(`${BASE_PATH}.${title}.${NOTE}`)}
            key={`${BASE_PATH}.${title}.${NOTE}`}
          >
            {!!goal?.note ? (
              <span className="font-normal w-full whitespace-pre-wrap">
                {goal?.note}
              </span>
            ) : (
              <EmptyField />
            )}
          </Label>
          <Label
            dataCy={`${title}-${TECHNOLOGIES}`}
            text={t(`${BASE_PATH}.${title}.${SKILLS}`)}
          >
            {goal?.technologies.length ? (
              <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
                {goal.technologies.map((tech: DictionaryEntryModel) => (
                  <Tag name={tech?.name} dataCy={tech?.id} key={tech?.id} />
                ))}
              </div>
            ) : (
              <EmptyField />
            )}
          </Label>
        </>
      ) : (
        <Empty section={title} />
      )}
    </div>
  );
};

export default DevelopmentGoals;
