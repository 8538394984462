import { AnyAction } from "redux";
import ActionTypes from "./actionTypes";
import { MENU_OPENED_LOCAL_STORAGE } from "../../shared";

const menuReducer = (
  state = localStorage.getItem(MENU_OPENED_LOCAL_STORAGE) === "opened",
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.SET_MENU_OPENED:
      return true;
    case ActionTypes.SET_MENU_HIDDEN:
      return false;
    default:
      return state;
  }
};

export default menuReducer;
