import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { useTabsContext } from "./context";
import { TabProps } from "./types";
import styles from "./styles.module.css";
import { focusVisibleStyles } from "../../shared";

const baseStyle = classJoin.template`
capitalize text-center
select-none text-neutralPrimary-20
bg-neutralPrimary-100 mt-1 gap-0.5
flex flex-col font-semibold rounded-md
`;

const Tab = ({
  className,
  to,
  label,
  width,
  Icon,
  focusedTabId,
}: TabProps): ReactElement => {
  const { activeTab, onChange, baseTabId } = useTabsContext();

  const isActive = activeTab?.toLowerCase() === to.toLowerCase();
  const additionalClass = isActive
    ? `cursor-default ${styles.tabActive}`
    : `cursor-pointer ${styles.tabInactive}`;

  return (
    <button
      id={`${baseTabId}-${to}`}
      autoFocus={`${baseTabId}-${to}` === focusedTabId}
      className={classJoin(
        baseStyle,
        focusVisibleStyles,
        styles.tab,
        className,
        additionalClass,
        width,
      )}
      data-cy={`${baseTabId}-${to}`}
      onClick={() => onChange && onChange(to)}
      tabIndex={onChange ? 0 : undefined}
    >
      <div className="flex items-center gap-1.5">
        {isActive && Icon ? (
          <Icon className="fill-current text-primary-50 w-4 h-4" />
        ) : (
          <></>
        )}
        {label}
      </div>
    </button>
  );
};

export default Tab;
