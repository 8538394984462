import { ReactElement } from "react";
import { uid } from "react-uid";
import { classJoin, useWindowResize } from "@ps/utils";
import { ExperienceExpandedCardProps } from "../../types";
import { useTranslationWithNamespace } from "../../../../hooks";
import styles from "../../styles.module.css";
import ExpandedCard, { CardHeader } from "../../../expanded-card";
import { PROFILE_SUMMARY_PROJECTS } from "../../../../shared/data-cy";
import { renderSectionIcon } from "../../helpers";
import { CURRENT_YEAR } from "../constants";

const BASE_PATH = "profileSummary";

const ExperienceExpandedCard = ({
  data,
  title,
  renderIcon = renderSectionIcon,
  technologyDict,
  Template,
  onClick,
  openedSection,
}: ExperienceExpandedCardProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { width = 0 } = useWindowResize();
  const iSmallScreen = width < 768;

  if (!data?.length) return <></>;

  const isOpen = openedSection === title;

  const titleWithYear = t(`${BASE_PATH}.${title}`, { year: CURRENT_YEAR });

  const prepareExpandDirection = (length: number) => {
    if (length === 1) return "";
    if (iSmallScreen) return "down";
    return "right";
  };
  const renderTemplateHeader = (): ReactElement => (
    <CardHeader
      Icon={renderIcon(title)}
      dataCy={`${PROFILE_SUMMARY_PROJECTS}_skills`}
    >
      <span className="font-extrabold">{titleWithYear}</span>
    </CardHeader>
  );

  return (
    <div
      className={classJoin(
        isOpen || iSmallScreen
          ? "flex-shrink-0 w-full"
          : styles.otherExperienceSection,
        openedSection && !isOpen ? "opacity-30 pointer-events-none" : "",
      )}
      key={title}
    >
      <ExpandedCard
        additionalContentClassName="px-8 py-6 flex flex-col gap-4 w-full h-full"
        additionalCardClassName="shadow-xl"
        isOpen={isOpen}
        expandDirection={prepareExpandDirection(data.length)}
        header={
          <CardHeader
            Icon={renderIcon(title)}
            dataCy={`${PROFILE_SUMMARY_PROJECTS}_skills`}
          >
            <span className="font-extrabold">{`${titleWithYear}  ${
              data.length > 1 ? `(${data.length})` : ""
            }`}</span>
          </CardHeader>
        }
        onClick={() => onClick(isOpen ? "" : title)}
        openLabel={t(`${BASE_PATH}.seeMoreExperiences`)}
      >
        {isOpen ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16 w-full">
            {data.map((item: any, index: number) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //  @ts-ignore
              <Template
                {...item}
                key={uid(item)}
                technologyDict={technologyDict}
                additionalClassName={index % 2 ? "" : "md:border-r md:pl-4"}
                header={renderTemplateHeader()}
              />
            ))}
          </div>
        ) : (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //  @ts-ignore
          <Template
            {...data[0]}
            technologyDict={technologyDict}
            header={renderTemplateHeader()}
          />
        )}
      </ExpandedCard>
    </div>
  );
};

export default ExperienceExpandedCard;
