import { ReactElement } from "react";
import { RowProps } from "../types";

const Row = ({ children, dataCy }: RowProps): ReactElement => (
  <div data-cy={dataCy} className="grid grid-cols-2">
    {children}
  </div>
);

export default Row;
