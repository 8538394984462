const fileToBinary = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => resolve(fileReader.result);
    fileReader.onerror = () => reject;

    fileReader.readAsArrayBuffer(file);
  });
};

export default fileToBinary;
