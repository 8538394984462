import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import { ReactComponent as PenIcon } from "../../../../../images/prospect/pen.svg";
import { ReactComponent as TrashIcon } from "../../../../../images/prospect/trash.svg";
import { focusVisibleStyles, Keys } from "../../../../../shared";
import { HolidaysHistoryActionButtonsProps } from "../types";

const iconsStyles = "w-4 h-4 text-neutralSecondary-41";

const iconDivStyle = classJoin.template`
p-2 bg-neutralPrimary-100 rounded-lg
border border-neutralSecondary-41
`;

const HolidaysHistoryActionButtons = ({
  canRemove,
  canEdit,
  onEdit,
  onRemove,
}: HolidaysHistoryActionButtonsProps): ReactElement => (
  <div className="flex items-center gap-1 xl:gap-5 justify-end mr-1 xl:mr-6">
    <div
      className={classJoin("flex items-center justify-center gap-1 xl:gap-2")}
    >
      {canEdit && (
        <div
          className={classJoin(iconDivStyle, focusVisibleStyles)}
          onClick={onEdit}
          role="button"
          tabIndex={0}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === Keys.ENTER) onEdit();
          }}
        >
          <PenIcon className={iconsStyles} />
        </div>
      )}
      {canRemove && (
        <div
          className={classJoin(iconDivStyle, focusVisibleStyles)}
          onClick={onRemove}
          role="button"
          tabIndex={0}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === Keys.ENTER) onRemove();
          }}
        >
          <TrashIcon className={iconsStyles} />
        </div>
      )}
    </div>
  </div>
);

export default HolidaysHistoryActionButtons;
