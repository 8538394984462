import { ReactElement } from "react";
import TimeRange from "./timeRange";
import { ReactComponent as PossibleIcon } from "../../../../images/profile-summary/possible.svg";
import { ReactComponent as ComfortableIcon } from "../../../../images/profile-summary/comfortable.svg";
import { TimelineProps } from "../../types";

const Timeline = ({
  comfortable,
  possible,
  zone,
}: TimelineProps): ReactElement => (
  <div className="md:border-l w-full md:w-3/12 flex md:justify-start flex-wrap md:flex-col items-start p-8 gap-4 h-auto">
    {comfortable?.start && (
      <TimeRange
        zone={zone}
        hours={comfortable}
        name="comfortable"
        Icon={PossibleIcon}
      />
    )}
    {possible?.start && (
      <TimeRange
        zone={zone}
        hours={possible}
        name="possible"
        Icon={ComfortableIcon}
      />
    )}
  </div>
);

export default Timeline;
