import moment from "moment-timezone";
import { useState, ReactElement } from "react";
import ISOLanguages from "iso-639-1";
import flag from "country-code-emoji";
import { Label, RestrictedWrapper, Tag } from "@ps/ui-components";
import {
  classJoin,
  getNameFromDictionary,
  languageCodeToCountryCode,
  setSeniorityColor,
} from "@ps/utils";
import {
  BLACKLISTED_COUNTRIES,
  MUST_HAVE_TECH_STACK,
  NEEDS,
  NICE_TO_HAVE_TECH_STACK,
  PROSPECT_VIEW,
} from "../../../../constants";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import { NeedViewProps, CountryProps, ContinentProps } from "../../types";
import {
  PROSPECT_FULL_ACCESS,
  PROSPECT_VIEW_RATES,
} from "../../../../../shared/permissions";
import countries from "../countries.json";
import { DictionaryEntryModel } from "../../../../../dictionaries";
import { NeedMapState, NeedMapStateReturn } from "./types";
import styles from "../styles.module.css";
import ExpandWrapper from "./expandWrapper";

const BASE_PATH = `${PROSPECT_VIEW}_${NEEDS}`;

const Need = ({
  expectedRate,
  mustHaveTechStack,
  niceToHaveTechStack,
  profession,
  quantity,
  seniority,
  workingTime,
  blacklistedCountries,
  expectedLanguage,
}: NeedViewProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const translate = (field: string) => t(`projects.prospect.needs.${field}`);

  const mapState = (state: NeedMapState): NeedMapStateReturn => ({
    professionDict: state.dictionaries.profession || [],
    seniorityDict: state.dictionaries.seniority || [],
  });
  const { professionDict, seniorityDict } = useMappedStateSelector(mapState);

  const [blackListedCountriesOpen, setBlackListedCountriesOpen] =
    useState(false);

  const [mustHaveTechStackOpen, setMustHaveTechStackOpen] = useState(false);
  const [niceHaveTechStackOpen, setNiceHaveTechStackOpen] = useState(false);

  const onlyCountries = countries
    .map((continent: ContinentProps) => continent.countries)
    .flat();

  const seniorityName = getNameFromDictionary(seniorityDict, seniority);

  const normalizedSeniority =
    seniorityName.charAt(0).toUpperCase() + seniorityName.slice(1);

  return (
    <>
      <div className={classJoin(styles.needsGrid, "w-full px-6 py-10")}>
        <span className="font-semibold">
          {getNameFromDictionary(professionDict, profession)}
        </span>
        <span className={`${setSeniorityColor(seniority)}`}>
          {normalizedSeniority}
        </span>
        <span className="font-semibold">{quantity}</span>

        <RestrictedWrapper
          permissions={[PROSPECT_VIEW_RATES, PROSPECT_FULL_ACCESS]}
        >
          {expectedRate?.rate ? (
            <span className="border w-fit px-2 rounded">
              {`${expectedRate?.rate} ${expectedRate?.currency}/${expectedRate?.rateUnit}`}
            </span>
          ) : (
            <span>-</span>
          )}
        </RestrictedWrapper>
        <div>
          {expectedRate?.engagement ? (
            <span>
              {translate(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                expectedRate?.engagement?.label || expectedRate?.engagement,
              )}
            </span>
          ) : (
            <span>-</span>
          )}
        </div>
        <span>{`${flag(
          languageCodeToCountryCode(expectedLanguage),
        )} ${ISOLanguages.getName(expectedLanguage)}`}</span>
        <div>
          <span>{workingTime?.coreTime.start}</span>
          <span>&nbsp;-&nbsp;</span>
          <span>{workingTime?.coreTime.end}</span>

          {workingTime?.zone ? (
            <span>
              &nbsp;
              {`${moment
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .tz(workingTime.zone?.value || workingTime.zone)
                .zoneName()}`}
            </span>
          ) : (
            <span>-</span>
          )}
        </div>
        {workingTime?.overlap ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <span>{workingTime.overlap?.label || workingTime.overlap}h</span>
        ) : (
          <span>-</span>
        )}
      </div>

      <div className="flex flex-col gap-y-6 px-6 w-full">
        {blacklistedCountries?.length ? (
          <Label
            dataCy={`${BASE_PATH}_blackListedCountries`}
            text={translate(BLACKLISTED_COUNTRIES)}
          >
            <ExpandWrapper
              threshold={40}
              onArrowClick={() => setBlackListedCountriesOpen((o) => !o)}
              isExpanded={blackListedCountriesOpen}
              initialHeight="h-9"
              outputHeight="h-auto"
              arrowColor="text-error-50"
            >
              <div className="flex flex-wrap gap-2 w-fit">
                {onlyCountries?.map(
                  (country: CountryProps) =>
                    blacklistedCountries.includes(country.code) && (
                      <div key={country.code}>
                        <Tag
                          additionalClassName="bg-error-50 text-primary-50 font-semibold"
                          name={country.name}
                          dataCy={country.code}
                        />
                      </div>
                    ),
                )}
              </div>
            </ExpandWrapper>
          </Label>
        ) : null}

        {mustHaveTechStack.length ? (
          <Label
            dataCy={`${BASE_PATH}_${MUST_HAVE_TECH_STACK}`}
            text={translate(MUST_HAVE_TECH_STACK)}
          >
            <ExpandWrapper
              threshold={40}
              onArrowClick={() => setMustHaveTechStackOpen((o) => !o)}
              isExpanded={mustHaveTechStackOpen}
              initialHeight="h-9"
              outputHeight="h-auto"
              arrowColor="text-primary-50"
            >
              <div className="flex flex-wrap gap-2 w-fit">
                {mustHaveTechStack?.map((tech: DictionaryEntryModel) => (
                  <div key={`${tech?.id}-${MUST_HAVE_TECH_STACK}`}>
                    <Tag name={tech?.name} dataCy={tech?.id} />
                  </div>
                ))}
              </div>
            </ExpandWrapper>
          </Label>
        ) : null}
        {niceToHaveTechStack?.length ? (
          <Label
            dataCy={`${BASE_PATH}_${NICE_TO_HAVE_TECH_STACK}`}
            text={translate(NICE_TO_HAVE_TECH_STACK)}
          >
            <ExpandWrapper
              threshold={40}
              onArrowClick={() => setNiceHaveTechStackOpen((o) => !o)}
              isExpanded={niceHaveTechStackOpen}
              initialHeight="h-9"
              outputHeight="h-auto"
              arrowColor="text-primary-50"
            >
              <div className="flex flex-wrap gap-2 w-fit">
                {niceToHaveTechStack?.map((tech: DictionaryEntryModel) => (
                  <div key={`${tech?.id}-${MUST_HAVE_TECH_STACK}`}>
                    <Tag name={tech?.name} dataCy={tech?.id} />
                  </div>
                ))}
              </div>
            </ExpandWrapper>
          </Label>
        ) : null}
        <div className="bg-neutralSecondary-41 bg-opacity-10 h-1" />
      </div>
    </>
  );
};

export default Need;
