import { ReactElement, useState, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { Scrollbars } from "react-custom-scrollbars-2";
import { LoadingAnyPage, ScrollableWrapper } from "@ps/ui-components";
import { getNameFromDictionary, divideArrayByField } from "@ps/utils";
import { useMappedStateSelector } from "../../../hooks";
import { fetchHolidaysUsers } from "../../store";
import { HolidaysUserModel, HolidaysUsersModel } from "../../models";
import {
  sortHolidaysUsers,
  filterHolidaysUsersBySearch,
  getTemplateFilterOptions,
} from "../../utils";
import { TEMPLATE_NAME } from "./constants";
import { HolidaysMapState, HolidaysMapStateReturn } from "./types";
import SearchRow from "./searchRow";
import SortBar from "./sort-bar";
import Row from "./row";

const Holidays = (): ReactElement => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [isCheckedArchive, setIsCheckedArchive] = useState(false);

  useEffect(() => {
    fetchHolidaysUsers(dispatch);
  }, []);

  const mapState = (state: HolidaysMapState): HolidaysMapStateReturn => ({
    professionDict: state?.dictionaries?.profession || [],
    seniorityDict: state?.dictionaries?.seniority || [],
    activeSorter: state.holidays.sorter,
    filters: state.holidays.filters,
    users: state.holidays.users,
    isLoading: state.requestStatus.isFetchingHolidaysUsers,
  });

  const {
    professionDict,
    seniorityDict,
    activeSorter,
    filters,
    users,
    isLoading,
  } = useMappedStateSelector(mapState);

  const mappedHolidaysUsers = users?.map(
    (user: HolidaysUserModel): HolidaysUserModel => ({
      ...user,
      profession: getNameFromDictionary(professionDict, user.profession),
    }),
  );

  const sortedUsers: HolidaysUsersModel = sortHolidaysUsers(
    mappedHolidaysUsers,
    activeSorter.name,
    activeSorter.order,
  );

  const [holidaysUsers, setHolidaysUsers] =
    useState<HolidaysUsersModel>(sortedUsers);

  const filteredUsers: HolidaysUsersModel = sortedUsers.filter(
    (user: HolidaysUserModel): boolean =>
      !filters[TEMPLATE_NAME].length ||
      filters[TEMPLATE_NAME].includes(user.holidaysTemplate?.[TEMPLATE_NAME]),
  );

  useEffect(() => {
    if (mappedHolidaysUsers.length) setHolidaysUsers(filteredUsers);
  }, [seniorityDict, professionDict, activeSorter, filters, users]);

  const templateFilterOptions: string[] =
    getTemplateFilterOptions(mappedHolidaysUsers);

  const { negatives: onlyArchivedUsers, positives: onlyActiveUsers } =
    divideArrayByField(holidaysUsers, "enabled");

  const filteredUsersBySearch: HolidaysUsersModel = filterHolidaysUsersBySearch(
    isCheckedArchive ? onlyArchivedUsers : onlyActiveUsers,
    searchValue,
  );

  if (isLoading) return <LoadingAnyPage />;

  return (
    <div className="flex flex-col gap-2 h-full w-full">
      <SearchRow
        onSearchValueChange={setSearchValue}
        setIsCheckedArchive={setIsCheckedArchive}
        isCheckedArchive={isCheckedArchive}
        usersListLength={{
          archived: onlyArchivedUsers?.length,
          active: onlyActiveUsers?.length,
        }}
      />
      <ScrollableWrapper>
        <SortBar
          holidaysUsers={filteredUsersBySearch}
          filterOptions={{
            templateFilterOptions,
          }}
        />
        <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
          <div className="flex flex-col w-full">
            {filteredUsersBySearch?.length ? (
              filteredUsersBySearch.map(
                (user: HolidaysUserModel): ReactElement => (
                  <Row
                    user={user}
                    key={user.id}
                    seniorityDict={seniorityDict}
                  />
                ),
              )
            ) : (
              <></>
            )}
          </div>
        </Scrollbars>
      </ScrollableWrapper>
    </div>
  );
};

export default Holidays;
