import { ReactElement, KeyboardEvent } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useMappedState, useDispatch } from "redux-react-hook";
import { Avatar, Icon, Navbar, Tooltip, useThemeMode } from "@ps/ui-components";
import { classJoin, useMappedStateSelector } from "@ps/utils";
import { setMenuHidden, setMenuOpened } from "../../store/menu/actions";
import { MAIN_APP } from "../../shared/data-cy";
import {
  NAVBAR_ITEMS,
  POSSIBLY_HIDDEN_FOR_TENANT,
} from "../../shared/constants";
import { DEFAULT_START_ROUTE } from "../../shared/routes";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { ReactComponent as SmallLogo } from "../../images/smallLogo.svg";
import { ReactComponent as LogoHighContrast } from "../../images/logoHighContrast.svg";
import { ReactComponent as SmallLogoHighContrast } from "../../images/smallLogoHighContrast.svg";
import { ReactComponent as NoAvatarIcon } from "../../images/main/no-avatar.svg";
import { ReactComponent as CloseMenuIcon } from "../../images/main/close-menu.svg";
import {
  focusVisibleStyles,
  Keys,
  MENU_OPENED_LOCAL_STORAGE,
} from "../../shared";
import { prepareAvailableNavbarMenuItems } from "../utils";
import { MenuMapState, MenuMapStateReturn } from "../types";
import { ENABLED_FEATURES } from "../../tenant-domain";

const baseStyle = "rounded mx-3 mt-16 px-4 py-2.5";
const logoStyles = "w-full h-full p-8 my-2";
const smallLogoStyles = "w-8 h-8 m-10";

const showAnimation = {
  hidden: {
    width: "80px",
    transition: {
      duration: 1.5,
    },
  },
  show: {
    width: "200px",
    transition: {
      duration: 1.5,
    },
  },
};

const Menu = (): ReactElement => {
  const dispatch = useDispatch();
  const { isHighContrast } = useThemeMode();

  const mapState = (state: MenuMapState): MenuMapStateReturn => ({
    currentFocusID: state.currentFocusID,
    isMenuVisible: state.isMenuVisible,
    isVisibleForTenant: state.tenantMetadata?.enabledFeatures?.includes(
      ENABLED_FEATURES.FRONTEND_SHOW_ALL_MODULES,
    ),
    login: state.login,
    myProfile: state?.profiles?.myProfile || {},
  });

  const { login, isMenuVisible, isVisibleForTenant } = useMappedState(mapState);
  const { myProfile } = useMappedStateSelector(mapState, "hh");

  const toggle = (): void => {
    if (isMenuVisible) {
      dispatch(setMenuHidden());
      return localStorage.setItem(MENU_OPENED_LOCAL_STORAGE, "hidden");
    }
    dispatch(setMenuOpened());
    return localStorage.setItem(MENU_OPENED_LOCAL_STORAGE, "opened");
  };

  const renderAvatar = (): ReactElement => (
    <div className="shrink-0">
      {myProfile?.avatar ? (
        <Avatar
          image={myProfile?.avatar}
          dataCy={`${MAIN_APP}_avatar`}
          width={8}
          height={8}
        />
      ) : (
        <Icon
          icon={
            <NoAvatarIcon className="fill-current text-neutralSecondary-60 w-full h-full" />
          }
          dataCy={`${MAIN_APP}_noAvatar`}
          width="8"
          height="8"
        />
      )}
    </div>
  );

  const renderLogo = (): ReactElement => {
    if (isMenuVisible)
      return isHighContrast ? (
        <LogoHighContrast className={logoStyles} />
      ) : (
        <Logo className={logoStyles} />
      );
    return isHighContrast ? (
      <SmallLogoHighContrast className={smallLogoStyles} />
    ) : (
      <SmallLogo className={smallLogoStyles} />
    );
  };

  return (
    <div className="h-full flex flex-col justify-self-start z-10 relative">
      <motion.div
        animate={{
          width: isMenuVisible ? "200px" : "80px",
          transition: {
            duration: 0.5,
            type: "spring",
          },
        }}
        className="bg-primary-10 h-full"
      >
        <div
          className={classJoin(
            "w-8 h-8 rounded-full bg-primary-10 absolute top-4 -right-3 cursor-pointer",
            focusVisibleStyles,
          )}
          onClick={toggle}
          role="button"
          tabIndex={0}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === Keys.ENTER) {
              toggle();
            }
          }}
        >
          <CloseMenuIcon
            className={classJoin(
              "text-primary-100 w-5 h-5 absolute top-1.5 right-1",
              isMenuVisible ? "transform duration-100 rotate-180" : "",
            )}
          />
        </div>
        <div className="flex items-center justify-center">
          <AnimatePresence>
            <motion.div
              variants={showAnimation}
              animate="show"
              className="self-center"
            >
              {renderLogo()}
            </motion.div>
          </AnimatePresence>
        </div>
        <Navbar
          dataCy={MAIN_APP}
          navbarItems={prepareAvailableNavbarMenuItems(
            NAVBAR_ITEMS,
            POSSIBLY_HIDDEN_FOR_TENANT,
            isVisibleForTenant,
          )}
          defaultRoute={DEFAULT_START_ROUTE}
          isMenuOpened={isMenuVisible}
          onItemEnter={(id: string): void => {
            setTimeout(() => document.getElementById(id)?.focus(), 1000);
          }}
        />
        <Tooltip
          placement="right"
          backgroundColor="primary-60"
          textColor="primary-100"
          additionalClassName={isMenuVisible ? "hidden" : ""}
          content={
            <div className={isMenuVisible ? "hidden" : "flex flex-col"}>
              <span className="uppercase">{login?.fullName}</span>
              <span
                className={classJoin(
                  "text-xs",
                  isHighContrast ? "text-primary-95" : "text-primary-70",
                )}
              >
                {login?.email}
              </span>
            </div>
          }
        >
          {login?.fullName?.length <= 18 && login?.email?.length <= 25 ? (
            <div
              className={classJoin(
                "flex flex-row",
                baseStyle,
                isHighContrast
                  ? "bg-primary-95 text-primary-30"
                  : "bg-primary-51 text-primary-100",
              )}
            >
              {renderAvatar()}
              {isMenuVisible ? (
                <div className="flex flex-col pl-2">
                  <div className="text-xs uppercase overflow-hidden">
                    {login?.fullName}
                  </div>
                  <div
                    className={classJoin(
                      "grow text-xs",
                      isHighContrast ? "text-primary-30" : "text-primary-70",
                    )}
                  >
                    {login?.email}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div
              className={classJoin(
                baseStyle,
                isHighContrast
                  ? "bg-primary-95 text-primary-30"
                  : "bg-primary-51 text-primary-100",
              )}
            >
              <div className="flex flex-row">
                {renderAvatar()}
                {isMenuVisible ? (
                  <div className="flex flex-col pl-2 justify-center">
                    <div className="text-xs uppercase overflow-hidden">
                      {login?.fullName}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {isMenuVisible ? (
                <>
                  <hr
                    className={classJoin(
                      "mt-2 opacity-1",
                      isHighContrast
                        ? "border-primary-30"
                        : "border-primary-70",
                    )}
                  />
                  <div
                    className={classJoin(
                      "text-xs",
                      isHighContrast ? "text-primary-30" : "text-primary-70",
                    )}
                  >
                    {login?.email}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </Tooltip>
      </motion.div>
    </div>
  );
};

export default Menu;
