export const CARD_PREFIX = "card";
export const EXPANDED_CARD_PREFIX = "expandedCard";
export const INPUT_PREFIX = "input";
export const BUTTON_PREFIX = "btn";
export const LINK_PREFIX = "link";
export const LABEL_PREFIX = "label";
export const SELECT_PREFIX = "select";
export const TABS_PREFIX = "tabs";
export const MIDDLE_SECTION_PREFIX = "middleSection";
export const MIDDLE_CONTENT_PREFIX = "middleContent";
export const TEXTAREA_PREFIX = "textarea";
export const MODAL_PREFIX = "modal";
export const MODAL_OVERLAY_PREFIX = "modal_overlay";
export const MODAL_CLOSE_ICON_PREFIX = `${MODAL_PREFIX}_close_icon`;
export const MODAL_BODY_PREFIX = `${MODAL_PREFIX}_body`;
export const MODAL_HEADER_PREFIX = `${MODAL_PREFIX}_header`;
export const MODAL_CONTENT_PREFIX = `${MODAL_PREFIX}_content`;
export const MODAL_REDIRECT_ALERT = `${MODAL_PREFIX}_redirect_alert`;
export const MODAL_FOOTER_PREFIX = `${MODAL_PREFIX}_footer`;
export const MODAL_REDIRECT_ALERT_CONFIRM = `${MODAL_REDIRECT_ALERT}_confirm`;
export const MODAL_REDIRECT_ALERT_CANCEL = `${MODAL_REDIRECT_ALERT}_cancel`;
export const NOTIFICATION_PREFIX = "notification";
export const TOAST_PREFIX = "toast";
export const ICON_PREFIX = "icon";
export const PROGRESS_BAR_PREFIX = "progressBar";
export const PROGRESS_BAR_STAGE_PREFIX = `${PROGRESS_BAR_PREFIX}-stage`;
export const PROGRESS_BAR_PROGRESS_PREFIX = `${PROGRESS_BAR_PREFIX}-progress`;
export const SLIDER_PREFIX = "slider";
export const RANGE_SLIDER_PREFIX = "range-slider";
export const TAG_PREFIX = "tag";
export const TIME_TRACKER_LABEL_PREFIX = "time-tracker-label";
export const TIME_TRACKER_INPUT_PREFIX = "time-tracker-input";
export const TIME_TRACKER_INPUT_ICON_PREFIX = `${TIME_TRACKER_INPUT_PREFIX}-icon`;
export const AVATAR_PREFIX = "avatar";
export const UPLOADER_PREFIX = "uploader";
export const UPLOADER_INPUT_PREFIX = "uploader-input";
export const IMAGE_UPLOADER_PREFIX = `image-${UPLOADER_PREFIX}`;
export const IMAGE_UPLOADER_BUTTON_PREFIX = `${IMAGE_UPLOADER_PREFIX}-upload-button`;
export const IMAGE_UPLOADER_MODAL_PREFIX = `${IMAGE_UPLOADER_PREFIX}-modal`;
export const IMAGE_UPLOADER_EDITOR_PREFIX = `${IMAGE_UPLOADER_PREFIX}-editor`;
export const IMAGE_UPLOADER_BUTTON_CANCEL_PREFIX = `${IMAGE_UPLOADER_PREFIX}-cancel`;
export const IMAGE_UPLOADER_BUTTON_SAVE_PREFIX = `${IMAGE_UPLOADER_PREFIX}-save`;
export const IMAGE_UPLOADER_SLIDER_ZOOM_PREFIX = `${IMAGE_UPLOADER_PREFIX}-zoom`;
export const COLLAPSE_PREFIX = "collapse";
export const COLLAPSE_PANEL_PREFIX = `${COLLAPSE_PREFIX}-panel`;
export const QUESTION_SCREEN_PREFIX = "question-screen";
export const DATEPICKER_PREFIX = "datepicker";
export const DATEPICKER_SELECTED_DATE_PREFIX = `${DATEPICKER_PREFIX}_selectedDate`;
export const DATEPICKER_PRESET_RANGE_PREFIX = `${DATEPICKER_PREFIX}_presetRange`;
export const NAVBAR = "navbar";
export const NAV_LINK = `${NAVBAR}-link`;
export const TAG_PICKER_PREFIX = "tag-picker";
export const TAG_PICKER_INPUT_PREFIX = `${TAG_PICKER_PREFIX}-input`;
export const SWITCH_PREFIX = "switch";
export const TEXT_SWITCH_PREFIX = "text-switch";
export const TOOLTIP_WRAPPER_PREFIX = "tooltip-wrapper";
export const TOOLTIP_OVERLAY_PREFIX = "tooltip-overlay";
export const SECTION_TAB = "section-tab";
export const TIMELINE_PROPS = "timeline";
export const SECTION_FORM_PROPS = "section-form";
export const SECTION_DISPLAY_CARD = "section-display-card";
export const TIME_TRACKER_PROJECT_NAME_PREFIX = "time-tracker-project-name";
export const TIME_TRACKER_PROJECT_NAME_CIRCLE_PREFIX = `${TIME_TRACKER_PROJECT_NAME_PREFIX}-circle`;
export const CHIP_PREFIX = "chip";
export const TIME_TRACKER_ICON_PREFIX = "time-tracker-icon";
export const TIME_TRACKER_TIME_NAVIGATOR_PREFIX = "time-tracker-time-navigator";
export const TIME_TRACKER_TIME_NAVIGATOR_CALENDAR_ICON_PREFIX = `${TIME_TRACKER_TIME_NAVIGATOR_PREFIX}-calendar-icon`;
export const TIME_TRACKER_TIME_NAVIGATOR_PREVIOUS_WEEK_ICON_PREFIX = `${TIME_TRACKER_TIME_NAVIGATOR_PREFIX}-previous-week-icon`;
export const TIME_TRACKER_TIME_NAVIGATOR_NEXT_WEEK_ICON_PREFIX = `${TIME_TRACKER_TIME_NAVIGATOR_PREFIX}-next-week-icon`;
export const TOOLTIP_EVENT_WRAPPER_PREFIX = "tooltip-event-wrapper";
export const SECTION_TAB_PREFIX = "section-tab";
export const TIMELINE_PREFIX = "timeline";
export const SECTION_FORM_PREFIX = "section-form";
export const SECTION_DISPLAY_CARD_PREFIX = "section-display-card";
export const SECTION_DISPLAY_CARD_EDIT_BUTTON_PREFIX = `${SECTION_DISPLAY_CARD_PREFIX}-edit`;
export const SECTION_DISPLAY_CARD_ICON_PREFIX = `${SECTION_DISPLAY_CARD_PREFIX}-icon`;
export const SCALE_PICKER_PREFIX = "scale-picker";
export const SCALE_PICKER_BUTTON_PREFIX = `${SCALE_PICKER_PREFIX}-button`;
export const CHECKBOX_PREFIX = "checkbox";
export const SKILL_BAR_PREFIX = "skill-bar";
export const SKILL_BAR_VALUE_PREFIX = `${SKILL_BAR_PREFIX}-value`;
export const USER_CARD_PREFIX = "user-card";
export const ICON_TOGGLE_PREFIX = "icon-toggle";
export const ICON_TOGGLE_RIGHT_ICON_PREFIX = `${ICON_TOGGLE_PREFIX}-right-icon`;
export const ICON_TOGGLE_LEFT_ICON_PREFIX = `${ICON_TOGGLE_PREFIX}-left-icon`;
export const ICON_TOGGLE_ICON_PREFIX = `${ICON_TOGGLE_PREFIX}-icon`;
export const ICON_TOGGLE_LABEL_PREFIX = `${ICON_TOGGLE_PREFIX}-label`;
export const PIN_INPUT_PREFIX = "pin-input";
export const PIN_INPUT_FIELD_PREFIX = "pin-input-field";
export const SEARCH_SELECT_PREFIX = "search-select";
export const PAGINATION_PREFIX = "pagination";
export const PAGE_403_PREFIX = "page-403";
export const PAGE_404_PREFIX = "page-404";
export const ROUNDED_BAR_PREFIX = "rounded-bar";
export const ROUNDED_BAR_TITLE_PREFIX = `${ROUNDED_BAR_PREFIX}-title`;
export const ROUNDED_BAR_LABEL_PREFIX = `${ROUNDED_BAR_PREFIX}-label`;
export const PAGE_FOLDER_PREFIX = "page-folder";
export const PAGE_PIN_PREFIX = "page-pin";
export const COLOR_PICKER_PREFIX = "color-picker";
export const SCALE_TILE = "scale-tile";
export const EXPERIENCE = "experience";
export const CIRCLE_CHART_PREFIX = "circle-chart";
export const THEME_TOGGLE_PREFIX = "theme-toggle";
export const CREATABLE_SEARCH_SELECT = "creatable-search-select";
export const INFO_TOOLTIP_PREFIX = "info-tooltip";
export const SEARCH_SELECT_PICKER_PREFIX = "search-select-picker";
export const SEARCH_SELECT_PICKER_INPUT_PICKER = `${SEARCH_SELECT_PICKER_PREFIX}-input`;
export const RATING_PREFIX = "rating";
export const CARD_HEADER = "cardHeader";
export const URL_BUTTON_PREFIX = "urlButton";
export const PROFILE_SUMMARY = "profileSummary";
export const PROFILE_SUMMARY_BASE_INFO = `${PROFILE_SUMMARY}_baseInfo`;
export const PROFILE_SUMMARY_LANGUAGES = `${PROFILE_SUMMARY}_LANGUAGES`;
export const PROFILE_SUMMARY_TIMELINE = `${PROFILE_SUMMARY}_TIMELINE`;
export const PROFILE_SUMMARY_SKILLS = `${PROFILE_SUMMARY}_SKILLS`;
export const PROFILE_SUMMARY_PROJECTS = `${PROFILE_SUMMARY}_PROJECTS`;
export const TABLE = "table";
export const TABLE_HEADER = `${TABLE}_header`;
export const TABLE_LABEL = `${TABLE}_label`;
export const TABLE_ROW = `${TABLE}_row`;
export const TABLE_SEARCH_BAR = `${TABLE}_search_bar`;
export const SKILLS_SEARCH_PICKER_PREFIX = "skills-search-picker";
export const SKILLS_SEARCH_PICKER_INPUT_PREFIX = `${SKILLS_SEARCH_PICKER_PREFIX}-input`;
export const CHECKBOX_SEARCH_SELECT_PREFIX = "checkbox-search-select";
export const CHECKBOX_SEARCH_SELECT_INPUT_PREFIX = `${CHECKBOX_SEARCH_SELECT_PREFIX}-input`;
export const STACKED_BAR_CHART_PREFIX = "stacked-bar-chart";
export const CHIP_INPUT_PREFIX = "chip-input";
export const OCR_UPLOADER = "ocr-uploader";
export const SAVE_CANCEL_INFO_BAR_PREFIX = "save-cancel-info-bar";
export const BUTTONS_GROUP_PREFIX = "buttons-group";
export const TABLE_TAB_PREFIX = "table-tab";
export const WIZARD = "wizard";
export const READONLY_AREA_PREFIX = "readonly-area";
export const TOGGLE_PREFIX = "toggle";
export const BADGE_PREFIX = "badge";
export const OCR_FINAL_MODAL = "ocrFinalModal";
export const PREV_NEXT_NAVIGATION = "prev_next_navigation";
export const STATUS = "status";
export const CIRCLE_BUTTON = "circleButton";
export const MORE_LINK = "more-link";
export const FILTERS = "filters";
export const PILL = "pill";
export const FILTER_DROPDOWN = "filterDropdown";
