const Keys = {
  SPACE: "Space",
  ARROW_UP: "ArrowUp",
  ARROW_DOWN: "ArrowDown",
  ARROW_LEFT: "ArrowLeft",
  ARROW_RIGHT: "ArrowRight",
  ENTER: "Enter",
  TAB: "Tab",
  BACKSPACE: "Backspace",
  ESC: "Escape",
  DELETE: "Delete",
};

export default Keys;
