import { ReactElement, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "redux-react-hook";
import { clearRedirection } from "@ps/alert-modal";
import {
  fetchClients,
  fetchClientsSummary,
  fetchClientSummary,
} from "../../store";
import useMappedStateSelector from "../../../hooks/use-mapped-state-selector";
import DisplayClientDetails from "./displayClientDetails";
import SectionWrapper from "./sectionWrapper";
import { DETAILS } from "../../constants";
import { toggleEditMode } from "../../../store";
import { ClientService } from "../../service";
import {
  ClientSummaryMapState,
  ClientSummaryMapStateReturn,
} from "../../models/types";
import { Client } from "../types";
import EditDetails from "./editDetails";

const Details = (): ReactElement => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    fetchClients(dispatch);
    fetchClientSummary(params.id, dispatch);
    fetchClientsSummary(dispatch);
  }, []);

  const mapState = (
    state: ClientSummaryMapState,
  ): ClientSummaryMapStateReturn => ({
    clientSummary: state?.clients.clientSummary || [],
  });

  const { clientSummary } = useMappedStateSelector(mapState);

  const methods = useForm({
    defaultValues: clientSummary,
  });

  const onSubmit = async (data: Client): Promise<void> => {
    await ClientService.updateClient(params.id, data);
    fetchClientSummary(params.id, dispatch);
    dispatch(toggleEditMode(""));
    dispatch(clearRedirection());
  };

  useEffect(() => {
    methods.reset(clientSummary);
  }, [clientSummary]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="w-full h-full">
        <SectionWrapper
          Edit={EditDetails}
          name={DETAILS}
          section={DETAILS}
          View={DisplayClientDetails}
          onSubmit={onSubmit}
        />
      </form>
    </FormProvider>
  );
};

export default Details;
