import Overview from "./overview";
import Experience from "./experience";
import Skills from "./skills";
import ProfileContainer from "./profileContainer";
import Summary from "./summary";
import OCRWizard from "./OCRWizard";
import {
  OtherProfileSummary,
  PDFGeneratorButton,
} from "./other-profile-summary";

export {
  Overview,
  Experience,
  Skills,
  Summary,
  OtherProfileSummary,
  PDFGeneratorButton,
  OCRWizard,
};

export default ProfileContainer;
