import { useState, ReactElement } from "react";
import dayjs from "dayjs";
import {
  Datepicker2,
  Input,
  Label,
  SearchSelectPicker,
} from "@ps/ui-components";
import { checkUrl, prepareTechStackDictionaryValues } from "@ps/utils";
import { Controller, useFormContext } from "react-hook-form";
import { motion } from "framer-motion";
import { uid } from "react-uid";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { FormSectionProps } from "../../education/types";
import { TECH_STACK } from "../../projects/constants";
import { checkHasEmptyFields } from "../../../helpers";
import { EXPERIENCE_ACCOMPLISHMENT_VIEW } from "../../../../../../shared/data-cy";
import {
  CONTRIBUTIONS,
  END_DATE,
  NAME,
  START_DATE,
  URL_PATH,
  URL_REGEXP_PATTERN,
  PLACEHOLDER,
  ISSUED_DATE,
} from "../constants";
import { ITechStackProposal } from "../../../types";
import {
  DATE_FORMAT,
  FULL_TECH_SEARCH_MAX,
} from "../../../../../../shared/constants";
import styles from "../../styles.module.css";

const MAX_LENGTH_NAME = 100;

const ContributionForm = ({
  fullTextSearch,
  index,
  sourcePath = "contributions",
  technologyDict,
}: FormSectionProps): ReactElement => {
  const { control, getValues, getFieldState, formState, watch, register } =
    useFormContext();
  const { t } = useTranslationWithNamespace();
  const [searchedProposals, setSearchedProposals] = useState<
    ITechStackProposal[] | []
  >([]);

  const getFormFieldName = (fieldName: string): string =>
    `${sourcePath}.${index}.${fieldName}`;

  const fieldName = getFieldState(getFormFieldName(NAME), formState);
  const fieldUrlPath = getFieldState(getFormFieldName(URL_PATH), formState);

  const BASE_PATH = `${EXPERIENCE_ACCOMPLISHMENT_VIEW}_${CONTRIBUTIONS}`;

  const translate = (field: string, isRequired = true): string =>
    `${t(`profile.experience.cards.contribution.${field}`)}${
      isRequired ? "*" : ""
    }`;

  const hasEmptyFields = checkHasEmptyFields(
    getValues([NAME, URL_PATH, START_DATE, TECH_STACK]),
  );

  const errorEmptyMessage = `${t("profile.errors.cantBeEmpty")}`;
  const errorBlankMessage = `${t("profile.errors.cantBeBlank")}`;
  const errorMaxLengthMessage = `${t("profile.errors.cantBeMoreChars", {
    number: MAX_LENGTH_NAME,
  })}`;

  return (
    <div
      className="flex flex-col gap-y-5"
      key={uid(`${CONTRIBUTIONS}-${index}`)}
    >
      <div className="flex flex-row gap-x-5">
        <Label dataCy={`${BASE_PATH}_${NAME}`} text={translate(NAME)}>
          <Input
            dataCy={`${BASE_PATH}_${NAME}`}
            width="w-full"
            autoFocus
            value={watch(getFormFieldName(NAME)) || ""}
            error={!!fieldName.error}
            message={fieldName?.error?.message || ""}
            placeholder={translate(`${NAME}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(NAME), {
              validate: (value) => {
                if (!value?.length && hasEmptyFields) return errorEmptyMessage;
                if (!value?.trim()?.length) return errorBlankMessage;
                if (value?.length > MAX_LENGTH_NAME)
                  return errorMaxLengthMessage;
                return true;
              },
            })}
          />
        </Label>
        <Label dataCy={`${BASE_PATH}_${URL_PATH}`} text={translate(URL_PATH)}>
          <Input
            dataCy={`${BASE_PATH}_${URL_PATH}`}
            width="w-full"
            value={watch(getFormFieldName(URL_PATH)) || ""}
            error={!!fieldUrlPath?.error}
            message={fieldUrlPath?.error?.message || ""}
            placeholder={translate(`${URL_PATH}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(URL_PATH), {
              validate: (value) => {
                return !value?.length && hasEmptyFields
                  ? errorEmptyMessage
                  : true;
              },
              pattern: {
                value: URL_REGEXP_PATTERN,
                message: `${t("profile.experience.errors.urlPatternNotMatch")}`,
              },
              onChange: (event) => checkUrl(event.target.value),
            })}
          />
        </Label>
      </div>
      <div className="flex flex-row gap-x-5 w-full pr-5">
        <div className="w-1/2">
          <motion.div>
            <Label
              text={translate(TECH_STACK)}
              dataCy={`${BASE_PATH}_${TECH_STACK}`}
            >
              <Controller
                name={getFormFieldName(TECH_STACK)}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelectPicker
                    dataCy={TECH_STACK}
                    additionalTagsWrapClassName={styles.techWrapperOther}
                    defaultSelected={prepareTechStackDictionaryValues(
                      value,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      technologyDict,
                    )}
                    onTagSelected={onChange}
                    proposalTags={searchedProposals}
                    onInputChange={(searchValue) => {
                      const result =
                        fullTextSearch &&
                        fullTextSearch(searchValue, FULL_TECH_SEARCH_MAX);
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      setSearchedProposals(result);
                    }}
                    error={!!error}
                    message={error?.message}
                  />
                )}
                rules={{
                  validate: (value) =>
                    !value?.length && hasEmptyFields ? errorEmptyMessage : true,
                }}
              />
            </Label>
          </motion.div>
        </div>
        <div className="flex flex-row justify-start gap-x-5">
          <Label
            text={translate(START_DATE)}
            dataCy={`${BASE_PATH}_${START_DATE}`}
          >
            <Controller
              name={getFormFieldName(START_DATE)}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Datepicker2
                  dataCy={`${BASE_PATH}_${START_DATE}`}
                  error={error?.message}
                  onChange={onChange}
                  selected={value ? dayjs(value) : null}
                  dateFormat="MM/YYYY"
                  timeView={["month", "year"]}
                  type="month"
                />
              )}
              rules={{
                validate: (value) => {
                  if (!value && hasEmptyFields) return errorEmptyMessage;
                  if (value && dayjs().isBefore(value))
                    return `${t("profile.errors.noFutureDate")}`;
                  return true;
                },
              }}
            />
          </Label>
          <Label
            text={translate(END_DATE, false)}
            dataCy={`${BASE_PATH}_${END_DATE}`}
          >
            <Controller
              name={getFormFieldName(END_DATE)}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Datepicker2
                  dataCy={`${BASE_PATH}_${END_DATE}`}
                  error={error?.message}
                  onChange={onChange}
                  selected={value ? dayjs(value) : null}
                  dateFormat="MM/YYYY"
                  timeView={["month", "year"]}
                  type="month"
                />
              )}
              rules={{
                validate: (value) => {
                  const fromDate = getValues(getFormFieldName(START_DATE));
                  return value && fromDate && fromDate.isAfter(value)
                    ? `${t("profile.errors.noEarlierDate", {
                        date: fromDate.format(DATE_FORMAT),
                      })}`
                    : true;
                },
              }}
            />
          </Label>
        </div>
      </div>
    </div>
  );
};

export default ContributionForm;
