import { ReactElement } from "react";
import { Trans } from "react-i18next";
import { Button } from "@ps/ui-components";
import MatrixSkillsTable from "./matrixSkillsTable";
import { useMatrixContext } from "./context";
import { SKILLS_MATRIX } from "../../../shared/data-cy";
import { ReactComponent as InfoIcon } from "../../../images/i-icon.svg";
import { NAMESPACE } from "../../../shared/constants";
import { useTranslationWithNamespace } from "../../../hooks";
import { ProfessionMatrixSearchProps } from "./types";
import { SKILLS_MATRIX_MODES } from "../../shared/consts";

const ProfessionMatrixSearch = ({
  profession,
  professionId,
  searchingValue,
  filteredData,
}: ProfessionMatrixSearchProps): ReactElement => {
  const { mode } = useMatrixContext();
  const { t } = useTranslationWithNamespace();

  const isCreateNewOptionVisible = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any | [],
    trimmedSearchValue: string,
  ): boolean =>
    !data?.find(
      (item) => item.name.toLowerCase() === trimmedSearchValue.toLowerCase(),
    );

  return (
    <>
      <MatrixSkillsTable
        professionSkills={filteredData?.fields || []}
        knowledgeScale={profession?.knowledgeScale?.slice(0, 6)}
        enjoymentScale={profession?.enjoymentScale?.slice(0, 5)}
        professionId={professionId}
        searchedValue={searchingValue}
      />
      {isCreateNewOptionVisible(
        filteredData?.fields || [],
        searchingValue.trim(),
      ) && (
        <div className="w-full rounded-md text-primary-100 bg-primary-50 pl-10 py-4 flex gap-6 items-center mt-10">
          <div className="w-4 h-4 rounded-full bg-primary-100 flex items-center justify-center">
            <InfoIcon className="fill-current text-primary-50" />
          </div>
          <span>
            <Trans i18nKey="profile.skills.wantToAddNewTech" ns={NAMESPACE}>
              <strong>{{ newTechnology: searchingValue }}</strong>
            </Trans>
          </span>
          <Button
            variant="primaryNegative"
            dataCy={`${SKILLS_MATRIX}_addNewTech`}
            disabled={mode !== SKILLS_MATRIX_MODES.USER_EDIT}
            onClick={(): void =>
              filteredData.append({
                technologyId: searchingValue,
                name: searchingValue,
                desc: searchingValue,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                isNew: true,
              })
            }
          >
            {`+ ${t("profile.skills.addNewTech")}`}
          </Button>
        </div>
      )}
    </>
  );
};

export default ProfessionMatrixSearch;
