import { ReactElement } from "react";
import { Empty } from "@ps/ui-components";
import { FoldersListProps } from "./types";
import FolderRow from "./folderRow";
import { FolderModel } from "../../models";

const FoldersList = ({ folders, clientId }: FoldersListProps): ReactElement =>
  folders?.length ? (
    <div className="flex flex-col w-full">
      {folders.map((folder: FolderModel) => (
        <FolderRow key={folder.id} folder={folder} clientId={clientId} />
      ))}
    </div>
  ) : (
    <div className="w-full flex items-center justify-center mt-16">
      <Empty />
    </div>
  );

export default FoldersList;
