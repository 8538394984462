import { ReactElement } from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { useTranslationWithNamespace } from "../../../hooks";
import { TitleComponent } from "../components";
import sharedStyles from "../sharedStyles/sharedStyles";
import { ProfileProps } from "../types";
import { PROFILE } from "../../../shared/constants";

const styles = StyleSheet.create({
  description: {
    color: "#2A2D33",
    fontSize: "10px",
    fontWeight: "thin",
    lineHeight: "1.8px",
    fontFamily: "PoppinsLight",
  },
});

const Profile = ({ bio, isBlind }: ProfileProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <View style={sharedStyles.container}>
      <View>
        <TitleComponent
          text={t(`resume.${PROFILE}`)}
          icon={PROFILE}
          isBlind={isBlind}
        />
      </View>
      <View>
        <Text style={styles.description}>{bio}</Text>
      </View>
    </View>
  );
};

export default Profile;
