import { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import {
  Button,
  Label,
  Modal,
  ModalFooter,
  ModalHeader,
  SearchSelect,
  Datepicker,
} from "@ps/ui-components";
import { prepareDictionarySearchSelect } from "@ps/utils";
import { holidayEntryDurationOptions } from "../../../../shared/constants";
import { ReactComponent as AddHolidayIcon } from "../../../../../images/finance/holidays/holiday.svg";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { HOLIDAY_TYPE, HOLIDAYS } from "../../../../../shared/data-cy";
import {
  CANCEL,
  CHOOSE,
  DATE_FORMAT,
  DATE_FROM,
  DATE_TO,
  DURATION,
  SUBMIT,
} from "../../../../../shared/constants";
import {
  AddHolidayModalProps,
  IHolidayEntry,
  IHolidayHistoryEntry,
  IHolidayListOption,
  IValueLabel,
  UserHolidaysItemModel,
} from "../types";

const AddHolidayModal = ({
  defaultData,
  holidayTypesList,
  isOpen,
  maxDate = null,
  minDate = null,
  onClose,
  onSave,
}: AddHolidayModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const translate = (field: string, required = false): string =>
    `${t(`finance.addHolidayModal.${field}`)}${required ? "*" : ""}`;
  const [isPartialDayOff, setIsPartialDayOff] = useState(false);

  // TODO holidays - fix types after BE
  const defaultValues: IHolidayEntry = {
    typeId: null,
    dateFrom: "",
    dateTo: "",
    duration: defaultData?.duration?.value || holidayEntryDurationOptions[0],
  };

  const { control, reset, clearErrors, getValues, watch, handleSubmit } =
    // TODO holidays - fix types after BE
    useForm({ defaultValues: defaultData || defaultValues });

  useEffect(() => {
    if (isOpen) reset(defaultData);
    else reset(defaultValues);
    reset(defaultData);
  }, [isOpen]);

  const checkIsPartialDayOff = (typeId: string): boolean =>
    holidayTypesList?.find(
      (item: IHolidayHistoryEntry | UserHolidaysItemModel) =>
        item.holidaysId === typeId,
    )?.allowPartialDayOff;

  const holidaysTypesOptions: IHolidayListOption[] =
    prepareDictionarySearchSelect(
      holidayTypesList,
      "holidaysId",
      "holidaysId",
      "name",
      "name",
    );

  const handleCloseModal = (): void => {
    reset();
    clearErrors();
    onClose();
  };

  const onSubmit = async (data: IHolidayEntry): Promise<void> => {
    await onSave(data);
    handleCloseModal();
  };

  const prepareMinDate = (date: Dayjs): boolean =>
    date?.isAfter(getValues(DATE_TO)) ||
    (!minDate ? false : date?.isBefore(minDate)) ||
    date.isAfter(dayjs().add(1, "year").startOf("year").subtract(1, "day"));

  const prepareMaxDate = (date: Dayjs): boolean =>
    !!maxDate ||
    date?.isBefore(minDate) ||
    date?.isBefore(getValues(DATE_FROM)) ||
    date.isAfter(dayjs().add(1, "year").startOf("year").subtract(1, "day"));

  return (
    <Modal
      dataCy={`${HOLIDAYS}_add`}
      isOpen={isOpen}
      onClose={handleCloseModal}
      withCloseIcon
      width="w-max"
      height="h-auto"
      additionalContentClassName="flex flex-col gap-8"
      withYScroll={false}
    >
      <ModalHeader
        title={translate("new")}
        Icon={
          <div className="flex items-center gap-4 mr-3">
            <AddHolidayIcon className="text-neutralSecondary-80 fill-current" />
            <div className="border-r border-neutralSecondary-80 h-10" />
          </div>
        }
      />
      <div className="flex items-center gap-4 h-12">
        <Label dataCy={HOLIDAY_TYPE} text={translate(CHOOSE, true)}>
          <Controller
            name="typeId"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SearchSelect
                dataCy={HOLIDAY_TYPE}
                defaultValue={
                  typeof value === "string"
                    ? holidaysTypesOptions.find(
                        (item: IValueLabel) => item.id === value,
                      )
                    : value
                }
                error={!!error}
                message={error?.message || ""}
                onChange={(newValue) => {
                  onChange(newValue);
                  setIsPartialDayOff(checkIsPartialDayOff(newValue.id));
                }}
                options={holidaysTypesOptions}
                placeholder={translate(CHOOSE)}
                width="w-80"
              />
            )}
            rules={{
              required: {
                value: true,
                message: translate("errors.cantBeEmpty"),
              },
            }}
          />
        </Label>
        <Label
          dataCy={`${HOLIDAYS}_${DATE_FROM}`}
          text={translate(DATE_FROM, true)}
        >
          <Controller
            name={DATE_FROM}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Datepicker
                dataCy={`${HOLIDAYS}_${DATE_FROM}`}
                defaultSelected={value ? [value, undefined] : undefined}
                disabledDate={prepareMinDate}
                error={!!error}
                message={error?.message}
                mode="date-picker"
                onSelected={onChange}
              />
            )}
            rules={{
              required: {
                value: true,
                message: translate("errors.cantBeEmpty"),
              },
              validate: (value: Dayjs | undefined) => {
                if (value?.isAfter(getValues(DATE_TO)))
                  return t(
                    "timesheet.timelog.addHolidayModal.errors.dateCannotBeLater",
                    {
                      date: getValues(DATE_TO)?.format(DATE_FORMAT),
                    },
                  );
                return true;
              },
            }}
          />
        </Label>
        <Label
          dataCy={`${HOLIDAYS}_${DATE_TO}`}
          text={translate(DATE_TO, true)}
        >
          <Controller
            name={DATE_TO}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Datepicker
                dataCy={`${HOLIDAYS}_${DATE_TO}`}
                defaultSelected={value ? [value, undefined] : undefined}
                disabledDate={prepareMaxDate}
                error={!!error}
                message={error?.message || ""}
                mode="date-picker"
                onSelected={onChange}
              />
            )}
            rules={{
              required: {
                value: true,
                message: translate("errors.cantBeEmpty"),
              },
              validate: (value: Dayjs | undefined) => {
                if (value?.isBefore(getValues(DATE_FROM)))
                  return t(
                    "timesheet.timelog.addHolidayModal.errors.dateCannotBeEarlier",
                    {
                      date: getValues(DATE_FROM)?.format(DATE_FORMAT),
                    },
                  );
                return true;
              },
            }}
          />
        </Label>
        {isPartialDayOff && (
          <Label dataCy={`${HOLIDAYS}_${DURATION}`} text={translate(DURATION)}>
            <Controller
              name={DURATION}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SearchSelect
                  dataCy={`${HOLIDAYS}_${DURATION}`}
                  defaultValue={value}
                  disabled={
                    (!watch(DATE_FROM) && !watch(DATE_TO)) ||
                    !watch(DATE_FROM)?.isSame(watch(DATE_TO))
                  }
                  error={!!error}
                  message={error?.message || ""}
                  onChange={onChange}
                  options={holidayEntryDurationOptions}
                  placeholder={translate(DURATION)}
                />
              )}
            />
          </Label>
        )}
      </div>
      <ModalFooter additionalClassName="flex gap-4 mt-4">
        <Button
          dataCy={`${HOLIDAYS}_${SUBMIT}`}
          type={SUBMIT}
          onClick={handleSubmit(onSubmit)}
        >
          {t("actions.submit")}
        </Button>
        <Button
          dataCy={`${HOLIDAYS}_${CANCEL}`}
          variant="secondary"
          onClick={handleCloseModal}
        >
          {t("actions.cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddHolidayModal;
