import { ReactElement } from "react";
import { Modal2, ModalMainText2, Button2 } from "@ps/ui-components";
import { ConfirmRemoveModalProps } from "../notes/types";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { ReactComponent as DeleteMan } from "../../../../../images/prospect/deleteMan.svg";
import { CONFIRM_MODAL } from "../../../../../shared/data-cy";

const ConfirmRemoveModal = ({
  isOpen,
  onClose,
  onConfirm,
  header,
}: ConfirmRemoveModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Modal2
      dataCy={CONFIRM_MODAL}
      isOpen={isOpen}
      onClose={onClose}
      additionalContentClassName="flex justify-center h-full gap-8 py-6 px-9"
      padding="0"
      background="bg-neutralSecondary-90"
      withCloseIcon
    >
      <DeleteMan />
      <div className="flex justify-between h-full flex-col gap-36 p-6">
        <ModalMainText2
          dataCy={CONFIRM_MODAL}
          additionalClassName="flex items-center gap-2"
        >
          <span>{header}</span>
        </ModalMainText2>
        <div className="flex items-center gap-4">
          <Button2
            dataCy={`${CONFIRM_MODAL}_delete_cancel`}
            width="w-1/2"
            variant="secondary"
            type="button"
            onClick={onClose}
          >
            {t("projects.prospect.actions.cancel")}
          </Button2>
          <Button2
            dataCy={`${CONFIRM_MODAL}_delete_confirm`}
            width="w-1/2"
            variant="primary"
            type="button"
            onClick={onConfirm}
          >
            {t("projects.prospect.actions.delete")}
          </Button2>
        </div>
      </div>
    </Modal2>
  );
};

export default ConfirmRemoveModal;
