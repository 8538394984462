import { ReactElement } from "react";
import { Label } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../hooks";
import { SimpleLabelProps } from "./types";

const SimpleLabel = ({
  basePath,
  name,
  value,
}: SimpleLabelProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <Label
      dataCy={`${basePath}-${name}`}
      text={t(`${basePath}.${name}`)}
      fontWeight="font-normal"
      labelFontWeight="font-bold"
      labelFontSize="text-lg"
      labelColor="text-neutralPrimary-20"
      additionalClassName="whitespace-pre-line"
    >
      {value || (
        <span className="text-neutralPrimary-30 font-normal">
          {t(`${basePath}.empty`)}
        </span>
      )}
    </Label>
  );
};

export default SimpleLabel;
