import { KeyboardEvent, ReactElement, useState } from "react";
import { classJoin } from "@ps/utils";
import { Pill, ShowMore } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../hooks";
import { FILTERS, PROJECT, PILL } from "../../constants";
import { ReactComponent as RemoveIcon } from "../../../images/project/remove-x.svg";
import { ReactComponent as ArrowDownIcon } from "../../../images/project/arrow-down.svg";
import { Keys } from "../../../shared";
import { PillData, PillsRowProps } from "./types";

const PillsRow = ({
  pills,
  onRemovePillClick,
}: PillsRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [isFullViewOpen, setIsFullViewOpen] = useState(false);

  return (
    <div className="flex items-center gap-x-4">
      <span className="text-neutralPrimary-20">
        {t(`${PROJECT}.${FILTERS}`)}:
      </span>
      <ShowMore
        isFullViewOpen={isFullViewOpen}
        setIsFullViewOpen={setIsFullViewOpen}
        abbrViewRowAmount={1}
        MoreComponent={
          <Pill
            additionalClassName="gap-1.5 w-max"
            colourClass="bg-neutralPrimary-10 text-neutralPrimary-100"
            dataCy="showMore"
          >
            <div
              className="flex items-center gap-1.5 whitespace-no-wrap"
              onClick={() => setIsFullViewOpen((prev) => !prev)}
              onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
                if (event.key === Keys.ENTER)
                  setIsFullViewOpen((prev) => !prev);
              }}
              role="button"
              tabIndex={0}
            >
              <span className="whitespace-no-wrap">
                {t(
                  `${PROJECT}.${PILL}.${
                    isFullViewOpen ? "showLess" : "showMore"
                  }`,
                )}
              </span>
              <ArrowDownIcon
                className={classJoin(isFullViewOpen && "transform rotate-180")}
              />
            </div>
          </Pill>
        }
      >
        {pills.map((p: PillData) => (
          <Pill
            dataCy={`${PROJECT}.${PILL}`}
            additionalClassName="gap-1.5 w-max"
            key={p.id}
          >
            <span className="whitespace-no-wrap">
              {t(`${PROJECT}.${PILL}.${p.type}`)}
            </span>
            <span className="font-bold whitespace-nowrap">{p.name}</span>
            <button
              className="cursor-pointer whitespace-no-wrap"
              onClick={() => onRemovePillClick(true, p, p.type)}
              onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                if (event.key === Keys.ENTER)
                  onRemovePillClick(true, p, p.type);
              }}
            >
              <RemoveIcon />
            </button>
          </Pill>
        ))}
      </ShowMore>
    </div>
  );
};

export default PillsRow;
