import { FC, useContext } from "react";
import PermissionContext from "./context";
import { RestrictedWrapperProps } from "./types";

const RestrictedWrapper: FC<RestrictedWrapperProps> = ({
  permissions,
  children,
  noPermissionsComponent,
}) => {
  const { hasPermission } = useContext(PermissionContext);

  if (hasPermission(permissions)) {
    return <>{children}</>;
  }

  if (noPermissionsComponent) return <>{noPermissionsComponent}</>;

  return <></>;
};

export default RestrictedWrapper;
