import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { ModalContentProps, ModalFooterProps, ModalHeaderProps } from "./types";
import { useModalContext } from "./context";
import {
  MODAL_CONTENT_PREFIX,
  MODAL_FOOTER_PREFIX,
  MODAL_HEADER_PREFIX,
} from "../../shared/data-cy";

const ModalHeader = ({
  Icon,
  title,
  additionalClassName,
  fontSize = "text-base",
  children,
}: ModalHeaderProps): ReactElement => {
  const { dataCy } = useModalContext();
  const id = `${MODAL_HEADER_PREFIX}-${dataCy}`;
  return (
    <div
      className={classJoin(
        "flex justify-start gap-2 items-center",
        additionalClassName,
      )}
      data-cy={id}
      key={id}
    >
      <div className="flex gap-2 items-center">
        {Icon}
        <div
          className={`${fontSize} text-base font-semibold text-neutralPrimary-20`}
        >
          {title}
        </div>
      </div>

      {children}
    </div>
  );
};

const ModalContent = ({
  additionalClassName,
  children,
  className,
  height = "h-5/6",
  withOverflow = true,
}: ModalContentProps): ReactElement => {
  const { dataCy } = useModalContext();
  const id = `${MODAL_CONTENT_PREFIX}-${dataCy}`;
  return (
    <div
      className={classJoin(
        className,
        additionalClassName,
        withOverflow ? "overflow-y-auto" : "",
        height,
      )}
      data-cy={id}
      key={id}
    >
      {children}
    </div>
  );
};

const ModalFooter = ({
  additionalClassName,
  children,
  className = "flex items-center gap-2",
}: ModalFooterProps): ReactElement => {
  const { dataCy } = useModalContext();
  const id = `${MODAL_FOOTER_PREFIX}-${dataCy}`;
  return (
    <div
      className={`${className} ${additionalClassName}`}
      data-cy={id}
      key={id}
    >
      {children}
    </div>
  );
};

export { ModalHeader, ModalContent, ModalFooter };
