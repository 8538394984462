import React from "react";
import ReactDOM from "react-dom";
import "i18n/i18n";
import { StoreContext } from "redux-react-hook";
import { ThemeModeProvider, FontSizeProvider } from "@ps/ui-components";
import "@ps/styling";
import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";
import App from "App";
import store from "store";
import reportWebVitals from "reportWebVitals";

countries.registerLocale(english);
ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <FontSizeProvider>
        <ThemeModeProvider>
          <App />
        </ThemeModeProvider>
      </FontSizeProvider>
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
