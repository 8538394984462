import { classJoin } from "@ps/utils";
import { ReactElement } from "react";
import { EmptySingleCellProps } from "../types";
import styles from "../styles.module.css";

const EmptySingleCell = ({
  additionalClassName = "",
}: EmptySingleCellProps): ReactElement => (
  <td className={styles.singleCell}>
    <span
      className={classJoin(
        "h-24 border-neutralSecondary-60 border flex flex-col justify-center items-center",
        additionalClassName,
      )}
    />
  </td>
);

export default EmptySingleCell;
