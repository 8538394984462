import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import EnjoymentIcon from "./enjoymentIcon";
import { SingleCellProps } from "../types";
import styles from "../styles.module.css";

const KNOWLEDGE_MAX_SCALE = 5;

const SingleCell = ({
  additionalClassName = "",
  enjoymentScale,
  knowledgeScale,
}: SingleCellProps): ReactElement => (
  <td className={styles.singleCell}>
    <span
      className={classJoin(
        additionalClassName,
        "relative h-24 border-neutralSecondary-60 border flex flex-col justify-center items-center",
      )}
    >
      <span className="flex items-center justify-center font-semibold text-4xl text-neutralPrimary-20">
        {knowledgeScale}
        <span className="text-neutralSecondary-60 text-base pt-2">{`/${KNOWLEDGE_MAX_SCALE}`}</span>
      </span>
      <EnjoymentIcon enjoymentScale={enjoymentScale} />
    </span>
  </td>
);

export default SingleCell;
