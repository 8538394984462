import {
  CSSProperties,
  useState,
  ReactElement,
  useEffect,
  useRef,
} from "react";
import { classJoin } from "@ps/utils";
import { uid } from "react-uid";
import { useThemeMode } from "../theme-mode-provider";
import { SkillBarProps } from "./types";
import { SKILL_BAR_PREFIX, SKILL_BAR_VALUE_PREFIX } from "../../shared/data-cy";

const SkillBar = ({
  dataCy,
  value,
  total,
  color,
  width,
  withLegend = false,
  additionalClassName,
  height = "h-3.5",
}: SkillBarProps): ReactElement => {
  const divRef = useRef<HTMLDivElement>(null);
  const { isHighContrast } = useThemeMode();

  const [newValue, setNewValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setNewValue((prevValue: number | undefined) => {
        const result = (prevValue || 0) + 1;
        return result >= (value || 0) ? value || 0 : result;
      });
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getProgressStyle = (i: number): string => {
    if (!value) {
      return "";
    }

    if (i < value) {
      return "bg-primary-50";
    }

    if (isHighContrast) return "bg-neutralSecondary-10";
    return "bg-primary-95";
  };

  const addBackgroundColor = (i: number): string =>
    color && value && i < value ? color : "";

  useEffect(() => {
    if (!divRef.current) return;

    const divChilds = Array.from(divRef.current.children) as HTMLDivElement[];

    divChilds.forEach((dc: HTMLDivElement, index: number) => {
      const childElement = dc;
      childElement.style.opacity = "0";
      childElement.style.animation = `fade-in 0.5s ${index * 0.1}s forwards`;
    });
  }, []);

  const renderDivs = () =>
    Array.from({ length: total }).map((_, i: number) => {
      const divStyle: CSSProperties = {
        width: `${100 / total}%`,
      };

      const layerDivStyle: CSSProperties = {
        backgroundColor: addBackgroundColor(i),
      };

      const className = classJoin(
        "rounded relative z-0 p-0 m-0",
        getProgressStyle(i),
        height,
      );

      return (
        <div
          key={uid(i)}
          className={className}
          style={divStyle}
          data-cy={`${SKILL_BAR_VALUE_PREFIX}-${dataCy}`}
        >
          <div
            style={layerDivStyle}
            className={classJoin(
              height,
              "rounded absolute z-1 left-0 top-0 w-full",
            )}
          />
        </div>
      );
    });

  return (
    <div className={classJoin(height, "flex items-center gap-2")}>
      <div
        ref={divRef}
        data-cy={`${SKILL_BAR_PREFIX}-${dataCy}`}
        className={classJoin(
          "flex items-center rounded-lg gap-0.5",
          width || "w-36",
          additionalClassName,
          height,
        )}
      >
        {renderDivs()}
      </div>
      {withLegend ? (
        <div className="flex mb-1 font-normal">
          <span className="font-normal">{newValue}</span>
          <span
            className={
              isHighContrast
                ? "text-neutralPrimary-50"
                : "text-neutralSecondary-80"
            }
          >{`/${total}`}</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SkillBar;
