import { KeyboardEvent, ReactElement } from "react";
import { uid } from "react-uid";
import Checkbox from "../../checkbox";
import { SimpleFilterOptionProps } from "./types";

const SimpleFilterOption = ({
  autoFocus,
  onClick,
  onKeyDown,
  item,
  isChecked,
}: SimpleFilterOptionProps): ReactElement => (
  <button
    autoFocus={autoFocus}
    key={uid(item)}
    className="flex gap-2 items-center"
    tabIndex={0}
    onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) =>
      onKeyDown(e, isChecked, item)
    }
    onClick={() => onClick(isChecked, item)}
  >
    <Checkbox dataCy={item.id} checked={isChecked} tabIndex={-1} />
    <span>{item.name}</span>
  </button>
);

export default SimpleFilterOption;
