import { useEffect, ReactElement, KeyboardEvent } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { useDispatch } from "redux-react-hook";
import { setRedirection, clearRedirection } from "@ps/alert-modal";
import { Button, Card, LinkButton, RestrictedWrapper } from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { ReactComponent as EditIcon } from "../../../../images/edit.svg";
import { toggleEditMode } from "../../../../store";
import { PROSPECT, PROSPECT_VIEW } from "../../../constants";
import {
  SectionWrapperProps,
  SectionArrayWrapperMapState,
  SectionWrapperMapStateReturn,
} from "./types";
import { PROSPECT_FULL_ACCESS } from "../../../../shared/permissions";
import { Keys } from "../../../../shared";

const SectionWrapper = ({
  name,
  Edit,
  View,
  section,
}: SectionWrapperProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();

  const mapState = (
    state: SectionArrayWrapperMapState,
  ): SectionWrapperMapStateReturn => ({
    editMode: state.editMode,
    data: state?.prospects?.prospect?.[section] || {} || [],
    domainDict: state.dictionaries?.domain || [],
    technologyDict: state.dictionaries?.technology || [],
    professionDict: state.dictionaries?.profession || [],
    seniorityDict: state.dictionaries?.seniority || [],
  });

  const {
    editMode,
    data,
    domainDict,
    technologyDict,
    professionDict,
    seniorityDict,
  } = useMappedStateSelector(mapState);

  const { clearErrors, reset, control } = useFormContext();

  const { isDirty } = useFormState({
    control,
  });

  useEffect(() => {
    if (isDirty && editMode) dispatch(setRedirection());
  }, [isDirty, editMode]);

  useEffect(() => {
    if (Array.isArray(data) && data.length) {
      reset({ [section]: data });
    } else if (Object.keys(data).map((key) => key)?.length) {
      reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleOnEdit = (value: string) => {
    dispatch(toggleEditMode(value));
    clearErrors();
  };

  const handleOnCancel = () => {
    handleOnEdit("");
    reset();
    dispatch(clearRedirection());
  };

  useEffect(() => {
    return () => {
      handleOnEdit("");
      dispatch(clearRedirection());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card additionalCardClassName="py-5 px-8" dataCy={`${PROSPECT}-${name}`}>
      {editMode === name ? (
        <div
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === Keys.ESC) handleOnCancel();
          }}
          role="presentation"
        >
          <Edit
            domainDict={domainDict}
            seniorityDict={seniorityDict}
            technologyDict={technologyDict}
            professionDict={professionDict}
          />
          <div className="flex flex-col">
            <div className="flex flex-row gap-x-2 px-16 pb-8 justify-end w-9/12">
              <Button dataCy="save" type="submit">
                {t("projects.prospect.actions.save")}
              </Button>
              <Button
                dataCy="cancel"
                variant="secondary"
                onClick={handleOnCancel}
              >
                {t("projects.prospect.actions.cancel")}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-between items-start relative w-full">
          <View
            {...data}
            domainDict={domainDict}
            editButton={
              <RestrictedWrapper permissions={PROSPECT_FULL_ACCESS}>
                <LinkButton
                  dataCy={`${PROSPECT_VIEW}_${name}_editButton`}
                  prefixIcon={<EditIcon className="fill-current" />}
                  onClick={() => handleOnEdit(name)}
                  additionalClassName="flex gap-1"
                  disabled={editMode}
                >
                  {t("profile.experience.actions.editSection")}
                </LinkButton>
              </RestrictedWrapper>
            }
          />
        </div>
      )}
    </Card>
  );
};
export default SectionWrapper;
