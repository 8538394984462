import { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
// eslint-disable-next-line prodigal-son/no-use-mapped-state
import { useMappedState } from "redux-react-hook";
import { useMappedStateSelector } from "../../../hooks";
import { SPECIALIST } from "../../../shared/constants";
import ProjectForm from "../experience/sections/projects/projectForm";
import {
  Availability,
  BasicInfo,
  BasicInfoOperative,
  Bio,
  DevelopmentGoals,
  Languages,
  Work,
} from "../overview/edit";
import AccomplishmentsWrapper from "./accomplishmentsWrapper";
import AdditionalInfoWrapper from "./additionalInfoWrapper";
import {
  ACCOMPLISHMENTS,
  ADDITIONAL_INFO,
  BIO,
  EDUCATION,
  EXPERIENCE,
  GOALS,
  LANGUAGES,
  PROJECTS,
} from "./constants";
import { useOCRWizardContext } from "./context";
import EducationWrapper from "./educationWrapper";
import { OCRWizardState, OCRWizardStateReturns } from "./types";
import { AVAILABILITY, WORKING_HOURS } from "../overview/constants";

const ContentWrapper = (): ReactElement => {
  const { subStep, currentFormName, ocr, fullTextSearch } =
    useOCRWizardContext();

  const mapState = (state: OCRWizardState): OCRWizardStateReturns => ({
    profile: state?.profiles?.myProfile,
    firstName: state?.login?.firstName,
    lastName: state?.login?.secondName,
  });

  const { profile }: OCRWizardStateReturns = useMappedStateSelector(mapState);

  // eslint-disable-next-line prodigal-son/no-use-mapped-state
  const { firstName, lastName } = useMappedState(mapState);

  const { formState } = useFormContext();
  const isDirty = !!Object.keys(formState.dirtyFields).length;

  const renderProperComponent = (name: string, index: number): ReactElement => {
    switch (name) {
      case BIO:
        return <Bio />;
      case EXPERIENCE:
        return profile.profile === SPECIALIST ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <BasicInfo firstName={firstName} lastName={lastName} />
        ) : (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <BasicInfoOperative firstName={firstName} lastName={lastName} />
        );
      case AVAILABILITY:
        return <Availability />;
      case WORKING_HOURS:
        return <Work />;
      case LANGUAGES:
        return <Languages />;
      case GOALS:
        return <DevelopmentGoals />;
      case PROJECTS:
        return (
          <ProjectForm
            fullTextSearch={fullTextSearch}
            index={index}
            isOcr={!ocr?.projects?.at(subStep - 1 || 0) || !isDirty}
          />
        );
      case EDUCATION:
        return <EducationWrapper index={index} />;
      case ACCOMPLISHMENTS:
        return (
          <AccomplishmentsWrapper
            index={index}
            isOcr={!ocr?.accomplishments?.at(subStep - 1 || 0) || !isDirty}
          />
        );
      case ADDITIONAL_INFO:
        return <AdditionalInfoWrapper index={index} />;
      default:
        return <Bio />;
    }
  };

  return <div>{renderProperComponent(currentFormName, subStep)}</div>;
};

export default ContentWrapper;
