import React, { KeyboardEvent, ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { useThemeMode } from "../theme-mode-provider";
import { SECTION_TAB_PREFIX } from "../../shared/data-cy";
import { SectionTabProps } from "./types";
import { focusVisibleStyles, Keys } from "../../shared";

const selectedStyle = (isHighContrast: boolean) => ({
  wrapper:
    "py-4 pl-1 shadow-lg border border-neutralSecondary-80 first:rounded-t-md last:rounded-b-md",
  title: "font-semibold text-neutralPrimary-20",
  icon: {
    wrapper: classJoin(
      isHighContrast
        ? "bg-primary-95 text-neutralSecondary-80"
        : "bg-primary-100",
      "mr-2",
    ),
    svg: isHighContrast ? "bg-primary-95" : "text-primary-50",
    border: "border-primary-50",
  },
});

const notSelectedStyle = (isHighContrast: boolean) => ({
  wrapper: "py-4 pl-1",
  title: "font-semibold text-neutralPrimary-20",
  icon: {
    wrapper: classJoin(
      isHighContrast
        ? "bg-primary-95 text-neutralSecondary-80"
        : "bg-primary-100",
      "mr-2",
    ),
    svg: isHighContrast ? "bg-primary-95" : "text-neutralSecondary-80",
    border: "border-neutralSecondary-80",
  },
});

const disabledStyles = (isHighContrast: boolean) => ({
  title: classJoin(
    isHighContrast ? "opacity-30" : "",
    "font-semibold text-neutralPrimary-50",
  ),
  icon: {
    wrapper: classJoin(
      isHighContrast
        ? "bg-primary-95 text-neutralPrimary-50"
        : "bg-primary-100",
      "mr-2",
    ),
    svg: isHighContrast ? "bg-primary-95" : "text-neutralSecondary-80",
    border: "border-neutralSecondary-80",
  },
});

const Tab = <T,>({
  title,
  icon,
  onClick,
  selected = false,
  tabKey,
  disabled,
  collapsed,
  children,
  onTabClick,
  id,
}: SectionTabProps<T>): ReactElement => {
  const { isHighContrast } = useThemeMode();
  const appliedStyle = selected
    ? selectedStyle(isHighContrast)
    : notSelectedStyle(isHighContrast);
  const disabledStyle = disabledStyles(isHighContrast);

  const handleOnClick = (tKey: T): void => {
    if (onTabClick) onTabClick();
    if (onClick) onClick(tKey);
  };

  return (
    <div
      id={id}
      className={classJoin(
        appliedStyle.wrapper,
        focusVisibleStyles,
        "bg-neutralPrimary-100 last:rounded-b-md",
      )}
      onClick={!disabled ? () => handleOnClick(tabKey) : undefined}
      role={disabled ? "presentation" : "button"}
      tabIndex={disabled ? undefined : 0}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === Keys.ENTER && !disabled) handleOnClick(tabKey);
      }}
      data-cy={`${SECTION_TAB_PREFIX}-${tabKey}`}
    >
      <div className="flex items-center">
        <div
          className={classJoin(
            disabled ? disabledStyle.icon.svg : appliedStyle.icon.svg,
            disabled ? disabledStyle.icon.border : appliedStyle.icon.border,
            "duration-200 mr-3 border-r-2 h-full",
          )}
        >
          {icon &&
            React.cloneElement(icon, {
              backgroundcolor: disabled
                ? disabledStyle.icon.wrapper
                : appliedStyle.icon.wrapper,
            })}
        </div>
        {collapsed ? (
          <></>
        ) : (
          <span
            className={classJoin(
              disabled ? disabledStyle.title : appliedStyle.title,
              "truncate flex-grow mr-1",
            )}
          >
            {title}
          </span>
        )}
        {children && <div className="mr-5">{children}</div>}
      </div>
    </div>
  );
};

export default Tab;
