import { ReactElement } from "react";
import { LoadingAnyPage } from "@ps/ui-components";
import { useMappedStateSelector } from "../../../hooks";
import SearchRow from "./searchRow";
import StartScreen from "./graphics/startScreen";
import NoResults from "./graphics/noResults";
import UsersTable from "./users-table";
import { SkillsSearchMapState, SkillsSearchMapStateReturn } from "./types";

const SkillsSearch = (): ReactElement => {
  const mapState = (
    state: SkillsSearchMapState,
  ): SkillsSearchMapStateReturn => ({
    skillsSearch: state?.skillsSearch,
    isFetchingSkillsSearchQueryResult:
      state.requestStatus.isFetchingSkillsSearchQueryResult,
  });

  const { skillsSearch, isFetchingSkillsSearchQueryResult } =
    useMappedStateSelector(mapState);

  const renderSkillsSearchContainer = (
    children: ReactElement,
  ): ReactElement => (
    <div className="flex flex-col gap-4 w-full">
      <SearchRow />
      {isFetchingSkillsSearchQueryResult ? (
        <LoadingAnyPage additionalClassName="mt-32" />
      ) : (
        children
      )}
    </div>
  );

  if (skillsSearch?.users && skillsSearch?.template)
    return renderSkillsSearchContainer(
      !skillsSearch?.users?.length || !skillsSearch?.template?.length ? (
        <NoResults />
      ) : (
        <UsersTable
          users={skillsSearch?.users}
          template={skillsSearch?.template}
        />
      ),
    );

  return renderSkillsSearchContainer(<StartScreen />);
};

export default SkillsSearch;
