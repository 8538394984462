export const PROJECTS = "projects";
export const USERS = "users";
export const TABLE = "table";
export const NO_DESCRIPTION = "noDescription";
export const REPORT = "report";
export const SUMMARY = "summary";
export const TOTAL = "total";

export const PROJECT_NAME = "projectName";
export const USER_NAME = "userName";
export const NOTE = "note";
