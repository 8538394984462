import { ReactElement } from "react";
import {
  ProjectName,
  Roles,
  TechStack,
  TimeRange,
  Other,
  Domain,
} from "./projectElements";
import { SingleProjectProps } from "../../types";

const SingleProjectList = ({
  project,
  projectRolesDict,
  technologyDict,
  domainsDict,
}: SingleProjectProps): ReactElement => (
  <div className="px-8 pb-6 flex flex-col md:grid grid-cols-3 gap-6">
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-4">
        <div className="flex items-start gap-4">
          <ProjectName name={project?.name || ""} />
        </div>
        <TimeRange
          startDate={project?.startDate}
          endDate={project?.endDate}
          className="text-primary-50 text-sm"
        />
        <Roles
          roles={project.projectRoles}
          projectRolesDict={projectRolesDict}
        />
        <Domain domain={project?.domain} domainDict={domainsDict} />
        <TechStack
          techStack={project.techStack}
          technologyDict={technologyDict}
          section="projects"
        />
      </div>
    </div>
    <Other
      className="flex flex-col items-center col-span-2 mt-10"
      description={project?.description}
      responsibility={project?.responsibility}
    />
  </div>
);

export default SingleProjectList;
