import { useState, ReactElement, MouseEvent, useEffect } from "react";
import {
  Button,
  RestrictedWrapper,
  SearchInput,
  Sorter,
  ActiveSelect,
} from "@ps/ui-components";
import { ReactComponent as NewProspectIcon } from "../../../images/prospect/newProspect.svg";
import CreateProspectModal from "./create-prospect-modal";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../hooks";
import { PROSPECT_FULL_ACCESS } from "../../../shared/permissions";
import {
  SearchBarMapState,
  SearchBarMapStateReturn,
  SearchBarProps,
} from "./types";
import {
  ASC,
  BD,
  CLIENT_NAME,
  CREATION_DATE,
  DESC,
  PM,
  PROSPECT_NAME,
  NAME,
  CLIENT,
  ACTIVE,
  ARCHIVED,
  PROJECT_MANAGER,
  BUSINESS_DEVELOPER,
} from "./constants";
import { ACTIVE_PROSPECTS, PROSPECTS_LIST } from "../../../shared/data-cy";

const BASE_PATH = "projects.prospect";

const SearchBar = ({
  setSearchValue,
  prospectsListLength,
  clientId,
  sorterParameters,
  onSorterClick,
  prospectsCheckers,
  setProspectsCheckers,
  clearFilters,
  disabledClearFilters,
}: SearchBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const mapState = (state: SearchBarMapState): SearchBarMapStateReturn => ({
    clientSummary: state.clients.clientSummary,
  });

  const { clientSummary } = useMappedStateSelector(mapState);

  useEffect(() => {
    if (clientSummary?.archived)
      setProspectsCheckers({ active: false, archived: true });
  }, []);

  const names = [
    {
      name: PROSPECT_NAME,
      label: t(`${BASE_PATH}.${NAME}`),
      isNestedField: true,
    },
    {
      name: CLIENT_NAME,
      label: t(`${BASE_PATH}.${CLIENT}`),
      isNestedField: true,
    },
    {
      name: PROJECT_MANAGER,
      label: t(`${BASE_PATH}.${PM}`),
      isNestedField: true,
    },
    {
      name: BUSINESS_DEVELOPER,
      label: t(`${BASE_PATH}.${BD}`),
      isNestedField: true,
    },
    {
      name: CREATION_DATE,
      label: t(`${BASE_PATH}.${CREATION_DATE}`),
      isNestedField: false,
    },
  ];

  const orders = [
    { order: ASC, label: t(`${BASE_PATH}.${ASC}`) },
    { order: DESC, label: t(`${BASE_PATH}.${DESC}`) },
  ];

  const options = [
    {
      id: ACTIVE,
      label: t(`${BASE_PATH}.showActive`),
      amount: prospectsListLength.active,
      checked: prospectsCheckers.active,
    },
    {
      id: ARCHIVED,
      label: t(`${BASE_PATH}.showArchived`),
      amount: prospectsListLength.archived,
      checked: prospectsCheckers.archived,
    },
  ];

  const handleOptionClick = (e: MouseEvent<HTMLDivElement>): void => {
    const { id } = e.currentTarget;
    setProspectsCheckers((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-4">
        <RestrictedWrapper permissions={PROSPECT_FULL_ACCESS}>
          <>
            {!clientSummary?.archived && (
              <Button
                dataCy="prospect-add-new"
                onClick={() => setIsModalOpen(true)}
                additionalClass="flex items-center gap-2"
              >
                <NewProspectIcon className="fill-current" />
                {t(`${BASE_PATH}.newProspect`)}
              </Button>
            )}
            <CreateProspectModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              defaultClientId={clientId}
            />
          </>
        </RestrictedWrapper>
        <ActiveSelect
          dataCy={`${PROSPECTS_LIST}_${ACTIVE_PROSPECTS}`}
          activeAmount={prospectsListLength.active}
          label={t(`${BASE_PATH}.activeProspects`)}
          options={options}
          onOptionClick={handleOptionClick}
        />
        {!disabledClearFilters && (
          <Button
            variant="secondary"
            dataCy={`${BASE_PATH}.clearFilters`}
            onClick={clearFilters}
          >
            {t("actions.clearFilters")}
          </Button>
        )}
      </div>
      <div className="flex items-center justify-end gap-4">
        <SearchInput
          dataCy="prospect_search"
          placeholder={t(`${BASE_PATH}.searchProspects`)}
          onChange={setSearchValue}
        />
        <Sorter
          activeSorter={sorterParameters}
          names={names}
          orders={orders}
          handleSorterClick={onSorterClick}
        />
      </div>
    </div>
  );
};

export default SearchBar;
