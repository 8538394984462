import { KeyboardEvent, ReactElement, useRef } from "react";
import {
  classJoin,
  getNameFromDictionary,
  prepareDictionarySearchSelect,
  setSeniorityColor,
  useClickOutsideClick,
} from "@ps/utils";
import {
  Avatar,
  Icon,
  RestrictedWrapper,
  SearchSelect,
  useThemeMode,
} from "@ps/ui-components";
import { Link } from "react-router-dom";
import { getName } from "i18n-iso-countries";
import { focusVisibleStyles, Keys } from "../../../../shared";
import { ReactComponent as BinIcon } from "../../../../images/bin.svg";
import { ReactComponent as LocationPinIcon } from "../../../../images/location.svg";
import {
  FOLDER_CANDIDATE,
  FOLDER_CANDIDATE_AVATAR,
  FOLDER_CANDIDATE_SENIORITY,
} from "../../../shared/data-cy";
import { ReactComponent as NoAvatarIcon } from "../../../../images/no-avatar.svg";
import { ProfilesSummaryListModel } from "../../../../profile-domain";
import { ReactComponent as EditIcon } from "../../../../images/prospect/edit.svg";
import { CandidateCardMapState, CandidateCardMapStateReturn } from "../types";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { CURRENT_LANGUAGE_CODE } from "../../../../i18n/constants";
import { APPROVED, REJECTED } from "../../../constants";
import { CandidateCardProps } from "./types";
import { PROSPECT_FULL_ACCESS } from "../../../../shared/permissions";

const CandidateCard = ({
  candidate,
  candidateEditingSeniority,
  candidateStatus,
  containerRef,
  handleOnUpdateCandidateSeniority,
  isFolderArchived,
  setCandidateDeleteModalVisible,
  setCandidateEditingSeniority,
  isProspectArchived,
  folderId,
}: CandidateCardProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();
  const mapState = (
    state: CandidateCardMapState,
  ): CandidateCardMapStateReturn => ({
    seniorityDict: state.dictionaries?.seniority || [],
    professionsDict: state.dictionaries?.profession || [],
    profilesSummaryList: state?.profiles?.profilesSummaryList || [],
  });

  const { professionsDict, seniorityDict, profilesSummaryList } =
    useMappedStateSelector(mapState);

  const selectRef = useRef<HTMLDivElement>(null);

  useClickOutsideClick(selectRef, () => setCandidateEditingSeniority(""));

  const renderBorderColor = (status?: string): string => {
    switch (status) {
      case APPROVED:
        return "border-other-toxicGreen border-2";
      case REJECTED:
        return "border-error-70 border-2";
      default:
        return "border-neutralSecondary-60 border";
    }
  };

  const seniorityDictForSearchSelect = prepareDictionarySearchSelect(
    seniorityDict,
    "id",
    "id",
    "name",
    "name",
  );

  const renderStatus = (status?: string): ReactElement =>
    status && (status === APPROVED || status === REJECTED) ? (
      <div className="flex gap-x-2 items-center">
        <div
          className={classJoin(
            status === APPROVED ? "bg-other-toxicGreen" : "bg-error-70",
            "h-4 w-4 rounded-full",
          )}
        />
        <span
          className={classJoin(
            status === APPROVED ? "text-success-50" : "text-error-50",
            isHighContrast && "text-neutralPrimary-30",
            "uppercase tracking-wide font-semibold",
          )}
        >
          {t(`folders.list.${status}`)}
        </span>
      </div>
    ) : (
      <div className="flex gap-x-2 items-center">
        <div className="h-4 w-4 rounded-full border-2" />
        <span className="uppercase tracking-wide font-semibold text-neutralPrimary-50">
          {t(`folders.list.profileSent`)}
        </span>
      </div>
    );

  const seniorityName = getNameFromDictionary(
    seniorityDict,
    candidate?.seniority,
  );

  const normalizedSeniority =
    seniorityName.charAt(0).toUpperCase() + seniorityName.slice(1);

  return (
    <div
      data-cy={FOLDER_CANDIDATE}
      className={classJoin(
        "flex flex-col gap-3 rounded-2xl p-4 shadow-lg",
        renderBorderColor(candidateStatus),
      )}
      key={candidate.userId}
    >
      <div className="flex justify-between">
        <div className="flex flex-col gap-1">
          {profilesSummaryList.map((profile: ProfilesSummaryListModel) =>
            profile.userId === candidate.userId && profile.mainProfession ? (
              <span className="text-neutralPrimary-30">
                {getNameFromDictionary(professionsDict, profile.mainProfession)}
              </span>
            ) : (
              <></>
            ),
          )}
          {/* TODO To object (main, secondary professions) after BE changes */}
          <div className="flex items-center justify-between gap-4">
            {candidateEditingSeniority === candidate.userId ? (
              <div className="h-12 flex items-center">
                <SearchSelect
                  ref={selectRef}
                  dataCy={FOLDER_CANDIDATE_SENIORITY}
                  options={seniorityDictForSearchSelect}
                  defaultValue={seniorityDictForSearchSelect.find(
                    (element: { value: string }) =>
                      element.value.toLowerCase() ===
                      candidate?.seniority.toLowerCase(),
                  )}
                  width="w-40"
                  onChange={(newValue: { value: string }) =>
                    handleOnUpdateCandidateSeniority(candidate, newValue.value)
                  }
                  autoFocus
                  searchable
                  containerRef={containerRef}
                  closeMenuOnScroll={(e: Event) => {
                    // eslint-disable-next-line
                    // @ts-ignore
                    return e?.target?.contains(containerRef.current);
                  }}
                  menuPosition="fixed"
                />
              </div>
            ) : (
              <div className="flex flex-col gap-1 h-12">
                <div className="flex items-center gap-2">
                  <RestrictedWrapper permissions={PROSPECT_FULL_ACCESS}>
                    {!isFolderArchived ? (
                      <EditIcon
                        data-cy={`${FOLDER_CANDIDATE_SENIORITY}_edit-btn`}
                        className={classJoin(
                          "text-neutralPrimary-30 hover:text-neutralPrimary-20 w-4 h-4 cursor-pointer",
                          focusVisibleStyles,
                        )}
                        onClick={() =>
                          setCandidateEditingSeniority(candidate.userId)
                        }
                        role="button"
                        tabIndex={0}
                        onKeyDown={(event: KeyboardEvent<SVGSVGElement>) => {
                          if (event.key === Keys.ENTER)
                            setCandidateEditingSeniority(candidate.userId);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </RestrictedWrapper>
                  {candidate?.seniority ? (
                    <span className={setSeniorityColor(seniorityName)}>
                      {normalizedSeniority}
                    </span>
                  ) : (
                    <span className="text-neutralPrimary-20 font-bold text-2xl">
                      -
                    </span>
                  )}
                </div>
                {candidate?.country?.value ? (
                  <div className="flex gap-2 items-center text-neutralPrimary-20 h-6">
                    {/* TODO check if language value works or other languages */}
                    <LocationPinIcon className="text-primary-70" />
                    <span className="text-neutralPrimary-30">
                      {getName(
                        candidate?.country?.value,
                        CURRENT_LANGUAGE_CODE,
                      )}
                    </span>
                  </div>
                ) : (
                  <span className="h-6" />
                )}
              </div>
            )}
          </div>
        </div>
        {candidate?.avatar ? (
          <Avatar
            image={candidate?.avatar}
            dataCy={FOLDER_CANDIDATE_AVATAR}
            width={16}
            height={16}
          />
        ) : (
          <Icon
            icon={<NoAvatarIcon className="text-neutralSecondary-60" />}
            dataCy={FOLDER_CANDIDATE_AVATAR}
            size="16"
          />
        )}
      </div>
      <div data-cy={`${FOLDER_CANDIDATE}_link`}>
        <Link
          to={`/folders/${folderId}/${candidate.userId}`}
          className={classJoin(
            "text-primary-50 cursor-pointer hover:opacity-50 hover:underline w-auto rounded-md font-semibold mr-auto",
            focusVisibleStyles,
          )}
          target="_blank"
          rel="noopener noreferrer"
        >{`${candidate.firstName} ${candidate.lastName}`}</Link>
      </div>
      <div className="flex items-center justify-between gap-2 h-8">
        <div data-cy={`${FOLDER_CANDIDATE}_status`}>
          {renderStatus(candidateStatus)}
        </div>
        <RestrictedWrapper permissions={PROSPECT_FULL_ACCESS}>
          {!isProspectArchived ? (
            <div
              data-cy={`${FOLDER_CANDIDATE}_delete-btn`}
              className={classJoin(
                classJoin.template`
                  text-neutralSecondary-60 self-end border border-neutralSecondary-60 
                  hover:text-error-50 hover:border-error-50 h-8 w-8 rounded-lg cursor-pointer
                  flex justify-center items-center`,
                focusVisibleStyles,
              )}
              onClick={() => setCandidateDeleteModalVisible(candidate.userId)}
              role="button"
              tabIndex={0}
              onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
                if (event.key === Keys.ENTER)
                  setCandidateDeleteModalVisible(candidate.userId);
              }}
            >
              <BinIcon />
            </div>
          ) : (
            <></>
          )}
        </RestrictedWrapper>
      </div>
    </div>
  );
};

export default CandidateCard;
