import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../../../hooks";
import { ReactComponent as NoResumesIcon } from "../../../images/noResumes.svg";

const BASE_PATH = "folders";

const NoResumes = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <div className="flex w-full justify-center items-center h-full">
      <div className="flex items-center justify-center gap-20 text-neutralPrimary-20 col-span-4 text-lg">
        <NoResumesIcon />
        <div className="flex flex-col">
          <span className="font-semibold">
            {t(`${BASE_PATH}.noCandidates`)}
          </span>
          <span>{t(`${BASE_PATH}.addCandidates`)}</span>
        </div>
      </div>
    </div>
  );
};

export default NoResumes;
