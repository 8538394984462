import APIService from "../api/team-api";
import skillsSearchQueryResultFromAPIToModel from "../models";
import { handleError } from "../../shared";

const SkillsSearchService = (() => {
  const getSkillsSearchQueryResult = async (
    professionId: string,
    mustHaveSkills: string[],
    niceToHaveSkills?: string[] | [],
  ) => {
    try {
      const mustHaveSkillsToString = mustHaveSkills.join(",");
      const niceToHaveSkillsToString = niceToHaveSkills?.length
        ? niceToHaveSkills.join(",")
        : undefined;
      const result =
        await APIService.API.getUsersWithFulfillmentsForSkillsAndProfession(
          professionId,
          mustHaveSkillsToString,
          niceToHaveSkillsToString,
        );
      if (result?.data) {
        return skillsSearchQueryResultFromAPIToModel(result.data);
      }
      return {};
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    getSkillsSearchQueryResult,
  };
})();

export default SkillsSearchService;
