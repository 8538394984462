import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { NotificationProps } from "./types";
import { ReactComponent as CloseIcon } from "../../images/close-notification-icon.svg";
import notificationStyles from "./styles";
import { NOTIFICATION_PREFIX } from "../../shared/data-cy";

const Notification = ({
  action,
  additionalClassName,
  dataCy,
  description,
  id,
  onClose,
  title,
  variant,
}: NotificationProps): ReactElement => {
  const {
    icon: Icon,
    backgroundColor,
    borderColor,
  } = notificationStyles[variant];

  return (
    <div
      className={classJoin(
        "relative border rounded-md p-2 pl-3 w-full z-30",
        `bg-${backgroundColor}`,
        `border-${borderColor}`,
        additionalClassName,
      )}
      data-cy={`${NOTIFICATION_PREFIX}-${dataCy}-${id}`}
    >
      <div className="flex flex-row">
        <span className="mr-3 pt-1">
          <Icon
            className={classJoin(`text-${borderColor}`, "fill-current")}
            data-cy={`svg-${variant}-icon`}
          />
        </span>
        <div className="flex flex-col mr-5">
          <span className="font-semibold whitespace-nowrap">{title}</span>
          <div className="w-full flex flex-row items-center">
            <span className="mr-1">{description}</span>
            <span>{action}</span>
          </div>
        </div>
        <button
          className="absolute top-0.5 right-0.5 cursor-pointer focus:outline-none"
          onClick={onClose}
        >
          <CloseIcon
            className={classJoin(`text-${borderColor}`, "fill-current")}
            data-cy="svg-close-icon"
          />
        </button>
      </div>
    </div>
  );
};
export default Notification;
