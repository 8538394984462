import Axios from "axios";
import { Store } from "redux";
import { uid } from "react-uid";
import {
  addNotification,
  DEFAULT_NOTIFICATION_DURATION,
  NotificationTypes,
} from "@ps/notifications";

interface IMessage {
  message: string;
  type?: string;
  timeoutDuration?: number;
}

const getAxiosInstance = () => {
  let isInterceptorSet = false;
  const axios = Axios.create();

  const addNotificationErrorsFactory =
    (store: Store) => (messages: IMessage[] | []) => {
      messages?.forEach((message: IMessage) => {
        const notification = {
          type: "error" as NotificationTypes,
          timeoutDuration: DEFAULT_NOTIFICATION_DURATION,
          message,
        };

        store.dispatch(
          addNotification({
            ...notification,
            id: uid(notification),
          }),
        );
      });
    };

  const addNotificationsFactory =
    (store: Store) => (messages: IMessage[] | []) => {
      messages?.forEach((message: IMessage) => {
        const notification = {
          type: "error" as NotificationTypes,
          timeoutDuration: DEFAULT_NOTIFICATION_DURATION,
          ...message,
        };

        store.dispatch(
          addNotification({
            ...notification,
            id: uid(notification),
          }),
        );
      });
    };

  return (store: Store) => {
    if (!isInterceptorSet) {
      isInterceptorSet = true;
      const addNotifications = addNotificationsFactory(store);
      const addErrorsNotification = addNotificationErrorsFactory(store);

      axios.interceptors.response.use(
        async (response) => {
          const {
            config: { silentMode = true, customMessages },
            status,
          } = response;

          if (!silentMode) {
            const additionalMessages = customMessages?.[status]
              ? [
                  {
                    ...customMessages?.[status],
                    type: "success",
                  },
                ]
              : [];
            addNotifications(additionalMessages);
          }
          return response;
        },
        (error) => {
          const {
            response: { data },
            config: { silentMode, customMessages },
            status,
          } = error;

          if (!silentMode) {
            const additionalMessages = customMessages?.[status]
              ? [customMessages?.[status]]
              : [];
            addErrorsNotification(data.messages || additionalMessages);
          }
          return Promise.reject(error);
        },
      );
    }
    return axios;
  };
};
export default getAxiosInstance();
