import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../../hooks";
import { EmptyProps } from "./types";

const Empty = ({ section, defaultValue }: EmptyProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const defValue = defaultValue || t(`profile.overview.empty`);
  return (
    <span className="text-neutralSecondary-40 font-normal">
      {t(`profile.overview.${section}.empty`, defValue)}
    </span>
  );
};

export default Empty;
