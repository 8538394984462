import { ReactElement } from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { LabelWithDotProps } from "./types";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    fontSize: "12px",
    alignItems: "center",
  },
  text: {
    fontFamily: "PoppinsBold",
    marginLeft: "5px",
  },
  dot: {
    borderRadius: "9999px",
    width: "6px",
    height: "6px",
  },
});

const LabelWithDot = ({
  color,
  text,
  additionalStyles,
}: LabelWithDotProps): ReactElement => (
  <View style={{ ...styles.container, ...additionalStyles }}>
    <View
      style={{
        backgroundColor: color,
        ...styles.dot,
      }}
    />
    <Text style={styles.text}>{text}</Text>
  </View>
);

export default LabelWithDot;
