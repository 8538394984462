import { ChangeEvent, forwardRef, ReactElement } from "react";
import { UPLOADER_PREFIX } from "../../../../shared/data-cy";
import { UploadFileInputProps } from "../types";

const UploadFileInput = forwardRef<HTMLInputElement, UploadFileInputProps>(
  ({ onFilesSelected, isMultiple = false, disabled }, ref): ReactElement => {
    const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files) onFilesSelected(files);
    };

    return (
      <input
        ref={ref}
        type="file"
        accept="application/pdf"
        className="hidden"
        multiple={isMultiple}
        onChange={handleInputOnChange}
        data-cy={UPLOADER_PREFIX}
        disabled={disabled}
      />
    );
  },
);

export default UploadFileInput;
