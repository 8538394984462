import { classJoin } from "@ps/utils";
import { KeyboardEvent, ReactElement } from "react";
import { ReactComponent as DeleteProjectRowIcon } from "../../../../images/timesheet/timelog/delete-project-row-icon.svg";
import { focusVisibleStyles, Keys } from "../../../../shared";
import { DeleteProjectProps } from "./types";
import { ROW_DELETE } from "../../../../shared/data-cy";

const DeleteProject = ({ onDelete, tabIndex = 0 }: DeleteProjectProps): ReactElement => (
  <DeleteProjectRowIcon
    data-cy={ROW_DELETE}
    className={classJoin("cursor-pointer", focusVisibleStyles)}
    onClick={onDelete}
    tabIndex={tabIndex}
    role="button"
    onKeyDown={(event: KeyboardEvent<SVGSVGElement>) => {
      if (event.key === Keys.ENTER) onDelete();
    }}
  />
);

export default DeleteProject;
