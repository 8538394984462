import {
  colorFormatters,
  dayJSToISOValueKnown,
  returnObjectIds,
} from "@ps/utils";
import {
  CreateProjectDetailsAPIModel,
  CreateTeamMemberAPIModel,
  UpdateProjectDetailsAPIModel,
  ProjectDetailsModel,
  TeamMemberModel,
} from "../types";

const prepareDomains = (domains) => {
  if (!domains) return [];
  if (domains.length && typeof domains[0] === "string") return domains;
  return domains.map((item) => item?.id);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const projectDetails = (data: ProjectDetailsModel | any) => ({
  name: data.name,
  contactPerson: {
    firstName: data?.contactPerson?.firstName || null,
    secondName: data?.contactPerson?.secondName || null,
    phoneNumber: data?.contactPerson?.phoneNumber || null,
    email: data?.contactPerson?.email || null,
  },
  clientId: data?.clientId?.id || data?.clientId || null,
  invoiceDetails: {
    companyName: data?.invoiceDetails?.companyName || null,
    country: data?.invoiceDetails?.country?.value || null,
    zipCode: data?.invoiceDetails?.zipCode || null,
    address: data?.invoiceDetails?.address || null,
    taxNumber: data?.invoiceDetails?.taxNumber || null,
    accountNumber: data?.invoiceDetails?.accountNumber || null,
  },
  techStack: returnObjectIds(data?.techStack) || [],
  domains: prepareDomains(data?.domains),
  projectManager: data?.projectManager?.userId || null,
  businessDeveloper: data?.businessDeveloper?.userId || null,
  color: colorFormatters.colorToRBGObject(data?.color),
  public: data.public,
  note: data.note,
});

export const createProjectToAPIModel = (
  data: ProjectDetailsModel,
): CreateProjectDetailsAPIModel => projectDetails(data);

export const updateProjectToAPIModel = (
  data: ProjectDetailsModel,
): UpdateProjectDetailsAPIModel => ({
  ...projectDetails(data),

  status: data?.status || "active",
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const teamMember = (data: TeamMemberModel | any) => ({
  userId: data.userId,
  statementOfWork: {
    startDate: dayJSToISOValueKnown(data?.statementOfWork?.startDate) || null,
    endDate: dayJSToISOValueKnown(data?.statementOfWork?.finishDate) || null,
    noticePeriod: data?.statementOfWork?.noticePeriod,
    engagement:
      data?.statementOfWork?.engagement?.label ||
      data?.statementOfWork?.engagement ||
      null,
  },
  rate: {
    amount: data?.rate?.amount || null,
    currency: data?.rate?.currency?.label || data?.rate?.currency || null,
  },
  projectFeedback: data?.feedback || null,
  rejectionReason: data?.delete || null,
});

export const createTeamMemberToAPIModel = (
  data: TeamMemberModel,
): CreateTeamMemberAPIModel => teamMember(data);
