import { uid } from "react-uid";
import { ReactElement, KeyboardEvent } from "react";
import { ScaleTile } from "@ps/ui-components";
import { colorFormatters, classJoin } from "@ps/utils";
import { Controller, useFormContext } from "react-hook-form";
import { ReactComponent as ScaleTileAddIcon } from "../../../../../../images/scale-tile-add.svg";
import { TilesGroupProps } from "./types";
import { focusVisibleStyles, Keys } from "../../../../../../shared";

const TilesGroup = ({
  tiles,
  label = "",
  onAddClick,
  withColor = 0,
  baseName,
  withoutFirst,
  scaleIndex,
  disabled,
}: TilesGroupProps): ReactElement => {
  const { control } = useFormContext();

  const displayColor = (item, index: number) => {
    if (withColor >= index) {
      return typeof item?.color === "string"
        ? item?.color
        : colorFormatters.prepareRGBObjectToHEX(item?.color);
    }
    return "";
  };

  return (
    <div className="flex flex-col gap-3">
      {label && <span className="text-neutralPrimary-30 pt-8">{label}</span>}
      <div className="flex gap-2 items-center pb-4">
        {tiles.map((item, index) => (
          <Controller
            key={uid(item)}
            name={`${baseName}.${scaleIndex || index}`}
            control={control}
            render={() => (
              <ScaleTile
                disabled={disabled}
                dataCy={item.desc}
                color={displayColor(item, index)}
                additionalClassName={
                  withoutFirst && index === 0 ? "hidden" : ""
                }
              />
            )}
          />
        ))}
        {/* TODO temporary hidden because of HH2-2698 */}
        {/* {onAddClick && tiles.length <= 10 && ( */}
        {/*  <ScaleTileAddIcon */}
        {/*    className={classJoin( */}
        {/*      classJoin.template` */}
        {/*    text-neutralSecondary-60 stroke-current hover:opacity-80 */}
        {/*    cursor-pointer rounded-md w-7 h-7 mb-1`, */}
        {/*      focusVisibleStyles, */}
        {/*    )} */}
        {/*    onClick={onAddClick} */}
        {/*    tabIndex={0} */}
        {/*    role="button" */}
        {/*    onKeyDown={(event: KeyboardEvent<SVGSVGElement>) => { */}
        {/*      if (event.key === Keys.ENTER) onAddClick(); */}
        {/*    }} */}
        {/*  /> */}
        {/* )} */}
      </div>
    </div>
  );
};

export default TilesGroup;
