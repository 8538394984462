import { ReactElement, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import {
  Button2,
  Input,
  LinkButton,
  Modal2,
  ModalContent,
  ModalFooter,
} from "@ps/ui-components";
import { UTCToDayjs, classJoin, setSeniorityColor } from "@ps/utils";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../hooks";
import { FINANCES, MONTHLY_SALARY, MONTHLY } from "./constants";
import UserAvatar from "./userAvatar";
import { UserModalFormProps, UserModalProps } from "../types";
import { fetchHolidaysUsers } from "../../store";
import {
  HolidaysUserMapState,
  HolidaysUserMapStateReturn,
} from "../holidays-user/types";
import { FinanceAPIService } from "../../../../index";

const BASE_PATH = "finance.finances.modal";

const UserModal = ({
  isOpen,
  onClose,
  user,
  seniority,
  profession,
}: UserModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const { control, setValue, handleSubmit, trigger, reset, getValues } =
    useForm<UserModalFormProps>();

  const mapState = (
    state: HolidaysUserMapState,
  ): HolidaysUserMapStateReturn => ({
    userDetails: state.holidays.userDetails || {},
    isLoading: state.requestStatus.isFetchingHolidaysUserDetails,
  });
  const { isLoading } = useMappedStateSelector(mapState);

  useEffect(() => {
    if (user.salary?.monthly) setValue(MONTHLY, user.salary.monthly.toString());
  }, [user]);

  const errorHigherThan = t(`${BASE_PATH}.higherThan`);
  const errorLowerThan = t(`${BASE_PATH}.lowerThan`);

  const handleOnClose = () => {
    onClose();
    reset({ [MONTHLY]: user.salary?.monthly });
  };

  const onSubmit = async (data: UserModalFormProps) => {
    const output = await trigger();
    if (output) {
      await FinanceAPIService.API.updateUserSalary(user.id, {
        monthly: +data.monthly,
      });
      fetchHolidaysUsers(dispatch);
      handleOnClose();
    }
  };

  const date = UTCToDayjs(user?.salary?.lastUpdated);

  const validate = (value: string) => {
    if (!value || +value <= 0) return errorHigherThan;
    if (+value > 2147483648) return errorLowerThan;
    return true;
  };

  if (isLoading) return <></>;

  return (
    <Modal2
      isOpen={isOpen}
      onClose={handleOnClose}
      dataCy={`${FINANCES}_modal`}
      withCloseIcon
      width="w-3/12"
      padding="p-0"
    >
      <ModalContent>
        <div className="flex gap-x-4 mb-2 pt-8 px-8">
          <UserAvatar avatar="" />
          <div className="flex flex-col">
            <span>{profession}</span>
            <span className={classJoin(setSeniorityColor(user?.seniority))}>
              {seniority}
            </span>
          </div>
        </div>
        <span className="font-semibold p-8">{user.name}</span>
        <div className="flex flex-col gap-y-4 mt-12">
          <div className="flex justify-between items-center bg-neutralPrimary-50 bg-opacity-40 py-0.5 -mb-1 px-8">
            <span className="font-semibold">
              {t(`${BASE_PATH}.${MONTHLY_SALARY}`)}
            </span>
            <Controller
              control={control}
              name={MONTHLY}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  dataCy={`${FINANCES}_${MONTHLY_SALARY}`}
                  additionalClass="self-end"
                  placeholder={t(`${BASE_PATH}.${MONTHLY_SALARY}`)}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, "");
                    onChange(newValue);
                  }}
                  width="w-32"
                  error={!!error}
                  message={error?.message || ""}
                  pattern="[0-9]*"
                  value={value}
                />
              )}
              rules={{
                validate: (value) => validate(value),
              }}
            />
          </div>
          <div className="flex justify-between items-center">
            <span className="font-semibold px-8">
              {t(`${BASE_PATH}.currency`)}
            </span>
            <span className="font-semibold px-8">
              {user.salary?.currency ? user.salary.currency : "EUR"}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="font-semibold px-8">
              {t(`${BASE_PATH}.changeDate`)}
            </span>
            <span className="font-semibold px-8">
              {user.salary?.lastUpdated
                ? dayjs(date).format("DD.MM.YYYY")
                : "-"}
            </span>
          </div>
        </div>
      </ModalContent>
      <ModalFooter additionalClassName="p-8 items-center justify-between mt-10">
        <LinkButton
          dataCy={`${FINANCES}_cancel`}
          additionalClassName="mt-auto underline"
          variant="secondary"
          onClick={handleOnClose}
        >
          {t("actions.cancel")}
        </LinkButton>
        <Button2 dataCy={`${FINANCES}_change`} onClick={handleSubmit(onSubmit)}>
          {t("actions.save")}
        </Button2>
      </ModalFooter>
    </Modal2>
  );
};

export default UserModal;
