import { ReactElement } from "react";
import dayjs, { isDayjs, Dayjs } from "dayjs";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  Label,
  TimeTrackerProjectName,
  useThemeMode,
  Empty,
  MoreLinkButton,
  hasPermissions,
} from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import {
  ACTIVE,
  ARCHIVED,
  BD_NAME,
  CLIENT_NAME,
  CREATION_DATE,
  MORE,
  PM_NAME,
  PROJECT_NAME,
  STATUS,
  USERS_IN_PROJECT,
} from "../../constants";
import { PROJECTS_LIST } from "../../../shared/data-cy";
import { ProjectRowMapState, ProjectRowProps } from "./types";
import styles from "./styles.module.css";
import { ReactComponent as LockIcon } from "../../../images/project/lock.svg";
import { hhPermissions } from "../../../../index";

const ProjectRow = ({ project }: ProjectRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();
  const history = useHistory();
  const { pathname } = useLocation();
  const currentClientPath = `/clients/${project?.details.clientId}`;

  const mapState = (state: ProjectRowMapState): ProjectRowMapState => ({
    permissions: state.permissions || [],
  });

  const { permissions } = useMappedStateSelector(mapState, "");

  const hasClientPermissions = hasPermissions(permissions, [
    hhPermissions.CLIENTS_MANAGE,
    hhPermissions.CLIENTS_LIST,
    hhPermissions.CLIENTS_LIST_ALL,
    hhPermissions.PROSPECT_FULL_ACCESS,
  ]);

  const translate = (field: string) => t(`project.list.${field}`);

  const creationDate: string | Dayjs = isDayjs(project?.creationDate)
    ? dayjs(project.creationDate).format("DD/MM/YYYY")
    : "";

  const stylesWhenArchived = project.archived
    ? "opacity-40 pointer-events-none"
    : "opacity-100 pointer-events-auto";

  const statusStyle = project.archived ? "bg-gray-200" : "bg-other-toxicGreen";

  const showStatus = (archived: boolean): ReactElement => {
    return (
      <div className="flex gap-x-3 items-center">
        <div className={classJoin(statusStyle, "h-4 w-4 rounded-full")} />
        <span className="uppercase font-semibold">
          {archived ? translate(ARCHIVED) : translate(ACTIVE)}
        </span>
      </div>
    );
  };

  const rowContent = (
    <>
      <Label dataCy={`${PROJECTS_LIST}_${PROJECT_NAME}`} verticalAlignment>
        <div className={classJoin(stylesWhenArchived, "flex items-center")}>
          <TimeTrackerProjectName
            dataCy={`${PROJECTS_LIST}_${PROJECT_NAME}`}
            label={
              <Link to={`/project/${project.id}`} className="text-primary-50">
                {project.details.name}
              </Link>
            }
            color={project.details.color}
            additionalClassName="break-all w-auto mr-2"
            icon={
              !project.public ? (
                <LockIcon className="mr-3" fill={`${project.details.color}`} />
              ) : undefined
            }
          />
        </div>
      </Label>
      <Label
        additionalClassName={stylesWhenArchived}
        dataCy={`${PROJECTS_LIST}_${CLIENT_NAME}`}
        verticalAlignment
      >
        {project.details.clientName &&
        hasClientPermissions &&
        pathname !== currentClientPath ? (
          <Link to={currentClientPath} className="text-primary-50">
            {project?.details?.clientName}
          </Link>
        ) : (
          <></>
        )}
        {project.details.clientName &&
        hasClientPermissions &&
        pathname === currentClientPath ? (
          <span>{project?.details?.clientName}</span>
        ) : (
          <></>
        )}
        {!project.details.clientName ? <Empty /> : <></>}
      </Label>
      <Label
        dataCy={`${PROJECTS_LIST}_${PM_NAME}`}
        additionalClassName={stylesWhenArchived}
        verticalAlignment
      >
        {project.details.projectManager ? (
          `${project.details.projectManager.firstName} ${project.details.projectManager.lastName}`
        ) : (
          <Empty />
        )}
      </Label>
      <Label
        dataCy={`${PROJECTS_LIST}_${BD_NAME}`}
        additionalClassName={stylesWhenArchived}
        verticalAlignment
      >
        {project.details.businessDeveloper ? (
          `${project.details.businessDeveloper.firstName} ${project.details.businessDeveloper.lastName}`
        ) : (
          <Empty />
        )}
      </Label>
      <Label
        dataCy={`${PROJECTS_LIST}_${CREATION_DATE}`}
        additionalClassName={stylesWhenArchived}
        verticalAlignment
      >
        {creationDate || <Empty />}
      </Label>
      <Label
        dataCy={`${PROJECTS_LIST}_${USERS_IN_PROJECT}`}
        additionalClassName={stylesWhenArchived}
        verticalAlignment
      >
        <>
          {project?.team?.length || (
            <span className="text-neutralSecondary-41">0</span>
          )}
        </>
      </Label>
      <Label
        additionalClassName={stylesWhenArchived}
        dataCy={`${PROJECTS_LIST}_${STATUS}`}
        verticalAlignment
      >
        {showStatus(project?.archived)}
      </Label>
      <MoreLinkButton
        additionalClassName="self-center ml-auto stylesWhenArchived"
        dataCy={`${PROJECTS_LIST}_${MORE}`}
        onClick={() => history.push(`/project/${project.id}`)}
      />
    </>
  );

  return (
    <Card
      dataCy={`${PROJECTS_LIST}_project`}
      additionalClassName="items-center"
      additionalCardClassName={classJoin(
        styles.gridContainer,
        "2xl:gap-4 gap-2 mb-1 py-10 top-1/2",
        isHighContrast && "border border-neutralSecondary-40",
      )}
    >
      {rowContent}
    </Card>
  );
};

export default ProjectRow;
