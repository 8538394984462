import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { ToastProps } from "./types";
import { ReactComponent as CloseIcon } from "../../images/close-notification-icon.svg";
import toastStyles from "../notification/styles";
import { TOAST_PREFIX } from "../../shared/data-cy";

const Toast = ({
  id,
  dataCy,
  title,
  variant,
  onClose,
}: ToastProps): ReactElement => {
  const { icon: Icon, backgroundColor, borderColor } = toastStyles[variant];

  const iconStyle = `text-${borderColor} fill-current`;

  return (
    <div
      className={classJoin(
        "relative border rounded-md p-2 pl-3 w-min",
        `bg-${backgroundColor} border-${borderColor}`,
      )}
      data-cy={`${TOAST_PREFIX}-${dataCy}-${id}`}
    >
      <div className="flex flex-row items-center">
        <span className="mr-3 pt-1">
          <Icon className={iconStyle} data-cy={`svg-${variant}-icon`} />
        </span>
        <div className="mr-5">
          <span className="font-semibold whitespace-nowrap">{title}</span>
        </div>
        <button
          className="absolute top-0.5 right-0.5 cursor-pointer focus:outline-none"
          onClick={onClose}
        >
          <CloseIcon className={iconStyle} data-cy="svg-close-icon" />
        </button>
      </div>
    </div>
  );
};
export default Toast;
