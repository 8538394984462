import React from "react";
import createFontSizePortal from "./createFontSizePortal";
import { FontSizeContextProps, FontSize } from "./types";

const FontSizeContext = React.createContext<FontSizeContextProps>({
  fontSize: FontSize.DEFAULT,
  set: () => {
    // do nothing
  },
  createPortal: (children, container, key) =>
    createFontSizePortal({
      fontSize: FontSize.DEFAULT,
      children,
      container,
      key,
    }),
});

export default FontSizeContext;
