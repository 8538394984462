const isScrollable = (ele: Element): boolean => {
  const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

  const overflowYStyle = window.getComputedStyle(ele).overflowY;
  const isOverflowHidden = overflowYStyle.indexOf("hidden") !== -1;

  return hasScrollableContent && !isOverflowHidden;
};

const getScrollableParent = (ele: Element | null): Element => {
  if (!ele || ele === document.body) return document.body;
  if (isScrollable(ele)) return ele;
  return getScrollableParent(ele.parentElement || document.body);
};

export default getScrollableParent;
