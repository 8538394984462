import { IData, Item, ProjectModel } from "../types";

export const getProperProject = (
  projects: ProjectModel[] = [],
  projectId?: string,
): ProjectModel | undefined =>
  projects?.length && projectId
    ? projects.find((item: ProjectModel): boolean => item.id === projectId)
    : undefined;

export const getProperProjectByDay = (
  days: IData,
  day: string,
  projectId: string,
): number | undefined =>
  days.find((item: Item) => item.name.toString() === day)?.[projectId];
