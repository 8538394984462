import { createContext, useContext } from "react";
import { EditMatrixContextProps } from "./types";

const initialState = {
  editProfession: "",
  editGroups: [],
  editTechnologies: [],
  setEditProfession: () => "",
  setEditGroups: () => "",
  setEditTechnologies: () => "",
  isDragEndDrop: false,
  setIsDragAndDrop: () => false,
  isNotificationVisible: false,
  setIsNotificationVisible: () => false,
  editTechnologyMode: false,
  setEditTechnologyMode: () => false,
};

export const EditMatrixContext =
  createContext<EditMatrixContextProps>(initialState);

export const useEditMatrixContext = (): EditMatrixContextProps => {
  const context = useContext(EditMatrixContext);
  if (!context) {
    throw new Error(
      "Edit Matrix compound components cannot be rendered outside the Edit Matrix component",
    );
  }
  return context;
};
