export const FINANCES = "finances";
export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const ASC = "asc";
export const DESC = "desc";
export const NAME = "name";
export const USER_NAME = "userName";
export const PROFESSION = "profession";
export const SENIORITY = "seniority";
export const MONTHLY_SALARY = "monthlySalary";
export const HOURLY_RATE = "hourlyRate";
export const STATUS = "status";
export const PROFESSIONS = "professions";
export const EDIT = "edit";
export const MONTHLY = "monthly";
export const HOURLY = "hourly";
export const PER_HOUR = "perHour";

export const initialCheckersState = {
  [ACTIVE]: true,
  [INACTIVE]: false,
};

export const initialFiltersState = {
  [PROFESSION]: [],
  [SENIORITY]: [],
};
