export default {
  login: {
    signIn: "Sign in with Google",
    logout: "Logout",
  },
  org: {
    title: "Hire Help",
  },
  navbar: {
    main: "main",
    you: "you",
    "log-time": "log time",
    holidays: "holidays",
    profile: "profile",
    team: "team",
    project: "project",
    clients: "clients",
    folders: "folders",
    settings: "settings",
    "time-tracker": "time tracker",
  },
};
