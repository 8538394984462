import { TIME_VALUE_REGEX } from "../constants";

const colonSeparator = ":";

const verifyTwoCharacters = (
  timeValue: string,
  character: number,
): boolean | undefined => {
  if (
    timeValue.charAt(1) === colonSeparator &&
    timeValue.charAt(0) === character.toString() &&
    timeValue.length === 2
  )
    return true;
  return undefined;
};

export const addZeroToTimeValue = (timeValue: string): string => {
  if (verifyTwoCharacters(timeValue, 0)) {
    return `0${timeValue}00`;
  }
  if (verifyTwoCharacters(timeValue, 1)) {
    return `0${timeValue}00`;
  }
  if (verifyTwoCharacters(timeValue, 2)) {
    return `0${timeValue}00`;
  }
  if (timeValue.charAt(2) === colonSeparator && timeValue.length === 4) {
    return `${timeValue}0`;
  }
  if (timeValue.charAt(1) === colonSeparator && timeValue.length === 3) {
    return `0${timeValue}0`;
  }
  if (timeValue.length === 1) {
    return `0${timeValue}${colonSeparator}00`;
  }
  if (timeValue.charAt(2) === colonSeparator && timeValue.length === 3) {
    return `${timeValue}00`;
  }
  if (timeValue.charAt(1) === colonSeparator) {
    return `0${timeValue}`;
  }
  if (timeValue === "0:0") {
    return "";
  }
  if (timeValue === "00:00") {
    return "";
  }
  return timeValue;
};

const isValidMinute = (minute: number): boolean =>
  Number.isInteger(minute) && minute >= 0 && minute < 60;

const isValidHour = (hour: number): boolean =>
  Number.isInteger(hour) && hour >= 0 && hour <= 24;

const isValid = (value: string): boolean => {
  if (!TIME_VALUE_REGEX.test(value)) {
    return false;
  }

  const parts = value.split(":");
  const hourString = parts[0] ?? "";
  const minuteString = parts[1] ?? "";
  const hours = +hourString || 0;
  const minutes = +minuteString || 0;

  if (!isValidHour(hours) || !isValidMinute(minutes)) {
    return false;
  }

  if (hours >= 24 && minutes > 0) {
    return false;
  }

  if (minutes < 10 && Number(minuteString[0]) > 5) {
    return false;
  }

  return true;
};

export const getTimeValue = (prevValue: string, newValue: string): string => {
  let tempNewValue = newValue;
  if (tempNewValue === prevValue || !isValid(tempNewValue)) {
    return "";
  }
  if (
    tempNewValue.length === 2 &&
    prevValue.length !== 3 &&
    !tempNewValue.includes(colonSeparator)
  ) {
    tempNewValue = `${tempNewValue}${colonSeparator}`;
  }

  if (tempNewValue.length === 2 && prevValue.length === 3) {
    tempNewValue = tempNewValue.slice(0, 1);
  }
  if (+newValue > 2 && tempNewValue.length === 1) {
    tempNewValue = `0${tempNewValue}${colonSeparator}`;
  }
  if (tempNewValue === colonSeparator) {
    tempNewValue = "";
  }

  return tempNewValue;
};
