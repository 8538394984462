import { ReactElement } from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { getOverallExperience } from "@ps/utils";
import { Experience, Professions, Role, Seniority } from "../components";
import { OutlineProps } from "../types";

const styles = StyleSheet.create({
  container: {
    marginTop: "16px",
    padding: "8px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #999999",
    borderBottom: "1px solid #999999",
  },
});

const Outline = ({
  professions,
  seniority,
  roles,
  experience,
  isBlind,
}: OutlineProps): ReactElement => (
  <View
    style={
      !seniority && !professions && roles.length < 1 && !experience
        ? undefined
        : styles.container
    }
  >
    {seniority ? <Seniority seniority={seniority} isBlind={isBlind} /> : <></>}
    {professions.length ? (
      <Professions professions={professions} isBlind={isBlind} />
    ) : (
      <></>
    )}
    {roles.length > 0 ? <Role roles={roles} isBlind={isBlind} /> : <></>}
    {experience ? (
      <Experience
        experience={getOverallExperience(experience)}
        isBlind={isBlind}
      />
    ) : (
      <></>
    )}
  </View>
);

export default Outline;
