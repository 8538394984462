/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Candidate Match API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Candidate
 */
export interface Candidate {
    /**
     * 
     * @type {string}
     * @memberof Candidate
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Candidate
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Candidate
     */
    'lastName'?: string;
    /**
     * How a candidate matches to a Need
     * @type {number}
     * @memberof Candidate
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof Candidate
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof Candidate
     */
    'seniority'?: string;
    /**
     * 
     * @type {string}
     * @memberof Candidate
     */
    'profession'?: string;
}
/**
 * 
 * @export
 * @interface CandidatesResponse
 */
export interface CandidatesResponse {
    /**
     * 
     * @type {string}
     * @memberof CandidatesResponse
     */
    'needId': string;
    /**
     * 
     * @type {Array<Candidate>}
     * @memberof CandidatesResponse
     */
    'candidates': Array<Candidate>;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ***DEPRECATED*** Returns Candidates that match given Prospect\'s needs, the ML service makes its best guess about the candidates that match the needs
         * @param {string} prospectId 
         * @param {number} [limit] Limits number of candidates returned. Returns top candidates up to \&quot;limit\&quot;
         * @param {number} [threshold] Returns only candidates that have score higher than \&quot;threshold\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidates: async (prospectId: string, limit?: number, threshold?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('getCandidates', 'prospectId', prospectId)
            const localVarPath = `/candidates/prospect/{prospectId}`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (threshold !== undefined) {
                localVarQueryParameter['threshold'] = threshold;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Candidates that match given Prospect\'s need, the ML service makes its best guess about the candidates that match the need
         * @param {string} prospectId 
         * @param {string} needId 
         * @param {number} [limit] Limits number of candidates returned. Returns top candidates up to \&quot;limit\&quot;
         * @param {number} [threshold] Returns only candidates that have score higher than \&quot;threshold\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidatesForNeed: async (prospectId: string, needId: string, limit?: number, threshold?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('getCandidatesForNeed', 'prospectId', prospectId)
            // verify required parameter 'needId' is not null or undefined
            assertParamExists('getCandidatesForNeed', 'needId', needId)
            const localVarPath = `/candidates/prospect/{prospectId}/{needId}`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)))
                .replace(`{${"needId"}}`, encodeURIComponent(String(needId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (threshold !== undefined) {
                localVarQueryParameter['threshold'] = threshold;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * ***DEPRECATED*** Returns Candidates that match given Prospect\'s needs, the ML service makes its best guess about the candidates that match the needs
         * @param {string} prospectId 
         * @param {number} [limit] Limits number of candidates returned. Returns top candidates up to \&quot;limit\&quot;
         * @param {number} [threshold] Returns only candidates that have score higher than \&quot;threshold\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandidates(prospectId: string, limit?: number, threshold?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidatesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCandidates(prospectId, limit, threshold, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Candidates that match given Prospect\'s need, the ML service makes its best guess about the candidates that match the need
         * @param {string} prospectId 
         * @param {string} needId 
         * @param {number} [limit] Limits number of candidates returned. Returns top candidates up to \&quot;limit\&quot;
         * @param {number} [threshold] Returns only candidates that have score higher than \&quot;threshold\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandidatesForNeed(prospectId: string, needId: string, limit?: number, threshold?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCandidatesForNeed(prospectId, needId, limit, threshold, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * ***DEPRECATED*** Returns Candidates that match given Prospect\'s needs, the ML service makes its best guess about the candidates that match the needs
         * @param {string} prospectId 
         * @param {number} [limit] Limits number of candidates returned. Returns top candidates up to \&quot;limit\&quot;
         * @param {number} [threshold] Returns only candidates that have score higher than \&quot;threshold\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidates(prospectId: string, limit?: number, threshold?: number, options?: any): AxiosPromise<Array<CandidatesResponse>> {
            return localVarFp.getCandidates(prospectId, limit, threshold, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Candidates that match given Prospect\'s need, the ML service makes its best guess about the candidates that match the need
         * @param {string} prospectId 
         * @param {string} needId 
         * @param {number} [limit] Limits number of candidates returned. Returns top candidates up to \&quot;limit\&quot;
         * @param {number} [threshold] Returns only candidates that have score higher than \&quot;threshold\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidatesForNeed(prospectId: string, needId: string, limit?: number, threshold?: number, options?: any): AxiosPromise<CandidatesResponse> {
            return localVarFp.getCandidatesForNeed(prospectId, needId, limit, threshold, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * ***DEPRECATED*** Returns Candidates that match given Prospect\'s needs, the ML service makes its best guess about the candidates that match the needs
     * @param {string} prospectId 
     * @param {number} [limit] Limits number of candidates returned. Returns top candidates up to \&quot;limit\&quot;
     * @param {number} [threshold] Returns only candidates that have score higher than \&quot;threshold\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCandidates(prospectId: string, limit?: number, threshold?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCandidates(prospectId, limit, threshold, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Candidates that match given Prospect\'s need, the ML service makes its best guess about the candidates that match the need
     * @param {string} prospectId 
     * @param {string} needId 
     * @param {number} [limit] Limits number of candidates returned. Returns top candidates up to \&quot;limit\&quot;
     * @param {number} [threshold] Returns only candidates that have score higher than \&quot;threshold\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCandidatesForNeed(prospectId: string, needId: string, limit?: number, threshold?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCandidatesForNeed(prospectId, needId, limit, threshold, options).then((request) => request(this.axios, this.basePath));
    }
}


