import { ReactElement } from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { EducationProps } from "../types";
import { Description, TimeRange } from "../components";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  certificateContainer: {
    marginBottom: "50px",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: "#2A2D33",
    textTransform: "uppercase",
    fontFamily: "PoppinsBold",
    fontSize: "12px",
    marginBottom: "4px",
  },
  subtitle: {
    fontFamily: "PoppinsBold",
    fontSize: "10px",
    marginBottom: "8px",
  },
});

const Education = ({
  title,
  startDate,
  endDate,
  description,
  fieldOfStudy,
  schoolName,
  isBlind,
}: EducationProps): ReactElement => (
  <View style={styles.container}>
    <View style={styles.certificateContainer}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.title}>{fieldOfStudy}</Text>
      <Text
        style={{ ...styles.subtitle, color: isBlind ? "#DE3933" : "#152DA8" }}
      >
        {schoolName}
      </Text>
      <TimeRange times={[startDate, endDate]} isBlind={isBlind} />
      <View>
        <Description description={description} />
      </View>
    </View>
  </View>
);

export default Education;
