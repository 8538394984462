import {
  Card,
  PrevNextNavigation,
  ReadonlyArea,
  Wizard,
} from "@ps/ui-components";
import { ReactElement, useEffect, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import {
  mapTechnologyWithOnlyAlphanumeric,
  useFullTextSearch,
} from "@ps/utils";
// eslint-disable-next-line prodigal-son/no-use-mapped-state
import { useMappedState } from "redux-react-hook";
import { MyProfileService } from "../../../..";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { PREVIOUS_YEAR } from "../../../shared/constants";
import { OCR } from "../../../shared/data-cy";
import {
  CourseModel,
  DegreeModel,
  OperativeDomainModel,
  ProjectModel,
  SpecialistDomainModel,
} from "../../models";
import {
  COURSE,
  DEGREE,
  DEGREES,
} from "../experience/sections/education/constants";
import { AVAILABILITY, GOALS, SHORT_INFORMATION } from "../overview/constants";
import ButtonsRow from "./buttonsRow";
import {
  ACCOMPLISHMENTS,
  ADDITIONAL_INFO,
  ARTICLES,
  BIO,
  EDUCATION,
  EXPERIENCE,
  PROJECTS,
  TRANSLATION_PATH,
  WIZARD_STEPS,
} from "./constants";
import ContentWrapper from "./contentWrapper";
import { OCRWizardContext } from "./context";
import Header from "./header";
import {
  ContentWrapperState,
  ContentWrapperStateReturn,
  IDataSubmit,
  ISectionType,
  SelectOption,
} from "./types";
import { getSourceText, prepareEmptyValues } from "./utils";

const OCRWizard = (): ReactElement => {
  const [step, setStep] = useState(1);
  const [subStep, setSubStep] = useState(0);
  const [OCRSourceText, setOCRSourceText] = useState("");
  const { t } = useTranslationWithNamespace();
  const [educationType, setEducationType] = useState<SelectOption>({
    value: DEGREES,
    label: t(`${TRANSLATION_PATH}.education.degree`),
  });
  const [accomplishmentType, setAccomplishmentType] = useState<SelectOption>({
    value: ARTICLES,
    label: t("profile.experience.sections.inner.articles"),
  });
  const [additionalInfoFormChoice, setAdditionalInfoFormChoice] =
    useState<SelectOption>({
      value: "",
      label: "",
    });
  const [savedProfileData, setSavedProfileData] = useState<
    SpecialistDomainModel | OperativeDomainModel
  >({});
  const [savedForms, setSavedForms] = useState<string[]>([]);

  const mapState = (state: ContentWrapperState): ContentWrapperStateReturn => ({
    ocr: state?.profiles?.ocrResults,
    profile: state?.profiles?.myProfile,
    firstName: state?.login?.firstName,
    lastName: state?.login?.secondName,
    technologyDict: state?.dictionaries?.technology,
  });

  const { profile, ocr, technologyDict }: ContentWrapperStateReturn =
    useMappedStateSelector(mapState);

  // eslint-disable-next-line prodigal-son/no-use-mapped-state
  const { firstName, lastName } = useMappedState(mapState);

  const { search } = useFullTextSearch(
    technologyDict,
    mapTechnologyWithOnlyAlphanumeric,
  );
  const unchangeableState = {
    profile: profile.profile,
    lastName,
    firstName,
    professions: { main: "", secondary: [] },
    roles: [],
  };

  const methods: UseFormReturn = useForm<
    SpecialistDomainModel | OperativeDomainModel
  >({
    defaultValues: unchangeableState,
    mode: "all",
  });

  const isValid = !Object.keys(methods.formState.errors).length;
  const isDirty = !!Object.keys(methods.formState.dirtyFields).length;

  const projects = useFieldArray({ control: methods.control, name: PROJECTS });
  const education = useFieldArray({
    control: methods.control,
    name: EDUCATION,
  });
  const accomplishments = useFieldArray({
    control: methods.control,
    name: ACCOMPLISHMENTS,
  });
  const additionalInfo = useFieldArray({
    control: methods.control,
    name: ADDITIONAL_INFO,
  });

  let currentFormName = WIZARD_STEPS[step - 1];

  useEffect(() => {
    methods.reset({
      [SHORT_INFORMATION]: savedForms.some((item) => item === currentFormName)
        ? savedProfileData?.[SHORT_INFORMATION]
        : ocr?.[BIO],
    });
    setOCRSourceText(ocr[BIO] || "");
  }, [ocr]);

  // Used in progress bar
  const labels = WIZARD_STEPS.map((code: string): string =>
    t(`${TRANSLATION_PATH}.labels.${code}`, { year: PREVIOUS_YEAR }),
  );

  // Sometimes loading an empty form would dirty or invalidate it, this resets it working around it
  useEffect(() => {
    if (
      [AVAILABILITY, GOALS, PROJECTS].some((v: string) => v === currentFormName)
    )
      methods.reset({ ...methods.getValues() });
  }, [currentFormName]);

  // Each step that has a choice of form requires similar looking function.
  // It adds a field that specifies what type of form user chose. Later it's
  // split to different arrays by this field
  const handleOnSetEducationClick = (type: {
    value: string;
    label: string;
  }): void => {
    const prevData = education.fields?.[subStep];
    education.update(subStep, { ...prevData, [EDUCATION]: type.value });
    setEducationType(type);
  };
  const handleOnSetAccomplishmentClick = (type: {
    value: string;
    label: string;
  }): void => {
    const prevData = accomplishments.fields?.[subStep];
    accomplishments.update(subStep, {
      ...prevData,
      [ACCOMPLISHMENTS]: type.value,
    });
    setAccomplishmentType(type);
  };

  const handleOnSetAdditionalInfoFromChoice = (type: SelectOption): void => {
    const prevData = additionalInfo.fields?.[subStep];
    additionalInfo.update(subStep, {
      ...prevData,
      [ADDITIONAL_INFO]: type.value,
    });
    setAdditionalInfoFormChoice(type);
  };

  const onNavNextClick = (): void => {
    const oldCurrentStep = step;
    const oldCurrentSubStep = subStep;
    const isArray = Array.isArray(ocr?.[currentFormName]);
    const nextFormName = WIZARD_STEPS[oldCurrentStep];
    const isNextArray = Array.isArray(ocr?.[nextFormName]);
    const nextStep = oldCurrentStep + 1;

    methods.trigger();
    if (!isValid) return;

    if (currentFormName === EXPERIENCE) {
      const [professions, seniority] = methods.getValues([
        "professions",
        "seniority",
      ]);

      if (!seniority || !professions.main) {
        methods.trigger();
        return;
      }
    }

    if (
      (currentFormName === ACCOMPLISHMENTS ||
        nextFormName === ACCOMPLISHMENTS) &&
      accomplishments.fields.length < ocr?.[nextFormName]?.length
    )
      accomplishments.append({ [ACCOMPLISHMENTS]: ARTICLES });

    if (currentFormName === ADDITIONAL_INFO) {
      const newSubStep = oldCurrentSubStep + 1;
      setSubStep(newSubStep);
      setAdditionalInfoFormChoice({ value: "", label: "" });
      methods.trigger();
      methods.clearErrors();
      return;
    }
    if (isArray && subStep < ocr?.[currentFormName]?.length - 1) {
      const newSubStep = oldCurrentSubStep + 1;
      if (
        currentFormName === PROJECTS &&
        projects.fields.length < ocr?.[currentFormName]?.length
      )
        projects.append({
          ...ocr?.[currentFormName][newSubStep],
          domain: undefined,
        });
      if (
        currentFormName === EDUCATION &&
        education.fields.length < ocr?.[currentFormName]?.length
      ) {
        education.append(ocr?.[currentFormName][newSubStep]);
      }

      setSubStep(newSubStep);
      setOCRSourceText(ocr?.[currentFormName]?.[newSubStep]?.sourceText || "");
      return;
    }

    if (
      isNextArray &&
      (!isArray ||
        (isArray && oldCurrentSubStep === ocr?.[currentFormName]?.length - 1))
    ) {
      if (
        nextFormName === PROJECTS &&
        ocr?.[nextFormName]?.length &&
        projects.fields.length < 1
      ) {
        const projectWithoutDomain = {
          ...ocr?.[nextFormName][0],
          domain: undefined,
        };
        projects.append(projectWithoutDomain);
      }
      if (
        nextFormName === EDUCATION &&
        ocr?.[nextFormName]?.length &&
        education.fields.length < 1
      )
        education.append(ocr?.[nextFormName][0]);

      setSubStep(0);
      setStep(nextStep);
      setOCRSourceText(ocr?.[nextFormName]?.[0]?.sourceText || "");
      return;
    }

    setStep(nextStep);
    setSubStep(0);
    setOCRSourceText(
      ocr?.[nextFormName]?.sourceText || ocr?.[nextFormName] || "",
    );
  };

  const onNavPrevClick = (): void => {
    const oldCurrentStep = step;
    const oldCurrentSubStep = subStep;
    const isArray = Array.isArray(ocr?.[currentFormName]);
    const prevFormName = WIZARD_STEPS[oldCurrentStep - 2];
    const isPrevArray = Array.isArray(ocr?.[prevFormName]);
    const newStep = oldCurrentStep - 1;

    if (currentFormName === ACCOMPLISHMENTS) {
      methods.reset({ ...methods.getValues() });
    }

    if (currentFormName === ADDITIONAL_INFO) {
      const newSubStep = subStep - 1;
      if (oldCurrentSubStep !== 0) {
        setSubStep(newSubStep);
        return;
      }
      setStep(newStep);
      return;
    }

    if (isPrevArray && (!isArray || (isArray && oldCurrentSubStep === 0))) {
      currentFormName = prevFormName;

      setSubStep(ocr?.[prevFormName].length - 1);
      setStep(newStep);
      setOCRSourceText(
        ocr?.[prevFormName]?.[ocr?.[prevFormName].length - 1]?.sourceText || "",
      );
      return;
    }

    if (isArray && oldCurrentSubStep > 0) {
      const actualPrevFormName = WIZARD_STEPS[oldCurrentStep - 1];
      currentFormName = actualPrevFormName;
      const newSubStep = oldCurrentSubStep - 1;

      setSubStep(newSubStep);
      setOCRSourceText(
        ocr?.[actualPrevFormName]?.[newSubStep]?.sourceText || "",
      );
      return;
    }

    setStep(newStep);
    setSubStep(0);
    setOCRSourceText(
      ocr?.[prevFormName]?.sourceText || ocr?.[prevFormName] || "",
    );
  };

  const isPrevDisabled = step === 1 || !isValid || isDirty;
  const isNextDisabled =
    (currentFormName === ADDITIONAL_INFO &&
      !additionalInfo.fields?.[subStep]?.[ADDITIONAL_INFO]) ||
    !isValid ||
    isDirty;

  const onSubmit = async (data: IDataSubmit) => {
    const accomplishmentsArr = data?.accomplishments || [];
    const additionalInfoArray = data?.additionalInfo || [];
    const accomplishmentsToSubmit = accomplishmentsArr
      .concat(additionalInfoArray)
      ?.reduce(
        (acc: { [key: string]: ISectionType[] }, item: ISectionType) => {
          const type = item?.accomplishmentType;
          return acc?.[type]
            ? {
                ...acc,
                [type]: [...acc[type], item],
              }
            : {
                ...acc,
                [type]: [item],
              };
        },

        {},
      );

    const toSave = {
      ...data,
      experience: {
        projects:
          data.projects?.filter((item: ProjectModel) => item.domain) || [],
        courses: data.education?.filter(
          (item: CourseModel | DegreeModel) => item.education === COURSE,
        ),
        degrees: data.education?.filter(
          (item: DegreeModel | CourseModel) => item.education === DEGREE,
        ),
        ...accomplishmentsToSubmit,
      },
      profile: profile.profile,
    };

    await MyProfileService.updateProfile(toSave);

    setSavedProfileData(toSave);
    setSavedForms((prev: string[]) => [...prev, currentFormName]);
    onNavNextClick();
  };

  const handleOnClear = (): void => {
    const prevData = methods.getValues();
    const selectedData = prevData[currentFormName];
    const emptyData = prepareEmptyValues(currentFormName, educationType?.value);
    let result = { ...prevData };
    if (Array.isArray(selectedData)) {
      const newArr = selectedData.map((item, index) =>
        index === subStep ? emptyData[currentFormName] : item,
      );
      result = { ...prevData, [currentFormName]: newArr };
    } else {
      result = { ...prevData, emptyData };
    }
    methods.reset(result);
  };

  return (
    <Wizard labels={labels} step={step} dataCy={OCR}>
      <FormProvider {...methods}>
        <form
          className="flex gap-5 mt-6"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <OCRWizardContext.Provider
            value={{
              step,
              subStep,
              currentFormName,
              educationType,
              handleOnSetEducationClick,
              accomplishmentType,
              handleOnSetAccomplishmentClick,
              additionalInfoFormChoice,
              handleOnSetAdditionalInfoFromChoice,
              ocr,
              fullTextSearch: search,
            }}
          >
            <Card
              additionalCardClassName="p-8"
              additionalClassName="h-fit"
              dataCy={OCR}
            >
              <div className="flex justify-between h-min mb-8">
                <Header formName={currentFormName} />
                <PrevNextNavigation
                  dataCy={OCR}
                  onNextClick={onNavNextClick}
                  onPrevClick={onNavPrevClick}
                  isPrevDisabled={isPrevDisabled}
                  isNextDisabled={isNextDisabled}
                  isTooltipInfoVisible={
                    (isPrevDisabled && isNextDisabled) || !isValid
                  }
                />
              </div>
              <ContentWrapper />
              <ButtonsRow onClear={handleOnClear} />
            </Card>
            {OCRSourceText && (
              <ReadonlyArea
                additionalClasses="bg-neutralPrimary-100 h-fit p-5 rounded-md"
                bodyText={getSourceText(ocr?.[currentFormName], subStep)}
                dataCy={OCR}
                title={t(`${TRANSLATION_PATH}.textFromCV`)}
                width="w-1/4"
              />
            )}
          </OCRWizardContext.Provider>
        </form>
      </FormProvider>
    </Wizard>
  );
};

export default OCRWizard;
