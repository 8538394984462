import { Button } from "@ps/ui-components";
import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../../hooks";
import { OCR, WIZARD_CONTENT_WRAPPER } from "../../../shared/data-cy";
import { TRANSLATION_PATH } from "./constants";
import { ButtonsRowProps } from "./types";

const ButtonsRow = ({ onClear }: ButtonsRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="flex gap-3 mt-6">
      <Button type="submit" dataCy={`${WIZARD_CONTENT_WRAPPER}-save-${OCR}`}>
        <span>{t(`${TRANSLATION_PATH}.save`)}</span>
      </Button>
      <Button
        variant="primaryNegative"
        dataCy={`${WIZARD_CONTENT_WRAPPER}-cancel-${OCR}`}
        onClick={onClear}
      >
        <span>{t(`${TRANSLATION_PATH}.clear`)}</span>
      </Button>
    </div>
  );
};

export default ButtonsRow;
