import { ReactElement } from "react";
import { useDispatch } from "redux-react-hook";
import { classJoin } from "@ps/utils";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import {
  DOMAINS_PROFESSION_ID,
  SKILLS_MATRIX_MODES,
} from "../../shared/consts";
import { FulfillmentModel } from "../../models";
import { useMatrixContext } from "./context";
import LoadingMatrixPage from "./loaders/loadingMatrixPage";
import MatrixSkillsRow from "./matrixSkillsRow";
import {
  DICTIONARIES,
  DictionariesService,
  DictionaryModel,
  fetchDictionary,
} from "../../../dictionaries";
import useSkills from "../../services/useSkills";
import { prepareDictionaryToSkillsMatrix } from "../helpers/utils";
import {
  MatrixSkillsTableMapState,
  MatrixSkillsTableMapStateReturn,
  MatrixSkillsTableProps,
} from "./types";
import { fetchTemplates } from "../../store";

const MatrixSkillsTable = ({
  enjoymentScale,
  groupName,
  isLast,
  knowledgeScale,
  professionId,
  professionSkills,
  searchedValue,
}: MatrixSkillsTableProps): ReactElement => {
  const { mode } = useMatrixContext();
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const { updateFulfillment } = useSkills();

  const mapState = (
    state: MatrixSkillsTableMapState,
  ): MatrixSkillsTableMapStateReturn => ({
    fulfillment:
      mode === SKILLS_MATRIX_MODES.USER_EDIT
        ? state?.skillsMatrix?.fulfillments?.[professionId ?? ""]
        : {},
    isLoading:
      state?.requestStatus?.isFetchingSkillsMatrix ||
      state?.requestStatus?.isFetchingDictionary,
    domainsDict: state?.dictionaries?.domain || [],
    techDict: state?.dictionaries?.technology || [],
  });

  const { fulfillment, isLoading, domainsDict, techDict } =
    useMappedStateSelector(mapState) as {
      fulfillment: FulfillmentModel;
      isLoading: boolean;
      domainsDict: DictionaryModel;
      techDict: {
        deleted: boolean;
        desc: null;
        iconUrl: null;
        id: string;
        name: string;
      }[];
    };

  const domains = prepareDictionaryToSkillsMatrix(domainsDict);

  const isDomainsSkillsTable = professionId === DOMAINS_PROFESSION_ID;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnSubmit = async (updatedValue: any): Promise<void> => {
    if (mode === SKILLS_MATRIX_MODES.USER_EDIT) {
      const newDictEntry = await DictionariesService.addDictionaryEntry(
        {
          name: updatedValue.id,
          isNew: updatedValue.isNew,
        },
        DICTIONARIES.TECHNOLOGY,
      );
      await updateFulfillment(professionId, {
        [newDictEntry.id || updatedValue.id]: {
          knowledge: updatedValue.knowledge,
          enjoyment: updatedValue.enjoyment,
        },
      });
      if (updatedValue.isNew) {
        await fetchDictionary(DICTIONARIES.TECHNOLOGY, dispatch);
        await fetchTemplates(professionId, dispatch, t);
      }
    }
  };

  const displayTechnologyName = (
    technologyId: string,
    desc?: string,
    isEditMode?: false,
  ): string => {
    if (isEditMode) {
      return isDomainsSkillsTable
        ? domains?.[technologyId]?.name || technologyId
        : techDict?.find((item) => item.id === technologyId)?.name ||
            technologyId;
    }

    return (
      (isDomainsSkillsTable
        ? domains?.[technologyId]?.name ?? desc
        : techDict?.find((item) => item.id === technologyId)?.name || desc) ||
      t(`profile.skills.noData.noTechnology`)
    );
  };

  if (isLoading)
    return <LoadingMatrixPage isLast={isLast} rows={professionSkills} />;

  return (
    <>
      {!isDomainsSkillsTable && groupName && (
        <div
          className={`text-primary-70 border first:border-t-0 border-primary-71
           h-10 pl-4 bg-neutralSecondary-90 flex items-center`}
        >
          {groupName}
        </div>
      )}
      <div
        className={classJoin(
          "border-l border-r border-neutralSecondary-60 flex flex-col bg-neutralPrimary-100 overflow-hidden ",
          isLast && "rounded-b-md",
        )}
      >
        {professionSkills.map(({ technologyId, desc, isNew, name }, index) => (
          <MatrixSkillsRow
            technologyId={technologyId || ""}
            technologyName={displayTechnologyName(
              technologyId || name || "",
              desc,
            )}
            key={technologyId}
            knowledgeScale={knowledgeScale}
            fulfillment={fulfillment}
            handleOnSubmit={handleOnSubmit}
            enjoymentScale={enjoymentScale}
            isNew={isNew}
            index={index}
            searchedValue={searchedValue}
          />
        ))}
      </div>
    </>
  );
};

export default MatrixSkillsTable;
