import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { UserCardProps } from "./types";
import { USER_CARD_PREFIX } from "../../shared/data-cy";

const UserCard = ({
  action,
  additionalClassName,
  avatar,
  children,
  dataCy,
  height = "h-auto",
  name,
  title,
  width = "w-48",
  borderColor = "border-neutralSecondary-60",
  borderWidth = "border",
}: UserCardProps): ReactElement => (
  <div
    className={classJoin(
      width,
      height,
      additionalClassName,
      "flex flex-col items-center justify-center p-4 rounded-lg bg-neutralPrimary-100",
      borderWidth,
      borderColor,
    )}
    data-cy={`${USER_CARD_PREFIX}-${dataCy}`}
  >
    <div className="self-end">{action}</div>
    <div className="mb-1">{avatar}</div>
    {typeof name === "string" ? (
      <span className="font-bold text-2xl text-neutralPrimary-20 text-center h-16">
        {name}
      </span>
    ) : (
      name
    )}
    {title && (
      <span className="text-neutralPrimary-30 text-lg capitalize h-8">
        {title}
      </span>
    )}
    <div className="border-t border-neutralSecondary-60 rounded-lg w-full my-2" />
    <div className="text-center flex flex-row justify-center">{children}</div>
  </div>
);

export default UserCard;
