import { createContext, useContext } from "react";
import { IContext } from "./types";

export const InvitingModalContext = createContext<IContext>({
  activeMemberId: null,
  setActiveMemberId: () => {
    // nothing
  },
  totalStepLength: null,
  setTotalStepLength: () => {
    // nothing
  },
  step: null,
  setStep: () => {
    // nothing
  },
});

export const useInvitingModalContext = (): IContext => {
  const context = useContext(InvitingModalContext);
  if (!context) {
    throw new Error(
      "Inviting Modal elements compound components cannot be rendered outside the Inviting Modal component",
    );
  }
  return context;
};
