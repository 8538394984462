import { Label, SearchSelect } from "@ps/ui-components";
import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../../hooks";
import { OCR } from "../../../shared/data-cy";
import {
  COURSES,
  DEGREES,
  EDUCATION,
} from "../experience/sections/education/constants";
import CourseForm from "../experience/sections/education/forms/courseForm";
import DegreeForm from "../experience/sections/education/forms/degreeForm";
import { TRANSLATION_PATH } from "./constants";
import { useOCRWizardContext } from "./context";
import { EducationWrapperProps, SelectOption } from "./types";

const EducationWrapper = ({ index }: EducationWrapperProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const educationFormOptions: SelectOption[] = [
    {
      value: DEGREES,
      label: t(`${TRANSLATION_PATH}.education.degree`),
    },
    {
      value: COURSES,
      label: t(`${TRANSLATION_PATH}.education.course`),
    },
  ];

  const { handleOnSetEducationClick, educationType } = useOCRWizardContext();

  return (
    <div className="flex flex-col gap-6">
      <Label dataCy={`label-${OCR}`} text={t(`${TRANSLATION_PATH}.addSection`)}>
        <SearchSelect
          key={index}
          dataCy={`education-${OCR}`}
          onChange={handleOnSetEducationClick}
          searchable={false}
          placeholder={t(`${TRANSLATION_PATH}.education.sectionName`)}
          defaultValue={educationFormOptions[0]}
          additionalClassName="mb-10 mt-1"
          options={educationFormOptions}
        />
      </Label>
      {educationType?.value === COURSES ? (
        <CourseForm index={index} sourcePath={EDUCATION} />
      ) : (
        <DegreeForm index={index} sourcePath={EDUCATION} />
      )}
    </div>
  );
};

export default EducationWrapper;
