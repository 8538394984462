import { ReactElement } from "react";
import { uid } from "react-uid";
import { TimeRange } from "@ps/ui-components";
import { EducationCardProps } from "../types";
import { COURSES, DEGREES } from "../../../constants";
import { SummaryEducationModel } from "../../../models/types";
import { useTranslationWithNamespace } from "../../../../hooks";

const educationCard = ({ title, data }: EducationCardProps): ReactElement => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslationWithNamespace();
  return (
    <div className="flex flex-col gap-y-3">
      <h4 className="uppercase text-neutralPrimary-40">
        {t(`profile.experience.sections.inner.${title}`)}
      </h4>
      <>
        {title === DEGREES &&
          data.map((d: SummaryEducationModel, i: number) => (
            <div key={uid(i)} className="flex flex-col gap-2">
              <span className="font-semibold flex flex-wrap text-neutralPrimary-20">
                {d.fieldOfStudy}
              </span>
              <TimeRange
                className="text-primary-50 text-sm"
                startDate={d.startDate}
                endDate={d.endDate}
              />
              <span className="flex flex-wrap font-bold text-neutralPrimary-20">
                {d.schoolName}
              </span>
              <span className="flex text-sm flex-wrap text-neutralPrimary-20">
                {d.degree}
              </span>
              <span className="flex font bold text-sm flex-wrap text-neutralPrimary-20">
                {d.description}
              </span>
            </div>
          ))}
      </>
      <>
        {title === COURSES &&
          data.map((c: SummaryEducationModel, i: number) => (
            <div key={uid(i)} className="flex flex-col gap-2">
              <span className="font-semibold flex flex-wrap text-neutralPrimary-20">
                {c.courseName}
              </span>
              <TimeRange
                className="text-primary-50 text-sm"
                startDate={c.startDate}
                endDate={c.endDate}
              />
              <span className="flex text-sm font-bold flex-wrap text-neutralPrimary-20">
                {t(`profile.experience.cards.course.by`)} {c.trainer}
              </span>
              <span className="flex text-sm flex-wrap text-neutralPrimary-20">
                {c.description}
              </span>
            </div>
          ))}
      </>
    </div>
  );
};

export default educationCard;
