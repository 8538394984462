import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import ActionTypes from "./common/actionTypes";
import baseRoutePath from "./common/basePath";
import {
  barChartDataReducer,
  pinnedProjectsReducer,
  projectsListReducer,
  timesheetReducer,
  isFetchingReport,
  isFetchingReportFilters,
  previousWeekTimesheetReducer,
  reportsReducer,
  holidaysReducer,
  isFetchingTimesheet,
} from "./reducers";
import {
  appendTimesheetEntry,
  clearReport,
  fetchLastWeekTimesheetData,
  fetchLastWeekTimesheetStart,
  fetchLastWeekTimesheetSuccess,
  fetchPinnedProjects,
  fetchPinnedProjectsError,
  fetchProjectList,
  fetchReport,
  fetchReportError,
  fetchReportFilters,
  fetchReportFiltersError,
  fetchReportFiltersStart,
  fetchReportFiltersSuccess,
  fetchReportStart,
  fetchReportSuccess,
  fetchTimesheetData,
  fetchTimesheetError,
  fetchTimesheetStart,
  fetchTimesheetSuccess,
  fetchUserHolidaysTemplate,
} from "./actions";
import { GroupNames } from "./reducers/types";
import { IS_PRODUCTION } from "../shared/constants";

export const timeTrackerReducer = combineReducers({
  barChartData: barChartDataReducer,
  baseRoutePath,
  pinnedProjects: pinnedProjectsReducer,
  projectsList: projectsListReducer,
  timesheet: timesheetReducer,
  reports: reportsReducer,
  requestStatus: combineReducers({
    isFetchingReportFilters,
    isFetchingReport,
    isFetchingTimesheet,
  }),
  previousWeekTimesheet: previousWeekTimesheetReducer,
  holidays: holidaysReducer,
});

export const publicTimeTrackerReducer = timeTrackerReducer;

const actionTypeEnumToString = (action: AnyAction): AnyAction =>
  typeof action.type === "number" && ActionTypes[action.type]
    ? {
        type: ActionTypes[action.type],
        payload: action.payload,
        typeNumber: action.type,
      }
    : action;

const middlewares = [thunkMiddleware];

if (!IS_PRODUCTION) {
  const logger = createLogger({ actionTransformer: actionTypeEnumToString });
  middlewares.push(logger);
}

const store = createStore(
  timeTrackerReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

export type AppState = ReturnType<typeof timeTrackerReducer>;
export default store;

export {
  fetchTimesheetData,
  fetchPinnedProjects,
  fetchProjectList,
  fetchTimesheetStart,
  fetchTimesheetSuccess,
  fetchTimesheetError,
  appendTimesheetEntry,
  fetchReportFiltersStart,
  fetchReportFiltersSuccess,
  fetchReportFiltersError,
  fetchReportFilters,
  fetchReportStart,
  fetchReportSuccess,
  fetchReportError,
  fetchReport,
  barChartDataReducer,
  pinnedProjectsReducer,
  projectsListReducer,
  timesheetReducer,
  fetchLastWeekTimesheetStart,
  fetchLastWeekTimesheetSuccess,
  fetchPinnedProjectsError,
  fetchLastWeekTimesheetData,
  clearReport,
  fetchUserHolidaysTemplate,
};

export type { GroupNames };
