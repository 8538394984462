const languageCodeToCountryCode = (languageCode: string) => {
  switch (languageCode) {
    case "aa":
      return "ET";
    case "ab":
      return "GE";
    case "af":
      return "ZA";
    case "ak":
      return "GH";
    case "am":
      return "ET";
    case "an":
      return "ES";
    case "ar":
      return "SA";
    case "as":
      return "IN";
    case "av":
      return "RU";
    case "ay":
      return "BO";
    case "az":
      return "AZ";
    case "ba":
      return "TR";
    case "be":
      return "BY";
    case "bg":
      return "BG";
    case "bh":
      return "BH";
    case "bi":
      return "VU";
    case "bm":
      return "ML";
    case "bn":
      return "BD";
    case "bo":
      return "CN";
    case "br":
      return "FR";
    case "bs":
      return "BA";
    case "ca":
      return "AD";
    case "ce":
      return "RU";
    case "ch":
      return "MP";
    case "co":
      return "FR";
    case "cr":
      return "CA";
    case "cs":
      return "CZ";
    case "cu":
      return "BG";
    case "cv":
      return "TR";
    case "cy":
      return "GB";
    case "da":
      return "DK";
    case "de":
      return "DE";
    case "dv":
      return "MV";
    case "dz":
      return "BT";
    case "ee":
      return "GH";
    case "el":
      return "GR";
    case "en":
      return "GB";
    case "eo":
      return "PL"; // ???
    case "es":
      return "ES";
    case "eu":
      return "ES";
    case "fa":
      return "IR";
    case "ff":
      return "CF";
    case "fi":
      return "FI";
    case "fj":
      return "FJ";
    case "fo":
      return "FO";
    case "fr":
      return "FR";
    case "fy":
      return "NL";
    case "ga":
      return "GB";
    case "gd":
      return "GB";
    case "gl":
      return "ES";
    case "gn":
      return "PY";
    case "gu":
      return "IN";
    case "gv":
      return "IM";
    case "ha":
      return "NG";
    case "he":
      return "IL";
    case "hi":
      return "IN";
    case "ho":
      return "PG";
    case "hr":
      return "HR";
    case "ht":
      return "HT";
    case "hu":
      return "HU";
    case "hy":
      return "AM";
    case "hz":
      return "AO";
    case "ia":
      return "US"; // ???
    case "id":
      return "ID";
    case "ie":
      return "DE"; // ???
    case "ig":
      return "NG";
    case "ii":
      return "CN";
    case "ik":
      return "CA";
    case "io":
      return "GB"; // ???
    case "is":
      return "IS";
    case "it":
      return "IT";
    case "iu":
      return "CA";
    case "ja":
      return "JP";
    case "jv":
      return "ID";
    case "ka":
      return "GE";
    case "kg":
      return "CG";
    case "ki":
      return "KE";
    case "kj":
      return "AO";
    case "kk":
      return "TR";
    case "kl":
      return "GL";
    case "km":
      return "KH";
    case "kn":
      return "IN";
    case "ko":
      return "KR";
    case "kr":
      return "NG";
    case "ks":
      return "IN";
    case "ku":
      return "TR";
    case "kv":
      return "RU";
    case "kw":
      return "GB";
    case "ky":
      return "TR";
    case "la":
      return "IT";
    case "lb":
      return "LU";
    case "lg":
      return "UG";
    case "li":
      return "DK";
    case "ln":
      return "CD";
    case "lo":
      return "TH";
    case "lt":
      return "LT";
    case "lu":
      return "CD";
    case "lv":
      return "LV";
    case "mg":
      return "MG";
    case "mh":
      return "MH";
    case "mi":
      return "NZ";
    case "mk":
      return "MK";
    case "ml":
      return "IN";
    case "mn":
      return "MN";
    case "mr":
      return "IN";
    case "ms":
      return "ID";
    case "mt":
      return "MT";
    case "my":
      return "MY";
    case "na":
      return "NR";
    case "nb":
      return "NO";
    case "nd":
      return "ZW";
    case "ne":
      return "NP";
    case "ng":
      return "AO";
    case "nl":
      return "NL";
    case "nn":
      return "NO";
    case "no":
      return "NO";
    case "nr":
      return "ZA";
    case "nv":
      return "US";
    case "ny":
      return "MW";
    case "oc":
      return "FR";
    case "oj":
      return "CA";
    case "om":
      return "ET";
    case "or":
      return "IN";
    case "os":
      return "IR";
    case "pa":
      return "PK";
    case "pi":
      return "IN";
    case "pl":
      return "PL";
    case "ps":
      return "AF";
    case "pt":
      return "PT";
    case "qu":
      return "AR";
    case "rm":
      return "CH";
    case "rn":
      return "BI";
    case "ro":
      return "RO";
    case "ru":
      return "RU";
    case "rw":
      return "RW";
    case "sa":
      return "IN";
    case "sc":
      return "IT";
    case "sd":
      return "PK";
    case "se":
      return "NO";
    case "sg":
      return "CF";
    case "si":
      return "LK";
    case "sk":
      return "CZ";
    case "sl":
      return "SI";
    case "sm":
      return "WS";
    case "sn":
      return "ZW";
    case "so":
      return "SO";
    case "sq":
      return "AL";
    case "sr":
      return "RS";
    case "ss":
      return "ZA";
    case "st":
      return "LS";
    case "su":
      return "ID";
    case "sv":
      return "SE";
    case "sw":
      return "TZ";
    case "ta":
      return "IN";
    case "te":
      return "IN";
    case "tg":
      return "TJ";
    case "th":
      return "TH";
    case "ti":
      return "ER";
    case "tk":
      return "TR";
    case "tl":
      return "PH";
    case "tn":
      return "ZA";
    case "to":
      return "TO";
    case "tr":
      return "TR";
    case "ts":
      return "SZ";
    case "tt":
      return "TR";
    case "tw":
      return "GH";
    case "ty":
      return "PF";
    case "ug":
      return "CN";
    case "uk":
      return "UA";
    case "ur":
      return "IN";
    case "uz":
      return "UZ";
    case "ve":
      return "ZA";
    case "vi":
      return "CN";
    case "vo":
      return "GB"; // ???
    case "wa":
      return "BE";
    case "wo":
      return "SN";
    case "xh":
      return "ZA";
    case "yi":
      return "IL";
    case "yo":
      return "BJ";
    case "za":
      return "CN";
    case "zh":
      return "CN";
    case "zu":
      return "ZA";
    default:
      return "PL";
  }
};

export default languageCodeToCountryCode;
