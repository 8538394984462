import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { classJoin } from "@ps/utils";
import FontSizeContext from "./context";
import createFontSizePortal from "./createFontSizePortal";
import getInitialFontSize from "./getInitialFontSize";
import {
  FONT_SIZE_LOCAL_STORAGE,
  FONT_SIZE_PROVIDER_ID,
  FontSizeContextProps,
  FontSizeProviderProps,
} from "./types";

const FontSizeProvider = ({
  additionalClassname,
  children,
}: FontSizeProviderProps): ReactElement => {
  const [currentFont, setCurrentFont] = useState(getInitialFontSize());
  const ref = useRef<HTMLDivElement>(null);

  const createPortal = useCallback(
    (
      portalChildren: ReactElement | ReactElement[],
      container: Element,
      key?: string,
    ) =>
      createFontSizePortal({
        fontSize: currentFont,
        children: portalChildren,
        container,
        key,
      }),
    [currentFont],
  );

  const providerValue: FontSizeContextProps = {
    fontSize: currentFont,
    set: setCurrentFont,
    providerRef: ref,
    createPortal,
  };

  useEffect(() => {
    localStorage.setItem(FONT_SIZE_LOCAL_STORAGE, currentFont);
  }, [currentFont]);

  return (
    <FontSizeContext.Provider value={providerValue}>
      <div
        ref={ref}
        id={FONT_SIZE_PROVIDER_ID}
        data-font-size={currentFont}
        className={classJoin("h-full", additionalClassname)}
      >
        {children}
      </div>
    </FontSizeContext.Provider>
  );
};

export default FontSizeProvider;
