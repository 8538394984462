/* eslint @typescript-eslint/no-explicit-any: 0 */
import { ReactElement, useEffect, useState } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { fetchAllRoles } from "@ps/hh";
import { useDispatch } from "redux-react-hook";
import { permissionsMappers, getSlug } from "@ps/utils";
import { hasPermissions, TabsContainer } from "@ps/ui-components";
import { MEMBERS } from "../shared/routes";
import { PERMISSIONS_TABS, NAMESPACE } from "../shared/constants";
import { useMappedStateSelector } from "../hooks";
import {
  PermissionContainerChildren,
  PermissionsContainerMapState,
} from "./types";

const PermissionsContainer = ({
  children,
}: PermissionContainerChildren): ReactElement => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const defaultTab = pathname === "/members" ? MEMBERS : getSlug(pathname);
  const [activeTab, setActiveTab] = useState<string>(defaultTab);

  useEffect(() => {
    setActiveTab(getSlug(pathname));
  }, [pathname, activeTab]);

  useEffect(() => {
    fetchAllRoles(dispatch);
  }, []);

  const mapState = (
    state: PermissionsContainerMapState,
  ): PermissionsContainerMapState => ({
    permissions: state.permissions || [],
  });

  const { permissions } = useMappedStateSelector(mapState, "");

  const handleOnTabChange = (slug: string): void => {
    history.push(`${path}/${slug}`);

    history.listen(() => setActiveTab(slug));
    setActiveTab(slug);
  };

  return (
    <TabsContainer
      activeTab={activeTab}
      handleOnTabChange={handleOnTabChange}
      prefix={NAMESPACE}
      tabs={permissionsMappers.filterTabsWithPermissions(
        PERMISSIONS_TABS,
        hasPermissions,
        permissions,
      )}
      translationID={NAMESPACE}
      translationPath="tabs"
    >
      {children}
    </TabsContainer>
  );
};

export default PermissionsContainer;
