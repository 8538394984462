import { ReactElement, useEffect, useState } from "react";
import { uid } from "react-uid";
import { useFormContext, useFormState } from "react-hook-form";
import { useDispatch } from "redux-react-hook";
import { setRedirection, clearRedirection } from "@ps/alert-modal";
import { Button, LinkButton, RestrictedWrapper } from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { ReactComponent as EditIcon } from "../../../../images/edit.svg";
import { toggleEditMode } from "../../../../store";
import { DETAILS, NEW_PROJECT, CANCEL, SAVE } from "../../../constants";
import ConfirmRemove from "../../../../profile-domain/ui/experience/common/confirmRemove";
import { PROJECT_FULL_ACCESS } from "../../../../shared/permissions";
import {
  SectionWrapperProps,
  SectionWrapperMapState,
  SectionWrapperMapStateReturn,
} from "../types";

const SectionWrapper = ({
  name,
  Edit,
  View,
  section,
  onSubmit,
}: SectionWrapperProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [removeIndex, setRemoveIndex] = useState<number | null>(null);

  const mapState = (
    state: SectionWrapperMapState,
  ): SectionWrapperMapStateReturn => ({
    editMode: state.editMode,
    data: state?.projects?.project?.[section] || {} || [],
    domainDict: state.dictionaries?.domain || [],
  });

  const { editMode, data, domainDict } = useMappedStateSelector(mapState);

  const { clearErrors, reset, handleSubmit, control } = useFormContext();

  const { isDirty } = useFormState({
    control,
  });

  useEffect(() => {
    if (isDirty && editMode) dispatch(setRedirection());
  }, [isDirty, editMode]);

  const handleOnEdit = () => {
    dispatch(toggleEditMode(NEW_PROJECT));
    clearErrors();
  };

  const handleOnEditArrayItem = (index: number) => {
    handleOnEdit();
    setEditIndex(index);
  };

  const handleOnCancel = (): void => {
    reset();
    dispatch(toggleEditMode(""));
    dispatch(clearRedirection());
  };

  useEffect(() => {
    if (Array.isArray(data) && data.length) {
      reset({ [section]: data });
    } else if (Object.keys(data).map((key) => key)?.length) {
      reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(toggleEditMode(""));
      dispatch(clearRedirection());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-neutralPrimary-100 -mx-10 h-full">
      {editMode === NEW_PROJECT ? (
        <div>
          <Edit {...data} />
          <div className="flex flex-col">
            <div className="flex flex-row gap-x-2 px-16 pb-8 justify-end w-9/12">
              <Button
                dataCy={CANCEL}
                variant="secondary"
                onClick={handleOnCancel}
              >
                {t("projects.prospect.actions.cancel")}
              </Button>
              <Button
                dataCy={SAVE}
                type="submit"
                onClick={handleSubmit((dataToSubmit) =>
                  editIndex || editIndex === 0
                    ? onSubmit(dataToSubmit[name]?.[editIndex])
                    : onSubmit(dataToSubmit),
                )}
              >
                {t("projects.prospect.actions.save")}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {Array.isArray(data) ? (
            data.map((item, index) => (
              <div
                key={uid(item)}
                className="flex justify-between items-start relative w-full"
              >
                <View {...item} domainDict={domainDict} />
                <RestrictedWrapper permissions={PROJECT_FULL_ACCESS}>
                  <LinkButton
                    dataCy={`${DETAILS}_${name}_editButton`}
                    prefixIcon={<EditIcon className="fill-current" />}
                    onClick={() => handleOnEditArrayItem(index)}
                    additionalClassName="flex gap-1"
                  >
                    {t("profile.experience.actions.editSection")}
                  </LinkButton>
                </RestrictedWrapper>
                {index === removeIndex && (
                  <ConfirmRemove
                    onCancel={() => setRemoveIndex(null)}
                    onRemove={() => {
                      // do nth
                    }}
                  />
                )}
              </div>
            ))
          ) : (
            <div className="flex justify-between items-start relative h-full w-full">
              <View
                {...data}
                domainDict={domainDict}
                editButton={
                  <RestrictedWrapper permissions={PROJECT_FULL_ACCESS}>
                    <LinkButton
                      focus
                      dataCy={`${DETAILS}_${name}_editButton`}
                      prefixIcon={<EditIcon className="fill-current" />}
                      onClick={handleOnEdit}
                      additionalClassName="flex gap-1"
                    >
                      {t("profile.experience.actions.editSection")}
                    </LinkButton>
                  </RestrictedWrapper>
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SectionWrapper;
