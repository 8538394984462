import { ReactElement, useEffect, useState } from "react";
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { useDispatch } from "redux-react-hook";
import { SearchSelect, SkillsSearchPicker, Button2 } from "@ps/ui-components";
import {
  prepareDictionarySearchSelect,
  useFullTextSearch,
  mapTechnologyWithOnlyAlphanumeric,
} from "@ps/utils";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import {
  PROFESSION_SELECT,
  MUST_HAVE_SKILLS_SELECT,
  NICE_TO_HAVE_SKILLS_SELECT,
  SEARCH_BUTTON,
} from "./constants";
import { ReactComponent as MagnifierIcon } from "../../../images/team/magnifier.svg";
import {
  SearchRowMapState,
  SearchRowMapStateReturn,
  ITechStackProposal,
} from "./types";
import {
  DictionaryEntryModel,
  fromAPIToEntryModel,
} from "../../../dictionaries";
import {
  fetchSkillsSearchQueryResult,
  clearSkillsSearchQuery,
} from "../../store";
import { SKILLS_SEARCH } from "../../../shared/data-cy";
import PillsRow from "./pillsRow";

const TRANSLATION_PATH = "team.skillsSearch";

const SearchRow = (): ReactElement => {
  const [mustHaveError, setMustHaveError] = useState("");
  const { t } = useTranslationWithNamespace();
  const methods = useForm({
    defaultValues: {
      [PROFESSION_SELECT]: null,
      [MUST_HAVE_SKILLS_SELECT]: [],
      [NICE_TO_HAVE_SKILLS_SELECT]: [],
    },
  });

  const mustHaveFieldArray = useFieldArray({
    control: methods.control,
    name: MUST_HAVE_SKILLS_SELECT,
  });

  const niceHaveFieldArray = useFieldArray({
    control: methods.control,
    name: NICE_TO_HAVE_SKILLS_SELECT,
  });

  const dispatch = useDispatch();

  const mapState = (state: SearchRowMapState): SearchRowMapStateReturn => ({
    technologyDict: state?.dictionaries?.technology || [],
    professionDict: state?.dictionaries?.profession || [],
  });

  const { technologyDict, professionDict } = useMappedStateSelector(mapState);

  const [searchedProposals, setSearchedProposals] = useState<
    ITechStackProposal[] | []
  >([]);

  const { search } = useFullTextSearch(
    technologyDict,
    mapTechnologyWithOnlyAlphanumeric,
  );

  const onSubmit = async (data: {
    [PROFESSION_SELECT]: null | { value: string; label: string; id: string };
    [MUST_HAVE_SKILLS_SELECT]: DictionaryEntryModel[];
    [NICE_TO_HAVE_SKILLS_SELECT]: DictionaryEntryModel[];
  }): Promise<void> => {
    if (!data?.[MUST_HAVE_SKILLS_SELECT]?.length) {
      setMustHaveError(t(`${TRANSLATION_PATH}.required`));
      return;
    }
    const professionId = data?.profession?.id;
    const mustHaveSkills = data.mustHaveSkills.map(
      (mustHaveSkill: DictionaryEntryModel): string => mustHaveSkill.id,
    );
    const niceToHaveSkills = data.niceToHaveSkills?.length
      ? data.niceToHaveSkills.map(
          (niceToHaveSkill: DictionaryEntryModel): string => niceToHaveSkill.id,
        )
      : [];
    await fetchSkillsSearchQueryResult(
      dispatch,
      professionId || "",
      mustHaveSkills,
      niceToHaveSkills,
    );
  };

  const handleOnClearFilters = (): void => {
    methods.reset({
      [PROFESSION_SELECT]: null,
      [MUST_HAVE_SKILLS_SELECT]: [],
      [NICE_TO_HAVE_SKILLS_SELECT]: [],
    });
    dispatch(clearSkillsSearchQuery());
    setMustHaveError("");
  };

  useEffect(() => () => handleOnClearFilters(), []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col w-full">
          <div className="flex items-start gap-4">
            <Controller
              name={PROFESSION_SELECT}
              control={methods.control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SearchSelect
                  dataCy={PROFESSION_SELECT}
                  options={prepareDictionarySearchSelect(
                    professionDict,
                    "id",
                    "id",
                    "name",
                    "name",
                  )}
                  autoFocus
                  value={value}
                  onChange={onChange}
                  searchable={false}
                  clearable
                  width="w-32 lg:w-40 xl:w-72"
                  placeholder={t(`${TRANSLATION_PATH}.${PROFESSION_SELECT}`)}
                  error={!!error}
                  message={error?.message}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t(`${TRANSLATION_PATH}.required`),
                },
              }}
            />
            <SkillsSearchPicker
              pillHeight=""
              dataCy={MUST_HAVE_SKILLS_SELECT}
              selected={mustHaveFieldArray?.fields}
              onSelected={(val) => {
                mustHaveFieldArray.append(val);
                setMustHaveError("");
              }}
              onRemove={(removeItemId) =>
                mustHaveFieldArray.remove(removeItemId)
              }
              proposalTags={searchedProposals}
              onInputChange={(searchValue: string): void => {
                const result = search(searchValue);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setSearchedProposals(result);
              }}
              placeholder={t(`${TRANSLATION_PATH}.${MUST_HAVE_SKILLS_SELECT}`)}
              width="w-full"
              error={!!mustHaveError}
              message={mustHaveError}
              skillColourClass="bg-primary-60 text-neutralPrimary-100"
              skillColourRemoveClass="bg-secondary-70 text-neutralPrimary-100"
            />
            <SkillsSearchPicker
              pillHeight=""
              dataCy={NICE_TO_HAVE_SKILLS_SELECT}
              selected={niceHaveFieldArray?.fields}
              onSelected={(val) => {
                niceHaveFieldArray.append(val);
              }}
              onRemove={(removeItemId: number) =>
                niceHaveFieldArray.remove(removeItemId)
              }
              skillColourClass="bg-secondary-70 text-neutralPrimary-100"
              skillColourRemoveClass="bg-primary-60 text-neutralPrimary-100"
              proposalTags={searchedProposals}
              onInputChange={(searchValue: string): void => {
                const result = search(searchValue);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setSearchedProposals(result);
              }}
              placeholder={t(
                `${TRANSLATION_PATH}.${NICE_TO_HAVE_SKILLS_SELECT}`,
              )}
              width="w-full"
            />
            <Button2
              dataCy={SEARCH_BUTTON}
              height="h-8.5"
              type="submit"
              paddingX="px-4"
            >
              <span className="flex items-center gap-1">
                <MagnifierIcon className="fill-current" />
                <span>{t(`${TRANSLATION_PATH}.${SEARCH_BUTTON}`)}</span>
              </span>
            </Button2>
            <Button2
              height="h-8.5"
              dataCy={SKILLS_SEARCH}
              disabled={
                !mustHaveFieldArray?.fields?.length &&
                !niceHaveFieldArray?.fields?.length &&
                !methods.getValues(PROFESSION_SELECT)
              }
              onClick={handleOnClearFilters}
              variant="secondary"
              additionalClass="whitespace-nowrap"
              paddingX="px-4"
            >
              {t(`${TRANSLATION_PATH}.clear`)}
            </Button2>
          </div>
        </div>
        <PillsRow
          mustHaveArr={mustHaveFieldArray.fields}
          niceHaveArr={niceHaveFieldArray.fields}
          onRemove={(itemId: number, isMustHave?: boolean) =>
            isMustHave
              ? mustHaveFieldArray.remove(itemId)
              : niceHaveFieldArray.remove(itemId)
          }
        />
      </form>
    </FormProvider>
  );
};

export default SearchRow;
