import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { TIME_TRACKER_LABEL_PREFIX } from "../../shared/data-cy";
import { TimeTrackerLabelProps } from "./types";

const TimeTrackerLabel = ({
  dataCy,
  text,
  additionalClassName,
  onClick,
}: TimeTrackerLabelProps): ReactElement => (
  <span
    className={classJoin(
      "text-center text-neutralPrimary-30 cursor-default",
      additionalClassName,
      onClick && "cursor-pointer",
    )}
    data-cy={`${TIME_TRACKER_LABEL_PREFIX}-${dataCy}`}
    onClick={onClick}
    role="presentation"
  >
    {text}
  </span>
);

export default TimeTrackerLabel;
