export const PROSPECT_NAME = "prospectName";
export const NAME = "name";
export const CLIENT_NAME = "clientName";
export const CLIENT = "client";
export const PM_NAME = "PMname";
export const BD_NAME = "BDname";
export const CREATION_DATE = "creationDate";
export const DAYS_PASSED = "daysPassed";
export const STATUS = "status";
export const MORE = "more";
export const ACTIVE = "active";
export const ARCHIVED = "archived";
export const PM = "pm";
export const BD = "bd";
export const BY_NAME = "byName";
export const BY_DATE = "byDate";
export const BY_STATUS = "byStatus";
export const ASC = "asc";
export const DESC = "desc";
export const PROJECT_MANAGER = "projectManager";
export const BUSINESS_DEVELOPER = "businessDeveloper";
export const PROSPECTS = "prospects";

export const initialFiltersState = {
  [CLIENT_NAME]: [],
  [PM]: [],
  [BD]: [],
};

export const initialCheckersState = {
  [ACTIVE]: true,
  [ARCHIVED]: false,
};
