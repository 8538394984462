export const BASE_TRANSLATION_PATH = "finance.holidaysUser";
export const HOLIDAYS_USER = "holidaysUser";
export const HOLIDAYS_USER_HISTORY = "holidaysUserHistory";
export const HOLIDAYS_TEMPLATE = "holidaysTemplate";

export const HOLIDAY_TYPE = "holidayType";
export const HOLIDAYS = "holidays";
export const TEMPLATE = "template";
export const MODIFICATION = "modification";
export const USER_TOTAL = "userTotal";
export const USED = "used";
export const UNUSED = "unused";
export const DATES = "dates";
export const AMOUNT_OF_DAYS = "amountOfDays";
export const DATE_FROM = "dateFrom";
export const TYPE_NAME = "typeName";

export const NO_LIMIT_THRESHOLD = 366;
