import { ReactElement, useEffect, useState } from "react";
import { uid } from "react-uid";
import { useDispatch } from "redux-react-hook";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import CardContainer from "./common/cardContainer";
import TilesGroup from "./common/tilesGroup";
import { ENJOYMENT_SCALE, KNOWLEDGE_SCALE } from "../../../../shared/consts";
import { IS_PRODUCTION } from "../../../../../shared/constants";

import ColorPickerTilePanel from "./common/colorPickerTilePanel";
import EnjoymentScaleWithDesc from "./common/enjoymentScaleWithDesc";
import KnowledgeScaleWithDesc from "./common/knowledgeScaleWithDesc";
import SkillsService from "../../../../services/skills";
import {
  SettingsSectionMapState,
  SettingsSectionMapStateReturn,
  SettingsSectionProps,
} from "./types";
import { DictionaryEntryModel } from "../../../../../dictionaries";
import { fetchTemplates } from "../../../../store";
import { RatingScaleModel } from "../../../../models";

const TRANSLATION_PATH = "expertise.matrix.settings";

const SettingsSection = ({ section }: SettingsSectionProps): ReactElement => {
  const [enjoymentScale, setEnjoymentScale] = useState<RatingScaleModel[]>([]);
  const [knowledgeScale, setKnowledgeScale] = useState<RatingScaleModel[]>([]);
  const dispatch = useDispatch();
  const { t } = useTranslationWithNamespace();

  const mapState = (
    state: SettingsSectionMapState,
  ): SettingsSectionMapStateReturn => ({
    professions: state?.dictionaries?.profession || [],
    domains:
      state?.dictionaries?.domain?.map(
        (domain: DictionaryEntryModel) => domain.id,
      ) || [],
    templates: state?.skillsMatrix?.templates,
  });
  const { domains, professions, templates } = useMappedStateSelector(mapState);

  useEffect(() => {
    if (templates && (!enjoymentScale?.length || knowledgeScale?.length)) {
      const firstTemplate = Object.values(templates)?.[0];
      if (firstTemplate) {
        // TODO temporary limited because of hh2-2698
        setEnjoymentScale(firstTemplate?.enjoymentScale?.slice(0, 5));
        setKnowledgeScale(firstTemplate?.knowledgeScale?.slice(0, 6));
      }
    }
  }, [templates]);

  const isKnowledgeScale = section === KNOWLEDGE_SCALE;

  const methods = useForm({
    defaultValues: isKnowledgeScale ? knowledgeScale : enjoymentScale,
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: isKnowledgeScale ? KNOWLEDGE_SCALE : ENJOYMENT_SCALE,
  });

  useEffect(() => {
    return isKnowledgeScale
      ? methods.reset({ knowledgeScale })
      : methods.reset({ enjoymentScale });
  }, [enjoymentScale, professions]);

  const onSubmit = async (data): Promise<void> => {
    await SkillsService.updateDomainTemplate(
      isKnowledgeScale
        ? {
            ...data,
            domains,
            enjoymentScale,
          }
        : {
            ...data,
            domains,
            knowledgeScale,
          },
    )
      .then(() =>
        Promise.allSettled(
          professions?.map((profession: DictionaryEntryModel) =>
            SkillsService.updateProfessionTemplate(
              profession.id,
              isKnowledgeScale
                ? {
                    ...data,
                    // eslint-disable-next-line
                    // @ts-ignore
                    domains,
                    enjoymentScale,
                    groups: templates?.[profession.id]?.groups,
                  }
                : {
                    ...data,
                    // eslint-disable-next-line
                    // @ts-ignore
                    domains,
                    knowledgeScale,
                    groups: templates?.[profession.id]?.groups,
                  },
            ),
          ),
        ),
      )
      .then(() => fetchTemplates(professions?.[0]?.id, dispatch, t));
  };

  const onCancel = (): void =>
    isKnowledgeScale
      ? methods.reset({ knowledgeScale })
      : methods.reset({ enjoymentScale });

  const onAddPreferenceStep = (score: number): void => {
    append({
      color: { red: 255, blue: 255, green: 255 },
      desc: t(`${TRANSLATION_PATH}.content`),
      iconUrl: "",
      score,
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CardContainer onCancel={onCancel}>
          <>
            <span className="font-semibold text-xl">
              {isKnowledgeScale
                ? t(`${TRANSLATION_PATH}.scale`)
                : t(`${TRANSLATION_PATH}.enjoyment`)}
            </span>
            <div className="flex gap-16">
              {/* TODO temporary limited because of hh2-2698 */}
              {/* <TilesGroup */}
              {/*  baseName={isKnowledgeScale ? KNOWLEDGE_SCALE : ENJOYMENT_SCALE} */}
              {/*  label={ */}
              {/*    isKnowledgeScale */}
              {/*      ? t(`${TRANSLATION_PATH}.defineScale`) */}
              {/*      : t(`${TRANSLATION_PATH}.defineEnjoyment`) */}
              {/*  } */}
              {/*  tiles={fields} */}
              {/*  onAddClick={() => onAddPreferenceStep(fields.length)} */}
              {/*  withColor={-1} */}
              {/*  disabled /}
              {/* /> */}
              <ColorPickerTilePanel
                baseName={isKnowledgeScale ? KNOWLEDGE_SCALE : ENJOYMENT_SCALE}
                fields={fields}
                label={t(`${TRANSLATION_PATH}.colourPalette`)}
                onRemove={(index) => remove(index)}
              />
            </div>
            <hr />
            <span className="text-neutralPrimary-30 pt-8">
              {t(`${TRANSLATION_PATH}.description`)}
            </span>
            <div className="flex gap-8 flex-wrap justify-start pt-4 pb-8">
              {fields.map((item, index) =>
                isKnowledgeScale ? (
                  <KnowledgeScaleWithDesc
                    item={item}
                    fields={fields}
                    index={index}
                    key={uid(item)}
                  />
                ) : (
                  <EnjoymentScaleWithDesc
                    index={index}
                    item={item}
                    key={uid(item)}
                  />
                ),
              )}
            </div>
          </>
        </CardContainer>
      </form>
    </FormProvider>
  );
};

export default SettingsSection;
