import { ReactElement } from "react";
import Calendar from "react-calendar";
import { classJoin } from "@ps/utils";
import { useTranslationWithNamespace } from "../../hooks";
import { TimeTrackerCalendarProps } from "./types";
import { CURRENT_LANGUAGE_CODE } from "../../i18n/constants";

const TimeTrackerCalendar = ({
  maxDate,
  minDate,
  onChange,
  value,
  isRangePicker = false,
  presets = [],
  onClickPreset,
  selectedPreset,
}: TimeTrackerCalendarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  // TODO: change first day of week (in calendar) dependent of user's choice in dashboard

  const toCalendarType = (languageCode?: string): "US" | "ISO 8601" =>
    languageCode === CURRENT_LANGUAGE_CODE ? "US" : "ISO 8601";

  return (
    <div className="flex items-start gap-2">
      {presets?.length && onClickPreset ? (
        <div className="flex flex-col gap-3 items-start py-5 border-r border-neutralSecondary-60 my-2.5">
          {presets.map((preset: string) => (
            <button
              key={preset}
              className={classJoin(
                "cursor-pointer text-neutralPrimary-30 hover:text-neutralPrimary-20 w-full px-5 py-1",
                selectedPreset === preset ? "bg-primary-85" : "",
              )}
              onClick={() => onClickPreset(preset)}
              tabIndex={0}
            >
              {t(`time-tracker.${preset}`)}
            </button>
          ))}
        </div>
      ) : (
        <></>
      )}

      <Calendar
        maxDate={maxDate}
        minDate={minDate}
        onChange={onChange}
        returnValue="range"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={value}
        selectRange={isRangePicker}
        showDoubleView={isRangePicker}
        calendarType={toCalendarType()}
        locale="en-GB"
      />
    </div>
  );
};

export default TimeTrackerCalendar;
