import { ReactElement } from "react";
import { Button } from "@ps/ui-components";
import { ReactComponent as InfoIcon } from "../../../../../../../images/info.svg";
import { ConfirmRemoveRowProps } from "../../types";
import { useTranslationWithNamespace } from "../../../../../../../hooks";
import { SKILLS_MATRIX_EXPERTISE_TEMPLATE } from "../../../../../../../shared/data-cy";

const ConfirmRemoveRow = ({
  onCancelClick,
  onRemove,
}: ConfirmRemoveRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="w-full items-center bg-primary-50 flex gap-4 p-4">
      <InfoIcon className="text-primary-100 fill-current w-10 h-10" />
      <span className="text-primary-100">
        {t("expertise.matrix.editMatrix.sureToDelete")}
      </span>
      <Button
        variant="primaryNegative"
        dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_delete`}
        onClick={onRemove}
      >
        {t("expertise.actions.delete")}
      </Button>
      <Button
        variant="secondaryNegative"
        dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_cancel`}
        onClick={onCancelClick}
      >
        {t("expertise.actions.cancel")}
      </Button>
    </div>
  );
};

export default ConfirmRemoveRow;
