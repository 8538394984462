import {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
} from "react";
import { classJoin } from "@ps/utils";
import { ReactComponent as Magnifier } from "../../images/magnifier.svg";
import { Keys } from "../../shared";
import { SearchInputProps } from "./types";

const wrapperStyle = `rounded-md flex border bg-neutralPrimary-100 text-neutralPrimary-20
 justify-between items-center pl-2 transition-all hover:border-neutralPrimary-40`;

const SearchInput = ({
  dataCy,
  value,
  placeholder,
  onChange,
}: SearchInputProps): RreactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) inputRef.current?.focus();
    else onChange("");
  }, [inputRef, open]);

  const toggleOpen = (): void => setOpen((o) => !o);
  const toggleStyle = open ? "w-52 border-neutralPrimary-40" : "w-12";
  const visibilityToggle = open ? "visible" : "invisible";

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value);
    if (!e.target.value.length) setOpen(false);
  };

  const handleKeyDownOnButton = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === Keys.ENTER) toggleOpen();
    if (e.key === Keys.ESC) setOpen(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === Keys.ESC) {
      setOpen(false);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
    if (!e.target.value.length) setOpen(false);
  };

  return (
    <div data-cy={dataCy} className={classJoin(wrapperStyle, toggleStyle)}>
      <div
        role="button"
        onClick={toggleOpen}
        onKeyDown={handleKeyDownOnButton}
        tabIndex={0}
        className="h-10 w-11 flex pl-1 pr-3 justify-center items-center cursor-pointer
        text-neutralPrimary-40 hover:text-neutralPrimary-20 transition-colors"
      >
        <Magnifier className="h-6 w-6" />
      </div>
      <input
        ref={inputRef}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        className={classJoin(
          "outline-none overflow-hidden bg-transparent",
          visibilityToggle,
        )}
      />
    </div>
  );
};

export default SearchInput;
