import { ReactElement, useState } from "react";
import { AddHolidayModal } from "@ps/hh";
import dayjs from "dayjs";
import { useDispatch } from "redux-react-hook";
import MyHistoryEntriesContainer from "./myHistoryEntriesContainer";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../hooks";
import DaysOff from "./days-off";
import { HolidayNewTimeEntryModel } from "../../models";
import { HolidaysService } from "../../services";
import { MyHolidaysState, MyHolidaysStateReturn } from "./types";
import { fetchHolidaysHistory } from "../../store/actions/myHolidaysActions";
import AddHolidayButton from "./addHolidayButton";

const MyHolidays = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();

  const [isAddHolidayModalOpen, setIsAddHolidayModalOpen] = useState(false);

  const mapState = (state: MyHolidaysState): MyHolidaysStateReturn => ({
    templateHolidays: state.holidays.userHolidays || [],
  });

  const { templateHolidays } = useMappedStateSelector(mapState);

  const handleOnNewHolidayEntrySave = async (
    data: HolidayNewTimeEntryModel,
  ): Promise<void> => {
    await HolidaysService.createHolidayTimeEntry(data);
    await fetchHolidaysHistory(dispatch);
  };

  const handleOnRemove = async (idsToRemove: string[]): Promise<void> => {
    await HolidaysService.deleteHolidayHistory(idsToRemove);
    await fetchHolidaysHistory(dispatch);
  };

  return (
    <section className="flex flex-col h-full">
      <div className="flex items-center gap-8 justify-end">
        <AddHolidayButton onClick={() => setIsAddHolidayModalOpen(true)} />
      </div>

      <h1 className="text-lg font-semibold pb-8 pt-4">
        {t("holidays.yourDaysOff")}
      </h1>
      <DaysOff />
      <MyHistoryEntriesContainer
        onAddHoliday={() => setIsAddHolidayModalOpen(true)}
        onRemove={handleOnRemove}
      />
      <AddHolidayModal
        holidayTypesList={templateHolidays}
        isOpen={isAddHolidayModalOpen}
        minDate={dayjs().subtract(1, "day")}
        onClose={() => setIsAddHolidayModalOpen(false)}
        onSave={handleOnNewHolidayEntrySave}
      />
    </section>
  );
};

export default MyHolidays;
