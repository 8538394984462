import {
  HolidaysItemModel,
  HolidaysItemAPIModel,
  HolidaysTemplateAPIModel,
  HolidaysTemplateModel,
  HolidaysTemplatesModel,
} from "../types";
import { NO_LIMIT_THRESHOLD } from "../../ui/constants";

export const holidaysItemFromAPI = (
  data: HolidaysItemAPIModel,
  isMainHoliday = false,
): HolidaysItemModel => ({
  holidaysId: data.id,
  name: data.holidaysName,
  numberOfDaysOff: data.numberOfDaysOff,
  payRatePercentage: data.payRatePercentage * 100,
  noLimit: data.numberOfDaysOff === NO_LIMIT_THRESHOLD,
  allowPartialDayOff: data.allowPartialDayOff,
  isMainHoliday,
});

export const holidaysTemplateFromAPI = (
  data: HolidaysTemplateAPIModel,
): HolidaysTemplateModel => {
  const mainHoliday = data?.holidaysTemplateItems?.main;
  const secondaryHolidays = data?.holidaysTemplateItems?.secondary;

  return {
    id: data.id,
    name: data.name,
    holidays: mainHoliday
      ? [
          holidaysItemFromAPI(mainHoliday, true),
          ...(secondaryHolidays?.length
            ? secondaryHolidays.map(
                (item: HolidaysItemAPIModel): HolidaysItemModel =>
                  holidaysItemFromAPI(item),
              )
            : []),
        ]
      : [],
  };
};

export const holidaysTemplatesFromAPI = (
  data: HolidaysTemplateAPIModel[],
): HolidaysTemplatesModel =>
  data.map(
    (template: HolidaysTemplateAPIModel): HolidaysTemplateModel =>
      holidaysTemplateFromAPI(template),
  );
