import {
  ExpandableSkillFulfillmentAPIModel,
  ExpandableSkillFulfillmentModel,
  FulfillmentsTemplateAPIModel,
  FulfillmentsTemplateModel,
  SkillFulfillmentAPIModel,
  SkillFulfillmentModel,
  SkillsSearchQueryResultAPIModel,
  SkillsSearchQueryResultModel,
  UsersSkillsFulfillmentsAPIModel,
  UsersSkillsFulfillmentsModel,
} from "../types";

const skillsSearchQueryResultFromAPIToModel = (
  data: SkillsSearchQueryResultAPIModel,
): SkillsSearchQueryResultModel => ({
  template: data.template.map(
    (
      templateItem: FulfillmentsTemplateAPIModel,
    ): FulfillmentsTemplateModel => ({
      skillId: templateItem.technologyId,
      requiredness: templateItem.requiredness,
      expandableSkills: templateItem.otherProfessions,
    }),
  ),
  users: data.profiles.length
    ? data.profiles.map(
        (
          profile: UsersSkillsFulfillmentsAPIModel,
        ): UsersSkillsFulfillmentsModel => ({
          userId: profile.userId,
          firstName: profile?.firstName || "",
          lastName: profile?.lastName || "",
          skills: profile?.techs.length
            ? profile.techs.map(
                (skill: SkillFulfillmentAPIModel): SkillFulfillmentModel => ({
                  skillId: skill?.techId || "",
                  knowledgeScale: skill?.k || 0,
                  enjoymentScale: skill?.e,
                  expandableSkills: skill?.othProf.length
                    ? skill.othProf.map(
                        (
                          expandableSkill: ExpandableSkillFulfillmentAPIModel,
                        ): ExpandableSkillFulfillmentModel => ({
                          professionId: expandableSkill?.profId || "",
                          knowledgeScale: expandableSkill?.k || 0,
                          enjoymentScale: expandableSkill?.e,
                        }),
                      )
                    : [],
                }),
              )
            : [],
        }),
      )
    : [],
});

export default skillsSearchQueryResultFromAPIToModel;
