const divideArrayByField = (array: any, field: string) =>
  array?.reduce(
    (acc: any, item: any): boolean =>
      item?.[field]
        ? { ...acc, positives: [...acc.positives, item] }
        : { ...acc, negatives: [...acc.negatives, item] },
    { positives: [], negatives: [] },
  );

export default divideArrayByField;
