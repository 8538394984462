/* eslint @typescript-eslint/no-explicit-any: 0 */
import { IMember } from "@ps/permissions";
import {
  CreateRoleAPIModel,
  NewRoleModel,
  NewUserModel,
  UpdatedRoleModel,
  UpdateRoleAPIModel,
} from "../types";

const getPermissionFromDeepObject = (data) => {
  const allPermissions = data.reduce(
    (acc, item) => [...acc, item.permissions],
    [],
  );
  return allPermissions
    .flat()
    .filter((item) => item.checked)
    .map((filtered) => filtered.itemId);
};

export const newRoleToAPIModel = (data: NewRoleModel): CreateRoleAPIModel => ({
  name: data.name,
  permissions: data?.permissions.length
    ? getPermissionFromDeepObject(data.permissions)
    : [],
});

export const updatedRoleToAPIModel = (
  data: UpdatedRoleModel,
): UpdateRoleAPIModel => ({
  name: data.name,
  permissions: data?.permissions.length
    ? getPermissionFromDeepObject(data.permissions)
    : [],
});

const newUserToApiModel = (user: IMember): NewUserModel => {
  return {
    email: user.email,
    firstName: user.name,
    lastName: user.surname,
    group: user.group?.label,
    role: user?.profileType?.label?.toLowerCase(),
    cv: user?.cv,
  };
};

// TODO add types
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const newUsersListToAPIModel = (items: any): any =>
  items.map((item) => newUserToApiModel(item));
