// eslint-disable-next-line import/prefer-default-export
export const TT_CHANGE_COLOUR = "change_colour";
export const TOTAL_LABEL = "total-label";
export const TOTAL_DAY = "total-day";
export const TOTAL_TIME_COUNTED = "total-time-counted";
export const TOTAL_TIME_COUNTED_ROW = `${TOTAL_TIME_COUNTED}-row`;
export const PROJECT_LABEL = "project-label";
export const PROJECT_NAME_LABEL = "project-name-label";
export const WEEK_DAY = "week-day";
export const TIME_INPUT = "time-input";
export const CHECK_ICON = "check-icon";
export const TIME_NAVIGATOR = "time-navigator";
export const CLIENT_LABEL = "client-label";
export const ADD_PROJECT_BUTTON = "add-project-button";
export const FILL_UP_LIKE_LAST_WEEK_BUTTON = "copy-previous-week-button";
export const SELECT_PROJECT = "select-project";
export const SELECT_RATE = "select-rate";
export const NOTE_INPUT = "note-input";
export const PAGE_404_TIME_TRACKER = "page-404-time-tracker";
export const TIME_TRACKER = "time-tracker";
export const DASHBOARD_CIRCLE_CHART = "dashboard-circle-chart";
export const DASHBOARD_PROJECT = "dashboard-project";
export const TIME_TRACKER_BAR_CHART = `${TIME_TRACKER}_bar-chart`;
export const TIME_TRACKER_REMOVE = `${TIME_TRACKER}_remove`;

export const HOLIDAY = "holiday";
export const HOLIDAY_TYPE = "holiday-type";
export const REPORTS = "reports";
export const ROW = "row";
export const PROJECT_ROW_PIN = "project-row-pin";
export const ROW_DELETE = `${ROW}-delete`;
