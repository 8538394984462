import dayjs, { Dayjs } from "dayjs";
import { Dispatch } from "react";
import {
  Action,
  InputActionTypes,
  InputType,
  SelectedDatesActionTypes,
  SelectedDatesHelperActions,
  SelectedDatesRangeActionTypes,
} from "./useSelectDateTypes";

const createActions = (
  dispatch: Dispatch<Action>,
): SelectedDatesHelperActions => {
  const onPointerEnter = (date: Dayjs) => () =>
    dispatch({ type: SelectedDatesActionTypes.HOVER, payload: date });
  const onPointerLeave = () =>
    dispatch({ type: SelectedDatesActionTypes.HOVER_LEAVE, payload: dayjs() });
  const onClick = (date: Dayjs) => () =>
    dispatch({ type: SelectedDatesActionTypes.SELECT, payload: date });
  const selectFirst = (date: Dayjs) =>
    dispatch({ type: SelectedDatesActionTypes.SELECT_FIRST, payload: date });
  const forceSelectFirst = (date: Dayjs) =>
    dispatch({
      type: SelectedDatesActionTypes.FORCE_SELECT_FIRST,
      payload: date,
    });
  const selectSecond = (date: Dayjs) =>
    dispatch({ type: SelectedDatesActionTypes.SELECT_SECOND, payload: date });
  const forceSelectSecond = (date: Dayjs) =>
    dispatch({
      type: SelectedDatesActionTypes.FORCE_SELECT_SECOND,
      payload: date,
    });
  const selectRange = (range: [Dayjs, Dayjs]) =>
    dispatch({
      type: SelectedDatesRangeActionTypes.SELECT_RANGE,
      payload: range,
    });
  const forceSelectRange = (range: [Dayjs, Dayjs]) =>
    dispatch({
      type: SelectedDatesRangeActionTypes.FORCE_SELECT_RANGE,
      payload: range,
    });
  const clear = () =>
    dispatch({ type: SelectedDatesActionTypes.CLEAR, payload: dayjs() });
  const clearRange = (actionType: SelectedDatesActionTypes) =>
    dispatch({
      type: actionType,
      payload: dayjs(),
    });
  const changeInput = (input: InputType) =>
    dispatch({ type: InputActionTypes.CHANGE_INPUT, payload: input });

  return {
    onPointerEnter,
    onPointerLeave,
    onClick,
    selectFirst,
    forceSelectFirst,
    selectSecond,
    forceSelectSecond,
    selectRange,
    forceSelectRange,
    clear,
    clearRange,
    changeInput,
  };
};

export default createActions;
