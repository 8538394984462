import dayjs, { Dayjs } from "dayjs";
import { DatepickerMode } from "../../types";
import {
  Action,
  InputActionTypes,
  InputType,
  SelectedDates,
  SelectedDatesActionTypes,
  SelectedDatesRangeActionTypes,
  State,
} from "./useSelectDateTypes";

interface CreateReducerProps {
  mode: DatepickerMode;
  updateDisplayedMonth?: (date: Dayjs) => void;
  disabledDate?: (date: Dayjs, currentSelection: SelectedDates) => boolean;
  onClear?: () => void;
  clearRange?: (actionType: Dayjs) => void;
}

const createReducer =
  ({
    mode,
    disabledDate,
    updateDisplayedMonth,
    onClear,
    clearRange,
  }: CreateReducerProps) =>
  (state: State, action: Action): State => {
    const returnWhenNotDisabled = (obj: State): State => {
      if (!dayjs.isDayjs(action.payload)) {
        if (Array.isArray(action.payload)) {
          const startCheck = disabledDate?.(action.payload[0], state.selected);
          const endCheck = disabledDate?.(action.payload[1], state.selected);

          return startCheck || endCheck ? state : obj;
        }
        return obj;
      }
      if (
        dayjs.isDayjs(action.payload) &&
        !disabledDate?.(action.payload, state.selected)
      ) {
        return obj;
      }
      return state;
    };

    const [rangeStart, rangeEnd] = state.selected ?? [undefined, undefined];

    switch (action.type) {
      case SelectedDatesActionTypes.HOVER:
        return returnWhenNotDisabled({ ...state, hover: action.payload });
      case SelectedDatesActionTypes.HOVER_LEAVE:
        return returnWhenNotDisabled({ ...state, hover: undefined });
      case SelectedDatesActionTypes.SELECT: {
        if (mode === DatepickerMode.DATE_PICKER) {
          return returnWhenNotDisabled({
            ...state,
            selected: [action.payload, undefined],
            input: InputType.SECOND,
          });
        }
        if (state.input === InputType.FIRST) {
          const isRangeEndBeforePayload = rangeEnd?.isBefore(action.payload);
          return returnWhenNotDisabled({
            ...state,
            selected: isRangeEndBeforePayload
              ? [rangeEnd, action.payload]
              : [action.payload, rangeEnd],
            input: InputType.SECOND,
          });
        }
        if (
          rangeStart &&
          rangeEnd &&
          rangeStart.isSame(action.payload, "day")
        ) {
          return returnWhenNotDisabled({
            ...state,
            selected: [undefined, rangeEnd],
            input: InputType.FIRST,
          });
        }
        if (rangeStart?.isAfter(action.payload)) {
          return returnWhenNotDisabled({
            ...state,
            selected: [action.payload, rangeEnd],
            input: InputType.SECOND,
          });
        }
        return returnWhenNotDisabled({
          ...state,
          selected: [rangeStart, action.payload],
          input: rangeStart ? InputType.SECOND : InputType.FIRST,
        });
      }
      case SelectedDatesActionTypes.SELECT_FIRST: {
        updateDisplayedMonth?.(action.payload);
        const isPayloadAfterRangeEnd =
          rangeEnd && action.payload.isAfter(rangeEnd, "day");

        return returnWhenNotDisabled({
          ...state,
          selected: isPayloadAfterRangeEnd
            ? [rangeEnd, action.payload]
            : [action.payload, rangeEnd],
          input: InputType.SECOND,
        });
      }
      case SelectedDatesActionTypes.FORCE_SELECT_FIRST: {
        updateDisplayedMonth?.(action.payload);
        return {
          ...state,
          selected: [action.payload, rangeEnd],
          input: InputType.SECOND,
        };
      }
      case SelectedDatesActionTypes.SELECT_SECOND: {
        updateDisplayedMonth?.(action.payload);
        const isPayloadBeforeRangeStart =
          rangeStart && action.payload.isBefore(rangeStart, "day");

        return returnWhenNotDisabled({
          ...state,
          selected: isPayloadBeforeRangeStart
            ? [action.payload, rangeStart]
            : [rangeStart, action.payload],
          input: InputType.FIRST,
        });
      }
      case SelectedDatesActionTypes.FORCE_SELECT_SECOND: {
        updateDisplayedMonth?.(action.payload);
        return {
          ...state,
          selected: [rangeStart, action.payload],
          input: InputType.FIRST,
        };
      }
      case SelectedDatesRangeActionTypes.SELECT_RANGE: {
        const [first, second] = action.payload;
        updateDisplayedMonth?.(first);
        const isFirstBeforeSecond = first.isBefore(second, "day");
        return returnWhenNotDisabled({
          ...state,
          selected: isFirstBeforeSecond ? action.payload : [second, first],
          input: InputType.FIRST,
        });
      }
      case SelectedDatesRangeActionTypes.FORCE_SELECT_RANGE: {
        updateDisplayedMonth?.(action.payload[0]);
        return {
          ...state,
          selected: action.payload,
          input: InputType.FIRST,
        };
      }
      case SelectedDatesActionTypes.CLEAR:
        onClear?.();
        return { ...state, selected: undefined, input: InputType.FIRST };
      case SelectedDatesActionTypes.CLEAR_RANGE_START:
        clearRange?.(action.payload);
        return {
          ...state,
          selected: [undefined, rangeEnd],
          input: InputType.FIRST,
        };
      case SelectedDatesActionTypes.CLEAR_RANGE_END:
        clearRange?.(action.payload);
        return {
          ...state,
          selected: [rangeStart, undefined],
          input: InputType.SECOND,
        };

      case InputActionTypes.CHANGE_INPUT:
        return { ...state, input: action.payload };
      default:
        return state;
    }
  };
export default createReducer;
