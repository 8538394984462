import APIService from "../api/time-tracker-api";
import {
  timeEntriesFromAPIModel,
  ProjectUpdatedTimeEntryModel,
  ProjectNewTimeEntryModel,
  TimesheetModel,
  toAPIModel,
} from "../models";
import {
  entriesBarChartGroup,
  entriesGroupedByTypeId,
  entriesGroupedByType,
} from "./utils";
import handleError from "../shared/utils";
import { SUCCESS_TOAST_DURATION_TIME } from "../shared/constants";
import messages from "./messages.json";

const TimeEntriesService = (() => {
  const fetchTimesheet = async (from: string, to: string) => {
    try {
      const response = await APIService.API.getAuthedUserTimeEntriesInDayRange(
        from,
        to,
      );
      if (response?.data) {
        const formattedData: TimesheetModel = timeEntriesFromAPIModel(
          response.data,
        );
        const groupedData = entriesGroupedByType([...formattedData]);
        const timesheet = {
          project: groupedData?.project?.length
            ? entriesGroupedByTypeId(groupedData.project)
            : {},
          holiday: groupedData?.holiday?.length
            ? entriesGroupedByTypeId(groupedData?.holiday)
            : {},
        };
        return {
          timesheet,
          barChartData: entriesBarChartGroup(formattedData, from, to),
        };
      }
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const createProjectTimeEntry = async (
    newEntry: ProjectNewTimeEntryModel,
    silentMode = false,
  ) => {
    try {
      const response = await APIService.API.addTimeEntry(
        toAPIModel.newProjectTimeEntrytoAPIModel(newEntry),
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          silentMode,
          customMessages: {
            201: {
              message: messages.createProjectTimeEntry201,
              timeoutDuration: SUCCESS_TOAST_DURATION_TIME,
            },
          },
        },
      );
      if (response?.data) {
        return response;
      }
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const copyPreviousWeekEntries = async (
    startOfTheWeek: string,
    silentMode = false,
  ) => {
    try {
      const response = await APIService.API.copyPreviousWeekTimeEntries(
        startOfTheWeek,
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          silentMode,
          customMessages: {
            204: {
              message: messages.copyPreviousWeekEntries204,
              timeoutDuration: SUCCESS_TOAST_DURATION_TIME,
            },
          },
        },
      );

      return response;
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchPreviousWeekData = async (from: string, to: string) => {
    try {
      const response = await APIService.API.getAuthedUserTimeEntriesInDayRange(
        from,
        to,
      );
      if (response?.data) {
        const formattedData = timeEntriesFromAPIModel(response.data);
        return {
          previousWeekTimesheet: entriesGroupedByTypeId(formattedData),
        };
      }
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const updateProjectTimeEntry = async (
    id: string,
    updatedEntry: ProjectUpdatedTimeEntryModel,
    silentMode = false,
  ) => {
    try {
      const response = await APIService.API.updateTimeEntry(
        id,
        toAPIModel.updatedProjectTimeEntryToAPIModel(updatedEntry),
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          silentMode,
          customMessages: {
            204: {
              message: messages.updateProjectTimeEntry204,
              timeoutDuration: SUCCESS_TOAST_DURATION_TIME,
            },
          },
        },
      );
      return response;
    } catch (error) {
      return handleError(error);
    }
  };

  const removeTimesheetEntry = async (id: string, silentMode = false) => {
    try {
      const response = await APIService.API.deleteTimeEntry(id, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        silentMode,
        customMessages: {
          204: {
            message: messages.removeTimesheetEntry204,
            timeoutDuration: SUCCESS_TOAST_DURATION_TIME,
          },
        },
      });
      return response;
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteManyTimeEntries = async (ids: string[]): Promise<any> => {
    try {
      const response = await Promise.allSettled(
        ids.map((id: string) => removeTimesheetEntry(id, true)),
      );
      return response;
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    copyPreviousWeekEntries,
    createProjectTimeEntry,
    deleteManyTimeEntries,
    fetchPreviousWeekData,
    fetchTimesheet,
    removeTimesheetEntry,
    updateProjectTimeEntry,
  };
})();

export default TimeEntriesService;
