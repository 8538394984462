import { ReactElement, KeyboardEvent, MouseEvent } from "react";
import { classJoin } from "@ps/utils";
import { CheckboxProps } from "./types";
import { ReactComponent as CheckboxIcon } from "../../images/check-checkbox.svg";
import { ReactComponent as NeutralCheckbox } from "../../images/neutral-checkbox.svg";
import { CHECKBOX_PREFIX } from "../../shared/data-cy";
import { focusVisibleStyles, Keys } from "../../shared";

const checkboxStyles = {
  checked: classJoin.template`
  bg-primary-50 border border-primary-50`,
  notChecked: classJoin.template`
  bg-neutralPrimary-100 border border-neutralSecondary-60`,
  disabled: classJoin.template`
  bg-opacity-60 cursor-not-allowed`,
  neutral: classJoin.template`
  bg-primary-50 border border-neutralSecondary-60`,
};

const Checkbox = ({
  additionalClassName,
  checked,
  dataCy,
  disabled,
  isNeutral,
  onChecked,
  tabIndex = 0,
}: CheckboxProps): ReactElement => {
  const getProperStyle = () => {
    if (isNeutral) return checkboxStyles.neutral;
    if (checked) return checkboxStyles.checked;
    return checkboxStyles.notChecked;
  };

  const styleIfDisabled = disabled ? checkboxStyles.disabled : undefined;

  const handleCheck = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    !disabled ? onChecked?.(!checked) : undefined;
  };

  return (
    <div
      className={classJoin(
        "w-5 h-5 rounded-md flex items-center justify-center",
        focusVisibleStyles,
        getProperStyle(),
        styleIfDisabled,
        additionalClassName,
      )}
      role={disabled ? "presentation" : "button"}
      tabIndex={disabled ? undefined : tabIndex}
      onClick={handleCheck}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (
          (event.key === Keys.ENTER || event.code === Keys.SPACE) &&
          !disabled
        ) {
          event.preventDefault();
          onChecked?.(!checked);
        }
      }}
      data-cy={`${CHECKBOX_PREFIX}_${dataCy}`}
    >
      {checked && !isNeutral && <CheckboxIcon className="text-primary-100" />}
      {isNeutral && <NeutralCheckbox className="text-primary-100" />}
    </div>
  );
};

export default Checkbox;
