import { AxiosResponse } from "axios";
import APIService from "../api/prospect-api";
import {
  createNoteToAPIModel,
  updateNoteToAPIModel,
  NoteModel,
  CreateNoteResponseAPIModel,
} from "../models";
import { handleError } from "../../shared/utils/service-utils";

export const createNote = async (
  prospectId: string,
  data: NoteModel,
): Promise<AxiosResponse<CreateNoteResponseAPIModel>> => {
  try {
    const result = await APIService.API.addNote(
      prospectId,
      // TODO to fix TS error later
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      createNoteToAPIModel(data),
    );

    return result;
  } catch (error) {
    return handleError(error);
  }
};

export const updateNote = async (
  prospectId: string,
  data: NoteModel,
): Promise<AxiosResponse<void>> => {
  try {
    const result = await APIService.API.updateNote(
      prospectId,
      data.id,
      updateNoteToAPIModel(data),
    );
    return result;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteNote = async (
  prospectId: string,
  id: string,
): Promise<AxiosResponse<void>> => {
  try {
    const result = await APIService.API.deleteNote(prospectId, id);
    return result;
  } catch (error) {
    return handleError(error);
  }
};
