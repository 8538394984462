import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { uid } from "react-uid";
import { useThemeMode } from "../theme-mode-provider";
import styles from "./styles.module.css";
import { TabsPlaceholder } from "../tabs";

const LoadingPanels = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const { isHighContrast } = useThemeMode();

  return (
    <div className="flex h-screen w-full">
      <div className="bg-primary-10 w-24 h-full flex flex-col justify-self-start">
        <div className="py-12 mx-5">
          <div
            className={classJoin(styles.item, "bg-white w-full h-6 rounded-lg")}
          />
        </div>
        <div className="flex flex-col justify-start mx-5 gap-y-6 h-2/3">
          <div
            className={classJoin(
              styles.item,
              "h-5 w-5 rounded-lg",
              isHighContrast ? "bg-primary-95" : "bg-primary-51",
            )}
          />
          {Array(8)
            .fill(0)
            .map(
              (_, index: number): ReactElement => (
                <div
                  key={uid(index)}
                  className={classJoin(
                    styles.item,
                    "h-5 w-full rounded-lg",
                    isHighContrast ? "bg-primary-95" : "bg-primary-51",
                  )}
                />
              ),
            )}
        </div>
        <div
          className={classJoin(
            "text-primary-100 rounded mx-3 mt-16 px-4 py-2.5",
            isHighContrast ? "bg-primary-95" : "bg-primary-51",
          )}
        >
          <div
            className={classJoin(
              styles.item,
              "bg-neutralSecondary-40 h-8 w-8 rounded-md self-center",
            )}
          />
        </div>
      </div>
      <section className="content-container h-screen">
        <div className="header-section bg-neutralPrimary-100 justify-end">
          <div
            className={classJoin(
              styles.item,
              "h-3 w-20 rounded-lg",
              isHighContrast ? "bg-primary-95" : "bg-primary-51",
            )}
          />
        </div>
        <TabsPlaceholder />
        {children || <div className="h-full bg-neutralPrimary-85" />}
      </section>
    </div>
  );
};

export default LoadingPanels;
