import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { Folders } from "../../../folder-domain/ui";

const ClientsFoldersList = (): ReactElement => {
  const params = useParams<{ id: string }>();

  return (
    <div className="flex flex-col gap-8 h-full overflow-hidden">
      <Folders clientId={params.id} />
    </div>
  );
};

export default ClientsFoldersList;
