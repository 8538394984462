import { ReactElement, useRef, useState } from "react";
import en from "date-fns/locale/en-GB";
import { classJoin, prepareDictionarySearchSelect } from "@ps/utils";
import { Controller, useFormContext } from "react-hook-form";
import { UserDomainModel, FilesService } from "@ps/hh";
import { uid } from "react-uid";
import { registerLocale } from "react-datepicker";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import {
  Button2,
  Input,
  LinkButton,
  ModalMainText2,
  ToggleInline,
  SearchSelect,
  Datepicker2,
} from "@ps/ui-components";
import { useDispatch } from "redux-react-hook";
import { BUTTON, INVITING_MODAL } from "../../../shared/data-cy";
import { ReactComponent as AddMemberIcon } from "../../../images/members/add-user.svg";
import { ReactComponent as XIcon } from "../../../images/x.svg";
import { ReactComponent as RefreshIcon } from "../../../images/members/refresh.svg";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { InvitingLabel, ProgressBar } from "./components";
import { AddNewMemberModalMapState } from "../types";
import UploadFileInput from "./components/uploadFileInput";
import { EMAIL_REGEXP_PATTERN } from "../../../shared/constants";
import { IMember, MemberDataScreenProps } from "./types";
import { validateFile } from "./utils";
import styles from "./styles.module.css";
import {
  errorUploadCVList,
  startUploadCVList,
  successUploadCVList,
} from "../../../actions";
import { useInvitingModalContext } from "./context";

const TRANSLATION_PATH = "members.inviting";

const MemberDataScreen = ({
  addAddedMemberList,
  fields,
  goNext,
  id,
  isEditMode,
  MARGIN,
  membersFieldsList,
  onCancel,
  remove,
  update,
}: MemberDataScreenProps): ReactElement => {
  const dispatch = useDispatch();
  const { control, getValues, trigger, clearErrors, reset } = useFormContext();

  const currentItem = getValues(`addingMembers[${id}]`);
  const [isCVLoading, setIsCVLoading] = useState(false);

  const locale = "en";
  registerLocale(locale, en);
  const { t } = useTranslationWithNamespace();

  const mapState = (
    state: AddNewMemberModalMapState,
  ): AddNewMemberModalMapState => ({
    allRoles: state?.allRoles || [],
    users: state?.users || [],
  });

  const { allRoles, users } = useMappedStateSelector(mapState, "hh");

  const {
    step,
    setStep,
    setActiveMemberId,
    setTotalStepLength,
    totalStepLength,
  } = useInvitingModalContext();

  const getFieldName = (field: string): string =>
    `addingMembers[${id}].${field}`;

  const emailFieldName = getFieldName("email");
  const nameFieldName = getFieldName("name");
  const surnameFieldName = getFieldName("surname");
  const profileTypeFieldName = getFieldName("profileType");
  const groupFieldName = getFieldName("group");
  const startDateFieldName = getFieldName("startDate");
  const fileFieldName = getFieldName("file");

  const inputRef = useRef<HTMLInputElement>(null);
  const handleBrowseClick = () => {
    inputRef.current?.click();
    inputRef.current.value = null;
  };

  const toggleOptions = [
    t(`${TRANSLATION_PATH}.operative`),
    t(`${TRANSLATION_PATH}.specialist`),
  ];

  const roles = [
    {
      id: "",
      label: t(`${TRANSLATION_PATH}.noGroup`),
      value: "noGroup",
    },
    ...prepareDictionarySearchSelect(
      allRoles,
      "itemId",
      "name",
      "name",
      "name",
    ),
  ];

  const usersEmails: string[] = users?.map(
    (user: UserDomainModel) => user.email,
  );

  const addedNewEmails: string[] = membersFieldsList
    .filter(
      (member: IMember | Record<"id", string>) => member?.id !== fields?.id,
    )
    .map((newUser: IMember | Record<"id", string>): string => newUser?.email)
    .filter((email: string) =>
      isEditMode ? email !== currentItem?.email : email,
    );

  const handleOnSaveUserData = async (): Promise<void> => {
    const result = await trigger("addingMembers");
    const temp = currentItem;
    if (!result || temp?.error?.length) return;
    addAddedMemberList(temp);
    goNext();
  };

  const handleOnFileSelected = async (newFiles: FileList): Promise<void> => {
    dispatch(startUploadCVList());
    setIsCVLoading(true);
    if (Object.keys(newFiles)?.length === 0) return;
    const currentFile = newFiles[0];

    const clientError = validateFile(currentFile, t);
    if (clientError?.length) {
      update(id, {
        ...getValues(`addingMembers[${id}]`),
        file: newFiles[0],
        clientError,
      });
    } else {
      const currentFileID = uuidv4();
      const S3UploadedList = await FilesService.uploadCVList([
        { file: currentFile, fileId: currentFileID },
      ]);

      if (S3UploadedList?.length) {
        dispatch(successUploadCVList());
        update(id, {
          ...getValues(`addingMembers[${id}]`),
          file: newFiles[0],
          fileId: currentFileID,
          cv: S3UploadedList[0]?.cv,
          error: S3UploadedList[0]?.error?.length
            ? [t("members.inviting.errors.unknownUploadCVError")]
            : "",
        });
      } else {
        dispatch(errorUploadCVList());
      }
    }
    setIsCVLoading(false);
  };

  return (
    <div
      className={classJoin(
        "flex flex-col gap-6 h-full w-full",
        isEditMode && styles.modalPartialWidth,
      )}
    >
      <UploadFileInput onFilesSelected={handleOnFileSelected} ref={inputRef} />
      <ModalMainText2
        dataCy={INVITING_MODAL}
        additionalClassName={`px-${MARGIN} pt-${MARGIN}`}
      >
        <div className="flex items-center gap-2">
          <AddMemberIcon className="fill-current h-8" />
          <span>{t(`${TRANSLATION_PATH}.addNewMembers`)}</span>
        </div>
      </ModalMainText2>
      <div
        className={classJoin(
          "flex items-center justify-between",
          `px-${MARGIN}`,
        )}
      >
        <span>{t(`${TRANSLATION_PATH}.fillForm`)}</span>
        {totalStepLength ? (
          <LinkButton
            dataCy={INVITING_MODAL}
            additionalClassName="mt-auto underline"
            padding="p-0"
            onClick={() => {
              remove(id);
              if (totalStepLength !== step) setStep(step + 1);
              else {
                reset({
                  addingMembers: [],
                  members: getValues("members"),
                });
                setActiveMemberId(null);
                setTotalStepLength(0);
                setStep(0);
              }
            }}
          >
            {t(`${TRANSLATION_PATH}.skipThis`)}
          </LinkButton>
        ) : (
          <></>
        )}
      </div>
      <div className={classJoin(`p-${MARGIN}`, "flex flex-col gap-8  h-full")}>
        <InvitingLabel
          text={t(`${TRANSLATION_PATH}.email`)}
          width="w-full"
          isRequired
          padding="px-0"
        >
          <Controller
            key={uid({ ...currentItem, id: emailFieldName })}
            name={emailFieldName}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                autoFocus
                additionalContainerClass={error?.message ? "" : "pb-5"}
                dataCy={`${INVITING_MODAL}_email`}
                defaultValue={value}
                error={!!error}
                message={error?.message || ""}
                onChange={onChange}
                onFocus={() => clearErrors(emailFieldName)}
                placeholder={t(`${TRANSLATION_PATH}.emailPlaceholder`)}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t("errors.cantBeEmpty"),
              },
              validate: (value: string) => {
                if (!value.length) return t("errors.cantBeEmpty");
                if (!EMAIL_REGEXP_PATTERN.test(value.trim()))
                  return t("errors.wrongEmailFormat");
                if (usersEmails.includes(value.trim()))
                  return t("errors.emailInvited");
                if (addedNewEmails.includes(value.trim()))
                  return t("errors.emailAdded");
                return true;
              },
            }}
          />
        </InvitingLabel>
        <div className="flex items-center gap-2">
          <InvitingLabel
            text={t(`${TRANSLATION_PATH}.firstName`)}
            width="w-1/2"
            padding="px-0"
            isRequired
          >
            <Controller
              key={uid({ ...currentItem, id: nameFieldName })}
              name={nameFieldName}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  additionalContainerClass={error?.message ? "" : "pb-3.5"}
                  dataCy={`${INVITING_MODAL}_name`}
                  defaultValue={value}
                  error={!!error}
                  message={error?.message || ""}
                  onChange={onChange}
                  onFocus={() => clearErrors(nameFieldName)}
                  placeholder={t(`${TRANSLATION_PATH}.firstNamePlaceholder`)}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t("errors.cantBeEmpty"),
                },
                maxLength: {
                  value: 40,
                  message: t("errors.cantBeLonger", { count: 40 }),
                },
              }}
            />
          </InvitingLabel>
          <InvitingLabel
            text={t(`${TRANSLATION_PATH}.surname`)}
            width="w-1/2"
            padding="px-0"
            isRequired
          >
            <Controller
              key={uid({ ...currentItem, id: surnameFieldName })}
              name={surnameFieldName}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  additionalContainerClass={error?.message ? "" : "pb-3.5"}
                  dataCy={`${INVITING_MODAL}_surname`}
                  defaultValue={value}
                  error={!!error}
                  message={error?.message || ""}
                  onChange={onChange}
                  onFocus={() => clearErrors(surnameFieldName)}
                  placeholder={t(`${TRANSLATION_PATH}.surnamePlaceholder`)}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t("errors.cantBeEmpty"),
                },
                maxLength: {
                  value: 40,
                  message: t("errors.cantBeLonger", { count: 40 }),
                },
              }}
            />
          </InvitingLabel>
        </div>
        <InvitingLabel
          text={t(`${TRANSLATION_PATH}.role`)}
          width="w-full"
          isRequired
          padding="px-0"
        >
          <Controller
            key={uid({ ...currentItem, id: profileTypeFieldName })}
            name={profileTypeFieldName}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <ToggleInline
                dataCy={`${INVITING_MODAL}`}
                onClick={(item) => {
                  clearErrors(profileTypeFieldName);
                  return onChange(item);
                }}
                options={toggleOptions}
                selected={value || undefined}
                error={!!error}
                message={error?.message || ""}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t("errors.cantBeEmpty"),
              },
              validate: (value: string) => {
                if (!value) return t("errors.cantBeEmpty");
                return true;
              },
            }}
          />
        </InvitingLabel>
        <div className="flex items-center gap-2 w-full">
          <InvitingLabel
            text={t(`${TRANSLATION_PATH}.group`)}
            width="w-full"
            padding="px-0 pb-5"
          >
            <Controller
              key={uid({ ...currentItem, id: groupFieldName })}
              name={groupFieldName}
              control={control}
              render={({ field: { onChange, value } }) => (
                <SearchSelect
                  dataCy={INVITING_MODAL}
                  onChange={(item) => onChange(item)}
                  fitMenuWidth={true}
                  isWideNameList={true}
                  options={roles}
                  width="w-full"
                  value={value?.label ? value : roles?.[0]}
                  placeholder={roles?.[0]?.label}
                />
              )}
            />
          </InvitingLabel>
          <InvitingLabel
            text={t(`${TRANSLATION_PATH}.startDate`)}
            width="w-full"
            isRequired
            padding="px-0 pb-5"
          >
            <Controller
              key={uid({ ...currentItem, id: startDateFieldName })}
              name={startDateFieldName}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Datepicker2
                  dataCy={startDateFieldName}
                  error={error?.message}
                  onChange={(newValue) => onChange(dayjs(newValue))}
                  selected={dayjs(value) || null}
                  dateFormat="DD/MM/YYYY"
                  timeView={["day"]}
                  type="day"
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t("errors.cantBeEmpty"),
                },
                validate: (value: string) => {
                  if (!dayjs(value).isValid()) return t("errors.cantBeEmpty");
                  return true;
                },
              }}
            />
          </InvitingLabel>
        </div>
        <InvitingLabel
          text={t(`${TRANSLATION_PATH}.cv`)}
          width="w-full"
          padding="px-0"
        >
          <div>
            {isCVLoading && <ProgressBar />}
            {!isCVLoading && (
              <Controller
                key={uid({ ...currentItem, id: fileFieldName })}
                name={fileFieldName}
                render={({ field: { value }, fieldState: { error } }) => {
                  return value?.name ? (
                    <div className="flex flex-col gap-2">
                      <div
                        className={classJoin(
                          "rounded-lg h-12 w-full border flex items-center pl-4 pr-2 justify-end gap-1",
                          error || fields[id]?.clientError?.length
                            ? "border-error-50 bg-error-50 bg-opacity-20"
                            : "border-neutralSecondary-60 bg-primary-100",
                        )}
                      >
                        <span
                          className={classJoin(
                            error || fields[id]?.clientError?.length
                              ? "text-error-50"
                              : "text-primary-50",
                            "mr-auto truncate block",
                          )}
                        >
                          {value?.name}
                        </span>

                        <div className="flex items-center">
                          <button
                            className="h-10 w-8"
                            onClick={(e) => {
                              e.preventDefault();
                              handleBrowseClick();
                            }}
                          >
                            <RefreshIcon className="text-neutralPrimary-40 w-full h-full py-3 fill-current cursor-pointer hover:opacity-70" />
                          </button>
                          <button
                            className="h-10 w-8"
                            onClick={(e) => {
                              e.preventDefault();
                              update(id, {
                                ...currentItem,
                                file: null,
                                error: "",
                                clientError: [],
                              });
                            }}
                          >
                            <XIcon className="text-neutralPrimary-40  w-full  h-full py-3 fill-current cursor-pointer hover:opacity-70" />
                          </button>
                        </div>
                      </div>
                      {error || fields[id]?.clientError?.length ? (
                        <span className="mt-1 text-xs text-error-50">
                          {error?.message || fields[id]?.clientError[0] || ""}
                        </span>
                      ) : null}
                    </div>
                  ) : (
                    <LinkButton
                      onClick={handleBrowseClick}
                      dataCy={`${INVITING_MODAL}_addCV`}
                      variant="secondary"
                      additionalClassName="w-max"
                    >
                      {t(`${TRANSLATION_PATH}.addCV`)}
                    </LinkButton>
                  );
                }}
              />
            )}
          </div>
        </InvitingLabel>
        {totalStepLength ? (
          <div className="flex items-center justify-between">
            <span>
              {t(`${TRANSLATION_PATH}.uploadedPDFsAmount`, {
                amount: step,
                totalAmount: totalStepLength,
              })}
            </span>
          </div>
        ) : (
          <></>
        )}
        <div className="flex items-center justify-between mt-auto">
          <LinkButton
            dataCy={`${BUTTON}-${INVITING_MODAL}_cancel`}
            additionalClassName="mt-auto underline"
            padding="p-0"
            variant="secondary"
            onClick={() => onCancel(true)}
          >
            {t("actions.cancel")}
          </LinkButton>
          <Button2
            additionalClass="gap-2 flex items-center self-center"
            dataCy={`${BUTTON}-${INVITING_MODAL}_save`}
            width="w-1/2"
            paddingX="px-0"
            type="button"
            variant="secondary"
            onClick={handleOnSaveUserData}
          >
            {t("actions.saveAndContinue")}
          </Button2>
        </div>
      </div>
    </div>
  );
};

export default MemberDataScreen;
