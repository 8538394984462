import { ReactElement } from "react";
import { ConditionalTooltipProps } from "./types";
import Tooltip from "../tooltip";

const ConditionalTooltip = ({
  enabled = false,
  content,
  children,
  additionalChildrenClassName,
}: ConditionalTooltipProps): ReactElement => {
  return enabled ? (
    <Tooltip
      content={<span>{content}</span>}
      placement="top"
      verticalOffset={-8}
      additionalChildrenClassName={additionalChildrenClassName}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
};

export default ConditionalTooltip;
