import { useHistory, useLocation } from "react-router-dom";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { dashConverter, getSlug, permissionsMappers } from "@ps/utils";
import { hasPermissions, TabsContainer } from "@ps/ui-components";
import { useMappedStateSelector } from "../../hooks";
import { NAMESPACE, TEAM_TABS } from "../../shared/constants";
import { USERS_LIST } from "../../shared/routes";
import {
  TeamContainerMapState,
  TeamContainerMapStateReturn,
  TeamContainerProps,
} from "./types";
import { fetchProfilesSummaryList } from "../../profile-domain";

const TeamContainer = ({ children }: TeamContainerProps): ReactElement => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultTab =
    getSlug(dashConverter(pathname)) === "team"
      ? USERS_LIST
      : getSlug(dashConverter(pathname));
  const [activeTab, setActiveTab] = useState<string>(defaultTab);

  useEffect(() => {
    setActiveTab(getSlug(dashConverter(pathname)));
  }, [pathname, activeTab]);

  useEffect(() => {
    fetchProfilesSummaryList(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapState = (
    state: TeamContainerMapState,
  ): TeamContainerMapStateReturn => ({
    editMode: state.editMode,
    allPermissions: state.permissions || [],
  });

  const { editMode } = useMappedStateSelector(mapState);
  const { allPermissions } = useMappedStateSelector(mapState, "");

  const handleOnTabChange = (path: string): void => {
    history.listen(() => setActiveTab(path));
    history.push(`/team/${path}`);
    if (!editMode) setActiveTab(path);
  };

  return (
    <TabsContainer
      activeTab={activeTab}
      handleOnTabChange={handleOnTabChange}
      prefix={`${NAMESPACE}_team`}
      tabs={permissionsMappers.filterTabsWithPermissions(
        TEAM_TABS,
        hasPermissions,
        allPermissions,
      )}
      translationID={NAMESPACE}
      translationPath="team.tabs"
    >
      <section className="h-full">{children}</section>
    </TabsContainer>
  );
};

export default TeamContainer;
