import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { Scrollbars } from "react-custom-scrollbars-2";
import { divideArrayByField, sortArray } from "@ps/utils";
import {
  EmptyList,
  LoadingMainPage,
  ScrollableWrapper,
} from "@ps/ui-components";
import SortBar from "./clients-list/sortBar";
import { fetchClientsSummary } from "../store";
import {
  useMappedStateSelector,
  useSessionStorage,
  useTranslationWithNamespace,
} from "../../hooks";
import {
  ClientsSummaryMapState,
  IMappedClientsListElement,
  ClientsSummaryMapStateReturn,
  ClientsCheckers,
} from "./types";
import {
  ACTIVE,
  ARCHIVED,
  ASC,
  CLIENTS,
  CLIENT_NAME,
  initialCheckersState,
} from "../constants";
import { filterClientsList } from "../utils/filterClientsList";
import SearchBar from "./clients-list/searchBar";
import ClientRow from "./clients-list/clientsRow";
import {
  SESSION_SORTERS,
  initialSessionSortersState,
} from "../../shared/constants";
import { ReactComponent as Guys } from "../images/empty-clients.svg";
import { SessionStorage } from "../../shared";

const Clients = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [clientsCheckers, setClientsCheckers] =
    useState<ClientsCheckers>(initialCheckersState);

  const mapState = (
    state: ClientsSummaryMapState,
  ): ClientsSummaryMapStateReturn => ({
    clients: state?.clients.clientsSummary || [],
    isLoading: state?.requestStatus?.isFetchingClientsSummaryList,
  });

  const { clients, isLoading } = useMappedStateSelector(mapState);

  const [storageSorter, setStorageSorter] = useSessionStorage<SessionStorage>(
    SESSION_SORTERS,
    initialSessionSortersState,
  );

  const [sortedClientsList, setSortedClientsList] =
    useState<IMappedClientsListElement[]>(clients);

  const [sorterParameters, setSorterParameters] = useState({
    order: ASC,
    name: CLIENT_NAME,
  });

  const statusFilters = {
    [ACTIVE]: (client: IMappedClientsListElement) => !client.archived,
    [ARCHIVED]: (client: IMappedClientsListElement) => client.archived,
  };

  const filterFoldersByStatus = (
    foldersArr: IMappedClientsListElement[],
    checkers: ClientsCheckers,
  ): IMappedClientsListElement[] =>
    foldersArr.filter((client: IMappedClientsListElement) =>
      Object.entries(checkers)
        .filter(([, value]) => value)
        .map(([key]) => statusFilters[key])
        .some((f: (prospect: IMappedClientsListElement) => void) => f(client)),
    );

  const prepareClientsList = (search: string): void => {
    const { clients: clientsSorter } = storageSorter.sessionSorters;

    const properSorter: {
      name: string;
      order: string;
    } = clientsSorter?.name?.length ? clientsSorter : sorterParameters;

    const resultByStatus = filterFoldersByStatus(clients, clientsCheckers);

    const resultBySearch = filterClientsList(resultByStatus, search);

    const resultbySorter = sortArray(
      resultBySearch,
      properSorter.name as keyof IMappedClientsListElement,
      properSorter.order,
    );

    setSortedClientsList(resultbySorter);
  };

  useEffect(() => {
    fetchClientsSummary(dispatch);
  }, []);

  useEffect(() => {
    prepareClientsList(searchValue);
  }, [
    clients,
    searchValue,
    clientsCheckers,
    sorterParameters.name,
    sorterParameters.order,
  ]);

  const { negatives: onlyActiveClients, positives: onlyArchivedClients } =
    divideArrayByField(clients, "archived");

  const handleOnClickSorter = (fieldName: string, order: string): void => {
    setStorageSorter((prevData) => ({
      ...prevData,
      sessionSorters: {
        ...prevData.sessionSorters,
        [CLIENTS]: {
          name: fieldName,
          order,
        },
      },
    }));

    setSorterParameters({ name: fieldName, order });
  };

  if (isLoading)
    return <LoadingMainPage additionalClassName="bg-neutralPrimary-85" />;

  return (
    <ScrollableWrapper>
      <SearchBar
        activeAmount={onlyActiveClients.length}
        archivedAmount={onlyArchivedClients.length}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        sorterParameters={sorterParameters}
        clientsCheckers={clientsCheckers}
        setClientsCheckers={setClientsCheckers}
        onSorterClick={handleOnClickSorter}
      />
      {sortedClientsList?.length ? (
        <div className="w-full h-full">
          <SortBar />
          <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
            <div className="flex flex-col w-full">
              {sortedClientsList.map((client: IMappedClientsListElement) => (
                <ClientRow key={client.id} client={client} />
              ))}
            </div>
          </Scrollbars>
        </div>
      ) : (
        <div className="w-full flex items-center h-full justify-center">
          <EmptyList
            searchValue={searchValue}
            text={t(`${CLIENTS}.noClients`)}
            Image={<Guys />}
          />
        </div>
      )}
    </ScrollableWrapper>
  );
};

export default Clients;
