import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ReactElement } from "react";
import { Button2, Modal2, ModalFooter, ModalHeader } from "@ps/ui-components";
import { ADD_NEW_CLIENT, CLIENTS } from "../constants";
import { useTranslationWithNamespace } from "../../hooks";
import { NewClientModel } from "../models";
import { ClientService } from "../service";
import { AddNewClientModalProps } from "./types";
import BaseClientsInfo from "./baseClientsInfo";
import { ReactComponent as SuitcaseIcon } from "../../images/suitcase.svg";
import styles from "./styles.module.css";

const AddNewClientModal = ({
  isOpen,
  onClose,
  fetchClients,

}: AddNewClientModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const methods = useForm();
  const history = useHistory();

  const onSubmit = async (data: NewClientModel) => {
    const newClient = await ClientService.createClient(data);
    if (newClient?.data?.id) {
      onClose(newClient?.data?.id);
      history.push(`${CLIENTS}/${newClient?.data?.id}`);
      fetchClients();
    }

    methods.reset();
  };

  const handleOnClose = (): void => {
    onClose();
    methods.reset();
  };

  return (
    <Modal2
      isOpen={isOpen}
      dataCy={ADD_NEW_CLIENT}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onClose={handleOnClose}
      height="h-auto"
      withYScroll={false}
      withCloseIcon
      additionalOuterClassName={styles.modal}
    >
      <ModalHeader
        title={t("projects.client.addNewModalTitle")}
        Icon={<SuitcaseIcon />}
        fontSize="text-xl"
      />
      <FormProvider {...methods}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <BaseClientsInfo />
        </form>
      </FormProvider>
      <ModalFooter additionalClassName="flex gap-x-4">
        <Button2
          dataCy="cancel"
          variant="secondary"
          width="w-full"
          onClick={handleOnClose}
        >
          {t("projects.prospect.actions.cancel")}
        </Button2>
        <Button2
          dataCy="save"
          type="submit"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={methods.handleSubmit(onSubmit)}
          width="w-full"
        >
          {t("projects.prospect.actions.create")}
        </Button2>
      </ModalFooter>
    </Modal2>
  );
};

export default AddNewClientModal;
