import React, { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { pickersLayoutClasses } from "@mui/x-date-pickers/PickersLayout";
import { ReactComponent as ArrowLeft } from "../../images/arrowLeft.svg";
import { useThemeMode } from "../../components/theme-mode-provider";
import { ReactComponent as ArrowRight } from "../../images/arrowRight.svg";
import { ReactComponent as CalendarIcon } from "../../images/calendar-icon.svg";
import Button from "../button";
import { useTranslationWithNamespace } from "../../hooks";
import { DatepickerProps } from "./types";
import { DATEPICKER_2 } from "../../shared/data-cy-2";
import { Keys } from "../../shared";

const Datepicker = ({
  autoFocus = false,
  dataCy,
  dateFormat = "DD/MM/YYYY",
  disabled = false,
  disableFuture = false,
  error,
  isInputClearable = false,
  onChange,
  openTo = "month",
  selected = null,
  timeView = ["day"],
  type = "day",
  width = "w-full",
}: DatepickerProps): ReactElement => {
  const { isLightTheme, isDarkTheme, isHighContrast } = useThemeMode();
  const { t } = useTranslationWithNamespace();

  const getThemeMode = () => {
    if (isHighContrast) return "highContrast";
    if (isDarkTheme) return "dark";
    return "light";
  };

  const setCalendarColourMode = () => {
    setTimeout(() => {
      const elements = document.getElementsByClassName("MuiPickersPopper-root");

      [...elements]?.map((singleElement) =>
        singleElement?.setAttribute("data-color-theme", getThemeMode()),
      );
    }, 10);
  };

  const BASE_DATA_CY = `${DATEPICKER_2}_${dataCy}`;

  const arrowButtonCommon = classJoin(
    classJoin.template`
w-6 h-6 rounded-md text-xs flex items-center justify-center
bg-primary-50 text-primary-100 hover:opacity-60 active:opacity-100
`,
    isLightTheme && "opacity-30",
  );
  return (
    <div className={classJoin("relative", width)} data-cy={BASE_DATA_CY}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disableFuture={disableFuture}
          autoFocus={autoFocus}
          disabled={disabled}
          openTo={openTo}
          views={timeView}
          format={dateFormat}
          onChange={onChange}
          defaultValue={selected}
          slotProps={{
            textField: {
              clearable: isInputClearable,
              helperText: error,
              error: !!error,
              onClick: () => {
                setCalendarColourMode();
              },
              onKeyDown: (event) => {
                if (event.key === Keys.ENTER || event.key === Keys.ESC) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              },
            },
            actionBar: { actions: ["clear", "today"], disableSpacing: false },
            layout: {
              sx: {
                [`.${pickersLayoutClasses.contentWrapper}`]: {
                  backgroundColor: "rgba(var(--color-neutral-primary-100))",
                },
              },
            },
          }}
          slots={{
            actionBar: (data) => (
              <div className={classJoin("flex gap-4 p-4", data.className)}>
                <Button
                  dataCy={`${BASE_DATA_CY}_clear`}
                  variant="secondary"
                  onClick={data.onClear}
                >
                  {t("actions.clear")}
                </Button>
                <Button
                  dataCy={`${BASE_DATA_CY}_today`}
                  variant="secondary"
                  onClick={data.onSetToday}
                >
                  {t(`datepicker2.${type === "month" ? "thisMonth" : "today"}`)}
                </Button>
              </div>
            ),
            openPickerIcon: () => (
              <CalendarIcon className="fill-current text-neutralPrimary-30" />
            ),
            leftArrowIcon: () => (
              <span className={arrowButtonCommon}>
                <ArrowLeft />
              </span>
            ),
            rightArrowIcon: () => (
              <span className={arrowButtonCommon}>
                <ArrowRight />
              </span>
            ),
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default Datepicker;
