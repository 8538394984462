import { ReactElement, useState } from "react";
import {
  Button,
  Input,
  RestrictedWrapper,
  Badge,
  Toggle,
  TableSearchBar,
} from "@ps/ui-components";
import { hhPermissions } from "@ps/hh";
import { useTranslationWithNamespace } from "../../hooks";
import { ReactComponent as AddMemberIcon } from "../../images/add-member.svg";
import { ReactComponent as ActiveMembersIcon } from "../../images/activeMembers.svg";
import { ReactComponent as ArchivedMembersIcon } from "../../images/archivedMembers.svg";
import { ADD_MEMBER_BUTTON, PERMISSIONS } from "../../shared/data-cy";
import { SearchRowProps } from "./types";
import InvitingModal from "./inviting-modal";

const TRANSLATE_PATH = "members";

const SearchRow = ({
  value,
  searchValue,
  setIsCheckedArchive,
  isCheckedArchive,
  usersListLength,
}: SearchRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [isAddNewMemberVisible, setIsAddNewMemberVisible] =
    useState<boolean>(false);

  return (
    <>
      <TableSearchBar
        dataCy={`${PERMISSIONS}_searchRow`}
        additionalClassName="flex justify-between items-center mb-8"
      >
        <div className="flex items-center gap-4">
          <Input
            autoFocus
            dataCy={`${PERMISSIONS}_searchRow`}
            defaultValue={value}
            onChange={(e) => searchValue(e.target.value)}
            isSearch
            placeholder={t(`${TRANSLATE_PATH}.search`)}
            width="w-80"
          />
          <RestrictedWrapper
            permissions={hhPermissions.USERS_ASSIGN_ROLES}
            noPermissionsComponent={<div />}
          >
            <Button
              dataCy={ADD_MEMBER_BUTTON}
              additionalClass="flex items-center gap-2"
              onClick={() => setIsAddNewMemberVisible(true)}
            >
              <AddMemberIcon />
              {t(`${TRANSLATE_PATH}.addNewMember`)}
            </Button>
          </RestrictedWrapper>
        </div>
        <div className="flex items-center gap-3">
          <Badge
            dataCy={`${PERMISSIONS}_active_users`}
            number={usersListLength?.active}
            Icon={ActiveMembersIcon}
            title={t(`${TRANSLATE_PATH}.activeMembers`)}
            isActive={!isCheckedArchive}
            onClick={() => setIsCheckedArchive(false)}
          />
          <Toggle
            dataCy={PERMISSIONS}
            checked={isCheckedArchive}
            onChange={() =>
              setIsCheckedArchive((wasChecked: boolean): boolean => !wasChecked)
            }
          />
          <Badge
            dataCy={`${PERMISSIONS}_archived_users`}
            number={usersListLength?.archived}
            Icon={ArchivedMembersIcon}
            title={t(`${TRANSLATE_PATH}.archivedMembers`)}
            isActive={isCheckedArchive}
            onClick={() => setIsCheckedArchive(true)}
          />
        </div>
      </TableSearchBar>
      <RestrictedWrapper
        permissions={hhPermissions.USERS_ASSIGN_ROLES}
        noPermissionsComponent={<div />}
      >
        {isAddNewMemberVisible ? (
          <InvitingModal
            isOpen={!!isAddNewMemberVisible}
            onClose={() => setIsAddNewMemberVisible(false)}
          />
        ) : (
          <></>
        )}
      </RestrictedWrapper>
    </>
  );
};

export default SearchRow;
