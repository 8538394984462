import { NavbarOptionsItem } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const prepareAvailableNavbarMenuItems = (
  items: NavbarOptionsItem[],
  conditionalList: string[],
  isVisibleForTenant: boolean,
): NavbarOptionsItem[] => {
  return items?.filter(
    (item: NavbarOptionsItem) =>
      !conditionalList.includes(item?.item) ||
      (conditionalList.includes(item?.item) && isVisibleForTenant),
  );
};
