// eslint-disable-next-line import/prefer-default-export
export const FIELD_CASES = {
  PROJECT: "project",
  BENCH: "bench",
  B2B: "b2b",
  UOP: "uop",
  SENIOR: "senior",
  REGULAR_PLUS: "regular_",
  REGULAR: "regular",
  JUNIOR_PLUS: "junior_",
  JUNIOR: "junior",
  INTERNSHIP: "internship",
  ROLE: "role",
  SPECIALIST: "specialist",
  OPERATIVE: "operative",
  DOMAIN: "domain",
};
