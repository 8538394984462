import { ReactElement } from "react";
import { Label, Tag } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { DATE_FORMAT } from "../../../common/constants";
import { AwardsCardProps } from "../types";
import { EXPERIENCE_ACCOMPLISHMENT_VIEW } from "../../../../../../shared/data-cy";
import {
  AWARD_NAME,
  CONTRIBUTIONS,
  DATE,
  REWARDER,
  DESCRIPTION,
} from "../constants";
import { DictionaryEntryModel } from "../../../../../../dictionaries";
import accomplishmentsGrid from "../../../common/styles";

const AwardsCard = ({
  awardName,
  rewarder,
  date,
  techStack,
  description,
}: AwardsCardProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const BASE_PATH = `${EXPERIENCE_ACCOMPLISHMENT_VIEW}_${CONTRIBUTIONS}`;

  const translate = (field: string): string =>
    t(`profile.experience.cards.awards.${field}`);

  return (
    <div className="relative w-full flex flex-col p-6">
      <div className={accomplishmentsGrid}>
        <Label
          text={translate(AWARD_NAME)}
          dataCy={`${BASE_PATH}_${AWARD_NAME}`}
        >
          {awardName}
        </Label>
        <Label text={translate(REWARDER)} dataCy={`${BASE_PATH}_${REWARDER}`}>
          {rewarder}
        </Label>
        <Label
          text={translate(DATE)}
          dataCy={`${BASE_PATH}_${DATE}`}
          additionalClassName="col-span-2"
        >
          {date?.format(DATE_FORMAT)}
        </Label>
      </div>
      {description && (
        <div className="w-full mb-5 whitespace-pre-wrap">
          <Label
            text={translate("description")}
            dataCy={`${BASE_PATH}_${DESCRIPTION}`}
          >
            {description}
          </Label>
        </div>
      )}
      <div className="w-full">
        <Label dataCy="techStack" text={translate("techStack")}>
          <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
            {techStack?.map((tech: DictionaryEntryModel) => (
              <Tag name={tech?.name} dataCy={tech?.id} key={tech?.id} />
            ))}
          </div>
        </Label>
      </div>
    </div>
  );
};

export default AwardsCard;
