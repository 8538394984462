import { ReactElement } from "react";
import { SearchInput, Sorter } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../hooks";
import {
  SEARCH,
  SENIORITY,
  PROFESSION,
  ASC,
  DESC,
  LOCATION,
  VALUE,
  PROFESSIONS,
} from "../../../../constants";
import { SearchBarProps } from "./types";

const TRANSLATE_PATH = "projects.prospect.proposalFolder";

const SearchBar = ({
  dataCy,
  searchValue,
  setSearchValue,
  sorterParameters,
  onSorterClick,
}: SearchBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const names = [
    {
      name: PROFESSIONS,
      label: t(`${TRANSLATE_PATH}.${PROFESSION}`),
    },
    { name: SENIORITY, label: t(`${TRANSLATE_PATH}.${SENIORITY}`) },
    {
      name: VALUE,
      label: t(`${TRANSLATE_PATH}.${LOCATION}`),
      isNestedField: true,
    },
  ];

  const orders = [
    { order: ASC, label: t(`${TRANSLATE_PATH}.${ASC}`) },
    { order: DESC, label: t(`${TRANSLATE_PATH}.${DESC}`) },
  ];

  return (
    <div className="flex w-full justify-between gap-6 items-center">
      <SearchInput
        dataCy={`${dataCy}_${SEARCH}`}
        value={searchValue}
        placeholder={t(`${TRANSLATE_PATH}.${SEARCH}`)}
        onChange={setSearchValue}
      />
      <Sorter
        names={names}
        orders={orders}
        activeSorter={sorterParameters}
        handleSorterClick={onSorterClick}
      />
    </div>
  );
};

export default SearchBar;
