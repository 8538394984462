import { ReactElement } from "react";
import { Label, Chip, Empty } from "@ps/ui-components";
import { classJoin, setChipColors, getNameFromDictionary } from "@ps/utils";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../../hooks";
import { BASE_TRANSLATION_PATH, HOLIDAYS_USER } from "../constants";
import styles from "./styles.module.css";
import { UserRowProps, UserRowMapState, UserRowMapStateReturn } from "./types";

const UserRow = ({ user }: UserRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const mapState = (state: UserRowMapState): UserRowMapStateReturn => ({
    professionDict: state?.dictionaries?.profession || [],
    seniorityDict: state?.dictionaries?.seniority || [],
  });

  const { professionDict, seniorityDict } = useMappedStateSelector(mapState);

  const seniority = getNameFromDictionary(seniorityDict, user?.seniority);
  const profession = getNameFromDictionary(professionDict, user?.profession);

  return (
    <div className={classJoin("grid", styles.tableColumns)}>
      <Label text={t(`${BASE_TRANSLATION_PATH}.name`)} dataCy={HOLIDAYS_USER}>
        {user.name}
      </Label>
      <div>
        <div className="flex items-center gap-1 w-36">
          <Label
            text={t(`${BASE_TRANSLATION_PATH}.seniority`)}
            dataCy={HOLIDAYS_USER}
            additionalClassName="w-auto"
          >
            {seniority ? (
              <Chip
                dataCy={HOLIDAYS_USER}
                label={seniority}
                tailwindColors={setChipColors(user.seniority)}
                additionalClassName="text-base capitalize"
              />
            ) : (
              <span />
            )}
          </Label>
          <Label
            text={t(`${BASE_TRANSLATION_PATH}.profession`)}
            dataCy={HOLIDAYS_USER}
            additionalClassName="w-auto"
          >
            {profession ? (
              <Chip
                dataCy={HOLIDAYS_USER}
                label={profession}
                tailwindColors={setChipColors(profession)}
                additionalClassName="text-base capitalize"
              />
            ) : (
              <span />
            )}
          </Label>
        </div>
        {!seniority && !profession && <Empty />}
      </div>
      <Label
        dataCy={HOLIDAYS_USER}
        text={t(`${BASE_TRANSLATION_PATH}.template`)}
      >
        {user.holidaysTemplate?.templateName || "-"}
      </Label>
      <Label dataCy={HOLIDAYS_USER} text={t(`${BASE_TRANSLATION_PATH}.status`)}>
        {user.enabled ? (
          <span className="text-success-60">
            {t(`${BASE_TRANSLATION_PATH}.active`)}
          </span>
        ) : (
          <span className="text-neutralPrimary-50">
            {t(`${BASE_TRANSLATION_PATH}.archived`)}
          </span>
        )}
      </Label>
    </div>
  );
};

export default UserRow;
