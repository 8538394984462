import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import { AnyAction, Dispatch } from "redux";
import ActionTypes from "./actionTypes";
import { ClientModel } from "../models";
import { ClientService } from "../service";
import { IAction } from "./types";

export const fetchClientsStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_CLIENTS_START);
export const fetchClientsSuccess = (payload: ClientModel[]): AnyAction =>
  actionCreator(ActionTypes.FETCH_CLIENTS_SUCCESS, payload);
export const fetchClientsError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_CLIENTS_ERROR, payload);

export const fetchClients = async (
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchClientsStart());
  const response = await ClientService.fetchClients();
  if (isAPIError(response)) {
    dispatch(fetchClientsError(response));
  } else {
    dispatch(fetchClientsSuccess(response));
  }
};

export const fetchClientsSummaryStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_CLIENTS_SUMMARY_START);
export const fetchClientsSummarySuccess = <T>(payload: T[]): AnyAction =>
  actionCreator(ActionTypes.FETCH_CLIENTS_SUMMARY_SUCCESS, payload);
export const fetchClientsSummaryError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_CLIENTS_SUMMARY_ERROR, payload);

export const fetchClientsSummary = async (
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchClientsSummaryStart());
  const response = await ClientService.fetchClientsSummary();
  if (isAPIError(response)) {
    dispatch(fetchClientsSummaryError(response));
  } else {
    dispatch(fetchClientsSummarySuccess(response));
  }
};

export const fetchClientSummaryStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_CLIENT_START);
export const fetchClientSummarySuccess = (
  payload: IAction<string>,
): AnyAction => actionCreator(ActionTypes.FETCH_CLIENT_SUCCESS, payload);
export const fetchClientSummaryError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_CLIENT_ERROR, payload);

export const fetchClientSummary = async (
  clientId: string,
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchClientSummaryStart());
  const response = await ClientService.fetchClientById(clientId);
  if (isAPIError(response)) {
    dispatch(fetchClientSummaryError(response));
  } else {
    dispatch(fetchClientSummarySuccess(response));
  }
};

export const setDefaultTab = (payload: string): AnyAction =>
  actionCreator(ActionTypes.SET_DEFAULT_TAB, payload);
