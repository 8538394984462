import { ReactElement } from "react";

const ScrollableWrapper = ({
  value = 90,
  children,
}: {
  value?: number;
  children?: JSX.Element | JSX.Element[];
}): ReactElement => (
  <div style={{ height: `calc(100% - ${value}px)` }} className="w-full">
    {children}
  </div>
);

export default ScrollableWrapper;
