import { ReactElement } from "react";
import { isDayjs } from "dayjs";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  Empty,
  Label,
  useThemeMode,
  MoreLinkButton,
} from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import { useTranslationWithNamespace } from "../../../hooks";
import { ProspectRowProps } from "./types";
import {
  ACTIVE,
  ARCHIVED,
  BD_NAME,
  CLIENT_NAME,
  CREATION_DATE,
  DAYS_PASSED,
  MORE,
  PM_NAME,
  PROSPECT_NAME,
  STATUS,
} from "./constants";
import { PROSPECTS_LIST } from "../../../shared/data-cy";
import styles from "./styles.module.css";
import { prepareUserFullName } from "../../utils/findUserById";

const ProspectRow = ({
  prospect,
  clientIsArchived,
}: ProspectRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();
  const history = useHistory();
  const { pathname } = useLocation();
  const currentClientPath = `/clients/${prospect?.details.clientId}`;

  const translate = (field: string) => t(`projects.prospect.list.${field}`);

  const creationDate = isDayjs(prospect.creationDate)
    ? prospect.creationDate.format("DD.MM.YYYY")
    : "";

  const stylesWhenArchived =
    (prospect.archived || clientIsArchived) && "opacity-40";

  const statusStyle = prospect.archived ? "bg-gray-200" : "bg-other-toxicGreen";

  const showStatus = (archived: boolean): ReactElement => {
    return (
      <div className="grid grid-flow-col gap-x-3 auto-cols-min items-center">
        <div className={classJoin(statusStyle, "h-4 w-4 rounded-full")} />
        <span className="uppercase font-semibold">
          {archived ? translate(ARCHIVED) : translate(ACTIVE)}
        </span>
      </div>
    );
  };

  const rowContent = (
    <>
      <Label
        dataCy={`${PROSPECTS_LIST}_${PROSPECT_NAME}`}
        additionalClassName={classJoin(stylesWhenArchived, "pr-5")}
        verticalAlignment
      >
        {prospect.details.prospectName ? (
          <Link to={`/prospect/${prospect.id}`} className="text-primary-50">
            {prospect.details.prospectName}
          </Link>
        ) : (
          <Empty />
        )}
      </Label>
      <Label
        dataCy={`${PROSPECTS_LIST}_${CLIENT_NAME}`}
        additionalClassName={stylesWhenArchived}
        fontWeight="font-normal"
        verticalAlignment
      >
        {prospect.details.clientName && pathname !== currentClientPath ? (
          <Link to={currentClientPath} className="text-primary-50">
            {prospect?.details?.clientName}
          </Link>
        ) : (
          <></>
        )}
        {prospect.details.clientName && pathname === currentClientPath ? (
          <span>{prospect.details.clientName}</span>
        ) : (
          <></>
        )}

        {!prospect.details.clientName ? <Empty /> : <></>}
      </Label>
      <Label
        dataCy={`${PROSPECTS_LIST}_${PM_NAME}`}
        additionalClassName={stylesWhenArchived}
        fontWeight="font-normal"
        verticalAlignment
      >
        {prospect.details?.pm ? (
          `${prospect.details?.projectManager}`
        ) : (
          <Empty />
        )}
      </Label>
      <Label
        dataCy={`${PROSPECTS_LIST}_${BD_NAME}`}
        additionalClassName={stylesWhenArchived}
        fontWeight="font-normal"
        verticalAlignment
      >
        {prospect.details?.bd ? (
          prepareUserFullName(prospect.details?.bd) || ""
        ) : (
          <Empty />
        )}
      </Label>
      <Label
        dataCy={`${PROSPECTS_LIST}_${CREATION_DATE}`}
        additionalClassName={stylesWhenArchived}
        fontWeight="font-normal"
        verticalAlignment
      >
        {creationDate || <Empty />}
      </Label>

      <Label
        dataCy={`${PROSPECTS_LIST}_${DAYS_PASSED}`}
        additionalClassName={stylesWhenArchived}
        fontWeight="font-normal"
        verticalAlignment
      >
        {prospect.daysPassed === null ||
        prospect.details.daysPassed === null ? (
          <Empty />
        ) : (
          <span>{prospect.daysPassed}</span>
        )}
      </Label>
      <Label
        dataCy={`${PROSPECTS_LIST}_${STATUS}`}
        additionalClassName={stylesWhenArchived}
        verticalAlignment
      >
        {showStatus(prospect?.archived)}
      </Label>
      <>
        <div
          className={classJoin(
            "flex items-center text-primary-50 self-center place-self-end",
            prospect.archived
              ? "text-neutralPrimary-30"
              : "hover:text-primary-60",
          )}
        >
          <MoreLinkButton
            dataCy={`${PROSPECTS_LIST}_${MORE}`}
            onClick={() => history.push(`/prospect/${prospect.id}`)}
          />
        </div>
      </>
    </>
  );

  return (
    <Card
      dataCy={`${PROSPECTS_LIST}_prospect`}
      additionalCardClassName={classJoin(
        styles.gridContainer,
        "rounded-lg mb-1 py-10 2xl:gap-x-4 gap-x-2",
        prospect?.archived ? "bg-opacity-40" : "",
        isHighContrast ? "border border-neutralSecondary-40" : "",
      )}
    >
      {rowContent}
    </Card>
  );
};

export default ProspectRow;
