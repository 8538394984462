import { ReactElement, useEffect } from "react";
import {
  classJoin,
  mapTechnologyWithOnlyAlphanumeric,
  useFullTextSearch,
} from "@ps/utils";
import { useDispatch } from "redux-react-hook";
import { uid } from "react-uid";
import { InputBase } from "@ps/ui-components";
import Scrollbars from "react-custom-scrollbars-2";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import {
  ProfessionMatrixCardMapState,
  ProfessionMatrixCardMapStateReturn,
  ProfessionMatrixCardProps,
} from "../types";
import { useMatrixContext } from "./context";
import InfoPanel from "./infoPanel";
import MatrixSkillsTable from "./matrixSkillsTable";
import LoadingProfessionMatrixCard from "./loaders/loadingProfessionMatrixCards";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { SKILLS_MATRIX } from "../../../shared/data-cy";
import styles from "./skeletonStyle.module.css";
import {
  debounce,
  prepareOtherTechnologiesDict,
  sortSkillsByName,
} from "../helpers/utils";
import {
  DOMAINS_PROFESSION_ID,
  SKILLS_MATRIX_MODES,
} from "../../shared/consts";
import ProfessionMatrixSearch from "./professionMatrixSearch";
import { ReactComponent as Magnifier } from "../../../images/skills-matrix/magnifier.svg";
import { ReactComponent as ClearIcon } from "../../../images/skills-matrix/x-icon.svg";
import { fetchFulfillments, fetchTemplates } from "../../store";
import { FulfillmentModel, ProfessionTemplateModel } from "../../models";
import { SEARCH_INPUT_ID } from "../common/constants";

const inputWrapperStyle = classJoin.template`
focus-within:text-neutralPrimary-20
flex flex-col
relative text-neutralPrimary-20
`;

const BASE_TRANSLATION = "profile.skills.legend";

const ProfessionMatrixCard = ({
  professionId,
  searchingValue,
  setSearchingValue,
  onClearIconClick,
}: ProfessionMatrixCardProps): ReactElement => {
  const dispatch = useDispatch();
  const { mode } = useMatrixContext();
  const { t } = useTranslationWithNamespace();
  const { isShowingInfoCard, setIsShowingInfoCard } = useMatrixContext();

  const prepareUsersTemplate = (
    data: ProfessionTemplateModel,
    skillsFulfillment: FulfillmentModel,
  ) => {
    if (data && skillsFulfillment) {
      return {
        ...data,
        groups: [
          ...data.groups,
          {
            desc: null,
            name: t("profile.skills.yourTechnologies"),
            skills: prepareOtherTechnologiesDict(
              skillsFulfillment,
              data.groups,
            ).sort(sortSkillsByName),
          },
        ],
      };
    }
    return data;
  };

  const mapState = (
    state: ProfessionMatrixCardMapState,
  ): ProfessionMatrixCardMapStateReturn => ({
    profession: state?.skillsMatrix?.templates?.[professionId],
    fulfillment: state?.skillsMatrix?.fulfillments?.[professionId],
    isLoadingTemplate: state.requestStatus.isFetchingDictionary,
    techDict: state?.dictionaries?.technology || [],
    userProfessionTemplate: prepareUsersTemplate(
      state?.skillsMatrix?.templates?.[professionId],
      state?.skillsMatrix?.fulfillments?.[professionId],
    ) || { groups: [], professionId, knowledgeScale: [], enjoymentScale: [] },
  });

  const {
    profession,
    fulfillment,
    isLoadingTemplate,
    techDict,
    userProfessionTemplate,
  } = useMappedStateSelector(mapState) as {
    profession: ProfessionTemplateModel;
    fulfillment: FulfillmentModel;
    isLoadingTemplate: boolean;
    techDict: {
      deleted: boolean;
      desc: null;
      iconUrl: null;
      id: string;
      name: string;
    }[];
    userProfessionTemplate;
  };

  const { search } = useFullTextSearch(
    techDict,
    mapTechnologyWithOnlyAlphanumeric,
  );

  const methods = useForm({
    defaultValues: { userProfessionTemplate, filteredData: [] },
  });

  const [groupsTechnologies, filteredData] = [
    useFieldArray({
      control: methods.control,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      name: "groups",
    }),
    useFieldArray({
      control: methods.control,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      name: "filteredData",
    }),
  ];

  const handleOnSetSearchingValue = (value: string): void => {
    const newValue = value.trim();
    if (newValue) {
      const searchResult = search(newValue);
      methods.reset({
        ...userProfessionTemplate,
        filteredData: searchResult,
      });
      setSearchingValue(newValue);
    } else {
      methods.reset(userProfessionTemplate);
      setSearchingValue("");
    }
  };

  useEffect(() => {
    if (mode === SKILLS_MATRIX_MODES.USER_EDIT)
      fetchFulfillments(professionId, dispatch);
    fetchTemplates(professionId, dispatch, t);
    handleOnSetSearchingValue("");
  }, [professionId]);

  useEffect(() => {
    methods.reset(userProfessionTemplate);
    handleOnSetSearchingValue(searchingValue);
  }, [profession, fulfillment]);

  const isDomainsSkillsTable = professionId === DOMAINS_PROFESSION_ID;

  useEffect(() => {
    if (isDomainsSkillsTable) return handleOnSetSearchingValue("");
    if (searchingValue) return handleOnSetSearchingValue(searchingValue);
    return methods.reset(userProfessionTemplate);
  }, [techDict, profession?.groups, fulfillment, searchingValue, professionId]);

  const toggleInfoCard = () => setIsShowingInfoCard((oldState) => !oldState);

  if (!professionId) return <h1>{t("profile.skills.error")}</h1>;

  if (isLoadingTemplate) return <LoadingProfessionMatrixCard />;

  const showSkillsMatrixContent = (): ReactElement[] | ReactElement => {
    if (searchingValue.length)
      return (
        <ProfessionMatrixSearch
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          profession={profession}
          professionId={professionId}
          searchingValue={searchingValue}
          filteredData={filteredData}
        />
      );
    return groupsTechnologies?.fields?.length ? (
      groupsTechnologies?.fields
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((item: any) => item.skills?.length)
        .map((group, index) => (
          <MatrixSkillsTable
            key={`base_${professionId}_${uid(index)}`}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            professionSkills={group.skills}
            knowledgeScale={profession?.knowledgeScale}
            enjoymentScale={profession?.enjoymentScale}
            professionId={professionId}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            groupName={group.name}
            isLast={index === filteredData?.fields?.length - 1}
          />
        ))
    ) : (
      <></>
    );
  };

  return (
    <div className="flex flex-row w-full h-full">
      <div className="w-full h-full">
        {!isDomainsSkillsTable && (
          <form
            className={classJoin(
              "bg-neutralPrimary-100 p-4 rounded-t-md border-t-4 border-neutralSecondary-90",
              inputWrapperStyle,
            )}
            id={SEARCH_INPUT_ID}
          >
            <Magnifier className="text-neutralSecondary-41 fill-current absolute left-6 top-4 transform translate-y-2.5" />
            <InputBase
              dataCy={`${SKILLS_MATRIX}_user_search_tech`}
              isSearch
              onChange={debounce((event) =>
                handleOnSetSearchingValue(event?.target?.value),
              )}
              defaultValue={searchingValue}
              type="text"
              placeholder={t("profile.skills.searchPlaceholder")}
              additionalContainerClass="w-full grow"
              autoFocus
              key={searchingValue}
            />
            {!!searchingValue?.length && (
              <ClearIcon
                className={`text-primary-70 hover:text-primary-60 fill-current w-4 h-4
                  absolute right-6 top-4 transform translate-y-2.5 cursor-pointer`}
                onClick={onClearIconClick}
              />
            )}
          </form>
        )}
        <div
          className={classJoin(
            styles.matrixUserRow,
            "bg-neutralSecondary-90 border border-primary-71 text-neutralSecondary-41 h-10",
          )}
        >
          <span className="pl-4">
            {t(
              `${BASE_TRANSLATION}.${
                isDomainsSkillsTable ? "domains" : "technology"
              }`,
            )}
          </span>
          <span className="border-r border-l border-primary-71 h-full flex items-center justify-center">
            {t(`${BASE_TRANSLATION}.scale`)}
          </span>
          <span className="h-full flex items-center justify-center">
            {t(`${BASE_TRANSLATION}.enjoymentLevel`)}
          </span>
        </div>
        <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
          <FormProvider {...methods}>
            <div className="flex flex-col w-full h-auto pb-32">
              {showSkillsMatrixContent()}
            </div>
          </FormProvider>
        </Scrollbars>
      </div>
      <InfoPanel
        show={isShowingInfoCard}
        onInfoButtonClick={toggleInfoCard}
        knowledgeScale={profession?.knowledgeScale}
        enjoymentScale={profession?.enjoymentScale}
      />
    </div>
  );
};

export default ProfessionMatrixCard;
