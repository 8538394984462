import { ReactElement } from "react";
import { RestrictedActionWrapperProps } from "./types";
import { hasActionAccesses } from "./utils";

const RestrictedActionWrapper = ({
  actionAccessesList = {},
  accesses = "",
  children,
  noAccessComponent,
}: RestrictedActionWrapperProps): ReactElement => {
  if (hasActionAccesses(actionAccessesList, accesses)) return <>{children}</>;

  if (noAccessComponent) return <>{noAccessComponent}</>;

  return <></>;
};

export default RestrictedActionWrapper;
