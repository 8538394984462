import APIService from "../api/dictionaries-api";
import {
  DictionaryNewEntryAPIModel,
  fromAPIToEntryModel,
  toNewEntryAPIModel,
} from "../models";
import { handleError } from "../../shared";
import { NewDictionaryEntry, PreparedDictionaryEntry } from "./types";

const DictionariesService = (() => {
  const fetchDictionary = async (dictionaryId) => {
    try {
      const response = await APIService.API.getDictEntries(dictionaryId);
      if (response?.data) {
        return fromAPIToEntryModel(response.data);
      }
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const createDictionaryEntry = async (
    dictionaryId,
    newEntry: DictionaryNewEntryAPIModel,
  ) => {
    try {
      const response = await APIService.API.createDictEntry(
        dictionaryId,
        toNewEntryAPIModel(newEntry),
      );
      if (response?.data) {
        return response?.data
      }
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const setDictionaryEntryDeleted = async (
    dictionaryId,
    entryId: string,
    deleted = true,
  ) => {
    try {
      const response = await APIService.API.setDeleted(dictionaryId, entryId, {
        deleted,
      });
      return response;
    } catch (error) {
      return handleError(error);
    }
  };

  const addDictionaryEntry = async (
    entry: PreparedDictionaryEntry,
    dictionaryId,
  ): Promise<NewDictionaryEntry | PreparedDictionaryEntry> => {
    try {
      if (entry?.isNew) {
        const result = await createDictionaryEntry(dictionaryId, entry);
        return result.data;
      }
      return entry;
    } catch (error) {
      return handleError(error);
    }
  };

  const updateDictionaryEntry = async (
    dictionaryId,
    entryId: string,
    updatedName: string,
  ) => {
    try {
      const response = await APIService.API.updateDict(dictionaryId, entryId, {
        name: updatedName,
      });
      return response;
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    fetchDictionary,
    createDictionaryEntry,
    setDictionaryEntryDeleted,
    addDictionaryEntry,
    updateDictionaryEntry,
  };
})();

export default DictionariesService;
