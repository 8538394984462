import imageCompression from "browser-image-compression";

export interface PixelCrop {
  width: number;
  height: number;
  x: number;
  y: number;
}

const createImage = (imageSrc: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = imageSrc;
  });

const getCompressedFile = (
  canvas: HTMLCanvasElement,
  progressCallback?: (progress: number) => void,
) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 500,
    onProgress: progressCallback,
    useWebWorker: true,
  };
  return imageCompression
    .canvasToFile(canvas, "image/png", "avatar", Date.now())
    .then((file) => imageCompression(file, options));
};

const getCroppedImage = async (
  imageSrc: string,
  pixelCrop: PixelCrop,
  progressCallback?: (progress: number) => void,
): Promise<File> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const canvasContext = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  canvasContext?.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );
  const data = canvasContext?.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  if (!data)
    return Promise.reject(Error("Error while geting data from canvas"));

  canvasContext?.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  return getCompressedFile(canvas, progressCallback);
};

export default getCroppedImage;
