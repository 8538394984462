import { useCallback, useEffect, useState } from "react";
import { utils, writeFileXLSX } from "xlsx";
import dayjs from "dayjs";
import { ReportsModel } from "../../../models";
import { flatEntriesData } from "../table/helpers";
import { ExcelColumsType } from "../types";
import { reportDayFormat } from "../constants";
import { useTranslationWithNamespace } from "../../../hooks";
import { FlattenEntry } from "../table/types";

export default function useExcelReport(
  report: ReportsModel,
  timeFrame: Date[],
): {
  exportExcel: () => void;
} {
  const { t } = useTranslationWithNamespace();
  const [normalizedReport, setNormalizedReport] = useState<ExcelColumsType[]>(
    [],
  );

  useEffect(() => {
    if (report) {
      const flatReport = flatEntriesData(report);
      const sortedReport: FlattenEntry[] = flatReport.sort(
        (a: FlattenEntry, b: FlattenEntry) => (a.date.isAfter(b.date) ? 1 : -1),
      );
      const mappedColumnsContent: ExcelColumsType[] = sortedReport.map(
        ({
          date,
          clientName,
          projectName,
          userName,
          minutes,
        }): ExcelColumsType => ({
          Date: date.format(reportDayFormat),
          Client: clientName,
          Project: projectName,
          Employee: userName,
          Hours: +(+(minutes / 60).toPrecision(4)).toFixed(2),
        }),
      );
      setNormalizedReport(mappedColumnsContent);
    }
  }, [report]);

  const exportExcel = useCallback(() => {
    const dateFrom = dayjs(timeFrame.at(0)).format(reportDayFormat);
    const dateTo = dayjs(timeFrame.at(-1)).format(reportDayFormat);

    const firstSheet = utils.json_to_sheet(normalizedReport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, firstSheet, `${t("reports.excelSheet")}`);
    writeFileXLSX(wb, `report_${dateFrom}_${dateTo}.xlsx`);
  }, [normalizedReport, timeFrame]);
  return {
    exportExcel,
  };
}
