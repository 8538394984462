// eslint-disable-next-line no-shadow
enum ActionTypes {
  FETCH_USERS_START = "FETCH_USERS_START",
  FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS",
  FETCH_USERS_ERROR = "FETCH_USERS_ERROR",
  FETCH_USER_DATA_START = "FETCH_USER_DATA_START",
  FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS",
  FETCH_USER_DATA_ERROR = "FETCH_USER_DATA_ERROR",
  FETCH_ALL_ROLES_START = "FETCH_ALL_ROLES_START",
  FETCH_ALL_ROLES_SUCCESS = "FETCH_ALL_ROLES_SUCCESS",
  FETCH_ALL_ROLES_ERROR = "FETCH_ALL_ROLES_ERROR",
  CREATE_ROLE_START = "CREATE_ROLE_START",
  CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS",
  CREATE_ROLE_ERROR = "CREATE_ROLE_ERROR",
  UPDATE_ROLE_START = "UPDATE_ROLE_START",
  UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS",
  UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR",
  DELETE_ROLE_START = "DELETE_ROLE_START",
  DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS",
  DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR",
  CREATE_USERS_START = "CREATE_USERS_START",
  CREATE_USERS_SUCCESS = "CREATE_USERS_SUCCESS",
  CREATE_USERS_ERROR = "CREATE_USERS_ERROR",
}

export default ActionTypes;
