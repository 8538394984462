export const START_DATE = "startDate";
export const END_DATE = "endDate";
export const RESPONSIBILITY = "responsibility";
export const PROJECT_ROLES = "projectRoles";
export const TECH_STACK = "techStack";
export const DESCRIPTION = "description";
export const DOMAIN = "domain";
export const PROJECTS = "projects";
export const SKILLS = "skills";
export const NAME = "name";
export const PLACEHOLDER = "Placeholder";
