import { ReactElement } from "react";
import { InfoTooltip, LinkButton } from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { TEAM_LIST_PROFILE_SUMMARY } from "../../../shared/data-cy";
import { ReactComponent as PDFIcon } from "../../../images/team/pdf.svg";
import {
  OtherProfileSummaryReturn,
  OtherProfileSummaryState,
  PDFGeneratorButtonProps,
} from "./types";
import generatePdfDocument from "./generatePDFDocument";

const PDFGeneratorButton = ({
  avatar,
  currentProfile,
  disabled,
  generationOption,
}: PDFGeneratorButtonProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const mapState = (
    state: OtherProfileSummaryState,
  ): OtherProfileSummaryReturn => ({
    professionDict: state.dictionaries.profession,
    seniorityDict: state.dictionaries.seniority,
    technologyDict: state.dictionaries.technology,
    roleDict: state.dictionaries.project_role,
    domainsDict: state.dictionaries.domain,
  });

  const {
    seniorityDict,
    professionDict,
    technologyDict,
    roleDict,
    domainsDict,
  } = useMappedStateSelector(mapState);

  return (
    <div className="flex items-center gap-1">
      <LinkButton
        dataCy={TEAM_LIST_PROFILE_SUMMARY}
        disabled={disabled}
        prefixIcon={<PDFIcon className="w-4 h-4" />}
        onClick={() =>
          generatePdfDocument(
            professionDict,
            seniorityDict,
            technologyDict,
            roleDict,
            domainsDict,
            currentProfile,
            generationOption,
            avatar,
          )
        }
      >
        {t(`team.usersList.${generationOption || "generatePdf"}`)}
      </LinkButton>
      <InfoTooltip
        dataCy={TEAM_LIST_PROFILE_SUMMARY}
        tooltipContent={t(
          `team.usersList.${generationOption || "generatePdf"}Tooltip`,
        )}
        tooltipSize="w-6 h-6"
        questionMarkSize="w-3 h-3"
        contentWidth="w-max"
      />
    </div>
  );
};

export default PDFGeneratorButton;
