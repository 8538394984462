import { ReactElement, useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import { useDispatch } from "redux-react-hook";
import {
  Button,
  Card,
  PermissionContext,
  RestrictedWrapper,
  TableLabel,
  Tooltip,
} from "@ps/ui-components";
import { clearRedirection, setRedirection } from "@ps/alert-modal";
import { classJoin } from "@ps/utils";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { ReactComponent as EditIcon } from "../../../../images/prospect/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../images/delete.svg";
import { ReactComponent as DuplicateIcon } from "../../../../images/prospect/duplicate.svg";
import { toggleEditMode } from "../../../../store";
import {
  DEMAND,
  ENGAGEMENT,
  EXPECTED_LANGUAGE,
  NEEDS,
  NEW_ITEMS,
  OVERLAP,
  PROFESSION,
  PROSPECT,
  PROSPECT_VIEW,
  RATE,
  SENIORITY,
  WORKING_TIME,
} from "../../../constants";
import { ReactComponent as AddNewIcon } from "../../../../images/prospect/addNeed.svg";
import {
  SectionArrayWrapperMapState,
  SectionArrayWrapperMapStateReturn,
  SectionArrayWrapperProps,
} from "./types";
import { PROSPECT_FULL_ACCESS } from "../../../../shared/permissions";
import { Keys } from "../../../../shared";
import styles from "./styles.module.css";
import NeedsModal from "./edit/needsModal";
import ConfirmRemoveModal from "./edit/confirmRemoveModal";
import EmptySection from "./view/emptySection";
import { ReactComponent as ProspectMen } from "../../../../images/prospect/prospect-men.svg";

const BASE_PATH = `${PROSPECT_VIEW}_${NEEDS}`;
const TRANSLATION_PATH = "projects.prospect.needs";

const SectionArrayWrapper = ({
  name,
  View,
  section,
  onRemove,
  onSubmit,
}: SectionArrayWrapperProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const translate = (field: string) => t(`projects.prospect.needs.${field}`);
  const dispatch = useDispatch();
  const { hasPermission } = useContext(PermissionContext);

  const [editIndex, setEditIndex] = useState(-1);
  const [removeIndex, setRemoveIndex] = useState(-1);

  const mapState = (
    state: SectionArrayWrapperMapState,
  ): SectionArrayWrapperMapStateReturn => ({
    accessesList: state?.prospects?.prospect?.accesses,
    data: state?.prospects?.prospect?.[section] || {} || [],
    domainDict: state.dictionaries?.domain || [],
    editMode: state.editMode,
    professionDict: state.dictionaries?.profession || [],
    seniorityDict: state.dictionaries?.seniority || [],
    technologyDict: state.dictionaries?.technology || [],
    archived: state.prospects.prospect.archived,
  });

  const {
    data,
    domainDict,
    editMode,
    professionDict,
    seniorityDict,
    technologyDict,
    archived,
  } = useMappedStateSelector(mapState);
  const { clearErrors, control, handleSubmit, reset } = useFormContext();

  const { isDirty } = useFormState({
    control,
  });

  useEffect(() => {
    if (isDirty) dispatch(setRedirection());
  }, [isDirty]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: section,
  });

  useEffect(() => {
    reset({ [section]: data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleOnEditArrayItem = (index: number, value: string): void => {
    dispatch(toggleEditMode(value));
    setEditIndex(index);
    clearErrors();
    dispatch(clearRedirection());
  };

  const handleOnCancel = (): void => {
    if (data?.length < fields?.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      remove(editIndex);
    }
    reset();
    handleOnEditArrayItem(-1, "");
  };

  const handleOnAdd = (value: string) => {
    append(NEW_ITEMS[name]);
    handleOnEditArrayItem(fields.length, value);
  };

  const handleOnDuplicateArrayItem = (index: number, value: string): void => {
    const copyItem = JSON.parse(JSON.stringify(fields[index]));
    delete copyItem.itemId;
    delete copyItem.id;
    append(copyItem);

    handleOnEditArrayItem(fields.length, value);
  };

  const handleOnRemove = (itemId: string): void => {
    setRemoveIndex(-1);
    onRemove(itemId);
  };

  useEffect(() => {
    return () => {
      handleOnEditArrayItem(-1, "");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labels = [
    PROFESSION,
    SENIORITY,
    DEMAND,
    RATE,
    ENGAGEMENT,
    EXPECTED_LANGUAGE,
    WORKING_TIME,
    OVERLAP,
  ];

  return (
    <Card
      additionalCardClassName="py-5 px-8 relative"
      dataCy={`${PROSPECT}-${name}`}
    >
      <div className="absolute -top-12 right-8">
        {!archived && (
          <RestrictedWrapper permissions={PROSPECT_FULL_ACCESS}>
            <Button
              dataCy={`${PROSPECT_VIEW}_${name}_addNew`}
              onClick={() => handleOnAdd(name)}
              additionalClass="flex gap-x-4"
              width="w-60"
            >
              <AddNewIcon className="w-4 h-4" />
              {t("projects.prospect.needs.addReq")}
            </Button>
          </RestrictedWrapper>
        )}
      </div>
      <div>
        {!fields.length && (
          <EmptySection
            onAddClick={() => handleOnAdd(name)}
            translationPath={TRANSLATION_PATH}
            noElementsTranslation="noReqs"
            toDoTranslation="defineReq"
            addNewTranslation="addReq"
            Image={<ProspectMen className="h-80 m-4" />}
            archived={archived || !hasPermission(PROSPECT_FULL_ACCESS)}
          />
        )}
      </div>

      <div
        className={classJoin(
          !!fields.length && styles.needsGrid,
          !!fields.length &&
            "w-full bg-neutralSecondary-41 bg-opacity-10 rounded-t-2xl h-16 items-center px-6 relative",
        )}
      >
        {!!fields.length &&
          labels.map((l: string) => (
            <TableLabel key={l} dataCy={`${BASE_PATH}_labels`}>
              {translate(l)}
            </TableLabel>
          ))}
      </div>
      <>
        {fields.map((item, index) => (
          <div
            key={item.id}
            className="flex justify-between flex-col items-start relative w-full"
            data-cy={`${PROSPECT_VIEW}_${name}`}
          >
            <View {...item} domainDict={domainDict} />

            {!archived && (
              <RestrictedWrapper permissions={PROSPECT_FULL_ACCESS}>
                <>
                  <div className="flex items-center gap-2 absolute top-8 right-6">
                    <Tooltip
                      placement="top"
                      content={
                        <span>{t("projects.prospect.actions.duplicate")}</span>
                      }
                    >
                      <div
                        data-cy={`${PROSPECT_VIEW}_${name}_duplicateButton`}
                        onClick={() => handleOnDuplicateArrayItem(index, name)}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            handleOnDuplicateArrayItem(index, name);
                          }
                        }}
                        role="button"
                        tabIndex={0}
                        className="border rounded-lg h-10 w-10 flex p-2"
                      >
                        <DuplicateIcon className="text-neutralSecondary-41 hover:text-neutralSecondary-50" />
                      </div>
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      content={
                        <span>{t("projects.prospect.actions.edit")}</span>
                      }
                    >
                      <div
                        data-cy={`${PROSPECT_VIEW}_${name}_editButton`}
                        onClick={() => handleOnEditArrayItem(index, name)}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            handleOnEditArrayItem(index, name);
                          }
                        }}
                        role="button"
                        tabIndex={0}
                        className="border rounded-lg h-10 w-10 flex p-2"
                      >
                        <EditIcon className="text-neutralSecondary-41" />
                      </div>
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      content={
                        <span>{t("projects.prospect.actions.delete")}</span>
                      }
                    >
                      <div
                        data-cy={`${PROSPECT_VIEW}_${name}_deleteButton`}
                        onClick={() => setRemoveIndex(index)}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            setRemoveIndex(index);
                          }
                        }}
                        role="button"
                        tabIndex={0}
                        className="border rounded-lg h-10 w-10 flex p-2"
                      >
                        <DeleteIcon className="text-neutralSecondary-41" />
                      </div>
                    </Tooltip>
                  </div>
                  {editIndex === index && (
                    <NeedsModal
                      isOpen={editMode === name}
                      onClose={handleOnCancel}
                      dataCy={`${BASE_PATH}_needsModal`}
                      index={index}
                      seniorityDict={seniorityDict}
                      technologyDict={technologyDict}
                      professionDict={professionDict}
                      onSubmit={handleSubmit((dataToSubmit) =>
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        onSubmit(dataToSubmit?.[section]?.[editIndex]),
                      )}
                      onCancel={handleOnCancel}
                      bannedCountries={item.blacklistedCountries}
                    />
                  )}
                </>
              </RestrictedWrapper>
            )}
            {index === removeIndex && (
              <ConfirmRemoveModal
                isOpen={index === removeIndex}
                onClose={() => setRemoveIndex(-1)}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onConfirm={() => handleOnRemove(item.itemId)}
                header={t("projects.prospect.needs.doYouWantToRemoveNeed")}
                editSection={NEEDS}
              />
            )}
          </div>
        ))}
      </>
    </Card>
  );
};

export default SectionArrayWrapper;
