import {
  ARTICLES,
  AWARDS,
  CERTIFICATES,
  CONTRIBUTIONS,
  TALKS,
  TUTORS,
} from "../experience/sections/accomplishment/constants";
import { AVAILABILITY, WORKING_HOURS } from "../overview/constants";

export { ARTICLES, AWARDS, CERTIFICATES, CONTRIBUTIONS, TALKS, TUTORS };

export const BIO = "bio";
export const EXPERIENCE = "experience";
export const LANGUAGES = "languages";
export const GOALS = "goals";
export const PROJECTS = "projects";
export const EDUCATION = "education";
export const ACCOMPLISHMENTS = "accomplishments";
export const ADDITIONAL_INFO = "additionalInfo";

export const WIZARD_STEPS = [
  BIO,
  EXPERIENCE,
  AVAILABILITY,
  WORKING_HOURS,
  LANGUAGES,
  // GOALS,
  PROJECTS,
  EDUCATION,
  ACCOMPLISHMENTS,
  ADDITIONAL_INFO,
];

export const TRANSLATION_PATH = "ocr.wizard";
