import { getNameFromDictionary, removeDiacriticsFromString } from "@ps/utils";
import { ProfilesSummaryListModel } from "../../../../profile-domain";
import { DiscoveryTeamFilters, FilterItem } from "./discovery-team/types";
import { SENIORITY, PROFESSIONS, MAIN, RESIDENCE } from "../../../constants";
import { DictionaryEntryModel, DictionaryModel } from "../../../../../index";
import { removeFilterDuplicates } from "../../../utils/filterProspectsList";
import { UserProps } from "./folder/types";

export const getCheckedNames = (
  filters: DiscoveryTeamFilters,
  name: string,
): string[] =>
  filters?.[name]?.map((item: FilterItem | string) =>
    typeof item === "string" ? item : item.name,
  );

export const formatFilterTooltipName = (
  tempFilters: DiscoveryTeamFilters,
  filterName: string,
  dictionaryList?: {
    [key: string]: DictionaryModel;
  }[],
): string[] => {
  const result = getCheckedNames(tempFilters, filterName);
  if (dictionaryList?.length) return result;

  if (dictionaryList?.[filterName])
    return result.map(
      (resultItem: string) =>
        dictionaryList[filterName]?.find(
          (profItem: DictionaryEntryModel) => profItem?.id === resultItem,
        )?.name,
    );

  return result;
};

export const filterUsers = (
  users: ProfilesSummaryListModel[] | [],
  searchValue: string,
  filters: DiscoveryTeamFilters,
  search?: (str: string) => unknown[],
): ProfilesSummaryListModel[] => {
  const preparedToFilter = !searchValue?.length
    ? users
    : (search &&
        (search(
          removeDiacriticsFromString(searchValue.toLowerCase()),
        ).reverse() as ProfilesSummaryListModel[])) ||
      [];

  if (
    !filters ||
    !Object.values(filters).filter((item: string[]) => item.length)?.length
  )
    return preparedToFilter;

  let result = preparedToFilter;

  if (filters[PROFESSIONS]?.length && result.length) {
    result = result.filter((user: ProfilesSummaryListModel) =>
      filters?.[PROFESSIONS]?.some(
        (item: string) => item === user.mainProfession,
      ),
    );
  }

  if (filters[SENIORITY]?.length && result.length) {
    result = result.filter((user: ProfilesSummaryListModel) =>
      filters[SENIORITY].some((item) => item === user.seniority),
    );
  }

  if (filters[RESIDENCE]?.length && result.length) {
    result = result.filter((user: ProfilesSummaryListModel) =>
      filters[RESIDENCE].some((item) => item === user[RESIDENCE]?.value),
    );
  }

  return result;
};

export const filterSpecialists = (
  users: UserProps[] | [],
  searchValue: string,
  filters: DiscoveryTeamFilters,
  search?: (str: string) => unknown[],
): UserProps[] => {
  const preparedToFilter = !searchValue?.length
    ? users
    : (search &&
        (search(
          removeDiacriticsFromString(searchValue.toLowerCase()),
        ).reverse() as UserProps[])) ||
      [];

  if (
    !filters ||
    !Object.values(filters).filter((item: string[]) => item.length)?.length
  )
    return preparedToFilter;

  let result = preparedToFilter;

  if (filters[PROFESSIONS]?.length && result.length) {
    result = result.filter((user: UserProps) =>
      filters?.[PROFESSIONS]?.some(
        (item: string) => item === user.mainProfession,
      ),
    );
  }

  if (filters[SENIORITY]?.length && result.length) {
    result = result.filter((user: UserProps) =>
      filters[SENIORITY].some((item) => item === user.seniority),
    );
  }

  if (filters[RESIDENCE]?.length && result.length) {
    result = result.filter((user: UserProps) =>
      filters[RESIDENCE].some((item) => item === user[RESIDENCE]?.value),
    );
  }

  return result;
};

export const countOptionFilterAmount = (
  optionId: string,
  fieldName: string,
  users: ProfilesSummaryListModel[],
  nestedName?: string,
): number =>
  nestedName
    ? users?.filter(
        (user: ProfilesSummaryListModel): boolean =>
          user[fieldName]?.[nestedName] === optionId,
      )?.length
    : users?.filter(
        (user: ProfilesSummaryListModel): boolean =>
          user[fieldName] === optionId,
      )?.length;

export const prepareProfessionFilterOptions = (
  users: ProfilesSummaryListModel[] | UserProps[],
  dictionary: DictionaryModel,
): FilterItem[] => {
  const temp = JSON.parse(JSON.stringify(users));
  const result: FilterItem[] = temp
    .filter((user: ProfilesSummaryListModel) => user[MAIN])
    .map(
      (item: ProfilesSummaryListModel): FilterItem => ({
        name: getNameFromDictionary(dictionary, item[MAIN]),
        id: item[MAIN] || "",
      }),
    );

  return removeFilterDuplicates(result);
};

export const prepareSeniorityFilterOptions = (
  users: ProfilesSummaryListModel[] | UserProps[],
  dictionary: DictionaryModel,
): FilterItem[] => {
  const temp = JSON.parse(JSON.stringify(users));
  const result: FilterItem[] = temp
    .filter((user: ProfilesSummaryListModel) => user[SENIORITY])
    .map(
      (item: ProfilesSummaryListModel): File => ({
        name: getNameFromDictionary(dictionary, item[SENIORITY]),
        id: item[SENIORITY] || "",
      }),
    );

  return removeFilterDuplicates(result);
};

export const prepareLocationFilterOptions = (
  users: ProfilesSummaryListModel[] | UserProps[],
  allCountries: { [key: string]: string },
): FilterItem[] => {
  const temp = JSON.parse(JSON.stringify(users));

  const result: FilterItem[] = temp
    .map(
      (item: ProfilesSummaryListModel): FilterItem => ({
        name: item[RESIDENCE]?.value ? allCountries[item[RESIDENCE].value] : "",
        id: item[RESIDENCE]?.label
          ? Object.keys(allCountries).find(
              (key: string) => allCountries[key] === item[RESIDENCE]?.label,
            )
          : "",
      }),
    )
    .filter((item: FilterItem) => item.id.length);
  return removeFilterDuplicates(result);
};
