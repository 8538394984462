import { TFunction } from "i18next";
import { FilesService, S3FileModel } from "@ps/hh";

export const emptyMember = {
  email: "",
  error: "",
  fileName: "",
  group: {},
  isFileUploading: false,
  name: "",
  profileType: 0,
  startDate: new Date(),
  surname: "",
  file: undefined,
};

export const MAX_FILES_UPLOAD = 50;
export const MAX_FILE_WEIGHT_MB = 4;
export const FILE_EXTENSION = "pdf";

export const validateFile = (
  file: File,
  t: TFunction,
  extension = FILE_EXTENSION,
  sizeLimitMB = MAX_FILE_WEIGHT_MB,
): string[] => {
  const MB = 1_000_000;
  const errors: string[] = [];
  const fileName = file?.name;

  if (file?.size / MB > sizeLimitMB) {
    errors.push(
      t("members.inviting.errors.fileTooBig", {
        size: `${(file.size / MB).toFixed(2)}`,
      }),
    );
  } else if (!fileName?.endsWith(`.${extension}`)) {
    errors.push(
      t("members.inviting.errors.wrongType", {
        extension: extension.toUpperCase(),
      }),
    );
  } else if (fileName?.length > 40) {
    errors.push(
      t("members.inviting.errors.fileNameTooLong", {
        length: 40,
      }),
    );
  } else if (fileName?.length < 8) {
    errors.push(
      t("members.inviting.errors.fileNameTooShort", {
        length: 3,
      }),
    );
  } else if (fileName?.indexOf(" ") >= 0) {
    errors.push(
      t("members.inviting.errors.noWhiteSpace", {
        length: 3,
      }),
    );
  } else if (/^[a-zA-Z]+$/.test(fileName)) {
    errors.push(t("members.inviting.errors.onlyAlphaChars"));
  }

  return errors;
};

export const S3UploadCV = async (
  files: { file: File; fileId: string; clientError?: string | string[] }[],
  max = 10,
): Promise<S3FileModel[]> => {
  if (!files.length) return [];
  const filesWithoutErrors = files?.filter(
    (item) => !item?.clientError?.length,
  );

  if (!filesWithoutErrors?.length) return [];
  if (files.length <= max) return FilesService.uploadCVList(filesWithoutErrors);

  let chunks: { file: File; fileId: string }[][] = [];
  for (let i = 0; i < filesWithoutErrors.length; i += max) {
    const chunk = filesWithoutErrors.slice(i, i + max);
    chunks = [...chunks, chunk];
  }
  const result = await Promise.allSettled(
    chunks.map((chunk: { file: File; fileId: string }[]) =>
      FilesService.uploadCVList(chunk),
    ),
  );
  return result;
};
