import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "redux-react-hook";
import dayjs from "dayjs";
import { TimeTrackerTimeNavigator } from "@ps/ui-components";
import { minutesToHHMM } from "@ps/utils";
import { TIME_NAVIGATOR } from "../../shared/data-cy";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../hooks";
import { ProjectTimeEntryModel, ProjectTimeEntriesModel } from "../../models";
import CircleChartColumn from "./circle-chart-column";
import BarChartColumn from "./bar-chart-column";
import { TimeTrackerMapState, TimeTrackerMapStateReturn } from "./types";
import { fetchTimesheetData } from "../../store";

const Dashboard = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const [focusedElementID, setFocusedElementID] = useState("");

  const mapState = (state: TimeTrackerMapState): TimeTrackerMapStateReturn => ({
    projectTimEntries: state?.timesheet?.project || {},
  });

  const { projectTimEntries } = useMappedStateSelector(mapState);

  const [timeFrame, setTimeFrame] = useState<Date[]>([
    dayjs().startOf("week").day(1).toDate(),
    dayjs().startOf("week").add(7, "day").toDate(),
  ]);

  useEffect(() => {
    fetchTimesheetData(timeFrame[0], timeFrame[1], dispatch);
  }, []);

  const entriesValues = projectTimEntries && Object.values(projectTimEntries);
  const onlyEntries: ProjectTimeEntryModel[] = entriesValues
    ?.map((item: ProjectTimeEntriesModel) => Object.values(item))
    .flat(2);
  const totalWeekWorkMinutes: number = onlyEntries.reduce(
    (a: number, b: ProjectTimeEntryModel) => a + b.minutes,
    0,
  );

  return (
    <section>
      <div className="flex flex-row justify-end mb-8 gap-4">
        <div className="mr-auto flex items-center gap-4">
          <span className="text-neutralPrimary-30">
            {t("totalWeekWorkTime", {
              totalWeekWorkTime: minutesToHHMM(totalWeekWorkMinutes),
            })}
          </span>
        </div>
        {/* temporarly hidden - no functionality */}
        {/* <LinkButton
          dataCy={`${TIME_TRACKER}_export`}
          prefixIcon={<ExportIcon className="fill-current" />}
        >
          {t("export")}
        </LinkButton> */}
        <TimeTrackerTimeNavigator
          enabledPresets
          dataCy={TIME_NAVIGATOR}
          timeFrame={timeFrame}
          setFocusedElementID={setFocusedElementID}
          focusedElementID={focusedElementID}
          setTimeFrame={(newTime) => {
            setTimeFrame(newTime);
            fetchTimesheetData(newTime[0], newTime[1], dispatch);
          }}
        />
      </div>
      <section className="flex 2xl:flex-row flex-col w-full justify-center gap-16 h-auto mt-16">
        <div className="2xl:w-7/12 w-full">
          <BarChartColumn timeFrame={timeFrame} />
        </div>
        <CircleChartColumn />
      </section>
    </section>
  );
};

export default Dashboard;
