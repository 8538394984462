import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { CHIP_PREFIX } from "../../shared/data-cy";
import { ChipProps } from "./types";
import { checkColor } from "../../shared/utils/colorsUtils";

const alphaChannel = "35";

const Chip = ({
  dataCy,
  label,
  isActive = true,
  color,
  fontSize = "font-semibold",
  borderWidth = "border-2",
  additionalClassName,
  onClick,
  tailwindColors,
}: ChipProps): ReactElement => {
  const colorCondition = color && color.length > 0 && checkColor(color);

  const setColors = () => {
    if (colorCondition) {
      return "";
    }
    if (!colorCondition && tailwindColors) {
      return tailwindColors;
    }
    return "text-neutralPrimary-30 bg-neutralPrimary-100 border-neutralSecondary-41";
  };

  const colorStyles =
    color && colorCondition
      ? {
          color,
          backgroundColor: `${checkColor(color)}${alphaChannel}`,
          borderColor: color,
        }
      : {};

  return (
    <span
      className={classJoin(
        "text-center rounded-lg px-4 select-none",
        borderWidth,
        fontSize,
        setColors(),
        isActive ? "" : "opacity-40",
        additionalClassName,
        onClick ? "cursor-pointer" : "cursor-default",
      )}
      style={colorStyles}
      data-cy={`${CHIP_PREFIX}-${dataCy}`}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
      role={onClick ? "button" : "presentation"}
      onKeyDown={() => {
        // do nothing
      }}
    >
      {label}
    </span>
  );
};

export default Chip;
