import { ReactElement } from "react";
import { Label, Tag } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { DATE_FORMAT } from "../../../common/constants";
import { TutorCardProps } from "../types";
import { TECH_STACK } from "../../projects/constants";
import { EXPERIENCE_ACCOMPLISHMENT_VIEW } from "../../../../../../shared/data-cy";
import { DATE, DESCRIPTION, TITLE, TUTORS } from "../constants";
import { DictionaryEntryModel } from "../../../../../../dictionaries";
import accomplishmentsGrid from "../../../common/styles";

const TutorCard = ({
  title,
  date,
  description,
  techStack,
}: TutorCardProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const BASE_PATH = `${EXPERIENCE_ACCOMPLISHMENT_VIEW}_${TUTORS}`;

  const translate = (field: string): string =>
    t(`profile.experience.cards.tutor.${field}`);

  return (
    <div className="relative w-full flex flex-col p-6">
      <div className={accomplishmentsGrid}>
        <Label text={translate(TITLE)} dataCy={`${BASE_PATH}_${TITLE}`}>
          {title}
        </Label>
        <Label
          text={translate(DATE)}
          dataCy={`${BASE_PATH}_${DATE}`}
          additionalClassName="w-min"
        >
          {date?.format(DATE_FORMAT)}
        </Label>
      </div>
      <div className="w-full mb-5 whitespace-pre-wrap">
        <Label
          text={translate(DESCRIPTION)}
          dataCy={`${BASE_PATH}_${DESCRIPTION}`}
          fontWeight="font-normal"
        >
          {description}
        </Label>
      </div>
      <Label dataCy={`${BASE_PATH}_${TECH_STACK}`} text={translate(TECH_STACK)}>
        <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
          {techStack?.map((tech: DictionaryEntryModel) => (
            <Tag name={tech?.name} dataCy={tech?.id} key={tech?.id} />
          ))}
        </div>
      </Label>
    </div>
  );
};

export default TutorCard;
