import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { AvatarProps } from "./types";
import { AVATAR_PREFIX } from "../../shared/data-cy";

const avatarStyle = classJoin.props<
  Pick<AvatarProps, "width" | "height" | "cursorType">
>`
bg-neutralSecondary-40 rounded-md flex items-center
justify-center overflow-hidden min-w-fit
${({ width, height }) => `w-${width} h-${height}`}
${({ cursorType }) => `cursor-${cursorType}`}
`;

const Avatar = ({
  image,
  defaultImage,
  dataCy,
  alt,
  width = 24,
  height = 24,
  onClick,
  cursorType = "default",
}: AvatarProps): ReactElement => (
  <div
    className={avatarStyle({ width, height, cursorType })}
    data-cy={`${AVATAR_PREFIX}-${dataCy}`}
  >
    <img
      src={
        image
          ? `${image}?d=${
              // added to prevent caching images in the browser
              Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) +
              1000000000
            }`
          : defaultImage
      }
      alt={alt}
      className={classJoin("object-cover", `w-${width}`, `h-${height}`)}
      onClick={onClick}
      role="presentation"
    />
  </div>
);

export default Avatar;
