import { colorFormatters } from "@ps/utils";
import {
  DomainTemplateModel,
  GroupAPIModel,
  ProfessionGroupModel,
  RatingScaleAPIModel,
  RatingScaleModel,
  SkillAPIModel,
  UpdateDomainTemplateAPIModel,
  UpdateProfessionTemplateAPIModel,
} from "../types";

const ratingScaleToAPI = (scale: RatingScaleModel): RatingScaleAPIModel => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  desc: scale?.desc || null,
  score: scale.score,
  color: colorFormatters.colorToRBGObject(scale?.color),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconUrl: scale?.iconUrl || null,
});

export const domainTemplateToAPI = (
  data: DomainTemplateModel | UpdateDomainTemplateAPIModel,
): UpdateDomainTemplateAPIModel => ({
  domains: data.domains,
  knowledgeScale: data?.knowledgeScale?.map((item) => ratingScaleToAPI(item)),
  enjoymentScale: data?.enjoymentScale?.map((item) => ratingScaleToAPI(item)),
});

export const professionTemplateToAPI = (
  data: ProfessionGroupModel | UpdateProfessionTemplateAPIModel,
): UpdateProfessionTemplateAPIModel => ({
  groups: data?.groups?.map((group: GroupAPIModel) => ({
    ...group,
    skills: group?.skills?.map((skill: SkillAPIModel) => ({
      ...skill,
      technologyId: skill.technologyId.replace(/\s+/g, "").toLowerCase(),
    })),
  })),
  knowledgeScale: data?.knowledgeScale?.map((item) => ratingScaleToAPI(item)),
  enjoymentScale: data?.enjoymentScale?.map((item) => ratingScaleToAPI(item)),
});
