import { ReactElement, useState, useEffect } from "react";
import {
  Button,
  Toggle,
  Input,
  Label,
  Modal2,
  ModalFooter,
  ModalHeader,
  RestrictedWrapper,
  SearchSelect,
  Textarea,
} from "@ps/ui-components";
import { FormProvider, useForm, Controller } from "react-hook-form";
import {
  classJoin,
  colorFormatters,
  prepareDictionarySearchSelect,
  removeDiacriticsFromString,
} from "@ps/utils";
import { useHistory } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import {
  NEW_PROJECT,
  PROJECT_NAME,
  PROJECT_NAME_PLACEHOLDER,
  SAVE,
  CANCEL,
  projectDefaultColors,
  GENERAL_NOTE_PLACEHOLDER,
  GENERAL_NOTE,
} from "../../../constants";
import { CreateProjectModalProps } from "../types";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { ProjectService } from "../../../services";
import { CLIENTS_MANAGE } from "../../../../shared/permissions";
import {
  ADD,
  CLIENT_LABEL,
  CLIENT_NAME,
  CLIENT_PLACEHOLDER,
  CREATE,
  CREATE_NEW_CLIENT,
} from "../../../../prospect-domain/constants";
import AddNewClientModal from "../../../../client-domain/ui/addNewClientModal";
import { fetchClients, ClientModel } from "../../../../client-domain";
import { filterArchivedClients, Keys } from "../../../../shared";
import {
  CreateProjectModalMapState,
  CreateProjectModalMapStateReturn,
} from "./types";
import { getClientData } from "./utils";
import { ProjectModel } from "../../../models";
import { ReactComponent as RocketIcon } from "../../../../images/rocket.svg";
import { ReactComponent as SuitCaseIcon } from "../../../../images/suitcase.svg";

const CreateProjectModal = ({
  isOpen,
  onClose,
  projects,
  defaultClientId,
}: CreateProjectModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isNewClientModal, setIsNewClientModal] = useState(false);

  const mapState = (
    state: CreateProjectModalMapState,
  ): CreateProjectModalMapStateReturn => ({
    clients: state.clients.clientsList || [],
  });

  const { clients } = useMappedStateSelector(mapState);

  useEffect(() => {
    fetchClients(dispatch);
  }, []);

  const methods = useForm({
    defaultValues: {
      name: "",
      note: "",
      clientId: defaultClientId || "",
      public: false,
    },
  });

  const handleOnClose = (): void => {
    methods.reset();
    onClose();
  };

  const onSubmit = async (data: {
    public: boolean;
    name: string;
    clientId: string;
  }): Promise<void> => {
    const newProjectId = await ProjectService.createProject({
      ...data,
      color: colorFormatters.colorToRBGObject(
        projectDefaultColors[
          Math.floor(Math.random() * projectDefaultColors.length)
        ],
      ),
    });
    if (newProjectId && typeof newProjectId === "string") {
      handleOnClose();
      history.push(`/project/${newProjectId}`);
    }
    methods.reset();
  };

  const translate = (field: string, isRequired = true): string =>
    `${t(`project.details.${field}`)}${isRequired ? "*" : ""}`;

  const validateProjectName = (newValue: string): string | boolean => {
    if (newValue.length > 100 || !newValue.trim().length)
      return `${t("project.errors.cantBeMoreThan", { number: 100 })}`;
    if (
      projects.some(
        (item: ProjectModel) =>
          removeDiacriticsFromString(item.details.name).toLowerCase() ===
          removeDiacriticsFromString(newValue).toLowerCase(),
      )
    )
      return `${t("project.errors.nameExists")}`;
    return true;
  };

  const handleOnNewClientClose = (newClientId = ""): void => {
    if (newClientId) {
      methods.setValue("clientId", newClientId);
    }
    setIsNewClientModal(false);
  };

  return isNewClientModal ? (
    <AddNewClientModal
      fetchClients={() => fetchClients(dispatch)}
      isOpen={isNewClientModal}
      onClose={handleOnNewClientClose}
    />
  ) : (
    <Modal2
      isOpen={isOpen}
      dataCy={NEW_PROJECT}
      onClose={onClose}
      width="w-1/3"
      height="h-auto"
      withCloseIcon
      withYScroll={false}
    >
      <FormProvider {...methods}>
        <ModalHeader
          additionalClassName="pb-6"
          fontSize="text-xl"
          title={translate("createProjectModalHeader", false)}
          Icon={<RocketIcon />}
        />

        <div className="flex gap-4 mt-5">
          <Label
            dataCy={`${NEW_PROJECT}_${PROJECT_NAME}`}
            text={`${translate(PROJECT_NAME, false)}*`}
            labelFontWeight="font-bold"
            labelFontSize="text-base"
          >
            <Controller
              name="name"
              control={methods.control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  autoFocus
                  dataCy={`${NEW_PROJECT}_${PROJECT_NAME}`}
                  defaultValue={value}
                  error={!!error}
                  message={error?.message}
                  onChange={onChange}
                  placeholder={translate(PROJECT_NAME_PLACEHOLDER, false)}
                  width="w-full"
                  additionalClass=":placeholder: text-base"
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: `${t("project.errors.cantBeEmpty")}`,
                },
                validate: (value) => validateProjectName(value),
              }}
            />
          </Label>
        </div>
        <div className="mt-5 flex gap-x-6 items-top">
          <Label
            dataCy={`${NEW_PROJECT}_${CLIENT_NAME}`}
            text={`${translate(CLIENT_LABEL, false)}*`}
            labelFontWeight="font-bold"
            labelFontSize="text-base"
          >
            {defaultClientId ? (
              clients.find(
                (item: ClientModel): boolean => item.id === defaultClientId,
              )?.clientName
            ) : (
              <Controller
                name="clientId"
                control={methods.control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelect
                    width="w-full"
                    height="2.4rem"
                    additionalClassName="-mt-0.5"
                    dataCy={CLIENT_NAME}
                    options={prepareDictionarySearchSelect(
                      filterArchivedClients(clients),
                      "id",
                      "id",
                      "clientName",
                      "clientName",
                    )}
                    defaultValue={value ? getClientData(value, clients) : null}
                    onChange={onChange}
                    placeholder={translate(CLIENT_PLACEHOLDER, false)}
                    error={!!error}
                    message={error?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: `${t("project.errors.cantBeEmpty")}`,
                  },
                }}
              />
            )}
          </Label>
          {!defaultClientId ? (
            <RestrictedWrapper permissions={CLIENTS_MANAGE}>
              <Button
                dataCy={ADD}
                type="button"
                additionalClass="mt-6 flex gap-x-4"
                width="w-full"
                onClick={() => setIsNewClientModal(true)}
                variant="secondary"
              >
                <SuitCaseIcon />
                {`${t(`projects.prospect.actions.${CREATE_NEW_CLIENT}`)}`}
              </Button>
            </RestrictedWrapper>
          ) : (
            <></>
          )}
        </div>
        <Label
          dataCy={`${NEW_PROJECT}-note`}
          additionalClassName="mt-6"
          text={`${translate(GENERAL_NOTE, false)}*`}
          labelFontWeight="font-bold"
          labelFontSize="text-base"
        >
          <Controller
            name="note"
            control={methods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Textarea
                dataCy={`${NEW_PROJECT}-note-textarea`}
                height="h-40"
                placeholder={translate(GENERAL_NOTE_PLACEHOLDER, false)}
                additionalClassName=":placeholder: text-base"
                error={!!error}
                message={error?.message}
                onChange={onChange}
                defaultValue={value}
              />
            )}
            rules={{
              required: {
                value: true,
                message: `${t("project.errors.cantBeEmpty")}`,
              },
            }}
          />
        </Label>
        <div className="flex gap-2 items-center pt-2 mt-4">
          <Controller
            render={({ field: { onChange, value } }) => (
              <>
                <Toggle
                  checked={value}
                  dataCy={NEW_PROJECT}
                  onChange={() => onChange(!value)}
                />
                <span
                  className={classJoin(
                    value ? "text-primary-50 font-semibold" : "",
                  )}
                  role="button"
                  tabIndex={0}
                  onClick={() => onChange(true)}
                  onKeyDown={(event) => {
                    if (event.key === Keys.ENTER || event.key === Keys.SPACE) {
                      onChange(true);
                    }
                  }}
                >
                  <strong>{translate("openProject", false)} </strong>
                  {translate("allUsers", false)}
                </span>
              </>
            )}
            name="public"
          />
        </div>
      </FormProvider>
      <ModalFooter additionalClassName="mt-6 gap-x-6">
        <Button
          dataCy={CANCEL}
          variant="secondary"
          onClick={handleOnClose}
          width="w-1/2"
        >
          {t(`projects.prospect.actions.${CANCEL}`)}
        </Button>
        <Button
          dataCy={SAVE}
          type="submit"
          onClick={methods.handleSubmit(onSubmit)}
          width="w-1/2"
        >
          {t(`projects.prospect.actions.${CREATE}`)}
        </Button>
      </ModalFooter>
    </Modal2>
  );
};

export default CreateProjectModal;
