import dayjs, { Dayjs } from "dayjs";

export const renderDatesDiff = (
  start: Dayjs | string,
  end?: Dayjs | string,
): number => {
  if (!dayjs.isDayjs(start)) {
    return 0;
  }
  const endDate = end || dayjs();

  if (!dayjs.isDayjs(endDate)) {
    return 0;
  }

  return endDate.diff(start, "month");
};

export const countTimeRange = (
  startDate: Dayjs | string,
  endDate?: Dayjs | string,
): { years: number; months: number } => {
  const datesDiffInMonths = renderDatesDiff(startDate, endDate);
  const years = parseInt(String(datesDiffInMonths / 12), 10);
  const months = datesDiffInMonths % 12;

  if (!years && !months) return { years: 0, months: 1 };

  if (months === 11) return { years: years ? years + 1 : 1, months: 0 };

  return { years, months: months + 1 };
};
