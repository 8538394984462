import { ChangeEvent, ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, Input } from "@ps/ui-components";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../../../hooks";
import { useHolidaysTemplatesContext } from "../../../context";
import {
  BASE_TRANSLATION_PATH,
  HOLIDAYS_TEMPLATES,
  ERRORS,
  HOLIDAYS_LIST,
  NO_LIMIT,
  DAYS,
  CATEGORY_NAME,
  HOLIDAY,
  PAYMENT_PERCENTAGE,
  NO_LIMIT_THRESHOLD,
  PARTIAL_DAY_OFF,
} from "../../../constants";
import {
  checkIfStringUniqInArrayOfObjects,
  validateNumberOfDaysOff,
  validatePayRatePercentage,
} from "../../utils";
import { EditRowProps, EditRowMapState } from "./types";

const EditRow = ({ index }: EditRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { currentTemplate } = useHolidaysTemplatesContext();
  const { control, getValues, watch, setValue } = useFormContext();

  const mapState = (state: EditRowMapState): EditRowMapState => ({
    editMode: state?.editMode,
  });

  const { editMode } = useMappedStateSelector(mapState);

  const translate = (field: string): string =>
    t(`${BASE_TRANSLATION_PATH}.${HOLIDAYS_LIST}.${field}`);

  return (
    <>
      <span />
      <Controller
        name={`${HOLIDAY}.name`}
        control={control}
        rules={{
          required: {
            value: true,
            message: translate(`${ERRORS}.isRequired`),
          },
          validate: (value: string): boolean | string => {
            if (
              checkIfStringUniqInArrayOfObjects(
                index,
                value,
                "name",
                !!editMode,
                currentTemplate?.holidays,
              )
            )
              return translate(`${ERRORS}.name.isUnique`);
            if (!value.trim().length) return translate(`${ERRORS}.isRequired`);
            return true;
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            autoFocus
            dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAY}_${CATEGORY_NAME}`}
            placeholder={translate(CATEGORY_NAME)}
            defaultValue={value}
            error={!!error}
            message={error?.message || ""}
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              onChange(event.target.value)
            }
            additionalContainerClass="w-64"
          />
        )}
      />
      <div className="flex items-center self-start gap-8">
        <Controller
          name={`${HOLIDAY}.numberOfDaysOff`}
          control={control}
          rules={{
            validate: (value: string): boolean | string =>
              validateNumberOfDaysOff(value, watch, t),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAY}_${DAYS}`}
              type="number"
              placeholder={translate(DAYS)}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                onChange(event.target.value)
              }
              defaultValue={getValues(`${HOLIDAY}.${NO_LIMIT}`) ? "0" : value}
              disabled={watch(`${HOLIDAY}.${NO_LIMIT}`)}
              additionalContainerClass="w-24"
              error={!!error && !getValues(`${HOLIDAY}.${NO_LIMIT}`)}
              message={error?.message || ""}
            />
          )}
        />

        <Controller
          name={`${HOLIDAY}.${NO_LIMIT}`}
          render={({ field: { value, onChange } }) => (
            <div className="flex items-center self-start mt-1.5 gap-1">
              <Checkbox
                dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAY}_${NO_LIMIT}`}
                checked={value}
                onChecked={(isNoLimit: boolean): void => {
                  setValue(
                    `${HOLIDAY}.numberOfDaysOff`,
                    isNoLimit ? NO_LIMIT_THRESHOLD.toString() : "0",
                  );
                  onChange(isNoLimit);
                }}
              />
              <span>{translate(NO_LIMIT)}</span>
            </div>
          )}
        />
      </div>
      <Controller
        name={`${HOLIDAY}.payRatePercentage`}
        control={control}
        rules={validatePayRatePercentage(t)}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAY}_${PAYMENT_PERCENTAGE}`}
            defaultValue={value}
            type="number"
            placeholder="%"
            error={!!error}
            message={error?.message || ""}
            additionalContainerClass="w-24"
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              onChange(event.target.value)
            }
          />
        )}
      />
      <Controller
        name={`${HOLIDAY}.${PARTIAL_DAY_OFF}`}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAY}_${PARTIAL_DAY_OFF}`}
            checked={value}
            onChecked={onChange}
            additionalClassName="self-start mt-2"
          />
        )}
      />
    </>
  );
};

export default EditRow;
