import { ReactElement, ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  RestrictedWrapper,
  SearchSelect,
} from "@ps/ui-components";
import { prepareDictionarySearchSelect } from "@ps/utils";
import { useTranslationWithNamespace } from "../../../../hooks";
import { filterArchivedClients } from "../../../../shared";
import { getClientData } from "../../../../shared/utils/clients-utils";
import {
  ADD,
  ADD_NEW_PROSPECT,
  CLIENT_NAME,
  CREATE_NEW_CLIENT,
  DOMAINS,
  PROSPECT_NAME,
} from "../../../constants";
import { ClientModel } from "../../../../client-domain";
import { CLIENTS_MANAGE } from "../../../../shared/permissions";
import { ProspectContentProps } from "./types";
import { CLIENT } from "../constants";
import { ReactComponent as SuitCaseIcon } from "../../../../images/suitcase.svg";

const BASE_PATH = `${ADD_NEW_PROSPECT}_prospectData`;

const NEW_PROSPECT_NAME_ID = "new-prospect-name-id";
const NEW_PROSPECT_CLIENT_NAME_ID = "new-prospect-client_name-id";
const NEW_PROSPECT_CLIENT_MANAGE_ID = "new-prospect-client_manage-id";
const NEW_PROSPECT_DOMAIN_ID = "new-prospect-domain-id";

const ProspectContent = ({
  addNewClient,
  clients,
  data,
  setData,
  error,
  setError,
  defaultClientId,
  domainDict,
  currentID,
  setCurrentID,
}: ProspectContentProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const translate = (field: string, isRequired = true) =>
    `${t(`projects.prospect.details.${field}`)}${isRequired ? "*" : ""}`;

  useEffect(() => {
    if (currentID === "new-prospect-client_manage-id") {
      document.getElementById("new-prospect-client_manage-id")?.focus();
    }
  }, []);

  return (
    <div className="flex flex-col items-start gap-4 w-full py-8">
      <Label
        dataCy={`${BASE_PATH}_${PROSPECT_NAME}`}
        text={translate(PROSPECT_NAME)}
        labelFontWeight="font-bold"
        labelFontSize="text-lg"
      >
        <Input
          additionalClass=":placeholder: text-base"
          autoFocus={currentID === NEW_PROSPECT_NAME_ID || currentID === ""}
          dataCy={`${BASE_PATH}_${PROSPECT_NAME}`}
          defaultValue={data?.prospectName}
          error={error.prospectName}
          id={NEW_PROSPECT_NAME_ID}
          message={translate("isRequired", false)}
          placeholder={translate("prospectNamePlaceholder", false)}
          width="w-full"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setError((prev) => ({ ...prev, prospectName: false }));
            setData((prev) => ({ ...prev, prospectName: event.target.value }));
            setCurrentID("new-prospect-name-id");
          }}
        />
      </Label>
      <div className="mt-5 flex gap-x-6 items-top w-full">
        <Label
          dataCy={`${BASE_PATH}_${CLIENT_NAME}`}
          text={translate(CLIENT)}
          labelFontWeight="font-bold"
          labelFontSize="text-lg"
        >
          {defaultClientId ? (
            clients.find(
              (item: ClientModel): boolean => item.id === defaultClientId,
            )?.clientName
          ) : (
            <SearchSelect
              autoFocus={currentID === NEW_PROSPECT_CLIENT_NAME_ID}
              selectId={NEW_PROSPECT_CLIENT_NAME_ID}
              width="w-full"
              height="2.4rem"
              additionalClassName="-mt-0.5"
              dataCy={CLIENT_NAME}
              options={prepareDictionarySearchSelect(
                filterArchivedClients(clients),
                "id",
                "id",
                "clientName",
                "clientName",
              )}
              defaultValue={
                data.clientName
                  ? getClientData(data?.clientName, clients)
                  : null
              }
              onChange={(newClient) => {
                setError((prev) => ({ ...prev, clientName: false }));
                setData((prev) => ({ ...prev, clientName: newClient.id }));
                setCurrentID("new-prospect-client_name-id");
              }}
              placeholder={translate("clientPlaceholder", false)}
              error={error?.clientName}
              message={translate("isRequired", false)}
            />
          )}
        </Label>

        {!defaultClientId ? (
          <RestrictedWrapper permissions={CLIENTS_MANAGE}>
            <Button
              autoFocus={currentID === NEW_PROSPECT_CLIENT_MANAGE_ID}
              id={NEW_PROSPECT_CLIENT_MANAGE_ID}
              dataCy={ADD}
              type="button"
              additionalClass="mt-7 flex flex gap-x-4"
              width="w-full"
              onClick={() => {
                addNewClient();
                setCurrentID(NEW_PROSPECT_CLIENT_MANAGE_ID);
              }}
              variant="secondary"
            >
              <SuitCaseIcon />
              {`${t(`projects.prospect.actions.${CREATE_NEW_CLIENT}`)}`}
            </Button>
          </RestrictedWrapper>
        ) : (
          <></>
        )}
      </div>
      <div className="w-full flex justify-center my-5">
        <Label
          text={translate(DOMAINS)}
          dataCy={`${BASE_PATH}_${DOMAINS}`}
          labelFontWeight="font-bold"
          labelFontSize="text-lg"
        >
          <SearchSelect
            autoFocus={currentID === NEW_PROSPECT_DOMAIN_ID}
            multiple
            dataCy={`${BASE_PATH}_${DOMAINS}`}
            options={prepareDictionarySearchSelect(
              domainDict,
              "id",
              "id",
              "name",
              "name",
            )}
            width="w-full"
            height="auto"
            onChange={(domains) => {
              setError((prev) => ({ ...prev, domains: false }));
              setData((prev) => ({
                ...prev,
                domains,
              }));
              setCurrentID(NEW_PROSPECT_DOMAIN_ID);
            }}
            defaultValue={
              data.domains &&
              data.domains.length &&
              typeof data.domains[0] === "string"
                ? data.domains.map((item) => ({
                    id: item,
                    value: item,
                    label: domainDict?.find((domain) => domain.id === item)
                      ?.name,
                  }))
                : data.domains
            }
            error={error?.domains}
            message={translate("isRequired", false)}
            placeholder={translate(DOMAINS)}
          />
        </Label>
      </div>
    </div>
  );
};

export default ProspectContent;
