import { ReactElement } from "react";
import { TableHeader } from "@ps/ui-components";
import {
  HOLIDAYS,
  NAME,
  PROFESSIONS,
  TEMPLATE_NAME,
  HOLIDAYS_TEMPLATE,
  USED_MAIN_HOLIDAYS,
  STATUS,
} from "../constants";
import styles from "../styles.module.css";
import Label from "./label";
import { SortBarProps } from "./types";

const SortBar = ({
  holidaysUsers,
  filterOptions,
}: SortBarProps): ReactElement => (
  <TableHeader columnsStyles={styles.tableColumns} dataCy={HOLIDAYS}>
    <Label field={NAME} isSortable />
    <Label field={PROFESSIONS} />
    <Label
      field={TEMPLATE_NAME}
      nestedField={HOLIDAYS_TEMPLATE}
      isSortable
      filterOptions={filterOptions.templateFilterOptions}
      holidaysUsers={holidaysUsers}
    />
    <Label field={USED_MAIN_HOLIDAYS} isSortable />
    <Label field={STATUS} />
  </TableHeader>
);

export default SortBar;
