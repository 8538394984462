import { ReactElement } from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { DictionaryEntryModel } from "@ps/hh";
import { useTranslationWithNamespace } from "../../../hooks";
import { TechStackTagsListProps } from "../types";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  techStackContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  techStackText: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#2A2D33",
    margin: "15px 0",
  },
  techTag: {
    padding: "3px 8px",
    borderRadius: "6px",
    fontSize: "10px",
    marginRight: "5px",
    marginBottom: "5px",
  },
  blindTechTag: {
    backgroundColor: "#FDE7E7",
    border: "1px solid #B0312D",
    color: "#B0312D",
  },
  defaultTechTag: {
    backgroundColor: "#F1F3FF",
    border: "1px solid #5066D9",
    color: "#5066D9",
  },
});

const TechStackTagsList = ({
  stacks,
  isBlind,
}: TechStackTagsListProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const properStyle = isBlind ? styles.blindTechTag : styles.defaultTechTag;

  return (
    <View style={styles.container} wrap={false}>
      <Text style={styles.techStackText}>{t("resume.techStack")}</Text>
      <View style={styles.techStackContainer}>
        {stacks.map(
          (technology: DictionaryEntryModel): JSX.Element => (
            <Text
              key={technology.id}
              style={{ ...styles.techTag, ...properStyle }}
            >
              {technology.name}
            </Text>
          ),
        )}
      </View>
    </View>
  );
};

export default TechStackTagsList;
