import {
  ReactElement,
  useEffect,
  useState,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import { AnyAction } from "redux";
import { useDispatch } from "redux-react-hook";
import { uid } from "react-uid";
import { classJoin, removeDiacriticsFromString } from "@ps/utils";
import { Checkbox, Input, TableLabel, CircleButton } from "@ps/ui-components";
import { useMappedStateSelector } from "../../../hooks";
import { setSorterByProperty, filterTeamUsersByProperty } from "../../store";
import { TEAM_LIST } from "../../../shared/data-cy";
import { focusVisibleStyles, Keys } from "../../../shared";
import { NAME } from "../../constants";
import {
  HeaderLabelProps,
  IOption,
  HeaderLabelMapState,
  HeaderLabelMapStateReturn,
  IProfileReturn,
} from "./types";
import styles from "./styles.module.css";

const DROPDOWN_ID = "filter-dropdown";

const HeaderLabel = ({
  canSort = false,
  children,
  filterOptions,
  name,
  withSearch,
  profiles,
}: HeaderLabelProps): ReactElement => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>("");
  const [options, setOptions] = useState<IOption[] | undefined>(filterOptions);
  const [showFilterOptions, setShowFilterOptions] = useState<boolean>(false);

  const handleOnSearch = (newValue: string): void => {
    setSearchValue(newValue);

    setOptions(
      newValue?.length
        ? filterOptions?.filter((item: IOption): boolean =>
            removeDiacriticsFromString(item.name.toLowerCase()).includes(
              removeDiacriticsFromString(newValue.toLowerCase()),
            ),
          )
        : filterOptions,
    );
  };

  useEffect(() => {
    setOptions(filterOptions);
  }, [filterOptions]);

  useEffect(() => {
    if (showFilterOptions) document.getElementById(DROPDOWN_ID)?.focus();
  }, [showFilterOptions]);

  const mapState = (state: HeaderLabelMapState): HeaderLabelMapStateReturn => ({
    activeSorter: state.teamUsers.sorter,
    filters: state.teamUsers.filters,
  });

  const { activeSorter, filters } = useMappedStateSelector(mapState);

  const handleOnClickSorter = (): AnyAction =>
    setSorterByProperty(
      dispatch,
      activeSorter.order === "asc" && activeSorter.name === name
        ? "desc"
        : "asc",
      name,
    );

  const countProfilesWithSpecificOption = (optionId: string): number => {
    const filteredProfiles: IProfileReturn[] = profiles?.filter(
      (profile: IProfileReturn): boolean => profile[name] === optionId,
    );
    return filteredProfiles?.length;
  };

  return (
    <TableLabel
      dataCy={`${TEAM_LIST}_${name}`}
      sorting={
        canSort
          ? {
              byName: name,
              onClick: handleOnClickSorter,
              isActive: activeSorter.name === name,
            }
          : undefined
      }
      filtering={
        filterOptions?.length
          ? {
              checked: filters?.[name]?.map(
                (item: string): string =>
                  options?.find(
                    (option: IOption): boolean => option?.id === item,
                  )?.name || item,
              ),
              dropdownOverlay: (
                <div
                  className="p-4 flex flex-col gap-4 focus:outline-none"
                  tabIndex={-1}
                  id={DROPDOWN_ID}
                >
                  {withSearch && (
                    <Input
                      autoFocus
                      isSearch
                      dataCy={TEAM_LIST}
                      defaultValue={searchValue}
                      onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                        handleOnSearch(event.target.value?.toLowerCase())
                      }
                      // eslint-disable-next-line
                      // @ts-ignore
                      onKeyDown={(
                        event: KeyboardEvent<HTMLInputElement>,
                      ): void => {
                        if (event.key === Keys.ESC) setShowFilterOptions(false);
                      }}
                    />
                  )}
                  <div
                    className={classJoin(
                      "flex flex-col gap-4 h-auto overflow-y-auto",
                      styles.filterDropdownContent,
                    )}
                  >
                    {options?.map((item: IOption): ReactElement => {
                      const isChecked: boolean = filters?.[name]?.includes(
                        item?.id,
                      );

                      return (
                        <div
                          className={classJoin(
                            "flex items-center justify-between gap-2 rounded-md focus:outline-none capitalize",
                            focusVisibleStyles,
                          )}
                          key={uid(item)}
                          onClick={(): AnyAction =>
                            filterTeamUsersByProperty(
                              dispatch,
                              name,
                              item.id,
                              isChecked,
                            )
                          }
                          role="button"
                          tabIndex={-1}
                          onKeyDown={(
                            event: KeyboardEvent<HTMLDivElement>,
                          ): AnyAction | void => {
                            if (event?.code === Keys.SPACE) {
                              event.preventDefault();
                              filterTeamUsersByProperty(
                                dispatch,
                                name,
                                item.id,
                                isChecked,
                              );
                            }
                            if (event.key === Keys.ESC)
                              setShowFilterOptions(false);
                          }}
                        >
                          <div className="flex items-center gap-2">
                            <Checkbox
                              dataCy={`${TEAM_LIST}_${item.id}`}
                              checked={isChecked}
                            />
                            {item.name}
                          </div>
                          {name !== NAME ? (
                            <CircleButton
                              activeNumber={countProfilesWithSpecificOption(
                                item.id,
                              )}
                              width="w-6"
                              height="w-6"
                              textSize="text-sm font-semibold"
                              nonActiveStyles="border-primary-80 text-primary-50 bg-secondary-95"
                              dataCy={TEAM_LIST}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ),
            }
          : undefined
      }
    >
      {children}
    </TableLabel>
  );
};

export default HeaderLabel;
