import { ReactComponent as SettingsTab } from "../../images/tabs/matrixSettings.svg";
import { ReactComponent as EditTab } from "../../images/tabs/editMatrix.svg";
import { ReactComponent as OverviewTab } from "../../images/tabs/overviewMatrix.svg";

export const MATRIX_TABS = [
  {
    item: "SETTINGS",
    icon: SettingsTab,
  },
  {
    item: "EDIT_MATRIX",
    icon: EditTab,
  },
  {
    item: "OVERVIEW",
    icon: OverviewTab,
  },
];

export const DOMAINS_PROFESSION_ID = "domains";
export const FULFILLMENT_PANEL_ID = "fulfillment";

export const HIDDEN_DOMAINS = ["non_specified"];

export const KNOWLEDGE_SCALE = "knowledgeScale";
export const ENJOYMENT_SCALE = "enjoymentScale";

export const SKILLS_MATRIX_MODES = {
  USER_EDIT: "userEdit",
  VIEW: "view",
};
