import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { uid } from "react-uid";
import { ScaleTile } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../../../hooks";
import { SKILLS_MATRIX_SPACE_FILLER } from "../../../../../../../shared/data-cy";
import { TechnologiesTableHeaderProps } from "../types";
import styles from "./styles.module.css";

const BASE_TRANSLATION = "profile.skills.legend";
const cellBaseStyle =
  "absolute top-1 left-0 w-full flex flex-row items-center justify-between px-3";

const TechnologiesTableHeader = ({
  tableType,
}: TechnologiesTableHeaderProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="relative bg-neutralPrimary-85 w-full rounded-md z-10">
      <div
        className={classJoin(
          `bg-primary-50 bg-opacity-30 w-full z-10 rounded-md
          border border-neutralSecondary-60 text-neutralPrimary-30`,
          styles.tableRowContainer,
        )}
      >
        <span className="w-4/5 truncate px-3 py-1 flex-row items-center justify-between">
          {t(`${BASE_TRANSLATION}.${tableType}`)}
        </span>

        <div className="relative min-w-min px-4 py-1 border-l border-neutralSecondary-60">
          <span className={cellBaseStyle}>
            {t(`${BASE_TRANSLATION}.difficulties`)}
          </span>
          <div className="flex flex-row gap-1 invisible">
            {[...Array(8)].map((_, tileIndex) => (
              <ScaleTile
                dataCy={`${SKILLS_MATRIX_SPACE_FILLER}_scale_tiles`}
                key={uid(tileIndex)}
              >
                <span className="text-neutralSecondary-60">
                  {tileIndex + 1}
                </span>
              </ScaleTile>
            ))}
          </div>
        </div>
        <div className="relative gap-1 px-4 min-w-min py-1">
          <span className={cellBaseStyle}>
            <span />
          </span>
        </div>
      </div>
    </div>
  );
};

export default TechnologiesTableHeader;
