import { KeyboardEvent, ReactElement, useEffect, useState } from "react";
import { classJoin } from "@ps/utils";
import {
  FilterDropdown,
  TableLabel,
  SimpleFilterOption,
} from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../hooks";
import { FilterItem, SortBarProps } from "./types";
import {
  getCheckedNames,
  prepareClientFilterOptions,
  prepareUserFilterOptions,
  selectFilterOptions,
} from "../../utils";
import {
  BD,
  BD_NAME,
  BUSINESS_DEVELOPER,
  PROJECT_MANAGER,
  CLIENT_NAME,
  CREATION_DATE,
  DAYS_PASSED,
  PM,
  PM_NAME,
  PROSPECT_NAME,
  STATUS,
} from "./constants";
import styles from "./styles.module.css";
import { Keys } from "../../../shared";
import { PROSPECTS_LIST } from "../../../shared/data-cy";

const DROPDOWN_ID = "filter-dropdown";

const SortBar = ({
  clientId,
  prospects,
  filters,
  updateFilters,
}: SortBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const translate = (field: string): string =>
    t(`projects.prospect.list.${field}`);
  const [searchValue, setSearchValue] = useState("");
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [currentIDFocused, setCurrentIDFocused] = useState("");

  useEffect(() => {
    if (showFilterOptions) document.getElementById(DROPDOWN_ID)?.focus();
  }, [showFilterOptions]);

  const clientFiltersOptions: FilterItem[] =
    prepareClientFilterOptions(prospects) || [];

  const PMFiltersOptions: FilterItem[] =
    prepareUserFilterOptions(prospects, PM) || [];

  const BDFiltersOptions: FilterItem[] =
    prepareUserFilterOptions(prospects, BD) || [];

  const renderLabel = (
    fieldName: string,
    translationName?: string,
    filterOptions: undefined | FilterItem[] = undefined,
    filterName?: string | undefined,
  ): ReactElement => (
    <TableLabel
      dataCy={translationName || fieldName}
      setSearchValue={setSearchValue}
      filtering={
        filterOptions
          ? {
              checked: getCheckedNames(filters, filterName || fieldName),
              dropdownOverlay: (
                <FilterDropdown
                  dataCy={`${PROSPECTS_LIST}-${filterName}`}
                  DROPDOWN_ID={DROPDOWN_ID}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setShowFilterOptions={setShowFilterOptions}
                  filterOptions={filterOptions}
                >
                  {filterOptions.map(
                    (item: { id: string; name: string }, index: number) => {
                      const isChecked = filters[filterName || fieldName]?.some(
                        ({ id }: FilterItem) => id === item.id,
                      );
                      const autoFocusId = `${filterName}-${index}`;
                      return (
                        <SimpleFilterOption
                          key={item.id}
                          autoFocus={autoFocusId === currentIDFocused}
                          item={item}
                          isChecked={isChecked}
                          onKeyDown={(
                            event: KeyboardEvent<HTMLButtonElement>,
                            clickedIsChecked: boolean,
                            currentItem: FilterItem,
                          ): void => {
                            if (event.code === Keys.SPACE) {
                              event.preventDefault();
                              updateFilters(
                                clickedIsChecked,
                                currentItem,
                                filterName || fieldName,
                              );
                              setCurrentIDFocused(autoFocusId);
                            }
                          }}
                          onClick={(
                            clickedIsChecked: boolean,
                            currentItem: FilterItem,
                          ) => {
                            updateFilters(
                              clickedIsChecked,
                              currentItem,
                              filterName || fieldName,
                            );
                            setCurrentIDFocused(autoFocusId);
                          }}
                        />
                      );
                    },
                  )}
                </FilterDropdown>
              ),
            }
          : undefined
      }
    >
      {translate(translationName || fieldName)}
    </TableLabel>
  );

  return (
    <div
      className={classJoin(
        styles.gridContainer,
        "2xl:gap-4 gap-2 bg-neutralSecondary-41 bg-opacity-10 rounded-t-2xl h-16 items-center",
        clientId ? "" : "mt-6",
      )}
    >
      {renderLabel(PROSPECT_NAME)}
      {renderLabel(
        CLIENT_NAME,
        undefined,
        selectFilterOptions(clientFiltersOptions, searchValue),
        CLIENT_NAME,
      )}
      {renderLabel(
        PROJECT_MANAGER,
        PM_NAME,
        selectFilterOptions(PMFiltersOptions, searchValue),
        PM,
      )}
      {renderLabel(
        BUSINESS_DEVELOPER,
        BD_NAME,
        selectFilterOptions(BDFiltersOptions, searchValue),
        BD,
      )}
      {renderLabel(CREATION_DATE, CREATION_DATE)}
      <TableLabel dataCy={DAYS_PASSED}>{translate(DAYS_PASSED)}</TableLabel>
      <TableLabel dataCy={STATUS}>{translate(STATUS)}</TableLabel>
      <span className="invisible" />
    </div>
  );
};

export default SortBar;
