/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Query API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface GetUsersWithFulfillmentsForSkillsAndProfession
 */
export interface GetUsersWithFulfillmentsForSkillsAndProfession {
    /**
     * 
     * @type {Array<SkillsAndProfessionsTemplate>}
     * @memberof GetUsersWithFulfillmentsForSkillsAndProfession
     */
    'template': Array<SkillsAndProfessionsTemplate>;
    /**
     * 
     * @type {Array<SkillsAndProfessionsFulfillment>}
     * @memberof GetUsersWithFulfillmentsForSkillsAndProfession
     */
    'profiles': Array<SkillsAndProfessionsFulfillment>;
}
/**
 * Score for knowledge and enjoyment for a given technology profession, values should be compatible with the scale given by the SkillMatrixTemplate
 * @export
 * @interface ProfessionRatingSimplified
 */
export interface ProfessionRatingSimplified {
    /**
     * 
     * @type {string}
     * @memberof ProfessionRatingSimplified
     */
    'profId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfessionRatingSimplified
     */
    'k'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfessionRatingSimplified
     */
    'e'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Requiredness = {
    MustHave: 'mustHave',
    NiceToHave: 'niceToHave'
} as const;

export type Requiredness = typeof Requiredness[keyof typeof Requiredness];


/**
 * 
 * @export
 * @interface SkillsAndProfessionsFulfillment
 */
export interface SkillsAndProfessionsFulfillment {
    /**
     * 
     * @type {string}
     * @memberof SkillsAndProfessionsFulfillment
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof SkillsAndProfessionsFulfillment
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SkillsAndProfessionsFulfillment
     */
    'lastName'?: string;
    /**
     * 
     * @type {Array<Technology>}
     * @memberof SkillsAndProfessionsFulfillment
     */
    'techs': Array<Technology>;
}
/**
 * 
 * @export
 * @interface SkillsAndProfessionsTemplate
 */
export interface SkillsAndProfessionsTemplate {
    /**
     * 
     * @type {string}
     * @memberof SkillsAndProfessionsTemplate
     */
    'technologyId': string;
    /**
     * 
     * @type {Requiredness}
     * @memberof SkillsAndProfessionsTemplate
     */
    'requiredness': Requiredness;
    /**
     * 
     * @type {Array<string>}
     * @memberof SkillsAndProfessionsTemplate
     */
    'otherProfessions': Array<string>;
}
/**
 * 
 * @export
 * @interface Technology
 */
export interface Technology {
    /**
     * 
     * @type {string}
     * @memberof Technology
     */
    'techId'?: string;
    /**
     * Score for knowledge for a given technology
     * @type {number}
     * @memberof Technology
     */
    'k'?: number;
    /**
     * Score for enjoyment for a given technology
     * @type {number}
     * @memberof Technology
     */
    'e'?: number;
    /**
     * Other professions with scores for a given technology
     * @type {Array<ProfessionRatingSimplified>}
     * @memberof Technology
     */
    'othProf': Array<ProfessionRatingSimplified>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch best candidate users with matching profession and skills
         * @param {string} professionId ProfessionId of the user
         * @param {string} mustHave List of must have skills separated with a comma
         * @param {string} [niceToHave] List of nice to have skills separated with a comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersWithFulfillmentsForSkillsAndProfession: async (professionId: string, mustHave: string, niceToHave?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'professionId' is not null or undefined
            assertParamExists('getUsersWithFulfillmentsForSkillsAndProfession', 'professionId', professionId)
            // verify required parameter 'mustHave' is not null or undefined
            assertParamExists('getUsersWithFulfillmentsForSkillsAndProfession', 'mustHave', mustHave)
            const localVarPath = `/query/users_by_skills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (professionId !== undefined) {
                localVarQueryParameter['professionId'] = professionId;
            }

            if (mustHave !== undefined) {
                localVarQueryParameter['must_have'] = mustHave;
            }

            if (niceToHave !== undefined) {
                localVarQueryParameter['nice_to_have'] = niceToHave;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch best candidate users with matching profession and skills
         * @param {string} professionId ProfessionId of the user
         * @param {string} mustHave List of must have skills separated with a comma
         * @param {string} [niceToHave] List of nice to have skills separated with a comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersWithFulfillmentsForSkillsAndProfession(professionId: string, mustHave: string, niceToHave?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersWithFulfillmentsForSkillsAndProfession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersWithFulfillmentsForSkillsAndProfession(professionId, mustHave, niceToHave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Fetch best candidate users with matching profession and skills
         * @param {string} professionId ProfessionId of the user
         * @param {string} mustHave List of must have skills separated with a comma
         * @param {string} [niceToHave] List of nice to have skills separated with a comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersWithFulfillmentsForSkillsAndProfession(professionId: string, mustHave: string, niceToHave?: string, options?: any): AxiosPromise<GetUsersWithFulfillmentsForSkillsAndProfession> {
            return localVarFp.getUsersWithFulfillmentsForSkillsAndProfession(professionId, mustHave, niceToHave, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Fetch best candidate users with matching profession and skills
     * @param {string} professionId ProfessionId of the user
     * @param {string} mustHave List of must have skills separated with a comma
     * @param {string} [niceToHave] List of nice to have skills separated with a comma
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUsersWithFulfillmentsForSkillsAndProfession(professionId: string, mustHave: string, niceToHave?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUsersWithFulfillmentsForSkillsAndProfession(professionId, mustHave, niceToHave, options).then((request) => request(this.axios, this.basePath));
    }
}


