enum ActionTypes {
  FETCH_BENCH_USERS_START = "FETCH_BENCH_USERS_START",
  FETCH_BENCH_USERS_SUCCESS = "FETCH_BENCH_USERS_SUCCESS",
  FETCH_BENCH_USERS_ERROR = "FETCH_BENCH_USERS_ERROR",

  ADD_BENCH_USERS_FILTER = "ADD_BENCH_USERS_FILTER",
  REMOVE_BENCH_USERS_FILTER = "REMOVE_BENCH_USERS_FILTER",
  RESET_BENCH_USERS_FILTERS = "RESET_BENCH_USERS_FILTERS",

  SET_BENCH_USERS_SORTER = "SET_BENCH_USERS_SORTER",

  ADD_OPENED_FOLDER_STATUS = "ADD_OPENED_FOLDER_STATUS",
  REMOVE_OPENED_FOLDER_STATUS = "REMOVE_OPENED_FOLDER_STATUS",
  RESET_OPENED_FOLDER_STATUSES = "RESET_FOLDER_STATUSES",
  SET_BENCH_USER_ID = "SET_BENCH_USER_ID",
  RESET_BENCH_USER_ID = "RESET_BENCH_USER_ID",
}

export default ActionTypes;
