import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import Loader from "../loader";
import { ReactComponent as Icon } from "../../images/person-loading-icon.svg";
import { useTranslationWithNamespace } from "../../hooks";
import { LoadingMainPageProps } from "./types";

const iconStyles = "w-64 h-64 lg:w-full lg:h-full";

const LoadingMainPage = ({
  additionalClassName,
}: LoadingMainPageProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div
      className={classJoin(
        "h-full overflow-auto flex flex-col justify-center items-center gap-y-5",
        additionalClassName,
      )}
    >
      <div className="relative mb-10">
        <Icon className={iconStyles} />
        <Loader
          additionalClassName="mb-10 absolute top-24 lg:top-28 left-8 lg:left-16"
          dotHeight="h-2 lg:h-6"
          dotWidth="w-2 lg:w-6"
        />
      </div>
      <span className="text-xl lg:text-3xl px-5 lg:p-0 font-bold text-neutralPrimary-20 text-center lg:text-justify">
        {t("loadingPage.thanks")}
      </span>
      <span className="text-lg lg:text-2xl px-5 lg:p-0 font-semibold text-neutralPrimary-30">
        {t("loadingPage.please")}
      </span>
    </div>
  );
};

export default LoadingMainPage;
