import { removeDiacriticsFromString } from "@ps/utils";

export const selectBenchFilterOptions = (
  data: string[],
  searchValue: string,
): string[] => {
  if (!searchValue) return data;
  return data.filter((item: string) =>
    removeDiacriticsFromString(item?.toLowerCase()).includes(
      removeDiacriticsFromString(searchValue.toLowerCase()),
    ),
  );
};
