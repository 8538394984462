import { ReactElement, KeyboardEvent } from "react";
import { isDayjs } from "dayjs";
import { RestrictedActionWrapper } from "@ps/ui-components";
import { ReactComponent as EditIcon } from "../../../../../images/prospect/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../../images/prospect/trash.svg";
import { accesses, Keys } from "../../../../../shared";
import { ActiveNoteProps } from "./types";
import ConfirmRemoveModal from "../edit/confirmRemoveModal";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { EDIT_NOTE, DELETE_NOTE } from "../../../../shared/data-cy";
import { NOTE, NOTES, PROSPECT_EDIT } from "../../../../constants";

const ActiveNote = ({
  activeNote,
  onEdit,
  onRemove,
  isRemoving,
  onRemoveCancel,
  prepareToRemove,
  PriorityIcon,
  archived,
}: ActiveNoteProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <>
      <div className="flex">
        <span
          className="text-center w-full"
          data-cy={`${PROSPECT_EDIT}-${NOTES}-active_date`}
        >
          {isDayjs(activeNote.note?.createDate)
            ? activeNote.note?.createDate.format("DD.MM.YYYY")
            : ""}
        </span>
        {!archived && (
          <RestrictedActionWrapper
            accesses={[accesses.UPDATE_NOTE, accesses.DELETE_NOTE]}
            actionAccessesList={activeNote.note?.accesses}
          >
            <div className="flex h-10 gap-4">
              <div
                data-cy={EDIT_NOTE}
                className="border rounded-lg h-10 w-10 flex p-2 cursor-pointer"
                tabIndex={0}
                role="button"
                onClick={onEdit}
                onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === Keys.ENTER) {
                    onEdit();
                  }
                }}
              >
                <EditIcon className="text-neutralSecondary-41 hover:text-neutralSecondary-50" />
              </div>
              <div
                data-cy={DELETE_NOTE}
                className="border rounded-lg h-10 w-10 p-2 cursor-pointer"
                tabIndex={0}
                role="button"
                onClick={prepareToRemove}
                onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === Keys.ENTER) {
                    prepareToRemove();
                  }
                }}
              >
                <DeleteIcon className="text-neutralSecondary-41 hover:text-neutralSecondary-50" />
              </div>
            </div>
          </RestrictedActionWrapper>
        )}
      </div>
      <div className="flex gap-x-4 items-center">
        <h3
          data-cy={`${NOTE}_title`}
          className="font-bold text-lg py-6 text-neutralPrimary-20"
        >
          {activeNote.note?.title}
        </h3>
        <div className="h-5 w-5">{PriorityIcon}</div>
      </div>
      <div data-cy={`${NOTE}_text`} className="text-neutralPrimary-40 mb-10">
        {activeNote.note?.description}
      </div>
      <ConfirmRemoveModal
        isOpen={isRemoving}
        onConfirm={onRemove}
        onClose={onRemoveCancel}
        header={t("projects.prospect.notes.doYouWantToRemoveNote")}
        editSection={NOTES}
      />
    </>
  );
};

export default ActiveNote;
