import React from "react";

const SectionFormContext = React.createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updatePercent: (updatePercent: number): void => {
    // do nothing
  },
});

export default SectionFormContext;
