import { ReactElement } from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { SkillType } from "../types";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "8px",
    width: "100px",
  },
  progressBarContainer: {
    width: "80px",
    height: "6.65px",
    backgroundColor: "#F0F2F5",
    borderRadius: "20px",
    marginBottom: "9px",
  },
  skillName: {
    fontSize: "8px",
    marginBottom: "4px",
  },
  legend: {
    fontSize: "10px",
    marginTop: "4px",
    marginLeft: "8px",
  },
});

const SkillComponent = ({ name, score }: SkillType): ReactElement => (
  <View style={styles.container}>
    <View>
      <Text style={styles.skillName}>{name}</Text>
      <View style={styles.progressBarContainer}>
        {/* #TODO 5 is max score */}
        <View
          style={{
            width: `${score * 20}%`,
            height: "100%",
            backgroundColor: "#7788E3",
            borderRadius: "20px",
          }}
        />
      </View>
    </View>
    <Text style={styles.legend}>{`${score}/5`}</Text>
  </View>
);

export default SkillComponent;
