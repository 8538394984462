import { ReactElement } from "react";
import { CustomLabelProps } from "./types";

const CustomLabel = ({
  children,
  label,
  dataCy,
  dark,
}: CustomLabelProps): ReactElement => {
  const isDark = dark ? "text-neutralPrimary-20" : "text-neutralPrimary-30";

  return (
    <div
      data-cy={dataCy}
      className="grid grid-flow-row grid-cols-2 items-center"
    >
      <div className={`${isDark} font-bold`}>{label}</div>
      <div className="text-neutralPrimary-20 w-full">{children}</div>
    </div>
  );
};

export default CustomLabel;
