import { classJoin } from "@ps/utils";

export const AmountDot = ({ amount, isEmpty = false }) => {
  const commonStyles = "rounded-full h-8 w-8 flex items-center justify-center";
  const detailedStyles = isEmpty
    ? "border border-primary-50 bg-neutralPrimary-100"
    : "bg-primary-50 border-none text-primary-100";
  return (
    <div className={classJoin(commonStyles, detailedStyles)}>{amount}</div>
  );
};

export default AmountDot;
