/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Bench API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const Acceptance = {
    Rejected: 'rejected',
    InProgress: 'inProgress',
    Approved: 'approved'
} as const;

export type Acceptance = typeof Acceptance[keyof typeof Acceptance];


/**
 * 
 * @export
 * @enum {string}
 */

export const BenchProjectFeedback = {
    UnhappyDev: 'unhappyDev',
    LowProfitability: 'lowProfitability',
    RiskOfClosure: 'riskOfClosure',
    OtherReasons: 'otherReasons'
} as const;

export type BenchProjectFeedback = typeof BenchProjectFeedback[keyof typeof BenchProjectFeedback];


/**
 * 
 * @export
 * @interface BenchUser
 */
export interface BenchUser {
    /**
     * 
     * @type {string}
     * @memberof BenchUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BenchUser
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BenchUser
     */
    'availability'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchUser
     */
    'putOnBench'?: string;
    /**
     * 
     * @type {BenchUserStatus}
     * @memberof BenchUser
     */
    'status': BenchUserStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof BenchUser
     */
    'professions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BenchUser
     */
    'projectRoles': Array<string>;
    /**
     * 
     * @type {{ [key: string]: BenchUserProjectDetails; }}
     * @memberof BenchUser
     */
    'projects'?: { [key: string]: BenchUserProjectDetails; };
    /**
     * 
     * @type {{ [key: string]: BenchUserFolderDetails; }}
     * @memberof BenchUser
     */
    'folders': { [key: string]: BenchUserFolderDetails; };
    /**
     * 
     * @type {string}
     * @memberof BenchUser
     */
    'seniority'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchUser
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof BenchUser
     */
    'daysOnBench': number;
    /**
     * 
     * @type {number}
     * @memberof BenchUser
     */
    'totalDaysOnBenchLastYear'?: number;
}
/**
 * 
 * @export
 * @interface BenchUserFolderDetails
 */
export interface BenchUserFolderDetails {
    /**
     * 
     * @type {string}
     * @memberof BenchUserFolderDetails
     */
    'folderName': string;
    /**
     * 
     * @type {string}
     * @memberof BenchUserFolderDetails
     */
    'notes'?: string;
    /**
     * 
     * @type {Acceptance}
     * @memberof BenchUserFolderDetails
     */
    'status': Acceptance;
}
/**
 * 
 * @export
 * @interface BenchUserProjectDetails
 */
export interface BenchUserProjectDetails {
    /**
     * 
     * @type {string}
     * @memberof BenchUserProjectDetails
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BenchUserProjectDetails
     */
    'endDate'?: string;
    /**
     * 
     * @type {BenchProjectFeedback}
     * @memberof BenchUserProjectDetails
     */
    'feedback'?: BenchProjectFeedback;
    /**
     * 
     * @type {string}
     * @memberof BenchUserProjectDetails
     */
    'engagement'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchUserProjectDetails
     */
    'projectName': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BenchUserStatus = {
    OnProject: 'onProject',
    WithoutProject: 'withoutProject',
    ExceptOnProject: 'exceptOnProject',
    UnhappyDev: 'unhappyDev',
    LowProfitability: 'lowProfitability',
    RiskOfClosure: 'riskOfClosure',
    OtherReasons: 'otherReasons'
} as const;

export type BenchUserStatus = typeof BenchUserStatus[keyof typeof BenchUserStatus];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateBenchUserFolder
 */
export interface UpdateBenchUserFolder {
    /**
     * The notes what is added for the benchUser
     * @type {string}
     * @memberof UpdateBenchUserFolder
     */
    'notes': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns non-paginated list of users on bench
         * @param {BenchUserStatus} [status] Returns non-paginated list of users on bench without a project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (status?: BenchUserStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bench/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the bench users folder attributes
         * @param {string} benchUserId 
         * @param {string} folderId 
         * @param {UpdateBenchUserFolder} updateBenchUserFolder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenchUserFolder: async (benchUserId: string, folderId: string, updateBenchUserFolder: UpdateBenchUserFolder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'benchUserId' is not null or undefined
            assertParamExists('updateBenchUserFolder', 'benchUserId', benchUserId)
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('updateBenchUserFolder', 'folderId', folderId)
            // verify required parameter 'updateBenchUserFolder' is not null or undefined
            assertParamExists('updateBenchUserFolder', 'updateBenchUserFolder', updateBenchUserFolder)
            const localVarPath = `/bench/users/{benchUserId}/folders/{folderId}`
                .replace(`{${"benchUserId"}}`, encodeURIComponent(String(benchUserId)))
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBenchUserFolder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns non-paginated list of users on bench
         * @param {BenchUserStatus} [status] Returns non-paginated list of users on bench without a project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(status?: BenchUserStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BenchUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the bench users folder attributes
         * @param {string} benchUserId 
         * @param {string} folderId 
         * @param {UpdateBenchUserFolder} updateBenchUserFolder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBenchUserFolder(benchUserId: string, folderId: string, updateBenchUserFolder: UpdateBenchUserFolder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenchUserFolder(benchUserId, folderId, updateBenchUserFolder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Returns non-paginated list of users on bench
         * @param {BenchUserStatus} [status] Returns non-paginated list of users on bench without a project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(status?: BenchUserStatus, options?: any): AxiosPromise<Array<BenchUser>> {
            return localVarFp.getUsers(status, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the bench users folder attributes
         * @param {string} benchUserId 
         * @param {string} folderId 
         * @param {UpdateBenchUserFolder} updateBenchUserFolder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenchUserFolder(benchUserId: string, folderId: string, updateBenchUserFolder: UpdateBenchUserFolder, options?: any): AxiosPromise<void> {
            return localVarFp.updateBenchUserFolder(benchUserId, folderId, updateBenchUserFolder, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Returns non-paginated list of users on bench
     * @param {BenchUserStatus} [status] Returns non-paginated list of users on bench without a project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUsers(status?: BenchUserStatus, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUsers(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the bench users folder attributes
     * @param {string} benchUserId 
     * @param {string} folderId 
     * @param {UpdateBenchUserFolder} updateBenchUserFolder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateBenchUserFolder(benchUserId: string, folderId: string, updateBenchUserFolder: UpdateBenchUserFolder, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateBenchUserFolder(benchUserId, folderId, updateBenchUserFolder, options).then((request) => request(this.axios, this.basePath));
    }
}


