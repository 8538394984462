import { AnyAction } from "redux";
import { ActionTypes } from "./actionTypes";

export const baseRoutePath = (state = "", action: AnyAction): string => {
  switch (action.type) {
    default:
      return state;
  }
};

export const editModeReducer = (state = "", action: AnyAction): string => {
  switch (action.type) {
    case ActionTypes.TOGGLE_EDIT_MODE:
      return action.payload || "";
    default:
      return state;
  }
};
