import { AnyAction, Dispatch } from "redux";
import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import ActionTypes from "./actionTypes";
import { ProjectService } from "../services";
import { DictionaryModel } from "../../dictionaries";
import { IAction } from "./types";

export const fetchProjectStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROJECT_START);
export const fetchProjectSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROJECT_SUCCESS, payload);
export const fetchProjectError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROJECT_ERROR, payload);

export const fetchProject = async (
  id: string,
  dispatch: Dispatch<AnyAction>,
  technologyDict: DictionaryModel,
): Promise<AnyAction | void> => {
  dispatch(fetchProjectStart());
  const response = await ProjectService.getProject(id, technologyDict);
  if (isAPIError(response)) dispatch(fetchProjectError(response));
  else dispatch(fetchProjectSuccess(response));
};

export const fetchProjectsStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROJECTS_START);
export const fetchProjectsSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROJECTS_SUCCESS, payload);
export const fetchProjectsError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROJECTS_ERROR, payload);

export const fetchProjectsList = async (
  dispatch: Dispatch,
  clientId?: string,
): Promise<AnyAction | void> => {
  dispatch(fetchProjectsStart());
  const response = await ProjectService.getProjects(clientId);
  if (isAPIError(response)) dispatch(fetchProjectsError(response));
  else dispatch(fetchProjectsSuccess(response));
};

export const fetchMyProjectsStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_MY_PROJECTS_START);
export const fetchMyProjectsSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_MY_PROJECTS_SUCCESS, payload);
export const fetchMyProjectsError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_MY_PROJECTS_ERROR, payload);

export const fetchMyProjects = async (
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchMyProjectsStart());
  const response = await ProjectService.fetchMyProjects();
  if (isAPIError(response)) dispatch(fetchMyProjectsError(response));
  else dispatch(fetchMyProjectsSuccess(response));
};
