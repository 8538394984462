import { ChangeEvent, ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "@ps/ui-components";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../../hooks";
import { Keys } from "../../../../shared";
import { HolidaysTemplateModel } from "../../../models";
import {
  BASE_TRANSLATION_PATH,
  HOLIDAYS_TEMPLATES,
  TEMPLATES_LIST,
  TEMPLATE,
  ERRORS,
} from "../../constants";
import {
  TemplateRowMapState,
  TemplateRowMapStateReturn,
  TemplateRowProps,
} from "./types";

const TemplateRow = ({ template }: TemplateRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { control, getValues } = useFormContext();

  const mapState = (state: TemplateRowMapState): TemplateRowMapStateReturn => ({
    editMode: state?.editMode,
    holidaysTemplates: state?.holidaysTemplates?.holidaysTemplates || [],
  });

  const { editMode, holidaysTemplates } = useMappedStateSelector(mapState);

  const validateTemplateName = (
    currentTemplate: HolidaysTemplateModel,
    value: string,
  ): boolean | string => {
    if (editMode && editMode.split(":")[0] !== TEMPLATE) return true;
    if (
      holidaysTemplates.some(
        (holidayTemplate: HolidaysTemplateModel): boolean =>
          holidayTemplate.id !== currentTemplate.id &&
          value.trim().toLowerCase() ===
            holidayTemplate.name.trim().toLowerCase(),
      )
    )
      return t(`${BASE_TRANSLATION_PATH}.${TEMPLATES_LIST}.${ERRORS}.isUnique`);
    if (!value.trim().length)
      return `${t(
        `${BASE_TRANSLATION_PATH}.${TEMPLATES_LIST}.${ERRORS}.isRequired`,
      )}`;
    return true;
  };

  return (
    <Controller
      name="template.name"
      control={control}
      rules={{
        required: {
          value: true,
          message: `${t(
            `${BASE_TRANSLATION_PATH}.${TEMPLATES_LIST}.${ERRORS}.isRequired`,
          )}`,
        },
        validate: (value: string) =>
          validateTemplateName(getValues(TEMPLATE), value),
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) =>
        editMode === `${TEMPLATE}:${template.id}` ? (
          <Input
            autoFocus
            dataCy={`${HOLIDAYS_TEMPLATES}_${TEMPLATE}`}
            placeholder={t(
              `${BASE_TRANSLATION_PATH}.${TEMPLATES_LIST}.templateName`,
            )}
            defaultValue={value}
            error={!!error}
            onKeyDown={(event) => {
              if (event.key === Keys.ENTER) event.preventDefault();
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              onChange(event.target.value)
            }
            message={error?.message || ""}
          />
        ) : (
          <span className="h-8.5 inline-flex items-center">
            {template.name}
          </span>
        )
      }
    />
  );
};

export default TemplateRow;
