import { ReactElement, SyntheticEvent } from "react";
import { CallbackArgs, VictoryPie } from "victory";
import { getNameFromDictionary } from "@ps/utils";
import { useSkillsContext } from "./context";
import {
  DonutChartProps,
  DonutSkillProps,
  IEntryOutput,
  IEventData,
} from "./types";
import Label from "./label";
import { colors, DEFAULT_COLOR, getColor } from "./utils";

const DonutChart = ({
  skills,
  renderIcon,
  professionsDict,
  height = 450,
  width = 650,
  innerRadius = 55,
  radius = 150,
  nameKey = "x",
  valueKey = "y",
}: DonutChartProps): ReactElement => {
  const { selectedSection, onPieClick } = useSkillsContext();

  const totalSkillsAmount = skills.reduce(
    (acc: number, next): number => acc + next.skills.length || 0,
    0,
  );

  const preparedDataToChart = skills.map(
    (item: DonutSkillProps, index: number): IEntryOutput => ({
      x: item?.professionId || "",
      label: getNameFromDictionary(professionsDict, item.professionId),
      y: (item.skills.length * 100) / totalSkillsAmount,
      color: colors[index] || DEFAULT_COLOR,
      icon: item.professionId || "",
      index,
    }),
  );

  const prepareColours = skills.map(
    (item: DonutSkillProps, index: number): string =>
      getColor(index) || DEFAULT_COLOR,
  );

  const centerX = width / 2;
  const centerY = height / 1.9;

  const handleOnPieClick = (selected?: string): void => {
    const clickedProfession = selected;
    if (
      (!selectedSection || selectedSection !== clickedProfession) &&
      clickedProfession
    ) {
      onPieClick && onPieClick(clickedProfession);
    } else {
      onPieClick && onPieClick("");
    }
  };

  return (
    <VictoryPie
      height={height}
      width={width}
      data={preparedDataToChart}
      colorScale={prepareColours}
      innerRadius={innerRadius}
      radius={(data) =>
        data.datum.x === selectedSection ? radius + 8 : radius
      }
      animate={{ duration: 300 }}
      x={nameKey}
      y={valueKey}
      style={{
        data: {
          opacity: (data: CallbackArgs): number =>
            !selectedSection || data.datum.x === selectedSection ? 1 : 0.3,
          filter: "drop-shadow(0px 8px 10px rgba(0,0,0,0.4))",
        },
      }}
      labelComponent={
        <Label
          cx={centerX}
          cy={centerY}
          handleOnClick={handleOnPieClick}
          innerRadius={innerRadius}
          radius={radius}
          renderIcon={renderIcon}
        />
      }
      events={[
        {
          target: "data",
          eventHandlers: {
            onClick: (event: SyntheticEvent, data: IEventData) =>
              handleOnPieClick(data.datum.x),
          },
        },
      ]}
    />
  );
};

export default DonutChart;
