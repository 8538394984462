import APIService from "../api/finance-api";
import { handleError } from "../../shared";
import {
  holidaysUserDetailsFromAPIToModel,
  holidaysUsersFromAPIToModel,
  userHolidaysFromAPIToModel,
  UpdateUserHolidayAPIModel,
} from "../models";
import { holidaysUserHistoryFromAPIToModel } from "../models/converters/fromAPIToModel";
import { holidaysUserEntry } from "../models/converters/toAPIModel";
import { HolidaysUserHistoryModel } from "../models/types";
import { UpdateSalary } from "../api/openapi-client";

const FinanceService = (() => {
  const fetchHolidaysUsers = async () => {
    try {
      const response = await APIService.API.getAllUsers();
      if (response?.data) return holidaysUsersFromAPIToModel(response.data);
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchHolidaysUserDetails = async (userId: string) => {
    try {
      const response = await APIService.API.getUserDetailed(userId);
      if (response?.data)
        return holidaysUserDetailsFromAPIToModel(response.data);
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  // for time tracker purpose to fetch logged user holidays template with entries
  const fetchUserHolidays = async () => {
    try {
      const response = await APIService.API.getUserHolidaysTemplate();
      if (response?.data)
        return userHolidaysFromAPIToModel(response.data?.holidaysItems || []);
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  // TODO holidays - Waiting for BE
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchUserHolidaysHistory = async (userId: string) => {
    try {
      // TODO holidays - add fetch holidays
      const mockedData = [
        {
          firstDay: "2022-03-01",
          lastDay: "2022-05-23",
          holidaysName: "test1",
          amountOfDays: 480,
          holidaysIds: ["1", "2"],
          holidaysItemId: "d195a262-131f-4b90-9854-eb206c5fb256",
        },
        {
          firstDay: "2022-09-13",
          lastDay: "2022-12-13",
          holidaysName: "test1",
          amountOfDays: 480,
          holidaysIds: ["1", "2"],
          holidaysItemId: "d195a262-131f-4b90-9854-eb206c5fb256",
        },
        {
          firstDay: "2022-07-07",
          lastDay: "2022-07-07",
          holidaysName: "test2",
          amountOfDays: 240,
          holidaysIds: ["1", "2"],
          holidaysItemId: "5ac1ce7b-2127-4f39-9348-d47fda5b0d80",
        },
        {
          firstDay: "2022-07-07",
          lastDay: "2022-07-07",
          holidaysName: "test1",
          amountOfDays: 480,
          holidaysIds: ["1", "2"],
          holidaysItemId: "d195a262-131f-4b90-9854-eb206c5fb256",
        },
        {
          firstDay: "2022-07-07",
          lastDay: "2022-07-07",
          holidaysName: "test2",
          amountOfDays: 240,
          holidaysIds: ["1", "2"],
          holidaysItemId: "d195a262-131f-4b90-9854-eb206c5fb256",
        },
        {
          firstDay: "2022-07-07",
          lastDay: "2022-07-07",
          holidaysName: "test1",
          amountOfDays: 480,
          holidaysIds: ["1", "2"],
          holidaysItemId: "d195a262-131f-4b90-9854-eb206c5fb256",
        },
        {
          firstDay: "2024-07-07",
          lastDay: "2024-07-07",
          holidaysName: "test2",
          amountOfDays: 480,
          holidaysIds: ["1", "2"],
          holidaysItemId: "5ac1ce7b-2127-4f39-9348-d47fda5b0d80",
        },
      ];
      return holidaysUserHistoryFromAPIToModel(mockedData);
    } catch (error) {
      return handleError(error);
    }
  };

  const changeUserTemplate = async (
    userId: string,
    updatedTemplateId: string,
  ) => {
    try {
      return APIService.API.changeUserTemplate(userId, {
        templateId: updatedTemplateId,
      });
    } catch (error) {
      return handleError(error);
    }
  };

  const updateUserHolidays = async (
    userId: string,
    updatedHolidays: UpdateUserHolidayAPIModel[],
  ) => {
    try {
      return APIService.API.updateUserTemplate(userId, updatedHolidays);
    } catch (error) {
      return handleError(error);
    }
  };

  const updateUserEntryHolidays = async (
    data: HolidaysUserHistoryModel,
  ): Promise<boolean> => {
    try {
      await holidaysUserEntry(data);
      return true;
    } catch (e) {
      handleError(e);
      return false;
    }
  };

  const updateSalary = async (userId: string, salary: UpdateSalary) => {
    try {
      return APIService.API.updateUserSalary(userId, salary);
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    changeUserTemplate,
    fetchHolidaysUserDetails,
    fetchHolidaysUsers,
    fetchUserHolidays,
    fetchUserHolidaysHistory,
    updateUserHolidays,
    updateUserEntryHolidays,
    updateSalary,
  };
})();

export default FinanceService;
