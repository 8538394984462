import { ReactElement, useState } from "react";
import { classJoin } from "@ps/utils";
import { uid } from "react-uid";
import { Pill, SelectedSkill, ShowMore, useThemeMode } from "@ps/ui-components";
import { PILL } from "../../../prospect-domain/constants";
import { ReactComponent as ArrowDownIcon } from "../../../images/project/arrow-down.svg";
import { SKILLS_SEARCH } from "../../../shared/data-cy";
import {
  ExtendedPillData,
  PillsRowProps,
  SkillSearchPillProps,
} from "./users-table/types";
import { PROJECT } from "../../../project-domain/constants";
import { useTranslationWithNamespace } from "../../../hooks";

const PillsRow = ({
  mustHaveArr,
  niceHaveArr,
  onRemove,
}: PillsRowProps): ReactElement => {
  const [isFullViewOpen, setIsFullViewOpen] = useState(false);
  const { isHighContrast } = useThemeMode();
  const { t } = useTranslationWithNamespace();

  const extendedMustHave = mustHaveArr.map(
    (item: SkillSearchPillProps, index: number): ExtendedPillData => ({
      ...item,
      isMustHave: true,
      removeId: index,
    }),
  );

  const extendedNiceHave = niceHaveArr.map(
    (item: SkillSearchPillProps, index: number): ExtendedPillData => ({
      ...item,
      removeId: index,
    }),
  );

  return (
    <div className="flex items-start gap-2.5 w-full mt-4">
      <ShowMore
        abbrViewRowAmount={1}
        isFullViewOpen={isFullViewOpen}
        setIsFullViewOpen={setIsFullViewOpen}
        MoreComponent={
          <Pill
            colourClass="bg-transparent text-neutralPrimary-100"
            dataCy={`${SKILLS_SEARCH}-showMore`}
            paddingX="px-0"
          >
            <SelectedSkill
              name={t(
                `team.skillsSearch.${isFullViewOpen ? "showLess" : "showMore"}`,
              )}
              colourClass={classJoin(
                isHighContrast ? "text-primary-30" : "text-neutralPrimary-100",
                "bg-neutralPrimary-10",
              )}
              colourRemoveClass={classJoin(
                isHighContrast ? "text-primary-30" : "text-neutralPrimary-100",
                "bg-neutralPrimary-10",
              )}
              Icon={
                <button
                  className="h-5 w-8 rounded-r-md hover:opacity-75 cursor-pointer flex items-center"
                  onClick={() => setIsFullViewOpen((prev) => !prev)}
                  tabIndex={0}
                  type="button"
                >
                  <ArrowDownIcon
                    className={classJoin(
                      isFullViewOpen && "transform rotate-180",
                    )}
                  />
                </button>
              }
            />
          </Pill>
        }
      >
        {[...extendedMustHave, ...extendedNiceHave].map(
          (p: ExtendedPillData): ReactElement => (
            <Pill
              colourClass="bg-transparent text-neutralPrimary-100"
              dataCy={`${SKILLS_SEARCH}.${PILL}_must`}
              key={uid(`${p?.isMustHave ? "mustHave" : "niceHave"}-${p.id}`)}
              paddingX="px-0"
            >
              <SelectedSkill
                name={p.name}
                onClick={() => onRemove(p.removeId, p?.isMustHave)}
                colourClass={classJoin(
                  "text-neutralPrimary-100 font-light",
                  p?.isMustHave ? "bg-primary-60" : "bg-secondary-70",
                )}
                colourRemoveClass={classJoin(
                  "text-neutralPrimary-100 current-fill",
                  p?.isMustHave ? "bg-secondary-70" : "bg-primary-60",
                )}
              />
            </Pill>
          ),
        )}
      </ShowMore>
    </div>
  );
};

export default PillsRow;
