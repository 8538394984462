import { DictionaryEntryModel } from "../mappers/types";

export const getNameFromDictionary = (
  dictionary: DictionaryEntryModel[],
  id?: string,
): string => dictionary?.find((item) => item.id === id)?.name || "";

export const getNamesFromDictionary = (
  arr: string[],
  dictionary: DictionaryEntryModel[],
): string => {
  if (!arr?.length || !dictionary?.length) return "";
  const result = arr
    .map((item) => dictionary.find((entry) => entry?.id === item))
    .map((el) => el?.name)
    .filter((single) => !!single);

  return result.length ? result.join(", ") : "";
};

export const getItemFromDictionary = (
  dictionary: DictionaryEntryModel[],
  value: string,
): DictionaryEntryModel | undefined =>
  dictionary.find((element) => element.id === value);

export const returnObjectIds = (
  data: DictionaryEntryModel[],
): string[] | [] => {
  if (!data?.length) return [];
  const result =
    typeof data[0] === "string"
      ? data
      : data.filter((el) => !!el).map((item) => item.id);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [...new Set(result)];
};
