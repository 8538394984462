/* eslint @typescript-eslint/no-explicit-any: 0 */
import { ISOToDayJs } from "@ps/utils";
import {
  HolidaysUsersModel,
  HolidaysUserModel,
  HolidaysUserAPIModel,
  HolidaysUserDetailsAPIModel,
  HolidaysUserDetailsModel,
  HolidaysItemModel,
  HolidaysItemAPIModel,
  HolidaysUserHistoryModel,
} from "../types";
import { holidayEntryDurationOptions } from "../../shared/constants";

export const holidaysUsersFromAPIToModel = (
  data: HolidaysUserAPIModel[],
): HolidaysUsersModel =>
  data.map(
    (user: HolidaysUserAPIModel): HolidaysUserModel => ({
      id: user.id,
      avatar: user.summary.avatar,
      name: `${user.summary?.firstName || ""} ${user.summary?.lastName || ""}`,
      profession: user.summary?.profession || "",
      seniority: user.summary?.seniority || "",
      enabled: user.summary.enabled,
      holidaysTemplate: user.summary.holidaysTemplate?.templateId
        ? {
            templateId: user.summary.holidaysTemplate?.templateId,
            templateName: user.summary.holidaysTemplate?.templateName,
          }
        : null,
      holidays: {
        used: user.summary.holidays?.taken || 0,
        total: user.summary.holidays?.full || 0,
      },
      salary: user.salary
        ? {
            perHour: user.salary?.perHour,
            monthly: user.salary?.monthly,
            currency: user.salary?.currency,
            lastUpdated: user.salary?.lastUpdated,
          }
        : null,
    }),
  );

export const holidaysUserDetailsFromAPIToModel = (
  user: HolidaysUserDetailsAPIModel,
): HolidaysUserDetailsModel => ({
  id: user.id,
  name: `${user.summary?.firstName || ""} ${user.summary?.lastName || ""}`,
  profession: user.summary?.profession || "",
  seniority: user.summary?.seniority || "",
  enabled: user.summary.enabled,
  holidaysTemplate: user.summary.holidaysTemplate?.templateId
    ? {
        templateId: user.summary.holidaysTemplate?.templateId,
        templateName: user.summary.holidaysTemplate?.templateName,
      }
    : null,
  holidays: user.holidaysTemplate?.holidaysItems?.length
    ? user.holidaysTemplate?.holidaysItems.map(
        (holiday: HolidaysItemAPIModel): HolidaysItemModel => ({
          id: holiday.holidaysItemId,
          name: holiday.name,
          total: holiday.numberOfDaysOff,
          used: holiday.numberOfDaysOffTaken,
          modification: holiday.modification,
          allowPartialDayOff: holiday.allowPartialDayOff,
          rate: holiday.payRatePercentage,
        }),
      )
    : [],
  salary: user.salary
    ? {
        monthly: user.salary?.monthly,
        perHour: user.salary?.perHour,
        currency: user.salary?.currency,
        lastUpdated: user.salary?.lastUpdated,
      }
    : null,
});

export const userHolidaysFromAPIToModel = (
  holidays: HolidaysItemAPIModel[],
): HolidaysItemModel[] =>
  holidays.length
    ? holidays.map(
        (holiday: HolidaysItemAPIModel): HolidaysItemModel => ({
          allowPartialDayOff: holiday.allowPartialDayOff,
          holidaysId: holiday.holidaysItemId,
          modification: holiday.modification,
          name: holiday.name,
          rate: holiday.payRatePercentage,
          total: holiday.numberOfDaysOff,
          used: holiday.numberOfDaysOffTaken,
        }),
      )
    : [];

export const holidaysUserHistoryFromAPIToModel = (
  // TODO holidays -  add proper values and TS types, when API will be ready
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  history: any,
): HolidaysUserHistoryModel[] =>
  // TODO holidays - add proper values and TS types, when API will be ready
  history.map((item: any) => ({
    dateFrom: ISOToDayJs(item.firstDay),
    dateTo: ISOToDayJs(item.lastDay),
    typeName: item.holidaysName,
    amountOfDays: item.amountOfDays / 60 / 8,
    duration:
      item.amountOfDays === 240
        ? holidayEntryDurationOptions[1]
        : holidayEntryDurationOptions[0],
    entryIds: item.holidaysTimeEntriesId,
    typeId: item.holidaysItemId,
  }));
