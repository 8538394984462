/* eslint @typescript-eslint/no-explicit-any: 0 */
import { ReactElement, MouseEvent } from "react";
import {
  Button,
  SearchInput,
  Sorter,
  ActiveSelect,
  RestrictedWrapper,
} from "@ps/ui-components";
import { PROJECT_TEAM, TEAM } from "../../../../../shared/data-cy";
import { ReactComponent as AddUserIcon } from "../../../../../images/project/add-user.svg";
import { ReactComponent as CloseIcon } from "../../../../../images/close.svg";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { useTeamContext } from "../../context";
import { SearchBarProps } from "./types";
import { BASE_PATH } from "./constants";
import {
  ASC,
  DESC,
  MAIN,
  SENIORITY,
  LOCATION,
  PROFESSION,
  FULL_NAME,
  NAME,
  ACTIVE,
  INACTIVE,
  ACTIVE_USERS,
} from "../../../../constants";
import { PROJECT_FULL_ACCESS } from "../../../../../shared/permissions";

const SearchBar = ({
  activeAmount,
  clearFiltersDisabled,
  inactiveAmount,
  onClearFilters,
  searchValue,
  setSearchValue,
  onSorterClick,
  sorterParameters,
  teamCheckers,
  setTeamCheckers,
  projectArchived,
}: SearchBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const { teamView, setTeamView } = useTeamContext();

  const names = [
    { name: FULL_NAME, label: t(`${BASE_PATH}.${NAME}`) },
    {
      name: MAIN,
      label: t(`${BASE_PATH}.${PROFESSION}`),
      isNestedField: true,
    },
    { name: SENIORITY, label: t(`${BASE_PATH}.${SENIORITY}`) },
    { name: LOCATION, label: t(`${BASE_PATH}.${LOCATION}`) },
  ];

  const orders = [
    { order: ASC, label: t(`${BASE_PATH}.${ASC}`) },
    { order: DESC, label: t(`${BASE_PATH}.${DESC}`) },
  ];

  const options = [
    {
      id: ACTIVE,
      label: t(`${BASE_PATH}.showActive`),
      amount: activeAmount,
      checked: teamCheckers.active,
    },
    {
      id: INACTIVE,
      label: t(`${BASE_PATH}.showInactive`),
      amount: inactiveAmount,
      checked: teamCheckers.inactive,
    },
  ];

  const handleOptionClick = (e: MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget;
    setTeamCheckers((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const namesWithoutLocation = names.filter(
    (n: { name: string }) => n.name !== LOCATION,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleNames: { name: string; label: any }[] = teamView
    ? namesWithoutLocation
    : names;

  return (
    <div className="flex w-full justify-between gap-6 items-center">
      {!clearFiltersDisabled && (
        <Button
          variant="secondary"
          dataCy={PROJECT_TEAM}
          onClick={onClearFilters}
        >
          {t("actions.clearFilters")}
        </Button>
      )}
      <SearchInput
        dataCy={PROJECT_TEAM}
        placeholder={t(`${BASE_PATH}.search`)}
        value={searchValue}
        onChange={setSearchValue}
      />
      <Sorter
        activeSorter={sorterParameters}
        orders={orders}
        names={toggleNames}
        handleSorterClick={onSorterClick}
      />

      {teamView && (
        <ActiveSelect
          dataCy={`${TEAM}_${ACTIVE_USERS}`}
          label={t(`${BASE_PATH}.${ACTIVE_USERS}`)}
          activeAmount={activeAmount}
          options={options}
          onOptionClick={handleOptionClick}
        />
      )}
      {projectArchived ? null : (
        <>
          {teamView ? (
            <RestrictedWrapper permissions={[PROJECT_FULL_ACCESS]}>
              <Button
                dataCy={`${PROJECT_TEAM}_addTo`}
                additionalClass="flex items-center gap-2"
                onClick={(): void => setTeamView(false)}
                width="w-34"
              >
                <AddUserIcon className="fill-current" />
                {t(`${BASE_PATH}.addUser`)}
              </Button>
            </RestrictedWrapper>
          ) : (
            <Button
              dataCy={`${PROJECT_TEAM}_closeAdd`}
              onClick={(): void => setTeamView(true)}
              additionalClass="flex items-center gap-2"
              variant="secondary"
              width="w-34"
            >
              {t(`${BASE_PATH}.close`)}
              <CloseIcon className="fill-current" />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default SearchBar;
