import { ReactElement } from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { useTranslationWithNamespace } from "../../../hooks";
import { ProfessionsProps } from "../types";
import { SectionIcon } from "../components";
import { BRIEFCASE } from "../../../shared/constants";
import { sharedOutlineStyles } from "../sharedStyles/sharedStyles";

const styles = StyleSheet.create({
  text: {
    fontFamily: "Poppins",
    fontSize: "10px",
  },
  lightText: {
    fontFamily: "PoppinsLight",
    fontSize: "7px",
  },
});

const Professions = ({
  professions,
  isBlind,
}: ProfessionsProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <View style={sharedOutlineStyles.container}>
      <View style={sharedOutlineStyles.iconContainer}>
        <SectionIcon icon={BRIEFCASE} isBlind={isBlind} />
      </View>
      <View style={sharedOutlineStyles.textContainer}>
        <>
          <Text style={sharedOutlineStyles.label}>
            {t("resume.profession")}
          </Text>
          {professions.map(
            (profession: string, index: number): ReactElement => (
              <Text
                key={profession}
                style={!index ? styles.text : styles.lightText}
              >
                {profession}
              </Text>
            ),
          )}
        </>
      </View>
    </View>
  );
};

export default Professions;
