import APIService from "../api/client-api";
import {
  clientsSummaryFromAPIToModel,
  clientSummaryFromAPIToModel,
  fromAPItoModel,
  NewClientModel,
  toAPIModel,
} from "../models";
import { handleError } from "../../shared";

const ClientService = (() => {
  const fetchClients = async () => {
    try {
      const response = await APIService.API.getClients();

      if (response?.data) return fromAPItoModel(response.data);
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const createClient = async (newClientData: NewClientModel) => {
    try {
      return APIService.API.addClient(toAPIModel(newClientData));
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchClientsSummary = async () => {
    try {
      const response = await APIService.API.getClientsSummary();
      if (response?.data) return clientsSummaryFromAPIToModel(response.data);
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchClientById = async (clientId: string) => {
    try {
      const response = await APIService.API.getClientById(clientId);
      if (response?.data) return clientSummaryFromAPIToModel(response.data);
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const archiveClient = async (id: string, archived: boolean) => {
    try {
      return APIService.API.setArchived(id, {
        archived,
      });
    } catch (error) {
      return handleError(error);
    }
  };

  const updateClient = async (id: string, data: NewClientModel) => {
    try {
      return APIService.API.updateClient(id, toAPIModel(data));
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    fetchClients,
    createClient,
    fetchClientsSummary,
    archiveClient,
    fetchClientById,
    updateClient,
  };
})();

export default ClientService;
