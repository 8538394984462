enum ActionTypes {
  FETCH_PROSPECT_START = "FETCH_PROSPECT_START",
  FETCH_PROSPECT_SUCCESS = "FETCH_PROSPECT_SUCCESS",
  FETCH_PROSPECT_ERROR = "FETCH_PROSPECT_ERROR",

  FETCH_PROSPECTS_START = "FETCH_PROSPECTS_START",
  FETCH_PROSPECTS_SUCCESS = "FETCH_PROSPECTS_SUCCESS",
  FETCH_PROSPECTS_ERROR = "FETCH_PROSPECTS_ERROR",

  CREATE_PROSPECT_START = "CREATE_PROSPECT_START",
  CREATE_PROSPECT_SUCCESS = "CREATE_PROSPECT_SUCCESS",
  CREATE_PROSPECT_ERROR = "CREATE_PROSPECT_ERROR",

  UPDATE_PROSPECT_START = "UPDATE_PROSPECT_START",
  UPDATE_PROSPECT_SUCCESS = "UPDATE_PROSPECT_SUCCESS",
  UPDATE_PROSPECT_ERROR = "UPDATE_PROSPECT_ERROR",

  ARCHIVE_PROSPECT_START = "ARCHIVE_PROSPECT_START",
  ARCHIVE_PROSPECT_SUCCESS = "ARCHIVE_PROSPECT_SUCCESS",
  ARCHIVE_PROSPECT_ERROR = "ARCHIVE_PROSPECT_ERROR",
}

export default ActionTypes;
