import { ReactElement } from "react";
import { Label } from "@ps/ui-components";
import { MAIN_PROFILE } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import { BioProps } from "./types";
import Empty from "../empty";
import { SHORT_INFORMATION } from "../constants";

const Bio = ({ title, shortInformation }: BioProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Label
      dataCy={`${title}-${SHORT_INFORMATION}`}
      key={`${MAIN_PROFILE}-${title}-${SHORT_INFORMATION}`}
      text={t(`profile.overview.${title}.${SHORT_INFORMATION}`)}
    >
      {shortInformation ? (
        <span className="font-normal w-full whitespace-pre-wrap">
          {shortInformation}
        </span>
      ) : (
        <Empty section={title} />
      )}
    </Label>
  );
};

export default Bio;
