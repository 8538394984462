import { KeyboardEvent, ReactElement, useState } from "react";
import { classJoin } from "@ps/utils";
import Tooltip from "../tooltip";
import { ReactComponent as CopyLinkIcon } from "../../images/copyLink.svg";
import { ReactComponent as CopiedLinkIcon } from "../../images/copiedLink.svg";
import { CopyLinkIconWithTooltipProps } from "./types";
import { useTranslationWithNamespace } from "../../hooks";
import { focusVisibleStyles, Keys } from "../../shared";

const COPIED = "copied";

const CopyLinkIconWithTooltip = ({
  valueToCopy,
  additionalClassName,
  displayClassName = "flex",
  dataCy,
}: CopyLinkIconWithTooltipProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const [displayTooltip, setDisplayTooltip] = useState<boolean>(false);

  const handleOnClickCopy = (value: string): void => {
    setDisplayTooltip(true);
    navigator.clipboard.writeText(value);
    setTimeout((): void => {
      setDisplayTooltip(false);
    }, 1000);
  };

  const linkIcon: ReactElement = (
    <div
      data-cy={dataCy}
      className={classJoin(
        "items-center p-2 rounded-lg border",
        displayClassName,
        displayTooltip
          ? "border-primary-20 bg-primary-20"
          : "border-neutralPrimary-50 bg-primary-100 hover:opacity-40",
        focusVisibleStyles,
        additionalClassName,
      )}
      onClick={() => handleOnClickCopy(valueToCopy)}
      tabIndex={displayTooltip ? undefined : 0}
      role={displayTooltip ? "presentation" : "button"}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === Keys.ENTER && !displayTooltip)
          handleOnClickCopy(valueToCopy);
      }}
    >
      {displayTooltip ? (
        <CopiedLinkIcon className="w-5 h-5 text-primary-100" />
      ) : (
        <CopyLinkIcon className="w-5 h-5 text-neutralPrimary-50 cursor-pointer" />
      )}
    </div>
  );

  if (displayTooltip)
    return (
      <Tooltip
        additionalClassName="content-center"
        placement="top"
        horizontalOffset={-17}
        verticalOffset={10}
        content={
          <span className="text-neutralPrimary-50">
            {t(`copyLinkIconWithTooltip.${COPIED}`)}
          </span>
        }
      >
        {linkIcon}
      </Tooltip>
    );

  return linkIcon;
};

export default CopyLinkIconWithTooltip;
