import { ReactElement, forwardRef, useRef } from "react";
import { classJoin } from "@ps/utils";
import { useTranslationWithNamespace } from "../../hooks";
import { ReactComponent as Wizard } from "../../images/gandalf.svg";
import { UPLOADER_INPUT_PREFIX, UPLOADER_PREFIX } from "../../shared/data-cy";
import Button from "../button";
import LinkButton from "../link";
import { UploaderProps } from "./types";
import ErrorUploadState from "./state/ErrorUploadState";
import { ReactComponent as CloudUploadIcon } from "../../images/cloud-upload-icon.svg";

const dropDivStyle = classJoin.template`
w-full flex justify-between items-center
bg-neutralPrimary-85 rounded-lg
text-neutralPrimary-20
`;

const PDF = ".pdf";

const Uploader = forwardRef<HTMLDivElement, UploaderProps>(
  (
    {
      dataCy,
      dropWrapperStyle,
      disabled,
      handleInputChange,
      verticalView,
      accept,
      error,
      message,
      multiple,
      isDraggingOver,
    },
    ref,
  ): ReactElement => {
    const { t } = useTranslationWithNamespace();

    const inputRef = useRef<HTMLInputElement>(null);

    const handleBrowseClick = () => {
      inputRef.current?.click();
    };

    const iconColor = isDraggingOver
      ? "text-primary-100"
      : "text-neutralPrimary-30 text-opacity-50";

    if (error) {
      return (
        <div data-cy={`${UPLOADER_PREFIX}-error-${dataCy}`}>
          <ErrorUploadState multiple={multiple} message={message} />
        </div>
      );
    }

    return (
      <>
        <input
          aria-hidden="true"
          ref={inputRef}
          type="file"
          disabled={disabled}
          accept={accept}
          className="hidden"
          onChange={handleInputChange}
          data-cy={`${UPLOADER_INPUT_PREFIX}-${dataCy}`}
        />
        <div
          ref={ref}
          className={classJoin(
            dropDivStyle,
            dropWrapperStyle,
            verticalView
              ? "flex-col-reverse mt-8 bg-neutralPrimary-100"
              : "h-40 border border-neutralSecondary-60",
          )}
          data-cy={`${UPLOADER_PREFIX}-${dataCy}`}
        >
          {accept === PDF ? (
            <>
              <div
                className={classJoin(
                  "flex justify-between h-full",
                  verticalView ? "w-full" : "w-1/2",
                )}
              >
                <div className="h-full overflow-hidden rounded-lg">
                  <Wizard className="w-full h-full" />
                </div>
                <div
                  className={classJoin(
                    "my-4 px-6 flex flex-col gap-y-4 justify-center items-start w-2/3",
                    verticalView ? "" : "border-r-2",
                  )}
                >
                  <span className="font-bold text-lg">
                    {t("uploader.quickStart")}
                  </span>
                  <div className="flex flex-col text-sm">
                    <span>{t("uploader.uploadCV")}</span>
                    <span>{t("uploader.fastAndEasy")}</span>
                  </div>
                </div>
              </div>

              <div
                className={classJoin(
                  "flex flex-col gap-y-1 justify-center items-center",
                  verticalView ? "w-full py-6" : "w-1/2",
                )}
              >
                <Button
                  dataCy="uploadButton"
                  onClick={handleBrowseClick}
                  disabled={disabled}
                >
                  {t("uploader.uploadPdf")}
                </Button>
                <span className="text-sm">{t("uploader.or")}</span>
                <span className="font-bold text-sm">
                  {t("uploader.selectFiles", { files: multiple ? 2 : 1 })}
                </span>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center w-full px-4">
              <div className="h-16 mb-2">
                <CloudUploadIcon className={`fill-current ${iconColor}`} />
              </div>
              <div className="flex flex-row items-center w-full text-neutralPrimary-20 text-lg whitespace-nowrap">
                {t("uploader.selectFiles", { files: multiple ? 2 : 1 })}
                <LinkButton
                  dataCy={`browse-computer-${dataCy}`}
                  additionalClassName="ml-1"
                  onClick={handleBrowseClick}
                >
                  {t("uploader.browseComputer")}
                </LinkButton>
              </div>
              <span className="text-neutralPrimary-30">
                {t("uploader.maximumWeight", { weight: 8 })}
              </span>
            </div>
          )}
        </div>
      </>
    );
  },
);

export default Uploader;
