import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { ReactElement } from "react";
import { uid } from "react-uid";
import { PaginationProps } from "./types";
import { PAGINATION_PREFIX } from "../../shared/data-cy";
import { ReactComponent as LeftArrow } from "../../images/arrowLeft.svg";
import { ReactComponent as RightArrow } from "../../images/arrowRight.svg";
import shouldDisplayPageAndDots from "./helpers/utils";

const arrowButtonsStyles =
  "text-neutralPrimary-30 flex items-center justify-center hover:opacity-40 disabled:opacity-25";

const DOTS = "...";

const Pagination = ({
  dataCy,
  active = 1,
  pages,
  onChange,
}: PaginationProps): ReactElement => {
  const currentActive = active ?? 1;

  const handleOnClickNewPage = (newActivePage: number) =>
    onChange?.(newActivePage);

  const handleOnClickPrevPage = () => active !== 1 && onChange?.(active - 1);

  const handleOnClickNextPage = () =>
    active !== pages && onChange?.(active + 1);

  return (
    <AnimateSharedLayout>
      <div
        className="flex flex-row items-center"
        data-cy={`${PAGINATION_PREFIX}-${dataCy}`}
      >
        <button
          className={arrowButtonsStyles}
          onClick={handleOnClickPrevPage}
          disabled={active === 1}
        >
          <LeftArrow />
        </button>
        {[...Array(pages)].map((_, index) => {
          const { page, dots } = shouldDisplayPageAndDots(
            index + 1,
            pages,
            currentActive,
          );
          return (
            <div key={uid(index)}>
              {page && (
                <div className="relative w-max">
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <AnimatePresence>
                    {currentActive === index + 1 ? (
                      <div className="border border-secondary-60 rounded-md bg-neutralSecondary-90">
                        <motion.button
                          layoutId="selected"
                          transition={{
                            type: "spring",
                            stiffness: 500,
                            damping: 25,
                          }}
                          className="justify-self-center px-2 text-neutralPrimary-20 font-semibold"
                        >
                          {index + 1}
                        </motion.button>
                      </div>
                    ) : (
                      <button
                        className="text-neutralPrimary-30 px-2"
                        onClick={() => handleOnClickNewPage(index + 1)}
                      >
                        {index + 1}
                      </button>
                    )}
                  </AnimatePresence>
                </div>
              )}
              {dots && <div className="text-neutralPrimary-30">{DOTS}</div>}
            </div>
          );
        })}
        <button
          className={arrowButtonsStyles}
          onClick={handleOnClickNextPage}
          disabled={active === pages}
        >
          <RightArrow />
        </button>
      </div>
    </AnimateSharedLayout>
  );
};

export default Pagination;
