import { ReactElement, useState } from "react";
import { Button, Label, Modal2, SearchSelect } from "@ps/ui-components";
import { DELETE, DELETE_USER, REASON, TEAM } from "../../../../constants";
import { useTranslationWithNamespace } from "../../../../../hooks";
import UserAvatar from "./userAvatar";
import { DeactivateUserModalProps } from "./types";

const DeactivateUserModal = ({
  isOpen,
  onClose,
  user,
  onDeactivate,
}: DeactivateUserModalProps): ReactElement => {
  const [rejectReason, setRejectReason] = useState("");
  const [rejectError, setRejectError] = useState(false);
  const { t } = useTranslationWithNamespace();

  const translate = (field: string, isRequired = false): string =>
    `${t(`project.team.${field}`)}${isRequired ? "*" : ""}`;

  const reasons = [...Array(10)].map(
    (
      _,
      index: number,
    ): {
      value: string;
      label: string;
    } => ({
      value: translate(`options.option${index}`),
      label: translate(`options.option${index}`),
    }),
  );

  const handleOnDeactivate = (): undefined => {
    if (!rejectReason.length) {
      setRejectError(true);
      return;
    }
    setRejectReason("");
    onDeactivate(rejectReason);
  };

  return (
    <Modal2
      isOpen={isOpen}
      withCloseIcon
      onClose={onClose}
      dataCy={TEAM}
      withYScroll={false}
      withOutsideClick
      height="h-max"
      width="w-1/3"
    >
      <div className="flex flex-col gap-8">
        <span className="text-neutralPrimary-20 text-lg font-bold">
          {translate(DELETE_USER)}
        </span>
        <div className="flex gap-3 items-center">
          <UserAvatar avatar={user.avatar} />
          <span className="text-primary-50 font-semibold">
            {`${user?.firstName} ${user?.lastName}`}
          </span>
        </div>
        <Label dataCy={`${TEAM}_${REASON}`} text={translate(REASON, true)}>
          <SearchSelect
            dataCy={TEAM}
            width="w-full"
            placeholder={translate(REASON)}
            options={reasons}
            onChange={(item) => {
              setRejectReason(item.value);
              setRejectError(false);
            }}
            error={rejectError}
            message={translate("rejectError")}
            menuPosition="fixed"
          />
        </Label>
        <Button dataCy={`${TEAM}_${DELETE}`} onClick={handleOnDeactivate}>
          {translate(DELETE)}
        </Button>
      </div>
    </Modal2>
  );
};

export default DeactivateUserModal;
