import {
  colorFormatters,
  getItemFromDictionary,
  ISOToDayJs,
  UTCToDayjs,
} from "@ps/utils";
import { getName } from "i18n-iso-countries";
import {
  BaseProjectSummaryModel,
  ProjectAPIModel,
  ProjectModel,
  ProjectSummary,
  ProjectSummaryAPI,
  ProjectUserSummaryAPIModel,
  ProjectUserSummaryModel,
  RateAPIModel,
  RateModel,
  StatementOfWorkAPIModel,
  TeamMemberAPIModel,
  TeamMemberModel,
} from "../types";
import { DictionaryModel } from "../../../dictionaries";

const prepareCountryToDisplay = (country: string) => ({
  label: getName(country, "en", { select: "official" }),
  value: country,
});

export const projectFromAPIToModel = (
  data: ProjectAPIModel,
  technologyDict: DictionaryModel | [],
): ProjectModel => ({
  id: data.id,
  public: data.public,
  details: {
    name: data.details.name,
    contactPerson: data.details.contactPerson,
    clientId: data.details?.clientId,
    clientName: data.details?.clientName,
    invoiceDetails: data.details?.invoiceDetails
      ? {
          companyName: data.details.invoiceDetails?.companyName,
          country: data.details.invoiceDetails?.country
            ? prepareCountryToDisplay(data.details.invoiceDetails.country)
            : null,
          zipCode: data.details.invoiceDetails?.zipCode,
          address: data.details.invoiceDetails?.address,
          taxNumber: data.details.invoiceDetails?.taxNumber,
          accountNumber: data.details.invoiceDetails?.accountNumber,
        }
      : undefined,
    techStack:
      data.details?.techStack?.length && technologyDict?.length
        ? data.details.techStack.map((tech: string) =>
            getItemFromDictionary(technologyDict, tech),
          )
        : [],
    domains: data.details?.domains,
    projectManager: data.details?.projectManager,
    businessDeveloper: data.details?.businessDeveloper,
    status: data.details.status,
    color: colorFormatters.prepareRGBObjectToHEX(data?.details?.color),
    note: data.details.note,
  },
  team:
    data.team?.map(
      (teamMember: TeamMemberAPIModel): TeamMemberModel => ({
        avatar: teamMember.avatar,
        userId: teamMember.userId,
        firstName: teamMember?.firstName || undefined,
        lastName: teamMember?.lastName || undefined,
        professions: teamMember?.professions?.length
          ? {
              main: teamMember?.professions[0],
              secondary: teamMember?.professions.slice(1) || [],
            }
          : undefined,
        seniority: teamMember?.seniority || undefined,
        statementOfWork: {
          startDate: ISOToDayJs(teamMember.statementOfWork?.startDate),
          finishDate: ISOToDayJs(teamMember.statementOfWork?.endDate) || null,
          noticePeriod: teamMember.statementOfWork?.noticePeriod || undefined,
          engagement: teamMember.statementOfWork?.engagement || undefined,
        },
        rates: teamMember.rates?.map(
          (rate: RateAPIModel): RateModel => ({
            date: ISOToDayJs(rate.date),
            amount: rate.amount,
            currency: rate.currency,
          }),
        ),
        deleted: teamMember?.rejectionReason,
        feedback: teamMember?.projectFeedback,
        enabled: teamMember.enabled,
      }),
    ) || undefined,
  creationDate: UTCToDayjs(data.creationDate || ""),
  deleted: data.deleted,
  archived: data.archived,
});

export const isUserProjectType = (
  data: unknown,
): data is ProjectUserSummaryAPIModel =>
  (<ProjectUserSummaryAPIModel>data)?.membershipDetails !== undefined;

const prepareProjectSummary = (
  project: ProjectSummaryAPI,
): BaseProjectSummaryModel => ({
  archived: project.archived,
  id: project.id,
  public: project.public,
  details: {
    name: project.details.name,
    clientId: project.details?.clientId,
    clientName: project.details?.clientName,
    projectManager: project.details?.projectManager,
    businessDeveloper: project.details?.businessDeveloper,
    status: project.details.status,
    color: colorFormatters.prepareRGBObjectToHEX(project?.details?.color),
  },
  creationDate: UTCToDayjs(project?.creationDate || ""),
  deleted: project.deleted,
});

const prepareUserProjectSummary = (
  project: ProjectUserSummaryAPIModel,
): ProjectUserSummaryModel => ({
  ...prepareProjectSummary(project),
  type: "ProjectUserSummary",
  team: project?.membershipDetails ? project?.membershipDetails.team : [],
  membershipDetails: project?.membershipDetails
    ? {
        deleted: project?.membershipDetails?.deleted,
        statementOfWork: {
          endDate: UTCToDayjs(
            project?.membershipDetails?.statementOfWork?.endDate || "",
          ),
          engagement:
            project?.membershipDetails?.statementOfWork?.engagement || "",
          noticePeriod:
            project?.membershipDetails?.statementOfWork?.noticePeriod || null,
          startDate: UTCToDayjs(
            project?.membershipDetails?.statementOfWork?.startDate || "",
          ),
        },
        archivedStatementsOfWork:
          project?.membershipDetails?.archivedStatementsOfWork?.map(
            (item: StatementOfWorkAPIModel) => ({
              startDate: UTCToDayjs(item.startDate),
              endDate: UTCToDayjs(item.endDate),
              noticePeriod: item.noticePeriod || null,
              engagement: item.engagement || "",
            }),
          ) || [],
      }
    : null,
});

export const projectsSummaryFromAPIToModel = (
  data: ProjectSummaryAPI[],
): ProjectSummary[] => {
  if (!data?.length) return [];
  return isUserProjectType(data[0])
    ? data.map((project: ProjectSummaryAPI) =>
        prepareUserProjectSummary(project),
      )
    : data.map((project: ProjectSummaryAPI) => ({
        ...prepareProjectSummary(project),
        team: project.team,
        type: "ProjectSummary",
      }));
};
