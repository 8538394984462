import { ReactComponent as OverviewTab } from "../images/tabs/overview.svg";
import { ReactComponent as ExperienceTab } from "../images/tabs/experience.svg";
import { ReactComponent as SkillsTab } from "../images/tabs/skills.svg";
import { ReactComponent as SummaryTab } from "../images/tabs/summary.svg";
import { ReactComponent as UsersListTab } from "../images/tabs/usersList.svg";
import { ReactComponent as SkillsSearchTab } from "../images/tabs/skillsSearch.svg";
import { ReactComponent as AllProspectsTab } from "../images/tabs/allprospects.svg";
import { ReactComponent as AllProjectsTab } from "../images/tabs/allprojects.svg";
import { ReactComponent as BenchTab } from "../images/tabs/bench.svg";
import { ReactComponent as PalmTab } from "../images/tabs/palm.svg";
import { ReactComponent as MoneyTab } from "../images/tabs/money.svg";
import { ReactComponent as HTemplateTab } from "../images/tabs/holidays-templates.svg";
import { MATRIX_FULFILLMENTS, USERS_LIST_ALL } from "./permissions";

export const NAMESPACE = "hh";
export const DATE_FORMAT = "DD/MM/YYYY";
export const MONTH_FORMAT = "MM.YYYY";

export const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === "production";

export const PROFILE_SPECIALIST_TABS = [
  { item: "SUMMARY", icon: SummaryTab },
  {
    item: "PERSONAL_INFO",
    icon: OverviewTab,
  },
  {
    item: "EXPERIENCE",
    icon: ExperienceTab,
  },
  {
    item: "SKILLS",
    icon: SkillsTab,
  },
];

export const PROFILE_OPERATIVE_TABS = [
  {
    item: "PERSONAL_INFO",
    icon: OverviewTab,
  },
];

export const TEAM_TABS = [
  {
    item: "USERS_LIST",
    icon: UsersListTab,
    permissions: [USERS_LIST_ALL],
  },
  {
    item: "SKILLS_SEARCH",
    icon: SkillsSearchTab,
    permissions: [MATRIX_FULFILLMENTS],
  },
  {
    item: "BENCH",
    icon: BenchTab,
    permissions: [USERS_LIST_ALL],
  },
];

export const PROSPECTS_TABS = [
  {
    item: "ALL_PROSPECTS",
    icon: AllProspectsTab,
  },
  // TODO temporary hidden because of HH2-2728
  // {
  //   item: "MY_PROSPECTS",
  //   icon: MyProspectsTab,
  // },
];

export const PROJECT_TABS = [
  {
    item: "ALL_PROJECTS",
    icon: AllProjectsTab,
  },
  // TODO temporary hidden because of HH2-2728
  // {
  //   item: "MY_PROJECTS",
  //   icon: MyProjectsTab,
  // },
];

export const FINANCE_TABS = [
  { item: "FINANCES", icon: MoneyTab },
  {
    item: "HOLIDAYS",
    icon: PalmTab,
  },
  {
    item: "HOLIDAYS_TEMPLATES",
    icon: HTemplateTab,
  },
];

export const SPECIALIST = "specialist";
export const OPERATIVE = "operative";

export const WORKING_HOURS_FORMAT = "hh:mmAM";
export const CURRENT_YEAR = new Date().getFullYear().toString();
export const PREVIOUS_YEAR = (new Date().getFullYear() - 1).toString();

export const TYPE = "type";
export const CHOOSE = "choose";
export const DATE_FROM = "dateFrom";
export const DATE_TO = "dateTo";
export const DURATION = "duration";
export const SUBMIT = "submit";
export const CANCEL = "cancel";
export const SESSION_SORTERS = "sessionSorters";

export const initialSessionSortersState = {
  sessionSorters: {
    bench: { name: "", order: "" },
    projects: { name: "", order: "", nestedFieldName: undefined },
    team: { name: "", order: "", nestedFieldName: undefined },
    prospects: { name: "", order: "" },
    finances: { name: "", order: "" },
    discoveryTeam: { name: "", order: "", nestedFieldName: undefined },
    proposalFolder: { name: "", order: "", nestedFieldName: undefined },
    specialists: { name: "", order: "", nestedFieldName: undefined },
    folders: { name: "", order: "", nestedFieldName: undefined },
    clients: { name: "", order: "" },
  },
};

export const FULL_TECH_SEARCH_MAX = 8;
