const getSelectedState = <
  State extends Record<string | number | symbol, unknown>,
  Result,
>(
  state: State,
  getResultState: (state: State | State[keyof State]) => Result,
  selector: keyof State,
): Result => {
  const preparedState = state[selector] ? state[selector] : state;
  return getResultState(preparedState);
};

export default getSelectedState;
