enum ActionTypes {
  FETCH_MY_PROFILE_START = "FETCH_MY_PROFILE_START",
  FETCH_MY_PROFILE_ERROR = "FETCH_MY_PROFILE_ERROR",
  FETCH_MY_PROFILE_SUCCESS = "FETCH_MY_PROFILE_SUCCESS",
  FETCH_PROFILE_START = "FETCH_PROFILE_START",
  FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS",
  FETCH_PROFILE_ERROR = "FETCH_PROFILE_ERROR",
  FETCH_PROFILES_START = "FETCH_PROFILES_START",
  FETCH_PROFILES_SUCCESS = "FETCH_PROFILES_SUCCESS",
  FETCH_PROFILES_ERROR = "FETCH_PROFILES_ERROR",
  FETCH_OTHER_PROFILE_START = "FETCH_OTHER_PROFILE_START",
  FETCH_OTHER_PROFILE_SUCCESS = "FETCH_OTHER_PROFILE_SUCCESS",
  FETCH_OTHER_PROFILE_ERROR = "FETCH_OTHER_PROFILE_ERROR",
  OCR_PROCESSING_START = "OCR_PROCESSING_START",
  OCR_PROCESSING_SUCCESS = "OCR_PROCESSING_SUCCESS",
  OCR_PROCESSING_ERROR = "OCR_PROCESSING_ERROR",
  FETCH_SPECIALIST_PROFILES_START = "FETCH_SPECIALIST_PROFILES_START",
  FETCH_SPECIALIST_PROFILES_SUCCESS = "FETCH_SPECIALIST_PROFILES_SUCCESS",
  FETCH_SPECIALIST_PROFILE_ERROR = "FETCH_SPECIALIST_PROFILE_ERROR",
}

export default ActionTypes;
