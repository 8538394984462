export default {
  hireHelp: "Hire Help",
  noData: "No data",
  preventRedirect: {
    message: "Are you sure you want to leave without saving data?",
  },
  buttons: {
    cancel: "Cancel",
    save: "Save",
    confirm: "Confirm",
    edit: "edit",
  },
  uploader: {
    quickStart: "Quick start",
    uploadCV: "Upload a CV to Profile Wizard,",
    fastAndEasy: "fast and easy way to fill up your profile",
    file: "file",
    file_plural: "files",
    uploadPdf: "Upload PDF",
    or: "or",
    selectFiles:
      'Drag and drop $t(uploader.file, {"count": {{files}} }) from your desktop to start',
    uploadError: 'Unable to upload $t(uploader.file, {"count": {{files}} })!',
    uploadSuccess: '$t(uploader.file, {"count": {{files}} }) upload complete!',
    uploadInProgress: 'Uploading $t(uploader.file, {"count": {{files}} })...',
    maximumWeight: "File must be no larger than {{weight}} MB",
    browseComputer: "browse computer",
    errorMaximumWeight:
      "File size limit is {{fileSize}} MB. Your photo was too big.",
  },
  imageUploader: {
    uploadButton: "Upload image",
    changeButton: "Change image",
    chooseImage: "Choose image",
    zoom: "Zoom",
    editImage: "Edit image",
  },
  labels: {
    selected: "Selected",
  },
  "time-tracker": {
    thisWeek: "This week",
    thisMonth: "This month",
    today: "Today",
    yesterday: "Yesterday",
    lastWeek: "Last week",
    lastMonth: "Last month",
    thisYear: "This year",
    lastYear: "Last year",
  },
  pinInput: {
    error: {
      title: "Wrong PIN!",
      description: "Please, try again",
    },
  },
  loadingPage: {
    thanks: "Thank you for using teamy.ai! We appreciate it!",
    please: "Please, wait a minute...",
  },
  colorPicker: {
    hex: "Hex",
    companyColors: "Company colors",
    exampleHex: "Ex. #0000",
    defaultColors: "Default colors",
  },
  pages: {
    forbidden: "Forbidden",
    sorry: "We are sorry, but you do not have access to this page or resource",
    home: "Go home",
    notFound: "page not found",
    back: "back",
    pin: {
      welcome: "Welcome!",
      text: "You may enter your PIN Code to see folder details",
      enterYour: "Enter your PIN code",
      description: "Here you can check the team proposed for your project",
    },
  },
  experience: {
    years: "{{count}} year",
    years_plural: "{{count}} years",
    months: "{{count}} month",
    months_plural: "{{count}} months",
  },
  empty: {
    default: "empty",
  },
  searchSelectPicker: {
    createNewSkill: `Create new skill: `,
    placeholder: "Search skill",
    cantBeCreated:
      "Can't be created. Tech name exceeds {{maxLength}} characters",
  },
  textarea: {
    maxNumber:
      "Max number of characters is exceeded, reduce it to {{ number }} characters",
  },
  urlButton: {
    link: "Link",
  },
  profileSummary: {
    emptyInfo: "No {{ section }} to show",
    here: "here",
    youCanAdd: "You can add",
    experience: "Experience",
    professions: "Professions",
    seniority: "Seniority",
    role: "Role",
    lastUpdate: "Last update: {{ date }}",
    roles: "Roles",
    domain: "Domain",
    projectRoles: "Project roles",
    joinDate: "{{name}} join date",
    years: "{{count}} year",
    years_plural: "{{count}} years",
    months: "{{count}} month",
    months_plural: "{{count}} months",
    seeMore: "see more",
    seeLess: "see less",
    domains: "Domains",
    skillsName: "{{name}} skills",
    ongoing: "Ongoing",
    defaultProjectName: "Project name",
    projectDescription: "Project description",
    projectResponsibilities: "Project responsibilities",
    seeMoreProjects: "See {{count}} more project",
    projects_plural: "See {{count}} more projects",
    seeMoreExperiences: "See more",
    hiddenReason:
      "Can't see content? We care about the privacy of our clients. You can be sure your data is safe with us!",
    programmerTooltip: `The leading competence: Analytical and critical thinking.
    Focuses on building and delivering good, efficient software. 
    In their tasks, the main focus is on problem-solving, analysis, and performance of various job development. 
    Thanks to their specialization and knowledge of the best industry practices, provide the best quality software.
    Can work alone or in a team, projects with a small number of tasks requiring communication and cooperation 
    between team members or on the business-developer line do not negatively affect their motivation levels.`,
    leaderTooltip: `The leading competence: Leadership.
    Strong focus on the team and the goal of the project, 
    taking responsibility for the technical results delivered by the team and “big picture thinking”. 
    Focus on communicating stakeholders’ requirements and expectations and their reflection in the final product, 
    ensuring an effective flow of communication between various interest groups. Focus on avoiding/eliminating tech debt.
    Leads team by example, building a culture of trust in the team and being a point of tech reference for team members.`,
    consultantTooltip: `The leading competence: Effective communication.
    Strong technical plus people&communication focus. Area of expertise includes consulting 
    at the level of project strategy/business needs of the project, its design and implementation plan, 
    in close cooperation with developers/business/clients. Works in various environments and various teams, 
    in which takes leadership and manages the work/development strategy. 
    Strong focus is on tech as well as on the business side of the projects.`,
    mentorTooltip: `The leading competence: Knowledge sharing.
    Strong focus on sharing knowledge, experience, and good practices with other team members. 
    Acting as a guide, coach, and buddy, when needed. Make sure their mentee has the full potential to develop your potential.
    Focus on listening, observing, advising, taking control when needed, and giving space when needed. 
    Sharing best practices in the area.`,
    speakerTooltip: `The leading competence: Effective communication.
    Strong focus on the representation of the organization through speeches and sharing knowledge outside the organization. 
    Strongly developed ability to present, communicate and effectively transfer knowledge and experiences. 
    Focus on expertise in a given topic and openness to engaging in dialogue/discussion.`,
    languagesSection: {
      empty: "You can add here languages you speak",
      language: "Language",
      level: "Level",
      Elementary: "Elementary proficiency",
      Limited: "Limited working proficiency",
      Professional: "Professional working proficiency",
      FullyProfessional: "Full professional proficiency",
      Native: "Native or bilingual proficiency",
    },
    languages: "Languages",
    nothingHere: "Nothing is here, so sad ;-(",
    projects: "Projects",
    degree: "Degree",
    degrees: "Degrees",
    courses: "Courses",
    goals: "Development Goals {{year}}",
    goalsSubheader: "Goals",
    contributions: "Contributions",
    contributionUrl: "Contribution URL",
    awards: "Awards",
    certificates: "Certificates",
    certificateUrl: "Certificate URL",
    careers: "Careers",
    articles: "Articles",
    publicationUrl: "Publication URL",
    talks: "Talks",
    eventUrl: "Event URL",
    videoUrl: "Video URL",
    tutors: "Tutors",
    techStack: "Tech stack",
    seeAllSkills: "See all skills",
    skills: "Skills",
    skillsLegend: {
      small: "I’ve been working with it in production context (small scale).",
      big: "I’ve been working with it in production context (big scale).",
      large:
        "I’ve extensive experience with it in many different production context (large scale).",
    },
    add: "add",
    edit: "edit",
    comfortable: "Comfortable hours",
    possible: "Possible hours",
    project: "Project hours",
  },
  expandedCard: {
    close: "Close",
  },
  checkboxSearchSelect: {
    selectAll: "Select all",
    selected: "Selected",
    numberSelected: "{{ number }} selected",
    noOptions: "No options",
  },
  copyLinkIconWithTooltip: {
    copied: "Copied",
  },
  prevNextNavigation: {
    next: "Next",
    back: "Back",
    unsaved: "You have unsaved changes",
  },
  chip: {
    status: {
      approved: "Interested",
      rejected: "Not interested",
    },
  },
  sortBar: { noResults: `No results for "{{ searchValue }}"` },
  sorter: {
    sortBy: "Sort by",
    order: "Order",
  },
  filter: {
    selectAll: "Select all",
  },
  datepicker2: {
    today: "Today",
    thisMonth: "This month",
  },
  actions: {
    clearAll: "Clear all",
    clear: "Clear",
  },
  emptyList: { noResults: `No results for "{{ searchValue }}"` },
};
