import { ReactElement, useRef, useEffect } from "react";
import { classJoin } from "@ps/utils";
import { useThemeMode } from "../theme-mode-provider";
import { NavIconProps } from "./types";

const NavIcon = ({ Icon, isActive }: NavIconProps): ReactElement => {
  const { isHighContrast } = useThemeMode();
  const activeIconRef = useRef<SVGSVGElement>(null);

  const scrollEditForm = (): void => {
    activeIconRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  useEffect(() => {
    if (isActive) scrollEditForm();
  }, [[isActive]]);

  const getIconColor = () => {
    if (isHighContrast) return isActive ? "text-primary-30" : "text-primary-95";
    return isActive ? "text-primary-100" : " text-primary-60";
  };

  return (
    <Icon
      className={classJoin(
        "fill-current stroke-current w-4 h-4",
        getIconColor(),
      )}
      ref={activeIconRef}
    />
  );
};

export default NavIcon;
