import APIService from "../api/user-api";
import {
  allRolesFromAPIToModel,
  fromAPItoModel,
  NewRoleModel,
  newRoleToAPIModel,
  updatedRoleToAPIModel,
  UpdateRoleAPIModel,
  NewUserModel,
} from "../models";
import { handleError } from "../../shared";
import { newUsersListToAPIModel } from "../models/coverters/toAPIModel";
import messages from "./messages.json";

const UserService = (() => {
  const fetchUsersList = async () => {
    try {
      const response = await APIService.API.getUsersList();
      if (response.data)
        return fromAPItoModel.usersListFromAPItoModel(response.data);
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchUserData = async (userId: string) => {
    try {
      const response = await APIService.API.getUser(userId);
      if (response.data)
        return fromAPItoModel.userFromAPItoModel(response.data);
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const createUsers = async (newUsers: NewUserModel[], silentMode = false) => {
    try {
      const result = await APIService.API.createUsers(
        {
          users: newUsersListToAPIModel(newUsers),
        },
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          silentMode,
          customMessages: {
            201: {
              message: messages.createUsers201,
            },
          },
        },
      );
      return result;
    } catch (error) {
      return handleError(error);
    }
  };

  const updateUser = async (userId: string, newRoles: string[]) => {
    try {
      const response = await APIService.API.updateUserRoles(userId, newRoles);
      return response;
    } catch (error) {
      return handleError(error);
    }
  };

  const setUserArchive = async (userId: string, archived = false) => {
    try {
      if (archived) {
        const response = await APIService.API.unArchive(userId);
        return response;
      }
      const response = await APIService.API.archive(userId);
      return response;
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchAllUsersRoles = async () => {
    try {
      const response = await APIService.API.getAllRoles();
      if (response.data) return allRolesFromAPIToModel(response.data);
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const createRole = async (data: NewRoleModel) => {
    try {
      const result = await APIService.API.createRole(newRoleToAPIModel(data));
      return result;
    } catch (error) {
      return handleError(error);
    }
  };

  const updateRole = async (roleId: string, data: UpdateRoleAPIModel) => {
    try {
      const result = await APIService.API.updateRole(
        roleId,
        updatedRoleToAPIModel(data),
      );
      return result;
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteRole = async (roleId: string) => {
    try {
      const result = await APIService.API.deleteRole(roleId);
      return result;
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchUserOCRFlag = async (userId: string): Promise<boolean> => {
    try {
      const response = await APIService.API.getOcrWizardFlag(userId);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    createRole,
    deleteRole,
    fetchAllUsersRoles,
    fetchUserData,
    fetchUsersList,
    updateRole,
    createUsers,
    updateUser,
    setUserArchive,
    fetchUserOCRFlag,
  };
})();

export default UserService;
