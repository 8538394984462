import { Button } from "@ps/ui-components";
import { ReactElement } from "react";
import { ReactComponent as NoTemplatesFrame } from "../../../images/finance/no-templates-frame.svg";
import { ReactComponent as IconTextSnippet } from "../../../images/finance/text-snippet.svg";
import { useTranslationWithNamespace } from "../../../hooks";
import {
  HOLIDAYS_TEMPLATES,
  ADD_TEMPLATE,
  BASE_TRANSLATION_PATH,
} from "../constants";
import { EmptyTableProps } from "./types";

const EmptyTable = ({ onAddTemplate }: EmptyTableProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="flex w-full h-full gap-10 justify-center items-center">
      <NoTemplatesFrame />

      <div className="flex flex-col justify-center gap-9">
        <div className="flex flex-col">
          <span className="font-semibold text-xl">
            {t(`${BASE_TRANSLATION_PATH}.noInfoHere`)}
          </span>
          <span>{t(`${BASE_TRANSLATION_PATH}.youCanAddHolidays`)}</span>
        </div>

        <Button
          dataCy={`${HOLIDAYS_TEMPLATES}_${ADD_TEMPLATE}`}
          onClick={onAddTemplate}
        >
          <IconTextSnippet className="fill-current mr-3" />
          <span>{t(`${BASE_TRANSLATION_PATH}.${ADD_TEMPLATE}`)}</span>
        </Button>
      </div>
    </div>
  );
};

export default EmptyTable;
