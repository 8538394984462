import { AnyAction, Dispatch } from "redux";
import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import { BenchUserAPIModel } from "../models";
import { BenchService } from "../services";
import ActionTypes from "./actionTypes";
import { FilterPayload, IAction, SorterPayload } from "./types";

export const fetchBenchUsersStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_BENCH_USERS_START);
export const fetchBenchUsersSuccess = (
  payload: IAction<BenchUserAPIModel>,
): AnyAction => actionCreator(ActionTypes.FETCH_BENCH_USERS_SUCCESS, payload);
export const fetchBenchUsersError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_BENCH_USERS_ERROR, payload);

export const fetchBenchUsers = async (
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchBenchUsersStart());
  const response = await BenchService.fetchBenchUsers();
  if (isAPIError(response)) {
    dispatch(fetchBenchUsersError(response));
  } else {
    dispatch(fetchBenchUsersSuccess(response));
  }
};

export const addFilter = (payload: FilterPayload): AnyAction =>
  actionCreator(ActionTypes.ADD_BENCH_USERS_FILTER, payload);
export const removeFilter = (payload: FilterPayload): AnyAction =>
  actionCreator(ActionTypes.REMOVE_BENCH_USERS_FILTER, payload);
export const resetFilters = (): AnyAction =>
  actionCreator(ActionTypes.RESET_BENCH_USERS_FILTERS);

export const filterBenchUsersByProperty = (
  dispatch: Dispatch,
  filterName: string,
  optionId: string,
  isChecked: boolean,
): AnyAction =>
  isChecked
    ? dispatch(removeFilter({ filterName, optionId }))
    : dispatch(addFilter({ filterName, optionId }));

export const resetBenchUsersByPropertyFilters = (
  dispatch: Dispatch,
): AnyAction => dispatch(resetFilters());

export const setSorter = (payload: SorterPayload): AnyAction =>
  actionCreator(ActionTypes.SET_BENCH_USERS_SORTER, payload);

export const setSorterByProperty = (
  dispatch: Dispatch,
  order: string,
  name: string,
): AnyAction => dispatch(setSorter({ order, name }));

export const addOpenedFolderStatus = (payload: string): AnyAction =>
  actionCreator(ActionTypes.ADD_OPENED_FOLDER_STATUS, payload);
export const removeOpenedFolderStatus = (payload: string): AnyAction =>
  actionCreator(ActionTypes.REMOVE_OPENED_FOLDER_STATUS, payload);
export const resetOpenedFoldersStatuses = (): AnyAction =>
  actionCreator(ActionTypes.RESET_OPENED_FOLDER_STATUSES);

export const setOpenedFoldersStatuses = (
  dispatch: Dispatch,
  userId: string,
  adding = true,
): AnyAction =>
  adding
    ? dispatch(addOpenedFolderStatus(userId))
    : dispatch(removeOpenedFolderStatus(userId));

export const setBenchUserId = (payload: string): AnyAction =>
  actionCreator(ActionTypes.SET_BENCH_USER_ID, payload);

export const resetBenchUserId = (): AnyAction =>
  actionCreator(ActionTypes.RESET_BENCH_USER_ID);
