import {
  CandidateMLAPIModel,
  CandidatesMLResponseAPIModel,
  CandidateMLModel,
  CandidatesMLResponseModel,
} from "../types";

export const fromAPI = (
  data: CandidatesMLResponseAPIModel[],
): CandidatesMLResponseModel[] =>
  data.map(
    (item: CandidatesMLResponseAPIModel): CandidatesMLResponseModel => ({
      needId: item.needId,
      candidates: item?.candidates?.length
        ? item.candidates.map(
            (candidate: CandidateMLAPIModel): CandidateMLModel => ({
              userId: candidate.id,
              firstName: candidate.firstName || "",
              lastName: candidate.lastName || "",
              score: candidate.score,
              avatar: candidate?.avatar,
              seniority: candidate.seniority,
              mainProfession: candidate.profession,
            }),
          )
        : [],
    }),
  );

export const singleNeedFromAPI = (
  data: CandidatesMLResponseAPIModel,
): CandidatesMLResponseModel => ({
  needId: data.needId,
  candidates: data?.candidates?.length
    ? data.candidates
        ?.filter(
          (singleCandidate: CandidateMLAPIModel) => singleCandidate.score > 0.0,
        )
        ?.map(
          (candidate: CandidateMLAPIModel): CandidateMLModel => ({
            userId: candidate.id,
            firstName: candidate.firstName || "",
            lastName: candidate.lastName || "",
            score: candidate.score,
            avatar: candidate?.avatar,
            seniority: candidate.seniority,
            mainProfession: candidate.profession,
          }),
        )
    : [],
});
export default fromAPI;
