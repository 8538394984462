/* eslint @typescript-eslint/no-explicit-any: 0 */
import { ReactElement, useEffect, useState } from "react";
import { classJoin, sortArray } from "@ps/utils";
import { uid } from "react-uid";
import {
  Button,
  Label,
  LinkButton,
  TableHeader,
  TableLabel,
} from "@ps/ui-components";
import dayjs from "dayjs";
import {
  AMOUNT_OF_DAYS,
  BASE_TRANSLATION_PATH,
  DATE_FROM,
  DATES,
  HOLIDAY_TYPE,
  HOLIDAYS_USER_HISTORY,
  TYPE_NAME,
} from "../../constants";
import styles from "../styles.module.css";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { ISorter } from "../../../../../bench-domain/ui/row/types";
// import FinanceService from "../../../services"; TODO holidays when BE will be ready
import { HolidaysUserHistoryModel } from "../../../../models/types";
import HolidaysHistoryActionButtons from "./holidaysHistoryActionButtons";
import RemoveUserHolidayEntryModal from "./removeUserHolidayEntryModal";
import { HolidaysHistoryProps, IFilterOption } from "../types";
import {
  getCheckedNames,
  prepareFilteredEntries,
  prepareFilterOptions,
} from "../utils";
import FilterDropdownContent from "./filterDropdownContent";
import AddHolidayModal from "./addHolidayModal";
import { ReactComponent as FunnelIcon } from "../../../../../images/funnel.svg";

const filtersInitialState = {
  [HOLIDAY_TYPE]: [],
};

const HolidaysHistory = ({
  history,
  templateEntries,
  isAdmin = false,
  onRemove,
}: // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// TODO add missing types
HolidaysHistoryProps | any): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [sorter, setSorter] = useState<ISorter>({
    order: "asc",
    name: DATE_FROM,
  });
  const [allEntriesList, setAlEntriesList] = useState<
    HolidaysUserHistoryModel[]
  >([]);
  const [allFilterOptions, setAllFilterOptions] = useState<IFilterOption[]>([]);
  const [itemsToDisplay, setItemsToDisplay] = useState<
    HolidaysUserHistoryModel[]
  >([]);
  const [isOnAddModalVisible, setIsOnAddModalVisible] = useState(false);
  const [removeItemIds, setRemoveItemIds] = useState<null | string[]>(null);
  const [entryInEditMode, setEntryInEditMode] = useState<null | number>(null);
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState<{ [HOLIDAY_TYPE]: IFilterOption[] }>(
    filtersInitialState,
  );
  const [filterOptionsToDisplay, setFilterOptionsToDisplay] =
    useState<IFilterOption[]>(allFilterOptions);

  useEffect(() => {
    if (history?.length) {
      const selectedDataToFilterOptions = prepareFilterOptions(history);
      const sorted = sortArray(
        selectedDataToFilterOptions,
        sorter?.name,
        sorter?.order,
      );
      setAllFilterOptions(sorted);
      setFilterOptionsToDisplay(sorted);
    }
    setAlEntriesList(history);
    const result = prepareFilteredEntries(history, filters);
    if (!result?.length) setItemsToDisplay([]);
    else {
      const toDisplay = sortArray(result, sorter.name, sorter.order);
      setItemsToDisplay(toDisplay);
    }
  }, [history]);

  const handleOnClickSorter = (fieldName: string): void => {
    const order = sorter?.order === "desc" ? "asc" : "desc";
    const sorted = sortArray(itemsToDisplay, fieldName, order);
    setItemsToDisplay(sorted);
    setSorter({ order, name: fieldName });
  };

  const handleOnClearFilters = (): void => {
    setFilters(filtersInitialState);
    setItemsToDisplay(
      prepareFilteredEntries(allEntriesList, filtersInitialState),
    );
  };

  return (
    <div className="flex flex-col">
      <LinkButton
        additionalClassName="justify-end"
        dataCy={HOLIDAYS_USER_HISTORY}
        onClick={handleOnClearFilters}
        prefixIcon={<FunnelIcon className="fill-current" />}
        disabled={
          !Object.values(filters).some(
            (value: IFilterOption[]) => value?.length,
          )
        }
      >
        {t("actions.clearFilters")}
      </LinkButton>
      <TableHeader
        dataCy={HOLIDAYS_USER_HISTORY}
        columnsStyles={styles.historyTableColumns}
      >
        <TableLabel
          dataCy={`${HOLIDAYS_USER_HISTORY}_${DATES}`}
          sorting={{
            byName: DATE_FROM,
            isActive: sorter?.name === DATE_FROM,
            onClick: () => handleOnClickSorter(DATE_FROM),
          }}
        >
          {t(`${BASE_TRANSLATION_PATH}.history.${DATES}`)}
        </TableLabel>
        <TableLabel
          dataCy={`${HOLIDAYS_USER_HISTORY}_${HOLIDAY_TYPE}`}
          sorting={{
            byName: TYPE_NAME,
            isActive: sorter?.name === TYPE_NAME,
            onClick: () => handleOnClickSorter(TYPE_NAME),
          }}
          filtering={
            filterOptionsToDisplay
              ? {
                  checked: getCheckedNames(
                    allFilterOptions,
                    filters,
                    HOLIDAY_TYPE,
                  ),
                  dropdownOverlay: (
                    <FilterDropdownContent
                      allEntriesList={allEntriesList}
                      filters={filters}
                      prepareItemsToDisplay={setItemsToDisplay}
                      allFilterOptions={allFilterOptions}
                      filterOptionsToDisplay={filterOptionsToDisplay}
                      filterName={HOLIDAY_TYPE}
                      setFilters={setFilters}
                      setSearchValue={setSearchValue}
                      searchValue={searchValue}
                      prepareFilterOptionsToDisplay={setFilterOptionsToDisplay}
                    />
                  ),
                }
              : undefined
          }
        >
          {t(`${BASE_TRANSLATION_PATH}.history.${HOLIDAY_TYPE}`)}
        </TableLabel>
        <TableLabel dataCy={`${HOLIDAYS_USER_HISTORY}_${AMOUNT_OF_DAYS}`}>
          {t(`${BASE_TRANSLATION_PATH}.history.${AMOUNT_OF_DAYS}`)}
        </TableLabel>
        {isAdmin ? (
          <Button
            additionalClass="ml-auto"
            dataCy={HOLIDAYS_USER_HISTORY}
            onClick={() => {
              setIsOnAddModalVisible(true);
            }}
          >
            {t(`${BASE_TRANSLATION_PATH}.history.addLeave`)}
          </Button>
        ) : (
          <span />
        )}
      </TableHeader>
      {itemsToDisplay?.length ? (
        itemsToDisplay.map((item: HolidaysUserHistoryModel, index: number) => (
          <div
            className={classJoin(
              "justify-center py-6 last:border-0 gap-1 px-8",
              styles.historyTableColumns,
              isAdmin
                ? "border-b border-neutralSecondary-60"
                : "rounded-md bg-neutralPrimary-100 mb-4",
            )}
            key={uid(item)}
          >
            {/* Edit modal */}
            {entryInEditMode === index && templateEntries?.length && (
              <AddHolidayModal
                defaultData={item}
                isOpen={entryInEditMode === index}
                onClose={() => setEntryInEditMode(null)}
                holidayTypesList={templateEntries || []}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onSave={(data: HolidaysUserHistoryModel) => {
                  // TODO holidays - add updating and fetching
                  // FinanceService.updateUserEntryHolidays(data);
                  // fetchUserHolidayEntries()
                  // TO DO
                }}
              />
            )}

            <Label dataCy={`${HOLIDAYS_USER_HISTORY}_${DATES}`}>
              {`${dayjs(item.dateFrom).format("DD/MM/YYYY")} - ${dayjs(
                item.dateTo,
              ).format("DD/MM/YYYY")}`}
            </Label>
            <Label dataCy={`${HOLIDAYS_USER_HISTORY}_${TYPE_NAME}`}>
              {item.typeName}
            </Label>

            <Label dataCy={`${HOLIDAYS_USER_HISTORY}_${AMOUNT_OF_DAYS}`}>
              {item?.amountOfDays < 1
                ? t(`${BASE_TRANSLATION_PATH}.history.days`, {
                    count: (item?.amountOfDays).toString(),
                  })
                : t(`${BASE_TRANSLATION_PATH}.history.days`, {
                    count: item?.amountOfDays,
                  })}
            </Label>
            <HolidaysHistoryActionButtons
              canEdit={isAdmin}
              canRemove={
                isAdmin || (!isAdmin && item.dateFrom?.isAfter(dayjs()))
              }
              onEdit={() => setEntryInEditMode(index)}
              onRemove={() => setRemoveItemIds(item.entryIds)}
            />
          </div>
        ))
      ) : (
        <></>
      )}
      <AddHolidayModal
        isOpen={isOnAddModalVisible}
        onClose={() => setIsOnAddModalVisible(false)}
        holidayTypesList={templateEntries || []}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSave={(data: HolidaysUserHistoryModel) => {
          // FinanceService.addNewEntry(data);
          // TODO holidays
          // fetchUserHolidayEntries()
          // TODO holidays
        }}
      />
      {removeItemIds && (
        <RemoveUserHolidayEntryModal
          isOpen={!!removeItemIds}
          onClose={() => setRemoveItemIds(null)}
          onRemove={() => onRemove(removeItemIds)}
        />
      )}
    </div>
  );
};

export default HolidaysHistory;
