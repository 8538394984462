import { ReactElement } from "react";
import { Route } from "react-router-dom";
import { Page403, RestrictedWrapper } from "@ps/ui-components";
import { PAGE_403_APP } from "../data-cy";
import { RestrictedRouteProps } from "./types";

const RestrictedRoute = ({
  permissions,
  children,
  path,
  isVisibleForTenant = true,
  isFetchingData = false,
}: RestrictedRouteProps): ReactElement => {
  const renderContent = (): ReactElement | Element => {
    if (
      (isVisibleForTenant && !permissions?.length) ||
      (isFetchingData && !permissions)
    )
      return children;
    if (isVisibleForTenant && permissions)
      return (
        <RestrictedWrapper
          permissions={permissions}
          noPermissionsComponent={<Page403 dataCy={PAGE_403_APP} path="/" />}
        >
          {children}
        </RestrictedWrapper>
      );

    return <Page403 dataCy={PAGE_403_APP} path="/" />;
  };
  return <Route path={path}>{renderContent()}</Route>;
};

export default RestrictedRoute;
