export const ANONYMOUS = "anonymous";
export const BLIND = "blind";
export const CURRENT_YEAR = new Date().getFullYear().toString();
export const PREVIOUS_YEAR = (new Date().getFullYear() - 1).toString();
export const ARTICLES = "articles";
export const TALKS = "talks";
export const AWARDS = "awards";
export const CERTIFICATES = "certificates";
export const TUTORS = "tutors";
export const CONTRIBUTIONS = "contributions";
export const DEGREES = "degrees";
export const COURSES = "courses";
