import { classJoin } from "@ps/utils";
import { ReactElement } from "react";
import { SAVE_CANCEL_INFO_BAR_PREFIX } from "../../shared/data-cy";
import Button from "../button";
import { BAR_LABEL, CANCEL, SAVE } from "./constants";
import { SaveCancelBarProps } from "./types";

const SaveCancelBar = ({
  dataCy,
  barLabel,
  additionalClass,
  additionalLabelClass,
  saveButton,
  cancelButton,
}: SaveCancelBarProps): ReactElement => {
  const {
    label: saveButtonLabel,
    variant: saveButtonVariant,
    ...saveButtonArgs
  } = saveButton;
  const {
    label: cancelButtonLabel,
    variant: cancelVariant,
    ...cancelButtonArgs
  } = cancelButton;

  return (
    <div
      data-cy={`${SAVE_CANCEL_INFO_BAR_PREFIX}-${dataCy}`}
      className={classJoin(
        `flex gap-8 items-center justify-end w-full h-32 fixed bottom-0 right-0
        bg-neutralPrimary-100 border-t border-neutralSecondary-60 pr-16`,
        additionalClass,
      )}
    >
      <span
        data-cy={`${SAVE_CANCEL_INFO_BAR_PREFIX}-${dataCy}-${BAR_LABEL}`}
        className={classJoin(
          "font-semibold text-xl text-neutralPrimary-20",
          additionalLabelClass,
        )}
      >
        {barLabel}
      </span>

      <div className="flex gap-4">
        <Button
          dataCy={`${SAVE_CANCEL_INFO_BAR_PREFIX}-${dataCy}-${SAVE}`}
          variant={saveButtonVariant || "primary"}
          {...saveButtonArgs}
        >
          {saveButtonLabel}
        </Button>

        <Button
          dataCy={`${SAVE_CANCEL_INFO_BAR_PREFIX}-${dataCy}-${CANCEL}`}
          variant={cancelVariant || "secondary"}
          {...cancelButtonArgs}
        >
          {cancelButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default SaveCancelBar;
