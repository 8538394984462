import { TimeTrackerInput } from "@ps/ui-components";
import { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { validations } from "@ps/utils";
import { useTranslationWithNamespace } from "../../hooks";
import { TimeRangeComponentProps } from "./types";

const TimeRangeComponent = ({
  type,
  timeMoment,
  name,
  autoFocus = false,
}: TimeRangeComponentProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { control, clearErrors } = useFormContext();

  const translate = (msg: string) => t(`profile.overview.errors.${msg}`);

  return (
    <Controller
      control={control}
      name={`${name}.${type}.${timeMoment}`}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TimeTrackerInput
          autoFocus={autoFocus}
          dataCy={`${name}_${type}_${timeMoment}`}
          error={!!error}
          message={error?.message || ""}
          onBlur={onChange}
          value={value}
          placeholder="HHMM"
          onFocus={() => clearErrors(`${name}.${type}.${timeMoment}`)}
        />
      )}
      rules={{
        validate: (value) =>
          validations.validateWorkingHours(
            value,
            translate("cantBeEmpty"),
            translate("wrongHoursFormat"),
          ),
      }}
    />
  );
};

export default TimeRangeComponent;
