import { ReactElement } from "react";
import { SearchFilter, SearchFilterCheckboxOption } from "@ps/ui-components";
import { Controller, useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import { IProjectOption } from "../types";
import { ProjectsFilterProps } from "./types";
import { useTranslationWithNamespace } from "../../../hooks";
import { PROJECT, PROJECTS } from "../../../shared/constants";
import { REPORTS } from "../../../shared/data-cy";
import { updatePillsList, updateSelectedAllFiltersList } from "./utils";
import { ALL_PROJECT_SELECTED } from "../constants";

const ProjectsFilter = ({
  filtersSelectedList,
  projectsList,
  searchValue,
  selectedLength,
  setSearchValue,
  id,
}: ProjectsFilterProps): ReactElement => {
  const { reset, getValues, setValue, control, watch } = useFormContext();
  const { t } = useTranslationWithNamespace();

  const { fields, append } = filtersSelectedList;

  const selectUnselectAll = (isSelectAll: boolean): void => {
    reset(
      {
        ...getValues(),
        [PROJECTS]: getValues(PROJECTS).map((item) => ({
          ...item,
          checked: !isSelectAll,
        })),
      },
      { keepDirty: true },
    );
    updateSelectedAllFiltersList(
      isSelectAll,
      PROJECT,
      fields || [],
      reset,
      getValues(),
      projectsList,
    );
    setValue(ALL_PROJECT_SELECTED, !isSelectAll, { shouldDirty: true });
  };

  return (
    <div className="max-w-72">
      <SearchFilter
        id={id}
        clearAllDisabled={!selectedLength}
        dataCy={`${REPORTS}_projects`}
        isAllSelected={
          watch(PROJECTS)?.filter((item) => item.checked)?.length ===
          projectsList.length
        }
        isPartialSelected={
          !!(selectedLength && selectedLength < projectsList.length)
        }
        onClearAllClick={() => selectUnselectAll(true)}
        onSearch={setSearchValue}
        onSelectAllClick={() => {
          selectUnselectAll(getValues(ALL_PROJECT_SELECTED));
        }}
        placeholder={t("reports.projects")}
        searchValue={searchValue}
        selectedItemsAmount={selectedLength}
      >
        {projectsList
          ?.filter(
            (item: IProjectOption) =>
              !searchValue ||
              item.name?.toLowerCase().includes(searchValue.toLowerCase()),
          )
          ?.map(
            (item: IProjectOption, index: number, arr: IProjectOption[]) => (
              <div key={uid(item)} className="flex flex-col">
                {(!index || item.clientId !== arr[index - 1]?.clientId) && (
                  <span
                    className={classJoin(
                      "uppercase text-neutralPrimary-50",
                      index ? "pt-3" : "pt-0",
                    )}
                  >
                    {item.clientName || t("reports.withoutClient")}
                  </span>
                )}
                <Controller
                  name={`${PROJECTS}.[${item.idInArray}].checked`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SearchFilterCheckboxOption
                      {...item}
                      checked={value}
                      dataCy={item.itemId}
                      onClick={(newValue) => {
                        onChange(!newValue);
                        updatePillsList(
                          newValue,
                          item,
                          reset,
                          getValues(),
                          fields,
                          append,
                        );
                      }}
                    />
                  )}
                />
              </div>
            ),
          )}
      </SearchFilter>
    </div>
  );
};
export default ProjectsFilter;
