import { ReactElement } from "react";
import { Button, Modal, ModalContent } from "@ps/ui-components";
import { ReactComponent as RemoveImage } from "../../../images/remove-technology-group.svg";
import { useTranslationWithNamespace } from "../../../hooks";
import {
  BASE_TRANSLATION_PATH,
  HOLIDAYS_TEMPLATES,
  DELETE_MODAL,
  CANCEL,
  DELETE,
} from "../constants";
import { DeleteModalProps } from "./types";

const DeleteModal = ({
  modalInfo,
  isOpen,
  onDelete,
  onCancel,
}: DeleteModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const { name, type } = modalInfo;

  return (
    <Modal
      dataCy={`${HOLIDAYS_TEMPLATES}_${DELETE_MODAL}`}
      isOpen={isOpen}
      onClose={onCancel}
      height="h-auto"
      width="w-max"
      withCloseIcon
      withOutsideClick
    >
      <ModalContent additionalClassName="flex flex-row gap-12 py-8 w-full">
        <RemoveImage className="w-1/3" />
        <div className="flex flex-col gap-2 justify-center items-start pt-2">
          <span className="text-2xl font-semibold">
            {t(`${BASE_TRANSLATION_PATH}.${DELETE_MODAL}.deleteTitle`, {
              type,
              name,
            })}
          </span>

          <span className="text-sm">
            {t(`${BASE_TRANSLATION_PATH}.${DELETE_MODAL}.secondaryText`, {
              type,
            })}
          </span>

          <div className="flex gap-4 pt-2">
            <Button
              dataCy={`${HOLIDAYS_TEMPLATES}_${DELETE_MODAL}_${DELETE}`}
              onClick={onDelete}
            >
              {t(`${BASE_TRANSLATION_PATH}.${DELETE_MODAL}.${DELETE}`)}
            </Button>

            <Button
              dataCy={`${HOLIDAYS_TEMPLATES}_${DELETE_MODAL}_${CANCEL}`}
              variant="secondary"
              onClick={onCancel}
            >
              {t(`${BASE_TRANSLATION_PATH}.${DELETE_MODAL}.${CANCEL}`)}
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
