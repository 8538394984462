import { motion, AnimatePresence } from "framer-motion";
import { ReactElement } from "react";
import useNotifications from "../hooks/useNotifications";
import { Notification } from "../types";
import SingleNotification from "./singleNotification";

const NotificationsContainer = (): ReactElement => {
  const { notifications } = useNotifications();

  return (
    <div className="z-50 absolute top-16 right-4 flex items-end flex-col">
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <AnimatePresence initial={false}>
        {notifications.map((notification: Notification) => (
          <motion.div
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
            className="p-2"
            key={notification.id}
            layout
          >
            <SingleNotification notification={notification} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default NotificationsContainer;
