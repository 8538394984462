import { useDispatch } from "redux-react-hook";
import { UseSkills, UseSkillsMapState, UseSkillsMapStateReturn } from "./types";
import { useMappedStateSelector } from "../../hooks";
import { DOMAINS_PROFESSION_ID } from "../shared/consts";
import { FulfillmentModel, UpdateFulfillmentModel } from "../models";
import { updateFulfillments } from "../store";

const useSkills = (): UseSkills => {
  const dispatch = useDispatch();

  const mapState = (state: UseSkillsMapState): UseSkillsMapStateReturn => ({
    fulfillments: state?.skillsMatrix?.fulfillments,
  });

  const { fulfillments } = useMappedStateSelector(mapState);

  const deleteTechnologyFromTemplate: (
    professionId: string,
    technologyId: string,
  ) => Promise<void> = async (professionId: string, technologyId: string) => {
    const postValues = Object.entries(
      fulfillments?.[professionId] ?? {},
    ).reduce((acc, [key, entry]) => {
      if (key !== technologyId) {
        return { ...acc, [key]: entry };
      }
      return acc;
    }, {});

    await updateFulfillments(professionId, dispatch, postValues);
  };

  const updateFulfillment: (
    professionId: string,
    values: UpdateFulfillmentModel,
  ) => Promise<void> = async (
    professionId: string,
    values: UpdateFulfillmentModel,
  ) => {
    const filteredValues = Object.entries(values).reduce(
      (acc, [key, entry]) => {
        return { ...acc, [key]: entry };
      },
      {},
    );

    const postValues: FulfillmentModel = {
      ...(fulfillments?.[professionId] ?? {}),
      ...filteredValues,
    };

    await updateFulfillments(
      professionId,
      dispatch,
      professionId === DOMAINS_PROFESSION_ID ? postValues : filteredValues,
      postValues,
    );
  };

  return {
    deleteTechnologyFromTemplate,
    updateFulfillment,
  };
};

export default useSkills;
