import { ReactElement, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { LoadingMainPage } from "@ps/ui-components";
import { useParams } from "react-router-dom";
import { fetchProspectsList } from "../../store";
import { useMappedStateSelector } from "../../../hooks";
import ProspectsListWrapper from "../common/prospectsListWrapper";
import { MyProspectsMapState, MyProspectsMapStateReturn } from "./types";

const MyProspects = (): ReactElement => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const mapState = (state: MyProspectsMapState): MyProspectsMapStateReturn => ({
    prospectsList: state?.prospects?.prospectsList || [],
    isLoading: state?.requestStatus?.isFetchingProspectsList,
  });

  const { prospectsList } = useMappedStateSelector(mapState);

  useEffect(() => {
    fetchProspectsList(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading)
    return <LoadingMainPage additionalClassName="bg-neutralPrimary-85" />;

  return (
    <ProspectsListWrapper clientId={params.id} prospects={prospectsList} />
  );
};

export default MyProspects;
