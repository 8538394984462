import dayjs from "dayjs";
import { Selection, select } from "d3";
import { colorFormatters, minutesToHHMM } from "@ps/utils";
import { getProperProject, getProperProjectByDay } from "./helpers";
import { IData, ProjectModel } from "../types";

export const mouseOver = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: Record<string, any>,
  svg: Selection<SVGSVGElement | null, unknown, null, undefined>,
  tooltipNode: Selection<HTMLDivElement | null, unknown, null, undefined>,
  allProjects: ProjectModel[],
  data: IData,
  isYear = false,
): void => {
  svg.selectAll("rect").style("opacity", 0.3);
  tooltipNode.style("opacity", 1);
  event.target.setAttribute("style", "opacity: 1;");

  const { parentNode } = event?.target;

  const projectId: string = parentNode.getAttribute("data-project-id");
  const dayDate: string = event.target.getAttribute("data-day-date");
  const currentProject = getProperProject(allProjects, projectId);
  const projectTimeByDay: number =
    getProperProjectByDay(data, dayDate, projectId) || 0;
  select(".project-name").html(currentProject?.details?.name || "");

  select(".project-dot-color").style(
    "background-color",
    // eslint-disable-next-line
    // @ts-ignore
    colorFormatters.hexToRgbString(currentProject?.details?.color) ||
      "rgb(var(--color-neutral-primary-100))",
  );

  select(".project-time-engagement").html(minutesToHHMM(projectTimeByDay * 60));
  select(".tooltip-date").html(
    isYear ? dayDate : dayjs(dayDate, "DD.MM.YYYY").format("ddd, DD.MM"),
  );
};

export const mouseMove = (
  event: MouseEvent,
  tooltipNode: Selection<HTMLDivElement | null, unknown, null, undefined>,
): void => {
  tooltipNode
    .style("left", `${event.x - 130}px`)
    .style("top", `${event.y - 220}px`)
    .style("width", "max-content");
};

export const mouseLeave = (
  tooltipNode: Selection<HTMLDivElement | null, unknown, null, undefined>,
  svg: Selection<SVGSVGElement | null, unknown, null, undefined>,
): void => {
  tooltipNode.style("opacity", 0);
  svg.selectAll("rect").style("opacity", 1);
};
