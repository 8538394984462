import React, { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import {
  DEFAULT_COLOR,
  getAverage,
  getColor,
  getXOffset,
  getYOffset,
} from "./utils";
import LabelLine from "./labelLine";
import { LabelProps } from "./types";
import { useSkillsContext } from "./context";

const Label = ({
  cx,
  cy,
  datum,
  handleOnClick,
  index,
  innerRadius,
  radius,
  renderIcon,
  slice,
  text,
}: LabelProps): ReactElement => {
  const { selectedSection } = useSkillsContext();

  const middleRadius = getAverage([innerRadius, radius]);
  const midAngle = getAverage([slice?.endAngle || 0, slice?.startAngle || 0]);

  const constantOffsetNumber = (datum?.index || 0) % 2 ? 2 : 1.8;

  const labelOffset = constantOffsetNumber * radius - middleRadius;
  const tempX = cx + getXOffset(labelOffset, midAngle);
  const x = cx < tempX ? tempX + 45 : tempX - 20;

  const y = cy + getYOffset(labelOffset, midAngle) - 6;

  const textAnchor = cx < tempX ? "start" : "end";

  const Icon = renderIcon(datum?.x || "0");
  const iconX = cx < tempX ? tempX + 18 : tempX - 18;
  const iconY = y - 19;

  const currentColor = getColor(index || 0) || DEFAULT_COLOR;

  return (
    <g>
      <g
        onClick={() => handleOnClick(datum?.x)}
        className={classJoin(
          "cursor-default",
          !selectedSection || selectedSection === datum?.x
            ? "opacity-100"
            : "opacity-30",
        )}
      >
        <text
          className="text-2xl sm:text-lg"
          fill={DEFAULT_COLOR}
          textAnchor={textAnchor}
          x={x}
          y={y}
        >
          {text}
        </text>
        <Icon
          x={iconX}
          y={iconY}
          textAnchor={textAnchor}
          className="fill-current"
          style={{ color: currentColor }}
        />
      </g>
      <LabelLine
        cx={cx}
        cy={cy}
        middleRadius={middleRadius}
        radius={radius}
        midAngle={midAngle}
        constantOffsetNumber={constantOffsetNumber}
      />
    </g>
  );
};

export default Label;
