import dayjs, { Dayjs } from "dayjs";
import { colorFormatters } from "@ps/utils";
import { TimeLoggingModels } from "@ps/time-tracker";
import { ISingleProject, ISingleReport } from "./types";

export const isDaysBar = (startDate: Dayjs, endDate: Dayjs): boolean => {
  if (!startDate || !endDate) return false;
  return endDate.diff(startDate, "day") + 1 <= 31;
};

export const prepareAllDatesList = (
  datesRange: Dayjs[],
  frequency = "month",
): Dayjs[] => {
  if (!datesRange?.length) return [];
  const start = datesRange[0];
  const end = datesRange[1];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const daysNumber = end.diff(start, frequency);
  return Array(daysNumber)
    .fill(1)
    .reduce(
      (acc, item, index) => [...acc, acc[index].add(1, frequency)],
      [start],
    );
};

export const sortDates = (timeFrame: Date[]): Dayjs[] => {
  if (!timeFrame?.length) return [];
  const start = dayjs(timeFrame[0]);
  const end = dayjs(timeFrame[1]);
  if (end.isBefore(start)) return [end, start];
  return [start, end];
};

export const prepareBarChartData = (
  report: TimeLoggingModels.ReportModel[],
  timeFrame: Dayjs[],
  isMonthly?: boolean,
): ISingleReport[] => {
  if (!report || !timeFrame) return [];
  const fullDatesList = prepareAllDatesList(
    timeFrame,
    isMonthly ? "month" : "day",
  );

  const prepared = fullDatesList.map(
    (item: Dayjs): ISingleReport => ({
      date: item,
      totalMinutes: 0,
      projects: [],
      totalHours: 0,
    }),
  );

  report.forEach((project: TimeLoggingModels.ReportModel): void => {
    project.entries.forEach(
      (entry: TimeLoggingModels.ReportEntryModel): void => {
        const dateFormat = isMonthly ? "MM" : "DD-MM-YYYY";
        const matchingElementIndex = prepared.reduce(
          (acc: number, item: ISingleReport, index: number) =>
            JSON.stringify(item.date.format(dateFormat)) ===
            JSON.stringify(dayjs(entry.date).format(dateFormat))
              ? index
              : acc,
          0,
        );
        let currentProject = prepared[matchingElementIndex];

        currentProject.totalMinutes += entry.minutes;
        currentProject.totalHours = currentProject.totalMinutes / 60;

        const currentId = currentProject.projects.reduce(
          (
            acc: number | null,
            proj: ISingleProject,
            id: number,
          ): number | null =>
            proj?.projectId === project.projectId ? id : acc,
          null,
        );

        if (currentId !== null) {
          currentProject.projects[currentId].minutes += entry.minutes;
          currentProject.projects[currentId].hours += entry.minutes / 60;
        } else {
          currentProject.projects = [
            ...currentProject.projects,
            {
              projectId: project.projectId,
              minutes: entry.minutes,
              hours: entry.minutes / 60,
              color: colorFormatters.prepareRGBObjectToRGB(
                project.projectColor,
              ),
            },
          ];
        }
      },
    );
  });

  return prepared;
};

///Prepare Barchart Graph functions
export const countColumnXStartPosition = (
  columnXSpace: number,
  index: number,
  columnWidthPercentage = 80,
): number => {
  const marginPercentage = (100 - columnWidthPercentage) / 2;
  const margin = (marginPercentage * columnXSpace) / 100;
  const columnWidth = (columnWidthPercentage * columnXSpace) / 100;
  if (!index) return margin;
  return (2 * margin + columnWidth) * index + margin;
};

export const countMarginPercentage = (
  columnXSpace: number,
  columnWidthPercentage = 80,
): number => {
  const marginPercentage = (100 - columnWidthPercentage) / 2;
  return (
    (marginPercentage * columnXSpace) / 100 -
    ((marginPercentage * columnXSpace) / 100) * 0.1
  );
};

//TODO fix me if it's needed
export const countChartScale = (height: number): number => height;

export const isColumnDescHorizontally = (columnsNumber: number): boolean =>
  columnsNumber < 4;

export const countColumnTextTransformation = (
  columnsNumber: number,
  chartTotalHeight: number,
  singleColumnSpaceWidth: number,
  columnWidthPercentage: number,
  isMonthly = false,
): string => {
  if (isColumnDescHorizontally(columnsNumber))
    return `translate(${singleColumnSpaceWidth * 0.06}, ${
      chartTotalHeight + 15
    })`;
  const shiftDown = isMonthly ? 40 : 55;
  return `translate(${countMarginPercentage(
    singleColumnSpaceWidth,
    columnWidthPercentage,
  )},${chartTotalHeight + shiftDown}) rotate(270)`;
};

export const countScaleHeight = (
  highestColumn: number,
  chartHeight: number,
  elementHeight: number,
): number => {
  const x = (elementHeight * 100) / highestColumn; // percentage
  return (x * chartHeight) / 100;
};

export const countChartTotalHeight = (
  maxHeight: number,
  highestColumn: number,
): number => {
  if (highestColumn >= maxHeight || highestColumn >= 4) return maxHeight;
  if (highestColumn < 1) return 40;
  return 100;
};
