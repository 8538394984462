import { ISOToDayJs, minutesToHHMM } from "@ps/utils";
import { PROJECT } from "../../shared/constants";
import {
  ProjectTimeEntryAPIModel,
  ProjectTimeEntryModel,
  HolidayTimeEntryAPIModel,
  HolidayTimeEntryModel,
} from "../timeEntriesTypes";

export const projectTimeEntryFromAPIModel = (
  item: ProjectTimeEntryAPIModel,
): ProjectTimeEntryModel => ({
  id: item.id,
  type: item.eventType,
  typeId: item.eventTypeId,
  date: ISOToDayJs(item.day),
  time: minutesToHHMM(item.minutes),
  minutes: item.minutes,
  rate: item.rate,
  note: item.note,
});

export const holidayTimeEntryFromAPIModel = (
  item: HolidayTimeEntryAPIModel,
): HolidayTimeEntryModel => ({
  id: item.id,
  type: item.eventType,
  typeId: item.eventTypeId,
  date: ISOToDayJs(item.day),
  time: minutesToHHMM(item.minutes),
  minutes: item.minutes,
  rate: item.rate,
});

export const timeEntriesFromAPIModel = (
  timeEntries: ProjectTimeEntryAPIModel[] | HolidayTimeEntryAPIModel[],
): (ProjectTimeEntryModel | HolidayTimeEntryModel)[] =>
  timeEntries.length
    ? timeEntries.map(
        (
          timeEntry: ProjectTimeEntryAPIModel | HolidayTimeEntryAPIModel,
        ): ProjectTimeEntryModel | HolidayTimeEntryModel =>
          timeEntry.eventType === PROJECT
            ? projectTimeEntryFromAPIModel(timeEntry)
            : holidayTimeEntryFromAPIModel(timeEntry),
      )
    : [];
