import { getItemFromDictionary, ISOToDayJs } from "@ps/utils";
import { DictionaryModel } from "../../../dictionaries";
import {
  OcrModel,
  OcrAPIModel,
  AccomplishmentAPIModel,
  OcredAccomplishmentModel,
  EducationAPIModel,
  OcredEducationModel,
  ProjectAPIModel,
  OcredProjectModel,
} from "../types";

const fromOcredProfileToModel = (
  data: OcrAPIModel,
  technologyDict: DictionaryModel,
): OcrModel => ({
  bio: data?.bio || null,
  languages: data?.languages || null,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  techStack: data?.techStack.map((tech: string) =>
    getItemFromDictionary(technologyDict, tech),
  ),
  projects: data?.projects?.projects?.map(
    (project: ProjectAPIModel): OcredProjectModel => ({
      domain: project?.domain || null,
      name: project?.name || null,
      startDate: ISOToDayJs(project?.startDate) || null,
      endDate: ISOToDayJs(project?.endDate) || null,
      description: project?.desc || null,
      responsibility: project?.responsibility || null,
      projectRoles: project?.projectRoles || [],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      techStack: project?.techStack.map((tech) =>
        getItemFromDictionary(technologyDict, tech),
      ),
      sourceText: project?.sourceText,
    }),
  ),
  education: data?.educations?.educations?.map(
    (education: EducationAPIModel): OcredEducationModel => ({
      startDate: ISOToDayJs(education?.startDate) || null,
      endDate: ISOToDayJs(education?.endDate) || null,
      description: education?.desc || null,
      sourceText: education?.sourceText || null,
    }),
  ),
  contactInformation: {
    email: data?.contactInformation?.email || null,
    phone: data?.contactInformation?.phone || null,
    // TODO Actually implement these links
    urlGithub: null,
    urlLinkedIn: null,
  },
  accomplishments: data?.accomplishments?.accomplishments?.map(
    (accomplishment: AccomplishmentAPIModel): OcredAccomplishmentModel => ({
      date: ISOToDayJs(accomplishment?.date) || null,
      description: accomplishment?.description || null,
      sourceText: accomplishment?.sourceText || null,
    }),
  ),
});

export default fromOcredProfileToModel;
