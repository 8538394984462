/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, ReactElement } from "react";
import Toast from "../../../ui-components/src/components/toast";
import { Notification } from "../types";
import useNotifications from "../hooks/useNotifications";

interface SingleNotificationProps {
  notification: Notification;
}

const SingleNotification = ({
  notification,
}: SingleNotificationProps): ReactElement => {
  const { type, id, message, timeoutDuration } = notification;
  const { removeNotification } = useNotifications();

  const remove = () => removeNotification(id);

  useEffect(() => {
    const timer = setTimeout(() => {
      remove();
    }, timeoutDuration);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Toast
      variant={type}
      id={id}
      title={message}
      dataCy={message}
      onClose={() => remove()}
    />
  );
};

export default SingleNotification;
