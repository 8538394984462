import { ReactElement } from "react";
import { View } from "@react-pdf/renderer";
import Skill from "./skill";
import { SkillsListProps, SkillType } from "../types";
import sharedStyles from "../sharedStyles/sharedStyles";

const SkillsList = ({
  skills,
  technologyDict,
}: SkillsListProps): ReactElement => {
  skills.map(
    (skill: SkillType): string =>
      (skill.name =
        technologyDict?.find((techEntry) => skill.technologyId === techEntry.id)
          ?.name || skill.technologyId),
  );
  skills.sort((a: SkillType, b: SkillType): number =>
    a.technologyId.localeCompare(b.technologyId),
  );

  return (
    <View style={sharedStyles.skillsContainer}>
      {skills.map(
        (skill: SkillType): JSX.Element => (
          <Skill
            key={skill.technologyId}
            technologyId={skill.technologyId}
            score={skill.score}
            name={skill.name}
          />
        ),
      )}
    </View>
  );
};

export default SkillsList;
