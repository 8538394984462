import { ReactElement } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Name } from "../components";
import locationImage from "../../../images/resume/location.png";
import AvatarImage from "../../../images/resume/avatar.png";
import ScalacLogoImage from "../../../images/resume/scalac-logo.png";
import { HeaderProps } from "../types";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  subcontainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "8px",
  },
  avatar: {
    width: "60px",
    height: "60px",
    marginRight: "30px",
  },
  scalacLogo: {
    width: "175px",
    height: "50px",
    marginBottom: "20px",
  },
  residenceImage: {
    width: "9px",
    height: "9px",
    marginRight: "3px",
  },
  residence: {
    color: "#2A2D33",
    fontWeight: "thin",
    fontSize: "10px",
  },
  jobTitle: {
    fontWeight: "thin",
    fontSize: "11px",
    marginRight: "12px",
    textTransform: "capitalize",
  },
});

const Header = ({
  avatar,
  firstName,
  isBlind,
  jobTitle,
  lastName,
  residence,
}: HeaderProps): ReactElement => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: isBlind ? "column" : "row",
        alignItems: isBlind ? "flex-start" : "center",
      }}
    >
      {isBlind ? (
        <Image src={ScalacLogoImage} style={styles.scalacLogo} />
      ) : (
        <Image src={avatar || AvatarImage} style={styles.avatar} />
      )}
      <View>
        {!firstName && !lastName ? (
          <></>
        ) : (
          <Name firstName={firstName} lastName={lastName} />
        )}
        <View style={styles.subcontainer}>
          {jobTitle ? (
            <Text
              style={{
                ...styles.jobTitle,
                color: isBlind ? "#EC4741" : "#091B76",
              }}
            >
              {jobTitle}
            </Text>
          ) : (
            <></>
          )}
          {residence ? (
            <View style={styles.container}>
              <Image style={styles.residenceImage} src={locationImage} />
              <Text style={styles.residence}>{residence}</Text>
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
    </View>
  );
};

export default Header;
