import {
  ARTICLES,
  AWARDS,
  CERTIFICATES,
  CONTRIBUTIONS,
  TALKS,
  TUTORS,
} from "./constants";
import ContributionCard from "./cards/contributionCard";
import { ReactComponent as ContributionIcon } from "../../../../../images/contribution.svg";
import AwardsCard from "./cards/awardsCard";
import { ReactComponent as AwardsIcon } from "../../../../../images/awards.svg";
import CertificatesCard from "./cards/certificatesCard";
import { ReactComponent as CertificatesIcon } from "../../../../../images/certificates.svg";
import ArticlesCard from "./cards/articlesCard";
import { ReactComponent as ArticlesIcon } from "../../../../../images/articles.svg";
import TalksCard from "./cards/talksCard";
import { ReactComponent as TalksIcon } from "../../../../../images/talks.svg";
import TutorCard from "./cards/tutorCard";
import { ReactComponent as TutorIcon } from "../../../../../images/tutor.svg";
import ContributionForm from "./forms/contributionForm";
import AwardsForm from "./forms/awardsForm";
import CertificatesForm from "./forms/certificatesForm";
import ArticlesForm from "./forms/articlesForm";
import TalksForm from "./forms/talksForm";
import TutorForm from "./forms/tutorForm";

const sections = [
  {
    name: CONTRIBUTIONS,
    View: ContributionCard,
    Edit: ContributionForm,
    Icon: ContributionIcon,
  },
  {
    name: AWARDS,
    View: AwardsCard,
    Edit: AwardsForm,
    Icon: AwardsIcon,
  },
  {
    name: CERTIFICATES,
    View: CertificatesCard,
    Edit: CertificatesForm,
    Icon: CertificatesIcon,
  },
  {
    name: ARTICLES,
    View: ArticlesCard,
    Edit: ArticlesForm,
    Icon: ArticlesIcon,
  },
  {
    name: TALKS,
    View: TalksCard,
    Edit: TalksForm,
    Icon: TalksIcon,
  },
  {
    name: TUTORS,
    View: TutorCard,
    Edit: TutorForm,
    Icon: TutorIcon,
  },
];

export default sections;
