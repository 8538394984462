import { ReactElement, forwardRef } from "react";
import { classJoin } from "@ps/utils";
import { useThemeMode } from "../theme-mode-provider";
import { LINK_PREFIX } from "../../shared/data-cy";
import { focusVisibleStyles } from "../../shared";
import { LinkProps } from "./types";
import { useFocus } from "../../hooks";

const variants = {
  primary: classJoin.template`
text-primary-50 rounded-md items-center
hover:text-primary-60 active:text-primary-50
disabled:font-normal disabled:text-neutralSecondary-60`,
  secondary: classJoin.template`
text-neutralPrimary-30 rounded-md items-center
hover:text-neutralPrimary-40 active:text-neutralPrimary-10
disabled:font-normal disabled:text-neutralSecondary-60`,
};

const baseStyling = classJoin.template`
disabled:pointer-events-none
`;

const Link = forwardRef<HTMLButtonElement, LinkProps>(
  (
    {
      additionalClassName = "",
      prefixIcon,
      onClick,
      suffixIcon,
      children,
      disabled,
      dataCy,
      type = "button",
      fontWeight = "semibold",
      whiteSpaceNoWrap = true,
      padding = "p-0.5",
      withBorder = false,
      variant = "primary",
      id,
      onFocus,
      focus,
      display = "flex",
      autoFocus = false,
      ...rest
    },
    ref,
  ): ReactElement => {
    const { isHighContrast } = useThemeMode();

    const [focusId] = useFocus(focus, false);

    return (
      <button
        autoFocus={autoFocus}
        ref={ref}
        id={focus ? focusId : id}
        type={type}
        onFocus={onFocus}
        className={classJoin(
          baseStyling,
          focusVisibleStyles,
          additionalClassName,
          variants[variant],
          padding,
          `font-${fontWeight}`,
          isHighContrast ? "hover:underline" : "",
          whiteSpaceNoWrap ? "whitespace-nowrap" : "",
          withBorder ? "border px-3 rounded-lg gap-x-2" : "",
          display,
          "focus:ring-1 focus:ring-secondary-50",
        )}
        style={{ textDecorationThickness: "2px" }}
        data-cy={`${LINK_PREFIX}-${dataCy}`}
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        {prefixIcon && <span className="mr-1">{prefixIcon}</span>}
        {children}
        {suffixIcon && <span className="ml-1">{suffixIcon}</span>}
      </button>
    );
  },
);
export default Link;
