import { ReactElement, useState } from "react";
import { uid } from "react-uid";
import { useLocation } from "react-router-dom";
import { classJoin } from "@ps/utils";
import { ReactComponent as SkillsIcon } from "../../../../images/profile-summary/skills.svg";
import ProfessionSkills from "./professionSkills";
import { PROFILE_SUMMARY_SKILLS } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import DonutChart from "./donutChart";
import { renderProfessionIcon } from "../../helpers";
import { SkillsContext } from "./context";
import { ByProfessionSkills, SingleSkill, SkillsProps } from "./types";
import Domains from "./domains";
import ExpandedCard, { CardHeader } from "../../../expanded-card";

const BASE_PATH = "profileSummary";
const padding = "py-6 px-6";

const Skills = ({
  domains,
  domainsDict,
  mainProfession,
  professionsDict,
  renderIcon = renderProfessionIcon,
  skills,
  technologyDict,
}: SkillsProps): ReactElement => {
  // TODO refactor if all summary view update
  const { pathname } = useLocation();

  const isSummary = pathname.includes("summary");

  const { t } = useTranslationWithNamespace();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");

  const renderSkills = () => {
    if (!selectedSection && !isOpen) {
      const professionSkills: SingleSkill[] = skills.byProfession
        .map((s) => s.skills)
        .flat();

      return (
        <>
          <ProfessionSkills
            maxScale={5}
            professionsDict={professionsDict}
            professionSkills={
              skills.best.length ? skills.best : professionSkills
            }
            renderIcon={renderIcon}
            technologyDict={technologyDict}
          />

          {!!domains?.length &&
          domainsDict &&
          !skills.best.length &&
          !skills.byProfession.length ? (
            <Domains
              domains={domains}
              domainsDict={domainsDict}
              maxScale={5}
              renderIcon={renderIcon}
              hideLabel={true}
            />
          ) : (
            <></>
          )}
        </>
      );
    }

    if (!isOpen) {
      const properProfession = skills.byProfession.find(
        (item: ByProfessionSkills) => item?.professionId === selectedSection,
      );
      return (
        <ProfessionSkills
          maxScale={5}
          professionsDict={professionsDict}
          professionSkills={properProfession?.skills || []}
          renderIcon={renderIcon}
          technologyDict={technologyDict}
        />
      );
    }

    const sortedProfessions = (
      skillsList: ByProfessionSkills[],
    ): ByProfessionSkills[] => {
      const sorted = skillsList.sort(
        (a: ByProfessionSkills, b: ByProfessionSkills) =>
          a.skills.length < b.skills.length ? 1 : -1,
      );

      return sorted.reduce(
        (acc: ByProfessionSkills[], item: ByProfessionSkills) =>
          item.professionId === mainProfession
            ? [item, ...acc]
            : [...acc, item],
        [],
      );
    };

    return (
      <div className="flex flex-col gap-16">
        {sortedProfessions(skills.byProfession)?.map(
          (item: ByProfessionSkills) => (
            <ProfessionSkills
              key={uid(item)}
              maxScale={5}
              professionId={item.professionId}
              professionsDict={professionsDict}
              professionSkills={item.skills}
              renderIcon={renderIcon}
              technologyDict={technologyDict}
            />
          ),
        )}
        {domains?.length && domainsDict ? (
          <Domains
            domains={domains}
            domainsDict={domainsDict}
            maxScale={5}
            renderIcon={renderIcon}
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  const isDonutChartVisible = skills.byProfession.length > 1 && !isOpen;

  return (
    <SkillsContext.Provider
      value={{ selectedSection, onPieClick: setSelectedSection }}
    >
      <ExpandedCard
        withAnimation={false}
        isOpen={isOpen}
        dataCy={PROFILE_SUMMARY_SKILLS}
        additionalContentClassName="flex flex-col gap-4"
        onClick={() => setIsOpen((prev) => !prev)}
        expandDirection="down"
        header={
          <>
            {!isSummary && (
              <CardHeader
                Icon={SkillsIcon}
                dataCy={`${PROFILE_SUMMARY_SKILLS}_skills`}
                additionalClassName={padding}
              >
                <span>{t(`${BASE_PATH}.skills`)}</span>
              </CardHeader>
            )}
          </>
        }
        openLabel={t(`${BASE_PATH}.seeAllSkills`)}
      >
        <div className="flex flex-col justify-center 2xl:justify-between 2xl:flex-row">
          {isDonutChartVisible && (
            <div
              className={classJoin(
                isDonutChartVisible
                  ? "xl:w-full xl:flex xl:justify-center"
                  : "w-full",
              )}
            >
              <DonutChart
                skills={skills.byProfession || []}
                renderIcon={renderIcon}
                professionsDict={professionsDict}
              />
            </div>
          )}
          <div
            className={classJoin(
              padding,
              isDonutChartVisible ? "xl:w-full" : "w-full",
            )}
          >
            {renderSkills()}
          </div>
        </div>
      </ExpandedCard>
    </SkillsContext.Provider>
  );
};

export default Skills;
