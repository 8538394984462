import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { classJoin } from "@ps/utils";
import { ReactComponent as ArrowDown } from "../../images/arrowDown.svg";
import { ReactComponent as SearchIcon } from "../../images/search-icon.svg";
import {
  SelectContext as SelectContextType,
  SelectMultipleProps,
  SelectProps,
  SelectSingleProps,
} from "./types";
import { SELECT_PREFIX } from "../../shared/data-cy";
import EmptyOptions from "./emptyOptions";
import renderWhenEmpty from "./helpers/renderWhenEmpty";
import getValueAndLabelFromMatchingChild from "./helpers/getValueAndLabelFromMatchingChild";
import filterWhenSelected from "./helpers/filterWhenSelected";
import identity from "./helpers/identity";
import Dropdown from "../dropdown/dropdown";
import searchFilter from "./helpers/searchFilter";
import doNothing from "./helpers/nothing";

const selectMainInputStyle = classJoin.template`
rounded-md h-8.5 font-normal border
flex flex-row justify-between items-center
bg-neutralPrimary-100 p-2 focus:outline-none 
`;

const selectMainInputDisabledStyle = classJoin.template`
bg-neutralSecondary-80 select-none pointer-events-none
`;

const getArrowRotation = (selectOpened: boolean): string => `
transform duration-100
${selectOpened ? "rotate-180" : ""}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectContext = React.createContext<SelectContextType<any>>({
  isMultiple: false,
});

type SelectedOptions<T> = {
  value?: T;
  label?: React.ReactElement;
}[];

const Select = <T,>({
  defaultValue,
  value,
  placeholder,
  disabled,
  onChange,
  children = [],
  hideDropdownOnSelect = true,
  width = "w-56",
  maxHeight = "max-h-56",
  dataCy,
  hideOptionWhenSelected,
  renderWhenEmpty: renderWhenEmptyComp,
  error,
  message,
  multiple,
  searchable,
}: SelectProps<T>): ReactElement => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentSelection, setCurrentSelection] = useState<T | undefined>();
  const clearCurrentSelection = () => setCurrentSelection(undefined);
  const toggleShow = () => setShowDropdown((oldState) => !oldState);
  const openDropdown = () => setShowDropdown(true);
  const hideOptions = () => {
    setShowDropdown(false);
    clearCurrentSelection();
  };

  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions<T>>(
    getValueAndLabelFromMatchingChild<T>(children)(defaultValue),
  );

  useEffect(() => {
    if (value !== undefined) {
      setSelectedOptions(getValueAndLabelFromMatchingChild<T>(children)(value));
    }
    // ? BUG? Eslint adds "T" to the dependecy array
    // eslint-disable-next-line
  }, [value, children]);

  const passChangeToOnChange = (selectedOptionsPassed: SelectedOptions<T>) => {
    const isDefined = (val: T | undefined): val is T => val !== undefined;
    const selected = selectedOptionsPassed
      .map(({ value: val }) => val)
      .filter(isDefined);
    if (multiple) {
      (onChange as SelectMultipleProps<T>["onChange"])?.(selected);
    } else {
      (onChange as SelectSingleProps<T>["onChange"])?.(selected[0]);
    }
  };

  const selectRef = useRef<HTMLDivElement>(null);

  const handleOptionChange = (optionValue: T, label: React.ReactElement) => {
    if (multiple) {
      if (selectedOptions.map(({ value: val }) => val).includes(optionValue)) {
        const filteredSelectedOptions = selectedOptions.filter(
          ({ value: val }) => val !== optionValue,
        );
        setSelectedOptions(filteredSelectedOptions);
        passChangeToOnChange(filteredSelectedOptions);
        return;
      }
      setSelectedOptions((oldState) => {
        passChangeToOnChange([...oldState, { value: optionValue, label }]);
        return [...oldState, { value: optionValue, label }];
      });

      return;
    }
    setSelectedOptions([{ value: optionValue, label }]);
    passChangeToOnChange([{ value: optionValue, label }]);
    setIsInputFocused(false);

    if (hideDropdownOnSelect) hideOptions();
  };

  const optionsFilter =
    hideOptionWhenSelected && !multiple
      ? filterWhenSelected(selectedOptions[0]?.value)
      : identity;
  const searchFilterIfSearchable = searchable
    ? searchFilter(searchValue)
    : identity;
  const filteredChildren = React.Children.map(children, optionsFilter).filter(
    searchFilterIfSearchable,
  );

  const whenNotDisabled = useCallback(
    <U,>(func: U) => (disabled ? undefined : func),
    [disabled],
  );

  const displayLabel = useMemo(() => {
    if (multiple && selectedOptions.length > 0) {
      const labelArray = selectedOptions.map(({ label }) => label);
      const isValueOfTypeString = labelArray.every(
        (label) => typeof label === "string",
      );
      if (isValueOfTypeString) return labelArray.join(", ");
      return <div className="flex flex-row gap-x-1">{labelArray}</div>;
    }
    if (selectedOptions[0]?.label) return selectedOptions[0].label;
    return undefined;
  }, [selectedOptions, multiple]);

  const setInputFocused = () => {
    setIsInputFocused(true);
  };
  const setInputNotFocused = () => {
    setIsInputFocused(false);
    setSearchValue("");
  };

  return (
    <Dropdown
      show={showDropdown}
      onClickOutside={hideOptions}
      overlay={
        <SelectContext.Provider
          value={
            {
              selectedOptions,
              onClick: handleOptionChange,
              currentSelection,
              selectRef,
              isMultiple: multiple,
            } as SelectContextType<T>
          }
        >
          <div
            onPointerLeave={clearCurrentSelection}
            className={classJoin(maxHeight, "overflow-auto")}
          >
            {Array.isArray(filteredChildren)
              ? renderWhenEmpty(
                  React.Children.map(filteredChildren, (element) =>
                    React.cloneElement(element, { dataCy }),
                  ),
                )(renderWhenEmptyComp)(<EmptyOptions />)
              : React.cloneElement(filteredChildren, { dataCy })}
          </div>
        </SelectContext.Provider>
      }
    >
      <div className={width}>
        <div
          className={classJoin(
            selectMainInputStyle,
            width,
            disabled && selectMainInputDisabledStyle,
            error
              ? "border-error-50 focus:border-error-50 text-error-50"
              : "border-neutralSecondary-60 text-neutralPrimary-30",
          )}
          ref={selectRef}
          role="button"
          tabIndex={whenNotDisabled(0)}
          onKeyDown={doNothing}
          onClick={whenNotDisabled(searchable ? openDropdown : toggleShow)}
          data-cy={`${SELECT_PREFIX}-${dataCy}`}
        >
          {searchable && <SearchIcon />}
          {searchable && isInputFocused ? (
            <span className="w-full ml-2">
              <input
                className="focus:outline-none bg-transparent capitalize"
                // eslint-disable-next-line
                autoFocus
                onBlur={setInputNotFocused}
                value={isInputFocused ? searchValue : undefined}
                onChange={(event) => setSearchValue(event.target.value)}
              />
            </span>
          ) : (
            <span
              className={classJoin(
                "w-full truncate capitalize",
                searchable && "ml-2",
                error
                  ? placeholder && !displayLabel && "text-error-50"
                  : placeholder && !displayLabel && "text-neutralSecondary-41",
              )}
              onClick={setInputFocused}
              onKeyDown={doNothing}
              role="button"
              tabIndex={0}
            >
              {displayLabel ?? placeholder}
            </span>
          )}
          {!searchable && (
            <ArrowDown
              className={classJoin(
                getArrowRotation(showDropdown),
                error ? "text-error-50" : "text-neutralPrimary-30",
              )}
            />
          )}
        </div>
        {error && (
          <span className="mt-1 text-xs text-error-50">{message ?? ""}</span>
        )}
      </div>
    </Dropdown>
  );
};

export default Select;
