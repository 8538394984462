import { Label, Textarea } from "@ps/ui-components";
import { ReactElement, ChangeEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslationWithNamespace } from "../../../hooks";
import { ControlledTextareaProps } from "./types";

const ControlledTextarea = ({
  additionalValidationRules = {},
  basePath,
  controllerPath,
  isRequired,
  maxLength,
  name,
  placeholder,
  translatePath,
  resize = false,
  height,
}: ControlledTextareaProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { control } = useFormContext();
  const translate = (field: string) =>
    `${t(`${translatePath}.${field}`)}${isRequired ? "*" : ""}`;

  return (
    <Label
      dataCy={`${basePath}_${name}`}
      text={translate(name)}
      labelFontWeight="font-bold"
      labelFontSize="text-lg"
      labelColor="text-neutralPrimary-20"
    >
      <Controller
        name={controllerPath || name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Textarea
            height={height}
            dataCy={`${basePath}_${name}`}
            defaultValue={value}
            maxLength={maxLength}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
              onChange(event.target.value)
            }
            message={error?.message}
            error={!!error?.message}
            placeholder={(placeholder && translate(placeholder)) || ""}
            resize={resize}
          />
        )}
        rules={{
          ...additionalValidationRules,
          required: {
            value: !!isRequired,
            message: t("projects.errors.cantBeEmpty"),
          },
          validate: (value) => {
            if (value && !value.trim().length && !!isRequired)
              return `${t("projects.errors.cantBeBlank")}`;
            if (value && maxLength && value.length > maxLength)
              return `${t("projects.errors.cantBeMoreChars", {
                number: maxLength,
              })}`;
            return true;
          },
        }}
      />
    </Label>
  );
};

export default ControlledTextarea;
