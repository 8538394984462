import { forwardRef, ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { InputProps } from "./types";
import { INPUT_PREFIX } from "../../shared/data-cy";

const variants: Record<"success" | "error", string> = {
  success: "border-success-60 focus:border-success-60 placeholder-success-60",
  error: "border-error-50 focus:border-error-50 placeholder-error-50",
};

const inputBaseStyle = classJoin.template`
rounded-md h-8.5 bg-neutralPrimary-100
text-neutralPrimary-20 border focus:outline-none
`;

const disabledStyle = "opacity-30";

const InputBase = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      additionalClass = "",
      autoComplete = "off",
      dataCy,
      defaultValue,
      disabled = false,
      error,
      name,
      onBlur,
      onChange,
      onFocus,
      onClick,
      onKeyDown,
      placeholder,
      success,
      type = "text",
      value,
      width = "w-full",
      isSearch,
      autoFocus = false,
      ...props
    },
    ref,
  ): ReactElement => {
    const optionalSuccess = success ? variants.success : undefined;
    const optionalError = error ? variants.error : undefined;
    const optionalStyle =
      optionalSuccess ??
      optionalError ??
      "border-neutralSecondary-60 placeholder-neutralSecondary-41 focus:border-secondary-50";

    return (
      <input
        ref={ref}
        autoFocus={autoFocus}
        tabIndex={0}
        autoComplete={autoComplete}
        className={classJoin(
          inputBaseStyle,
          additionalClass,
          width,
          optionalStyle,
          disabled && disabledStyle,
          isSearch ? "pl-7 py-2 pr-2" : "p-2",
        )}
        data-cy={`${INPUT_PREFIX}-${dataCy}`}
        defaultValue={defaultValue}
        disabled={disabled}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onClick={onClick}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        type={type}
        value={value}
        {...props}
      />
    );
  },
);

export default InputBase;
