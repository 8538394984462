import { ReportModel, ReportEntryModel } from "../../../models";
import { IValueLabel } from "../types";
import { ISelectedGroups, FlattenEntry } from "./types";
import { GroupNames } from "../../../store";

export const prepareGroupOptionsToSearchSelect = (
  arr: string[],
  t: (translation: string) => string,
  selectedGroups?: GroupNames,
  filterGroups?: string[],
): IValueLabel[] => {
  const valueLabelOptions: IValueLabel[] = arr.map(
    (item: string): IValueLabel => ({
      value: item,
      label: t(`reports.${item}`),
    }),
  );
  return filterGroups?.length && selectedGroups
    ? valueLabelOptions
        .filter(
          (item: IValueLabel) =>
            item.value !== selectedGroups[filterGroups?.[0]].value,
        )
        .filter(
          (item: IValueLabel) =>
            item.value !== selectedGroups[filterGroups?.[1]].value,
        )
    : valueLabelOptions;
};

export const flatEntriesData = (data: ReportModel[]): FlattenEntry[] =>
  data
    .map((reportEntry: ReportModel) =>
      reportEntry.entries.map(
        (entry: ReportEntryModel): FlattenEntry => ({
          ...entry,
          projectId: reportEntry.projectId,
          projectName: reportEntry.projectName,
          projectColor: reportEntry.projectColor,
          clientId: reportEntry.clientId,
          clientName: reportEntry.clientName,
          userId: reportEntry.userId,
          userName: `${reportEntry.userFirstName} ${reportEntry.userLastName}`,
          isPublic: reportEntry.isPublic,
        }),
      ),
    )
    .flat();

export const groupDataBasedOnParameter = (
  arr: FlattenEntry[],
  groupField: string,
): Record<string, FlattenEntry[]> =>
  arr.reduce((acc, entry) => {
    if (!acc[entry[groupField]]) {
      acc[entry[groupField]] = [];
    }
    acc[entry[groupField]].push(entry);
    return acc;
  }, {});

export const countTotalInGroup = (group: FlattenEntry[]): number =>
  group?.reduce((acc, entry: FlattenEntry) => acc + entry.minutes, 0);
