import { ReactPortal, KeyboardEvent, MouseEvent } from "react";
import FocusTrap from "focus-trap-react";
import { classJoin } from "@ps/utils";
import { ReactComponent as CloseIcon } from "../../images/modal/close.svg";
import styles from "./styles.module.css";
import { ModalProps } from "./types";
import { ModalContext } from "./context";
import {
  MODAL_BODY_PREFIX,
  MODAL_CLOSE_ICON_PREFIX,
  MODAL_OVERLAY_PREFIX,
  MODAL_PREFIX,
} from "../../shared/data-cy";
import useThemeMode from "../theme-mode-provider/useThemeMode";
import { useFontSizeMode } from "../font-size-provider";
import { focusVisibleStyles, Keys } from "../../shared";

const Modal = ({
  additionalClassName,
  additionalContentClassName,
  withOutsideClick = false,
  children,
  dataCy,
  height = "h-5/6",
  isOpen,
  onClose,
  padding = "p-8",
  width = "w-96",
  withCloseIcon,
  withYScroll = true,
  withFocusTrap = true,
}: ModalProps): ReactPortal | null => {
  const { createPortal, isHighContrast } = useThemeMode();
  const { fontSize } = useFontSizeMode();

  const Content = () => (
    <div
      className={classJoin.template`
              bg-black bg-opacity-70
              inset-0 absolute h-full w-auto
              flex justify-center items-center
              z-20 text-neutralPrimary-20
              focus:outline-none cursor-default`}
      data-cy={`${MODAL_OVERLAY_PREFIX}-${dataCy}`}
      onClick={withOutsideClick ? onClose : undefined}
      role={withOutsideClick || withCloseIcon ? "button" : "presentation"}
      tabIndex={withOutsideClick || withCloseIcon ? 0 : undefined}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === Keys.ESC) onClose();
      }}
      data-font-size={fontSize}
    >
      <div
        id="modalContent"
        className={classJoin(
          "bg-neutralPrimary-100 rounded-md absolute",
          padding,
          width,
          height,
          additionalClassName,
          isHighContrast ? "border border-neutralSecondary-40" : "",
        )}
        data-cy={`${MODAL_PREFIX}-${dataCy}`}
        role="presentation"
        onClick={(event: MouseEvent<HTMLDivElement>) => event.stopPropagation()}
      >
        {withCloseIcon && (
          <CloseIcon
            className={classJoin(
              "fill-current text-primary-50 ml-auto absolute cursor-pointer rounded-md",
              focusVisibleStyles,
              styles.modalCloseIcon,
            )}
            data-cy={`${MODAL_CLOSE_ICON_PREFIX}-${dataCy}`}
            onClick={onClose}
            role="button"
            tabIndex={0}
            onKeyDown={(event: KeyboardEvent<SVGSVGElement>) => {
              if (event.key === Keys.ENTER) onClose();
            }}
          />
        )}
        <div
          className={classJoin(
            withYScroll && "overflow-y-auto",
            additionalContentClassName,
          )}
          data-cy={`${MODAL_BODY_PREFIX}-${dataCy}`}
        >
          {children}
        </div>
      </div>
    </div>
  );

  return isOpen
    ? createPortal(
        <ModalContext.Provider value={{ dataCy }}>
          {withFocusTrap ? (
            <FocusTrap
              focusTrapOptions={{
                fallbackFocus: "#modalContent",
              }}
            >
              <Content />
            </FocusTrap>
          ) : (
            <Content />
          )}
        </ModalContext.Provider>,
        document.body,
      )
    : null;
};

export default Modal;
