import {
  DictionaryAPIModel,
  DictionaryEntryAPIModel,
  DictionaryEntryModel,
  DictionaryModel,
} from "../types";

export const prepareSingleEntryModel = (
  data: DictionaryEntryAPIModel,
): DictionaryEntryModel => ({
  id: data.id,
  name: data.name,
  iconUrl: data.icon_url,
  desc: data.desc,
  deleted: data.deleted,
  itemId: data.id,
});

const fromAPIToEntryModel = (
  dictionary: DictionaryAPIModel,
): DictionaryModel | [] =>
  dictionary?.length
    ? dictionary
        .map(
          (item: DictionaryEntryAPIModel): DictionaryEntryModel =>
            prepareSingleEntryModel(item),
        )
        .filter((item: DictionaryEntryModel): boolean => !item.deleted)
    : [];

export default fromAPIToEntryModel;
