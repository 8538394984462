import { createContext, useContext } from "react";
import { SkillsContextProps } from "../../types";

export const SkillsContext = createContext<SkillsContextProps>({
  onPieClick: () => {
    return "";
  },
  selectedSection: "",
});

export const useSkillsContext = (): SkillsContextProps => {
  const context = useContext(SkillsContext);
  if (!context) {
    throw new Error(
      "Skills elements compound components cannot be rendered outside the Skills component",
    );
  }
  return context;
};
