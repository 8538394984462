export default {
  tabs: {
    timesheet: "Timesheet",
    my_schedule: "My schedule",
    calendar: "Calendar",
    my_holidays: "My holidays",
    dashboard: "Dashboard",
    reports: "Reports",
  },
  totalWeekWorkTime: "{{totalWeekWorkTime}}h",
  dashboard: "Dashboard",
  export: "export",
  workTime: "Work time",
  projects: "Projects",
  noInformation: "No information",
  noInformationHeader: "There is no time logged for the selected time range.",
  addProjectHeader: "Click button to log time on project.",
  projectTime: "{{ time }}h",
  timesheet: {
    timelog: {
      projectHasBeenChanged: "Project has been changed!",
      removeTimesheetEntries: "All time entries have been removed!",
      deleteRowConfirmationHeader: "Are you sure you want to delete this row?",
      deleteRowConfirmationContent:
        "It will delete all added time entries, rates and notes for this week for this project.",
      outOfSOW: "You are no longer in this project",
      outOfSOWThisWeek:
        "Your attendance on this projects is between {{ start }} and {{ end }}",
      beforeSOW: "Start of your attendance on this project is on {{ date }}",
      afterSOW: "End of your attendance on this project is on {{ date }}",
      deletedFromProject: "You are no longer in this project",
      archivedProject: "Project is archived",
      sections: {
        timesheet: "Timesheet",
        total: "Total",
        addProject: "Add Project",
        select: "Select your project",
        event: "Event",
        fillUp: "Copy previous week",
        fillUpDisabled: "Enabled when no time entries added this week",
        lastWeekNotFilled: "No time entries added last week",
        addProjectDisabled: "No more projects available",
        holiday: "Holiday",
        unpinnedTooltip:
          "Pin this project so it is always visible in your timesheet",
        pinnedTooltip: "Unpin this project",
      },
      removeHolidayModal: {
        title: "Are you sure, you want delete this holiday?",
        desc: "You can't cancel delete action and you will need to create holiday again",
        delete: "Delete",
        cancel: "Cancel",
      },
      week: {
        monday: "Mon",
        tuesday: "Tue",
        wednesday: "Wed",
        thursday: "Thu",
        friday: "Fri",
        saturday: "Sat",
        sunda: "Sun",
      },
      advancedView: {
        next: "next",
        prev: "prev",
        new: "add new time entry",
        note: "Add note",
        rateTooltip: "Set rate multiplier according to your work contract",
        newRowTooltip: "Enter time first",
        addTimeEntry: "Add time entry",
        errorMaxCharsNote:
          "Field must contain less than {{ count }} characters",
      },
    },
    mySchedule: {
      title: "My schedule",
    },
    calendar: {
      title: "Calendar",
    },
  },
  holidays: {
    history: "History",
    yourDaysOff: "Your days off",
    add: "Add holiday",
    alreadyUsedDaysOff: "Already used days off",
    unusedDaysOff: "Unused days off",
    sum: "Sum",
    noHolidaysHeader: "It looks like you haven’t added any holidays yet...",
    noHolidaysContent: "Maybe it's time to take a vacation?",
  },
  reports: {
    ascending: "Ascending",
    descending: "Descending",
    noGroup: "no group",
    noDescription: "no description",
    showMore: "Show more",
    showLess: "Show less",
    clients: "Clients",
    users: "Users",
    user: "User",
    project: "Project",
    client: "Client",
    projects: "Projects",
    withoutClient: "Without client",
    mainTitle: "Reports",
    apply: "Apply",
    clearAll: "Clear all",
    filters: "Filters",
    usersPlaceholder: "Find users",
    projectsPlaceholder: "Find projects",
    clientsPlaceholder: "Find clients",
    noData: "There is no data",
    searchInformation: "Choose users, client or project and click 'Apply'",
    export: "export",
    totalTime: "Total: {{ hours }}h {{ minutes }}m",
    noResult: "There are no data with such search request",
    tryAgainInformation: "Please, try again with another filters",
    months: {
      jan: "Jan",
      feb: "Feb",
      mar: "Mar",
      apr: "Apr",
      may: "May",
      jun: "Jun",
      jul: "Jul",
      aug: "Aug",
      sep: "Sep",
      oct: "Oct",
      nov: "Nov",
      dec: "Dec",
    },
    groupBy: "Group by:",
    projectName: "Project",
    clientName: "Client",
    userName: "User",
    note: "Description",
    title: "Title",
    duration: "Duration",
    rate: "Rate",
    excelSheet: "Projects and employees",
  },
  actions: {
    clearAll: "Clear all",
    delete: "Delete",
    close: "Close",
  },
};
