import { ReactElement, ReactPortal, RefObject } from "react";

export interface ThemeModeProviderProps {
  additionalClassname?: string;
  children: ReactElement | ReactElement[];
}

export enum Theme {
  LIGHT = "light",
  DARK = "dark",
  HIGH_CONTRAST = "highContrast",
}

export interface ThemeModeContextProps {
  theme: Theme;
  set: (theme: Theme) => void;
  toggle: () => void;
  providerRef?: RefObject<HTMLDivElement>;
  createPortal: (
    children: ReactElement | ReactElement[],
    container: Element,
    key?: string,
  ) => ReactPortal;
}

export interface ThemeModePortalProps {
  children: ReactElement | ReactElement[];
  className?: string;
  container: Element;
  key?: string;
  theme: Theme;
}

export const THEME_MODE_PROVIDER_ID = "theme-mode-provider";
export const THEME_MODE_LOCAL_STORAGE = "theme-mode-users-selection";
