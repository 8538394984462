import { forwardRef, ReactElement, useEffect, useState } from "react";
import Select from "react-select";
import { useUID } from "react-uid";
import { classJoin } from "@ps/utils";
import searchSelectStyles from "./styles";
import { SearchSelectProps } from "./types";
import { SEARCH_SELECT_PREFIX } from "../../shared/data-cy";
import { Keys } from "../../shared";

const Option = (props): ReactElement => {
  return (
    <div
      data-cy={`${SEARCH_SELECT_PREFIX}-${props.dataCy}-option-${props.value}`}
      ref={props.innerRef}
      style={props.getStyles("option", props)}
      className={props.cx(props.className)}
      {...props.innerProps}
    >
      {props.children}
    </div>
  );
};

const SearchSelect = forwardRef<HTMLDivElement, SearchSelectProps>(
  (
    {
      selectId,
      dataCy,
      options,
      disabled,
      width = "w-56",
      height = "2.188rem",
      placeholder,
      onChange,
      multiple,
      searchable = true,
      defaultValue,
      value,
      clearable = false,
      error,
      message,
      additionalClassName,
      isMenuOpenTop = false,
      containerRef,
      closeMenuOnScroll,
      autoFocus,
      fitMenuWidth,
      onBlur,
      filterOption,
      openMenuOnFocus,
      menuIsOpen,
      menuPosition,
      isWideNameList = false,
    },
    ref,
  ): ReactElement => {
    const [rect, setRect] = useState<DOMRect>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
      setIsOpen(!!menuIsOpen);
    }, [menuIsOpen]);

    const id = useUID();

    useEffect(() => {
      const container = document.getElementById(id);
      if (container) setRect(container.getBoundingClientRect());
      return () => setRect(undefined);
    }, []);

    return (
      <div
        id={id}
        className={classJoin(
          additionalClassName,
          "flex flex-col overflow h-max",
        )}
        data-cy={`${SEARCH_SELECT_PREFIX}-${dataCy}`}
        ref={ref}
      >
        <Select
          components={{
            Option: (optionProps) => (
              <Option {...optionProps} dataCy={dataCy} />
            ),
          }}
          id={selectId}
          options={options}
          className={classJoin(width, "text-neutralPrimary-30")}
          placeholder={placeholder}
          // @ts-ignore
          styles={searchSelectStyles(
            error,
            height,
            isMenuOpenTop,
            fitMenuWidth ? `${rect?.width}px` : "inherit",
            isWideNameList,
            searchable,
          )}
          isSearchable={searchable}
          defaultValue={defaultValue}
          isDisabled={disabled}
          isClearable={clearable}
          onChange={onChange}
          onMenuOpen={() => setIsOpen(true)}
          onMenuClose={() => setIsOpen(false)}
          value={value}
          isMulti={multiple}
          closeMenuOnScroll={closeMenuOnScroll}
          containerRef={containerRef}
          autoFocus={autoFocus}
          filterOption={filterOption}
          onBlur={onBlur}
          openMenuOnFocus={openMenuOnFocus}
          menuIsOpen={isOpen}
          menuPosition={menuPosition}
          onKeyDown={(e) => {
            if (
              (e.key === Keys.ENTER || e.key === Keys.ARROW_DOWN) &&
              !isOpen
            ) {
              setIsOpen(true);
              e.preventDefault();
            }
            if (e.key === Keys.ESC) {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen(false);
            }
          }}
        />
        {error && (
          <span className="mt-1 text-xs text-error-50">{message ?? ""}</span>
        )}
      </div>
    );
  },
);

export default SearchSelect;
