import {
  KeyboardEvent,
  ReactElement,
  useState,
  useRef,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";
import { classJoin } from "@ps/utils";
import { ReactComponent as SortAZIcon } from "../../images/sortAZ.svg";
import { ReactComponent as Sort19Icon } from "../../images/sort19.svg";
import { ReactComponent as FilterIcon } from "../../images/filter.svg";
import { ReactComponent as FunnelIcon } from "../../images/funnel.svg";
import { TABLE_LABEL } from "../../shared/data-cy";
import { focusVisibleStyles, Keys } from "../../shared";
import Tooltip from "../tooltip";
import { TableLabelProps } from "./types";
import Fade from "../fade";
import { ConditionalTooltip } from "../../..";

const contentWrapperClassName = `z-50 rounded-md bg-neutralPrimary-100 mt-1 mb-1
flex flex-col justify-start text-neutralPrimary-20
border border-neutralSecondary-60 whitespace-nowrap max-h-80 overflow-y-auto w-auto`;

const TableLabel = ({
  additionalClassname,
  children,
  dataCy,
  filtering,
  popupStyle,
  setSearchValue,
  sorting,
}: TableLabelProps): ReactElement => {
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [isEllipsis, setIsEllipsis] = useState(false);
  const labelRef = useRef<HTMLSpanElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const checkEllipsis = () => {
      if (labelRef?.current && containerRef?.current) {
        setIsEllipsis(
          labelRef.current.scrollWidth >= labelRef.current.offsetWidth + 3,
        );
      }
    };

    checkEllipsis();

    const handleResize = () => {
      checkEllipsis();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [labelRef, containerRef]);

  const handleFilter = (): void => {
    setShowFilterDropdown((prev: boolean): boolean => !prev);
    if (setSearchValue) setSearchValue("");
  };

  // TODO - remove if new view accepted
  const { pathname } = useLocation();
  const isProjectPage =
    pathname.includes("project") ||
    pathname.includes("bench") ||
    pathname.includes("prospect") ||
    pathname.includes("finances") ||
    pathname.includes("folders") ||
    pathname.includes("clients");

  const uppercaseStyle = isProjectPage ? "uppercase" : "";

  const amountStyle = isProjectPage
    ? "absolute -top-1 -right-8 w-6 h-6 rounded-full bg-other-toxicGreen text-black text-center"
    : "absolute -top-2 -right-1 w-3.5 h-3.5 rounded-full bg-primary-50 text-white text-center text-xs";

  return (
    <div
      data-cy={`${TABLE_LABEL}_${dataCy}`}
      ref={containerRef}
      className={classJoin(
        "flex items-center gap-1",
        sorting?.isActive ? "text-neutralPrimary-20" : "text-neutralPrimary-30",
        additionalClassname,
      )}
    >
      <ConditionalTooltip
        content={children}
        enabled={isEllipsis}
        additionalChildrenClassName="truncate w-full"
      >
        <span
          ref={labelRef}
          className={classJoin(uppercaseStyle, "select-none truncate")}
        >
          {children}
        </span>
      </ConditionalTooltip>
      {filtering ? (
        <Fade
          open={showFilterDropdown}
          handleClose={(): void => setShowFilterDropdown(false)}
          handleOpen={handleFilter}
          contentWrapperClassName={classJoin(
            contentWrapperClassName,
            !popupStyle ? "left-0" : popupStyle,
          )}
          clickElement={
            <div
              className={classJoin(
                "flex relative items-center justify-center w-5 h-5 mt-0.5 cursor-pointer rounded-md",
                focusVisibleStyles,
                filtering?.checked?.length
                  ? "bg-primary-60 rounded bg-opacity-20"
                  : "",
              )}
              onClick={handleFilter}
              role="presentation"
            >
              {filtering?.checked?.length ? (
                <Tooltip
                  placement="bottom"
                  backgroundColor="neutralSecondary-41"
                  textColor="primary-85"
                  content={
                    <span className="capitalize text-sm whitespace-pre-wrap">
                      {filtering?.checked?.join(",\n")}
                    </span>
                  }
                >
                  <>
                    <div className={amountStyle}>
                      {filtering?.checked?.length}
                    </div>
                    {isProjectPage ? (
                      <FilterIcon
                        className="fill-current w-5 h-5 p-0.5"
                        onClick={handleFilter}
                      />
                    ) : (
                      <FunnelIcon
                        className="fill-current w-5 h-5 p-0.5"
                        onClick={handleFilter}
                      />
                    )}
                  </>
                </Tooltip>
              ) : (
                <>
                  {isProjectPage ? (
                    <FilterIcon
                      className="fill-current w-5 h-5 p-0.5"
                      onClick={handleFilter}
                    />
                  ) : (
                    <FunnelIcon
                      className="fill-current w-5 h-5 p-0.5"
                      onClick={handleFilter}
                    />
                  )}
                </>
              )}
            </div>
          }
        >
          {filtering.dropdownOverlay}
        </Fade>
      ) : (
        <></>
      )}
      {sorting ? (
        <div
          className={classJoin(
            "cursor-pointer w-5 h-5 mt-0.5 rounded-md",
            focusVisibleStyles,
          )}
          role="button"
          tabIndex={0}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>): void => {
            if (event.key === Keys.ENTER || event.key === Keys.SPACE)
              sorting?.onClick(sorting?.byName);
          }}
          onClick={(): void => sorting?.onClick(sorting?.byName)}
        >
          {sorting?.iconVariant === "1-9" ? (
            <Sort19Icon className="w-full h-full p-0.5" />
          ) : (
            <SortAZIcon className="w-full h-full p-0.5" />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TableLabel;
