import { ReactElement } from "react";
import { Loader } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { ReactComponent as LoadingMLIcon } from "../../../../../images/prospect/loadingML.svg";

const BASE_PATH = "projects.prospect.proposalFolder";

const LoadingCandidates = (): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="flex w-full h-full justify-center items-center font-semibold p-10 gap-x-10">
      <LoadingMLIcon className="w-56" />
      <div className="flex flex-col gap-y-6 font-semibold">
        <span className="text-xl text-neutralPrimary-20">
          {t(`${BASE_PATH}.teamGenerating`)}
        </span>
        <div className="flex flex-col gap-2 w-full text-neutralPrimary-30">
          <span>{t(`${BASE_PATH}.loadingWait`)}</span>
          <Loader additionalClassName="" dotHeight="h-5" dotWidth="w-5" />
        </div>
      </div>
    </div>
  );
};

export default LoadingCandidates;
