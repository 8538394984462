import { ReactElement, useState, useEffect } from "react";
import {
  removeDiacriticsFromString,
  sortArray,
  useFullTextSearch,
} from "@ps/utils";
import { CandidateModel } from "../../../../../folder-domain/models/types";
import MLQuestionScreen from "./mlQuestionScreen";
import SearchBar from "./searchBar";
import ResumesPanel from "./resumesPanel";
import {
  SESSION_SORTERS,
  initialSessionSortersState,
} from "../../../../../shared/constants";
import { SessionStorage } from "../../../../../shared";
import { useSessionStorage } from "../../../../../hooks";
import {
  ASC,
  PROFESSIONS,
  PROPOSAL_FOLDER,
  COUNTRY,
} from "../../../../constants";
import { CandidatesProps, SorterParametersProps } from "./types";
import EmptySpecialists from "./emptySpecialists";

const Candidates = ({
  folder,
  needs,
  seniorityDict,
  professionDict,
  onManuallyClick,
  prospectArchived,
  onOpenMLClick,
}: CandidatesProps): ReactElement => {
  const [onlyActiveCandidates, setOnlyActiveCandidates] = useState<
    CandidateModel[]
  >([]);
  const [searchValue, setSearchValue] = useState("");
  const [storageSorter, setStorageSorter] = useSessionStorage<SessionStorage>(
    SESSION_SORTERS,
    initialSessionSortersState,
  );
  const [sorterParameters, setSorterParameters] =
    useState<SorterParametersProps>({
      order: ASC,
      name: PROFESSIONS,
    });

  const candidates: CandidateModel[] = folder?.candidates?.filter(
    (candidate: CandidateModel): boolean => !candidate.deleted,
  );

  const { search } = useFullTextSearch(candidates || [], undefined, {
    keys: ["firstName", "lastName"],
  });

  const prepareTeamList = (searchingValue: string): void => {
    const { proposalFolder: teamSorter } = storageSorter.sessionSorters;

    const properSorter: {
      name: string;
      order: string;
      nestedFieldName?: string;
    } = teamSorter?.name?.length ? teamSorter : sorterParameters;

    const bySearch = searchingValue
      ? (search(
          removeDiacriticsFromString(searchValue.toLowerCase()),
        ).reverse() as CandidateModel[])
      : candidates;

    const resultBySorter = sortArray(
      bySearch,
      properSorter.name as keyof CandidateModel,
      properSorter.order,
      properSorter.nestedFieldName as keyof CandidateModel,
    );

    setOnlyActiveCandidates(resultBySorter);
  };

  useEffect(() => {
    if (folder?.candidates) prepareTeamList(searchValue);
  }, [searchValue, sorterParameters?.name, sorterParameters?.order, folder]);

  const handleOnClickSorter = (
    fieldName: string,
    order: string,
    isNestedField?: boolean,
  ): void => {
    const nestedFieldName = isNestedField ? COUNTRY : undefined;

    setStorageSorter((prevData) => ({
      ...prevData,
      sessionSorters: {
        ...prevData.sessionSorters,
        [PROPOSAL_FOLDER]: {
          name: fieldName,
          order,
          nestedFieldName,
        },
      },
    }));

    setSorterParameters({ name: fieldName, order });
  };

  return (
    <div className="w-9/12">
      {candidates?.length ? (
        <>
          <div className="absolute right-8 -top-14">
            <SearchBar
              dataCy={PROPOSAL_FOLDER}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              sorterParameters={
                storageSorter?.sessionSorters?.proposalFolder?.name?.length
                  ? storageSorter?.sessionSorters?.proposalFolder
                  : sorterParameters
              }
              onSorterClick={handleOnClickSorter}
            />
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-3 bg-neutralPrimary-100 pr-8">
              <ResumesPanel
                onlyActiveCandidates={onlyActiveCandidates}
                archived={prospectArchived}
              />
            </div>
          </div>
          {!!searchValue.length && !onlyActiveCandidates.length && (
            <EmptySpecialists searchValue={searchValue} />
          )}
        </>
      ) : (
        <div className="h-3/4 my-5">
          <MLQuestionScreen
            onClickLeft={onManuallyClick}
            needs={needs}
            onMLClick={onOpenMLClick}
            professionDict={professionDict}
            seniorityDict={seniorityDict}
          />
        </div>
      )}
    </div>
  );
};

export default Candidates;
