import { ReactElement } from "react";
import { uid } from "react-uid";
import ISOLanguages from "iso-639-1";
import flag from "country-code-emoji";
import { languageCodeToCountryCode } from "@ps/utils";
import { LanguagesProps } from "./types";
import { useTranslationWithNamespace } from "../../../../hooks";
import Empty from "../empty";

const Languages = ({ languages, title }: LanguagesProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return languages?.length ? (
    <div className="w-full grid grid-cols-2 text-lg">
      {languages.map((item) =>
        item?.language?.length && item?.language.length === 2 ? (
          <div className="grid grid-cols-2 font-semibold py-2" key={uid(item)}>
            <div className="flex items-center gap-2">
              <span>{flag(languageCodeToCountryCode(item.language))}</span>
              <span>{ISOLanguages.getName(item.language)}</span>
            </div>
            <span>{t(`profile.overview.languages.${item.level}`)}</span>
          </div>
        ) : (
          <></>
        ),
      )}
    </div>
  ) : (
    <Empty section={title} />
  );
};

export default Languages;
