import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { LABEL_PREFIX } from "../../shared/data-cy";
import { LabelProps } from "./types";

const wrapperStyle = "relative flex flex-col w-full h-full";

const getContentStyle = ({
  truncate,
  noWrap,
  fontWeight,
  verticalAlignment,
}: Pick<
  LabelProps,
  "truncate" | "noWrap" | "fontWeight" | "verticalAlignment"
>) => {
  const baseStyle = `w-full h-full text-lg ${fontWeight} text-neutralPrimary-20`;
  const noWrapOption = noWrap ? "" : "flex-wrap";
  const truncateOption = truncate ? "truncate" : "";
  const alignment = verticalAlignment ? "flex content-center" : "";
  return [baseStyle, noWrapOption, truncateOption, alignment].join(" ");
};

const labelStyle = (
  labelFontSize: string,
  labelFontWeight: string,
  labelColor: string,
): string => `${labelColor} ${labelFontSize} ${labelFontWeight} text-sm mb-1`;

const Label = ({
  additionalClassName,
  labelFontSize = "text-sm",
  labelFontWeight = "font-normal",
  labelColor = "text-neutralPrimary-30",
  children,
  dataCy,
  fontWeight = "font-semibold",
  labelSiblings,
  noWrap,
  text,
  truncate,
  verticalAlignment,
  ...rest
}: LabelProps): ReactElement => (
  <div
    className={classJoin(wrapperStyle, additionalClassName)}
    data-cy={`${LABEL_PREFIX}-${dataCy}`}
    {...rest}
  >
    <div className="flex flex-row items-center gap-1">
      <span className={labelStyle(labelFontSize, labelFontWeight, labelColor)}>
        {text}
      </span>
      {labelSiblings}
    </div>
    <div
      className={getContentStyle({
        truncate,
        noWrap,
        fontWeight,
        verticalAlignment,
      })}
    >
      {children}
    </div>
  </div>
);

export default Label;
