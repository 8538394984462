import { ReactElement, useContext, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { COURSES, DEGREES } from "./constants";
import SECTIONS from "./sectionsList";
import SectionWrapper from "../../common";
import { useMappedStateSelector } from "../../../../../hooks";
import { EducationMapState, EducationMapStateReturn } from "./types";

const Education = (): ReactElement => {
  const { reset, control } = useFormContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const degreesArray = useFieldArray({
    control,
    name: DEGREES,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const coursesArray = useFieldArray({
    control,
    name: COURSES,
  });

  const mapState = (state: EducationMapState): EducationMapStateReturn => ({
    degrees: state?.profiles?.myProfile?.experience.degrees || [],
    courses: state?.profiles?.myProfile?.experience.courses || [],
  });

  const { degrees, courses } = useMappedStateSelector(mapState);

  useEffect(() => {
    reset({ degrees, courses });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [degrees, courses]);

  return (
    <div className="grid gap-4">
      {SECTIONS.map((section) => (
        <SectionWrapper
          key={section.name}
          {...section}
          // eslint-disable-next-line no-eval
          arrayData={eval(`${section.name}Array`)}
        />
      ))}
    </div>
  );
};
export default Education;
