import { UTCToDayjs, sortArray } from "@ps/utils";
import {
  RoleAPIModel,
  RoleModel,
  UserAPIModel,
  UserDomainModel,
} from "../types";

export const usersListFromAPItoModel = (
  data: UserAPIModel[],
): UserDomainModel[] =>
  data?.map((user: UserAPIModel) => ({
    id: user.id,
    firstName: user?.firstName,
    secondName: user?.lastName,
    enabled: user.enabled,
    email: user?.email,
    roles: user.roles,
  }));

export const userFromAPItoModel = (user: UserAPIModel): UserDomainModel => ({
  id: user.id,
  firstName: user?.firstName,
  secondName: user?.lastName,
  enabled: user.enabled,
  email: user?.email,
  roles: user.roles,
});

export const allRolesFromAPIToModel = (roles: RoleAPIModel[]): RoleModel[] =>
  sortArray(
    roles.map(
      (role: RoleAPIModel): RoleModel => ({
        itemId: role.id,
        name: role.name,
        permissions: role.permissions,
        createdAt: UTCToDayjs(role.createdAt || ""),
      }),
    ),
    "createdAt",
    "desc",
  );
