import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { ActionTypes } from "./common/actionTypes";
import { baseRoutePath, editModeReducer, toggleEditMode } from "./common";
import {
  profilesReducer,
  isFetchingSpecialistProfiles,
  isFetchingProfiles,
} from "../profile-domain";
import { dictionariesReducer, isFetchingDictionary } from "../dictionaries";
import {
  isFetchingProspectDetails,
  isFetchingProspectsList,
  prospectsReducer,
} from "../prospect-domain";
import { isFetchingProjectDetails, projectsReducer } from "../project-domain";
import {
  isFetchingSkillsMatrix,
  skillsMatrixReducer,
} from "../skills-domain/store/reducers";
import {
  foldersReducer,
  isAddingCandidateToFolder,
  isFetchingFolder,
  isFetchingFoldersList,
} from "../folder-domain";
import {
  allRolesReducer,
  userRequestsInProgressReducer,
  usersReducer,
} from "../user-domain";
import {
  isFetchingSkillsSearchQueryResult,
  skillsSearchReducer,
  teamUsersReducer,
} from "../team-domain/store";
import { clientsReducer, isFetchingClientsList } from "../client-domain";
import { benchReducer, isFetchingBenchUsers } from "../bench-domain/store";
import {
  holidaysTemplatesReducer,
  isFetchingHolidays,
} from "../holidays-templates-domain/store";
import {
  holidaysReducer,
  isFetchingHolidaysUserDetails,
  isFetchingHolidaysUserHistory,
  isFetchingHolidaysUsers,
} from "../finance-domain/store";
import { isFetchingProjectsList } from "../project-domain/store";
import { isFetchingClientsSummaryList } from "../client-domain/store";
import { IS_PRODUCTION } from "../shared/constants";

export const hhReducer = combineReducers({
  allRoles: allRolesReducer,
  baseRoutePath,
  editMode: editModeReducer,
  profiles: profilesReducer,
  users: usersReducer,
  dictionaries: dictionariesReducer,
  skillsMatrix: skillsMatrixReducer,
  holidaysTemplates: holidaysTemplatesReducer,
  holidays: holidaysReducer,
  clients: clientsReducer,
  prospects: prospectsReducer,
  projects: projectsReducer,
  folders: foldersReducer,
  skillsSearch: skillsSearchReducer,
  bench: benchReducer,
  teamUsers: teamUsersReducer,
  requestStatus: combineReducers({
    ...userRequestsInProgressReducer,
    isFetchingProspectDetails,
    isFetchingProjectDetails,
    isFetchingFolder,
    isFetchingSkillsMatrix,
    isFetchingDictionary,
    isFetchingSkillsSearchQueryResult,
    isFetchingBenchUsers,
    isFetchingHolidays,
    isFetchingHolidaysUserDetails,
    isFetchingHolidaysUsers,
    isFetchingSpecialistProfiles,
    isAddingCandidateToFolder,
    isFetchingHolidaysUserHistory,
    isFetchingFoldersList,
    isFetchingClientsList,
    isFetchingProspectsList,
    isFetchingProjectsList,
    isFetchingClientsSummaryList,
    isFetchingProfiles,
  }),
});

export const publicHHReducer = hhReducer;

const actionTypeEnumToString = (action: AnyAction): AnyAction =>
  typeof action.type === "number" && ActionTypes[action.type]
    ? {
        type: ActionTypes[action.type],
        payload: action.payload,
        typeNumber: action.type,
      }
    : action;

const middlewares = [thunkMiddleware];

if (!IS_PRODUCTION) {
  const logger = createLogger({ actionTransformer: actionTypeEnumToString });
  middlewares.push(logger);
}

const store = createStore(
  hhReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

export { toggleEditMode };

// eslint-disable-next-line no-undef
export type AppState = ReturnType<typeof hhReducer>;
export default store;
