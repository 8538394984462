import { ReactElement, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { useHistory, useParams } from "react-router-dom";
import { LinkButton, Loader } from "@ps/ui-components";
import { fetchOtherProfileWithFulfillment } from "../../profile-domain";
import { OtherSummaryReturn, OtherSummaryState } from "./users-list/types";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../hooks";
import { modifyPostponedUsersList } from "../store";
import { USERS_LIST } from "../../shared/routes";
import PostponedList from "./users-list/postponedList";
import { TEAM_LIST_PROFILE_SUMMARY } from "../../shared/data-cy";
import { ReactComponent as ArrowIcon } from "../../images/more-arrow.svg";
import { OtherProfileSummaryWithPDF } from "../../profile-domain/ui/other-profile-summary";

const OtherSummary = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, parentPath } = useParams<{
    id: string;
    parentPath: string;
  }>();
  const { t } = useTranslationWithNamespace();

  const mapState = (state: OtherSummaryState): OtherSummaryReturn => ({
    technologyDict: state.dictionaries.technology,
    currentProfile: state.profiles.otherProfiles?.[id] || null,
    postponedUsersList: state.teamUsers.postponedUsersList,
  });

  const { currentProfile, postponedUsersList, technologyDict } =
    useMappedStateSelector(mapState);

  useEffect(() => {
    if (!currentProfile) {
      fetchOtherProfileWithFulfillment(id, dispatch, technologyDict);
    }
  }, [currentProfile, technologyDict]);

  return (
    <>
      {!currentProfile && (
        <Loader
          dotHeight="h-6"
          dotWidth="w-6"
          additionalClassName="absolute bottom-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      )}
      <section className="flex gap-8 h-full">
        {postponedUsersList.length && parentPath === USERS_LIST ? (
          <PostponedList
            postponedUsers={postponedUsersList}
            onShowMoreClick={(userId: string) =>
              history.push(`/team/${USERS_LIST}/${userId}`)
            }
            onRemoveClick={(user) => {
              modifyPostponedUsersList(dispatch, "remove", user);
            }}
            watchingProfile={id}
          />
        ) : (
          <></>
        )}
        <OtherProfileSummaryWithPDF
          otherActions={
            <LinkButton
              dataCy={TEAM_LIST_PROFILE_SUMMARY}
              onClick={() => history.push(`/team/${parentPath}`)}
              additionalClassName="text-lg"
              prefixIcon={
                <ArrowIcon className="transform rotate-180 w-3 h-3" />
              }
            >
              {t("team.usersList.backToList")}
            </LinkButton>
          }
          profile={currentProfile}
        />
      </section>
    </>
  );
};

export default OtherSummary;
