import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import { AnyAction, Dispatch } from "redux";
import ActionTypes from "./actionTypes";
import DictionariesService from "../services/dictionaries";
import { IAction } from "./types";

export const fetchDictionaryStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_DICTIONARY_START);
export const fetchDictionarySuccess = (
  payload: IAction<string>,
  id: string,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_DICTIONARY_SUCCESS, {
    dictionaries: payload,
    id,
  });
export const fetchDictionaryError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_DICTIONARY_ERROR, payload);

export const fetchDictionary = async (
  id: string,
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchDictionaryStart());
  const response = await DictionariesService.fetchDictionary(id);
  if (isAPIError(response)) {
    dispatch(fetchDictionaryError(response));
  } else {
    dispatch(fetchDictionarySuccess(response, id));
  }
};
