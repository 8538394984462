import { Label, SearchSelect } from "@ps/ui-components";
import { ReactElement, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { OCR } from "../../../shared/data-cy";
import ArticlesForm from "../experience/sections/accomplishment/forms/articlesForm";
import AwardsForm from "../experience/sections/accomplishment/forms/awardsForm";
import CertificatesForm from "../experience/sections/accomplishment/forms/certificatesForm";
import ContributionForm from "../experience/sections/accomplishment/forms/contributionForm";
import TalksForm from "../experience/sections/accomplishment/forms/talksForm";
import TutorForm from "../experience/sections/accomplishment/forms/tutorForm";
import { COURSES, DEGREES } from "../experience/sections/education/constants";
import CourseForm from "../experience/sections/education/forms/courseForm";
import DegreeForm from "../experience/sections/education/forms/degreeForm";
import ProjectForm from "../experience/sections/projects/projectForm";
import AdditionalInfoEndCard from "./additionalInfoEndCard";
import {
  ADDITIONAL_INFO,
  ARTICLES,
  AWARDS,
  CERTIFICATES,
  CONTRIBUTIONS,
  PROJECTS,
  TALKS,
  TRANSLATION_PATH,
  TUTORS,
} from "./constants";
import { useOCRWizardContext } from "./context";
import {
  AccomplishmentWrapperState,
  AccomplishmentWrapperStateReturns,
  AdditionalInfo,
  AdditionalInfoWrapperProps,
  SelectOption,
} from "./types";

const accomplishmentTranslationPath = "profile.experience.sections.inner";

const AdditionalInfoWrapper = ({
  index,
}: AdditionalInfoWrapperProps): ReactElement => {
  const { handleOnSetAdditionalInfoFromChoice, fullTextSearch } =
    useOCRWizardContext();
  const { getValues, setValue } = useFormContext();
  const { t } = useTranslationWithNamespace();
  const mapState = (
    state: AccomplishmentWrapperState,
  ): AccomplishmentWrapperStateReturns => ({
    technologyDict: state.dictionaries.technology,
  });
  const { technologyDict }: AccomplishmentWrapperStateReturns =
    useMappedStateSelector(mapState);

  const sectionOption: SelectOption[] = [
    {
      value: PROJECTS,
      label: t(`${TRANSLATION_PATH}.labels.projects`),
    },
    {
      value: DEGREES,
      label: t(`${TRANSLATION_PATH}.education.degree`),
    },
    {
      value: COURSES,
      label: t(`${TRANSLATION_PATH}.education.course`),
    },
    {
      value: ARTICLES,
      label: t(`${accomplishmentTranslationPath}.articles`),
    },
    {
      value: AWARDS,
      label: t(`${accomplishmentTranslationPath}.awards`),
    },
    {
      value: CERTIFICATES,
      label: t(`${accomplishmentTranslationPath}.certificates`),
    },
    {
      value: CONTRIBUTIONS,
      label: t(`${accomplishmentTranslationPath}.contributions`),
    },
    {
      value: TALKS,
      label: t(`${accomplishmentTranslationPath}.talks`),
    },
    {
      value: TUTORS,
      label: t(`${accomplishmentTranslationPath}.tutors`),
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: undefined,
      label: "None",
    },
  ];

  const accomplishmentType = sectionOption?.[index]?.value;

  useEffect(() => {
    setValue(`additionalInfo.${index}.accomplishmentType`, accomplishmentType);
  }, [index]);

  const returnForm = (formName: string, step: number) => {
    switch (formName) {
      case PROJECTS:
        return (
          <ProjectForm
            index={step}
            sourcePath={ADDITIONAL_INFO}
            fullTextSearch={fullTextSearch}
          />
        );
      case DEGREES:
        return <DegreeForm index={step} sourcePath={ADDITIONAL_INFO} />;
      case COURSES:
        return <CourseForm index={step} sourcePath={ADDITIONAL_INFO} />;
      case ARTICLES:
        return (
          <ArticlesForm
            fullTextSearch={fullTextSearch}
            index={step}
            sourcePath={ADDITIONAL_INFO}
            technologyDict={technologyDict}
          />
        );
      case AWARDS:
        return (
          <AwardsForm
            fullTextSearch={fullTextSearch}
            index={step}
            sourcePath={ADDITIONAL_INFO}
            technologyDict={technologyDict}
          />
        );
      case CONTRIBUTIONS:
        return (
          <ContributionForm
            fullTextSearch={fullTextSearch}
            index={step}
            sourcePath={ADDITIONAL_INFO}
            technologyDict={technologyDict}
          />
        );
      case CERTIFICATES:
        return (
          <CertificatesForm
            fullTextSearch={fullTextSearch}
            index={step}
            sourcePath={ADDITIONAL_INFO}
            technologyDict={technologyDict}
          />
        );
      case TALKS:
        return (
          <TalksForm
            fullTextSearch={fullTextSearch}
            index={step}
            sourcePath={ADDITIONAL_INFO}
            technologyDict={technologyDict}
          />
        );
      case TUTORS:
        return (
          <TutorForm
            fullTextSearch={fullTextSearch}
            index={step}
            sourcePath={ADDITIONAL_INFO}
            technologyDict={technologyDict}
          />
        );
      default:
        return <AdditionalInfoEndCard />;
    }
  };

  const additionalInfoValue = getValues(ADDITIONAL_INFO);
  const additionalInfoArray: string[] = additionalInfoValue?.length
    ? additionalInfoValue?.map((value: AdditionalInfo): string => {
        return value?.[ADDITIONAL_INFO];
      })
    : [];

  const handleOnChange = (data: SelectOption): void => {
    handleOnSetAdditionalInfoFromChoice(data);
    setValue(`additionalInfo.${index}.accomplishmentType`, data?.value);
  };

  return (
    <div className="flex flex-col gap-6">
      <Label dataCy={OCR} text={t(`${TRANSLATION_PATH}.addSection`)}>
        <SearchSelect
          dataCy={`additional-info-${OCR}`}
          onChange={handleOnChange}
          searchable={false}
          placeholder={t(`${TRANSLATION_PATH}.education.sectionName`)}
          value={
            additionalInfoArray[index]
              ? sectionOption.find(
                  (item: SelectOption): boolean =>
                    item.value === additionalInfoArray[index],
                )
              : null
          }
          additionalClassName="mb-10 mt-1"
          options={sectionOption}
        />
      </Label>
      {returnForm(additionalInfoArray[index], index)}
    </div>
  );
};

export default AdditionalInfoWrapper;
