import { Fragment, ReactElement } from "react";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import { Button2 } from "@ps/ui-components";
import { INVITING_MODAL } from "../../../shared/data-cy";
import styles from "./styles.module.css";
import { useTranslationWithNamespace } from "../../../hooks";
import { IMember, MembersListProps } from "./types";
import { InvitationRow } from "./components";

const MembersList = ({
  newMembersFieldArray,
  onCloseModal,
  onEdit,
}: MembersListProps): ReactElement => {
  const { fields, remove } = newMembersFieldArray;
  const { t } = useTranslationWithNamespace();

  return (
    <div
      className={classJoin(
        "w-full h-full bg-neutralPrimary-100 border rounded-lg flex flex-col",
        styles.leftColumnContainer,
        styles.contentModal,
      )}
    >
      <div className="flex flex-col h-full gap-2 py-2 overflow-auto">
        {fields?.map((member: IMember, index: number) => (
          <Fragment key={uid(member)}>
            <InvitationRow
              {...member}
              removeItem={() => remove(index)}
              onEdit={() => onEdit(index)}
            />
          </Fragment>
        ))}
      </div>
      <div className={classJoin(" h-4 w-full", styles.downMask)} />
      <div className="flex items-center gap-4 w-full justify-between p-4">
        <Button2
          variant="secondary"
          dataCy={`${INVITING_MODAL}_cancel`}
          width="w-1/2"
          onClick={onCloseModal}
        >
          {t("actions.cancel")}
        </Button2>
        <Button2
          dataCy={`${INVITING_MODAL}_sendInvites`}
          width="w-1/2"
          type="submit"
          variant="primary"
          disabled={!fields?.length}
        >
          {fields?.length
            ? t("members.inviting.invite", { count: fields?.length })
            : t("members.inviting.inviteZero")}
        </Button2>
      </div>
    </div>
  );
};

export default MembersList;
