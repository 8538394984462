import { ReactElement } from "react";
import { Button } from "@ps/ui-components";
import { ReactComponent as NoRolesIcon } from "../../../images/empty-roles.svg";
import { PERMISSIONS_ROLE } from "../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../hooks";
import { EmptyRolesProps } from "./types";

const BASE_PATH = "permissions";

const EmptyRoles = ({ addFirstRole }: EmptyRolesProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="flex justify-center items-center gap-16 ">
      <NoRolesIcon />
      <div className="flex flex-col gap-4">
        <span className="font-semibold text-2xl">
          {t(`${BASE_PATH}.createNewTitle`)}
        </span>
        <span className="pb-8 w-96">
          {t(`${BASE_PATH}.createNewDescription`)}
        </span>
        <Button
          dataCy={`${PERMISSIONS_ROLE}_create`}
          additionalClass="items-center justify-center flex p-0 gap-2"
          onClick={addFirstRole}
        >
          <span className="self-baseline text-3xl">+</span>
          {t(`${BASE_PATH}.createTable`)}
        </Button>
      </div>
    </div>
  );
};

export default EmptyRoles;
