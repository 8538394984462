import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../hooks";
import { ReactComponent as DoubleArrowLeft } from "../../images/double-arrow-left.svg";
import { ReactComponent as DoubleArrowRight } from "../../images/double-arrow-right.svg";
import { PREV_NEXT_NAVIGATION } from "../../shared/data-cy";
import ConditionalTooltip from "../conditional-tooltip";
import LinkButton from "../link";
import { PrevNextNavigationProps } from "./types";

export const TRANSLATION_PATH = "ocr.wizard";

const PrevNextNavigation = ({
  dataCy,
  isNextDisabled,
  isPrevDisabled,
  onNextClick,
  onPrevClick,
  isTooltipInfoVisible = false,
}: PrevNextNavigationProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <ConditionalTooltip
      enabled={isTooltipInfoVisible}
      content={t("prevNextNavigation.unsaved")}
    >
      <div
        className="flex h-fit gap-4 justify-end"
        data-cy={`${PREV_NEXT_NAVIGATION}-${dataCy}`}
      >
        <LinkButton
          prefixIcon={<DoubleArrowLeft />}
          disabled={isPrevDisabled}
          onClick={onPrevClick}
          dataCy={`${PREV_NEXT_NAVIGATION}-prev-${dataCy}`}
        >
          {t("prevNextNavigation.back")}
        </LinkButton>
        <LinkButton
          suffixIcon={<DoubleArrowRight />}
          disabled={isNextDisabled}
          onClick={onNextClick}
          dataCy={`${PREV_NEXT_NAVIGATION}-next-${dataCy}`}
        >
          {t("prevNextNavigation.next")}
        </LinkButton>
      </div>
    </ConditionalTooltip>
  );
};

export default PrevNextNavigation;
