import { ReactElement } from "react";
import { InfoTooltip, Label } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import { LabelWrapperProps } from "../types";
import { useTranslationWithNamespace } from "../../../../hooks";
import { MAIN_PROFILE_EDIT_MODE } from "../../../../shared/data-cy";

const LabelWrapper = ({
  field,
  children,
  className,
  tooltipContent,
  isRequired,
  translationPath,
  overviewElementPath,
}: LabelWrapperProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const id = `${MAIN_PROFILE_EDIT_MODE}_${overviewElementPath}_${field}`;
  const label = `${t(`${translationPath}.${field}`)}${isRequired ? "*" : ""}`;

  return (
    <Label
      additionalClassName={classJoin(className, "h-min")}
      dataCy={id}
      key={id}
      text={
        tooltipContent ? (
          <div className="flex items-center gap-2">
            {label}
            <InfoTooltip
              tooltipContent={t(`${translationPath}.${tooltipContent}`)}
              dataCy={`${overviewElementPath}_${field}`}
            />
          </div>
        ) : (
          label
        )
      }
    >
      {children}
    </Label>
  );
};

export default LabelWrapper;
