import {
  ReactElement,
  useEffect,
  KeyboardEvent,
  Children,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { changeSeparator, classJoin } from "@ps/utils";
import { useThemeMode } from "../theme-mode-provider";
import { NavItemProps } from "./types";
import { ReactComponent as ArrowDownIcon } from "../../images/navbar-arrow-down.svg";
import styles from "./styles.module.css";
import { NAVBAR } from "../../shared/data-cy";
import { getTabStatusStyles } from "./utils";
import NavIcon from "./navIcon";
import TooltipContainer from "./tooltipContainer";
import { focusVisibleStyles, Keys } from "../../shared";
import { RestrictedWrapper } from "../permissions";

const NAV_LINK_HEIGHT = 54;
const itemClassName = classJoin.template`
items-center whitespace-nowrap mx-3 my-2 cursor-pointer
px-6 h-12 uppercase select-none rounded-lg`;

const NavItem = ({
  children,
  defaultIsOpen,
  Icon,
  isMenuOpened,
  isPage,
  label,
  nestedElements,
  path,
  permission,
}: NavItemProps): ReactElement => {
  const { pathname } = useLocation();
  const { isHighContrast } = useThemeMode();
  const isChildActive: boolean = pathname.includes(path);
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen || isChildActive);

  useEffect(() => {
    if (!isMenuOpened) setIsOpen(false);
    else setIsOpen(isChildActive);
  }, [isMenuOpened]);

  const childrenAmount: number = Children.count(children);

  const isActive: boolean | undefined = pathname === `/${path}` && isPage;

  const labelToDisplay: string = changeSeparator(label);

  return (
    <TooltipContainer
      isMenuOpened={isMenuOpened}
      label={labelToDisplay}
      nestedElements={nestedElements}
      parentPath={path}
    >
      <div
        className={classJoin("flex flex-col rounded-md", focusVisibleStyles)}
        role={isMenuOpened ? "button" : "presentation"}
        tabIndex={isMenuOpened ? 0 : undefined}
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
          if (event.key === Keys.ENTER && isMenuOpened) {
            setIsOpen((prev) => !prev);
          }
        }}
      >
        <RestrictedWrapper permissions={permission || ""} key={path}>
          <Link
            to={isPage ? `/${path}` : ""}
            className={classJoin(
              isMenuOpened ? "grid grid-cols-6 gap-0.5" : "flex justify-center",
              itemClassName,
              getTabStatusStyles(!!isActive, isHighContrast),
              isChildActive && !isHighContrast
                ? "text-primary-100"
                : "text-primary-30",
            )}
            data-cy={`${NAVBAR}-${path}`}
            onClick={() => {
              if (isMenuOpened) setIsOpen((prev) => !prev);
            }}
          >
            <NavIcon
              Icon={Icon}
              isActive={!!isActive || (isChildActive && !isHighContrast)}
            />
            {isMenuOpened ? (
              <>
                <span className="col-span-4">{labelToDisplay}</span>
                <ArrowDownIcon
                  className={classJoin(
                    isOpen ? "transform duration-100 rotate-180" : "",
                    "w-3 h-3 ml-3",
                  )}
                />
              </>
            ) : (
              <></>
            )}
          </Link>
        </RestrictedWrapper>
        <div
          style={{
            height: isOpen ? childrenAmount * NAV_LINK_HEIGHT : 0,
          }}
          className={classJoin(
            "flex flex-col overflow-hidden",
            isOpen
              ? classJoin("opacity-1", styles.navbarLinksOpen)
              : classJoin("opacity-0", styles.navbarLinksClose),
          )}
        >
          {children}
        </div>
      </div>
    </TooltipContainer>
  );
};

export default NavItem;
