import React, { ReactElement, MouseEvent, KeyboardEvent } from "react";
import { uid } from "react-uid";
import { motion } from "framer-motion";
import { Card } from "@ps/ui-components";
import { classJoin, colorFormatters } from "@ps/utils";
import { ReactComponent as ArrowIcon } from "../../../images/skills-matrix/arrow.svg";
import iconForScale from "../../mock/iconForScale";
import { useTranslationWithNamespace } from "../../../hooks";
import { SKILLS_MATRIX_INFO_CARD } from "../../../shared/data-cy";
import { InfoPanelProps } from "./types";
import { RatingScaleModel } from "../../models";
import { focusVisibleStyles, Keys } from "../../../shared";

const iconStyle = "text-primary-100 h-full w-full p-1";
const BASE_TRANSLATION = "profile.skills.info";

const InfoPanel = ({
  show,
  onInfoButtonClick,
  enjoymentScale,
  knowledgeScale,
}: InfoPanelProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const colorHexToRGB = (color?: string): string =>
    colorFormatters.hexToRgbString(color);

  const prepareTileColor = (
    score: number,
    index: number,
    color: string | undefined,
  ): string =>
    score > index
      ? colorHexToRGB(color)
      : "bg-neutralPrimary-100 border-neutralSecondary-60";

  return (
    <>
      <motion.div
        className={classJoin(
          "flex flex-col gap-y-3 items-center justify-center bg-neutralPrimary-100 ml-3 rounded-l-md cursor-pointer",
          focusVisibleStyles,
          !show && "absolute right-0 top-1/6",
        )}
        initial={{ width: "3rem", height: "6rem" }}
        animate={
          show
            ? { width: "3rem", height: "6rem" }
            : { width: "3rem", height: "6rem" }
        }
        transition={{ type: "spring", stiffness: 40 }}
        layout
        onClick={(event: MouseEvent<HTMLDivElement>) =>
          onInfoButtonClick(event)
        }
        tabIndex={0}
        role="button"
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
          if (event.key === Keys.ENTER) onInfoButtonClick(event);
        }}
      >
        <ArrowIcon
          className={classJoin(
            show ? "" : "transform rotate-180 duration-100",
            "text-primary-50 w-5 h-5",
          )}
        />
        <span className="text-primary-50 text-lg font-semibold transform -rotate-90">
          {t(`${BASE_TRANSLATION}.informationTitle`)}
        </span>
      </motion.div>
      <motion.div
        initial={{ width: "0rem", height: "2.5rem" }}
        animate={
          show
            ? { width: "30rem", height: "90%" }
            : { width: "0rem", height: "0rem" }
        }
        style={{ overflow: "hidden" }}
        transition={{ type: "spring", stiffness: 40 }}
        layout
      >
        <Card
          dataCy={SKILLS_MATRIX_INFO_CARD}
          additionalClassName="h-max"
          additionalCardClassName="rounded-tl-none"
        >
          <div className="flex flex-col text-sm gap-y-2 my-5 mx-4">
            {knowledgeScale?.map(
              ({ score, desc }: RatingScaleModel): ReactElement => (
                <div className="flex flex-row gap-x-5 items-start" key={score}>
                  <div className="flex flex-row gap-x-0.5 pt-1">
                    {knowledgeScale
                      .filter((_, id: number): boolean => id > 0)
                      .map(
                        (
                          item: RatingScaleModel,
                          index: number,
                        ): ReactElement => (
                          <div
                            className={classJoin(
                              "w-4 h-4 rounded flex-shrink-0 border",
                            )}
                            style={{
                              borderColor: prepareTileColor(
                                score,
                                index,
                                item?.color,
                              ),
                              backgroundColor: prepareTileColor(
                                score,
                                index,
                                item?.color,
                              ),
                            }}
                            key={uid(item)}
                          />
                        ),
                      )}
                  </div>
                  {desc}
                </div>
              ),
            )}
          </div>
          <div className="flex flex-col mx-4 gap-y-2">
            <span className="font-bold">
              {t(`${BASE_TRANSLATION}.additionally`)}:
            </span>
            <span className="text-sm">
              {t(`${BASE_TRANSLATION}.description`)}
            </span>
          </div>
          <div className="flex flex-col text-sm gap-y-2 my-5 mx-9">
            {[...(enjoymentScale ?? [])]
              ?.map(
                (
                  { score, desc, color }: RatingScaleModel,
                  index: number,
                ): ReactElement => (
                  <div
                    className="flex flex-row gap-x-3 items-center"
                    key={score}
                  >
                    <div className="flex flex-row gap-x-0.5 pt-1">
                      <div
                        className={classJoin(
                          "w-7 h-7 rounded-md flex-shrink-0 border",
                          "flex flex-row items-center justify-center",
                          !color && "bg-primary-50",
                        )}
                        style={{
                          borderColor: colorHexToRGB(color),
                          backgroundColor: colorHexToRGB(color),
                        }}
                      >
                        {iconForScale[index] ? (
                          React.cloneElement(iconForScale[index], {
                            className: classJoin(
                              iconForScale[index]?.props?.className,
                              iconStyle,
                            ),
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {desc}
                  </div>
                ),
              )
              .reverse()}
          </div>
        </Card>
      </motion.div>
    </>
  );
};

export default InfoPanel;
