import { ReactElement } from "react";
import { Empty2 } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../hooks";
import { ReactComponent as EduIcon } from "../../../../images/profile-summary/edu.svg";
import OtherSummaryHeader from "./otherSummaryHeader";
import { ExpProps } from "../types";

const Education = ({
  filled,
  children,
  onAddOrEditClick,
}: ExpProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <div className="flex flex-col gap-y-4">
      <OtherSummaryHeader
        title={t("profile.experience.sections.education")}
        Icon={<EduIcon className="text-neutralSecondary-41" />}
        withButton
        onButtonClick={onAddOrEditClick}
        filled={filled}
      />
      {filled ? (
        children
      ) : (
        <Empty2
          section={t("profile.experience.sections.education")}
          onClick={onAddOrEditClick}
        />
      )}
    </div>
  );
};

export default Education;
