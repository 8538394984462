import { ReactElement, useContext, useEffect, useState } from "react";
import { uid } from "react-uid";
import {
  HolidaysItemModel,
  AddHolidayModal,
  RemoveHolidayEntryModal,
  ProjectSummary,
  ProjectUserSummaryModel,
} from "@ps/hh";
import { useDispatch } from "redux-react-hook";
import dayjs, { Dayjs } from "dayjs";
import { Button, TimeTrackerTimeNavigator, Tooltip } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import TimeLogContext from "../../../contexts/TimelogContext";
import { ReactComponent as AddProjectIcon } from "../../../images/timesheet/timelog/add-project-icon.svg";
import { ReactComponent as AddHolidayIcon } from "../../../images/timesheet/timelog/holiday.svg";
import { ReactComponent as CalendarIcon } from "../../../images/tabs/calendar.svg";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import {
  ADD_PROJECT_BUTTON,
  FILL_UP_LIKE_LAST_WEEK_BUTTON,
  TIME_NAVIGATOR,
  HOLIDAY,
} from "../../../shared/data-cy";
import {
  fetchLastWeekTimesheetData,
  fetchPinnedProjects,
  fetchTimesheetData,
} from "../../../store";
import {
  HolidayNewTimeEntryModel,
  ProjectTimeEntriesModel,
} from "../../../models";
import { HolidaysService, TimeEntriesService } from "../../../services";
import Header from "./header/header";
import { EmptyProjectRow, ProjectRow } from "./project-row";
import HolidayRow from "./holiday-row";
import Summary from "./summary";
import { TimeLogMapState, TimeLogMapStateReturn, TimeLogProps } from "./types";
import { IS_PRODUCTION } from "../../../shared/constants";
import { getUnusedProjects } from "./project-row/utils";
import { removeTimesheetEntry } from "../../../store/actions/timeEntriesActions";
import LoaderRow from "./project-row/loaderRow";

const BASE_PATH = "timesheet.timelog";
const timesheetButtonStyles = "mt-8 flex flex-row items-center";

const TimeLog = ({ dayAmount = 7 }: TimeLogProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const { enableAddProject } = useContext(TimeLogContext);
  const [newEmptyRow, setNewEmptyRow] = useState(0);
  const [isNewProject, setIsNewProject] = useState(false);
  const [focusedElementID, setFocusedElementID] = useState("");
  const [isAddHolidayModalOpen, setIsAddHolidayModalOpen] = useState(false);
  const [removingHolidaysIs, setRemovingHolidaysId] = useState("");
  const [projectsRowsDisplaying, setProjectsRowsDisplaying] = useState<
    ProjectUserSummaryModel[] | null
  >([]);
  const [openedProjectAdvancedView, setOpenedProjectAdvancedView] = useState({
    projectId: "",
    date: "",
  });
  const [rowProjectsDisplayingOrderIds, setRowProjectsDisplayingOrderIds] =
    useState<string[]>([]);
  const [timesheetProjectIdsWithPinned, setTimesheetProjectIdsWithPinned] =
    useState<string[] | undefined>(undefined);
  const [isLoadingOnMount, setIsLoadingOnMount] = useState(true);

  const mapState = (state: TimeLogMapState): TimeLogMapStateReturn => ({
    holidaysTimeEntries: state.timesheet.holiday || [],
    isFetchingTimesheet: state.requestStatus.isFetchingTimesheet,
    pinnedProjects: state?.pinnedProjects || [],
    previousWeekTimesheet: state.previousWeekTimesheet,
    projects: state?.projectsList,
    projectsTimeEntries: state.timesheet.project || null,
    templateHolidays: state.holidays.userHolidaysTemplate || [],
  });

  const {
    projectsTimeEntries,
    holidaysTimeEntries,
    pinnedProjects,
    projects,
    templateHolidays,
    previousWeekTimesheet,
    isFetchingTimesheet,
  } = useMappedStateSelector(mapState);

  const checkProjectSummaries = (
    idsArray: string[],
    projectSummaries: ProjectUserSummaryModel[],
  ) =>
    !idsArray.some((id: string) => {
      const matchingSummary = projectSummaries.find(
        (summary: ProjectUserSummaryModel) => summary.id === id,
      );

      return matchingSummary
        ? matchingSummary.archived || matchingSummary.membershipDetails?.deleted
        : true;
    });

  const isProjectEnable = checkProjectSummaries(
    Object.keys(previousWeekTimesheet),
    projects,
  );

  const [timeFrame, setTimeFrame] = useState<Date[]>([
    dayjs().startOf("week").day(1).toDate(),
    dayjs().startOf("week").add(7, "day").toDate(),
  ]);

  useEffect(() => {
    setTimesheetProjectIdsWithPinned([
      ...new Set([
        ...(projectsTimeEntries ? Object.keys(projectsTimeEntries) : []),
        ...pinnedProjects,
      ]),
    ]);
  }, [projectsTimeEntries, pinnedProjects]);

  const startOfWeek: Dayjs = dayjs(timeFrame?.[0]);
  const endOfWeek: Dayjs = dayjs(timeFrame?.[1]);
  const previousWeekStartDate = dayjs(startOfWeek).subtract(1, "week");
  const previousWeekEndDate = dayjs(endOfWeek).subtract(1, "week");
  const nextWeekStartDate = dayjs(startOfWeek).add(1, "week");

  const lastDayOfWeek = dayjs().endOf("week");
  const isNextWeekOrAfter = dayjs(timeFrame[0]).isAfter(lastDayOfWeek);

  useEffect(() => {
    if (!isFetchingTimesheet && projectsRowsDisplaying && projectsTimeEntries) {
      setIsLoadingOnMount(false);
    }
  }, [
    projectsTimeEntries,
    isFetchingTimesheet,
    projectsRowsDisplaying?.length,
  ]);

  useEffect(() => {
    fetchTimesheetData(startOfWeek, endOfWeek, dispatch);
    fetchPinnedProjects(dispatch);
    return () => {
      setProjectsRowsDisplaying(null);
      setRowProjectsDisplayingOrderIds([]);
    };
  }, []);

  const handleOnTimeRangeChange = async (newTime) => {
    setTimeFrame(newTime);
    const result = await fetchTimesheetData(newTime[0], newTime[1], dispatch);
    setOpenedProjectAdvancedView({ projectId: "", date: "" });
    const loggedProjectsIds = result?.timesheet?.project
      ? Object.keys(result?.timesheet?.project)
      : [];

    if (!loggedProjectsIds?.length) {
      setProjectsRowsDisplaying(
        pinnedProjects.map((id: string) =>
          projects?.find((item: ProjectSummary) => item.id === id),
        ),
      );
      setRowProjectsDisplayingOrderIds(pinnedProjects || []);
    } else {
      const newData = [
        ...new Set([...(loggedProjectsIds || []), ...(pinnedProjects || [])]),
      ];

      setProjectsRowsDisplaying(
        newData?.reduce((acc: ProjectSummary[], id: string) => {
          const matchedItem = projects?.find(
            (item: ProjectSummary) => item.id === id,
          );
          return matchedItem ? [...acc, matchedItem] : acc;
        }, []),
      );
      setRowProjectsDisplayingOrderIds(newData);
    }
  };

  useEffect(() => {
    if (projects?.length) {
      setNewEmptyRow(0);
      handleOnTimeRangeChange(timeFrame);
      fetchLastWeekTimesheetData(
        dayjs(previousWeekStartDate),
        dayjs(previousWeekEndDate),
        dispatch,
      );
    }
  }, [timeFrame, projects]);

  const updateProjects = (order: string[], withPinned: string[]): void => {
    const prevProjectsOrder = order?.length ? [...order] : order;

    const newProjectsOrder = prevProjectsOrder
      ? [...new Set([...(prevProjectsOrder || []), ...(withPinned || [])])]
      : timesheetProjectIdsWithPinned || [];

    const result = newProjectsOrder.map((id: string) =>
      projects?.find((item: ProjectSummary) => item.id === id),
    );

    setRowProjectsDisplayingOrderIds(newProjectsOrder);
    setProjectsRowsDisplaying(result);
  };

  useEffect(() => {
    if (projects?.length && timesheetProjectIdsWithPinned) {
      updateProjects(
        rowProjectsDisplayingOrderIds,
        timesheetProjectIdsWithPinned,
      );
    }
  }, [projects, timesheetProjectIdsWithPinned]);

  const timesheetHolidayIds: string[] = Object.keys(holidaysTimeEntries);

  const handleCopyPreviousWeek = async (date: Dayjs): Promise<void | null> => {
    await TimeEntriesService.copyPreviousWeekEntries(date.format("YYYY-MM-DD"));

    await fetchTimesheetData(startOfWeek, endOfWeek, dispatch);
  };

  const handleOnNewHolidayEntrySave = async (
    data: HolidayNewTimeEntryModel,
  ): Promise<void> => {
    await HolidaysService.createHolidayTimeEntry(data);
    await fetchTimesheetData(startOfWeek, endOfWeek, dispatch);
  };

  const onRemoveClick = (id: string): void => {
    setRemovingHolidaysId(id);
  };

  const handleRemoveEntry = async (): Promise<void> => {
    await TimeEntriesService.removeTimesheetEntry(removingHolidaysIs);
    await fetchTimesheetData(startOfWeek, endOfWeek, dispatch);
  };

  const refreshAfterDeleting = (removeId: string): void => {
    const isPinned = pinnedProjects.some((item: string) => item === removeId);
    if (isPinned) return;

    const newIdsOrder = [...rowProjectsDisplayingOrderIds]?.filter(
      (item: string) => item !== removeId,
    );

    setRowProjectsDisplayingOrderIds(newIdsOrder);

    const result = newIdsOrder.map((id: string) =>
      projects?.find((item: ProjectSummary) => item.id === id),
    );
    setProjectsRowsDisplaying(result);
    dispatch(removeTimesheetEntry({ id: removeId }));
  };

  const handleOnUpdateProjects = (
    prevId: string,
    newId?: string | undefined,
  ) => {
    const prevOrder = [...rowProjectsDisplayingOrderIds];
    const prevWithPinned = timesheetProjectIdsWithPinned
      ? [...timesheetProjectIdsWithPinned]
      : [];

    if (!newId) {
      updateProjects([...prevOrder, prevId], prevWithPinned);
    } else {
      const updatedOrder = prevOrder.map((id: string) =>
        id === prevId ? newId : id,
      );
      const updatedWithPinned = prevWithPinned.filter(
        (item: string) => item !== prevId,
      );
      updateProjects(updatedOrder, updatedWithPinned);
    }
  };

  const isCopyPrevBtnDisabled =
    Object.keys(previousWeekTimesheet)?.length !== 0 &&
    projectsTimeEntries !== null &&
    Object.values(projectsTimeEntries)
      ?.map((item: { [key: string]: ProjectTimeEntriesModel } | unknown) =>
        Object.values(item as { [key: string]: ProjectTimeEntriesModel }),
      )
      ?.flat()?.length === 0 &&
    !nextWeekStartDate?.isAfter(dayjs()?.add(7, "day")) &&
    !isFetchingTimesheet;

  const renderCopyPrevWeekBtn = (): ReactElement => (
    <Button
      dataCy={FILL_UP_LIKE_LAST_WEEK_BUTTON}
      additionalClass={timesheetButtonStyles}
      onClick={() => handleCopyPreviousWeek(previousWeekStartDate)}
      variant="primary"
      disabled={!isCopyPrevBtnDisabled || !isProjectEnable}
    >
      <CalendarIcon className="mr-4" />
      {t(`${BASE_PATH}.sections.fillUp`)}
    </Button>
  );

  const isProjectListEmpty = !getUnusedProjects(
    projects,
    rowProjectsDisplayingOrderIds,
  )?.length;

  const addButtonDisabled =
    enableAddProject ||
    newEmptyRow === 1 ||
    (newEmptyRow === 0 && isProjectListEmpty);

  // TODO Tutuaj fix
  const renderAddProjectBtn = (): ReactElement => (
    <Button
      dataCy={ADD_PROJECT_BUTTON}
      additionalClass={classJoin(timesheetButtonStyles, "mr-5")}
      onClick={() => {
        setNewEmptyRow(1);
        setIsNewProject(true);
      }}
      disabled={addButtonDisabled}
    >
      <AddProjectIcon className="mr-4" />
      {t(`${BASE_PATH}.sections.addProject`)}
    </Button>
  );

  return (
    <section className="h-full relative">
      <div className="flex items-center justify-end mb-8">
        <div className="flex items-center gap-8">
          <TimeTrackerTimeNavigator
            dataCy={TIME_NAVIGATOR}
            focusedElementID={focusedElementID}
            setFocusedElementID={setFocusedElementID}
            setTimeFrame={handleOnTimeRangeChange}
            shouldOpenCalendarByLabelClick
            timeFrame={timeFrame}
          />
          {IS_PRODUCTION ? (
            <></>
          ) : (
            <Button
              dataCy={`${HOLIDAY}_add`}
              onClick={() => setIsAddHolidayModalOpen(true)}
              disabled={!templateHolidays.length}
            >
              <AddHolidayIcon className="mr-3" />
              {t("holidays.add")}
            </Button>
          )}
        </div>
      </div>
      <Header date={startOfWeek} dayAmount={dayAmount} />
      {!isLoadingOnMount && projectsRowsDisplaying ? (
        projectsRowsDisplaying?.map((project: ProjectUserSummaryModel) => (
          <ProjectRow
            chosenProject={project}
            data={projectsTimeEntries}
            dayAmount={dayAmount}
            fetchData={(from: Dayjs, to: Dayjs) =>
              fetchTimesheetData(from, to, dispatch)
            }
            isPinned={pinnedProjects?.some((id: string) => id === project?.id)}
            key={project?.id}
            openedProjectAdvancedView={openedProjectAdvancedView}
            setOpenedProjectAdvancedView={setOpenedProjectAdvancedView}
            timeFrame={[startOfWeek, endOfWeek]}
            projectIdsInOrder={rowProjectsDisplayingOrderIds}
            updateProjectOrder={handleOnUpdateProjects}
            removeProjectFromList={refreshAfterDeleting}
          />
        ))
      ) : (
        <LoaderRow timeFrame={[startOfWeek, endOfWeek]} />
      )}
      {timesheetHolidayIds.length && templateHolidays.length ? (
        timesheetHolidayIds.map((row: string): ReactElement => {
          const holiday: HolidaysItemModel | undefined = templateHolidays?.find(
            (item: HolidaysItemModel) => item?.holidaysId === row,
          );
          return holiday ? (
            <HolidayRow
              key={row}
              timeFrame={[startOfWeek, endOfWeek]}
              dayAmount={dayAmount}
              data={holidaysTimeEntries}
              chosenHoliday={holiday}
              fetchData={(from: Dayjs, to: Dayjs) =>
                fetchTimesheetData(from, to, dispatch)
              }
              onRemove={onRemoveClick}
            />
          ) : (
            <></>
          );
        })
      ) : (
        <></>
      )}
      {(!rowProjectsDisplayingOrderIds?.length ||
        (rowProjectsDisplayingOrderIds?.length && newEmptyRow)) &&
      !isLoadingOnMount ? (
        [...Array(newEmptyRow || 1)].map(
          (_, index: number): ReactElement => (
            <EmptyProjectRow
              autoFocus={isNewProject}
              dayAmount={dayAmount}
              key={uid(index)}
              projectIdsInOrder={rowProjectsDisplayingOrderIds}
              setNewEmptyRow={(newValue) => {
                setNewEmptyRow(newValue);
                setIsNewProject(false);
              }}
              updateProjectOrder={handleOnUpdateProjects}
            />
          ),
        )
      ) : (
        <></>
      )}
      <Summary
        dayAmount={dayAmount}
        startDate={startOfWeek}
        data={{ ...projectsTimeEntries, ...holidaysTimeEntries }}
        isAdvancedView={openedProjectAdvancedView}
      />
      <div className="flex">
        {isProjectListEmpty ? (
          <Tooltip
            verticalOffset={-17}
            placement="top"
            additionalClassName="border border-neutralPrimary-20 border-opacity-30"
            content={
              <span>{t(`${BASE_PATH}.sections.addProjectDisabled`)}</span>
            }
          >
            {renderAddProjectBtn()}
          </Tooltip>
        ) : (
          renderAddProjectBtn()
        )}
        {isCopyPrevBtnDisabled ? (
          renderCopyPrevWeekBtn()
        ) : (
          <Tooltip
            verticalOffset={-17}
            placement="top"
            additionalClassName="border border-neutralPrimary-20 border-opacity-30"
            isDisabled={isNextWeekOrAfter}
            content={
              <span>
                {Object.keys(previousWeekTimesheet)?.length === 0
                  ? t(`${BASE_PATH}.sections.lastWeekNotFilled`)
                  : t(`${BASE_PATH}.sections.fillUpDisabled`)}
              </span>
            }
          >
            {renderCopyPrevWeekBtn()}
          </Tooltip>
        )}
      </div>
      <AddHolidayModal
        holidayTypesList={templateHolidays}
        isOpen={isAddHolidayModalOpen}
        minDate={dayjs().subtract(1, "day")}
        onClose={() => setIsAddHolidayModalOpen(false)}
        onSave={handleOnNewHolidayEntrySave}
      />
      {removingHolidaysIs && (
        <RemoveHolidayEntryModal
          isOpen={!!removingHolidaysIs}
          onClose={() => setRemovingHolidaysId("")}
          onRemove={handleRemoveEntry}
        />
      )}
    </section>
  );
};

export default TimeLog;
