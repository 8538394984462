import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { EditMatrixContext } from "./context";
import {
  SKILLS_MATRIX_EXPERTISE,
  SKILLS_MATRIX_EXPERTISE_TEMPLATE,
} from "../../../../../shared/data-cy";
import { TemplateContainer } from "./components";
import { toggleEditMode } from "../../../../../store";

const EditMatrix = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [editProfession, setEditProfession] = useState("");
  const [editGroups, setEditGroups] = useState<string[] | []>([]);
  const [editTechnologies, setEditTechnologies] = useState<string[] | []>([]);
  const [isDragEndDrop, setIsDragAndDrop] = useState(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [editTechnologyMode, setEditTechnologyMode] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      editProfession ||
      editGroups?.length ||
      editTechnologies?.length ||
      isDragEndDrop
    ) {
      dispatch(toggleEditMode(SKILLS_MATRIX_EXPERTISE_TEMPLATE));
    } else {
      dispatch(toggleEditMode(""));
    }
  }, [editProfession, editGroups, editTechnologies, isDragEndDrop]);

  return (
    <section className="h-5/6 flex flex-col gap-4">
      <span className="text-base font-semibold text-neutralPrimary-20">
        {t("expertise.matrix.editMatrix.createMatrix")}
      </span>
      <EditMatrixContext.Provider
        value={{
          editProfession,
          setEditProfession,
          editTechnologies,
          editGroups,
          setEditGroups,
          setEditTechnologies,
          isDragEndDrop,
          setIsDragAndDrop,
          isNotificationVisible,
          setIsNotificationVisible,
          editTechnologyMode,
          setEditTechnologyMode,
        }}
      >
        <div
          className="flex flex-col h-full w-full max-h-full"
          data-cy={`${SKILLS_MATRIX_EXPERTISE}_template`}
        >
          <TemplateContainer />
        </div>
      </EditMatrixContext.Provider>
    </section>
  );
};

export default EditMatrix;
