import { useDispatch } from "redux-react-hook";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { ReactElement, useEffect, useState } from "react";
import {
  createUsersError,
  createUsersStart,
  createUsersSuccess,
  fetchUsers,
  S3FileModel,
  UserService,
} from "@ps/hh";
import { v4 as uuidv4 } from "uuid";
import { Modal2 } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import styles from "./styles.module.css";
import { INVITING_MODAL } from "../../../shared/data-cy";
import StartScreen from "./startScreen";
import MemberDataScreen from "./memberDataScreen";
import {
  emptyMember,
  MAX_FILES_UPLOAD,
  S3UploadCV,
  validateFile,
} from "./utils";
import MembersList from "./membersList";
import { InfoNotification } from "./components";
import CancelingModal from "./components/cancelingModal";
import { IMember, InvitingModalProps } from "./types";
import { useTranslationWithNamespace } from "../../../hooks";
import { InvitingModalContext } from "./context";

const MARGIN = 9;

const InvitingModal = ({
  isOpen,
  onClose,
}: InvitingModalProps): ReactElement => {
  const dispatch = useDispatch();
  const [activeMemberId, setActiveMemberId] = useState<null | number>(null);
  const [totalStepLength, setTotalStepLength] = useState(0);
  const [step, setStep] = useState(0);

  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [editingModalID, setEditingModalID] = useState<null | number>(null);
  const [isCancelingModalOpen, setIsCancelingModalOpen] = useState(false);
  const { t } = useTranslationWithNamespace();

  useEffect(() => {
    setTotalStepLength(0);
    setStep(0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInfoVisible(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isInfoVisible]);

  const methods = useForm({
    defaultValues: {
      addingMembers: [],
      members: [],
    },
  });

  const { fields, append, update, remove } = useFieldArray({
    control: methods.control,
    name: "addingMembers",
  });

  const addedMembersFieldArray = useFieldArray({
    control: methods.control,
    name: "members",
  });

  const onSubmit = async (
    data: IMember[] | Record<"id", string>[],
  ): Promise<void> => {
    const newMembers = data?.members;
    dispatch(createUsersStart());
    const response = await UserService.createUsers(newMembers);
    if (response?.data?.failed?.length) {
      dispatch(createUsersError());
      const APIErrors = response?.data?.failed;
      addedMembersFieldArray?.fields?.map(
        (item: IMember | Record<"id", string>, index: number) => {
          const errorEmailIndex = APIErrors.findIndex(
            (el: { reason: string; email: string }) =>
              el?.email === item?.email,
          );
          return index === errorEmailIndex
            ? addedMembersFieldArray?.update(index, {
                ...item,
                error: APIErrors[errorEmailIndex]?.reason,
              })
            : item;
        },
      );
    } else {
      dispatch(createUsersSuccess());
      onClose();
    }
    await fetchUsers(dispatch);
  };

  const onError = async (): Promise<void> => {
    methods.clearErrors();
    await onSubmit(fields);
  };

  const handleOnCancel = () => {
    setIsCancelingModalOpen(true);
  };

  const handleOnCloseModal = () => {
    if (activeMemberId === null && addedMembersFieldArray?.fields?.length) {
      methods.reset({
        addingMembers: [],
        members: [],
      });
      setIsCancelingModalOpen(true);
    } else if (activeMemberId !== null) {
      setIsCancelingModalOpen(true);
    } else {
      onClose();
      setActiveMemberId(null);
    }
  };

  const handleOnCloseXModal = () => {
    if (
      fields?.length ||
      addedMembersFieldArray?.fields?.length ||
      activeMemberId !== null
    ) {
      setIsCancelingModalOpen(true);
    } else onClose();
  };

  const getModalWidth = () => {
    if (typeof editingModalID === "number")
      return styles.modalPartialAnimationWidth;
    if (
      (!fields?.length && !addedMembersFieldArray?.fields?.length) ||
      (editingModalID === null && fields.length)
    )
      return styles.containerPartialWidth;
    if (addedMembersFieldArray?.fields?.length && !fields?.length)
      return styles.modalFullAnimationWidth;
    return styles.containerFullWidth;
  };

  const handleOnFileSelected = async (files: FileList): Promise<void> => {
    setTotalStepLength(files?.length);
    setStep(1);

    if (
      Object.entries(files)?.length + addedMembersFieldArray?.fields.length >
      MAX_FILES_UPLOAD
    ) {
      setIsInfoVisible(true);
      return;
    }

    const uidList = new Array(files.length).fill(0).map(() => uuidv4());

    const simplifiedFiles = Object.entries(files).map(
      (item, index: number) => ({
        file: item[1],
        fileId: uidList[index],
        clientError: validateFile(item[1], t),
      }),
    );

    const S3UploadedList = await S3UploadCV(simplifiedFiles);

    simplifiedFiles?.map((item: { file: File; fileId: string }): void => {
      const currentItem = S3UploadedList?.find(
        (el: S3FileModel) => el.fileId === item.fileId,
      );
      return append({
        ...emptyMember,
        ...(currentItem || item),
        error: currentItem?.error
          ? [t("members.inviting.errors.unknownUploadCVError")]
          : "",
      });
    });
    setActiveMemberId(0);
  };

  const renderContent = (): ReactElement => {
    if (fields.length && activeMemberId !== null)
      return (
        <MemberDataScreen
          remove={(id) => {
            if (!(fields?.length - 1)) setActiveMemberId(null);
            remove(id);
          }}
          MARGIN={MARGIN}
          id={activeMemberId}
          fields={fields}
          update={update}
          onCancel={handleOnCancel}
          isEditMode={editingModalID !== null}
          membersFieldsList={addedMembersFieldArray?.fields}
          addAddedMemberList={(newItem: IMember): void =>
            editingModalID !== null
              ? addedMembersFieldArray?.update(editingModalID, newItem)
              : addedMembersFieldArray.append(newItem)
          }
          goNext={() => {
            const prevID = activeMemberId;
            if (
              prevID === null ||
              prevID + 1 >= fields?.length ||
              step + 1 > totalStepLength
            ) {
              setActiveMemberId(null);
              methods.reset({
                addingMembers: [],
                members: methods.getValues("members"),
              });
              setEditingModalID(null);
              setStep(1);
              setTotalStepLength(0);
            } else {
              setActiveMemberId(prevID + 1);
              setStep(step + 1);
            }
          }}
        />
      );
    if (
      !fields.length &&
      activeMemberId === null &&
      addedMembersFieldArray?.fields?.length
    )
      return (
        <div className="flex pag-8">
          <StartScreen
            additionalClassName={styles.modalContent}
            width="w-1/2"
            MARGIN={MARGIN}
            fields={fields}
            onAddNewClick={() => {
              append(emptyMember);
              setActiveMemberId(0);
            }}
            onFilesSelected={async (files: FileList): Promise<void> => {
              await handleOnFileSelected(files);
            }}
          />
          <div
            className={classJoin(
              "w-1/2",
              `py-${MARGIN}`,
              `pr-${MARGIN}`,
              styles.rightColumn,
            )}
          >
            <MembersList
              newMembersFieldArray={addedMembersFieldArray}
              onCloseModal={handleOnCancel}
              onEdit={(id: number): void => {
                setActiveMemberId(0);
                setEditingModalID(id);
                append(addedMembersFieldArray.fields[id]);
              }}
            />
          </div>
        </div>
      );

    return (
      <StartScreen
        MARGIN={MARGIN}
        fields={fields}
        onCloseClick={handleOnCloseModal}
        onAddNewClick={() => {
          append(emptyMember);
          setActiveMemberId((prev: number | null) =>
            prev === null ? 0 : fields.length + 1,
          );
        }}
        onFilesSelected={async (files: FileList): Promise<void> => {
          await handleOnFileSelected(files);
        }}
      />
    );
  };

  return (
    <>
      <InfoNotification isVisible={isInfoVisible} />
      {isCancelingModalOpen && (
        <CancelingModal
          isOpen={isCancelingModalOpen}
          onClose={() => setIsCancelingModalOpen(false)}
          onConfirm={() => {
            if (
              activeMemberId === null &&
              addedMembersFieldArray?.fields.length
            ) {
              onClose();
            } else if (activeMemberId === null) {
              methods.reset({
                addingMembers: [],
                members: [],
              });
              setTotalStepLength(0);
              setStep(0);
              setIsCancelingModalOpen(false);
            } else if (editingModalID !== null) {
              setEditingModalID(null);
              setActiveMemberId(null);
              methods.reset({
                addingMembers: [],
                members: methods.getValues("members"),
              });
              setTotalStepLength(0);
              setStep(0);
            } else {
              methods.reset({
                addingMembers: [],
                members: methods.getValues("members"),
              });
              setTotalStepLength(0);
              setStep(0);
              setActiveMemberId(null);
            }
            setIsCancelingModalOpen(false);
          }}
          onContinue={() => setIsCancelingModalOpen(false)}
        />
      )}
      <Modal2
        isOpen={isOpen}
        withYScroll={false}
        dataCy={INVITING_MODAL}
        onClose={handleOnCloseXModal}
        additionalContentClassName="flex justify-center h-full"
        width={getModalWidth()}
        padding="0"
        background="bg-neutralSecondary-90"
        withCloseIcon
        height={styles.container}
        overlayHide={isCancelingModalOpen}
      >
        <FormProvider {...methods}>
          <InvitingModalContext.Provider
            value={{
              step,
              setStep,
              totalStepLength,
              setTotalStepLength,
              activeMemberId,
              setActiveMemberId,
            }}
          >
            <form
              onSubmit={methods.handleSubmit(onSubmit, onError)}
              className={classJoin(
                "flex justify-center h-full w-full",
                !fields.length &&
                  activeMemberId === null &&
                  addedMembersFieldArray?.fields?.length
                  ? styles.modalFullWidth
                  : styles.modalPartialWidth,
              )}
            >
              {renderContent()}
            </form>
          </InvitingModalContext.Provider>
        </FormProvider>
      </Modal2>
    </>
  );
};

export default InvitingModal;
