import { ReactElement, useEffect, useRef, useState } from "react";
import { classJoin } from "@ps/utils";
import { ExpandWrapperProps } from "./types";
import { ReactComponent as ArrowDownIcon } from "../../../../../images/prospect/arrowDown.svg";

const ExpandWrapper = ({
  children,
  threshold,
  onArrowClick,
  isExpanded,
  initialHeight,
  outputHeight,
  arrowColor,
}: ExpandWrapperProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (!containerRef.current) return;
    const containerWidth = containerRef.current.offsetWidth;

    const child = containerRef.current.children[0] as HTMLElement;
    child.style.position = "relative";
    child.style.width = "fit-content";
    const childWidth = child.offsetWidth;

    if (childWidth + threshold > containerWidth) setShowButton(true);
  }, [containerRef]);

  const containerClassName = !isExpanded
    ? `overflow-hidden ${initialHeight}`
    : `${outputHeight}`;

  return (
    <div ref={containerRef} className={classJoin(containerClassName, "w-full")}>
      {children}
      {showButton && (
        <ArrowDownIcon
          tabIndex={0}
          role="button"
          className={classJoin(
            isExpanded
              ? "transform duration-200 rotate-180"
              : "duration-200 rotate-180",
            "h-7 w-7 transform-rotate absolute top-6 right-0 outline-none",
            arrowColor,
          )}
          onClick={onArrowClick}
        />
      )}
    </div>
  );
};

export default ExpandWrapper;
