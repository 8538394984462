import { AnyAction } from "redux";
import ActionTypes from "./notificationsActionTypes";
import { Notification } from "../types";

const notificationsReducer = (
  state: Notification[] = [],
  action: AnyAction,
) => {
  switch (action.type) {
    case ActionTypes.ADD_NOTIFICATION:
      return [...state, action.payload];
    case ActionTypes.REMOVE_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.payload);
    case ActionTypes.CLEAR_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
};

export default notificationsReducer;
