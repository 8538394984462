import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import { AnyAction, Dispatch } from "redux";
import { ProfileService } from "../../profile-domain";
import FolderService from "../services";
import ActionTypes from "./actionTypes";
import { IAction } from "./types";
import { NewCandidateAPIModel } from "../models";

export const fetchFolderStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_FOLDER_START);
export const fetchFolderSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_FOLDER_SUCCESS, payload);
export const fetchFolderError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_FOLDER_ERROR, payload);

export const fetchFolder = async (
  id: string,
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchFolderStart());
  const response = await FolderService.getFolder(id);
  if (isAPIError(response)) {
    dispatch(fetchFolderError(response));
  } else {
    dispatch(fetchFolderSuccess(response));
  }
};

export const fetchFoldersStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_FOLDERS_START);
export const fetchFoldersSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_FOLDERS_SUCCESS, payload);
export const fetchFoldersError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_FOLDERS_ERROR, payload);
export const clearFolders = (): void =>
  actionCreator(ActionTypes.CLEAR_FOLDERS);

export const fetchFolders = async (
  dispatch: Dispatch,
  clientId?: string,
  archived?: boolean,
): Promise<AnyAction | void> => {
  dispatch(fetchFoldersStart());
  const response = await FolderService.getAllFolders(clientId, archived);
  if (isAPIError(response)) {
    dispatch(fetchFoldersError(response));
  } else {
    dispatch(fetchFoldersSuccess(response));
  }
};

export const fetchFolderProposalProfileStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_FOLDER_PROPOSAL_PROFILE_START);
export const fetchFolderProposalProfileSuccess = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_FOLDER_PROPOSAL_PROFILE_SUCCESS, payload);
export const fetchFolderProposalProfileError = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_FOLDER_PROPOSAL_PROFILE_ERROR, payload);

export const fetchFolderProposalProfile = async (
  userId: string,
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchFolderProposalProfileStart());
  const result = await ProfileService.fetchProfile(userId);
  if (isAPIError(result)) {
    dispatch(fetchFolderProposalProfileError(result));
  } else {
    dispatch(fetchFolderProposalProfileSuccess(result));
  }
};

export const toggleFolderOpen = (
  id: string,
  isOpened: boolean,
  dispatch: Dispatch,
): AnyAction =>
  isOpened
    ? dispatch(actionCreator(ActionTypes.REMOVE_OPENED_FOLDERS_ID, id))
    : dispatch(actionCreator(ActionTypes.ADD_OPENED_FOLDERS_ID, id));

export const resetOpenedFolders = (): AnyAction =>
  actionCreator(ActionTypes.RESET_OPENED_FOLDERS);

export const addCandidateToFolderStart = (): AnyAction =>
  actionCreator(ActionTypes.ADD_CANDIDATE_TO_FOLDER_START);
export const addCandidateToFolderSuccess = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.ADD_CANDIDATE_TO_FOLDER_SUCCESS, payload);
export const addCandidateToFolderError = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.ADD_CANDIDATE_TO_FOLDER_ERROR, payload);

export const addingCandidatesToFolder = async (
  folderId: string,
  data: NewCandidateAPIModel[],
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(addCandidateToFolderStart());
  const result = await FolderService.addCandidates(folderId, data);
  if (isAPIError(result)) {
    dispatch(addCandidateToFolderError(result));
  } else {
    dispatch(addCandidateToFolderSuccess(result));
  }
};
