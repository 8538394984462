import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import { QuantityBoxProps } from "../types";
import { Keys } from "../../../shared";

const QuantityBox = ({
  quantity,
  borderStyle,
  onClick,
  dataCy,
}: QuantityBoxProps): ReactElement => {
  return (
    <div
      data-cy={dataCy}
      onClick={onClick}
      tabIndex={onClick ? 0 : -1}
      role={onClick ? "button" : "presentation"}
      onKeyDown={(e: KeyboardEvent) => {
        if (onClick && e.key === Keys.ENTER) onClick();
      }}
      className={classJoin(
        borderStyle,
        onClick && "hover:opacity-80",
        "flex justify-center items-center border w-10 h-10 rounded-lg",
      )}
    >
      {quantity}
    </div>
  );
};

export default QuantityBox;
