import APIService from "../api/prospect-api";
import {
  createProspectToAPIModel,
  ProspectDetailsModel,
  prospectFromAPIToModel,
  prospectsListFromAPIToModel,
  updateProspectToAPIModel,
} from "../models";
import {
  createNeed,
  deleteNeed,
  fetchNeeds,
  updateNeed,
} from "./prospectNeeds";
import { createNote, deleteNote, updateNote } from "./prospectNotes";
import { DictionaryModel } from "../../dictionaries";
import { handleError } from "../../shared";

const ProspectService = (() => {
  const createProspect = async (
    data: ProspectDetailsModel,
  ): Promise<unknown> => {
    try {
      const result = await APIService.API.addProspect(
        createProspectToAPIModel(data),
      );

      return result?.data?.id;
    } catch (error) {
      return handleError(error);
    }
  };

  const getProspect = async (id: string, technologyDict: DictionaryModel) => {
    try {
      const result = await APIService.API.getProspect(id);
      if (result?.data) {
        return prospectFromAPIToModel(result.data, technologyDict);
      }
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const updateProspect = async (id: string, data) => {
    try {
      const result = await APIService.API.updateProspect(
        id,
        updateProspectToAPIModel(data),
      );
      return result;
    } catch (error) {
      return handleError(error);
    }
  };

  const getProspects = async (clientId?: string, archived?: boolean) => {
    try {
      const result = await APIService.API.getProspects(clientId, archived);
      if (result?.data) {
        return prospectsListFromAPIToModel(result.data);
      }
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const archiveProspect = async (id: string, archived: boolean) => {
    try {
      const result = await APIService.API.setArchived(id, {
        archived,
      });
      return result;
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    createNeed,
    createNote,
    createProspect,
    deleteNeed,
    fetchNeeds,
    getProspect,
    updateNeed,
    updateNote,
    updateProspect,
    deleteNote,
    getProspects,
    archiveProspect,
  };
})();

export default ProspectService;
