const minutesToHHMM = (minutes: number): string => {
  const HH = minutes / 60;
  const rhours = Math.floor(HH);
  const formattedRhours =
    rhours.toString().length === 1 ? `0${rhours}` : rhours;
  const MM = (HH - rhours) * 60;
  const rminutes = Math.round(MM);
  const formattedRminutes =
    rminutes.toString().length === 1 ? `0${rminutes}` : rminutes;
  return `${formattedRhours}:${formattedRminutes}`;
};

export default minutesToHHMM;
