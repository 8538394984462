import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { focusVisibleStyles } from "../../shared";
import { IconContainerProps } from "./types";

const IconContainer = ({
  children,
  additionalClassName = "",
  onClick,
  type = "button",
  disabled,
}: IconContainerProps): ReactElement => (
  <button
    type={type}
    className={classJoin(
      `border rounded-lg p-1.5 border-neutralSecondary-41
      flex justify-center items-center hover:opacity-50 disabled:pointer-events-none`,
      focusVisibleStyles,
      additionalClassName,
      disabled ? "opacity-40 cursor-not-allowed" : "",
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

export default IconContainer;
