import { AxiosResponse } from "axios";
import APIService from "../api/project-api";
import { handleError } from "../../shared/utils/service-utils";
import { createTeamMemberToAPIModel, TeamMemberModel } from "../models";

export const createTeamMember = async (
  projectId: string,
  data: TeamMemberModel,
): Promise<AxiosResponse<void>> => {
  try {
    const result = await APIService.API.addProjectTeamMember(
      projectId,
      createTeamMemberToAPIModel(data),
    );
    return result;
  } catch (error) {
    return handleError(error);
  }
};

export const updateTeamMember = async (
  projectId: string,
  userId: string,
  data: TeamMemberModel,
): Promise<AxiosResponse<void>> => {
  try {
    const result = await APIService.API.updateProjectTeamMember(
      projectId,
      userId,
      createTeamMemberToAPIModel(data),
    );
    return result;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteTeamMember = async (
  projectId: string,
  userId: string,
  rejectReason: string,
): Promise<AxiosResponse<void>> => {
  try {
    const result = await APIService.API.deleteProjectTeamMember(
      projectId,
      userId,
      rejectReason,
    );
    return result;
  } catch (error) {
    return handleError(error);
  }
};
