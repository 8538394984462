import { HolidaysItemModel, UpdateUserHolidayAPIModel } from "../models";

const mapHolidaysToModification = (
  holidaysArr: HolidaysItemModel[],
): UpdateUserHolidayAPIModel[] =>
  holidaysArr.map(
    (holiday: HolidaysItemModel): UpdateUserHolidayAPIModel => ({
      id: holiday.id,
      modification: parseInt(holiday.modification.toString(), 10),
    }),
  );

export default mapHolidaysToModification;
