import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../hooks";
import { EmptyProps } from "./types";

const Empty = ({ text }: EmptyProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <span className="text-neutralSecondary-40 font-normal">
      {text || t("empty.default")}
    </span>
  );
};

export default Empty;
