import { ReactElement } from "react";
import { Label } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { DATE_FORMAT } from "../../../common/constants";
import { CourseCardProps } from "../types";
import { EXPERIENCE_EDUCATIONS_VIEW } from "../../../../../../shared/data-cy";
import {
  COURSE,
  COURSE_NAME,
  DESCRIPTION,
  START_DATE,
  TRAINER,
} from "../constants";
import { END_DATE } from "../../projects/constants";

const CourseCard = ({
  trainer,
  courseName,
  startDate,
  endDate,
  description,
}: CourseCardProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const translate = (field: string) =>
    t(`profile.experience.cards.course.${field}`);

  const BASE_PATH = `${EXPERIENCE_EDUCATIONS_VIEW}_${COURSE}`;

  return (
    <div className="w-full grid grid-cols-3 gap-5 p-6">
      <Label text={translate(TRAINER)} dataCy={`${BASE_PATH}_${TRAINER}`}>
        {trainer}
      </Label>
      <Label
        text={translate(COURSE_NAME)}
        dataCy={`${BASE_PATH}_${COURSE_NAME}`}
        additionalClassName="col-span-2"
      >
        {courseName}
      </Label>
      <div className="w-min flex gap-8 justify-start col-span-3">
        <Label
          text={translate(START_DATE)}
          dataCy={`${BASE_PATH}_${START_DATE}`}
        >
          {startDate?.format(DATE_FORMAT)}
        </Label>
        <Label
          text={translate(END_DATE)}
          dataCy={`${BASE_PATH}_${END_DATE}`}
          additionalClassName="col-span-2"
        >
          {endDate
            ? endDate?.format(DATE_FORMAT)
            : `${t("profile.other.present")}`}
        </Label>
      </div>
      {description && (
        <div className="w-full whitespace-pre-wrap col-span-3">
          <Label
            text={translate(DESCRIPTION)}
            dataCy={`${BASE_PATH}_${DESCRIPTION}`}
            fontWeight="font-normal"
          >
            {description}
          </Label>
        </div>
      )}
    </div>
  );
};

export default CourseCard;
