import { KeyboardEvent, ReactElement } from "react";
import dayjs from "dayjs";
import { DATEPICKER_SELECTED_DATE_PREFIX } from "../../../shared/data-cy";
import constructDataCy from "../helpers/dataCy";
import getDaysArray from "../helpers/time/getDaysArray";
import {
  getSelectedStyle,
  isSelectedDate,
} from "../helpers/style/getSelectedStyle";
import getTimeUnitButtonStyle from "../helpers/style/getTimeUnitButtonStyle";
import {
  CalendarDisplayModes,
  DatepickerMode,
  DatepickerPickerEnum,
  DisplayModeProps,
} from "../types";
import { InputType } from "../helpers/state/useSelectDateTypes";
import styles from "../styles.module.css";
import { Keys } from "../../../shared";

const WeekDisplayMode = ({
  selectedDatesActions,
  currentDisplayedMonth,
  state,
  mode,
  disabledDate,
  dataCy,
  closeCalendar,
}: DisplayModeProps): ReactElement => {
  const daysOfWeek = dayjs.weekdaysShort(true);

  const getButtonStyle = getTimeUnitButtonStyle({
    stylesSelected: {
      notSelected: "bg-transparent text-neutralPrimary-30",
      selectedInBetween: "bg-primary-50 bg-opacity-30 text-primary-100",
      selectedFirstInRow: "bg-primary-50 bg-opacity-30 rounded-l-full",
      selectedLastInRow: "bg-primary-50 bg-opacity-30 rounded-r-full",
      notInCurrentSelection: "bg-transparent text-neutralPrimary-30 opacity-30",
    },
    stylesHover: {
      hoverInBetween:
        "border-t border-b border-primary-50 border-opacity-50 border-dashed bg-opacity-50",
      nextUnitAfterHoverSecondInput: "",
      prevUnitBeforeHoverFirstInput: "",
      hoverFirstInRow:
        "border-t border-b border-primary-50 border-opacity-50 rounded-l-full border-dashed border-l bg-opacity-50",
      hoverLastInRow:
        "border-t border-b border-primary-50 border-opacity-50 rounded-r-full border-dashed border-r bg-opacity-50",
    },
    compareMode: DatepickerPickerEnum.DAY,
    displayMode: CalendarDisplayModes.WEEKS,
  })({
    selectedDates: state.selected,
    hoveringOverDate:
      mode === DatepickerMode.RANGE_PICKER ? state.hover : undefined,
    currentInput: state.input,
  });

  const applyDataCy = constructDataCy(dataCy);

  const rangeStart = state.selected?.[0];

  return (
    <div
      className={`grid w-full h-min ${styles.day_grid} grid-cols-7 gap-y-1 px-2.5 mb-2 justify-items-center`}
      onPointerLeave={selectedDatesActions?.onPointerLeave}
    >
      {daysOfWeek.map((day) => (
        <span
          className="text-xs text-neutralPrimary-20 font-semibold"
          key={day}
        >
          {day.toUpperCase()}
        </span>
      ))}
      {getDaysArray(currentDisplayedMonth).map((month, monthIndex) => {
        // Index 0 is prev month, Index 2 is one month after the currently focused
        const isDateFromFocusedMonth = monthIndex === 1;
        return month.map((day) => {
          const isDaySelected = isSelectedDate(state.selected, day);
          return (
            <button
              className={`flex items-center justify-center w-full h-10 border-box flex-shrink-0
              ${getButtonStyle(day, isDateFromFocusedMonth)}`}
              key={day.toISOString()}
              data-cy={
                isDaySelected
                  ? applyDataCy(
                      DATEPICKER_SELECTED_DATE_PREFIX,
                      rangeStart && day.isSame(rangeStart, "day")
                        ? InputType.FIRST
                        : InputType.SECOND,
                    )
                  : undefined
              }
              onPointerEnter={selectedDatesActions?.onPointerEnter(day)}
              onClick={() =>
                selectedDatesActions?.forceSelectRange([
                  day.startOf("week"),
                  day.endOf("week"),
                ])
              }
              onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                if (event.key === Keys.ESC) {
                  event.preventDefault();
                  closeCalendar();
                  event.stopPropagation();
                }
              }}
              disabled={disabledDate?.(day, state.selected)}
            >
              <div
                className={`flex items-center justify-center rounded-full flex-shrink-0
              focus:outline-none w-8 h-8 cursor-pointer
              disabled:pointer-events-none disabled:text-neutralSecondary-30 disabled:opacity-30 text-sm
              ${
                isDateFromFocusedMonth
                  ? getSelectedStyle(state.selected, day)
                  : ""
              }`}
              >
                {day.date()}
              </div>
            </button>
          );
        });
      })}
    </div>
  );
};

export default WeekDisplayMode;
