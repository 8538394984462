import { AnyAction, Dispatch } from "redux";
import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import ActionTypes from "./actionTypes";
import { ProspectService } from "../services";
import { DictionaryModel } from "../../dictionaries";
import { IAction } from "./types";

export const fetchProspectStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROSPECT_START);
export const fetchProspectSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROSPECT_SUCCESS, payload);
export const fetchProspectError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROSPECT_ERROR, payload);

export const fetchProspect = async (
  id: string,
  dispatch: Dispatch,
  technologyDict: DictionaryModel,
): Promise<AnyAction | void> => {
  dispatch(fetchProspectStart());
  const response = await ProspectService.getProspect(id, technologyDict);
  if (isAPIError(response)) {
    dispatch(fetchProspectError(response));
  } else {
    dispatch(fetchProspectSuccess(response));
  }
};

export const fetchProspectsStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROSPECTS_START);
export const fetchProspectsSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROSPECTS_SUCCESS, payload);
export const fetchProspectsError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROSPECTS_ERROR, payload);

export const updateProspectsStart = (): AnyAction =>
  actionCreator(ActionTypes.UPDATE_PROSPECT_START);
export const updateProspectsSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.UPDATE_PROSPECT_SUCCESS, payload);
export const updateProspectsError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.UPDATE_PROSPECT_ERROR, payload);

export const archiveProspectStart = (): AnyAction =>
  actionCreator(ActionTypes.ARCHIVE_PROSPECT_START);
export const archiveProspectSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.ARCHIVE_PROSPECT_SUCCESS, payload);
export const archiveProspectError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.ARCHIVE_PROSPECT_ERROR, payload);

export const fetchProspectsList = async (
  dispatch: Dispatch,
  clientId?: string,
  archived?: boolean,
): Promise<AnyAction | void> => {
  dispatch(fetchProspectsStart());
  const result = await ProspectService.getProspects(clientId, archived);
  if (isAPIError(result)) {
    dispatch(fetchProspectsError(result));
  } else {
    dispatch(fetchProspectsSuccess(result));
  }
};
