import { ReactElement } from "react";
import { ReactComponent as CheckMarkIcon } from "../../../images/check-mark.svg";
import { ReactComponent as XIcon } from "../../../images/x.svg";
import { useTranslationWithNamespace } from "../../../hooks";
import { STATUS } from "../../../shared/data-cy";
import Chip from "../chip";
import { StatusChipProps } from "../types";

const APPROVED = "approved";
const REJECTED = "rejected";

const StatusChip = ({ status, dataCy }: StatusChipProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const matchColorToStatus = (): string => {
    switch (status) {
      case APPROVED:
        return "bg-success-60 text-primary-100 border-success-60";
      case REJECTED:
        return "bg-error-30 text-primary-100 border-error-30";
      default:
        return "border-neutralSecondary-60";
    }
  };

  return (
    <Chip
      tailwindColors={matchColorToStatus()}
      borderWidth="border"
      label={
        <div className="flex items-center h-8 gap-2">
          {status === APPROVED ? (
            <CheckMarkIcon className="w-3.5 h-3.5 fill-color" />
          ) : (
            <XIcon className="w-3.5 h-3.5 fill-current" />
          )}
          <span>{t(`chip.status.${status}`)}</span>
        </div>
      }
      dataCy={`${STATUS}_${dataCy}`}
    />
  );
};

export default StatusChip;
