import { ProjectTimeEntriesModel } from "../../../../models";

const countTotalRow = (entries: ProjectTimeEntriesModel[]): number => {
  const onlyEntries = entries?.map((item) => item[1]);
  const flattenEntries = onlyEntries?.flat();
  const sumMinutes = flattenEntries?.reduce((acc, val) => acc + val.minutes, 0);
  return sumMinutes || 0;
};

export default countTotalRow;
