import { ReactElement } from "react";
import dayjs from "dayjs";
import { StackedBarChart, Card, useThemeMode } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { ReactComponent as SearchingImage } from "../../../images/reports/searching.svg";
import {
  StartScreenProps,
  StartScreenMapState,
  StartScreenMapStateReturn,
} from "./types";
import { STACKED_BAR_CHART } from "../constants";
import { IDateAsName } from "../types";

const StartScreen = ({ timeFrame }: StartScreenProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();

  const mapState = (state: StartScreenMapState): StartScreenMapStateReturn => ({
    report: state?.reports?.report || [],
  });

  const { report } = useMappedStateSelector(mapState);

  const timeFrameDifference: number =
    dayjs(timeFrame?.[1]).diff(dayjs(timeFrame?.[0]), "day") + 1;
  const isYearView: boolean =
    dayjs(timeFrame?.[1]).diff(dayjs(timeFrame?.[0]), "month") + 1 === 12;

  const timeFrameDates: IDateAsName[] = isYearView
    ? dayjs.monthsShort().map((month: string): IDateAsName => ({ name: month }))
    : Array(timeFrameDifference)
        .fill(0)
        .map(
          (_, index: number): IDateAsName => ({
            name: dayjs(timeFrame?.[0]).add(index, "day").format("DD.MM.YYYY"),
          }),
        );

  return (
    <div className="flex flex-col gap-3 select-none mt-4">
      <Card
        dataCy={STACKED_BAR_CHART}
        additionalCardClassName="h-full w-full p-8 shadow-md"
      >
        {report?.length ? (
          <StackedBarChart
            allProjects={report}
            colors={[]}
            data={timeFrameDates}
            dataCy={STACKED_BAR_CHART}
            isYear={isYearView}
            keys={[]}
          />
        ) : (
          <></>
        )}
        <div className="m-24 flex justify-center items-center gap-8 h-100">
          <SearchingImage
            className={classJoin(
              isHighContrast ? "filter sepia" : "",
              "h-inherit",
            )}
          />
          <div className="flex flex-col gap-8">
            <span
              className={classJoin(
                "font-semibold text-neutralPrimary-20 text-3xl",
              )}
            >
              {t("reports.noData")}
            </span>
            <span
              className={classJoin(
                "text-neutralPrimary-30 text-xl",
                "h-inherit",
              )}
            >
              {t("reports.searchInformation")}
            </span>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default StartScreen;
