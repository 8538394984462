import { useDispatch } from "redux-react-hook";
import { useMappedStateSelector } from "@ps/utils";
import { uid } from "react-uid";
import { Notification, UseNotifications } from "../types";
import { DEFAULT_NOTIFICATION_DURATION } from "../constants";
import {
  removeNotification as removeNotificationAction,
  addNotification as addNotificationAction,
  clearNotifications as clearNotificationsAction,
} from "../store/actions";
import { UseNotificationsMapState } from "./types";

const mapState = (
  state: UseNotificationsMapState,
): UseNotificationsMapState => ({
  notifications: state.notifications,
});

function useNotifications(): UseNotifications {
  const dispatch = useDispatch();
  const { notifications } = useMappedStateSelector(mapState);

  const addNotification = (notification: Notification) => {
    dispatch(addNotificationAction(notification));
  };

  const removeNotification = (notification: string) => {
    dispatch(removeNotificationAction(notification));
  };

  const clearNotifications = () => {
    dispatch(clearNotificationsAction());
  };

  const addSuccesNotification = (
    message: string,
    options: Partial<Notification> = {},
  ) => {
    dispatch(
      addNotificationAction({
        type: "success",
        message,
        timeoutDuration: DEFAULT_NOTIFICATION_DURATION,
        id: uid({
          message,
          type: "success",
        }),
        ...options,
      }),
    );
  };

  const addInfoNotification = (
    message: string,
    options: Partial<Notification> = {},
  ) => {
    dispatch(
      addNotificationAction({
        type: "info",
        message,
        timeoutDuration: DEFAULT_NOTIFICATION_DURATION,
        id: uid({
          message,
          type: "info",
        }),
        ...options,
      }),
    );
  };

  const addErrorNotification = (
    message: string,
    options: Partial<Notification> = {},
  ) => {
    dispatch(
      addNotificationAction({
        type: "error",
        message,
        timeoutDuration: DEFAULT_NOTIFICATION_DURATION,
        id: uid({
          message,
          type: "error",
        }),
        ...options,
      }),
    );
  };

  const getNotification = (id: string): Notification => {
    return notifications.filter(
      (notification: Notification) => notification.id === id,
    );
  };

  return {
    notifications,
    getNotification,
    addNotification,
    removeNotification,
    clearNotifications,
    addSuccesNotification,
    addInfoNotification,
    addErrorNotification,
  };
}

export default useNotifications;
