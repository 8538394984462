import { ReactElement, useState } from "react";
import { Icon, SectionForm } from "@ps/ui-components";
import { ReactComponent as DomainsIcon } from "../../../images/domains.svg";
import { SkillsMatrixMapState, SkillsMatrixMapStateReturn } from "../types";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { ProperIcon } from "../common";
import ProfessionMatrixCard from "./professionMatrixCard";
import { DOMAINS_PROFESSION_ID } from "../../shared/consts";
import { getTabsIds, sortTabsByProfession } from "../helpers/utils";
import { MatrixContext } from "./context";
import { SKILLS_MATRIX } from "../../../shared/data-cy";
import { SEARCH_INPUT_ID } from "../common/constants";

const SkillsMatrix = ({ mode }: { mode?: string }): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [searchingValue, setSearchingValue] = useState("");
  const [isShowingInfoCard, setIsShowingInfoCard] = useState(true);

  const onClearIconClick = (): void => {
    setSearchingValue("");

    // TODO LATER change way to reset input value (for now packed with form)
    // eslint-disable-next-line
    // @ts-ignore
    const element = document.getElementById(SEARCH_INPUT_ID);
    if (element) {
      // eslint-disable-next-line
      // @ts-ignore
      element.reset();
    }
  };

  const mapState = (
    state: SkillsMatrixMapState,
  ): SkillsMatrixMapStateReturn => ({
    editMode: state.editMode,
    profileProfessions: state?.profiles?.myProfile?.professions?.main || "",
    professionsDict: state?.dictionaries?.profession || [],
    isFetchingDictionary: state.requestStatus.isFetchingDictionary,
  });

  const {
    editMode,
    profileProfessions,
    professionsDict,
    isFetchingDictionary,
  } = useMappedStateSelector(mapState);

  const tabs = [
    ...professionsDict?.map(({ id, name }) => ({
      icon: (
        <Icon dataCy={name} size="11" icon={<ProperIcon professionId={id} />} />
      ),
      title: name,
      tabKey: id,
      child: (
        <ProfessionMatrixCard
          onClearIconClick={onClearIconClick}
          professionId={id}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
        />
      ),
    })),
    {
      icon: <Icon icon={<DomainsIcon />} dataCy="domains" size="11" />,
      title: t("profile.skills.legend.domains"),
      tabKey: "domains-tab",
      child: (
        <ProfessionMatrixCard
          onClearIconClick={onClearIconClick}
          professionId={DOMAINS_PROFESSION_ID}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
        />
      ),
    },
  ];

  const sortedTabs = sortTabsByProfession(tabs, profileProfessions);

  return (
    <MatrixContext.Provider
      value={{ mode, isShowingInfoCard, setIsShowingInfoCard }}
    >
      <SectionForm
        additionalClassName="overflow-hidden"
        dataCy={`${SKILLS_MATRIX}_${mode}`}
        defaultSelectedSection={sortedTabs[0]?.tabKey}
        disabledTabs={editMode ? getTabsIds(editMode, sortedTabs) : []}
        isLoading={isFetchingDictionary}
        showProgressBar={false}
        tabs={sortedTabs}
        withOverflow={false}
        onTabClick={() => onClearIconClick()}
      />
    </MatrixContext.Provider>
  );
};

export default SkillsMatrix;
