import { Icon } from "@ps/ui-components";
import { ReactComponent as ProjectsIcon } from "../../../images/projects.svg";
import { ReactComponent as EducationIcon } from "../../../images/education.svg";
import { ReactComponent as AccomplishmentIcon } from "../../../images/accomplishment.svg";
import { ExperienceSections, SectionFormTab } from "./types";
import ProjectsSection from "./sections/projects";
import EducationSection from "./sections/education";
import AccomplishmentSection from "./sections/accomplishment";
import { COURSES, DEGREES } from "./sections/education/constants";
import {
  ARTICLES,
  AWARDS,
  CERTIFICATES,
  CONTRIBUTIONS,
  TALKS,
  TUTORS,
  PROJECTS,
} from "../OCRWizard/constants";

const tabs: SectionFormTab<ExperienceSections>[] = [
  {
    icon: <Icon icon={<ProjectsIcon />} dataCy="projects" size="11" />,
    title: "profile.experience.sections.projects",
    tabKey: ExperienceSections.PROJECTS,
    child: <ProjectsSection />,
    fields: [PROJECTS],
    id: ExperienceSections.PROJECTS,
  },
  {
    icon: <Icon icon={<EducationIcon />} size="11" dataCy="education" />,
    title: "profile.experience.sections.education",
    tabKey: ExperienceSections.EDUCATION,
    child: <EducationSection />,
    fields: [DEGREES, COURSES],
    id: ExperienceSections.EDUCATION,
  },
  {
    icon: (
      <Icon icon={<AccomplishmentIcon />} size="11" dataCy="accomplishment" />
    ),
    title: "profile.experience.sections.accomplishment",
    tabKey: ExperienceSections.ACCOMPLISHMENT,
    child: <AccomplishmentSection />,
    fields: [CONTRIBUTIONS, AWARDS, CERTIFICATES, ARTICLES, TALKS, TUTORS],
    id: ExperienceSections.ACCOMPLISHMENT,
  },
];

export default tabs;
