import {
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { uid } from "react-uid";
import { TimeTrackerLabel, useThemeMode } from "@ps/ui-components";
import { classJoin, useClickOutsideClick } from "@ps/utils";
import { useDispatch } from "redux-react-hook";
import { TOTAL_TIME_COUNTED } from "../../../../shared/data-cy";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { ReactComponent as SelectIcon } from "../../../../images/timesheet/timelog/select-project-icon.svg";
import SelectProject from "./selectProject";
import {
  EmptyProjectRowProps,
  EmptyProjectRowState,
  EmptyProjectRowStateReturn,
} from "./types";
import { appendTimesheetEntry } from "../../../../store";
import { getUnusedProjects } from "./utils";
import styles from "../styles.module.css";
import DeleteProject from "./deleteProject";
import TimeLogContext from "../../../../contexts/TimelogContext";

const BASE_PATH = "timesheet.timelog.sections";

const EmptyProjectRow = ({
  dayAmount,
  setNewEmptyRow,
  projectIdsInOrder,
  updateProjectOrder,
  autoFocus,
}: EmptyProjectRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const dispatch = useDispatch();
  const { isHighContrast } = useThemeMode();
  const selectRef = useRef<HTMLDivElement>(null);
  const selectButtonRef = useRef<HTMLButtonElement>(null);
  const [editProject, setEditProject] = useState(false);
  const { setEnableAddProject } = useContext(TimeLogContext);

  useEffect(() => {
    if (selectButtonRef.current) setEnableAddProject(true);
    return () => setEnableAddProject(false);
  }, [selectButtonRef]);

  const mapState = (
    state: EmptyProjectRowState,
  ): EmptyProjectRowStateReturn => ({
    projects: state?.projectsList || [],
  });

  const { projects } = useMappedStateSelector(mapState);

  const handleChangeEmptyRow = (): void =>
    setNewEmptyRow((prev) => (prev ? prev - 1 : 0));

  const handleSetProject = async (project: {
    value: string;
    label: Element;
  }): Promise<void> => {
    dispatch(appendTimesheetEntry({ id: project.value }));
    updateProjectOrder(project?.value);
    handleChangeEmptyRow();
  };

  const handleOnClickEditProject = (
    event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    setEditProject((prev: boolean) => !prev);
  };

  useClickOutsideClick(selectRef, () => setEditProject(false));

  return (
    <div
      className={classJoin(
        styles.rowContainer,
        "bg-neutralPrimary-100 my-3 h-15",
        isHighContrast ? "border border-primary-60" : "border-none",
      )}
    >
      {!editProject ? (
        <button
          autoFocus={autoFocus}
          ref={selectButtonRef}
          className={classJoin(
            "flex flex-row items-center text-primary-50 font-semibold hover:underline rounded-md py-6 focus:ring-1",
          )}
          style={{ textDecorationThickness: isHighContrast ? "2px" : "1px" }}
          onClick={handleOnClickEditProject}
        >
          <SelectIcon className="ml-5 mr-3" />
          {t(`${BASE_PATH}.select`)}
        </button>
      ) : (
        <div className="ml-5">
          <SelectProject
            selectRef={selectRef}
            handleSelectOnChange={handleSetProject}
            options={getUnusedProjects(projects, projectIdsInOrder)}
            projects={projects}
            menuIsOpen={editProject}
          />
        </div>
      )}
      <div
        className={classJoin(styles.timeEntriesContainer, styles.weekendEntry)}
      >
        {[...Array(dayAmount)].map(
          (_, index: number): ReactElement => (
            <div
              className={classJoin(
                styles.weekendEntry,
                "h-full w-full flex justify-center items-center",
              )}
              key={uid(index)}
            >
              <div className="rounded-lg w-28 h-9 overflow-hidden border border-neutralSecondary-60" />
            </div>
          ),
        )}
      </div>
      <TimeTrackerLabel text="00:00" dataCy={TOTAL_TIME_COUNTED} />
      <DeleteProject onDelete={handleChangeEmptyRow} />
    </div>
  );
};

export default EmptyProjectRow;
