import { ReactElement } from "react";
import { LinkButton, Modal, ModalContent } from "@ps/ui-components";
import { ReactComponent as OcrFinalIcon } from "../../../../images/ocr/ocr-final-icon.svg";
import { OcrUploadModalProps } from "./types";
import { useTranslationWithNamespace } from "../../../../hooks";
import { OCR_UPLOADER } from "../../../../shared/data-cy";

const graphicsClasses = "p-5 h-52 w-52";

const OcrFinalModal = ({
  isOpen,
  onClose,
}: OcrUploadModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Modal
      dataCy={`${OCR_UPLOADER}_finalModal`}
      height="h-auto"
      isOpen={isOpen}
      onClose={onClose}
      width="w-min-1/2 w-max-fit"
    >
      <ModalContent
        withOverflow={false}
        additionalClassName="flex flex-col gap-2 items-center"
      >
        <div className="relative">
          <OcrFinalIcon className={graphicsClasses} />
        </div>
        <h1 className="text-lg font-bold">{t("ocr.final.header")}</h1>
        <p className="text-sm mb-1">{t("ocr.final.content")}</p>
        <LinkButton
          additionalClassName="bg-primary-51 text-primary-100 hover:text-primary-100"
          dataCy={`${OCR_UPLOADER}_finalModal`}
          padding="px-8 py-3"
          onClick={onClose}
        >
          {t("ocr.final.go")}
        </LinkButton>
      </ModalContent>
    </Modal>
  );
};

export default OcrFinalModal;
