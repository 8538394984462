import { ISOToDayJs } from "@ps/utils";
import { DaysOffModel, HolidaysUserHistoryModel } from "../holidaysTypes";
import { HistoryHolidayTimeEntry, UserDayOff } from "../../api/openapi-client";

export type HistoryHolidayTimeEntryModel = HistoryHolidayTimeEntry;
export type UserDayOffModel = UserDayOff;

export const holidaysHistoryFromAPIToModel = (
  history: HistoryHolidayTimeEntryModel[],
): HolidaysUserHistoryModel[] =>
  history.map((item: HistoryHolidayTimeEntryModel) => ({
    dateFrom: ISOToDayJs(item.firstDay),
    dateTo: ISOToDayJs(item.lastDay),
    typeName: item.holidayName,
    amountOfDays: item.amountOfDays,
    entryIds: item.timeEntriesIds,
    typeId: item.holidayId,
  }));

export const daysOffFromAPIToModel = (
  history: UserDayOffModel[],
): DaysOffModel[] =>
  history.map((item: UserDayOffModel) => ({
    holidaysItemId: item.holidayId,
    holidayName: item.holidayName,
    usedDaysOff: item.usedDaysOff,
    unusedDaysOff: item.unusedDaysOff,
    sumDays:
      item.unusedDaysOff !== null
        ? item.usedDaysOff + item.unusedDaysOff
        : null,
  }));
