import { ReactElement, useLayoutEffect, useState } from "react";
import { sortArray, useFullTextSearch } from "@ps/utils";
import { useDispatch } from "redux-react-hook";
import SearchBar from "../discovery-team/searchBar";
import {
  SorterParametersProps,
  SpecialistsMapStateReturn,
  SpecialistsProps,
  SpecialstsMapState,
  UserProps,
} from "./types";
import {
  RESIDENCE,
  SPECIALISTS,
  initialFiltersState,
  SCORE,
  DESC,
  SPECIALIST,
} from "../../../../constants";
import { SessionStorage } from "../../../../../shared";
import SpecialistRow from "./specialistRow";
import SpecialistsSortBar from "./specialistsSortBar";
import { DiscoveryTeamFilters } from "../discovery-team/types";
import {
  SESSION_SORTERS,
  initialSessionSortersState,
} from "../../../../../shared/constants";
import {
  useSessionStorage,
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import { filterSpecialists } from "../utils";
import {
  prepareExtendedUsersList,
  prepareActiveCandidatesList,
} from "../../../../../folder-domain/ui/folders-list/resumes/utils";
import { addingCandidatesToFolder } from "../../../../../folder-domain/store";
import FolderService from "../../../../../folder-domain/services";
import EmptySpecialists from "./emptySpecialists";

const Specialists = ({
  users,
  folderId,
  onCancel,
}: SpecialistsProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] =
    useState<DiscoveryTeamFilters>(initialFiltersState);
  const [sorterParameters, setSorterParameters] =
    useState<SorterParametersProps>({
      name: SCORE,
      order: DESC,
    });
  const [usersListDisplay, setUsersListDisplay] = useState<UserProps[]>([]);

  const [addedUsers, setAddedUsers] = useState<string[]>([]);

  const [storageSorter, setStorageSorter] = useSessionStorage<SessionStorage>(
    SESSION_SORTERS,
    initialSessionSortersState,
  );

  const mapState = (state: SpecialstsMapState): SpecialistsMapStateReturn => ({
    candidates: prepareActiveCandidatesList(
      state?.folders?.folder?.candidates || [],
    ),
    isAddingCandidateToFolder: state?.requestStatus?.isAddingCandidateToFolder,
  });

  const { candidates } = useMappedStateSelector(mapState);

  const extendedUsers = prepareExtendedUsersList(users, candidates);

  const { search } = useFullTextSearch(extendedUsers || [], undefined, {
    keys: ["firstName", "lastName"],
  });

  const prepareTeamList = (
    searchingValue: string,
    userListFilters: DiscoveryTeamFilters,
  ): void => {
    const { specialists: teamSorter } = storageSorter.sessionSorters;

    const properSorter: {
      name: string;
      order: string;
      nestedFieldName?: string;
    } = teamSorter?.name?.length ? teamSorter : sorterParameters;

    const resultByFilter = filterSpecialists(
      extendedUsers,
      searchingValue,
      userListFilters,
      search,
    );

    const resultBySorter = sortArray(
      resultByFilter,
      properSorter.name as keyof UserProps,
      properSorter.order,
      properSorter.nestedFieldName as keyof UserProps,
    );
    setUsersListDisplay(resultBySorter);
  };

  const handleOnClickSorter = (
    fieldName: string,
    order: string,
    isNestedField?: boolean,
  ): void => {
    const nestedFieldName = isNestedField ? RESIDENCE : undefined;

    setStorageSorter((prevData) => ({
      ...prevData,
      sessionSorters: {
        ...prevData.sessionSorters,
        [SPECIALISTS]: {
          name: fieldName,
          order,
          nestedFieldName,
        },
      },
    }));

    setSorterParameters({ name: fieldName, order });
  };

  const handleOnAddUser = async (id = ""): Promise<void> => {
    const isAlreadyAdded = addedUsers?.includes(id);
    if (!isAlreadyAdded) {
      const userToAdd = usersListDisplay.find(
        (u: UserProps): boolean => u.userId === id,
      );

      await addingCandidatesToFolder(
        folderId,
        [
          {
            userId: id,
            overriddenSeniority: userToAdd?.seniority || "",
          },
        ],
        dispatch,
      );
      return setAddedUsers((prev) => [...prev, id]);
    }
    await FolderService.removeCandidate(folderId, id);
    return setAddedUsers((prev: string[]): string[] =>
      prev.filter((item: string): boolean => item !== id),
    );
  };

  useLayoutEffect(() => {
    prepareTeamList(searchValue, filters);
  }, [searchValue, users, sorterParameters.name, sorterParameters.order]);

  const handleOnClearFilters = () => {
    setSearchValue("");
    setFilters(initialFiltersState);
    prepareTeamList("", initialFiltersState);
  };

  const handleOnFilterClick = (
    isChecked: boolean,
    id: string,
    filterName: string,
  ): void => {
    const prevFilters = JSON.parse(JSON.stringify(filters));

    const newFiltersState: DiscoveryTeamFilters = isChecked
      ? {
          ...prevFilters,
          [filterName]: prevFilters[filterName]?.filter(
            (item: string) => item !== id,
          ),
        }
      : { ...prevFilters, [filterName]: [...prevFilters[filterName], id] };

    setFilters(newFiltersState);
    prepareTeamList(searchValue, newFiltersState);
  };

  return (
    <div className="w-full relative">
      <div className="absolute right-8 -top-20">
        <SearchBar
          additionalSorterOptions={[
            {
              name: SCORE,
              label: t(`projects.prospect.proposalFolder.${SCORE}`),
            },
          ]}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onClearFilters={handleOnClearFilters}
          onSorterClick={handleOnClickSorter}
          sorterParameters={
            storageSorter?.sessionSorters?.specialists?.name?.length
              ? storageSorter?.sessionSorters?.specialists
              : sorterParameters
          }
          clearFiltersDisabled={
            !Object.values(filters).filter((item: string[]) => item.length)
              .length && !searchValue
          }
          onCancel={onCancel}
          dataCy={SPECIALIST}
        />
      </div>
      {usersListDisplay && usersListDisplay.length ? (
        <>
          <SpecialistsSortBar
            users={users}
            filters={filters}
            updateFilters={handleOnFilterClick}
          />
          {usersListDisplay.map((user: UserProps) => (
            <SpecialistRow
              isAdded={addedUsers.includes(user.userId)}
              key={user.userId}
              user={user}
              addedUsers={addedUsers}
              onUserAdd={handleOnAddUser}
            />
          ))}
        </>
      ) : (
        <EmptySpecialists />
      )}
    </div>
  );
};

export default Specialists;
