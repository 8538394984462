import {
  ReactElement,
  useState,
  MouseEvent,
  KeyboardEvent,
  useRef,
  useLayoutEffect,
  useContext,
  useEffect,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import {
  PermissionContext,
  Tabs2,
  ToggleSelect,
  Tooltip,
} from "@ps/ui-components";
import {
  ACTIVE,
  ARCHIVED,
  CLIENTS,
  DETAILS,
  FOLDERS,
  PROJECTS,
  PROSPECTS,
} from "../../constants";
import { ClientsDetailsMapState, ClientsDetailsMapStateReturn } from "./types";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { Keys } from "../../../shared";
import ClientsFoldersList from "../lists/clientsFoldersList";
import ClientsProspectsList from "../lists/clientsProspectsList";
import Details from "./details";
import ClientsProjectsList from "../lists/clientsProjectsList";
import { ClientService } from "../../service";
import { fetchClientSummary } from "../../store";
import {
  CLIENTS_MANAGE,
  PROJECT_LIST_ALL,
  PROSPECT_FULL_ACCESS,
} from "../../../shared/permissions";
import { ClientSummaryAPIModel } from "../../models/types";

const BASE_PATH = "clients";

const ClientsDetails = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const history = useHistory();
  const { state: redirectState } = useLocation<{
    tab?: string;
  }>();

  const dispatch = useDispatch();
  const { hasPermission } = useContext(PermissionContext);

  const mapState = (
    state: ClientsDetailsMapState,
  ): ClientsDetailsMapStateReturn => ({
    editMode: state.editMode,
    clientSummary: state.clients.clientSummary,
    clientsSummary: state.clients.clientsSummary,
  });

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const {
    editMode,
    clientSummary,
    clientsSummary: clients,
  } = useMappedStateSelector(mapState);

  const tabs = [
    {
      label: t(`${BASE_PATH}.clientDetails`),
      value: DETAILS,
    },
    {
      label: t(`${BASE_PATH}.${PROSPECTS}`),
      value: PROSPECTS,
    },
    {
      label: t(`${BASE_PATH}.${FOLDERS}`),
      value: FOLDERS,
    },
    {
      label: t(`${BASE_PATH}.${PROJECTS}`),
      value: PROJECTS,
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  const handleTabClick = (e: MouseEvent<HTMLDivElement>): void => {
    setCurrentTab(e.currentTarget.id);
    history.replace({ state: { tab: e.currentTarget.id } });
  };

  const handleTabKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === Keys.ENTER) setCurrentTab(e.currentTarget.id);
  };

  const toggleArchiveStatus = async (): Promise<void> => {
    await ClientService.archiveClient(
      clientSummary.id,
      !clientSummary.archived,
    );
    await fetchClientSummary(clientSummary.id, dispatch);
  };

  const renderContent = () => {
    switch (currentTab) {
      case DETAILS:
        return <Details />;
      case FOLDERS:
        return <ClientsFoldersList />;
      case PROSPECTS:
        return <ClientsProspectsList />;
      case PROJECTS:
        return <ClientsProjectsList />;
      default:
        return <Details />;
    }
  };

  const isDetails = currentTab === DETAILS;

  // setting height of wrapper bc each list using ScrollableWrapper with hidden overflow
  useLayoutEffect(() => {
    if (!wrapperRef.current) return;
    const wrapper = wrapperRef.current;
    const wrapperTop = wrapper.offsetTop;
    if (!isDetails) {
      wrapper.style.height = `calc(100vh - ${wrapperTop + 50}px)`;
      wrapper.style.padding = "0 2.5rem 0 2.5rem";
    } else {
      wrapper.style.height = "100%";
      wrapper.style.padding = "0";
    }
  }, [isDetails]);

  useEffect(() => {
    if (!redirectState) return;
    if (redirectState?.tab) setCurrentTab(redirectState.tab);
    // eslint-disable-next-line consistent-return
  }, [redirectState]);

  const client: ClientSummaryAPIModel = clients?.find(
    (c: ClientSummaryAPIModel) => c?.id === clientSummary?.id,
  );

  const checkIfAnyActive = (clientData: ClientSummaryAPIModel): boolean => {
    return (
      clientData?.numberOfActiveProspects > 0 ||
      clientData?.numberOfActiveProjects > 0 ||
      clientData?.numberOfActiveFolders > 0
    );
  };

  const hasActive = checkIfAnyActive(client);

  return (
    <div>
      {!editMode.length && (
        <>
          <span
            data-cy={`${CLIENTS}_back-btn`}
            role="button"
            tabIndex={0}
            onClick={() => history.goBack()}
            onKeyDown={(event: KeyboardEvent) => {
              if (event.key === Keys.ENTER) {
                history.goBack();
              }
            }}
            className="text-neutralPrimary-30 cursor-pointer select-none hover:text-neutralPrimary-20"
          >
            {`<`} {t(`clients.back`)}
          </span>
          <h2 className="text-4xl text-neutralPrimary-20 mt-6 mb-8 font-bold">
            {clientSummary?.clientName}
          </h2>
        </>
      )}
      <div className="flex justify-between w-9/12 pr-12">
        <Tabs2
          currentValue={currentTab}
          tabs={tabs}
          onTabClick={handleTabClick}
          // eslint-disable-next-line
          // @ts-ignore
          onTabKeyDown={handleTabKeyDown}
        />
        {isDetails && (
          <Tooltip
            placement="top"
            content={<span>{t(`${BASE_PATH}.cantArchive`)}</span>}
            isDisabled={(!hasActive && !client?.archived) || client.archived}
          >
            <ToggleSelect
              dataCy={`${BASE_PATH}.toggle`}
              checked={!clientSummary?.archived}
              activeLabel={t(`clients.${ACTIVE}`)}
              negationLabel={t(`clients.${ARCHIVED}`)}
              onChange={toggleArchiveStatus}
              disabled={
                hasActive ||
                !hasPermission(
                  CLIENTS_MANAGE || PROSPECT_FULL_ACCESS || PROJECT_LIST_ALL,
                )
              }
            />
          </Tooltip>
        )}
      </div>
      <div ref={wrapperRef} className="bg-neutralPrimary-100 -mx-10 pt-8">
        {renderContent()}
      </div>
    </div>
  );
};

export default ClientsDetails;
