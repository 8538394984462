import { ReactElement } from "react";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import styles from "./styles.module.css";
import { LoaderProps } from "./types";

const Loader = ({
  dotWidth = "w-8",
  dotHeight = "h-8",
  additionalClassName,
}: LoaderProps): ReactElement => {
  const loadingDotStyles = "float-left rounded-full inline-block mr-4";

  return (
    <div className={classJoin(additionalClassName, "w-max")}>
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <div
            key={uid(index)}
            className={classJoin(
              styles.dot,
              loadingDotStyles,
              dotWidth,
              dotHeight,
            )}
          />
        ))}
    </div>
  );
};

export default Loader;
