import { UserDomainModel } from "../../user-domain";

export const findUserById = (
  id: string | undefined,
  usersList: UserDomainModel[],
): string | null => {
  const user = usersList.find(
    (item: UserDomainModel): boolean => item.id === id,
  );
  return user ? `${user?.firstName} ${user?.secondName}` : null;
};

export const prepareUserFullName = (
  user: unknown & {
    firstName: string;
    lastName: string;
  },
): string | null =>
  user?.firstName || user?.lastName
    ? `${user?.firstName} ${user?.lastName}`
    : null;
