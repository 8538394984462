import { Controller, useFormContext } from "react-hook-form";
import { ReactElement } from "react";
import { Label, SearchSelect } from "@ps/ui-components";
import {
  DESCRIPTION,
  NOTES,
  PRIORITY,
  PROSPECT,
  TITLE,
} from "../../../../constants";
import { useTranslationWithNamespace } from "../../../../../hooks";
import ControlledInput from "../../../common/controlledInput";
import ControlledTextarea from "../../../common/controlledTextarea";
import { NoteEditProps } from "../../types";
import { ReactComponent as LowIcon } from "../../../../../images/prospect/low.svg";
import { ReactComponent as MidIcon } from "../../../../../images/prospect/normal.svg";
import { ReactComponent as HighIcon } from "../../../../../images/prospect/high.svg";
import styles from "./styles.module.css";

const BASE_PATH = `${PROSPECT}_edit`;
const TRANSLATE_PATH = "projects.prospect.notes";

const priorityDict = [
  { id: "high", name: "high", deleted: false },
  { id: "medium", name: "medium", deleted: false },
  { id: "low", name: "low", deleted: false },
];

const Note = ({ index }: NoteEditProps): ReactElement => {
  const { control } = useFormContext();

  const noteIcon = {
    low: <LowIcon className={styles.low} />,
    medium: <MidIcon className={styles.mid} />,
    high: <HighIcon className={styles.high} />,
  };

  const { t } = useTranslationWithNamespace();

  const getName = (name: string) => `${NOTES}.${index}.${name}`;
  const translate = (field: string, isRequired = true) =>
    `${t(`${TRANSLATE_PATH}.${field}`)}${isRequired ? "*" : ""}`;

  const priorityDictForSearchSelect = priorityDict.map((priority) => ({
    value: priority.id,
    label: (
      <div className="flex items-center gap-2">
        <div className="h-5 w-5">{noteIcon[priority.name]}</div>
        <span className="text-neutralPrimary-20">{priority.name}</span>
      </div>
    ),
  }));

  return (
    <section className="w-full flex flex-col gap-8">
      <div className="flex gap-4">
        <ControlledInput
          basePath={`${BASE_PATH}_${TITLE}`}
          controllerPath={getName(TITLE)}
          name={TITLE}
          isRequired
          autoFocus
          placeholder="titlePlaceholder"
          translatePath={TRANSLATE_PATH}
          additionalValidation={(value: string) => {
            if (!value.trim().length)
              return `${t("projects.errors.cantBeBlank")}`;
            if (value.length > 300)
              return `${t("projects.errors.cantBeMoreThan", { number: 3000 })}`;
            return true;
          }}
        />
        <div>
          <Label
            dataCy={`${NOTES}_${PRIORITY}`}
            text={translate(PRIORITY, false)}
            labelFontWeight="font-bold"
            labelFontSize="text-lg"
            labelColor="text-neutralPrimary-20"
          >
            <Controller
              render={({ field: { onChange, value } }) => (
                <SearchSelect
                  dataCy={`${NOTES}_${PRIORITY}`}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  options={priorityDictForSearchSelect}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  defaultValue={
                    priorityDictForSearchSelect.find(
                      (priority) => priority.value === value,
                    ) ||
                    priorityDictForSearchSelect[
                      priorityDictForSearchSelect.length - 1
                    ]
                  }
                  placeholder={translate(PRIORITY, false)}
                  searchable={false}
                  onChange={(newValue) => onChange(newValue.value)}
                />
              )}
              name={getName(PRIORITY)}
              control={control}
            />
          </Label>
        </div>
      </div>
      <ControlledTextarea
        height="h-96"
        basePath={`${BASE_PATH}_${DESCRIPTION}`}
        controllerPath={getName(DESCRIPTION)}
        placeholder="descriptionPlaceholder"
        isRequired
        name={DESCRIPTION}
        translatePath={TRANSLATE_PATH}
        maxLength={30000}
        resize
        additionalValidationRules={(value: string) =>
          !value.trim().length ? `${t("projects.errors.cantBeBlank")}` : true
        }
      />
    </section>
  );
};

export default Note;
