import { ReactElement } from "react";
import { SearchFilter, SearchFilterCheckboxOption } from "@ps/ui-components";
import { Controller, useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import { UsersFilterProps } from "./types";
import { IUserOption } from "../types";
import { useTranslationWithNamespace } from "../../../hooks";
import { USER, USERS } from "../../../shared/constants";
import { REPORTS } from "../../../shared/data-cy";
import { updatePillsList, updateSelectedAllFiltersList } from "./utils";
import { ALL_USER_SELECTED } from "../constants";

const UsersFilter = ({
  searchValue,
  setSearchValue,
  filtersSelectedList,
  selectedLength,
  usersList,
}: UsersFilterProps): ReactElement => {
  const { getValues, control, reset, setValue, watch } = useFormContext();
  const { t } = useTranslationWithNamespace();

  const { fields, append } = filtersSelectedList;

  const selectUnselectAll = (isSelectAll: boolean): void => {
    reset(
      {
        ...getValues(),
        [USERS]: getValues(USERS).map((item) => ({
          ...item,
          checked: !isSelectAll,
        })),
      },
      { keepDirty: true },
    );
    updateSelectedAllFiltersList(
      isSelectAll,
      USER,
      fields || [],
      reset,
      getValues(),
      usersList,
    );
    setValue(ALL_USER_SELECTED, !isSelectAll, { shouldDirty: true });
  };

  return (
    <div className="max-w-72">
      <SearchFilter
        clearAllDisabled={!selectedLength}
        dataCy={`${REPORTS}_users`}
        isAllSelected={
          watch(USERS)?.filter((item) => item.checked)?.length ===
          usersList.length
        }
        isPartialSelected={
          !!(selectedLength && selectedLength < usersList.length)
        }
        onClearAllClick={() => selectUnselectAll(true)}
        onSearch={setSearchValue}
        onSelectAllClick={() => {
          selectUnselectAll(getValues(ALL_USER_SELECTED));
        }}
        placeholder={t("reports.users")}
        searchValue={searchValue}
        selectedItemsAmount={selectedLength}
      >
        {usersList
          // eslint-disable-next-line
          // @ts-ignore
          ?.filter(
            (item: IUserOption) =>
              !searchValue ||
              item.name?.toLowerCase().includes(searchValue.toLowerCase()),
          )
          .map((item: IUserOption) => (
            <Controller
              key={uid(item)}
              render={({ field: { value, onChange } }) => (
                <SearchFilterCheckboxOption
                  {...item}
                  checked={value}
                  dataCy={item.itemId}
                  onClick={(newValue) => {
                    onChange(!newValue);
                    updatePillsList(
                      newValue,
                      item,
                      reset,
                      getValues(),
                      fields,
                      append,
                    );
                  }}
                />
              )}
              name={`${USERS}.[${item.idInArray}].checked`}
              control={control}
            />
          ))}
      </SearchFilter>
    </div>
  );
};
export default UsersFilter;
