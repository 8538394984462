import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { useParams } from "react-router-dom";
import {
  Label,
  SearchSelect,
  Modal2,
  Button2,
  ModalFooter2,
  ModalMainText2,
} from "@ps/ui-components";
import {
  fetchHolidaysTemplates,
  HolidaysTemplateModel,
} from "../../../../holidays-templates-domain";
import { ReactComponent as NoTemplateIcon } from "../../../../images/finance/holidays/noTemplate.svg";
import { ReactComponent as WarningGroupIcon } from "../../../../images/warning-info-group.svg";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { fetchHolidaysUserDetails } from "../../../store";
import FinanceService from "../../../services/finance";
import {
  BASE_TRANSLATION_PATH,
  HOLIDAYS_USER,
  HOLIDAYS_TEMPLATE,
} from "../constants";
import {
  NoTemplateMapState,
  NoTemplateMapStateReturn,
  IValueLabel,
} from "./types";
import styles from "./styles.module.css";

const NoTemplate = (): ReactElement => {
  const dispatch = useDispatch();
  const { userId } = useParams<{
    userId: string;
  }>();
  const { t } = useTranslationWithNamespace();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chosenTemplate, setChosenTemplate] = useState<null | IValueLabel>(
    null,
  );

  useEffect(() => {
    fetchHolidaysTemplates(dispatch);
  }, []);

  const mapState = (state: NoTemplateMapState): NoTemplateMapStateReturn => ({
    holidaysTemplates: state.holidaysTemplates.holidaysTemplates,
  });

  const { holidaysTemplates } = useMappedStateSelector(mapState);

  const dataCy = `${HOLIDAYS_USER}_${HOLIDAYS_TEMPLATE}`;

  const handleChooseUserTemplate = async (
    newTemplate: IValueLabel | null,
  ): Promise<void> => {
    if (userId && newTemplate) {
      await FinanceService.changeUserTemplate(userId, newTemplate.value);
      fetchHolidaysUserDetails(dispatch, userId);
    }
  };

  const templatesOptions: IValueLabel[] = holidaysTemplates?.map(
    (template: HolidaysTemplateModel): IValueLabel => ({
      value: template.id,
      label: template.name,
    }),
  );

  const handleOnModalClose = (): void => {
    setIsModalVisible(false);
    setChosenTemplate(null);
  };

  return (
    <section className="flex flex-col gap-10">
      <div className="flex items-center gap-16">
        <Label dataCy={dataCy} additionalClassName="w-max">
          {t(`${BASE_TRANSLATION_PATH}.${HOLIDAYS_TEMPLATE}`)}
        </Label>
        <SearchSelect
          dataCy={dataCy}
          options={templatesOptions}
          width="w-80"
          placeholder={t(`${BASE_TRANSLATION_PATH}.chooseTemplate`)}
          onChange={(template) => {
            setIsModalVisible(true);
            setChosenTemplate(template);
          }}
        />
      </div>
      <div className="flex items-center gap-10 self-center m-10">
        <NoTemplateIcon />
        <span className="flex flex-col gap-3">
          <p className="font-semibold text-xl">
            {t(`${BASE_TRANSLATION_PATH}.noTemplate`)}
          </p>
          {t(`${BASE_TRANSLATION_PATH}.addNewTemplate`)}
        </span>
      </div>
      {isModalVisible ? (
        <Modal2
          dataCy={dataCy}
          isOpen
          onClose={handleOnModalClose}
          withCloseIcon
          width={styles.chooseTemplateModal}
          padding="py-10 px-8"
        >
          <div className="flex gap-x-6">
            <div className="flex items-center justify-center">
              <WarningGroupIcon />
            </div>
            <div className="flex flex-col gap-y-4">
              <ModalMainText2 dataCy={dataCy}>
                {t(`${BASE_TRANSLATION_PATH}.chooseTemplateModal.areYouSure`)}
              </ModalMainText2>
              <p className="text-xl">
                <span className="font-normal">
                  {t(`${BASE_TRANSLATION_PATH}.chooseTemplateModal.yourChoice`)}
                </span>{" "}
                <span className="text-primary-50 font-extrabold">
                  {chosenTemplate?.label}
                </span>
              </p>
              <span className="text-error-50 font-normal text-lg">
                {t(
                  `${BASE_TRANSLATION_PATH}.chooseTemplateModal.changingWarning`,
                )}
              </span>
              <ModalFooter2 dataCy={dataCy} additionalClassName="mt-auto">
                <Button2
                  dataCy={`${dataCy}_cancel`}
                  onClick={handleOnModalClose}
                  variant="secondary"
                >
                  {t("actions.close")}
                </Button2>
                <Button2
                  dataCy={`${dataCy}_save`}
                  onClick={() => handleChooseUserTemplate(chosenTemplate)}
                >
                  {t("actions.confirm")}
                </Button2>
              </ModalFooter2>
            </div>
          </div>
        </Modal2>
      ) : (
        <></>
      )}
    </section>
  );
};

export default NoTemplate;
