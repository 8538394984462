import { uid } from "react-uid";
import { ReactElement, Fragment, KeyboardEvent } from "react";
import { Checkbox } from "@ps/ui-components";
import { SKILLS_SEARCH } from "../../../../../shared/data-cy";
import SingleCell from "./singleCell";
import EmptySingleCell from "./emptySingleCell";
import { Keys } from "../../../../../shared";
import { ITemplateSkill, SingleUserRowProps } from "../types";
import {
  IExpandedSkillSkillsSearch,
  ISkillSkillsSearch,
} from "../../../../models/types";

const SingleUserRow = ({
  userName,
  userSkills,
  templateSkills,
  expandedTechs,
  onClick,
  isSelected,
  userId,
}: SingleUserRowProps): ReactElement => (
  <tr>
    <th className="sticky left-0 bg-neutralPrimary-100 z-10">
      <div
        className="h-24 flex items-center justify-start gap-2 border-neutralSecondary-60 border-t border-b ml-6 pr-20 w-full"
        onClick={() => onClick(userId)}
        role="button"
        tabIndex={0}
        onKeyDown={(e: KeyboardEvent<HTMLDivElement>) =>
          e.code === Keys.SPACE && onClick(userId)
        }
      >
        <Checkbox dataCy={SKILLS_SEARCH} tabIndex={-1} checked={isSelected} />
        <span className="text-neutralPrimary-20 truncate">{userName}</span>
      </div>
    </th>
    {templateSkills.map((singleTemplateSkill: ITemplateSkill) => {
      const resultItem = userSkills.find(
        (item: ISkillSkillsSearch): boolean =>
          item.skillId === singleTemplateSkill.skillId,
      );
      if (resultItem)
        return (
          <Fragment key={uid(resultItem)}>
            <SingleCell
              enjoymentScale={resultItem.enjoymentScale}
              knowledgeScale={resultItem.knowledgeScale}
            />
            {expandedTechs?.includes(resultItem.skillId) ? (
              singleTemplateSkill?.expandableSkills?.map(
                (singleTemplateProfession: string): ReactElement => {
                  const resultExpandableSkillItem =
                    resultItem?.expandableSkills?.find(
                      (el: IExpandedSkillSkillsSearch): boolean =>
                        el.professionId === singleTemplateProfession,
                    );
                  if (resultExpandableSkillItem) {
                    return (
                      <SingleCell
                        key={uid(resultExpandableSkillItem)}
                        enjoymentScale={
                          resultExpandableSkillItem.enjoymentScale
                        }
                        knowledgeScale={
                          resultExpandableSkillItem.knowledgeScale
                        }
                        additionalClassName="bg-neutralSecondary-90"
                      />
                    );
                  }
                  return (
                    <EmptySingleCell
                      key={uid(singleTemplateProfession)}
                      additionalClassName="bg-neutralSecondary-90"
                    />
                  );
                },
              )
            ) : (
              <></>
            )}
          </Fragment>
        );
      if (expandedTechs.includes(singleTemplateSkill?.skillId))
        return (
          <Fragment key={uid(`empty-detailed-${singleTemplateSkill?.skillId}`)}>
            <EmptySingleCell />
            {singleTemplateSkill?.expandableSkills?.map(
              (item: string): ReactElement => (
                <EmptySingleCell
                  key={uid(`empty-general-${item}`)}
                  additionalClassName="bg-neutralSecondary-90"
                />
              ),
            )}
          </Fragment>
        );
      return <EmptySingleCell />;
    })}
  </tr>
);

export default SingleUserRow;
