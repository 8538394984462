import { ReactElement } from "react";
import { CardHeader, Label } from "@ps/ui-components";
import {
  PROFILE_SUMMARY_BASE_INFO,
  PROFILE_SUMMARY_EXPERIENCE,
} from "../../../../shared/data-cy";
import { ReactComponent as ExperienceIcon } from "../../../../images/profile-summary/experience.svg";
import { useTranslationWithNamespace } from "../../../../hooks";
import { ExperienceProps } from "./types";

const BASE_PATH = "profile.summary";

const Experience = ({ overallExperience }: ExperienceProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <CardHeader
      dataCy={`${PROFILE_SUMMARY_BASE_INFO}_experience`}
      Icon={ExperienceIcon}
      iconColor="text-primary-50"
    >
      <Label
        dataCy={`${PROFILE_SUMMARY_EXPERIENCE}_experience`}
        text={t(`${BASE_PATH}.experience`)}
      >
        <span className="flex flex-col font-semibold">
          {`${
            overallExperience?.years
              ? t(`${BASE_PATH}.totalExperience.years`, {
                  count: overallExperience.years,
                })
              : ""
          } ${
            overallExperience?.months
              ? t(`${BASE_PATH}.totalExperience.months`, {
                  count: overallExperience?.months,
                })
              : ""
          } `}
        </span>
      </Label>
    </CardHeader>
  );
};

export default Experience;
