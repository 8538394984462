import React, {
  Children,
  cloneElement,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import { ShowMoreProps } from "./types";
import { updateRowPillsAmount, prepareChildrenToRender } from "./utils";
import styles from "./styles.module.css";

const ShowMore = <T,>({
  abbrViewRowAmount = 2,
  children,
  isFullViewOpen,
  MoreComponent,
  setIsFullViewOpen,
}: ShowMoreProps): ReactElement => {
  const [isShowMoreVisible, setIsShowMoreVisible] = useState(false);
  const [baseChildren, setBaseChildren] = useState<ReactElement[]>([]);
  const [baseChildrenLength, setBaseChildrenLength] = useState(0);
  const [childrenRowsItems, setChildrenRowItems] = useState<T[][]>();

  const node = useRef<HTMLDivElement>(null);
  const nodeSize = node?.current?.getBoundingClientRect();

  useEffect(() => {
    const clonedElements = Children.map(children, (child: ReactElement) =>
      cloneElement(child, child),
    );
    setBaseChildren(clonedElements);

    const zeroElementKey = clonedElements[0];
    setBaseChildrenLength(
      zeroElementKey?.props.children ? clonedElements?.length : 0,
    );
  }, [children]);

  useEffect(() => {
    if (typeof nodeSize?.width === "number") {
      setTimeout(() => {
        if (baseChildrenLength === 0) {
          setIsShowMoreVisible(false);
          setChildrenRowItems([]);
          return;
        }

        const showMoreNode = node?.current?.children;
        const showMoreNodeLength: number =
          (showMoreNode?.length &&
            showMoreNode[showMoreNode.length - 1].getBoundingClientRect()
              ?.width) ||
          0;

        const nodeTotalWidth = nodeSize?.width;
        const pillsNodesWidthArr =
          (node?.current?.children?.length &&
            Array.from(node.current.children).map(
              (item: Element) => item.getBoundingClientRect()?.width,
            )) ||
          [];

        let baseRowsAmount = new Array(abbrViewRowAmount).fill(0).map(() => 0);
        let childrenToRenderArrays = new Array(abbrViewRowAmount)
          .fill(0)
          .map(() => []);
        let totalPillsWidth = 0;

        pillsNodesWidthArr.forEach((item, index) => {
          if (
            totalPillsWidth + item < nodeTotalWidth &&
            (totalPillsWidth + item) / nodeTotalWidth < abbrViewRowAmount - 1
          ) {
            baseRowsAmount = updateRowPillsAmount([...baseRowsAmount], 0);
            totalPillsWidth += item;

            childrenToRenderArrays = prepareChildrenToRender(
              [...childrenToRenderArrays],
              0,
              children[index],
            );
          } else if (
            totalPillsWidth + item <
            nodeTotalWidth * abbrViewRowAmount - showMoreNodeLength - 120
          ) {
            baseRowsAmount = updateRowPillsAmount(
              [...baseRowsAmount],
              abbrViewRowAmount - 1,
            );

            totalPillsWidth += item;
            childrenToRenderArrays = prepareChildrenToRender(
              [...childrenToRenderArrays],
              abbrViewRowAmount - 1,
              children[index],
            );
            setIsShowMoreVisible(false);
          } else {
            setIsShowMoreVisible(true);
          }
        });

        setChildrenRowItems(childrenToRenderArrays);

        if (childrenToRenderArrays.flat()?.length - 1 === baseChildrenLength) {
          setIsFullViewOpen(false);
        }

        if (!baseChildrenLength) setIsFullViewOpen(false);
      }, 0);
    }
  }, [nodeSize?.width, baseChildrenLength, isFullViewOpen]);

  return (
    <div className="relative flex flex-col w-full">
      <div
        className={classJoin(
          "w-full flex items-center flex-wrap gap-2.5",
          isFullViewOpen
            ? ""
            : `${styles.allPillsRowHidden} absolute opacity-0 pointer-events-none`,
        )}
        ref={node}
      >
        {isFullViewOpen
          ? baseChildren
          : Children.map(baseChildren, (child: ReactElement) =>
              cloneElement(child, { isOutOfTabKeys: true }),
            )}
        {isFullViewOpen && Children.count(children) && MoreComponent}
      </div>
      <div
        className={classJoin(
          styles.singlePillsRows,
          "w-full flex items-center flex-wrap gap-2.5",
        )}
      >
        {!isFullViewOpen &&
          new Array(abbrViewRowAmount).fill(0).map((_, index: number) => (
            <React.Fragment key={uid(index)}>
              {childrenRowsItems?.[index]}
              {index === abbrViewRowAmount - 1 &&
              isShowMoreVisible &&
              Children.count(children) ? (
                MoreComponent
              ) : (
                <></>
              )}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default ShowMore;
