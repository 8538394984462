import { SearchFilter, SearchFilterCheckboxOption } from "@ps/ui-components";
import { Controller, useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import { ReactElement } from "react";
import { ClientsFilterProps } from "./types";
import { IClientOption } from "../types";
import { useTranslationWithNamespace } from "../../../hooks";
import { CLIENT, CLIENTS } from "../../../shared/constants";
import { REPORTS } from "../../../shared/data-cy";
import { updatePillsList, updateSelectedAllFiltersList } from "./utils";
import { ALL_CLIENT_SELECTED } from "../constants";

const ClientsFilter = ({
  clientsList,
  filtersSelectedList,
  searchValue,
  selectedLength,
  setSearchValue,
}: ClientsFilterProps): ReactElement => {
  const { reset, getValues, control, setValue, watch } = useFormContext();
  const { t } = useTranslationWithNamespace();

  const { fields, append } = filtersSelectedList;

  const selectUnselectAll = (isSelectAll: boolean): void => {
    reset(
      {
        ...getValues(),
        [CLIENTS]: getValues(CLIENTS).map((item) => ({
          ...item,
          checked: !isSelectAll,
        })),
      },
      { keepDirty: true },
    );
    updateSelectedAllFiltersList(
      isSelectAll,
      CLIENT,
      fields || [],
      reset,
      getValues(),
      clientsList,
    );
    setValue(ALL_CLIENT_SELECTED, !isSelectAll, { shouldDirty: true });
  };

  return (
    <div className="max-w-72">
      <SearchFilter
        clearAllDisabled={!selectedLength}
        dataCy={`${REPORTS}_clients`}
        isAllSelected={
          watch(CLIENTS)?.filter((item) => item.checked)?.length ===
          clientsList.length
        }
        isPartialSelected={
          !!(selectedLength && selectedLength < clientsList.length)
        }
        onClearAllClick={() => selectUnselectAll(true)}
        onSearch={setSearchValue}
        onSelectAllClick={() => {
          selectUnselectAll(getValues(ALL_CLIENT_SELECTED));
        }}
        placeholder={t("reports.clients")}
        searchValue={searchValue}
        selectedItemsAmount={selectedLength}
      >
        {clientsList
          ?.filter(
            (item: IClientOption) =>
              !searchValue ||
              item.name?.toLowerCase().includes(searchValue.toLowerCase()),
          )
          ?.map((item: IClientOption) => (
            <Controller
              key={uid(item)}
              render={({ field: { value, onChange } }) => (
                <SearchFilterCheckboxOption
                  {...item}
                  checked={value}
                  dataCy={item.itemId}
                  onClick={(newValue) => {
                    onChange(!newValue);
                    updatePillsList(
                      newValue,
                      item,
                      reset,
                      getValues(),
                      fields,
                      append,
                    );
                  }}
                />
              )}
              name={`${CLIENTS}.[${item.idInArray}].checked`}
              control={control}
            />
          ))}
      </SearchFilter>
    </div>
  );
};
export default ClientsFilter;
