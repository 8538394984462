import { APPROVED, REJECTED } from "../constants";

const setColorToStatus = (status: string): string => {
  switch (status) {
    case APPROVED:
      return "text-success-50";
    case REJECTED:
      return "text-error-50";
    default:
      return "text-primary-70";
  }
};

export default setColorToStatus;
