import { ReactElement, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { clearRedirection, setRedirection } from "@ps/alert-modal";
import { classJoin } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import styles from "./styles.module.css";
import DetailsSection from "./detailsSection";
import Others from "./others";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { MyProfileService } from "../../services";
import {
  OperativeDomainModel,
  ProfileDomainModel,
  SpecialistDomainModel,
} from "../../models";
import { toggleEditMode } from "../../../store";
import { fetchMyProfile } from "../../store";
import {
  DICTIONARIES,
  DictionariesService,
  DictionaryEntryModel,
  DictionaryModel,
  fetchDictionaryError,
  fetchDictionaryStart,
  fetchDictionarySuccess,
} from "../../../dictionaries";
import {
  OverviewMapState,
  OverviewMapStateReturn,
  OverviewProps,
} from "./types";
import { CURRENT_YEAR } from "./constants";
import OcrUploader from "../OCRWizard/uploader";
import { GoalsDataModel } from "../../models/types";
import { SPECIALIST } from "../../../shared/constants";

const Overview = ({
  defaultProfile,
  defaultOnSubmit,
}: OverviewProps): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslationWithNamespace();

  const mapState = (state: OverviewMapState): OverviewMapStateReturn => ({
    profileDetails: state?.profiles?.myProfile,
    editMode: state.editMode,
    isFormModified: state.isFormModified,
  });

  const { profileDetails, editMode } = useMappedStateSelector(mapState);
  const { isFormModified } = useMappedStateSelector(mapState, "");

  const methods = useForm<SpecialistDomainModel | OperativeDomainModel>({
    defaultValues: profileDetails,
  });

  const { isDirty } = methods.formState;

  const handleOnEdit = (section: string) => dispatch(toggleEditMode(section));

  const redirect = async (): Promise<
    Record<string, unknown> | undefined | void
  > => {
    if (isDirty || editMode.length) return dispatch(setRedirection());
    if (isFormModified && !editMode) {
      methods.reset(profileDetails);
      return dispatch(clearRedirection());
    }
    return methods.reset(profileDetails);
  };

  useEffect(() => {
    redirect();
  }, [isDirty, editMode]);

  useEffect(() => {
    methods.reset(profileDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails]);

  const mapTechnologies = async (
    goals: GoalsDataModel,
    year: string,
  ): Promise<DictionaryModel> => {
    if (typeof goals?.[year].technologies !== "undefined") {
      const results = await Promise.allSettled(
        goals[year].technologies.map((technologies: DictionaryEntryModel) =>
          DictionariesService.addDictionaryEntry(
            technologies,
            DICTIONARIES.TECHNOLOGY,
          ),
        ),
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return results.map((result) => result.value);
    }
    return [];
  };

  const onSubmit: SubmitHandler<ProfileDomainModel> = async (profile) => {
    if (!profile) return;
    const technologies = profile.goals?.[CURRENT_YEAR].technologies;
    if (technologies) {
      profile.goals[CURRENT_YEAR].technologies = await mapTechnologies(
        profile.goals,
        CURRENT_YEAR,
      );
    }

    const updatedProfile = {
      ...(defaultProfile || profileDetails),
      ...profile,
      goals: profile.goals,
    };

    if (defaultOnSubmit) return defaultOnSubmit(updatedProfile);
    await MyProfileService.updateProfile(updatedProfile);
    // Redux do not update immediately state, so there was a need to pass response after dictionary fetch
    dispatch(fetchDictionaryStart());
    const response = await DictionariesService.fetchDictionary(
      DICTIONARIES.TECHNOLOGY,
    );
    if (isAPIError(response)) {
      dispatch(fetchDictionaryError(response));
    } else {
      dispatch(fetchDictionarySuccess(response, DICTIONARIES.TECHNOLOGY));
      await fetchMyProfile(dispatch, response);
      dispatch(clearRedirection());
      handleOnEdit("");
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <section
          className={classJoin(
            "grid gap-6 grid-flow-col mb-5",
            styles.overviewContainer,
          )}
        >
          <div className="flex flex-col gap-6">
            {!profileDetails?.experience?.projects?.length &&
              profileDetails?.profile === SPECIALIST && (
                <OcrUploader disabled={editMode} />
              )}
            <DetailsSection
              canEdit
              toggleEditMode={handleOnEdit}
              profile={defaultProfile}
            />
          </div>
          <Others canEdit />
        </section>
      </form>
    </FormProvider>
  );
};

export default Overview;
