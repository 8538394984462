import { ITab } from "./types";

const filterTabsWithPermissions = (
  tabs: (ITab | null)[],
  hasPermissions: (
    item: string[] | [],
    allPermissions: string[] | [],
  ) => boolean,
  allPermissions: string[] | [],
) =>
  tabs.reduce((acc, item, index) => {
    if (
      ((item?.permissions &&
        hasPermissions(item.permissions, allPermissions)) ||
        !item?.permissions) &&
      item?.item
    ) {
      return {
        ...acc,
        [index]: item?.icon,
        [item.item]: index,
      };
    }
    return acc;
  }, {});

export default filterTabsWithPermissions;
