import { ReactElement, useState, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import Scrollbars from "react-custom-scrollbars-2";
import {
  EmptyList,
  LoadingAnyPage,
  ScrollableWrapper,
} from "@ps/ui-components";
import { sortArray } from "@ps/utils";
import {
  useMappedStateSelector,
  useSessionStorage,
  useTranslationWithNamespace,
} from "../../../hooks";
import SearchBar from "./searchBar";
import SortBar from "./sortBar";
import { fetchHolidaysUsers } from "../../store";
import {
  NAME,
  ASC,
  initialCheckersState,
  initialFiltersState,
  FINANCES,
} from "./constants";
import { Filters, SorterParametersProps, UsersCheckers } from "../types";
import { HolidaysMapState, HolidaysMapStateReturn } from "../holidays/types";
import { HolidaysUserModel } from "../../models";
import {
  SESSION_SORTERS,
  initialSessionSortersState,
} from "../../../shared/constants";
import { SessionStorage } from "../../../shared";
import { filterTeamByStatus, filterUsers, filterUsersBySearch } from "./utils";
import UserRow from "./userRow";
import { ReactComponent as EmptyGuy } from "../../images/guy.svg";
import { ENABLED_FEATURES } from "@ps/app";

const Finances = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    fetchHolidaysUsers(dispatch);
  }, []);

  const [usersListToDisplay, setUsersListToDisplay] = useState<
    HolidaysUserModel[]
  >([]);

  const [storageSorter, setStorageSorter] = useSessionStorage<SessionStorage>(
    SESSION_SORTERS,
    initialSessionSortersState,
  );

  const [sorterParameters, setSorterParameters] =
    useState<SorterParametersProps>({
      order: ASC,
      name: NAME,
    });

  const [filters, setFilters] = useState<Filters>(initialFiltersState);

  const [usersCheckers, setUsersCheckers] =
    useState<UsersCheckers>(initialCheckersState);

  const mapState = (state: HolidaysMapState): HolidaysMapStateReturn => ({
    professionDict: state?.dictionaries?.profession || [],
    seniorityDict: state?.dictionaries?.seniority || [],
    activeSorter: state?.holidays?.sorter,
    filters: state?.holidays?.filters,
    users: state.holidays?.users,
    isLoading: state.requestStatus?.isFetchingHolidaysUsers,
    isUserEditEnable: state.tenantMetadata?.enabledFeatures?.includes(
      ENABLED_FEATURES.UNSAFE_USER_SALARY,
    ),
  });

  const { professionDict, seniorityDict, users, isLoading } =
    useMappedStateSelector(mapState);

  const { isUserEditEnable } = useMappedStateSelector(mapState, "");

  const activeUsers: HolidaysUserModel[] =
    users?.filter((user: HolidaysUserModel) => user.enabled) || [];

  const inactiveUsers: HolidaysUserModel[] =
    users?.filter((user: HolidaysUserModel) => !user.enabled) || [];

  const prepareUsersList = (search: string, usersFilters: Filters): void => {
    const { finances: financesSorter } = storageSorter.sessionSorters;
    const properSorter: {
      name: string;
      order: string;
      nestedFieldName?: string;
    } = financesSorter?.name?.length ? financesSorter : sorterParameters;

    const resultByStatus = filterTeamByStatus(users, usersCheckers);
    const resultByFilter = filterUsers(resultByStatus, usersFilters);
    const resultBySearch = filterUsersBySearch(resultByFilter, search);
    const resultBySorter = sortArray(
      resultBySearch,
      properSorter.name as keyof HolidaysUserModel,
      properSorter.order,
      properSorter.nestedFieldName as keyof HolidaysUserModel,
    );

    setUsersListToDisplay(resultBySorter);
  };

  useEffect(() => {
    prepareUsersList(searchValue, filters);
  }, [
    users,
    searchValue,
    usersCheckers,
    activeUsers.length,
    inactiveUsers.length,
    sorterParameters.name,
    sorterParameters.order,
    filters,
  ]);

  const handleSorterClick = (
    fieldName: string,
    order: string,
    isNestedField?: boolean,
  ): void => {
    const nestedFieldName = isNestedField ? "salary" : undefined;
    setStorageSorter((prevData) => ({
      ...prevData,
      sessionSorters: {
        ...prevData.sessionSorters,
        [FINANCES]: {
          name: fieldName,
          order,
          nestedFieldName,
        },
      },
    }));

    setSorterParameters({ name: fieldName, order });
  };

  const handleOnFilterClick = (
    isChecked: boolean,
    id: string,
    filterName: string,
  ): void => {
    const prevFilters = JSON.parse(JSON.stringify(filters));

    const newFiltersState: Filters = isChecked
      ? {
          ...prevFilters,
          [filterName]: prevFilters[filterName]?.filter(
            (item: string) => item !== id,
          ),
        }
      : { ...prevFilters, [filterName]: [...prevFilters[filterName], id] };

    setFilters(newFiltersState);
    prepareUsersList(searchValue, newFiltersState);
  };

  const handleOnClearFilters = () => {
    setSearchValue("");
    setFilters(initialFiltersState);
    prepareUsersList("", initialFiltersState);
  };

  if (isLoading) return <LoadingAnyPage />;

  return (
    <div className="flex flex-col gap-y-4 w-full h-full">
      <SearchBar
        onSorterClick={handleSorterClick}
        sorterParameters={
          storageSorter?.sessionSorters?.finances?.name?.length
            ? storageSorter.sessionSorters.finances
            : sorterParameters
        }
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        activeAmount={activeUsers.length}
        inactiveAmount={inactiveUsers.length}
        usersCheckers={usersCheckers}
        setUsersCheckers={setUsersCheckers}
        clearFiltersDisabled={
          !Object.values(filters).filter((item: string[]) => item.length)
            .length && !searchValue
        }
        handleOnCleanFilters={handleOnClearFilters}
      />
      {usersListToDisplay.length ? (
        <ScrollableWrapper>
          <SortBar
            users={filterTeamByStatus(users, usersCheckers)}
            filters={filters}
            updateFilters={handleOnFilterClick}
            professionDict={professionDict}
            seniorityDict={seniorityDict}
          />
          <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
            {usersListToDisplay.map((user: HolidaysUserModel) => (
              <UserRow
                isEditEnable={isUserEditEnable}
                key={user.id}
                professionDict={professionDict}
                seniorityDict={seniorityDict}
                user={user}
              />
            ))}
          </Scrollbars>
        </ScrollableWrapper>
      ) : (
        <div className="w-full flex justify-center">
          <EmptyList
            searchValue={searchValue}
            text={t(`finance.${FINANCES}.noUsers`)}
            Image={<EmptyGuy />}
          />
        </div>
      )}
    </div>
  );
};

export default Finances;
