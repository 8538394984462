import { ChangeEvent, ReactElement, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { motion } from "framer-motion";
import { Label, SearchSelectPicker, Textarea } from "@ps/ui-components";
import {
  mapTechnologyWithOnlyAlphanumeric,
  prepareTechStackDictionaryValues,
  useFullTextSearch,
} from "@ps/utils";
import LabelWrapper from "./labelWrapper";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { MAIN_PROFILE_EDIT_MODE } from "../../../../shared/data-cy";
import {
  GOALS,
  GOALS_CURRENT_YEAR,
  NOTE,
  TECHNOLOGIES,
  CURRENT_YEAR,
  SKILLS,
  TOOLTIP_CONTENT,
} from "../constants";
import { ITechStackProposal } from "../../experience/types";
import {
  DevelopmentGoalsMapState,
  DevelopmentGoalsMapStateReturn,
} from "./types";
import styles from "../../experience/sections/styles.module.css";

const MAX_LENGTH = 3000;
const TRANSLATION_PATH = `profile.overview.${GOALS_CURRENT_YEAR}`;

const DevelopmentGoals = (): ReactElement => {
  const { control } = useFormContext();

  const { t } = useTranslationWithNamespace();
  const mapState = (
    state: DevelopmentGoalsMapState,
  ): DevelopmentGoalsMapStateReturn => ({
    technologyDict: state.dictionaries?.technology || [],
  });

  const { technologyDict } = useMappedStateSelector(mapState);

  const [searchedProposals, setSearchedProposals] = useState<
    ITechStackProposal[] | []
  >([]);

  const { search } = useFullTextSearch(
    technologyDict,
    mapTechnologyWithOnlyAlphanumeric,
  );

  return (
    <div className="flex flex-col gap-y-4">
      <Label
        text={t(`${TRANSLATION_PATH}.${NOTE}`)}
        dataCy={`${MAIN_PROFILE_EDIT_MODE}_${GOALS}_${NOTE}`}
        key={`${MAIN_PROFILE_EDIT_MODE}_${GOALS}_${NOTE}`}
      >
        <Controller
          name={`${GOALS}.${CURRENT_YEAR}.${NOTE}`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Textarea
              dataCy={`${GOALS}-${NOTE}`}
              maxLength={MAX_LENGTH}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                onChange(event.target.value)
              }
              autoFocus
              defaultValue={value}
              error={!!error}
              message={error?.message}
              resize
              placeholder={t(`${TRANSLATION_PATH}.goalsPlaceholder`)}
            />
          )}
          rules={{
            validate: (value) => {
              if (value && value.length > MAX_LENGTH)
                return `${t("profile.errors.cantBeMoreChars", {
                  number: MAX_LENGTH,
                })}`;
              if (!value.length) return `${t("profile.errors.cantBeEmpty")}`;
              return true;
            },
          }}
        />
      </Label>
      <motion.div>
        <LabelWrapper
          overviewElementPath={GOALS_CURRENT_YEAR}
          translationPath={TRANSLATION_PATH}
          field={SKILLS}
          tooltipContent={TOOLTIP_CONTENT}
          className="w-1/2"
        >
          <Controller
            name={`${GOALS}.${CURRENT_YEAR}.${TECHNOLOGIES}`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SearchSelectPicker
                dataCy={`${GOALS}-${TECHNOLOGIES}`}
                additionalTagsWrapClassName={styles.techWrapper}
                defaultSelected={prepareTechStackDictionaryValues(
                  value,
                  technologyDict,
                )}
                onTagSelected={onChange}
                proposalTags={searchedProposals}
                onInputChange={(searchValue) => {
                  const result = search(searchValue);
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setSearchedProposals(result);
                }}
                error={!!error}
                message={error?.message}
              />
            )}
          />
        </LabelWrapper>
      </motion.div>
    </div>
  );
};

export default DevelopmentGoals;
