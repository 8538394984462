/* eslint max-len: 0 */
export const radian = Math.PI / 180;
export const getXOffsetMultiplayerByAngle = (angle: number): number =>
  Math.cos(angle - 90 * radian);
export const getYOffsetMultiplayerByAngle = (angle: number): number =>
  Math.sin(angle - 90 * radian);
export const getXOffset = (offset: number, angle: number): number =>
  offset * getXOffsetMultiplayerByAngle(angle);
export const getYOffset = (offset: number, angle: number): number =>
  offset * getYOffsetMultiplayerByAngle(angle);
export const getAverage = (array: number[]): number =>
  array.reduce((acc: number, cur: number) => acc + cur, 0) / array.length;

export const colors = [
  "rgb(var(--color-primary-20))",
  "rgb(var(--color-primary-51))",
  "rgb(var(--color-primary-70))",
  "rgb(var(--color-secondary-80))",
  "rgb(var(--color-neutral-secondary-80))",
];

export const DEFAULT_COLOR = "rgb(var(--color-neutral-secondary-30))";

export const getColor = (index: number): string =>
  colors[index % colors.length];

export const professionStringIcons = {
  devops: `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.2148 18.9391L17.2148 22.9391L9.21484 19.9391V22.9391L5.02484 17.1891L17.9348 18.2391V7.27909L22.2148 6.58909V18.9391ZM5.02484 17.1891V9.89909L17.9348 7.27909L10.8148 2.93909V5.77909L4.18484 7.69909L2.21484 10.3191V16.0091L5.02484 17.1891Z"
          fill="currentColor"/>
</svg>

`,
  data: `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0703 3.47375C7.65031 3.47375 4.07031 5.26375 4.07031 7.47375V17.4738C4.07031 19.6838 7.66031 21.4738 12.0703 21.4738C16.4803 21.4738 20.0703 19.6838 20.0703 17.4738V7.47375C20.0703 5.26375 16.4903 3.47375 12.0703 3.47375ZM18.0703 17.4738C18.0703 17.9738 15.9403 19.4738 12.0703 19.4738C8.20031 19.4738 6.07031 17.9738 6.07031 17.4738V15.2438C7.68031 16.0238 9.79031 16.4738 12.0703 16.4738C14.3503 16.4738 16.4603 16.0238 18.0703 15.2438V17.4738ZM18.0703 12.9238C16.7703 13.8738 14.4903 14.4738 12.0703 14.4738C9.65031 14.4738 7.37031 13.8738 6.07031 12.9238V10.1138C7.54031 10.9438 9.68031 11.4738 12.0703 11.4738C14.4603 11.4738 16.6003 10.9438 18.0703 10.1138V12.9238ZM12.0703 9.47375C8.20031 9.47375 6.07031 7.97375 6.07031 7.47375C6.07031 6.97375 8.20031 5.47375 12.0703 5.47375C15.9403 5.47375 18.0703 6.97375 18.0703 7.47375C18.0703 7.97375 15.9403 9.47375 12.0703 9.47375Z"
          fill="currentColor"/>
</svg>
`,
  quality_assurance: `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.2148 7.93909H15.4048C14.9548 7.13909 14.3348 6.43909 13.5848 5.93909L15.2148 4.34909L13.8048 2.93909L11.6348 5.10909C11.1748 4.99909 10.7148 4.93909 10.2148 4.93909C9.71484 4.93909 9.26484 4.99909 8.80484 5.10909L6.62484 2.93909L5.21484 4.34909L6.83484 5.93909C6.08484 6.43909 5.47484 7.14909 5.02484 7.93909H2.21484V9.93909H4.30484C4.24484 10.2691 4.21484 10.5991 4.21484 10.9391V11.9391H2.21484V13.9391H4.21484V14.9391C4.21484 15.2791 4.24484 15.6091 4.30484 15.9391H2.21484V17.9391H5.02484C6.47484 20.4391 9.49484 21.5491 12.2148 20.5891C12.2148 20.3691 12.2148 20.1591 12.2148 19.9391C12.2148 19.3691 12.3048 18.7991 12.4648 18.2491C11.8048 18.6991 11.0148 18.9391 10.2148 18.9391C9.15398 18.9391 8.13656 18.5177 7.38642 17.7675C6.63627 17.0174 6.21484 16 6.21484 14.9391V10.9391C6.21484 9.87822 6.63627 8.86081 7.38642 8.11066C8.13656 7.36051 9.15398 6.93909 10.2148 6.93909C11.2757 6.93909 12.2931 7.36051 13.0433 8.11066C13.7934 8.86081 14.2148 9.87822 14.2148 10.9391V14.9391C14.2148 15.1291 14.2148 15.3291 14.1648 15.5191C14.7548 14.9791 15.4548 14.5591 16.2148 14.2891V13.9391H18.2148V11.9391H16.2148V10.9391C16.2148 10.5991 16.1848 10.2691 16.1248 9.93909H18.2148V7.93909ZM21.5548 16.9391L17.9648 20.5291L16.3748 18.9391L15.2148 20.0991L17.9648 23.0991L22.7148 18.3491L21.5548 16.9391ZM12.2148 9.93909V11.9391H8.21484V9.93909H12.2148ZM12.2148 13.9391V15.9391H8.21484V13.9391H12.2148Z"
          fill="currentColor"/>
</svg>
`,
  frontend: `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7333 3.65332L15.6933 4.05332L11.9533 21.6533L9.99326 21.2533L13.7333 3.65332ZM20.4333 12.6533L16.8433 9.06332V6.23332L23.2633 12.6533L16.8433 19.0633V16.2333L20.4333 12.6533ZM2.42326 12.6533L8.84326 6.23332V9.06332L5.25326 12.6533L8.84326 16.2333V19.0633L2.42326 12.6533Z"
          fill="currentColor"/>
</svg>
`,
  scala: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 24C4.724 24 4.5 23.776 4.5 23.5V17.926C4.5 17.65 4.724 17.426 5 17.426C9.657 17.426 17.909 15.865 18.584 14.857C18.707 14.674 18.932 14.592 19.145 14.657C19.355 14.721 19.499 14.916 19.499 15.136V20.626C19.499 20.726 19.469 20.822 19.414 20.904C18.213 22.692 8.679 24 5 24ZM6 19V22.5C10.642 22.4 16.92 21.026 18 20V17C15.369 18.296 9.355 18.908 6 19ZM5 9.365C4.724 9.365 4.5 9.141 4.5 8.865V3.291C4.5 3.015 4.724 2.791 5 2.791C9.657 2.791 17.909 1.23 18.584 0.221997C18.707 0.0389971 18.932 -0.0430029 19.145 0.021997C19.356 0.0859971 19.5 0.279997 19.5 0.499997V5.99C19.5 6.09 19.47 6.186 19.415 6.268C18.213 8.058 8.679 9.365 5 9.365ZM6.5 4.50004V7.50004C11.142 7.40004 16.92 6.52604 18 5.50004V2.50004C15.369 3.79504 9.855 4.40804 6.5 4.50004Z"
              fill="currentColor"/>
        <path d="M5 16.684C4.724 16.684 4.5 16.46 4.5 16.184V10.609C4.5 10.333 4.724 10.109 5 10.109C9.657 10.109 17.909 8.548 18.584 7.54C18.707 7.357 18.932 7.274 19.145 7.34C19.355 7.404 19.499 7.599 19.499 7.819V13.31C19.499 13.41 19.469 13.506 19.414 13.588C18.213 15.376 8.679 16.684 5 16.684ZM6 11.5V15C10.642 14.9 17.2172 13.526 18.2972 12.5V9.50001C15.6662 10.795 9.355 11.408 6 11.5Z"
              fill="currentColor"/>
</svg>
`,
  domains: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.5 4.20996L12 6.80996L16.5 4.20996" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M7.5 19.79V14.6L3 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21 12L16.5 14.6V19.79" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.27002 6.95996L12 12.01L20.73 6.95996" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M12 22.08V12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
};
