enum ActionTypes {
  LOGIN_START,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_START,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  CLEAR_ERROR,
  SET_IS_VISIBLE_FOR_TENANT,
}

export default ActionTypes;
