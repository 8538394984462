import { FallbackPlacementType, PlacementType } from "./types";

export const getFallbackPlacement = (
  basePlacement: PlacementType,
): FallbackPlacementType => {
  switch (basePlacement) {
    case "right-start":
      return ["right-end", "left-start", "left-end"];
    case "left-start":
      return ["left-end", "right-start", "right-end"];
    case "top-start":
      return ["top-end", "bottom-start", "bottom-end"];
    case "bottom-start":
    default:
      return ["bottom-end", "top-start", "top-end"];
  }
};
