import { ReactElement, KeyboardEvent } from "react";
import { useDispatch } from "redux-react-hook";
import { classJoin } from "@ps/utils";
import { ReactComponent as IconPin } from "../../../../../images/finance/pin.svg";
import { ReactComponent as CheckMarkIcon } from "../../../../../images/finance/holidays/checkMark.svg";
import { focusVisibleStyles, Keys } from "../../../../../shared";
import HolidaysTemplateService from "../../../../services/holidays-templates";
import { fetchHolidaysTemplates } from "../../../../store";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../../../hooks";
import { useHolidaysTemplatesContext } from "../../../context";
import {
  BASE_TRANSLATION_PATH,
  HOLIDAYS_LIST,
  NO_LIMIT,
  NO_LIMIT_THRESHOLD,
} from "../../../constants";
import { ViewRowProps, ViewRowMapState } from "./types";

const ViewRow = ({ holiday }: ViewRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const { currentTemplate, setRecentlyEditedTemplateId } =
    useHolidaysTemplatesContext();

  const mapState = (state: ViewRowMapState): ViewRowMapState => ({
    editMode: state?.editMode,
  });

  const { editMode } = useMappedStateSelector(mapState);

  const daysOffInfo = (): string | number =>
    holiday.numberOfDaysOff < NO_LIMIT_THRESHOLD
      ? holiday.numberOfDaysOff
      : t(`${BASE_TRANSLATION_PATH}.${HOLIDAYS_LIST}.${NO_LIMIT}`);

  const onPinHolidayClick = async (): Promise<void> => {
    if (holiday.isMainHoliday) return;
    await HolidaysTemplateService.setMainHolidayItem(
      currentTemplate?.id,
      holiday.holidaysId,
    );
    setRecentlyEditedTemplateId(currentTemplate?.id);
    await fetchHolidaysTemplates(dispatch);
  };

  return (
    <>
      <div
        role={editMode ? "presentation" : "button"}
        tabIndex={0}
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
          if (event.key === Keys.ENTER && !editMode) onPinHolidayClick();
        }}
        onClick={!editMode ? onPinHolidayClick : undefined}
        className={classJoin(
          "h-6 w-4 p-0.5 mt-1 rounded-md",
          focusVisibleStyles,
        )}
      >
        <IconPin
          className={classJoin(
            "w-full h-full",
            holiday?.isMainHoliday
              ? "text-primary-50 hover:text-primary-70"
              : "text-neutralSecondary-60 hover:text-neutralPrimary-30",
          )}
        />
      </div>
      <span className="self-center">{holiday.name}</span>
      <span className="self-center">{daysOffInfo()}</span>
      <span className="self-center">{holiday.payRatePercentage}%</span>
      {holiday.allowPartialDayOff ? (
        <CheckMarkIcon className="self-center text-success-50" />
      ) : (
        <span />
      )}
    </>
  );
};

export default ViewRow;
