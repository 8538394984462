import dayjs, { Dayjs } from "dayjs";

// eslint-disable-next-line import/prefer-default-export
export const getOverallExperience = (
  careerSpan?: {
    startDate?: Dayjs | null;
    gapMonths?: { years?: number; months?: number } | null;
  } | null,
): { years: number; months: number } => {
  if (!careerSpan) return { years: 0, months: 0 };
  if (!careerSpan.startDate) return { years: 0, months: 0 };
  const difference = parseInt(
    String(dayjs().diff(careerSpan.startDate, "month")),
    10,
  );
  const onlyMonths = careerSpan.gapMonths
    ? (careerSpan.gapMonths.years ?? 0) * 12 +
      (careerSpan.gapMonths.months ?? 0)
    : 0;
  const sumExperience = difference - onlyMonths;
  const years = Math.floor(sumExperience / 12);
  const months = sumExperience % 12;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(years) || years < 0) return { years: 0, months };
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(months) || months < 0) return { years, months: 0 };
  return { years, months };
};
