import { ReactElement } from "react";
import { motion } from "framer-motion";
import { classJoin } from "@ps/utils";
import { useThemeMode } from "../theme-mode-provider";
import styles from "./styles.module.css";

const baseStyle = classJoin.template`
absolute max-w-1/2 max-h-screen
overflow-hidden pl-40
`;

const PageBackground = (): ReactElement => {
  const { isHighContrast } = useThemeMode();

  return (
    <div
      className={classJoin(
        "overflow-hidden h-screen w-full absolute right-0 top-0",
        styles.zindex,
      )}
      style={{
        background: isHighContrast
          ? "#000000"
          : "linear-gradient(to bottom right, #0D2290, #3244E8)",
      }}
    >
      <div className="absolute top-0 right-0 h-full">
        <motion.div
          animate={{
            scale: [1.2, 1, 1.2],
            rotate: [0, 2, 0],
          }}
          transition={{
            duration: 15,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          }}
        >
          <svg
            width="1166"
            height="827"
            viewBox="0 0 1166 827"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M146.061 232.888C44.6296 201.838 0.212891 114.897 0.212891 -17H1166V806.349C951.143 866.191 907.376 785.713 857.721
            659.838C804.973 526.125 735.65
            412.214 638.408 350.308C476.827 247.444 276.293 272.755 146.061 232.888Z"
              fill="white"
              fillOpacity="0.1"
            />
          </svg>
        </motion.div>
        <div className="absolute top-0 right-0 h-full">
          <motion.div
            animate={{
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: 15,
              ease: "easeInOut",
              loop: Infinity,
              repeatDelay: 1,
            }}
          >
            <svg
              width="767"
              height="825"
              viewBox="0 0 767 825"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.337891 367.845C0.337844 179.067 48.5954 53.498 80.3346 -17H767V687.216C678.296 764.687 533.807 846.032
            359.788 818.705C151.306 785.967 0.337942 574.706 0.337891 367.845Z"
                fill="white"
                fillOpacity="0.1"
              />
            </svg>
          </motion.div>
        </div>
        <div className={classJoin("bottom-0 left-0", baseStyle)}>
          <motion.div
            animate={{
              scale: [1, 0.9, 1],
              rotate: [0, 6, 0],
              opacity: [1, 0.7, 1],
            }}
            transition={{
              duration: 15,
              ease: "easeInOut",
              loop: Infinity,
              repeatDelay: 1,
            }}
          >
            <svg
              width="1343"
              height="341"
              viewBox="0 0 1343 341"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="83.8852"
                cy="324.637"
                r="15.0659"
                stroke="white"
                strokeOpacity="0.3"
                strokeWidth="2"
              />
              <circle
                cx="313.127"
                cy="294.685"
                r="9.12704"
                stroke="white"
                strokeOpacity="0.3"
                strokeWidth="2"
              />
              <circle
                cx="613.367"
                cy="133.576"
                r="9.12704"
                stroke="white"
                strokeOpacity="0.3"
                strokeWidth="2"
              />
              <circle
                cx="10.1681"
                cy="60.8731"
                r="9.12704"
                stroke="white"
                strokeOpacity="0.3"
                strokeWidth="2"
              />
              <circle
                cx="1332"
                cy="10.4559"
                r="9.12704"
                stroke="white"
                strokeOpacity="0.3"
                strokeWidth="2"
              />
              <circle
                cx="470.642"
                cy="198.317"
                r="14.1294"
                stroke="white"
                strokeOpacity="0.3"
                strokeWidth="2"
              />
              <circle
                cx="623.494"
                cy="276.478"
                r="7.08036"
                stroke="white"
                strokeOpacity="0.3"
                strokeWidth="2"
              />
              <circle
                cx="842.363"
                cy="316.557"
                r="7.08036"
                stroke="white"
                strokeOpacity="0.3"
                strokeWidth="2"
              />
            </svg>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default PageBackground;
