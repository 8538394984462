import { AnyAction, combineReducers } from "redux";
import ActionTypes from "./actionTypes";
import { ProspectModel } from "../models";

const prospectReducer = (
  state: ProspectModel | Record<string, unknown> = {},
  action: AnyAction,
): ProspectModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_PROSPECT_START:
      return state;
    case ActionTypes.FETCH_PROSPECT_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_PROSPECT_ERROR:
      return {};
    default:
      return state;
  }
};

const prospectsListReducer = (
  state: ProspectModel[] | [] = [],
  action: AnyAction,
): ProspectModel[] | [] => {
  switch (action.type) {
    case ActionTypes.FETCH_PROSPECTS_START:
      return state;
    case ActionTypes.FETCH_PROSPECTS_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_PROSPECTS_ERROR:
      return [];
    default:
      return state;
  }
};

export const isFetchingProspectDetails = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_PROSPECT_START:
      return true;
    case ActionTypes.FETCH_PROSPECT_SUCCESS:
    case ActionTypes.FETCH_PROSPECT_ERROR:
      return false;
    default:
      return state;
  }
};

export const isFetchingProspectsList = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_PROSPECTS_START:
      return true;
    case ActionTypes.FETCH_PROSPECTS_SUCCESS:
    case ActionTypes.FETCH_PROSPECTS_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  prospect: prospectReducer,
  prospectsList: prospectsListReducer,
});
