import { StyleSheet } from "@react-pdf/renderer";

const sharedStyles = StyleSheet.create({
  container: {
    marginTop: "50px",
  },
  skillsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export const sharedOutlineStyles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    color: "#6B7482",
    fontWeight: "thin",
    fontSize: "10px",
    marginRight: "8px",
  },
  text: {
    color: "#2A2D33",
    fontWeight: "thin",
    fontSize: "12px",
    marginRight: "8px",
    textTransform: "capitalize",
  },
  textContainer: {
    marginLeft: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "50px",
    borderRight: "1px solid #D0D7E1",
  },
});

export default sharedStyles;
