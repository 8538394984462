import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import {
  TableRow,
  Label,
  Chip,
  Empty,
  MoreLinkButton,
} from "@ps/ui-components";
import { classJoin, getNameFromDictionary, setChipColors } from "@ps/utils";
import { useTranslationWithNamespace } from "../../../hooks";
import {
  BASE_TRANSLATION_PATH,
  HOLIDAYS,
  NAME,
  PROFESSIONS,
  TEMPLATE_NAME,
  USED_MAIN_HOLIDAYS,
  STATUS,
} from "./constants";
import styles from "./styles.module.css";
import { RowProps } from "./types";

const Row = ({ user, seniorityDict }: RowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const history = useHistory();

  const seniority = getNameFromDictionary(seniorityDict, user?.seniority);

  return (
    <TableRow
      columnsStyles={classJoin(
        styles.tableColumns,
        "mb-3 border",
        user.holidaysTemplate?.templateId
          ? "border-neutralPrimary-100"
          : "border-error-80",
      )}
      dataCy={HOLIDAYS}
      additionalClassName={user.enabled ? "" : "opacity-40"}
    >
      <Label dataCy={NAME}>{user.name}</Label>
      <Label dataCy={PROFESSIONS}>
        <div className="flex items-center gap-2">
          {!user.seniority && !user.profession && <Empty />}
          {user?.seniority ? (
            <Chip
              dataCy={PROFESSIONS}
              label={seniority}
              tailwindColors={setChipColors(user.seniority)}
              additionalClassName="text-base capitalize"
            />
          ) : (
            <></>
          )}
          {user?.profession ? (
            <Chip
              dataCy={PROFESSIONS}
              label={user.profession}
              tailwindColors={setChipColors(user.profession)}
              additionalClassName="text-base"
            />
          ) : (
            <></>
          )}
        </div>
      </Label>
      <Label dataCy={TEMPLATE_NAME}>
        {user.holidaysTemplate?.templateName || "-"}
      </Label>
      <Label dataCy={USED_MAIN_HOLIDAYS}>
        <span className="text-primary-70">{user.holidays.used}</span>
        <span>{` / ${user.holidays.total}`}</span>
      </Label>
      <Label dataCy={STATUS}>
        {user.enabled ? (
          <span className="text-success-60">
            {t(`${BASE_TRANSLATION_PATH}.active`)}
          </span>
        ) : (
          <span className="text-neutralPrimary-50">
            {t(`${BASE_TRANSLATION_PATH}.archived`)}
          </span>
        )}
      </Label>
      <MoreLinkButton
        dataCy={`${HOLIDAYS}_more`}
        disabled={!user.enabled}
        onClick={() => history.push(`/finance/holidays/${user.id}`)}
        additionalClassName="self-center place-self-end"
      />
    </TableRow>
  );
};

export default Row;
