import { AnyAction } from "redux";
import { ProjectModel } from "@ps/hh";
import ActionTypes from "../actions/actionTypes";
import { BarChartDataModel, ProjectTimeEntriesModel } from "../../models";
import { TimesheetState } from "./types";

export const timesheetReducer = (
  state: TimesheetState = {},
  action: AnyAction,
): TimesheetState => {
  switch (action.type) {
    case ActionTypes.FETCH_TIMESHEET_START:
      return state;
    case ActionTypes.FETCH_TIMESHEET_SUCCESS:
      return action.payload?.timesheet;
    case ActionTypes.FETCH_TIMESHEET_ERROR:
      return {};
    case ActionTypes.APPEND_TIMESHEET_ENTRY:
      return {
        ...state,
        project: {
          ...state?.project,
          [action.payload.id]: {},
        },
      };
    case ActionTypes.REMOVE_TIMESHEET_ENTRY: {
      const newProjectsList = state?.project
        ? Object.entries(state?.project)
            .filter(([key]) => key !== action.payload.id)
            ?.reduce(
              (acc, [keyItem, keyValue]) => ({
                ...acc,
                [keyItem]: keyValue,
              }),
              {},
            )
        : state;
      return { ...state, project: newProjectsList };
    }
    default:
      return state;
  }
};

export const previousWeekTimesheetReducer = (
  state: ProjectTimeEntriesModel | Record<string, unknown> = {},
  action: AnyAction,
): ProjectTimeEntriesModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_LAST_WEEK_TIMESHEET_START:
      return state;
    case ActionTypes.FETCH_LAST_WEEK_TIMESHEET_SUCCESS:
      return action.payload?.previousWeekTimesheet;
    case ActionTypes.FETCH_LAST_WEEK_TIMESHEET_ERROR:
      return {};
    default:
      return state;
  }
};

export const barChartDataReducer = (
  state: BarChartDataModel | Record<string, unknown> = {},
  action: AnyAction,
): BarChartDataModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_TIMESHEET_START:
      return state;
    case ActionTypes.FETCH_TIMESHEET_SUCCESS:
      return action.payload?.barChartData;
    case ActionTypes.FETCH_TIMESHEET_ERROR:
      return {};
    default:
      return state;
  }
};

export const pinnedProjectsReducer = (
  state: string[] = [],
  action: AnyAction,
): string[] | [] => {
  switch (action.type) {
    case ActionTypes.FETCH_PINNED_PROJECTS_START:
      return state;
    case ActionTypes.FETCH_PINNED_PROJECTS_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_PINNED_PROJECTS_ERROR:
      return [];
    default:
      return state;
  }
};

export const projectsListReducer = (
  state: ProjectModel[] = [],
  action: AnyAction,
): ProjectModel[] => {
  switch (action.type) {
    case ActionTypes.FETCH_PROJECTS_LIST_START:
      return state;
    case ActionTypes.FETCH_PROJECTS_LIST_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_PROJECTS_LIST_ERROR:
      return [];
    default:
      return state;
  }
};

export const isFetchingTimesheet = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_LAST_WEEK_TIMESHEET_START:
    case ActionTypes.FETCH_PINNED_PROJECTS_START:
    case ActionTypes.FETCH_PROJECTS_LIST_START:
    case ActionTypes.FETCH_TIMESHEET_START:
      return true;
    case ActionTypes.FETCH_LAST_WEEK_TIMESHEET_ERROR:
    case ActionTypes.FETCH_LAST_WEEK_TIMESHEET_SUCCESS:
    case ActionTypes.FETCH_PINNED_PROJECTS_ERROR:
    case ActionTypes.FETCH_PINNED_PROJECTS_SUCCESS:
    case ActionTypes.FETCH_TIMESHEET_ERROR:
    case ActionTypes.FETCH_TIMESHEET_SUCCESS:
      return false;
    default:
      return state;
  }
};
