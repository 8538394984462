import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import Uploader from "@ps/ui-components/src/components/uploader/uploader";
import { classJoin, useDropDiv } from "@ps/utils";
import { useDispatch } from "redux-react-hook";
import { useHistory } from "react-router-dom";
import { validate } from "./helpers";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { OCR_UPLOADER } from "../../../../shared/data-cy";
import OcrUploadFailModal from "./ocrUploadFailModal";
import OcrUploadWaitingModal from "./ocrUploadWaitingModal";
import {
  ocrProcessingError,
  ocrProcessingStart,
  ocrProcessingSuccess,
} from "../../../store";
import { MyProfileService } from "../../../services";
import {
  OCRUploaderState,
  OCRUploaderStateReturn,
  OcrUploaderProps,
} from "./types";

const MAX_SIZE_MB = 4;
const EXTENSION = "pdf";

const OcrUploader = ({
  disabled,
  verticalView = false,
}: OcrUploaderProps): ReactElement => {
  const [errors, setErrors] = useState<string[]>([]);
  const [status, setStatus] = useState<number>(-1);
  const [ocrInProgress, setOcrInProgress] = useState<boolean>(false);
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const history = useHistory();

  const mapState = (state: OCRUploaderState): OCRUploaderStateReturn => ({
    technologyDict: state?.dictionaries?.technology,
  });

  const { technologyDict } = useMappedStateSelector(mapState);

  useEffect(
    () => () => {
      setErrors([]);
      setStatus(-1);
    },
    [],
  );

  const ocrProcessing = async (cv: File): Promise<string[] | void> => {
    setOcrInProgress(true);
    dispatch(ocrProcessingStart());
    const result = await MyProfileService.ocrProcessing(cv, technologyDict);
    setOcrInProgress(false);
    if (!result || result?.status) {
      setStatus(result?.status ?? -1);
      dispatch(ocrProcessingError(result));
      return result?.data?.messages;
    }
    dispatch(ocrProcessingSuccess(result));
    history.push("/profile/wizard");
    return undefined;
  };

  const validateAndRun = async (file: File | null): Promise<void> => {
    if (!file) return;

    const validationErrors = validate(MAX_SIZE_MB, EXTENSION, file, t);
    setErrors(validationErrors);
    if (!validationErrors?.length) {
      // TODO: Remove at later date, temporary solution
      const apiErrors = (await ocrProcessing?.(file)) || [];
      if (apiErrors?.length) {
        setErrors(apiErrors);
      }
    }
  };

  const { dropDivRef, isDraggingOver } = useDropDiv({
    onFilesSelected: (files: FileList): void => {
      const file = files.item(0);
      validateAndRun(file);
    },
  });

  const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;
    const file = files?.item(0) ?? null;
    validateAndRun(file);
  };

  const bodyColor = isDraggingOver ? "bg-primary-80" : "bg-primary-85";

  return (
    <div className={classJoin(disabled && "opacity-40")}>
      <Uploader
        accept={`.${EXTENSION}`}
        dataCy={OCR_UPLOADER}
        disabled={ocrInProgress || disabled}
        ref={dropDivRef}
        handleInputChange={handleInputOnChange}
        dropWrapperStyle={bodyColor}
        verticalView={verticalView}
        isDraggingOver={isDraggingOver}
      />
      <OcrUploadFailModal
        status={status}
        isOpen={errors.length > 0}
        onClose={(): void => setErrors([])}
        message={errors?.[0]}
      />
      <OcrUploadWaitingModal
        isOpen={ocrInProgress}
        onClose={(): void => {
          // doing nothing for now
        }}
      />
    </div>
  );
};

export default OcrUploader;
