import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { Chip, Empty, Label } from "@ps/ui-components";
import { classJoin, getNamesFromDictionary, setChipColors } from "@ps/utils";
import {
  ACTIVE_FOLDERS,
  ACTIVE_PROSPECTS,
  ACTIVE_PROJECTS,
  CLIENT_CITY,
  CLIENT_COUNTRY,
  CLIENT_DETAILS,
  CONTACT_PERSON_EMAIL,
  CONTACT_PERSON_NAME,
  DOMAINS,
  PHONE_NUMBER,
  CLIENTS,
  PROSPECTS,
  FOLDERS,
  PROJECTS,
} from "../../constants";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import {
  DisplayClientDetailsMapState,
  DisplayClientDetailsMapStateReturn,
  SimpleLabelProps,
} from "./types";
import QuantityBox from "../clients-list/quantityBox";
import { ClientSummaryAPIModel } from "../../models/types";
import styles from "./styles.module.css";
import { CLIENT } from "../../../shared/data-cy";

const TRANSLATION_PATH = "clients";

const SimpleLabel = ({
  basePath,
  name,
  value,
  label,
}: SimpleLabelProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <Label
      dataCy={`${basePath}-${name}`}
      text={t(`${basePath}.${label}`)}
      labelFontWeight="font-bold"
      labelFontSize="text-lg"
      labelColor="text-neutralPrimary-20"
      additionalClassName="whitespace-pre-line"
    >
      {value || <Empty />}
    </Label>
  );
};

const DisplayClientDetails = ({
  editButton,
}: {
  editButton: ReactElement;
}): ReactElement => {
  const history = useHistory();
  const mapState = (
    state: DisplayClientDetailsMapState,
  ): DisplayClientDetailsMapStateReturn => {
    return {
      clientSummary: state?.clients.clientSummary || [],
      domainsDict: state.dictionaries.domain || [],
      clientsSummary: state.clients.clientsSummary,
    };
  };
  const {
    clientSummary,
    domainsDict,
    clientsSummary: clients,
  } = useMappedStateSelector(mapState);
  const selectedDomains: string[] = getNamesFromDictionary(
    clientSummary?.domains,
    domainsDict,
  ).split(",");

  const client: ClientSummaryAPIModel = clients?.find(
    (c: ClientSummaryAPIModel) => c?.id === clientSummary?.id,
  );

  const redirect = (tab?: string) => {
    const pathname = `/${CLIENTS}/${client?.id}`;
    if (tab) history.push({ pathname, state: { tab } });
    else history.push(pathname);
  };

  return (
    <>
      <section className="w-full h-full flex pb-16 relative">
        <div className="flex flex-col justify-start min-h-4/6 gap-y-8 w-9/12">
          <div className="w-9/12">
            <div className="flex mb-8">
              <SimpleLabel
                basePath={TRANSLATION_PATH}
                name={ACTIVE_PROSPECTS}
                label={ACTIVE_PROSPECTS}
                value={
                  <QuantityBox
                    dataCy={`${CLIENT}_${ACTIVE_PROSPECTS}`}
                    quantity={client?.numberOfActiveProspects}
                    borderStyle="border-primary-51"
                    onClick={() => redirect(PROSPECTS)}
                  />
                }
              />
              <SimpleLabel
                basePath={TRANSLATION_PATH}
                name={ACTIVE_FOLDERS}
                label={ACTIVE_FOLDERS}
                value={
                  <QuantityBox
                    dataCy={`${CLIENT}_${ACTIVE_FOLDERS}`}
                    quantity={client?.numberOfActiveFolders}
                    borderStyle="border-success-50"
                    onClick={() => redirect(FOLDERS)}
                  />
                }
              />
              <SimpleLabel
                basePath={TRANSLATION_PATH}
                name={ACTIVE_PROJECTS}
                label={ACTIVE_PROJECTS}
                value={
                  <QuantityBox
                    dataCy={`${CLIENT}_${ACTIVE_PROJECTS}`}
                    quantity={client?.numberOfActiveProjects}
                    borderStyle="border-neutralSecondary-30"
                    onClick={() => redirect(PROJECTS)}
                  />
                }
              />
            </div>
            <SimpleLabel
              basePath={TRANSLATION_PATH}
              name={DOMAINS}
              label={DOMAINS}
              value={
                <div className="flex flex-wrap gap-y-2 gap-x-2">
                  {!selectedDomains.includes("") ? (
                    selectedDomains.map((selectedDomain: string) => (
                      <Chip
                        key={selectedDomain}
                        dataCy={`${CLIENT_DETAILS}_${DOMAINS}`}
                        label={selectedDomain}
                        borderWidth="border"
                        tailwindColors={setChipColors("domain")}
                      />
                    ))
                  ) : (
                    <Empty />
                  )}
                </div>
              }
            />
          </div>
        </div>
        <div
          className={classJoin(
            "flex flex-col overflow-visible bg-neutralPrimary-100 w-3/12 right-8 -top-32 absolute rounded-2xl",
            styles.boxShadow,
          )}
        >
          <div className="flex justify-end h-9 items-center px-6">
            {!clientSummary?.archived && editButton}
          </div>
          <div
            className={classJoin(
              "flex flex-col gap-y-6 px-6 pb-6",
              styles.displayHeight,
            )}
          >
            <SimpleLabel
              basePath={TRANSLATION_PATH}
              name={CONTACT_PERSON_NAME}
              value={clientSummary?.contactPersonName}
              label={CONTACT_PERSON_NAME}
            />
            <SimpleLabel
              basePath={TRANSLATION_PATH}
              name={PHONE_NUMBER}
              value={clientSummary?.phoneNumber}
              label={PHONE_NUMBER}
            />
            <SimpleLabel
              basePath={TRANSLATION_PATH}
              name={CONTACT_PERSON_EMAIL}
              value={clientSummary?.contactPersonEmail}
              label={CONTACT_PERSON_EMAIL}
            />
            <SimpleLabel
              basePath={TRANSLATION_PATH}
              name={CLIENT_COUNTRY}
              value={clientSummary?.country?.label}
              label={CLIENT_COUNTRY}
            />
            <SimpleLabel
              basePath={TRANSLATION_PATH}
              name={CLIENT_CITY}
              value={clientSummary?.city}
              label={CLIENT_CITY}
            />
          </div>
          <div
            className={classJoin(
              clientSummary?.archived ? "bg-gray-200" : "bg-other-toxicGreen",
              "h-5 rounded-b-2xl",
            )}
          />
        </div>
      </section>
    </>
  );
};

export default DisplayClientDetails;
