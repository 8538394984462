import {
  useState,
  useEffect,
  ReactElement,
  KeyboardEvent,
  MouseEvent,
  ChangeEvent,
} from "react";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import Tag from "../tag";
import Input from "../input";
import {
  SEARCH_SELECT_PICKER_INPUT_PICKER,
  SEARCH_SELECT_PICKER_PREFIX,
} from "../../shared/data-cy";
import { Keys } from "../../shared";
import { useTranslationWithNamespace } from "../../hooks";
import { SearchSelectPickerProps, TagProps } from "./types";
import {
  isCreateNewOptionVisible,
  filteredProposalTags,
} from "../../shared/utils/searchPickerUtils";
import getHighlightedText from "../../shared/utils/highlightTextUtil";
import Fade from "../fade";
import SearchSelectPickerButton from "./searchSelectPickerButton";

const SearchSelectPicker = ({
  additionalTagsWrapClassName,
  dataCy,
  defaultSelected,
  error,
  maxProposalTagsNumber = 10,
  message,
  onInputChange,
  onTagSelected,
  placeholder,
  proposalTags,
  shouldScrollToTop = false,
}: SearchSelectPickerProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [currentSelection, setCurrentSelection] = useState<number>(0);
  const [selectedTags, setSelectedTags] = useState<TagProps[]>(defaultSelected);
  const [searchValue, setSearchValue] = useState<string>("");
  const tagsToDisplay: TagProps[] = filteredProposalTags(
    proposalTags,
    selectedTags,
  )?.slice(0, maxProposalTagsNumber);

  useEffect(() => {
    onTagSelected?.(selectedTags);
  }, [selectedTags]);

  useEffect(() => {
    onInputChange?.(searchValue);
  }, [searchValue]);

  const handleOnAddCreateNewTag = (newName: string) => {
    setSelectedTags([
      ...selectedTags,
      { id: uid(newName), name: newName, isNew: true },
    ]);
    setSearchValue("");
  };

  const handleOnAppendTag = (newTag: TagProps) => {
    setSelectedTags([...selectedTags, newTag]);
    setSearchValue("");
  };

  const trimmedSearchValue = searchValue.trim();

  const handleInputChange = (value: string) => {
    if (value && !/^[a-zA-Z0-9. # _+-]+$/.test(value)) return;
    setCurrentSelection(0);
    setSearchValue(value);
  };

  const handleOnTagClick = (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
    id: string,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedTags(selectedTags.filter((item: TagProps) => item.id !== id));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      currentSelection === tagsToDisplay?.length &&
      event.key === Keys.ARROW_DOWN &&
      isCreateNewOptionVisible(proposalTags, trimmedSearchValue, selectedTags)
    )
      setCurrentSelection(tagsToDisplay?.length);
    if (
      currentSelection === tagsToDisplay?.length &&
      event.key === Keys.ARROW_DOWN
    )
      setCurrentSelection(-1);
    if (event.key === Keys.ARROW_DOWN)
      setCurrentSelection((prevIndex: number | null) =>
        prevIndex !== null ? prevIndex + 1 : 0,
      );
    if (
      event.key === Keys.ARROW_UP &&
      currentSelection &&
      currentSelection > -1
    )
      setCurrentSelection((prevIndex: number) =>
        prevIndex !== 0 ? prevIndex - 1 : 0,
      );

    if (event.key === Keys.ENTER && searchValue) {
      event.preventDefault();
      event.stopPropagation();
      currentSelection < tagsToDisplay?.length
        ? handleOnAppendTag(
            filteredProposalTags(proposalTags, selectedTags)?.[
              currentSelection
            ],
          )
        : handleOnAddCreateNewTag(trimmedSearchValue);
      setCurrentSelection(0);
    }
  };
  const targetElm = document.querySelector(".scrollBox");

  const scrollTop = () => {
    targetElm?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div
      className="flex flex-col scrollBox"
      data-cy={`${SEARCH_SELECT_PICKER_PREFIX}_${dataCy}`}
    >
      {selectedTags?.length ? (
        <div
          className={classJoin(
            "flex flex-row flex-wrap gap-1 mb-1",
            additionalTagsWrapClassName,
          )}
        >
          {selectedTags
            ?.filter((item) => item)
            ?.map(({ id, name }: TagProps) => (
              <Tag
                name={name}
                key={`${id}-${name}`}
                dataCy={`${id}`}
                onClick={(e) => handleOnTagClick(e, id)}
              />
            ))}
        </div>
      ) : (
        <></>
      )}
      <Fade
        open={!!trimmedSearchValue.length}
        handleClose={() => setSearchValue("")}
        contentWrapperClassName={classJoin(
          error ? "top-9" : "",
          "z-50 border w-full rounded-md",
        )}
        clickElementWrapperClassName="relative"
        clickElement={
          <Input
            maxLength={40}
            dataCy={SEARCH_SELECT_PICKER_INPUT_PICKER}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleInputChange(event.target.value);
              if (
                shouldScrollToTop &&
                !searchValue?.length &&
                event.target.value?.length === 1
              )
                setTimeout(() => scrollTop(), 100);
            }}
            value={searchValue}
            placeholder={placeholder || t("searchSelectPicker.placeholder")}
            error={error}
            message={message}
            onKeyDown={handleKeyDown}
          />
        }
      >
        <div className="flex flex-col gap-1 bg-neutralPrimary-100 rounded-md">
          {tagsToDisplay?.map((item: TagProps, index: number) =>
            getHighlightedText(
              item,
              index,
              trimmedSearchValue,
              currentSelection,
              handleOnAppendTag,
            ),
          )}
          {isCreateNewOptionVisible(
            proposalTags,
            trimmedSearchValue,
            selectedTags,
          ) ? (
            <SearchSelectPickerButton
              dataCy={dataCy}
              maxLength={40}
              onNewTagClick={handleOnAddCreateNewTag}
              trimmedSearchValue={trimmedSearchValue}
            />
          ) : (
            <></>
          )}
        </div>
      </Fade>
    </div>
  );
};

export default SearchSelectPicker;
