import { ReactElement, useEffect, useState } from "react";
import { isAPIError } from "@ps/api-utils";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "redux-react-hook";
import { Notification, SaveCancelBar } from "@ps/ui-components";
import TabsContainer from "./tabs/tabsContainer";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../../hooks";
import { useEditMatrixContext } from "../context";
import { FormValuesProps, IGroup, ISkill } from "./types";
import {
  DICTIONARIES,
  DictionariesService,
  DictionaryEntryModel,
  DictionaryModel,
  fetchDictionary,
  prepareSingleEntryModel,
} from "../../../../../../dictionaries";
import {
  TemplateContainerMapState,
  TemplateContainerMapStateReturn,
} from "../types";
import { AddNewGroupButton, AddProfessionButton } from "./buttons";
import { GroupTechnologiesTable } from "./table";
import { GroupAPIModel, SkillAPIModel } from "../../../../../models";
import { fetchTemplates } from "../../../../../store";
import { SKILLS_MATRIX_EXPERTISE_TEMPLATE } from "../../../../../../shared/data-cy";
import { DOMAINS_PROFESSION_ID } from "../../../../../shared/consts";
import SkillsService from "../../../../../services/skills";
import { updateNewTechnologyId } from "../utils";

const TemplateContainer = (): ReactElement => {
  const methods = useForm<FormValuesProps>();

  const isFormValid = !!methods.formState.errors;

  const [activeTab, setActiveTab] = useState("");
  const { t } = useTranslationWithNamespace();
  const {
    editGroups,
    editProfession,
    setEditGroups,
    setEditProfession,
    setEditTechnologies,
    setIsDragAndDrop,
    isNotificationVisible,
    setIsNotificationVisible,
    setEditTechnologyMode,
  } = useEditMatrixContext();
  const dispatch = useDispatch();

  const mapState = (
    state: TemplateContainerMapState,
  ): TemplateContainerMapStateReturn => ({
    editMode: state.editMode,
    isFetchingDictionary: state.requestStatus.isFetchingDictionary,
    professionsDict: state.dictionaries?.profession || [],
    templates: state?.skillsMatrix?.templates,
  });

  const { editMode, professionsDict, isFetchingDictionary, templates } =
    useMappedStateSelector(mapState);

  const tabsArray = useFieldArray({
    control: methods.control,
    name: "tabs",
  });

  const groupArray = useFieldArray({
    control: methods.control,
    name: "groups",
  });

  const baseProfessions = [
    ...professionsDict.filter(
      (item: DictionaryEntryModel): boolean => !item.deleted,
    ),
    {
      id: DOMAINS_PROFESSION_ID,
      name: t(`profile.skills.legend.${DOMAINS_PROFESSION_ID}`),
      iconUrl: "",
      desc: "",
      deleted: false,
      itemId: DOMAINS_PROFESSION_ID,
    },
  ];

  useEffect(() => {
    setActiveTab(professionsDict?.[0]?.itemId);
  }, []);

  useEffect(() => {
    methods.reset({
      tabs: baseProfessions,
    });
    setActiveTab(baseProfessions?.[0]?.itemId);
  }, [professionsDict]);

  const prepareTechArray = (dataToFilter: GroupAPIModel[]): string[] =>
    dataToFilter
      .map((group: GroupAPIModel): SkillAPIModel[] => group.skills)
      .flat()
      .map((item: SkillAPIModel): string => item.technologyId);

  const handleOnSubmit = async (data: {
    tabs: DictionaryModel;
    groups: GroupAPIModel[];
    itemIdToRemove?: string;
  }): Promise<void> => {
    const isDomainTab = activeTab === DOMAINS_PROFESSION_ID;
    if (editProfession) {
      const tabsToSave = data.tabs.find((item) =>
        tabsArray.fields.some(
          (tab) => tab.name !== item.name && tab.itemId === item.itemId,
        ),
      );

      if (tabsToSave?.isNew) {
        const response = await DictionariesService.createDictionaryEntry(
          DICTIONARIES.PROFESSION,
          tabsToSave,
        );
        const newEntry = prepareSingleEntryModel(response?.data);

        // TODO Entry shouldn't be reverted to deleted: false after post
        if (response?.data?.id && response?.status?.toString() === "409") {
          await DictionariesService.setDictionaryEntryDeleted(
            DICTIONARIES.PROFESSION,
            response?.data?.id,
            false,
          );
          await fetchDictionary(DICTIONARIES.PROFESSION, dispatch);
          setEditProfession("");
        }

        const isProfessionError = isAPIError(response);

        if (isProfessionError) {
          const index = data.tabs.reduce(
            (acc, item, id) => (item.itemId === tabsToSave.itemId ? id : acc),
            0,
          );

          // TODO Notification should be displayed
          methods.setError(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            `tabs[${index}].name`,
            { type: "focus" },
            { shouldFocus: true },
          );
          return;
        }
        setActiveTab(newEntry?.id);
      }
      setEditProfession("");
      if (tabsToSave) {
        const response = await DictionariesService.updateDictionaryEntry(
          DICTIONARIES.PROFESSION,
          tabsToSave.id,
          tabsToSave.name,
        );
        const isProfessionError = isAPIError(response);
        if (isProfessionError) return;
      }
      await fetchDictionary(DICTIONARIES.PROFESSION, dispatch);
    } else {
      const editingProfession = templates?.[activeTab];

      const oldTechIdsArray = prepareTechArray(editingProfession?.groups || []);
      const newTechArray = prepareTechArray(data?.groups || []);
      const arrDifference = newTechArray.filter(
        (tech: string): boolean => !oldTechIdsArray.includes(tech),
      );

      if (data.itemIdToRemove) {
        await DictionariesService.setDictionaryEntryDeleted(
          isDomainTab ? DICTIONARIES.DOMAIN : DICTIONARIES.TECHNOLOGY,
          data.itemIdToRemove,
        );
      }

      const savedDicEntries = await Promise.allSettled(
        arrDifference.map((name: string): Promise<void> => {
          return DictionariesService.createDictionaryEntry(
            isDomainTab ? DICTIONARIES.DOMAIN : DICTIONARIES.TECHNOLOGY,
            {
              name,
            },
          );
        }),
      );

      // TODO temporary solution to save new profession with knowledge and enjoyment scale
      // TODO for now scale have to be `borrow` from other profession or set on empty arrays if any profession exists
      const prepareScales = () => {
        if (
          editingProfession?.enjoymentScale &&
          editingProfession?.knowledgeScale
        ) {
          return {
            enjoymentScale: editingProfession?.enjoymentScale,
            knowledgeScale: editingProfession?.knowledgeScale,
          };
        }

        const otherProfessionScales = Object.entries(templates).find(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ([_, value]) => value?.enjoymentScale && value?.knowledgeScale, // eslint-disable-line @typescript-eslint/no-unused-vars
        )?.[1];

        if (otherProfessionScales) {
          return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            enjoymentScale: otherProfessionScales?.enjoymentScale,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            knowledgeScale: otherProfessionScales?.knowledgeScale,
          };
        }

        return {
          enjoymentScale: [],
          knowledgeScale: [],
        };
      };

      const scales = prepareScales();

      const result = {
        // Works till one new technology is saving (not working for group)
        groups: updateNewTechnologyId(data.groups, savedDicEntries?.[0]?.value),
        enjoymentScale: scales.enjoymentScale || [],
        knowledgeScale: scales.knowledgeScale || [],
      };

      if (isDomainTab) {
        const domainsList = result.groups?.[0].skills.map(
          (item: { technologyId: string }) => item.technologyId,
        );
        await SkillsService.updateDomainTemplate({
          domains: domainsList,
          enjoymentScale: result.enjoymentScale,
          knowledgeScale: result.knowledgeScale,
        });
      } else {
        await SkillsService.updateProfessionTemplate(activeTab, result);
      }
      await fetchTemplates(activeTab, dispatch, t);
      await fetchDictionary(
        isDomainTab ? DICTIONARIES.DOMAIN : DICTIONARIES.TECHNOLOGY,
        dispatch,
      );

      setEditGroups([]);
      setEditTechnologies([]);
      setIsDragAndDrop(false);
      setEditTechnologyMode(false);
    }
  };

  const handleOnAddProfession = (): void => {
    const newTabKey = `new_${tabsArray.fields?.length}_${Math.random()}`;
    setEditProfession(newTabKey);
    tabsArray.prepend({
      deleted: false,
      isNew: true,
      itemId: newTabKey,
      name: "",
    });
  };

  const handleOnRemoveProfession = async (
    dictId = "",
    index: number,
  ): Promise<void> => {
    if (dictId) {
      await DictionariesService.setDictionaryEntryDeleted(
        DICTIONARIES.PROFESSION,
        dictId,
      );
      tabsArray.remove(index);
      await fetchDictionary(DICTIONARIES.PROFESSION, dispatch);
      methods.reset({
        tabs: baseProfessions,
      });
      setActiveTab(baseProfessions?.[0]?.itemId);
    }
  };

  const handleOnAddGroup = (): void => {
    const newGroupId = `new_${groupArray.fields?.length}_${Math.random()}`;
    setEditGroups((prev) => [...prev, newGroupId]);
    groupArray.prepend({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      desc: null,
      isNew: true,
      itemId: newGroupId,
      name: "",
      skills: [],
    });
  };

  const arrIndexesToCancel = groupArray?.fields.reduce(
    (acc, value, index): number[] =>
      value.name === "" && value.desc === null ? [...acc, index] : acc,
    [],
  );

  return (
    <FormProvider {...methods}>
      <form
        className="h-inherit relative"
        onSubmit={methods.handleSubmit(handleOnSubmit)}
      >
        {isNotificationVisible && (
          <div className="absolute w-1/2 right-0 z-50">
            <Notification
              dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_cant_remove_profession`}
              description={t(
                "expertise.matrix.editMatrix.cantRemoveProfession",
              )}
              id=""
              onClose={() => setIsNotificationVisible(false)}
              title="Error!"
              variant="error"
            />
          </div>
        )}
        <div className="flex justify-between">
          <AddProfessionButton
            disabled={!!editProfession || !!editGroups?.length}
            onClick={handleOnAddProfession}
          />
          {groupArray?.fields?.length ? (
            <AddNewGroupButton
              onClick={handleOnAddGroup}
              disabled={
                !!editProfession ||
                activeTab === DOMAINS_PROFESSION_ID ||
                editMode
              }
            />
          ) : (
            <></>
          )}
        </div>
        <TabsContainer
          activeTab={activeTab}
          isLoading={isFetchingDictionary}
          onActiveTabClick={setActiveTab}
          professions={tabsArray.fields}
          handleOnRemoveProfession={handleOnRemoveProfession}
          onSubmit={methods.handleSubmit(handleOnSubmit)}
        >
          <GroupTechnologiesTable
            addNewGroup={handleOnAddGroup}
            groupArray={groupArray}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSubmit={handleOnSubmit}
            professionId={activeTab}
          />
        </TabsContainer>

        <SaveCancelBar
          dataCy={SKILLS_MATRIX_EXPERTISE_TEMPLATE}
          barLabel={t("expertise.matrix.editMatrix.saveYourMatrix")}
          saveButton={{
            label: t("expertise.actions.save"),
            disabled: !editMode || !isFormValid,
            type: "submit",
          }}
          cancelButton={{
            label: t("expertise.actions.cancel"),
            disabled: !editMode,
            onClick: () => {
              methods.reset({
                tabs: baseProfessions,
                groups: groupArray?.fields,
              });
              setEditProfession("");
              setEditGroups([]);
              setEditTechnologies([]);
              setActiveTab(professionsDict?.[0]?.itemId);
              setEditTechnologyMode(false);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              groupArray?.remove(arrIndexesToCancel);
            },
          }}
        />
      </form>
    </FormProvider>
  );
};
export default TemplateContainer;
