import { ReactElement } from "react";
import { colorFormatters, minutesToHHMM } from "@ps/utils";
import { ProjectModel } from "@ps/hh";
import { CircleChart } from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { DASHBOARD_CIRCLE_CHART } from "../../../shared/data-cy";
import { ProjectTimeEntriesModel } from "../../../models";
import { ChartMapState, ChartMapStateReturn } from "./types";
import { dataFromTimesheet } from "./utils";

const Chart = (): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const mapState = (state: ChartMapState): ChartMapStateReturn => ({
    projectsTimeEntries: state.timesheet?.project || {},
    projects: state?.projectsList || [],
  });

  const { projectsTimeEntries, projects } = useMappedStateSelector(
    mapState,
  ) as {
    projectsTimeEntries: ProjectTimeEntriesModel;
    projects: ProjectModel[];
  };

  const timesheetValues =
    projectsTimeEntries && Object.values(projectsTimeEntries);
  const projectIds = Object.keys(projectsTimeEntries);

  const getProjectColor = (id: string) => {
    const project = projects?.find((singleProject) => singleProject.id === id);
    return (
      colorFormatters.hexToRgbString(project?.details?.color) ||
      "rgb(208, 215, 225)"
    );
  };

  const onlyEntries = timesheetValues
    ?.map((item) => Object.values(item))
    .flat(2);
  const totalWorkTime = onlyEntries?.reduce((a, b) => a + b.minutes, 0);

  return (
    <CircleChart
      data={dataFromTimesheet(timesheetValues)}
      fillColors={(_, i: number) => getProjectColor(projectIds[i])}
      dataCy={DASHBOARD_CIRCLE_CHART}
      additionalClassName="relative"
    >
      <div className="absolute top-1/3 left-1/4 flex flex-col gap-5 items-center">
        <span
          className="text-neutralPrimary-30"
          style={{ fontSize: "36px", lineHeight: "36px" }}
        >
          {t("workTime")}
        </span>
        <span
          className="text-neutralPrimary-20 font-semibold"
          style={{ fontSize: "60px", lineHeight: 1 }}
        >
          {minutesToHHMM(totalWorkTime)}
        </span>
      </div>
    </CircleChart>
  );
};

export default Chart;
