import { AnyAction, combineReducers } from "redux";
import {
  OperativeDomainModel,
  SpecialistDomainModel,
} from "../../profile-domain";
import ActionTypes from "./actionTypes";
import { FolderModel } from "../models";

const folderReducer = (
  state: FolderModel | Record<string, unknown> = {},
  action: AnyAction,
): FolderModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_FOLDER_START:
      return state;
    case ActionTypes.FETCH_FOLDER_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_FOLDER_ERROR:
      return {};
    default:
      return state;
  }
};

const foldersListReducer = (
  state: FolderModel[] | [] = [],
  action: AnyAction,
): FolderModel[] | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_FOLDERS_START:
      return state;
    case ActionTypes.FETCH_FOLDERS_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_FOLDERS_ERROR:
    case ActionTypes.CLEAR_FOLDERS:
      return [];
    default:
      return state;
  }
};

const folderProposalProfilesReducer = (
  state:
    | OperativeDomainModel
    | SpecialistDomainModel
    | Record<string, unknown> = {},
  action: AnyAction,
): Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_FOLDER_PROPOSAL_PROFILE_START:
      return state;
    case ActionTypes.FETCH_FOLDER_PROPOSAL_PROFILE_SUCCESS:
      return { ...state, [action?.payload?.id]: action?.payload };
    case ActionTypes.FETCH_FOLDER_PROPOSAL_PROFILE_ERROR:
      return state;
    default:
      return {};
  }
};

export const isFetchingFolder = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_FOLDER_START:
      return true;
    case ActionTypes.FETCH_FOLDER_SUCCESS:
    case ActionTypes.FETCH_FOLDER_ERROR:
      return false;
    default:
      return state;
  }
};
export const isFetchingFoldersList = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_FOLDERS_START:
      return true;
    case ActionTypes.FETCH_FOLDERS_SUCCESS:
    case ActionTypes.FETCH_FOLDERS_ERROR:
      return false;
    default:
      return state;
  }
};

export const openedFolders = (
  state: string[] = [],
  action: AnyAction,
): string[] => {
  switch (action.type) {
    case ActionTypes.ADD_OPENED_FOLDERS_ID:
      return [...state, action.payload];
    case ActionTypes.REMOVE_OPENED_FOLDERS_ID:
      return state.filter((item) => item !== action.payload);
    case ActionTypes.RESET_OPENED_FOLDERS:
      return [];
    default:
      return state;
  }
};

export const isAddingCandidateToFolder = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.ADD_CANDIDATE_TO_FOLDER_START:
      return true;
    case ActionTypes.ADD_CANDIDATE_TO_FOLDER_SUCCESS:
    case ActionTypes.ADD_CANDIDATE_TO_FOLDER_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  folder: folderReducer,
  foldersList: foldersListReducer,
  folderProposalProfiles: folderProposalProfilesReducer,
  openedFolders,
});
