import { TagProps } from "../../components/search-select-picker/types";

export const filteredProposalTags = (
  proposalTags: TagProps[],
  selectedTags: TagProps[],
): TagProps[] =>
  proposalTags?.filter(
    (item: TagProps) =>
      !selectedTags?.find(
        ({ name: selectedName }: TagProps) => selectedName === item?.name,
      ) ||
      !selectedTags?.find(
        (selectedTag: TagProps | string) =>
          (typeof selectedTag === "string" &&
            selectedTag?.toLowerCase() === item?.name?.toLowerCase()) ||
          (typeof selectedTag !== "string" &&
            selectedTag?.name?.toLowerCase() === item?.name?.toLowerCase()),
      ),
  );

export const isCreateNewOptionVisible = (
  proposalTags: TagProps[],
  trimmedSearchValue: string,
  selectedTags: TagProps[],
): boolean =>
  !(
    proposalTags?.find(
      (tag: TagProps) =>
        tag.name.toLowerCase() === trimmedSearchValue.toLowerCase(),
    ) ||
    selectedTags?.find(
      (tag: TagProps) =>
        tag.name.toLowerCase() === trimmedSearchValue.toLowerCase(),
    )
  );
