enum ActionTypes {
  FETCH_FOLDER_START = "FETCH_FOLDER_START",
  FETCH_FOLDER_SUCCESS = "FETCH_FOLDER_SUCCESS",
  FETCH_FOLDER_ERROR = "FETCH_FOLDER_ERROR",

  FETCH_FOLDERS_START = "FETCH_FOLDERS_START",
  FETCH_FOLDERS_SUCCESS = "FETCH_FOLDERS_SUCCESS",
  FETCH_FOLDERS_ERROR = "FETCH_FOLDERS_ERROR",
  CLEAR_FOLDERS = "CLEAR_FOLDERS",

  FETCH_FOLDER_PROPOSAL_PROFILE_START = "FETCH_FOLDER_PROPOSAL_PROFILE_START",
  FETCH_FOLDER_PROPOSAL_PROFILE_SUCCESS = "FETCH_FOLDER_PROPOSAL_PROFILE_SUCCESS",
  FETCH_FOLDER_PROPOSAL_PROFILE_ERROR = "FETCH_FOLDER_PROPOSAL_PROFILE_ERROR",

  ADD_OPENED_FOLDERS_ID = "ADD_OPENED_FOLDERS_ID",
  REMOVE_OPENED_FOLDERS_ID = "REMOVE_OPENED_FOLDERS_ID",
  RESET_OPENED_FOLDERS = "RESET_OPENED_FOLDERS",

  ADD_CANDIDATE_TO_FOLDER_START = "ADD_CANDIDATE_TO_FOLDER_START",
  ADD_CANDIDATE_TO_FOLDER_SUCCESS = "ADD_CANDIDATE_TO_FOLDER_SUCCESS",
  ADD_CANDIDATE_TO_FOLDER_ERROR = "ADD_CANDIDATE_TO_FOLDER_ERROR",
}

export default ActionTypes;
