import { ISkillSkillsSearch, IUserSkillsSearch } from "../../../models/types";

export const MAX_NAME_LENGTH = 12;

export const sortByKnowledgeScale = (
  data: IUserSkillsSearch[],
  skillId: string,
  ascending: boolean,
): IUserSkillsSearch[] => {
  const sortOrder = ascending ? 1 : -1;

  const sortedData = data.sort((a: IUserSkillsSearch, b: IUserSkillsSearch) => {
    const skillA = a.skills.find(
      (skill: ISkillSkillsSearch) => skill.skillId === skillId,
    ) || {
      knowledgeScale: 0,
    };
    const skillB = b.skills.find(
      (skill: ISkillSkillsSearch) => skill.skillId === skillId,
    ) || {
      knowledgeScale: 0,
    };

    if (skillA.knowledgeScale < skillB.knowledgeScale) {
      return -1 * sortOrder;
    }
    if (skillA.knowledgeScale > skillB.knowledgeScale) {
      return 1 * sortOrder;
    }
    return 0;
  });

  return sortedData;
};

export const splitUsers = (
  users: IUserSkillsSearch[],
  selectedUsersId: string[],
): { selectedUsers: IUserSkillsSearch[]; restUsers: IUserSkillsSearch[] } => {
  const selectedUsers: IUserSkillsSearch[] = [];
  const restUsers: IUserSkillsSearch[] = [];
  users.forEach((user: IUserSkillsSearch) =>
    selectedUsersId.includes(user.userId)
      ? selectedUsers.push(user)
      : restUsers.push(user),
  );

  return { selectedUsers, restUsers };
};

export const displayName = (name?: string): string =>
  name && name.length <= MAX_NAME_LENGTH
    ? name
    : `${name?.substring(0, MAX_NAME_LENGTH)}...`;

export const getCellWidth = (name: string): string => {
  const techNameLength = name?.length;
  if (techNameLength > 30) return `230px`;
  if (techNameLength > 20 && name.split(" ")?.length > 3) return `190px`;
  return "auto";
};
