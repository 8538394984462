import { ReactElement } from "react";
import { Label, Tag, URLButton } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { DATE_FORMAT } from "../../../common/constants";
import { CertificatesCardProps } from "../types";
import { EXPERIENCE_ACCOMPLISHMENT_VIEW } from "../../../../../../shared/data-cy";
import {
  CERTIFICATES,
  EXPIRATION_DATE,
  ISSUED_DATE,
  TITLE,
  URL_PATH,
} from "../constants";
import { TECH_STACK } from "../../projects/constants";
import { DictionaryEntryModel } from "../../../../../../dictionaries";
import accomplishmentsGrid from "../../../common/styles";

const CertificatesCard = ({
  expirationDate,
  issuedDate,
  techStack,
  title,
  url,
  isDeleting,
}: CertificatesCardProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const BASE_PATH = `${EXPERIENCE_ACCOMPLISHMENT_VIEW}_${CERTIFICATES}`;

  const translate = (field: string) =>
    t(`profile.experience.cards.certificates.${field}`);
  return (
    <div className="relative w-full flex flex-col p-6">
      <div className={accomplishmentsGrid}>
        <Label text={translate(TITLE)} dataCy={`${BASE_PATH}_${TITLE}`}>
          {title}
        </Label>
        {url && (
          <Label
            text={translate(URL_PATH)}
            dataCy={`${BASE_PATH}_${URL_PATH}`}
            additionalClassName="gap-1"
          >
            <URLButton
              dataCy={`${BASE_PATH}_${URL_PATH}`}
              url={url}
              disabled={isDeleting}
            />
          </Label>
        )}
        <Label
          text={translate(ISSUED_DATE)}
          dataCy={`${BASE_PATH}_${ISSUED_DATE}`}
        >
          {issuedDate?.format(DATE_FORMAT)}
        </Label>
        {expirationDate && (
          <Label
            text={translate(EXPIRATION_DATE)}
            dataCy={`${BASE_PATH}_${EXPIRATION_DATE}`}
          >
            {expirationDate.format(DATE_FORMAT)}
          </Label>
        )}
      </div>
      <Label dataCy={`${BASE_PATH}_${TECH_STACK}`} text={translate(TECH_STACK)}>
        <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
          {techStack?.map((tech: DictionaryEntryModel) => (
            <Tag name={tech?.name} dataCy={tech?.id} key={tech?.id} />
          ))}
        </div>
      </Label>
    </div>
  );
};

export default CertificatesCard;
