import { ReactElement } from "react";
import {
  getNameFromDictionary,
  setSeniorityColor,
  languageCodeToCountryCode,
} from "@ps/utils";
import flag from "country-code-emoji";
import ISOLanguages from "iso-639-1";
import { uid } from "react-uid";
import { Empty2, Label, SkillBar, Tag } from "@ps/ui-components";
import OtherSummaryHeader from "./otherSummaryHeader";
import { InfoProps } from "../types";
import { ReactComponent as InfoIcon } from "../../../../images/profile-summary/info.svg";
import { ReactComponent as GoalsIcon } from "../../../../images/profile-summary/goals.svg";
import { useTranslationWithNamespace } from "../../../../hooks";
import { PROFILE_SUMMARY } from "../../../../shared/data-cy";
import { CURRENT_YEAR, languageLevelsDict } from "../../overview/constants";
import { GoalModel } from "../../../models/types";
import { DictionaryEntryModel } from "../experienceSection/types";

const BASE_PATH = "profile.summary";

const Info = ({
  profession,
  overallExperience,
  seniority,
  languages,
  goals,
  seniorityDict,
  onAddOrEditClick,
}: InfoProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const normalizedSeniority = (seniorty: string): string => {
    const seniorityName = getNameFromDictionary(seniorityDict, seniorty);
    return seniorityName.charAt(0).toUpperCase() + seniorityName.slice(1);
  };

  const getSelectedNumber = (item: string): number =>
    languageLevelsDict.reduce(
      (result, level, index) => (level === item ? index + 1 : result),
      1,
    );

  const goalsFilled = goals?.length > 0;
  const goal = goals?.find((g: GoalModel) => g.year === CURRENT_YEAR);

  const sectionEmpty =
    !profession?.length &&
    overallExperience?.years === 0 &&
    !seniority?.length &&
    !languages?.length;

  return (
    <div className="flex flex-col gap-y-10">
      <div className="flex flex-col gap-y-4">
        <OtherSummaryHeader
          title={t("profile.overview.personalInfo.info")}
          additionalTitle={t("profile.overview.personalInfo.personalInfo")}
          Icon={<InfoIcon className="text-neutralSecondary-41" />}
          withButton
          onButtonClick={onAddOrEditClick}
          filled={!sectionEmpty}
        />
        {!sectionEmpty ? (
          <>
            <Label dataCy={PROFILE_SUMMARY} text={t(`${BASE_PATH}.profession`)}>
              {profession}
            </Label>
            <Label dataCy={PROFILE_SUMMARY} text={t(`${BASE_PATH}.seniority`)}>
              <span className={`${setSeniorityColor(seniority)}`}>
                {seniority && normalizedSeniority(seniority)}
              </span>
            </Label>
            <Label dataCy={PROFILE_SUMMARY} text={t(`${BASE_PATH}.experience`)}>
              {`${
                overallExperience?.years
                  ? t(`${BASE_PATH}.totalExperience.years`, {
                      count: overallExperience.years,
                    })
                  : ""
              } ${
                overallExperience?.months
                  ? t(`${BASE_PATH}.totalExperience.months`, {
                      count: overallExperience?.months,
                    })
                  : ""
              } `}
            </Label>
            <Label dataCy={PROFILE_SUMMARY} text={t(`${BASE_PATH}.languages`)}>
              <div>
                {languages?.map((item) => (
                  <div className="flex gap-1 flex-col" key={uid(item)}>
                    <div className="flex gap-2 items-center">
                      {`${flag(
                        languageCodeToCountryCode(item.language),
                      )} ${ISOLanguages.getName(item.language)}`}

                      <SkillBar
                        dataCy={`${PROFILE_SUMMARY}_${item.language}`}
                        total={5}
                        value={getSelectedNumber(item.level)}
                        height="h-2"
                        width="w-20"
                      />
                    </div>
                    <span className="text-neutralSecondary-41 text-sm">
                      {t(`profile.overview.languages.${item.level}`)}
                    </span>
                  </div>
                ))}
              </div>
            </Label>
          </>
        ) : (
          <Empty2
            section={t("profile.overview.personalInfo.info")}
            onClick={onAddOrEditClick}
          />
        )}
      </div>
      {goalsFilled && (
        <div className="flex flex-col gap-y-4">
          <OtherSummaryHeader
            title={t("profile.overview.details.goalsCurrentYear", {
              year: CURRENT_YEAR,
            })}
            Icon={<GoalsIcon className="text-neutralSecondary-41" />}
          />
          <span className="text-neutralPrimary-20 text-sm">{goal?.note}</span>
          {goal?.techStack.length ? (
            <Label
              dataCy={PROFILE_SUMMARY}
              text={t("profile.overview.goalsCurrentYear.skills")}
              fontWeight="font-semibold"
              labelColor="text-neutralPrimary-20"
            >
              <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
                {goal.techStack.map((tech: DictionaryEntryModel) => (
                  <Tag
                    additionalClassName="bg-neutralSecondary-41 text-sm"
                    name={tech?.name}
                    dataCy={tech?.id}
                    key={tech?.id}
                  />
                ))}
              </div>
            </Label>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Info;
