import { ReactElement } from "react";
import { Button, SearchInput, Sorter } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { SearchBarProps } from "./types";
import {
  DISCOVERY_TEAM,
  SEARCH,
  SENIORITY,
  PROFESSION,
  ASC,
  DESC,
  MAIN,
  LOCATION,
  VALUE,
} from "../../../../constants";
import { ReactComponent as CloseIcon } from "../../../../../images/close.svg";

const TRANSLATE_PATH = "projects.prospect.proposalFolder";

const SearchBar = ({
  additionalSorterOptions = [],
  searchValue,
  setSearchValue,
  sorterParameters,
  onSorterClick,
  onClearFilters,
  clearFiltersDisabled,
  onCancel,
  dataCy,
}: SearchBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const names = [
    {
      name: MAIN,
      label: t(`${TRANSLATE_PATH}.${PROFESSION}`),
    },
    { name: SENIORITY, label: t(`${TRANSLATE_PATH}.${SENIORITY}`) },
    {
      name: VALUE,
      label: t(`${TRANSLATE_PATH}.${LOCATION}`),
      isNestedField: true,
    },
    ...additionalSorterOptions,
  ];

  const orders = [
    { order: ASC, label: t(`${TRANSLATE_PATH}.${ASC}`) },
    { order: DESC, label: t(`${TRANSLATE_PATH}.${DESC}`) },
  ];

  return (
    <div className="flex w-full justify-between gap-6 items-center">
      {!clearFiltersDisabled && (
        <Button
          variant="secondary"
          dataCy={DISCOVERY_TEAM}
          onClick={onClearFilters}
        >
          {t("actions.clearFilters")}
        </Button>
      )}
      <SearchInput
        dataCy={`${dataCy}_${SEARCH}`}
        value={searchValue}
        placeholder={t(`${TRANSLATE_PATH}.${SEARCH}`)}
        onChange={setSearchValue}
      />
      <Sorter
        names={names}
        orders={orders}
        activeSorter={sorterParameters}
        handleSorterClick={onSorterClick}
      />
      {onCancel && (
        <Button
          variant="secondary"
          dataCy="cancelAddingResumes"
          onClick={onCancel}
          additionalClass="flex items-center gap-2"
          width="w-34"
        >
          {t("actions.cancel")}
          <CloseIcon className="fill-current" />
        </Button>
      )}
    </div>
  );
};

export default SearchBar;
