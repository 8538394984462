import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { MORE_LINK } from "../../shared/data-cy";
import { ReactComponent as MoreIcon } from "../../images/more.svg";
import { focusVisibleStyles } from "../../shared";
import { MoreLinkProps } from "./types";

const baseStyling = classJoin.template`
p-1.5 w-max h-max text-primary-50 rounded-md
hover:bg-primary-95 active:text-primary-50
disabled:opacity-40 disabled:pointer-events-none
`;

const MoreLink = ({
  dataCy,
  additionalClassName = "",
  onClick,
  disabled = false,
}: MoreLinkProps): ReactElement => (
  <button
    className={classJoin(baseStyling, focusVisibleStyles, additionalClassName)}
    data-cy={`${MORE_LINK}-${dataCy}`}
    onClick={onClick}
    disabled={disabled}
  >
    <MoreIcon className="w-4 h-4" />
  </button>
);

export default MoreLink;
