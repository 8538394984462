import { ReactElement, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  TimeTrackerInput,
  TimeTrackerLabel,
  useThemeMode,
} from "@ps/ui-components";
import { classJoin, minutesToHHMM } from "@ps/utils";
import { ReactComponent as DeleteIcon } from "../../../../images/timesheet/timelog/delete-holiday.svg";
import countTotalRow from "../common/utils";
import { HolidayTimeEntryModel } from "../../../../models";
import { useTranslationWithNamespace } from "../../../../hooks";
import {
  HOLIDAY_TYPE,
  TIME_INPUT,
  TOTAL_TIME_COUNTED,
} from "../../../../shared/data-cy";
import { HolidayRowProps } from "./types";
import styles from "../styles.module.css";

const HolidayRow = ({
  chosenHoliday,
  data,
  dayAmount,
  onRemove,
  timeFrame,
}: HolidayRowProps): ReactElement => {
  const { isHighContrast } = useThemeMode();
  const { t } = useTranslationWithNamespace();
  useState(false);

  const holidayId: string = chosenHoliday.holidaysId;

  const holidayEntries = data[holidayId];
  const entries = holidayEntries && Object.entries(holidayEntries);

  const timeFrameAllDays: Dayjs[] = [...Array(dayAmount)].map(
    (_, index: number): Dayjs => timeFrame[0].add(index, "day"),
  );

  return (
    <div
      className={classJoin(
        styles.rowContainer,
        "bg-neutralPrimary-100 my-3 py-6",
        isHighContrast ? "border border-primary-60" : "border-none",
      )}
    >
      <div
        className="flex items-center justify-start gap-5 ml-16 col-span-3"
        style={{ minWidth: "20rem" }}
      >
        <span className="text-neutralPrimary-20">
          {t("timesheet.timelog.sections.holiday")}
        </span>
        <TimeTrackerLabel
          text={chosenHoliday.name}
          dataCy={HOLIDAY_TYPE}
          additionalClassName="break-all"
        />
      </div>
      <div className={styles.timeEntriesContainer}>
        {timeFrameAllDays.map((date: Dayjs): ReactElement => {
          const currentEntry = entries?.find((item) => {
            return dayjs(item[0]).isSame(date, "day") ? item[1] : undefined;
          });
          const sumTimeFromDay: number =
            currentEntry &&
            currentEntry[1].reduce(
              (acc: number, val: HolidayTimeEntryModel) => acc + val.minutes,
              0,
            );

          const isDisabled =
            !sumTimeFromDay || dayjs(currentEntry[0]).isBefore(dayjs());

          return (
            <TimeTrackerInput
              key={`${holidayId}-${date}`}
              dataCy={`${TIME_INPUT}-${holidayId}-${date}`}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              Icon={isDisabled ? undefined : DeleteIcon}
              disabled={isDisabled}
              isAdvancedView={!!sumTimeFromDay}
              onClick={() =>
                onRemove(isDisabled ? "" : currentEntry?.[1]?.[0]?.id)
              }
              value={sumTimeFromDay ? minutesToHHMM(sumTimeFromDay) : ""}
            />
          );
        })}
      </div>
      <TimeTrackerLabel
        text={minutesToHHMM(countTotalRow(entries))}
        dataCy={TOTAL_TIME_COUNTED}
        additionalClassName="self-center"
      />
    </div>
  );
};

export default HolidayRow;
