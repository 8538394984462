import { ReactElement, useState } from "react";
import dayjs from "dayjs";
import { classJoin } from "@ps/utils";
import { INVITING_MODAL } from "../../../../shared/data-cy";
import { ReactComponent as DeleteIcon } from "../../../../images/members/delete.svg";
import { ReactComponent as ErrorIcon } from "../../../../images/members/error.svg";
import { ReactComponent as EditIcon } from "../../../../images/members/edit.svg";
import { useTranslationWithNamespace } from "../../../../hooks";
import { ProgressBar } from "./index";
import ConfirmDeletingModal from "./confirmDeletingModal";
import { InvitationRowPros } from "../types";
import { Keys } from "../../../../shared";

const TRANSLATION_PATH = "members.inviting";

const InvitationRow = ({
  delay,
  email,
  error,
  file,
  group,
  isFileUploading,
  name,
  onEdit,
  profileType,
  removeItem,
  startDate,
  surname,
}: InvitationRowPros): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [isDeletingModalOpen, setIsDeletingModalOpen] = useState(false);

  const fullName = `${name} ${surname}`;
  const prepareStartDate =
    startDate &&
    dayjs().format("DD-MM-YYYY") === dayjs(startDate).format("DD-MM-YYYY")
      ? t(`${TRANSLATION_PATH}.startNow`)
      : t(`${TRANSLATION_PATH}.start`, {
          date: dayjs(startDate).format("DD.MM.YYYY"),
        });

  const stateStyles = {
    warning: "bg-warning-40 text-error-60",
    error: "bg-error-80",
    neutral: "bg-neutralPrimary-85 text-neutralPrimary-60",
  };

  const isWarning = (!fullName || !email) && !isFileUploading;

  const getProperStyles = (): string => {
    let result = "";
    if (error?.length) result = stateStyles.error;
    else if (isWarning) result = stateStyles.warning;
    else result = stateStyles.neutral;
    return result;
  };

  const fillEmail = email || t(`${TRANSLATION_PATH}.fillInEmail`);
  const fillName = fullName.trim() || t(`${TRANSLATION_PATH}.fillInName`);

  const renderCVContent = (): ReactElement => {
    if (error?.length)
      return (
        <span className="font-semibold text-error-30 flex gap-2 items-center overflow-ellipsis overflow-hidden truncate">
          <ErrorIcon className="fill-current h-5" />
          {t(`${TRANSLATION_PATH}.uploadFailed`, {
            reason: error?.[0],
          })}
        </span>
      );

    if (isFileUploading) return <ProgressBar delay={delay} />;
    if (file)
      return (
        <span
          className={classJoin(
            "truncate block w-72",
            !isWarning && "text-primary-60",
          )}
        >
          {file?.name}
        </span>
      );

    return (
      <span
        className={classJoin(
          "font-semibold w-fit",
          !isWarning && !isFileUploading && "text-neutralPrimary-50",
        )}
      >
        {t(`${TRANSLATION_PATH}.noCV`)}
      </span>
    );
  };

  return (
    <div
      data-cy={`${INVITING_MODAL}_invitationRow`}
      className={classJoin(
        "flex items-start justify-end p-5 gap-6 h-32",
        getProperStyles(),
      )}
    >
      {isDeletingModalOpen && (
        <ConfirmDeletingModal
          isOpen={isDeletingModalOpen}
          onClose={() => setIsDeletingModalOpen(false)}
          onConfirm={removeItem}
        />
      )}
      <div className="flex flex-col mr-auto w-full gap-1 truncate w-9/12">
        <span className="font-semibold truncate">{fillName}</span>
        <span
          className={classJoin(
            "truncate font-medium",
            isWarning && !isFileUploading && "text-error-80",
            !isWarning && !isFileUploading && "text-neutralPrimary-50",
          )}
        >
          {fillEmail}
        </span>
        {renderCVContent()}
      </div>
      <div className="flex flex-col w-2/12">
        <span className="font-semibold capitalize">{profileType?.label}</span>
        <span className={classJoin(!isWarning && "text-neutralPrimary-50")}>
          {prepareStartDate}
        </span>
        <span
          className={classJoin(
            !isWarning && "text-neutralPrimary-50",
            "truncate",
          )}
        >
          {group?.label}
        </span>
      </div>
      <div className="flex flex-col h-full justify-around w-1/12">
        <button
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onEdit();
          }}
        >
          <EditIcon className="fill-current text-primary-50 w-4 h-auto self-center cursor-pointer text-neutralPrimary-60" />
        </button>
        <button
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setIsDeletingModalOpen(true);
          }}
        >
          <DeleteIcon className="fill-current w-6 h-auto self-center cursor-pointer text-neutralPrimary-60" />
        </button>
      </div>
    </div>
  );
};

export default InvitationRow;
