import { getName } from "i18n-iso-countries";
import {
  ProfilesSummaryListModel,
  ProfilesSummaryListAPIModel,
} from "../types";
import { prepareResidenceToDisplay } from "../helpers";

const profilesSummaryListModel = (
  data: ProfilesSummaryListAPIModel[],
): ProfilesSummaryListModel[] =>
  data?.map((profile: ProfilesSummaryListAPIModel) => ({
    profile: profile.profile,
    userId: profile.userId || "",
    firstName: profile?.firstName || "",
    lastName: profile?.lastName || "",
    avatar: profile?.avatar || "",
    mainProfession: profile?.professions?.main || "",
    professions: profile?.professions?.secondary || [],
    seniority: profile?.seniority || "",
    residence: profile?.countryOfResidence
      ? prepareResidenceToDisplay(profile.countryOfResidence, getName)
      : { value: "", label: "" },
  }));

export default profilesSummaryListModel;
