import jwt from "jsonwebtoken";
import { isAPIError } from "@ps/api-utils";
import APIService from "../api/profile-api";
import {
  fromAPIModel,
  fromOcredProfileToModel,
  fromPreviewFulfillmentToModel,
  OperativeDomainModel,
  toAPIModel,
} from "../models";
import newProfileModel from "../models/converters/newProfileModel";
import { handleError } from "../../shared";
import { DictionaryModel } from "../../dictionaries";

const MyProfileService = (() => {
  const fetchProfile = async (
    technologyDict?: DictionaryModel,
    silentMode = false,
  ) => {
    try {
      const token = localStorage.getItem("token");
      const decodedToken = jwt.decode(token);
      const response = await APIService.API.getProfile(decodedToken?.sub, {
        silentMode,
      });

      if (isAPIError(response)) {
        console.log("1 response: ", response);
        return response;
      }
      if (response.data) {
        console.log("2 response: ", response);
        console.log(
          "2a response: ",
          fromAPIModel(response.data, technologyDict || []),
        );
        return fromAPIModel(response.data, technologyDict || []);
      }
      return {};
    } catch (error) {
      console.log("3 response: ", error);
      return handleError(error);
    }
  };

  const createProfile = async (profile: string) => {
    const result = await APIService.API.updateProfile(newProfileModel(profile));
    return result;
  };

  const updateProfile = async (newData: OperativeDomainModel) => {
    try {
      const result = await APIService.API.updateProfile(toAPIModel(newData));
      return result;
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchMyProfileSummaryFulfillment = async (
    userId: string,
    mainProfession: string,
  ) => {
    try {
      const response = await APIService.API.getProfilePreviewFulfillments(
        userId,
      );
      if (response.data) {
        return fromPreviewFulfillmentToModel(response.data, mainProfession);
      }
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const ocrProcessing = async (cv: File, technologyDict?: DictionaryModel) => {
    try {
      const response = await APIService.API.ocrProfile(cv, cv?.name);
      if (response?.data) {
        return fromOcredProfileToModel(response.data, technologyDict || []);
      }
      return response;
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchMyOCRData = async (technologyDict?: DictionaryModel) => {
    try {
      const response = await APIService.API.getProfileOcr();
      if (response?.data) {
        return fromOcredProfileToModel(response.data, technologyDict || []);
      }
      return response;
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    createProfile,
    fetchProfile,
    updateProfile,
    fetchMyProfileSummaryFulfillment,
    ocrProcessing,
    fetchMyOCRData,
  };
})();

export default MyProfileService;
