import { ChangeEvent, KeyboardEvent, ReactElement, memo } from "react";
import { classJoin } from "@ps/utils";
import { Keys } from "../../../shared";
import { useTranslationWithNamespace } from "../../../hooks";
import { FILTER_DROPDOWN } from "../../../shared/data-cy";
import { FilterDropdownProps } from "./types";
import styles from "../styles.module.css";
import Input from "../../input";

const FilterDropdown = ({
  children,
  DROPDOWN_ID,
  searchValue,
  setSearchValue,
  setShowFilterOptions,
  filterOptions,
  dataCy,
}: FilterDropdownProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <div
      className="p-4 flex flex-col gap-4 focus:outline-none"
      tabIndex={-1}
      id={DROPDOWN_ID}
    >
      <Input
        autoFocus
        isSearch
        dataCy={`${FILTER_DROPDOWN}-${dataCy}`}
        defaultValue={searchValue}
        onChange={(event: ChangeEvent<HTMLInputElement>): void =>
          setSearchValue(event.target.value?.toLowerCase())
        }
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>): void => {
          if (event.key === Keys.ESC) setShowFilterOptions(false);
        }}
      />
      {!filterOptions.length && (
        <span>{t(`sortBar.noResults`, { searchValue })}</span>
      )}
      <div
        className={classJoin(
          "flex flex-col gap-4 h-auto overflow-y-auto",
          styles.filterDropdownContent,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default memo(FilterDropdown);
