import dayjs from "dayjs";
import {
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  LAST_WEEK,
  THIS_MONTH,
  LAST_MONTH,
  THIS_YEAR,
  LAST_YEAR,
  TODAY_DATE,
  YESTERDAY_DATE,
  THIS_WEEK_START_DATE,
  THIS_WEEK_END_DATE,
  LAST_WEEK_START_DATE,
  LAST_WEEK_END_DATE,
  THIS_MONTH_START_DATE,
  THIS_MONTH_END_DATE,
  LAST_MONTH_START_DATE,
  LAST_MONTH_END_DATE,
  THIS_YEAR_START_DATE,
  THIS_YEAR_END_DATE,
  LAST_YEAR_START_DATE,
  LAST_YEAR_END_DATE,
} from "../constants";

const checkDate = (fromTime: Date, toTime: Date): string => {
  if (
    dayjs(fromTime).isSame(THIS_WEEK_START_DATE, "day") &&
    dayjs(toTime).isSame(THIS_WEEK_END_DATE, "day")
  )
    return THIS_WEEK;
  if (
    dayjs(fromTime).isSame(TODAY_DATE, "day") &&
    dayjs(toTime).isSame(TODAY_DATE, "day")
  )
    return TODAY;
  if (
    dayjs(fromTime).isSame(YESTERDAY_DATE, "day") &&
    dayjs(toTime).isSame(YESTERDAY_DATE, "day")
  )
    return YESTERDAY;
  if (
    dayjs(fromTime).isSame(LAST_WEEK_START_DATE, "day") &&
    dayjs(toTime).isSame(LAST_WEEK_END_DATE, "day")
  )
    return LAST_WEEK;
  if (
    dayjs(fromTime).isSame(THIS_MONTH_START_DATE, "day") &&
    dayjs(toTime).isSame(THIS_MONTH_END_DATE, "day")
  )
    return THIS_MONTH;
  if (
    dayjs(fromTime).isSame(LAST_MONTH_START_DATE, "day") &&
    dayjs(toTime).isSame(LAST_MONTH_END_DATE, "day")
  )
    return LAST_MONTH;
  if (
    dayjs(fromTime).isSame(THIS_YEAR_START_DATE, "day") &&
    dayjs(toTime).isSame(THIS_YEAR_END_DATE, "day")
  )
    return THIS_YEAR;
  if (
    dayjs(fromTime).isSame(LAST_YEAR_START_DATE, "day") &&
    dayjs(toTime).isSame(LAST_YEAR_END_DATE, "day")
  )
    return LAST_YEAR;
  return "";
};

export default checkDate;
