import React from "react";

const ExperienceContext = React.createContext({
  isEditMode: false,
  setEditMode: () => {
    // do nothing
  },
  cancelEditMode: () => {
    // do nothing
  },
  onSubmit: (data) => data,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fullTextSearch: (value: string, result?: number) => {
    // do nothing
  },
  deletingMode: "",
  setDeletingMode: (mode) => mode,
  setFocusElement: (el: string) => el,
  handleTabFocus: () => {
    // do nothing
  },
});

export default ExperienceContext;
