import {
  cloneElement,
  ReactElement,
  MouseEvent,
  KeyboardEvent,
  ChangeEvent,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import { classJoin } from "@ps/utils";
import { PanelProps } from "./types";
import { ReactComponent as ArrowDown } from "../../images/arrowDown.svg";
import { COLLAPSE_PANEL_PREFIX } from "../../shared/data-cy";
import { useThemeMode } from "../theme-mode-provider";
import Input from "../input";
import Card from "../card";
import { focusVisibleStyles, Keys } from "../../shared";

const getArrowRotation = classJoin.props<boolean>`
transform duration-200
${(selectOpened) => (selectOpened ? "rotate-180" : "")}
`;

const baseStyle = classJoin.template`
w-full border rounded-md flex flex-row items-center
justify-between p-3 my-1 mouse-pointer gap-4
`;

const Panel = ({
  canBeEdit = false,
  children,
  contentAfterArrow,
  contentBeforeArrow,
  dataCy,
  openedPanels,
  disableCardWrapper = false,
  disabled,
  icon,
  inputError,
  inputMessage,
  isPanelNameEdit = false,
  onOpenPanelClick,
  panelKey,
  title,
  haveFields,
  value,
  onChange,
  index,
}: PanelProps): ReactElement => {
  const { isLightTheme } = useThemeMode();

  const selectedStyle = {
    wrapper: "bg-primary-70 bg-opacity-20 border-primary-50 border-opacity-70",
    title: isLightTheme
      ? "font-semibold text-primary-50 opacity-70"
      : "font-semibold text-neutralPrimary-20",
    icon: {
      wrapper: "primary-50 bg-opacity-30",
      svg: "text-white",
    },
    arrow: isLightTheme
      ? "text-primary-50 opacity-70"
      : "text-neutralPrimary-20",
  };

  const notSelectedStyle = {
    wrapper: "bg-neutralPrimary-100 border-neutralSecondary-60",
    title: isLightTheme ? "text-neutralPrimary-20" : "text-neutralSecondary-41",
    icon: {
      wrapper: "neutralPrimary-85",
      svg: isLightTheme ? "text-primary-50" : "text-neutralSecondary-60",
    },
    arrow: isLightTheme
      ? "text-neutralPrimary-30 opacity-70"
      : "text-neutralSecondary-60",
  };

  const isPanelSelected = openedPanels?.includes(panelKey);

  const appliedStyle = isPanelSelected ? selectedStyle : notSelectedStyle;

  return (
    <div
      data-cy={`${COLLAPSE_PANEL_PREFIX}-${dataCy}-${panelKey}`}
      className={classJoin(
        disabled ? "opacity-50 cursor-default pointer-event-none" : "",
      )}
    >
      <div className={classJoin(baseStyle, appliedStyle.wrapper)}>
        <div
          role={disabled ? undefined : "button"}
          onClick={onOpenPanelClick}
          className={classJoin(
            "flex items-center w-full gap-2 justify-between rounded-md",
            focusVisibleStyles,
          )}
          tabIndex={disabled ? undefined : 0}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === Keys.ENTER && !disabled) onOpenPanelClick();
          }}
        >
          {isPanelNameEdit && typeof title === "string" ? (
            <div
              role="presentation"
              onClick={(event: MouseEvent<HTMLDivElement>) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <Input
                name={`groups.[${index}].name`}
                autoFocus
                dataCy={dataCy}
                defaultValue={value}
                error={inputError}
                message={inputMessage}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  onChange(event.target.value);
                }}
              />
            </div>
          ) : (
            <div className="flex flex-row items-center">
              <div className={classJoin(appliedStyle.icon.svg, "duration-100")}>
                {icon &&
                  cloneElement(icon, {
                    backgroundColor: classJoin(
                      appliedStyle.icon.wrapper,
                      "duration-200",
                    ),
                  })}
              </div>
              <span className={classJoin("ml-2", appliedStyle.title)}>
                {title}
              </span>
            </div>
          )}
          {contentBeforeArrow}
          {haveFields && (
            <ArrowDown
              className={classJoin(
                getArrowRotation(isPanelSelected),
                appliedStyle.arrow,
              )}
            />
          )}
        </div>
        <div
          onClick={(event: MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          role="presentation"
        >
          {contentAfterArrow}
        </div>
      </div>
      {canBeEdit ? (
        isPanelSelected && children
      ) : (
        <AnimatePresence>
          {isPanelSelected && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "max-content" }}
              exit={{ height: 0 }}
              style={{ overflow: "hidden" }}
              transition={{ ease: "easeOut", duration: 0.2, bounce: true }}
            >
              {disableCardWrapper ? (
                children
              ) : (
                <Card
                  dataCy={`${dataCy}-${panelKey}`}
                  additionalCardClassName="p-3"
                >
                  {children}
                </Card>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

export default Panel;
