import { removeDiacriticsFromString } from "@ps/utils";
import { ProfilesSummaryListModel } from "../../profile-domain";
import { IProfileReturn } from "./users-list/types";

export const profileConverter = (
  data: ProfilesSummaryListModel,
): IProfileReturn => ({
  ...data,
  name: `${data?.firstName} ${data?.lastName}` || "",
  profession: data?.mainProfession || data?.professions?.[0] || "",
  status: data?.profile || "",
  seniority: data?.seniority || "",
  location: data?.residence?.label || "",
});

export const filterTeamUsersBySearch = (
  data: IProfileReturn[],
  searchValue: string,
): IProfileReturn[] =>
  data?.filter(
    (baseProfile: IProfileReturn): boolean =>
      removeDiacriticsFromString(baseProfile.name.toLowerCase()).includes(
        removeDiacriticsFromString(searchValue.toLowerCase().trim()),
      ) ||
      removeDiacriticsFromString(
        baseProfile.name.split(" ").reverse().join(" ").toLowerCase(),
      ).includes(removeDiacriticsFromString(searchValue.toLowerCase().trim())),
  );

export const getFilterOptions = (
  users: IProfileReturn[],
  field: "profession" | "seniority" | "status" | "location",
): string[] => [
  ...new Set<string>(
    users
      ?.map((benchUser: IProfileReturn): string => benchUser?.[field] || "")
      .flat()
      .filter((value: string | undefined) => value),
  ),
];
