import { ReactElement } from "react";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import styles from "../skeletonStyle.module.css";
import { LoadingMatrixPageProps } from "../types";

const LoadingMatrixPage = ({
  rows,
  isLast,
}: LoadingMatrixPageProps): ReactElement => (
  <>
    <div
      className={classJoin(
        "border-b border-l border-r border-neutralSecondary-60 flex flex-col bg-neutralPrimary-100 overflow-hidden",
        isLast && "rounded-b-md",
      )}
    >
      {rows.map(({ technologyId, id }, index: number) => (
        <div
          className="flex flex-col h-12"
          key={uid(technologyId || id || index)}
        >
          <div
            className={classJoin(
              "text-neutralPrimary-20 h-12 flex justify-center items-center",
              index !== rows.length - 1 &&
                "border-b border-neutralSecondary-60",
            )}
            key={technologyId}
          >
            <div
              className={classJoin(
                styles.skeleton,
                "w-full h-10 mx-2 bg-neutralPrimary-100 rounded-md",
              )}
            />
          </div>
        </div>
      ))}
    </div>
  </>
);

export default LoadingMatrixPage;
