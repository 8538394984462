import { useEffect } from "react";
import { Keys } from "../../shared";

const useFocusEdit = (sectionTitle: string) => {
  useEffect(() => {
    if (!sectionTitle.length) return;
    let focusEl = document.getElementById(sectionTitle) as HTMLElement | null;
    if (!focusEl) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === Keys.ESC && focusEl) {
        focusEl.focus();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    focusEl.focus();

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      focusEl = null;
    };
  });
};

export default useFocusEdit;
