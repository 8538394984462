import { Children, ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { ButtonProps } from "../button/types";
import { BUTTONS_GROUP_PREFIX } from "../../shared/data-cy";
import { ButtonsGroupProps } from "./types";

const ButtonsGroup = ({
  dataCy,
  children,
  orientation = "row",
  borderRadius = "md",
  additionalClass = "",
}: ButtonsGroupProps): ReactElement => (
  <div
    data-cy={`${BUTTONS_GROUP_PREFIX}-${dataCy}`}
    className={classJoin(
      `flex-${orientation}`,
      "inline-flex shadow-sm",
      additionalClass,
    )}
    role="group"
  >
    {Children.map(
      children,
      (GroupedButton: ReactElement<ButtonProps>): ReactElement<ButtonProps> => {
        // This changes the styles of the buttons depending on orientation and where they are in the group
        const [radius, border] = (() => {
          if (orientation === "row") {
            const [tempRadius, tempBorder] = [
              `first:rounded-l-${borderRadius} last:rounded-r-${borderRadius}`,
              GroupedButton.props.variant === "primary" &&
              GroupedButton.props.border
                ? GroupedButton.props.border
                : "first:border-r first:border-l border-l-none border-r border-t border-b",
            ];

            return [tempRadius, tempBorder];
          }

          const [tempRadius, tempBorder] = [
            `first:rounded-t-${borderRadius} last:rounded-b-${borderRadius}`,
            GroupedButton.props.variant === "primary" &&
            GroupedButton.props.border
              ? GroupedButton.props.border
              : "first:border-b border-t border-b-none border-l border-r",
          ];

          return [tempRadius, tempBorder];
        })();

        const newProps: ButtonProps = {
          ...GroupedButton.props,
          borderRadius: radius,
          border,
          width: "w-full",
        };

        const editedButton = {
          ...GroupedButton,
          props: newProps,
        };

        return editedButton;
      },
    )}
  </div>
);

export default ButtonsGroup;
