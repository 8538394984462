import { useState, ReactElement, ChangeEvent } from "react";
import { useDispatch } from "redux-react-hook";
import { Controller, useFormContext } from "react-hook-form";
import { classJoin, prepareDictionarySearchSelect } from "@ps/utils";
import { Label, SearchSelect } from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import {
  filterArchivedUsers,
  mapUsersToValueLabel,
  inputBaseStyle,
  wrapperStyle,
  filterArchivedClients,
} from "../../../../../shared";
import { getClientData } from "../../../../../shared/utils/clients-utils";
import AddNewClientModal from "../../../../../client-domain/ui/addNewClientModal";
import { fetchClients } from "../../../../../client-domain";
import { ReactComponent as ErrorIcon } from "../../../../../images/error.svg";
import {
  CLIENT_NAME,
  CONTRACTORS_INFO,
  DETAILS,
  PROSPECT_EDIT,
  PROSPECT_NAME,
  BD,
  NOTE,
  PM,
  TECH_OWNER,
  DISABLED_ERROR,
  DOMAINS,
} from "../../../../constants";
import ControlledTextarea from "../../../common/controlledTextarea";
import { DetailsMapState, DetailsMapStateReturn } from "./types";
import BaseClientsInfo from "./baseClientsInfo";
import { UserDomainModel } from "../../../../../../index";

const TRANSLATE_PATH = "projects.prospect.details";
const BASE_PATH = `${PROSPECT_EDIT}_${DETAILS}_${CONTRACTORS_INFO}`;

const Details = (): ReactElement => {
  const { control } = useFormContext();
  const [isAddClientVisible, setIsAddClientVisible] = useState(false);
  const dispatch = useDispatch();

  const mapState = (state: DetailsMapState): DetailsMapStateReturn => ({
    clientsList: state?.clients?.clientsList || [],
    users: state.users || [],
    domainDict: state.dictionaries?.domain || [],
  });
  const { clientsList, users, domainDict } = useMappedStateSelector(mapState);

  const { t } = useTranslationWithNamespace();
  const translate = (field: string, isRequired = true) =>
    `${t(`projects.prospect.details.${field}`)}${isRequired ? "*" : ""}`;

  const mappedUsersValueLabel = mapUsersToValueLabel(
    filterArchivedUsers(users),
  );

  return (
    <>
      <section className="w-full h-full p-3 flex pb-16 relative">
        <div className="flex flex-col gap-y-8 w-9/12 pr-14">
          <div className="flex gap-y-6 w-full flex-col">
            <Label
              dataCy={`${BASE_PATH}_${PROSPECT_NAME}`}
              text={translate(PROSPECT_NAME)}
              labelFontWeight="font-bold"
              labelFontSize="text-lg"
              labelColor="text-neutralPrimary-20"
            >
              <Controller
                name={PROSPECT_NAME}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className={wrapperStyle}>
                    <input
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChange(event.target.value)
                      }
                      defaultValue={value}
                      className={classJoin(
                        "w-full",
                        inputBaseStyle,
                        error
                          ? "border-error-50 focus:border-error-50 placeholder-error-50"
                          : "border-neutralSecondary-60 placeholder-neutralSecondary-41 focus:border-secondary-50",
                      )}
                      autoFocus
                      placeholder={translate(PROSPECT_NAME)}
                    />
                    <span className="my-1 text-xs text-error-50">
                      {error?.message ?? ""}
                    </span>
                    <span className="absolute right-2 top-0 transform translate-y-1/3">
                      {error && (
                        <ErrorIcon className="text-error-50 fill-current" />
                      )}
                    </span>
                  </div>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("projects.errors.cantBeEmpty"),
                  },
                  validate: (value: string) => {
                    if (!value.trim().length)
                      return `${t("projects.errors.cantBeBlank")}`;
                    return true;
                  },
                }}
              />
            </Label>
            <ControlledTextarea
              name={NOTE}
              basePath={BASE_PATH}
              translatePath={TRANSLATE_PATH}
              maxLength={3000}
              placeholder={NOTE}
              resize
            />
          </div>
          <div className="flex gap-x-6">
            <Label
              text={t(`${TRANSLATE_PATH}.${PM}`)}
              dataCy={`${BASE_PATH}_${PM}`}
              labelFontWeight="font-bold"
              labelFontSize="text-lg"
              labelColor="text-neutralPrimary-20"
            >
              <Controller
                control={control}
                name={PM}
                rules={{
                  validate: (value) => {
                    if (value) {
                      const user = users.find(
                        (u: UserDomainModel) => u.id === value.userId,
                      );
                      if (!user?.enabled)
                        return translate(DISABLED_ERROR, false);
                    }
                    return true;
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelect
                    dataCy={PM}
                    width="w-full"
                    options={mappedUsersValueLabel}
                    defaultValue={mapUsersToValueLabel(users).find(
                      (user: {
                        userId: string;
                        value: string;
                        label: string;
                      }) => user.userId === value?.userId,
                    )}
                    clearable
                    onChange={onChange}
                    error={!!error}
                    message={error?.message}
                    placeholder={t(`${TRANSLATE_PATH}.${PM}`)}
                  />
                )}
              />
            </Label>
            <Label
              text={t(`${TRANSLATE_PATH}.${BD}`)}
              dataCy={`${BASE_PATH}_${BD}`}
              labelFontWeight="font-bold"
              labelFontSize="text-lg"
              labelColor="text-neutralPrimary-20"
            >
              <Controller
                control={control}
                name={BD}
                rules={{
                  validate: (value) => {
                    if (value) {
                      const user = users.find(
                        (u: UserDomainModel) => u.id === value.userId,
                      );
                      if (!user?.enabled)
                        return translate(DISABLED_ERROR, false);
                    }
                    return true;
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelect
                    dataCy={BD}
                    width="w-full"
                    options={mappedUsersValueLabel}
                    defaultValue={mapUsersToValueLabel(users).find(
                      (user) => user.userId === value?.userId,
                    )}
                    clearable
                    onChange={onChange}
                    error={!!error}
                    message={error?.message}
                    placeholder={t(`${TRANSLATE_PATH}.${BD}`)}
                  />
                )}
              />
            </Label>
            <Label
              text={t(`${TRANSLATE_PATH}.${TECH_OWNER}`)}
              dataCy={`${BASE_PATH}_${TECH_OWNER}`}
              labelFontWeight="font-bold"
              labelFontSize="text-lg"
              labelColor="text-neutralPrimary-20"
            >
              <Controller
                control={control}
                name={TECH_OWNER}
                rules={{
                  validate: (value) => {
                    if (value) {
                      const user = users?.find(
                        (u: UserDomainModel) => u.id === value,
                      );
                      if (user && !user.enabled)
                        return translate(DISABLED_ERROR, false);
                    }
                    return true;
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelect
                    dataCy={TECH_OWNER}
                    width="w-full"
                    options={mappedUsersValueLabel}
                    defaultValue={mapUsersToValueLabel(users).find(
                      (user) =>
                        user.userId === value?.userId || user.userId === value,
                    )}
                    clearable
                    onChange={onChange}
                    error={!!error}
                    message={error?.message}
                    placeholder={t(`${TRANSLATE_PATH}.${TECH_OWNER}`)}
                  />
                )}
              />
            </Label>
          </div>
          <Label
            text={`${t(`${TRANSLATE_PATH}.${DOMAINS}`)}*`}
            dataCy={`${BASE_PATH}_${DOMAINS}`}
          >
            <Controller
              control={control}
              name={DOMAINS}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SearchSelect
                  multiple
                  dataCy={`${BASE_PATH}_${DOMAINS}`}
                  options={prepareDictionarySearchSelect(
                    domainDict,
                    "id",
                    "id",
                    "name",
                    "name",
                  )}
                  width="w-full"
                  height="auto"
                  onChange={onChange}
                  defaultValue={
                    value && value.length && typeof value[0] === "string"
                      ? value.map((item) => ({
                          id: item,
                          value: item,
                          label: domainDict?.find(
                            (domain) => domain.id === item,
                          )?.name,
                        }))
                      : value
                  }
                  error={!!error}
                  message={error?.message}
                  placeholder={t(`${TRANSLATE_PATH}.${DOMAINS}`)}
                />
              )}
              rules={{
                validate: (value) =>
                  !value?.length
                    ? `${t("profile.overview.errors.cantBeEmpty")}`
                    : true,
              }}
            />
          </Label>
        </div>

        <div className="flex flex-col overflow-auto bg-neutralPrimary-100 w-3/12 right-8 p-9 -top-16 absolute rounded-2xl shadow gap-y-4">
          <Label
            dataCy={`${BASE_PATH}_${CLIENT_NAME}`}
            text={translate(CLIENT_NAME)}
            labelFontWeight="font-bold"
            labelFontSize="text-lg"
            labelColor="text-neutralPrimary-20"
          >
            <Controller
              name={CLIENT_NAME}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SearchSelect
                  width="w-full"
                  height="2.188rem"
                  dataCy={CLIENT_NAME}
                  onChange={onChange}
                  options={prepareDictionarySearchSelect(
                    filterArchivedClients(clientsList),
                    "id",
                    "id",
                    "clientName",
                    "clientName",
                  )}
                  defaultValue={getClientData(value, clientsList)}
                  placeholder={translate(CLIENT_NAME)}
                  error={!!error}
                  message={error?.message}
                  menuPosition="fixed"
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t("projects.errors.cantBeEmpty"),
                },
              }}
            />
          </Label>
          <BaseClientsInfo />
        </div>
      </section>
      <AddNewClientModal
        fetchClients={() => fetchClients(dispatch)}
        isOpen={isAddClientVisible}
        onClose={() => setIsAddClientVisible(false)}
      />
    </>
  );
};

export default Details;
