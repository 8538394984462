import {
  useEffect,
  useState,
  ReactElement,
  KeyboardEvent,
  ChangeEvent,
} from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { classJoin, sortArray } from "@ps/utils";
import {
  Button,
  hasPermissions,
  Input,
  Label,
  LinkButton,
  Modal,
  ModalContent,
  ModalHeader,
  Sorter,
  TableLabel,
} from "@ps/ui-components";
import {
  BENCH,
  FOLDER_NAME,
  NOTE,
  STATUS,
  ASC,
  DESC,
  FOLDERS_STATUSES,
} from "../constants";
import { FoldersModalMapState, FoldersModalProps } from "./types";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../hooks";
import { ReactComponent as FolderIcon } from "../../images/bench/folder.svg";
import { ReactComponent as EditNoteIcon } from "../../images/bench/edit-note.svg";
import { ReactComponent as ApproveIcon } from "../../images/bench/approved.svg";
import styles from "./styles.module.css";
import { BenchUserFolderModel } from "../models";
import { BenchService } from "../services";
import { fetchBenchUsers } from "../store";
import { Keys } from "../../shared";
import { setColorToStatus } from "../utils";
import {
  PROSPECT_FULL_ACCESS,
  PROSPECT_LIST_ALL,
  USERS_LIST_ALL,
} from "../../shared/permissions";

const FoldersModal = ({
  isOpen,
  onClose,
  name,
  folders,
  benchUserId,
}: FoldersModalProps) => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeSorter, setActiveSorter] = useState({
    name: FOLDER_NAME,
    order: ASC,
  });
  const [currentEditingFolderNote, setCurrentEditingFolderNote] = useState("");
  const [note, setNote] = useState("");
  const [sortedFolders, setSortedFolders] =
    useState<BenchUserFolderModel[]>(folders);

  const mapState = (state: FoldersModalMapState): FoldersModalMapState => ({
    permissions: state.permissions || [],
  });

  const { permissions } = useMappedStateSelector(mapState, "");

  const handleSorterClick = (valueName: string, order: string): void => {
    const bySort = sortArray(
      sortedFolders,
      valueName as keyof BenchUserFolderModel,
      order,
    );
    setSortedFolders(bySort);
    setActiveSorter({ name, order });
  };

  const handleUpdateNote = async (
    folderId: string,
    updatedNote: string,
  ): Promise<void> => {
    await BenchService.updateBenchUserFolderNote(
      benchUserId,
      folderId,
      updatedNote,
    );
    await fetchBenchUsers(dispatch);
  };

  const handleOnSaveNote = (folder: BenchUserFolderModel): void => {
    if (!currentEditingFolderNote)
      return setCurrentEditingFolderNote(folder.folderId);

    const trimmedNote = note.trim();
    if (trimmedNote !== folder.notes) handleUpdateNote(folder.folderId, note);
    setCurrentEditingFolderNote("");
  };

  const handleOnFolderClick = (id: string): void => {
    history.push(`/folders/${id}`);
    onClose();
  };

  useEffect(() => {
    setSortedFolders(folders);
  }, [folders]);

  const names = [
    {
      name: FOLDER_NAME,
      label: t(`${BENCH}.${FOLDERS_STATUSES}.${FOLDER_NAME}`),
    },
    {
      name: STATUS,
      label: t(`${BENCH}.${FOLDERS_STATUSES}.${STATUS}`),
    },
  ];

  const orders = [
    { order: ASC, label: t(`${BENCH}.${ASC}`) },
    { order: DESC, label: t(`${BENCH}.${DESC}`) },
  ];

  return (
    <Modal
      dataCy={`${BENCH}.modal`}
      isOpen={isOpen}
      onClose={onClose}
      withCloseIcon
      width="w-8/12"
      height="h-fit"
    >
      <ModalHeader
        additionalClassName="relative mx-6 justify-between"
        Icon={<FolderIcon />}
        title={name}
        fontSize="text-lg"
      >
        <Sorter
          activeSorter={activeSorter}
          handleSorterClick={handleSorterClick}
          names={names}
          orders={orders}
        />
      </ModalHeader>
      <ModalContent className="relative text-neutralPrimary-20">
        <div className="shadow-lg m-6 rounded-xl">
          <div
            className={classJoin(
              "w-full bg-neutralSecondary-41 bg-opacity-10 pl-10 rounded-t-2xl h-16 items-center px-6",
              styles.foldersStatusesTableColumns,
            )}
          >
            <TableLabel dataCy={`${BENCH}_${FOLDER_NAME}`}>
              {t(`${BENCH}.${FOLDERS_STATUSES}.${FOLDER_NAME}`)}
            </TableLabel>
            <TableLabel dataCy={`${BENCH}_${STATUS}`}>
              {t(`${BENCH}.${FOLDERS_STATUSES}.${STATUS}`)}
            </TableLabel>
            <TableLabel dataCy={`${BENCH}_${NOTE}`}>
              {t(`${BENCH}.${FOLDERS_STATUSES}.${NOTE}`)}
            </TableLabel>
          </div>
          <section className="flex flex-col gap-y-0 max-h-96 overflow-y-auto w-full">
            {sortedFolders.map(
              (folder: BenchUserFolderModel, index: number): ReactElement => {
                const folderId: string = folder.folderId;

                return (
                  <div
                    key={folderId}
                    className={classJoin(
                      "gap-2 py-4 pl-4 border-b-2 border-neutralSecondary-41 border-opacity-10",
                      styles.foldersStatusesTableColumns,
                    )}
                  >
                    <Label dataCy={FOLDER_NAME}>
                      <div className="flex items-start gap-5">
                        <span>{index + 1}</span>
                        <LinkButton
                          dataCy={FOLDER_NAME}
                          additionalClassName="hover:underline  hover:text-primary-50 text-left"
                          onClick={() => handleOnFolderClick(folderId)}
                          whiteSpaceNoWrap={false}
                          padding=""
                          disabled={
                            !(
                              hasPermissions(permissions, USERS_LIST_ALL) &&
                              hasPermissions(permissions, [
                                PROSPECT_FULL_ACCESS,
                              ])
                            )
                          }
                        >
                          {folder.folderName}
                        </LinkButton>
                      </div>
                    </Label>
                    <Label dataCy={STATUS}>
                      <span className={setColorToStatus(folder.status)}>
                        {t(`${BENCH}.${FOLDERS_STATUSES}.${folder.status}`)}
                      </span>
                    </Label>
                    {currentEditingFolderNote === folderId ? (
                      <Input
                        dataCy={NOTE}
                        defaultValue={folder.notes}
                        additionalContainerClass="-ml-5 pr-5"
                        onChange={(
                          event: ChangeEvent<HTMLInputElement>,
                        ): void => setNote(event.target.value)}
                        // eslint-disable-next-line
                        // @ts-ignore
                        onKeyDown={(
                          event: KeyboardEvent<HTMLInputElement>,
                        ): void => {
                          if (event.key === Keys.ENTER)
                            handleOnSaveNote(folder);
                        }}
                      />
                    ) : (
                      <Label
                        fontWeight="font-normal"
                        additionalClassName="-ml-4"
                        dataCy={NOTE}
                      >
                        {folder?.notes || "-"}
                      </Label>
                    )}
                    <Button
                      border="none"
                      dataCy={`${BENCH}_${NOTE}`}
                      variant="secondary"
                      width="w-9"
                      height="h-8.5"
                      disabled={
                        !!currentEditingFolderNote &&
                        currentEditingFolderNote !== folderId
                      }
                      paddingX="px-2"
                      onClick={(): void => {
                        setNote(folder.notes || "");
                        handleOnSaveNote(folder);
                      }}
                    >
                      {currentEditingFolderNote !== folderId ? (
                        <EditNoteIcon className="w-4 h-4" />
                      ) : (
                        <ApproveIcon />
                      )}
                    </Button>
                  </div>
                );
              },
            )}
          </section>
          <div className="rounded-b-2xl h-16 bg-neutralSecondary-41 bg-opacity-10" />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default FoldersModal;
