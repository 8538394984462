import { ReactElement, useEffect, useRef, useState } from "react";
import { Card } from "@ps/ui-components";
import { useDispatch } from "redux-react-hook";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import styles from "../styles.module.css";
import { fetchDaysOff } from "../../../store/actions/myHolidaysActions";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { DaysOffState, DaysOffStateReturn } from "../types";
import EmptyColumn from "./emptyColumn";
import SingleCell from "./singleCell";
import { rowStyles } from "./constants";
import { DaysOffModel } from "../../../models/holidaysTypes";

const BASE_TRANSLATION_PATH = "holidays";

const DaysOff = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslationWithNamespace();
  const colsContainerNode = useRef(null);
  const [isScrollVisible, setIsScrollVisible] = useState(false);
  const mapState = (state: DaysOffState): DaysOffStateReturn => ({
    daysOff: state?.holidays?.daysOff || [],
  });

  const { daysOff } = useMappedStateSelector(mapState);

  useEffect(() => {
    fetchDaysOff(dispatch);
  }, []);

  useEffect(() => {
    if (!isScrollVisible && daysOff.length)
      setIsScrollVisible(
        colsContainerNode?.current?.scrollWidth >
          colsContainerNode?.current?.clientWidth,
      );
  }, [colsContainerNode?.current?.scrollWidth, daysOff]);

  return (
    <Card dataCy="test" additionalCardClassName="flex items-center">
      <table
        className={classJoin(
          styles.tableGrid,
          "table-fixed grid text-lg font-semibold w-full",
        )}
      >
        <thead>
          <tr className="grid grid-cols-1 divide-y items-start w-max pl-4">
            <th className={rowStyles} />
            <th
              className={classJoin(
                rowStyles,
                "ml-auto px-2 px-4 flex border-primary-70",
              )}
            >
              {t(`${BASE_TRANSLATION_PATH}.alreadyUsedDaysOff`)}
            </th>
            <th className={classJoin(rowStyles, "border-primary-70")}>
              {t(`${BASE_TRANSLATION_PATH}.unusedDaysOff`)}
            </th>
            <th className={classJoin(rowStyles, "border-primary-70")}>
              {t(`${BASE_TRANSLATION_PATH}.sum`)}
            </th>
          </tr>
        </thead>
        <tbody
          className="overflow-x-auto flex last:pr-4"
          ref={colsContainerNode}
        >
          {daysOff.map((item: DaysOffModel) => (
            <SingleCell data={item} key={uid(item)} />
          ))}
          {isScrollVisible ? <></> : <EmptyColumn />}
        </tbody>
      </table>
    </Card>
  );
};

export default DaysOff;
