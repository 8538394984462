import dayjs, { Dayjs } from "dayjs";
import { ReactElement } from "react";
import { uid } from "react-uid";
import { Empty, Label } from "@ps/ui-components";
import Row from "./row";
import { DocumentsListProps } from "../types";
import { AVAILABILITY, EXPIRATION_DATE } from "../../constants";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { DATE_FORMAT } from "../../../../../shared/constants";

const DocumentsList = ({
  items,
  field,
  basePath,
  translationPath,
}: DocumentsListProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const isDocumentExpired = (date: Dayjs) => date && dayjs().isAfter(date);

  return (
    <>
      {items && items.length ? (
        items.map((item) => (
          <Row
            key={`${basePath}_${field}_${uid(item)}`}
            dataCy={`${basePath}_${field}`}
          >
            <Label
              key={`${field}_country`}
              text={t(`${translationPath}.${AVAILABILITY}.${field}`)}
              dataCy={`${basePath}_${field}`}
            >
              {typeof item?.country !== "string" && item?.country?.label ? (
                item.country.label
              ) : (
                <Empty />
              )}
            </Label>
            <Label
              key={`${field}_${EXPIRATION_DATE}`}
              text={t(`${translationPath}.${AVAILABILITY}.${EXPIRATION_DATE}`)}
              dataCy={`${basePath}_${field}_${EXPIRATION_DATE}`}
              additionalClassName="whitespace-nowrap"
            >
              {item?.expirationDate ? (
                <span
                  className={
                    isDocumentExpired(item.expirationDate)
                      ? "text-error-50"
                      : ""
                  }
                >
                  {dayjs(item.expirationDate).format(DATE_FORMAT)}
                </span>
              ) : (
                <Empty />
              )}
            </Label>
          </Row>
        ))
      ) : (
        <Row>
          <Label
            text={t(`${translationPath}.${AVAILABILITY}.${field}`)}
            dataCy={`${basePath}_${field}`}
          >
            <Empty />
          </Label>
          <Label
            text={t(`${translationPath}.${AVAILABILITY}.${EXPIRATION_DATE}`)}
            dataCy={`${basePath}_${field}_${EXPIRATION_DATE}`}
          >
            <Empty />
          </Label>
        </Row>
      )}
    </>
  );
};

export default DocumentsList;
