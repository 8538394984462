import { BenchUsersModel, BenchUserModel } from "../models";

const getFilterOptions = (
  benchUsers: BenchUsersModel,
  field: "profession" | "seniority" | "name" | "location" | "roles" | "reason",
): string[] => [
  ...new Set<string>(
    benchUsers
      ?.map(
        (benchUser: BenchUserModel): string | string[] =>
          benchUser?.[field] || "",
      )
      .flat(2)
      .filter((value: string | undefined) => value),
  ),
];

export default getFilterOptions;
