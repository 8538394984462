import RCSlider from "rc-slider";
import "rc-slider/assets/index.css";
import { ReactElement } from "react";
import { SLIDER_PREFIX } from "../../shared/data-cy";
import { SliderProps } from "./types";

const Slider = ({
  min = 0,
  max = 100,
  step = 1,
  defaultValue,
  value,
  onChange,
  onAfterChange,
  dataCy,
}: SliderProps): ReactElement => {
  const sliderStyling = {
    railStyle: { backgroundColor: "rgb(var(--color-neutral-secondary-60))" },
    trackStyle: { backgroundColor: "rgb(var(--color-primary-50))" },
    handleStyle: {
      borderColor: "rgb(var(--color-primary-50))",
      backgroundColor: "rgb(var(--color-primary-50))",
      boxShadow: "none",
    },
  };
  const sliderProps = {
    min,
    max,
    step,
    defaultValue,
    onChange,
    onAfterChange,
  };
  const component = value ? (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <RCSlider value={value} {...sliderProps} {...sliderStyling} />
  ) : (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <RCSlider {...sliderProps} {...sliderStyling} />
  );
  return <div data-cy={`${SLIDER_PREFIX}-${dataCy}`}>{component}</div>;
};

export default Slider;
