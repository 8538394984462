import { ReactElement } from "react";
import { classJoin, getNameFromDictionary } from "@ps/utils";
import { PROFILE_SUMMARY_SKILLS } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import { CardHeader } from "../../../expanded-card";
import SkillBar from "../../../skill-bar";
import Label from "../../../label";
import { renderSectionIcon } from "../../helpers";
import { DomainsProps } from "./types";
import { DOMAINS } from "../constants";

const Domains = ({
  additionalClassName,
  domains,
  domainsDict,
  maxScale,
  renderIcon,
  hideLabel,
}: DomainsProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <div
      className={classJoin(
        "pb-6 flex flex-col gap-4 justify-center sm:justify-between",
        additionalClassName,
      )}
    >
      {hideLabel ? null : (
        <CardHeader
          dataCy={`${PROFILE_SUMMARY_SKILLS}${DOMAINS}`}
          Icon={renderIcon(DOMAINS)}
          iconColor="text-neutralSecondary-60"
          svgCurrent="stroke"
        >
          <span className="flex flex-col gap-2">
            {t("profileSummary.domains")}
          </span>
        </CardHeader>
      )}
      <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-y-8 gap-x-10 sm:gap-x-24">
        {domains?.map((domain) => (
          <div key={domain.technologyId} className="w-36 sm:w-48">
            <Label
              dataCy={domain.technologyId}
              additionalClassName="font-normal"
              labelSiblings={
                domain.experienceOccurrences?.length ? (
                  domain.experienceOccurrences.map((item: string) => {
                    const Icon = renderSectionIcon(item);
                    return (
                      <Icon
                        key={item}
                        className="fill-current text-primary-50 h-4 w-auto"
                      />
                    );
                  })
                ) : (
                  <></>
                )
              }
              text={getNameFromDictionary(domainsDict, domain.technologyId)}
            >
              <SkillBar
                value={domain.score}
                total={maxScale}
                dataCy={domain.technologyId}
                color={domain.color}
                withLegend
              />
            </Label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Domains;
