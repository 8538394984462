import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { InvitingLabelProps } from "../types";

const InvitingLabel = ({
  text,
  children,
  width = "w-full",
  padding = "px-8",
  isRequired = false,
}: InvitingLabelProps): ReactElement => (
  <div className={classJoin(width, padding, "flex flex-col font-semibold")}>
    <span>{`${text}${isRequired ? "*" : ""}`}</span>
    {children}
  </div>
);

export default InvitingLabel;
