import { useTranslationWithNamespace } from "../../hooks";
import { ReactComponent as AddSkillIcon } from "../../images/add-skill.svg";
import Button from "../../components-2/button";
import { SearchSelectPickerButtonProps } from "./types";
import { ReactElement } from "react";

const SearchSelectPickerButton = ({
  dataCy,
  maxLength,
  onNewTagClick,
  trimmedSearchValue,
}: SearchSelectPickerButtonProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return trimmedSearchValue?.length <= maxLength ? (
    <button
      data-cy={`${dataCy}_addNewTech`}
      className="rounded-lg px-2 py-2
                  flex
                  items-center
                  mb-3
                  ml-2
                  mr-2
                  w-inherit
                  bg-other-toxicGreen
                  text-neutralPrimary-20 cursor-pointer"
      onClick={() => onNewTagClick(trimmedSearchValue)}
      role="presentation"
    >
      <AddSkillIcon className="min-w-6" />
      <span className="text-sm-base w-max">
        {t("searchSelectPicker.createNewSkill")}
        &ldquo;<strong>{trimmedSearchValue}</strong>&ldquo;
      </span>
    </button>
  ) : (
    <Button
      dataCy={`${dataCy}_toLongTechName`}
      height="h-16"
      width="w-inherit"
      paddingX="px-0"
      disabled
      variant="primary"
      additionalClass="m-2"
    >
      {t("searchSelectPicker.cantBeCreated", { maxLength })}
    </Button>
  );
};

export default SearchSelectPickerButton;
