import dayjs from "dayjs";

export const safeToLowerCase = <T>(value: T): T | string => {
  return typeof value === "string" ? value.toLowerCase().trim() : value;
};

const compareValues = <T>(valueA: T, valueB: T): number => {
  if (typeof valueA === "number" && typeof valueB === "number") {
    return valueA - valueB;
  }

  if (dayjs.isDayjs(valueA) && dayjs.isDayjs(valueB)) {
    if (valueA.isAfter(valueB)) {
      return 1;
    }
    if (valueA.isBefore(valueB)) {
      return -1;
    }
    return 0;
  }

  const stringA = String(valueA).toLowerCase();
  const stringB = String(valueB).toLowerCase();
  return stringA.localeCompare(stringB);
};
export const sortArray = <T>(
  array: T[],
  fieldName: keyof T,
  order: string,
  nestedFieldName?: keyof NonNullable<T>,
): T[] => {
  const onlyItemsFulfilled = nestedFieldName
    ? array?.filter(
        (item: T) =>
          item?.[nestedFieldName]?.[fieldName] ||
          typeof item?.[nestedFieldName]?.[fieldName] === "number" ||
          dayjs.isDayjs(item?.[nestedFieldName]?.[fieldName]),
      )
    : array?.filter(
        (item: T) =>
          item?.[fieldName] ||
          typeof item?.[fieldName] === "number" ||
          dayjs.isDayjs(item?.[fieldName]),
      );

  const restOfArray = nestedFieldName
    ? array?.filter(
        (item: T) =>
          !item?.[nestedFieldName]?.[fieldName] &&
          typeof item?.[nestedFieldName]?.[fieldName] !== "number" &&
          !dayjs.isDayjs(item?.[nestedFieldName]?.[fieldName]),
      )
    : array?.filter(
        (item: T) =>
          !item?.[fieldName] &&
          typeof item?.[fieldName] !== "number" &&
          !dayjs.isDayjs(item?.[fieldName]),
      );

  if (order === "desc") {
    const sortedItems = nestedFieldName
      ? onlyItemsFulfilled.sort((a: T, b: T) =>
          compareValues(
            b?.[nestedFieldName]?.[fieldName],
            a?.[nestedFieldName]?.[fieldName],
          ),
        )
      : onlyItemsFulfilled.sort((a: T, b: T) =>
          compareValues(b[fieldName], a[fieldName]),
        );
    return [...sortedItems, ...restOfArray];
  }

  const sortedItems = nestedFieldName
    ? onlyItemsFulfilled.sort((a: T, b: T) =>
        compareValues(
          a?.[nestedFieldName]?.[fieldName],
          b?.[nestedFieldName]?.[fieldName],
        ),
      )
    : onlyItemsFulfilled.sort((a: T, b: T) =>
        compareValues(a[fieldName], b[fieldName]),
      );
  return [...sortedItems, ...restOfArray];
};

export const sortObjectArrayWithLocalCompare = <T>(
  array: T[],
  fieldName: keyof T,
  order: "asc" | "desc" = "asc",
  nestedFieldName?: keyof NonNullable<T>,
): T[] => {
  const onlyItemsFulfilled = nestedFieldName
    ? array.filter((item: T) => item?.[nestedFieldName]?.[fieldName])
    : array.filter((item: T) => item?.[fieldName]);
  const restOfArray = nestedFieldName
    ? array.filter((item: T) => !item?.[nestedFieldName]?.[fieldName])
    : array.filter((item: T) => !item?.[fieldName]);
  if (order === "desc") {
    const sortedItems = nestedFieldName
      ? onlyItemsFulfilled.sort((a: T, b: T) =>
          compareValues(
            b?.[nestedFieldName]?.[fieldName],
            a?.[nestedFieldName]?.[fieldName],
          ),
        )
      : onlyItemsFulfilled.sort((a: T, b: T) =>
          compareValues(b[fieldName], a[fieldName]),
        );
    return [...sortedItems, ...restOfArray];
  }

  const sortedItems = nestedFieldName
    ? onlyItemsFulfilled.sort((a: T, b: T) =>
        compareValues(
          a?.[nestedFieldName]?.[fieldName],
          b?.[nestedFieldName]?.[fieldName],
        ),
      )
    : onlyItemsFulfilled.sort((a: T, b: T) =>
        compareValues(a[fieldName], b[fieldName]),
      );
  return [...sortedItems, ...restOfArray];
};
