import { ReactElement, useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { classJoin } from "@ps/utils";
import Button from "../button";
import Label from "../label";
import { ModalFooter } from "../modal";
import { Slider } from "../slider";
import getCroppedImage, { PixelCrop } from "./helpers/getCroppedImage";
import { ReactComponent as Spinner } from "../../images/spinner.svg";
import { ImageEditorProps } from "./types";
import {
  IMAGE_UPLOADER_BUTTON_CANCEL_PREFIX,
  IMAGE_UPLOADER_BUTTON_SAVE_PREFIX,
  IMAGE_UPLOADER_EDITOR_PREFIX,
  IMAGE_UPLOADER_SLIDER_ZOOM_PREFIX,
} from "../../shared/data-cy";
import { useTranslationWithNamespace } from "../../hooks";

const ImageEditor = ({
  image,
  handleCroppedImage,
  onError,
  onCancel,
  dataCy,
  uploadProgressVal,
}: ImageEditorProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [compressionProgressVal, setCompressionProgressVal] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<PixelCrop>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const onCropComplete = useCallback((_, croppedAreaPixelsNew) => {
    setCroppedAreaPixels(croppedAreaPixelsNew);
  }, []);

  const onSave = () => {
    setIsProcessing(true);
    getCroppedImage(image, croppedAreaPixels, setCompressionProgressVal)
      .then(handleCroppedImage)
      .catch(onError);
  };

  const total = Math.round((uploadProgressVal + compressionProgressVal) / 2);

  return (
    <div
      className="flex flex-col h-full w-full mt-3"
      data-cy={`${IMAGE_UPLOADER_EDITOR_PREFIX}-${dataCy}`}
    >
      <div className="relative w-full h-5/6 rounded-md overflow-hidden p-3">
        {isProcessing && total > 0 && (
          <div
            className={classJoin.template`
            absolute flex flex-col items-center justify-center top-0
            left-0 w-full h-full bg-neutralPrimary-85 z-10 bg-opacity-80 p-5
            `}
          >
            <Spinner />
            <span className="text-lg mt-1">{`${total}%`}</span>
          </div>
        )}
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          showGrid={false}
        />
      </div>
      <div className="relative w-full h-min my-5">
        <Label
          dataCy={`${IMAGE_UPLOADER_SLIDER_ZOOM_PREFIX}-${dataCy}`}
          text={t("imageUploader.zoom")}
        >
          <Slider
            dataCy={`${IMAGE_UPLOADER_SLIDER_ZOOM_PREFIX}-${dataCy}`}
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={setZoom}
          />
        </Label>
      </div>
      <div className="flex flex-row mb-4 justify-end">
        <ModalFooter>
          <Button
            dataCy={`${IMAGE_UPLOADER_BUTTON_CANCEL_PREFIX}-${dataCy}`}
            onClick={onCancel}
            variant="secondary"
            disabled={isProcessing}
          >
            {t("buttons.cancel")}
          </Button>
          <Button
            onClick={onSave}
            dataCy={`${IMAGE_UPLOADER_BUTTON_SAVE_PREFIX}-${dataCy}`}
            disabled={isProcessing}
          >
            {t("buttons.save")}
          </Button>
        </ModalFooter>
      </div>
    </div>
  );
};

export default ImageEditor;
