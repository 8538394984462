import { ChangeEvent, ReactElement, useState } from "react";
import imageCompression from "browser-image-compression";
import Button from "../button";
import Modal, { ModalFooter, ModalHeader } from "../modal";
import Uploader from "../uploader";
import readFile from "./helpers/readFile";
import ImageEditor from "./imageEditor";
import { ImageUploaderProps } from "./types";
import {
  IMAGE_UPLOADER_BUTTON_CANCEL_PREFIX,
  IMAGE_UPLOADER_BUTTON_PREFIX,
  IMAGE_UPLOADER_MODAL_PREFIX,
} from "../../shared/data-cy";
import { useDropDiv, useTranslationWithNamespace } from "../../hooks";
import { ReactComponent as UploadButtonIcon } from "../../images/upload-image-button-icon.svg";

const ImageUploader = ({
  dataCy,
  disabled,
  getImage,
  maxSize = 8,
  setUploadProgressVal,
  uploaded,
  uploadProgressVal,
}: ImageUploaderProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [showModal, setShowModal] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<string>();
  const [isError, setIsError] = useState(false);

  const setError = () => setIsError(true);
  const clearError = () => setIsError(false);

  const checkFileSize = (fileSize: number) => {
    if (maxSize && fileSize > maxSize * 1000000) {
      setError();
      return true;
    }
    clearError();
    return false;
  };

  const handleImageSelection = (files: FileList) => {
    const image = files?.[0];
    const hasSizeError = checkFileSize(image?.size);
    if (image && !hasSizeError)
      readFile(image).then(setSelectedImageSrc).catch(setError);
  };

  const { dropDivRef, isDraggingOver } = useDropDiv({
    onFilesSelected: (files: FileList): void => {
      handleImageSelection(files);
    },
  });

  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setSelectedImageSrc(undefined);
    setShowModal(false);
    clearError();
    if (setUploadProgressVal) {
      setUploadProgressVal(0);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) handleImageSelection?.(files);
  };

  const onSave = (file: File) => {
    imageCompression
      .getDataUrlFromFile(file)
      .then(getImage)
      .then(closeModal)
      .catch(setError);
  };

  const modes = {
    select: {
      width: "w-min",
      height: "h-auto",
      title: t("imageUploader.chooseImage"),
      component: (
        <Uploader
          ref={dropDivRef}
          isDraggingOver={isDraggingOver}
          accept="image/*"
          dataCy={dataCy}
          error={isError}
          maxSizeMB={maxSize}
          message={t("uploader.errorMaximumWeight", { fileSize: maxSize })}
          handleInputChange={handleInputChange}
        />
      ),
      footer: (
        <div className="flex flex-row justify-end w-full">
          <ModalFooter additionalClassName="mt-6">
            <Button
              dataCy={`${IMAGE_UPLOADER_BUTTON_CANCEL_PREFIX}-${dataCy}`}
              onClick={closeModal}
              variant="secondary"
            >
              {t("buttons.cancel")}
            </Button>
          </ModalFooter>
        </div>
      ),
    },
    edit: {
      width: "w-5/6",
      height: "h-5/6",
      title: t("imageUploader.editImage"),
      component: (
        <ImageEditor
          image={selectedImageSrc ?? ""}
          handleCroppedImage={onSave}
          onCancel={closeModal}
          onError={setError}
          dataCy={dataCy}
          uploadProgressVal={uploadProgressVal}
        />
      ),
      footer: <></>,
    },
  };

  const CURRENT_MODE = selectedImageSrc && !isError ? modes.edit : modes.select;

  return (
    <>
      <Button
        onClick={openModal}
        dataCy={`${IMAGE_UPLOADER_BUTTON_PREFIX}-${dataCy}`}
        disabled={disabled}
      >
        <div className="flex items-center gap-2">
          <UploadButtonIcon />
          <span>
            {uploaded
              ? t("imageUploader.changeButton")
              : t("imageUploader.uploadButton")}
          </span>
        </div>
      </Button>
      <Modal
        additionalContentClassName="h-full"
        dataCy={`${IMAGE_UPLOADER_MODAL_PREFIX}-${dataCy}`}
        height={CURRENT_MODE.height}
        isOpen={showModal}
        onClose={closeModal}
        width={CURRENT_MODE.width}
        withCloseIcon
        withYScroll={false}
      >
        <ModalHeader title={CURRENT_MODE.title} />
        <div className="flex flex-col h-full pt-3">
          <div className="flex items-center justify-center h-full">
            {CURRENT_MODE.component}
          </div>
          {CURRENT_MODE.footer}
        </div>
      </Modal>
    </>
  );
};

export default ImageUploader;
