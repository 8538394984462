import { useEffect, useState } from "react";
import { useDispatch } from "redux-react-hook";
import {
  removeDiacriticsFromString,
  sortArray,
  useFullTextSearch,
} from "@ps/utils";
import { LoadingMainPage, ScrollableWrapper } from "@ps/ui-components";
import {
  useMappedStateSelector,
  useSessionStorage,
} from "../../../../../hooks";
import { SessionStorage } from "../../../../../shared";
import SearchBar from "./searchBar";
import {
  IExtendedTeamMemberModel,
  IListFilters,
  TeamMapState,
  TeamMapStateReturn,
} from "./types";
import SortBar from "./sortBar";
import UserRow from "./userRow";
import { useTeamContext } from "../../context";
import { extendedUsers, filterUsers } from "./utils";
import {
  FIRST_NAME,
  FULL_NAME,
  ACTIVE,
  INACTIVE,
  ASC,
  PROFESSIONS,
  TEAM,
} from "../../../../constants";
import { fetchMySpecialistProfiles } from "../../../../../profile-domain/store/actions";
import { LAST_NAME } from "../../../../../profile-domain/ui/overview/constants";
import { SorterParametersProps, TeamCheckers } from "../../../common/types";
import EmptyPage from "./emptyPage";
import { initialFiltersState, initialCheckersState } from "./constants";
import { removeIntersections } from "./add-user/utils";
import { ProfilesSummaryListModel } from "../../../../../profile-domain";
import {
  SESSION_SORTERS,
  initialSessionSortersState,
} from "../../../../../shared/constants";

const Team = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { teamView } = useTeamContext();
  const [profiles, setProfiles] = useState<IExtendedTeamMemberModel[]>([]);

  const [usersListToDisplay, setUsersListToDisplay] = useState<
    IExtendedTeamMemberModel[]
  >([]);

  const [filters, setFilters] = useState<IListFilters>(initialFiltersState);
  const [searchValue, setSearchValue] = useState("");
  const [sorterParameters, setSorterParameters] =
    useState<SorterParametersProps>({
      order: ASC,
      name: FULL_NAME,
    });

  const [storageSorter, setStorageSorter] = useSessionStorage<SessionStorage>(
    SESSION_SORTERS,
    initialSessionSortersState,
  );

  const [usersCheckers, setUsersCheckers] =
    useState<TeamCheckers>(initialCheckersState);

  const mapState = (state: TeamMapState): TeamMapStateReturn => ({
    professionsDict:
      [
        ...state?.dictionaries?.profession,
        ...state?.dictionaries?.operative_profession,
      ] || [],
    projectId: state.projects?.project.id,
    archived: state.projects?.project.archived,
    seniorityDict: state.dictionaries?.seniority || [],
    users: state.projects?.project?.team || [],
    isFetchingSpecialistProfiles:
      state.requestStatus.isFetchingSpecialistProfiles,
  });

  const {
    seniorityDict,
    professionsDict,
    projectId,
    archived: isProjectArchived,
    users,
    isFetchingSpecialistProfiles,
  } = useMappedStateSelector(mapState);

  const activeUsers: IExtendedTeamMemberModel[] = extendedUsers(
    users?.filter((user: IExtendedTeamMemberModel) => !user.deleted) || [],
  );

  const inactiveUsers: IExtendedTeamMemberModel[] = extendedUsers(
    users?.filter((user: IExtendedTeamMemberModel) => user.deleted) || [],
  );

  const statusFilters = {
    [ACTIVE]: (user: IExtendedTeamMemberModel) => !user.deleted,
    [INACTIVE]: (user: IExtendedTeamMemberModel) => user.deleted,
  };

  const filterTeamByStatus = (
    teamUsers: IExtendedTeamMemberModel[],
    teamCheckers: TeamCheckers,
  ): IExtendedTeamMemberModel[] => {
    const selected = Object.entries(teamCheckers)
      .filter(([, value]: [string, boolean]) => value)
      .map(([key]: [string, boolean]) => statusFilters[key]);

    return teamUsers.filter((u: IExtendedTeamMemberModel) =>
      selected.some((f: (user: IExtendedTeamMemberModel) => void) => f(u)),
    );
  };

  const { search } = useFullTextSearch(usersListToDisplay, undefined, {
    keys: [FIRST_NAME, LAST_NAME],
  });

  const prepareUsersList = (
    searchingValue: string,
    userListFilters: IListFilters,
  ): void => {
    const { team: teamSorter } = storageSorter.sessionSorters;

    const properSorter: {
      name: string;
      order: string;
      nestedFieldName?: string;
    } = teamSorter?.name?.length ? teamSorter : sorterParameters;

    const resultByStatus = filterTeamByStatus(
      teamView ? extendedUsers(users) : profiles,
      usersCheckers,
    );
    const resultByFilter = filterUsers(
      resultByStatus,
      searchingValue,
      userListFilters,
      search,
    );
    const resultBySorter = sortArray(
      resultByFilter,
      properSorter.name as keyof IExtendedTeamMemberModel,
      properSorter.order,
      properSorter.nestedFieldName as keyof IExtendedTeamMemberModel,
    );

    const nonCreatedUsers = teamView
      ? resultBySorter.filter((user: IExtendedTeamMemberModel) =>
          users.some((u: IExtendedTeamMemberModel) => u.userId === user.userId),
        )
      : resultBySorter;

    setUsersListToDisplay(nonCreatedUsers);
  };

  const getSpecialistProfiles = async (): Promise<void> => {
    const result = await fetchMySpecialistProfiles(dispatch);

    const removedAlreadyAdded = removeIntersections(
      result as ProfilesSummaryListModel[],
      extendedUsers(users),
    );

    const extendedElements: IExtendedTeamMemberModel[] =
      removedAlreadyAdded.map((item: ProfilesSummaryListModel) => ({
        userId: item?.userId,
        avatar: item?.avatar,
        lastName: item?.lastName,
        firstName: item?.firstName,
        professions: { main: item?.mainProfession, secondary: [] },
        noForeignCharsFullName: removeDiacriticsFromString(
          `${item?.firstName} ${item?.lastName}`,
        ),
        location: item?.residence ?? null,
        statementOfWork: {
          startDate: "",
          finishDate: "",
          engagement: "",
          noticePeriod: null,
        },
        deleted: null,
        seniority: item?.seniority,
        rates: [],
      }));
    setProfiles(extendedElements);
    setUsersListToDisplay(extendedElements);
  };

  const handleOnFilterClick = (
    isChecked: boolean,
    id: string,
    filterName: string,
  ): void => {
    const prevFilters = JSON.parse(JSON.stringify(filters));

    const newFiltersState: IListFilters = isChecked
      ? {
          ...prevFilters,
          [filterName]: prevFilters[filterName]?.filter(
            (item: string) => item !== id,
          ),
        }
      : { ...prevFilters, [filterName]: [...prevFilters?.[filterName], id] };

    setFilters(newFiltersState);
    prepareUsersList(searchValue, newFiltersState);
  };

  const handleOnClearFilters = () => {
    setSearchValue("");
    setFilters(initialFiltersState);
    prepareUsersList("", initialFiltersState);
  };

  useEffect(() => {
    if (!teamView) {
      getSpecialistProfiles();
    } else {
      prepareUsersList(searchValue, filters);
    }
    handleOnClearFilters();
  }, [
    teamView,
    usersCheckers,
    activeUsers.length,
    inactiveUsers.length,
    users,
    sorterParameters.name,
    sorterParameters.order,
  ]);

  const handleOnSearch = (newValue: string): void => {
    setSearchValue(newValue);
    prepareUsersList(searchValue, filters);
  };

  const handleOnClickSorter = (
    fieldName: string,
    order: string,
    isNestedField?: boolean,
  ): void => {
    const nestedFieldName = isNestedField ? PROFESSIONS : undefined;

    setStorageSorter((prevData) => ({
      ...prevData,
      sessionSorters: {
        ...prevData.sessionSorters,
        [TEAM]: {
          name: fieldName,
          order,
          nestedFieldName,
        },
      },
    }));

    setSorterParameters({ name: fieldName, order });
  };

  const sortBarFiltersUsers = teamView
    ? filterTeamByStatus(extendedUsers(users), usersCheckers)
    : profiles;

  return (
    <section className="h-full flex flex-col relative">
      <div className="absolute right-0 -top-14">
        <SearchBar
          searchValue={searchValue}
          setSearchValue={handleOnSearch}
          activeAmount={activeUsers.length}
          inactiveAmount={inactiveUsers.length}
          onClearFilters={handleOnClearFilters}
          clearFiltersDisabled={
            !Object.values(filters).filter((item: string[]) => item.length)
              .length && !searchValue
          }
          onSorterClick={handleOnClickSorter}
          sorterParameters={
            storageSorter?.sessionSorters?.team?.name?.length
              ? storageSorter?.sessionSorters?.team
              : sorterParameters
          }
          teamCheckers={usersCheckers}
          setTeamCheckers={setUsersCheckers}
          projectArchived={isProjectArchived}
        />
      </div>

      <ScrollableWrapper>
        {usersListToDisplay?.length ? (
          <div className="flex flex-col py-5 bg-neutralPrimary-100 -mx-9 px-9">
            <SortBar
              activeSorter={sorterParameters.name}
              updateFilters={handleOnFilterClick}
              users={sortBarFiltersUsers}
              filters={filters}
            />
            {isFetchingSpecialistProfiles ? (
              <LoadingMainPage additionalClassName="pt-24 lg:pt-40" />
            ) : (
              <div>
                {usersListToDisplay.map(
                  (singleUser: IExtendedTeamMemberModel) => (
                    <UserRow
                      professionsDict={professionsDict}
                      key={singleUser.userId}
                      seniorityDict={seniorityDict}
                      user={singleUser}
                      userDisabled={singleUser.enabled}
                      projectId={projectId}
                    />
                  ),
                )}
              </div>
            )}
          </div>
        ) : (
          <EmptyPage />
        )}
      </ScrollableWrapper>
    </section>
  );
};

export default Team;
