import { ReactElement, useContext } from "react";
import {
  Modal2,
  ModalContent,
  ToggleSelect,
  Button,
  PermissionContext,
  RestrictedWrapper,
} from "@ps/ui-components";
import dayjs from "dayjs";
import UserAvatar from "./userAvatar";
import { ViewModalProps } from "./types";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { matchIconToFeedback } from "../../../../utils";
import { BASE_PATH } from "./constants";
import {
  START_DATE,
  FINISH_DATE,
  NOTICE_PERIOD,
  ENGAGEMENT,
  RATE,
  REASON,
  PROJECT_FEEDBACK,
} from "../../../../constants";
import { normalizedDate } from "./utils";
import { ReactComponent as InfinityIcon } from "../../../../../images/project/loop.svg";
import DisabledInfo from "./disabledInfo";
import { PROJECT_FULL_ACCESS } from "../../../../../shared/permissions";

const ViewModal = ({
  isOpen,
  onClose,
  user,
  normalizedSeniority,
  mainProfessionName,
  userDeleted,
  userDisabled,
  toggleRestoreUser,
  onClickChangeConditions,
}: ViewModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const { hasPermission } = useContext(PermissionContext);

  const rate = user?.rates?.[user?.rates.length - 1];
  const { statementOfWork, feedback } = user;

  const daysPeriod = statementOfWork?.noticePeriod;
  const daysPeriodValue = daysPeriod
    ? t(`${BASE_PATH}.day`, { count: +daysPeriod })
    : t(`${BASE_PATH}.day`, { count: +daysPeriod, context: "0" });

  const translatedEngagement = (engagement: string): string =>
    engagement === "Full time"
      ? t(`${BASE_PATH}.fullTime`)
      : t(`${BASE_PATH}.partTime`);

  return (
    <Modal2
      dataCy="viewModal"
      isOpen={isOpen}
      onClose={onClose}
      withCloseIcon
      withYScroll={false}
      width="w-3/12"
      height="h-fit"
    >
      <ModalContent>
        <div className="flex gap-x-4 mb-2">
          <UserAvatar avatar={user?.avatar} />
          <div className="flex flex-col">
            <span className="text-neutralPrimary-40">{mainProfessionName}</span>
            <span>{normalizedSeniority}</span>
          </div>
        </div>
        <div className="font-bold">{`${user?.firstName} ${user?.lastName}`}</div>
        <div className="flex justify-end">
          {!userDisabled ? (
            <DisabledInfo label={t(`${BASE_PATH}.disabled`)} />
          ) : (
            <ToggleSelect
              dataCy="activeUser"
              activeLabel={t(`${BASE_PATH}.active`)}
              negationLabel={t(`${BASE_PATH}.inactive`)}
              onChange={toggleRestoreUser}
              checked={!userDeleted}
              disabled={!hasPermission(PROJECT_FULL_ACCESS)}
            />
          )}
        </div>
        <div className="flex flex-col gap-y-5">
          <div className="flex justify-between items-center border-t-2 mt-4 pt-3">
            <span className="font-bold">{t(`${BASE_PATH}.${RATE}`)}</span>
            <span>{`${rate?.amount || 0} ${
              rate?.currency?.label || rate?.currency
            }/h`}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="font-bold">{t(`${BASE_PATH}.${START_DATE}`)}</span>
            <span>
              {statementOfWork?.startDate
                ? normalizedDate(statementOfWork.startDate)
                : ""}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="font-bold">
              {t(`${BASE_PATH}.${FINISH_DATE}`)}
            </span>
            <span>
              {statementOfWork.finishDate === null ||
              !dayjs(statementOfWork.finishDate)?.isValid() ? (
                <InfinityIcon className="h-7" />
              ) : (
                normalizedDate(statementOfWork.finishDate)
              )}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="font-bold">
              {t(`${BASE_PATH}.${NOTICE_PERIOD}`)}
            </span>
            <span>{daysPeriodValue}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="font-bold">{t(`${BASE_PATH}.${ENGAGEMENT}`)}</span>
            <span>
              {statementOfWork.engagement
                ? translatedEngagement(statementOfWork.engagement.label)
                : ""}
            </span>
          </div>
          {!userDeleted && (
            <RestrictedWrapper permissions={[PROJECT_FULL_ACCESS]}>
              <Button
                onClick={onClickChangeConditions}
                additionalClass="my-4"
                width="w-full"
                dataCy="userEdit"
              >
                {t(`${BASE_PATH}.changeConditions`)}
              </Button>
            </RestrictedWrapper>
          )}
          {feedback ? (
            <div className="flex flex-col gap-y-3">
              <span className="font-bold">
                {t(`${BASE_PATH}.${PROJECT_FEEDBACK}`)}
              </span>
              <div className="flex gap-x-4">
                {matchIconToFeedback(feedback)}
                {t(`${BASE_PATH}.feedbacks.${feedback}`)}
              </div>
            </div>
          ) : null}
          {userDeleted && (
            <div className="flex flex-col gap-y-2 border-t-2 pt-3">
              <span className="font-bold">{t(`${BASE_PATH}.${REASON}`)}</span>
              <span>{user?.deleted}</span>
            </div>
          )}
        </div>
      </ModalContent>
    </Modal2>
  );
};

export default ViewModal;
