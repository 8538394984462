import { NewCandidateAPIModel } from "../types";

const addCandidatesToAPI = (
  data: NewCandidateAPIModel[],
): NewCandidateAPIModel[] =>
  data?.map((item: NewCandidateAPIModel) => ({
    userId: item.userId,
  }));

const overwriteCandidatesToAPI = (
  data: NewCandidateAPIModel[],
): NewCandidateAPIModel[] =>
  data?.map((item: NewCandidateAPIModel) => ({
    userId: item.userId,
  }));

export { addCandidatesToAPI, overwriteCandidatesToAPI };
