import { ChangeEvent, KeyboardEvent, ReactElement } from "react";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import { Checkbox, Input } from "@ps/ui-components";
import { prepareFilteredEntries } from "../utils";
import { Keys } from "../../../../../shared";
import styles from "../styles.module.css";
import { FilterDropdownContentProps } from "./types";
import { HOLIDAYS_USER_HISTORY } from "../../constants";
import { IFilterOption } from "../types";

const DROPDOWN_ID = "filter-dropdown";

const FilterDropdownContent = ({
  allEntriesList,
  filters,
  allFilterOptions,
  filterOptionsToDisplay,
  filterName,
  setFilters,
  prepareFilterOptionsToDisplay,
  searchValue,
  setSearchValue,
  prepareItemsToDisplay,
}: FilterDropdownContentProps): ReactElement => {
  const onSearchInputChange = (
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (!event && event.key !== Keys.ESC) return;
    setSearchValue(event.target.value);
    const filteredFilterOptions = allFilterOptions.filter((item) =>
      item.name.toLowerCase().includes(event.target.value.toLowerCase()),
    );

    if (event.target.value) {
      prepareFilterOptionsToDisplay(filteredFilterOptions);
    } else prepareFilterOptionsToDisplay(allFilterOptions);
  };

  const onFilterOptionClick = (
    currentItem: IFilterOption,
    isChecked: boolean,
  ): void => {
    const tempFilters = JSON.parse(JSON.stringify(filters));

    const newFilters = {
      ...tempFilters,
      [filterName]: isChecked
        ? tempFilters[filterName].filter((el) => el.id !== currentItem.id)
        : [...tempFilters[filterName], currentItem],
    };
    const result = prepareFilteredEntries(allEntriesList, newFilters);
    prepareItemsToDisplay(result);
    setFilters(newFilters);
  };

  return (
    <div
      className="p-4 flex flex-col gap-4 focus:outline-none"
      tabIndex={-1}
      id={DROPDOWN_ID}
    >
      <Input
        autoFocus
        isSearch
        dataCy={HOLIDAYS_USER_HISTORY}
        defaultValue={searchValue}
        onChange={onSearchInputChange}
        // eslint-disable-next-line
        // @ts-ignore
        onKeyDown={onSearchInputChange}
      />
      <div
        className={classJoin(
          "flex flex-col gap-4 h-auto overflow-y-auto",
          styles.filterDropdownContent,
        )}
      >
        {filterOptionsToDisplay.map((item: IFilterOption) => {
          const isChecked = filters[filterName]?.some(
            (filterItem: IFilterOption) => filterItem.id === item.id,
          );
          return (
            <div
              key={uid(item)}
              className="flex gap-2 items-center"
              onClick={() => onFilterOptionClick(item, isChecked)}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  onFilterOptionClick(item, isChecked);
                }
              }}
              role="button"
              tabIndex={0}
            >
              <Checkbox dataCy={item.id} checked={isChecked} />
              <span>{item.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterDropdownContent;
