import { ReactElement, useState } from "react";
import { classJoin } from "@ps/utils";
import { useThemeMode } from "../../../theme-mode-provider";
import { ReactComponent as ProjectsIcon } from "../../../../images/profile-summary/project.svg";
import { ReactComponent as RightArrow } from "../../../../images/profile-summary/rightArrow.svg";
import ProjectDetailsModal from "./projectDetailsModal";
import { ProjectName, Roles, TechStack, TimeRange } from "./projectElements";
import { PROFILE_SUMMARY_PROJECTS } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import { SingleProjectProps } from "../../types";
import Card from "../../../card";
import { CardHeader } from "../../../expanded-card";
import LinkButton from "../../../link";

const SingleProjectTile = ({
  project,
  projectRolesDict,
  technologyDict,
}: SingleProjectProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Card
        dataCy={`${PROFILE_SUMMARY_PROJECTS}_singleProject`}
        additionalCardClassName={classJoin(
          "flex flex-col border",
          isHighContrast ? "hover:border-primary-10" : "",
        )}
      >
        <CardHeader
          additionalClassName="py-6"
          dataCy={`${PROFILE_SUMMARY_PROJECTS}_singleProject`}
          Icon={ProjectsIcon}
          separatorHeight="h-full"
        >
          <div className="flex flex-col gap-2">
            <Roles
              roles={project.projectRoles}
              projectRolesDict={projectRolesDict}
            />
            <ProjectName name={project?.name || ""} />
            <TimeRange
              startDate={project?.startDate}
              endDate={project?.endDate}
            />
          </div>
        </CardHeader>
        <div className="px-4">
          <TechStack
            techStack={project.techStack}
            showTwoRows
            technologyDict={technologyDict}
            section="projects"
          />
        </div>
        <LinkButton
          additionalClassName="mr-4 mb-2 self-end flex items-center gap-1 "
          dataCy={`${PROFILE_SUMMARY_PROJECTS}_seeMore`}
          suffixIcon={<RightArrow className="stroke-current" />}
          onClick={() => setIsModalOpen(true)}
        >
          {t("profileSummary.seeMore")}
        </LinkButton>
      </Card>
      <ProjectDetailsModal
        project={project}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen((prev) => !prev)}
        projectRolesDict={projectRolesDict}
        technologyDict={technologyDict}
      />
    </>
  );
};

export default SingleProjectTile;
