import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import Tooltip from "../tooltip";
import NavIcon from "./navIcon";
import { TooltipContainerProps, NavbarOptionsItem } from "./types";
import { RestrictedWrapper } from "../permissions";

const TooltipContainer = ({
  children,
  isMenuOpened,
  label,
  nestedElements,
  parentPath,
}: TooltipContainerProps): ReactElement => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Tooltip
      placement={nestedElements?.length ? "right-start" : "right"}
      backgroundColor="primary-60"
      textColor="primary-100"
      padding={nestedElements?.length ? "p-0" : "px-3 py-2"}
      isInteractive={!!nestedElements?.length}
      additionalClassName={classJoin(isMenuOpened ? "hidden" : "")}
      content={
        nestedElements?.length ? (
          <div className="flex flex-col uppercase">
            <span className="py-2 px-3">{label}</span>
            <div className="border-t rounded-md border-primary-100 mx-3 mb-2 opacity-40" />
            <div className="flex flex-col">
              {nestedElements?.length ? (
                nestedElements.map(
                  (nestedElement: NavbarOptionsItem): ReactElement => {
                    const path = `/${parentPath}/${nestedElement.path}`;
                    return (
                      <RestrictedWrapper
                        permissions={nestedElement.permission || ""}
                        key={nestedElement.path}
                      >
                        <Link
                          className={classJoin(
                            "grid grid-cols-3 gap-2 grid-rows-1 items-center select-none p-3 last:rounded-b-lg first:rounded-t-md capitalize",
                            pathname.includes(path)
                              ? "bg-primary-20 bg-opacity-40"
                              : "",
                          )}
                          to={path}
                        >
                          <NavIcon Icon={nestedElement.icon} isActive={true} />
                          <span className="col-span-2">
                            {t(
                              `navbar.${nestedElement.item}`,
                              nestedElement.item,
                            )}
                          </span>
                        </Link>
                      </RestrictedWrapper>
                    );
                  },
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <span className="uppercase">{label}</span>
        )
      }
    >
      {children}
    </Tooltip>
  );
};

export default TooltipContainer;
