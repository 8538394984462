import { SetStateAction } from "react";
import { AnyAction, Dispatch } from "redux";
import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import ActionTypes from "./actionTypes";
import { MyProfileService, ProfileService } from "../services";
import { IAction } from "./types";
import { DictionaryModel } from "../../dictionaries";
import { OcrModel } from "../models";
import { ExtendedProfilesSummaryListModel } from "../models/types";

export const fetchMyProfileStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_MY_PROFILE_START);
export const fetchMyProfileSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_MY_PROFILE_SUCCESS, payload);
export const fetchMyProfileError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_MY_PROFILE_ERROR, payload);

export const fetchProfileStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROFILE_START);
export const fetchProfileSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROFILE_SUCCESS, payload);
export const fetchProfileError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROFILE_ERROR, payload);

export const fetchProfilesListStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROFILES_START);
export const fetchProfilesListSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROFILES_SUCCESS, payload);
export const fetchProfilesListError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROFILES_ERROR, payload);

export const fetchSpecialistProfilesStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_SPECIALIST_PROFILES_START);
export const fetchSpecialistProfilesSuccess = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_SPECIALIST_PROFILES_SUCCESS, payload);
export const fetchSpecialistProfilesError = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_SPECIALIST_PROFILE_ERROR, payload);

export const fetchMySpecialistProfiles = async (
  dispatch: Dispatch,
): Promise<SetStateAction<ExtendedProfilesSummaryListModel[]>> => {
  dispatch(fetchSpecialistProfilesStart());
  const result = await ProfileService.fetchSpecialistProfilesList();
  if (isAPIError(result)) {
    dispatch(fetchSpecialistProfilesError(result));
    return result;
  }
  dispatch(fetchSpecialistProfilesSuccess(result));
  return result;
};

export const fetchMyProfile = async (
  dispatch: Dispatch,
  technologyDict: DictionaryModel,
): Promise<AnyAction | void> => {
  dispatch(fetchMyProfileStart());
  const result = await MyProfileService.fetchProfile(technologyDict);
  if (isAPIError(result)) {
    dispatch(fetchMyProfileError(result));
  } else {
    dispatch(fetchMyProfileSuccess(result));
  }
};

export const fetchProfile = async (
  id: string,
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchProfileStart());
  const result = await ProfileService.fetchProfile(id);
  if (isAPIError(result)) {
    dispatch(fetchProfileError(result));
  } else {
    dispatch(fetchProfileSuccess(result));
  }
};

export const fetchProfilesSummaryList = async (
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchProfilesListStart());
  const result = await ProfileService.fetchProfilesList();
  if (isAPIError(result)) {
    dispatch(fetchProfilesListError(result));
  } else {
    dispatch(fetchProfilesListSuccess(result));
  }
};

export const fetchOtherProfileWithFulfillmentStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_OTHER_PROFILE_START);
export const fetchOtherProfileWithFulfillmentSuccess = (
  payload: IAction<string>,
): AnyAction => actionCreator(ActionTypes.FETCH_OTHER_PROFILE_SUCCESS, payload);
export const fetchOtherProfileWithFulfillmentError = (
  payload: IAction<string>,
): AnyAction => actionCreator(ActionTypes.FETCH_OTHER_PROFILE_ERROR, payload);

// fetch profile and preview to create object with profiles data and best skills, skills by profession and domains
export const fetchOtherProfileWithFulfillment = async (
  userId: string,
  dispatch: Dispatch,
  technologyDict: DictionaryModel,
): Promise<AnyAction | void> => {
  dispatch(fetchOtherProfileWithFulfillmentStart());
  const result = await ProfileService.fetchProfileWithFulfillment(
    userId,
    technologyDict,
  );
  if (isAPIError(result)) {
    dispatch(fetchOtherProfileWithFulfillmentError(result));
  } else {
    dispatch(fetchOtherProfileWithFulfillmentSuccess(result));
  }
};

export const ocrProcessingStart = (): AnyAction =>
  actionCreator(ActionTypes.OCR_PROCESSING_START);
export const ocrProcessingSuccess = (payload: IAction<OcrModel>): AnyAction =>
  actionCreator(ActionTypes.OCR_PROCESSING_SUCCESS, payload);
export const ocrProcessingError = (
  payload: IAction<Record<string, unknown>>,
): AnyAction => actionCreator(ActionTypes.OCR_PROCESSING_ERROR, payload);
