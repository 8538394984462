import React, { ReactElement, useEffect, useRef, useState } from "react";
import { classJoin } from "@ps/utils";
import { PROGRESS_BAR_PREFIX } from "../../shared/data-cy";
import Bar from "./components/bar";
import Stage from "./components/stage";
import { ProgressBarProps } from "./types";

const ProgressBar = ({
  step,
  totalSteps,
  percent = 0,
  labels = [],
  additionalClassNames = "",
  itemsClassNames = "items-center",
  dataCy,
}: ProgressBarProps): ReactElement => {
  const [singleFragmentWidth, setSingleFragmentWidth] = useState<
    number | undefined
  >(undefined);
  const barNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (labels?.length && barNode?.current) {
      const { width } = barNode?.current?.getBoundingClientRect();
      setSingleFragmentWidth(width / labels.length);
    }
  }, [barNode]);

  const stepWidth: number | undefined =
    singleFragmentWidth && singleFragmentWidth * 0.8;

  return (
    <div
      className={classJoin(
        additionalClassNames,
        itemsClassNames,
        "flex flex-row justify-between w-full",
      )}
      data-cy={`${PROGRESS_BAR_PREFIX}-${dataCy}`}
    >
      <div
        className="flex flex-shrink flex-row w-full items-baseline mr-5"
        ref={barNode}
      >
        {new Array(Math.max(totalSteps - 1, 0))
          .fill(0)
          .map((_, index): ReactElement => {
            const elementStep = index + 1;
            const dataCyToAdd = `${dataCy}-${elementStep}`;
            const isNext = elementStep === step;
            const widthIfNext = isNext ? percent : 0;
            const width = elementStep < step ? 100 : widthIfNext;

            return (
              <React.Fragment key={elementStep}>
                <Stage
                  checked={elementStep <= step}
                  dataCy={dataCyToAdd}
                  index={index}
                  isLast={false}
                  label={labels[index]}
                  singleFragmentWidth={stepWidth}
                />
                <Bar width={width} dataCy={dataCyToAdd} isNext={isNext} />
              </React.Fragment>
            );
          })}
        <Stage
          checked={step >= totalSteps}
          dataCy={`${dataCy}-${totalSteps}`}
          index={labels?.length}
          isLast
          label={labels[totalSteps - 1]}
          singleFragmentWidth={stepWidth}
        />
      </div>
      <div className="w-fit">
        <span>{`${Math.max(
          Math.min(step, totalSteps),
          0,
        )}/${totalSteps}`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
