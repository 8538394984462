import axios from "axios";
import APIService from "../api/files-api";
import { TModelFileType, TModelUploadFile } from "../types";
import { handleError } from "../../shared";
import { CVListToAPIModel } from "../models";
import { UploadingCVModel, APIUploadCVMetadata } from "../models/types";

const FilesService = (() => {
  const createFile = async (
    fileType: TModelFileType,
    fileName: TModelUploadFile,
    file: File,
    progressCallback,
  ) => {
    try {
      const response = await APIService.API.getUploadMetadata(
        fileType,
        fileName,
      )
        .then(async (result): Promise<string> => {
          const status = await axios.put(
            result.data.presignedRequest.url,
            file,
            {
              onUploadProgress: (progressEvent) => {
                progressCallback(
                  Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total,
                  ),
                );
              },
              headers: {
                [result.data.presignedRequest.requiredHeaders[0].key]:
                  result.data.presignedRequest.requiredHeaders[0].value,
              },
            },
          );
          for (let i = 0; i < 3; i++) {
            setTimeout((): string => {
              if (status.status?.[0] === "2") return result.data.contentUrl;
              return "";
            }, 5000);
          }
          return result.data.contentUrl;
        })
        .then((result) => {
          return result;
        });
      if (response) return response;
      return "";
    } catch (error) {
      return handleError(error);
    }
  };

  const matchURLToFile = (
    entry: APIUploadCVMetadata,
    extendedFiles: UploadingCVModel[],
  ) => {
    const result = extendedFiles.find(
      (item: UploadingCVModel) => item.fileId === entry?.fileId,
    );

    return {
      ...result,
      presignedRequest: entry?.presignedRequest,
      file: result?.file,
      cv: `${entry.batchId}/${entry?.fileId}`,
    };
  };

  const uploadCVList = async (CVList: UploadingCVModel[]): Promise<any> => {
    const dataToUpload = await CVListToAPIModel(CVList);

    const result = await APIService.API.getCvUploadMetadata(dataToUpload).then(
      async (response) => {
        const dataToS3 = response.data?.map((item: APIUploadCVMetadata) =>
          matchURLToFile(item, CVList),
        );

        const tasks = dataToS3.map((item) => {
          return axios
            .put(item.presignedRequest?.url, item.file, {
              headers: {
                [item.presignedRequest.requiredHeaders[0].key]:
                  item.presignedRequest.requiredHeaders[0].value,
                "content-type": "application/pdf",
              },
            })
            .then(() => item)
            .catch(() => ({ ...item, error: ["error"] }))
            .finally((data) => data);
        });

        const responses = await Promise.allSettled(tasks);

        return responses?.map((item) => item.value)?.filter((item) => item);
      },
    );
    return result;
  };
  return {
    createFile,
    uploadCVList,
  };
})();

export default FilesService;
