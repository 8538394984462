export const PROFESSION_SELECT = "profession";
export const MUST_HAVE_SKILLS_SELECT = "mustHaveSkills";
export const NICE_TO_HAVE_SKILLS_SELECT = "niceToHaveSkills";
export const SEARCH_BUTTON = "search";
export const AVAILABLE_CHECKBOX = "available";
export const USER_CHECKBOX = "userCheckbox";

export const scaleColors = {
  1: "#FFFFFF",
  2: "#EEF0FA",
  3: "#DDE0F5",
  4: "#CCD2F1",
  5: "#BAC3EC",
  6: "#A9B4E8",
  7: "#8796DE",
  8: "#7285DA",
  9: "#4A61C9",
  10: "#394BA1",
};
