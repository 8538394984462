import Keycloak from "keycloak-js";

const tenantId = window.location.host.split(".")[0];
const keycloak = Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: tenantId,
  clientId: "trusted",
});

export default keycloak;
