enum ActionTypes {
  FETCH_CLIENTS_START = "FETCH_CLIENTS_START",
  FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS",
  FETCH_CLIENTS_ERROR = "FETCH_CLIENTS_ERROR",

  FETCH_CLIENTS_SUMMARY_START = "FETCH_CLIENTS_SUMMARY_START",
  FETCH_CLIENTS_SUMMARY_SUCCESS = "FETCH_CLIENTS_SUMMARY_SUCCESS",
  FETCH_CLIENTS_SUMMARY_ERROR = "FETCH_CLIENTS_SUMMARY_ERROR",

  FETCH_CLIENT_START = "FETCH_CLIENT_START",
  FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS",
  FETCH_CLIENT_ERROR = "FETCH_CLIENT_SUMMARY_ERROR",

  SET_DEFAULT_TAB = "SET_DEFAULT_TAB",
}

export default ActionTypes;
