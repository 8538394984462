import { ReactElement, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Notification } from "@ps/ui-components";
import { INVITING_MODAL } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import styles from "../styles.module.css";
import { InfoNotificationProps } from "../types";
import { MAX_FILES_UPLOAD } from "../utils";

const InfoNotification = ({
  className = "root-portal",
  el = "div",
  isVisible,
}: InfoNotificationProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const [container] = useState(() => {
    // This will be executed only on the initial render
    // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    return document.createElement(el);
  });

  useEffect(() => {
    container.classList.add(className);
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return createPortal(
    <div className="absolute top-0 right-0 pt-8 pr-8">
      {isVisible && (
        <Notification
          additionalClassName={styles.infoNotification}
          dataCy={INVITING_MODAL}
          description={t("members.inviting.limitNotificationInfo", {
            count: MAX_FILES_UPLOAD,
          })}
          id="1"
          title=""
          variant="info"
        />
      )}
    </div>,
    container,
  );
};
export default InfoNotification;
