import { AnyAction } from "redux";
import ActionTypes from "./actionTypes";
import { RoleModel, UserDomainModel } from "../models";

export const usersReducer = (
  state: UserDomainModel[] | [] = [],
  action: AnyAction,
): UserDomainModel[] | [] => {
  switch (action.type) {
    case ActionTypes.FETCH_USERS_START:
      return state;
    case ActionTypes.FETCH_USERS_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_USERS_ERROR:
      return [];
    default:
      return state;
  }
};

export const allRolesReducer = (
  state: RoleModel[] | [] = [],
  action: AnyAction,
): RoleModel[] | [] => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_ROLES_START:
      return state;
    case ActionTypes.FETCH_ALL_ROLES_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_ALL_ROLES_ERROR:
      return [];
    default:
      return state;
  }
};

const isFetchingUsers = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_USERS_START:
      return true;
    case ActionTypes.FETCH_USERS_SUCCESS:
    case ActionTypes.FETCH_USERS_ERROR:
      return false;
    default:
      return state;
  }
};

const isFetchingAllRoles = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_ROLES_START:
      return true;
    case ActionTypes.FETCH_ALL_ROLES_SUCCESS:
    case ActionTypes.FETCH_ALL_ROLES_ERROR:
      return false;
    default:
      return state;
  }
};

const isModifyingRoles = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case ActionTypes.CREATE_ROLE_START:
    case ActionTypes.UPDATE_ROLE_START:
    case ActionTypes.DELETE_ROLE_START:
      return true;
    case ActionTypes.CREATE_ROLE_SUCCESS:
    case ActionTypes.CREATE_ROLE_ERROR:
    case ActionTypes.UPDATE_ROLE_SUCCESS:
    case ActionTypes.UPDATE_ROLE_ERROR:
    case ActionTypes.DELETE_ROLE_SUCCESS:
    case ActionTypes.DELETE_ROLE_ERROR:
      return false;
    default:
      return state;
  }
};

const isCreatingUsers = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case ActionTypes.CREATE_USERS_START:
      return true;
    case ActionTypes.CREATE_USERS_SUCCESS:
    case ActionTypes.CREATE_USERS_ERROR:
      return false;
    default:
      return state;
  }
};

export const userRequestsInProgressReducer = {
  isFetchingUsers,
  isFetchingAllRoles,
  isModifyingRoles,
  isCreatingUsers,
};
