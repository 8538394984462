import { ReactElement } from "react";
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { uid } from "react-uid";
import { getNameFromDictionary } from "@ps/utils";
import {
  Certificate,
  Contribution,
  Education,
  Header,
  Languages,
  Outline,
  Profile,
  SkillsLegend,
} from "./sections";
import { useTranslationWithNamespace } from "../../hooks";
import {
  CERTIFICATES,
  CONTRIBUTIONS,
  EDUCATION,
  SKILLS,
  WORK_EXPERIENCE,
} from "../../shared/constants";
import PoppinsRegular from "../../fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../fonts/Poppins-Bold.ttf";
import PoppinsLight from "../../fonts/Poppins-Light.ttf";
import { Skill, SkillsList, TitleComponent } from "./components";
import {
  CertificateModel,
  ContributionModel,
  DegreeModel,
  ProjectModel,
  ResumeProps,
  SkillType,
} from "./types";
import sharedStyles from "./sharedStyles/sharedStyles";

const styles = StyleSheet.create({
  page: {
    padding: "30px",
    position: "relative",
    fontFamily: "Poppins",
  },
  skillsByProfessionContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "12px",
  },
  professionTitle: {
    color: "#2A2D33",
    fontFamily: "PoppinsBold",
    fontSize: "12px",
    marginBottom: "8px",
  },
});

Font.register({
  family: "Poppins",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsLight",
  src: PoppinsLight,
});

Font.register({
  family: "PoppinsBold",
  src: PoppinsBold,
});

const Resume = ({
  avatar,
  domainDict,
  isAnonymous = false,
  isBlind = false,
  professionDict,
  profile,
  roleDict,
  seniorityDict,
  technologyDict,
}: ResumeProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const seniority: string = getNameFromDictionary(
    seniorityDict,
    profile?.seniority,
  );

  const mainProfessionName: string = getNameFromDictionary(
    professionDict,
    profile?.professions?.main,
  );

  const secondaryProfessionNames: string[] = profile?.professions?.secondary
    ?.length
    ? profile?.professions?.secondary?.map((profession: string) =>
        getNameFromDictionary(professionDict, profession),
      )
    : [];

  const roleNames: string[] = profile?.projectRoles?.map((role: string) =>
    getNameFromDictionary(roleDict, role),
  );

  return (
    <Document
      title={`${isBlind ? "" : profile.firstName} ${
        isAnonymous || isBlind ? "" : profile.lastName
      } CV`}
    >
      <Page style={styles.page}>
        <Header
          avatar={avatar}
          firstName={isBlind ? "" : profile.firstName}
          lastName={isBlind || isAnonymous ? "" : profile?.lastName}
          jobTitle={`${seniority} ${mainProfessionName}`}
          residence={profile?.residence?.label || ""}
          isBlind={isBlind}
        />
        <Outline
          seniority={seniority}
          professions={
            profile?.professions
              ? [mainProfessionName, ...secondaryProfessionNames]
              : []
          }
          roles={roleNames}
          experience={profile?.careerSpan}
          isBlind={isBlind}
        />
        {profile?.languages.length > 0 ? (
          <Languages languages={profile?.languages} isBlind={isBlind} />
        ) : (
          <></>
        )}
        {profile?.bio ? (
          <Profile bio={profile?.bio} isBlind={isBlind} />
        ) : (
          <></>
        )}
        {profile?.skills?.best?.length ? (
          <View style={sharedStyles.container} wrap={false}>
            <View>
              <TitleComponent
                text={t("resume.mainSkills")}
                icon={SKILLS}
                isBlind={isBlind}
              />
            </View>
            <SkillsList
              skills={profile?.skills?.best}
              technologyDict={technologyDict}
            />
          </View>
        ) : (
          <></>
        )}

        {profile.experience.projects?.length ? (
          <View style={sharedStyles.container}>
            <TitleComponent
              text={t(`resume.${WORK_EXPERIENCE}`)}
              icon={WORK_EXPERIENCE}
              isBlind={isBlind}
            />
            {profile.experience.projects.map(
              (project: ProjectModel): ReactElement => (
                <Contribution
                  key={uid(project)}
                  projectRoles={project.projectRoles}
                  name={isBlind ? "" : project.name}
                  domain={project.domain}
                  description={project.description}
                  startDate={project.startDate}
                  endDate={project.endDate}
                  techStack={project.techStack}
                  domainDict={domainDict}
                  rolesDict={roleDict}
                  isBlind={isBlind}
                />
              ),
            )}
          </View>
        ) : (
          <></>
        )}
        {profile.experience.contributions?.length ? (
          <View style={sharedStyles.container}>
            <TitleComponent
              text={t(`resume.${CONTRIBUTIONS}`)}
              icon={CONTRIBUTIONS}
              isBlind={isBlind}
            />
            {profile.experience.contributions.map(
              (contribution: ContributionModel): ReactElement => (
                <Contribution
                  key={uid(contribution)}
                  name={contribution.name}
                  startDate={contribution?.startDate}
                  endDate={contribution.endDate}
                  techStack={contribution.techStack}
                  description={contribution.url}
                  isBlind={isBlind}
                />
              ),
            )}
          </View>
        ) : (
          <></>
        )}
        {profile.experience.certificates?.length ? (
          <View style={sharedStyles.container}>
            <TitleComponent
              text={t(`resume.${CERTIFICATES}`)}
              icon={CERTIFICATES}
              isBlind={isBlind}
            />
            {profile.experience.certificates.map(
              (certificate: CertificateModel): ReactElement => (
                <Certificate
                  key={uid(certificate)}
                  title={certificate.title}
                  issuedDate={certificate.issuedDate}
                  expirationDate={certificate.expirationDate}
                  techStack={certificate.techStack}
                  url={certificate.url}
                  isBlind={isBlind}
                />
              ),
            )}
          </View>
        ) : (
          <></>
        )}
        {profile.experience.degrees?.length ? (
          <View style={sharedStyles.container}>
            <TitleComponent
              text={t(`resume.${EDUCATION}`)}
              icon={EDUCATION}
              isBlind={isBlind}
            />
            {profile.experience.degrees.map(
              (degree: DegreeModel): ReactElement => (
                <Education
                  key={uid(degree)}
                  title={degree.degree}
                  // @ts-ignore
                  startDate={degree.startDate}
                  // @ts-ignore
                  endDate={degree.endDate}
                  description={degree?.description || ""}
                  fieldOfStudy={degree?.fieldOfStudy || ""}
                  schoolName={degree?.schoolName || ""}
                  isBlind={isBlind}
                />
              ),
            )}
          </View>
        ) : (
          <></>
        )}
        {profile.skills.byProfession?.length ? (
          <View style={sharedStyles.container}>
            <TitleComponent
              text={t("resume.allSkills")}
              icon={SKILLS}
              isBlind={isBlind}
            />
            {profile.skills?.byProfession?.map(
              (profession: {
                professionId: string;
                skills: SkillType[];
              }): ReactElement => (
                <View
                  key={uid(profession)}
                  style={styles.skillsByProfessionContainer}
                >
                  <Text style={styles.professionTitle}>
                    {professionDict.find(
                      (professionEntry) =>
                        professionEntry.id === profession.professionId,
                    )?.name || profession.professionId}
                  </Text>
                  <SkillsList
                    skills={profession.skills}
                    technologyDict={technologyDict}
                  />
                </View>
              ),
            )}
            {profile.domains?.length ? (
              <View style={styles.skillsByProfessionContainer}>
                <Text style={styles.professionTitle}>
                  {t("resume.domains")}
                </Text>
                <View style={sharedStyles.skillsContainer} wrap={false}>
                  {profile.domains?.map(
                    (domain: SkillType): ReactElement => (
                      <Skill
                        key={uid(domain)}
                        score={domain.score}
                        name={
                          domainDict.find(
                            (domainEntry) =>
                              domainEntry.id === domain.technologyId,
                          )?.name || domain.technologyId
                        }
                        technologyId={domain.technologyId}
                      />
                    ),
                  )}
                </View>
              </View>
            ) : (
              <></>
            )}
          </View>
        ) : (
          <></>
        )}
        {profile.skills.byProfession?.length ||
        profile?.skills?.best?.length ? (
          <SkillsLegend />
        ) : (
          <></>
        )}
      </Page>
    </Document>
  );
};

export default Resume;
