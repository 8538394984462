import { AnyAction } from "redux";
import dayjs from "dayjs";
import { useDispatch } from "redux-react-hook";
import {
  KeyboardEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Chip,
  ToggleSelect,
  useFocus,
  PermissionContext,
} from "@ps/ui-components";
import { FormProvider, useForm } from "react-hook-form";
import { classJoin, getNameFromDictionary, setSeniorityColor } from "@ps/utils";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../../../hooks";
import { PROJECT_FEEDBACK, RATE, ADD_TO_PROJECT } from "../../../../constants";
import { ReactComponent as MoreIcon } from "../../../../../images/project/more2.svg";
import { ReactComponent as AddIcon } from "../../../../../images/project/add-new-color.svg";
import { ReactComponent as InfinityIcon } from "../../../../../images/project/loop.svg";
import styles from "./styles.module.css";
import { useTeamContext } from "../../context";
import DeactivateUserModal from "./deactivateUserModal";
import UserAvatar from "./userAvatar";
import {
  deleteTeamMember,
  updateTeamMember,
  createTeamMember,
} from "../../../../services";
import { fetchProject } from "../../../../store";
import { UserRowProps, UserRowState, UserRowStateReturn } from "./types";
import { BASE_PATH } from "./constants";
import EditModal from "./editModal";
import ViewModal from "./viewModal";
import { normalizedDate } from "./utils";
import DisabledInfo from "./disabledInfo";
import { Keys } from "../../../../../shared";
import { PROJECT_FULL_ACCESS } from "../../../../../shared/permissions";
import { TeamMemberModel } from "../../../../models";

const UserRow = ({
  professionsDict,
  seniorityDict,
  user,
  userDisabled,
  projectId,
}: UserRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const {
    teamView,
    editUserId,
    setEditUserId,
    viewUserId,
    setViewUserId,
    setFocusId,
    focusId: id,
    isAddingNewUser,
    setIsAddingNewUser,
  } = useTeamContext();
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { hasPermission } = useContext(PermissionContext);

  const mapState = (state: UserRowState): UserRowStateReturn => ({
    technologyDict: state?.dictionaries?.technology || [],
  });

  const { technologyDict } = useMappedStateSelector(mapState);

  const userRate = user?.rates?.[user?.rates.length - 1];
  const userData = {
    ...user,
    unspecifiedEnd: isAddingNewUser
      ? false
      : user?.statementOfWork?.finishDate === null ||
        !dayjs(user?.statementOfWork?.finishDate)?.isValid(),
    [RATE]: userRate,
    [PROJECT_FEEDBACK]: user?.feedback,
  };
  const methods = useForm({
    defaultValues: userData,
    mode: "all",
  });

  useEffect(() => {
    methods.reset(userData);
  }, [editUserId === user?.userId]);

  const [focusId] = useFocus(id === user.userId, false);

  const handleOnModalClose = () => {
    setViewUserId("");
    setEditUserId("");
    setIsAddingNewUser(false);
    methods.reset();
  };
  const onSubmit = async (data: TeamMemberModel): Promise<void> => {
    const output = await methods.trigger();
    if (output) {
      await updateTeamMember(projectId, user?.userId, {
        ...data,
        userId: user?.userId,
      });
      await fetchProject(projectId, dispatch, technologyDict);
      handleOnModalClose();
    }
  };

  const onAddUser = async (data: TeamMemberModel): Promise<void> => {
    const output = await methods.trigger();
    if (output) {
      await createTeamMember(projectId, {
        ...data,
        userId: user.userId,
      });
      await fetchProject(projectId, dispatch, technologyDict);
      handleOnModalClose();
    }
  };

  const onDeactivateUser = async (
    rejectReason: string,
  ): Promise<AnyAction | void> => {
    await deleteTeamMember(projectId, user.userId, rejectReason);
    setIsDeactivateModalOpen(false);
    await fetchProject(projectId, dispatch, technologyDict);
  };

  const handleRestoreUser = (): void => {
    setEditUserId(user.userId);
    methods.reset();
  };

  const onRestoreUser = async () => {
    const output = await methods.trigger();
    if (output) {
      await createTeamMember(projectId, {
        ...methods.getValues(),
        userId: editUserId,
        deleted: null,
      });
      await fetchProject(projectId, dispatch, technologyDict);
      setEditUserId("");
      setViewUserId("");
    }
  };

  const isUserDeleted = !!user?.deleted?.length;

  const toggleRestoreUser = () => {
    if (isUserDeleted) {
      setViewUserId("");
      handleRestoreUser();
    } else {
      setIsDeactivateModalOpen(true);
      setViewUserId("");
    }
  };

  const handleClickChangeConditions = () => {
    setEditUserId(user.userId);
    setViewUserId("");
  };

  const seniorityName = getNameFromDictionary(seniorityDict, user?.seniority);

  const normalizedSeniority =
    seniorityName.charAt(0).toUpperCase() + seniorityName.slice(1);

  const mainProfessionName = getNameFromDictionary(
    professionsDict,
    user?.professions?.main,
  );

  return (
    <div
      className="p-6 bg-neutralPrimary-100 border-2 border-neutralSecondary-90"
      data-cy={`${BASE_PATH}_row_${user.userId}`}
    >
      <div
        className={classJoin(
          teamView ? styles.gridContainer : styles.addUserContainer,
          "w-full items-center",
        )}
      >
        <div className="flex items-center gap-8">
          <UserAvatar avatar={user?.avatar} />
          <span className="text-neutralPrimary-20 font-bold">
            {user?.firstName} {user?.lastName}
          </span>
        </div>

        <div className="flex items-center text-neutralPrimary-20">
          {user.professions?.main ? mainProfessionName : ""}
        </div>

        <div className="flex items-center flex-wrap text-neutralPrimary-20">
          <span className={`${setSeniorityColor(user?.seniority)}`}>
            {user?.seniority ? normalizedSeniority : ""}
          </span>
        </div>
        {teamView ? (
          <>
            <div
              className="text-neutralPrimary-20"
              data-cy={`${BASE_PATH}_row_startDate_${user.userId}`}
            >
              {user?.statementOfWork?.startDate
                ? normalizedDate(user?.statementOfWork?.startDate)
                : ""}
            </div>

            <div
              className="text-neutralPrimary-20"
              data-cy={`${BASE_PATH}_row_endDate_${user.userId}`}
            >
              {user?.statementOfWork?.finishDate === null ||
              !dayjs(user?.statementOfWork?.finishDate).isValid() ? (
                <InfinityIcon />
              ) : (
                normalizedDate(user?.statementOfWork?.finishDate)
              )}
            </div>

            <Chip
              dataCy={`${BASE_PATH}-${RATE}`}
              label={`${userRate?.amount || 0} ${
                userRate?.currency?.label || userRate?.currency
              }/h`}
              additionalClassName="text-primary-50 border-primary-50 bg-primary-70 w-32 opacity-70 px-2"
            />

            {userDisabled ? (
              <ToggleSelect
                dataCy={`${BASE_PATH}-activate-user`}
                activeLabel={t("project.team.active")}
                negationLabel={t("project.team.inactive")}
                onChange={toggleRestoreUser}
                checked={!isUserDeleted}
                disabled={!hasPermission(PROJECT_FULL_ACCESS)}
              />
            ) : (
              <DisabledInfo label={t("project.team.disabled")} />
            )}
            <button
              id={focusId}
              onClick={() => {
                setViewUserId(user?.userId);
                setFocusId(user.userId);
              }}
              onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                if (event.key === Keys.ENTER) {
                  setViewUserId(user?.userId);
                  setFocusId(user.userId);
                }
              }}
              data-cy={`${BASE_PATH}_row_moreIcon_${user.userId}`}
              className="w-min ml-4"
            >
              <MoreIcon className="cursor-pointer text-neutralPrimary-30" />
            </button>
          </>
        ) : (
          <>
            <div className="flex gap-2 items-center flex-wrap pr-4 text-neutralPrimary-20">
              {user?.location?.label
                ? `${user.location.label}, ${user.location.value}`
                : ""}
            </div>

            <Button
              dataCy={`${BASE_PATH}.addUser`}
              additionalClass="justify-self-end pr-6"
              variant="secondary"
              onClick={() => {
                setEditUserId(user?.userId);
                setIsAddingNewUser(true);
              }}
            >
              <AddIcon />
              {t(`${BASE_PATH}.${ADD_TO_PROJECT}`)}
            </Button>
          </>
        )}
      </div>
      <FormProvider {...methods}>
        {editUserId === user?.userId && (
          <EditModal
            isOpen={editUserId === user?.userId}
            mainProfessionName={mainProfessionName}
            normalizedSeniority={normalizedSeniority}
            onAddUserClick={onAddUser}
            onClose={handleOnModalClose}
            onRestoreClick={onRestoreUser}
            onSaveClick={onSubmit}
            user={user}
            userDeleted={isUserDeleted}
          />
        )}
      </FormProvider>
      {viewUserId === user?.userId && (
        <ViewModal
          isOpen={viewUserId === user?.userId}
          onClose={() => setViewUserId("")}
          user={user}
          normalizedSeniority={normalizedSeniority}
          mainProfessionName={mainProfessionName}
          userDeleted={isUserDeleted}
          userDisabled={userDisabled}
          toggleRestoreUser={toggleRestoreUser}
          onClickChangeConditions={handleClickChangeConditions}
        />
      )}
      <DeactivateUserModal
        isOpen={isDeactivateModalOpen}
        onClose={() => {
          setIsDeactivateModalOpen(false);
        }}
        onDeactivate={onDeactivateUser}
        user={user}
      />
    </div>
  );
};

export default UserRow;
