import { AnyAction, combineReducers } from "redux";
import ActionTypes from "./actionTypes";
import { ClientModel, ClientSummaryModel } from "../models";

// eslint-disable-next-line import/prefer-default-export
export const clientsReducer = (
  state: ClientModel[] | [] = [],
  action: AnyAction,
): [] | ClientModel[] => {
  switch (action.type) {
    case ActionTypes.FETCH_CLIENTS_START:
      return state;
    case ActionTypes.FETCH_CLIENTS_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_CLIENTS_ERROR:
      return [];
    default:
      return state;
  }
};
export const clientsSummaryReducer = (
  state: ClientSummaryModel[] | [] = [],
  action: AnyAction,
): [] | ClientSummaryModel[] => {
  switch (action.type) {
    case ActionTypes.FETCH_CLIENTS_SUMMARY_START:
      return state;
    case ActionTypes.FETCH_CLIENTS_SUMMARY_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_CLIENTS_SUMMARY_ERROR:
      return [];
    default:
      return state;
  }
};
export const clientSummaryReducer = (
  state: ClientSummaryModel[] | [] = [],
  action: AnyAction,
): [] | ClientSummaryModel[] => {
  switch (action.type) {
    case ActionTypes.FETCH_CLIENT_START:
      return state;
    case ActionTypes.FETCH_CLIENT_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_CLIENT_ERROR:
      return [];
    default:
      return state;
  }
};

const defaultTabReducer = (
  state: Record<string, string | undefined> = {},
  action: AnyAction,
): Record<string, string | undefined> => {
  switch (action.type) {
    case ActionTypes.SET_DEFAULT_TAB:
      return action.payload;
    default:
      return state;
  }
};

export const isFetchingClientsList = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_CLIENT_START:
      return true;
    case ActionTypes.FETCH_CLIENTS_SUCCESS:
    case ActionTypes.FETCH_CLIENTS_ERROR:
      return false;
    default:
      return state;
  }
};

export const isFetchingClientsSummaryList = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_CLIENTS_START:
      return true;
    case ActionTypes.FETCH_CLIENTS_SUMMARY_SUCCESS:
    case ActionTypes.FETCH_CLIENTS_SUMMARY_ERROR:
      return false;
    default:
      return state;
  }
};


export default combineReducers({
  defaultTab: defaultTabReducer,
  clientsList: clientsReducer,
  clientsSummary: clientsSummaryReducer,
  clientSummary: clientSummaryReducer,
});
