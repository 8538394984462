import { ReactElement, useEffect, useState } from "react";
import {
  EmptyList,
  LoadingMainPage,
  MiddleContent,
  MiddleSection,
  ScrollableWrapper,
  TabsPlaceholder,
} from "@ps/ui-components";
import { sortArray, divideArrayByField } from "@ps/utils";
import { useDispatch } from "redux-react-hook";
import { Scrollbars } from "react-custom-scrollbars-2";
import { SessionStorage } from "../../shared";
import { FOLDERS_LIST } from "../../shared/data-cy";
import { SorterParametersProps } from "../../prospect-domain/ui/common/types";
import {
  ACTIVE,
  ARCHIVED,
  ASC,
  CLIENT_NAME,
  FOLDERS,
  FOLDER_NAME,
  initialCheckersState,
} from "../constants";
import { fetchFolders } from "../store";
import { FolderModel } from "../models";
import FoldersList from "./folders-list/foldersList";
import SortBar from "./folders-list/sortBar";
import {
  useMappedStateSelector,
  useSessionStorage,
  useTranslationWithNamespace,
} from "../../hooks";
import { filterFoldersByFields } from "../utils/filterFoldersList";
import {
  FilterItem,
  Filters,
  FoldersCheckers,
  FoldersMapState,
  FoldersMapStateReturn,
  FoldersProps,
  IMappedFolderListElement,
} from "./types";
import { ClientModel, fetchClients } from "../../client-domain";
import { clearFolders } from "../store/actions";
import { fetchProfilesSummaryList } from "../../profile-domain";
import SearchBar from "./folders-list/searchBar";
import {
  SESSION_SORTERS,
  initialSessionSortersState,
} from "../../shared/constants";
import { ReactComponent as EmptyImage } from "../../images/emptyFolder.svg";

const Folders = ({ clientId }: FoldersProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [searchValue, setSearchValue] = useState("");
  const [foldersCheckers, setFoldersCheckers] =
    useState<FoldersCheckers>(initialCheckersState);
  const [storageSorter, setStorageSorter] = useSessionStorage<SessionStorage>(
    SESSION_SORTERS,
    initialSessionSortersState,
  );

  const [filters, setFilters] = useState<Filters>({ [CLIENT_NAME]: [] });

  const [sortedFoldersList, setSortedFoldersList] = useState<
    IMappedFolderListElement[] | []
  >([]);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchClients(dispatch);
    fetchFolders(dispatch, clientId);
    fetchProfilesSummaryList(dispatch);
  }, []);

  useEffect(() => () => dispatch(clearFolders()), []);

  const [sorterParameters, setSorterParameters] =
    useState<SorterParametersProps>({
      order: ASC,
      name: FOLDER_NAME,
    });

  const mapState = (state: FoldersMapState): FoldersMapStateReturn => ({
    foldersList: state?.folders?.foldersList || [],
    clientsList: state?.clients?.clientsList || [],
    isLoading: state?.requestStatus?.isFetchingFoldersList,
  });

  const { foldersList, clientsList, isLoading } =
    useMappedStateSelector(mapState);

  const mappedFoldersList: IMappedFolderListElement[] = foldersList?.map(
    (folder: FolderModel): IMappedFolderListElement => ({
      ...folder,
      clientName: clientsList.find(
        (client: ClientModel): boolean => client.id === folder.clientId,
      )?.clientName,
    }),
  );

  const statusFilters = {
    [ACTIVE]: (folder: IMappedFolderListElement) => !folder.archived,
    [ARCHIVED]: (folder: IMappedFolderListElement) => folder.archived,
  };

  const filterFoldersByStatus = (
    foldersArr: IMappedFolderListElement[],
    checkers: FoldersCheckers,
  ): IMappedFolderListElement[] =>
    foldersArr.filter((folder: IMappedFolderListElement) =>
      Object.entries(checkers)
        .filter(([, value]) => value)
        .map(([key]) => statusFilters[key])
        .some((f: (prospect: IMappedFolderListElement) => void) => f(folder)),
    );

  const prepareFoldersList = (
    search: string,
    foldersFilters: Filters,
  ): void => {
    const { folders: foldersSorter } = storageSorter.sessionSorters;

    const properSorter: {
      name: string;
      order: string;
      nestedFieldName?: string;
    } = foldersSorter?.name?.length ? foldersSorter : sorterParameters;

    const resultByStatus = filterFoldersByStatus(
      mappedFoldersList,
      foldersCheckers,
    );

    const resultByFilter = filterFoldersByFields(
      resultByStatus,
      search,
      foldersFilters,
    );

    const resultbySorter = sortArray(
      resultByFilter,
      properSorter.name as keyof IMappedFolderListElement,
      properSorter.order,
    );

    setSortedFoldersList(resultbySorter);
  };

  useEffect(() => {
    if (clientsList.length) prepareFoldersList(searchValue, filters);
  }, [
    searchValue,
    foldersList,
    clientsList,
    foldersCheckers,
    sorterParameters.name,
    sorterParameters.order,
    filters,
  ]);

  const { negatives: onlyActiveFolders, positives: onlyArchivedFolders } =
    divideArrayByField(mappedFoldersList, "archived");

  const handleOnClickSorter = (
    fieldName: string,
    order: string,
    isNestedField?: boolean,
  ): void => {
    const nestedFieldName = isNestedField ? "details" : undefined;

    setStorageSorter((prevData) => ({
      ...prevData,
      sessionSorters: {
        ...prevData.sessionSorters,
        [FOLDERS]: {
          name: fieldName,
          order,
          nestedFieldName,
        },
      },
    }));

    setSorterParameters({ name: fieldName, order });
  };

  const handleOnFilterClick = (
    isChecked: boolean,
    filterItem: FilterItem,
    filterName: string,
  ): void => {
    const prevFilters = JSON.parse(JSON.stringify(filters));

    const newFiltersState: Filters = isChecked
      ? {
          ...prevFilters,
          [filterName]: prevFilters[filterName]?.filter(
            (item: FilterItem) => item.id !== filterItem.id,
          ),
        }
      : {
          ...prevFilters,
          [filterName]: [...prevFilters[filterName], filterItem],
        };

    setFilters(newFiltersState);
    prepareFoldersList(searchValue, newFiltersState);
  };

  const handleOnClearFilters = (): void => {
    setFilters({ [CLIENT_NAME]: [] });
    prepareFoldersList(searchValue, { [CLIENT_NAME]: [] });
  };

  const renderFoldersList = (): ReactElement => (
    <>
      <div>
        <SearchBar
          activeAmount={onlyActiveFolders.length}
          archivedAmount={onlyArchivedFolders.length}
          clientId={clientId}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          sorterParameters={
            storageSorter.sessionSorters?.folders?.name?.length
              ? storageSorter.sessionSorters.folders
              : sorterParameters
          }
          onSorterClick={handleOnClickSorter}
          foldersCheckers={foldersCheckers}
          setFoldersCheckers={setFoldersCheckers}
          clearFilters={handleOnClearFilters}
          disabledClearFilters={
            !Object.values(filters).some((item: string[]) => item?.length)
          }
        />
      </div>

      <ScrollableWrapper>
        {sortedFoldersList?.length ? (
          <div className="w-full h-full">
            <SortBar
              filters={filters}
              clientId={clientId}
              folders={filterFoldersByStatus(
                mappedFoldersList,
                foldersCheckers,
              )}
              updateFilters={handleOnFilterClick}
            />
            <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
              <FoldersList folders={sortedFoldersList} clientId={clientId} />
            </Scrollbars>
          </div>
        ) : (
          <div className="w-full flex justify-center">
            <EmptyList
              searchValue={searchValue}
              text={t(`${FOLDERS}.list.noFoldersTitle`)}
              Image={<EmptyImage />}
            />
          </div>
        )}
      </ScrollableWrapper>
    </>
  );

  if (isLoading)
    return <LoadingMainPage additionalClassName="bg-neutralPrimary-85" />;

  return (
    <>
      {clientId ? (
        renderFoldersList()
      ) : (
        <MiddleSection dataCy={FOLDERS_LIST}>
          <TabsPlaceholder />
          <MiddleContent dataCy={FOLDERS_LIST}>
            {renderFoldersList()}
          </MiddleContent>
        </MiddleSection>
      )}
    </>
  );
};

export default Folders;
