import { ReactElement } from "react";
import { getNamesFromDictionary, getOverallExperience } from "@ps/utils";
import Experience from "./experience";
import Roles from "./roles";
import Seniority from "./seniority";
import Professions from "./professions";
import { ExperienceSectionProps } from "./types";

const ExperienceSection = ({
  careerSpan,
  professions,
  professionsDict,
  projectRoles,
  roleDict,
  seniority,
  seniorityDict,
}: ExperienceSectionProps): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const overallExperience = getOverallExperience(careerSpan);

  return (
    <div className="h-full w-full bg-neutralPrimary-85 bg-opacity-50 flex gap-x-28 justify-start items-center px-10 py-6">
      {overallExperience?.years || overallExperience?.months ? (
        <Experience overallExperience={overallExperience} />
      ) : (
        <></>
      )}
      <div className="flex gap-16 items-center">
        {seniority ? (
          <Seniority seniority={seniority} seniorityDict={seniorityDict} />
        ) : (
          <></>
        )}
        {professions?.main || professions?.secondary?.length ? (
          <Professions
            professions={professions}
            professionsDict={professionsDict}
          />
        ) : (
          <></>
        )}
      </div>
      {projectRoles?.length ? (
        <Roles
          roles={getNamesFromDictionary(projectRoles, roleDict)
            .split(",")
            .filter((item) => !!item)}
        />
      ) : (
        <></>
      )}
      {/* TODO temporary hidden */}
      {/* {isDayjs(careerSpan?.startDate) && ( */}
      {/*  <JoinDate date={careerSpan.startDate} /> */}
      {/* )} */}
    </div>
  );
};

export default ExperienceSection;
