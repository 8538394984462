import { removeDiacriticsFromString } from "@ps/utils";
import { ProjectModel } from "../../models";
import {
  ExtendedProjectDetails,
  ExtendedProjectModel,
  FilterItem,
  IFilterOption,
  IFilters,
  PillData,
  ProjectCheckers,
} from "./types";
import {
  BD,
  BUSINESS_DEVELOPER,
  CLIENT_NAME,
  PM,
  PROJECT_MANAGER,
  PUBLIC,
  PRIVATE,
  ARCHIVED,
} from "../../constants";

export const extendProjects = (list: ProjectModel[]): ExtendedProjectModel[] =>
  list?.map((project: ProjectModel) => ({
    ...project,
    details: {
      ...project.details,
      [BD]: project.details?.[BUSINESS_DEVELOPER]
        ? `${project.details?.[BUSINESS_DEVELOPER]?.firstName} ${project.details?.[BUSINESS_DEVELOPER]?.lastName}`
        : null,
      [PM]: project.details?.[PROJECT_MANAGER]
        ? `${project.details?.[PROJECT_MANAGER]?.firstName} ${project.details?.[PROJECT_MANAGER]?.lastName}`
        : null,
      status: project.details.status || null,
    },
  })) || [];

export const removeFilterDuplicates = (
  items: IFilterOption[],
): IFilterOption[] =>
  items.reduce(
    (acc: IFilterOption[], item: IFilterOption) =>
      acc.some((item2: IFilterOption) => item2.id === item.id)
        ? acc
        : [...acc, item],
    [],
  );

export const prepareUserFilterOptions = (
  projects: ExtendedProjectModel[],
  filterName: string,
): IFilterOption[] => {
  const temp = projects
    ?.filter(
      ({
        details,
      }: {
        details: ExtendedProjectDetails;
      }): ExtendedProjectDetails => details?.[filterName]?.firstName,
    )
    .map(
      (
        { details }: { details: ExtendedProjectDetails },
        index: number,
      ): IFilterOption => ({
        id: details?.[filterName]?.userId || index.toString(),
        name:
          `${details?.[filterName]?.firstName} ${details?.[filterName]?.lastName}` ||
          "",
      }),
    );

  return removeFilterDuplicates(temp);
};

export const prepareClientFilterOptions = (
  projects: ExtendedProjectModel[],
): IFilterOption[] => {
  const temp = projects
    ?.filter(
      ({ details }: { details: ExtendedProjectDetails }) =>
        details?.[CLIENT_NAME],
    )
    .map(({ details }: { details: ExtendedProjectDetails }, index: number) => ({
      id: details?.clientId || index.toString(),
      name: details?.[CLIENT_NAME] || "",
    }));

  return removeFilterDuplicates(temp);
};

export const compareNormalizedStrings = (
  value: string,
  searchValue: string,
): boolean =>
  removeDiacriticsFromString(value.toLowerCase()).includes(
    removeDiacriticsFromString(searchValue.toLowerCase()),
  );

export const filterProjects = (
  projects: ExtendedProjectModel[],
  searchValue: string,
  filters?: IFilters,
): ExtendedProjectModel[] => {
  const preparedToFilter: ExtendedProjectModel[] = !searchValue?.length
    ? projects
    : projects.filter((item: ExtendedProjectModel) =>
        compareNormalizedStrings(item?.details?.name, searchValue),
      );

  if (!filters) return preparedToFilter;

  let result: ExtendedProjectModel[] = preparedToFilter;

  if (filters[CLIENT_NAME]?.length && result.length) {
    result = result.filter((singleProject: ExtendedProjectModel) =>
      filters[CLIENT_NAME].some(
        ({ id }: FilterItem) => id === singleProject.details.clientId,
      ),
    );
  }

  if (filters[BD]?.length && result.length) {
    result = result.filter((singleProject: ExtendedProjectModel) =>
      filters[BD].some(
        ({ id }: FilterItem) =>
          id === singleProject.details?.[BUSINESS_DEVELOPER]?.userId,
      ),
    );
  }

  if (filters[PM]?.length && result.length) {
    result = result.filter((singleProject: ExtendedProjectModel) =>
      filters[PM].some(
        ({ id }: FilterItem) =>
          id === singleProject.details?.[PROJECT_MANAGER]?.userId,
      ),
    );
  }

  return result;
};

export const selectFilterOptions = (
  data: IFilterOption[],
  searchValue: string,
): IFilterOption[] =>
  data.filter((item) =>
    removeDiacriticsFromString(item.name?.toLowerCase()).includes(
      removeDiacriticsFromString(searchValue.toLowerCase()),
    ),
  );

export const getCheckedNames = (filters: IFilters, name: string): string[] =>
  filters?.[name]?.map((item: FilterItem) => item.name);

export const filtersToPills = (filters: IFilters): PillData[] =>
  Object.entries(filters).flatMap(([key, value]: [string, PillData[]]) =>
    value.map(({ name, id }: PillData) => ({ type: key, id, name })),
  );

const filters = {
  [PUBLIC]: (project: ExtendedProjectModel) =>
    project.public && !project.archived,
  [PRIVATE]: (project: ExtendedProjectModel) =>
    !project.public && !project.archived,
  [ARCHIVED]: (project: ExtendedProjectModel) => project.archived,
};

export const filterProjectsByStatus = (
  projects: ExtendedProjectModel[],
  projectCheckers: ProjectCheckers,
): ExtendedProjectModel[] =>
  projects.filter((p) =>
    Object.entries(projectCheckers)
      .filter(([, value]: [string, boolean]) => value)
      .some(([key]: [string, boolean]) => filters[key](p)),
  );
