import { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNameFromDictionary, getOverallExperience } from "@ps/utils";
import { uid } from "react-uid";
// eslint-disable-next-line prodigal-son/no-use-mapped-state
import { useMappedState } from "redux-react-hook";
import {
  LoadingMainPage,
  SummaryProjects,
  SummarySkills,
} from "@ps/ui-components";
import { useMappedStateSelector } from "../../../hooks";
import { PROFILE_SUMMARY } from "../../../shared/data-cy";
import OtherExperienceSection from "./otherExperienceSection";
import {
  ProfileSummaryModel,
  fromProfileFulfillmentToModel,
} from "../../models";
import { MyProfileService } from "../../services";
import { SummaryMapState, SummaryMapStateReturn } from "./types";
import BasicInfo from "./components/basicInfo";
import WorkingHours from "./components/workingHours";
import Info from "./components/info";
import Skills from "./components/skills";
import Projects from "./components/projects";
import Education from "./components/education";
import Accomplishment from "./components/accomplishment";
import EducationCard from "./components/educationCard";
import {
  ARTICLES,
  AWARDS,
  CERTIFICATES,
  CONTRIBUTIONS,
  COURSES,
  DEGREES,
  TALKS,
  TUTORS,
} from "../../constants";
import { SummaryEducationModel } from "../../models/types";

const EDIT_REDIRECT_OVERVIEW_PATH = "/profile/overview";
const EDIT_REDIRECT_EXPERIENCE_PATH = "/profile/experience";
const EDIT_REDIRECT_SKILLS_PATH = "/profile/skills";

const Summary = (): ReactElement => {
  const history = useHistory();
  const [summaryProfile, setSummaryProfile] =
    useState<ProfileSummaryModel | null>(null);

  const redirect = (section: string, path: string) => (): void => {
    history.push({
      pathname: path,
      state: {
        section,
      },
    });
  };

  const mapState = (state: SummaryMapState): SummaryMapStateReturn => ({
    domainsDict: state.dictionaries?.domain || [],
    login: state.login,
    professionsDict: state.dictionaries?.profession || [],
    profile: state.profiles?.myProfile || null,
    roleDict: state.dictionaries?.project_role || [],
    seniorityDict: state.dictionaries?.seniority || [],
    technologyDict: state.dictionaries?.technology || [],
  });

  // eslint-disable-next-line prodigal-son/no-use-mapped-state
  const { login } = useMappedState(mapState);
  const {
    domainsDict,
    professionsDict,
    profile,
    roleDict,
    seniorityDict,
    technologyDict,
  } = useMappedStateSelector(mapState);

  const prepareSummaryData = async (userId: string): Promise<void> => {
    const fulfillment = await MyProfileService.fetchMyProfileSummaryFulfillment(
      userId,
      profile?.professions?.main || "",
    );

    setSummaryProfile(
      fromProfileFulfillmentToModel({ ...login, ...profile, ...fulfillment }),
    );
  };

  useEffect(() => {
    if (login && profile) {
      prepareSummaryData(login?.sub);
    }
  }, [login, profile]);

  if (!summaryProfile)
    return <LoadingMainPage additionalClassName="bg-neutralPrimary-85" />;

  const {
    avatar,
    lastName,
    firstName,
    residence,
    bio,
    updatedAt,
    timeRanges,
    careerSpan,
    professions,
    seniority,
    languages,
    experience,
    skills,
    domains,
  } = summaryProfile;

  const overallExperience = getOverallExperience(careerSpan);
  const profession = getNameFromDictionary(professionsDict, professions?.main);

  const filterExperience = (keys: string[]) =>
    Object.entries(experience).reduce(
      (acc, [key, value]) =>
        keys.includes(key) ? { ...acc, [key]: value } : acc,
      {},
    );

  const getIsolatedEducation = (
    keys: string[],
  ): Record<string, SummaryEducationModel[]> => {
    const isolatedEducation: Record<string, SummaryEducationModel[]> = {};

    keys.forEach((key) => {
      isolatedEducation[key] =
        key in
        (experience as unknown as Record<string, SummaryEducationModel[]>)
          ? (experience as unknown as Record<string, SummaryEducationModel[]>)[
              key
            ]
          : [];
    });

    return isolatedEducation;
  };
  const educationKeys = [DEGREES, COURSES];
  const accomplishmentKeys = [
    ARTICLES,
    TALKS,
    AWARDS,
    CERTIFICATES,
    TUTORS,
    CONTRIBUTIONS,
  ];

  const isolatedEducation = getIsolatedEducation(educationKeys);

  const hasEducation = educationKeys.some(
    (key: string) => !!experience?.[key]?.length,
  );

  const hasAccomplishment = accomplishmentKeys.some(
    (key: string) => !!experience?.[key]?.length,
  );

  return (
    <section
      data-cy={PROFILE_SUMMARY}
      className="flex flex-col gap-8 px-4 pt-4 pb-16 bg-neutralPrimary-100 rounded-xl"
    >
      <div className="flex">
        <BasicInfo
          avatar={avatar}
          lastName={lastName}
          firstName={firstName}
          residence={residence}
          bio={bio}
          updatedAt={updatedAt}
          timeRanges={timeRanges}
          profile={profile?.profile}
        />
        <WorkingHours
          timeRanges={timeRanges}
          onAddOrEditClick={redirect(
            "personal-info",
            EDIT_REDIRECT_OVERVIEW_PATH,
          )}
        />
      </div>
      <div className="flex">
        <div className="w-3/12 flex flex-col pr-4 gap-y-9">
          <Info
            profession={profession}
            overallExperience={overallExperience}
            seniority={seniority}
            languages={languages}
            goals={experience.goals}
            seniorityDict={seniorityDict}
            onAddOrEditClick={redirect(
              "personal-info",
              EDIT_REDIRECT_OVERVIEW_PATH,
            )}
          />
          <Education
            filled={hasEducation}
            onAddOrEditClick={redirect(
              "education",
              EDIT_REDIRECT_EXPERIENCE_PATH,
            )}
          >
            {Object.keys(isolatedEducation).map((key: string) => (
              <EducationCard
                key={uid(key)}
                title={key}
                data={isolatedEducation[key]}
              />
            ))}
          </Education>
        </div>
        <div className="flex w-9/12 border-l-2 pl-4 flex-col gap-y-10">
          <Skills
            filled={
              !!skills?.best?.length ||
              !!skills?.byProfession?.length ||
              domains?.length > 0
            }
            onAddOrEditClick={redirect("skills", EDIT_REDIRECT_SKILLS_PATH)}
          >
            <SummarySkills
              domains={domains}
              domainsDict={domainsDict}
              mainProfession={professions?.main}
              professionsDict={professionsDict}
              skills={skills}
              technologyDict={technologyDict}
            />
          </Skills>
          <Projects
            filled={!!experience?.projects?.length}
            onAddOrEditClick={redirect(
              "projects",
              EDIT_REDIRECT_EXPERIENCE_PATH,
            )}
          >
            <SummaryProjects
              projectRolesDict={roleDict}
              projects={experience.projects}
              technologyDict={technologyDict}
              domainsDict={domainsDict}
            />
          </Projects>

          <Accomplishment
            filled={hasAccomplishment}
            onAddOrEditClick={redirect(
              "accomplishment",
              EDIT_REDIRECT_EXPERIENCE_PATH,
            )}
          >
            <OtherExperienceSection
              technologyDict={technologyDict}
              otherExperience={filterExperience(accomplishmentKeys)}
            />
          </Accomplishment>
        </div>
      </div>
    </section>
  );
};
export default Summary;
