import {
  colorFormatters,
  getItemFromDictionary,
  ISOToDayJs,
  skillsColors,
  UTCToDayjs,
} from "@ps/utils";
import { getName } from "i18n-iso-countries";
import {
  APIProfileFulfillment,
  PreviewFulfillmentModel,
  ProfileSummaryModel,
  ProfileWithFullfillemnt,
  TProfileSummary,
} from "../types";
import {
  filterCurrentYear,
  filterCurrentYearWithTechnologyDict,
  prepareCareerPauseModel,
  prepareResidenceToDisplay,
  prepareWorkingHoursToDisplay,
  sortArrayByDate,
  sortArrayByDates,
} from "../helpers";
import { DictionaryModel } from "../../../dictionaries";
import { HIDDEN_FIELDS } from "./constants";

export const fromPreviewFulfillmentToModel = (
  data: APIProfileFulfillment,
  mainProfession: string,
): PreviewFulfillmentModel => ({
  skills: {
    best: data.skillsRatings.best.map((skill) => ({
      technologyId: skill.technology,
      ...skillsColors.prepareBestSkillsWithColors(
        skill,
        data?.skillsRatings?.byProfession,
        mainProfession,
      ),
    })),
    byProfession: data?.skillsRatings?.byProfession
      .map((profession) => ({
        professionId: profession.profession,
        skills: profession.ratings
          ?.filter((item) => item.score > 0)
          .map((skill) => ({
            technologyId: skill.technology,
            score: skill.score,
            color: colorFormatters.prepareRGBObjectToHEX(
              profession?.scale?.find(
                (scaleItem) => scaleItem.score === skill.score,
              )?.color,
            ),
          })),
      }))
      ?.filter((item) => item.skills.length),
  },
  domains: Object.entries(data?.domainRatings?.ratings || {})
    ?.filter(([_, el]) => !!el.knowledge) // eslint-disable-line @typescript-eslint/no-unused-vars
    .map(([key, value]) => ({
      technologyId: key,
      score: value?.knowledge,
      color: colorFormatters.prepareRGBObjectToHEX(
        data?.domainRatings?.scale?.find(
          (scaleItem) => scaleItem.score === value?.knowledge,
        )?.color,
      ),
    }))
    ?.sort((a, b) => (a?.score && b?.score && a.score > b.score ? -1 : 1)),
});

export const fromOtherProfileFulfillmentToModel = (
  data: ProfileWithFullfillemnt,
  technologyDict: DictionaryModel,
): ProfileSummaryModel => ({
  avatar: data?.avatar || "",
  firstName: data.firstName,
  lastName: data.lastName,
  bio: data.bio,
  id: data.userId,
  residence: prepareResidenceToDisplay(data.countryOfResidence, getName),
  seniority: data.seniority || "",
  projectRoles: data.projectRoles || [],
  professions: data?.professions
    ? {
        main: data?.professions?.main,
        secondary: data?.professions?.secondary,
      }
    : undefined,
  careerSpan: data?.careerSpan
    ? {
        startDate: ISOToDayJs(data?.careerSpan?.startDate),
        gapMonths: prepareCareerPauseModel(data?.careerSpan?.gapMonths),
      }
    : undefined,
  timeRanges: data?.workingHours
    ? prepareWorkingHoursToDisplay(data.workingHours)
    : null,
  languages: data.languages,
  skills: data.skills,
  domains: data.domains,
  updatedAt: data.updatedAt ? UTCToDayjs(data.updatedAt) : null,
  experience: {
    projects: sortArrayByDate(
      data.experience?.projects?.map((project) => ({
        domain: project.domain,
        name: project.name,
        startDate: ISOToDayJs(project.startDate),
        endDate: ISOToDayJs(project.endDate),
        description: project.desc,
        responsibility: project.responsibility,
        projectRoles: project.projectRoles,
        techStack: project.techStack.map((item) => ({
          ...getItemFromDictionary(technologyDict, item),
          technologyId: item,
        })),
      })),
    ),
    courses: sortArrayByDates(
      data.experience?.educations
        ?.filter((item) => item.education === "course")
        .map((course) => ({
          startDate: ISOToDayJs(course.startDate),
          endDate: ISOToDayJs(course.endDate) || undefined,
          description: course.desc,
          education: course.education,
          trainer:
            course.trainer && HIDDEN_FIELDS.includes(course.trainer)
              ? ""
              : course.trainer,
          courseName: course.courseName,
        })),
    ),
    degrees: sortArrayByDates(
      data.experience.educations
        ?.filter((item) => item.education === "degree")
        .map((degree) => ({
          startDate: ISOToDayJs(degree.startDate),
          endDate: ISOToDayJs(degree.endDate) || undefined,
          description: degree.desc,
          schoolName: degree.schoolName,
          fieldOfStudy: degree.fieldOfStudy,
          degree:
            degree.degree && HIDDEN_FIELDS.includes(degree.degree)
              ? ""
              : degree.degree,
        })),
    ),
    articles: sortArrayByDates(
      data.experience?.articles.map((article) => ({
        title: article.title,
        publicationDate: ISOToDayJs(article.publicationDate),
        url: article.url,
        techStack: article.techStack.map((item) => ({
          ...getItemFromDictionary(technologyDict, item),
          technologyId: item,
        })),
      })),
    ),
    talks: sortArrayByDates(
      data.experience?.talks.map((talk) => ({
        eventName: HIDDEN_FIELDS.includes(talk.eventName) ? "" : talk.eventName,
        title: talk.title,
        videoUrl: talk.videoUrl,
        url: talk.url,
        techStack: talk.techStack.map((item) => ({
          ...getItemFromDictionary(technologyDict, item),
          technologyId: item,
        })),
      })),
    ),
    contributions: sortArrayByDates(
      data.experience?.contributions.map((contribution) => ({
        name: contribution.name,
        url: contribution.url,
        startDate: ISOToDayJs(contribution.startDate),
        endDate: ISOToDayJs(contribution.endDate) || undefined,
        techStack: contribution.techStack.map((item) => ({
          ...getItemFromDictionary(technologyDict, item),
          technologyId: item,
        })),
      })),
    ),
    tutors: sortArrayByDates(
      data.experience?.tutors.map((tutor) => ({
        title: tutor.title,
        description: tutor.desc,
        date: ISOToDayJs(tutor.date) || undefined,
        techStack: tutor.techStack.map((item) => ({
          ...getItemFromDictionary(technologyDict, item),
          technologyId: item,
        })),
      })),
    ),
    awards: sortArrayByDate(
      data.experience?.awards.map((award) => ({
        awardName: award.awardName,
        rewarder: award.rewarder,
        date: ISOToDayJs(award.date),
        description: award.description,
        techStack: award.techStack.map((item) => ({
          ...getItemFromDictionary(technologyDict, item),
          technologyId: item,
        })),
      })),
    ),
    certificates: sortArrayByDates(
      data.experience?.certificates.map((certificate) => ({
        title: certificate.title,
        url: certificate.url,
        issuedDate: ISOToDayJs(certificate.issuedDate),
        expirationDate: ISOToDayJs(certificate.expirationDate) || undefined,
        techStack: certificate.techStack.map((item) => ({
          ...getItemFromDictionary(technologyDict, item),
          technologyId: item,
        })),
      })),
    ),
    goals: filterCurrentYearWithTechnologyDict(data.goals, technologyDict),
  },
});

export const fromProfileFulfillmentToModel = (
  data: TProfileSummary,
): ProfileSummaryModel => ({
  avatar: data?.avatar,
  firstName: data.firstName,
  lastName: data.secondName,
  bio: data.shortInformation,
  id: data.id,
  residence: data.residence,
  seniority: data.seniority,
  projectRoles: data.roles || [],
  professions: data.professions,
  careerSpan: data.careerSpan,
  timeRanges: data.workingHours,
  languages: data.languages,
  skills: data.skills,
  domains: data.domains,
  updatedAt: data.updatedAt,
  experience: {
    projects: data.experience?.projects?.map((project) => ({
      domain: project.domain,
      name: project.name,
      startDate: project.startDate,
      endDate: project.endDate || undefined,
      description: project.description,
      responsibility: project.responsibility,
      projectRoles: project.projectRoles,
      techStack: project.techStack?.map((item) => ({
        ...item,
        technologyId: item?.id,
      })),
    })),
    courses: data.experience?.courses?.map((course) => ({
      startDate: course.startDate,
      endDate: course.endDate || undefined,
      description: course.description,
      education: course.education,
      trainer:
        course.trainer && HIDDEN_FIELDS.includes(course.trainer)
          ? ""
          : course.trainer,
      courseName: course.courseName,
    })),
    degrees: data.experience?.degrees?.map((degree) => ({
      startDate: degree.startDate,
      endDate: degree.endDate || undefined,
      description: degree.description,
      schoolName: degree.schoolName,
      fieldOfStudy: degree.fieldOfStudy,
      degree:
        degree.degree && HIDDEN_FIELDS.includes(degree.degree)
          ? ""
          : degree.degree,
    })),
    articles: data.experience?.articles.map((article) => ({
      title: article.title,
      publicationDate: article.date,
      url: article.url,
      techStack: article.techStack.map((item) => ({
        ...item,
        technologyId: item.id,
      })),
    })),
    talks: data.experience?.talks.map((talk) => ({
      eventName: HIDDEN_FIELDS.includes(talk.eventName) ? "" : talk.eventName,
      title: talk.title,
      videoUrl: talk.videoUrl,
      url: talk.url,
      techStack: talk.techStack.map((item) => ({
        ...item,
        technologyId: item.id,
      })),
    })),
    contributions: data.experience?.contributions.map((contribution) => ({
      name: contribution.name,
      url: contribution.url,
      startDate: ISOToDayJs(contribution.startDate),
      endDate: ISOToDayJs(contribution.endDate) || undefined,
      techStack: contribution.techStack.map((item) => ({
        ...item,
        technologyId: item.id,
      })),
    })),
    tutors: data.experience?.tutors.map((tutor) => ({
      title: tutor.title,
      description: tutor.description,
      date: ISOToDayJs(tutor.date) || undefined,
      techStack: tutor.techStack.map((item) => ({
        ...item,
        technologyId: item.id,
      })),
    })),
    awards: data.experience?.awards.map((award) => ({
      awardName: award.awardName,
      rewarder: award.rewarder,
      date: award.date,
      description: award.description,
      techStack: award.techStack.map((item) => ({
        ...item,
        technologyId: item.id,
      })),
    })),
    certificates: data.experience?.certificates.map((certificate) => ({
      title: certificate.title,
      url: certificate.url,
      issuedDate: ISOToDayJs(certificate.issuedDate),
      expirationDate: ISOToDayJs(certificate.expirationDate) || undefined,
      techStack: certificate.techStack.map((item) => ({
        ...item,
        technologyId: item.id,
      })),
    })),
    goals: data.goals && filterCurrentYear(data.goals),
  },
});
