import { HolidaysUsersModel, HolidaysUserModel } from "../models";

const getTemplateFilterOptions = (
  holidaysUsers: HolidaysUsersModel,
): string[] => [
  ...new Set<string>(
    holidaysUsers
      ?.map(
        (user: HolidaysUserModel): string =>
          user.holidaysTemplate?.templateName || "",
      )
      .flat()
      .filter((value: string | undefined) => value),
  ),
];

export default getTemplateFilterOptions;
