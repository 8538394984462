/* eslint @typescript-eslint/no-explicit-any: 0 */
import { createContext } from "react";
import { PermissionsContextType } from "./types";

const PermissionsContext = createContext<PermissionsContextType>({
  onSubmit: () => {
    // do nothing
  },
  roleIndexInEdit: null,
  setRoleIndexInEdit: (newValue: number | null) => newValue,
  isAddingNew: null,
  setIsAddingNew: (newValue: boolean) => newValue,
});

export default PermissionsContext;
