import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import styles from "../styles.module.css";

const Loader = (): ReactElement => (
  <div className="w-full h-6 bg-neutralSecondary-80 rounded-full flex items-center opacity-40 relative">
    <div
      className={classJoin(
        styles.loader,
        "h-full rounded-full absolute flex justify-center items-center bg-neutralPrimary-85",
      )}
    />
  </div>
);

export default Loader;
