import { forwardRef } from "react";
import { classJoin } from "@ps/utils";
import { ClickElementProps } from "./types";
import { ReactComponent as ArrowDown } from "../../images/arrowDown.svg";

const wrapperStyle = `group cursor-pointer select-none flex justify-between items-center gap-x-4 px-5 bg-neutralPrimary-100 
border rounded-md p-2 hover:text-neutralPrimary-20 hover:border-neutralPrimary-40 transition-all`;

const ClickElement = forwardRef<HTMLDivElement, ClickElementProps>(
  ({ width, label, activeAmount, open, dataCy }, ref) => {
    const openStyle = open
      ? "text-neutralPrimary-20 border-neutralPrimary-40 z-40"
      : "text-neutralPrimary-40 border";

    const arrowStyle = open
      ? "text-neutralPrimary-20"
      : "transition-all, text-neutralPrimary-40 group-hover:text-neutralPrimary-20";

    return (
      <div
        ref={ref}
        data-cy={dataCy}
        className={classJoin(wrapperStyle, openStyle, width)}
      >
        <span className="font-semibold">{label}</span>
        <div className="bg-other-toxicGreen px-2 rounded-lg text-black font-semibold">
          {activeAmount}
        </div>
        <div className={classJoin(arrowStyle)}>
          <ArrowDown />
        </div>
      </div>
    );
  },
);

export default ClickElement;
