import { ComponentType, ReactElement, useState } from "react";
import { uid } from "react-uid";
import {
  SummaryExperienceExpandedCard,
  SummaryTemplates,
} from "@ps/ui-components";
import {
  ARTICLES,
  AWARDS,
  CERTIFICATES,
  CONTRIBUTIONS,
  GOALS,
  TALKS,
  TUTORS,
} from "../../experience/sections/accomplishment/constants";
import {
  COURSES,
  DEGREES,
} from "../../experience/sections/education/constants";
import { OtherExperienceSectionProps } from "../types";

const OtherExperienceSection = ({
  otherExperience,
  technologyDict,
}: OtherExperienceSectionProps): ReactElement => {
  const [openedSection, setOpenedSection] = useState("");
  const {
    TalksTemplate,
    CertificatesTemplate,
    TutorTemplate,
    ArticlesTemplate,
    DegreeTemplate,
    CourseTemplate,
    AwardsTemplate,
    GoalsTemplate,
    ContributionTemplate,
  } = SummaryTemplates;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const properTemplate = (section: string): ComponentType<any> => {
    switch (section) {
      case TALKS:
        return TalksTemplate;
      case CERTIFICATES:
        return CertificatesTemplate;
      case TUTORS:
        return TutorTemplate;
      case ARTICLES:
        return ArticlesTemplate;
      case DEGREES:
        return DegreeTemplate;
      case COURSES:
        return CourseTemplate;
      case AWARDS:
        return AwardsTemplate;
      case GOALS:
        return GoalsTemplate;
      case CONTRIBUTIONS:
      default:
        return ContributionTemplate;
    }
  };

  const groupedOtherExperience = Object.entries(otherExperience).reduce(
    (acc, [key, value]) => {
      if (value?.length) {
        return { ...acc, [key]: value };
      }
      return acc;
    },
    {},
  );

  return (
    <section className="flex md:flex-wrap flex-col md:flex-row gap-6">
      {Object.entries(groupedOtherExperience).map(([key, value]) => (
        <SummaryExperienceExpandedCard
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data={value}
          key={uid(value)}
          onClick={setOpenedSection}
          openedSection={openedSection}
          technologyDict={technologyDict}
          Template={properTemplate(key)}
          title={key}
        />
      ))}
    </section>
  );
};

export default OtherExperienceSection;
