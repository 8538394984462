import { ReactElement } from "react";
import { EmptyListProps } from "./types";
import { useTranslationWithNamespace } from "../../hooks";
import { ReactComponent as SearchingWomen } from "../../images/searching.svg";

const EmptyList = ({
  searchValue,
  Image,
  text,
}: EmptyListProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  if (searchValue)
    return (
      <div className="p-10 w-full h-full flex gap-10 justify-center items-center select-none">
        <div className="w-1/2 flex items-center justify-end">
          <SearchingWomen className="w-56" />
        </div>
        <div className="h-full w-1/2 justify-start flex flex-col gap-y-6 items-center text-neutralPrimary-20">
          <div className="flex justify-center items-start h-full w-full flex-col">
            <h3 className="w-full text-xl">
              {t(`emptyList.noResults`, {
                searchValue:
                  searchValue.length > 10
                    ? searchValue.substring(0, 10).concat("...")
                    : searchValue,
              })}
            </h3>
          </div>
        </div>
      </div>
    );

  return (
    <div className="flex items-center justify-center gap-10">
      <div className="flex flex-col items-center gap-4">
        {Image}
        <span className="text-neutralPrimary-20 font-bold text-3xl">
          {text}
        </span>
      </div>
    </div>
  );
};

export default EmptyList;
