import {
  ReactElement,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
} from "react";
import { FocusTrap } from "@mui/base";
import { classJoin } from "@ps/utils";
import { ReactComponent as CloseIcon } from "../../images-2/close-modal.svg";
import {
  MODAL_BODY_PREFIX,
  MODAL_CLOSE_ICON_PREFIX,
  MODAL_OVERLAY_PREFIX,
  MODAL_PREFIX,
} from "../../shared/data-cy";
import { focusVisibleStyles, Keys } from "../../shared";
import { ModalProps } from "./types";
import { useFontSizeMode } from "../../components/font-size-provider";
import { useThemeMode } from "../../components/theme-mode-provider";

const Modal = ({
  additionalContentClassName,
  additionalOuterClassName,
  background = "bg-neutralPrimary-100",
  children,
  dataCy,
  height,
  isOpen,
  onClose,
  padding = "p-8",
  width,
  withCloseIcon,
  withOutsideClick = false,
  withYScroll = true,
  hiddenLayer,
  hiddenLayerStyles,
  hiddenLayerInlineStyle,
  overlayHide,
}: ModalProps): ReactElement => {
  const { createPortal, isHighContrast } = useThemeMode();
  const { fontSize } = useFontSizeMode();
  const modalRef = useRef<HTMLDivElement>(null);
  const layerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!modalRef.current) return;
    const modalRect = modalRef.current.getBoundingClientRect();
    if (!layerRef.current) return;
    layerRef.current.style.position = "absolute";
    layerRef.current.style.height = `${modalRect?.height}px`;
    layerRef.current.style.top = `${modalRect?.top}px`;
  }, [isOpen]);

  if (!isOpen) return <></>;
  return createPortal(
    <FocusTrap open>
      <div
        className={classJoin(
          `inset-0 absolute h-full w-auto
              flex justify-center items-center
              z-20 text-neutralPrimary-20
              focus:outline-none cursor-default`,
          overlayHide ? "bg-transparent" : "bg-black bg-opacity-70",
        )}
        data-cy={`${MODAL_OVERLAY_PREFIX}-${dataCy}`}
        onClick={withOutsideClick ? onClose : undefined}
        role={withOutsideClick || withCloseIcon ? "button" : "presentation"}
        tabIndex={withOutsideClick || withCloseIcon ? 0 : undefined}
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
          if (event.key === Keys.ESC && withOutsideClick) onClose();
        }}
        data-font-size={fontSize}
      >
        <div
          id="modalContent"
          ref={modalRef}
          className={classJoin(
            "rounded-2.5xl absolute shadow-2xl z-40",
            background,
            padding,
            width,
            height,
            additionalOuterClassName,
            isHighContrast ? "border border-neutralSecondary-40" : "",
          )}
          data-cy={`${MODAL_PREFIX}-${dataCy}`}
          role="presentation"
          onClick={(event: MouseEvent<HTMLDivElement>) =>
            event.stopPropagation()
          }
        >
          {withCloseIcon && (
            <CloseIcon
              className={classJoin(
                "text-neutralSecondary-60 fill-current ml-auto absolute cursor-pointer right-4 top-4",
                focusVisibleStyles,
              )}
              data-cy={`${MODAL_CLOSE_ICON_PREFIX}-${dataCy}`}
              onClick={onClose}
              role="button"
              tabIndex={0}
              onKeyDown={(event: KeyboardEvent<SVGSVGElement>) => {
                if (event.key === Keys.ENTER) onClose();
              }}
            />
          )}
          <div
            className={classJoin(
              withYScroll && "overflow-y-auto",
              additionalContentClassName,
            )}
            data-cy={`${MODAL_BODY_PREFIX}-${dataCy}`}
          >
            {children}
          </div>
        </div>
        {hiddenLayer && (
          <div
            ref={layerRef}
            style={hiddenLayerInlineStyle}
            className={hiddenLayerStyles}
          >
            {hiddenLayer}
          </div>
        )}
      </div>
    </FocusTrap>,
    document.body,
  );
};

export default Modal;
