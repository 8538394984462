import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { Resume } from "@ps/pdf-generator";
import { ANONYMOUS, BLIND } from "../../constants";
import { DictionaryModel } from "../../../dictionaries";
import {
  OperativeDomainModel,
  ProfileSummaryModel,
  SpecialistDomainModel,
} from "../../models";
import { Avatar } from "./types";

const generatePdfDocument = async (
  professionDict: DictionaryModel,
  seniorityDict: DictionaryModel,
  technologyDict: DictionaryModel,
  roleDict: DictionaryModel,
  domainsDict: DictionaryModel,
  currentProfile:
    | OperativeDomainModel
    | SpecialistDomainModel
    | ProfileSummaryModel,
  generationOption: "anonymous" | "blind" | undefined,
  avatar: Avatar,
): Promise<void> => {
  const prepareFileName = (): string => {
    const prefix = "CV";
    if (generationOption === ANONYMOUS)
      return `${prefix}-${currentProfile.firstName}-A`;

    if (generationOption === BLIND) return `${prefix}-${currentProfile.id}-B}`;
    return `${prefix}-${currentProfile.firstName}-${currentProfile.lastName}`;
  };

  const blob = await pdf(
    <Resume
      avatar={avatar}
      domainDict={domainsDict}
      isAnonymous={generationOption === ANONYMOUS}
      isBlind={generationOption === BLIND}
      professionDict={professionDict}
      profile={currentProfile}
      roleDict={roleDict}
      seniorityDict={seniorityDict}
      technologyDict={technologyDict}
    />,
  ).toBlob();

  saveAs(blob, prepareFileName());
};

export default generatePdfDocument;
