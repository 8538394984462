import { ReactElement } from "react";
import { getNameFromDictionary } from "@ps/utils";
import { uid } from "react-uid";
import { Label, CardHeader } from "@ps/ui-components";
import { ReactComponent as ProfessionsIcon } from "../../../../images/profile-summary/profession.svg";
import { PROFILE_SUMMARY_EXPERIENCE } from "../../../../shared/data-cy";
import { ProfessionsProps } from "./types";
import { useTranslationWithNamespace } from "../../../../hooks";

const Professions = ({
  professions,
  professionsDict,
}: ProfessionsProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const renderMainProfession = () =>
    getNameFromDictionary(professionsDict, professions?.main);

  const renderSecondaryProfessions = () =>
    professions?.secondary?.map((item, index) => (
      <span
        className="font-normal text-neutralSecondary-41"
        key={uid(`${index}-bigScreen`)}
      >
        {getNameFromDictionary(professionsDict, item)}
      </span>
    ));

  return (
    <CardHeader
      Icon={ProfessionsIcon}
      iconColor="text-primary-50"
      dataCy={`${PROFILE_SUMMARY_EXPERIENCE}_professions`}
    >
      <Label
        dataCy={`${PROFILE_SUMMARY_EXPERIENCE}_professions`}
        text={t("profile.summary.professions")}
      >
        <div className="flex flex-col gap-1 w-auto">
          <span className="font-extrabold">{renderMainProfession()}</span>
          {renderSecondaryProfessions()}
        </div>
      </Label>
    </CardHeader>
  );
};

export default Professions;
