import { ReactElement, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import HolidaysHistory from "./holidaysHistory";
import {
  HolidaysHistoryContainerProps,
  HolidaysHistoryPropsMapState,
  HolidaysHistoryPropsMapStateReturn,
} from "../types";
import { getTemplateHolidayEntriesById } from "../utils";
import { useMappedStateSelector } from "../../../../../hooks";
import { fetchUserHolidaysHistory } from "../../../../store/actions";

const HolidaysHistoryContainer = ({
  userId,
  userTemplateId,
}: HolidaysHistoryContainerProps): ReactElement => {
  const dispatch = useDispatch();

  const mapState = (
    state: HolidaysHistoryPropsMapState,
  ): HolidaysHistoryPropsMapStateReturn => ({
    userHolidaysHistory: state?.holidays?.holidaysUserHistory || [],
    userTemplateEntries: userTemplateId
      ? getTemplateHolidayEntriesById(
          userTemplateId,
          state?.holidaysTemplates?.holidaysTemplates || [],
        )
      : [],
  });

  const { userHolidaysHistory, userTemplateEntries } =
    useMappedStateSelector(mapState);

  useEffect(() => {
    if (userId) {
      fetchUserHolidaysHistory(dispatch, userId);
    }
  }, []);

  return (
    <HolidaysHistory
      isAdmin
      history={userHolidaysHistory}
      templateEntries={userTemplateEntries}
    />
  );
};

export default HolidaysHistoryContainer;
