import { FC, SVGProps } from "react";
import { ReactComponent as DevopsIcon } from "../../images/skills-professions/devops.svg";
import { ReactComponent as DataBaseIcon } from "../../images/skills-professions/data.svg";
import { ReactComponent as TesterIcon } from "../../images/skills-professions/qa.svg";
import { ReactComponent as FrontendIcon } from "../../images/skills-professions/fe.svg";
import { ReactComponent as ScalaIcon } from "../../images/skills-professions/scala.svg";
import { ReactComponent as DomainsIcon } from "../../images/skills-professions/domains.svg";

import {
  ARTICLES,
  AWARDS,
  CERTIFICATES,
  CONTRIBUTIONS,
  COURSES,
  DEGREES,
  GOALS,
  LANGUAGES,
  TALKS,
  TUTORS,
} from "./sections/constants";
import { ReactComponent as CoursesIcon } from "../../images/profile-summary/courses.svg";
import { ReactComponent as ArticlesIcon } from "../../images/profile-summary/articles.svg";
import { ReactComponent as TalksIcon } from "../../images/profile-summary/talks.svg";
import { ReactComponent as ContributionsIcon } from "../../images/profile-summary/contributions.svg";
import { ReactComponent as TutorsIcon } from "../../images/profile-summary/tutors.svg";
import { ReactComponent as AwardsIcon } from "../../images/profile-summary/awards.svg";
import { ReactComponent as CertificatesIcon } from "../../images/profile-summary/certificates.svg";
import { ReactComponent as LanguagesIcon } from "../../images/profile-summary/flag.svg";
import { ReactComponent as GoalsIcon } from "../../images/profile-summary/goal.svg";

export const renderProfessionIcon = (
  professionId: string,
): FC<SVGProps<SVGSVGElement>> => {
  switch (professionId) {
    case "devops":
      return DevopsIcon;
    case "data":
      return DataBaseIcon;
    case "quality_assurance":
      return TesterIcon;
    case "frontend":
      return FrontendIcon;
    case "scala":
      return ScalaIcon;
    default:
      return DomainsIcon;
  }
};

export const renderSectionIcon = (
  professionId: string,
): FC<SVGProps<SVGSVGElement>> => {
  switch (professionId) {
    case COURSES:
    case DEGREES:
      return CoursesIcon;
    case ARTICLES:
      return ArticlesIcon;
    case TALKS:
      return TalksIcon;
    case CONTRIBUTIONS:
      return ContributionsIcon;
    case TUTORS:
      return TutorsIcon;
    case AWARDS:
      return AwardsIcon;
    case CERTIFICATES:
      return CertificatesIcon;
    case LANGUAGES:
      return LanguagesIcon;
    case GOALS:
      return GoalsIcon;
    default:
      return CoursesIcon;
  }
};
