import { ReactElement, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { useParams } from "react-router-dom";
import {
  LoadingMainPage,
  MiddleContent,
  MiddleSection,
  TabsPlaceholder,
} from "@ps/ui-components";
import { fetchOtherProfileWithFulfillment } from "../../profile-domain";
import { FOLDERS_LIST } from "../../shared/data-cy";
import { useMappedStateSelector } from "../../hooks";
import { OtherSummaryReturn, OtherSummaryState } from "./types";
import { OtherProfileSummaryWithPDF } from "../../profile-domain/ui/other-profile-summary";

const OtherSummary = (): ReactElement => {
  const dispatch = useDispatch();
  const { profileId } = useParams<{
    profileId: string;
  }>();

  const mapState = (state: OtherSummaryState): OtherSummaryReturn => ({
    technologyDict: state.dictionaries.technology,
    currentProfile: state.profiles.otherProfiles?.[profileId] || null,
  });

  const { technologyDict, currentProfile } = useMappedStateSelector(mapState);

  useEffect(() => {
    if (!currentProfile) {
      fetchOtherProfileWithFulfillment(profileId, dispatch, technologyDict);
    }
  }, [currentProfile, technologyDict]);

  return (
    <MiddleSection dataCy={FOLDERS_LIST}>
      <TabsPlaceholder />
      <MiddleContent dataCy={FOLDERS_LIST}>
        {!currentProfile ? (
          <LoadingMainPage additionalClassName="bg-neutralPrimary-85 w-full h-full" />
        ) : (
          <OtherProfileSummaryWithPDF profile={currentProfile} />
        )}
      </MiddleContent>
    </MiddleSection>
  );
};

export default OtherSummary;
