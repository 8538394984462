import { classJoin } from "@ps/utils";
import React, { ReactElement } from "react";
import { ReactComponent as DoubleThumbUpIcon } from "../../../../../images/icons/double-thumb-up.svg";
import { ReactComponent as ThumbDownIcon } from "../../../../../images/icons/thumb-down.svg";
import { ReactComponent as NeutralIcon } from "../../../../../images/icons/neutral.svg";
import { ReactComponent as ThumbUpIcon } from "../../../../../images/icons/thumb-up.svg";
import { EnjoymentIconProps } from "../types";

const enjoyIconStyle =
  "fill-current w-6 h-6 text-neutralSecondary-60 absolute bottom-1 left-1";

const EnjoymentIcon = ({
  enjoymentScale,
}: EnjoymentIconProps): ReactElement => {
  switch (enjoymentScale) {
    case 0:
      return (
        <DoubleThumbUpIcon
          className={classJoin(enjoyIconStyle, "transform rotate-180")}
        />
      );
    case 1:
      return <ThumbDownIcon className={enjoyIconStyle} />;
    case 2:
      return <NeutralIcon className={enjoyIconStyle} />;
    case 3:
      return <ThumbUpIcon className={enjoyIconStyle} />;
    case 4:
      return <DoubleThumbUpIcon className={enjoyIconStyle} />;
    default:
      return <></>;
  }
};

export default EnjoymentIcon;
