import { ReactElement } from "react";
import { ProjectSummary } from "@ps/hh";
import { SearchSelect, TimeTrackerProjectName } from "@ps/ui-components";
import { PROJECT_NAME_LABEL, SELECT_PROJECT } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import { SelectProjectProps } from "./types";

const BASE_PATH = "timesheet.timelog.sections";

const SelectProject = ({
  currentProjectId,
  currentProjectName,
  handleSelectOnChange,
  projects,
  options,
  selectRef,
  menuIsOpen,
}: SelectProjectProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const changeProjectToValueLabel = (singleProject: ProjectSummary) => ({
    value: singleProject.id,
    label: (
      <TimeTrackerProjectName
        color={singleProject?.details?.color}
        dataCy={`${PROJECT_NAME_LABEL}-${singleProject.id}`}
        label={singleProject?.details?.name}
      />
    ),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customFilter = (option: { data: any }, searchText: string) =>
    option.data.label.props.label
      .toLowerCase()
      .includes(searchText.toLowerCase());

  return (
    <SearchSelect
      dataCy={SELECT_PROJECT}
      placeholder={currentProjectName || t(`${BASE_PATH}.select`)}
      onChange={handleSelectOnChange}
      options={options?.map((unusedProject: ProjectSummary) =>
        changeProjectToValueLabel(unusedProject),
      )}
      width="xl:w-96 w-64"
      height="auto"
      defaultValue={projects
        .map((singleProject: ProjectSummary) =>
          changeProjectToValueLabel(singleProject),
        )
        .find(
          (singleProject: { value: string; label: JSX.Element }) =>
            singleProject.value === currentProjectId,
        )}
      searchable
      filterOption={customFilter}
      openMenuOnFocus
      ref={selectRef}
      menuIsOpen={menuIsOpen}
      autoFocus
    />
  );
};

export default SelectProject;
