import { ReactElement, useEffect, useState } from "react";
import { ScrollableWrapper } from "@ps/ui-components";
import { Scrollbars } from "react-custom-scrollbars-2";
import { OtherProfileSummaryWithPDFProps } from "./types";
import OtherProfileSummary from "./otherProfileSummary";
import PDFGeneratorButton from "./pdfGeneratorButton";
import { ANONYMOUS, BLIND } from "../../constants";
import { ProfileService } from "../../index";

const OtherProfileSummaryWithPDF = ({
  otherActions,
  profile,
}: OtherProfileSummaryWithPDFProps): ReactElement => {
  const [avatarBase64, setAvatarBase64] = useState<string>();
  const [isAvatarReady, setIsAvatarReady] = useState(false);

  const handleOnFetchAvatar = async (): Promise<void> => {
    if (profile?.avatar) {
      const result = await ProfileService.fetchBase64Avatar(profile?.avatar);
      if (result) {
        setAvatarBase64(result);
      }
    }
    setIsAvatarReady(true);
  };

  useEffect(() => {
    handleOnFetchAvatar();
  }, [profile?.avatar]);

  useEffect(() => {
    if (profile?.avatar !== null || profile?.avatar) {
      handleOnFetchAvatar();
    } else {
      setIsAvatarReady(true);
    }
  }, []);

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex items-center justify-between px-4 pb-4">
        {otherActions}
        <div className="flex gap-5 ml-auto">
          <PDFGeneratorButton
            avatar={avatarBase64}
            currentProfile={profile}
            disabled={!isAvatarReady}
          />
          <PDFGeneratorButton
            avatar={avatarBase64}
            currentProfile={profile}
            disabled={!isAvatarReady}
            generationOption={ANONYMOUS}
          />
          <PDFGeneratorButton
            currentProfile={profile}
            disabled={!isAvatarReady}
            generationOption={BLIND}
          />
        </div>
      </div>
      <ScrollableWrapper value={45}>
        <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
          <OtherProfileSummary
            avatar={profile?.avatar}
            currentProfile={profile}
          />
        </Scrollbars>
      </ScrollableWrapper>
    </div>
  );
};

export default OtherProfileSummaryWithPDF;
