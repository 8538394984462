import { UTCToDayjs } from "@ps/utils";
import {
  BenchUserAPIModel,
  BenchUserModel,
  BenchUsersModel,
  BenchUserFolderModel,
  BenchUserFolderAPIModel,
  BenchUserProjectsModel,
  BenchUserProjectsAPIModel,
} from "../types";

const benchUsersFromAPIToModel = (data: BenchUserAPIModel[]): BenchUsersModel =>
  data.map(
    (user: BenchUserAPIModel): BenchUserModel => ({
      userId: user.id,
      name: user.name,
      availability: UTCToDayjs(user.availability),
      putOnBench: UTCToDayjs(user?.putOnBench || ""),
      reason: user.status,
      profession: user?.professions?.[0] || "",
      seniority: user?.seniority || "",
      location: user?.location || "",
      firstRole: user?.projectRoles?.[0] || "",
      roles: user?.projectRoles,
      daysOnBench: user.daysOnBench,
      projects: user?.projects
        ? Object.keys(user?.projects ?? {}).map(
            (id: string): BenchUserProjectsModel => {
              const project: BenchUserProjectsAPIModel =
                user?.projects?.[id] ?? {};
              return {
                id,
                startDate: project.startDate,
                endDate: project.endDate,
                feedback: project.feedback,
                engagement: project.engagement,
                projectName: project.projectName,
              };
            },
          )
        : [],
      folders: Object.keys(user?.folders)?.map(
        (folderId: string): BenchUserFolderModel => {
          const folder: BenchUserFolderAPIModel = user?.folders?.[folderId];
          return {
            folderId,
            folderName: folder.folderName,
            status: folder.status,
            notes: folder.notes,
          };
        },
      ),
    }),
  );

export default benchUsersFromAPIToModel;
