/* eslint-disable jsx-a11y/label-has-associated-control */
import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import styles from "./styles.module.css";
import { ReactComponent as LightModeIcon } from "../../images/lightmode.svg";
import { ReactComponent as DarkModeIcon } from "../../images/darkmode.svg";
import { THEME_TOGGLE_PREFIX } from "../../shared/data-cy";
import { ThemeToggleProps } from "./types";
import { focusVisibleStyles, Keys } from "../../shared";

const ThemeToggle = ({
  dataCy,
  checked,
  onChange,
  additionalClassName,
}: ThemeToggleProps): ReactElement => (
  <div
    className={classJoin("rounded-md", focusVisibleStyles, additionalClassName)}
    data-cy={`${THEME_TOGGLE_PREFIX}-${dataCy}`}
    role="button"
    tabIndex={0}
    onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === Keys.ENTER) onChange();
    }}
  >
    <label htmlFor="theme-toggle">
      <input
        type="checkbox"
        name="theme-toggle"
        id="theme-toggle"
        className={classJoin(styles.toggleinput, "hidden")}
        checked={checked}
        onChange={onChange}
      />
      <span className={styles.togglebutton}>
        <DarkModeIcon
          className={classJoin(styles.toggleicon, "text-primary-100")}
        />
        <LightModeIcon
          className={classJoin(styles.toggleicon, "text-primary-100")}
        />
      </span>
    </label>
  </div>
);

export default ThemeToggle;
