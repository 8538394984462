import { ReactElement } from "react";
import { Avatar, Button2, Icon, Tooltip } from "@ps/ui-components";
import { classJoin, getNameFromDictionary, setSeniorityColor } from "@ps/utils";
import { FOLDER_CANDIDATE_AVATAR } from "../../../../../shared/data-cy";
import { ReactComponent as NoAvatarIcon } from "../../../../../../images/no-avatar.svg";
import styles from "./styles.module.css";
import { TeamListProps } from "./types";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { TEAM_GENERATOR } from "../../../../../../shared/data-cy";

const TRANSLATION_PATH = "projects.prospect.proposalFolder.teamGenerator";

const TeamList = ({
  seniorityDict,
  professionDict,
  team,
  onAddTeamToFolder,
  areCandidatesAdded,
}: TeamListProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const normalizedSeniority = (seniority: string): string => {
    const seniorityName = getNameFromDictionary(seniorityDict, seniority);
    return seniorityName.charAt(0).toUpperCase() + seniorityName.slice(1);
  };

  const renderProfessions = (professions: string[]) =>
    professions.map((p: string, i: number) => (
      <span className="font-semibold">
        {getNameFromDictionary(professionDict, p)}
        {i !== professions.length - 1 && ", "}
      </span>
    ));

  return (
    <>
      <div className="shadow-2xl rounded-t-xl pl-8 pr-2 pt-4">
        <div
          className={classJoin(
            "grid grid-flow-row grid-cols-2 place-content-start overflow-auto gap-y-4",
            styles.teamList,
          )}
        >
          {team?.map((teamMate, index: number) => (
            <div
              key={teamMate?.userId}
              data-cy={`${TEAM_GENERATOR}_user`}
              className={classJoin(
                "flex gap-x-2",
                index === team.length - 1 && "pb-2",
              )}
            >
              {teamMate?.avatar ? (
                <Avatar
                  image={teamMate?.avatar}
                  dataCy={FOLDER_CANDIDATE_AVATAR}
                  width={16}
                  height={16}
                />
              ) : (
                <Icon
                  icon={<NoAvatarIcon className="text-neutralSecondary-60" />}
                  dataCy={FOLDER_CANDIDATE_AVATAR}
                  size="16"
                />
              )}
              <div className="flex flex-col leading-5 text-neutralPrimary-20">
                <span className="mb-1 font-bold">
                  {teamMate?.firstName} {teamMate?.lastName}
                </span>
                <div className="flex gap-x-1">
                  <span>
                    {getNameFromDictionary(
                      professionDict,
                      teamMate?.mainProfession,
                    )}
                  </span>
                  <div>
                    {teamMate?.professions?.length ? (
                      <Tooltip
                        placement="top"
                        additionalClassName="text-sm"
                        content={
                          <div>
                            {teamMate?.professions.length > 1
                              ? t(`${TRANSLATION_PATH}.additionalProfessions`)
                              : t(`${TRANSLATION_PATH}.additionalProfession`)}
                            {renderProfessions(teamMate?.professions)}
                          </div>
                        }
                      >
                        <span
                          className="cursor-default text-sm bg-neutralSecondary-60
                         flex items-center justify-center w-5 h-5 p-x-0.5 rounded-full text-black"
                        >
                          +{teamMate?.professions.length}
                        </span>
                      </Tooltip>
                    ) : null}
                  </div>
                </div>

                {teamMate?.seniority ? (
                  <span className={setSeniorityColor(teamMate?.seniority)}>
                    {normalizedSeniority(teamMate?.seniority)}
                  </span>
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Button2
        disabled={areCandidatesAdded}
        width="w-full"
        dataCy={`${TEAM_GENERATOR}_addTeam`}
        onClick={onAddTeamToFolder}
      >
        {!areCandidatesAdded
          ? t(`${TRANSLATION_PATH}.addToFolder`)
          : t(`${TRANSLATION_PATH}.added`)}
      </Button2>
    </>
  );
};

export default TeamList;
