import { ReactElement } from "react";
import {
  Button2,
  Modal2,
  ModalMainText2,
  ModalFooter2,
} from "@ps/ui-components";
import { ReactComponent as ThrowTrashImage } from "../../../../images/timesheet/timelog/throw-trash.svg";
import { useTranslationWithNamespace } from "../../../../hooks";
import styles from "../styles.module.css";
import { RemovingModalProps } from "./types";
import { TIME_TRACKER_REMOVE } from "../../../../shared/data-cy";

const RemovingRowModal = ({
  isOpen,
  onClose,
  onRemove,
}: RemovingModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Modal2
      isOpen={isOpen}
      onClose={onClose}
      dataCy="test"
      withCloseIcon
      width={styles.deleteModal}
    >
      <div className="flex gap-x-6">
        <div className="flex items-center justify-center">
          <ThrowTrashImage />
        </div>
        <div className="flex flex-col gap-y-4">
          <ModalMainText2 dataCy={TIME_TRACKER_REMOVE}>
            {t(`timesheet.timelog.deleteRowConfirmationHeader`)}
          </ModalMainText2>
          <span className="flex flex-col gap-y-4">
            {t(`timesheet.timelog.deleteRowConfirmationContent`)}
          </span>
          <ModalFooter2 dataCy={TIME_TRACKER_REMOVE} additionalClassName="pt-8">
            <Button2
              dataCy={`${TIME_TRACKER_REMOVE}_cancel`}
              onClick={onClose}
              variant="secondary"
            >
              {t("actions.close")}
            </Button2>
            <Button2
              dataCy={`${TIME_TRACKER_REMOVE}_delete`}
              onClick={onRemove}
            >
              {t("actions.delete")}
            </Button2>
          </ModalFooter2>
        </div>
      </div>
    </Modal2>
  );
};

export default RemovingRowModal;
