import { createContext, useContext } from "react";
import { ContextProps } from "./types";

// @ts-ignore
export const TabsContext = createContext<ContextProps>({});

// @ts-ignore
export const useTabsContext = (): ContextProps => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error(
      "Tab compound components cannot be rendered outside the Tabs component",
    );
  }
  return context;
};
