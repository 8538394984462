import { AnyAction, Dispatch } from "redux";
import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import ActionTypes from "./actionTypes";
import { SkillsSearchQueryResultAPIModel } from "../models/types";
import { SkillsSearchService } from "../services";
import { FilterPayload, IAction, IProfileReturn, SorterPayload } from "./types";

export const fetchSkillsSearchQueryResultStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_SKILLS_SEARCH_QUERY_RESULT_START);
export const fetchSkillsSearchQueryResultSuccess = (
  payload: IAction<SkillsSearchQueryResultAPIModel>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_SKILLS_SEARCH_QUERY_RESULT_SUCCESS, payload);
export const fetchSkillsSearchQueryResultError = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_SKILLS_SEARCH_QUERY_RESULT_ERROR, payload);
export const clearSkillsSearchQuery = (): AnyAction =>
  actionCreator(ActionTypes.CLEAR_SKILLS_SEARCH_QUERY);

export const fetchSkillsSearchQueryResult = async (
  dispatch: Dispatch,
  professionId: string,
  mustHaveSkills: string[],
  niceToHaveSkills?: string[] | [],
): Promise<AnyAction | void> => {
  dispatch(fetchSkillsSearchQueryResultStart());
  const response = await SkillsSearchService.getSkillsSearchQueryResult(
    professionId,
    mustHaveSkills,
    niceToHaveSkills,
  );
  if (isAPIError(response)) {
    dispatch(fetchSkillsSearchQueryResultError(response));
  } else {
    dispatch(fetchSkillsSearchQueryResultSuccess(response));
  }
};

export const addFilter = (payload: FilterPayload): AnyAction =>
  actionCreator(ActionTypes.ADD_TEAM_USERS_FILTER, payload);
export const removeFilter = (payload: FilterPayload): AnyAction =>
  actionCreator(ActionTypes.REMOVE_TEAM_USERS_FILTER, payload);
export const resetFilters = (): AnyAction =>
  actionCreator(ActionTypes.RESET_TEAM_USERS_FILTERS);

export const filterTeamUsersByProperty = (
  dispatch: Dispatch,
  filterName: string,
  optionId: string,
  isChecked: boolean,
): AnyAction =>
  isChecked
    ? dispatch(removeFilter({ filterName, optionId }))
    : dispatch(addFilter({ filterName, optionId }));

export const resetTeamUsersByPropertyFilters = (
  dispatch: Dispatch,
): AnyAction => dispatch(resetFilters());

export const setSorter = (payload: SorterPayload): AnyAction =>
  actionCreator(ActionTypes.SET_TEAM_USERS_SORTER, payload);

export const setSorterByProperty = (
  dispatch: Dispatch,
  order: "asc" | "desc",
  name: string,
): AnyAction => dispatch(setSorter({ order, name }));

export const addPostponedUser = (payload: IProfileReturn): AnyAction =>
  actionCreator(ActionTypes.ADD_POSTPONED_USER, payload);
export const removePostponedUser = (payload: IProfileReturn): AnyAction =>
  actionCreator(ActionTypes.REMOVE_POSTPONED_USER, payload);
export const resetPostponedUsers = (): AnyAction =>
  actionCreator(ActionTypes.RESET_POSTPONED_USERS);

export const modifyPostponedUsersList = (
  dispatch: Dispatch,
  operation: "remove" | "add",
  user: IProfileReturn,
): AnyAction =>
  operation === "add"
    ? dispatch(addPostponedUser(user))
    : dispatch(removePostponedUser(user));

export const resetPostponedUsersList = (dispatch: Dispatch): AnyAction =>
  dispatch(resetPostponedUsers());
