import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../../hooks";
import { ReactComponent as BioIcon } from "../../../images/ocr/bio-wizard-icon.svg";
import { ReactComponent as ExperienceIcon } from "../../../images/ocr/experience-wizard-icon.svg";
import { ReactComponent as TravelDocsIcon } from "../../../images/ocr/travel-documents-wizard-icon.svg";
import { ReactComponent as WorkIcon } from "../../../images/ocr/work-wizard-icon.svg";
import { ReactComponent as LanguagesIcon } from "../../../images/ocr/languages-wizard-icon.svg";
import { ReactComponent as DevelopmentGoalsIcon } from "../../../images/ocr/development-goals-wizard-icon.svg";
import { ReactComponent as ProjectIcon } from "../../../images/ocr/project-wizard-icon.svg";
import { ReactComponent as EducationIcon } from "../../../images/ocr/education-wizard-icon.svg";
import { ReactComponent as CourseIcon } from "../../../images/ocr/course-wizard-icon.svg";
import { ReactComponent as ArticlesIcon } from "../../../images/ocr/articles-wizard-icon.svg";
import { ReactComponent as ContributionsIcon } from "../../../images/ocr/contributions-wizard-icon.svg";
import { ReactComponent as TalksIcon } from "../../../images/ocr/talks-wizard-icon.svg";
import { ReactComponent as TutorIcon } from "../../../images/ocr/tutor-wizard-icon.svg";
import {
  AVAILABILITY,
  BIO,
  CURRENT_YEAR,
  WORKING_HOURS,
} from "../overview/constants";
import {
  ACCOMPLISHMENTS,
  ARTICLES,
  AWARDS,
  CERTIFICATES,
  CONTRIBUTIONS,
  EDUCATION,
  EXPERIENCE,
  GOALS,
  LANGUAGES,
  PROJECTS,
  TALKS,
  TRANSLATION_PATH,
  TUTORS,
} from "./constants";
import { HeaderProps, IconProps, SelectOption } from "./types";
import { DEGREES } from "../experience/sections/education/constants";
import { useOCRWizardContext } from "./context";

export const iconsList = {
  [BIO]: (
    accomplishmentType: SelectOption,
    educationType: SelectOption,
    rest: IconProps,
  ): ReactElement => <BioIcon {...rest} />,
  [EXPERIENCE]: (
    accomplishmentType: SelectOption,
    educationType: SelectOption,
    rest: IconProps,
  ): ReactElement => <ExperienceIcon {...rest} />,
  [AVAILABILITY]: (
    accomplishmentType: SelectOption,
    educationType: SelectOption,
    rest: IconProps,
  ): ReactElement => <TravelDocsIcon {...rest} />,
  [WORKING_HOURS]: (
    accomplishmentType: SelectOption,
    educationType: SelectOption,
    rest: IconProps,
  ): ReactElement => <WorkIcon {...rest} />,
  [LANGUAGES]: (
    accomplishmentType: SelectOption,
    educationType: SelectOption,
    rest: IconProps,
  ): ReactElement => <LanguagesIcon {...rest} />,
  [GOALS]: (
    accomplishmentType: SelectOption,
    educationType: SelectOption,
    rest: IconProps,
  ): ReactElement => <DevelopmentGoalsIcon {...rest} />,
  [PROJECTS]: (
    accomplishmentType: SelectOption,
    educationType: SelectOption,
    rest: IconProps,
  ): ReactElement => <ProjectIcon {...rest} />,
  [EDUCATION]: (
    accomplishmentType: SelectOption,
    educationType: SelectOption,
    rest: IconProps,
  ): ReactElement => {
    return educationType.value === DEGREES ? (
      <EducationIcon {...rest} />
    ) : (
      <CourseIcon {...rest} />
    );
  },
  [ACCOMPLISHMENTS]: (
    accomplishmentType: SelectOption,
    educationType: SelectOption,
    rest: IconProps,
  ): ReactElement => {
    switch (accomplishmentType.value) {
      case ARTICLES:
        return <ArticlesIcon {...rest} />;
      case AWARDS:
        return <DevelopmentGoalsIcon {...rest} />;
      case CONTRIBUTIONS:
        return <ContributionsIcon {...rest} />;
      case CERTIFICATES:
        return <CourseIcon {...rest} />;
      case TALKS:
        return <TalksIcon {...rest} />;
      case TUTORS:
        return <TutorIcon {...rest} />;
      default:
        return <ArticlesIcon {...rest} />;
    }
  },
};

const Header = ({ formName }: HeaderProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { educationType, accomplishmentType } = useOCRWizardContext();
  const CurrentIcon = iconsList?.[formName];

  return (
    <header className="flex gap-6">
      <div className="h-13 w-fit text-neutralPrimary-50 border-r p-6 flex align-middle">
        <div className="-m-1">
          {CurrentIcon && (
            <CurrentIcon
              width={20}
              height={20}
              className="fill-current"
              educationType={educationType}
              accomplishmentType={accomplishmentType}
            />
          )}
        </div>
      </div>
      <div className="h-fit self-center">
        {t(`${TRANSLATION_PATH}.labels.${formName}`, { year: CURRENT_YEAR })}
      </div>
    </header>
  );
};

export default Header;
