import { ReactElement } from "react";
import { SectionDisplayCardProps } from "./types";
import Card from "../card";
import LinkButton from "../link";
import Icon from "../icon";
import Timeline from "../timeline";
import { SECTION_DISPLAY_CARD_PREFIX } from "../../shared/data-cy";
import { ReactComponent as AddNewIcon } from "../../images/add-new.svg";

const SectionDisplayCard = ({
  icon,
  title,
  dataCy,
  children,
  additionalClassName = "",
  onAddClick,
}: SectionDisplayCardProps): ReactElement => (
  <Card
    additionalCardClassName={`py-5 px-8 ${additionalClassName}`}
    dataCy={`${SECTION_DISPLAY_CARD_PREFIX}-${dataCy}`}
  >
    <div className="flex gap-x-2 items-center text-2xl font-bold mb-5">
      <Icon icon={icon} size="8" dataCy="education" />
      {title}
    </div>
    <Timeline dataCy={dataCy} offset={3}>
      {children}
    </Timeline>
    <LinkButton
      dataCy="addNew"
      prefixIcon={<AddNewIcon className="text-primary-50 fill-current" />}
      onClick={onAddClick}
    >
      add new
    </LinkButton>
  </Card>
);

export default SectionDisplayCard;
