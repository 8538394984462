import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import styles from "../styles.module.css";
import { SingleOtherTechHeaderCellProps } from "../types";
import { getCellWidth } from "../utils";

const SingleOtherTechHeaderCell = ({
  additionalClassName = "",
  mainColor,
  profession,
  techName,
  title = "",
}: SingleOtherTechHeaderCellProps): ReactElement => (
  <th>
    <span className="flex flex-col">
      <span
        className={classJoin(
          "h-10 text-primary-100 flex items-center justify-start px-2 font-normal",
          additionalClassName,
          `bg-${mainColor}`,
          styles.expandingCell,
        )}
      >
        {title}
      </span>
      <span
        className={classJoin(
          "h-20 border-neutralSecondary-60 border p-4 flex justify-center items-center bg-neutralSecondary-90",
          `text-${mainColor}`,
          styles.expandingCell,
        )}
        style={{ width: getCellWidth(`${techName} (${profession})`) }}
      >
        <span
          className={classJoin(
            styles.otherTechnology,
            "font-normal text-neutralPrimary-20 flex items-center justify-center text-sm font-light flex-col gap-1",
          )}
        >
          <span>{techName}</span>
          <span>{`(${profession})`}</span>
        </span>
      </span>
    </span>
  </th>
);

export default SingleOtherTechHeaderCell;
