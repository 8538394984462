import {
  ReactElement,
  useState,
  KeyboardEvent,
  useEffect,
  MouseEvent,
  useCallback,
} from "react";
import dayjs from "dayjs";
import { classJoin } from "@ps/utils";
import { ReactComponent as ArrowLeft } from "../../images/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../images/arrowRight.svg";
import { ReactComponent as CalendarIcon } from "../../images/timetracker/calendar.svg";
import { useTranslationWithNamespace } from "../../hooks";
import {
  TIME_TRACKER_TIME_NAVIGATOR_NEXT_WEEK_ICON_PREFIX,
  TIME_TRACKER_TIME_NAVIGATOR_PREFIX,
  TIME_TRACKER_TIME_NAVIGATOR_PREVIOUS_WEEK_ICON_PREFIX,
} from "../../shared/data-cy";
import { TIME_FORMAT_DAY_MONTH } from "../../shared/constants";
import { TimeTrackerTimeNavigatorProps } from "./types";
import Dropdown from "../dropdown";
import TimeTrackerCalendar from "../time-tracker-calendar";
import { defaultPresets } from "./constants";
import checkDate from "./helpers/checkDate";
import setDateFromPreset from "./helpers/setDateFromPreset";
import { Keys, focusVisibleStyles } from "../../shared";

const arrowButtonWeek = classJoin.template`
w-5 h-5 rounded-sm flex items-center
disabled:opacity-25 disabled:pointer-events-none
justify-center hover:opacity-40
`;

const CALENDAR_TIMETRACKER_ICON_ID = "calendar-timetracker-icon_id";
const CALENDAR_TIMETRACKER_LABEL_ID = "calendar-timetracker-label_id";
const TIME_LABEL = "calendar-timetracker-time-label";

const TimeTrackerTimeNavigator = ({
  dataCy,
  duration = 1,
  enabledPresets = false,
  manualChoice = false,
  maxDate,
  minDate,
  setTimeFrame,
  timeFrame,
  timeUnit = "week",
  setFocusedElementID,
  focusedElementID,
}: TimeTrackerTimeNavigatorProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [isOpenedCalendar, setIsOpenedCalendar] = useState<boolean>(false);
  const [callSource, setCallSource] = useState("");
  const handleUserKeyPress = useCallback((event) => {
    const { key } = event;
    if (key === Keys.ESC) {
      setIsOpenedCalendar(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    if (focusedElementID) {
      document.getElementById(focusedElementID)?.focus();
    }
  }, [focusedElementID]);

  const fromTime: Date = timeFrame?.[0];
  const toTime: Date = timeFrame?.[1];

  const leftRangeDate: Date = minDate
    ? minDate.toDate()
    : dayjs().subtract(6, "month").startOf("week").day(1).toDate();

  const rightRangeDate: Date = maxDate
    ? maxDate.toDate()
    : dayjs().add(2, "week").endOf("week").day(7).toDate();

  const handleOnDatesChange = (newValues: Date[]): void => {
    if (manualChoice) {
      return setTimeFrame([newValues[0], newValues[1]]);
    }
    if (
      dayjs(newValues[0]).format("dddd") === "Sunday" ||
      dayjs(newValues[1]).format("dddd") === "Sunday"
    ) {
      const startDate = dayjs(newValues[0]).startOf("week").day(-6);
      const endDate = dayjs(newValues[0]).endOf("week").day(0);
      const result = [startDate.toDate(), endDate.toDate()];
      return setTimeFrame(result);
    }
    const startDate = dayjs(newValues[0]).startOf("week").day(1);
    const endDate = dayjs(newValues[0]).endOf("week").day(7);
    const result = [startDate.toDate(), endDate.toDate()];
    return setTimeFrame(result);
  };

  const onNavigationClick = (date: Date, direction = "next"): Date =>
    direction === "next"
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dayjs(date).add(duration, timeUnit).toDate()
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dayjs(date).subtract(duration, timeUnit).toDate();

  const chosenPreset = checkDate(fromTime, toTime);

  return (
    <div
      className="flex flex-row items-center whitespace-no-wrap"
      data-cy={`${TIME_TRACKER_TIME_NAVIGATOR_PREFIX}-${dataCy}`}
    >
      <div
        id={CALENDAR_TIMETRACKER_LABEL_ID}
        className={classJoin(
          "block text-neutralPrimary-20 font-semibold mr-4 whitespace-nowrap cursor-pointer",
          isOpenedCalendar ? "pointer-events-none" : "pointer-events-auto",
        )}
        tabIndex={0}
        role="button"
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
          if (event.key === Keys.ENTER) {
            setIsOpenedCalendar((prev: boolean) => !prev);
            setCallSource(TIME_LABEL);
          } else if (event.key === Keys.TAB) {
            setFocusedElementID && setFocusedElementID("");
          }
        }}
        onClick={() => setIsOpenedCalendar((prev: boolean) => !prev)}
      >
        {chosenPreset
          ? t(`time-tracker.${checkDate(fromTime, toTime)}`)
          : `${dayjs(fromTime).format(TIME_FORMAT_DAY_MONTH)} - ${dayjs(
              toTime,
            ).format(TIME_FORMAT_DAY_MONTH)}`}
      </div>
      <Dropdown
        width="w-full"
        show={isOpenedCalendar}
        onClickOutside={() => setIsOpenedCalendar(false)}
        overlay={
          <TimeTrackerCalendar
            maxDate={rightRangeDate}
            minDate={leftRangeDate}
            onChange={(newValues: Date[]) => {
              handleOnDatesChange(newValues);
              setIsOpenedCalendar(false);
              setFocusedElementID &&
                setFocusedElementID(
                  TIME_LABEL === callSource
                    ? CALENDAR_TIMETRACKER_LABEL_ID
                    : CALENDAR_TIMETRACKER_ICON_ID,
                );
              setCallSource("");
            }}
            value={[fromTime, toTime]}
            isRangePicker={manualChoice}
            presets={enabledPresets ? defaultPresets : []}
            onClickPreset={(preset: string) => {
              setTimeFrame(setDateFromPreset(preset));
              setIsOpenedCalendar(false);
              setFocusedElementID &&
                setFocusedElementID(
                  TIME_LABEL === callSource
                    ? CALENDAR_TIMETRACKER_LABEL_ID
                    : CALENDAR_TIMETRACKER_ICON_ID,
                );
              setCallSource("");
            }}
            selectedPreset={chosenPreset}
          />
        }
      >
        <button
          id={CALENDAR_TIMETRACKER_ICON_ID}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setIsOpenedCalendar((prev: boolean) => !prev);
            setFocusedElementID && setFocusedElementID("");
          }}
          onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === Keys.TAB) {
              setFocusedElementID && setFocusedElementID("");
            }
          }}
        >
          <CalendarIcon className="cursor-pointer" />
        </button>
      </Dropdown>
      <button
        className={classJoin(
          "text-neutralPrimary-30 ml-4 border border-neutralPrimary-30",
          arrowButtonWeek,
          focusVisibleStyles,
        )}
        data-cy={`${TIME_TRACKER_TIME_NAVIGATOR_PREVIOUS_WEEK_ICON_PREFIX}-${dataCy}`}
        disabled={dayjs(fromTime).isBefore(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dayjs(leftRangeDate).add(1, timeUnit),
        )}
        type="button"
        onClick={() =>
          setTimeFrame([
            onNavigationClick(fromTime, "prev"),
            onNavigationClick(toTime, "prev"),
          ])
        }
      >
        <ArrowLeft />
      </button>
      <button
        className={classJoin(
          "text-neutralPrimary-30 ml-2 border border-neutralPrimary-30 pl-0.5",
          arrowButtonWeek,
          focusVisibleStyles,
        )}
        data-cy={`${TIME_TRACKER_TIME_NAVIGATOR_NEXT_WEEK_ICON_PREFIX}-${dataCy}`}
        disabled={dayjs(toTime).isAfter(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dayjs(rightRangeDate).subtract(1, timeUnit),
        )}
        type="button"
        onClick={() =>
          setTimeFrame([onNavigationClick(fromTime), onNavigationClick(toTime)])
        }
      >
        <ArrowRight />
      </button>
    </div>
  );
};

export default TimeTrackerTimeNavigator;
