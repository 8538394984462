import { ReactElement, useContext, useEffect } from "react";
import { classJoin } from "@ps/utils";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox } from "@ps/ui-components";
import { PERMISSIONS } from "../../../shared/data-cy";
import PermissionsContext from "../context";
import { AccessCheckboxProps } from "./types";
import styles from "./styles.module.css";

const PermissionsCheckbox = ({
  additionalClassName = "",
  arrPath,
  groupPath = "",
  isDisabled,
  isMiddle,
  name,
  roleId = 0,
}: AccessCheckboxProps): ReactElement => {
  const { control, getValues, setValue, handleSubmit } = useFormContext();
  const { onSubmit } = useContext(PermissionsContext);

  useEffect(() => {
    const values = getValues(`${arrPath}.permissions`) || [];
    if (!values.some((item) => !item.checked)) {
      setValue(`${groupPath || arrPath}.checked`, true);
    }
  }, []);

  const handleOnCheckboxClick = (isChecked: boolean): void => {
    const values = getValues(`${arrPath}.permissions`) || [];

    if (isChecked && !values.some((item) => item.checked !== isChecked)) {
      setValue(`${groupPath || arrPath}.checked`, isChecked);
    } else if (
      !isChecked &&
      values.some((item) => item.checked !== isChecked)
    ) {
      setValue(`${groupPath || arrPath}.checked`, isChecked);
    }
    handleSubmit(onSubmit(roleId, getValues("roles")));
  };

  return (
    <div
      className={classJoin(
        "justify-center text-neutralPrimary-30 font-normal text-sm border-r",
        styles.baseSingleCell,
        additionalClassName,
      )}
    >
      {isMiddle && (
        <Controller
          name={`${name}.checked`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              dataCy={`${PERMISSIONS}_permission`}
              checked={value}
              disabled={isDisabled}
              onChecked={(isChecked) => {
                onChange(isChecked);
                handleOnCheckboxClick(isChecked);
              }}
            />
          )}
        />
      )}
    </div>
  );
};
export default PermissionsCheckbox;
