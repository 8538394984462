import { Button, Modal, ModalContent } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import { ReactElement } from "react";
import { ReactComponent as RemoveImage } from "../../../images/remove-role-column.svg";
import { useTranslationWithNamespace } from "../../../hooks";
import { PERMISSIONS_ROLE } from "../../../shared/data-cy";
import { ConfirmDeleteRoleModalProps } from "./types";

const BASE_PATH = "permissions";

const ConfirmDeleteRoleModal = ({
  handleOnDeleteRole,
  isOpen,
  onCancel,
  roleName,
}: ConfirmDeleteRoleModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Modal
      dataCy="Test"
      isOpen={isOpen}
      onClose={onCancel}
      height="h-auto"
      width="w-auto"
      withCloseIcon
    >
      <ModalContent
        additionalClassName={classJoin(
          "flex flex-row items-center justify-center py-8 p-10 gap-12",
        )}
      >
        <RemoveImage />
        <div className="flex flex-col gap-2 justify-center pt-2">
          <span className="font-semibold text-base">
            {t(`${BASE_PATH}.deleteColumn`)}
          </span>
          <span className="text-sm">
            {t(`${BASE_PATH}.confirmDelete`, { role: roleName })}
          </span>
          <div className="flex gap-4 pt-2">
            <Button
              dataCy={`${PERMISSIONS_ROLE}_delete`}
              onClick={handleOnDeleteRole}
            >
              {t("actions.delete")}
            </Button>
            <Button
              dataCy={`${PERMISSIONS_ROLE}_cancel`}
              variant="secondary"
              onClick={onCancel}
            >
              {t("actions.cancel")}
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmDeleteRoleModal;
