import { Dayjs } from "dayjs";
import { ReactElement } from "react";
import {
  InputType,
  SelectedDates,
  SelectedDatesActionTypes,
  SelectedDatesHelperActions,
  State,
} from "./helpers/state/useSelectDateTypes";

export enum DatepickerMode {
  DATE_PICKER = "date-picker",
  RANGE_PICKER = "range-picker",
}

export enum DatepickerPickerEnum {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  YEAR = "year",
}

export type DatepickerPickerType =
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year";

export enum DatepickerArrow {
  LEFT = "left",
  RIGHT = "right",
}

export type PresetRangeValue = Dayjs | [Dayjs, Dayjs];

export interface PresetRange<T> {
  displayName: string;
  value: T;
}

export interface DisplayModeProps {
  closeCalendar: () => void;
  currentDisplayedMonth: Dayjs;
  dataCy: string;
  disabledDate?: (day: Dayjs, currentSelection: SelectedDates) => boolean;
  mode: DatepickerMode;
  picker: DatepickerPickerType;
  selectedDatesActions?: SelectedDatesHelperActions;
  setDisplayedMonth?: (date: Dayjs) => void;
  setDisplayMode?: (mode: CalendarDisplayModes) => void;
  state: State;
}

export enum CalendarDisplayModes {
  DAYS = "DAYS",
  WEEKS = "WEEKS",
  MONTHS = "MONTHS",
  QUARTERS = "QUARTERS",
  YEARS = "YEARS",
}

export interface DatepickerInputProps
  extends Partial<SelectedDatesHelperActions> {
  mode: DatepickerMode;
  onClickInput: () => void;
  onClickClear: () => void;
  clearRange: (actionType: SelectedDatesActionTypes) => void;
  onChangeFirst: (date: Dayjs) => void;
  onChangeSecond: (date: Dayjs) => void;
  dateFormatTemplate?: string;
  value?: SelectedDates;
  onFocusDate?: (date: Dayjs) => void;
  currentInput?: InputType;
  onChangeInput?: (input: InputType) => void;
  dataCy: string;
  error?: boolean;
  message?: string;
  disabled?: boolean;
  showCalendar: boolean;
  width?: `w-${string}`;
  autoFocus?: boolean;
}

// Main component props

interface DatepickerPropsCommon {
  monthToOpenOn?: Dayjs;
  dateFormatTemplate?: string;
  dataCy: string;
  picker?: DatepickerPickerType;
  closeDropdownAfterSelection?: boolean;
  overrideInput?: (props: DatepickerInputProps) => ReactElement;
}

interface DatepickerSingleMode extends DatepickerPropsCommon {
  mode: "date-picker";
  defaultSelected?: [Dayjs, undefined];
  disabledDate?: (day: Dayjs) => boolean;
  presetRanges?: PresetRange<Dayjs>[];
  onSelected?: (day: Dayjs | undefined) => void;
  error?: boolean;
  message?: string;
  disabled?: boolean;
  width?: `w-${string}`;
  autoFocus?: boolean;
}

interface DatepickerRangeMode extends DatepickerPropsCommon {
  mode: "range-picker";
  disabledDate?: (day: Dayjs, currentSelection: SelectedDates) => boolean;
  defaultSelected?: [Dayjs, Dayjs] | [Dayjs, undefined] | [undefined, Dayjs];
  presetRanges?: PresetRange<[Dayjs, Dayjs]>[];
  onSelected?: (range: [Dayjs, Dayjs] | undefined) => void;
  error?: boolean;
  message?: string;
  disabled?: boolean;
  width?: `w-${string}`;
  autoFocus?: boolean;
}

export type DatepickerProps = DatepickerSingleMode | DatepickerRangeMode;
