import { MutableRefObject, ReactElement, useRef } from "react";
import { SearchSelect, Sorter } from "@ps/ui-components";
import { classJoin, sortArray } from "@ps/utils";
import { useTranslationWithNamespace } from "../../../hooks";
import {
  firstGroupSelect,
  secondGroupSelect,
  thirdGroupSelect,
  GROUP_SELECT,
  emptyValueLabel,
  TITLE,
  DURATION,
} from "../constants";
import { IValueLabel } from "../types";
import { FlattenEntry, GroupSelectsProps } from "./types";
import {
  countTotalInGroup,
  prepareGroupOptionsToSearchSelect,
} from "./helpers";

const GroupSelectsRow = ({
  selectedGroups,
  setSelectedGroups,
  activeSorter,
  setActiveSorter,
  setSortedGroupData,
}: GroupSelectsProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const sortOrders = [
    {
      order: "asc",
      label: t("reports.ascending"),
    },
    {
      order: "desc",
      label: t("reports.descending"),
    },
  ];

  const sorterNames = [
    {
      name: "title",
      label: t("reports.title"),
    },
    {
      name: "duration",
      label: t("reports.duration"),
    },
  ];

  const containerRef = useRef() as MutableRefObject<HTMLInputElement>;

  const mappedFirstGroup: IValueLabel[] = prepareGroupOptionsToSearchSelect(
    firstGroupSelect,
    t,
    selectedGroups,
    ["group1", "group2"],
  );

  const mappedSecondGroup: IValueLabel[] = [
    { label: t("reports.noGroup"), value: "noGroup" },
    ...prepareGroupOptionsToSearchSelect(secondGroupSelect, t, selectedGroups, [
      "group0",
      "group2",
    ]),
  ];

  const mappedThirdGroup: IValueLabel[] = [
    { label: t("reports.noGroup"), value: "noGroup" },
    ...prepareGroupOptionsToSearchSelect(thirdGroupSelect, t, selectedGroups, [
      "group0",
      "group1",
    ]),
  ];

  const handleOnClickSorter = (fieldName: string): void => {
    const order = activeSorter?.order === "desc" ? "asc" : "desc";
    if (fieldName === TITLE) {
      setSortedGroupData((prev: Record<string, FlattenEntry[]>) => {
        const sortedKeys: string[] = Object.keys(prev).sort();
        const checkIfNeedReverse: string[] =
          order === "desc" ? sortedKeys.reverse() : sortedKeys;
        return checkIfNeedReverse.reduce(
          (acc: Record<string, FlattenEntry[]>, entry: string) => {
            acc[entry] = prev[entry];
            return acc;
          },
          {},
        );
      });
    }
    if (fieldName === DURATION) {
      setSortedGroupData((prev: Record<string, FlattenEntry[]>) => {
        const groupDataWithTotalDuration = Object.keys(prev).map(
          (item: string) => ({
            itemName: item,
            duration: countTotalInGroup(prev[item]),
          }),
        );
        const sortedArrayByDuration = sortArray(
          groupDataWithTotalDuration,
          fieldName,
          order,
        );
        const getAllSortedItemNames: string[] = sortedArrayByDuration.map(
          (item) => item.itemName,
        );
        return getAllSortedItemNames.reduce(
          (acc: Record<string, FlattenEntry[]>, entry: string) => {
            acc[entry] = prev[entry];
            return acc;
          },
          {},
        );
      });
    }
    setActiveSorter({ name: fieldName, order });
  };

  return (
    <div className="flex items-center justify-between">
      <div
        className={classJoin.template`
      flex items-center justify-between
      py-3 px-5 text-neutralPrimary-30 relative`}
        ref={containerRef}
      >
        <div className="flex items-center gap-4 text-sm select-none">
          <span>{t(`reports.${GROUP_SELECT}`)}</span>
          <SearchSelect
            dataCy={`${GROUP_SELECT}_0`}
            options={mappedFirstGroup}
            searchable={false}
            onChange={(newValue: IValueLabel): void =>
              setSelectedGroups({
                group0: newValue,
                group1: emptyValueLabel,
                group2: emptyValueLabel,
              })
            }
            placeholder=""
            value={selectedGroups?.group0}
            containerRef={containerRef}
            closeMenuOnScroll={(e) => {
              // eslint-disable-next-line
              // @ts-ignore
              return e?.target?.contains(containerRef.current);
            }}
          />

          <span className="text-lg">{">"}</span>
          <SearchSelect
            dataCy={`${GROUP_SELECT}_1`}
            options={mappedSecondGroup}
            searchable={false}
            onChange={(newValue: IValueLabel): void =>
              setSelectedGroups({
                ...selectedGroups,
                group1: newValue,
                group2: emptyValueLabel,
              })
            }
            placeholder=""
            value={
              selectedGroups?.group1?.value
                ? selectedGroups?.group1
                : mappedSecondGroup[0]
            }
            containerRef={containerRef}
            closeMenuOnScroll={(e) => {
              // eslint-disable-next-line
              // @ts-ignore
              return e?.target?.contains(containerRef.current);
            }}
          />
          <span className="text-xlg">{">"}</span>
          <SearchSelect
            dataCy={`${GROUP_SELECT}_2`}
            options={mappedThirdGroup}
            searchable={false}
            disabled={
              !selectedGroups?.group1.value ||
              selectedGroups?.group1.value === "noGroup"
            }
            // disabled
            onChange={(newValue: IValueLabel): void =>
              setSelectedGroups({
                ...selectedGroups,
                group2: newValue,
              })
            }
            placeholder=""
            value={
              selectedGroups?.group2?.value
                ? selectedGroups?.group2
                : mappedThirdGroup[0]
            }
            containerRef={containerRef}
            closeMenuOnScroll={(e) => {
              // eslint-disable-next-line
              // @ts-ignore
              return e?.target?.contains(containerRef.current);
            }}
          />
        </div>
      </div>
      <Sorter
        activeSorter={activeSorter}
        handleSorterClick={handleOnClickSorter}
        names={sorterNames}
        orders={sortOrders}
      />
    </div>
  );
};

export default GroupSelectsRow;
