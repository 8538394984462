import { classJoin } from "@ps/utils";
import { ReactElement } from "react";
import { ModalHeaderProps } from "./types";
import { MODAL_MAIN_TEXT_PREFIX } from "../../shared/data-cy-2";

const ModalMainText = ({
  additionalClassName,
  children,
  dataCy,
}: ModalHeaderProps): ReactElement => (
  <span
    data-cy={`${MODAL_MAIN_TEXT_PREFIX}_${dataCy}`}
    className={classJoin(additionalClassName, "text-3xl leading-10 font-bold")}
  >
    {children}
  </span>
);

export default ModalMainText;
