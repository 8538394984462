export const PERMISSIONS = "permissions";
export const MEMBERS = "members";
export const INVITING_MODAL = "invitingModal";

export const PERMISSIONS_ROLE = `${PERMISSIONS}_role`;
export const UPLOADER_PREFIX = "uploader";
export const BUTTON = "button";
export const ADD_MEMBER_BUTTON = `${BUTTON}-${MEMBERS}_addNew`;
export const DROPDOWN = "dropdown";
export const MODAL = "modal";
