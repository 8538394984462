import { ReactElement, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Collapse } from "@ps/ui-components";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import {
  ProfessionMatrixCardViewMapState,
  ProfessionMatrixCardViewMapStateReturn,
} from "../../../../../user-skills-matrix/types";
import { useMappedStateSelector } from "../../../../../../../hooks";
import { SKILLS_MATRIX_EXPERTISE_TEMPLATE } from "../../../../../../../shared/data-cy";
import LoadingProfessionMatrixCard from "../../../../../user-skills-matrix/loaders/loadingProfessionMatrixCards";
import SingleGroup from "./singleGroup";
import { GroupTechnologiesTableProps, IGroup } from "../types";
import EmptyGroups from "./emptyGroups";
import { useEditMatrixContext } from "../../context";
import { DOMAINS_PROFESSION_ID } from "../../../../../../shared/consts";

const GroupTechnologiesTable = ({
  addNewGroup,
  groupArray,
  onSubmit,
  professionId,
}: GroupTechnologiesTableProps): ReactElement => {
  const { reset, getValues } = useFormContext();
  const { fields, replace } = groupArray;
  const [openedPanels, setOpenedPanels] = useState<string[]>([]);
  const { setIsDragAndDrop } = useEditMatrixContext();
  const mapState = (
    state: ProfessionMatrixCardViewMapState,
  ): ProfessionMatrixCardViewMapStateReturn => ({
    isLoadingTemplate: state.requestStatus.isFetchingDictionary,
    profession: state?.skillsMatrix?.templates?.[professionId],
  });

  const { profession, isLoadingTemplate } = useMappedStateSelector(mapState);

  useEffect(() => {
    reset({ ...getValues(), groups: profession?.groups || [] });
  }, [profession]);

  useEffect(() => {
    if (professionId === DOMAINS_PROFESSION_ID) {
      setOpenedPanels([`${DOMAINS_PROFESSION_ID}-Domains`]);
    }
  }, [professionId]);

  const handleDrag = (result: DropResult): void => {
    if (!result.destination) return;

    const sourceFieldId = result.source.droppableId;
    const sourceIndex = result.source.index;
    const destinationFieldId = result.destination.droppableId;
    const destinationIndex = result.destination.index;

    const element = fields[sourceFieldId].skills[sourceIndex];
    const newFieldsObj = JSON.parse(JSON.stringify(fields));

    newFieldsObj[sourceFieldId].skills.splice(sourceIndex, 1);

    newFieldsObj[destinationFieldId].skills.splice(
      destinationIndex,
      0,
      element,
    );
    replace(newFieldsObj);
    setIsDragAndDrop(true);
  };

  const handleTogglePanel = (panelId: string): void => {
    if (!openedPanels.includes(panelId)) {
      setOpenedPanels((prev) => [...prev, panelId]);
    } else {
      const filtredOpenPanels = openedPanels.filter((p) => p !== panelId);
      setOpenedPanels(filtredOpenPanels);
    }
  };

  if (isLoadingTemplate) return <LoadingProfessionMatrixCard />;

  if (!fields?.length) return <EmptyGroups addNewGroup={addNewGroup} />;
  return (
    <motion.div layout className="w-full">
      <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
        <Collapse
          dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_${professionId}`}
        >
          <DragDropContext onDragEnd={handleDrag}>
            {fields?.map((group: IGroup, index: number) => (
              <SingleGroup
                baseGroups={profession?.groups}
                enjoymentScale={profession?.enjoymentScale}
                group={group}
                index={index}
                key={uid(group)}
                knowledgeScale={profession?.knowledgeScale}
                onSubmit={onSubmit}
                openedPanels={openedPanels}
                professionId={professionId}
                removeGroupFromArray={() => groupArray.remove(index)}
                handleTogglePanel={handleTogglePanel}
              />
            ))}
          </DragDropContext>
        </Collapse>
      </Scrollbars>
    </motion.div>
  );
};

export default GroupTechnologiesTable;
