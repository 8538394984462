import ActionTypes from "./notificationsActionTypes";
import { actionCreator } from "../../../utils";
import { Notification } from "../types";

export const addNotification = (payload: Notification) =>
  actionCreator(ActionTypes.ADD_NOTIFICATION, payload);
export const removeNotification = (payload: string) =>
  actionCreator(ActionTypes.REMOVE_NOTIFICATION, payload);
export const clearNotifications = () =>
  actionCreator(ActionTypes.CLEAR_NOTIFICATIONS);
