import { ReactElement, MouseEvent } from "react";
import { classJoin } from "@ps/utils";
import { Button, ButtonsGroup } from "@ps/ui-components";
import { ReactComponent as EditIcon } from "../../../../../../../images/edit-pen.svg";
import { ReactComponent as TrashIcon } from "../../../../../../../images/edit-trash.svg";
import { ReactComponent as CancelIcon } from "../../../../../../../images/close.svg";
import { ReactComponent as NewTechnologyIcon } from "../../../../../../../images/new-technology.svg";
import { EditPanelButtonsProps, EditPanelButtonsMapState } from "../../types";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../../../../../hooks";
import { SKILLS_MATRIX_EXPERTISE_TEMPLATE } from "../../../../../../../shared/data-cy";
import { focusVisibleStyles } from "../../../../../../../shared";

const EditPanelButtons = ({
  addTechnologyClick,
  buttonPaddingX = "px-2.5",
  cantEdit,
  cantRemove,
  cantCancel,
  dataCy,
  iconSize = "5",
  isEditMode = false,
  onCancelClick,
  onEditClick,
  variant = "primary",
  onRemoveClick,
  isGroupNew,
  hideButtons = false,
}: EditPanelButtonsProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const mapState = (
    state: EditPanelButtonsMapState,
  ): EditPanelButtonsMapState => ({
    editMode: state.editMode,
  });

  const { editMode } = useMappedStateSelector(mapState);

  return (
    <div
      className="flex gap-6 items-center"
      data-cy={dataCy}
      onClick={(event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      role="presentation"
    >
      {addTechnologyClick && (
        <Button
          additionalClass="flex items-center text-sm"
          onClick={addTechnologyClick}
          dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_addNewTechnology`}
          disabled={(!!editMode && !isEditMode) || isGroupNew}
        >
          <NewTechnologyIcon className="fill-current mt-0.5" />
          {t("expertise.matrix.editMatrix.addTechnology")}
        </Button>
      )}
      {!hideButtons && (
        <ButtonsGroup
          dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_editButtonGroup`}
          additionalClass="items-center"
          borderRadius="lg"
        >
          <Button
            dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_editButton`}
            onClick={(event: MouseEvent<HTMLButtonElement>) =>
              isEditMode ? onCancelClick?.(event) : onEditClick(event)
            }
            additionalClass={classJoin(
              focusVisibleStyles,
              variant === "primary"
                ? "bg-primary-70 hover:opacity-80 hover:bg-primary-70 b-left"
                : "",
              "disabled:opacity-50 disabled:cursor-not-allowed",
            )}
            paddingX={buttonPaddingX}
            variant={variant}
            disabled={cantCancel || cantEdit || (!!editMode && !isEditMode)}
            border={variant === "primary" ? "border-r" : undefined}
          >
            {isEditMode ? (
              <CancelIcon className={`w-${iconSize} h-${iconSize}`} />
            ) : (
              <EditIcon className={`w-${iconSize} h-${iconSize}`} />
            )}
          </Button>

          <Button
            dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_deleteButton`}
            onClick={onRemoveClick}
            disabled={cantRemove || (!!editMode && !isEditMode)}
            paddingX={buttonPaddingX}
            variant={variant}
            border={variant === "primary" ? "border-none" : undefined}
            additionalClass={classJoin(
              focusVisibleStyles,
              variant === "primary"
                ? "bg-primary-70 hover:opacity-80 hover:bg-primary-70"
                : "",
              "disabled:opacity-50 disabled:cursor-not-allowed",
              "border-l",
            )}
          >
            <TrashIcon className={`w-${iconSize} h-${iconSize}`} />
          </Button>
        </ButtonsGroup>
      )}
    </div>
  );
};

export default EditPanelButtons;
