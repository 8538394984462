import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import { IconToggleProps } from "./types";
import { ReactComponent as CheckIcon } from "../../images/check-icon.svg";
import { ReactComponent as XIcon } from "../../images/x-icon.svg";
import {
  ICON_TOGGLE_PREFIX,
  ICON_TOGGLE_RIGHT_ICON_PREFIX,
  ICON_TOGGLE_LEFT_ICON_PREFIX,
  ICON_TOGGLE_ICON_PREFIX,
  ICON_TOGGLE_LABEL_PREFIX,
} from "../../shared/data-cy";
import { focusVisibleStyles, Keys } from "../../shared";

const iconStyle = "fill-current w-4 h-4 cursor-pointer";
const defaultIconStyle = "text-neutralSecondary-41 border-neutralSecondary-41";
const checkedLeftIconStyle = "text-primary-100 bg-primary-50 border-primary-50";
const checkedRightIconStyle = "text-primary-100 bg-error-50 border-error-50";

const IconToggle = ({
  dataCy,
  onClick,
  Icon,
  label,
  disabled = false,
  fontWeight = "font-normal",
  preference,
  onLeftClick,
  onRightClick,
}: IconToggleProps): ReactElement => {
  const setPreferenceTextColor = preference
    ? "text-primary-50"
    : "text-error-50";

  return (
    <div
      className={classJoin(
        "flex items-center gap-x-2 w-min",
        disabled && "opacity-30",
      )}
      data-cy={`${ICON_TOGGLE_PREFIX}-${dataCy}`}
    >
      <div className="flex">
        <div
          className={classJoin(
            "border-r border-l-2 border-t-2 border-b-2 rounded-l-lg p-2",
            preference ? checkedLeftIconStyle : defaultIconStyle,
            disabled && "opacity-90 pointer-events-none",
            focusVisibleStyles,
          )}
          onClick={onLeftClick}
          role="button"
          tabIndex={0}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === Keys.ENTER) onLeftClick?.();
          }}
          data-cy={`${ICON_TOGGLE_LEFT_ICON_PREFIX}-${dataCy}`}
        >
          <CheckIcon className={iconStyle} />
        </div>
        <div
          className={classJoin(
            "border-l border-r-2 border-t-2 border-b-2 rounded-r-lg p-2",
            !preference ? checkedRightIconStyle : defaultIconStyle,
            disabled && "opacity-90 pointer-events-none",
            focusVisibleStyles,
          )}
          onClick={onRightClick}
          role="button"
          tabIndex={0}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === Keys.ENTER) onRightClick?.();
          }}
          data-cy={`${ICON_TOGGLE_RIGHT_ICON_PREFIX}-${dataCy}`}
        >
          <XIcon className={iconStyle} />
        </div>
      </div>
      {Icon && (
        <Icon
          className={classJoin("fill-current w-7 h-7", setPreferenceTextColor)}
          data-cy={`${ICON_TOGGLE_ICON_PREFIX}-${dataCy}`}
        />
      )}
      {label && (
        <span
          className={classJoin(
            "text-lg whitespace-nowrap",
            fontWeight,
            setPreferenceTextColor,
            onClick && "cursor-pointer",
          )}
          data-cy={`${ICON_TOGGLE_LABEL_PREFIX}-${dataCy}`}
          onClick={onClick}
          role={onClick ? "button" : undefined}
          tabIndex={onClick ? 0 : undefined}
          onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
            if (event.key === Keys.ENTER && onClick) onClick();
          }}
        >
          {label}
        </span>
      )}
    </div>
  );
};

export default IconToggle;
