import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { ReactComponent as ArrowIcon } from "../../../../../images/arrow-right.svg";
import { ReactComponent as SwapIcon } from "../../../../../images/swap.svg";
import { SingleTechHeaderCellProps } from "../types";
import styles from "../styles.module.css";
import { getCellWidth } from "../utils";

const SingleTechHeaderCell = ({
  additionalClassName = "",
  isExpanded,
  mainColor,
  onExpandClick,
  techName,
  title = "",
  hasExpandedChildren,
  onSort,
  isSorterChecked,
}: SingleTechHeaderCellProps): ReactElement => (
  <th>
    <span className="flex flex-col">
      <span
        className={classJoin(
          " w-full h-10 text-primary-100 flex items-center justify-start px-2 font-normal",
          additionalClassName,
          `bg-${mainColor}`,
        )}
      >
        <span className="absolute text-neutralPrimary-100">{title}</span>
      </span>
      <span
        className={classJoin(
          "h-20 border-neutralSecondary-60 border p-8 relative flex justify-center items-center",
          `text-${mainColor}`,
        )}
        style={{ width: getCellWidth(techName) }}
      >
        {hasExpandedChildren ? (
          <button
            className="h-4 w-4 absolute top-1 right-0 flex items-center justify-center"
            onClick={onExpandClick}
          >
            <ArrowIcon
              className={classJoin(
                "fill-current",
                isExpanded ? styles.arrowRotateRight : styles.arrowRotateLeft,
              )}
            />
          </button>
        ) : (
          <></>
        )}
        {techName}
        <button onClick={onSort} className="h-4 w-4 absolute bottom-1 right-1">
          <SwapIcon
            className={classJoin(
              "fill-current h-full w-full",
              isSorterChecked ? "text-primary-50" : "text-neutralSecondary-60",
            )}
          />
        </button>
      </span>
    </span>
  </th>
);

export default SingleTechHeaderCell;
