import APIService from "../api/time-tracker-api";
import { reportFiltersFromAPIModel, reportFromAPIModel } from "../models";
import handleError from "../shared/utils";
import convertStringArrayToString from "./helpers";

const ReportsService = (() => {
  const fetchReportFilters = async (
    users?: string[],
    projects?: string[],
    clients?: string[],
  ) => {
    try {
      const response = await APIService.API.getReportFilters(
        convertStringArrayToString(users),
        convertStringArrayToString(projects),
        convertStringArrayToString(clients),
      );
      if (response?.data) {
        return reportFiltersFromAPIModel(response.data);
      }
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchReport = async (
    from: string,
    to: string,
    users: string[] = [],
    projects: string[] = [],
  ) => {
    try {
      const response = await APIService.API.getReport2(from, to, {
        users,
        projects,
      });
      if (response?.data) {
        return reportFromAPIModel(response.data);
      }
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    fetchReportFilters,
    fetchReport,
  };
})();

export default ReportsService;
