import { Context, ContextType, createContext, useContext } from "react";
import { BIO } from "./constants";
import { OCRWizardContextType } from "./types";

export const OCRWizardContext = createContext<OCRWizardContextType>({
  step: 1,
  subStep: 0,
  currentFormName: BIO,
  educationType: {
    label: "",
    value: "",
  },
  handleOnSetEducationClick: () => {
    // intentionally empty function
  },
  accomplishmentType: {
    label: "",
    value: "",
  },
  handleOnSetAccomplishmentClick: () => {
    // intentionally empty function
  },
  additionalInfoFormChoice: {
    label: "",
    value: "",
  },
  handleOnSetAdditionalInfoFromChoice: () => {
    // intentionally empty function
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fullTextSearch: (value: string, result?: number) => {
    // intentionally empty function
  },
});

export const useOCRWizardContext = (): ContextType<
  Context<OCRWizardContextType>
> => {
  const context = useContext(OCRWizardContext);
  if (!context) {
    throw new Error(
      "OCR Wizard elements compound components cannot be rendered outside the Modal component",
    );
  }
  return context;
};
