import { getName } from "i18n-iso-countries";

// eslint-disable-next-line import/prefer-default-export
export const getResidenceData = (
  currentLanguageCode: string,
  residence?: string,
): string => {
  if (!residence) return "";
  return (
    getName(residence, currentLanguageCode, {
      select: "official",
    }) || residence
  );
};
