import { ReactElement, ChangeEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Input, Label } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../hooks";
import { ControlledInputProps } from "./types";

const ControlledInput = ({
  additionalClassName,
  additionalValidation,
  autoFocus = false,
  basePath,
  controllerPath,
  defaultValue,
  isRequired,
  manualControl,
  max,
  min,
  name,
  noLabel,
  placeholder,
  translatePath,
  type,
}: ControlledInputProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { control } = useFormContext();
  const translate = (field: string) =>
    `${t(`${translatePath}.${field}`)}${isRequired ? "*" : ""}`;

  return (
    <Label
      labelFontWeight="font-bold"
      labelFontSize="text-lg"
      labelColor="text-neutralPrimary-20"
      dataCy={`${basePath}_${name}`}
      text={noLabel ? "" : translate(name)}
    >
      <Controller
        name={controllerPath || name}
        control={manualControl || control}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            autoFocus={autoFocus}
            dataCy={`${basePath}_${name}`}
            defaultValue={defaultValue || ""}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChange(event.target.value)
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type={type}
            placeholder={(placeholder && translate(placeholder)) || ""}
            additionalClass={additionalClassName}
            message={error?.message}
            error={!!error?.message}
            min={min}
            max={max}
          />
        )}
        rules={{
          validate: additionalValidation,
          required: {
            value: !!isRequired,
            message: t("project.errors.cantBeEmpty"),
          },
        }}
      />
    </Label>
  );
};

export default ControlledInput;
