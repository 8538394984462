import { ReactElement, MouseEvent } from "react";
import { Button } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../../../hooks";
import { SKILLS_MATRIX_EXPERTISE } from "../../../../../../../shared/data-cy";
import { ReactComponent as AddProfessionIcon } from "../../../../../../../images/add-profession.svg";
import { AddProfessionButtonProps } from "../types";

const AddProfessionButton = ({
  onClick,
  disabled,
}: AddProfessionButtonProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Button
      additionalClass="flex gap-2 items-center justify-center mb-8 ml-0"
      dataCy={`${SKILLS_MATRIX_EXPERTISE}_add`}
      disabled={disabled}
      onClick={(event: MouseEvent<HTMLButtonElement>) =>
        onClick && onClick(event)
      }
      type="button"
    >
      <AddProfessionIcon className="fill-current" />
      {t("expertise.matrix.editMatrix.addProfession")}
    </Button>
  );
};

export default AddProfessionButton;
