import { ReactPortal } from "react";
import ReactDOM from "react-dom";
import { ThemeModePortalProps } from "./types";

const createThemeModePortal = ({
  children,
  className,
  container,
  key,
  theme,
}: ThemeModePortalProps): ReactPortal =>
  ReactDOM.createPortal(
    <div data-color-theme={theme} className={className}>
      {children}
    </div>,
    container,
    key,
  );

export default createThemeModePortal;
