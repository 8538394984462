/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Dict API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateDictEntry
 */
export interface CreateDictEntry {
    /**
     * 
     * @type {string}
     * @memberof CreateDictEntry
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDictEntry
     */
    'icon_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDictEntry
     */
    'desc'?: string;
}
/**
 * 
 * @export
 * @interface DeleteFlag
 */
export interface DeleteFlag {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteFlag
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface DictEntry
 */
export interface DictEntry {
    /**
     * 
     * @type {string}
     * @memberof DictEntry
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DictEntry
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DictEntry
     */
    'icon_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictEntry
     */
    'desc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DictEntry
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DictType = {
    Domain: 'domain',
    Profession: 'profession',
    ProjectRole: 'project_role',
    Seniority: 'seniority',
    Technology: 'technology',
    Engagement: 'engagement',
    OperativeProfession: 'operative_profession'
} as const;

export type DictType = typeof DictType[keyof typeof DictType];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateDict
 */
export interface UpdateDict {
    /**
     * 
     * @type {string}
     * @memberof UpdateDict
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDict
     */
    'icon_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDict
     */
    'desc'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create dict entry
         * @param {DictType} dictType 
         * @param {CreateDictEntry} createDictEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDictEntry: async (dictType: DictType, createDictEntry: CreateDictEntry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictType' is not null or undefined
            assertParamExists('createDictEntry', 'dictType', dictType)
            // verify required parameter 'createDictEntry' is not null or undefined
            assertParamExists('createDictEntry', 'createDictEntry', createDictEntry)
            const localVarPath = `/dict/{dictType}`
                .replace(`{${"dictType"}}`, encodeURIComponent(String(dictType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDictEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns dict entry list of a given type
         * @param {DictType} dictType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictEntries: async (dictType: DictType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictType' is not null or undefined
            assertParamExists('getDictEntries', 'dictType', dictType)
            const localVarPath = `/dict/{dictType}`
                .replace(`{${"dictType"}}`, encodeURIComponent(String(dictType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set soft delete flaf of a dict entry of a given type
         * @param {DictType} dictType 
         * @param {string} id 
         * @param {DeleteFlag} deleteFlag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeleted: async (dictType: DictType, id: string, deleteFlag: DeleteFlag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictType' is not null or undefined
            assertParamExists('setDeleted', 'dictType', dictType)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setDeleted', 'id', id)
            // verify required parameter 'deleteFlag' is not null or undefined
            assertParamExists('setDeleted', 'deleteFlag', deleteFlag)
            const localVarPath = `/dict/{dictType}/{id}/deleted`
                .replace(`{${"dictType"}}`, encodeURIComponent(String(dictType)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteFlag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set soft delete flaf of a dict entry of a given type
         * @param {DictType} dictType 
         * @param {string} id 
         * @param {UpdateDict} updateDict 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDict: async (dictType: DictType, id: string, updateDict: UpdateDict, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictType' is not null or undefined
            assertParamExists('updateDict', 'dictType', dictType)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDict', 'id', id)
            // verify required parameter 'updateDict' is not null or undefined
            assertParamExists('updateDict', 'updateDict', updateDict)
            const localVarPath = `/dict/{dictType}/{id}`
                .replace(`{${"dictType"}}`, encodeURIComponent(String(dictType)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDict, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Create dict entry
         * @param {DictType} dictType 
         * @param {CreateDictEntry} createDictEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDictEntry(dictType: DictType, createDictEntry: CreateDictEntry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDictEntry(dictType, createDictEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns dict entry list of a given type
         * @param {DictType} dictType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictEntries(dictType: DictType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DictEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictEntries(dictType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set soft delete flaf of a dict entry of a given type
         * @param {DictType} dictType 
         * @param {string} id 
         * @param {DeleteFlag} deleteFlag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDeleted(dictType: DictType, id: string, deleteFlag: DeleteFlag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDeleted(dictType, id, deleteFlag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set soft delete flaf of a dict entry of a given type
         * @param {DictType} dictType 
         * @param {string} id 
         * @param {UpdateDict} updateDict 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDict(dictType: DictType, id: string, updateDict: UpdateDict, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDict(dictType, id, updateDict, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Create dict entry
         * @param {DictType} dictType 
         * @param {CreateDictEntry} createDictEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDictEntry(dictType: DictType, createDictEntry: CreateDictEntry, options?: any): AxiosPromise<DictEntry> {
            return localVarFp.createDictEntry(dictType, createDictEntry, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns dict entry list of a given type
         * @param {DictType} dictType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictEntries(dictType: DictType, options?: any): AxiosPromise<Array<DictEntry>> {
            return localVarFp.getDictEntries(dictType, options).then((request) => request(axios, basePath));
        },
        /**
         * Set soft delete flaf of a dict entry of a given type
         * @param {DictType} dictType 
         * @param {string} id 
         * @param {DeleteFlag} deleteFlag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeleted(dictType: DictType, id: string, deleteFlag: DeleteFlag, options?: any): AxiosPromise<void> {
            return localVarFp.setDeleted(dictType, id, deleteFlag, options).then((request) => request(axios, basePath));
        },
        /**
         * Set soft delete flaf of a dict entry of a given type
         * @param {DictType} dictType 
         * @param {string} id 
         * @param {UpdateDict} updateDict 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDict(dictType: DictType, id: string, updateDict: UpdateDict, options?: any): AxiosPromise<void> {
            return localVarFp.updateDict(dictType, id, updateDict, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Create dict entry
     * @param {DictType} dictType 
     * @param {CreateDictEntry} createDictEntry 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createDictEntry(dictType: DictType, createDictEntry: CreateDictEntry, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createDictEntry(dictType, createDictEntry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns dict entry list of a given type
     * @param {DictType} dictType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDictEntries(dictType: DictType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getDictEntries(dictType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set soft delete flaf of a dict entry of a given type
     * @param {DictType} dictType 
     * @param {string} id 
     * @param {DeleteFlag} deleteFlag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setDeleted(dictType: DictType, id: string, deleteFlag: DeleteFlag, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setDeleted(dictType, id, deleteFlag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set soft delete flaf of a dict entry of a given type
     * @param {DictType} dictType 
     * @param {string} id 
     * @param {UpdateDict} updateDict 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateDict(dictType: DictType, id: string, updateDict: UpdateDict, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateDict(dictType, id, updateDict, options).then((request) => request(this.axios, this.basePath));
    }
}


