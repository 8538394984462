import { ReactElement } from "react";
import { QuestionScreen, useThemeMode } from "@ps/ui-components";
import { ReactComponent as DefaultImage } from "./images/default.svg";
import { ReactComponent as DefaultImageHighContrast } from "./images/defaultHighContrast.svg";
import { ReactComponent as SpecialistImage } from "./images/specialist.svg";
import { ReactComponent as SpecialistImageHighContrast } from "./images/specialistHighContrast.svg";
import { ReactComponent as OperativesImage } from "./images/operatives.svg";
import { ReactComponent as OperativesImageHighContrast } from "./images/operativesHighContrast.svg";
import { OPERATIVE, SPECIALIST } from "../../shared/constants";
import { ProfileScreenProps } from "./types";
import { CHOOSE_PROFILE_SCREEN } from "../../shared/data-cy";
import { useTranslationWithNamespace } from "../../hooks";

const ChooseProfileScreen = ({ onClick }: ProfileScreenProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();
  return (
    <QuestionScreen
      question={<>{t("chooseProfileScreen")}</>}
      dataCy={CHOOSE_PROFILE_SCREEN}
      defaultImage={
        isHighContrast ? <DefaultImageHighContrast /> : <DefaultImage />
      }
      hoverOnLeftImage={
        isHighContrast ? <SpecialistImageHighContrast /> : <SpecialistImage />
      }
      hoverOnRightImage={
        isHighContrast ? <OperativesImageHighContrast /> : <OperativesImage />
      }
      leftButtonText={SPECIALIST}
      onClickLeft={() => onClick(SPECIALIST)}
      onClickRight={() => onClick(OPERATIVE)}
      rightButtonText={OPERATIVE}
    />
  );
};

export default ChooseProfileScreen;
