import { ReactElement } from "react";
import { uid } from "react-uid";
import { useFormContext } from "react-hook-form";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import {
  MatrixSkillsTableTemplateMapState,
  MatrixSkillsTableTemplateMapStateReturn,
} from "../../types";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../../../hooks";
import { DictionaryModel } from "../../../../../../../dictionaries";
import { prepareDictionaryToSkillsMatrix } from "../../../../../helpers/utils";
import { DOMAINS_PROFESSION_ID } from "../../../../../../shared/consts";
import TechnologiesTableHeader from "./technologiesTableHeader";
import TechnologiesTableRow from "./technologiesTableRow";
import { useEditMatrixContext } from "../../context";
import {
  IGroup,
  TechnologiesTableProps,
  TechnologiesTableRowProps,
} from "../types";
import { ProfessionTemplateModel } from "../../../../../../models";

const TechnologiesTable = ({
  enjoymentScale,
  groupPathName,
  itemId,
  knowledgeScale,
  onArrayRemoveClick,
  onSubmit,
  professionId,
  professionSkills,
  providedDroppable,
}: TechnologiesTableProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { getValues, reset, handleSubmit } = useFormContext();

  const mapState = (
    state: MatrixSkillsTableTemplateMapState,
  ): MatrixSkillsTableTemplateMapStateReturn => ({
    domainsDict: state?.dictionaries?.domain || [],
    profession: state?.skillsMatrix?.templates?.[professionId],
    technologyDict: state?.dictionaries?.technology || [],
  });
  const { technologyDict, domainsDict, profession } = useMappedStateSelector(
    mapState,
  ) as {
    domainsDict: DictionaryModel;
    profession: ProfessionTemplateModel;
    technologyDict: DictionaryModel;
  };

  const { setEditTechnologies, editTechnologies, setEditTechnologyMode } =
    useEditMatrixContext();

  const handleOnEditTechnologiesCancel = (groupId: string): void => {
    setEditTechnologies((prev) =>
      prev.filter((item: string) => item !== groupId),
    );
    setEditTechnologyMode(false);
    reset(getValues(), { keepIsValid: false });
  };

  const handleRemoveRowFromFields = (
    technology: TechnologiesTableRowProps["technology"],
    rowIndex: number,
  ): void => {
    if (technology.isNew) {
      onArrayRemoveClick(rowIndex);
      handleOnEditTechnologiesCancel(technology.technologyId || "");
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      handleSubmit(onSubmit(getValues()));
      onArrayRemoveClick(rowIndex);
    }
  };

  const domains = prepareDictionaryToSkillsMatrix(domainsDict);
  const technologies = prepareDictionaryToSkillsMatrix(technologyDict);

  const isDomainsSkillsTable = professionId === DOMAINS_PROFESSION_ID;

  const displayTechnologyName = (
    technologyId: string,
    desc?: string,
    isEditMode?: false,
  ): string => {
    if (isEditMode) {
      return isDomainsSkillsTable
        ? domains?.[technologyId]?.name || technologyId
        : technologies?.[technologyId]?.name || technologyId;
    }
    return isDomainsSkillsTable
      ? domains?.[technologyId]?.name ?? desc
      : technologies?.[technologyId]?.name ??
          desc ??
          t("profile.skills.noData.noTechnology");
  };

  const handleTechnologyEdit =
    (technology: TechnologiesTableRowProps["technology"]) => (): void => {
      setEditTechnologies((prev: string[]): string[] => [
        ...prev,
        technology.technologyId,
      ]);
      setEditTechnologyMode(true);
    };

  const renderTechnologiesRows = (
    index: number,
    technology: TechnologiesTableRowProps["technology"],
    provided?: DraggableProvided,
  ): ReactElement => (
    <TechnologiesTableRow
      key={uid(technology)}
      baseSkills={
        profession?.groups?.find(
          (item: IGroup): boolean => item?.itemId === itemId,
        )?.skills
      }
      displayTechnologyName={displayTechnologyName}
      enjoymentScale={enjoymentScale}
      index={index}
      isEdit={editTechnologies.includes(technology?.technologyId)}
      knowledgeScale={knowledgeScale}
      professionId={professionId}
      professionSkills={professionSkills}
      provided={provided}
      technology={technology}
      groupPathName={`${groupPathName}.[${index}]`}
      onEditClick={handleTechnologyEdit(technology)}
      onCancelClick={() =>
        technology.isNew
          ? onArrayRemoveClick(index)
          : handleOnEditTechnologiesCancel(technology.technologyId || "")
      }
      onRemove={() => handleRemoveRowFromFields(technology, index)}
    />
  );

  return (
    <div>
      <TechnologiesTableHeader
        enjoymentScale={enjoymentScale}
        knowledgeScale={knowledgeScale}
        tableType={isDomainsSkillsTable ? DOMAINS_PROFESSION_ID : "technology"}
      />
      <div className="border-b border-l border-r rounded-b-md border-neutralSecondary-60 flex flex-col bg-neutralPrimary-100 overflow-hidden">
        {professionSkills.map(
          (technology, index): ReactElement =>
            isDomainsSkillsTable ? (
              renderTechnologiesRows(index, technology)
            ) : (
              <Draggable
                key={technology.id}
                draggableId={technology.id || ""}
                index={index}
                isDragDisabled={editTechnologies.includes(
                  technology?.technologyId,
                )}
              >
                {(provided: DraggableProvided): ReactElement =>
                  renderTechnologiesRows(index, technology, provided)
                }
              </Draggable>
            ),
        )}
        {providedDroppable?.placeholder}
      </div>
    </div>
  );
};

export default TechnologiesTable;
