export const ARTICLES = "articles";
export const TALKS = "talks";
export const CONTRIBUTIONS = "contributions";
export const TUTORS = "tutors";
export const AWARDS = "awards";
export const CERTIFICATES = "certificates";
export const GOALS = "goals";

export const REWARDER = "rewarder";
export const AWARD_NAME = "awardName";
export const URL_PATH = "url";
export const VIDEO_URL = "videoUrl";
export const DATE = "date";
export const START_DATE = "startDate";
export const END_DATE = "endDate";
export const DESCRIPTION = "description";
export const TITLE = "title";
export const ISSUED_DATE = "issuedDate";
export const EXPIRATION_DATE = "expirationDate";
export const EVENT_NAME = "eventName";
export const NAME = "name";
export const PLACEHOLDER = "Placeholder";

const expression =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const URL_REGEXP_PATTERN = new RegExp(expression);
