export const PROJECT = "project";
export const NEW_PROJECT = "newProject";

export const SAVE = "save";
export const CANCEL = "cancel";

export const PROJECT_NAME = "name";
export const PROJECT_NAME_PLACEHOLDER = "projectNamePlaceholder";
export const PROJECTS = "projects";
export const CLIENT_NAME = "clientName";
export const CLIENT = "client";
export const CLIENT_ID = "clientId";
export const PM_NAME = "pmName";
export const BD_NAME = "bdName";
export const CREATION_DATE = "creationDate";
export const STATUS = "status";
export const USERS_IN_PROJECT = "usersInProject";
export const USERS = "users";
export const MORE = "more";
export const COLOR = "color";
export const ASC = "asc";
export const DESC = "desc";

export const GENERAL_NOTE = "generalNote";
export const GENERAL_NOTE_PLACEHOLDER = "generalNotePlaceholder";

export const PADLOCK_TOOLTIP = "padlockTooltip";
export const OPEN = "open";
export const CLOSED = "closed";

export const PUBLIC = "public";
export const PRIVATE = "private";
export const ARCHIVED = "archived";
export const DISABLED = "disabled";

export const ACTIVE = "active";
export const INACTIVE = "inactive";

export const ACTIVE_USERS = "activeUsers";
export const INACTIVE_USERS = "inactiveUsers";

export const DETAILS = "details";
export const TEAM = "team";
export const FILE_REPOSITORY = "fileRepository";

export const CONTACT_PERSON = "contactPerson";
export const FIRST_NAME = "firstName";
export const EMAIL = "email";
export const PHONE_NUMBER = "phoneNumber";
export const COMPANY_NAME = "companyName";
export const COUNTRY = "country";
export const ZIP_CODE = "zipCode";
export const ADDRESS = "address";
export const TAX_NUMBER = "taxNumber";
export const ACCOUNT_NUMBER = "accountNumber";
export const TECH_STACK = "techStack";
export const DOMAIN = "domains";
export const PM = "pm";
export const BD = "bd";
export const INVOICE_DETAILS = "invoiceDetails";

export const ADD = "add";

export const SEARCH = "search";
export const NAME = "name";
export const PROFESSION = "profession";
export const PROFESSIONS = "professions";
export const SENIORITY = "seniority";
export const ADD_TO_PROJECT = "addToProject";
export const UPDATE_MEMBER = "updateMember";
export const REJECTED = "rejected";
export const LOCATION = "location";
export const RESIDENCE = "residence";
export const MAIN = "main";

export const FULL_NAME = "noForeignCharsFullName";
export const USER_NAME = "userName";

export const START_DATE = "startDate";
export const FINISH_DATE = "finishDate";
export const NOTICE_PERIOD = "noticePeriod";
export const ENGAGEMENT = "engagement";
export const RATE_PER_USER = "ratePerUser";
export const CURRENCY = "currency";
export const RATE = "rate";
export const STATEMENT_OF_WORK = "statementOfWork";
export const AMOUNT = "amount";
export const DELETE_USER = "deleteUser";
export const REASON = "reason";
export const DELETE = "delete";
export const PROJECT_FEEDBACK = "feedback";
export const UNSPECIFIED_END = "unspecifiedEnd";

export const BY_NAME = "byName";
export const BY_DATE = "byDate";
export const BY_STATUS = "byStatus";

export const PROJECT_MANAGER = "projectManager";
export const BUSINESS_DEVELOPER = "businessDeveloper";
export const DELETED = "deleted";
export const FILTERS = "filters";
export const PILL = "pill";
export const USER_DISABLED = "User disabled";
export const DISABLED_ERROR = "disabledError";

export const currencyDict = [
  { id: "GBP", name: "GBP", deleted: false },
  { id: "USD", name: "USD", deleted: false },
  { id: "PLN", name: "PLN", deleted: false },
  { id: "EUR", name: "EUR", deleted: false },
];

export const engagementDict = [
  { id: "full", name: "Full time", deleted: false },
  { id: "part", name: "Part time", deleted: false },
];

export const EMAIL_REGEXP_PATTERN = new RegExp(
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const DATE_FORMAT = "DD/MM/YYYY";

export const UNHAPPY_DEV = "unhappyDev";
export const LOW_PROFIT = "lowProfitability";
export const RISK_OF_CLOSURE = "riskOfClosure";
export const OTHER_REASONS = "otherReasons";
export const ON_PROJECT = "onProject";
export const WITHOUT_PROJECT = "withoutProject";
export const EXCEPT_ON_PROJECT = "exceptOnProject";

export const projectMemberFeedbacks = [
  UNHAPPY_DEV,
  LOW_PROFIT,
  RISK_OF_CLOSURE,
  OTHER_REASONS,
];

export const projectDefaultColors = [
  "#2b6ab4",
  "#50b5d6",
  "#1fc8ff",
  "#93edff",
  "#c8f0f8",
  "#005f73",
  "#0a9396",
  "#94d2bd",
  "#e9d8a6",
  "#ee9b00",
  "#ca6702",
  "#bb3e03",
  "#f278a3",
  "#d14358",
  "#ae2012",
];

export const initialFiltersState = {
  [CLIENT_NAME]: [],
  [PM]: [],
  [BD]: [],
};

export const initialCheckersState = {
  [PUBLIC]: true,
  [PRIVATE]: true,
  [ARCHIVED]: false,
};
