import APIService from "../api/candidate-ml-api";
import {
  fromAPI,
  CandidatesMLResponseModel,
  singleNeedFromAPI,
} from "../models";
import { handleError } from "../../shared";

const DOWN_THRESHOLD_SCORE = 0;

const CandidateMLService = (() => {
  const fetchCandidatesML = async (
    prospectId: string,
  ): Promise<CandidatesMLResponseModel[]> => {
    try {
      const response = await APIService.API.getCandidates(
        prospectId,
        undefined,
        DOWN_THRESHOLD_SCORE,
      );
      if (response?.data) {
        return fromAPI(response.data);
      }
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchCandidatesMLByNeedID = async (
    prospectId: string,
    needID: string,
  ): Promise<CandidatesMLResponseModel> => {
    try {
      const response = await APIService.API.getCandidatesForNeed(
        prospectId,
        needID,
      );
      return singleNeedFromAPI(response.data);
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    fetchCandidatesML,
    fetchCandidatesMLByNeedID,
  };
})();

export default CandidateMLService;
