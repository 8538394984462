import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { ReactElement } from "react";
import { useDispatch } from "redux-react-hook";
import { RestrictedRoute } from "@ps/app";
import { clearRedirection, RedirectAlertModal } from "@ps/alert-modal";
import { useMappedStateSelector } from "../../hooks";
import TeamContainer, { UsersList, SkillsSearch, OtherSummary } from "../ui";
import { USERS_LIST, SKILLS_SEARCH, BENCH } from "../../shared/routes";
import { TeamRoutesMapState } from "./types";
import { USERS_LIST_ALL } from "../../shared/permissions";
import { Bench } from "../../bench-domain";
import { hhPermissions } from "../../../index";

const TeamRoutes = (): ReactElement => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const mapState = (state: TeamRoutesMapState): TeamRoutesMapState => ({
    permissions: state.permissions || [],
  });

  const { permissions } = useMappedStateSelector(mapState, "");

  return (
    <TeamContainer>
      <RedirectAlertModal
        dispatch={dispatch}
        clearRedirection={clearRedirection}
      />
      <Switch>
        <Route exact path={`${path}/${USERS_LIST}`}>
          <UsersList />
        </Route>
        <RestrictedRoute
          path={`${path}/${SKILLS_SEARCH}`}
          permissions={[hhPermissions.MATRIX_FULFILLMENTS]}
        >
          <SkillsSearch />
        </RestrictedRoute>
        <Route exact path={`${path}/${BENCH}`}>
          <Bench />
        </Route>
        <Route path={`${path}/:parentPath/:id/`}>
          <OtherSummary />
        </Route>
        <Route>
          <Redirect
            to={`${path}/${
              permissions.includes(USERS_LIST_ALL) ? USERS_LIST : SKILLS_SEARCH
            }`}
          />
        </Route>
      </Switch>
    </TeamContainer>
  );
};

export default TeamRoutes;
