import { ReactElement, useEffect, useState } from "react";
import { classJoin } from "@ps/utils";
import styles from "./styles.module.css";
import { IOption, ToggleInlineProps } from "./types";
import { TOGGLE_INLINE_PREFIX } from "../../shared/data-cy-2";

const ToggleInline = ({
  dataCy,
  onClick,
  options,
  selected,
  error,
  message,
}: ToggleInlineProps): ReactElement => {
  const [clickNumber, setClickNumber] = useState(0);

  useEffect(() => {
    if (selected) setClickNumber(2);
  }, []);

  const preparedOptions: IOption[] | string[] =
    typeof options?.[0] === "string"
      ? options?.map((item: string, index: number) => ({
          label: item,
          value: index,
        }))
      : options;

  const preparedSelected =
    typeof selected === "number" ? preparedOptions?.[selected] : selected;

  const getProperStyles = () => {
    if (
      typeof preparedSelected === "undefined" ||
      typeof preparedSelected === "string"
    )
      return {};

    return {
      transform:
        preparedSelected?.value === 0
          ? "translateX(0)"
          : `translateX(${preparedSelected?.value * 100 - 2}%)`,
    };
  };

  const setTransition = (): string => {
    if (clickNumber && clickNumber === 1)
      return `w-1/${preparedOptions?.length}`;
    if (typeof preparedSelected === "undefined") return "";
    return `${styles.toggleElement} w-1/${preparedOptions?.length}`;
  };

  return (
    <div>
      <div
        data-cy={`${TOGGLE_INLINE_PREFIX}-${dataCy}`}
        className={classJoin(
          "bg-neutralPrimary-85 flex items-center rounded-lg w-full h-9 relative",
          error && "border border-error-50",
        )}
      >
        <div
          style={getProperStyles()}
          className={classJoin(
            "cursor-default h-7 absolute rounded-lg bg-primary-100 left-1",
            setTransition(),
          )}
        />

        {preparedOptions?.map(
          (
            item: IOption | string,
            index: number,
            arr: IOption[] | string[],
          ) => (
            <button
              className={classJoin(
                "cursor-default flex items-center justify-center w-full h-7 z-10",
                typeof preparedSelected === "undefined" &&
                  index < arr.length - 1
                  ? "border-r border-neutralSecondary-60"
                  : "",
                preparedSelected?.value === index
                  ? "text-secondary-60"
                  : "text-neutralPrimary-20 cursor-pointer",
              )}
              key={item.label}
              id={item.label}
              name={item.label}
              type="button"
              value={item?.value}
              onClick={() => {
                onClick({
                  label: item?.label,
                  value: item?.value,
                });
              }}
            >
              {item?.label}
            </button>
          ),
        )}
      </div>
      {message && (
        <span
          className={classJoin("mt-1 text-xs", error ? "text-error-50" : "")}
        >
          {message}
        </span>
      )}
    </div>
  );
};

export default ToggleInline;
