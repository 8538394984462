import APIService from "../api/folder-api";
import {
  addCandidatesToAPI,
  fromAPIToModel,
  foldersListFromAPIToModel,
  folderAnonymizationToModel,
  folderFieldsUpdateToModel,
  updateFolderToAPIModel,
  NewCandidateAPIModel,
} from "../models";
import { handleError } from "../../shared";

const FolderService = (() => {
  const addCandidates = async (
    folderId: string,
    data: NewCandidateAPIModel[],
  ) => {
    try {
      const response = await APIService.API.addCandidates(
        folderId,
        addCandidatesToAPI(data),
      );
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };

  const removeCandidate = async (folderId: string, candidateId: string) => {
    try {
      const response = await APIService.API.deleteCandidate(
        folderId,
        candidateId,
      );
      return response?.data;
    } catch (error) {
      return handleError(error);
    }
  };

  const updateCandidate = async (
    folderId: string,
    candidateId: string,
    overriddenSeniority: string,
  ) => {
    try {
      const response = await APIService.API.updateCandidate(
        folderId,
        candidateId,
        { overriddenSeniority },
      );
      return response?.data;
    } catch (error) {
      return handleError(error);
    }
  };

  const getFolder = async (folderId: string) => {
    try {
      const result = await APIService.API.getFolder(folderId);
      if (result?.data) {
        return fromAPIToModel(result.data);
      }
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const updateFolder = async (folderId: string, data) => {
    try {
      const response = await APIService.API.updateFolder(
        folderId,
        data?.folder
          ? updateFolderToAPIModel(folderFieldsUpdateToModel(data))
          : updateFolderToAPIModel(folderAnonymizationToModel(data)),
      );
      return response?.data;
    } catch (error) {
      return handleError(error);
    }
  };

  const getAllFolders = async (clientId?: string, archived?: boolean) => {
    try {
      const result = await APIService.API.getAllFolders(clientId, archived);
      if (result?.data) {
        return foldersListFromAPIToModel(result.data);
      }
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const createFolder = async (folderName = "", clientId: string) => {
    try {
      const result = await APIService.API.createFolder({
        clientId,
        name: folderName,
      });
      return result?.data?.id;
    } catch (error) {
      return handleError(error);
    }
  };

  const archiveFolder = async (id: string) => {
    try {
      return APIService.API.archiveFolder(id);
    } catch (error) {
      return handleError(error);
    }
  };

  const unarchiveFolder = async (id: string) => {
    try {
      return APIService.API.unarchiveFolder(id);
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    addCandidates,
    removeCandidate,
    updateCandidate,
    getFolder,
    updateFolder,
    getAllFolders,
    createFolder,
    archiveFolder,
    unarchiveFolder,
  };
})();

export default FolderService;
