import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import styles from "../projects/styles.module.css";
import { PROFILE_SUMMARY_PROJECTS } from "../../../../shared/data-cy";
import { OtherExperienceModalProps } from "../../types";
import Modal, { ModalContent } from "../../../modal";

const OtherExperienceModal = ({
  isOpen,
  onClose,
  children,
}: OtherExperienceModalProps): ReactElement => (
  <Modal
    additionalClassName={classJoin(styles.modal, "overflow-hidden flex")}
    additionalContentClassName="flex flex-col gap-4 h-auto"
    dataCy={`${PROFILE_SUMMARY_PROJECTS}_projectDetails`}
    height="h-auto"
    isOpen={isOpen}
    onClose={onClose}
    width="w-10/12"
    withCloseIcon
    withOutsideClick
  >
    <ModalContent
      additionalClassName={classJoin(
        "flex flex-col gap-4",
        styles.modalContent,
      )}
      height="h-initial"
    >
      {children}
    </ModalContent>
  </Modal>
);

export default OtherExperienceModal;
