import { Dayjs } from "dayjs";

export type CompareMode = "day" | "month" | "quarter" | "year";

export const dateSameOrAfter =
  (date1: Dayjs | undefined, compareMode: CompareMode) =>
  (date2: Dayjs | undefined): boolean => {
    if (!date1 || !date2) return false;
    return (
      date2.isAfter(date1, compareMode) || date2.isSame(date1, compareMode)
    );
  };

export const dateSameOrBefore =
  (date1: Dayjs | undefined, compareMode: CompareMode) =>
  (date2: Dayjs | undefined): boolean => {
    if (!date1 || !date2) return false;
    return (
      date2.isBefore(date1, compareMode) || date2.isSame(date1, compareMode)
    );
  };

export const dateInBetween =
  (
    rangeStart: Dayjs | undefined,
    rangeEnd: Dayjs | undefined,
    compareMode: CompareMode,
  ) =>
  (date: Dayjs | undefined): boolean =>
    dateSameOrAfter(rangeStart, compareMode)(date) &&
    dateSameOrBefore(rangeEnd, compareMode)(date);

export const dateInBetweenExclusive =
  (
    rangeStart: Dayjs | undefined,
    rangeEnd: Dayjs | undefined,
    compareMode: CompareMode,
  ) =>
  (date: Dayjs | undefined): boolean => {
    if (!rangeStart || !rangeEnd || !date) return false;
    return (
      date.isAfter(rangeStart, compareMode) &&
      date.isBefore(rangeEnd, compareMode)
    );
  };

export const shouldApplyHoveringStyle =
  (
    hover: Dayjs | undefined,
    rangeStart: Dayjs | undefined,
    rangeEnd: Dayjs | undefined,
    compareMode: CompareMode,
  ) =>
  (date?: Dayjs): boolean => {
    if (!hover) return false;
    if (rangeStart && dateInBetween(rangeStart, hover, compareMode)(date))
      return true;
    if (rangeEnd && dateInBetween(hover, rangeEnd, compareMode)(date))
      return true;
    return false;
  };

export const isNotEmpty = (value: string): boolean => value !== "";

type TimeComparatorSingle = (date: Dayjs | undefined) => boolean | undefined;
type TimeComparatorRange = (
  rangeStart: Dayjs | undefined,
  rangeEnd: Dayjs | undefined,
) => boolean | undefined;
interface TimeComparators {
  isBefore: TimeComparatorSingle;
  isAfter: TimeComparatorSingle;
  isSame: TimeComparatorSingle;
  isSameOrBefore: TimeComparatorSingle;
  isSameOrAfter: TimeComparatorSingle;
  subtractOne: () => TimeComparators;
  inBetweenExclusive: TimeComparatorRange;
  inBetween: TimeComparatorRange;
  inBetweenHoverAndRange: (
    hoveringOverDate: Dayjs | undefined,
    rangeStart: Dayjs | undefined,
    rangeEnd: Dayjs | undefined,
  ) => boolean;
}

export const timeComparatorsForCompareMode =
  (compareMode: CompareMode) =>
  (timeUnit?: Dayjs): TimeComparators => {
    const isBefore: TimeComparatorSingle = (date?: Dayjs) =>
      date && timeUnit?.isBefore(date, compareMode);

    const isAfter = (date?: Dayjs) =>
      date && timeUnit?.isAfter(date, compareMode);

    const isSame = (date?: Dayjs) =>
      date && timeUnit?.isSame(date, compareMode);

    const isSameOrBefore = (date?: Dayjs) =>
      date && (timeUnit?.isSame(date) || timeUnit?.isBefore(date));

    const isSameOrAfter = (date?: Dayjs) =>
      date && (timeUnit?.isSame(date) || timeUnit?.isAfter(date));

    const subtractOne = () =>
      timeComparatorsForCompareMode(compareMode)(
        timeUnit?.subtract(1, compareMode),
      );

    const inBetweenExclusive: TimeComparatorRange = (rangeStart, rangeEnd) =>
      rangeStart &&
      rangeEnd &&
      dateInBetweenExclusive(rangeStart, rangeEnd, compareMode)(timeUnit);

    const inBetween: TimeComparatorRange = (rangeStart, rangeEnd) =>
      dateInBetween(rangeStart, rangeEnd, compareMode)(timeUnit);

    const inBetweenHoverAndRange: TimeComparators["inBetweenHoverAndRange"] = (
      hoveringOverDate,
      rangeStart,
      rangeEnd,
    ) =>
      shouldApplyHoveringStyle(
        hoveringOverDate,
        rangeStart,
        rangeEnd,
        compareMode,
      )(timeUnit);

    return {
      isBefore,
      isAfter,
      isSame,
      isSameOrBefore,
      isSameOrAfter,
      subtractOne,
      inBetweenExclusive,
      inBetween,
      inBetweenHoverAndRange,
    };
  };
