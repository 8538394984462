// eslint-disable-next-line prodigal-son/no-use-translation
import { TFunction, useTranslation } from "react-i18next";
import { NAMESPACE } from "../shared/constants";

function useTranslationWithNamespace(namespace = NAMESPACE): {
  t: TFunction<string>;
} {
  // eslint-disable-next-line prodigal-son/no-use-translation
  const { t } = useTranslation(namespace);
  return { t };
}

export default useTranslationWithNamespace;
