import { useEffect, ReactElement } from "react";
import { useDispatch } from "redux-react-hook";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { LoadingMainPage } from "@ps/ui-components";
import SectionWrapper from "./sectionWrapper";
import { DETAILS } from "../../../constants";
import DetailsEdit from "./edit/details";
import DetailsView from "./view/details";
import { ProspectService } from "../../../services";
import { toggleEditMode } from "../../../../store";
import { useMappedStateSelector } from "../../../../hooks";
import { fetchProspect } from "../../../store";
import { DetailsMapStateReturn, DetailsMapState } from "./types";

const Details = (): ReactElement => {
  const mapState = (state: DetailsMapState): DetailsMapStateReturn => ({
    details: state.prospects?.prospect?.details || {},
    isFetchingProspectDetails: state.requestStatus.isFetchingProspectDetails,
    technologyDict: state?.dictionaries?.technology || [],
  });

  const { details, isFetchingProspectDetails, technologyDict } =
    useMappedStateSelector(mapState);

  const methods = useForm({ defaultValues: details });
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    methods.reset(details);
  }, [params.id, details]);

  const onSubmit = async (data) => {
    const output = await methods.trigger();
    if (output) {
      await ProspectService.updateProspect(params.id, data);
      dispatch(toggleEditMode(""));
      await fetchProspect(params.id, dispatch, technologyDict);
    }
  };

  return isFetchingProspectDetails ? (
    <LoadingMainPage additionalClassName="bg-neutralPrimary-85" />
  ) : (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="w-full h-full">
        <div className="grid gap-4 -mx-10">
          <SectionWrapper
            Edit={DetailsEdit}
            name={DETAILS}
            section={DETAILS}
            View={DetailsView}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default Details;
