import { ReactElement } from "react";
import { DisabledInfoProps } from "./types";

const DisabledInfo = ({ label }: DisabledInfoProps): ReactElement => {
  return (
    <div
      className="flex justify-center items-center gap-x-4 px-5 py-1 bg-neutralPrimary-100
      border border-gray-300 rounded-full p-2 text-gray-300 w-44"
    >
      {label}
    </div>
  );
};

export default DisabledInfo;
