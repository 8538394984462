import { ReactElement, useState, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { Scrollbars } from "react-custom-scrollbars-2";
import { getName } from "i18n-iso-countries";
import { sortArray } from "@ps/utils";
import { ScrollableWrapper, LoadingAnyPage } from "@ps/ui-components";
import { CURRENT_LANGUAGE_CODE } from "../../i18n/constants";
import { SessionStorage } from "../../shared";
import { useMappedStateSelector, useSessionStorage } from "../../hooks";
import {
  fetchBenchUsers,
  resetOpenedFoldersStatuses,
  setSorterByProperty,
} from "../store";
import { BenchUsersModel, BenchUserModel } from "../models";
import {
  filterBenchUsersBySearch,
  filterBenchUsersByStatus,
  getFilterOptions,
} from "../utils";
import {
  NAME,
  PROFESSION,
  SENIORITY,
  LOCATION,
  REASON,
  initialCheckersState,
  BENCH,
} from "../constants";
import { BenchCheckers, BenchMapState, BenchMapStateReturn } from "./types";
import SearchRow from "./searchRow";
import SortBar from "./sort-bar";
import Row from "./row";
import {
  SESSION_SORTERS,
  initialSessionSortersState,
} from "../../shared/constants";

const Bench = (): ReactElement => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    fetchBenchUsers(dispatch);
  }, []);

  const mapState = (state: BenchMapState): BenchMapStateReturn => ({
    professionDict: state?.dictionaries?.profession || [],
    seniorityDict: state?.dictionaries?.seniority || [],
    roleDict: state?.dictionaries?.project_role || [],
    activeSorter: state.bench.sorter,
    filters: state.bench.filters,
    users: state.bench?.users || [],
    openedFoldersStatuses: state.bench?.openedFoldersStatuses || [],
    isLoading: state.requestStatus.isFetchingBenchUsers,
  });

  const {
    professionDict,
    seniorityDict,
    roleDict,
    activeSorter,
    filters,
    users,
    openedFoldersStatuses,
    isLoading,
  } = useMappedStateSelector(mapState);

  useEffect(() => {
    if (openedFoldersStatuses.length) dispatch(resetOpenedFoldersStatuses());
  }, []);

  const [storageSorter, setStorageSorter] = useSessionStorage<SessionStorage>(
    SESSION_SORTERS,
    initialSessionSortersState,
  );

  const [benchCheckers, setBenchCheckers] =
    useState<BenchCheckers>(initialCheckersState);
  const [benchUsers, setBenchUsers] = useState<BenchUsersModel>([]);

  const mappedBenchUsers = users?.map(
    (benchUser: BenchUserModel): BenchUserModel => ({
      ...benchUser,
      profession: benchUser.profession,
      seniority: benchUser.seniority,
      location: getName(benchUser.location, CURRENT_LANGUAGE_CODE),
    }),
  );

  const usersByStatus = filterBenchUsersByStatus(
    mappedBenchUsers,
    benchCheckers,
  );

  function filterUsers(usersByStatus: BenchUserModel[]): BenchUsersModel {
    const filteredUsers: BenchUsersModel = usersByStatus.filter(
      (benchUser: BenchUserModel): boolean =>
        Object.entries(filters).every(
          ([key, values]) =>
            (values as string[]).length === 0 ||
            (values as string[]).includes(benchUser[key]),
        ),
    );
    return filteredUsers;
  }

  function sortUsers(filteredUsers: BenchUsersModel): BenchUsersModel {
    const { bench } = storageSorter.sessionSorters;

    const sortedUsers: BenchUsersModel = sortArray(
      filteredUsers,
      bench?.name && bench?.order
        ? (bench.name as keyof BenchUserModel)
        : (activeSorter.name as keyof BenchUserModel),
      bench ? bench.order : activeSorter.order,
    );
    return sortedUsers;
  }

  useEffect(() => {
    if (mappedBenchUsers.length) {
      const byStatus = filterBenchUsersByStatus(
        mappedBenchUsers,
        benchCheckers,
      );

      const byFilter = filterUsers(byStatus);
      const bySearch = filterBenchUsersBySearch(byFilter, searchValue);
      const bySorter = sortUsers(bySearch);

      setBenchUsers(bySorter);
    }
  }, [
    seniorityDict,
    professionDict,
    roleDict,
    filters,
    users,
    benchCheckers,
    activeSorter.name,
    activeSorter.order,
    searchValue,
  ]);

  const namesFilterOptions: string[] = getFilterOptions(usersByStatus, NAME);

  const professionsFilterOptions: string[] = getFilterOptions(
    usersByStatus,
    PROFESSION,
  );
  const senioritiesFilterOptions: string[] = getFilterOptions(
    usersByStatus,
    SENIORITY,
  );
  const locationFilterOptions: string[] = getFilterOptions(
    usersByStatus,
    LOCATION,
  );
  const reasonsFilterOptions: string[] = getFilterOptions(
    usersByStatus,
    REASON,
  );

  const handleOnClickSorter = (fieldName: string, order: string): void => {
    setStorageSorter((prevData) => ({
      ...prevData,
      sessionSorters: {
        ...prevData.sessionSorters,
        [BENCH]: {
          name: fieldName,
          order,
        },
      },
    }));

    setSorterByProperty(dispatch, order, fieldName);
  };

  const availableAmount = users.filter(
    (u: BenchUserModel) => u?.availability !== "",
  ).length;

  const busyAmount = users.filter(
    (u: BenchUserModel) => u?.availability === "",
  ).length;

  if (isLoading) return <LoadingAnyPage />;

  return (
    <div className="flex flex-col gap-2 h-full w-full">
      <SearchRow
        searchValue={searchValue}
        onSearchValueChange={setSearchValue}
        allAmount={users.length}
        availableAmount={availableAmount}
        busyAmount={busyAmount}
        benchCheckers={benchCheckers}
        setBenchCheckers={setBenchCheckers}
        handleSorterClick={handleOnClickSorter}
        activeSorter={
          storageSorter?.sessionSorters?.bench?.name?.length
            ? storageSorter?.sessionSorters?.bench
            : activeSorter
        }
      />
      <ScrollableWrapper>
        <SortBar
          benchUsers={usersByStatus}
          filterOptions={{
            namesFilterOptions,
            professionsFilterOptions,
            senioritiesFilterOptions,
            locationFilterOptions,
            reasonsFilterOptions,
          }}
        />
        <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
          <div className="flex flex-col w-full">
            {benchUsers?.length
              ? benchUsers.map(
                  (benchUser: BenchUserModel): ReactElement => (
                    <Row user={benchUser} key={benchUser.userId} />
                  ),
                )
              : null}
          </div>
        </Scrollbars>
      </ScrollableWrapper>
    </div>
  );
};

export default Bench;
