/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Matrix API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Each RGB value needs to be between 0 and 255
 * @export
 * @interface Color
 */
export interface Color {
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'red': number;
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'green': number;
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'blue': number;
}
/**
 * Score for knowledge and enjoyment for a given domain, values should be compatible with the scale given by the DomainMatrixTemplate
 * @export
 * @interface DomainRating
 */
export interface DomainRating {
    /**
     * 
     * @type {number}
     * @memberof DomainRating
     */
    'knowledge'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainRating
     */
    'enjoyment'?: number;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface GetDomainMatrixFulfillment
 */
export interface GetDomainMatrixFulfillment {
    /**
     * 
     * @type {string}
     * @memberof GetDomainMatrixFulfillment
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetDomainMatrixFulfillment
     */
    'updatedAt': string;
    /**
     * A DomainId-to-DomainRating dictionary
     * @type {{ [key: string]: DomainRating; }}
     * @memberof GetDomainMatrixFulfillment
     */
    'ratings': { [key: string]: DomainRating; };
}
/**
 * 
 * @export
 * @interface GetDomainMatrixTemplate
 */
export interface GetDomainMatrixTemplate {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDomainMatrixTemplate
     */
    'domains': Array<string>;
    /**
     * 
     * @type {Array<GetRating>}
     * @memberof GetDomainMatrixTemplate
     */
    'knowledgeScale': Array<GetRating>;
    /**
     * 
     * @type {Array<GetRating>}
     * @memberof GetDomainMatrixTemplate
     */
    'enjoymentScale': Array<GetRating>;
}
/**
 * 
 * @export
 * @interface GetRating
 */
export interface GetRating {
    /**
     * Score tied to a given rating
     * @type {number}
     * @memberof GetRating
     */
    'score': number;
    /**
     * A non blank description that gives us context about the entry
     * @type {string}
     * @memberof GetRating
     */
    'desc': string;
    /**
     * 
     * @type {Color}
     * @memberof GetRating
     */
    'color'?: Color;
    /**
     * URL of an image that\'ll serve as an icon for this entry
     * @type {string}
     * @memberof GetRating
     */
    'iconUrl'?: string;
}
/**
 * 
 * @export
 * @interface GetSkillMatrixFulfillment
 */
export interface GetSkillMatrixFulfillment {
    /**
     * 
     * @type {string}
     * @memberof GetSkillMatrixFulfillment
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetSkillMatrixFulfillment
     */
    'professionId': string;
    /**
     * A TechnologyId-to-TechnologyRating dictionary
     * @type {{ [key: string]: TechnologyRating; }}
     * @memberof GetSkillMatrixFulfillment
     */
    'ratings': { [key: string]: TechnologyRating; };
}
/**
 * 
 * @export
 * @interface GetSkillMatrixTemplate
 */
export interface GetSkillMatrixTemplate {
    /**
     * 
     * @type {string}
     * @memberof GetSkillMatrixTemplate
     */
    'professionId': string;
    /**
     * Groups fetched from the template
     * @type {Array<Group>}
     * @memberof GetSkillMatrixTemplate
     */
    'groups': Array<Group>;
    /**
     * 
     * @type {Array<GetRating>}
     * @memberof GetSkillMatrixTemplate
     */
    'knowledgeScale': Array<GetRating>;
    /**
     * 
     * @type {Array<GetRating>}
     * @memberof GetSkillMatrixTemplate
     */
    'enjoymentScale': Array<GetRating>;
}
/**
 * Group of skills under the given profession
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'name': string;
    /**
     * 
     * @type {Array<Skill>}
     * @memberof Group
     */
    'skills': Array<Skill>;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'desc'?: string;
}
/**
 * A short description of a given technology
 * @export
 * @interface Skill
 */
export interface Skill {
    /**
     * 
     * @type {string}
     * @memberof Skill
     */
    'technologyId': string;
    /**
     * 
     * @type {string}
     * @memberof Skill
     */
    'desc'?: string;
    /**
     * 
     * @type {number}
     * @memberof Skill
     */
    'difficulty'?: number;
}
/**
 * Score for knowledge and enjoyment for a given technology, values should be compatible with the scale given by the SkillMatrixTemplate
 * @export
 * @interface TechnologyRating
 */
export interface TechnologyRating {
    /**
     * 
     * @type {number}
     * @memberof TechnologyRating
     */
    'knowledge'?: number;
    /**
     * 
     * @type {number}
     * @memberof TechnologyRating
     */
    'enjoyment'?: number;
}
/**
 * 
 * @export
 * @interface UpdateDomainMatrixFulfillment
 */
export interface UpdateDomainMatrixFulfillment {
    /**
     * A DomainId-to-DomainRating dictionary
     * @type {{ [key: string]: DomainRating; }}
     * @memberof UpdateDomainMatrixFulfillment
     */
    'ratings': { [key: string]: DomainRating; };
}
/**
 * 
 * @export
 * @interface UpdateDomainMatrixTemplate
 */
export interface UpdateDomainMatrixTemplate {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDomainMatrixTemplate
     */
    'domains': Array<string>;
    /**
     * Index-sensitive rating scale (the value of the rating is based on its index in the array)
     * @type {Array<UpdateRating>}
     * @memberof UpdateDomainMatrixTemplate
     */
    'knowledgeScale': Array<UpdateRating>;
    /**
     * Index-sensitive rating scale (the value of the rating is based on its index in the array)
     * @type {Array<UpdateRating>}
     * @memberof UpdateDomainMatrixTemplate
     */
    'enjoymentScale': Array<UpdateRating>;
}
/**
 * 
 * @export
 * @interface UpdateRating
 */
export interface UpdateRating {
    /**
     * A non blank description that gives us context about the entry
     * @type {string}
     * @memberof UpdateRating
     */
    'desc': string;
    /**
     * 
     * @type {Color}
     * @memberof UpdateRating
     */
    'color'?: Color;
    /**
     * URL of an image that\'ll serve as an icon for this entry
     * @type {string}
     * @memberof UpdateRating
     */
    'iconUrl'?: string;
}
/**
 * 
 * @export
 * @interface UpdateSkillMatrixFulfillment
 */
export interface UpdateSkillMatrixFulfillment {
    /**
     * A TechnologyId-to-TechnologyRating dictionary
     * @type {{ [key: string]: TechnologyRating; }}
     * @memberof UpdateSkillMatrixFulfillment
     */
    'ratings': { [key: string]: TechnologyRating; };
}
/**
 * 
 * @export
 * @interface UpdateSkillMatrixTemplate
 */
export interface UpdateSkillMatrixTemplate {
    /**
     * Groups to be included in the template
     * @type {Array<Group>}
     * @memberof UpdateSkillMatrixTemplate
     */
    'groups': Array<Group>;
    /**
     * Index-sensitive rating scale (the value of the rating is based on its index in the array)
     * @type {Array<UpdateRating>}
     * @memberof UpdateSkillMatrixTemplate
     */
    'knowledgeScale': Array<UpdateRating>;
    /**
     * Index-sensitive rating scale (the value of the rating is based on its index in the array)
     * @type {Array<UpdateRating>}
     * @memberof UpdateSkillMatrixTemplate
     */
    'enjoymentScale': Array<UpdateRating>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch matrix fulfillment of a user with given id
         * @param {string} userId UserId to fetch the fulfillment of
         * @param {string} date Date to fetch the history fulfillment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHistorySkillMatrixFulfillments: async (userId: string, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllHistorySkillMatrixFulfillments', 'userId', userId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAllHistorySkillMatrixFulfillments', 'date', date)
            const localVarPath = `/matrix/users/{userId}/fulfillments/skill/history`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch matrix fulfillment of a user with given id
         * @param {string} userId UserId to fetch the history fulfillment of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSkillMatrixFulfillments: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllSkillMatrixFulfillments', 'userId', userId)
            const localVarPath = `/matrix/users/{userId}/fulfillments/skill`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch DomainMatrixFulfillment of the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUserDomainMatrixFulfillment: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/matrix/me/fulfillments/domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch matrix fulfillment of an authed user
         * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUserSkillMatrixFulfillment: async (professionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'professionId' is not null or undefined
            assertParamExists('getAuthedUserSkillMatrixFulfillment', 'professionId', professionId)
            const localVarPath = `/matrix/me/fulfillments/skill/professions/{professionId}`
                .replace(`{${"professionId"}}`, encodeURIComponent(String(professionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch DomainMatrixFulfillment of the specified user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainMatrixFulfillment: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getDomainMatrixFulfillment', 'userId', userId)
            const localVarPath = `/matrix/users/{userId}/fulfillments/domain`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch current DomainMatrixTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainMatrixTemplate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/matrix/templates/domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch matrix fulfillment of a user with given id
         * @param {string} userId UserId to fetch the fulfillment of
         * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillMatrixFulfillment: async (userId: string, professionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSkillMatrixFulfillment', 'userId', userId)
            // verify required parameter 'professionId' is not null or undefined
            assertParamExists('getSkillMatrixFulfillment', 'professionId', professionId)
            const localVarPath = `/matrix/users/{userId}/fulfillments/skill/professions/{professionId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"professionId"}}`, encodeURIComponent(String(professionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a matrix template tied to the given ProfessionId
         * @param {string} professionId ProfessionId tied to the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillMatrixTemplate: async (professionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'professionId' is not null or undefined
            assertParamExists('getSkillMatrixTemplate', 'professionId', professionId)
            const localVarPath = `/matrix/templates/skill/professions/{professionId}`
                .replace(`{${"professionId"}}`, encodeURIComponent(String(professionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update only the specified ratings in newest fulfillment
         * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
         * @param {UpdateSkillMatrixFulfillment} updateSkillMatrixFulfillment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partiallyUpdateAuthedUserSkillMatrixFulfillment: async (professionId: string, updateSkillMatrixFulfillment: UpdateSkillMatrixFulfillment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'professionId' is not null or undefined
            assertParamExists('partiallyUpdateAuthedUserSkillMatrixFulfillment', 'professionId', professionId)
            // verify required parameter 'updateSkillMatrixFulfillment' is not null or undefined
            assertParamExists('partiallyUpdateAuthedUserSkillMatrixFulfillment', 'updateSkillMatrixFulfillment', updateSkillMatrixFulfillment)
            const localVarPath = `/matrix/me/fulfillments/skill/professions/{professionId}`
                .replace(`{${"professionId"}}`, encodeURIComponent(String(professionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSkillMatrixFulfillment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update or create a DomainMatrixFulfillment for the current user
         * @param {UpdateDomainMatrixFulfillment} updateDomainMatrixFulfillment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthedUserDomainMatrixFulfillment: async (updateDomainMatrixFulfillment: UpdateDomainMatrixFulfillment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDomainMatrixFulfillment' is not null or undefined
            assertParamExists('updateAuthedUserDomainMatrixFulfillment', 'updateDomainMatrixFulfillment', updateDomainMatrixFulfillment)
            const localVarPath = `/matrix/me/fulfillments/domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDomainMatrixFulfillment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create/update skill matrix fulfillment of a current user
         * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
         * @param {UpdateSkillMatrixFulfillment} updateSkillMatrixFulfillment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthedUserSkillMatrixFulfillment: async (professionId: string, updateSkillMatrixFulfillment: UpdateSkillMatrixFulfillment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'professionId' is not null or undefined
            assertParamExists('updateAuthedUserSkillMatrixFulfillment', 'professionId', professionId)
            // verify required parameter 'updateSkillMatrixFulfillment' is not null or undefined
            assertParamExists('updateAuthedUserSkillMatrixFulfillment', 'updateSkillMatrixFulfillment', updateSkillMatrixFulfillment)
            const localVarPath = `/matrix/me/fulfillments/skill/professions/{professionId}`
                .replace(`{${"professionId"}}`, encodeURIComponent(String(professionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSkillMatrixFulfillment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update or create DomainMatrixTemplate
         * @param {UpdateDomainMatrixTemplate} updateDomainMatrixTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDomainMatrixTemplate: async (updateDomainMatrixTemplate: UpdateDomainMatrixTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDomainMatrixTemplate' is not null or undefined
            assertParamExists('updateDomainMatrixTemplate', 'updateDomainMatrixTemplate', updateDomainMatrixTemplate)
            const localVarPath = `/matrix/templates/domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDomainMatrixTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update or create SkillMatrixTemplate tied to the supplied professionId with more or less groups
         * @param {string} professionId 
         * @param {UpdateSkillMatrixTemplate} updateSkillMatrixTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkillMatrixTemplate: async (professionId: string, updateSkillMatrixTemplate: UpdateSkillMatrixTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'professionId' is not null or undefined
            assertParamExists('updateSkillMatrixTemplate', 'professionId', professionId)
            // verify required parameter 'updateSkillMatrixTemplate' is not null or undefined
            assertParamExists('updateSkillMatrixTemplate', 'updateSkillMatrixTemplate', updateSkillMatrixTemplate)
            const localVarPath = `/matrix/templates/skill/professions/{professionId}`
                .replace(`{${"professionId"}}`, encodeURIComponent(String(professionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSkillMatrixTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch matrix fulfillment of a user with given id
         * @param {string} userId UserId to fetch the fulfillment of
         * @param {string} date Date to fetch the history fulfillment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllHistorySkillMatrixFulfillments(userId: string, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetSkillMatrixFulfillment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllHistorySkillMatrixFulfillments(userId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch matrix fulfillment of a user with given id
         * @param {string} userId UserId to fetch the history fulfillment of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSkillMatrixFulfillments(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetSkillMatrixFulfillment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSkillMatrixFulfillments(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch DomainMatrixFulfillment of the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthedUserDomainMatrixFulfillment(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDomainMatrixFulfillment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthedUserDomainMatrixFulfillment(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch matrix fulfillment of an authed user
         * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthedUserSkillMatrixFulfillment(professionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSkillMatrixFulfillment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthedUserSkillMatrixFulfillment(professionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch DomainMatrixFulfillment of the specified user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDomainMatrixFulfillment(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDomainMatrixFulfillment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDomainMatrixFulfillment(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch current DomainMatrixTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDomainMatrixTemplate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDomainMatrixTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDomainMatrixTemplate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch matrix fulfillment of a user with given id
         * @param {string} userId UserId to fetch the fulfillment of
         * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkillMatrixFulfillment(userId: string, professionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSkillMatrixFulfillment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkillMatrixFulfillment(userId, professionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a matrix template tied to the given ProfessionId
         * @param {string} professionId ProfessionId tied to the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkillMatrixTemplate(professionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSkillMatrixTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkillMatrixTemplate(professionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update only the specified ratings in newest fulfillment
         * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
         * @param {UpdateSkillMatrixFulfillment} updateSkillMatrixFulfillment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partiallyUpdateAuthedUserSkillMatrixFulfillment(professionId: string, updateSkillMatrixFulfillment: UpdateSkillMatrixFulfillment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partiallyUpdateAuthedUserSkillMatrixFulfillment(professionId, updateSkillMatrixFulfillment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update or create a DomainMatrixFulfillment for the current user
         * @param {UpdateDomainMatrixFulfillment} updateDomainMatrixFulfillment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAuthedUserDomainMatrixFulfillment(updateDomainMatrixFulfillment: UpdateDomainMatrixFulfillment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAuthedUserDomainMatrixFulfillment(updateDomainMatrixFulfillment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create/update skill matrix fulfillment of a current user
         * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
         * @param {UpdateSkillMatrixFulfillment} updateSkillMatrixFulfillment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAuthedUserSkillMatrixFulfillment(professionId: string, updateSkillMatrixFulfillment: UpdateSkillMatrixFulfillment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAuthedUserSkillMatrixFulfillment(professionId, updateSkillMatrixFulfillment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update or create DomainMatrixTemplate
         * @param {UpdateDomainMatrixTemplate} updateDomainMatrixTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDomainMatrixTemplate(updateDomainMatrixTemplate: UpdateDomainMatrixTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDomainMatrixTemplate(updateDomainMatrixTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update or create SkillMatrixTemplate tied to the supplied professionId with more or less groups
         * @param {string} professionId 
         * @param {UpdateSkillMatrixTemplate} updateSkillMatrixTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSkillMatrixTemplate(professionId: string, updateSkillMatrixTemplate: UpdateSkillMatrixTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSkillMatrixTemplate(professionId, updateSkillMatrixTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Fetch matrix fulfillment of a user with given id
         * @param {string} userId UserId to fetch the fulfillment of
         * @param {string} date Date to fetch the history fulfillment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHistorySkillMatrixFulfillments(userId: string, date: string, options?: any): AxiosPromise<Array<GetSkillMatrixFulfillment>> {
            return localVarFp.getAllHistorySkillMatrixFulfillments(userId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch matrix fulfillment of a user with given id
         * @param {string} userId UserId to fetch the history fulfillment of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSkillMatrixFulfillments(userId: string, options?: any): AxiosPromise<Array<GetSkillMatrixFulfillment>> {
            return localVarFp.getAllSkillMatrixFulfillments(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch DomainMatrixFulfillment of the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUserDomainMatrixFulfillment(options?: any): AxiosPromise<GetDomainMatrixFulfillment> {
            return localVarFp.getAuthedUserDomainMatrixFulfillment(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch matrix fulfillment of an authed user
         * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUserSkillMatrixFulfillment(professionId: string, options?: any): AxiosPromise<GetSkillMatrixFulfillment> {
            return localVarFp.getAuthedUserSkillMatrixFulfillment(professionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch DomainMatrixFulfillment of the specified user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainMatrixFulfillment(userId: string, options?: any): AxiosPromise<GetDomainMatrixFulfillment> {
            return localVarFp.getDomainMatrixFulfillment(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch current DomainMatrixTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainMatrixTemplate(options?: any): AxiosPromise<GetDomainMatrixTemplate> {
            return localVarFp.getDomainMatrixTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch matrix fulfillment of a user with given id
         * @param {string} userId UserId to fetch the fulfillment of
         * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillMatrixFulfillment(userId: string, professionId: string, options?: any): AxiosPromise<GetSkillMatrixFulfillment> {
            return localVarFp.getSkillMatrixFulfillment(userId, professionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a matrix template tied to the given ProfessionId
         * @param {string} professionId ProfessionId tied to the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillMatrixTemplate(professionId: string, options?: any): AxiosPromise<GetSkillMatrixTemplate> {
            return localVarFp.getSkillMatrixTemplate(professionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update only the specified ratings in newest fulfillment
         * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
         * @param {UpdateSkillMatrixFulfillment} updateSkillMatrixFulfillment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partiallyUpdateAuthedUserSkillMatrixFulfillment(professionId: string, updateSkillMatrixFulfillment: UpdateSkillMatrixFulfillment, options?: any): AxiosPromise<void> {
            return localVarFp.partiallyUpdateAuthedUserSkillMatrixFulfillment(professionId, updateSkillMatrixFulfillment, options).then((request) => request(axios, basePath));
        },
        /**
         * Update or create a DomainMatrixFulfillment for the current user
         * @param {UpdateDomainMatrixFulfillment} updateDomainMatrixFulfillment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthedUserDomainMatrixFulfillment(updateDomainMatrixFulfillment: UpdateDomainMatrixFulfillment, options?: any): AxiosPromise<void> {
            return localVarFp.updateAuthedUserDomainMatrixFulfillment(updateDomainMatrixFulfillment, options).then((request) => request(axios, basePath));
        },
        /**
         * Create/update skill matrix fulfillment of a current user
         * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
         * @param {UpdateSkillMatrixFulfillment} updateSkillMatrixFulfillment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthedUserSkillMatrixFulfillment(professionId: string, updateSkillMatrixFulfillment: UpdateSkillMatrixFulfillment, options?: any): AxiosPromise<void> {
            return localVarFp.updateAuthedUserSkillMatrixFulfillment(professionId, updateSkillMatrixFulfillment, options).then((request) => request(axios, basePath));
        },
        /**
         * Update or create DomainMatrixTemplate
         * @param {UpdateDomainMatrixTemplate} updateDomainMatrixTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDomainMatrixTemplate(updateDomainMatrixTemplate: UpdateDomainMatrixTemplate, options?: any): AxiosPromise<void> {
            return localVarFp.updateDomainMatrixTemplate(updateDomainMatrixTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update or create SkillMatrixTemplate tied to the supplied professionId with more or less groups
         * @param {string} professionId 
         * @param {UpdateSkillMatrixTemplate} updateSkillMatrixTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkillMatrixTemplate(professionId: string, updateSkillMatrixTemplate: UpdateSkillMatrixTemplate, options?: any): AxiosPromise<void> {
            return localVarFp.updateSkillMatrixTemplate(professionId, updateSkillMatrixTemplate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Fetch matrix fulfillment of a user with given id
     * @param {string} userId UserId to fetch the fulfillment of
     * @param {string} date Date to fetch the history fulfillment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllHistorySkillMatrixFulfillments(userId: string, date: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllHistorySkillMatrixFulfillments(userId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch matrix fulfillment of a user with given id
     * @param {string} userId UserId to fetch the history fulfillment of
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllSkillMatrixFulfillments(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllSkillMatrixFulfillments(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch DomainMatrixFulfillment of the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuthedUserDomainMatrixFulfillment(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuthedUserDomainMatrixFulfillment(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch matrix fulfillment of an authed user
     * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuthedUserSkillMatrixFulfillment(professionId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuthedUserSkillMatrixFulfillment(professionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch DomainMatrixFulfillment of the specified user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDomainMatrixFulfillment(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getDomainMatrixFulfillment(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch current DomainMatrixTemplate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDomainMatrixTemplate(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getDomainMatrixTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch matrix fulfillment of a user with given id
     * @param {string} userId UserId to fetch the fulfillment of
     * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSkillMatrixFulfillment(userId: string, professionId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSkillMatrixFulfillment(userId, professionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a matrix template tied to the given ProfessionId
     * @param {string} professionId ProfessionId tied to the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSkillMatrixTemplate(professionId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSkillMatrixTemplate(professionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update only the specified ratings in newest fulfillment
     * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
     * @param {UpdateSkillMatrixFulfillment} updateSkillMatrixFulfillment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partiallyUpdateAuthedUserSkillMatrixFulfillment(professionId: string, updateSkillMatrixFulfillment: UpdateSkillMatrixFulfillment, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partiallyUpdateAuthedUserSkillMatrixFulfillment(professionId, updateSkillMatrixFulfillment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update or create a DomainMatrixFulfillment for the current user
     * @param {UpdateDomainMatrixFulfillment} updateDomainMatrixFulfillment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAuthedUserDomainMatrixFulfillment(updateDomainMatrixFulfillment: UpdateDomainMatrixFulfillment, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateAuthedUserDomainMatrixFulfillment(updateDomainMatrixFulfillment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create/update skill matrix fulfillment of a current user
     * @param {string} professionId ProfessionId of the template that the fulfillment is tied to
     * @param {UpdateSkillMatrixFulfillment} updateSkillMatrixFulfillment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAuthedUserSkillMatrixFulfillment(professionId: string, updateSkillMatrixFulfillment: UpdateSkillMatrixFulfillment, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateAuthedUserSkillMatrixFulfillment(professionId, updateSkillMatrixFulfillment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update or create DomainMatrixTemplate
     * @param {UpdateDomainMatrixTemplate} updateDomainMatrixTemplate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateDomainMatrixTemplate(updateDomainMatrixTemplate: UpdateDomainMatrixTemplate, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateDomainMatrixTemplate(updateDomainMatrixTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update or create SkillMatrixTemplate tied to the supplied professionId with more or less groups
     * @param {string} professionId 
     * @param {UpdateSkillMatrixTemplate} updateSkillMatrixTemplate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSkillMatrixTemplate(professionId: string, updateSkillMatrixTemplate: UpdateSkillMatrixTemplate, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateSkillMatrixTemplate(professionId, updateSkillMatrixTemplate, options).then((request) => request(this.axios, this.basePath));
    }
}


