import { ReactElement } from "react";
import { Image, StyleSheet } from "@react-pdf/renderer";
import { SectionIconProps } from "../types";
import accountTieLogo from "../../../images/resume/account-tie-outline.png";
import accountTieBlindLogo from "../../../images/resume/account-tie-outline-blind.png";
import yearsLogo from "../../../images/resume/experience.png";
import yearsBlindLogo from "../../../images/resume/experience-blind.png";
import accountSettingsOutlineLogo from "../../../images/resume/account-settings-outline.png";
import accountSettingsOutlineBlindLogo from "../../../images/resume/account-settings-outline-blind.png";
import briefCaseLogo from "../../../images/resume/briefcase-outline.png";
import briefCaseBlindLogo from "../../../images/resume/briefcase-outline-blind.png";
import accountCircleLogo from "../../../images/resume/account_circle.png";
import accountCircleBlindLogo from "../../../images/resume/account_circle-blind.png";
import blurLogo from "../../../images/resume/blur.png";
import blurBlindLogo from "../../../images/resume/blur-blind.png";
import bookEducationLogo from "../../../images/resume/book-education-outline.png";
import bookEducationBlindLogo from "../../../images/resume/book-education-outline-blind.png";
import languageLogo from "../../../images/resume/language.png";
import languageBlindLogo from "../../../images/resume/language-blind.png";
import contributionLogo from "../../../images/resume/contributions.png";
import contributionBlindLogo from "../../../images/resume/contributions-blind.png";
import laptopLogo from "../../../images/resume/laptop.png";
import laptopBlindLogo from "../../../images/resume/laptop-blind.png";
import schoolLogo from "../../../images/resume/school-outline.png";
import schoolBlindLogo from "../../../images/resume/school-outline-blind.png";
import {
  OUTLINE,
  ROLE,
  EXPERIENCE_TIME,
  BRIEFCASE,
  LANGUAGES,
  PROFILE,
  SKILLS,
  WORK_EXPERIENCE,
  CONTRIBUTIONS,
  CERTIFICATES,
  EDUCATION,
} from "../../../shared/constants";

const styles = StyleSheet.create({
  icon: {
    width: "12px",
    height: "auto",
    marginRight: "7px",
  },
  sectionIcon: {
    width: "22px",
    height: "auto",
    marginRight: "11px",
  },
});

const getIconName = (
  iconPurpose: string,
  isBlind = false,
): ReactElement | null => {
  switch (iconPurpose) {
    case OUTLINE:
      return (
        <Image
          src={isBlind ? accountTieBlindLogo : accountTieLogo}
          style={styles.icon}
        />
      );
    case ROLE:
      return (
        <Image
          src={
            isBlind
              ? accountSettingsOutlineBlindLogo
              : accountSettingsOutlineLogo
          }
          style={styles.icon}
        />
      );
    case EXPERIENCE_TIME:
      return (
        <Image src={isBlind ? yearsBlindLogo : yearsLogo} style={styles.icon} />
      );
    case BRIEFCASE:
      return (
        <Image
          src={isBlind ? briefCaseBlindLogo : briefCaseLogo}
          style={styles.icon}
        />
      );
    case LANGUAGES:
      return (
        <Image
          src={isBlind ? languageBlindLogo : languageLogo}
          style={styles.icon}
        />
      );
    case PROFILE:
      return (
        <Image
          src={isBlind ? accountCircleBlindLogo : accountCircleLogo}
          style={styles.sectionIcon}
        />
      );
    case SKILLS:
      return (
        <Image
          src={isBlind ? blurBlindLogo : blurLogo}
          style={styles.sectionIcon}
        />
      );
    case WORK_EXPERIENCE:
      return (
        <Image
          src={isBlind ? laptopBlindLogo : laptopLogo}
          style={styles.sectionIcon}
        />
      );
    case CONTRIBUTIONS:
      return (
        <Image
          src={isBlind ? contributionBlindLogo : contributionLogo}
          style={styles.sectionIcon}
        />
      );
    case CERTIFICATES:
      return (
        <Image
          src={isBlind ? bookEducationBlindLogo : bookEducationLogo}
          style={styles.sectionIcon}
        />
      );
    case EDUCATION:
      return (
        <Image
          src={isBlind ? schoolBlindLogo : schoolLogo}
          style={styles.sectionIcon}
        />
      );
    default:
      return null;
  }
};

const SectionIcon = ({ icon, isBlind }: SectionIconProps): ReactElement => (
  <>{getIconName(icon, isBlind)}</>
);

export default SectionIcon;
