import { APICvFile, APIUploadCVList, UploadingCVModel } from "../types";

const convertPDFFileToBlob = async (file: File): Blob => {
  const arrayBuffer = await file.arrayBuffer();
  const pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });
  return pdfBlob;
};

async function encodeBlobToSHA256(blob: Blob): Promise<string> {
  const buffer = await blob?.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  const hashArray = new Uint8Array(hashBuffer);
  const hashHex = Array.from(hashArray)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

const singleCVToAPIModel = async (
  item: UploadingCVModel,
): Promise<APICvFile> => {
  const blob = await convertPDFFileToBlob(item.file);
  const sha256 = await encodeBlobToSHA256(blob);

  return {
    sha256,
    size: item?.file.size,
    fileId: item.fileId,
  };
};

const CVListToAPIModel = async (
  list: UploadingCVModel[],
): Promise<APIUploadCVList> => {
  const result = await Promise.allSettled(
    list.map((cv: UploadingCVModel) => singleCVToAPIModel(cv)),
  );
  const toSend = result.map((item) => item.value);
  return {
    cvs: toSend,
  };
};

export { CVListToAPIModel };
