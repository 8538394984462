import { removeDiacriticsFromString } from "@ps/utils";
import {
  FilterItem,
  IFilters,
  IMappedProspectListElement,
} from "../ui/common/types";
import { BD, CLIENT_NAME, PM } from "../constants";

export const getCheckedNames = (filters: IFilters, name: string): string[] =>
  filters?.[name]?.map((item: FilterItem) => item.name);

export const selectFilterOptions = (
  data: FilterItem[],
  searchValue: string,
): FilterItem[] =>
  data.filter((item) =>
    removeDiacriticsFromString(item.name?.toLowerCase()).includes(
      removeDiacriticsFromString(searchValue.toLowerCase()),
    ),
  );

export const compareNormalizedStrings = (
  value: string,
  searchValue: string,
): boolean =>
  removeDiacriticsFromString(value.toLowerCase()).includes(
    removeDiacriticsFromString(searchValue.toLowerCase()),
  );

export const removeFilterDuplicates = (items: FilterItem[]): FilterItem[] =>
  items.reduce(
    (acc: FilterItem[], item: FilterItem) =>
      acc.some((item2: FilterItem) => item2.id === item.id)
        ? acc
        : [...acc, item],
    [],
  );

export const prepareUserFilterOptions = (
  prospects: IMappedProspectListElement[],
  filterName: string,
): FilterItem[] => {
  const temp = prospects
    ?.filter(
      ({
        details,
      }: {
        details: IMappedProspectListElement["details"];
      }): IMappedProspectListElement["details"] =>
        details?.[filterName]?.firstName,
    )
    .map(
      (
        { details }: { details: IMappedProspectListElement["details"] },
        index: number,
      ): FilterItem => ({
        id: details?.[filterName]?.userId || index.toString(),
        name:
          `${details?.[filterName]?.firstName} ${details?.[filterName]?.lastName}` ||
          "",
      }),
    );
  return removeFilterDuplicates(temp);
};

export const prepareClientFilterOptions = (
  prospects: IMappedProspectListElement[],
): FilterItem[] => {
  const temp = prospects
    ?.filter(
      ({ details }: { details: IMappedProspectListElement["details"] }) =>
        details?.[CLIENT_NAME],
    )
    .map(
      (
        { details }: { details: IMappedProspectListElement["details"] },
        index: number,
      ) => ({
        id: details?.clientName || index.toString(),
        name: details?.[CLIENT_NAME] || "",
      }),
    );

  return removeFilterDuplicates(temp);
};

export const filterProspectsByFields = (
  prospects: IMappedProspectListElement[],
  searchValue: string,
  filters?: IFilters,
): IMappedProspectListElement[] => {
  const preparedToFilter: IMappedProspectListElement[] = !searchValue?.length
    ? prospects
    : prospects.filter((item: IMappedProspectListElement) =>
        compareNormalizedStrings(item?.details?.prospectName, searchValue),
      );

  if (!filters) return preparedToFilter;

  let result: IMappedProspectListElement[] = preparedToFilter;

  if (filters[CLIENT_NAME]?.length && result.length) {
    result = result.filter((singleProject: IMappedProspectListElement) =>
      filters[CLIENT_NAME].some(
        ({ id }: FilterItem) => id === singleProject.details.clientName,
      ),
    );
  }

  if (filters[BD]?.length && result.length) {
    result = result.filter((singleProspect: IMappedProspectListElement) =>
      filters[BD].some(
        ({ id }: FilterItem) => id === singleProspect.details?.[BD]?.userId,
      ),
    );
  }

  if (filters[PM]?.length && result.length) {
    result = result.filter((singleProject: IMappedProspectListElement) =>
      filters[PM].some(
        ({ id }: FilterItem) => id === singleProject.details?.[PM]?.userId,
      ),
    );
  }

  return result;
};
