import { sortArray } from "@ps/utils";
import { HolidaysUsersModel, HolidaysUserModel } from "../models";
import {
  TEMPLATE_NAME,
  USED_MAIN_HOLIDAYS,
  HOLIDAYS,
} from "../ui/holidays/constants";

const sortHolidaysUsers = (
  arr: HolidaysUsersModel,
  field: string,
  order = "asc",
): HolidaysUsersModel => {
  if (field === TEMPLATE_NAME) {
    const onlyWithTemplate = arr.filter(
      (item: HolidaysUserModel) => item.holidaysTemplate?.templateId,
    );
    const onlyWithoutTemplate = arr.filter(
      (item: HolidaysUserModel) => !item.holidaysTemplate?.templateId,
    );

    return [
      ...sortArray(
        onlyWithoutTemplate,
        TEMPLATE_NAME,
        order,
        "holidaysTemplate",
      ),
      ...sortArray(onlyWithTemplate, TEMPLATE_NAME, order, "holidaysTemplate"),
    ];
  }
  if (field === USED_MAIN_HOLIDAYS) {
    const onlyWithUsedHolidays = arr.filter(
      (item: HolidaysUserModel) => item.holidays.used,
    );
    const onlyWithoutUsedHolidays = arr.filter(
      (item: HolidaysUserModel) => !item.holidays.used,
    );
    const onlyUsedSorted = sortArray(
      onlyWithUsedHolidays,
      "used",
      order,
      HOLIDAYS,
    );
    const only0Sorted = sortArray(
      onlyWithoutUsedHolidays,
      "used",
      order,
      HOLIDAYS,
    );
    return order === "asc"
      ? [...onlyUsedSorted, ...only0Sorted]
      : [...only0Sorted, ...onlyUsedSorted];
  }
  return sortArray(arr, field, order);
};

export default sortHolidaysUsers;
