import { ReactElement, ChangeEvent } from "react";
import { useDispatch } from "redux-react-hook";
import {
  TableSearchBar,
  Input,
  LinkButton,
  Badge,
  Toggle,
} from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { resetHolidaysUsersByPropertyFilters } from "../../store";
import {
  BASE_TRANSLATION_PATH,
  HOLIDAYS,
  SEARCH,
  TEMPLATE_NAME,
} from "./constants";
import {
  SearchRowProps,
  SearchRowMapState,
  SearchRowMapStateReturn,
} from "./types";

const SearchRow = ({
  onSearchValueChange,
  usersListLength,
  setIsCheckedArchive,
  isCheckedArchive,
}: SearchRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();

  const mapState = (state: SearchRowMapState): SearchRowMapStateReturn => ({
    filters: state.holidays.filters,
  });

  const { filters } = useMappedStateSelector(mapState);

  return (
    <TableSearchBar
      dataCy={`${HOLIDAYS}_${SEARCH}`}
      additionalClassName="flex items-center justify-between mb-2"
    >
      <Input
        dataCy={`${HOLIDAYS}_${SEARCH}`}
        placeholder={t(`${BASE_TRANSLATION_PATH}.search`)}
        onChange={(event: ChangeEvent<HTMLInputElement>): void =>
          onSearchValueChange(event.target.value)
        }
        isSearch
        width="w-80"
      />
      <div className="flex items-center gap-5">
        <div className="flex items-center gap-3">
          <Badge
            dataCy={`${HOLIDAYS}_active_users`}
            number={usersListLength?.active}
            title={t(`${BASE_TRANSLATION_PATH}.activeUsers`)}
            isActive={!isCheckedArchive}
            onClick={() => setIsCheckedArchive(false)}
          />
          <Toggle
            dataCy={HOLIDAYS}
            checked={isCheckedArchive}
            onChange={() =>
              setIsCheckedArchive((wasChecked: boolean): boolean => !wasChecked)
            }
          />
          <Badge
            dataCy={`${HOLIDAYS}_archived_users`}
            number={usersListLength?.archived}
            title={t(`${BASE_TRANSLATION_PATH}.archivedUsers`)}
            isActive={isCheckedArchive}
            onClick={() => setIsCheckedArchive(true)}
          />
        </div>
        {!filters[TEMPLATE_NAME].length ? (
          <></>
        ) : (
          <LinkButton
            dataCy={`${HOLIDAYS}_clearFilters`}
            onClick={() => resetHolidaysUsersByPropertyFilters(dispatch)}
          >
            {t(`${BASE_TRANSLATION_PATH}.clearFilters`)}
          </LinkButton>
        )}
      </div>
    </TableSearchBar>
  );
};

export default SearchRow;
