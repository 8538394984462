import { CSSProperties, ReactElement, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment";
import {
  classJoin,
  mapTechnologyWithOnlyAlphanumeric,
  prepareDictionarySearchSelect,
  prepareTechStackDictionaryValues,
  useFullTextSearch,
  validations,
} from "@ps/utils";
import {
  Button,
  Label,
  Modal2,
  ModalContent,
  ModalFooter2,
  ModalHeader,
  SearchSelect,
  SearchSelectPicker,
  TimeTrackerInput,
} from "@ps/ui-components";
import ISOLanguages from "iso-639-1";
import { ITechStackProposal, NeedsModalProps } from "./types";
import {
  CORE_TIME,
  CURRENCY,
  END,
  ENGAGEMENT,
  EXPECTED_LANGUAGE,
  EXPECTED_RATE,
  MUST_HAVE_TECH_STACK,
  NEEDS,
  NICE_TO_HAVE_TECH_STACK,
  OVERLAP,
  PROFESSION,
  PROSPECT,
  PROSPECT_REQUIREMENT,
  QUANTITY,
  RATE,
  RATE_UNIT,
  SENIORITY,
  START,
  WORKING_TIME,
  ZONE,
  currencyDict,
  engagementDict,
  rateUnitsDict,
} from "../../../../constants";
import { useTranslationWithNamespace } from "../../../../../hooks";
import {
  availableLanguages,
  PROFESSIONS,
} from "../../../../../profile-domain/ui/overview/constants";
import { ControlledInput } from "../../../../../project-domain/ui/common";
import RegionsHiddenLayer from "./regionsHiddenLayer";
import { ReactComponent as HeaderIcon } from "../../../../../images/prospect/add.svg";
import styles from "./styles.module.css";

const BASE_PATH = `${PROSPECT}_edit`;
const TRANSLATE_PATH = "projects.prospect.needs";

const NeedsModal = ({
  isOpen,
  onClose,
  dataCy,
  seniorityDict,
  technologyDict,
  professionDict,
  index,
  onSubmit,
  onCancel,
  bannedCountries,
}: NeedsModalProps): ReactElement => {
  const getName = (name: string) => `${NEEDS}.${index}.${name}`;
  const [searchedProposals, setSearchedProposals] = useState<
    ITechStackProposal[] | []
  >([]);

  const { control, getValues } = useFormContext();
  const { t } = useTranslationWithNamespace();
  const translate = (field: string, isRequired = true) =>
    `${t(`${TRANSLATE_PATH}.${field}`)}${isRequired ? "*" : ""}`;

  const timezones = moment.tz.names();

  const validateWithOtherFields = (
    fields: string[],
    value,
    basePath,
    errorMsg = "cantBeEmpty",
  ) => {
    const valueNames = fields.map((item) => `${getName(basePath)}.${item}`);
    const hasEmptyFields = getValues(valueNames).some((field) => !!field);
    return !value && hasEmptyFields
      ? `${t(`projects.errors.${errorMsg}`)}`
      : true;
  };

  const { search } = useFullTextSearch(
    technologyDict,
    mapTechnologyWithOnlyAlphanumeric,
  );

  const [bannedRegions, setBannedRegions] = useState<string[]>([]);

  useEffect(() => {
    if (bannedCountries && bannedCountries.length) {
      setBannedRegions(bannedCountries);
    }
  }, [bannedCountries]);

  const getValueData = (value, dict) => {
    const currentId = value?.id || value;
    return value && currentId
      ? {
          id: currentId,
          value: currentId,
          label: dict.find((item) => item.id === currentId)?.name,
        }
      : value;
  };

  const quantityDict = [...Array(21)].fill(0).map((_, id) => {
    const quantity = (id + 1).toString();
    return {
      id: quantity,
      value: quantity,
      label: quantity,
    };
  });

  const overlapDict = [...Array(23)].fill(0).map((_, id) => ({
    id: id.toString(),
    value: id.toString(),
    label: id.toString(),
  }));

  const getLanguageWithCodes = () => {
    const allLanguageCodes = ISOLanguages.getAllCodes();
    const allLanguageNames = ISOLanguages.getAllNames();
    const allLanguages = allLanguageCodes.map((code: string, idx: number) => ({
      id: code,
      label: allLanguageNames[idx],
      value: code,
    }));
    const filteredLanguagesList = allLanguages.filter((language) =>
      availableLanguages.includes(language.value),
    );
    return filteredLanguagesList;
  };

  const languagesList = getLanguageWithCodes() || [];

  const [expanded, setExpanded] = useState(false);

  const expandedStyle: CSSProperties = {
    transform: !expanded ? "translateX(30px)" : "translateX(40%)",
    transition: "transform 0.3s ease-in-out",
  };

  const expandedClassName = !expanded
    ? "transition duration-300 ease-in-out transform translate-x-0"
    : "transition duration-300 ease-in-out transform -translate-x-1/2";

  return (
    <Modal2
      dataCy={dataCy}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setBannedRegions([]);
        setExpanded(false);
      }}
      width="w-1/3"
      withCloseIcon
      additionalOuterClassName={expandedClassName}
      hiddenLayerStyles="w-1/3 z-20 rounded-2.5xl bg-neutralSecondary-90"
      hiddenLayerInlineStyle={expandedStyle}
      hiddenLayer={
        <RegionsHiddenLayer
          basePath={BASE_PATH}
          onExpandClick={() => setExpanded((e) => !e)}
          index={index}
          defaultBannedRegions={bannedCountries}
          setBannedRegions={setBannedRegions}
          bannedRegions={bannedRegions}
        />
      }
    >
      <ModalHeader
        title={t(`${TRANSLATE_PATH}.${PROSPECT_REQUIREMENT}`)}
        fontSize="text-lg"
        additionalClassName="font-bold mb-6"
        Icon={<HeaderIcon className="h-8 w-8" />}
      />
      <ModalContent additionalClassName="relative">
        <div className="flex flex-col gap-y-4" id="prospect-need-container">
          <div className="flex justify-between gap-x-6">
            <Label
              dataCy={`${BASE_PATH}_${PROFESSION}`}
              text={translate(PROFESSION)}
            >
              <Controller
                name={`${getName(PROFESSION)}`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelect
                    height="2.188rem"
                    width="w-full"
                    dataCy={`${BASE_PATH}_${PROFESSION}`}
                    options={prepareDictionarySearchSelect(
                      professionDict,
                      "id",
                      "name",
                      "name",
                      "name",
                    )}
                    autoFocus
                    defaultValue={getValueData(value, professionDict)}
                    placeholder={translate(PROFESSION)}
                    searchable={false}
                    onChange={onChange}
                    error={!!error}
                    message={error?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("projects.errors.cantBeEmpty"),
                  },
                }}
              />
            </Label>
            <Label
              dataCy={`${BASE_PATH}_${SENIORITY}`}
              text={translate(SENIORITY)}
            >
              <Controller
                name={`${getName(SENIORITY)}`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelect
                    height="2.188rem"
                    width="w-full"
                    dataCy={`${BASE_PATH}_${SENIORITY}`}
                    options={prepareDictionarySearchSelect(
                      seniorityDict,
                      "id",
                      "name",
                      "name",
                      "name",
                    )}
                    defaultValue={getValueData(value, seniorityDict)}
                    placeholder={translate(SENIORITY)}
                    searchable={false}
                    onChange={onChange}
                    error={!!error}
                    message={error?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("projects.errors.cantBeEmpty"),
                  },
                }}
              />
            </Label>
          </div>

          <div className="flex justify-between gap-x-6">
            <Label
              dataCy={`${BASE_PATH}_${QUANTITY}`}
              text={translate(QUANTITY)}
            >
              <Controller
                name={`${getName(QUANTITY)}`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelect
                    height="2.188rem"
                    width="w-full"
                    dataCy={`${BASE_PATH}_${QUANTITY}`}
                    options={quantityDict}
                    defaultValue={quantityDict.find(
                      (item) => item.id === value?.toString(),
                    )}
                    placeholder={translate(QUANTITY)}
                    searchable={false}
                    onChange={onChange}
                    error={!!error}
                    message={error?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("projects.errors.cantBeEmpty"),
                  },
                }}
              />
            </Label>

            <Label
              dataCy={`${BASE_PATH}_${ENGAGEMENT}`}
              text={translate(ENGAGEMENT, false)}
            >
              <Controller
                name={`${getName(EXPECTED_RATE)}.${ENGAGEMENT}`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelect
                    height="2.188rem"
                    width="w-full"
                    dataCy={`${BASE_PATH}_${ENGAGEMENT}`}
                    options={prepareDictionarySearchSelect(
                      engagementDict,
                      "id",
                      "name",
                      "name",
                      "name",
                    )}
                    clearable
                    defaultValue={getValueData(value, engagementDict)}
                    placeholder={translate(ENGAGEMENT, false)}
                    searchable={false}
                    onChange={onChange}
                    error={!!error}
                    message={error?.message}
                  />
                )}
                rules={{
                  validate: (value) =>
                    validateWithOtherFields(
                      [RATE, RATE_UNIT, CURRENCY],
                      value,
                      EXPECTED_RATE,
                    ),
                }}
              />
            </Label>
          </div>

          <div className="flex justify-between gap-x-6">
            <Label
              dataCy={`${BASE_PATH}_${RATE}`}
              text={translate(RATE, false)}
            >
              <div className="-mt-1">
                <ControlledInput
                  basePath={`${BASE_PATH}_${EXPECTED_RATE}_${RATE}`}
                  controllerPath={`${getName(EXPECTED_RATE)}.${RATE}`}
                  name={RATE}
                  placeholder={RATE}
                  translatePath={TRANSLATE_PATH}
                  defaultValue={getValues(
                    `${NEEDS}.${index}.expectedRate.rate`,
                  )}
                  additionalValidation={(value) => {
                    if (value <= 0)
                      return translate("rateMustBeHigherThan0", false);
                    return validateWithOtherFields(
                      [RATE_UNIT, CURRENCY, ENGAGEMENT],
                      value,
                      EXPECTED_RATE,
                    );
                  }}
                  type="number"
                  min={0.0}
                  noLabel
                />
              </div>
            </Label>
            <Label
              dataCy={`${BASE_PATH}_${RATE_UNIT}`}
              text={translate(RATE_UNIT, false)}
            >
              <Controller
                name={`${getName(EXPECTED_RATE)}.${RATE_UNIT}`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelect
                    height="2.188rem"
                    width="w-full"
                    dataCy={`${BASE_PATH}_${RATE_UNIT}`}
                    options={prepareDictionarySearchSelect(
                      rateUnitsDict,
                      "id",
                      "name",
                      "name",
                      "name",
                    )}
                    clearable
                    defaultValue={getValueData(value, rateUnitsDict)}
                    placeholder={translate(RATE_UNIT, false)}
                    searchable={false}
                    onChange={onChange}
                    error={!!error}
                    message={error?.message}
                  />
                )}
                rules={{
                  validate: (value) =>
                    validateWithOtherFields(
                      [RATE, CURRENCY, ENGAGEMENT],
                      value,
                      EXPECTED_RATE,
                    ),
                }}
              />
            </Label>
            <Label
              dataCy={`${BASE_PATH}_${CURRENCY}`}
              text={translate(CURRENCY, false)}
            >
              <Controller
                name={`${getName(EXPECTED_RATE)}.${CURRENCY}`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SearchSelect
                    height="2.188rem"
                    width="w-full"
                    dataCy={`${BASE_PATH}_${CURRENCY}`}
                    options={prepareDictionarySearchSelect(
                      currencyDict,
                      "id",
                      "name",
                      "name",
                      "name",
                    )}
                    clearable
                    defaultValue={getValueData(value, currencyDict)}
                    placeholder={translate(CURRENCY, false)}
                    searchable={false}
                    onChange={onChange}
                    error={!!error}
                    message={error?.message}
                  />
                )}
                rules={{
                  validate: (value) =>
                    validateWithOtherFields(
                      [RATE, RATE_UNIT, ENGAGEMENT],
                      value,
                      EXPECTED_RATE,
                    ),
                }}
              />
            </Label>
          </div>

          <div className="flex justify-between gap-x-6">
            <div className="w-9/12">
              <Label
                dataCy={`${BASE_PATH}-${CORE_TIME}_${ZONE}`}
                text={translate(ZONE, true)}
              >
                <Controller
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <SearchSelect
                      width="w-full"
                      dataCy={`${BASE_PATH}-${CORE_TIME}_${ZONE}`}
                      onChange={onChange}
                      value={value}
                      message={error?.message || ""}
                      error={!!error}
                      searchable
                      clearable
                      isMenuOpenTop
                      placeholder={translate(ZONE, true)}
                      options={timezones.map((singleZone) => ({
                        label: `${moment
                          .tz(singleZone)
                          .format("Z z")} (${singleZone})`,
                        value: singleZone,
                      }))}
                    />
                  )}
                  name={getName(`${WORKING_TIME}.${ZONE}`)}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t("projects.errors.cantBeEmpty"),
                    },
                  }}
                />
              </Label>
            </div>
            <div className="w-3/12">
              <Label
                dataCy={`${BASE_PATH}_${OVERLAP}`}
                text={translate(OVERLAP, false)}
              >
                <Controller
                  name={getName(`${WORKING_TIME}.${OVERLAP}`)}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <SearchSelect
                      width="w-full"
                      height="2.188rem"
                      dataCy={`${BASE_PATH}_${OVERLAP}`}
                      options={overlapDict}
                      defaultValue={overlapDict.find(
                        (item) => item.id === value?.toString(),
                      )}
                      clearable
                      placeholder={translate("sameOverlap", false)}
                      isMenuOpenTop
                      searchable={false}
                      onChange={onChange}
                      error={!!error}
                      message={error?.message}
                    />
                  )}
                />
              </Label>
            </div>
          </div>

          <div className="flex justify-between gap-x-6">
            <div className="w-1/2">
              <Label
                dataCy={`${BASE_PATH}-${WORKING_TIME}`}
                text={translate(WORKING_TIME, true)}
              >
                <div className="flex gap-4">
                  <Controller
                    control={control}
                    name={`${NEEDS}.${index}.${WORKING_TIME}.${CORE_TIME}.${START}`}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <TimeTrackerInput
                        additionalContainerClass="w-max"
                        placeholder="00:00"
                        dataCy={`${NEEDS}.${index}.${WORKING_TIME}.${CORE_TIME}.${START}`}
                        error={!!error}
                        message={error?.message || ""}
                        onBlur={onChange}
                        value={value}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: t("projects.errors.cantBeEmpty"),
                      },
                      validate: (value) => {
                        return validations.validateWorkingHours(
                          value,
                          t("projects.errors.cantBeEmpty"),
                          t("projects.errors.wrongHoursFormat"),
                        );
                      },
                    }}
                  />
                  <span className="mt-1">-</span>
                  <Controller
                    control={control}
                    name={`${NEEDS}.${index}.${WORKING_TIME}.${CORE_TIME}.${END}`}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <TimeTrackerInput
                        additionalContainerClass="w-max"
                        placeholder="00:00"
                        dataCy={`${NEEDS}.${index}.${WORKING_TIME}.${CORE_TIME}.${END}`}
                        error={!!error}
                        message={error?.message || ""}
                        onBlur={onChange}
                        value={value}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: t("projects.errors.cantBeEmpty"),
                      },
                      validate: (value) => {
                        return validations.validateWorkingHours(
                          value,
                          t("projects.errors.cantBeEmpty"),
                          t("projects.errors.wrongHoursFormat"),
                        );
                      },
                    }}
                  />
                </div>
              </Label>
            </div>
            <div className="flex w-1/2">
              <Label
                dataCy={`${BASE_PATH}_${EXPECTED_LANGUAGE}`}
                text={translate(EXPECTED_LANGUAGE)}
              >
                <Controller
                  name={`${getName(EXPECTED_LANGUAGE)}`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <SearchSelect
                      height="2.188rem"
                      width="w-full"
                      dataCy={`${BASE_PATH}_${EXPECTED_LANGUAGE}`}
                      options={languagesList}
                      defaultValue={
                        value
                          ? {
                              id: value,
                              value,
                              label: languagesList.find(
                                (item) => item.id === value,
                              )?.label,
                            }
                          : value
                      }
                      placeholder={translate(EXPECTED_LANGUAGE)}
                      onChange={onChange}
                      error={!!error}
                      message={error?.message}
                      isMenuOpenTop
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: t("projects.errors.cantBeEmpty"),
                    },
                  }}
                />
              </Label>
            </div>
          </div>
          <Label
            dataCy={`${BASE_PATH}_${MUST_HAVE_TECH_STACK}`}
            text={translate(MUST_HAVE_TECH_STACK)}
          >
            <Controller
              name={`${getName(MUST_HAVE_TECH_STACK)}`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SearchSelectPicker
                  shouldScrollToTop
                  dataCy={`${BASE_PATH}_${MUST_HAVE_TECH_STACK}`}
                  defaultSelected={prepareTechStackDictionaryValues(
                    value,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    technologyDict,
                  )}
                  onTagSelected={onChange}
                  proposalTags={searchedProposals}
                  onInputChange={(searchValue) => {
                    const result = search(searchValue);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setSearchedProposals(result);
                  }}
                  error={!!error}
                  message={error?.message}
                  additionalTagsWrapClassName={classJoin(styles.tagsWrapper)}
                  maxProposalTagsNumber={8}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t("projects.errors.cantBeEmpty"),
                },
                validate: (value) =>
                  validateWithOtherFields(
                    [PROFESSIONS, SENIORITY, QUANTITY, NICE_TO_HAVE_TECH_STACK],
                    value,
                    MUST_HAVE_TECH_STACK,
                  ),
              }}
            />
          </Label>
          <Label
            dataCy={`${BASE_PATH}_${NICE_TO_HAVE_TECH_STACK}`}
            text={translate(NICE_TO_HAVE_TECH_STACK, false)}
          >
            <Controller
              name={`${getName(NICE_TO_HAVE_TECH_STACK)}`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SearchSelectPicker
                  shouldScrollToTop
                  dataCy={`${BASE_PATH}_${NICE_TO_HAVE_TECH_STACK}`}
                  defaultSelected={prepareTechStackDictionaryValues(
                    value,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    technologyDict,
                  )}
                  onTagSelected={onChange}
                  proposalTags={searchedProposals}
                  onInputChange={(searchValue) => {
                    const result = search(searchValue);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setSearchedProposals(result);
                    // setTimeout(() => scrollTop(), 100);
                  }}
                  error={!!error}
                  message={error?.message}
                  additionalTagsWrapClassName={classJoin(styles.tagsWrapper)}
                  maxProposalTagsNumber={8}
                />
              )}
              rules={{
                validate: (value) =>
                  validateWithOtherFields(
                    [PROFESSIONS, SENIORITY, QUANTITY, MUST_HAVE_TECH_STACK],
                    value,
                    NICE_TO_HAVE_TECH_STACK,
                  ),
              }}
            />
          </Label>
        </div>
      </ModalContent>
      <ModalFooter2 dataCy="">
        <div className="flex gap-x-2 pt-10 w-full justify-end">
          <Button
            dataCy="cancel"
            variant="secondary"
            onClick={() => {
              setBannedRegions(bannedCountries);
              setExpanded(false);
              onCancel();
            }}
          >
            {t("projects.prospect.actions.cancel")}
          </Button>
          <Button dataCy="save" type="submit" onClick={onSubmit}>
            {t("projects.prospect.actions.save")}
          </Button>
        </div>
      </ModalFooter2>
    </Modal2>
  );
};

export default NeedsModal;
