/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Finances Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface FinanceUser
 */
export interface FinanceUser {
    /**
     * 
     * @type {string}
     * @memberof FinanceUser
     */
    'id': string;
    /**
     * 
     * @type {FinanceUserSummary}
     * @memberof FinanceUser
     */
    'summary': FinanceUserSummary;
    /**
     * 
     * @type {UserHolidaysTemplate}
     * @memberof FinanceUser
     */
    'holidaysTemplate'?: UserHolidaysTemplate;
    /**
     * 
     * @type {Salary}
     * @memberof FinanceUser
     */
    'salary'?: Salary;
}
/**
 * 
 * @export
 * @interface FinanceUserSummary
 */
export interface FinanceUserSummary {
    /**
     * 
     * @type {string}
     * @memberof FinanceUserSummary
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceUserSummary
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceUserSummary
     */
    'profession'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinanceUserSummary
     */
    'seniority'?: string;
    /**
     * 
     * @type {HolidaysTemplateSummary}
     * @memberof FinanceUserSummary
     */
    'holidaysTemplate'?: HolidaysTemplateSummary;
    /**
     * 
     * @type {Holidays}
     * @memberof FinanceUserSummary
     */
    'holidays'?: Holidays;
    /**
     * 
     * @type {boolean}
     * @memberof FinanceUserSummary
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof FinanceUserSummary
     */
    'avatar'?: string;
}
/**
 * 
 * @export
 * @interface FinanceUserSummaryWithId
 */
export interface FinanceUserSummaryWithId {
    /**
     * 
     * @type {string}
     * @memberof FinanceUserSummaryWithId
     */
    'id': string;
    /**
     * 
     * @type {FinanceUserSummary}
     * @memberof FinanceUserSummaryWithId
     */
    'summary': FinanceUserSummary;
    /**
     * 
     * @type {Salary}
     * @memberof FinanceUserSummaryWithId
     */
    'salary'?: Salary;
}
/**
 * 
 * @export
 * @interface Holidays
 */
export interface Holidays {
    /**
     * 
     * @type {number}
     * @memberof Holidays
     */
    'taken': number;
    /**
     * 
     * @type {number}
     * @memberof Holidays
     */
    'full': number;
}
/**
 * 
 * @export
 * @interface HolidaysItemFulfillment
 */
export interface HolidaysItemFulfillment {
    /**
     * 
     * @type {string}
     * @memberof HolidaysItemFulfillment
     */
    'holidaysItemId': string;
    /**
     * 
     * @type {string}
     * @memberof HolidaysItemFulfillment
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof HolidaysItemFulfillment
     */
    'allowPartialDayOff': boolean;
    /**
     * 
     * @type {number}
     * @memberof HolidaysItemFulfillment
     */
    'numberOfDaysOff': number;
    /**
     * 
     * @type {number}
     * @memberof HolidaysItemFulfillment
     */
    'numberOfDaysOffTaken': number;
    /**
     * 
     * @type {number}
     * @memberof HolidaysItemFulfillment
     */
    'modification': number;
    /**
     * Pay rate during absence
     * @type {number}
     * @memberof HolidaysItemFulfillment
     */
    'payRatePercentage': number;
}
/**
 * 
 * @export
 * @interface HolidaysTemplateSummary
 */
export interface HolidaysTemplateSummary {
    /**
     * 
     * @type {string}
     * @memberof HolidaysTemplateSummary
     */
    'templateId': string;
    /**
     * 
     * @type {string}
     * @memberof HolidaysTemplateSummary
     */
    'templateName': string;
}
/**
 * 
 * @export
 * @interface ItemIdWithModification
 */
export interface ItemIdWithModification {
    /**
     * 
     * @type {string}
     * @memberof ItemIdWithModification
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ItemIdWithModification
     */
    'modification': number;
}
/**
 * 
 * @export
 * @interface Salary
 */
export interface Salary {
    /**
     * 
     * @type {number}
     * @memberof Salary
     */
    'monthly': number;
    /**
     * 
     * @type {number}
     * @memberof Salary
     */
    'perHour': number;
    /**
     * 
     * @type {string}
     * @memberof Salary
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof Salary
     */
    'lastUpdated': string;
}
/**
 * 
 * @export
 * @interface UpdateSalary
 */
export interface UpdateSalary {
    /**
     * 
     * @type {number}
     * @memberof UpdateSalary
     */
    'monthly': number;
}
/**
 * 
 * @export
 * @interface UpdateTemplateId
 */
export interface UpdateTemplateId {
    /**
     * 
     * @type {string}
     * @memberof UpdateTemplateId
     */
    'templateId': string;
}
/**
 * 
 * @export
 * @interface UserHolidaysTemplate
 */
export interface UserHolidaysTemplate {
    /**
     * 
     * @type {Array<HolidaysItemFulfillment>}
     * @memberof UserHolidaysTemplate
     */
    'holidaysItems': Array<HolidaysItemFulfillment>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Changes user template
         * @param {string} userId 
         * @param {UpdateTemplateId} updateTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserTemplate: async (userId: string, updateTemplateId: UpdateTemplateId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('changeUserTemplate', 'userId', userId)
            // verify required parameter 'updateTemplateId' is not null or undefined
            assertParamExists('changeUserTemplate', 'updateTemplateId', updateTemplateId)
            const localVarPath = `/finances/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTemplateId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of all users with limited informations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/finances/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch User with additional data
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetailed: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserDetailed', 'userId', userId)
            const localVarPath = `/finances/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all user\'s holidays items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserHolidaysTemplate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/finances/users/current/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates user salary
         * @param {string} userId 
         * @param {UpdateSalary} updateSalary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserSalary: async (userId: string, updateSalary: UpdateSalary, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserSalary', 'userId', userId)
            // verify required parameter 'updateSalary' is not null or undefined
            assertParamExists('updateUserSalary', 'updateSalary', updateSalary)
            const localVarPath = `/finances/users/{userId}/salary`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSalary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates custom template for user
         * @param {string} userId 
         * @param {Array<ItemIdWithModification>} itemIdWithModification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTemplate: async (userId: string, itemIdWithModification: Array<ItemIdWithModification>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserTemplate', 'userId', userId)
            // verify required parameter 'itemIdWithModification' is not null or undefined
            assertParamExists('updateUserTemplate', 'itemIdWithModification', itemIdWithModification)
            const localVarPath = `/finances/users/{userId}/template`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemIdWithModification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Changes user template
         * @param {string} userId 
         * @param {UpdateTemplateId} updateTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserTemplate(userId: string, updateTemplateId: UpdateTemplateId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserTemplate(userId, updateTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a list of all users with limited informations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FinanceUserSummaryWithId>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch User with additional data
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDetailed(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinanceUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDetailed(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all user\'s holidays items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserHolidaysTemplate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserHolidaysTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserHolidaysTemplate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates user salary
         * @param {string} userId 
         * @param {UpdateSalary} updateSalary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserSalary(userId: string, updateSalary: UpdateSalary, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserSalary(userId, updateSalary, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates custom template for user
         * @param {string} userId 
         * @param {Array<ItemIdWithModification>} itemIdWithModification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserTemplate(userId: string, itemIdWithModification: Array<ItemIdWithModification>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserTemplate(userId, itemIdWithModification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Changes user template
         * @param {string} userId 
         * @param {UpdateTemplateId} updateTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserTemplate(userId: string, updateTemplateId: UpdateTemplateId, options?: any): AxiosPromise<void> {
            return localVarFp.changeUserTemplate(userId, updateTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of all users with limited informations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(options?: any): AxiosPromise<Array<FinanceUserSummaryWithId>> {
            return localVarFp.getAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch User with additional data
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetailed(userId: string, options?: any): AxiosPromise<FinanceUser> {
            return localVarFp.getUserDetailed(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all user\'s holidays items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserHolidaysTemplate(options?: any): AxiosPromise<UserHolidaysTemplate> {
            return localVarFp.getUserHolidaysTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates user salary
         * @param {string} userId 
         * @param {UpdateSalary} updateSalary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserSalary(userId: string, updateSalary: UpdateSalary, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserSalary(userId, updateSalary, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates custom template for user
         * @param {string} userId 
         * @param {Array<ItemIdWithModification>} itemIdWithModification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTemplate(userId: string, itemIdWithModification: Array<ItemIdWithModification>, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserTemplate(userId, itemIdWithModification, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Changes user template
     * @param {string} userId 
     * @param {UpdateTemplateId} updateTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public changeUserTemplate(userId: string, updateTemplateId: UpdateTemplateId, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).changeUserTemplate(userId, updateTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a list of all users with limited informations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllUsers(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch User with additional data
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserDetailed(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserDetailed(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all user\'s holidays items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserHolidaysTemplate(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserHolidaysTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates user salary
     * @param {string} userId 
     * @param {UpdateSalary} updateSalary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserSalary(userId: string, updateSalary: UpdateSalary, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUserSalary(userId, updateSalary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates custom template for user
     * @param {string} userId 
     * @param {Array<ItemIdWithModification>} itemIdWithModification 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserTemplate(userId: string, itemIdWithModification: Array<ItemIdWithModification>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUserTemplate(userId, itemIdWithModification, options).then((request) => request(this.axios, this.basePath));
    }
}


