import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import { Dispatch, AnyAction } from "redux";
import {
  HolidaysItemModel,
  HolidaysTemplateModel,
  HolidaysTemplatesModel,
} from "../models";
import HolidaysTemplateService from "../services/holidays-templates";
import ActionTypes from "./actionTypes";

export const fetchHolidayItemStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_ITEM_START);
export const fetchHolidayItemSuccess = (
  payload: HolidaysItemModel | Record<string, never>,
): AnyAction => actionCreator(ActionTypes.FETCH_HOLIDAYS_ITEM_SUCCESS, payload);
export const fetchHolidayItemError = (
  payload: HolidaysItemModel | Record<string, never>,
): AnyAction => actionCreator(ActionTypes.FETCH_HOLIDAYS_ITEM_ERROR, payload);

export const fetchHolidayItem = async (
  templateId: string,
  holidayId: string,
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(fetchHolidayItemStart());

  const res = await HolidaysTemplateService.getHolidaysItemById(
    templateId,
    holidayId,
  );

  if (isAPIError(res)) dispatch(fetchHolidayItemError(res));
  else dispatch(fetchHolidayItemSuccess(res));
};

export const fetchHolidaysTemplateStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_TEMPLATE_START);
export const fetchHolidaysTemplateSuccess = (
  payload: HolidaysTemplateModel | Record<string, never>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_TEMPLATE_SUCCESS, payload);
export const fetchHolidaysTemplateError = (
  payload: HolidaysTemplateModel | Record<string, never>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_TEMPLATE_ERROR, payload);

export const fetchHolidaysTemplate = async (
  templateId: string,
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(fetchHolidaysTemplateStart());

  const res = await HolidaysTemplateService.getHolidaysTemplateById(templateId);

  if (isAPIError(res)) dispatch(fetchHolidaysTemplateError(res));
  else dispatch(fetchHolidaysTemplateSuccess(res));
};

export const fetchHolidaysTemplatesStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_TEMPLATES_START);
export const fetchHolidaysTemplatesSuccess = (
  payload: HolidaysTemplatesModel,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_TEMPLATES_SUCCESS, payload);
export const fetchHolidaysTemplatesError = (
  payload: HolidaysTemplateModel,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_TEMPLATES_ERROR, payload);

export const fetchHolidaysTemplates = async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(fetchHolidaysTemplatesStart());

  const res = await HolidaysTemplateService.getAllHolidaysTemplates();

  if (isAPIError(res)) dispatch(fetchHolidaysTemplatesError(res));
  else dispatch(fetchHolidaysTemplatesSuccess(res));
};
