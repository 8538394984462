import { removeDiacriticsFromString } from "@ps/utils";
import { HolidaysUsersModel, HolidaysUserModel } from "../models";

const filterHolidaysUsersBySearch = (
  data: HolidaysUsersModel,
  searchValue: string,
): HolidaysUsersModel =>
  data?.filter(
    (user: HolidaysUserModel): boolean =>
      removeDiacriticsFromString(user.name.toLowerCase()).includes(
        removeDiacriticsFromString(searchValue.toLowerCase().trim()),
      ) ||
      removeDiacriticsFromString(
        user.name.split(" ").reverse().join(" ").toLowerCase(),
      ).includes(removeDiacriticsFromString(searchValue.toLowerCase().trim())),
  );

export default filterHolidaysUsersBySearch;
