import React, { useState, ReactElement } from "react";
import { COLLAPSE_PREFIX } from "../../shared/data-cy";
import CollapseContext from "./context";
import { CollapseProps } from "./types";

const Collapse = ({
  children,
  accordion = false,
  dataCy,
  defaultOpened,
}: CollapseProps): ReactElement => {
  const [openedPanels, setOpenedPanels] = useState<string[]>(
    defaultOpened ?? [],
  );
  const togglePanel = (key: string) => {
    setOpenedPanels((opened) => {
      if (opened.includes(key)) {
        return opened.filter((value) => value !== key);
      }
      return [...opened, key];
    });
  };

  const togglePanelWhenAccordion = (key: string) => {
    setOpenedPanels((opened) => {
      if (opened.includes(key)) {
        return [];
      }
      return [key];
    });
  };
  return (
    <CollapseContext.Provider
      value={{
        togglePanel: accordion ? togglePanelWhenAccordion : togglePanel,
        openedPanels,
      }}
    >
      <div className="flex flex-col" data-cy={`${COLLAPSE_PREFIX}-${dataCy}`}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, { dataCy }),
        )}
      </div>
    </CollapseContext.Provider>
  );
};

export default Collapse;
