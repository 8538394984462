import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "redux-react-hook";
import { useHistory } from "react-router-dom";
import {
  logoutStart,
  logoutSuccess,
  logoutError,
} from "../../store/login/actions";
import { DEFAULT } from "../../shared/routes";

const useKeycloakLogout = (): Record<string, () => void> => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { keycloak } = useKeycloak();

  const logout = (): void => {
    dispatch(logoutStart());
    history.push(DEFAULT);

    keycloak
      .logout()
      .then(() => dispatch(logoutSuccess()))
      .catch(() => dispatch(logoutError));
  };

  return { logout };
};

export default useKeycloakLogout;
