import { Dayjs } from "dayjs";
import { DatesProps, InitialConfigProps } from "./getTimeUnitButtonStyleTypes";
import {
  isNotEmpty,
  timeComparatorsForCompareMode,
} from "../time/styleTimeUnitHelpers";
import { InputType } from "../state/useSelectDateTypes";
import { CalendarDisplayModes } from "../../types";

const getTimeUnitButtonStyle =
  ({
    stylesSelected,
    stylesHover,
    compareMode,
    displayMode,
  }: InitialConfigProps) =>
  ({ selectedDates, hoveringOverDate, currentInput }: DatesProps) =>
  (timeUnit: Dayjs, isInCurrentSelection = true): string => {
    if (!isInCurrentSelection)
      return stylesSelected.notInCurrentSelection ?? "";

    const hoveringOverDateWrapped =
      timeComparatorsForCompareMode(compareMode)(hoveringOverDate);

    const timeUnitWrapped =
      timeComparatorsForCompareMode(compareMode)(timeUnit);

    const isWeekPicker = displayMode === CalendarDisplayModes.WEEKS;
    const isTheSameWeekAsHover =
      hoveringOverDate &&
      timeUnitWrapped.inBetweenExclusive(
        hoveringOverDate.startOf("week"),
        hoveringOverDate.endOf("week"),
      );
    const isStartOfTheSameWeek =
      hoveringOverDate &&
      timeUnitWrapped.isSame(hoveringOverDate.startOf("week"));
    const isEndOfTheSameWeek =
      hoveringOverDate &&
      timeUnitWrapped.isSame(hoveringOverDate.endOf("week"));

    let selectedStyle = "";
    let hoverStyle = "";

    const addHoverStyle = (style?: string) => {
      if (style) {
        hoverStyle += `${style} `;
      }
    };
    const addSelectedStyle = (style: string) => {
      selectedStyle += `${style} `;
    };

    if (isWeekPicker) {
      if (isTheSameWeekAsHover) {
        addHoverStyle(stylesHover?.hoverInBetween);
      }
      if (isStartOfTheSameWeek) {
        addHoverStyle(stylesHover?.hoverFirstInRow);
      }
      if (isEndOfTheSameWeek) {
        addHoverStyle(stylesHover?.hoverLastInRow);
      }
    }

    if (!selectedDates)
      return [stylesSelected.notSelected, hoverStyle]
        .filter(isNotEmpty)
        .join(" ");

    const [rangeStart, rangeEnd] = selectedDates;

    const isRangeSelected =
      rangeStart && rangeEnd && !rangeStart?.isSame(rangeEnd, compareMode);

    const isFirstInput = currentInput === InputType.FIRST;
    const isSecondInput = !isFirstInput;

    const isNotInSelection =
      timeUnitWrapped.isBefore(rangeStart) || timeUnitWrapped.isAfter(rangeEnd);
    const isStartOfSelection = timeUnitWrapped.isSame(rangeStart);
    const isEndOfSelection = timeUnitWrapped.isSame(rangeEnd);
    const isBetweenSelection = timeUnitWrapped.inBetweenExclusive(
      rangeStart,
      rangeEnd,
    );
    const isHoverBetweenSelection = hoveringOverDateWrapped.inBetween(
      rangeStart,
      rangeEnd,
    );
    const isAfterFirstSelection =
      timeUnitWrapped.isAfter(rangeStart) && !isEndOfSelection;
    const isBetweenDateAndHover = timeUnitWrapped.inBetweenHoverAndRange(
      hoveringOverDate,
      rangeStart,
      rangeEnd,
    );
    const isLastInRowDuringHover =
      (hoveringOverDateWrapped.isAfter(rangeEnd) ||
        (isSecondInput && hoveringOverDateWrapped.isSameOrAfter(rangeStart))) &&
      timeUnitWrapped.isSame(hoveringOverDate);
    const isFirstInRowDuringHover =
      (hoveringOverDateWrapped.isBefore(rangeStart) ||
        (isFirstInput && hoveringOverDateWrapped.isSameOrBefore(rangeEnd))) &&
      timeUnitWrapped.isSame(hoveringOverDate);
    const isNextAfterHover =
      isSecondInput &&
      isHoverBetweenSelection &&
      timeUnitWrapped.subtractOne().isSame(hoveringOverDate);
    const isOneBeforeHover =
      isFirstInput &&
      isHoverBetweenSelection &&
      hoveringOverDateWrapped.subtractOne().isSame(timeUnit);

    if (isNotInSelection) {
      addSelectedStyle(stylesSelected.notSelected);
    }
    if (isBetweenSelection) {
      addSelectedStyle(stylesSelected.selectedInBetween);
      // Add not selected if only one date is selected
    } else if (isAfterFirstSelection) {
      addSelectedStyle(stylesSelected.notSelected);
    }
    if (isStartOfSelection) {
      addSelectedStyle(stylesSelected.selectedFirstInRow);
      if (!isRangeSelected && !hoveringOverDate) {
        addSelectedStyle(stylesSelected.selectedLastInRow);
      }
    }
    if (isEndOfSelection) {
      addSelectedStyle(stylesSelected.selectedLastInRow);
      if (!isRangeSelected) {
        addSelectedStyle(stylesSelected.selectedFirstInRow);
      }
    }
    if (isBetweenDateAndHover && !isWeekPicker) {
      addHoverStyle(stylesHover?.hoverInBetween);
    }
    if (isLastInRowDuringHover && !isWeekPicker) {
      addHoverStyle(stylesHover?.hoverLastInRow);
    }
    if (isFirstInRowDuringHover && !isWeekPicker) {
      addHoverStyle(stylesHover?.hoverFirstInRow);
    }
    if (isNextAfterHover && isNotEmpty(selectedStyle) && !isWeekPicker) {
      addHoverStyle(stylesHover?.nextUnitAfterHoverSecondInput);
    }
    if (isOneBeforeHover && isNotEmpty(selectedStyle) && !isWeekPicker) {
      addHoverStyle(stylesHover?.prevUnitBeforeHoverFirstInput);
    }

    return [selectedStyle, hoverStyle].filter(isNotEmpty).join(" ");
  };
export default getTimeUnitButtonStyle;
