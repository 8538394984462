import { ReactElement, useRef } from "react";
import { Button2 } from "@ps/ui-components";
import { classJoin, useDropDiv } from "@ps/utils";
import UploadFileInput from "./uploadFileInput";
import { UploaderProps } from "../types";
import { useTranslationWithNamespace } from "../../../../hooks";

const UPLOADER_PREFIX = "uploader";

const Uploader = ({
  onFilesSelected,
  disabled,
}: UploaderProps): ReactElement => {
  const { dropDivRef, isDraggingOver } = useDropDiv({
    onFilesSelected,
  });
  const { t } = useTranslationWithNamespace();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBrowseClick = () => {
    inputRef.current?.click();
    inputRef.current.value = null;
  };

  const opacityStyle = isDraggingOver ? "opacity-50" : "opacity-100";
  return (
    <>
      <UploadFileInput
        disabled={disabled}
        isMultiple
        onFilesSelected={(files: FileList) => {
          onFilesSelected(files);
        }}
        ref={inputRef}
      />
      <div
        ref={disabled ? null : dropDivRef}
        className={classJoin(
          "w-full flex justify-center items-center px-5 py-8 bg-neutralPrimary-100 relative",
          // TODO uncomment when full feature is ready
          disabled ? "opacity-50 pointer-events-none" : opacityStyle,
        )}
        data-cy={UPLOADER_PREFIX}
      >
        <div className="flex flex-col items-center w-full gap-2">
          <Button2
            onClick={handleBrowseClick}
            dataCy={UPLOADER_PREFIX}
            disabled={disabled}
          >
            {t("members.inviting.uploadFile", { type: "PDF" })}
          </Button2>
          {disabled ? (
            <></>
          ) : (
            <div className="flex flex-col items-center gap-4">
              <span className="flex items-center gap-2">
                <span className="text-lg">or</span>
                <span className="text-neutralPrimary-20 text-lg whitespace-nowrap font-semibold">
                  {t("members.inviting.dragAndDrop")}
                </span>
              </span>
              <span className="text-neutralPrimary-20 text-lg whitespace-nowrap">
                {t("members.inviting.fileMustBeNoLonger", { size: 4 })}
              </span>
            </div>
          )}

          {disabled && (
            <span className="text-warning-60 text-lg whitespace-nowrap font-semibold">
              {t("members.inviting.exceededLimit")}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default Uploader;
