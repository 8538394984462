import {
  ReactElement,
  useEffect,
  useState,
  MouseEvent,
  KeyboardEvent,
  useContext,
} from "react";
import { useDispatch } from "redux-react-hook";
import { useParams, useHistory } from "react-router-dom";
import { Tabs2, ToggleSelect } from "@ps/ui-components";
import PermissionContext from "@ps/ui-components/src/components/permissions/context";
import { NewProjectMapState, NewProjectMapStateReturn } from "./types";
import {
  DETAILS,
  TEAM,
  FILE_REPOSITORY,
  PROJECT,
  ACTIVE,
  ARCHIVED,
} from "../../constants";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { toggleEditMode } from "../../../store";
import { fetchProject } from "../../store";
import { Details } from "./sections/details";
import { Team } from "./sections/team";
import { ProjectService } from "../../services";
import { Keys } from "../../../shared";
import { ReactComponent as LockIcon } from "../../../images/project/lock.svg";
import { PROJECT_FULL_ACCESS } from "../../../shared/permissions";
import { TeamContext } from "./context";

const BASE_PATH = "project.newProjectTabs";

const NewProject = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { hasPermission } = useContext(PermissionContext);

  const [editUserId, setEditUserId] = useState("");
  const [viewUserId, setViewUserId] = useState("");
  const [focusId, setFocusId] = useState("");
  const [teamView, setTeamView] = useState(true);
  const [isAddingNewUser, setIsAddingNewUser] = useState(true);

  const mapState = (state: NewProjectMapState): NewProjectMapStateReturn => ({
    editMode: state.editMode,
    technologyDict: state?.dictionaries?.technology || [],
    project: state?.projects?.project,
  });

  const { technologyDict, project, editMode } =
    useMappedStateSelector(mapState);

  const tabs = [
    {
      label: t(`${BASE_PATH}.${DETAILS}`),
      value: DETAILS,
    },
    {
      label: t(`${BASE_PATH}.${TEAM}`),
      value: TEAM,
    },
    // TODO temporary hidden because of HH2-2728
    // {
    //   label: t(`${BASE_PATH}.${FILE_REPOSITORY}`),
    //   value: FILE_REPOSITORY,
    // },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  const handleTabClick = (e: MouseEvent<HTMLDivElement>): void =>
    setCurrentTab(e.currentTarget.id);

  const handleTabKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === Keys.ENTER) setCurrentTab(e.currentTarget.id);
  };

  useEffect(() => {
    if (technologyDict.length)
      fetchProject(params.id, dispatch, technologyDict);
  }, [technologyDict]);

  useEffect(() => {
    return () => {
      toggleEditMode("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleArchiveStatus = async () => {
    await ProjectService.archiveProject(project.id, !project.archived);
    await fetchProject(project.id, dispatch, technologyDict);
  };

  const renderContent = () => {
    switch (currentTab) {
      case DETAILS:
        return <Details />;
      case TEAM:
        return <Team />;
      case FILE_REPOSITORY:
        return <div className="bg-neutralPrimary-100 w-full" />;
      default:
        return <Details />;
    }
  };

  const isDetails = currentTab === DETAILS;

  useEffect(() => setFocusId(""), []);

  return (
    <TeamContext.Provider
      value={{
        editUserId,
        setEditUserId,
        viewUserId,
        setViewUserId,
        teamView,
        setTeamView,
        focusId,
        setFocusId,
        isAddingNewUser,
        setIsAddingNewUser,
      }}
    >
      <div>
        {!editMode.length && (
          <>
            <span
              role="button"
              tabIndex={0}
              onClick={() => history.goBack()}
              onKeyDown={(event) => {
                if (event.key === Keys.ENTER) history.goBack();
              }}
              className="text-neutralPrimary-30 cursor-pointer select-none hover:text-neutralPrimary-20"
            >
              {`<`} {t(`${PROJECT}.back`)}
            </span>
            <div className="flex items-center gap-x-5">
              {!project?.public && (
                <LockIcon
                  className="mb-2"
                  height="100%"
                  fill={`${project?.details?.color}`}
                />
              )}
              <h2 className="text-4xl text-neutralPrimary-20 mt-6 mb-8 font-bold">
                {project.details?.name}
              </h2>
            </div>
          </>
        )}
        <div className="flex justify-between w-9/12 pr-8">
          <Tabs2
            currentValue={currentTab}
            tabs={tabs}
            onTabClick={handleTabClick}
            // eslint-disable-next-line
            // @ts-ignore
            onTabKeyDown={handleTabKeyDown}
          />
          {isDetails && (
            <ToggleSelect
              dataCy={`${BASE_PATH}.toggle`}
              checked={!project?.archived}
              activeLabel={t(`${PROJECT}.toggle.${ACTIVE}`)}
              negationLabel={t(`${PROJECT}.toggle.${ARCHIVED}`)}
              onChange={toggleArchiveStatus}
              disabled={!hasPermission(PROJECT_FULL_ACCESS)}
            />
          )}
        </div>
        {renderContent()}
      </div>
    </TeamContext.Provider>
  );
};

export default NewProject;
