import { ReactElement } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  useThemeMode,
} from "@ps/ui-components";
import { HOLIDAY } from "../../../../../holidays-templates-domain/ui/constants";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { ReactComponent as RemoveGraphic } from "../../../../../images/finance/holidays/removeGraphic.svg";
import { ReactComponent as RemoveGraphicHC } from "../../../../../images/finance/holidays/removeGraphicHighContrast.svg";
import { RemoveHolidayEntryModalProps } from "./types";

const RemoveHolidayEntryModal = ({
  isOpen,
  onClose,
  onRemove,
}: RemoveHolidayEntryModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();

  const handleOnRemove = () => {
    onRemove();
    onClose();
  };

  return (
    <Modal
      dataCy={`${HOLIDAY}History_remove`}
      isOpen={isOpen}
      onClose={onClose}
      withCloseIcon
      height="h-auto"
      additionalContentClassName="flex flex-col gap-8"
      withYScroll={false}
      width="w-2/5"
    >
      <ModalContent additionalClassName="flex items-center justify-center gap-4 pt-10">
        {isHighContrast ? <RemoveGraphicHC /> : <RemoveGraphic />}
        <div className="flex flex-col gap-4">
          <h1 className="text-lg font-bold">Delete Holiday</h1>
          <p className="text-sm mb-1">
            Do you really want to delete his holiday?
          </p>
        </div>
      </ModalContent>
      <ModalFooter additionalClassName="flex items-center justify-center gap-4 pb-10">
        <Button
          dataCy={`${HOLIDAY}_delete`}
          type="submit"
          onClick={handleOnRemove}
        >
          {t("actions.delete")}
        </Button>
        <Button
          dataCy={`${HOLIDAY}_cancel`}
          variant="secondary"
          onClick={onClose}
        >
          {t("actions.cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveHolidayEntryModal;
