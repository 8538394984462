import { KeyboardEvent, ReactElement, useEffect, useState } from "react";
import { classJoin } from "@ps/utils";
import countries from "i18n-iso-countries";
import {
  FilterDropdown,
  TableLabel,
  AmountFilterOption,
} from "@ps/ui-components";
import styles from "./styles.module.css";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import { TEAM_LIST } from "../../../../../shared/data-cy";
import Keys from "../../../../../shared/keys";
import {
  PROFESSION,
  PROFESSIONS,
  FULL_NAME,
  SENIORITY,
  USER_NAME,
  START_DATE,
  FINISH_DATE,
  RATE,
  STATUS,
  LOCATION,
} from "../../../../constants";
import { useTeamContext } from "../../context";
import {
  countOptionFilterAmount,
  getCheckedNames,
  prepareLocationFilterOptions,
  prepareProfessionFilterOptions,
  prepareSeniorityFilterOptions,
  selectFilterOptions,
} from "./utils";
import {
  IFilterOption,
  SortBarProps,
  TeamSortBarMapState,
  TeamSortBarMapStateReturn,
} from "./types";
import { ReactComponent as FaceIcon } from "../../../../../images/project/face-id.svg";
import { CURRENT_LANGUAGE_CODE } from "../../../../../i18n/constants";

const DROPDOWN_ID = "filter-dropdown";

const SortBar = ({
  filters,
  updateFilters,
  users,
}: SortBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const translate = (field: string) => t(`project.team.${field}`);
  const [searchValue, setSearchValue] = useState("");
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [currentIDFocused, setCurrentIDFocused] = useState("");

  const { teamView } = useTeamContext();

  const mapState = (state: TeamSortBarMapState): TeamSortBarMapStateReturn => ({
    seniorityDict: state.dictionaries?.seniority || [],
    allProfessionsDict: [
      ...(state?.dictionaries?.profession || []),
      ...(state?.dictionaries.operative_profession || []),
    ],
  });

  const { seniorityDict, allProfessionsDict } =
    useMappedStateSelector(mapState);

  useEffect(() => {
    if (showFilterOptions) document.getElementById(DROPDOWN_ID)?.focus();
  }, [showFilterOptions]);

  const professionFiltersOptions = prepareProfessionFilterOptions(
    users,
    allProfessionsDict,
  );
  const seniorityFiltersOptions = prepareSeniorityFilterOptions(
    users,
    seniorityDict,
  );

  const allCountries = countries.getNames(CURRENT_LANGUAGE_CODE, {
    select: "official",
  });

  const locationFiltersOptions = prepareLocationFilterOptions(
    users,
    allCountries,
  );

  const renderLabel = (
    filterName: string,
    translationName?: string,
    filterOptions: IFilterOption[] | undefined = undefined,
    withAmount = false,
    nestedName = "",
  ): ReactElement => (
    <TableLabel
      dataCy={translationName || filterName}
      setSearchValue={setSearchValue}
      filtering={
        filterOptions
          ? {
              checked: getCheckedNames(filters, filterName),
              dropdownOverlay: (
                <FilterDropdown
                  dataCy={`${TEAM_LIST}-${filterName}`}
                  DROPDOWN_ID={DROPDOWN_ID}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setShowFilterOptions={setShowFilterOptions}
                  filterOptions={filterOptions}
                >
                  {filterOptions.map((item: IFilterOption, index: number) => {
                    const isChecked = filters[filterName]?.some(
                      (itemId: string) => itemId === item.id,
                    );
                    const autoFocusId = `${filterName}-${index}`;
                    return (
                      <AmountFilterOption
                        key={item.id}
                        autoFocus={autoFocusId === currentIDFocused}
                        activeNumber={countOptionFilterAmount(
                          item.id,
                          filterName,
                          users,
                          nestedName,
                        )}
                        item={item}
                        isChecked={isChecked}
                        filterName={filterName}
                        withAmount={withAmount}
                        dataCy={TEAM_LIST}
                        onClick={(
                          isCurrenChecked: boolean,
                          itemId: string,
                          currentFilterName: string,
                        ) => {
                          updateFilters(
                            isCurrenChecked,
                            itemId,
                            currentFilterName,
                          );
                          setCurrentIDFocused(autoFocusId);
                        }}
                        onKeyDown={(
                          e: KeyboardEvent<HTMLButtonElement>,
                          isCurrenChecked: boolean,
                          itemId: string,
                          currentFilterName: string,
                        ) => {
                          if (e.code === Keys.SPACE) {
                            e.preventDefault();
                            updateFilters(
                              isCurrenChecked,
                              itemId,
                              currentFilterName,
                            );
                            setCurrentIDFocused(autoFocusId);
                          }
                        }}
                      />
                    );
                  })}
                </FilterDropdown>
              ),
            }
          : undefined
      }
    >
      {translate(translationName || filterName)}
    </TableLabel>
  );

  return (
    <div
      className={classJoin(
        teamView ? styles.gridContainer : styles.addUserContainer,
        "w-full bg-neutralSecondary-41 bg-opacity-10 rounded-t-2xl h-16 items-center px-6",
      )}
    >
      <div className="flex gap-x-9 items-center pl-3">
        <FaceIcon className="text-neutralPrimary-30" />
        {renderLabel(FULL_NAME, USER_NAME)}
      </div>

      {renderLabel(
        PROFESSIONS,
        PROFESSION,
        selectFilterOptions(professionFiltersOptions, searchValue),
        true,
        "main",
      )}
      {renderLabel(
        SENIORITY,
        undefined,
        selectFilterOptions(seniorityFiltersOptions, searchValue),
        true,
      )}
      {teamView && renderLabel(START_DATE, START_DATE)}
      {teamView && renderLabel(FINISH_DATE, FINISH_DATE)}
      {teamView && renderLabel(RATE, RATE)}
      {teamView && renderLabel(STATUS, STATUS)}
      {!teamView &&
        renderLabel(
          LOCATION,
          undefined,
          selectFilterOptions(locationFiltersOptions, searchValue),
          true,
          "value",
        )}
      <div />
    </div>
  );
};

export default SortBar;
