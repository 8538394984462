import { ReactElement, KeyboardEvent } from "react";

export interface TagProps {
  name: string | ReactElement;
  dataCy: string;
  onClick?: (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | KeyboardEvent<HTMLDivElement>,
  ) => void;
  disableDelete?: boolean;
  disabled?: boolean;
  secondary?: boolean;
  additionalClassName?: string;
}

export enum AnimationVariant {
  WHEN_HOVER = "hover",
  WHEN_NOT_HOVER = "notHover",
}
