import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import Tab from "./tab";
import { useEditMatrixContext } from "../../context";
import LoadingTab from "./loadingTab";
import { DictionaryEntryModel } from "../../../../../../../dictionaries";
import {
  TabsContainerProps,
  TabsContainerMapState,
  TabsContainerMapStateReturn,
} from "./types";
import { useMappedStateSelector } from "../../../../../../../hooks";
import styles from "../../styles.module.css";
import { DOMAINS_PROFESSION_ID } from "../../../../../../shared/consts";

const TabsContainer = ({
  activeTab,
  children,
  isLoading,
  onActiveTabClick,
  professions = [],
  handleOnRemoveProfession,
  onSubmit,
}: TabsContainerProps): ReactElement => {
  const { editProfession, editGroups, editTechnologies } =
    useEditMatrixContext();

  const mapState = (
    state: TabsContainerMapState,
  ): TabsContainerMapStateReturn => ({
    templates: state?.skillsMatrix?.templates,
  });

  const { templates } = useMappedStateSelector(mapState);

  if (isLoading)
    return (
      <div>
        {new Array(4).fill(0).map(() => (
          <LoadingTab />
        ))}
      </div>
    );
  return (
    <section className={classJoin("flex gap-1", styles.pageContent)}>
      <div className="h-full overflow-x-auto">
        {professions.map(
          (profession: DictionaryEntryModel, index: number): ReactElement => (
            <Tab
              cantRemove={
                !!templates?.[profession?.itemId || ""]?.groups?.length
              }
              disabled={
                !!(editProfession && editProfession !== profession.itemId) ||
                !!editGroups?.length ||
                !!editTechnologies?.length
              }
              index={index}
              isActive={activeTab === profession.itemId}
              key={profession.id}
              onActiveTabClick={() => onActiveTabClick(profession.itemId || "")}
              onRemove={() =>
                handleOnRemoveProfession(profession.itemId, index)
              }
              tab={profession}
              onSubmit={onSubmit}
              hideButtons={profession.itemId === DOMAINS_PROFESSION_ID}
            />
          ),
        )}
      </div>
      {children}
    </section>
  );
};

export default TabsContainer;
