export const hexToRgbString = (value = ""): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(value);

  const color = result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : null;

  return color ? `rgb(${color.red}, ${color.green}, ${color.blue})` : "";
};

export const prepareRGBObjectToRGB = (colorToConvert?: {
  red: number;
  green: number;
  blue: number;
}) => {
  if (!colorToConvert) return "";
  return `rgb(${colorToConvert.red}, ${colorToConvert.green}, ${colorToConvert.blue})`;
};

const colorValueToHex = (colorValue: number) => {
  const hex = colorValue.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

export const prepareRGBObjectToHEX = (colorToConvert: any) => {
  if (!colorToConvert || !Object.keys(colorToConvert)?.length) return "";
  return `#${colorValueToHex(colorToConvert.red)}${colorValueToHex(
    colorToConvert.green,
  )}${colorValueToHex(colorToConvert.blue)}`;
};

export const colorToRBGObject = (
  color?:
    | string
    | {
        red: number;
        green: number;
        blue: number;
      },
) => {
  if (!color) {
    return undefined;
  }

  const isHex = (value: string) =>
    /^#?([a-f\d]{3,4}|[a-f\d]{6}|[a-f\d]{8})$/.test(value);

  const isRGB = (value: string) =>
    value.match(
      new RegExp(
        "^rgb\\((25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9]), ?(25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9]), ?(25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9])\\)$",
      ),
    ) !== null;

  if (typeof color !== "string")
    return {
      red: color?.red,
      green: color?.green,
      blue: color?.blue,
    };

  if (isHex(color)) {
    const valuesArr = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);

    return valuesArr
      ? {
          red: parseInt(valuesArr[1], 16),
          green: parseInt(valuesArr[2], 16),
          blue: parseInt(valuesArr[3], 16),
        }
      : undefined;
  }

  if (isRGB(color)) {
    const valuesArr = color.match(/\d+/g);
    return valuesArr
      ? {
          red: parseInt(valuesArr[0], 10),
          green: parseInt(valuesArr[1], 10),
          blue: parseInt(valuesArr[2], 10),
        }
      : undefined;
  }

  return undefined;
};

export const hexToRGBObject = (
  value = "",
): { red: number; green: number; blue: number } | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(value);

  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : null;
};
