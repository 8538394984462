import { ReactElement } from "react";
import dayjs from "dayjs";
import { classJoin } from "@ps/utils";
import {
  Empty,
  Label,
  Card,
  MoreLinkButton,
  useThemeMode,
} from "@ps/ui-components";
import { Link, useHistory } from "react-router-dom";
import QuantityBox from "./quantityBox";
import {
  ACTIVE_FOLDERS,
  ACTIVE_PROJECTS,
  ACTIVE_PROSPECTS,
  CLIENT,
  CLIENT_NAME,
  CLIENTS_LIST,
} from "../../../shared/data-cy";
import styles from "./styles.module.css";
import {
  ACTIVE,
  ARCHIVED,
  CLIENTS,
  FOLDERS,
  LAST_UPDATE,
  MORE,
  PROJECTS,
  PROSPECTS,
  ROW_DATE_FORMAT,
  STATUS,
} from "../../constants";
import { ClientsRowProps } from "../types";
import { useTranslationWithNamespace } from "../../../hooks";

const ClientRow = ({ client }: ClientsRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();
  const history = useHistory();

  const statusStyle = client.archived ? "bg-gray-200" : "bg-other-toxicGreen";

  const showStatus = (archived: boolean): ReactElement => {
    return (
      <div className="flex gap-x-3 items-center">
        <div className={classJoin(statusStyle, "h-4 w-4 rounded-full")} />
        <span className="uppercase font-semibold">
          {archived ? t(`${CLIENTS}.${ARCHIVED}`) : t(`${CLIENTS}.${ACTIVE}`)}
        </span>
      </div>
    );
  };

  const stylesWhenArchived = client?.archived ? "opacity-40" : "";

  const redirect = (tab?: string) => {
    const pathname = `/${CLIENTS}/${client?.id}`;
    if (tab) history.push({ pathname, state: { tab } });
    else history.push(pathname);
  };

  return (
    <Card
      dataCy={CLIENTS_LIST}
      additionalClassName="items-center"
      additionalCardClassName={classJoin(
        styles.gridContainer,
        "2xl:gap-4 gap-2 mb-1 py-10 top-1/2",
        isHighContrast && "border border-neutralSecondary-40",
      )}
    >
      <Label
        dataCy={`${CLIENTS_LIST}_${CLIENT_NAME}`}
        verticalAlignment
        additionalClassName={stylesWhenArchived}
      >
        {client?.clientName ? (
          <Link to={`/${CLIENTS}/${client?.id}`} className="text-primary-50">
            {client?.clientName}
          </Link>
        ) : (
          <Empty />
        )}
      </Label>
      <Label
        dataCy={`${CLIENTS_LIST}_${ACTIVE_PROSPECTS}`}
        verticalAlignment
        additionalClassName={stylesWhenArchived}
      >
        <QuantityBox
          dataCy={`${CLIENT}_${ACTIVE_PROSPECTS}`}
          quantity={client?.numberOfActiveProspects}
          borderStyle="border-primary-51"
          onClick={() => redirect(PROSPECTS)}
        />
      </Label>
      <Label
        dataCy={`${CLIENTS_LIST}_${ACTIVE_FOLDERS}`}
        verticalAlignment
        additionalClassName={stylesWhenArchived}
      >
        <QuantityBox
          dataCy={`${CLIENT}_${ACTIVE_FOLDERS}`}
          quantity={client?.numberOfActiveFolders}
          borderStyle="border-success-50"
          onClick={() => redirect(FOLDERS)}
        />
      </Label>
      <Label
        dataCy={`${CLIENTS_LIST}_${ACTIVE_PROJECTS}`}
        verticalAlignment
        additionalClassName={stylesWhenArchived}
      >
        <QuantityBox
          dataCy={`${CLIENT}_${ACTIVE_PROJECTS}`}
          quantity={client?.numberOfActiveProjects}
          borderStyle="border-neutralSecondary-30"
          onClick={() => redirect(PROJECTS)}
        />
      </Label>
      <Label
        dataCy={`${CLIENTS_LIST}_${LAST_UPDATE}`}
        verticalAlignment
        additionalClassName={stylesWhenArchived}
      >
        {client?.lastUpdate &&
          dayjs(client?.lastUpdate).format(ROW_DATE_FORMAT)}
      </Label>
      <Label
        dataCy={`${CLIENTS_LIST}_${STATUS}`}
        verticalAlignment
        additionalClassName={stylesWhenArchived}
      >
        {showStatus(client.archived)}
      </Label>

      <MoreLinkButton
        dataCy={`${CLIENTS_LIST}_${MORE}`}
        onClick={() => redirect()}
        additionalClassName="self-center place-self-end mr-5"
      />
    </Card>
  );
};

export default ClientRow;
