import { ReactElement } from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { NameProps } from "../types";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "1rem",
    flexWrap: "wrap",
  },
  text: {
    fontSize: "20px",
    fontFamily: "PoppinsBold",
    textTransform: "uppercase",
    color: "#091B76",
    fontWeight: "black",
    marginRight: "8px",
  },
  text2: {
    fontSize: "20px",
    textTransform: "uppercase",
    fontFamily: "PoppinsLight",
    color: "#A9A9A9",
    fontWeight: "thin",
  },
});

const Name = ({ firstName, lastName }: NameProps): ReactElement => (
  <View style={styles.container}>
    <Text style={styles.text}>{firstName}</Text>
    <Text style={styles.text2}>{lastName}</Text>
  </View>
);

export default Name;
