import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { TableLabel } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../hooks";
import {
  CLIENT_NAME,
  ACTIVE_PROSPECTS,
  ACTIVE_FOLDERS,
  ACTIVE_PROJECTS,
  LAST_UPDATE,
  STATUS,
} from "../../constants";
import styles from "./styles.module.css";

const SortBar = (): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const translate = (field: string): string => t(`clients.sortBar.${field}`);
  return (
    <div
      className={classJoin(
        styles.gridContainer,
        "2xl:gap-4 gap-2 bg-neutralSecondary-41 bg-opacity-10 rounded-t-2xl h-16 items-center mt-8",
      )}
    >
      <TableLabel dataCy={CLIENT_NAME}>{translate(CLIENT_NAME)}</TableLabel>
      <TableLabel dataCy={ACTIVE_PROSPECTS}>
        {translate(ACTIVE_PROSPECTS)}
      </TableLabel>
      <TableLabel dataCy={ACTIVE_PROSPECTS}>
        {translate(ACTIVE_FOLDERS)}
      </TableLabel>
      <TableLabel dataCy={ACTIVE_PROJECTS}>
        {translate(ACTIVE_PROJECTS)}
      </TableLabel>
      <TableLabel dataCy={LAST_UPDATE}>{translate(LAST_UPDATE)}</TableLabel>
      <TableLabel dataCy={STATUS}>{translate(STATUS)}</TableLabel>
    </div>
  );
};

export default SortBar;
