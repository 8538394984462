import { combineReducers, AnyAction } from "redux";
import { HolidaysTemplateModel, HolidaysItemModel } from "../models";
import ActionTypes from "./actionTypes";

const holidaysItemReducer = (
  state: HolidaysItemModel | Record<string, unknown> = {},
  action: AnyAction,
): HolidaysItemModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_HOLIDAYS_ITEM_START:
      return state;
    case ActionTypes.FETCH_HOLIDAYS_ITEM_SUCCESS:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return action.payload!;
    case ActionTypes.FETCH_HOLIDAYS_ITEM_ERROR:
      return {};
    default:
      return state;
  }
};

const holidaysTemplateReducer = (
  state: HolidaysTemplateModel | Record<string, unknown> = {},
  action: AnyAction,
): HolidaysTemplateModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_HOLIDAYS_TEMPLATE_START:
      return state;
    case ActionTypes.FETCH_HOLIDAYS_TEMPLATE_SUCCESS:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return action.payload!;
    case ActionTypes.FETCH_HOLIDAYS_TEMPLATE_ERROR:
      return {};
    default:
      return state;
  }
};

const holidaysTemplatesReducer = (
  state: HolidaysTemplateModel[] = [],
  action: AnyAction,
): HolidaysTemplateModel[] => {
  switch (action.type) {
    case ActionTypes.FETCH_HOLIDAYS_TEMPLATES_START:
      return state;
    case ActionTypes.FETCH_HOLIDAYS_TEMPLATES_SUCCESS:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return action.payload!;
    case ActionTypes.FETCH_HOLIDAYS_TEMPLATES_ERROR:
      return [];
    default:
      return state;
  }
};

export const isFetchingHolidays = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_HOLIDAYS_ITEM_START:
    case ActionTypes.FETCH_HOLIDAYS_TEMPLATE_START:
    case ActionTypes.FETCH_HOLIDAYS_TEMPLATES_START:
      return true;
    case ActionTypes.FETCH_HOLIDAYS_ITEM_SUCCESS:
    case ActionTypes.FETCH_HOLIDAYS_ITEM_ERROR:
    case ActionTypes.FETCH_HOLIDAYS_TEMPLATE_SUCCESS:
    case ActionTypes.FETCH_HOLIDAYS_TEMPLATE_ERROR:
    case ActionTypes.FETCH_HOLIDAYS_TEMPLATES_SUCCESS:
    case ActionTypes.FETCH_HOLIDAYS_TEMPLATES_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  holidaysItem: holidaysItemReducer,
  holidaysTemplate: holidaysTemplateReducer,
  holidaysTemplates: holidaysTemplatesReducer,
});
