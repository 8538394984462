import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { useTranslationWithNamespace } from "../../../hooks";
import { LEFT } from "../../../shared/constants";
import { PermissionsGroupProps } from "./types";
import styles from "./styles.module.css";

const GROUP_STYLES = {
  left: "border-r-0",
  middle: "border-r-0 border-l-0",
  right: "border-l-0",
};

const BASE_PATH = "permissions";

const PermissionsGroup = ({
  group,
  column = "",
}: PermissionsGroupProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const Icon = group.icon;
  const isLeft = column === LEFT;

  const renderPermissionsList = (data) => {
    if (!data?.permissions) {
      return (
        <div
          className={classJoin(
            "text-primary-70 font-normal text-sm",
            styles.baseSingleCell,
          )}
        >
          {isLeft &&
            t(
              `${BASE_PATH}.accessNames.${group.itemId}.${data.itemId.replace(
                ":",
                "-",
              )}`,
            )}
        </div>
      );
    }

    return (
      <div
        className={classJoin(
          "flex items-center w-full",
          data?.additionalId ? "" : "",
        )}
      >
        {data.additionalId && isLeft && (
          <span className="font-bold text-primary-70 transform -rotate-90 whitespace-nowrap h-8 w-8 pr-6">
            {t(`${BASE_PATH}.list.${data?.additionalId}`)}
          </span>
        )}
        <div
          className={classJoin(
            "flex flex-col w-full",
            data?.additionalId ? "pl-2" : "pl-6",
          )}
        >
          {data.permissions.map((item) => renderPermissionsList(item))}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <div
        className={classJoin(
          "flex gap-2 items-center pl-8 border border-primary-70 bg-primary-85 bg-opacity-30",
          GROUP_STYLES[column],
          styles.baseSingleCell,
        )}
      >
        {isLeft && (
          <>
            <div className="flex items-center justify-center bg-primary-70 rounded-md h-6 w-6">
              <Icon className="text-white" />
            </div>
            <span className="text-primary-70">
              {t(`${BASE_PATH}.list.${group?.itemId}`)}
            </span>
          </>
        )}
      </div>
      {renderPermissionsList(group)}
    </div>
  );
};
export default PermissionsGroup;
