import { ReactElement, useState, MouseEvent } from "react";
import { useDispatch } from "redux-react-hook";
import {
  ActiveSelect,
  Button,
  RestrictedWrapper,
  SearchInput,
  Sorter,
} from "@ps/ui-components";
import AddNewClientModal from "../addNewClientModal";
import { useTranslationWithNamespace } from "../../../hooks";
import { fetchClientsSummary } from "../../store";
import {
  ACTIVE,
  ARCHIVED,
  ASC,
  DESC,
  CLIENT_NAME,
  LAST_UPDATE,
  ACTIVE_PROSPECTS,
  NUMBER_OF_ACTIVE_PROSPECTS,
  NUMBER_OF_ACTIVE_FOLDERS,
  ACTIVE_FOLDERS,
  NUMBER_OF_ACTIVE_PROJECTS,
  ACTIVE_PROJECTS,
  ADD_NEW_CLIENT,
} from "../../constants";
import { SearchBarProps } from "../types";
import { ReactComponent as SuitcaseIcon } from "../../../images/suitcase.svg";
import {
  CLIENTS_MANAGE,
  PROSPECT_FULL_ACCESS,
} from "../../../shared/permissions";

const BASE_PATH = "clients";

const SearchBar = ({
  activeAmount,
  archivedAmount,
  clientsCheckers,
  setClientsCheckers,
  searchValue,
  setSearchValue,
  sorterParameters,
  onSorterClick,
}: SearchBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [isAddClientVisible, setIsAddClientVisible] = useState(false);
  const dispatch = useDispatch();

  const options = [
    {
      id: ACTIVE,
      label: t(`${BASE_PATH}.showActive`),
      amount: activeAmount,
      checked: clientsCheckers.active,
    },
    {
      id: ARCHIVED,
      label: t(`${BASE_PATH}.showArchived`),
      amount: archivedAmount,
      checked: clientsCheckers.archived,
    },
  ];

  const handleOptionClick = (e: MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget;
    setClientsCheckers((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const names = [
    {
      name: CLIENT_NAME,
      label: t(`${BASE_PATH}.${CLIENT_NAME}`),
    },
    {
      name: NUMBER_OF_ACTIVE_PROSPECTS,
      label: t(`${BASE_PATH}.${ACTIVE_PROSPECTS}`),
    },
    {
      name: NUMBER_OF_ACTIVE_FOLDERS,
      label: t(`${BASE_PATH}.${ACTIVE_FOLDERS}`),
    },
    {
      name: NUMBER_OF_ACTIVE_PROJECTS,
      label: t(`${BASE_PATH}.${ACTIVE_PROJECTS}`),
    },
    {
      name: LAST_UPDATE,
      label: t(`${BASE_PATH}.${LAST_UPDATE}`),
    },
  ];

  const orders = [
    { order: ASC, label: t(`${BASE_PATH}.${ASC}`) },
    { order: DESC, label: t(`${BASE_PATH}.${DESC}`) },
  ];

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-x-4">
        <RestrictedWrapper permissions={[CLIENTS_MANAGE, PROSPECT_FULL_ACCESS]}>
          <div>
            <Button
              additionalClass="gap-x-3"
              dataCy={`${ADD_NEW_CLIENT}_button`}
              onClick={() => setIsAddClientVisible(true)}
            >
              <SuitcaseIcon />
              {t(`${BASE_PATH}.addNewModalTitle`)}
            </Button>
            <AddNewClientModal
              fetchClients={() => fetchClientsSummary(dispatch)}
              isOpen={isAddClientVisible}
              onClose={() => setIsAddClientVisible(false)}
            />
          </div>
        </RestrictedWrapper>
        <ActiveSelect
          dataCy={`${BASE_PATH}_active_clients`}
          label={t(`${BASE_PATH}.activeClients`)}
          activeAmount={activeAmount}
          options={options}
          onOptionClick={handleOptionClick}
        />
      </div>
      <div className="flex items-center justify-end gap-x-4">
        <SearchInput
          dataCy={`${BASE_PATH}_search`}
          value={searchValue}
          onChange={setSearchValue}
          placeholder={t(`${BASE_PATH}.searchClients`)}
        />
        <Sorter
          activeSorter={sorterParameters}
          names={names}
          orders={orders}
          handleSorterClick={onSorterClick}
        />
      </div>
    </div>
  );
};

export default SearchBar;
