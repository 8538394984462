import { AnyAction } from "redux";
import ActionTypes from "./actionTypes";
import { TenantMetadataModel } from "../models/types";

const tenantMetadataReducer = (
  state: TenantMetadataModel = { enabledFeatures: [] },
  action: AnyAction,
): TenantMetadataModel => {
  switch (action.type) {
    case ActionTypes.FETCH_TENANT_METADATA_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_TENANT_METADATA_ERROR:
      return action.payload;
    default:
      return state;
  }
};

export const isTenantMetadataFetchingReducer = (
  state = true,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_TENANT_FEATURE_FLAGS_START:
      return true;
    case ActionTypes.FETCH_TENANT_METADATA_SUCCESS:
    case ActionTypes.FETCH_TENANT_METADATA_ERROR:
      return false;
    default:
      return state;
  }
};

export default tenantMetadataReducer;
