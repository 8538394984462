import { ReactElement, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Label, SearchSelect } from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { OCR } from "../../../shared/data-cy";
import ArticlesForm from "../experience/sections/accomplishment/forms/articlesForm";
import AwardsForm from "../experience/sections/accomplishment/forms/awardsForm";
import CertificatesForm from "../experience/sections/accomplishment/forms/certificatesForm";
import ContributionForm from "../experience/sections/accomplishment/forms/contributionForm";
import TalksForm from "../experience/sections/accomplishment/forms/talksForm";
import TutorForm from "../experience/sections/accomplishment/forms/tutorForm";
import {
  ACCOMPLISHMENTS,
  ARTICLES,
  AWARDS,
  CERTIFICATES,
  CONTRIBUTIONS,
  TALKS,
  TRANSLATION_PATH,
  TUTORS,
} from "./constants";
import { useOCRWizardContext } from "./context";
import {
  AccomplishmentWrapperProps,
  AccomplishmentWrapperState,
  AccomplishmentWrapperStateReturns,
} from "./types";

const AccomplishmentsWrapper = ({
  index = 0,
  isOcr,
}: AccomplishmentWrapperProps): ReactElement => {
  const localTranslationPath = "profile.experience.sections.inner";
  const { t } = useTranslationWithNamespace();

  const mapState = (
    state: AccomplishmentWrapperState,
  ): AccomplishmentWrapperStateReturns => ({
    technologyDict: state.dictionaries.technology,
  });

  const { technologyDict }: AccomplishmentWrapperStateReturns =
    useMappedStateSelector(mapState);

  const { setValue } = useFormContext();

  const { handleOnSetAccomplishmentClick, accomplishmentType, fullTextSearch } =
    useOCRWizardContext();

  const selectOptions = [
    {
      label: t(`${localTranslationPath}.articles`),
      value: ARTICLES,
    },
    {
      label: t(`${localTranslationPath}.awards`),
      value: AWARDS,
    },
    {
      label: t(`${localTranslationPath}.certificates`),
      value: CERTIFICATES,
    },
    {
      label: t(`${localTranslationPath}.contributions`),
      value: CONTRIBUTIONS,
    },
    {
      label: t(`${localTranslationPath}.talks`),
      value: TALKS,
    },
    {
      label: t(`${localTranslationPath}.tutors`),
      value: TUTORS,
    },
  ];

  useEffect(() => {
    setValue(
      `accomplishments.${index}.accomplishmentType`,
      accomplishmentType?.value,
    );
  }, [index]);

  const getProperForm = (formName: string): unknown => {
    switch (formName) {
      case AWARDS:
        return AwardsForm;
      case CERTIFICATES:
        return CertificatesForm;
      case CONTRIBUTIONS:
        return ContributionForm;
      case TALKS:
        return TalksForm;
      case TUTORS:
        return TutorForm;
      default:
        return ArticlesForm;
    }
  };

  const AccomplishmentForm = getProperForm(accomplishmentType?.value);

  return (
    <div className="flex flex-col gap-6">
      <Label dataCy={OCR} text={t(`${TRANSLATION_PATH}.addSection`)}>
        <SearchSelect
          dataCy={`accomplishments-${OCR}`}
          defaultValue={selectOptions[index]}
          onChange={handleOnSetAccomplishmentClick}
          options={selectOptions}
          searchable={false}
        />
      </Label>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <AccomplishmentForm
        fullTextSearch={fullTextSearch}
        index={index}
        isOcr={isOcr}
        sourcePath={ACCOMPLISHMENTS}
        technologyDict={technologyDict}
      />
    </div>
  );
};

export default AccomplishmentsWrapper;
