import { ReactElement, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { useFormContext } from "react-hook-form";
import { classJoin } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import { TableTab, Notification, useThemeMode } from "@ps/ui-components";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../../hooks";
import { toggleEditMode } from "../../../../store";
import { HolidaysTemplateModel } from "../../../models";
import { fetchHolidaysTemplates } from "../../../store";
import HolidaysTemplateService from "../../../services/holidays-templates";
import {
  BASE_TRANSLATION_PATH,
  TEMPLATES_LIST,
  HOLIDAYS_TEMPLATES,
  TEMPLATE,
  TEMP_TEMPLATE_ID,
} from "../../constants";
import { useHolidaysTemplatesContext } from "../../context";
import { DeleteModal } from "../../modals";
import {
  TemplatesListProps,
  TemplatesListMapState,
  TemplatesListMapStateReturn,
  DeleteModalState,
} from "./types";
import TemplateRow from "./templateRow";

const TemplatesList = ({
  holidaysTemplates,
  deleteNewTemplate,
}: TemplatesListProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();
  const { reset } = useFormContext();
  const dispatch = useDispatch();
  const { currentTemplate, setCurrentTemplate } = useHolidaysTemplatesContext();

  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState<DeleteModalState>({
    id: "",
    name: "",
  });

  const mapState = (
    state: TemplatesListMapState,
  ): TemplatesListMapStateReturn => ({
    editMode: state?.editMode,
  });

  const { editMode } = useMappedStateSelector(mapState);

  const handleDeleteTemplate = async (): Promise<void> => {
    const result = await HolidaysTemplateService.deleteHolidaysTemplateById(
      deleteModalOpened.id,
    );

    const isStatusValid = !isAPIError(result);
    if (isStatusValid) {
      if (editMode) dispatch(toggleEditMode(""));
      await fetchHolidaysTemplates(dispatch);
    }
  };

  const checkDeleteTemplate = (
    templateToDelete: HolidaysTemplateModel,
  ): void => {
    if (editMode === `${TEMPLATE}:${TEMP_TEMPLATE_ID}`) {
      deleteNewTemplate();
      return;
    }

    const isNotDeletingTemplateEmpty =
      (holidaysTemplates?.find(
        (template: HolidaysTemplateModel) =>
          template?.id === templateToDelete.id,
      )?.holidays.length || 0) <= 1;
    if (isNotDeletingTemplateEmpty) {
      setDeleteModalOpened({
        id: templateToDelete.id,
        name: templateToDelete.name,
      });
      return;
    }
    setIsNotificationVisible(true);
    setTimeout(() => setIsNotificationVisible(false), 5000);
  };

  return (
    <section className="flex gap-1 h-full w-80">
      {isNotificationVisible && (
        <div className="absolute w-1/3 right-10 top-28 z-10">
          <Notification
            dataCy={`${HOLIDAYS_TEMPLATES}_${TEMPLATE}_cant_remove`}
            description={t(
              `${BASE_TRANSLATION_PATH}.errorNotification.cantRemove`,
            )}
            id=""
            onClose={() => setIsNotificationVisible(false)}
            title={t(`${BASE_TRANSLATION_PATH}.errorNotification.error`)}
            variant="error"
          />
        </div>
      )}
      <div className="h-full w-full overflow-x-auto">
        {holidaysTemplates.map(
          (template: HolidaysTemplateModel): ReactElement => {
            const isCurrentTemplate = currentTemplate?.id === template.id;
            const isEditing = editMode === `${TEMPLATE}:${template.id}`;
            const disabled = !!editMode && !isEditing;

            return (
              <TableTab
                key={template.id}
                dataCy={`${HOLIDAYS_TEMPLATES}_${TEMPLATE}`}
                isEditing={isEditing}
                disabled={disabled}
                additionalClass={classJoin(
                  "font-semibold box-border",
                  isHighContrast && isCurrentTemplate
                    ? "bg-primary-50 bg-opacity-20 text-neutralSecondary-20"
                    : "text-primary-50",
                  isCurrentTemplate
                    ? "bg-primary-50 text-primary-100"
                    : "bg-neutralPrimary-100 text-neutralPrimary-20",
                )}
                isCurrentTab={isCurrentTemplate}
                onClick={(): void =>
                  isCurrentTemplate ? undefined : setCurrentTemplate(template)
                }
                editButton={{
                  onClick: (): void => {
                    dispatch(toggleEditMode(`${TEMPLATE}:${template.id}`));
                    reset({ template });
                  },
                  variant: "secondary",
                  additionalClass: "bg-primary-100",
                }}
                deleteButton={{
                  disabled,
                  onClick: (): void => checkDeleteTemplate(template),
                  variant: "secondary",
                  additionalClass: "bg-primary-100",
                }}
                cancelButton={{
                  onClick: (): void => deleteNewTemplate(),
                  variant: "secondary",
                  additionalClass: "bg-primary-100",
                }}
              >
                <TemplateRow template={template} />
              </TableTab>
            );
          },
        )}
      </div>
      <DeleteModal
        isOpen={!!deleteModalOpened.id}
        onDelete={handleDeleteTemplate}
        onCancel={() => setDeleteModalOpened({ id: "", name: "" })}
        modalInfo={{
          type: t(`${BASE_TRANSLATION_PATH}.${TEMPLATES_LIST}.template`),
          name: deleteModalOpened?.name,
        }}
      />
    </section>
  );
};

export default TemplatesList;
