import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { ReactComponent as XIcon } from "../../images/x-icon.svg";
import { SelectedSkillProps } from "./types";

const SelectedSkill = ({
  name,
  onClick,
  colourClass = "bg-primary-50 text-primary-100",
  colourRemoveClass = "bg-primary-70",
  Icon,
  height = "h-6",
}: SelectedSkillProps): ReactElement => (
  <div
    className={classJoin(
      colourClass,
      "flex items-center w-max rounded-md capitalize leading-6",
      height,
    )}
  >
    <span className="px-2 py-0.5">{name}</span>
    {Icon || (
      <button
        className={classJoin(
          colourRemoveClass,
          "w-8.5 rounded-r-md hover:opacity-75 cursor-pointer h-full",
          height,
        )}
        onClick={onClick}
        tabIndex={0}
        type="button"
      >
        <XIcon className="h-6 w-5 p-1 fill-current" />
      </button>
    )}
  </div>
);

export default SelectedSkill;
