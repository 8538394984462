import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { ReactComponent as SearchingWomen } from "../../../../../../../time-tracker/src/images/reports/searching.svg";
import { EmptySpecialistsProps } from "./types";

const TRANSLATION_PATH = "projects.prospect.proposalFolder";

const EmptySpecialists = ({
  searchValue,
}: EmptySpecialistsProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <div className="p-10 w-full h-full flex gap-10 justify-center items-center select-none">
      <div className="w-1/2 flex items-center justify-end">
        <SearchingWomen className="w-56" />
      </div>
      <div className="h-full w-1/2 justify-start flex flex-col gap-y-6 items-center text-neutralPrimary-20">
        <div className="flex justify-center items-start h-full w-full flex-col">
          <h3 className="w-full text-xl">
            {searchValue
              ? t(`${TRANSLATION_PATH}.noSearchResults`, {
                  searchValue:
                    searchValue.length > 10
                      ? searchValue.substring(0, 10).concat("...")
                      : searchValue,
                })
              : t(`${TRANSLATION_PATH}.noResults`)}
          </h3>
          {!searchValue && (
            <div className="flex gap-x-2">
              <span className="text-neutralPrimary-30">
                {t(`${TRANSLATION_PATH}.tryAgain`)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmptySpecialists;
