import { ReactElement } from "react";
import { uid } from "react-uid";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { useTranslationWithNamespace } from "../../../hooks";

const styles = StyleSheet.create({
  container: {
    fontSize: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  text: { color: "#7788E3", marginRight: "3px", width: "4px" },
});

const SkillsLegend = (): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <View>
      {Array(5)
        .fill(0)
        .map(
          (_, index: number): JSX.Element => (
            <View style={styles.container} key={uid(index)}>
              <Text style={styles.text}>{index + 1}</Text>
              <Text>{`- ${t(`resume.skillsLegend.level${index + 1}`)}`}</Text>
            </View>
          ),
        )}
    </View>
  );
};

export default SkillsLegend;
