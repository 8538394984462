import { ReactElement } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "../../keycloak";
import setAPIToken from "./setAPIToken";

const KeycloakProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}): ReactElement => (
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ onLoad: "login-required" }}
    onTokens={({ token = "" }) => {
      setAPIToken(token);
      localStorage.setItem("token", token);
    }}
  >
    {children}
  </ReactKeycloakProvider>
);

export default KeycloakProvider;
