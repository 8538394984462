import { ReactElement, useRef } from "react";
import Tippy from "@tippyjs/react/headless";
import { classJoin } from "@ps/utils";
import { motion, useSpring } from "framer-motion";
import styles from "./styles.module.css";
import { useThemeMode, Theme } from "../theme-mode-provider";
import { Placement, TooltipProps } from "./types";
import getScrollableParent from "./getScrollableParent";

const arrowPosition = (placement?: Placement): string => {
  if (placement?.startsWith("top")) return styles.arrow_top;
  if (placement?.startsWith("bottom")) return styles.arrow_bottom;
  if (placement?.startsWith("left")) return styles.arrow_left;
  if (placement?.startsWith("right")) return styles.arrow_right;
  return styles.arrow_top;
};

const offsetFromPlacement = (
  placement?: Placement,
  verticalOffset?: number,
): number => {
  if (placement?.startsWith("top")) return verticalOffset || 20;
  if (placement?.startsWith("bottom")) return 20;
  return 10;
};

const Tooltip = ({
  placement = "auto",
  isInteractive,
  children,
  content,
  hideOnClick = false,
  additionalClassName,
  additionalChildrenClassName,
  backgroundColor,
  textColor,
  horizontalOffset = 0,
  verticalOffset = 20,
  padding = "px-2 py-1",
  isDisabled = false,
}: TooltipProps): ReactElement => {
  const { ThemeModeWrapper, theme } = useThemeMode();
  const spanRef = useRef<HTMLSpanElement>(null);
  const springConfig = { damping: 15, stiffness: 300 };
  const initialScale = 0.5;
  const opacity = useSpring(0, springConfig);
  const scale = useSpring(initialScale, springConfig);

  const onMount = (): void => {
    scale.set(1);
    opacity.set(1);
  };

  const onHide = ({ unmount }: { unmount: () => void }): void => {
    const cleanup = scale.onChange((value) => {
      if (value <= initialScale) {
        cleanup();
        unmount();
      }
    });
    scale.set(initialScale);
    opacity.set(0);
  };

  const getTooltipTextColor = (): string => {
    if (textColor) return `text-${textColor}`;
    if (theme === Theme.HIGH_CONTRAST) return "text-primary-30";
    return "text-neutralPrimary-20";
  };

  return (
    <Tippy
      offset={({ placement: tippyPlacement }) => [
        horizontalOffset,
        offsetFromPlacement(tippyPlacement, verticalOffset),
      ]}
      render={(attrs) => (
        <ThemeModeWrapper>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <motion.div
            className={classJoin(
              "rounded-md",
              styles.tooltip,
              padding,
              additionalClassName,
              getTooltipTextColor(),
              `bg-${backgroundColor || "neutralPrimary-85"}`,
            )}
            style={{ scale, opacity }}
            {...attrs}
            role="tooltip"
          >
            {content}
            <div
              className={classJoin(
                styles.arrow,
                arrowPosition(attrs["data-placement"]),
              )}
              data-popper-arrow
            />
          </motion.div>
        </ThemeModeWrapper>
      )}
      animation
      onMount={onMount}
      onHide={onHide}
      placement={placement}
      hideOnClick={hideOnClick}
      interactive={isInteractive}
      disabled={isDisabled}
      appendTo={() => getScrollableParent(spanRef.current)}
    >
      <div ref={spanRef} className={additionalChildrenClassName}>
        {children}
      </div>
    </Tippy>
  );
};

export default Tooltip;
