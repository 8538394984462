import dayjs from "dayjs";
import { AVAILABLE, BUSY } from "../constants";
import { BenchUserModel } from "../models";
import { BenchCheckers } from "../ui/types";

const statusFilters = {
  [AVAILABLE]: (benchUser: BenchUserModel) => benchUser?.availability !== "",
  [BUSY]: (benchUser: BenchUserModel) => benchUser?.availability === "",
};

const filterBenchUsersByStatus = (
  users: BenchUserModel[],
  benchCheckers: BenchCheckers,
) =>
  users.filter((user: BenchUserModel) =>
    Object.entries(benchCheckers)
      .filter(([, value]) => value)
      .map(([key]) => statusFilters[key])
      .some((filterFn: (user: BenchUserModel) => void) => filterFn(user)),
  );

export default filterBenchUsersByStatus;
