import { Card } from "@ps/ui-components";
import { ReactElement } from "react";
import { uid } from "react-uid";

const Tiles = ({ amount = 5 }): ReactElement => (
  <div className="flex items-center gap-2">
    {[...Array(amount)].map((_, index) => (
      <div
        key={uid(index)}
        className="h-10 w-10 rounded-xl bg-neutralPrimary-85"
      />
    ))}
  </div>
);

const TilesWithTextArea = ({ amount = 5 }): ReactElement => (
  <div className="flex flex-col gap-4 w-96">
    <Tiles amount={amount} />
    <div className="bg-neutralPrimary-85 w-full h-20 rounded-xl" />
  </div>
);

const Buttons = (): ReactElement => (
  <div className="flex items-center gap-4">
    <div className="bg-primary-50 h-12 w-28 rounded" />
    <div className="bg-neutralPrimary-85 h-12 w-28 rounded" />
  </div>
);

const LoadingPage = (): ReactElement => {
  return (
    <section className="flex flex-col gap-4">
      <div className="w-98 h-4 rounded-b-full" />
      <Card
        additionalClassName=" w-full"
        additionalCardClassName="flex flex-col gap-8 px-8 py-12"
      >
        <div className="flex flex-row items-end gap-4">
          <div className="flex flex-col gap-3 pr-32">
            <div className="bg-neutralSecondary-60 w-2/6 h-3 rounded-full" />
            <div className="bg-neutralSecondary-60 w-3/6 h-3 rounded-full" />
            <Tiles />
          </div>
          <div className="flex flex-col gap-3">
            <div className="bg-neutralSecondary-60 w-3/6 h-3 rounded-full" />
            <Tiles />
          </div>
        </div>

        <hr />

        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-8 pr-32">
            <div className="bg-neutralSecondary-60 w-1/12 h-3 rounded-full" />
            <div className="bg-neutralSecondary-60 w-3/12 h-3 rounded-full" />
            <div className="flex gap-16 flex-wrap">
              {[
                ...Array(5)
                  .fill(0)
                  .map((_, index) => <TilesWithTextArea key={uid(index)} />),
              ]}
            </div>
          </div>
        </div>
        <Buttons />
      </Card>

      <Card
        additionalClassName=" w-full"
        additionalCardClassName="flex flex-col gap-8 px-8 py-12"
      >
        <div className="flex flex-row items-end gap-4">
          <div className="flex flex-col gap-3 pr-32">
            <div className="bg-neutralSecondary-60 w-2/6 h-3 rounded-full" />
            <div className="bg-neutralSecondary-60 w-3/6 h-3 rounded-full" />
            <Tiles amount={3} />
          </div>
          <div className="flex flex-col gap-3">
            <div className="bg-neutralSecondary-60 w-3/6 h-3 rounded-full" />
            <Tiles amount={3} />
          </div>
        </div>

        <hr />

        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-8 pr-32">
            <div className="bg-neutralSecondary-60 w-1/12 h-3 rounded-full" />
            <div className="bg-neutralSecondary-60 w-3/12 h-3 rounded-full" />
            <div className="flex gap-16 flex-wrap">
              {[
                ...Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <TilesWithTextArea key={uid(index)} amount={3} />
                  )),
              ]}
            </div>
          </div>
        </div>
        <Buttons />
      </Card>
    </section>
  );
};

export default LoadingPage;
