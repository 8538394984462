import { useState, useEffect, ReactElement } from "react";
import { useDispatch } from "redux-react-hook";
import {
  Card,
  Chip,
  Empty,
  Label,
  RestrictedWrapper,
  SearchSelect,
  useThemeMode,
} from "@ps/ui-components";
import {
  classJoin,
  useMappedStateSelector,
  prepareDictionarySearchSelect,
} from "@ps/utils";
import { hhPermissions, UserService, fetchUsers } from "@ps/hh";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { setRedirection, clearRedirection } from "@ps/alert-modal";
import {
  ACCESS_GROUP,
  EMAIL,
  NAME,
  STATUS,
  ACTIVE,
  ARCHIVED,
} from "../../shared/constants";
import { MEMBERS } from "../../shared/data-cy";
import { useTranslationWithNamespace } from "../../hooks";
import ActionsPanel from "./actionsPanel";
import { useMembersContext } from "./context";
import ConfirmRemoveModal from "./confirmRemoveModal";
import styles from "./styles.module.css";
import { UserRowProps, UserRowMapState, UserRowMapStateReturn } from "./types";

const UserRow = ({ data }: UserRowProps): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();
  const { isEdit } = useMembersContext();
  const [isRemoveModalVisible, setIsRemoveModalVisible] =
    useState<boolean>(false);

  const mapState = (state: UserRowMapState): UserRowMapStateReturn => ({
    allRoles: state?.allRoles || [],
    users: state?.users || [],
  });

  const { allRoles } = useMappedStateSelector(mapState, "hh");
  const methods = useForm();
  const { isDirty } = methods.formState;

  useEffect(() => {
    if (isDirty) dispatch(setRedirection());
  }, [isDirty]);

  const stylesWhenArchived: string = !data?.enabled ? "opacity-40" : "";
  const isEditMode: boolean = isEdit === data?.id;

  const onSubmit = async (updatedData): Promise<void> => {
    const accessGroupIds: string[] = updatedData[ACCESS_GROUP]?.map(
      (item) => item.id,
    );
    if (
      data?.roles?.length === accessGroupIds?.length &&
      !data?.roles?.filter(
        (role): boolean => !accessGroupIds?.includes(role.id),
      ).length
    )
      return;
    const response = await UserService.updateUser(data.id, accessGroupIds);
    if (!response) return;
    dispatch(clearRedirection());
    await fetchUsers(dispatch);
  };

  const onArchive = async (): Promise<void> => {
    const response = await UserService.setUserArchive(data.id, !data.enabled);
    if (!response) return;
    await fetchUsers(dispatch);
  };

  const onRemove = async (): Promise<void> => {
    dispatch(clearRedirection());
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card
          dataCy={`${MEMBERS}_user`}
          additionalCardClassName={classJoin(
            styles.gridContainer,
            "py-8",
            !data?.enabled ? "bg-opacity-40" : "",
            isHighContrast ? "border border-neutralSecondary-40" : "",
          )}
          totalHeight="h-full"
        >
          <Label
            dataCy={`${MEMBERS}_list_${NAME}`}
            additionalClassName={stylesWhenArchived}
          >
            {data?.firstName && data?.secondName ? (
              `${data.firstName} ${data.secondName}`
            ) : (
              <Empty />
            )}
          </Label>
          <Label
            dataCy={`${MEMBERS}_list_${EMAIL}`}
            additionalClassName={stylesWhenArchived}
          >
            {data?.email ? (
              <span className="truncate">{data.email}</span>
            ) : (
              <Empty />
            )}
          </Label>
          <div className="relative h-full self-center">
            {isEditMode ? (
              <RestrictedWrapper
                permissions={hhPermissions.USERS_ASSIGN_ROLES}
                noPermissionsComponent={<div />}
              >
                <Controller
                  render={({ field: { onChange } }) => (
                    <SearchSelect
                      autoFocus
                      dataCy={`${MEMBERS}_${ACCESS_GROUP}`}
                      onChange={onChange}
                      width="w-full"
                      additionalClassName="absolute mr-2"
                      defaultValue={prepareDictionarySearchSelect(
                        data.roles,
                        "id",
                        "name",
                        "name",
                        "name",
                      )}
                      options={prepareDictionarySearchSelect(
                        allRoles,
                        "itemId",
                        "name",
                        "name",
                        "name",
                      )}
                      multiple
                      searchable={false}
                      height="auto"
                      placeholder={t(`${MEMBERS}.${ACCESS_GROUP}`)}
                    />
                  )}
                  name={ACCESS_GROUP}
                  control={methods.control}
                />
              </RestrictedWrapper>
            ) : (
              <Label
                dataCy={`${MEMBERS}_list_${ACCESS_GROUP}`}
                additionalClassName={stylesWhenArchived}
              >
                <div className="flex flex-wrap items-center gap-1">
                  {data?.roles?.length ? (
                    data.roles.map(
                      (item): ReactElement => (
                        <Chip
                          key={`${ACCESS_GROUP}_${item.id}`}
                          dataCy={`${MEMBERS}_list_${ACCESS_GROUP}`}
                          label={item.name}
                        />
                      ),
                    )
                  ) : (
                    <Empty />
                  )}
                </div>
              </Label>
            )}
          </div>
          <Label
            dataCy={`${MEMBERS}_list_${STATUS}`}
            additionalClassName={stylesWhenArchived}
          >
            <span
              className={classJoin(
                "capitalize",
                data?.enabled ? "text-success-60" : "text-error-50",
              )}
            >
              {data.enabled
                ? t(`${MEMBERS}.${ACTIVE}`)
                : t(`${MEMBERS}.${ARCHIVED}`)}
            </span>
          </Label>
          <div className="w-full h-full">
            <ActionsPanel
              userId={data.id}
              onArchiveClick={isEditMode ? undefined : onArchive}
              onRemoveClick={() => setIsRemoveModalVisible(true)}
              isArchived={!data.enabled}
            />
          </div>
        </Card>
      </form>
      <ConfirmRemoveModal
        userName={`${data?.firstName} ${data?.secondName}`}
        isOpen={isRemoveModalVisible}
        onClose={() => setIsRemoveModalVisible(false)}
        onRemove={() => onRemove(data.id)}
      />
    </FormProvider>
  );
};

export default UserRow;
