import { Store } from "redux";
import { getAxiosConfig, getAxiosInstance } from "@ps/api-utils";
import { DefaultApi } from "./openapi-client";

class APIService {
  public API: DefaultApi;

  private token?: string;

  private store?: Store;

  constructor() {
    const baseConfig = getAxiosConfig();
    this.API = new DefaultApi(baseConfig);
  }

  private setApi() {
    let axiosInstance;
    const baseConfig = getAxiosConfig();
    if (this.store) {
      axiosInstance = getAxiosInstance(this.store);
    }

    this.API = new DefaultApi(
      {
        ...baseConfig,
        accessToken: this.token,
      },
      undefined,
      axiosInstance,
    );
  }

  public setConfig(token: string) {
    this.token = token;
    this.setApi();
  }

  public setStore(store: Store) {
    this.store = store;
    this.setApi();
  }
}

export default new APIService();
