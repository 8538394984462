/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Prospect API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddMember
 */
export interface AddMember {
    /**
     * 
     * @type {string}
     * @memberof AddMember
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface ArchiveFlag
 */
export interface ArchiveFlag {
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveFlag
     */
    'archived': boolean;
}
/**
 * 
 * @export
 * @interface Author
 */
export interface Author {
    /**
     * 
     * @type {string}
     * @memberof Author
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Author
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Author
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface CreateNeed
 */
export interface CreateNeed {
    /**
     * 
     * @type {string}
     * @memberof CreateNeed
     */
    'profession': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNeed
     */
    'seniority': string;
    /**
     * 
     * @type {number}
     * @memberof CreateNeed
     */
    'quantity': number;
    /**
     * 
     * @type {ExpectedRate}
     * @memberof CreateNeed
     */
    'expectedRate'?: ExpectedRate;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateNeed
     */
    'mustHaveTechStack': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateNeed
     */
    'niceToHaveTechStack': Array<string>;
    /**
     * 
     * @type {WorkingTime}
     * @memberof CreateNeed
     */
    'workingTime': WorkingTime;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateNeed
     */
    'blacklistedCountries': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateNeed
     */
    'expectedLanguage': string;
}
/**
 * 
 * @export
 * @interface CreateNeedResponse
 */
export interface CreateNeedResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateNeedResponse
     */
    'needEntryId': string;
}
/**
 * 
 * @export
 * @interface CreateNote
 */
export interface CreateNote {
    /**
     * 
     * @type {string}
     * @memberof CreateNote
     */
    'title': string;
    /**
     * 
     * @type {Priority}
     * @memberof CreateNote
     */
    'priority'?: Priority;
    /**
     * 
     * @type {string}
     * @memberof CreateNote
     */
    'body': string;
}
/**
 * 
 * @export
 * @interface CreateNoteResponse
 */
export interface CreateNoteResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateNoteResponse
     */
    'noteId': string;
}
/**
 * 
 * @export
 * @interface CreateProspectRequest
 */
export interface CreateProspectRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProspectRequest
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProspectRequest
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProspectRequest
     */
    'pm'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProspectRequest
     */
    'bd'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProspectRequest
     */
    'techOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProspectRequest
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProspectRequest
     */
    'contactPersonName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProspectRequest
     */
    'contactPersonEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProspectRequest
     */
    'projectCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProspectRequest
     */
    'projectCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProspectRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProspectRequest
     */
    'domains': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateProspectResponse
 */
export interface CreateProspectResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateProspectResponse
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface Details
 */
export interface Details {
    /**
     * 
     * @type {string}
     * @memberof Details
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof Details
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof Details
     */
    'clientName': string;
    /**
     * 
     * @type {Operative}
     * @memberof Details
     */
    'pm'?: Operative;
    /**
     * 
     * @type {Operative}
     * @memberof Details
     */
    'bd'?: Operative;
    /**
     * 
     * @type {Operative}
     * @memberof Details
     */
    'techOwner'?: Operative;
    /**
     * 
     * @type {string}
     * @memberof Details
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof Details
     */
    'contactPersonName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Details
     */
    'contactPersonEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Details
     */
    'projectCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof Details
     */
    'projectCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof Details
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Details
     */
    'domains': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EngagementSpan = {
    Full: 'full',
    Part: 'part'
} as const;

export type EngagementSpan = typeof EngagementSpan[keyof typeof EngagementSpan];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface ExpectedRate
 */
export interface ExpectedRate {
    /**
     * 
     * @type {number}
     * @memberof ExpectedRate
     */
    'rate': number;
    /**
     * 
     * @type {RateSpan}
     * @memberof ExpectedRate
     */
    'rateSpan': RateSpan;
    /**
     * 
     * @type {string}
     * @memberof ExpectedRate
     */
    'currency': string;
    /**
     * 
     * @type {EngagementSpan}
     * @memberof ExpectedRate
     */
    'engagementSpan': EngagementSpan;
}
/**
 * 
 * @export
 * @interface GenerateTeamNeed
 */
export interface GenerateTeamNeed {
    /**
     * 
     * @type {string}
     * @memberof GenerateTeamNeed
     */
    'needId': string;
    /**
     * 
     * @type {number}
     * @memberof GenerateTeamNeed
     */
    'slots': number;
}
/**
 * 
 * @export
 * @interface GetExpectedRate
 */
export interface GetExpectedRate {
    /**
     * 
     * @type {number}
     * @memberof GetExpectedRate
     */
    'rate'?: number;
    /**
     * 
     * @type {RateSpan}
     * @memberof GetExpectedRate
     */
    'rateSpan': RateSpan;
    /**
     * 
     * @type {string}
     * @memberof GetExpectedRate
     */
    'currency': string;
    /**
     * 
     * @type {EngagementSpan}
     * @memberof GetExpectedRate
     */
    'engagementSpan': EngagementSpan;
}
/**
 * A time range, start and end formatted as ISO 8601 time as UTC
 * @export
 * @interface HoursRange
 */
export interface HoursRange {
    /**
     * 
     * @type {string}
     * @memberof HoursRange
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof HoursRange
     */
    'end': string;
}
/**
 * 
 * @export
 * @interface Member
 */
export interface Member {
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'avatarUrl'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Member
     */
    'professions': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'seniority'?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'countryOfResidence'?: string;
}
/**
 * 
 * @export
 * @interface NeedEntry
 */
export interface NeedEntry {
    /**
     * 
     * @type {string}
     * @memberof NeedEntry
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NeedEntry
     */
    'profession': string;
    /**
     * 
     * @type {string}
     * @memberof NeedEntry
     */
    'seniority': string;
    /**
     * 
     * @type {number}
     * @memberof NeedEntry
     */
    'quantity': number;
    /**
     * 
     * @type {GetExpectedRate}
     * @memberof NeedEntry
     */
    'expectedRate'?: GetExpectedRate;
    /**
     * 
     * @type {Array<string>}
     * @memberof NeedEntry
     */
    'mustHaveTechStack': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NeedEntry
     */
    'niceToHaveTechStack': Array<string>;
    /**
     * 
     * @type {WorkingTime}
     * @memberof NeedEntry
     */
    'workingTime'?: WorkingTime;
    /**
     * 
     * @type {Array<string>}
     * @memberof NeedEntry
     */
    'blacklistedCountries': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NeedEntry
     */
    'expectedLanguage'?: string;
}
/**
 * 
 * @export
 * @interface Note
 */
export interface Note {
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'title': string;
    /**
     * 
     * @type {Priority}
     * @memberof Note
     */
    'priority'?: Priority;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'body': string;
    /**
     * 
     * @type {Author}
     * @memberof Note
     */
    'author': Author;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'creatorId'?: string;
    /**
     * Creation date and time
     * @type {string}
     * @memberof Note
     */
    'createDate'?: string;
    /**
     * Creation date and time
     * @type {string}
     * @memberof Note
     */
    'updateDate'?: string;
    /**
     * 
     * @type {NoteAccesses}
     * @memberof Note
     */
    'accesses': NoteAccesses;
}
/**
 * 
 * @export
 * @interface NoteAccesses
 */
export interface NoteAccesses {
    /**
     * 
     * @type {boolean}
     * @memberof NoteAccesses
     */
    'update': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NoteAccesses
     */
    'delete': boolean;
}
/**
 * 
 * @export
 * @interface Operative
 */
export interface Operative {
    /**
     * 
     * @type {string}
     * @memberof Operative
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Operative
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Operative
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Priority = {
    High: 'high',
    Medium: 'medium',
    Low: 'low'
} as const;

export type Priority = typeof Priority[keyof typeof Priority];


/**
 * 
 * @export
 * @interface Prospect
 */
export interface Prospect {
    /**
     * 
     * @type {boolean}
     * @memberof Prospect
     */
    'archived': boolean;
    /**
     * 
     * @type {string}
     * @memberof Prospect
     */
    'creationDate'?: string;
    /**
     * 
     * @type {Details}
     * @memberof Prospect
     */
    'details': Details;
    /**
     * 
     * @type {Array<NeedEntry>}
     * @memberof Prospect
     */
    'needs': Array<NeedEntry>;
    /**
     * 
     * @type {Array<Note>}
     * @memberof Prospect
     */
    'notes': Array<Note>;
    /**
     * 
     * @type {Array<Member>}
     * @memberof Prospect
     */
    'discoveryTeam': Array<Member>;
    /**
     * 
     * @type {string}
     * @memberof Prospect
     */
    'folder': string;
    /**
     * 
     * @type {ProspectAccesses}
     * @memberof Prospect
     */
    'accesses': ProspectAccesses;
}
/**
 * 
 * @export
 * @interface ProspectAccesses
 */
export interface ProspectAccesses {
    /**
     * 
     * @type {boolean}
     * @memberof ProspectAccesses
     */
    'addNotes': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProspectAccesses
     */
    'manageNeeds': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProspectAccesses
     */
    'manageDiscoveryTeam': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProspectAccesses
     */
    'update': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProspectAccesses
     */
    'archive': boolean;
}
/**
 * 
 * @export
 * @interface ProspectSummary
 */
export interface ProspectSummary {
    /**
     * 
     * @type {string}
     * @memberof ProspectSummary
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProspectSummary
     */
    'archived': boolean;
    /**
     * 
     * @type {Details}
     * @memberof ProspectSummary
     */
    'details': Details;
    /**
     * 
     * @type {string}
     * @memberof ProspectSummary
     */
    'folder': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectSummary
     */
    'creationDate'?: string;
    /**
     * 
     * @type {ProspectSummaryAccesses}
     * @memberof ProspectSummary
     */
    'accesses': ProspectSummaryAccesses;
}
/**
 * 
 * @export
 * @interface ProspectSummaryAccesses
 */
export interface ProspectSummaryAccesses {
    /**
     * 
     * @type {boolean}
     * @memberof ProspectSummaryAccesses
     */
    'fetch': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProspectSummaryAccesses
     */
    'update': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProspectSummaryAccesses
     */
    'archive': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RateSpan = {
    Hour: 'hour',
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Year: 'year'
} as const;

export type RateSpan = typeof RateSpan[keyof typeof RateSpan];


/**
 * 
 * @export
 * @interface TeamNeedResult
 */
export interface TeamNeedResult {
    /**
     * 
     * @type {string}
     * @memberof TeamNeedResult
     */
    'needId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeamNeedResult
     */
    'employeesIds': Array<string>;
}
/**
 * 
 * @export
 * @interface TeamResult
 */
export interface TeamResult {
    /**
     * 
     * @type {Array<TeamNeedResult>}
     * @memberof TeamResult
     */
    'needs': Array<TeamNeedResult>;
    /**
     * 
     * @type {TeamResultScores}
     * @memberof TeamResult
     */
    'scores': TeamResultScores;
}
/**
 * 
 * @export
 * @interface TeamResultScores
 */
export interface TeamResultScores {
    /**
     * 
     * @type {number}
     * @memberof TeamResultScores
     */
    'skill': number;
    /**
     * 
     * @type {number}
     * @memberof TeamResultScores
     */
    'enjoyment': number;
    /**
     * 
     * @type {number}
     * @memberof TeamResultScores
     */
    'languageAdaptability': number;
    /**
     * 
     * @type {number}
     * @memberof TeamResultScores
     */
    'timeAdaptability': number;
    /**
     * 
     * @type {number}
     * @memberof TeamResultScores
     */
    'seniority': number;
    /**
     * 
     * @type {number}
     * @memberof TeamResultScores
     */
    'cost': number;
}
/**
 * 
 * @export
 * @interface UpdateNeed
 */
export interface UpdateNeed {
    /**
     * 
     * @type {string}
     * @memberof UpdateNeed
     */
    'profession': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNeed
     */
    'seniority': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateNeed
     */
    'quantity': number;
    /**
     * 
     * @type {ExpectedRate}
     * @memberof UpdateNeed
     */
    'expectedRate'?: ExpectedRate;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateNeed
     */
    'mustHaveTechStack': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateNeed
     */
    'niceToHaveTechStack': Array<string>;
    /**
     * 
     * @type {WorkingTime}
     * @memberof UpdateNeed
     */
    'workingTime': WorkingTime;
    /**
     * 
     * @type {number}
     * @memberof UpdateNeed
     */
    'timeZone'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateNeed
     */
    'blacklistedCountries': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateNeed
     */
    'expectedLanguage': string;
}
/**
 * 
 * @export
 * @interface UpdateNote
 */
export interface UpdateNote {
    /**
     * 
     * @type {string}
     * @memberof UpdateNote
     */
    'title': string;
    /**
     * 
     * @type {Priority}
     * @memberof UpdateNote
     */
    'priority'?: Priority;
    /**
     * 
     * @type {string}
     * @memberof UpdateNote
     */
    'body': string;
}
/**
 * 
 * @export
 * @interface UpdateProspectRequest
 */
export interface UpdateProspectRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProspectRequest
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProspectRequest
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProspectRequest
     */
    'pm'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProspectRequest
     */
    'bd'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProspectRequest
     */
    'techOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProspectRequest
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProspectRequest
     */
    'contactPersonName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProspectRequest
     */
    'contactPersonEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProspectRequest
     */
    'projectCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProspectRequest
     */
    'projectCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProspectRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProspectRequest
     */
    'domains': Array<string>;
}
/**
 * 
 * @export
 * @interface WorkingTime
 */
export interface WorkingTime {
    /**
     * 
     * @type {HoursRange}
     * @memberof WorkingTime
     */
    'coreTime': HoursRange;
    /**
     * 
     * @type {number}
     * @memberof WorkingTime
     */
    'overlap'?: number;
    /**
     * A time-zone ID eg. \'Europe/Paris\'
     * @type {string}
     * @memberof WorkingTime
     */
    'zone': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a Need for a Prospect
         * @param {string} prospectId 
         * @param {CreateNeed} createNeed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNeed: async (prospectId: string, createNeed: CreateNeed, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('addNeed', 'prospectId', prospectId)
            // verify required parameter 'createNeed' is not null or undefined
            assertParamExists('addNeed', 'createNeed', createNeed)
            const localVarPath = `/prospect/prospects/{prospectId}/needs`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNeed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new Note
         * @param {string} prospectId 
         * @param {CreateNote} createNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNote: async (prospectId: string, createNote: CreateNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('addNote', 'prospectId', prospectId)
            // verify required parameter 'createNote' is not null or undefined
            assertParamExists('addNote', 'createNote', createNote)
            const localVarPath = `/prospect/prospects/{prospectId}/notes`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new Prospect
         * @param {CreateProspectRequest} createProspectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProspect: async (createProspectRequest: CreateProspectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProspectRequest' is not null or undefined
            assertParamExists('addProspect', 'createProspectRequest', createProspectRequest)
            const localVarPath = `/prospect/prospects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProspectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Discovery Team Member
         * @param {string} prospectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiscoveryTeamMember: async (prospectId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('deleteDiscoveryTeamMember', 'prospectId', prospectId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteDiscoveryTeamMember', 'userId', userId)
            const localVarPath = `/prospect/prospects/{prospectId}/discovery_team/{userId}`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Need from Prospect
         * @param {string} prospectId 
         * @param {string} needId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNeed: async (prospectId: string, needId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('deleteNeed', 'prospectId', prospectId)
            // verify required parameter 'needId' is not null or undefined
            assertParamExists('deleteNeed', 'needId', needId)
            const localVarPath = `/prospect/prospects/{prospectId}/needs/{needId}`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)))
                .replace(`{${"needId"}}`, encodeURIComponent(String(needId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Note from Prospect
         * @param {string} prospectId 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote: async (prospectId: string, noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('deleteNote', 'prospectId', prospectId)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('deleteNote', 'noteId', noteId)
            const localVarPath = `/prospect/prospects/{prospectId}/notes/{noteId}`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)))
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate team for needs
         * @param {string} prospectId 
         * @param {Array<GenerateTeamNeed>} generateTeamNeed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTeam: async (prospectId: string, generateTeamNeed: Array<GenerateTeamNeed>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('generateTeam', 'prospectId', prospectId)
            // verify required parameter 'generateTeamNeed' is not null or undefined
            assertParamExists('generateTeam', 'generateTeamNeed', generateTeamNeed)
            const localVarPath = `/prospect/prospects/{prospectId}/teamgen`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateTeamNeed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Prospect by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProspect: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProspect', 'id', id)
            const localVarPath = `/prospect/prospects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of prospects
         * @param {string} [clientId] ClientId assigned to the prospect
         * @param {boolean} [archived] Archived flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProspects: async (clientId?: string, archived?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/prospect/prospects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set Archived Flag of Prospect
         * @param {string} id 
         * @param {ArchiveFlag} archiveFlag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArchived: async (id: string, archiveFlag: ArchiveFlag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setArchived', 'id', id)
            // verify required parameter 'archiveFlag' is not null or undefined
            assertParamExists('setArchived', 'archiveFlag', archiveFlag)
            const localVarPath = `/prospect/prospects/{id}/archived`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archiveFlag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Need
         * @param {string} prospectId 
         * @param {string} needId 
         * @param {UpdateNeed} updateNeed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNeed: async (prospectId: string, needId: string, updateNeed: UpdateNeed, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('updateNeed', 'prospectId', prospectId)
            // verify required parameter 'needId' is not null or undefined
            assertParamExists('updateNeed', 'needId', needId)
            // verify required parameter 'updateNeed' is not null or undefined
            assertParamExists('updateNeed', 'updateNeed', updateNeed)
            const localVarPath = `/prospect/prospects/{prospectId}/needs/{needId}`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)))
                .replace(`{${"needId"}}`, encodeURIComponent(String(needId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNeed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Note
         * @param {string} prospectId 
         * @param {string} noteId 
         * @param {UpdateNote} updateNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: async (prospectId: string, noteId: string, updateNote: UpdateNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('updateNote', 'prospectId', prospectId)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('updateNote', 'noteId', noteId)
            // verify required parameter 'updateNote' is not null or undefined
            assertParamExists('updateNote', 'updateNote', updateNote)
            const localVarPath = `/prospect/prospects/{prospectId}/notes/{noteId}`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)))
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update basic info (Details) about Prospect
         * @param {string} id 
         * @param {UpdateProspectRequest} updateProspectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProspect: async (id: string, updateProspectRequest: UpdateProspectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProspect', 'id', id)
            // verify required parameter 'updateProspectRequest' is not null or undefined
            assertParamExists('updateProspect', 'updateProspectRequest', updateProspectRequest)
            const localVarPath = `/prospect/prospects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProspectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upsert Discovery Team Member
         * @param {string} prospectId 
         * @param {AddMember} addMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertDiscoveryTeamMember: async (prospectId: string, addMember: AddMember, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('upsertDiscoveryTeamMember', 'prospectId', prospectId)
            // verify required parameter 'addMember' is not null or undefined
            assertParamExists('upsertDiscoveryTeamMember', 'addMember', addMember)
            const localVarPath = `/prospect/prospects/{prospectId}/discovery_team`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a Need for a Prospect
         * @param {string} prospectId 
         * @param {CreateNeed} createNeed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNeed(prospectId: string, createNeed: CreateNeed, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNeedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNeed(prospectId, createNeed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new Note
         * @param {string} prospectId 
         * @param {CreateNote} createNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNote(prospectId: string, createNote: CreateNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNote(prospectId, createNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new Prospect
         * @param {CreateProspectRequest} createProspectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProspect(createProspectRequest: CreateProspectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProspectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProspect(createProspectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Discovery Team Member
         * @param {string} prospectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDiscoveryTeamMember(prospectId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDiscoveryTeamMember(prospectId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Need from Prospect
         * @param {string} prospectId 
         * @param {string} needId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNeed(prospectId: string, needId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNeed(prospectId, needId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Note from Prospect
         * @param {string} prospectId 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNote(prospectId: string, noteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNote(prospectId, noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate team for needs
         * @param {string} prospectId 
         * @param {Array<GenerateTeamNeed>} generateTeamNeed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTeam(prospectId: string, generateTeamNeed: Array<GenerateTeamNeed>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTeam(prospectId, generateTeamNeed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Prospect by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProspect(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Prospect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProspect(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of prospects
         * @param {string} [clientId] ClientId assigned to the prospect
         * @param {boolean} [archived] Archived flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProspects(clientId?: string, archived?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProspectSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProspects(clientId, archived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set Archived Flag of Prospect
         * @param {string} id 
         * @param {ArchiveFlag} archiveFlag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setArchived(id: string, archiveFlag: ArchiveFlag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setArchived(id, archiveFlag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Need
         * @param {string} prospectId 
         * @param {string} needId 
         * @param {UpdateNeed} updateNeed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNeed(prospectId: string, needId: string, updateNeed: UpdateNeed, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNeed(prospectId, needId, updateNeed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Note
         * @param {string} prospectId 
         * @param {string} noteId 
         * @param {UpdateNote} updateNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNote(prospectId: string, noteId: string, updateNote: UpdateNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNote(prospectId, noteId, updateNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update basic info (Details) about Prospect
         * @param {string} id 
         * @param {UpdateProspectRequest} updateProspectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProspect(id: string, updateProspectRequest: UpdateProspectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProspect(id, updateProspectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upsert Discovery Team Member
         * @param {string} prospectId 
         * @param {AddMember} addMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertDiscoveryTeamMember(prospectId: string, addMember: AddMember, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertDiscoveryTeamMember(prospectId, addMember, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Create a Need for a Prospect
         * @param {string} prospectId 
         * @param {CreateNeed} createNeed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNeed(prospectId: string, createNeed: CreateNeed, options?: any): AxiosPromise<CreateNeedResponse> {
            return localVarFp.addNeed(prospectId, createNeed, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new Note
         * @param {string} prospectId 
         * @param {CreateNote} createNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNote(prospectId: string, createNote: CreateNote, options?: any): AxiosPromise<CreateNoteResponse> {
            return localVarFp.addNote(prospectId, createNote, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new Prospect
         * @param {CreateProspectRequest} createProspectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProspect(createProspectRequest: CreateProspectRequest, options?: any): AxiosPromise<CreateProspectResponse> {
            return localVarFp.addProspect(createProspectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Discovery Team Member
         * @param {string} prospectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiscoveryTeamMember(prospectId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDiscoveryTeamMember(prospectId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Need from Prospect
         * @param {string} prospectId 
         * @param {string} needId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNeed(prospectId: string, needId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNeed(prospectId, needId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Note from Prospect
         * @param {string} prospectId 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote(prospectId: string, noteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNote(prospectId, noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate team for needs
         * @param {string} prospectId 
         * @param {Array<GenerateTeamNeed>} generateTeamNeed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTeam(prospectId: string, generateTeamNeed: Array<GenerateTeamNeed>, options?: any): AxiosPromise<Array<TeamResult>> {
            return localVarFp.generateTeam(prospectId, generateTeamNeed, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Prospect by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProspect(id: string, options?: any): AxiosPromise<Prospect> {
            return localVarFp.getProspect(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of prospects
         * @param {string} [clientId] ClientId assigned to the prospect
         * @param {boolean} [archived] Archived flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProspects(clientId?: string, archived?: boolean, options?: any): AxiosPromise<Array<ProspectSummary>> {
            return localVarFp.getProspects(clientId, archived, options).then((request) => request(axios, basePath));
        },
        /**
         * Set Archived Flag of Prospect
         * @param {string} id 
         * @param {ArchiveFlag} archiveFlag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArchived(id: string, archiveFlag: ArchiveFlag, options?: any): AxiosPromise<void> {
            return localVarFp.setArchived(id, archiveFlag, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Need
         * @param {string} prospectId 
         * @param {string} needId 
         * @param {UpdateNeed} updateNeed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNeed(prospectId: string, needId: string, updateNeed: UpdateNeed, options?: any): AxiosPromise<void> {
            return localVarFp.updateNeed(prospectId, needId, updateNeed, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Note
         * @param {string} prospectId 
         * @param {string} noteId 
         * @param {UpdateNote} updateNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote(prospectId: string, noteId: string, updateNote: UpdateNote, options?: any): AxiosPromise<void> {
            return localVarFp.updateNote(prospectId, noteId, updateNote, options).then((request) => request(axios, basePath));
        },
        /**
         * Update basic info (Details) about Prospect
         * @param {string} id 
         * @param {UpdateProspectRequest} updateProspectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProspect(id: string, updateProspectRequest: UpdateProspectRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateProspect(id, updateProspectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Upsert Discovery Team Member
         * @param {string} prospectId 
         * @param {AddMember} addMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertDiscoveryTeamMember(prospectId: string, addMember: AddMember, options?: any): AxiosPromise<void> {
            return localVarFp.upsertDiscoveryTeamMember(prospectId, addMember, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Create a Need for a Prospect
     * @param {string} prospectId 
     * @param {CreateNeed} createNeed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addNeed(prospectId: string, createNeed: CreateNeed, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addNeed(prospectId, createNeed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new Note
     * @param {string} prospectId 
     * @param {CreateNote} createNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addNote(prospectId: string, createNote: CreateNote, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addNote(prospectId, createNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new Prospect
     * @param {CreateProspectRequest} createProspectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addProspect(createProspectRequest: CreateProspectRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addProspect(createProspectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Discovery Team Member
     * @param {string} prospectId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteDiscoveryTeamMember(prospectId: string, userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteDiscoveryTeamMember(prospectId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Need from Prospect
     * @param {string} prospectId 
     * @param {string} needId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteNeed(prospectId: string, needId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteNeed(prospectId, needId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Note from Prospect
     * @param {string} prospectId 
     * @param {string} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteNote(prospectId: string, noteId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteNote(prospectId, noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate team for needs
     * @param {string} prospectId 
     * @param {Array<GenerateTeamNeed>} generateTeamNeed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateTeam(prospectId: string, generateTeamNeed: Array<GenerateTeamNeed>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).generateTeam(prospectId, generateTeamNeed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Prospect by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProspect(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProspect(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of prospects
     * @param {string} [clientId] ClientId assigned to the prospect
     * @param {boolean} [archived] Archived flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProspects(clientId?: string, archived?: boolean, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProspects(clientId, archived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set Archived Flag of Prospect
     * @param {string} id 
     * @param {ArchiveFlag} archiveFlag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setArchived(id: string, archiveFlag: ArchiveFlag, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setArchived(id, archiveFlag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Need
     * @param {string} prospectId 
     * @param {string} needId 
     * @param {UpdateNeed} updateNeed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateNeed(prospectId: string, needId: string, updateNeed: UpdateNeed, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateNeed(prospectId, needId, updateNeed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Note
     * @param {string} prospectId 
     * @param {string} noteId 
     * @param {UpdateNote} updateNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateNote(prospectId: string, noteId: string, updateNote: UpdateNote, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateNote(prospectId, noteId, updateNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update basic info (Details) about Prospect
     * @param {string} id 
     * @param {UpdateProspectRequest} updateProspectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProspect(id: string, updateProspectRequest: UpdateProspectRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateProspect(id, updateProspectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upsert Discovery Team Member
     * @param {string} prospectId 
     * @param {AddMember} addMember 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public upsertDiscoveryTeamMember(prospectId: string, addMember: AddMember, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).upsertDiscoveryTeamMember(prospectId, addMember, options).then((request) => request(this.axios, this.basePath));
    }
}


