import { ReactElement, useEffect, useState } from "react";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import {
  AmountFilterOption,
  FilterDropdown,
  TableLabel,
} from "@ps/ui-components";
import { IFilterOption, SortBarProps } from "../types";
import styles from "./styles.module.css";
import {
  countOptionFilterAmount,
  getCheckedNames,
  prepareProfessionFilterOptions,
  prepareSeniorityFilterOptions,
  selectFilterOptions,
} from "./utils";
import { useTranslationWithNamespace } from "../../../hooks";
import {
  FINANCES,
  HOURLY_RATE,
  MONTHLY_SALARY,
  NAME,
  PROFESSION,
  SENIORITY,
  USER_NAME,
  STATUS,
} from "./constants";
import { Keys } from "../../../shared";
import { ReactComponent as FaceIcon } from "../../../images/project/face-id.svg";
import { TEAM_LIST } from "../../../shared/data-cy";

const SortBar = ({
  filters,
  users,
  updateFilters,
  professionDict,
  seniorityDict,
}: SortBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const translate = (field: string) => t(`finance.finances.sortBar.${field}`);
  const DROPDOWN_ID = "filter-dropdown";
  const [searchValue, setSearchValue] = useState("");
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [currentIDFocused, setCurrentIDFocused] = useState("");

  useEffect(() => {
    if (showFilterOptions) document.getElementById(DROPDOWN_ID)?.focus();
  }, [showFilterOptions]);

  const professionFiltersOptions = prepareProfessionFilterOptions(
    users,
    professionDict,
  );

  const seniorityFiltersOptions = prepareSeniorityFilterOptions(
    users,
    seniorityDict,
  );

  const renderLabel = (
    filterName: string,
    translationName?: string,
    filterOptions: IFilterOption[] | undefined = undefined,
    withAmount = false,
    nestedName = "",
  ): ReactElement => (
    <TableLabel
      dataCy={translationName || filterName}
      setSearchValue={setSearchValue}
      filtering={
        filterOptions
          ? {
              checked: getCheckedNames(filterOptions, filters, filterName),
              dropdownOverlay: (
                <FilterDropdown
                  dataCy={FINANCES}
                  DROPDOWN_ID={DROPDOWN_ID}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setShowFilterOptions={setShowFilterOptions}
                  filterOptions={filterOptions}
                >
                  {filterOptions.map((item: IFilterOption, index: number) => {
                    const isChecked = filters[filterName]?.some(
                      (itemId: string) => itemId === item.id,
                    );
                    const autoFocusId = `${filterName}-${index}`;

                    return (
                      <AmountFilterOption
                        key={uid(item)}
                        autoFocus={autoFocusId === currentIDFocused}
                        activeNumber={countOptionFilterAmount(
                          item.id,
                          filterName,
                          users,
                          nestedName,
                        )}
                        item={item}
                        isChecked={isChecked}
                        filterName={filterName}
                        withAmount={withAmount}
                        dataCy={TEAM_LIST}
                        onClick={() => {
                          updateFilters(isChecked, item.id, filterName);

                          setCurrentIDFocused(autoFocusId);
                        }}
                        onKeyDown={() => {
                          if (e.key === Keys.SPACE) {
                            e.preventDefault();
                            updateFilters(isChecked, item.id, filterName);

                            setCurrentIDFocused(autoFocusId);
                          }
                        }}
                      />
                    );
                  })}
                </FilterDropdown>
              ),
            }
          : undefined
      }
    >
      {translate(translationName || filterName)}
    </TableLabel>
  );

  return (
    <div
      className={classJoin(
        styles.financesGrid,
        "w-full bg-neutralSecondary-41 bg-opacity-10 rounded-t-2xl h-16 items-center px-6",
      )}
    >
      <div className="flex gap-x-9 items-center pl-3">
        <FaceIcon className="text-neutralPrimary-30" />
        {renderLabel(NAME, USER_NAME)}
      </div>
      {renderLabel(
        PROFESSION,
        undefined,
        selectFilterOptions(professionFiltersOptions, searchValue),
        true,
      )}
      {renderLabel(
        SENIORITY,
        undefined,
        selectFilterOptions(seniorityFiltersOptions, searchValue),
        true,
      )}
      {renderLabel(MONTHLY_SALARY)}
      {renderLabel(HOURLY_RATE)}
      {renderLabel(STATUS)}
    </div>
  );
};

export default SortBar;
