import React from "react";
import createThemeModePortal from "./createThemeModePortal";
import { ThemeModeContextProps, Theme } from "./types";

const ThemeModeContext = React.createContext<ThemeModeContextProps>({
  theme: Theme.LIGHT,
  set: () => {
    // do nothing
  },
  toggle: () => {
    // do nothing
  },
  createPortal: (children, container, key) =>
    createThemeModePortal({ theme: Theme.LIGHT, children, container, key }),
});

export default ThemeModeContext;
