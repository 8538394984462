import {
  KeyboardEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { Pill, ShowMore } from "@ps/ui-components";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import { ReactComponent as ClockIcon } from "../../../images/reports/clock.svg";
import { ReactComponent as RemoveIcon } from "../../../images/reports/remove-x.svg";
import { useTranslationWithNamespace } from "../../../hooks";
import { Keys } from "../../../shared";
import { ReactComponent as ArrowDownIcon } from "../../../images/reports/arrow-down.svg";
import useChartData from "../hooks/useChartData";
import { PillsRowProps } from "./types";

const PillsRow = ({ arrayFields, timeFrame }: PillsRowProps): ReactElement => {
  const { fields, remove } = arrayFields;
  const { reset, getValues } = useFormContext();
  const { t } = useTranslationWithNamespace();
  const [isFullViewOpen, setIsFullViewOpen] = useState(false);
  const showLessNode = useRef(null);
  const showMoreNode = useRef(null);

  const isYearView: boolean =
    dayjs(timeFrame?.[1]).diff(dayjs(timeFrame?.[0]), "month") + 1 === 12;

  const { totalTimeInMinutes } = useChartData(timeFrame, isYearView);

  const HH: number = totalTimeInMinutes / 60;
  const hours: number = Math.floor(HH);
  const MM: number = (HH - hours) * 60;
  const minutes: number = Math.round(MM);

  const onRemovePillClick = (
    index: number,
    itemId: string,
    type: string,
  ): void => {
    remove(index);
    reset({
      ...getValues(),
      [`${type}s`]: getValues(`${type}s`).map((single) =>
        single.itemId === itemId ? { ...single, checked: false } : single,
      ),
    });
  };

  useEffect(() => {
    if (!fields.length) setIsFullViewOpen(false);
  }, [fields.length]);

  return (
    <div className="flex items-start gap-2.5 w-full">
      <div className="flex items-center gap-2.5 text-neutralPrimary-20 w-auto whitespace-nowrap pt-1">
        <ClockIcon className="fill-current text-neutralPrimary-20" />
        <span>{t("reports.totalTime", { hours, minutes })}</span>
      </div>
      <ShowMore
        abbrViewRowAmount={1}
        isFullViewOpen={isFullViewOpen}
        setIsFullViewOpen={setIsFullViewOpen}
        MoreComponent={
          <>
            {fields.length ? (
              <Pill
                additionalClassName="gap-1.5 w-max"
                colourClass="bg-neutralPrimary-10 text-neutralPrimary-100"
                dataCy="showMore"
              >
                {!isFullViewOpen ? (
                  <button
                    autoFocus
                    ref={showMoreNode}
                    className="flex items-center gap-1.5 whitespace-no-wrap"
                    onClick={() => {
                      setIsFullViewOpen((prev) => !prev);
                    }}
                    tabIndex={0}
                  >
                    <span className="whitespace-no-wrap">
                      {t(`reports.showMore`)}
                    </span>
                    <ArrowDownIcon />
                  </button>
                ) : (
                  <button
                    autoFocus
                    ref={showLessNode}
                    className="flex items-center gap-1.5 whitespace-no-wrap"
                    onClick={() => {
                      setIsFullViewOpen((prev) => !prev);
                    }}
                    tabIndex={0}
                  >
                    <span className="whitespace-no-wrap">
                      {t(`reports.showLess`)}
                    </span>
                    <ArrowDownIcon className="transform rotate-180" />
                  </button>
                )}
              </Pill>
            ) : null}
          </>
        }
      >
        {fields?.length ? (
          fields.map((item, index) => (
            <Pill
              key={uid(item)}
              additionalClassName="gap-1.5 w-max"
              dataCy={item.itemId}
            >
              <span className="whitespace-no-wrap">
                {t(`reports.${item.type}`)}
              </span>
              <span className="font-bold whitespace-no-wrap">{item.name}</span>
              <button
                className="cursor-pointer whitespace-no-wrap"
                onClick={() => onRemovePillClick(index, item.itemId, item.type)}
                onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                  if (event.key === Keys.ENTER)
                    onRemovePillClick(index, item.itemId, item.type);
                }}
              >
                <RemoveIcon />
              </button>
            </Pill>
          ))
        ) : (
          <></>
        )}
      </ShowMore>
    </div>
  );
};

export default PillsRow;
