/* eslint @typescript-eslint/no-explicit-any: 0 */
import { IFilterOption, IHolidayHistoryEntry } from "./types";
import { HOLIDAY_TYPE } from "../constants";
import { HolidaysUserHistoryModel } from "../../../models/types";

export const getTemplateHolidayEntriesById = (
  templateId: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // TODO add missing types
  templates: any,
): IHolidayHistoryEntry[] =>
  templates.find((item) => item.id === templateId)?.holidays || [];

export const prepareFilteredEntries = (
  entries: HolidaysUserHistoryModel[],
  filters: {
    [name: string]: IFilterOption[];
  },
): HolidaysUserHistoryModel[] => {
  if (
    !filters ||
    !Object.values(filters).filter((item: IFilterOption[]) => item)?.length
  ) {
    return entries;
  }

  let result = entries;

  if (filters[HOLIDAY_TYPE]?.length && result.length) {
    result = result.filter((item: any) =>
      filters?.[HOLIDAY_TYPE]?.some(
        (singleFilter) => singleFilter.name === item?.typeName,
      ),
    );
  }

  return result;
};

export const prepareFilterOptions = (
  data: HolidaysUserHistoryModel[],
): IFilterOption[] =>
  data
    ?.map((item: HolidaysUserHistoryModel) => ({
      id: item.typeId,
      name: item.typeName,
    }))
    .reduce(
      (acc: IFilterOption[], option: IFilterOption) =>
        acc.some(
          (el: IFilterOption) => el.id === option.id || el === option?.id,
        )
          ? acc
          : [...acc, option],
      [],
    );

export const getCheckedNames = (
  filterOptions: IFilterOption[],
  filters: { [HOLIDAY_TYPE]: IFilterOption[] },
  name: string,
): string[] =>
  filterOptions.reduce(
    (acc: string[], item: IFilterOption): string[] =>
      filters[name]?.some((single) => single === item.id)
        ? [...acc, item.name]
        : acc,
    [],
  );
