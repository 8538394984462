import { AnyAction, Dispatch } from "redux";
import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import { HolidaysUserAPIModel, HolidaysUserDetailsAPIModel } from "../models";
import FinanceService from "../services";
import ActionTypes from "./actionTypes";
import { IAction, FilterPayload, SorterPayload } from "./types";

export const fetchHolidaysUsersStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_USERS_START);
export const fetchHolidaysUsersSuccess = (
  payload: IAction<HolidaysUserAPIModel>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_USERS_SUCCESS, payload);
export const fetchHolidaysUsersErrror = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_USERS_ERROR, payload);

export const fetchHolidaysUsers = async (dispatch: Dispatch): Promise<void> => {
  dispatch(fetchHolidaysUsersStart());
  const response = await FinanceService.fetchHolidaysUsers();
  if (isAPIError(response)) {
    dispatch(fetchHolidaysUsersErrror(response));
  } else {
    dispatch(fetchHolidaysUsersSuccess(response));
  }
};

export const fetchHolidaysUserDetailsStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_USER_DETAILS_START);
export const fetchHolidaysUserDetailsSuccess = (
  payload: IAction<HolidaysUserDetailsAPIModel>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_USER_DETAILS_SUCCESS, payload);
export const fetchHolidaysUserDetailsError = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_USER_DETAILS_ERROR, payload);

export const fetchHolidaysUserDetails = async (
  dispatch: Dispatch,
  userId: string,
): Promise<void> => {
  dispatch(fetchHolidaysUserDetailsStart());
  const response = await FinanceService.fetchHolidaysUserDetails(userId);
  if (isAPIError(response)) {
    dispatch(fetchHolidaysUserDetailsError(response));
  } else {
    dispatch(fetchHolidaysUserDetailsSuccess(response));
  }
};

export const addFilter = (payload: FilterPayload): AnyAction =>
  actionCreator(ActionTypes.ADD_HOLIDAYS_USERS_FILTER, payload);
export const removeFilter = (payload: FilterPayload): AnyAction =>
  actionCreator(ActionTypes.REMOVE_HOLIDAYS_USERS_FILTER, payload);
export const resetFilters = (): AnyAction =>
  actionCreator(ActionTypes.RESET_HOLIDAYS_USERS_FILTERS);

export const filterHolidaysUsersByProperty = (
  dispatch: Dispatch,
  filterName: string,
  optionId: string,
  isChecked: boolean,
): AnyAction =>
  isChecked
    ? dispatch(removeFilter({ filterName, optionId }))
    : dispatch(addFilter({ filterName, optionId }));

export const resetHolidaysUsersByPropertyFilters = (
  dispatch: Dispatch,
): AnyAction => dispatch(resetFilters());

export const setSorter = (payload: SorterPayload): AnyAction =>
  actionCreator(ActionTypes.SET_HOLIDAYS_USERS_SORTER, payload);

export const setSorterByProperty = (
  dispatch: Dispatch,
  order: "asc" | "desc",
  name: string,
): AnyAction => dispatch(setSorter({ order, name }));

export const fetchUserHolidaysHistoryStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_USER_HISTORY_START);
export const fetchUserHolidaysHistorySuccess = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_USER_HISTORY_SUCCESS, payload);
export const fetchUserHolidaysHistoryError = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_USER_HISTORY_ERROR, payload);

export const fetchUserHolidaysHistory = async (
  dispatch: Dispatch,
  userId: string,
): Promise<void> => {
  dispatch(fetchUserHolidaysHistoryStart());
  const response = await FinanceService.fetchUserHolidaysHistory(userId);
  if (isAPIError(response)) {
    dispatch(fetchUserHolidaysHistoryError(response));
  } else {
    dispatch(fetchUserHolidaysHistorySuccess(response));
  }
};
