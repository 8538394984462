import { AxiosResponse } from "axios";
import APIService from "../api/prospect-api";
import {
  NeedModel,
  CreateNeedResponseAPIModel,
  ProspectAPIModel,
  createNeedToAPIModel,
  updateNeedToAPIModel,
} from "../models";
import { handleError } from "../../shared/utils/service-utils";
import { GenerateTeamNeed, TeamResult } from "../api/openapi-client";

export const createNeed = async (
  prospectId: string,
  needData: NeedModel,
): Promise<AxiosResponse<CreateNeedResponseAPIModel>> => {
  try {
    const result = await APIService.API.addNeed(
      prospectId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      createNeedToAPIModel(needData),
    );

    return result;
  } catch (error) {
    return handleError(error);
  }
};

export const updateNeed = async (
  prospectId: string,
  needId: string,
  data: NeedModel,
): Promise<AxiosResponse<void>> => {
  try {
    const result = await APIService.API.updateNeed(
      prospectId,
      needId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updateNeedToAPIModel(data),
    );
    return result;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteNeed = async (
  prospectId: string,
  needId: string,
): Promise<AxiosResponse<void>> => {
  try {
    const result = await APIService.API.deleteNeed(prospectId, needId);

    return result;
  } catch (error) {
    return handleError(error);
  }
};

export const fetchNeeds = async (
  prospectId: string,
): Promise<ProspectAPIModel | Record<string, unknown>> => {
  try {
    const result = await APIService.API.getProspect(prospectId);

    if (result?.data) {
      return result.data;
    }
    return {};
  } catch (error) {
    return handleError(error);
  }
};

export const generateNeeds = async (
  prospectId: string,
  generateTeamNeed: GenerateTeamNeed[],
): Promise<TeamResult[] | Record<string, unknown>> => {
  try {
    const result = await APIService.API.generateTeam(
      prospectId,
      generateTeamNeed,
    );

    if (result.data) {
      return result.data;
    }
    return {};
  } catch (error) {
    return handleError(error);
  }
};
