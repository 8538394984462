import {
  useEffect,
  useState,
  ReactElement,
  KeyboardEvent,
  Fragment,
  useContext,
} from "react";
import dayjs, { isDayjs } from "dayjs";
import {
  Button,
  LoadingAnyPage,
  PermissionContext,
  RestrictedActionWrapper,
} from "@ps/ui-components";
import { useParams } from "react-router-dom";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "redux-react-hook";
import { classJoin } from "@ps/utils";
import { setRedirection, clearRedirection } from "@ps/alert-modal";
import { NEW_ITEMS, NOTES, PROSPECT_EDIT } from "../../../../constants";
import { ProspectService } from "../../../../services";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import NoteEdit from "../edit/note";
import { ReactComponent as AddNewIcon } from "../../../../../images/prospect/addNote.svg";
import { toggleEditMode } from "../../../../../store";
import { fetchProspect } from "../../../../store";
import { ActiveNoteType, NotesMapState, NotesMapStateReturn } from "./types";
import { accesses, Keys } from "../../../../../shared";
import { Note, FormValues } from "../../types";
import ActiveNote from "../view/activeNote";
import NotesItem from "./notesItem";
import { ReactComponent as LowIcon } from "../../../../../images/prospect/low.svg";
import { ReactComponent as MidIcon } from "../../../../../images/prospect/normal.svg";
import { ReactComponent as HighIcon } from "../../../../../images/prospect/high.svg";
import { ReactComponent as ProspectWomen } from "../../../../../images/prospect/prospect-women.svg";
import styles from "./styles.module.css";
import EmptySection from "../view/emptySection";
import { PROSPECT_FULL_ACCESS } from "../../../../../shared/permissions";

const TRANSLATION_PATH = "projects.prospect.notes";

const Notes = (): ReactElement => {
  const [editIndex, setEditIndex] = useState(-1);
  const [removeNoteIndex, setRemoveNoteIndex] = useState(-1);
  const { t } = useTranslationWithNamespace();
  const params = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const { hasPermission } = useContext(PermissionContext);

  const mapState = (state: NotesMapState): NotesMapStateReturn => ({
    editMode: state.editMode,
    notes: state?.prospects?.prospect?.notes || [],
    mineId: state.login?.sub || "",
    technologyDict: state?.dictionaries?.technology || [],
    accessesList: state?.prospects?.prospect?.accesses,
    archived: state?.prospects?.prospect?.archived,
  });

  const { editMode, notes, accessesList, technologyDict, archived } =
    useMappedStateSelector(mapState);

  const [activeNote, setActiveNote] = useState<ActiveNoteType>({
    note: notes?.[0],
    index: 0,
  });

  const [emptyNoteVisible, setEmptyNoteVisible] = useState(false);

  const [notesLength, setNotesLength] = useState(0);

  const methods = useForm<FormValues>({
    defaultValues: { notes: notes || [] },
  });

  const notesFields = useFieldArray({
    control: methods.control,
    name: NOTES,
  });

  useEffect(() => {
    if (notes?.length) {
      methods.reset({ [NOTES]: notes });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  const { isDirty, isSubmitting, isSubmitSuccessful } = methods.formState;

  useEffect(() => {
    if (isDirty) dispatch(setRedirection());
  }, [isDirty]);

  const onSubmit = async (data) => {
    const prospectId = params.id;
    if (Number.isInteger(editIndex) && editIndex !== -1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const currentNote = data[NOTES][editIndex];

      if (currentNote?.id) {
        await ProspectService.updateNote(prospectId, currentNote);
      } else await ProspectService.createNote(prospectId, currentNote);
      dispatch(toggleEditMode(""));
      dispatch(clearRedirection());
      await fetchProspect(prospectId, dispatch, technologyDict);
      setEmptyNoteVisible(false);
    }
  };

  const handleOnEdit = (index: number): void => {
    dispatch(toggleEditMode(NOTES));
    dispatch(setRedirection());
    methods.clearErrors();
    setEditIndex(index);
  };

  const handleOnCancel = (): void => {
    methods.reset();
    dispatch(clearRedirection());
    dispatch(toggleEditMode(""));
    setActiveNote({ note: notes?.[0], index: 0 });
    setEmptyNoteVisible(false);
  };

  const handleOnAdd = (): void => {
    setEmptyNoteVisible(true);
    setActiveNote({ note: undefined, index: -1 });
    notesFields?.append(NEW_ITEMS[NOTES]);
    handleOnEdit(notesFields.fields.length);
  };

  const handleOnRemoveItem = async (): Promise<void> => {
    await ProspectService.deleteNote(params.id, notes[removeNoteIndex].id);
    setRemoveNoteIndex(-1);
    await fetchProspect(params.id, dispatch, technologyDict);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      setActiveNote({ note: notes[editIndex], index: editIndex });
    }
  }, [isSubmitSuccessful, editIndex]);

  useEffect(() => {
    setNotesLength(notes.length);
    return () => setNotesLength(0);
  }, [notes]);

  useEffect(() => {
    if (notes.length !== notesLength) {
      setActiveNote({ note: notes[0], index: 0 });
    }
    if (notes.length === 0) setActiveNote({ note: undefined, index: -1 });
  }, [notes, notesLength]);

  const priorityIcons = {
    low: (
      <LowIcon
        className={styles.low}
        data-cy={`${PROSPECT_EDIT}-${NOTES}-low`}
      />
    ),
    medium: (
      <MidIcon
        className={styles.mid}
        data-cy={`${PROSPECT_EDIT}-${NOTES}-medium`}
      />
    ),
    high: (
      <HighIcon
        className={styles.high}
        data-cy={`${PROSPECT_EDIT}-${NOTES}-high`}
      />
    ),
  };

  return (
    <section
      className="-mx-10 bg-neutralPrimary-100 text-neutralPrimary-20 relative"
      data-cy={`${PROSPECT_EDIT}-${NOTES}`}
    >
      {editMode !== NOTES && !archived && (
        <RestrictedActionWrapper
          accesses={accesses.ADD_NOTES}
          actionAccessesList={accessesList}
        >
          <div className="flex flex-row gap-x-2 absolute -top-12 right-10">
            <Button dataCy="addNewNote" type="button" onClick={handleOnAdd}>
              <span className="flex gap-x-4 items-center">
                <AddNewIcon className="h-5 w-5 fill-current" />
                {t("projects.prospect.notes.addNewNote")}
              </span>
            </Button>
          </div>
        </RestrictedActionWrapper>
      )}
      {editMode === NOTES && (
        <div className="flex flex-row gap-x-2 absolute -top-12 right-10">
          <Button dataCy="cancel" variant="secondary" onClick={handleOnCancel}>
            {t("projects.prospect.actions.cancel")}
          </Button>
          <Button dataCy="save" onClick={methods.handleSubmit(onSubmit)}>
            {t("projects.prospect.actions.save")}
          </Button>
        </div>
      )}
      {isSubmitting && !notes.length && (
        <LoadingAnyPage additionalClassName="p-14" />
      )}
      {!notes.length && editMode !== NOTES && !isSubmitting && (
        <div className="absolute w-full p-14 left-0 right-0 bg-neutralPrimary-100">
          <EmptySection
            translationPath={TRANSLATION_PATH}
            noElementsTranslation="noNotes"
            toDoTranslation="startCreating"
            addNewTranslation="addNewNote"
            onAddClick={handleOnAdd}
            Image={<ProspectWomen />}
            archived={archived || !hasPermission(PROSPECT_FULL_ACCESS)}
          />
        </div>
      )}
      <div
        className={classJoin(
          "flex p-6 pb-0 rounded-t-2xl",
          isSubmitting && "hidden",
        )}
      >
        <div
          className={classJoin(
            editMode === NOTES && "pointer-events-none",
            "w-3/12 bg-neutralSecondary-41 bg-opacity-10 rounded-tl-2xl",
          )}
        >
          {emptyNoteVisible && (
            <div className="p-6 rounded-tl-2xl pr-10 flex flex-col gap-2 bg-neutralPrimary-50 bg-opacity-10">
              <span className="font-semibold">
                {t(`${TRANSLATION_PATH}.newNote`)}
              </span>
              <span className="text-neutralPrimary-30">
                {dayjs().format("DD.MM.YYYY")}
              </span>
            </div>
          )}
          {notes && notes.length
            ? notes.map((note: Note, index: number) => (
                <Fragment key={note.id}>
                  <NotesItem
                    dataCy={NOTES}
                    activeNote={activeNote}
                    note={note}
                    index={index}
                    onClickNote={() => setActiveNote({ note, index })}
                    notesQuantity={notes.length}
                    PriorityIcon={priorityIcons[note.priority]}
                  />
                </Fragment>
              ))
            : null}
        </div>
        <div className="w-9/12 rounded-2xl -ml-4 mb-6 bg-neutralPrimary-100 shadow-xl p-6 flex flex-col text-neutralPrimary-30 ">
          {editMode === NOTES && (
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className="w-full h-full"
              >
                <div
                  className="flex justify-center flex-col"
                  data-cy={`${PROSPECT_EDIT}-${NOTES}`}
                  onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === Keys.ESC) handleOnCancel();
                  }}
                  role="presentation"
                >
                  <span
                    className="text-center w-full"
                    data-cy={`${PROSPECT_EDIT}-${NOTES}_date`}
                  >
                    {isDayjs(activeNote.note?.createDate)
                      ? activeNote.note?.createDate.format("DD.MM.YYYY")
                      : ""}
                  </span>
                  <NoteEdit index={editIndex} />
                </div>
              </form>
            </FormProvider>
          )}
          <>
            {notes.length && activeNote.note && editMode !== NOTES ? (
              <ActiveNote
                archived={archived}
                activeNote={activeNote}
                onEdit={() => handleOnEdit(activeNote.index)}
                prepareToRemove={() => setRemoveNoteIndex(activeNote.index)}
                onRemove={() => handleOnRemoveItem()}
                isRemoving={activeNote.index === removeNoteIndex}
                onRemoveCancel={() => setRemoveNoteIndex(-1)}
                PriorityIcon={
                  activeNote?.note?.priority &&
                  priorityIcons[activeNote.note.priority]
                }
              />
            ) : null}
          </>
        </div>
      </div>
    </section>
  );
};
export default Notes;
