import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import {
  TIME_TRACKER_PROJECT_NAME_CIRCLE_PREFIX,
  TIME_TRACKER_PROJECT_NAME_PREFIX,
} from "../../shared/data-cy";
import { TimeTrackerProjectNameProps } from "./types";
import { checkColor } from "../../shared/utils/colorsUtils";
import { focusVisibleStyles, Keys } from "../../shared";

const TimeTrackerProjectName = ({
  dataCy,
  label,
  color,
  onClick,
  additionalClassName,
  icon,
}: TimeTrackerProjectNameProps): ReactElement => {
  const colorCondition = color && checkColor(color);
  const circleStyles = colorCondition
    ? ""
    : "border border-neutralSecondary-40 bg-neutralSecondary-80";
  const clickableLabelStyles = onClick
    ? classJoin(
        "hover:text-neutralPrimary-30 cursor-pointer rounded-md",
        focusVisibleStyles,
      )
    : "";

  return (
    <div
      className={classJoin(
        "flex flex-row items-center w-auto cursor-default",
        clickableLabelStyles,
        additionalClassName,
      )}
      data-cy={`${TIME_TRACKER_PROJECT_NAME_PREFIX}-${dataCy}`}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
      role={onClick ? "button" : undefined}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === Keys.ENTER) onClick?.(event);
      }}
    >
      {!icon ? (
        <div
          className={classJoin(
            "flex-shrink-0 rounded-full w-3 h-3 mr-3",
            circleStyles,
          )}
          style={colorCondition ? { backgroundColor: color } : {}}
          data-cy={`${TIME_TRACKER_PROJECT_NAME_CIRCLE_PREFIX}-${dataCy}`}
        />
      ) : (
        <div>{icon}</div>
      )}
      <div
        className={classJoin(
          "block text-neutralPrimary-20",
          clickableLabelStyles,
        )}
      >
        {label}
      </div>
    </div>
  );
};

export default TimeTrackerProjectName;
