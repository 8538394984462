import { ReactElement, useContext, useEffect, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { sortArray, useFullTextSearch } from "@ps/utils";
import { PermissionContext, LoadingAnyPage } from "@ps/ui-components";
import {
  useMappedStateSelector,
  useSessionStorage,
} from "../../../../../hooks";
import UserRow from "./userRow";
import {
  fetchProfilesSummaryList,
  ProfilesSummaryListModel,
} from "../../../../../profile-domain";
import { SessionStorage } from "../../../../../shared";
import {
  DiscoveryTeamMapStateReturn,
  DiscoveryTeamMapState,
  IUserWithCheck,
  SorterParametersProps,
  DiscoveryTeamFilters,
} from "./types";
import { DiscoveryTeamModel } from "../../../../models";
import SearchBar from "./searchBar";
import SortBar from "./sortBar";
import {
  SENIORITY,
  ASC,
  DISCOVERY_TEAM,
  initialFiltersState,
  RESIDENCE,
} from "../../../../constants";
import {
  SESSION_SORTERS,
  initialSessionSortersState,
} from "../../../../../shared/constants";
import { filterUsers } from "../utils";
import EmptySpecialists from "../folder/emptySpecialists";
import { PROSPECT_FULL_ACCESS } from "../../../../../shared/permissions";
import EmptySection from "../view/emptySection";
import { ReactComponent as ProspectStalker } from "../../../../../images/prospect/prospect-stalker.svg";

const DiscoveryTeam = (): ReactElement => {
  const dispatch = useDispatch();

  const { hasPermission } = useContext(PermissionContext);

  const [usersListToDisplay, setUsersListToDisplay] = useState<
    ProfilesSummaryListModel[]
  >([]);

  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] =
    useState<DiscoveryTeamFilters>(initialFiltersState);

  const [sorterParameters, setSorterParameters] =
    useState<SorterParametersProps>({
      order: ASC,
      name: SENIORITY,
    });

  const [storageSorter, setStorageSorter] = useSessionStorage<SessionStorage>(
    SESSION_SORTERS,
    initialSessionSortersState,
  );

  useEffect(() => {
    if (hasPermission(PROSPECT_FULL_ACCESS)) fetchProfilesSummaryList(dispatch);
  }, []);

  const mapState = (
    state: DiscoveryTeamMapState,
  ): DiscoveryTeamMapStateReturn => ({
    archived: state?.prospects?.prospect.archived,
    discoveryTeam: state?.prospects?.prospect?.discoveryTeam || [],
    isFetchingProfiles: state?.requestStatus?.isFetchingProfiles,
    users: state?.profiles?.profilesSummaryList || [],
  });

  const { users, discoveryTeam, archived, isFetchingProfiles } =
    useMappedStateSelector(mapState);

  const [checked, setChecked] = useState<string[]>(
    discoveryTeam?.map((user: DiscoveryTeamModel) => user.userId) || [],
  );

  const normalizedDiscoveryTeam = discoveryTeam?.map(
    (user: DiscoveryTeamModel) => ({
      user: user.userId,
      avatar: user.avatar,
      mainProfession: user.professions[0],
      residence: user.residence,
      seniority: user.seniority,
      firstName: user.firstName,
      lastName: user.lastName,
    }),
  );

  const mappedUsersWithCheck = users?.map((user: ProfilesSummaryListModel) => ({
    ...user,
    checked: checked.includes(user.userId),
  }));

  const sortedUsersByCheck = mappedUsersWithCheck?.sort(
    (a: IUserWithCheck, b: IUserWithCheck) => (a.checked > b.checked ? -1 : 1),
  );

  const { search } = useFullTextSearch(usersListToDisplay || [], undefined, {
    keys: ["firstName", "lastName"],
  });

  const prepareTeamList = (
    searchingValue: string,
    userListFilters: DiscoveryTeamFilters,
  ): void => {
    const { discoveryTeam: teamSorter } = storageSorter.sessionSorters;

    const properSorter: {
      name: string;
      order: string;
      nestedFieldName?: string;
    } = teamSorter?.name?.length ? teamSorter : sorterParameters;

    const resultByFilter = filterUsers(
      sortedUsersByCheck.length ? sortedUsersByCheck : normalizedDiscoveryTeam,
      searchingValue,
      userListFilters,
      search,
    );

    const resultBySorter = sortArray(
      resultByFilter,
      properSorter.name as keyof ProfilesSummaryListModel,
      properSorter.order,
      properSorter.nestedFieldName as keyof ProfilesSummaryListModel,
    );

    setUsersListToDisplay(resultBySorter);
  };

  useEffect(() => {
    prepareTeamList(searchValue, filters);
  }, [searchValue, users, sorterParameters.name, sorterParameters.order]);

  const handleOnClickSorter = (
    fieldName: string,
    order: string,
    isNestedField?: boolean,
  ): void => {
    const nestedFieldName = isNestedField ? RESIDENCE : undefined;

    setStorageSorter((prevData) => ({
      ...prevData,
      sessionSorters: {
        ...prevData.sessionSorters,
        [DISCOVERY_TEAM]: {
          name: fieldName,
          order,
          nestedFieldName,
        },
      },
    }));

    setSorterParameters({ name: fieldName, order });
  };

  const handleOnFilterClick = (
    isChecked: boolean,
    id: string,
    filterName: string,
  ): void => {
    const prevFilters = JSON.parse(JSON.stringify(filters));

    const newFiltersState: DiscoveryTeamFilters = isChecked
      ? {
          ...prevFilters,
          [filterName]: prevFilters[filterName]?.filter(
            (item: string) => item !== id,
          ),
        }
      : { ...prevFilters, [filterName]: [...prevFilters[filterName], id] };

    setFilters(newFiltersState);
    prepareTeamList(searchValue, newFiltersState);
  };

  const handleOnClearFilters = () => {
    setSearchValue("");
    setFilters(initialFiltersState);
    prepareTeamList("", initialFiltersState);
  };

  if (isFetchingProfiles)
    return (
      <div className="absolute w-full p-14 left-0 right-0 bg-neutralPrimary-100">
        <LoadingAnyPage />
      </div>
    );

  if (!hasPermission(PROSPECT_FULL_ACCESS))
    return (
      <div className="absolute w-full p-14 left-0 right-0 bg-neutralPrimary-100">
        <EmptySection
          addNewTranslation="noUsers"
          archived={archived || !hasPermission(PROSPECT_FULL_ACCESS)}
          Image={<ProspectStalker className="h-64" />}
          noElementsTranslation="noUsers"
          onAddClick={() => undefined}
          toDoTranslation=""
          translationPath="projects.prospect.discoveryTeam"
        />
      </div>
    );

  if (!usersListToDisplay.length && !!searchValue.length)
    return (
      <div className="absolute w-full p-14 left-0 right-0 bg-neutralPrimary-100">
        <EmptySpecialists searchValue={searchValue} />
      </div>
    );

  return (
    <div className="flex flex-col bg-neutralPrimary-100 -mx-10 gap-5 px-8 py-6 relative">
      <div className="absolute right-8 -top-14">
        <SearchBar
          dataCy={DISCOVERY_TEAM}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          sorterParameters={
            storageSorter?.sessionSorters?.discoveryTeam?.name?.length
              ? storageSorter?.sessionSorters?.discoveryTeam
              : sorterParameters
          }
          onSorterClick={handleOnClickSorter}
          onClearFilters={handleOnClearFilters}
          clearFiltersDisabled={
            !Object.values(filters).filter((item: string[]) => item.length)
              .length && !searchValue
          }
        />
      </div>

      <>
        <SortBar
          users={users}
          filters={filters}
          updateFilters={handleOnFilterClick}
        />
        {(usersListToDisplay || []).map((user: ProfilesSummaryListModel) => (
          <UserRow
            key={user?.userId}
            user={user}
            checked={checked}
            setChecked={setChecked}
            archived={archived}
          />
        ))}
      </>
    </div>
  );
};

export default DiscoveryTeam;
