import React, { useEffect } from "react";
import {
  MiddleContent,
  MiddleSection,
  TabsPlaceholder,
} from "@ps/ui-components";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { clearRedirection, RedirectAlertModal } from "@ps/alert-modal";
import Clients from "../ui/clients";
import { FOLDERS_LIST } from "../../shared/data-cy";
import ClientsDetails from "../ui/clients-details/clientsDetails";
import { DETAILS } from "../constants";
import { setDefaultTab } from "../store";

const ClientsRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDefaultTab(DETAILS));
  }, []);

  return (
    <MiddleSection dataCy={FOLDERS_LIST}>
      <TabsPlaceholder />
      <MiddleContent dataCy={FOLDERS_LIST}>
        <RedirectAlertModal
          dispatch={dispatch}
          clearRedirection={clearRedirection}
        />
        <Switch>
          <Route exact path={path}>
            <Clients />
          </Route>
          <Route path={`${path}/:id`}>
            <ClientsDetails />
          </Route>
        </Switch>
      </MiddleContent>
    </MiddleSection>
  );
};

export default ClientsRoutes;
