import { ReactElement } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { clearRedirection, RedirectAlertModal } from "@ps/alert-modal";
import { dashConverter } from "@ps/utils";
import { EditMatrix, MatrixContainer, Settings, SkillsMatrix } from "../ui";
import { EDIT_MATRIX, OVERVIEW, SETTINGS } from "../shared/routes";
import { SKILLS_MATRIX_MODES } from "../shared/consts";

const MatrixRoutes = (): ReactElement => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  return (
    <MatrixContainer>
      <RedirectAlertModal
        dispatch={dispatch}
        clearRedirection={clearRedirection}
      />
      <Switch>
        <Route path={`${path}/${SETTINGS}`}>
          <Settings />
        </Route>
        <Route path={`${path}/${dashConverter(EDIT_MATRIX)}`}>
          <EditMatrix />
        </Route>
        <Route path={`${path}/${OVERVIEW}`}>
          <SkillsMatrix mode={SKILLS_MATRIX_MODES.VIEW} />
        </Route>
        <Route>
          <Redirect to={`${path}/${SETTINGS}`} />
        </Route>
      </Switch>
    </MatrixContainer>
  );
};

export default MatrixRoutes;
