/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Holidays Template API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateHolidaysItem
 */
export interface CreateHolidaysItem {
    /**
     * Type of leave
     * @type {string}
     * @memberof CreateHolidaysItem
     */
    'holidaysName': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHolidaysItem
     */
    'allowPartialDayOff': boolean;
    /**
     * Number of days off an employee can take
     * @type {number}
     * @memberof CreateHolidaysItem
     */
    'numberOfDaysOff': number;
    /**
     * Pay rate during absence
     * @type {number}
     * @memberof CreateHolidaysItem
     */
    'payRatePercentage': number;
}
/**
 * 
 * @export
 * @interface CreateHolidaysTemplate
 */
export interface CreateHolidaysTemplate {
    /**
     * 
     * @type {string}
     * @memberof CreateHolidaysTemplate
     */
    'name': string;
    /**
     * 
     * @type {CreateHolidaysTemplateItems}
     * @memberof CreateHolidaysTemplate
     */
    'holidaysTemplateItems'?: CreateHolidaysTemplateItems;
}
/**
 * 
 * @export
 * @interface CreateHolidaysTemplateItems
 */
export interface CreateHolidaysTemplateItems {
    /**
     * 
     * @type {CreateHolidaysItem}
     * @memberof CreateHolidaysTemplateItems
     */
    'main': CreateHolidaysItem;
    /**
     * 
     * @type {Array<CreateHolidaysItem>}
     * @memberof CreateHolidaysTemplateItems
     */
    'secondary': Array<CreateHolidaysItem>;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface HolidaysItem
 */
export interface HolidaysItem {
    /**
     * item ID
     * @type {string}
     * @memberof HolidaysItem
     */
    'id': string;
    /**
     * Type of leave
     * @type {string}
     * @memberof HolidaysItem
     */
    'holidaysName': string;
    /**
     * 
     * @type {boolean}
     * @memberof HolidaysItem
     */
    'allowPartialDayOff': boolean;
    /**
     * Number of days off an employee can take
     * @type {number}
     * @memberof HolidaysItem
     */
    'numberOfDaysOff': number;
    /**
     * Pay rate during absence
     * @type {number}
     * @memberof HolidaysItem
     */
    'payRatePercentage': number;
}
/**
 * 
 * @export
 * @interface HolidaysTemplate
 */
export interface HolidaysTemplate {
    /**
     * template ID
     * @type {string}
     * @memberof HolidaysTemplate
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HolidaysTemplate
     */
    'name': string;
    /**
     * 
     * @type {HolidaysTemplateItems}
     * @memberof HolidaysTemplate
     */
    'holidaysTemplateItems'?: HolidaysTemplateItems;
}
/**
 * 
 * @export
 * @interface HolidaysTemplateItems
 */
export interface HolidaysTemplateItems {
    /**
     * 
     * @type {HolidaysItem}
     * @memberof HolidaysTemplateItems
     */
    'main': HolidaysItem;
    /**
     * 
     * @type {Array<HolidaysItem>}
     * @memberof HolidaysTemplateItems
     */
    'secondary': Array<HolidaysItem>;
}
/**
 * 
 * @export
 * @interface UpdateHolidaysItem
 */
export interface UpdateHolidaysItem {
    /**
     * Type of leave
     * @type {string}
     * @memberof UpdateHolidaysItem
     */
    'holidaysName': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHolidaysItem
     */
    'allowPartialDayOff': boolean;
    /**
     * Number of days off an employee can take
     * @type {number}
     * @memberof UpdateHolidaysItem
     */
    'numberOfDaysOff': number;
    /**
     * Pay rate during absence
     * @type {number}
     * @memberof UpdateHolidaysItem
     */
    'payRatePercentage': number;
}
/**
 * 
 * @export
 * @interface UpdateHolidaysTemplate
 */
export interface UpdateHolidaysTemplate {
    /**
     * template name to be changed
     * @type {string}
     * @memberof UpdateHolidaysTemplate
     */
    'templateName': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new holidays item in template
         * @param {string} templateId 
         * @param {CreateHolidaysItem} createHolidaysItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHolidaysItem: async (templateId: string, createHolidaysItem: CreateHolidaysItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('createHolidaysItem', 'templateId', templateId)
            // verify required parameter 'createHolidaysItem' is not null or undefined
            assertParamExists('createHolidaysItem', 'createHolidaysItem', createHolidaysItem)
            const localVarPath = `/finances/holidays_templates/{templateId}/holidays_item/`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHolidaysItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new holidays template
         * @param {CreateHolidaysTemplate} createHolidaysTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate: async (createHolidaysTemplate: CreateHolidaysTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHolidaysTemplate' is not null or undefined
            assertParamExists('createTemplate', 'createHolidaysTemplate', createHolidaysTemplate)
            const localVarPath = `/finances/holidays_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHolidaysTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete holidays item identified by id
         * @param {string} templateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHolidaysItem: async (templateId: string, itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deleteHolidaysItem', 'templateId', templateId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteHolidaysItem', 'itemId', itemId)
            const localVarPath = `/finances/holidays_templates/{templateId}/holidays_item/{itemId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a holidays template identified by id
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate: async (templateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deleteTemplate', 'templateId', templateId)
            const localVarPath = `/finances/holidays_templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of created holidays templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/finances/holidays_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches holidays item for specific template by id
         * @param {string} templateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHolidaysItem: async (templateId: string, itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getHolidaysItem', 'templateId', templateId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('getHolidaysItem', 'itemId', itemId)
            const localVarPath = `/finances/holidays_templates/{templateId}/holidays_item/{itemId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a holidays template identified by id
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate: async (templateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getTemplate', 'templateId', templateId)
            const localVarPath = `/finances/holidays_templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets main holiday item
         * @param {string} templateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMainItem: async (templateId: string, itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('setMainItem', 'templateId', templateId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('setMainItem', 'itemId', itemId)
            const localVarPath = `/finances/holidays_templates/{templateId}/holidays_item/{itemId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update holidays item identified by id
         * @param {string} templateId 
         * @param {string} itemId 
         * @param {UpdateHolidaysItem} updateHolidaysItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHolidaysItem: async (templateId: string, itemId: string, updateHolidaysItem: UpdateHolidaysItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateHolidaysItem', 'templateId', templateId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('updateHolidaysItem', 'itemId', itemId)
            // verify required parameter 'updateHolidaysItem' is not null or undefined
            assertParamExists('updateHolidaysItem', 'updateHolidaysItem', updateHolidaysItem)
            const localVarPath = `/finances/holidays_templates/{templateId}/holidays_item/{itemId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHolidaysItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update holidays template identified by id
         * @param {string} templateId 
         * @param {UpdateHolidaysTemplate} updateHolidaysTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate: async (templateId: string, updateHolidaysTemplate: UpdateHolidaysTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateTemplate', 'templateId', templateId)
            // verify required parameter 'updateHolidaysTemplate' is not null or undefined
            assertParamExists('updateTemplate', 'updateHolidaysTemplate', updateHolidaysTemplate)
            const localVarPath = `/finances/holidays_templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHolidaysTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Create new holidays item in template
         * @param {string} templateId 
         * @param {CreateHolidaysItem} createHolidaysItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHolidaysItem(templateId: string, createHolidaysItem: CreateHolidaysItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHolidaysItem(templateId, createHolidaysItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new holidays template
         * @param {CreateHolidaysTemplate} createHolidaysTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplate(createHolidaysTemplate: CreateHolidaysTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplate(createHolidaysTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete holidays item identified by id
         * @param {string} templateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHolidaysItem(templateId: string, itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHolidaysItem(templateId, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a holidays template identified by id
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplate(templateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemplate(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a list of created holidays templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HolidaysTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches holidays item for specific template by id
         * @param {string} templateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHolidaysItem(templateId: string, itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolidaysItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHolidaysItem(templateId, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a holidays template identified by id
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplate(templateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolidaysTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplate(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets main holiday item
         * @param {string} templateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMainItem(templateId: string, itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMainItem(templateId, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update holidays item identified by id
         * @param {string} templateId 
         * @param {string} itemId 
         * @param {UpdateHolidaysItem} updateHolidaysItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHolidaysItem(templateId: string, itemId: string, updateHolidaysItem: UpdateHolidaysItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHolidaysItem(templateId, itemId, updateHolidaysItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update holidays template identified by id
         * @param {string} templateId 
         * @param {UpdateHolidaysTemplate} updateHolidaysTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplate(templateId: string, updateHolidaysTemplate: UpdateHolidaysTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplate(templateId, updateHolidaysTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Create new holidays item in template
         * @param {string} templateId 
         * @param {CreateHolidaysItem} createHolidaysItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHolidaysItem(templateId: string, createHolidaysItem: CreateHolidaysItem, options?: any): AxiosPromise<string> {
            return localVarFp.createHolidaysItem(templateId, createHolidaysItem, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new holidays template
         * @param {CreateHolidaysTemplate} createHolidaysTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(createHolidaysTemplate: CreateHolidaysTemplate, options?: any): AxiosPromise<string> {
            return localVarFp.createTemplate(createHolidaysTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete holidays item identified by id
         * @param {string} templateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHolidaysItem(templateId: string, itemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteHolidaysItem(templateId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a holidays template identified by id
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(templateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of created holidays templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTemplates(options?: any): AxiosPromise<Array<HolidaysTemplate>> {
            return localVarFp.getAllTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches holidays item for specific template by id
         * @param {string} templateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHolidaysItem(templateId: string, itemId: string, options?: any): AxiosPromise<HolidaysItem> {
            return localVarFp.getHolidaysItem(templateId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a holidays template identified by id
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(templateId: string, options?: any): AxiosPromise<HolidaysTemplate> {
            return localVarFp.getTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sets main holiday item
         * @param {string} templateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMainItem(templateId: string, itemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.setMainItem(templateId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update holidays item identified by id
         * @param {string} templateId 
         * @param {string} itemId 
         * @param {UpdateHolidaysItem} updateHolidaysItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHolidaysItem(templateId: string, itemId: string, updateHolidaysItem: UpdateHolidaysItem, options?: any): AxiosPromise<void> {
            return localVarFp.updateHolidaysItem(templateId, itemId, updateHolidaysItem, options).then((request) => request(axios, basePath));
        },
        /**
         * Update holidays template identified by id
         * @param {string} templateId 
         * @param {UpdateHolidaysTemplate} updateHolidaysTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(templateId: string, updateHolidaysTemplate: UpdateHolidaysTemplate, options?: any): AxiosPromise<void> {
            return localVarFp.updateTemplate(templateId, updateHolidaysTemplate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Create new holidays item in template
     * @param {string} templateId 
     * @param {CreateHolidaysItem} createHolidaysItem 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createHolidaysItem(templateId: string, createHolidaysItem: CreateHolidaysItem, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createHolidaysItem(templateId, createHolidaysItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new holidays template
     * @param {CreateHolidaysTemplate} createHolidaysTemplate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTemplate(createHolidaysTemplate: CreateHolidaysTemplate, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createTemplate(createHolidaysTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete holidays item identified by id
     * @param {string} templateId 
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteHolidaysItem(templateId: string, itemId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteHolidaysItem(templateId, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a holidays template identified by id
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTemplate(templateId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteTemplate(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a list of created holidays templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllTemplates(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches holidays item for specific template by id
     * @param {string} templateId 
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHolidaysItem(templateId: string, itemId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHolidaysItem(templateId, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a holidays template identified by id
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTemplate(templateId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTemplate(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets main holiday item
     * @param {string} templateId 
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setMainItem(templateId: string, itemId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setMainItem(templateId, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update holidays item identified by id
     * @param {string} templateId 
     * @param {string} itemId 
     * @param {UpdateHolidaysItem} updateHolidaysItem 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateHolidaysItem(templateId: string, itemId: string, updateHolidaysItem: UpdateHolidaysItem, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateHolidaysItem(templateId, itemId, updateHolidaysItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update holidays template identified by id
     * @param {string} templateId 
     * @param {UpdateHolidaysTemplate} updateHolidaysTemplate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateTemplate(templateId: string, updateHolidaysTemplate: UpdateHolidaysTemplate, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateTemplate(templateId, updateHolidaysTemplate, options).then((request) => request(this.axios, this.basePath));
    }
}


