import { hhPermissions } from "@ps/hh";
import { ReactComponent as LockIcon } from "../images/lock.svg";
import { ReactComponent as SkillsIcon } from "../images/skills.svg";
import { ReactComponent as ProjectIcon } from "../images/project.svg";
import { ReactComponent as ProspectIcon } from "../images/prospects.svg";
import { ReactComponent as ClientsIcon } from "../images/clients.svg";
import { ReactComponent as TeamIcon } from "../images/team.svg";
import { ReactComponent as MembersTabIcon } from "../images/membersTab.svg";
import { ReactComponent as PermissionsTabIcon } from "../images/accessesTab.svg";
import { ReactComponent as FinancesIcon } from "../images/financesTab.svg";
import { ROLES_MANAGE } from "./permissions";

export const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === "production";
export const NAMESPACE = "permissions";

export const PERMISSIONS_TABS = [
  {
    item: "MEMBERS",
    permissions: [hhPermissions.USERS_LIST_ALL],
    icon: MembersTabIcon,
  },
  {
    item: "PERMISSIONS",
    permissions: [ROLES_MANAGE],
    icon: PermissionsTabIcon,
  },
];

export const NAVBAR_ITEMS = {
  item: "members",
  path: "members",
  icon: LockIcon,
  permission: [hhPermissions.USERS_LIST_ALL, ROLES_MANAGE],
};

export const USER = "user";

export const LEFT = "left";
export const MIDDLE = "middle";
export const RIGHT = "right";
export const ROLES = "roles";

export const NAME = "name";
export const EMAIL = "email";
export const PROFESSION = "profession";
export const ACCESS_GROUP = "group";
export const STATUS = "enabled";
export const FIRST_NAME = "firstName";
export const LAST_NAME = "lastName";
export const START_DATE = "startDate";
export const ACTIVE = "active";
export const ARCHIVED = "archived";

export const EMAIL_REGEXP_PATTERN = new RegExp(
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const permissionsMatrix = [
  {
    itemId: "team",
    icon: TeamIcon,
    permissions: [
      {
        itemId: hhPermissions.USERS_LIST_ALL,
      },
      {
        itemId: hhPermissions.USERS_ASSIGN_ROLES,
      },
      {
        itemId: ROLES_MANAGE,
      },
    ],
  },
  {
    itemId: "skills",
    icon: SkillsIcon,
    permissions: [
      {
        itemId: hhPermissions.MATRIX_FULFILLMENTS,
      },
      {
        itemId: hhPermissions.MATRIX_MANAGE_TEMPLATES,
      },
    ],
  },
  {
    itemId: "project",
    icon: ProjectIcon,
    permissions: [
      {
        itemId: hhPermissions.PROJECT_FULL_ACCESS,
      },
      {
        itemId: hhPermissions.PROJECT_LIST_ALL,
      },
      {
        itemId: hhPermissions.PROJECT_EDIT_TIMESHEETS,
      },
    ],
  },
  {
    itemId: "prospect",
    icon: ProspectIcon,
    permissions: [
      {
        itemId: hhPermissions.PROSPECT_FULL_ACCESS,
      },
      {
        itemId: hhPermissions.PROSPECT_LIST_ALL,
      },
      {
        itemId: hhPermissions.PROSPECT_VIEW_RATES,
      },
    ],
  },
  {
    itemId: "clients",
    icon: ClientsIcon,
    permissions: [
      {
        itemId: hhPermissions.CLIENTS_MANAGE,
      },
      {
        itemId: hhPermissions.CLIENTS_LIST_ALL,
      },
    ],
  },
  {
    itemId: "finances",
    icon: FinancesIcon,
    permissions: [
      {
        itemId: hhPermissions.FINANCES_MANAGE_FINANCES,
      },
    ],
  },
  // TODO temporary hidden
  // {
  //   itemId: "folder",
  //   icon: ProjectIcon,
  //   permissions: [
  //     {
  //       itemId: "create-edit-folder",
  //     },
  //     {
  //       itemId: "list-folders",
  //     },
  //   ],
  // },
  // {
  //   itemId: "holidays",
  //   icon: ProjectIcon,
  //   permissions: [
  //     {
  //       itemId: "fill-view-holidays",
  //     },
  //     {
  //       itemId: "view-all-holidays",
  //     },
  //     {
  //       itemId: "edit-all-holidays",
  //     },
  //   ],
  // },
];
