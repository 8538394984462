import { createContext, useContext } from "react";
import { ContextProps } from "./types";

export const MembersContext = createContext<ContextProps>({
  isEdit: null,
  setIsEdit: () => {
    // nothing
  },
});

export const useMembersContext = (): ContextProps => {
  const context = useContext(MembersContext);
  if (!context) {
    throw new Error(
      "Member compound components cannot be rendered outside the Members component",
    );
  }
  return context;
};
