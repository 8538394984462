import {
  FulfillmentModel,
  GroupAPIModel,
  SkillsMatrixDictionaryModel,
} from "../../models";
import { ProfessionSkillsProps, SectionFormTab } from "../types";
import { DictionaryModel } from "../../../dictionaries";

export const getProfessionsSkillsIds = (
  professionsGroups: GroupAPIModel[],
): string[] =>
  professionsGroups
    .map((group) => group.skills.map((skill) => skill.technologyId))
    .flat();

export const getOtherFulfillmentSkills = (
  fulfillment: FulfillmentModel,
  technologiesIds: string[],
): ProfessionSkillsProps[] =>
  Object.keys(fulfillment ?? {})
    .filter((technologyId) => !technologiesIds?.includes(technologyId))
    .map((technologyId) => ({
      id: technologyId,
      name: technologyId,
      technologyId,
    }));

export const prepareOtherTechnologiesDict = (
  fullfilments: FulfillmentModel,
  groups: GroupAPIModel[],
): string[] | [] | ProfessionSkillsProps[] => {
  if (!groups || !fullfilments) return [];
  const technologiesIds = getProfessionsSkillsIds(groups);
  return getOtherFulfillmentSkills(fullfilments, technologiesIds);
};

export const debounce = (
  func: (event) => void,
  timeout = 250,
): (() => void) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      func.apply(this, args as any);
    }, timeout);
  };
};

export const sortSkillsByName = (
  a: ProfessionSkillsProps | string,
  b: ProfessionSkillsProps | string,
): number => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (a?.name && b?.name) return a?.name > b?.name ? 1 : -1;
  if (typeof a === "string" && typeof b === "string") return a > b ? 1 : -1;
  return 0;
};

export const prepareDictionaryToSkillsMatrix = (
  dictionaryEntryArray: DictionaryModel,
): SkillsMatrixDictionaryModel =>
  dictionaryEntryArray.reduce(
    (acc, entry) => ({ ...acc, [entry.id]: entry }),
    {},
  );

export const sortTabsByProfession = (
  currentTabs: SectionFormTab[],
  currentProfessions: DictionaryModel | [],
): SectionFormTab[] =>
  currentTabs.reduce((acc: [] | SectionFormTab[], item) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (currentProfessions?.includes(item.tabKey)) {
      return [item, ...acc];
    }
    return [...acc, item];
  }, []);

export const getTabsIds = (
  activeTab: number,
  sortedTabs: SectionFormTab[],
): number[] =>
  sortedTabs
    .filter((item) => item?.tabKey !== activeTab)
    .map((tab) => tab.tabKey);
