import * as d3 from "d3";
import { ReactElement, useEffect } from "react";
import { CIRCLE_CHART_PREFIX } from "../../shared/data-cy";
import { CircleChartProps } from "./types";

const CircleChart = ({
  dataCy,
  data,
  fillColors,
  width = 350,
  height = 350,
  innerRadius = 175,
  outerRadius = 155,
  children,
  additionalClassName,
}: CircleChartProps): ReactElement => {
  const drawChart = () => {
    d3.select("#circle-container").select("svg").remove();

    const svg = d3
      .select("#circle-container")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    const arcGenerator = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .padRadius(1000)
      .padAngle(2 / 200)
      .cornerRadius(500);

    const circleGenerator = d3
      .pie()
      .padAngle(0)
      // @ts-config
      // @ts-ignore
      .value((d: Record<string, unknown>) => d.value);

    const arc = data?.length
      ? svg.selectAll().data(circleGenerator(data)).enter()
      : svg
          .selectAll()
          // @ts-ignore
          .data(circleGenerator([{ value: 1 }]))
          .enter();

    // @ts-ignore
    arc.append("path").attr("d", arcGenerator).style("fill", fillColors);
  };

  useEffect(() => {
    drawChart();
  }, [data]);

  return (
    <div
      id="circle-container"
      className={additionalClassName}
      data-cy={`${CIRCLE_CHART_PREFIX}_${dataCy}`}
    >
      {children}
    </div>
  );
};

export default CircleChart;
