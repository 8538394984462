import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { clearRedirection, RedirectAlertModal } from "@ps/alert-modal";
import { FINANCES, HOLIDAYS, HOLIDAYS_TEMPLATES } from "../../shared/routes";
import FinanceContainer from "../ui/financeContainer";
import { Holidays } from "../ui/holidays";
import { HolidaysUser } from "../ui/holidays-user";
import { HolidaysTemplates } from "../../holidays-templates-domain";
import Finances from "../ui/finances/finances";

const FinanceRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  return (
    <FinanceContainer>
      <RedirectAlertModal
        dispatch={dispatch}
        clearRedirection={clearRedirection}
      />
      <Switch>
        <Route exact path={`${path}/${FINANCES}`}>
          <Finances />
        </Route>
        <Route exact path={`${path}/${HOLIDAYS}`}>
          <Holidays />
        </Route>
        <Route exact path={`${path}/${HOLIDAYS}/:userId`}>
          <HolidaysUser />
        </Route>
        <Route path={`${path}/${HOLIDAYS_TEMPLATES}`}>
          <HolidaysTemplates />
        </Route>
        <Route>
          <Redirect to={`${path}/${FINANCES}`} />
        </Route>
      </Switch>
    </FinanceContainer>
  );
};

export default FinanceRoutes;
