import { FIELD_CASES } from "../constants";

// TODO - if new project view accepted consider to delete

const setChipColors = (field?: keyof typeof FIELD_CASES): string => {
  switch (field) {
    case FIELD_CASES.PROJECT:
      return "text-neutralPrimary-85 border-primary-50 bg-primary-50";
    case FIELD_CASES.BENCH:
      return "text-neutralPrimary-85 bg-neutralSecondary-30 border-neutralSecondary-30";
    case FIELD_CASES.B2B:
      return "text-neutralPrimary-85 border-error-60 bg-error-60";
    case FIELD_CASES.UOP:
      return "text-neutralPrimary-85 border-success-60 bg-success-60";
    case FIELD_CASES.SENIOR:
      return "text-neutralPrimary-85 border-primary-30 bg-primary-30";
    case FIELD_CASES.REGULAR:
    case FIELD_CASES.REGULAR_PLUS:
      return "text-neutralPrimary-85 border-success-60 bg-success-60";
    case FIELD_CASES.JUNIOR:
    case FIELD_CASES.JUNIOR_PLUS:
      return "text-neutralPrimary-85 border-primary-70 bg-primary-70";
    case FIELD_CASES.INTERNSHIP:
      return "text-neutralPrimary-85 border-other-cyan bg-other-cyan";
    case FIELD_CASES.ROLE:
      return "text-neutralPrimary-85 border-neutralPrimary-40 bg-neutralPrimary-40";
    case FIELD_CASES.SPECIALIST:
      return "text-neutralPrimary-85 bg-success-40 border-success-40";
    case FIELD_CASES.OPERATIVE:
      return "text-neutralPrimary-85 bg-neutralSecondary-41 border-neutralSecondary-41";
    case FIELD_CASES.DOMAIN:
      return "text-neutralPrimary-85 border-secondary-70 bg-secondary-70";
    default:
      return "text-neutralPrimary-85 border-primary-50 bg-primary-50";
  }
};

export default setChipColors;
