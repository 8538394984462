import { ReactElement } from "react";
import { Dayjs } from "dayjs";
import {
  TimeTrackerInput,
  TimeTrackerLabel,
  Tooltip,
  useThemeMode,
} from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import { uid } from "react-uid";
import { ReactComponent as PinIcon } from "../../../../images/timesheet/timelog/pinned.svg";
import { focusVisibleStyles } from "../../../../shared";
import { ReactComponent as FileData } from "../../../../images/timesheet/timelog/file-data.svg";
import { TIME_INPUT, TOTAL_TIME_COUNTED } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import Loader from "./loader";
import styles from "../styles.module.css";
import { LoaderRowProp } from "./types";

const LoaderRow = ({ timeFrame }: LoaderRowProp): ReactElement => {
  const { isHighContrast } = useThemeMode();
  const { t } = useTranslationWithNamespace();

  const startOfWeek: Dayjs = timeFrame[0];

  const timeFrameAllDays: Dayjs[] = [...Array(7)].map(
    (_, index: number): Dayjs => startOfWeek.add(index, "day"),
  );

  return (
    <div
      className={classJoin(
        "flex flex-col bg-neutralPrimary-100 my-3 rounded-lg",
        isHighContrast ? "border border-primary-60" : "border-none",
      )}
    >
      <div className={styles.rowContainer}>
        <div
          className="flex items-center justify-start gap-5 ml-5"
          style={{ minWidth: "20rem" }}
        >
          <Tooltip
            placement="top"
            content={
              <span>{t("timesheet.timelog.sections.unpinnedTooltip")}</span>
            }
          >
            <PinIcon
              className={classJoin(
                "fill-current cursor-pointer rounded-md w-5 h-5 mt-1",
                "text-neutralSecondary-60 hover:text-neutralPrimary-30",
                focusVisibleStyles,
              )}
            />
          </Tooltip>
          <div className="flex items-center gap-4 w-full">
            <Loader />
            <Loader />
          </div>
        </div>
        <div className={styles.timeEntriesContainer}>
          {timeFrameAllDays.map((date: Dayjs) => (
            <TimeTrackerInput
              additionalContainerClass={classJoin(
                styles.weekendEntry,
                "h-full w-full flex items-center justify-center",
              )}
              key={uid(date)}
              dataCy={`${TIME_INPUT}-${date}-loader`}
              Icon={FileData}
              placeholder="HHMM"
              disabled
            />
          ))}
        </div>
        <TimeTrackerLabel
          text="00:00"
          dataCy={TOTAL_TIME_COUNTED}
          additionalClassName="self-center py-6"
        />
        <span />
      </div>
    </div>
  );
};

export default LoaderRow;
