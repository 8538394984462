import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { HH_NAMESPACE, HHTranslationEN } from "@ps/hh";
import {
  TIME_TRACKER_NAMESPACE,
  TimeTrackerTranslationEN,
} from "@ps/time-tracker";
import {
  UI_COMPONENT_NAMESPACE,
  uiComponentTranslationEN,
} from "@ps/ui-components";
import {
  PERMISSIONS_NAMESPACE,
  PermissionsTranslationEN,
} from "@ps/permissions";
import {
  ALERT_MODAL_NAMESPACE,
  alertModalTranslationEN,
} from "@ps/alert-modal";
import {
  PDF_GENERATOR_NAMESPACE,
  pdfGeneratorTranslationEN,
} from "@ps/pdf-generator";
import translationEN from "i18n/en";
import { IS_PRODUCTION } from "../shared/constants";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !IS_PRODUCTION,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: translationEN,
        [HH_NAMESPACE]: HHTranslationEN,
        [UI_COMPONENT_NAMESPACE]: uiComponentTranslationEN,
        [TIME_TRACKER_NAMESPACE]: TimeTrackerTranslationEN,
        [PERMISSIONS_NAMESPACE]: PermissionsTranslationEN,
        [ALERT_MODAL_NAMESPACE]: alertModalTranslationEN,
        [PDF_GENERATOR_NAMESPACE]: pdfGeneratorTranslationEN,
      },
    },
  });

export default i18n;
