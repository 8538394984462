export const DEGREE = "degree";
export const COURSE = "course";
export const SCHOOL_NAME = "schoolName";
export const FIELD_OF_STUDY = "fieldOfStudy";
export const START_DATE = "startDate";
export const END_DATE = "endDate";
export const DESCRIPTION = "description";
export const TRAINER = "trainer";
export const COURSE_NAME = "courseName";
export const EDUCATION = "education";
export const PLACEHOLDER = "Placeholder";

export const DEGREES = "degrees";
export const COURSES = "courses";
