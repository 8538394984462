// eslint-disable-next-line import/prefer-default-export
export const DICTIONARIES = {
  DOMAIN: "domain",
  PROFESSION: "profession",
  PROJECT_ROLE: "project_role",
  SENIORITY: "seniority",
  TECHNOLOGY: "technology",
  COUNTRY: "country",
  OPERATIVE_PROFESSIONS: "operative_profession",
};
