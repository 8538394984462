export const FOLDERS = "folders";
export const ADD_NEW_FOLDER = "addNewFolder";
export const FOLDER_NAME = "folderName";
export const CLIENT = "client";
export const CLIENT_NAME = "clientName";
export const ADD_CLIENT = "addClient";
export const FOLDER_URL = "folderUrl";
export const FOLDERS_PIN = "foldersPIN";
export const RESUMES = "resumes";
export const LAST_UPDATE = "lastUpdate";
export const STATUS = "status";
export const ACTIVE = "active";
export const ARCHIVED = "archived";
export const MORE = "more";
export const CREATION_DATE = "creationDate";
export const ANONYMISE = "anonymise";
export const ANONYMIZED = "anonymized";
export const ADD_RESUME = "addResume";
export const FOLDER = "folder";
export const CLIENT_ID = "clientId";
export const COPIED = "copied";

export const BY_NAME = "byName";
export const BY_DATE = "byDate";
export const BY_STATUS = "byStatus";

export const TIME_ZONE = "timeZone";
export const ROLE = "role";
export const EXPERIENCE = "experience";
export const LOCATION = "location";
export const LANGUAGES = "languages";
export const WORK = "work";
export const AVATAR = "avatar";
export const PROFESSION = "profession";
export const SENIORITY = "seniority";
export const OVERLAP = "overlap";
export const COMFORTABLE_HOURS = "comfortableHours";
export const ASC = "asc";
export const DESC = "desc";
export const PROFESSIONS = "professions";
export const PROPOSAL_FOLDER = "proposalFolder";
export const COUNTRY = "country";

export const SPECIALIST = "specialist";

export const createFolderErrorsInitialState = {
  folderName: false,
  clientId: false,
};

export const APPROVED = "approved";
export const REJECTED = "rejected";

export const initialCheckersState = {
  [ACTIVE]: true,
  [ARCHIVED]: false,
};
