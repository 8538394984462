import { KeyboardEvent, ReactElement } from "react";
import { AnyAction } from "redux";
import { useDispatch } from "redux-react-hook";
import { Checkbox, TableLabel, CircleButton } from "@ps/ui-components";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../../hooks";
import {
  setSorterByProperty,
  filterHolidaysUsersByProperty,
} from "../../../store";
import { HolidaysUsersModel, HolidaysUserModel } from "../../../models";
import { BASE_TRANSLATION_PATH, HOLIDAYS } from "../constants";
import { LabelProps, LabelMapState, LabelMapStateReturn } from "./types";
import { filterBenchUsersByProperty } from "../../../../bench-domain/store";
import Keys from "../../../../shared/keys";

const Label = ({
  field,
  nestedField,
  isSortable,
  filterOptions,
  holidaysUsers,
}: LabelProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();

  const mapState = (state: LabelMapState): LabelMapStateReturn => ({
    activeSorter: state.holidays.sorter,
    filters: state.holidays.filters,
  });

  const { activeSorter, filters } = useMappedStateSelector(mapState);

  const handleOnClickSorter = (): AnyAction =>
    setSorterByProperty(
      dispatch,
      activeSorter.order === "asc" && activeSorter.name === field
        ? "desc"
        : "asc",
      field,
    );

  const countUsersWithSpecificOption = (optionId: string): number => {
    const filteredUsers: HolidaysUsersModel =
      holidaysUsers?.filter((user: HolidaysUserModel): boolean =>
        nestedField
          ? user?.[nestedField]?.[field] === optionId
          : user?.[field] === optionId,
      ) || [];
    return filteredUsers?.length;
  };

  return (
    <TableLabel
      dataCy={`${HOLIDAYS}_${field}`}
      sorting={
        isSortable
          ? {
              byName: field,
              isActive: activeSorter.name === field,
              onClick: handleOnClickSorter,
            }
          : undefined
      }
      filtering={
        filterOptions?.length
          ? {
              checked: filters?.[field],
              dropdownOverlay: (
                <div
                  className="flex flex-col p-4 gap-4 h-auto overflow-y-auto"
                  style={{ maxHeight: "50vh" }}
                  tabIndex={-1}
                >
                  {filterOptions?.map((option: string): ReactElement => {
                    const isChecked: boolean = filters[field]?.includes(option);

                    return (
                      <div
                        className="flex items-center gap-2 justify-between"
                        key={option}
                        tabIndex={0}
                        role="button"
                        onClick={(): AnyAction =>
                          filterHolidaysUsersByProperty(
                            dispatch,
                            field,
                            option,
                            isChecked,
                          )
                        }
                        onKeyDown={(
                          event: KeyboardEvent<HTMLInputElement>,
                        ): void => {
                          if (event?.code === Keys.SPACE) {
                            event.preventDefault();
                            filterHolidaysUsersByProperty(
                              dispatch,
                              field,
                              option,
                              isChecked,
                            );
                          }
                        }}
                      >
                        <div className="flex items-center gap-2">
                          <Checkbox
                            dataCy={`${HOLIDAYS}_filter_${option}`}
                            checked={isChecked}
                            tabIndex={-1}
                          />
                          <span className="capitalize">{option}</span>
                        </div>

                        <CircleButton
                          dataCy={HOLIDAYS}
                          activeNumber={countUsersWithSpecificOption(option)}
                          width="w-6"
                          height="w-6"
                          textSize="text-sm font-semibold"
                          nonActiveStyles="border-primary-80 text-primary-50 bg-secondary-95"
                        />
                      </div>
                    );
                  })}
                </div>
              ),
            }
          : undefined
      }
    >
      {t(`${BASE_TRANSLATION_PATH}.${field}`)}
    </TableLabel>
  );
};

export default Label;
