import { ReactElement, useState } from "react";
import { TableHeader } from "@ps/ui-components";
import {
  BENCH,
  AVAILABILITY,
  NAME,
  PROFESSION,
  SENIORITY,
  FOLDER_STATUS,
  REASON,
  DAYS,
  PROJECTS,
} from "../../constants";
import styles from "../styles.module.css";
import Label from "./label";
import {
  SortBarProps,
  SortBarStateProps,
  SortBarStatePropsReturn,
} from "./types";
import { IS_PRODUCTION } from "../../../shared/constants";
import { useMappedStateSelector } from "../../../hooks";
import { selectBenchFilterOptions } from "../../utils";

const SortBar = ({
  filterOptions,
  benchUsers,
}: SortBarProps): ReactElement | null => {
  const [searchValue, setSearchValue] = useState("");

  const mapState = (state: SortBarStateProps): SortBarStatePropsReturn => ({
    professionDict: state?.dictionaries?.profession || [],
    seniorityDict: state?.dictionaries?.seniority || [],
  });

  const { professionDict, seniorityDict } = useMappedStateSelector(mapState);
  return (
    <TableHeader
      columnsStyles={
        IS_PRODUCTION ? styles.tableColumnsProduction : styles.tableColumns
      }
      dataCy={BENCH}
    >
      <Label field={AVAILABILITY} isSortable />
      <Label
        field={NAME}
        isSortable
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        filterOptions={selectBenchFilterOptions(
          filterOptions.namesFilterOptions,
          searchValue,
        )}
        benchUsers={benchUsers}
      />
      <Label
        field={PROFESSION}
        isSortable
        filterOptions={selectBenchFilterOptions(
          filterOptions.professionsFilterOptions,
          searchValue,
        )}
        benchUsers={benchUsers}
        dict={professionDict}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
      />
      <Label
        field={SENIORITY}
        isSortable
        filterOptions={selectBenchFilterOptions(
          filterOptions.senioritiesFilterOptions,
          searchValue,
        )}
        benchUsers={benchUsers}
        dict={seniorityDict}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
      />
      <Label field={PROJECTS} />
      <Label field={FOLDER_STATUS} />
      <Label
        field={REASON}
        isSortable
        filterOptions={selectBenchFilterOptions(
          filterOptions.reasonsFilterOptions,
          searchValue,
        )}
        benchUsers={benchUsers}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
      />
      {IS_PRODUCTION ? null : <Label field={DAYS} isSortable />}
      <span />
    </TableHeader>
  );
};

export default SortBar;
