import { ReactElement, KeyboardEvent } from "react";
import { useTranslationWithNamespace } from "../../../../../hooks";
import Keys from "../../../../../shared/keys";
import { EmptySectionProps } from "./types";
import { NOTES, PROSPECT_EDIT } from "../../../../constants";

const EmptySection = ({
  onAddClick,
  translationPath,
  noElementsTranslation,
  toDoTranslation,
  addNewTranslation,
  Image,
  archived,
}: EmptySectionProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <div className="flex w-full h-full justify-center items-center font-semibold gap-x-6 p-10">
      {Image}
      <div
        className="h-full flex flex-col gap-y-6"
        data-cy={`${PROSPECT_EDIT}-${NOTES}_emptySection`}
      >
        <h3 className="text-xl text-neutralPrimary-20">
          {t(`${translationPath}.${noElementsTranslation}`)}
        </h3>
        {!archived && (
          <div className="flex gap-x-2">
            <span className="text-neutralPrimary-40">
              {t(`${translationPath}.${toDoTranslation}`)}
            </span>
            <span
              role="button"
              tabIndex={0}
              className="text-neutralPrimary-30 hover:text-neutralPrimary-20 underline"
              onClick={onAddClick}
              onKeyDown={(e: KeyboardEvent<HTMLSpanElement>) => {
                if (e.key === Keys.ENTER) {
                  onAddClick();
                }
              }}
            >
              {t(`${translationPath}.${addNewTranslation}`)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptySection;
