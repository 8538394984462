/* eslint @typescript-eslint/no-explicit-any: 0 */
import {
  dayJSToISO,
  formatHoursAndMinutes,
  getItemFromDictionary,
  ISOToDayJs,
  returnObjectIds,
} from "@ps/utils";
import moment from "moment-timezone";
import { Dayjs } from "dayjs";
import {
  CareerSpanModel,
  DocumentsInput,
  DocumentsOutput,
  GoalsDataModel,
  GoalsModel,
  GoalsPayloadModel,
  Item,
  TWorkingHours,
  WorkingHoursModel,
} from "./types";
import { CURRENT_LANGUAGE_CODE } from "../../i18n/constants";
import { DictionaryEntryModel, DictionaryModel } from "../../../index";
import { CURRENT_YEAR } from "../constants";

export const prepareWorkingHoursToDisplay = (
  workingHours: TWorkingHours,
): WorkingHoursModel | null => {
  if (workingHours?.comfortable?.start || workingHours?.possible?.start) {
    return {
      comfortable: {
        start: formatHoursAndMinutes(workingHours.comfortable?.start),
        end: formatHoursAndMinutes(workingHours.comfortable?.end),
      },
      possible: {
        start: formatHoursAndMinutes(workingHours.possible?.start),
        end: formatHoursAndMinutes(workingHours.possible?.end),
      },
      zone: {
        value: workingHours.zone,
        label: `${moment.tz(workingHours.zone).format("Z z")} (${
          workingHours.zone
        })`,
        abbreviation: moment.tz(workingHours.zone).zoneAbbr(),
      },
    };
  }
  return null;
};

export const prepareWorkingHours = (
  workingHours: WorkingHoursModel,
): TWorkingHours | null => {
  if (workingHours?.comfortable?.start || workingHours?.possible?.start) {
    return {
      comfortable: {
        start: formatHoursAndMinutes(workingHours.comfortable?.start),
        end: formatHoursAndMinutes(workingHours.comfortable?.end),
      },
      possible: {
        start: formatHoursAndMinutes(workingHours.possible?.start),
        end: formatHoursAndMinutes(workingHours.possible?.end),
      },
      zone: workingHours.zone.value,
    };
  }
  return null;
};

export const prepareDocumentsToArray = (
  documents: DocumentsInput["documents"],
  mode = "toDayjs",
  getCountryName?: DocumentsInput["getCountryName"],
): DocumentsOutput => {
  if (!documents?.length) return [];
  return documents
    .filter((document) => document?.country)
    .map((item) => ({
      country:
        getCountryName && item?.country
          ? {
              value: item.country,

              label: getCountryName(item.country, CURRENT_LANGUAGE_CODE, {
                select: "official",
              }),
            }
          : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            item?.country?.value,
      expirationDate:
        mode === "toDayjs"
          ? ISOToDayJs(item.expirationDate as Dayjs | null | undefined)
          : dayJSToISO(item.expirationDate as Dayjs | null | undefined),
    }));
};

export const prepareCareerPauseModel = (
  experience?: number,
): { years: number; months: number } => {
  if (!experience) return { years: 0, months: 0 };

  const years = parseInt(String(experience / 12), 10);
  const months = parseInt(String(experience % 12), 10);

  return { years, months };
};

export const countMonthsOfPause = (
  data: CareerSpanModel["gapMonths"],
): number => {
  if (!data?.years) return data?.months || 0;
  return data.years * 12 + (data.months || 0);
};

export const prepareSelectDataToSave = (
  data?: { value: string; label: string }[] | string[],
): string[] => (data?.length ? data.map((item) => item.value || item) : []);

export const prepareNationalitiesToDisplay = (
  nationalities: string[],
  getCountryName: DocumentsInput["getCountryName"],
): { value: string; label: string }[] | [] =>
  nationalities?.length
    ? nationalities.map((item) => ({
        value: item,
        label: getCountryName(item, CURRENT_LANGUAGE_CODE, {
          select: "official",
        }),
      }))
    : [];

export const prepareResidenceToDisplay = (
  countryOfResidence: string | null | undefined,
  getCountryName: DocumentsInput["getCountryName"],
): { value: string; label: string } | null => {
  return countryOfResidence
    ? {
        label: getCountryName(countryOfResidence, CURRENT_LANGUAGE_CODE, {
          select: "official",
        }),
        value: countryOfResidence,
      }
    : null;
};

export const sortArrayByDates = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  array: any[],
  startDateFieldName = "startDate",
  endDateFieldName = "endDate",
): any => {
  const onlyWithEndDates = array
    .filter((el) => !!el[endDateFieldName])
    .sort((a, b) =>
      a[endDateFieldName]?.isAfter(b?.[endDateFieldName]) ? -1 : 1,
    );
  const onlyPresent = array
    .filter((el) => !el[endDateFieldName])
    .sort((a, b) =>
      a[startDateFieldName]?.isAfter(b?.[startDateFieldName] || "") ? -1 : 1,
    );
  return [...onlyPresent, ...onlyWithEndDates];
};

export const sortArrayByDate = (array: any[]): any =>
  array.sort((a, b) => (a?.date?.isAfter(b?.date) ? -1 : 1));

export const transformGoals = (
  goals: GoalsPayloadModel,
  technologyDict?: DictionaryModel,
): GoalsDataModel => {
  let newObj: GoalsPayloadModel = {};
  if (!Object.prototype.hasOwnProperty.call(goals, CURRENT_YEAR)) {
    newObj = { ...goals, [CURRENT_YEAR]: { note: null, technologies: [] } };
  } else {
    newObj = goals;
  }

  return Object.keys(newObj)
    .map((key: string) => ({
      year: key,
      note: newObj[key].note,
      technologies:
        newObj[key].technologies.length && technologyDict
          ? newObj[key].technologies.map((tech: string) =>
              getItemFromDictionary(technologyDict, tech),
            )
          : [],
    }))
    .reduce(
      (
        obj: {},
        item: {
          technologies: (DictionaryEntryModel | undefined)[];
          note: string | null | undefined;
          year: string;
        },
      ) => {
        return {
          ...obj,
          [item.year]: {
            note: item.note,
            technologies: item.technologies,
          },
        };
      },
      {},
    );
};

export const normalizeGoalsIds = (goals: GoalsDataModel) => {
  if (!goals) {
    return {};
  }

  return Object.keys(goals)
    .map((key) => ({
      year: key,
      note: goals[key].note,
      technologies: returnObjectIds(goals[key].technologies),
    }))
    .reduce((obj: {}, item: Item) => {
      const { year, ...rest } = item;
      return { ...obj, [year]: rest };
    }, {});
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const filterCurrentYear = (goals: GoalsDataModel): GoalsModel =>
  Object.keys(goals)
    .map((key: string) => ({
      year: key,
      note: goals[key].note,
      techStack: goals[key].technologies.map((t: DictionaryEntryModel) => ({
        technologyId: t.id,
        ...t,
      })),
    }))
    // TODO add types
    .filter((g) => g.year === CURRENT_YEAR && g.note !== null);

export const filterCurrentYearWithTechnologyDict = (
  goals: GoalsPayloadModel,
  technologyDict: DictionaryModel,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
): any =>
  Object.keys(goals)
    .map((key: string) => ({
      year: key,
      note: goals[key].note,
      techStack: goals[key].technologies.map((t: string) => ({
        ...getItemFromDictionary(technologyDict, t),
        technologyId: t,
      })),
    }))
    .filter((g) => g.year === CURRENT_YEAR && g.note !== null);

export const normalizeSocials = (social: string[]): { path: string }[] =>
  social.length ? social.map((s) => ({ path: s })) : [];
