import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../../../hooks";
import { ReactComponent as NoResultsIcon } from "../../../../images/team/skillsSearchNoResults.svg";

const TRANSLATION_PATH = "team.skillsSearch";

const NoResults = (): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="flex items-center w-full gap-12 justify-center mt-24">
      <NoResultsIcon className="w-1/4 h-1/4" />
      <div className="flex flex-col gap-5 justify-start text-neutralPrimary-20">
        <span className="font-bold text-2xl">
          {t(`${TRANSLATION_PATH}.noInformation`)}
        </span>
        <span className="text-lg">{t(`${TRANSLATION_PATH}.tryAgain`)}</span>
      </div>
    </div>
  );
};

export default NoResults;
