import { ReactElement, useContext, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { LoadingMainPage, PermissionContext } from "@ps/ui-components";
import { isAPIError } from "@ps/api-utils";
import SectionWrapper from "../sectionWrapper";
import EditDetails from "./editDetails";
import ViewDetails from "./viewDetails";
import { toggleEditMode } from "../../../../../store";
import { DETAILS } from "../../../../constants";
import { ProjectService } from "../../../../services";
import { fetchProject } from "../../../../store";
import { useMappedStateSelector } from "../../../../../hooks";
import { fetchUsers } from "../../../../../user-domain";
import { fetchClients } from "../../../../../client-domain";
import { DetailsMapState, DetailsMapStateReturn } from "./types";
import {
  DICTIONARIES,
  DictionariesService,
  PreparedDictionaryEntry,
  NewDictionaryEntry,
  fetchDictionaryStart,
  fetchDictionarySuccess,
  fetchDictionaryError,
} from "../../../../../dictionaries";
import { PROJECT_FULL_ACCESS } from "../../../../../shared/permissions";

const Details = (): ReactElement => {
  const { hasPermission } = useContext(PermissionContext);
  const mapState = (state: DetailsMapState): DetailsMapStateReturn => ({
    details: state.projects?.project?.details || {},
    isFetchingProjectDetails: state.requestStatus.isFetchingProjectDetails,
    technologyDict: state?.dictionaries?.technology || [],
  });
  const dispatch = useDispatch();
  const { details, isFetchingProjectDetails, technologyDict } =
    useMappedStateSelector(mapState);
  const params = useParams<{ id: string }>();

  const methods = useForm({
    defaultValues: details,
  });

  useEffect(() => {
    if (hasPermission(PROJECT_FULL_ACCESS)) {
      fetchUsers(dispatch);
      fetchClients(dispatch);
    }
  }, []);

  useEffect(() => {
    methods.reset(details);
  }, [details]);

  const mapTechnologies = async (
    techStack: (PreparedDictionaryEntry | NewDictionaryEntry)[] | [],
  ): Promise<(PreparedDictionaryEntry | NewDictionaryEntry)[]> => {
    if (!techStack.length) return [];
    const preparedTechnologies = await Promise.allSettled(
      techStack.map(
        (technology: PreparedDictionaryEntry | NewDictionaryEntry) =>
          DictionariesService.addDictionaryEntry(
            technology,
            DICTIONARIES.TECHNOLOGY,
          ),
      ),
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return preparedTechnologies.map((item) => item.value);
  };

  const onSubmit = async (data) => {
    const mappedTechStack = await mapTechnologies(data.techStack);
    const projectDetailsWithMappedTechStack = {
      ...data,
      techStack: mappedTechStack,
    };
    await ProjectService.updateProject(
      params.id,
      projectDetailsWithMappedTechStack,
    );
    // Redux do not update immediately state, so there was a need to pass response after dictionary fetch
    if (data?.techStack?.find((tech: PreparedDictionaryEntry) => tech?.isNew)) {
      dispatch(fetchDictionaryStart());
      const response = await DictionariesService.fetchDictionary(
        DICTIONARIES.TECHNOLOGY,
      );
      if (isAPIError(response)) {
        dispatch(fetchDictionaryError(response));
      } else {
        dispatch(fetchDictionarySuccess(response, DICTIONARIES.TECHNOLOGY));
        await fetchProject(params.id, dispatch, response);
        dispatch(toggleEditMode(""));
      }
    } else {
      await fetchProject(params.id, dispatch, technologyDict);
      dispatch(toggleEditMode(""));
    }
  };

  return isFetchingProjectDetails ? (
    <LoadingMainPage additionalClassName="bg-neutralPrimary-85" />
  ) : (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="w-full h-full">
        <SectionWrapper
          Edit={EditDetails}
          name={DETAILS}
          section={DETAILS}
          View={ViewDetails}
          onSubmit={onSubmit}
        />
      </form>
    </FormProvider>
  );
};

export default Details;
