import { ReactElement } from "react";
import dayjs from "dayjs";
import { colorFormatters } from "@ps/utils";
import { Card, Chip, Label, StackedBarChart } from "@ps/ui-components";
import { TIME_TRACKER_BAR_CHART } from "../../../shared/data-cy";
import prepareProjectsWithColours from "../../../shared/utils/prepareProjectsWithColors";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import {
  BarChartColumnMapState,
  BarChartColumnMapStateReturn,
  BarChartProps,
  IDateAsName,
} from "./types";
import { TIME_FORMAT_DD_MM_YYYY } from "../../../shared/constants";

const BarChartColumn = ({ timeFrame }: BarChartProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const mapState = (
    state: BarChartColumnMapState,
  ): BarChartColumnMapStateReturn => ({
    barChartData: state?.barChartData?.entries || [],
    trackedProjectIds: state?.barChartData?.projectIds || [],
    allProjectsList: state?.projectsList || [],
  });

  const startDate = dayjs(timeFrame?.[0]);
  const endDate = dayjs(timeFrame?.[1]);

  const isYearView: boolean = endDate.diff(startDate, "month") + 1 === 12;

  const { barChartData, trackedProjectIds, allProjectsList } =
    useMappedStateSelector(mapState);

  const entriesToArray: IDateAsName[] = barChartData?.map((e: IDateAsName) => ({
    ...e,
    name: isYearView
      ? dayjs(e.name, TIME_FORMAT_DD_MM_YYYY).format("MMM")
      : e.name,
  }));

  const mergeArrayByName = (data: IDateAsName[]): IDateAsName[] => {
    const mergedData: IDateAsName[] = Object.values(
      data.reduce(
        (result: { [name: string]: IDateAsName }, current: IDateAsName) => {
          const { name } = current;
          if (!result[name]) {
            // eslint-disable-next-line no-param-reassign
            result[name] = { ...current };
          } else {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in current) {
              if (key !== "name") {
                // eslint-disable-next-line no-param-reassign
                result[name][key] =
                  (+result[name][key] || 0) + (current[key] as number);
              }
            }
          }
          return result;
        },
        {},
      ),
    );

    return mergedData;
  };

  const normalizedEntries = mergeArrayByName(entriesToArray);

  const projectColours = prepareProjectsWithColours(
    trackedProjectIds,
    allProjectsList,
  );

  const renderProjectChip = (item: string): ReactElement => {
    const currentProject = allProjectsList.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (project: { id: string; [item: string]: any }) => project.id === item,
    );
    return (
      <Chip
        color={colorFormatters.hexToRgbString(currentProject?.details?.color)}
        dataCy={item}
        key={item}
        label={currentProject?.details?.name}
      />
    );
  };

  return (
    <Card
      dataCy={TIME_TRACKER_BAR_CHART}
      additionalCardClassName="h-full w-full p-8 relative"
    >
      <StackedBarChart
        dataCy={TIME_TRACKER_BAR_CHART}
        allProjects={allProjectsList}
        colors={projectColours}
        data={normalizedEntries}
        keys={trackedProjectIds}
        chartHeight="40rem"
        isYear={isYearView}
        isMonth={normalizedEntries.length > 7}
      />
      <hr className="my-10" />
      <Label dataCy={TIME_TRACKER_BAR_CHART} text={t("projects")}>
        <div className="flex gap-4 items-center flex-wrap">
          {trackedProjectIds.length ? (
            trackedProjectIds.map((item: string) => renderProjectChip(item))
          ) : (
            <span className="text-neutralSecondary-60">
              {t("noInformation")}
            </span>
          )}
        </div>
      </Label>
    </Card>
  );
};

export default BarChartColumn;
