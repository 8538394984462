import {
  IDictionaryEntry,
  IDictionaryEntryWithData,
  INormalizedDictionaryEntry,
} from "./types";

const isDictionaryEntry = (dict: IDictionaryEntry): dict is IDictionaryEntry =>
  (<IDictionaryEntry>dict).name !== undefined &&
  (<IDictionaryEntry>dict).id !== undefined;

export const mapTechnologyWithOnlyAlphanumeric = (
  data: unknown[],
): INormalizedDictionaryEntry[] => {
  if (Array.isArray(data) && data.length && isDictionaryEntry(data[0]))
    return data.map((item) => ({
      ...item,
      normalizedName: item.name.replace(/[\W_]+/g, "").toLowerCase(),
      technologyId: item.id,
      // TODO add alases when BE will be ready
    }));
  return [];
};

export const prepareOutput = (
  data: IDictionaryEntryWithData[],
): INormalizedDictionaryEntry[] => data.map((element) => element.item);

export const sortResult = (
  data: IDictionaryEntryWithData[],
): IDictionaryEntryWithData[] =>
  data.sort((a, b) => (b.score > a.score ? 1 : -1));

export const sumScores = (...args: any): IDictionaryEntryWithData[] | [] => {
  const data = JSON.parse(JSON.stringify(args));
  let result: IDictionaryEntryWithData[] = [];
  if (args.length === 0) return result;
  if (args.length === 1) return data;
  result = data.shift();
  const rest = data;

  rest.forEach((arr: IDictionaryEntryWithData[]) =>
    arr.forEach((arrItem) => {
      if (result.some((item) => item.refIndex === arrItem.refIndex)) {
        result.forEach((el, index) => {
          if (el.refIndex === arrItem.refIndex) {
            result[index] = {
              ...result[index],
              score: result[index].score + arrItem.score,
            };
          }
        });
      } else {
        result.push(arrItem);
      }
    }),
  );

  return result;
};
