import {
  ReactElement,
  useState,
  Fragment,
  useEffect,
  KeyboardEvent,
} from "react";
import { ProjectModel } from "@ps/hh";
import { useDispatch } from "redux-react-hook";
import {
  Card,
  TimeTrackerProjectName,
  TimeTrackerLabel,
} from "@ps/ui-components";
import { colorFormatters, minutesToHHMM, classJoin } from "@ps/utils";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { ReportModel, ReportsModel } from "../../../models";
import { REPORTS } from "../../../shared/constants";
import {
  ExtendedReportModel,
  FlattenEntry,
  IActiveSorter,
  TableMapState,
  TableMapStateReturn,
} from "./types";
import {
  flatEntriesData,
  groupDataBasedOnParameter,
  countTotalInGroup,
} from "./helpers";
import { TITLE } from "../constants";
import GroupSelectsRow from "./groupSelectsRow";
import styles from "./styles.module.css";
import { updateReportsGroupBy } from "../../../store/actions";
import { GroupNames } from "../../../store";
import { ReactComponent as LockIcon } from "../../../images/reports/lock.svg";
import { ReactComponent as DownArrow } from "../../../images/reports/arrow-down.svg";
import AmountDot from "./amountDot";
import { updateActiveSorter } from "../../../store/actions/reportsActions";
import { Keys } from "../../../shared";
import TableHeader from "./tableHeader";
import { ROW } from "../../../shared/data-cy";

const defaultSorterParameters: IActiveSorter = {
  order: "asc",
  name: TITLE,
};

const NO_GROUP = "noGroup";

const Table = (): ReactElement => {
  const [expandedRows, setExpandedRows] = useState<{
    [key: number]: [number, number];
  }>({});

  const dispatch = useDispatch();
  const { t } = useTranslationWithNamespace();

  const mapState = (state: TableMapState): TableMapStateReturn => ({
    activeSorter: state?.reports?.activeSorter,
    groupedBy: state?.reports?.groupedBy,
    projectsList: state.projectsList,
    report: state?.reports?.report || [],
  });

  const { report, groupedBy, activeSorter, projectsList } =
    useMappedStateSelector(mapState) as {
      report: ReportsModel;
      groupedBy: GroupNames;
      activeSorter: IActiveSorter;
      projectsList: ProjectModel[];
    };

  const extendReport = (
    reportData: ReportsModel = [],
    projectsData: ProjectModel[] = [],
  ) =>
    reportData.reduce(
      (acc: ExtendedReportModel[], singleReportItem: ReportModel) => {
        const foundElement = projectsData.find(
          (item: ProjectModel) => item.id === singleReportItem.projectId,
        );
        if (singleReportItem.projectId === foundElement?.id)
          return [
            ...acc,
            { ...singleReportItem, isPublic: foundElement.public },
          ];
        return acc;
      },
      [],
    );

  const [extendedReport, setExtendedReport] = useState<ExtendedReportModel[]>(
    [],
  );

  useEffect(() => {
    if (report?.length && projectsList?.length) {
      setExtendedReport(extendReport(report, projectsList));
    }
  }, [report, projectsList]);

  const groupedData: Record<string, FlattenEntry[]> = groupDataBasedOnParameter(
    flatEntriesData(extendedReport),
    groupedBy?.group0?.value,
  );
  const sortedGroupedData: Record<string, FlattenEntry[]> = Object.keys(
    groupedData,
  )
    .sort()
    .reduce((acc: Record<string, FlattenEntry[]>, entry: string) => {
      acc[entry] = groupedData[entry];
      return acc;
    }, {});

  const [sortedGroupData, setSortedGroupData] =
    useState<Record<string, FlattenEntry[]>>(sortedGroupedData);

  useEffect(() => {
    setSortedGroupData(sortedGroupedData);
  }, [extendedReport, groupedBy]);

  useEffect(() => {
    setExpandedRows({});
    updateActiveSorter(defaultSorterParameters);
  }, [groupedBy]);

  const handleOnClickExpand = (
    itemName: string,
    itemId: number | [number, number],
  ): void => {
    const tempExpanded = JSON.parse(JSON.stringify(expandedRows));
    const flattenToArray = Object.entries(tempExpanded);
    if (typeof itemId === "number") {
      setExpandedRows(
        tempExpanded[itemId]
          ? flattenToArray.reduce(
              (acc: { [key: number]: number[] }, [key, value]) =>
                parseInt(key, 10) === itemId
                  ? acc
                  : {
                      ...acc,
                      [key]: value,
                    },
              {},
            )
          : { ...tempExpanded, [itemId]: [] },
      );
    } else {
      const currentFirstRow = tempExpanded[itemId[0]];
      const currentArrayElement = currentFirstRow.find(
        (item: number) => item === itemId[1],
      );
      const updatedRows =
        typeof currentArrayElement === "number"
          ? currentFirstRow.filter(
              (item: number) => item !== currentArrayElement,
            )
          : [...currentFirstRow, itemId[1]];
      setExpandedRows({ ...expandedRows, [itemId[0]]: updatedRows });
    }
  };

  const renderRows = (
    itemId: number | [number, number],
    group: FlattenEntry[],
    groupNumber: 0 | 1 | 2,
    itemName: string,
    additionalClassName?: string,
    hasArrow = false,
  ): ReactElement => {
    const isArrowVisible = hasArrow && groupNumber !== 2;
    const nextGroupValue = groupedBy?.[`group${groupNumber + 1}`]?.value;
    const isRowOpened = !!(
      (typeof itemId === "number" && expandedRows?.[itemId]) ||
      (Array.isArray(itemId) && expandedRows?.[itemId[0]].includes(itemId[1]))
    );

    return (
      <div
        className={classJoin(
          styles.gridContainerGroupedBy,
          additionalClassName,
          "h-24 items-center border-b",
          isArrowVisible
            ? " hover:opacity-70 cursor-pointer"
            : "cursor-default",
        )}
        data-cy={`${REPORTS}-${ROW}-itemName`}
        onClick={() => isArrowVisible && handleOnClickExpand(itemName, itemId)}
        role="button"
        tabIndex={0}
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
          if (event.key === Keys.ENTER && isArrowVisible)
            handleOnClickExpand(itemName, itemId);
        }}
      >
        {groupedBy?.[`group${groupNumber}`]?.value === "projectName" ? (
          <div className="flex items-center gap-4 h-16">
            <TimeTrackerProjectName
              dataCy={itemName}
              additionalClassName="items-center gap-2"
              color={colorFormatters.prepareRGBObjectToHEX(
                group?.[0]?.projectColor,
              )}
              label={group?.[0]?.projectName || ""}
              icon={
                group?.[0]?.isPublic ? null : (
                  <LockIcon
                    style={{
                      color: colorFormatters.prepareRGBObjectToHEX(
                        group?.[0]?.projectColor,
                      ),
                    }}
                    className="fill-current h-5"
                  />
                )
              }
            />
            {nextGroupValue &&
            Object.values(groupDataBasedOnParameter(group, nextGroupValue))
              ?.length &&
            nextGroupValue !== NO_GROUP ? (
              <AmountDot
                isEmpty={isRowOpened}
                amount={
                  Object.values(
                    groupDataBasedOnParameter(group, nextGroupValue),
                  )?.length
                }
              />
            ) : (
              <div />
            )}
            <TimeTrackerLabel
              dataCy={itemName}
              text={group?.[0]?.clientName || ""}
            />
          </div>
        ) : (
          <div className="flex items-center gap-4">
            <span className="text-neutralPrimary-20">
              {groupedBy?.[`group${groupNumber}`]?.value === "rate"
                ? `x${
                    parseInt(
                      group?.[0]?.[groupedBy?.[`group${groupNumber}`]?.value],
                      10,
                    ) / 10
                  }`
                : group?.[0]?.[groupedBy?.[`group${groupNumber}`]?.value] || (
                    <span className="text-neutralPrimary-30 ">
                      {t("reports.noDescription")}
                    </span>
                  )}
            </span>
            {nextGroupValue &&
            Object.values(groupDataBasedOnParameter(group, nextGroupValue))
              ?.length &&
            nextGroupValue !== NO_GROUP ? (
              <AmountDot
                isEmpty={isRowOpened}
                amount={
                  Object.values(
                    groupDataBasedOnParameter(group, nextGroupValue),
                  )?.length
                }
              />
            ) : (
              <div />
            )}
          </div>
        )}
        <div className="font-semibold text-neutralPrimary-20">
          {minutesToHHMM(countTotalInGroup(group))}
        </div>
        {isArrowVisible ? (
          <DownArrow
            className={classJoin(
              "fill-current h-4 cursor-pointer ml-auto text-primary-50",
              isRowOpened ? "transform rotate-180" : "",
            )}
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  const updateGroupByFilters = (newData: GroupNames): void => {
    dispatch(updateReportsGroupBy(newData));
  };

  return (
    <div className="flex flex-col select-none mt-5">
      <GroupSelectsRow
        activeSorter={activeSorter}
        selectedGroups={groupedBy}
        setActiveSorter={(data) => dispatch(updateActiveSorter(data))}
        setSelectedGroups={updateGroupByFilters}
        setSortedGroupData={setSortedGroupData}
      />
      <TableHeader />
      <Card
        additionalCardClassName="h-max w-full shadow-md flex flex-col"
        dataCy={REPORTS}
      >
        {Object.keys(sortedGroupData).map(
          (itemName: string, firstRowId: number): ReactElement => {
            const group: FlattenEntry[] = sortedGroupData[itemName];

            return (
              <Fragment key={itemName}>
                {renderRows(
                  firstRowId,
                  group,
                  0,
                  itemName,
                  styles.firstRow,
                  (groupedBy?.group1.value &&
                    groupedBy?.group1.value !== NO_GROUP) ||
                    undefined,
                )}
                {expandedRows?.[firstRowId] ? (
                  <div className="w-full flex flex-col">
                    {Object.keys(
                      groupDataBasedOnParameter(group, groupedBy?.group1.value),
                    ).map(
                      (
                        firstNestedItemName: string,
                        secondRowId: number,
                      ): ReactElement => {
                        const firstNestedGroup: FlattenEntry[] =
                          groupDataBasedOnParameter(
                            group,
                            groupedBy?.group1.value,
                          )[firstNestedItemName];

                        return (
                          <Fragment key={firstNestedItemName}>
                            {renderRows(
                              [firstRowId, secondRowId],
                              firstNestedGroup,
                              1,
                              firstNestedItemName,
                              `${styles.secondRow} bg-neutralPrimary-50 bg-opacity-10 pl-20`,
                              !!(
                                groupedBy?.group2?.value?.length &&
                                groupedBy?.group2?.value !== NO_GROUP
                              ),
                            )}
                            {expandedRows?.[firstRowId].includes(secondRowId) &&
                            groupedBy?.group2.value ? (
                              <div className="bg-primary-95 h-max w-full flex flex-col rounded-md">
                                {Object.keys(
                                  groupDataBasedOnParameter(
                                    firstNestedGroup,
                                    groupedBy?.group2.value,
                                  ),
                                ).map(
                                  (
                                    secondNestedItemName: string,
                                    thirdRowId: number,
                                  ): ReactElement => {
                                    const secondNestedGroup: FlattenEntry[] =
                                      groupDataBasedOnParameter(
                                        firstNestedGroup,
                                        groupedBy?.group2.value,
                                      )[secondNestedItemName];

                                    return (
                                      <Fragment key={secondNestedItemName}>
                                        {renderRows(
                                          thirdRowId,
                                          secondNestedGroup,
                                          2,
                                          secondNestedItemName,
                                          `${styles.thirdRow} bg-neutralPrimary-50 bg-opacity-20 pl-24`,
                                        )}
                                      </Fragment>
                                    );
                                  },
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </Fragment>
                        );
                      },
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </Fragment>
            );
          },
        )}
      </Card>
    </div>
  );
};

export default Table;
