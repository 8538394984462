import { ReactComponent as NavbarTimeTracker } from "../images/navbar/navbar-time-tracker.svg";
import { ReactComponent as NavbarReports } from "../images/navbar/navbar-reports.svg";
import { ReactComponent as TimelogTab } from "../images/tabs/timelog.svg";
import { ReactComponent as PalmTab } from "../images/tabs/palm.svg";
import { PROJECT_FULL_ACCESS } from "./permissions";

export const NAMESPACE = "time-tracker";
export const TIMESHEET = "timesheet";
export const DASHBOARD = "dashboard";
export const REPORTS = "reports";

export const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === "production";

export const TIME_TRACKER_TABS = [
  {
    item: "DASHBOARD",
    icon: NavbarTimeTracker,
  },
  {
    item: "TIMESHEET",
    icon: TimelogTab,
  },
  IS_PRODUCTION
    ? null
    : {
        item: "MY_HOLIDAYS",
        icon: PalmTab,
      },
  {
    item: "REPORTS",
    icon: NavbarReports,
    permissions: [PROJECT_FULL_ACCESS],
  },
  //  TEMPORARY HIDDEN (no content inside)
  // {
  //   item: "MY_SCHEDULE",
  //   icon: MyscheduleTab,
  // },
  // {
  //   item: "CALENDAR",
  //   icon: CalendarTab,
  // },
].filter((item) => item);

export const NAVBAR_ITEMS = {
  item: "time-tracker",
  path: "time-tracker",
  icon: NavbarTimeTracker,
  defaultFocusID: "tabs-time-tracker-timesheet",
};

export const TIME_FORMAT_DDD_MMM_DD = "ddd, MMM DD";
export const TIME_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const TIME_FORMAT_DD_MM_YYYY = "DD.MM.YYYY";

export const PROJECT = "project";
export const HOLIDAY = "holiday";

export const USERS = "users";
export const PROJECTS = "projects";
export const CLIENTS = "clients";
export const USER = "user";
export const CLIENT = "client";

export const SUCCESS_TOAST_DURATION_TIME = 2000;
