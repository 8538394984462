/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateRole
 */
export interface CreateRole {
    /**
     * 
     * @type {string}
     * @memberof CreateRole
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRole
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateUser
 */
export interface CreateUser {
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'lastName': string;
    /**
     * 
     * @type {UserRole}
     * @memberof CreateUser
     */
    'role'?: UserRole;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'group'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'cv'?: string;
}
/**
 * 
 * @export
 * @interface CreateUserError
 */
export interface CreateUserError {
    /**
     * 
     * @type {string}
     * @memberof CreateUserError
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserError
     */
    'reason': string;
}
/**
 * 
 * @export
 * @interface CreateUsers
 */
export interface CreateUsers {
    /**
     * 
     * @type {Array<CreateUser>}
     * @memberof CreateUsers
     */
    'users': Array<CreateUser>;
}
/**
 * 
 * @export
 * @interface CreatedRoleResponse
 */
export interface CreatedRoleResponse {
    /**
     * 
     * @type {string}
     * @memberof CreatedRoleResponse
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface GetRole
 */
export interface GetRole {
    /**
     * 
     * @type {string}
     * @memberof GetRole
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetRole
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetRole
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface GetRoleSummary
 */
export interface GetRoleSummary {
    /**
     * 
     * @type {string}
     * @memberof GetRoleSummary
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleSummary
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateRole
 */
export interface UpdateRole {
    /**
     * 
     * @type {string}
     * @memberof UpdateRole
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRole
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {Array<GetRoleSummary>}
     * @memberof User
     */
    'roles': Array<GetRoleSummary>;
}
/**
 * 
 * @export
 * @interface UserIdEmail
 */
export interface UserIdEmail {
    /**
     * 
     * @type {string}
     * @memberof UserIdEmail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserIdEmail
     */
    'email': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserRole = {
    Operative: 'operative',
    Specialist: 'specialist'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];


/**
 * 
 * @export
 * @interface UsersCreationFailure
 */
export interface UsersCreationFailure {
    /**
     * 
     * @type {Array<UserIdEmail>}
     * @memberof UsersCreationFailure
     */
    'created'?: Array<UserIdEmail>;
    /**
     * 
     * @type {Array<CreateUserError>}
     * @memberof UsersCreationFailure
     */
    'failed': Array<CreateUserError>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Archive User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('archive', 'userId', userId)
            const localVarPath = `/users/{userId}/archive`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new role
         * @param {CreateRole} createRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (createRole: CreateRole, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRole' is not null or undefined
            assertParamExists('createRole', 'createRole', createRole)
            const localVarPath = `/users/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create users
         * @param {CreateUsers} createUsers 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsers: async (createUsers: CreateUsers, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUsers' is not null or undefined
            assertParamExists('createUsers', 'createUsers', createUsers)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUsers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (roleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteRole', 'roleId', roleId)
            const localVarPath = `/users/roles/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a showOcrWizard flag
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOcrWizardFlag: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getOcrWizardFlag', 'userId', userId)
            const localVarPath = `/users/{userId}/info`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a user with given id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unarchive User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unArchive: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('unArchive', 'userId', userId)
            const localVarPath = `/users/{userId}/unarchive`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a role
         * @param {string} roleId 
         * @param {UpdateRole} updateRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (roleId: string, updateRole: UpdateRole, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('updateRole', 'roleId', roleId)
            // verify required parameter 'updateRole' is not null or undefined
            assertParamExists('updateRole', 'updateRole', updateRole)
            const localVarPath = `/users/roles/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates user roles to the ones provided
         * @param {string} userId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRoles: async (userId: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserRoles', 'userId', userId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateUserRoles', 'requestBody', requestBody)
            const localVarPath = `/users/{userId}/roles`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Archive User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archive(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archive(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new role
         * @param {CreateRole} createRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(createRole: CreateRole, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(createRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create users
         * @param {CreateUsers} createUsers 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsers(createUsers: CreateUsers, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserIdEmail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsers(createUsers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(roleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of all roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetRole>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a showOcrWizard flag
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOcrWizardFlag(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOcrWizardFlag(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a user with given id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list of user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unarchive User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unArchive(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unArchive(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a role
         * @param {string} roleId 
         * @param {UpdateRole} updateRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(roleId: string, updateRole: UpdateRole, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRole(roleId, updateRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates user roles to the ones provided
         * @param {string} userId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserRoles(userId: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserRoles(userId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Archive User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.archive(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new role
         * @param {CreateRole} createRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(createRole: CreateRole, options?: any): AxiosPromise<CreatedRoleResponse> {
            return localVarFp.createRole(createRole, options).then((request) => request(axios, basePath));
        },
        /**
         * Create users
         * @param {CreateUsers} createUsers 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsers(createUsers: CreateUsers, options?: any): AxiosPromise<Array<UserIdEmail>> {
            return localVarFp.createUsers(createUsers, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(roleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRole(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoles(options?: any): AxiosPromise<Array<GetRole>> {
            return localVarFp.getAllRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a showOcrWizard flag
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOcrWizardFlag(userId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.getOcrWizardFlag(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a user with given id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersList(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsersList(options).then((request) => request(axios, basePath));
        },
        /**
         * Unarchive User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unArchive(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unArchive(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a role
         * @param {string} roleId 
         * @param {UpdateRole} updateRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(roleId: string, updateRole: UpdateRole, options?: any): AxiosPromise<void> {
            return localVarFp.updateRole(roleId, updateRole, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates user roles to the ones provided
         * @param {string} userId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRoles(userId: string, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserRoles(userId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Archive User
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public archive(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).archive(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new role
     * @param {CreateRole} createRole 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createRole(createRole: CreateRole, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createRole(createRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create users
     * @param {CreateUsers} createUsers 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createUsers(createUsers: CreateUsers, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createUsers(createUsers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a role
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteRole(roleId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteRole(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllRoles(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a showOcrWizard flag
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOcrWizardFlag(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getOcrWizardFlag(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a user with given id
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUser(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUsersList(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUsersList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unarchive User
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unArchive(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).unArchive(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a role
     * @param {string} roleId 
     * @param {UpdateRole} updateRole 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateRole(roleId: string, updateRole: UpdateRole, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateRole(roleId, updateRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates user roles to the ones provided
     * @param {string} userId 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserRoles(userId: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUserRoles(userId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


