import { AnyAction } from "redux";
import ActionTypes from "./actionTypes";

const permissionsReducer = (
  state: string[] = [],
  action: AnyAction,
): Array<string> => {
  switch (action.type) {
    case ActionTypes.SET_PERMISSIONS:
      return action.payload;
    case ActionTypes.CLEAR_PERMISSIONS:
      return [];
    default:
      return state;
  }
};

export default permissionsReducer;
