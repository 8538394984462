import { ReactElement } from "react";
import { useMappedStateSelector } from "../../../../../hooks";
import LoadingPage from "./loadingPage";
import { SettingsMapState, SettingsMapStateReturn } from "./types";
import { ENJOYMENT_SCALE, KNOWLEDGE_SCALE } from "../../../../shared/consts";
import SettingsSection from "./settingsSection";

const Settings = (): ReactElement => {
  const mapState = (state: SettingsMapState): SettingsMapStateReturn => ({
    isFetchingSkillsMatrix: state?.requestStatus?.isFetchingSkillsMatrix,
    professions: state?.dictionaries?.profession || [],
  });

  const { professions, isFetchingSkillsMatrix } =
    useMappedStateSelector(mapState);

  return isFetchingSkillsMatrix || !professions?.length ? (
    <LoadingPage />
  ) : (
    <section className="flex flex-col gap-6 mb-5">
      <>
        <SettingsSection section={KNOWLEDGE_SCALE} />
        <SettingsSection section={ENJOYMENT_SCALE} />
      </>
    </section>
  );
};

export default Settings;
