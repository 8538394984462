import { ReactElement } from "react";
import { CardHeader, Label } from "@ps/ui-components";
import { getNameFromDictionary } from "@ps/utils";
import { ReactComponent as SeniorityIcon } from "../../../../images/profile-summary/seniority.svg";
import { PROFILE_SUMMARY_EXPERIENCE } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import { SeniorityProps } from "./types";

const BASE_PATH = "profile.summary";

const Seniority = ({
  seniority,
  seniorityDict,
}: SeniorityProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <CardHeader
      Icon={SeniorityIcon}
      iconColor="text-primary-50"
      dataCy={`${PROFILE_SUMMARY_EXPERIENCE}_seniority`}
    >
      <Label
        dataCy={`${PROFILE_SUMMARY_EXPERIENCE}_seniority`}
        text={t(`${BASE_PATH}.seniority`)}
      >
        <div className="flex flex-row gap-8 items-center">
          <span className="capitalize font-semibold">
            {getNameFromDictionary(seniorityDict, seniority) || ""}
          </span>
        </div>
      </Label>
    </CardHeader>
  );
};

export default Seniority;
