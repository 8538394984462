import React, { ReactElement, useEffect, useState } from "react";
import { classJoin, colorFormatters } from "@ps/utils";
import { motion, useAnimation } from "framer-motion";
import { ScaleElement, ScalePickerProps } from "./types";
import styles from "./styles.module.css";
import {
  SCALE_PICKER_BUTTON_PREFIX,
  SCALE_PICKER_PREFIX,
} from "../../shared/data-cy";
import { focusVisibleStyles } from "../../shared/styles";

const animationStyle = {
  duration: 0.2,
  delay: 0.1,
  delayIfSingle: 0,
  scale: [1.3, 1.0],
  scaleIfNotAnimating: 1.0,
};

const ScalePicker = ({
  scale = [],
  singlePickMode,
  firstScaleEmpty,
  dataCy,
  defaultValue,
  onChange,
  disabled = false,
}: ScalePickerProps): ReactElement => {
  const [indexSelected, setIndexSelected] = useState(
    scale.findIndex(({ score }) => score === defaultValue) -
      (firstScaleEmpty ? 1 : 0),
  );
  const [hoverIndex, setHoverIndex] = useState(-1);
  const animationControls = useAnimation();

  const isSelected = (index: number) =>
    singlePickMode ? indexSelected === index : indexSelected >= index;
  const isHoveringOver = (index: number) =>
    singlePickMode ? hoverIndex === index : hoverIndex >= index;

  const animateBlocks = (delay: (i: number) => number, indexMax: number) => {
    const condition = (i: number) =>
      singlePickMode ? indexMax === i : indexMax >= i;
    return animationControls.start((i) => ({
      scale: condition(i)
        ? animationStyle.scale
        : animationStyle.scaleIfNotAnimating,
      transition: {
        delay: singlePickMode ? animationStyle.delayIfSingle : delay(i),
        duration: animationStyle.duration,
      },
    }));
  };

  const animateOnDeselect = (indexMax: number) => {
    animateBlocks(
      (i) => indexMax * animationStyle.delay - i * animationStyle.delay,
      indexMax,
    ).then(() => setHoverIndex(-1));
  };

  const animateOnSelect = (indexMax: number) => {
    setHoverIndex(indexMax);
    animateBlocks((i) => i * 0.1, indexMax);
  };

  const handleButtonClick = (index: number) => () => {
    setIndexSelected((oldValue) => {
      if (oldValue === index) {
        animateOnDeselect(index);
        onChange?.(singlePickMode ? undefined : 0);
        return -1;
      }
      animateOnSelect(index);
      onChange?.(scale[index + (firstScaleEmpty ? 1 : 0)].score);
      return index;
    });
  };

  const handlePointerEnter = (index: number) => () => setHoverIndex(index);
  const handlePointerLeave = () => setHoverIndex(-1);

  const filterScale = ({ score }: ScaleElement) =>
    firstScaleEmpty ? score !== scale?.[0]?.score : true;

  const colorHexToRGB = (color?: string) =>
    colorFormatters.hexToRgbString(color);

  useEffect(() => {
    setIndexSelected(
      scale.findIndex(({ score }) => score === defaultValue) -
        (firstScaleEmpty ? 1 : 0),
    );
  }, [defaultValue, scale, firstScaleEmpty]);

  return (
    <div
      className="flex flex-row gap-x-1 w-min cursor-pointer"
      onPointerLeave={handlePointerLeave}
      data-cy={`${SCALE_PICKER_PREFIX}-${dataCy}`}
    >
      {scale
        .filter(filterScale)
        .map(({ score, iconUrl, icon, color }, index) => {
          const isColorSpecified = !!color;
          const shouldColorButton =
            isHoveringOver(index) || (hoverIndex === -1 && isSelected(index));
          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <motion.button
              className={classJoin(
                "h-7 w-7 rounded-md border border-neutralSecondary-60",
                styles.smooth_background,
                shouldColorButton &&
                  !isColorSpecified &&
                  "bg-primary-50 border-primary-50",
                focusVisibleStyles,
              )}
              style={
                shouldColorButton && isColorSpecified
                  ? {
                      borderColor: colorHexToRGB(color),
                      backgroundColor: colorHexToRGB(color),
                    }
                  : {}
              }
              custom={index}
              animate={animationControls}
              onClick={disabled ? undefined : handleButtonClick(index)}
              onPointerEnter={handlePointerEnter(index)}
              key={score}
              data-cy={`${SCALE_PICKER_BUTTON_PREFIX}-${dataCy}-${score}`}
            >
              {iconUrl && <img src={iconUrl} alt={`${score}`} />}
              {icon &&
                React.cloneElement(icon, {
                  className: classJoin(
                    icon.props.className,
                    classJoin(
                      "h-full w-full p-1",
                      isHoveringOver(index) ||
                        (hoverIndex === -1 && isSelected(index))
                        ? "text-primary-100"
                        : "text-neutralSecondary-60",
                    ),
                  ),
                })}
            </motion.button>
          );
        })}
    </div>
  );
};

export default ScalePicker;
