import { ReactElement, useRef } from "react";
import dayjs from "dayjs";
import {
  Datepicker2,
  InfoTooltip,
  Input,
  SearchSelect,
  useFontSizeMode,
} from "@ps/ui-components";
import {
  classJoin,
  getNameFromDictionary,
  prepareDictionarySearchSelect,
} from "@ps/utils";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { MAIN_PROFILE_EDIT_MODE } from "../../../../../shared/data-cy";
import {
  BASIC_INFO,
  CAREER_SPAN,
  FIRST_NAME,
  LAST_NAME,
  MAIN_PROFESSION,
  PROFESSIONS,
  ROLE,
  SENIORITY,
  START_DATE,
} from "../../constants";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import {
  BasicInfoMapState,
  BasicInfoMapStateReturn,
  EditProps,
  ValueLabelProps,
} from "../types";
import { DictionaryEntryModel } from "../../../../../dictionaries";
import LabelWrapper from "../labelWrapper";

const TRANSLATION_PATH = `profile.overview.${BASIC_INFO}`;
const OVERVIEW_EXPERIENCE_PROFESSIONS_SELECT =
  "overview-experience-professions-select";
const OVERVIEW_EXPERIENCE_ROLES_SELECT = "overview-experience-roles-select";
const OVERVIEW_EXPERIENCE_SENIORITY_SELECT =
  "overview-experience-seniority-select";
const OVERVIEW_EXPERIENCE_CAREER_SPAN_CALENDAR =
  "overview-experience-career_span-calendar";
const OVERVIEW_EXPERIENCE_CAREER_SPAN_YEAR_SELECT =
  "overview-experience-career_span_year-select";
const OVERVIEW_EXPERIENCE_CAREER_SPAN_MONTHS_SELECT =
  "overview-experience-career_span_months-select";

const BasicInfo = ({
  firstName,
  lastName,
  setLastFocusID = () => {
    return;
  },
  lastFocusID,
}: EditProps): ReactElement => {
  const { control, getValues, watch } = useFormContext();
  const { t } = useTranslationWithNamespace();
  console.log("test 04-01-2024, base info: ");
  const { isLargeFont } = useFontSizeMode();

  const mapState = (state: BasicInfoMapState): BasicInfoMapStateReturn => ({
    seniorityDict: state.dictionaries?.seniority || [],
    professionsDict: state.dictionaries?.profession || [],
    roleDict: state.dictionaries?.project_role || [],
  });

  const { seniorityDict, professionsDict, roleDict } =
    useMappedStateSelector(mapState);

  const professionsNodeInput = useRef(null);

  const professionsDictForSearchSelect = prepareDictionarySearchSelect(
    professionsDict,
    undefined,
    "id",
    "name",
    "name",
  );

  const seniorityDictForSearchSelect = prepareDictionarySearchSelect(
    seniorityDict,
    undefined,
    "id",
    "name",
    "name",
  );

  const yearsArray = [...Array(100)].fill(0).map((_, index: number) => ({
    value: index,
    label: index,
  }));
  const monthsArray = [...Array(12)].fill(0).map((_, index: number) => ({
    value: index,
    label: index,
  }));

  const gapMonths = useWatch({
    control,
    name: `${CAREER_SPAN}.gapMonths.months`,
  });
  const gapYears = useWatch({
    control,
    name: `${CAREER_SPAN}.gapMonths.years`,
  });

  const rolesDictForSearchSelect = roleDict?.map(
    (role: DictionaryEntryModel) => ({
      value: role.id,
      label: (
        <div
          className={classJoin(
            "flex gap-1 items-center",
            isLargeFont ? "p-0.5" : "",
          )}
        >
          <span>{role.name}</span>
          <InfoTooltip
            dataCy={`${MAIN_PROFILE_EDIT_MODE}_${ROLE}`}
            tooltipContent={t(`${TRANSLATION_PATH}.${role.name}Tooltip`)}
          />
        </div>
      ),
    }),
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-6 w-full">
        <LabelWrapper
          translationPath={TRANSLATION_PATH}
          overviewElementPath={BASIC_INFO}
          field={FIRST_NAME}
          className="w-1/2"
        >
          <Input
            dataCy={`${MAIN_PROFILE_EDIT_MODE}_${FIRST_NAME}`}
            defaultValue={firstName || ""}
            disabled
            name={FIRST_NAME}
          />
        </LabelWrapper>
        <LabelWrapper
          translationPath={TRANSLATION_PATH}
          overviewElementPath={BASIC_INFO}
          field={LAST_NAME}
          className="w-1/2"
        >
          <Input
            dataCy={`${MAIN_PROFILE_EDIT_MODE}_${LAST_NAME}`}
            defaultValue={lastName || ""}
            disabled
            name={LAST_NAME}
          />
        </LabelWrapper>
      </div>
      <div className="flex gap-6 w-full">
        <LabelWrapper
          translationPath={TRANSLATION_PATH}
          overviewElementPath={BASIC_INFO}
          field={MAIN_PROFESSION}
          className="w-full"
          isRequired
        >
          <Controller
            render={({ field: { onChange }, fieldState: { error } }) => (
              <SearchSelect
                autoFocus={
                  lastFocusID === "" || lastFocusID === "main-profession"
                }
                dataCy={`${MAIN_PROFILE_EDIT_MODE}_${MAIN_PROFESSION}`}
                width="w-full"
                options={professionsDictForSearchSelect.filter(
                  (profession: ValueLabelProps) => {
                    const currentDictProfId = profession.value;
                    const watchSecondaryProfession = watch(
                      `${PROFESSIONS}.secondary`,
                    )?.map((secondaryProfession: ValueLabelProps | string) =>
                      typeof secondaryProfession === "string"
                        ? secondaryProfession
                        : secondaryProfession.value,
                    );
                    return !watchSecondaryProfession?.includes(
                      currentDictProfId,
                    );
                  },
                )}
                value={professionsDictForSearchSelect.find(
                  (profession: ValueLabelProps) =>
                    profession.value === watch(`${PROFESSIONS}.main`),
                )}
                placeholder={t(`${TRANSLATION_PATH}.mainProfessionPlaceholder`)}
                searchable={false}
                error={!!error}
                message={error?.message}
                onChange={(newValue) => {
                  onChange(newValue.value);
                  setLastFocusID("");
                }}
                height="auto"
              />
            )}
            name={`${PROFESSIONS}.main`}
            control={control}
            rules={{
              validate: (value) =>
                !value ? `${t("profile.errors.cantBeEmpty")}` : true,
            }}
          />
        </LabelWrapper>

        <LabelWrapper
          translationPath={TRANSLATION_PATH}
          overviewElementPath={BASIC_INFO}
          field={PROFESSIONS}
          className="w-full"
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <SearchSelect
                  autoFocus={
                    lastFocusID === OVERVIEW_EXPERIENCE_PROFESSIONS_SELECT
                  }
                  ref={professionsNodeInput}
                  dataCy={`${MAIN_PROFILE_EDIT_MODE}_${PROFESSIONS}`}
                  onChange={(e) => {
                    onChange(e);
                    setLastFocusID(OVERVIEW_EXPERIENCE_PROFESSIONS_SELECT);
                  }}
                  width="w-full"
                  value={value?.map((item: ValueLabelProps | string) =>
                    typeof item === "string"
                      ? {
                          value: item,
                          label: isLargeFont ? (
                            <div className="flex gap-1 items-center p-0.5">
                              <span>
                                {getNameFromDictionary(professionsDict, item)}
                              </span>
                            </div>
                          ) : (
                            getNameFromDictionary(professionsDict, item)
                          ),
                        }
                      : item,
                  )}
                  placeholder={t(
                    `${TRANSLATION_PATH}.otherProfessionsPlaceholder`,
                  )}
                  options={professionsDictForSearchSelect.filter(
                    (profession: ValueLabelProps) =>
                      profession.value !== watch(`${PROFESSIONS}.main`),
                  )}
                  multiple
                  searchable={false}
                  height="auto"
                />
              );
            }}
            name={`${PROFESSIONS}.secondary`}
            control={control}
          />
        </LabelWrapper>
      </div>
      <div className="flex gap-6 w-full">
        <LabelWrapper
          translationPath={TRANSLATION_PATH}
          overviewElementPath={BASIC_INFO}
          field={SENIORITY}
          className="w-full"
          isRequired
        >
          <Controller
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SearchSelect
                autoFocus={lastFocusID === OVERVIEW_EXPERIENCE_SENIORITY_SELECT}
                dataCy={`${MAIN_PROFILE_EDIT_MODE}_${SENIORITY}`}
                width="w-full"
                height="auto"
                onChange={(newValue) => {
                  onChange(newValue?.value);
                  setLastFocusID(OVERVIEW_EXPERIENCE_SENIORITY_SELECT);
                }}
                options={seniorityDictForSearchSelect}
                defaultValue={seniorityDictForSearchSelect.find(
                  (seniority: ValueLabelProps) => seniority.value === value,
                )}
                placeholder={t(`${TRANSLATION_PATH}.seniorityPlaceholder`)}
                error={!!error}
                message={error?.message}
                searchable={false}
              />
            )}
            name={SENIORITY}
            control={control}
            rules={{
              validate: (value) =>
                !value ? `${t("profile.errors.cantBeEmpty")}` : true,
            }}
          />
        </LabelWrapper>
        <LabelWrapper
          translationPath={TRANSLATION_PATH}
          overviewElementPath={BASIC_INFO}
          field={ROLE}
          className="w-full"
        >
          <Controller
            render={({ field: { onChange, value } }) => (
              <SearchSelect
                autoFocus={lastFocusID === OVERVIEW_EXPERIENCE_ROLES_SELECT}
                dataCy={`${MAIN_PROFILE_EDIT_MODE}_${ROLE}`}
                onChange={(newValue) => {
                  onChange(newValue);
                  setLastFocusID(OVERVIEW_EXPERIENCE_ROLES_SELECT);
                }}
                width="w-full"
                value={value?.map((item: ValueLabelProps) =>
                  typeof item === "string"
                    ? {
                        value: item,
                        label: (
                          <div
                            className={classJoin(
                              "flex gap-1 items-center",
                              isLargeFont ? "p-0.5" : "",
                            )}
                          >
                            <span>{getNameFromDictionary(roleDict, item)}</span>
                            <InfoTooltip
                              dataCy={`${MAIN_PROFILE_EDIT_MODE}_${ROLE}`}
                              tooltipContent={t(
                                `${TRANSLATION_PATH}.${getNameFromDictionary(
                                  roleDict,
                                  item,
                                )}Tooltip`,
                              )}
                            />
                          </div>
                        ),
                      }
                    : item,
                )}
                options={rolesDictForSearchSelect}
                placeholder={t(`${TRANSLATION_PATH}.rolePlaceholder`)}
                multiple
                searchable={false}
                height="auto"
              />
            )}
            name="roles"
            control={control}
          />
        </LabelWrapper>
      </div>
      <div className="flex gap-6">
        <LabelWrapper
          overviewElementPath={BASIC_INFO}
          translationPath={TRANSLATION_PATH}
          field={START_DATE}
          className="w-auto"
          tooltipContent="careerStartTooltip"
        >
          <Controller
            name={`${CAREER_SPAN}.${START_DATE}`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Datepicker2
                isInputClearable
                disableFuture
                autoFocus={
                  OVERVIEW_EXPERIENCE_CAREER_SPAN_CALENDAR === lastFocusID
                }
                dataCy={BASIC_INFO}
                error={error?.message}
                onChange={(newValue) => {
                  onChange(newValue);
                  setLastFocusID(OVERVIEW_EXPERIENCE_CAREER_SPAN_CALENDAR);
                }}
                selected={value}
                dateFormat="MM/YYYY"
                timeView={["month", "year"]}
                type="month"
              />
            )}
            rules={{
              validate: (value) => {
                if (dayjs().isBefore(value))
                  return `${t("profile.errors.noFutureDate")}`;
                if (
                  (getValues(`${CAREER_SPAN}.gapMonths.years`) ||
                    getValues(`${CAREER_SPAN}.gapMonths.months`)) &&
                  !value
                )
                  return `${t("profile.overview.errors.startDateEmpty")}`;
                if (value && !value?.isValid())
                  return `${t("profile.overview.errors.wrongDateFormat")}`;
                return true;
              },
            }}
          />
        </LabelWrapper>
        <LabelWrapper
          overviewElementPath={BASIC_INFO}
          translationPath={TRANSLATION_PATH}
          field={CAREER_SPAN}
          className="w-auto"
          tooltipContent="gapTooltip"
        >
          <div className="flex gap-6">
            <Controller
              render={({ field: { onChange } }) => (
                <SearchSelect
                  autoFocus={
                    lastFocusID === OVERVIEW_EXPERIENCE_CAREER_SPAN_YEAR_SELECT
                  }
                  dataCy={`${MAIN_PROFILE_EDIT_MODE}_${CAREER_SPAN}_years`}
                  onChange={(newValue) => {
                    onChange(newValue?.value);
                    setLastFocusID(OVERVIEW_EXPERIENCE_CAREER_SPAN_YEAR_SELECT);
                  }}
                  placeholder={t(
                    `profile.overview.${BASIC_INFO}.placeholderYears`,
                  )}
                  value={
                    !gapYears
                      ? undefined
                      : yearsArray.find((year) => year.value === gapYears)
                  }
                  options={yearsArray}
                  searchable={false}
                />
              )}
              name={`${CAREER_SPAN}.gapMonths.years`}
              control={control}
            />
            <Controller
              render={({ field: { onChange } }) => (
                <SearchSelect
                  autoFocus={
                    lastFocusID ===
                    OVERVIEW_EXPERIENCE_CAREER_SPAN_MONTHS_SELECT
                  }
                  dataCy={`${MAIN_PROFILE_EDIT_MODE}_${CAREER_SPAN}_months`}
                  onChange={(newValue) => {
                    onChange(newValue?.value);
                    setLastFocusID(
                      OVERVIEW_EXPERIENCE_CAREER_SPAN_MONTHS_SELECT,
                    );
                  }}
                  placeholder={t(
                    `profile.overview.${BASIC_INFO}.placeholderMonths`,
                  )}
                  value={
                    !gapMonths
                      ? undefined
                      : monthsArray.find((month) => month.value === gapMonths)
                  }
                  options={monthsArray}
                  searchable={false}
                />
              )}
              name={`${CAREER_SPAN}.gapMonths.months`}
              control={control}
            />
          </div>
        </LabelWrapper>
      </div>
    </div>
  );
};

export default BasicInfo;
