import { useHistory, useLocation } from "react-router-dom";
import { ReactElement, useState, useEffect, useContext } from "react";
import { useDispatch } from "redux-react-hook";
import {
  hasPermissions,
  PermissionContext,
  TabsContainer,
} from "@ps/ui-components";
import { permissionsMappers, getSlug, dashConverter } from "@ps/utils";
import { NAMESPACE, PROSPECTS_TABS } from "../../shared/constants";
import {
  MainProps,
  ProspectsContainerMapState,
  ProspectsContainerMapStateReturn,
} from "./types";
import { ALL_PROSPECTS } from "../../shared/routes";
import { useMappedStateSelector } from "../../hooks";
import { fetchUsers } from "../../user-domain";
import { fetchClients } from "../../client-domain";
import { PROSPECT_FULL_ACCESS } from "../../shared/permissions";

const ProspectsContainer = ({ children }: MainProps): ReactElement => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { hasPermission } = useContext(PermissionContext);

  const defaultTab =
    getSlug(dashConverter(pathname)) === "prospect"
      ? ALL_PROSPECTS
      : getSlug(dashConverter(pathname));

  const [activeTab, setActiveTab] = useState<string>(defaultTab);

  useEffect(() => {
    setActiveTab(getSlug(dashConverter(pathname)));
  }, [pathname, activeTab]);

  useEffect(() => {
    if (hasPermission(PROSPECT_FULL_ACCESS)) {
      fetchUsers(dispatch);
      fetchClients(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapState = (
    state: ProspectsContainerMapState,
  ): ProspectsContainerMapStateReturn => ({
    editMode: state.editMode,
    allPermissions: state.permissions || [],
  });

  const { editMode } = useMappedStateSelector(mapState);
  const { allPermissions } = useMappedStateSelector(mapState, "");

  const handleOnTabChange = (path: string) => {
    history.push(path);

    history.listen(() => setActiveTab(path));
    if (!editMode) setActiveTab(path);
  };

  return (
    <TabsContainer
      activeTab={activeTab}
      handleOnTabChange={handleOnTabChange}
      prefix={`${NAMESPACE}_prospects`}
      tabs={permissionsMappers.filterTabsWithPermissions(
        PROSPECTS_TABS,
        hasPermissions,
        allPermissions,
      )}
      translationID={NAMESPACE}
      translationPath="prospects.tabs"
    >
      <section className="h-full">{children}</section>
    </TabsContainer>
  );
};

export default ProspectsContainer;
