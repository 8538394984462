import { ReactElement, Children } from "react";
import { classJoin } from "@ps/utils";
import { PillProps } from "./types";
import { PILL } from "../../shared/data-cy";

const Pill = ({
  additionalClassName,
  children,
  colourClass = "bg-primary-60 text-primary-100",
  dataCy,
  isOutOfTabKeys = false,
  paddingX = "px-4",
  paddingY = "py-1",
}: PillProps): ReactElement => {
  // TODO it's necessary for hide these elements from tab keys accessibility
  const removeNecessaryButtons = (
    nodes: string | ReactElement | ReactElement[],
  ) => {
    return Children.map(nodes, (childNode) => {
      if (typeof childNode === "string") return childNode;
      if (
        ((childNode?.type === "div" && childNode?.props?.role === "button") ||
          childNode?.type === "button") &&
        isOutOfTabKeys
      )
        return (
          <span className={childNode.props.className}>
            {childNode.props.children}
          </span>
        );
      return childNode;
    });
  };

  const clonedChildren = removeNecessaryButtons(children);

  if (isOutOfTabKeys) {
    return (
      <div
        data-cy={`${PILL}_${dataCy}`}
        className={classJoin(
          additionalClassName,
          colourClass,
          paddingY,
          paddingX,
          "rounded-full flex items-center justify-center",
        )}
        role="presentation"
      >
        {clonedChildren}
      </div>
    );
  }
  return (
    <div
      data-cy={`${PILL}_${dataCy}`}
      className={classJoin(
        additionalClassName,
        colourClass,
        paddingY,
        paddingX,
        "rounded-full flex items-center justify-center",
      )}
    >
      {children}
    </div>
  );
};

export default Pill;
