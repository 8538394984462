import { useFormContext } from "react-hook-form";
import { ReactElement, useEffect } from "react";
import { Input, Label } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../hooks";
import { ControlledInputProps } from "./types";

const ControlledInput = ({
  additionalClass,
  additionalValidation = {},
  basePath,
  controllerPath,
  isRequired,
  name,
  placeholder,
  translatePath,
  type,
  min,
  max,
  step,
  autoFocus,
}: ControlledInputProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { control, watch, getFieldState, formState, setFocus } =
    useFormContext();
  const translate = (field: string) =>
    `${t(`${translatePath}.${field}`)}${isRequired ? "*" : ""}`;

  const fieldTitle = getFieldState(controllerPath || name, formState);

  useEffect(() => {
    if (autoFocus) setFocus(controllerPath || name);
  }, [setFocus]);

  return (
    <Label
      dataCy={`${basePath}_${name}`}
      text={translate(name)}
      labelFontWeight="font-bold"
      labelFontSize="text-lg"
      labelColor="text-neutralPrimary-20"
    >
      <Input
        dataCy={`${basePath}_${name}`}
        defaultValue={watch(controllerPath || name)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        type={type}
        placeholder={(placeholder && translate(placeholder)) || ""}
        additionalClass={additionalClass}
        message={fieldTitle.error?.message}
        error={!!fieldTitle.error}
        min={min}
        max={max}
        step={step}
        autoFocus={autoFocus}
        {...control.register(controllerPath || name, {
          validate: additionalValidation,
          required: {
            value: !!isRequired,
            message: t("projects.errors.cantBeEmpty"),
          },
        })}
      />
    </Label>
  );
};

export default ControlledInput;
