import { AnyAction, combineReducers } from "redux";
import ActionTypes from "../actions/actionTypes";
import { ReportsModel } from "../../models";
import { GroupNames, ISorter } from "./types";

export const reportReducer = (
  state: ReportsModel = [],
  action: AnyAction,
): ReportsModel => {
  switch (action.type) {
    case ActionTypes.FETCH_REPORT_START:
      return state;
    case ActionTypes.FETCH_REPORT_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_REPORT_ERROR:
    case ActionTypes.CLEAR_REPORT:
      return [];
    default:
      return state;
  }
};

export const groupedByReducer = (
  state: GroupNames = {},
  action: AnyAction,
): GroupNames => {
  switch (action.type) {
    case ActionTypes.UPDATE_REPORTS_GROUP_BY:
      return action.payload;
    case ActionTypes.CLEAR_REPORTS_GROUP_BY:
      return {};
    default:
      return state;
  }
};

export const activeSorterReducer = (
  state: ISorter = { order: "asc", name: "title" },
  action: AnyAction,
): ISorter => {
  switch (action.type) {
    case ActionTypes.UPDATE_ACTIVE_SORTER:
      return action.payload;
    default:
      return state;
  }
};

export const isFetchingReportFilters = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_REPORT_FILTERS_START:
      return true;
    case ActionTypes.FETCH_REPORT_FILTERS_SUCCESS:
    case ActionTypes.FETCH_REPORT_FILTERS_ERROR:
      return false;
    default:
      return state;
  }
};

export const isFetchingReport = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_REPORT_START:
      return true;
    case ActionTypes.FETCH_REPORT_SUCCESS:
    case ActionTypes.FETCH_REPORT_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  report: reportReducer,
  groupedBy: groupedByReducer,
  activeSorter: activeSorterReducer,
});
