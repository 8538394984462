import { ChangeEvent, ReactElement } from "react";
import { useParams } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import { classJoin, prepareDictionarySearchSelect } from "@ps/utils";
import { Input, Label, SearchSelect } from "@ps/ui-components";
import countries from "i18n-iso-countries";
import {
  ADD_NEW_CLIENT,
  CITY,
  CLIENT_NAME,
  CONTACT_PERSON_EMAIL,
  CONTACT_PERSON_NAME,
  COUNTRY,
  DOMAINS,
  EMAIL_REGEXP_PATTERN,
  PHONE_NUMBER,
} from "../../constants";
import ControlledInput from "../common/controlledInput";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { CURRENT_LANGUAGE_CODE } from "../../../i18n/constants";
import { DictionaryEntryModel } from "../../../dictionaries";
import { ClientModel } from "../../models";
import {
  BaseClientsInfoMapState,
  BaseClientsInfoMapStateReturn,
} from "../types";
import {
  ClientSummaryMapState,
  ClientSummaryMapStateReturn,
} from "../../models/types";
import styles from "./styles.module.css";

const BASE_PATH = ADD_NEW_CLIENT;
const TRANSLATE_PATH = "projects.client";

const EditDetails = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { control } = useFormContext();

  const mapState = (
    state: BaseClientsInfoMapState,
  ): BaseClientsInfoMapStateReturn => ({
    domainDict: state.dictionaries?.domain || [],
    clients: state.clients?.clientsList || [],
  });

  const clientsSummaryMapState = (
    state: ClientSummaryMapState,
  ): ClientSummaryMapStateReturn => ({
    clientSummary: state?.clients.clientSummary || [],
  });

  const params = useParams<{ id: string }>();

  const { domainDict, clients } = useMappedStateSelector(mapState);
  const { clientSummary } = useMappedStateSelector(clientsSummaryMapState);

  const checkClientName = (name: string): ClientModel =>
    clients
      ?.filter((value: ClientModel) =>
        params.id ? value.clientName !== clientSummary?.clientName : true,
      )
      .some((client: ClientModel) => client.clientName === name);

  const allCountries = countries.getNames(CURRENT_LANGUAGE_CODE, {
    select: "official",
  });

  const isBlankOrClientExistsOrMoreChars = (
    value: string,
    maxChars = 50,
    clientValidation = false,
  ): boolean | string => {
    if (clientValidation && value && checkClientName(value))
      return `${t("projects.prospect.errors.clientExists")}`;
    if (value && !value.trim().length)
      return `${t("projects.errors.cantBeBlank")}`;
    if (value && value.length > maxChars)
      return `${t("projects.errors.cantBeMoreThan", { number: maxChars })}`;
    return true;
  };

  return (
    <section className="w-full h-full flex pb-16 relative">
      <div className="flex flex-col gap-y-8 w-9/12 pr-9">
        <Label
          dataCy={`${BASE_PATH}_${CLIENT_NAME}`}
          text={`${t(`projects.client.${CLIENT_NAME}`)}*`}
          labelFontWeight="font-bold"
          labelFontSize="text-lg"
          labelColor="text-neutralPrimary-20"
        >
          <Controller
            name={CLIENT_NAME}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                dataCy={`${BASE_PATH}_${CLIENT_NAME}`}
                width="w-1/2"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onChange(event.target.value)
                }
                autoFocus
                defaultValue={value}
                error={!!error}
                message={error?.message || ""}
                placeholder={`${t(`projects.client.clientPlaceholder`)}*`}
                additionalClass=":placeholder: text-base"
              />
            )}
            rules={{
              validate: (value) =>
                isBlankOrClientExistsOrMoreChars(value, 50, true),
              required: {
                value: true,
                message: t("projects.errors.cantBeEmpty"),
              },
            }}
          />
        </Label>
        <Label
          text={t(`${TRANSLATE_PATH}.${DOMAINS}`)}
          dataCy={`${BASE_PATH}_${DOMAINS}`}
          labelFontWeight="font-bold"
          labelFontSize="text-lg"
          labelColor="text-neutralPrimary-20"
        >
          <Controller
            control={control}
            name={DOMAINS}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SearchSelect
                multiple
                dataCy={`${BASE_PATH}_${DOMAINS}`}
                options={prepareDictionarySearchSelect(
                  domainDict,
                  "id",
                  "id",
                  "name",
                  "name",
                )}
                width="w-1/2"
                height="auto"
                onChange={onChange}
                defaultValue={
                  value && value.length && typeof value[0] === "string"
                    ? value.map((item) => ({
                        id: item,
                        value: item,
                        label: domainDict?.find(
                          (domain: DictionaryEntryModel) => domain.id === item,
                        )?.name,
                      }))
                    : value
                }
                error={!!error}
                message={error?.message}
                placeholder={t(`${TRANSLATE_PATH}.${DOMAINS}`)}
              />
            )}
          />
        </Label>
      </div>
      <div
        className={classJoin(
          `flex flex-col gap-y-4 overflow-auto bg-neutralPrimary-100
           w-3/12 right-8 pt-9 pb-12 px-9 -top-28 absolute rounded-2xl`,
          styles.boxShadow,
        )}
      >
        <ControlledInput
          isDetailsEdit
          name={CONTACT_PERSON_NAME}
          basePath={BASE_PATH}
          translatePath={TRANSLATE_PATH}
          placeholder={CONTACT_PERSON_NAME}
          additionalClass=":placeholder: text-base"
          additionalValidation={(value) =>
            isBlankOrClientExistsOrMoreChars(value)
          }
        />
        <Label
          dataCy={`${BASE_PATH}_${CONTACT_PERSON_EMAIL}`}
          text={t(`${TRANSLATE_PATH}.${CONTACT_PERSON_EMAIL}`)}
          labelFontWeight="font-bold"
          labelFontSize="text-lg"
          labelColor="text-neutralPrimary-20"
        >
          <Controller
            name={CONTACT_PERSON_EMAIL}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                dataCy={`${BASE_PATH}_${CONTACT_PERSON_EMAIL}`}
                defaultValue={value}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onChange(event.target.value)
                }
                placeholder={t(`${TRANSLATE_PATH}.${CONTACT_PERSON_EMAIL}`)}
                error={!!error}
                message={error?.message || ""}
                additionalClass=":placeholder: text-base"
              />
            )}
            rules={{
              validate: (value) => isBlankOrClientExistsOrMoreChars(value),
              required: false,
              pattern: {
                value: EMAIL_REGEXP_PATTERN,
                message: t("projects.errors.emailPatternNotMatch"),
              },
            }}
          />
        </Label>
        <ControlledInput
          isDetailsEdit
          name={PHONE_NUMBER}
          basePath={BASE_PATH}
          translatePath={TRANSLATE_PATH}
          placeholder={PHONE_NUMBER}
          additionalClass=":placeholder: text-base"
          additionalValidation={(value: string) =>
            isBlankOrClientExistsOrMoreChars(value, 40)
          }
        />
        <Label
          text={t(`${TRANSLATE_PATH}.${COUNTRY}`)}
          dataCy={`${BASE_PATH}_${COUNTRY}`}
          labelFontWeight="font-bold"
          labelFontSize="text-lg"
          labelColor="text-neutralPrimary-20"
        >
          <Controller
            control={control}
            name={COUNTRY}
            render={({ field: { onChange, value } }) => (
              <SearchSelect
                dataCy={`${BASE_PATH}_${COUNTRY}`}
                defaultValue={value}
                onChange={onChange}
                width="w-full"
                options={Object.entries(allCountries).map(([key, name]) => ({
                  label: name,
                  value: key,
                }))}
                clearable
                placeholder={t(`${TRANSLATE_PATH}.${COUNTRY}`)}
                menuPosition="fixed"
              />
            )}
          />
        </Label>
        <ControlledInput
          isDetailsEdit
          name={CITY}
          basePath={BASE_PATH}
          translatePath={TRANSLATE_PATH}
          placeholder={CITY}
          additionalClass=":placeholder: text-base"
          additionalValidation={(value: string) =>
            isBlankOrClientExistsOrMoreChars(value)
          }
        />
      </div>
    </section>
  );
};

export default EditDetails;
