import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ReactElement, useEffect, useState } from "react";
import { hasPermissions, TabsContainer } from "@ps/ui-components";
import { getSlug, permissionsMappers, dashConverter } from "@ps/utils";
import { PROFILE, SUMMARY } from "../../shared/routes";
import {
  NAMESPACE,
  PROFILE_OPERATIVE_TABS,
  PROFILE_SPECIALIST_TABS,
  SPECIALIST,
} from "../../shared/constants";
import {
  MainProps,
  ProfileContainerMapState,
  ProfileContainerMapStateReturn,
} from "./types";
import { useMappedStateSelector } from "../../hooks";

const ProfileContainer = ({ children }: MainProps): ReactElement => {
  const { pathname } = useLocation();

  const history = useHistory();

  const mapState = (
    state: ProfileContainerMapState,
  ): ProfileContainerMapStateReturn => ({
    editMode: state.editMode,
    allPermissions: state.permissions || [],
    isMenuVisible: state.isMenuVisible,
    myProfile: state?.profiles?.myProfile,
  });

  const { editMode, myProfile } = useMappedStateSelector(mapState);
  const { allPermissions } = useMappedStateSelector(mapState, "");

  const defaultTab =
    getSlug(dashConverter(pathname)) === PROFILE &&
    myProfile?.profile === SPECIALIST
      ? SUMMARY
      : getSlug(dashConverter(pathname));

  const [activeTab, setActiveTab] = useState<string>(defaultTab);
  const { path } = useRouteMatch();

  useEffect(() => {
    setActiveTab(getSlug(dashConverter(defaultTab)));
  }, [pathname]);

  const handleOnTabChange = (slug: string): void => {
    history.push(`${path}/${slug}`);

    history.listen(() => setActiveTab(slug));
    if (!editMode) setActiveTab(slug);
  };

  return (
    <TabsContainer
      activeTab={activeTab}
      handleOnTabChange={handleOnTabChange}
      prefix={`${NAMESPACE}_profile`}
      tabs={
        myProfile?.profile === SPECIALIST
          ? permissionsMappers.filterTabsWithPermissions(
              PROFILE_SPECIALIST_TABS,
              hasPermissions,
              allPermissions,
            )
          : permissionsMappers.filterTabsWithPermissions(
              PROFILE_OPERATIVE_TABS,
              hasPermissions,
              allPermissions,
            )
      }
      translationID={NAMESPACE}
      translationPath="profile.tabs"
    >
      {children}
    </TabsContainer>
  );
};

export default ProfileContainer;
