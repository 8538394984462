import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { FormProvider, useForm } from "react-hook-form";
import { createRole, fetchAllRoles, updateRole } from "@ps/hh";
import { setRedirection, clearRedirection } from "@ps/alert-modal";
import { useMappedStateSelector } from "@ps/utils";
import PermissionsContext from "./context";
import PermissionsLoadingGrid from "./permissions-grid/permissionsLoadingGrid";
import PermissionsGrid from "./permissions-grid";
import { PermissionsMapState, PermissionsMapStateReturn } from "./types";

const Permissions = (): ReactElement => {
  const [roleIndexInEdit, setRoleIndexInEdit] = useState<null | number>(null);
  const [isAddingNew, setAddingNew] = useState<boolean>(false);

  const dispatch = useDispatch();

  const mapState = (state: PermissionsMapState): PermissionsMapStateReturn => ({
    isLoading:
      state?.requestStatus.isFetchingAllRoles ||
      state?.requestStatus.isModifyingRoles,
  });

  const { isLoading } = useMappedStateSelector(mapState, "hh");
  const methods = useForm();
  const { isDirty } = methods.formState;

  useEffect(() => {
    if (isDirty) dispatch(setRedirection());
  }, [isDirty]);

  const onSubmit = async (roleId: string, roles: string[]): Promise<void> => {
    if (isAddingNew) {
      await createRole(dispatch, roles[roleId]);
      setAddingNew(false);
    } else {
      await updateRole(dispatch, roles[roleId].itemId, roles[roleId]);
    }
    setRoleIndexInEdit(null);
    dispatch(clearRedirection());
    await fetchAllRoles(dispatch);
  };

  return (
    <PermissionsContext.Provider
      value={{
        isAddingNew,
        onSubmit,
        roleIndexInEdit,
        setAddingNew,
        setRoleIndexInEdit,
      }}
    >
      <FormProvider {...methods}>
        <form className="w-full h-full">
          <section className="flex flex-col gap-4">
            {isLoading ? <PermissionsLoadingGrid /> : <PermissionsGrid />}
          </section>
        </form>
      </FormProvider>
    </PermissionsContext.Provider>
  );
};

export default Permissions;
