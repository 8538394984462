import { createContext, useContext } from "react";
import { ContextTeamProps } from "./sections/team/types";

export const TeamContext = createContext<ContextTeamProps>({
  editUserId: "",
  setEditUserId: () => {
    // do nothing
  },
  viewUserId: "",
  setViewUserId: () => {
    // do nothing
  },
  teamView: true,
  setTeamView: () => {
    // do nothing
  },
  focusId: "",
  setFocusId: () => {
    // do nothing
  },
  isAddingNewUser: false,
  setIsAddingNewUser: () => {
    // do nothing
  },
});

export const useTeamContext = (): ContextTeamProps => {
  const context = useContext(TeamContext);
  if (!context) {
    throw new Error(
      "Team elements compound components cannot be rendered outside the team list component",
    );
  }
  return context;
};
