import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { URL_BUTTON_PREFIX } from "../../shared/data-cy";
import { useTranslationWithNamespace } from "../../hooks";
import { focusVisibleStyles } from "../../shared/styles";
import { URLButtonProps } from "./types";

const urlButtonStyles = classJoin.template`
text-primary-50 bg-neutralPrimary-100 border border-primary-50 rounded-md
hover:bg-primary-50 hover:text-primary-100 px-6 py-2 rounded-md h-max w-max text-base
`;

const URLButton = ({
  dataCy,
  url,
  disabled,
  additionalClassName,
}: URLButtonProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const buttonStyles: string = classJoin(
    urlButtonStyles,
    additionalClassName,
    focusVisibleStyles,
  );

  const text = t("urlButton.link");

  if (disabled)
    return (
      <span className={buttonStyles} data-cy={`${URL_BUTTON_PREFIX}_${dataCy}`}>
        {text}
      </span>
    );

  return (
    <a
      data-cy={`${URL_BUTTON_PREFIX}_${dataCy}`}
      className={buttonStyles}
      href={url}
      rel="noreferrer noopener"
      target="_blank"
    >
      {text}
    </a>
  );
};

export default URLButton;
