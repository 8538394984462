import { ReactElement, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { useFormContext } from "react-hook-form";
import { Button, TableRow, Tooltip } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import { ReactComponent as IconDelete } from "../../../../../images/delete.svg";
import { ReactComponent as IconEditPen } from "../../../../../images/edit-pen.svg";
import { ReactComponent as IconEraser } from "../../../../../images/eraser.svg";
import { toggleEditMode } from "../../../../../store";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import HolidaysTemplateService from "../../../../services/holidays-templates";
import { fetchHolidaysTemplates } from "../../../../store";
import styles from "../styles.module.css";
import { useHolidaysTemplatesContext } from "../../../context";
import {
  HOLIDAY,
  HOLIDAYS_TEMPLATES,
  BASE_TRANSLATION_PATH,
  HOLIDAYS_LIST,
  TEMP_HOLIDAY_ID,
  EDIT,
  ERASE,
  DELETE,
} from "../../../constants";
import { DeleteModal } from "../../../modals";
import {
  HolidayRowProps,
  HolidayRowMapState,
  HolidayRowMapStateReturn,
} from "./types";
import ViewRow from "./viewRow";
import EditRow from "./editRow";

const HolidayRow = ({
  holiday,
  index,
  deleteNewHoliday,
}: HolidayRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { currentTemplate, setRecentlyEditedTemplateId } =
    useHolidaysTemplatesContext();
  const dispatch = useDispatch();
  const { reset } = useFormContext();

  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

  const mapState = (state: HolidayRowMapState): HolidayRowMapStateReturn => ({
    editMode: state?.editMode,
  });

  const { editMode } = useMappedStateSelector(mapState);

  const isCurrentlyEditing = editMode === `${HOLIDAY}:${holiday.holidaysId}`;
  const disabled = !!editMode && !isCurrentlyEditing;

  const handleDeleteHoliday = async (): Promise<void> => {
    const currentTemplateId = currentTemplate?.id;
    const result = await HolidaysTemplateService.deleteHolidaysItemById(
      currentTemplateId,
      holiday.holidaysId,
    );

    const isStatusValid = !isAPIError(result);
    if (isStatusValid) {
      setRecentlyEditedTemplateId(currentTemplateId);
      if (editMode) dispatch(toggleEditMode(""));
      await fetchHolidaysTemplates(dispatch);
    }
  };

  const renderDeleteButton = (): ReactElement => (
    <Button
      dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAY}_${DELETE}`}
      variant="secondary"
      paddingX="px-0"
      width="w-8"
      height="h-8"
      onClick={() => {
        if (holiday.holidaysId === TEMP_HOLIDAY_ID) {
          deleteNewHoliday();
        } else {
          setIsDeleteModalOpened(true);
        }
        return undefined;
      }}
      disabled={disabled || holiday.isMainHoliday}
    >
      <IconDelete className="w-4 h-4" />
    </Button>
  );

  return (
    <div
      className={classJoin(
        "h-30 mb-2 font-semibold",
        disabled ? "opacity-50 cursor-default pointer-event-none" : "",
      )}
    >
      <TableRow
        dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAY}`}
        columnsStyles={styles.tableColumns}
      >
        {isCurrentlyEditing ? (
          <EditRow index={index} />
        ) : (
          <ViewRow holiday={holiday} />
        )}

        <div className="flex justify-end gap-3">
          {isCurrentlyEditing ? (
            <Button
              dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAY}_${ERASE}`}
              variant="secondary"
              paddingX="px-0"
              width="w-8"
              height="h-8"
              onClick={() => {
                if (holiday.holidaysId === TEMP_HOLIDAY_ID) {
                  deleteNewHoliday();
                  return;
                }
                dispatch(toggleEditMode(""));
                reset();
              }}
            >
              <IconEraser className="w-4 h-4" />
            </Button>
          ) : (
            <Button
              dataCy={`${HOLIDAYS_TEMPLATES}_${HOLIDAY}_${EDIT}`}
              variant="secondary"
              paddingX="px-0"
              width="w-8"
              height="h-8"
              onClick={() => {
                dispatch(toggleEditMode(`${HOLIDAY}:${holiday.holidaysId}`));
                reset({ holiday });
              }}
              disabled={disabled}
            >
              <IconEditPen className="w-4 h-4" />
            </Button>
          )}

          {holiday.isMainHoliday ? (
            <Tooltip
              placement="top-start"
              content={
                <span>
                  {t(`${BASE_TRANSLATION_PATH}.${HOLIDAYS_LIST}.cannotDelete`)}
                </span>
              }
            >
              {renderDeleteButton()}
            </Tooltip>
          ) : (
            renderDeleteButton()
          )}
        </div>
      </TableRow>
      <DeleteModal
        isOpen={isDeleteModalOpened}
        onDelete={handleDeleteHoliday}
        onCancel={() => setIsDeleteModalOpened(false)}
        modalInfo={{
          type: t(`${BASE_TRANSLATION_PATH}.${HOLIDAYS_LIST}.${HOLIDAY}`),
          name: holiday.name,
        }}
      />
    </div>
  );
};

export default HolidayRow;
