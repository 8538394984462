import { NotificationVariant, NotificationVariantStyle } from "./types";
import { ReactComponent as InfoIcon } from "../../images/info-notification-icon.svg";
import { ReactComponent as SuccessIcon } from "../../images/success-notification-icon.svg";
import { ReactComponent as ErrorIcon } from "../../images/error-notification-icon.svg";

const infoStyle: NotificationVariantStyle = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  icon: InfoIcon,
  borderColor: "primary-51",
  backgroundColor: "primary-85",
};

const successStyle: NotificationVariantStyle = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  icon: SuccessIcon,
  borderColor: "success-60",
  backgroundColor: "success-99",
};

const errorStyle: NotificationVariantStyle = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  icon: ErrorIcon,
  borderColor: "error-50",
  backgroundColor: "error-90",
};

const notificationStyles: Record<
  NotificationVariant,
  NotificationVariantStyle
> = {
  error: errorStyle,
  info: infoStyle,
  success: successStyle,
};
export default notificationStyles;
