import { Modal, ModalContent } from "@ps/ui-components";
import { ReactElement } from "react";
import { OcrUploadModalProps } from "./types";
import { OCR_UPLOADER } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";

const OcrUploadWaitingModal = ({
  isOpen,
  onClose,
}: OcrUploadModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Modal
      dataCy={`${OCR_UPLOADER}_waitingModal`}
      isOpen={isOpen}
      onClose={onClose}
      width="w-1/2"
      height="h-auto"
    >
      <ModalContent
        withOverflow={false}
        additionalClassName="flex flex-col gap-2 items-center"
      >
        <p className="text-sm mb-1">{t("ocr.uploadWaitingBody")}</p>
      </ModalContent>
    </Modal>
  );
};

export default OcrUploadWaitingModal;
