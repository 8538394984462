import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { useThemeMode } from "../theme-mode-provider";
import { TABLE_SEARCH_BAR } from "../../shared/data-cy";
import { TableSearchBarProps } from "./types";

const TableSearchBar = ({
  children,
  dataCy,
  additionalClassName,
}: TableSearchBarProps): ReactElement => {
  const { isHighContrast } = useThemeMode();

  return (
    <div
      data-cy={`${TABLE_SEARCH_BAR}_${dataCy}`}
      className="flex flex-col w-full"
    >
      <div className="border border-primary-70 bg-neutralSecondary-90 h-1.5 rounded-t-md" />
      <div
        className={classJoin(
          "bg-neutralPrimary-100 rounded-b-md p-3 w-full shadow-md",
          isHighContrast && "border-b border-l border-r border-primary-60",
          additionalClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default TableSearchBar;
