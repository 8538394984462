import { ReactElement, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch } from "redux-react-hook";
import {
  LinkButton,
  Card,
  LoadingAnyPage,
  ScrollableWrapper,
} from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { ReactComponent as ArrowIcon } from "../../../images/more-arrow.svg";
import { fetchHolidaysUserDetails } from "../../store";
import { HOLIDAYS_USER, BASE_TRANSLATION_PATH } from "./constants";
import { UserRow } from "./common";
import { NoTemplate, FilledTemplate } from "./template-table";
import { HolidaysUserMapState, HolidaysUserMapStateReturn } from "./types";

const HolidaysUser = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams<{
    userId: string;
  }>();
  const { t } = useTranslationWithNamespace();

  useEffect(() => {
    if (userId) {
      fetchHolidaysUserDetails(dispatch, userId);
    }
  }, []);

  const mapState = (
    state: HolidaysUserMapState,
  ): HolidaysUserMapStateReturn => ({
    userDetails: state.holidays.userDetails || {},
    isLoading: state.requestStatus.isFetchingHolidaysUserDetails,
  });

  const { userDetails, isLoading } = useMappedStateSelector(mapState);

  if (isLoading) return <LoadingAnyPage />;

  return (
    <>
      <LinkButton
        dataCy={HOLIDAYS_USER}
        onClick={() => history.push("/finance/holidays")}
        additionalClassName="text-lg mb-4"
        prefixIcon={<ArrowIcon className="transform rotate-180 w-3 h-3" />}
      >
        {t(`${BASE_TRANSLATION_PATH}.backToList`)}
      </LinkButton>
      <ScrollableWrapper value={30}>
        <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
          <Card
            dataCy={HOLIDAYS_USER}
            additionalCardClassName="flex flex-col gap-10 rounded-lg p-6"
          >
            <UserRow user={userDetails} />
            {userDetails?.holidaysTemplate?.templateId ? (
              <FilledTemplate user={userDetails} userId={userId} />
            ) : (
              <NoTemplate />
            )}
          </Card>
        </Scrollbars>
      </ScrollableWrapper>
    </>
  );
};

export default HolidaysUser;
