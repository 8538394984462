import { ReactElement, useEffect, useRef } from "react";
import {
  axisBottom,
  axisLeft,
  max,
  scaleBand,
  scaleLinear,
  select,
  stack,
  Series,
} from "d3";
import dayjs, { Dayjs } from "dayjs";
import { useWindowResize } from "@ps/utils";
import { StackedBarChartProps } from "./types";
import { STACKED_BAR_CHART_PREFIX } from "../../shared/data-cy";
import { mouseOver, mouseMove, mouseLeave } from "./helpers/mouseControls";

const StackedBarChart = ({
  dataCy,
  data,
  keys,
  colors,
  allProjects,
  isYear = false,
  isMonth = false,
  chartHeight = "30rem",
}: StackedBarChartProps): ReactElement => {
  const size = useWindowResize();
  const svgRef = useRef<SVGSVGElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const svg = select(svgRef.current);
  const tooltipNode = select(tooltipRef.current);

  useEffect(() => {
    const tooltip = tooltipRef?.current;
    if (tooltip) tooltip.style.opacity = "0";
  }, []);

  useEffect(() => {
    svg.select(".x-axis").selectAll("g").remove();
    svg.select(".y-axis").selectAll("g").remove();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { width, height } = wrapperRef?.current.getBoundingClientRect();
    const stackGenerator = stack<Series, string>().keys(keys);
    const layers: Series[][] = stackGenerator(data);
    const extent: [number, number] = [
      0,
      max(layers, (layer: number) =>
        max(layer, (sequence: number[]) => sequence[1]),
      ),
    ];
    const yScale = scaleLinear().domain(extent).range([height, 0]);
    const x0Scale = scaleBand()
      .domain(data.map((d: { name: string }) => d.name))
      .range([0, width * 0.94])
      .padding(0.46);
    const x1Scale = scaleBand()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .domain(data.map((d: unknown) => d.type))
      .rangeRound([0, x0Scale.bandwidth()])
      .padding(0.12);

    const xAix = axisBottom(x0Scale);
    const yAix = axisLeft(yScale).scale(yScale).tickSize(-width);

    svg
      .select(".x-axis")
      .attr("transform", `translate(60, ${height + 10})`)
      .call(xAix);
    svg.select(".y-axis").attr("transform", "translate(60, 10 )").call(yAix);
    svg.select(".y-axis").selectAll(".domain").attr("class", "invisible");
    svg.select(".x-axis").selectAll(".domain").attr("class", "invisible");
    svg
      .select(".y-axis")
      .selectAll("line")
      .attr("class", "text-neutralPrimary-85");
    svg.select(".x-axis").selectAll("line").attr("class", "invisible");
    svg
      .select(".x-axis")
      .selectAll("text")
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .text((value) => {
        const formatType = isMonth ? "DD" : "DD.MM";
        return isYear
          ? value
          : dayjs(value as Dayjs, "DD.MM.YYY").format(formatType);
      });
    svg
      .selectAll(".layer")
      .data(layers)
      .join("g")
      .attr("class", "layer")
      .attr(
        "fill",
        (layer: Series<{ [key: string]: number }, string>) =>
          colors[layer.key] || "rgb(var(--color-neutral-primary-100))",
      )
      .attr("stroke", (layer: Series<{ [key: string]: number }, string>) =>
        colors[layer.key]
          ? colors[layer.key]
          : "rgb(var(--color-neutral-secondary-60))",
      )
      .attr("data-project-id", (layer: { key: string }) => layer.key)
      .selectAll("rect")
      .data((layer: number) => layer)
      .join("rect")
      .attr(
        "x",
        (sequence: { data: { name: string; type: string } }) =>
          x0Scale(sequence.data.name) + x1Scale(sequence.data.type),
      )
      .attr("width", x1Scale.bandwidth())
      .attr("y", (sequence: number[]) => yScale(sequence[1]))
      .attr(
        "height",
        (sequence: number[]) => yScale(sequence[0]) - yScale(sequence[1]) || 0,
      )
      .attr(
        "data-day-date",
        (sequence: { data: { name: string } }) => sequence.data.name,
      )
      .attr("transform", "translate(60, 9)")
      .on("mouseover", (event) =>
        mouseOver(event, svg, tooltipNode, allProjects, data, isYear),
      )
      .on("mousemove", (event) => mouseMove(event, tooltipNode))
      .on("mouseleave", () => mouseLeave(tooltipNode, svg))
      .selectAll("line");

    svg
      .select(".y-axis")
      .selectAll(".tick")
      .attr("class", "text-neutralPrimary-30 font-semibold text-lg")
      .selectAll("text")
      .text((value: number | string) =>
        Number.isInteger(value) ? `${value}:00` : "",
      );

    svg
      .select(".x-axis")
      .selectAll(".tick")
      .attr("class", "x-axis-scale-item")
      .attr("font-family", "Poppins")
      .attr("class", (dateValue: string) =>
        data.some((obj: { name: string }) => {
          if (obj.name === dateValue) return Object.keys(obj).length > 1;
          return Object.keys(obj);
        })
          ? "opacity-100 text-base font-light"
          : "opacity-25 text-base font-light",
      );
  }, [data, keys, colors, size, tooltipNode]);

  return (
    <div
      ref={wrapperRef}
      id={STACKED_BAR_CHART_PREFIX}
      className="w-full mb-14 relative"
      style={{ height: chartHeight }}
      data-cy={`${STACKED_BAR_CHART_PREFIX}-${dataCy}`}
    >
      <svg ref={svgRef} className="w-full relative" style={{ height: "110%" }}>
        <g className="x-axis" />
        <g className="y-axis" />
      </svg>
      <div
        ref={tooltipRef}
        className="tooltip z-50 border rounded-lg p-2 absolute bg-neutralPrimary-20 text-neutralPrimary-100 flex flex-col gap-2 pointer-events-none"
      >
        <span className="tooltip-date text-xs" />
        <div className="flex gap-4 items-center">
          <div className="flex items-center gap-2">
            <div className="project-dot-color w-2.5 h-2.5 rounded-full" />
            <span className="project-name" />
          </div>
          <span className="project-time-engagement" />
        </div>
      </div>
    </div>
  );
};

export default StackedBarChart;
