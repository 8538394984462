import { ReactElement, useState, Fragment } from "react";
import { classJoin, getNameFromDictionary } from "@ps/utils";
import { uid } from "react-uid";
import { SKILLS_SEARCH } from "../../../../shared/data-cy";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { SingleTechHeaderCell, SingleOtherTechHeaderCell } from "./header";
import SingleUserRow from "./row";
import { sortByKnowledgeScale, splitUsers } from "./utils";
import {
  ITemplateSkill,
  UsersTableMapState,
  UsersTableMapStateReturn,
  UsersTableProps,
} from "./types";
import { IUserSkillsSearch } from "../../../models/types";
import styles from "./styles.module.css";

const MUST_HAVE = "mustHave";

const UsersTable = ({ users, template }: UsersTableProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [expandedTechs, setExpandedTechs] = useState<string[]>([]);
  const [selectedUsersId, setSelectedUsersId] = useState<string[]>([]);
  const [usersList, setUsersList] = useState<IUserSkillsSearch[]>(users);
  const [sorter, setSorter] = useState({
    techId: "",
    asc: true,
  });

  const mapState = (state: UsersTableMapState): UsersTableMapStateReturn => ({
    technologyDict: state.dictionaries.technology || [],
    professionDict: state?.dictionaries?.profession || [],
  });

  const { technologyDict, professionDict } = useMappedStateSelector(mapState);

  const mustHaveSkills: ITemplateSkill[] = [];
  const niceToHaveSkills: ITemplateSkill[] = [];


  template?.forEach((item: ITemplateSkill) =>
    item.requiredness === MUST_HAVE
      ? mustHaveSkills.push(item)
      : niceToHaveSkills.push(item),
  );

  const onOpenTechDetailsClick = (techId: string): void => {
    const prevState = [...expandedTechs];
    const result = prevState.includes(techId)
      ? prevState.filter((item: string) => item !== techId)
      : [...prevState, techId];
    setExpandedTechs(result);
  };

  const onUserClick = (userId: string): void => {
    const prevState = [...selectedUsersId];
    const result = prevState.includes(userId)
      ? prevState.filter((item: string) => item !== userId)
      : [...prevState, userId];
    setSelectedUsersId(result);

    const checked: IUserSkillsSearch[] = [];
    const notChecked: IUserSkillsSearch[] = [];

    users.forEach((item: IUserSkillsSearch) =>
      result.includes(item.userId) ? checked.push(item) : notChecked.push(item),
    );

    setUsersList([...checked, ...notChecked]);
  };

  const sortByKnowledge = (techId: string): void => {
    const prevSorter = JSON.parse(JSON.stringify(sorter));
    if (techId === prevSorter.techId && !prevSorter.asc) {
      setSorter({ techId: "", asc: true });

      if (selectedUsersId?.length) {
        const { selectedUsers, restUsers } = splitUsers(users, selectedUsersId);
        setUsersList([...selectedUsers, ...restUsers]);
      } else setUsersList(users);
      return;
    }
    const isAsc = !prevSorter?.techId || techId !== prevSorter.techId;
    if (selectedUsersId?.length) {
      const { selectedUsers, restUsers } = splitUsers(users, selectedUsersId);
      const sortedSelected = sortByKnowledgeScale(selectedUsers, techId, isAsc);
      const sortedRest = sortByKnowledgeScale(restUsers, techId, isAsc);
      setUsersList([...sortedSelected, ...sortedRest]);
      setSorter({ techId, asc: isAsc });
    } else {
      const sorted = sortByKnowledgeScale(users, techId, isAsc);
      setUsersList(sorted);
      setSorter({ techId, asc: isAsc });
    }
  };

  return (
    <div
      className="relative h-screen bg-neutralPrimary-100 w-full rounded-t-xl pt-4"
      data-cy={SKILLS_SEARCH}
    >
      <table
        cellPadding="0"
        cellSpacing="0"
        className="absolute block w-full h-full overflow-scroll"
      >
        <thead>
          <tr
            className={classJoin(
              "h-20 w-full sticky top-0 bg-neutralPrimary-100 z-10",
              styles.headerRow,
            )}
          >
            <th className="min-w-56 sticky left-0 bg-neutralPrimary-100 z-10" />
            {mustHaveSkills?.map(
              (item: ITemplateSkill, index: number): ReactElement => (
                <Fragment key={uid(item)}>
                  <SingleTechHeaderCell
                    hasExpandedChildren={!!item.expandableSkills?.length}
                    isExpanded={expandedTechs.includes(item.skillId)}
                    isSorterChecked={sorter?.techId === item.skillId}
                    mainColor="primary-50"
                    onExpandClick={() => onOpenTechDetailsClick(item.skillId)}
                    onSort={() => sortByKnowledge(item.skillId)}
                    techName={getNameFromDictionary(
                      technologyDict,
                      item.skillId,
                    )}
                    title={index === 0 ? t("team.skillsSearch.mustHave") : ""}
                  />
                  {expandedTechs?.includes(item.skillId) &&
                    item.expandableSkills.map((expandableSkillProfession) => (
                      <SingleOtherTechHeaderCell
                        key={uid(expandableSkillProfession)}
                        techName={getNameFromDictionary(
                          technologyDict,
                          item.skillId,
                        )}
                        profession={getNameFromDictionary(
                          professionDict,
                          expandableSkillProfession,
                        )}
                        mainColor="primary-50"
                        additionalClassName={styles.expandingCell}
                      />
                    ))}
                </Fragment>
              ),
            )}
            {niceToHaveSkills?.map((item: ITemplateSkill, index: number) => {
              return (
                <Fragment key={uid(item)}>
                  <SingleTechHeaderCell
                    isSorterChecked={sorter?.techId === item.skillId}
                    onSort={() => sortByKnowledge(item.skillId)}
                    hasExpandedChildren={!!item.expandableSkills?.length}
                    isExpanded={expandedTechs.includes(item.skillId)}
                    onExpandClick={() => onOpenTechDetailsClick(item.skillId)}
                    techName={getNameFromDictionary(
                      technologyDict,
                      item.skillId,
                    )}
                    title={index === 0 ? t("team.skillsSearch.niceHave") : ""}
                    mainColor="primary-70"
                  />
                  {expandedTechs?.includes(item.skillId) &&
                    item.expandableSkills.map((expandableSkillProfession) => (
                      <SingleOtherTechHeaderCell
                        mainColor="primary-70"
                        key={uid(expandableSkillProfession)}
                        techName={getNameFromDictionary(
                          technologyDict,
                          item.skillId,
                        )}
                        profession={getNameFromDictionary(
                          professionDict,
                          expandableSkillProfession,
                        )}
                        additionalClassName={styles.expandingCell}
                      />
                    ))}
                </Fragment>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {usersList?.map(
            (user: IUserSkillsSearch): ReactElement => (
              <SingleUserRow
                key={uid(user)}
                templateSkills={[...mustHaveSkills, ...niceToHaveSkills]}
                userName={`${user.firstName} ${user.lastName}`}
                userSkills={user.skills}
                expandedTechs={expandedTechs}
                onClick={onUserClick}
                userId={user.userId}
                isSelected={selectedUsersId.includes(user.userId)}
              />
            ),
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
