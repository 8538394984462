import dayjs, { Dayjs } from "dayjs";

export const parseDate =
  (year: number) =>
  (month: number) =>
  (day: number): Dayjs =>
    dayjs(new Date(year, month, day));

export const parseArray = (date: Dayjs): ((day: number) => Dayjs) =>
  parseDate(date.year())(date.month());

export const makeIncrementingArray = (length: number, start = 1): number[] =>
  new Array(length).fill(0).map((_, index) => start + index);

export const makeArrayFromUpperBound = (
  length: number,
  start = 0,
): number[] => {
  return new Array(length)
    .fill(0)
    .map((_, index) => start - length + index + 1);
};

export const roundWeekDays = (weekDayNum: number): number =>
  weekDayNum < 0 ? 7 + weekDayNum : weekDayNum;

const getDaysArray = (displayMonth?: Dayjs): Dayjs[][] => {
  const CALENDAR_GRID_ROWS = 6;
  const CALENDAR_GRID_COLS = 7;

  const initialDate = displayMonth ?? dayjs();

  const prevMonth = initialDate.subtract(1, "month");
  const nextMonth = initialDate.add(1, "month");

  const numOfDaysInMonth = initialDate.daysInMonth();
  const firstDayOfMonthWeekDay = initialDate.startOf("month").day();
  const initialMonthArray = makeIncrementingArray(numOfDaysInMonth);

  const numOfDaysFromPrevMonth = roundWeekDays(
    firstDayOfMonthWeekDay - dayjs.localeData().firstDayOfWeek(),
  );
  const prevMonthDaysInMonth = prevMonth.daysInMonth();
  const prevMonthArray = makeArrayFromUpperBound(
    numOfDaysFromPrevMonth,
    prevMonthDaysInMonth,
  );

  const numOfDaysFromNextMonth =
    CALENDAR_GRID_ROWS * CALENDAR_GRID_COLS -
    (prevMonthArray.length + initialMonthArray.length);
  const nextMonthArray = makeIncrementingArray(numOfDaysFromNextMonth);

  return [
    prevMonthArray.map(parseArray(prevMonth)),
    initialMonthArray.map(parseArray(initialDate)),
    nextMonthArray.map(parseArray(nextMonth)),
  ];
};
export default getDaysArray;
