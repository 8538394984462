import { Empty, Label } from "@ps/ui-components";
import { ReactElement } from "react";
import { getOverallExperience } from "@ps/utils";
import { MAIN_PROFILE } from "../../../../shared/data-cy";
import {
  EXPERIENCE_AMOUNT,
  FIRST_NAME,
  LAST_NAME,
  MAIN_PROFESSION,
  PROFESSIONS,
  ROLE,
  SENIORITY,
} from "../constants";
import {
  BaseLabelProps,
  BasicInfoMapState,
  BasicInfoMapStateReturn,
  BasicInfoProps,
} from "./types";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { DictionaryEntryModel } from "../../../../dictionaries";
import { renderFieldConditionally } from "../utils";
import { OPERATIVE, SPECIALIST } from "../../../../shared/constants";

const BaseLabel = ({ title, item, value }: BaseLabelProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <Label
      dataCy={`${title}-${item}`}
      key={`${MAIN_PROFILE}-${title}-${item}`}
      text={t(`profile.overview.${title}.${item}`)}
    >
      {value ? <span className="capitalize">{value}</span> : <Empty />}
    </Label>
  );
};

const BasicInfo = ({
  careerSpan,
  fields,
  firstName,
  lastName,
  mainProfession,
  professions,
  profile,
  roles,
  seniority,
  title,
}: BasicInfoProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const mapState = (state: BasicInfoMapState): BasicInfoMapStateReturn => ({
    seniorityDict: state.dictionaries?.seniority || [],
    professionsDict: state.dictionaries?.profession || [],
    roleDict: state.dictionaries?.project_role || [],
    operativeProfessionsDict: state.dictionaries?.operative_profession || [],
  });

  const { seniorityDict, professionsDict, roleDict, operativeProfessionsDict } =
    useMappedStateSelector(mapState);

  const professionDictionary =
    profile === SPECIALIST ? professionsDict : operativeProfessionsDict;

  const getProfessionNames = (professionsIds: string[]) =>
    professionsIds?.length
      ? professionsIds
          .map((id) => {
            const item = professionDictionary?.find(
              (singleProfession: DictionaryEntryModel) =>
                singleProfession?.id === id,
            );
            return item?.name;
          })
          .join(", ")
      : "";

  const getRoleNames = (rolesIds: string[]) =>
    rolesIds?.length
      ? rolesIds
          .map((id) => {
            const item = roleDict?.find(
              (singleRole: DictionaryEntryModel) => singleRole?.id === id,
            );
            return item?.name;
          })
          .join(", ")
      : "";

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const experienceAmount = getOverallExperience(careerSpan);

  return (
    <div className="flex flex-col gap-6 w-full">
      <div className="grid grid-cols-4">
        <BaseLabel title={title} item={FIRST_NAME} value={firstName} />
        <BaseLabel title={title} item={LAST_NAME} value={lastName} />
        {profile === OPERATIVE && (
          <Label
            dataCy={`${title}-${PROFESSIONS}`}
            key={`${MAIN_PROFILE}-${title}-${PROFESSIONS}`}
            text={t(`profile.overview.${title}.operativeProfessions`)}
          >
            {professions?.length ? (
              <span className="capitalize">
                {getProfessionNames(professions)}
              </span>
            ) : (
              <Empty />
            )}
          </Label>
        )}
        {renderFieldConditionally(fields, MAIN_PROFESSION) && (
          <BaseLabel
            title={title}
            item={MAIN_PROFESSION}
            value={getProfessionNames([mainProfession])}
          />
        )}
        {profile === SPECIALIST && (
          <BaseLabel
            title={title}
            item={PROFESSIONS}
            value={getProfessionNames(professions)}
          />
        )}
      </div>
      <div className="grid grid-cols-4">
        {renderFieldConditionally(fields, SENIORITY) && (
          <BaseLabel
            title={title}
            item={SENIORITY}
            value={
              seniorityDict?.find(
                (item: DictionaryEntryModel) => item?.id === seniority,
              )?.name || ""
            }
          />
        )}
        {renderFieldConditionally(fields, ROLE) && (
          <BaseLabel title={title} item={ROLE} value={getRoleNames(roles)} />
        )}
        {renderFieldConditionally(fields, EXPERIENCE_AMOUNT) &&
        (experienceAmount.months || experienceAmount.years) ? (
          <Label
            dataCy={`${title}-${EXPERIENCE_AMOUNT}`}
            key={`${MAIN_PROFILE}-${title}-${EXPERIENCE_AMOUNT}`}
            text={t(`profile.overview.${title}.${EXPERIENCE_AMOUNT}`)}
          >
            {`${
              experienceAmount.years
                ? t(`profile.overview.${title}.years`, {
                    count: experienceAmount.years,
                  })
                : ""
            } ${
              experienceAmount.months > 0
                ? t(`profile.overview.${title}.months`, {
                    count: experienceAmount.months,
                  })
                : ""
            }`}
          </Label>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default BasicInfo;
