export const DEFAULT_ROUTE = "/";
export const MAIN = "main";
export const USER = "user";
export const PERSONAL_INFO = "personal-info";
export const EXPERIENCE = "experience";
export const SKILLS = "skills";
export const PROFILE = "profile";
export const ALL_PROSPECTS = "all-prospects";
export const MY_PROSPECTS = "my-prospects";
export const OLD_SUMMARY = "oldsummary";
export const SUMMARY = "summary";
export const ALL_PROJECTS = "all-projects";
export const MY_PROJECTS = "my-projects";
export const NEW_PROJECT = "newproject";
export const USERS_LIST = "users-list";
export const SKILLS_SEARCH = "skills-search";
export const BENCH = "bench";
export const HOLIDAYS = "holidays";
export const HOLIDAYS_TEMPLATES = "holidays-templates";
export const PROFILE_WIZARD = "wizard";
export const FINANCES = "finances";
