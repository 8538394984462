import { ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { hasPermissions, TabsContainer } from "@ps/ui-components";
import { dashConverter, getSlug, permissionsMappers } from "@ps/utils";
import { useDispatch } from "redux-react-hook";
import { MATRIX, SETTINGS } from "../../../shared/routes";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { DOMAINS_PROFESSION_ID, MATRIX_TABS } from "../../../shared/consts";
import { NAMESPACE } from "../../../../shared/constants";
import {
  MatrixTabContainerMapState,
  MatrixTabContainerMapStateReturn,
  MatrixTabContainerProps,
} from "./types";
import { fetchTemplates } from "../../../store";
import { DictionaryEntryModel } from "../../../../dictionaries";

const MatrixTabContainer = ({
  children,
}: MatrixTabContainerProps): ReactElement => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslationWithNamespace();

  const defaultTab =
    getSlug(dashConverter(pathname)) === MATRIX
      ? SETTINGS
      : getSlug(dashConverter(pathname));
  const [activeTab, setActiveTab] = useState<string>(defaultTab);
  const { path } = useRouteMatch();

  useEffect(() => {
    setActiveTab(getSlug(dashConverter(pathname)));
  }, [pathname, activeTab]);

  const mapState = (
    state: MatrixTabContainerMapState,
  ): MatrixTabContainerMapStateReturn => ({
    allPermissions: state.permissions || [],
    editMode: state.editMode,
    professionsDict: state.dictionaries?.profession || [],
  });

  const { editMode, professionsDict } = useMappedStateSelector(mapState);
  const { allPermissions } = useMappedStateSelector(mapState, "");

  useEffect(() => {
    if (professionsDict?.length) {
      professionsDict
        .filter((item: DictionaryEntryModel) => !item.deleted)
        .map((profession: DictionaryEntryModel) =>
          fetchTemplates(profession.itemId || "", dispatch, t),
        );
    }
    fetchTemplates(DOMAINS_PROFESSION_ID || "", dispatch, t);
  }, [professionsDict]);

  const handleOnTabChange = (slug: string): void => {
    history.push(`${path}/${slug}`);

    history.listen(() => setActiveTab(slug));
    if (!editMode) setActiveTab(slug);
  };

  return (
    <TabsContainer
      activeTab={activeTab}
      handleOnTabChange={handleOnTabChange}
      prefix={`${NAMESPACE}_matrix`}
      tabs={permissionsMappers.filterTabsWithPermissions(
        MATRIX_TABS,
        hasPermissions,
        allPermissions,
      )}
      translationID={NAMESPACE}
      translationPath="expertise.matrix.tabs"
    >
      {children}
    </TabsContainer>
  );
};

export default MatrixTabContainer;
