import { ReactElement, useState, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { LoadingAnyPage } from "@ps/ui-components";
import { useMappedStateSelector } from "../../hooks";
import { HolidaysTemplateModel } from "../models";
import { fetchHolidaysTemplates } from "../store";
import { HolidaysTemplatesContext } from "./context";
import Table from "./table";
import {
  HolidaysTemplatesMapState,
  HolidaysTemplatesMapStateReturn,
} from "./types";

const HolidaysTemplates = (): ReactElement => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchHolidaysTemplates(dispatch);
  }, []);

  const mapState = (
    state: HolidaysTemplatesMapState,
  ): HolidaysTemplatesMapStateReturn => ({
    templates: state?.holidaysTemplates?.holidaysTemplates || [],
    isLoading: state?.requestStatus?.isFetchingHolidays,
  });

  const { templates, isLoading } = useMappedStateSelector(mapState);

  const [currentTemplate, setCurrentTemplate] = useState<HolidaysTemplateModel>(
    templates?.[0],
  );
  const [recentlyEditedTemplateId, setRecentlyEditedTemplateId] = useState("");

  const setMostCurrentTemplate = (): void => {
    const newCurrentTemplate = templates.find(
      (template: HolidaysTemplateModel) =>
        template.id === recentlyEditedTemplateId,
    );
    setCurrentTemplate(newCurrentTemplate || templates?.[0]);
    setRecentlyEditedTemplateId("");
  };

  useEffect(() => {
    setMostCurrentTemplate();
  }, [templates]);

  if (isLoading) return <LoadingAnyPage />;

  return (
    <HolidaysTemplatesContext.Provider
      value={{
        currentTemplate,
        setCurrentTemplate,
        recentlyEditedTemplateId,
        setRecentlyEditedTemplateId,
      }}
    >
      <Table />
    </HolidaysTemplatesContext.Provider>
  );
};

export default HolidaysTemplates;
