enum ActionTypes {
  CREATE_PROJECT_START = "CREATE_PROJECT_START",
  CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS",
  CREATE_PROJECT_ERROR = "CREATE_PROJECT_ERROR",

  FETCH_PROJECT_START = "FETCH_PROJECT_START",
  FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS",
  FETCH_PROJECT_ERROR = "FETCH_PROJECT_ERROR",

  FETCH_PROJECTS_START = "FETCH_PROJECTS_START",
  FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS",
  FETCH_PROJECTS_ERROR = "FETCH_PROJECTS_ERROR",

  UPDATE_PROJECT_START = "UPDATE_PROJECT_START",
  UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS",
  UPDATE_PROJECT_ERROR = "UPDATE_PROJECT_ERROR",

  FETCH_MY_PROJECTS_START = "FETCH_MY_PROJECTS_START",
  FETCH_MY_PROJECTS_SUCCESS = "FETCH_MY_PROJECTS_SUCCESS",
  FETCH_MY_PROJECTS_ERROR = "FETCH_MY_PROJECTS_ERROR",
}

export default ActionTypes;
