import { Fragment, ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, LinkButton } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import {
  DETAILS_OPERATIVE_ITEMS,
  DETAILS_SPECIALIST_ITEMS,
  GOALS_PREVIOUS_YEAR,
} from "./constants";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import {
  DetailsSectionMapState,
  DetailsSectionMapStateReturn,
  DetailsSectionProps,
  SectionProps,
} from "./types";
import {
  MAIN_DETAILS_SECTION,
  MAIN_PROFILE_CONTENT,
  MAIN_PROFILE_EDIT,
  MAIN_PROFILE_LABEL,
} from "../../../shared/data-cy";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import styles from "./styles.module.css";
import { EditMode } from "./edit";
import ViewMode from "./view/viewMode";
import {
  SPECIALIST,
  CURRENT_YEAR,
  PREVIOUS_YEAR,
} from "../../../shared/constants";
import useFocusEdit from "../../hooks/useFocusEdit";

const BASE_PATH = "profile.overview";

const DetailsSection = ({
  canEdit,
  toggleEditMode,
  profile,
}: DetailsSectionProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const history = useHistory();

  const [lastFocusID, setLastFocusID] = useState("");

  const mapState = (
    state: DetailsSectionMapState,
  ): DetailsSectionMapStateReturn => ({
    editMode: state.editMode,
    profileDetails: state?.profiles?.myProfile,
  });

  const { editMode, profileDetails } = useMappedStateSelector(mapState);

  const [editSection, setEditSection] = useState("");

  useFocusEdit(editSection);

  const getTitleCardStyles = (title: string): string =>
    classJoin(
      "flex justify-center items-center h-24.25 shadow-none",
      !editMode || editMode === title
        ? "opacity-1"
        : "opacity-40 pointer-events-none",
    );

  const getDataCardStyles = (title: string): string =>
    classJoin(
      "flex items-start px-8 py-5 justify-start gap-16 h-auto",
      !editMode || editMode === title
        ? "opacity-1"
        : "opacity-40 pointer-events-none",
    );

  const currentProfileItems =
    profileDetails?.profile === SPECIALIST || profile?.profile === SPECIALIST
      ? DETAILS_SPECIALIST_ITEMS
      : DETAILS_OPERATIVE_ITEMS;

  const noPreviousYear = (sectionTitle: string): boolean =>
    sectionTitle !== GOALS_PREVIOUS_YEAR;

  useEffect(() => {
    history.replace({ ...history.location, state: undefined });
  }, []);

  const handleEditClick = (title: string): void => {
    toggleEditMode(title);
    setEditSection(title);
  };

  return (
    <div
      className={classJoin(
        "grid gap-y-8",
        editMode ? styles.detailsContainerEdit : styles.detailsContainer,
      )}
      data-cy={MAIN_DETAILS_SECTION}
    >
      {currentProfileItems.map((section: SectionProps) => (
        <Fragment key={section.title}>
          <Card
            additionalCardClassName={getTitleCardStyles(section.title)}
            dataCy={`${MAIN_PROFILE_LABEL}-${section.title}`}
          >
            <span className="text-center text-lg font-semibold px-3">
              {t(`${BASE_PATH}.details.${section.title}`, {
                year: noPreviousYear(section.title)
                  ? CURRENT_YEAR
                  : PREVIOUS_YEAR,
              })}
            </span>
          </Card>
          <Card
            additionalCardClassName={classJoin(
              getDataCardStyles(section.title),
              editMode && editMode === section.title
                ? styles.editOpen
                : styles.editClose,
            )}
            dataCy={`${MAIN_PROFILE_CONTENT}-${section.title}`}
          >
            {editMode &&
            editMode === section.title &&
            noPreviousYear(section.title) ? (
              <EditMode
                data={profileDetails}
                section={editMode}
                toggleEditMode={toggleEditMode}
                profile={profile}
                setLastFocusID={setLastFocusID}
                lastFocusID={lastFocusID}
              />
            ) : (
              <>
                <ViewMode section={section} profile={profile} />
                {canEdit && noPreviousYear(section.title) && (
                  <LinkButton
                    id={section.title}
                    additionalClassName="ml-auto"
                    dataCy={`${MAIN_PROFILE_EDIT}-${section.title}`}
                    onClick={() => handleEditClick(section.title)}
                    disabled={editMode && editMode !== section.title}
                    prefixIcon={<EditIcon className="fill-current" />}
                  >
                    {t(`${BASE_PATH}.actions.edit`)}
                  </LinkButton>
                )}
              </>
            )}
          </Card>
        </Fragment>
      ))}
    </div>
  );
};

export default DetailsSection;
