import { classJoin } from "@ps/utils";

export const wrapperStyle = classJoin.template`
focus-within:text-neutralPrimary-20
flex flex-col
relative text-neutralPrimary-20
`;

export const inputBaseStyle = classJoin.template`
rounded-md h-8.5 bg-neutralPrimary-100
text-neutralPrimary-20 border p-3 focus:outline-none
focus:border-secondary-50
`;

export const focusVisibleStyles = classJoin.template`
focus:outline-none focus-visible:ring-2
focus-visible:ring-secondary-50
`;
