import { ReactElement, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useDispatch } from "redux-react-hook";
import { SaveCancelBar } from "@ps/ui-components";
import { isAPIError } from "@ps/api-utils";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../hooks";
import { toggleEditMode } from "../../../store";
import { fetchHolidaysTemplates } from "../../store";
import HolidaysTemplateService from "../../services/holidays-templates";
import { HolidaysTemplateModel, HolidaysItemModel } from "../../models";
import {
  BASE_TRANSLATION_PATH,
  HOLIDAYS_TEMPLATES,
  BOTTOM_INFO,
  CREATE,
  SAVE,
  TEMPLATE,
  CANCEL,
  TEMP_HOLIDAY_ID,
  TEMP_TEMPLATE_ID,
} from "../constants";
import { useHolidaysTemplatesContext } from "../context";
import { ConfirmModal } from "../modals";
import { FooterSaveBarProps, FooterSaveBarMapState } from "./types";

const FooterSaveBar = ({
  handleCancelNewHoliday,
  handleCancelNewTemplate,
}: FooterSaveBarProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const { trigger, getValues, formState, control } = useFormContext();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const { currentTemplate, setRecentlyEditedTemplateId } =
    useHolidaysTemplatesContext();

  const [isSaveModalOpened, setIsSaveModalOpened] = useState(false);

  const mapState = (state: FooterSaveBarMapState): FooterSaveBarMapState => ({
    editMode: state?.editMode,
  });

  const { editMode } = useMappedStateSelector(mapState);

  const [type, typeId] = editMode?.split(":");
  const currentTemplateId = currentTemplate?.id;

  // to watch if any field has change after immediately blur save
  const watchedData = useWatch({
    control,
    name: type,
  });

  useEffect(() => {
    setIsFormChanged(true);
    return () => setIsFormChanged(false);
  }, [watchedData]);

  const createNewTemplate = async (
    newTemplate: HolidaysTemplateModel,
  ): Promise<void> => {
    const result = await HolidaysTemplateService.createHolidaysTemplate(
      newTemplate,
    );
    if (!isAPIError(result) && result?.data)
      setRecentlyEditedTemplateId(result?.data);
  };

  const updateTemplate = async (updatedTemplateName: string): Promise<void> => {
    const result = await HolidaysTemplateService.updateHolidaysTemplate(
      currentTemplateId,
      updatedTemplateName,
    );
    if (!isAPIError(result)) setRecentlyEditedTemplateId(currentTemplateId);
  };

  const createNewHoliday = async (
    newHoliday: HolidaysItemModel,
  ): Promise<void> => {
    const result = await HolidaysTemplateService.createHolidaysItem(
      currentTemplateId,
      newHoliday,
    );
    if (!isAPIError(result)) setRecentlyEditedTemplateId(currentTemplateId);
  };

  const updateHoliday = async (
    updatedHoliday: HolidaysItemModel,
  ): Promise<void> => {
    const result = await HolidaysTemplateService.updateHolidaysItem(
      currentTemplateId,
      updatedHoliday,
    );
    if (!isAPIError(result)) setRecentlyEditedTemplateId(currentTemplateId);
  };

  const onSubmit = async (): Promise<void> => {
    // to consider native methods.handleSubmit rather than getValues
    const { template: newTemplate, holiday: newHoliday } = getValues();

    if (type === TEMPLATE && typeId === TEMP_TEMPLATE_ID) {
      await createNewTemplate(newTemplate);
    } else if (type === TEMPLATE) {
      await updateTemplate(newTemplate.name);
    } else if (typeId === TEMP_HOLIDAY_ID) {
      await createNewHoliday(newHoliday);
    } else {
      await updateHoliday(newHoliday);
    }

    await fetchHolidaysTemplates(dispatch);
    dispatch(toggleEditMode(""));
    setIsSaveModalOpened(false);
  };

  const isTempTypeId =
    typeId === TEMP_TEMPLATE_ID || typeId === TEMP_HOLIDAY_ID;

  const handleOnSave = async () => {
    const isValid = await trigger(type);
    if (!formState.isDirty && !isTempTypeId && isValid && !isFormChanged) {
      return dispatch(toggleEditMode(""));
    }
    if (isValid) {
      setIsSaveModalOpened(true);
    }
    return undefined;
  };

  return (
    <>
      <SaveCancelBar
        dataCy={`${HOLIDAYS_TEMPLATES}_${BOTTOM_INFO}`}
        barLabel={
          isTempTypeId
            ? t(`${BASE_TRANSLATION_PATH}.${BOTTOM_INFO}.createData`, { type })
            : t(`${BASE_TRANSLATION_PATH}.${BOTTOM_INFO}.saveChanges`, {
                name: currentTemplate.name,
              })
        }
        saveButton={{
          label: t(
            `${BASE_TRANSLATION_PATH}.${BOTTOM_INFO}.${
              isTempTypeId ? CREATE : SAVE
            }`,
          ),
          onClick: handleOnSave,
        }}
        cancelButton={{
          label: t(`${BASE_TRANSLATION_PATH}.${BOTTOM_INFO}.${CANCEL}`),
          onClick: () => {
            if (type === TEMPLATE) return handleCancelNewTemplate();
            return handleCancelNewHoliday();
          },
        }}
      />
      <ConfirmModal
        isOpen={isSaveModalOpened}
        onSave={onSubmit}
        onCancel={() => setIsSaveModalOpened(false)}
      />
    </>
  );
};

export default FooterSaveBar;
