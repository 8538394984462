import { ReactElement, ChangeEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import { Textarea } from "@ps/ui-components";
import TilesGroup from "./tilesGroup";
import { KNOWLEDGE_SCALE } from "../../../../../shared/consts";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { KnowledgeScaleWithDescProps } from "./types";

const KnowledgeScaleWithDesc = ({
  item,
  fields,
  index,
}: KnowledgeScaleWithDescProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { control } = useFormContext();

  return (
    <div className="flex flex-col w-96" key={uid(item)}>
      <TilesGroup
        tiles={fields}
        withColor={item?.score}
        baseName={KNOWLEDGE_SCALE}
        withoutFirst
        scaleIndex={index}
        disabled
      />
      <Controller
        name={`${KNOWLEDGE_SCALE}.[${index}].desc`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Textarea
            dataCy={KNOWLEDGE_SCALE}
            height="h-32"
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
              onChange(event.target.value)
            }
            defaultValue={value}
            error={!!error}
            message={error?.message}
          />
        )}
        rules={{
          required: {
            value: true,
            message: `${t("expertise.errors.isRequired")}`,
          },
        }}
      />
    </div>
  );
};

export default KnowledgeScaleWithDesc;
