import { ReactElement, useState, KeyboardEvent } from "react";
import { uid } from "react-uid";
import { useLocation } from "react-router-dom";
import { useWindowResize, classJoin } from "@ps/utils";
import { ReactComponent as ProjectsIcon } from "../../../../images/profile-summary/project.svg";
import { ReactComponent as TileViewIcon } from "../../../../images/profile-summary/tile-view.svg";
import { ReactComponent as ListViewIcon } from "../../../../images/profile-summary/list-view.svg";
import SingleProjectTile from "./singleProjectTile";
import SingleProjectList from "./singleProjectList";
import { useTranslationWithNamespace } from "../../../../hooks";
import { Keys, focusVisibleStyles } from "../../../../shared";
import { PROFILE_SUMMARY_PROJECTS } from "../../../../shared/data-cy";
import { ProjectsProps, ProjectProps } from "../../types";
import { LIST, TILE } from "../constants";
import ExpandedCard, { CardHeader } from "../../../expanded-card";
import Timeline from "../../../timeline";

const BASE_PATH = "profileSummary";

const Projects = ({
  projects,
  technologyDict,
  projectRolesDict,
  domainsDict,
}: ProjectsProps): ReactElement => {
  // TODO refactor if all summary view update
  const { pathname } = useLocation();

  const isSummary = pathname.includes("summary");

  const { t } = useTranslationWithNamespace();
  const [view, setView] = useState<"list" | "tile">(LIST);
  const [isOpen, setIsOpen] = useState(false);
  const { width = 0 } = useWindowResize();

  const isMobile = width < 768;

  const defaultProjectsAmount = isMobile ? 2 : 3;

  const projectsToRender: ProjectProps[] = isOpen
    ? projects
    : projects.slice(0, defaultProjectsAmount);

  const renderViewOption = (
    viewOption: "list" | "tile",
    Icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >,
  ): ReactElement => (
    <div
      className={classJoin(
        "rounded-md p-2 border cursor-pointer",
        focusVisibleStyles,
        view === viewOption
          ? "bg-primary-50 border-primary-50"
          : "border-neutralPrimary-50",
      )}
      role="button"
      tabIndex={0}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === Keys.ENTER) setView(viewOption);
      }}
      onClick={() => setView(viewOption)}
    >
      <Icon
        className={classJoin(
          view === viewOption
            ? "text-neutralPrimary-100"
            : "text-neutralPrimary-50",
          "w-5 h-5",
        )}
      />
    </div>
  );

  return (
    <ExpandedCard
      isOpen={isOpen}
      dataCy={PROFILE_SUMMARY_PROJECTS}
      additionalContentClassName="py-6 px-6 flex flex-col gap-4"
      additionalCardClassName="shadow-none"
      onClick={() => setIsOpen((prev: boolean) => !prev)}
      openLabel={t(`${BASE_PATH}.seeMoreProjects`, {
        count: projects.length - defaultProjectsAmount,
      })}
      expandDirection={projects.length > defaultProjectsAmount ? "down" : ""}
    >
      <>
        {!isSummary && (
          <CardHeader
            Icon={ProjectsIcon}
            dataCy={`${PROFILE_SUMMARY_PROJECTS}_skills`}
            width="w-full"
          >
            <div className="flex justify-between items-center w-full">
              <span>{t(`${BASE_PATH}.projects`)}</span>
              <div className="flex items-center gap-3">
                {renderViewOption(TILE, TileViewIcon)}
                {renderViewOption(LIST, ListViewIcon)}
              </div>
            </div>
          </CardHeader>
        )}

        {view === TILE ? (
          <div
            className={`grid col-auto gap-4 grid-cols-1 md:grid-cols-${
              projects.length >= defaultProjectsAmount
                ? defaultProjectsAmount
                : projects.length
            } pt-6`}
          >
            {projectsToRender?.length ? (
              projectsToRender.map(
                (item: ProjectProps): ReactElement => (
                  <SingleProjectTile
                    project={item}
                    projectRolesDict={projectRolesDict}
                    technologyDict={technologyDict}
                    key={uid(item)}
                    domainsDict={domainsDict}
                  />
                ),
              )
            ) : (
              <span>{t(`${BASE_PATH}.nothingHere`)}</span>
            )}
          </div>
        ) : (
          <Timeline dataCy={PROFILE_SUMMARY_PROJECTS} offset={3}>
            {projectsToRender?.length ? (
              projectsToRender.map(
                (item: ProjectProps): ReactElement => (
                  <SingleProjectList
                    project={item}
                    projectRolesDict={projectRolesDict}
                    technologyDict={technologyDict}
                    domainsDict={domainsDict}
                    key={uid(item)}
                  />
                ),
              )
            ) : (
              <span>{t(`${BASE_PATH}.nothingHere`)}</span>
            )}
          </Timeline>
        )}
      </>
    </ExpandedCard>
  );
};

export default Projects;
