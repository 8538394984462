import { NewClientAPIModel, NewClientModel } from "../types";

const prepareDomains = (domains: string[] | []) => {
  if (!domains) return [];
  if (domains.length && typeof domains[0] === "string") return domains;
  return domains.map((item) => item?.id);
};

const toAPIModel = (client: NewClientModel): NewClientAPIModel =>
  ({
    clientName: client?.clientName,
    contactPersonName: client?.contactPersonName,
    contactPersonEmail: client?.contactPersonEmail,
    country: client?.country?.value || null,
    city: client?.city,
    phoneNumber: client?.phoneNumber,
    domains: prepareDomains(client?.domains || []),
  } as NewClientAPIModel);

export default toAPIModel;
