import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Keys } from "../shared";

const useFocus = (focus?: boolean, withEsc?: boolean): string[] => {
  const focusId = uuidv4();
  useEffect(() => {
    if (!focus) return;
    let focusEl = document.getElementById(focusId) as HTMLElement | null;
    if (!focusEl) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === Keys.ESC && focusEl) {
        focusEl.focus();
      }
    };
    if (withEsc) document.addEventListener("keydown", handleKeyDown);

    focusEl.focus();

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      focusEl = null;
    };
  });

  return [focusId];
};

export default useFocus;
