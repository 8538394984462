import { ReactElement, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import { Panel } from "@ps/ui-components";
import { SKILLS_MATRIX_EXPERTISE_TEMPLATE } from "../../../../../../../shared/data-cy";
import TechnologiesTable from "./technologiesTable";
import { useEditMatrixContext } from "../../context";
import DeleteGroupModal from "../modals/confirmRemoveGroupModal";
import { EditPanelButtons } from "../buttons";
import { useTranslationWithNamespace } from "../../../../../../../hooks";
import { IGroup, SingleGroupProps } from "../types";
import { DOMAINS_PROFESSION_ID } from "../../../../../../shared/consts";

const SingleGroup = ({
  baseGroups,
  enjoymentScale,
  group: { name, skills, id, itemId, isNew },
  index,
  knowledgeScale,
  onSubmit,
  openedPanels,
  professionId,
  removeGroupFromArray,
  handleTogglePanel,
}: SingleGroupProps): ReactElement => {
  const { control, handleSubmit, getValues } = useFormContext();
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const { t } = useTranslationWithNamespace();
  const {
    editGroups,
    setEditGroups,
    editProfession,
    setEditTechnologies,
    editTechnologies,
  } = useEditMatrixContext();

  const isEdit =
    editGroups.includes(id || "") || editGroups.includes(itemId || "");
  const isDisabled = !!editProfession;
  const panelKey = `${professionId}-${name?.replaceAll(" ", "_")}`;
  const groupPathName = `groups[${index}].skills`;
  const isPanelOpen = openedPanels.indexOf(panelKey) === 0;

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: groupPathName,
  });

  const handleCollapsePanelClick = (): void => {
    if (editTechnologies.length) return;
    handleTogglePanel(panelKey);
  };

  const handleOnAddTechnology = (): void => {
    const newTechId = `new_${fields?.length}_${Math.random()}`;
    setEditTechnologies((prev) => [...prev, newTechId]);
    prepend({
      desc: null,
      difficulty: null,
      isNew: true,
      technologyId: newTechId,
    });
    if (openedPanels.includes(panelKey)) return;
    handleCollapsePanelClick();
  };

  const handleValidateGroup = (value: string): boolean => {
    if (
      baseGroups
        ?.filter((_, valueIndex) => valueIndex !== index)
        .some(
          (item: IGroup) =>
            isEdit &&
            item.name.replaceAll(" ", "").toLowerCase() ===
              value.replaceAll(" ", "").toLowerCase(),
        )
    ) {
      return t("expertise.errors.groupNameExists");
    }
    return true;
  };

  const handleOnEditGroupCancel = (groupId: string): void => {
    setEditGroups([]);
    setEditTechnologies((prev: string[]) =>
      prev.filter((item: string) => item !== groupId),
    );
  };

  const handleEditButtonClick = (): void =>
    setEditGroups((prev: string[]) => [...prev, id || ""]);

  const handleDeleteGroup = (): void => {
    setIsRemoveModalVisible(false);
    removeGroupFromArray();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    handleSubmit(onSubmit(getValues()));
  };

  const handleCancelButtonClick = (): void => handleOnEditGroupCancel(id || "");
  const handleRemoveButtonClick = (): void =>
    isNew ? handleDeleteGroup() : setIsRemoveModalVisible(true);

  const cantEdit =
    isDisabled ||
    professionId === DOMAINS_PROFESSION_ID ||
    isNew ||
    isPanelOpen;

  const cantRemove = professionId === DOMAINS_PROFESSION_ID;

  return (
    <Droppable droppableId={`${index}`}>
      {(provided: DroppableProvided): ReactElement => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <Controller
            name={`groups[${index}].name`}
            rules={{
              required: {
                value: true,
                message: t("expertise.errors.isRequired"),
              },
              validate: (value) => {
                return handleValidateGroup(value);
              },
            }}
            render={({ fieldState: { error }, field: { value, onChange } }) => (
              <Panel
                panelKey={panelKey}
                openedPanels={openedPanels}
                disabled={isDisabled}
                canBeEdit
                dataCy={SKILLS_MATRIX_EXPERTISE_TEMPLATE}
                disableCardWrapper
                isPanelNameEdit={isEdit}
                onOpenPanelClick={handleCollapsePanelClick}
                title={name}
                inputError={!!error}
                inputMessage={error?.message}
                haveFields={!!fields.length}
                value={value}
                onChange={onChange}
                index={index}
                contentAfterArrow={
                  <EditPanelButtons
                    addTechnologyClick={handleOnAddTechnology}
                    cantEdit={cantEdit}
                    cantRemove={cantRemove}
                    dataCy={`${SKILLS_MATRIX_EXPERTISE_TEMPLATE}_panel_buttons`}
                    isEditMode={isEdit}
                    onCancelClick={handleCancelButtonClick}
                    variant={isPanelOpen ? "primary" : "secondary"}
                    onEditClick={handleEditButtonClick}
                    onRemoveClick={handleRemoveButtonClick}
                    isGroupNew={isNew}
                    hideButtons={professionId === DOMAINS_PROFESSION_ID}
                  />
                }
                contentBeforeArrow={
                  <span className="ml-auto text-neutralPrimary-20">{`${skills?.length}/${fields?.length}`}</span>
                }
              >
                {fields.length ? (
                  <TechnologiesTable
                    enjoymentScale={enjoymentScale}
                    knowledgeScale={knowledgeScale}
                    onArrayRemoveClick={(removeId) => remove(removeId)}
                    professionId={professionId}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    professionSkills={fields}
                    providedDroppable={provided}
                    groupPathName={groupPathName}
                    onSubmit={onSubmit}
                    itemId={itemId || ""}
                    isNewGroup={isNew}
                    haveFields={!!fields.length}
                    groupIndex={index}
                  />
                ) : (
                  <></>
                )}
              </Panel>
            )}
          />
          <DeleteGroupModal
            isOpen={isRemoveModalVisible}
            name={name}
            onCancel={() => setIsRemoveModalVisible(false)}
            onDelete={handleDeleteGroup}
          />
        </div>
      )}
    </Droppable>
  );
};

export default SingleGroup;
