import { ReactElement } from "react";
import { Label, Chip, Empty } from "@ps/ui-components";
import { classJoin, getNamesFromDictionary, setChipColors } from "@ps/utils";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import SimpleLabel from "../../../common/simpleLabel";
import {
  CLIENT_NAME,
  CONTACT_PERSON_EMAIL,
  CONTACT_PERSON_NAME,
  DOMAINS,
  PHONE_NUMBER,
  PROSPECT,
  PROSPECT_CITY,
  PROSPECT_COUNTRY,
  BD,
  PM,
  TECH_OWNER,
  GENERAL_NOTE,
} from "../../../../constants";
import { prepareUserFullName } from "../../../../utils/findUserById";
import { DetailsProps, DetailsMapState, DetailsMapStateReturn } from "./types";
import styles from "../styles.module.css";

const TRANSLATION_PATH = "projects.prospect.details";

const Details = ({
  clientName,
  contactPersonEmail,
  contactPersonName,
  domains,
  phoneNumber,
  prospectCity,
  prospectCountry,
  bd,
  note,
  pm,
  techOwner,
  editButton,
}: DetailsProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const mapState = (state: DetailsMapState): DetailsMapStateReturn => ({
    domainsDict: state.dictionaries.domain || [],
    archived: state?.prospects?.prospect?.archived,
  });

  const { domainsDict, archived } = useMappedStateSelector(mapState);

  const selectedDomains: string[] = getNamesFromDictionary(
    domains || [],
    domainsDict,
  ).split(",");

  return (
    <section className="w-full h-full py-9 px-4 flex pb-16 relative">
      <div className="flex flex-col justify-start min-h-4/6 gap-y-8 w-9/12">
        <div className="w-9/12">
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={GENERAL_NOTE}
            value={note}
          />
        </div>
        <div className="flex">
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={PM}
            value={prepareUserFullName(pm)}
          />
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={BD}
            value={prepareUserFullName(bd)}
          />
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={TECH_OWNER}
            value={prepareUserFullName(techOwner)}
          />
        </div>

        <Label
          dataCy={`${PROSPECT}_view_${DOMAINS}`}
          text={t(`${TRANSLATION_PATH}.${DOMAINS}`)}
          additionalClassName="col-span-3 w-full"
          fontWeight="font-normal"
          labelFontWeight="font-bold"
          labelFontSize="text-lg"
          labelColor="text-neutralPrimary-20"
        >
          <div className="flex flex-wrap gap-y-2 gap-x-2">
            {domains?.length && selectedDomains.length ? (
              selectedDomains.map(
                (selectedDomain: string): ReactElement => (
                  <Chip
                    key={selectedDomain}
                    dataCy={`${PROSPECT}_view_${DOMAINS}`}
                    label={selectedDomain}
                    borderWidth="border"
                    fontSize="font-semibold"
                    tailwindColors={setChipColors("domain")}
                  />
                ),
              )
            ) : (
              <Empty />
            )}
          </div>
        </Label>
      </div>
      <div
        style={{
          boxShadow:
            "0 2px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
        }}
        className="flex flex-col overflow-visible bg-neutralPrimary-100 w-3/12 right-8 -top-32 absolute rounded-2xl"
      >
        <div className="flex justify-end h-9 items-center px-6">
          {!archived && editButton}
        </div>
        <div
          className={classJoin(
            styles.detailsHigh,
            "flex flex-col gap-y-6 px-6 pb-6",
          )}
        >
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={CLIENT_NAME}
            value={clientName?.label || ""}
            additionalClassName="col-span-3"
          />

          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={CONTACT_PERSON_NAME}
            value={contactPersonName}
          />
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={PHONE_NUMBER}
            value={phoneNumber}
          />
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={CONTACT_PERSON_EMAIL}
            value={contactPersonEmail}
          />
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={PROSPECT_COUNTRY}
            value={prospectCountry?.label}
          />
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={PROSPECT_CITY}
            value={prospectCity}
          />
        </div>
        <div
          className={classJoin(
            archived && archived ? "bg-gray-200" : "bg-other-toxicGreen",
            "h-5 rounded-b-2xl",
          )}
        />
      </div>
    </section>
  );
};

export default Details;
