import { TIME_TRACKER_NAVBAR_ITEMS } from "@ps/time-tracker";
import { PERMISSIONS_NAVBAR_ITEMS } from "@ps/permissions";
import { hhPermissions } from "@ps/hh";
import { ReactComponent as NavbarHomeIcon } from "images/main/navbar-home.svg";
import { ReactComponent as NavbarTeamIcon } from "images/main/navbar-team.svg";
// import { ReactComponent as NavbarHolidaysIcon } from "images/main/navbar-calendar.svg";
// import { ReactComponent as NavbarSettings } from "images/main/navbar-settings.svg";
import { ReactComponent as NavbarProfile } from "images/main/navbar-profile.svg";
import { ReactComponent as NavbarProject } from "images/main/navbar-project.svg";
import { ReactComponent as NavbarProspect } from "images/main/navbar-prospect.svg";
import { ReactComponent as NavbarFolders } from "images/main/navbar-folders.svg";
import { ReactComponent as NavbarClients } from "images/main/navbar-clients.svg";
// import { ReactComponent as SkillsIcon } from "images/main/navbar-skills.svg";
import { ReactComponent as MatrixIcon } from "images/main/navbar-create-matrix.svg";
import { ReactComponent as NavbarFinance } from "images/main/navbar-finance.svg";
// import { ReactComponent as NavbarContracts } from "images/main/navbar-contracts.svg";
// import { ReactComponent as NavbarProjects } from "images/main/navbar-projects.svg";
// import { MATRIX_MANAGE_TEMPLATES } from "@ps/hh/src/shared/perimissions";

export const MENU_OPENED_LOCAL_STORAGE = "menu-users-selection";
export const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === "production";

const ITEMS = [
  {
    item: "main",
    path: "main",
    icon: NavbarHomeIcon,
  },
  {
    item: "profile",
    path: "profile",
    icon: NavbarProfile,
  },
  {
    item: "team",
    path: "team",
    icon: NavbarTeamIcon,
    // isPage: true,
    // items: [
    // {
    //   item: "log time",
    //   path: "log-tme",
    //   icon: NavbarLogTimeIcon,
    // },
    // { item: "holidays", path: "holidays", icon: NavbarHolidaysIcon },
    // { item: "user profiles", path: "user-profiles", icon: NavbarProfile },
    // ],
    permission: [
      hhPermissions.USERS_LIST_ALL,
      hhPermissions.MATRIX_FULFILLMENTS,
    ],
  },
  {
    item: "prospect",
    path: "prospect",
    icon: NavbarProspect,
    permission: [
      hhPermissions.PROSPECT_LIST_ALL,
      hhPermissions.PROSPECT_FULL_ACCESS,
    ],
  },
  {
    item: "project",
    path: "project",
    icon: NavbarProject,
    permission: [
      hhPermissions.PROJECT_LIST_ALL,
      hhPermissions.PROJECT_FULL_ACCESS,
    ],
  },
  {
    item: "clients",
    path: "clients",
    icon: NavbarClients,
    permission: [
      hhPermissions.CLIENTS_MANAGE,
      hhPermissions.CLIENTS_LIST,
      hhPermissions.CLIENTS_LIST_ALL,
      hhPermissions.PROSPECT_FULL_ACCESS,
    ],
  },
  {
    item: "folders",
    path: "folders",
    icon: NavbarFolders,
    permission: [hhPermissions.PROSPECT_FULL_ACCESS],
  },
  PERMISSIONS_NAVBAR_ITEMS,
  {
    item: "skills-matrix",
    path: "skills-matrix",
    icon: MatrixIcon,
    isPage: true,
    permission: [hhPermissions.MATRIX_MANAGE_TEMPLATES],
  },
  {
    item: "finance",
    path: "finance",
    icon: NavbarFinance,
    permission: [hhPermissions.FINANCES_MANAGE_FINANCES],
  },
  TIME_TRACKER_NAVBAR_ITEMS,
  // { item: "settings", path: "settings", icon: NavbarSettings },
];

export const POSSIBLY_HIDDEN_FOR_TENANT = ["main", "finance", "time-tracker"];

export const NAVBAR_ITEMS = ITEMS.filter((el) => !!el);
export const SPECIALIST = "specialist";
