import {
  HolidaysItemModel,
  UpdateHolidaysItemAPIModel,
  HolidaysTemplateModel,
  CreateHolidaysItemAPIModel,
  CreateHolidaysTemplateAPIModel,
} from "../types";

export const holidaysItemFromModel = (
  data: HolidaysItemModel,
): UpdateHolidaysItemAPIModel | CreateHolidaysItemAPIModel => ({
  holidaysName: data.name,
  numberOfDaysOff: data.numberOfDaysOff,
  payRatePercentage: data.payRatePercentage / 100,
  allowPartialDayOff: data.allowPartialDayOff,
});

export const createHolidaysTemplateFromModel = (
  data: HolidaysTemplateModel,
): CreateHolidaysTemplateAPIModel => ({
  name: data.name,
  holidaysTemplateItems: undefined,
});
