import { AxiosResponse } from "axios";

const isIResponse = (data: unknown): data is AxiosResponse =>
  (<AxiosResponse>data)?.status !== undefined;

const isAPIError = (payload: unknown): boolean =>
  isIResponse(payload) &&
  (payload?.status?.toString()[0] === "4" ||
    payload?.status?.toString()[0] === "5");

export default isAPIError;
