import { ReactElement } from "react";
import { Label } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../hooks";
import { MAIN_PROFILE } from "../../../../shared/data-cy";
import Empty from "../empty";
import { COMFORTABLE_HOURS, POSSIBLE_HOURS, WORK, ZONE } from "../constants";
import { WorkProps } from "./types";

const BASE_PATH = "profile.overview";

const Work = ({ title, workingHours }: WorkProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const comfortable = workingHours?.comfortable || {};
  const possible = workingHours?.possible || {};
  const zone = workingHours?.zone || "";

  const renderTimeZone = () =>
    zone ? (
      <span>{` (${zone?.value?.replace(/_/g, " ")})`}</span>
    ) : (
      <Empty section={title} />
    );

  return (
    <div className="grid grid-cols-4 w-full">
      <Label
        dataCy={`${MAIN_PROFILE}_${COMFORTABLE_HOURS}`}
        key={`${MAIN_PROFILE}_${COMFORTABLE_HOURS}`}
        text={t(`${BASE_PATH}.${WORK}.comfortableHours`)}
      >
        <span>{comfortable?.start || <Empty section={title} />}</span>
        <span>&nbsp;-&nbsp;</span>
        <span>{comfortable?.end || <Empty section={title} />}</span>
      </Label>
      <Label
        dataCy={`${MAIN_PROFILE}_${POSSIBLE_HOURS}`}
        key={`${MAIN_PROFILE}_${POSSIBLE_HOURS}`}
        text={t(`${BASE_PATH}.${WORK}.possibleHours`)}
      >
        <span>{possible?.start || <Empty section={title} />}</span>
        <span>&nbsp;-&nbsp;</span>
        <span>{possible?.end ? possible.end : <Empty section={title} />}</span>
      </Label>
      <Label
        key={`${MAIN_PROFILE}_${ZONE}`}
        dataCy={`${MAIN_PROFILE}_${ZONE}`}
        text={t(`${BASE_PATH}.${WORK}.${ZONE}`)}
      >
        {renderTimeZone()}
      </Label>
      <span className="w-full invisible" />
    </div>
  );
};

export default Work;
