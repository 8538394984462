import { ReactElement } from "react";
import { Avatar, Button, Icon, Modal } from "@ps/ui-components";
import {
  FOLDER_CANDIDATE_AVATAR,
  FOLDER_CANDIDATE_REMOVE_BUTTON,
  FOLDER_CANDIDATE_REMOVE_MODAL,
} from "../../../shared/data-cy";
import { ReactComponent as NoAvatarIcon } from "../../../../images/no-avatar.svg";
import { useTranslationWithNamespace } from "../../../../hooks";
import { CandidateDeleteModalProps } from "./types";

const BASE_PATH = "folders";

const CandidateDeleteModal = ({
  isOpen,
  onClose,
  avatar,
  firstName,
  lastName,
  handleOnRemoveCandidate,
}: CandidateDeleteModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Modal
      isOpen={isOpen}
      withCloseIcon
      onClose={onClose}
      withOutsideClick
      dataCy={FOLDER_CANDIDATE_REMOVE_MODAL}
      width="w-1/5"
      height="h-max"
      withYScroll={false}
    >
      <div className="flex flex-col gap-8">
        <span className="text-neutralPrimary-20 text-lg font-bold">
          {t(`${BASE_PATH}.rejectConfirm`)}
        </span>
        <div className="flex gap-3 items-center">
          {avatar ? (
            <Avatar
              image={avatar}
              dataCy={FOLDER_CANDIDATE_AVATAR}
              width={20}
              height={20}
            />
          ) : (
            <Icon
              icon={<NoAvatarIcon className="text-neutralSecondary-60" />}
              dataCy={FOLDER_CANDIDATE_AVATAR}
              size="20"
            />
          )}
          <div className="flex flex-col gap-3">
            <span className="text-neutralPrimary-20 font-semibold">
              {`${firstName} ${lastName}`}
            </span>
          </div>
        </div>
        <Button
          dataCy={FOLDER_CANDIDATE_REMOVE_BUTTON}
          additionalClass="self-end"
          onClick={handleOnRemoveCandidate}
        >
          {t(`${BASE_PATH}.remove`)}
        </Button>
      </div>
    </Modal>
  );
};

export default CandidateDeleteModal;
