import { Dayjs } from "dayjs";
import { useReducer } from "react";
import { DatepickerMode } from "../../types";
import createActions from "./selectDateActions";
import createReducer from "./selectDateReducer";
import {
  SelectedDates,
  SelectedDatesHelperActions,
  State,
  InputType,
  ReducerType,
} from "./useSelectDateTypes";

export interface UseSelectDateProps {
  mode: DatepickerMode;
  defaultDates?: SelectedDates;
  updateDisplayedMonth?: (date: Dayjs) => void;
  disabledDate?: (date: Dayjs, currentSelection: SelectedDates) => boolean;
  onClear?: () => void;
  clearRange?: (actionType: Dayjs) => void;
}

const useSelectDate = ({
  mode,
  defaultDates,
  updateDisplayedMonth,
  disabledDate,
  onClear,
  clearRange,
}: UseSelectDateProps): {
  SelectedDatesActions: SelectedDatesHelperActions;
  state: State;
} => {
  const reducer = createReducer({
    mode,
    disabledDate,
    updateDisplayedMonth,
    onClear,
    clearRange,
  });

  const [reducerState, dispatch] = useReducer<ReducerType>(reducer, {
    selected: defaultDates,
    input: InputType.NONE,
  });

  const SelectedDatesActions = createActions(dispatch);

  return { SelectedDatesActions, state: reducerState };
};

export default useSelectDate;
