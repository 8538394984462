import { handleError } from "../../shared";
import APIService from "../api/holidays-templates-api";
import {
  HolidaysItemModel,
  holidaysItemFromAPI,
  holidaysTemplatesFromAPI,
  holidaysItemFromModel,
  HolidaysTemplateModel,
  holidaysTemplateFromAPI,
  createHolidaysTemplateFromModel,
} from "../models";
import { NO_LIMIT_THRESHOLD } from "../ui/constants";

const HolidaysTemplateService = (() => {
  const getHolidaysItemById = async (templateId: string, holidayId: string) => {
    try {
      const res = await APIService.API.getHolidaysItem(templateId, holidayId);

      if (res?.data) return holidaysItemFromAPI(res.data);
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const getHolidaysTemplateById = async (templateId: string) => {
    try {
      const res = await APIService.API.getTemplate(templateId);

      if (res?.data) return holidaysTemplateFromAPI(res.data);
      return {};
    } catch (error) {
      return handleError(error);
    }
  };

  const getAllHolidaysTemplates = async () => {
    try {
      const res = await APIService.API.getAllTemplates();

      if (res?.data) return holidaysTemplatesFromAPI(res.data);
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const createHolidaysItem = async (
    templateId: string,
    newHoliday: HolidaysItemModel,
  ) => {
    try {
      const newHolidayItem: HolidaysItemModel = {
        ...newHoliday,
        numberOfDaysOff: newHoliday.noLimit
          ? NO_LIMIT_THRESHOLD.toString()
          : newHoliday.numberOfDaysOff,
      };

      return APIService.API.createHolidaysItem(
        templateId,
        holidaysItemFromModel(newHolidayItem),
      );
    } catch (error) {
      return handleError(error);
    }
  };

  const createHolidaysTemplate = async (
    newHolidaysTemplate: HolidaysTemplateModel,
  ) => {
    try {
      return APIService.API.createTemplate(
        createHolidaysTemplateFromModel(newHolidaysTemplate),
      );
    } catch (error) {
      return handleError(error);
    }
  };

  const updateHolidaysItem = async (
    templateId: string,
    updatedHoliday: HolidaysItemModel,
  ) => {
    try {
      return APIService.API.updateHolidaysItem(
        templateId,
        updatedHoliday.holidaysId,
        holidaysItemFromModel(updatedHoliday),
      );
    } catch (error) {
      return handleError(error);
    }
  };

  const updateHolidaysTemplate = async (
    templateId: string,
    updateTemplateName: string,
  ) => {
    try {
      return APIService.API.updateTemplate(templateId, {
        templateName: updateTemplateName,
      });
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteHolidaysItemById = async (
    templateId: string,
    holidayId: string,
  ) => {
    try {
      return APIService.API.deleteHolidaysItem(templateId, holidayId);
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteHolidaysTemplateById = async (templateId: string) => {
    try {
      return APIService.API.deleteTemplate(templateId);
    } catch (error) {
      return handleError(error);
    }
  };

  const setMainHolidayItem = async (templateId: string, holidayId: string) => {
    try {
      return APIService.API.setMainItem(templateId, holidayId);
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    getHolidaysItemById,
    getHolidaysTemplateById,
    getAllHolidaysTemplates,
    createHolidaysItem,
    createHolidaysTemplate,
    updateHolidaysItem,
    updateHolidaysTemplate,
    deleteHolidaysItemById,
    deleteHolidaysTemplateById,
    setMainHolidayItem,
  };
})();

export default HolidaysTemplateService;
