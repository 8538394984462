import { ReactElement } from "react";
import { ProjectModel } from "@ps/hh";
import { uid } from "react-uid";
import { classJoin, minutesToHHMM } from "@ps/utils";
import { TimeTrackerProjectName } from "@ps/ui-components";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { DASHBOARD_PROJECT, ROW } from "../../../shared/data-cy";
import { ProjectTimeEntriesModel } from "../../../models";
import {
  IRatesMinutes,
  ProjectRowMapState,
  ProjectRowMapStateReturn,
} from "./types";
import styles from "./styles.module.css";
import { getRatesMinutes, displayTotalFromProject } from "./utils";

const ProjectRow = ({ projectId }: { projectId: string }): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const mapState = (state: ProjectRowMapState): ProjectRowMapStateReturn => ({
    projectsTimeEntries: state?.timesheet?.project || {},
    projects: state?.projectsList || [],
  });

  const { projectsTimeEntries, projects } = useMappedStateSelector(
    mapState,
  ) as {
    projectsTimeEntries: ProjectTimeEntriesModel;
    projects: ProjectModel[];
  };

  const project: ProjectModel | undefined = projects?.find(
    (item) => item.id === projectId,
  );
  const timeEntryFromProject: ProjectTimeEntriesModel =
    projectsTimeEntries && typeof projectsTimeEntries === "object"
      ? Object.values(projectsTimeEntries?.[projectId])
      : [];

  return (
    <div
      data-cy={`${DASHBOARD_PROJECT}-${ROW}`}
      className={classJoin(
        styles.projectRow,
        "bg-neutralPrimary-100 p-5 rounded-lg gap-2 place-content-center place-items-start w-full",
      )}
    >
      <TimeTrackerProjectName
        label={project?.details?.name || ""}
        dataCy={DASHBOARD_PROJECT}
        color={project?.details?.color}
        additionalClassName="flex items-center self-center break-all"
      />
      <span className="text-neutralPrimary-30 self-center break-all">
        {project?.details?.clientName}
      </span>
      <div className="flex flex-col gap-2 items-start whitespace-nowrap w-full self-center">
        {getRatesMinutes(timeEntryFromProject).map(
          (item: IRatesMinutes): ReactElement => (
            <span
              key={uid(item)}
              className="px-2 border border-neutralPrimary-30 rounded-lg text-neutralPrimary-30 w-40 text-center"
            >
              {`${t("projectTime", {
                time: minutesToHHMM(item?.minutes),
              })} - x${item.rate / 10}`}
            </span>
          ),
        )}
      </div>
      <span className="text-neutralPrimary-20 font-semibold self-center">
        {displayTotalFromProject(timeEntryFromProject)}
      </span>
    </div>
  );
};

export default ProjectRow;
