import { ReactElement, ChangeEvent } from "react";
import {
  Button,
  Input,
  Label,
  SearchSelect,
  RestrictedWrapper,
} from "@ps/ui-components";
import { prepareDictionarySearchSelect } from "@ps/utils";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { getClientData } from "../../../shared/utils/clients-utils";
import {
  ADD_NEW_FOLDER,
  FOLDER_NAME,
  CLIENT_NAME,
  ADD_CLIENT,
  CLIENT,
} from "../../constants";
import { filterArchivedClients } from "../../../shared";
import { CLIENTS_MANAGE } from "../../../shared/permissions";
import {
  FolderContentProps,
  FolderContentMapState,
  FolderContentMapStateReturn,
  CreateFolderModalErrors,
} from "./types";
import { ClientModel } from "../../../client-domain";
import { ReactComponent as FolderGuy } from "../../images/folderGuy.svg";
import { CREATE_NEW_CLIENT } from "../../../prospect-domain/constants";
import { ReactComponent as SuitCaseIcon } from "../../../images/suitcase.svg";

const CREATING_FOLDER_FOLDER_NAME_ID = "creating-folder-folder-name-id";
const CREATING_FOLDER_CLIENT_NAME_ID = "creating-folder-client-name-id";

const FolderContent = ({
  addNewClient,
  data,
  setData,
  error,
  setError,
  defaultClientId,
  currentID,
  setCurrentID,
}: FolderContentProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const translate = (field: string, isRequired = true) =>
    `${t(`folders.${field}`)}${isRequired ? "*" : ""}`;

  const mapState = (
    state: FolderContentMapState,
  ): FolderContentMapStateReturn => ({
    clients: state.clients.clientsList || [],
  });

  const { clients } = useMappedStateSelector(mapState);

  const lengthErr = (value: string) => {
    if (!value.length || value.length > 100)
      setError((prev: CreateFolderModalErrors) => ({
        ...prev,
        folderName: true,
      }));
    else
      setError((prev: CreateFolderModalErrors) => ({
        ...prev,
        folderName: false,
      }));
  };

  const errorMaxLengthMessage = `${t("folders.errors.cantBeMoreChars", {
    number: 100,
  })}`;

  const errorMessage = (value: string) => {
    if (!value.length) return translate("errors.required", false);
    if (value.length > 100) return errorMaxLengthMessage;
    return "";
  };

  return (
    <div className="flex flex-col items-start gap-4 w-full py-8">
      <Label
        dataCy={`${ADD_NEW_FOLDER}_${FOLDER_NAME}`}
        text={translate(FOLDER_NAME, true)}
        labelFontWeight="font-bold"
        labelFontSize="text-base"
      >
        <Input
          id={CREATING_FOLDER_FOLDER_NAME_ID}
          autoFocus={
            currentID === CREATING_FOLDER_FOLDER_NAME_ID || currentID === ""
          }
          dataCy={`${ADD_NEW_FOLDER}_${FOLDER_NAME}`}
          width="w-full"
          defaultValue={data?.folderName}
          placeholder={translate("folderPlaceholder", false)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setCurrentID(CREATING_FOLDER_FOLDER_NAME_ID);
            setData((prev) => ({ ...prev, folderName: event.target.value }));
            lengthErr(event.target.value);
          }}
          error={error.folderName}
          message={errorMessage(data.folderName)}
          additionalClass=":placeholder: text-base"
        />
      </Label>
      <div className="flex gap-x-4 w-full items-top">
        <div className="w-1/2">
          <Label
            dataCy={`${ADD_NEW_FOLDER}_${CLIENT_NAME}`}
            text={translate(CLIENT, true)}
            labelFontWeight="font-bold"
            labelFontSize="text-base"
          >
            {defaultClientId ? (
              clients.find(
                (item: ClientModel): boolean => item.id === defaultClientId,
              )?.clientName
            ) : (
              <SearchSelect
                selectId={CREATING_FOLDER_CLIENT_NAME_ID}
                autoFocus={CREATING_FOLDER_CLIENT_NAME_ID === currentID}
                width="w-full"
                height="2.4rem"
                additionalClassName="-mt-0.5"
                dataCy={CLIENT_NAME}
                options={prepareDictionarySearchSelect(
                  filterArchivedClients(clients),
                  "id",
                  "id",
                  "clientName",
                  "clientName",
                )}
                defaultValue={
                  data.clientId ? getClientData(data?.clientId, clients) : null
                }
                onChange={(newClient) => {
                  setCurrentID(CREATING_FOLDER_CLIENT_NAME_ID);
                  setError((prev: CreateFolderModalErrors) => ({
                    ...prev,
                    clientId: false,
                  }));
                  setData((prev) => ({ ...prev, clientId: newClient.id }));
                }}
                placeholder={translate("chooseClient", false)}
                error={error.clientId}
                message={translate("errors.required", false)}
              />
            )}
          </Label>
        </div>
        {!defaultClientId ? (
          <div className="w-1/2">
            <RestrictedWrapper permissions={CLIENTS_MANAGE}>
              <Button
                dataCy={ADD_CLIENT}
                type="button"
                additionalClass="mt-6 flex gap-x-4 whitespace-nowrap"
                onClick={addNewClient}
                variant="secondary"
                width="w-full"
              >
                <SuitCaseIcon className="h-6 w-6" />
                {`${t(`projects.prospect.actions.${CREATE_NEW_CLIENT}`)}`}
              </Button>
            </RestrictedWrapper>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="flex justify-center w-full mt-8">
        <FolderGuy />
      </div>
    </div>
  );
};

export default FolderContent;
