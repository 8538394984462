import {
  KeyboardEvent,
  ReactElement,
  useState,
  useRef,
  useContext,
  useEffect,
} from "react";
import { useDispatch } from "redux-react-hook";
import dayjs, { Dayjs } from "dayjs";
import {
  TimeTrackerInput,
  TimeTrackerLabel,
  TimeTrackerProjectName,
  useThemeMode,
  Tooltip,
} from "@ps/ui-components";
import { useNotifications } from "@ps/notifications";
import { ProjectService } from "@ps/hh";
import {
  classJoin,
  HHMMToMinutes,
  minutesToHHMM,
  useClickOutsideClick,
} from "@ps/utils";
import {
  CLIENT_LABEL,
  PROJECT_NAME_LABEL,
  PROJECT_ROW_PIN,
  TIME_INPUT,
  TOTAL_TIME_COUNTED,
} from "../../../../shared/data-cy";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../hooks";
import { ReactComponent as FileData } from "../../../../images/timesheet/timelog/file-data.svg";
import { ReactComponent as XIcon } from "../../../../images/timesheet/timelog/delete-project-row-icon.svg";
import { ReactComponent as PinnedIcon } from "../../../../images/timesheet/timelog/pinned.svg";
import { ReactComponent as UnpinnedIcon } from "../../../../images/timesheet/timelog/unpinned.svg";
import { ReactComponent as PapersIcon } from "../../../../images/timesheet/timelog/papers-icon.svg";
import SelectProject from "./selectProject";
import {
  DayProps,
  ProjectRowProps,
  ProjectRowState,
  ProjectRowStateReturn,
} from "./types";
import AdvancedView from "./advanced-view";
import { TIME_FORMAT_YYYY_MM_DD } from "../../../../shared/constants";
import countTotalRow from "../common/utils";
import { focusVisibleStyles, Keys } from "../../../../shared";
import { appendTimesheetEntry, fetchPinnedProjects } from "../../../../store";
import { TimeEntriesService } from "../../../../services";
import { getUnusedProjects, isInputDisable, renderTooltipText } from "./utils";
import styles from "../styles.module.css";
import DeleteProject from "./deleteProject";
import RemovingRowModal from "./removingRowModal";
import Loader from "./loader";
import IconWithTooltip from "./iconWithTooltip";
import TimeLogContext from "../../../../contexts/TimelogContext";

const BASE_PATH = "timesheet.timelog.sections";

const ProjectRow = ({
  chosenProject,
  data,
  dayAmount,
  fetchData,
  isPinned,
  openedProjectAdvancedView,
  projectIdsInOrder,
  removeProjectFromList,
  setOpenedProjectAdvancedView,
  timeFrame,
  updateProjectOrder,
}: ProjectRowProps): ReactElement => {
  const dispatch = useDispatch();
  const { isHighContrast } = useThemeMode();
  const { setEnableAddProject } = useContext(TimeLogContext);
  const [editProject, setEditProject] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isProjectChanging, setIsProjectChanging] = useState<boolean>(false);

  const selectRef = useRef<HTMLDivElement>(null);
  useClickOutsideClick(selectRef, () => setEditProject(false));

  const { t } = useTranslationWithNamespace();
  const chosenProjectId: string = chosenProject?.id;

  const mapState = (state: ProjectRowState): ProjectRowStateReturn => ({
    isFetchingTimesheet: state.requestStatus.isFetchingTimesheet,
    pinnedProjects: state?.pinnedProjects || [],
    projects: state?.projectsList || [],
  });

  const { projects, pinnedProjects, isFetchingTimesheet } =
    useMappedStateSelector(mapState);

  const projectEntries = data?.[chosenProjectId];
  const entries = projectEntries && Object.entries(projectEntries);
  const startOfWeek: Dayjs = timeFrame[0];
  const endOfWeek: Dayjs = timeFrame[1];
  const { addSuccesNotification } = useNotifications();

  const timeFrameAllDays: Dayjs[] = [...Array(dayAmount)].map(
    (_, index: number): Dayjs => startOfWeek.add(index, "day"),
  );

  const numberOfunusedProjectsInDropdown = getUnusedProjects(
    projects,
    projectIdsInOrder,
  ).length;

  useEffect(() => {
    if (numberOfunusedProjectsInDropdown === 0) setEnableAddProject(false);
  }, [numberOfunusedProjectsInDropdown]);

  useEffect(() => {
    const elementContainer = document.getElementById(chosenProject?.id);
    const inputElements = elementContainer?.getElementsByTagName("input");
    inputElements?.[0]?.focus();
  }, [chosenProject?.id]);

  const tooltipSOWContent = renderTooltipText(timeFrame, t, chosenProject);
  const prepareTooltipType = (): string => {
    if (chosenProject.archived) return "archived";
    if (chosenProject.membershipDetails?.deleted) return "deleted";
    if (tooltipSOWContent) return "autOfSow";
    return "";
  };
  const handleSaveDataUpdate = async (
    value: string,
    day: DayProps,
  ): Promise<void> => {
    const currentEntry = day[1][0];
    if (value === "00:00" || value === "") {
      await TimeEntriesService.removeTimesheetEntry(currentEntry.id);
      await fetchData(startOfWeek, endOfWeek);
      if (Object.values(projectEntries).length === 1)
        dispatch(appendTimesheetEntry({ id: currentEntry?.typeId }));

      return;
    }
    if (value === currentEntry.time) return;
    await TimeEntriesService.updateProjectTimeEntry(currentEntry.id, {
      typeId: currentEntry.typeId,
      day: dayjs(currentEntry.date),
      minutes: HHMMToMinutes(value),
      rate: currentEntry.rate,
      note: currentEntry.note,
    });

    fetchData(startOfWeek, endOfWeek);
  };

  const handleSaveDataCreate = async (
    value: string,
    date: Dayjs,
  ): Promise<void | null> => {
    if (value === "00:00" || value === "") return null;
    await TimeEntriesService.createProjectTimeEntry({
      typeId: chosenProjectId,
      day: date,
      minutes: HHMMToMinutes(value),
      rate: 10,
    });
    return fetchData(startOfWeek, endOfWeek);
  };

  const handleOnPinClick = async (): Promise<void> => {
    if (isPinned) await ProjectService.unPinProject(chosenProjectId);
    else await ProjectService.pinProject(chosenProjectId);
    await fetchPinnedProjects(dispatch);
  };

  const handleSelectOnChange = async (newProject: {
    value: string;
    label: Element;
  }): Promise<void> => {
    setEditProject((prev: boolean) => !prev);
    setIsProjectChanging(true);
    if (pinnedProjects?.includes(chosenProjectId)) {
      await ProjectService.unPinProject(chosenProjectId);
      await ProjectService.pinProject(newProject.value);
      await fetchPinnedProjects(dispatch);
    }

    const onlyEntries = entries
      ?.flat()
      .filter((item) => typeof item !== "string")
      .flat();

    if (onlyEntries?.length) {
      await Promise?.allSettled(
        onlyEntries?.map((entry) =>
          TimeEntriesService.removeTimesheetEntry(entry.id, true),
        ),
      );

      await Promise.allSettled(
        onlyEntries?.map((entry) => {
          TimeEntriesService.createProjectTimeEntry(
            {
              ...entry,
              typeId: newProject.value,
              day: dayjs(entry.date),
            },
            true,
          );
          return (
            fetchData && timeFrame && fetchData(timeFrame[0], timeFrame[1])
          );
        }),
      );
      setIsProjectChanging(false);
      addSuccesNotification(t("timesheet.timelog.projectHasBeenChanged"));
    }

    await fetchPinnedProjects(dispatch);
    await fetchData(timeFrame[0], timeFrame[1]);
    await updateProjectOrder(chosenProjectId, newProject.value);
  };

  const handleOnChangeTimeTrackerInput = (date: Dayjs): void => {
    setOpenedProjectAdvancedView((prev) =>
      prev.projectId === chosenProjectId && dayjs(prev.date).isSame(date, "day")
        ? { projectId: "", date: "" }
        : {
            projectId: chosenProjectId,
            date: date.format(TIME_FORMAT_YYYY_MM_DD),
          },
    );
  };

  const renderDisplayingProjectAndClient = (): ReactElement => (
    <div className="flex items-center gap-4 w-full">
      {!chosenProject?.details?.name ||
      (isFetchingTimesheet && isProjectChanging) ? (
        <Loader />
      ) : (
        <TimeTrackerProjectName
          additionalClassName={classJoin(
            "break-all",
            chosenProject?.archived || chosenProject?.membershipDetails?.deleted
              ? "pointer-events-none opacity-30"
              : "",
          )}
          color={chosenProject?.details?.color}
          dataCy={PROJECT_NAME_LABEL}
          label={chosenProject?.details?.name}
          onClick={() => {
            if (
              chosenProject.archived ||
              chosenProject?.membershipDetails?.deleted
            )
              return;
            setEditProject((canEditProject) => !canEditProject);
          }}
        />
      )}
      {!chosenProject?.details?.clientName ||
      (isFetchingTimesheet && isProjectChanging) ? (
        <Loader />
      ) : (
        <TimeTrackerLabel
          text={chosenProject?.details?.clientName}
          dataCy={CLIENT_LABEL}
          additionalClassName={classJoin(
            "break-all",
            chosenProject?.archived || chosenProject?.membershipDetails?.deleted
              ? "opacity-30 visible"
              : "",
          )}
        />
      )}
      <IconWithTooltip
        SOWContent={tooltipSOWContent}
        type={prepareTooltipType()}
      />
    </div>
  );

  const deleteEntireWeek = async (): Promise<void> => {
    if (!projectEntries || !Object.values(projectEntries)?.length) {
      removeProjectFromList(chosenProjectId);
    } else {
      const entryIds = Object.values(projectEntries)
        ?.flat()
        ?.map((item) => item?.id);

      const result = await TimeEntriesService.deleteManyTimeEntries(entryIds);
      if (result?.length) {
        addSuccesNotification(t("timesheet.timelog.removeTimesheetEntries"));
        await fetchData(timeFrame[0], timeFrame[1]);
        removeProjectFromList(chosenProjectId);
      }
    }
  };

  return (
    <div
      id={chosenProject?.id}
      className={classJoin(
        "flex flex-col bg-neutralPrimary-100 my-3 rounded-lg min-h-15",
        isHighContrast ? "border border-primary-60" : "border-none",
      )}
    >
      <div className={styles.rowContainer}>
        <div
          className="flex items-center justify-start gap-5 ml-5"
          style={{ minWidth: "20rem" }}
        >
          <Tooltip
            placement="top"
            content={
              <span>
                {t(
                  `${BASE_PATH}.${
                    isPinned ? "pinnedTooltip" : "unpinnedTooltip"
                  }`,
                )}
              </span>
            }
          >
            {isPinned ? (
              <PinnedIcon
                data-cy={PROJECT_ROW_PIN}
                className={classJoin(
                  "fill-current cursor-pointer rounded-md w-5 h-5 mt-1",
                  focusVisibleStyles,
                  isPinned
                    ? "text-primary-50 hover:text-primary-70"
                    : "text-neutralSecondary-60 hover:text-neutralPrimary-30",
                )}
                role="button"
                tabIndex={0}
                onKeyDown={(event: KeyboardEvent<SVGSVGElement>) => {
                  if (event.key === Keys.ENTER) handleOnPinClick();
                }}
                onClick={handleOnPinClick}
              />
            ) : (
              <UnpinnedIcon
                data-cy={PROJECT_ROW_PIN}
                className={classJoin(
                  "fill-current cursor-pointer rounded-md w-5 h-5 mt-1",
                  focusVisibleStyles,
                  isPinned
                    ? "text-primary-50 hover:text-primary-70"
                    : "text-neutralSecondary-60 hover:text-neutralPrimary-30",
                )}
                role="button"
                tabIndex={0}
                onKeyDown={(event: KeyboardEvent<SVGSVGElement>) => {
                  if (event.key === Keys.ENTER) handleOnPinClick();
                }}
                onClick={handleOnPinClick}
              />
            )}
          </Tooltip>
          {editProject &&
          openedProjectAdvancedView.projectId !== chosenProjectId ? (
            <SelectProject
              selectRef={selectRef}
              currentProjectId={chosenProjectId}
              currentProjectName={chosenProject?.details?.name}
              handleSelectOnChange={handleSelectOnChange}
              options={getUnusedProjects(projects, projectIdsInOrder)}
              projects={projects}
            />
          ) : (
            renderDisplayingProjectAndClient()
          )}
        </div>
        <div className={styles.timeEntriesContainer}>
          {timeFrameAllDays.map((date: Dayjs) => {
            const currentEntry = entries?.find((item) => {
              return dayjs(item[0]).isSame(date, "day") ? item[1] : undefined;
            });
            const sumTimeFromDay: number =
              currentEntry &&
              currentEntry[1].reduce((acc, val) => acc + val.minutes, 0);
            const multipleDataInside =
              currentEntry && currentEntry[1].length > 1;
            const isAdvancedView =
              openedProjectAdvancedView.projectId === chosenProjectId &&
              dayjs(openedProjectAdvancedView.date).isSame(date, "day");
            const properSimpleViewIcon = multipleDataInside
              ? PapersIcon
              : FileData;
            return (
              <TimeTrackerInput
                additionalContainerClass={classJoin(
                  styles.weekendEntry,
                  "h-full w-full flex items-center justify-center",
                )}
                tabIndex={
                  (openedProjectAdvancedView.projectId && isAdvancedView) ||
                  (!isAdvancedView && !openedProjectAdvancedView.projectId)
                    ? 0
                    : -1
                }
                key={`${chosenProjectId} -${date}`}
                dataCy={`${TIME_INPUT} -${chosenProjectId} -${date}`}
                Icon={isAdvancedView ? XIcon : properSimpleViewIcon}
                iconColor={multipleDataInside && chosenProject?.details?.color}
                placeholder="HHMM"
                isAdvancedView={isAdvancedView}
                onClick={() => handleOnChangeTimeTrackerInput(date)}
                disabled={
                  isInputDisable(date, chosenProject) ||
                  (isFetchingTimesheet && isProjectChanging)
                }
                value={sumTimeFromDay ? minutesToHHMM(sumTimeFromDay) : ""}
                onBlur={(value: string) =>
                  currentEntry
                    ? handleSaveDataUpdate(value || "", currentEntry)
                    : handleSaveDataCreate(value || "", date)
                }
              />
            );
          })}
        </div>
        <TimeTrackerLabel
          text={minutesToHHMM(countTotalRow(entries))}
          dataCy={TOTAL_TIME_COUNTED}
          additionalClassName="self-center py-6"
        />
        {chosenProject?.membershipDetails?.deleted ||
        chosenProject.archived ||
        (isPinned && !projectEntries) ? (
          <span />
        ) : (
          <DeleteProject
            onDelete={() => setIsModalOpen(true)}
            tabIndex={openedProjectAdvancedView.projectId ? -1 : 0}
          />
        )}
        {isModalOpen ? (
          <RemovingRowModal
            isOpen
            onClose={() => setIsModalOpen(false)}
            onRemove={() => {
              deleteEntireWeek();
              setIsModalOpen(false);
            }}
          />
        ) : (
          <></>
        )}
      </div>
      {openedProjectAdvancedView.projectId === chosenProjectId && (
        <AdvancedView
          fetchData={fetchData}
          isDisabled={
            chosenProject.archived ||
            isInputDisable(dayjs(openedProjectAdvancedView.date), chosenProject)
          }
          openedProjectAdvancedView={openedProjectAdvancedView}
          projectEntries={projectEntries}
          setOpenedProjectAdvancedView={setOpenedProjectAdvancedView}
          timeFrame={[dayjs(startOfWeek), dayjs(endOfWeek)]}
        />
      )}
    </div>
  );
};

export default ProjectRow;
