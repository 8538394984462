/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * accomplishment
 * @export
 * @interface Accomplishment
 */
export interface Accomplishment {
    /**
     * 
     * @type {string}
     * @memberof Accomplishment
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Accomplishment
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'title': string;
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof Article
     */
    'publicationDate': string;
    /**
     * An URL to the article
     * @type {string}
     * @memberof Article
     */
    'url'?: string;
    /**
     * List of technology ids
     * @type {Array<string>}
     * @memberof Article
     */
    'techStack': Array<string>;
}
/**
 * 
 * @export
 * @interface Award
 */
export interface Award {
    /**
     * Award name eg. \'Best Cooking Blog of 2019\'
     * @type {string}
     * @memberof Award
     */
    'awardName': string;
    /**
     * The entity that supplies the award eg. \'medium\'
     * @type {string}
     * @memberof Award
     */
    'rewarder': string;
    /**
     * A date on which the award was rewarded
     * @type {string}
     * @memberof Award
     */
    'date': string;
    /**
     * A description of the award
     * @type {string}
     * @memberof Award
     */
    'description'?: string;
    /**
     * List of technology ids
     * @type {Array<string>}
     * @memberof Award
     */
    'techStack': Array<string>;
}
/**
 * 
 * @export
 * @interface CareerSpan
 */
export interface CareerSpan {
    /**
     * 
     * @type {string}
     * @memberof CareerSpan
     */
    'startDate': string;
    /**
     * The number of months considered to be \'gap\' months in the careerSpan of a Specialist
     * @type {number}
     * @memberof CareerSpan
     */
    'gapMonths': number;
}
/**
 * 
 * @export
 * @interface Certificate
 */
export interface Certificate {
    /**
     * The title of the certificate eg. \'Certified Lightbend Reactive Developer\'
     * @type {string}
     * @memberof Certificate
     */
    'title': string;
    /**
     * The URL which accesses the certificate
     * @type {string}
     * @memberof Certificate
     */
    'url'?: string;
    /**
     * The date on which the certificate was issued
     * @type {string}
     * @memberof Certificate
     */
    'issuedDate': string;
    /**
     * The date on which the certificate expires
     * @type {string}
     * @memberof Certificate
     */
    'expirationDate'?: string;
    /**
     * List of technology ids
     * @type {Array<string>}
     * @memberof Certificate
     */
    'techStack': Array<string>;
}
/**
 * Each RGB value needs to be between 0 and 255
 * @export
 * @interface Color
 */
export interface Color {
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'red': number;
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'green': number;
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'blue': number;
}
/**
 * Contact informations
 * @export
 * @interface ContactInformation
 */
export interface ContactInformation {
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'phone'?: string;
    /**
     * 
     * @type {ContactInformationUrls}
     * @memberof ContactInformation
     */
    'urls'?: ContactInformationUrls;
}
/**
 * 
 * @export
 * @interface ContactInformationUrls
 */
export interface ContactInformationUrls {
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactInformationUrls
     */
    'linkedIn'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactInformationUrls
     */
    'github'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Contribution
 */
export interface Contribution {
    /**
     * Eg. a Github project name
     * @type {string}
     * @memberof Contribution
     */
    'name': string;
    /**
     * An URL to the PR/contribution in some other form
     * @type {string}
     * @memberof Contribution
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof Contribution
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof Contribution
     */
    'endDate'?: string;
    /**
     * List of technology ids
     * @type {Array<string>}
     * @memberof Contribution
     */
    'techStack': Array<string>;
}
/**
 * 
 * @export
 * @interface Course
 */
export interface Course extends Education {
    /**
     * A name of the person/company that hosted the course
     * @type {string}
     * @memberof Course
     */
    'trainer': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'courseName': string;
}
/**
 * 
 * @export
 * @interface CourseAllOf
 */
export interface CourseAllOf {
    /**
     * A name of the person/company that hosted the course
     * @type {string}
     * @memberof CourseAllOf
     */
    'trainer': string;
    /**
     * 
     * @type {string}
     * @memberof CourseAllOf
     */
    'courseName': string;
}
/**
 * 
 * @export
 * @interface Degree
 */
export interface Degree extends Education {
    /**
     * The name of the university or college
     * @type {string}
     * @memberof Degree
     */
    'schoolName': string;
    /**
     * Eg. \'IT\' etc.
     * @type {string}
     * @memberof Degree
     */
    'fieldOfStudy': string;
    /**
     * Eg. \'MBA\'
     * @type {string}
     * @memberof Degree
     */
    'degree': string;
}
/**
 * 
 * @export
 * @interface DegreeAllOf
 */
export interface DegreeAllOf {
    /**
     * The name of the university or college
     * @type {string}
     * @memberof DegreeAllOf
     */
    'schoolName': string;
    /**
     * Eg. \'IT\' etc.
     * @type {string}
     * @memberof DegreeAllOf
     */
    'fieldOfStudy': string;
    /**
     * Eg. \'MBA\'
     * @type {string}
     * @memberof DegreeAllOf
     */
    'degree': string;
}
/**
 * Score for knowledge and enjoyment for a given domain, values should be compatible with the scale given by the DomainMatrixTemplate
 * @export
 * @interface DomainRating
 */
export interface DomainRating {
    /**
     * 
     * @type {number}
     * @memberof DomainRating
     */
    'knowledge'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainRating
     */
    'enjoyment'?: number;
}
/**
 * 
 * @export
 * @interface Education
 */
export interface Education {
    /**
     * Education type discriminator either \'course\' or \'degree\'
     * @type {string}
     * @memberof Education
     */
    'education': string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'desc'?: string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface Experience
 */
export interface Experience {
    /**
     * 
     * @type {Array<Project>}
     * @memberof Experience
     */
    'projects': Array<Project>;
    /**
     * 
     * @type {Array<Education>}
     * @memberof Experience
     */
    'educations': Array<Education>;
    /**
     * 
     * @type {Array<Article>}
     * @memberof Experience
     */
    'articles': Array<Article>;
    /**
     * 
     * @type {Array<Talk>}
     * @memberof Experience
     */
    'talks': Array<Talk>;
    /**
     * 
     * @type {Array<Contribution>}
     * @memberof Experience
     */
    'contributions': Array<Contribution>;
    /**
     * 
     * @type {Array<Tutor>}
     * @memberof Experience
     */
    'tutors': Array<Tutor>;
    /**
     * 
     * @type {Array<Award>}
     * @memberof Experience
     */
    'awards': Array<Award>;
    /**
     * 
     * @type {Array<Certificate>}
     * @memberof Experience
     */
    'certificates': Array<Certificate>;
}
/**
 * 
 * @export
 * @interface GetOperativeProfile
 */
export interface GetOperativeProfile extends GetProfile {
}
/**
 * 
 * @export
 * @interface GetOperativeProfileSummary
 */
export interface GetOperativeProfileSummary extends GetProfileSummary {
}
/**
 * 
 * @export
 * @interface GetProfile
 */
export interface GetProfile {
    /**
     * Discriminator for profile types (either \'specialist\' or \'operative\') please refer to GetProfile.yaml for descriptive documentation of this type as Swagger UI doesn\'t handle \'discriminator\' properly
     * @type {string}
     * @memberof GetProfile
     */
    'profile': string;
    /**
     * 
     * @type {string}
     * @memberof GetProfile
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetProfile
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfile
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfile
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfile
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfile
     */
    'bio'?: string;
    /**
     * 
     * @type {Professions}
     * @memberof GetProfile
     */
    'professions'?: Professions;
    /**
     * 
     * @type {string}
     * @memberof GetProfile
     */
    'seniority'?: string;
    /**
     * Array of all currently possessed passports
     * @type {Array<Passport>}
     * @memberof GetProfile
     */
    'passports': Array<Passport>;
    /**
     * Array of all currently possessed visas
     * @type {Array<Visa>}
     * @memberof GetProfile
     */
    'visas': Array<Visa>;
    /**
     * 
     * @type {string}
     * @memberof GetProfile
     */
    'countryOfResidence'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProfile
     */
    'nationality': Array<string>;
    /**
     * 
     * @type {WorkingHours}
     * @memberof GetProfile
     */
    'workingHours'?: WorkingHours;
    /**
     * 
     * @type {Array<LanguageProficiency>}
     * @memberof GetProfile
     */
    'languages': Array<LanguageProficiency>;
    /**
     * 
     * @type {Social}
     * @memberof GetProfile
     */
    'social': Social;
    /**
     * 
     * @type {{ [key: string]: Goals; }}
     * @memberof GetProfile
     */
    'goals': { [key: string]: Goals; };
}
/**
 * 
 * @export
 * @interface GetProfilePreviewFulfillments
 */
export interface GetProfilePreviewFulfillments {
    /**
     * 
     * @type {Skills}
     * @memberof GetProfilePreviewFulfillments
     */
    'skillsRatings': Skills;
    /**
     * 
     * @type {ProfileDomainMatrixFulfillment}
     * @memberof GetProfilePreviewFulfillments
     */
    'domainRatings'?: ProfileDomainMatrixFulfillment;
}
/**
 * 
 * @export
 * @interface GetProfileSummary
 */
export interface GetProfileSummary {
    /**
     * Discriminator for profile types (either \'specialist\' or \'operative\') please refer to GetProfile.yaml for descriptive documentation of this type as Swagger UI doesn\'t handle \'discriminator\' properly
     * @type {string}
     * @memberof GetProfileSummary
     */
    'profile': string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileSummary
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileSummary
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileSummary
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileSummary
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileSummary
     */
    'avatar'?: string;
    /**
     * 
     * @type {Professions}
     * @memberof GetProfileSummary
     */
    'professions'?: Professions;
    /**
     * 
     * @type {string}
     * @memberof GetProfileSummary
     */
    'seniority'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileSummary
     */
    'countryOfResidence'?: string;
}
/**
 * 
 * @export
 * @interface GetRating
 */
export interface GetRating {
    /**
     * Score tied to a given rating
     * @type {number}
     * @memberof GetRating
     */
    'score': number;
    /**
     * A non blank description that gives us context about the entry
     * @type {string}
     * @memberof GetRating
     */
    'desc': string;
    /**
     * 
     * @type {Color}
     * @memberof GetRating
     */
    'color'?: Color;
    /**
     * URL of an image that\'ll serve as an icon for this entry
     * @type {string}
     * @memberof GetRating
     */
    'iconUrl'?: string;
}
/**
 * 
 * @export
 * @interface GetSpecialistProfile
 */
export interface GetSpecialistProfile extends GetProfile {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSpecialistProfile
     */
    'projectRoles': Array<string>;
    /**
     * 
     * @type {Experience}
     * @memberof GetSpecialistProfile
     */
    'experience': Experience;
    /**
     * 
     * @type {CareerSpan}
     * @memberof GetSpecialistProfile
     */
    'careerSpan'?: CareerSpan;
}
/**
 * 
 * @export
 * @interface GetSpecialistProfileAllOf
 */
export interface GetSpecialistProfileAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSpecialistProfileAllOf
     */
    'projectRoles': Array<string>;
    /**
     * 
     * @type {Experience}
     * @memberof GetSpecialistProfileAllOf
     */
    'experience': Experience;
    /**
     * 
     * @type {CareerSpan}
     * @memberof GetSpecialistProfileAllOf
     */
    'careerSpan'?: CareerSpan;
}
/**
 * 
 * @export
 * @interface GetSpecialistProfileSummary
 */
export interface GetSpecialistProfileSummary extends GetProfileSummary {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSpecialistProfileSummary
     */
    'projectRoles': Array<string>;
}
/**
 * 
 * @export
 * @interface GetSpecialistProfileSummaryAllOf
 */
export interface GetSpecialistProfileSummaryAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSpecialistProfileSummaryAllOf
     */
    'projectRoles': Array<string>;
}
/**
 * 
 * @export
 * @interface Goals
 */
export interface Goals {
    /**
     * 
     * @type {Array<string>}
     * @memberof Goals
     */
    'technologies': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Goals
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LanguageLevel = {
    Elementary: 'Elementary',
    Limited: 'Limited',
    Professional: 'Professional',
    FullyProfessional: 'FullyProfessional',
    Native: 'Native'
} as const;

export type LanguageLevel = typeof LanguageLevel[keyof typeof LanguageLevel];


/**
 * 
 * @export
 * @interface LanguageProficiency
 */
export interface LanguageProficiency {
    /**
     * 
     * @type {string}
     * @memberof LanguageProficiency
     */
    'language': string;
    /**
     * 
     * @type {LanguageLevel}
     * @memberof LanguageProficiency
     */
    'level': LanguageLevel;
}
/**
 * accomplishment
 * @export
 * @interface OcredAccomplishment
 */
export interface OcredAccomplishment {
    /**
     * 
     * @type {string}
     * @memberof OcredAccomplishment
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof OcredAccomplishment
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof OcredAccomplishment
     */
    'sourceText': string;
}
/**
 * accomplishment
 * @export
 * @interface OcredAccomplishments
 */
export interface OcredAccomplishments {
    /**
     * 
     * @type {string}
     * @memberof OcredAccomplishments
     */
    'fullText'?: string;
    /**
     * 
     * @type {Array<OcredAccomplishment>}
     * @memberof OcredAccomplishments
     */
    'accomplishments': Array<OcredAccomplishment>;
}
/**
 * 
 * @export
 * @interface OcredEducation
 */
export interface OcredEducation {
    /**
     * 
     * @type {string}
     * @memberof OcredEducation
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof OcredEducation
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OcredEducation
     */
    'desc': string;
    /**
     * 
     * @type {string}
     * @memberof OcredEducation
     */
    'sourceText': string;
}
/**
 * 
 * @export
 * @interface OcredEducations
 */
export interface OcredEducations {
    /**
     * 
     * @type {string}
     * @memberof OcredEducations
     */
    'fullText'?: string;
    /**
     * 
     * @type {Array<OcredEducation>}
     * @memberof OcredEducations
     */
    'educations': Array<OcredEducation>;
}
/**
 * 
 * @export
 * @interface OcredProfile
 */
export interface OcredProfile {
    /**
     * 
     * @type {string}
     * @memberof OcredProfile
     */
    'bio'?: string;
    /**
     * 
     * @type {string}
     * @memberof OcredProfile
     */
    'languages'?: string;
    /**
     * List of technology ids
     * @type {Array<string>}
     * @memberof OcredProfile
     */
    'techStack': Array<string>;
    /**
     * 
     * @type {OcredProjects}
     * @memberof OcredProfile
     */
    'projects': OcredProjects;
    /**
     * 
     * @type {OcredEducations}
     * @memberof OcredProfile
     */
    'educations': OcredEducations;
    /**
     * 
     * @type {ContactInformation}
     * @memberof OcredProfile
     */
    'contactInformation'?: ContactInformation;
    /**
     * 
     * @type {OcredAccomplishments}
     * @memberof OcredProfile
     */
    'accomplishments': OcredAccomplishments;
}
/**
 * 
 * @export
 * @interface OcredProject
 */
export interface OcredProject {
    /**
     * 
     * @type {string}
     * @memberof OcredProject
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof OcredProject
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OcredProject
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof OcredProject
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OcredProject
     */
    'desc': string;
    /**
     * 
     * @type {string}
     * @memberof OcredProject
     */
    'responsibility'?: string;
    /**
     * List of project role ids
     * @type {Array<string>}
     * @memberof OcredProject
     */
    'projectRoles'?: Array<string>;
    /**
     * List of technology ids
     * @type {Array<string>}
     * @memberof OcredProject
     */
    'techStack': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OcredProject
     */
    'sourceText': string;
}
/**
 * 
 * @export
 * @interface OcredProjects
 */
export interface OcredProjects {
    /**
     * 
     * @type {string}
     * @memberof OcredProjects
     */
    'fullText'?: string;
    /**
     * 
     * @type {Array<OcredProject>}
     * @memberof OcredProjects
     */
    'projects': Array<OcredProject>;
}
/**
 * 
 * @export
 * @interface Passport
 */
export interface Passport {
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof Passport
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Passport
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface ProfessionSkills
 */
export interface ProfessionSkills {
    /**
     * 
     * @type {string}
     * @memberof ProfessionSkills
     */
    'profession': string;
    /**
     * 
     * @type {Array<GetRating>}
     * @memberof ProfessionSkills
     */
    'scale': Array<GetRating>;
    /**
     * List of skills in a given Profession sorted by score.
     * @type {Array<TechnologySkill>}
     * @memberof ProfessionSkills
     */
    'ratings': Array<TechnologySkill>;
}
/**
 * 
 * @export
 * @interface Professions
 */
export interface Professions {
    /**
     * 
     * @type {string}
     * @memberof Professions
     */
    'main': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Professions
     */
    'secondary': Array<string>;
}
/**
 * 
 * @export
 * @interface ProfileDomainMatrixFulfillment
 */
export interface ProfileDomainMatrixFulfillment {
    /**
     * A DomainId-to-DomainRating dictionary
     * @type {{ [key: string]: DomainRating; }}
     * @memberof ProfileDomainMatrixFulfillment
     */
    'ratings': { [key: string]: DomainRating; };
    /**
     * 
     * @type {Array<GetRating>}
     * @memberof ProfileDomainMatrixFulfillment
     */
    'scale': Array<GetRating>;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'desc': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'responsibility': string;
    /**
     * List of project role ids
     * @type {Array<string>}
     * @memberof Project
     */
    'projectRoles': Array<string>;
    /**
     * List of technology ids
     * @type {Array<string>}
     * @memberof Project
     */
    'techStack': Array<string>;
}
/**
 * 
 * @export
 * @interface Skills
 */
export interface Skills {
    /**
     * List of most relevant/best skills sorted by score.
     * @type {Array<TechnologySkill>}
     * @memberof Skills
     */
    'best': Array<TechnologySkill>;
    /**
     * List of skills grouped by profession.
     * @type {Array<ProfessionSkills>}
     * @memberof Skills
     */
    'byProfession': Array<ProfessionSkills>;
}
/**
 * 
 * @export
 * @interface Social
 */
export interface Social {
    /**
     * List of LinkedIn handles eg. jdoe
     * @type {Array<string>}
     * @memberof Social
     */
    'linkedIn': Array<string>;
    /**
     * List of GitHub handles eg. @jdoe
     * @type {Array<string>}
     * @memberof Social
     */
    'github': Array<string>;
    /**
     * List of Twitter handles eg. @jdoe
     * @type {Array<string>}
     * @memberof Social
     */
    'twitter': Array<string>;
}
/**
 * 
 * @export
 * @interface Talk
 */
export interface Talk {
    /**
     * 
     * @type {string}
     * @memberof Talk
     */
    'eventName': string;
    /**
     * 
     * @type {string}
     * @memberof Talk
     */
    'title': string;
    /**
     * An URL to the talk
     * @type {string}
     * @memberof Talk
     */
    'url'?: string;
    /**
     * List of technology ids
     * @type {Array<string>}
     * @memberof Talk
     */
    'techStack': Array<string>;
    /**
     * The date on which the talk has taken place
     * @type {string}
     * @memberof Talk
     */
    'date'?: string;
    /**
     * An URL to a video of the talk
     * @type {string}
     * @memberof Talk
     */
    'videoUrl'?: string;
}
/**
 * 
 * @export
 * @interface TechnologySkill
 */
export interface TechnologySkill {
    /**
     * 
     * @type {string}
     * @memberof TechnologySkill
     */
    'technology': string;
    /**
     * 
     * @type {number}
     * @memberof TechnologySkill
     */
    'score': number;
}
/**
 * A time range, start and end formatted as ISO 8601 time without timezone (eg. 10:15:30)
 * @export
 * @interface TimeRange
 */
export interface TimeRange {
    /**
     * 
     * @type {string}
     * @memberof TimeRange
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof TimeRange
     */
    'end': string;
}
/**
 * 
 * @export
 * @interface Tutor
 */
export interface Tutor {
    /**
     * Name of the workshop you tutored
     * @type {string}
     * @memberof Tutor
     */
    'title': string;
    /**
     * A date on which the workshop occurred
     * @type {string}
     * @memberof Tutor
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof Tutor
     */
    'desc': string;
    /**
     * List of technology ids
     * @type {Array<string>}
     * @memberof Tutor
     */
    'techStack': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateOperativeProfile
 */
export interface UpdateOperativeProfile extends UpdateProfile {
}
/**
 * 
 * @export
 * @interface UpdateProfile
 */
export interface UpdateProfile {
    /**
     * Discriminator for profile types (either \'specialist\' or \'operative\') please refer to UpdateProfile.yaml for descriptive documentation of this type as Swagger UI doesn\'t handle \'discriminator\' properly
     * @type {string}
     * @memberof UpdateProfile
     */
    'profile': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    'bio'?: string;
    /**
     * 
     * @type {Professions}
     * @memberof UpdateProfile
     */
    'professions'?: Professions;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    'seniority'?: string;
    /**
     * Array of all currently possessed passports
     * @type {Array<Passport>}
     * @memberof UpdateProfile
     */
    'passports': Array<Passport>;
    /**
     * Array of all currently possessed visas
     * @type {Array<Visa>}
     * @memberof UpdateProfile
     */
    'visas': Array<Visa>;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    'countryOfResidence'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProfile
     */
    'nationality': Array<string>;
    /**
     * 
     * @type {WorkingHours}
     * @memberof UpdateProfile
     */
    'workingHours'?: WorkingHours;
    /**
     * 
     * @type {Array<LanguageProficiency>}
     * @memberof UpdateProfile
     */
    'languages': Array<LanguageProficiency>;
    /**
     * 
     * @type {Social}
     * @memberof UpdateProfile
     */
    'social': Social;
    /**
     * 
     * @type {{ [key: string]: Goals; }}
     * @memberof UpdateProfile
     */
    'goals': { [key: string]: Goals; };
}
/**
 * 
 * @export
 * @interface UpdateSpecialistProfile
 */
export interface UpdateSpecialistProfile extends UpdateProfile {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSpecialistProfile
     */
    'projectRoles': Array<string>;
    /**
     * 
     * @type {Experience}
     * @memberof UpdateSpecialistProfile
     */
    'experience': Experience;
    /**
     * 
     * @type {CareerSpan}
     * @memberof UpdateSpecialistProfile
     */
    'careerSpan'?: CareerSpan;
}
/**
 * 
 * @export
 * @interface Visa
 */
export interface Visa {
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof Visa
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Visa
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface WorkingHours
 */
export interface WorkingHours {
    /**
     * 
     * @type {TimeRange}
     * @memberof WorkingHours
     */
    'comfortable': TimeRange;
    /**
     * 
     * @type {TimeRange}
     * @memberof WorkingHours
     */
    'possible': TimeRange;
    /**
     * A time-zone ID eg. \'Europe/Paris\'
     * @type {string}
     * @memberof WorkingHours
     */
    'zone': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the profile of a user with given id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getProfile', 'userId', userId)
            const localVarPath = `/profile/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the ocred profile of a user with given id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileOcr: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/me/ocr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a profile reated that that is not set in Profile. Used to preview user profile
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilePreviewFulfillments: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getProfilePreviewFulfillments', 'userId', userId)
            const localVarPath = `/profile/{userId}/preview`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all profile summaries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileSummaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/profiles/summaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all specialists\' profile summaries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialistsProfileSummaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/profiles/specialists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new profile based on CV
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ocrProfile: async (file?: File, fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/profiles/me/ocr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('fileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update  profile of the current user
         * @param {UpdateProfile} updateProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (updateProfile: UpdateProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProfile' is not null or undefined
            assertParamExists('updateProfile', 'updateProfile', updateProfile)
            const localVarPath = `/profile/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the profile of a user with given id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the ocred profile of a user with given id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileOcr(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OcredProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileOcr(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches a profile reated that that is not set in Profile. Used to preview user profile
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfilePreviewFulfillments(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfilePreviewFulfillments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfilePreviewFulfillments(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all profile summaries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileSummaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetProfileSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileSummaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all specialists\' profile summaries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialistsProfileSummaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetProfileSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialistsProfileSummaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates new profile based on CV
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ocrProfile(file?: File, fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OcredProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ocrProfile(file, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update  profile of the current user
         * @param {UpdateProfile} updateProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(updateProfile: UpdateProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(updateProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Get the profile of a user with given id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(userId: string, options?: any): AxiosPromise<GetProfile> {
            return localVarFp.getProfile(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the ocred profile of a user with given id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileOcr(options?: any): AxiosPromise<OcredProfile> {
            return localVarFp.getProfileOcr(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a profile reated that that is not set in Profile. Used to preview user profile
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilePreviewFulfillments(userId: string, options?: any): AxiosPromise<GetProfilePreviewFulfillments> {
            return localVarFp.getProfilePreviewFulfillments(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all profile summaries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileSummaries(options?: any): AxiosPromise<Array<GetProfileSummary>> {
            return localVarFp.getProfileSummaries(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all specialists\' profile summaries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialistsProfileSummaries(options?: any): AxiosPromise<Array<GetProfileSummary>> {
            return localVarFp.getSpecialistsProfileSummaries(options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new profile based on CV
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ocrProfile(file?: File, fileName?: string, options?: any): AxiosPromise<OcredProfile> {
            return localVarFp.ocrProfile(file, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Update  profile of the current user
         * @param {UpdateProfile} updateProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(updateProfile: UpdateProfile, options?: any): AxiosPromise<void> {
            return localVarFp.updateProfile(updateProfile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Get the profile of a user with given id
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProfile(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProfile(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the ocred profile of a user with given id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProfileOcr(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProfileOcr(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a profile reated that that is not set in Profile. Used to preview user profile
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProfilePreviewFulfillments(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProfilePreviewFulfillments(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all profile summaries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProfileSummaries(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProfileSummaries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all specialists\' profile summaries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSpecialistsProfileSummaries(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSpecialistsProfileSummaries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new profile based on CV
     * @param {File} [file] 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ocrProfile(file?: File, fileName?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ocrProfile(file, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update  profile of the current user
     * @param {UpdateProfile} updateProfile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProfile(updateProfile: UpdateProfile, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateProfile(updateProfile, options).then((request) => request(this.axios, this.basePath));
    }
}


