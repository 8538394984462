import { ReactElement, ReactPortal, RefObject } from "react";

export interface FontSizeProviderProps {
  additionalClassname?: string;
  children: ReactElement | ReactElement[];
}

export enum FontSize {
  SMALL = "small",
  DEFAULT = "default",
  LARGE = "large",
}

export interface FontSizeContextProps {
  fontSize: FontSize;
  set: (font: FontSize) => void;
  providerRef?: RefObject<HTMLDivElement>;
  createPortal: (
    children: ReactElement | ReactElement[],
    container: Element,
    key?: string,
  ) => ReactPortal;
}

export interface FontSizePortalProps {
  fontSize: FontSize;
  children: ReactElement | ReactElement[];
  container: Element;
  key?: string;
}

export interface FontSizeSwitchProps {
  additionalClassName?: string;
  textColor?: string;
}

export const FONT_SIZE_PROVIDER_ID = "font-size-provider";
export const FONT_SIZE_LOCAL_STORAGE = "font-size-users-selection";
