import { Children, ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { TabsProps } from "./types";
import { TabsContext } from "./context";
import { TABS_PREFIX } from "../../shared/data-cy";

const ulBaseStyle = classJoin.template`
grid gap-x-5 grid-flow-col w-min
list-none bg-transparent p-0 m-0
`;

const Tabs = ({
  activeTab,
  additionalClassName,
  children,
  dataCy,
  onChange,
}: // @ts-ignore
TabsProps): ReactElement => {
  const baseTabId = `${TABS_PREFIX}-${dataCy}`;
  const currentContentContainer = Children.toArray(children).find(
    (child) => (child as ReactElement)?.props?.to === activeTab,
  );
  return (
    <TabsContext.Provider value={{ activeTab, onChange, baseTabId }}>
      <div
        className={classJoin(ulBaseStyle, additionalClassName)}
        data-cy={baseTabId}
      >
        {children}
      </div>
      {(currentContentContainer as ReactElement)?.props?.children || null}
    </TabsContext.Provider>
  );
};

export default Tabs;
