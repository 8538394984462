import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { MiddleContentProps } from "./types";
import { MIDDLE_CONTENT_PREFIX } from "../../shared/data-cy";

const MiddleContent = ({
  children,
  dataCy,
  className,
}: MiddleContentProps): ReactElement => (
  <div
    data-cy={`${MIDDLE_CONTENT_PREFIX}-${dataCy}`}
    className={classJoin(
      "middle-content bg-neutralPrimary-85 overflow-auto focus:outline-none",
      className,
    )}
    id={MIDDLE_CONTENT_PREFIX}
    tabIndex={-1}
  >
    {children}
  </div>
);

export default MiddleContent;
