import { useTranslation } from "react-i18next";
import { ReactElement, useEffect } from "react";
import { classJoin } from "@ps/utils";
import NavItem from "./navItem";
import NavLink from "./navLink";
import {
  NavbarOptionsProps,
  NavbarOptionsItems,
  NavbarOptionsItem,
} from "./types";
import styles from "./styles.module.css";
import { RestrictedWrapper } from "../permissions";
import { Keys } from "@ps/time-tracker/src/shared";
import { useLocation } from "react-router-dom";

const Navbar = ({
  dataCy,
  defaultRoute = "",
  dictionaryPath = "navbar",
  navbarItems,
  isMenuOpened,
  onItemEnter,
}: NavbarOptionsProps): ReactElement => {
  const { t } = useTranslation();

  useEffect(() => {
    document.getElementById(`navbar_${navbarItems?.[0]?.path}`)?.focus();
  }, []);

  return (
    <div
      className={classJoin(
        "flex flex-col h-2/3 overflow-y-auto",
        styles.navbar,
      )}
      data-cy={dataCy}
    >
      {navbarItems.map((element: NavbarOptionsItems): ReactElement => {
        if (!element) return <></>;
        return element?.items ? (
          <RestrictedWrapper
            permissions={element.permission || ""}
            key={element.path}
          >
            <NavItem
              Icon={element.icon}
              isPage={element.isPage}
              label={t(`${dictionaryPath}.${element.item}`, element.item)}
              path={element.path}
              defaultIsOpen={defaultRoute.includes(`/${element.path}/`)}
              isMenuOpened={isMenuOpened}
              nestedElements={element?.items}
            >
              {element?.items.length && isMenuOpened ? (
                element?.items.map(
                  (nestedElement: NavbarOptionsItem): ReactElement => (
                    <RestrictedWrapper
                      permissions={nestedElement.permission || ""}
                      key={nestedElement.path}
                    >
                      <NavLink
                        Icon={nestedElement.icon}
                        label={t(
                          `${dictionaryPath}.${nestedElement.item}`,
                          nestedElement.item,
                        )}
                        parentPath={element.path}
                        path={nestedElement.path}
                        isMenuOpened={isMenuOpened}
                        onItemEnter={onItemEnter}
                        defaultFocusID={nestedElement?.defaultFocusID}
                      />
                    </RestrictedWrapper>
                  ),
                )
              ) : (
                <></>
              )}
            </NavItem>
          </RestrictedWrapper>
        ) : (
          <RestrictedWrapper
            permissions={element.permission || ""}
            key={element.path}
          >
            <NavLink
              Icon={element.icon}
              label={t(`${dictionaryPath}.${element.item}`, element.item)}
              path={element.path || ""}
              isMenuOpened={isMenuOpened}
              onItemEnter={onItemEnter}
              defaultFocusID={element?.defaultFocusID}
            />
          </RestrictedWrapper>
        );
      })}
    </div>
  );
};

export default Navbar;
