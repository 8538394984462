import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { returnObjectIds, dayJSToMonthYear } from "@ps/utils";
import timezone from "dayjs/plugin/timezone";
import { CertificateModel } from "@ps/pdf-generator";
import {
  ArticleModel,
  CourseModel,
  DegreeModel,
  OperativeAPIModel,
  OperativeDomainModel,
  ProjectModel,
  SpecialistAPIModel,
  SpecialistDomainModel,
  Talk,
  Tutor,
} from "../types";
import { SPECIALIST } from "../../../shared/constants";
import {
  countMonthsOfPause,
  normalizeGoalsIds,
  prepareDocumentsToArray,
  prepareSelectDataToSave,
  prepareWorkingHours,
} from "../helpers";

dayjs.extend(utc);
dayjs.extend(timezone);

const BaseModel = (data: OperativeDomainModel) => ({
  bio: data?.shortInformation || null,
  countryOfResidence: data?.residence?.value || null,
  userId: data?.id || null,
  languages:
    data?.languages?.filter((item) => item.language && item.level) || [],
  nationality: prepareSelectDataToSave(data?.nationality) || [],
  passports: prepareDocumentsToArray(data.passports, "toISO"),
  professions:
    data?.professions?.main || data?.professions?.secondary
      ? {
          main: data?.professions?.main || "",
          secondary:
            prepareSelectDataToSave(data?.professions?.secondary) || [],
        }
      : null,
  profile: data?.profile || null,
  seniority: data?.seniority || null,
  avatar: data?.avatar || null,
  social: {
    twitter: data?.social?.twitter || [],
    linkedIn: data?.social?.linkedIn || [],
    github: data?.social?.github || [],
  },
  workingHours: data?.workingHours
    ? prepareWorkingHours(data.workingHours)
    : null,
  visas: prepareDocumentsToArray(data.visas, "toISO"),
  goals: normalizeGoalsIds(data?.goals),
});

const prepareEducations = (data: SpecialistDomainModel) => {
  const courses =
    data?.experience?.courses?.map((course: CourseModel) => ({
      startDate: dayJSToMonthYear(course.startDate || null),
      endDate: dayJSToMonthYear(course.endDate || null),
      desc: course.description || null,
      education: "course",
      trainer: course.trainer,
      courseName: course.courseName,
    })) || [];
  const degrees =
    data?.experience?.degrees?.map((degree: DegreeModel) => ({
      startDate: dayJSToMonthYear(degree?.startDate || null),
      endDate: dayJSToMonthYear(degree?.endDate || null),
      desc: degree.description || null,
      education: "degree",
      schoolName: degree.schoolName,
      fieldOfStudy: degree.fieldOfStudy,
      degree: degree.degree,
    })) || [];
  return [...courses, ...degrees];
};
const SpecialistModel = (data: SpecialistDomainModel) => {
  const baseModel = BaseModel(data);
  return {
    ...baseModel,
    experience: {
      projects:
        data?.experience?.projects?.map((item: ProjectModel) => ({
          name: item?.name || undefined,
          desc: item.description,
          domain: item.domain,
          endDate: dayJSToMonthYear(item.endDate),
          projectRoles: prepareSelectDataToSave(item?.projectRoles),
          responsibility: item.responsibility,
          startDate: dayJSToMonthYear(item.startDate),
          techStack: returnObjectIds(item.techStack),
        })) || [],
      educations: prepareEducations(data),
      articles:
        data?.experience?.articles?.map((item: ArticleModel) => ({
          publicationDate: dayJSToMonthYear(item.date),
          techStack: returnObjectIds(item.techStack),
          title: item.title,
          url: item.url || null,
        })) || [],
      talks:
        data?.experience?.talks?.map((item: Talk) => ({
          eventName: item.eventName,
          techStack: returnObjectIds(item.techStack),
          title: item.title,
          url: item.url || null,
          videoUrl: item.videoUrl || null,
        })) || [],
      contributions:
        data?.experience?.contributions?.map((item) => ({
          endDate: dayJSToMonthYear(item.endDate),
          name: item.name,
          startDate: dayJSToMonthYear(item.startDate),
          techStack: returnObjectIds(item.techStack),
          url: item.url,
        })) || [],
      tutors:
        data?.experience?.tutors?.map((item: Tutor) => ({
          date: dayJSToMonthYear(item.date),
          desc: item.description,
          techStack: returnObjectIds(item.techStack),
          title: item.title,
        })) || [],
      awards:
        data?.experience?.awards?.map((item) => ({
          awardName: item.awardName,
          date: dayJSToMonthYear(item.date),
          rewarder: item.rewarder,
          techStack: returnObjectIds(item.techStack),
          description: item.description,
        })) || [],
      certificates:
        data?.experience?.certificates?.map((item: CertificateModel) => ({
          expirationDate: dayJSToMonthYear(item.expirationDate),
          issuedDate: dayJSToMonthYear(item.issuedDate),
          techStack: returnObjectIds(item.techStack),
          title: item.title,
          url: item.url || null,
        })) || [],
    },
    projectRoles: prepareSelectDataToSave(data.roles),
    careerSpan: data?.careerSpan?.startDate
      ? {
          startDate: dayJSToMonthYear(data?.careerSpan?.startDate) || null,
          gapMonths: countMonthsOfPause(data?.careerSpan?.gapMonths),
        }
      : null,
  };
};

const toAPIModel = (
  data: OperativeDomainModel | SpecialistDomainModel,
): OperativeAPIModel | SpecialistAPIModel =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  data?.profile === SPECIALIST ? SpecialistModel(data) : BaseModel(data);

export default toAPIModel;
