import { AxiosResponse } from "axios";
import handleError from "../shared/utils";
import {
  daysOffFromAPIToModel,
  holidaysHistoryFromAPIToModel,
} from "../models/converters/holidaysFromAPIModel";
import { HolidayNewTimeEntryModel, toAPIModel } from "../models";
import APIService from "../api/time-tracker-api";

const HolidaysService = (() => {
  const createHolidayTimeEntry = async (newEntry: HolidayNewTimeEntryModel) => {
    try {
      const response = await APIService.API.addTimeEntry(
        toAPIModel.newHolidayTimeEntryToAPIModel(newEntry),
      );
      if (response?.data) {
        return response;
      }
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchHolidaysHistory = async () => {
    try {
      const response = await APIService.API.getAuthedUserHolidaysHistory();
      return holidaysHistoryFromAPIToModel(response.data);
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteHolidayHistory = async (
    ids: string[],
  ): Promise<void | AxiosResponse> => {
    try {
      return await APIService.API.deleteAuthedUserHistoryTimeEntries(ids);
    } catch (error) {
      return handleError(error);
    }
  };

  const fetchDaysOff = async () => {
    try {
      const response = await APIService.API.getAuthedUserDaysOff();
      return daysOffFromAPIToModel(response.data);
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    createHolidayTimeEntry,
    deleteHolidayHistory,
    fetchDaysOff,
    fetchHolidaysHistory,
  };
})();

export default HolidaysService;
