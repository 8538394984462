import { ReactElement, useState } from "react";
import { classJoin } from "@ps/utils";
import { isDayjs } from "dayjs";
import { useLocation } from "react-router-dom";
import { TechStack, TimeRange } from "../projects/projectElements";
import {
  ArticlesTemplateProps,
  AwardsTemplateProps,
  CertificatesTemplateProps,
  ContributionTemplateProps,
  CourseTemplateProps,
  DegreeTemplateProps,
  GoalsTemplateProps,
  TalksTemplateProps,
  TutorTemplateProps,
} from "../../types";
import URLButton from "../../../url-button";
import Label from "../../../label";
import LinkButton from "../../../link";
import { PROFILE_SUMMARY } from "../../../../shared/data-cy";
import {
  ARTICLES,
  CERTIFICATES,
  CONTRIBUTIONS,
  DATE_FORMAT_MM_YYYY,
  TALKS,
} from "../constants";
import { useTranslationWithNamespace } from "../../../../hooks";
import { ReactComponent as RightArrowIcon } from "../../../../images/profile-summary/rightArrow.svg";
import OtherExperienceModal from "./otherExperienceModal";
import { ContainerProps } from "./types";

const Container = ({
  additionalClassName,
  children,
  onMoreClick,
  canSeeMore = true,
  header,
}: ContainerProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  // TODO refactor if all summary view update
  const { pathname } = useLocation();

  const isSummary = pathname.includes("summary");

  return (
    <div className="flex flex-col h-full justify-between">
      {!canSeeMore && header}
      <div
        className={classJoin(
          "flex flex-col md:grid grid-cols-2 items-start pt-4 gap-4",
          additionalClassName,
          !canSeeMore && "border-none",
        )}
      >
        {children}
      </div>
      {!isSummary && canSeeMore && (
        <LinkButton
          dataCy={`${PROFILE_SUMMARY}_template_seeMore`}
          suffixIcon={<RightArrowIcon className="mt-1" />}
          additionalClassName="self-end pr-2"
          onClick={onMoreClick}
        >
          {t(`profileSummary.seeMore`)}
        </LinkButton>
      )}
    </div>
  );
};

const ContributionTemplate = ({
  additionalClassName,
  endDate,
  name,
  startDate,
  technologyDict,
  techStack,
  url,
  header,
}: ContributionTemplateProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslationWithNamespace();

  const renderContent = () => (
    <Container
      additionalClassName={additionalClassName}
      canSeeMore={!isOpen}
      header={header}
      onMoreClick={() => setIsOpen(true)}
    >
      <div className="flex flex-col gap-2">
        <span className="font-semibold flex flex-wrap">{name}</span>
        <TimeRange
          className="text-sm text-neutralSecondary-41"
          startDate={startDate}
          endDate={endDate}
        />
        <Label
          dataCy={`${PROFILE_SUMMARY}_${CONTRIBUTIONS}`}
          text={t("profileSummary.contributionUrl")}
          additionalClassName="gap-1"
          fontWeight="font-thin"
        >
          <URLButton url={url} dataCy={`${PROFILE_SUMMARY}_${CONTRIBUTIONS}`} />
        </Label>
      </div>
      <TechStack
        showTwoRows={!isOpen}
        technologyDict={technologyDict}
        techStack={techStack}
      />
    </Container>
  );

  return (
    <>
      {renderContent()}
      <OtherExperienceModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderContent()}
      </OtherExperienceModal>
    </>
  );
};

const TalksTemplate = ({
  additionalClassName,
  eventName,
  header,
  technologyDict,
  techStack,
  title,
  url,
  date,
  videoUrl,
}: TalksTemplateProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslationWithNamespace();

  const renderContent = () => (
    <Container
      additionalClassName={additionalClassName}
      canSeeMore={!isOpen}
      header={header}
      onMoreClick={() => setIsOpen(true)}
    >
      <div className="flex flex-col gap-2">
        <span className="font-semibold flex flex-wrap">{title}</span>
        <span className="flex flex-wrap">{eventName}</span>
        <div className="flex items-center gap-4 h-16">
          {url ? (
            <Label
              dataCy={`${PROFILE_SUMMARY}_${TALKS}`}
              text={t(`profileSummary.eventUrl`)}
              additionalClassName="gap-1"
              fontWeight="font-thin"
            >
              <URLButton url={url} dataCy={`${PROFILE_SUMMARY}_${TALKS}`} />
            </Label>
          ) : (
            <></>
          )}
          {videoUrl ? (
            <Label
              dataCy={`${PROFILE_SUMMARY}_${TALKS}`}
              text={t(`profileSummary.videoUrl`)}
              additionalClassName="gap-1"
              fontWeight="font-thin"
            >
              <URLButton
                url={videoUrl}
                dataCy={`${PROFILE_SUMMARY}_${TALKS}`}
              />
            </Label>
          ) : (
            <></>
          )}
        </div>
        {date && isOpen ? (
          <span className="text-sm">
            {isDayjs(date) ? date?.format("MM-RR") : date}
          </span>
        ) : (
          <></>
        )}
      </div>
      <TechStack
        showTwoRows={!isOpen}
        technologyDict={technologyDict}
        techStack={techStack}
      />
    </Container>
  );

  return (
    <>
      {renderContent()}
      <OtherExperienceModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderContent()}
      </OtherExperienceModal>
    </>
  );
};

const CertificatesTemplate = ({
  additionalClassName,
  expirationDate,
  issuedDate,
  technologyDict,
  techStack,
  title,
  url,
  header,
}: CertificatesTemplateProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslationWithNamespace();

  const renderContent = () => (
    <Container
      additionalClassName={additionalClassName}
      canSeeMore={!isOpen}
      header={header}
      onMoreClick={() => setIsOpen(true)}
    >
      <div className="flex flex-col gap-2">
        <span className="font-semibold flex flex-wrap">{title}</span>
        <TimeRange
          className="text-sm"
          startDate={issuedDate}
          endDate={expirationDate}
        />
        {url ? (
          <Label
            dataCy={`${PROFILE_SUMMARY}_${CERTIFICATES}`}
            text={t(`profileSummary.certificateUrl`)}
            additionalClassName="gap-1"
            fontWeight="font-thin"
          >
            <URLButton
              url={url}
              dataCy={`${PROFILE_SUMMARY}_${CERTIFICATES}`}
            />
          </Label>
        ) : (
          <></>
        )}
      </div>
      <TechStack
        showTwoRows={!isOpen}
        technologyDict={technologyDict}
        techStack={techStack}
      />
    </Container>
  );

  return (
    <>
      {renderContent()}
      <OtherExperienceModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderContent()}
      </OtherExperienceModal>
    </>
  );
};

const TutorTemplate = ({
  additionalClassName,
  date,
  technologyDict,
  techStack,
  title,
  description,
  header,
}: TutorTemplateProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const renderContent = () => (
    <Container
      additionalClassName={additionalClassName}
      canSeeMore={!isOpen}
      header={header}
      onMoreClick={() => setIsOpen(true)}
    >
      <div className="flex flex-col gap-2">
        <span className="font-semibold flex flex-wrap">{title}</span>
        <span className="text-neutralSecondary-41 text-sm">
          {isDayjs(date) ? date?.format(DATE_FORMAT_MM_YYYY) : ""}
        </span>
      </div>
      <TechStack
        showTwoRows={!isOpen}
        technologyDict={technologyDict}
        techStack={techStack}
      />
      <p className="text-sm col-span-2">{description}</p>
    </Container>
  );

  return (
    <>
      {renderContent()}
      <OtherExperienceModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderContent()}
      </OtherExperienceModal>
    </>
  );
};

const ArticlesTemplate = ({
  additionalClassName,
  publicationDate,
  technologyDict,
  techStack,
  title,
  url,
  header,
}: ArticlesTemplateProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslationWithNamespace();

  const renderContent = () => (
    <Container
      additionalClassName={additionalClassName}
      canSeeMore={!isOpen}
      header={header}
      onMoreClick={() => setIsOpen(true)}
    >
      <div className="flex flex-col gap-1">
        <span className="font-semibold flex flex-wrap">{title}</span>
        <span className="text-neutralSecondary-41">
          {isDayjs(publicationDate)
            ? publicationDate?.format(DATE_FORMAT_MM_YYYY)
            : ""}
        </span>
        {url && (
          <Label
            dataCy={`${PROFILE_SUMMARY}_${ARTICLES}`}
            text={t(`profileSummary.publicationUrl`)}
            additionalClassName="gap-1"
            fontWeight="font-thin"
          >
            <URLButton url={url} dataCy={`${PROFILE_SUMMARY}_${ARTICLES}`} />
          </Label>
        )}
      </div>
      <TechStack
        showTwoRows={!isOpen}
        technologyDict={technologyDict}
        techStack={techStack}
      />
    </Container>
  );

  return (
    <>
      {renderContent()}
      <OtherExperienceModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderContent()}
      </OtherExperienceModal>
    </>
  );
};

const DegreeTemplate = ({
  additionalClassName,
  degree,
  endDate,
  fieldOfStudy,
  schoolName,
  startDate,
  description,
  header,
}: DegreeTemplateProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const renderContent = () => (
    <Container
      additionalClassName={additionalClassName}
      canSeeMore={!isOpen}
      header={header}
      onMoreClick={() => setIsOpen(true)}
    >
      <div className="flex flex-col gap-2">
        <span className="font-semibold flex flex-wrap">{fieldOfStudy}</span>
        <span className="flex flex-wrap">{schoolName}</span>
        <span className="flex flex-wrap">{degree}</span>
        <TimeRange startDate={startDate} endDate={endDate} />
      </div>
      <p className="text-sm col-span-2">{description}</p>
    </Container>
  );

  return (
    <>
      {renderContent()}
      <OtherExperienceModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderContent()}
      </OtherExperienceModal>
    </>
  );
};

const CourseTemplate = ({
  additionalClassName,
  courseName,
  endDate,
  startDate,
  trainer,
  description,
  header,
}: CourseTemplateProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const renderContent = () => (
    <Container
      additionalClassName={additionalClassName}
      canSeeMore={!isOpen}
      header={header}
      onMoreClick={() => setIsOpen(true)}
    >
      <div className="flex flex-col gap-2">
        <span className="font-semibold flex flex-wrap">{courseName}</span>
        <span className="flex flex-wrap">{trainer}</span>
        <TimeRange startDate={startDate} endDate={endDate} />
      </div>
      <p className="text-sm col-span-2">{description}</p>
    </Container>
  );

  return (
    <>
      {renderContent()}
      <OtherExperienceModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderContent()}
      </OtherExperienceModal>
    </>
  );
};

const AwardsTemplate = ({
  additionalClassName,
  awardName,
  date,
  description,
  header,
  rewarder,
  technologyDict,
  techStack,
}: AwardsTemplateProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const renderContent = () => (
    <Container
      additionalClassName={additionalClassName}
      canSeeMore={!isOpen}
      header={header}
      onMoreClick={() => setIsOpen(true)}
    >
      <div className="flex flex-col gap-1">
        <span className="font-semibold flex flex-wrap">{awardName}</span>
        <span className="flex flex-wrap">{rewarder}</span>
        <span className="text-neutralSecondary-41 text-sm">
          {isDayjs(date) ? date?.format(DATE_FORMAT_MM_YYYY) : ""}
        </span>
      </div>
      <TechStack
        showTwoRows={!isOpen}
        technologyDict={technologyDict}
        techStack={techStack}
      />
      <p className="text-sm col-span-2">{description}</p>
    </Container>
  );

  return (
    <>
      {renderContent()}
      <OtherExperienceModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderContent()}
      </OtherExperienceModal>
    </>
  );
};

const GoalsTemplate = ({
  additionalClassName,
  technologyDict,
  techStack,
  header,
  note,
}: GoalsTemplateProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslationWithNamespace();

  const renderContent = () => (
    <Container
      additionalClassName={additionalClassName}
      canSeeMore={!isOpen}
      header={header}
      onMoreClick={() => setIsOpen(true)}
    >
      <div className="flex flex-col gap-2">
        <span className="font-semibold flex flex-wrap">
          {t(`profileSummary.goalsSubheader`)}
        </span>
        <span className="flex flex-wrap">{note}</span>
      </div>
      <TechStack
        showTwoRows={!isOpen}
        technologyDict={technologyDict}
        techStack={techStack}
        isGoalsCard
      />
    </Container>
  );

  return (
    <>
      {renderContent()}
      <OtherExperienceModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderContent()}
      </OtherExperienceModal>
    </>
  );
};

export {
  ArticlesTemplate,
  AwardsTemplate,
  CertificatesTemplate,
  ContributionTemplate,
  CourseTemplate,
  DegreeTemplate,
  TalksTemplate,
  TutorTemplate,
  GoalsTemplate,
};
