export default {
  tabs: {
    members: "Members",
    permissions: "Permissions",
  },
  permissions: {
    searchUser: "Search user",
    title: "Permissions",
    newRole: "New role",
    deleteColumn: "Delete column",
    confirmDelete: "Do you really want to delete column {{role}}?",
    createNewTitle: "Create table with permissions groups.",
    createNewDescription: "Here you can create permissions for all users.",
    createTable: "Create table",
    noLongerThan: "Max {{ number }} characters",
    list: {
      team: "Team permissions",
      skills: "Skills permissions",
      finance: "Finance permissions",
      project: "Project permissions",
      clients: "Clients permissions",
      projects: "Projects",
      prospect: "Prospects permissions",
      folder: "Folder permissions",
      holidays: "Holidays permissions",
      finances: "Finances",
    },
    accessNames: {
      team: {
        "users-assign-roles": "Assign roles",
        "roles-manage": "Create roles",
        "users-list-all": "List users",
      },
      skills: {
        "matrix-fulfillments": "Fill up your matrix",
        "matrix-manage-templates":
          "Manage matrix template, view all company matrix data",
      },
      finance: {
        "fetch-all-users-data":
          "Fetching all financial data about employees including salary or revenues",
        "fetch-all-project-data":
          "Fetching all financial data per projects assigned",
        "fetch-all-project-data-wth-rates-revenues":
          "Fetching all financial data about projects including rates or revenues",
      },
      project: {
        "project-full-access": "Full access",
        "project-list-all": "List all projects",
        "project-edit-timesheets": "Fill timesheet for user",
        "create-edit-folder": "Create, edit folder",
        "list-folders": "List folders",
        "fill-view-holidays": "Fill up, view",
        "view-all-holidays": "View all",
        "edit-all-holidays": "Edit all",
      },
      prospect: {
        "prospect-full-access": "Full access",
        "prospect-list-all": "List all prospects",
        "prospect-view-rates": "View rates",
      },
      clients: {
        "clients-manage": "Full access",
        "clients-list-all": "List clients",
      },
      finances: {
        "finances-manage-finances": "Manage finances and holidays",
      },
    },
  },
  members: {
    title: "Members permissions",
    addNewMember: "Add new member",
    name: "Name",
    email: "Email",
    activeMembers: "Active members",
    archivedMembers: "Archived members",
    usersEmails: "Add users email",
    selectGroup: "Select group",
    profession: "Profession",
    group: "Permission group",
    enabled: "Status",
    deleteUser: "Delete user",
    doYouWantToDelete: "Do you really want to delete <0>{{ user }}</0>?",
    inviteUser: "Invite users",
    inviteToTheGroup: "Invite users to the organization",
    inviteEmail:
      "Invite user by email. Add up to 50 members at once by separating emails with a comma",
    groupName: "Group",
    byName: "By name",
    byProfession: "By profession",
    byAccess: "By permission",
    byStatus: "By status",
    search: "Search members",
    active: "Active",
    archived: "Archived",
    firstName: "First name",
    lastName: "Last name",
    startDate: "Start date",
    inviting: {
      fillInEmail: "Fill in email address",
      fillInName: "Fill in name",
      addNewMembers: "Add New Members",
      info: "Invite up to {{ count }} users at a time",
      infoOrFill: "or fill in the form below",
      fillForm: "Fill the form below to add new user",
      uploadedPDFsAmount: "{{amount}}/{{totalAmount}} uploaded PDFs",
      noGroup: "No Group",
      cv: "CV",
      skipThis: "Skip this one >>",
      email: "Email",
      emailPlaceholder: "Type email of New Member",
      firstName: "First name",
      firstNamePlaceholder: "New Member Name",
      surname: "Last name",
      surnamePlaceholder: "New Member Last Name",
      role: "Role",
      group: "Permissions",
      startDate: "Start date",
      start: "Start: {{date}}",
      startNow: "Start now",
      uploadFailed: "Upload has failed - {{reason}}",
      invite_one: "Send {{count}} invite",
      invite_other: "Send {{count}} invites",
      inviteZero: "Send invite",
      specialist: "Specialist",
      operative: "Operative",
      addCV: "+Add CV",
      noCV: "no CV",
      addMember: "Add Member",
      toastInviteUserSuccess: "Invitations has been sent",
      uploadFile: "Upload {{type}}",
      dragAndDrop: "Drag and drop files",
      fileMustBeNoLonger: "File must be no larger than {{ size }} MB",
      exceededLimit: "The file limit has been exceeded",
      cancelHeader: "Are you sure you want to cancel this operation?",
      limitNotificationInfo: "You can invite only {{ count }} users at a time",
      cancelHeaderDesc:
        "None of the uploaded PDFs will be added to the bulk invitation",
      deletingHeader: "Are you sure you want to delete this item?",

      errors: {
        fileTooBig:
          "Your file is {{ size }} MB, it's too big. Make it smaller and upload again.",
        wrongType: "Your file is not {{ extension }} type.",
        fileNameTooLong:
          "File name shouldn't be longer than {{ length }} chars",
        unknownUploadCVError:
          "An unknown error occurred. Please try to upload your CV again.",
        fileNameTooShort: "File name should have at least {{ length }} chars",
        noWhiteSpace: "File name can't contain white spaces.",
        onlyAlphaChars: "File name can contain only letters",
      },
    },
  },
  errors: {
    cantBeEmpty: "Field is required",
    wrongEmailFormat: "Wrong email format",
    emailInvited: "Email already invited",
    alreadyExists: "Name already exists",
    emailAdded: "Email already added",
    maxEmails: "Maximum number of emails exceeded, reduce to {{ number }}",
    cantBeLonger: "Text can't be longer than {{count}} chars",
  },
  actions: {
    save: "Save",
    cancel: "Cancel",
    edit: "Edit",
    delete: "Delete",
    send: "Send",
    confirm: "Confirm",
    keepGoing: "Keep going",
    saveAndContinue: "Save and Continue",
    goBack: "Go back",
  },
};
