import { classJoin } from "@ps/utils";
import { ReactElement } from "react";
import { TabProps } from "./types";

const Tab = ({
  active,
  label,
  value,
  onClick,
  onKeyDown,
}: TabProps): ReactElement => {
  const activeStyle = active
    ? "bg-neutralPrimary-100 rounded-t-md text-primary-30"
    : "bg-transparent text-neutralPrimary-40 hover:text-neutralPrimary-20";

  return (
    <div
      className={classJoin(
        activeStyle,
        "cursor-pointer px-4 pt-2 pb-3 font-semibold text-lg",
      )}
      id={value}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
    >
      {label}
    </div>
  );
};

export default Tab;
