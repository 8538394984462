import { AnyAction, Dispatch } from "redux";
import { FinanceService, HolidaysItemAPIModel } from "@ps/hh";
import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import ActionTypes from "./actionTypes";
import { IAction } from "./types";
import { HolidaysService } from "../../services";

export const fetchUserHolidaysTemplateStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_USER_HOLIDAYS_TEMPLATE_START);
export const fetchUserHolidaysTemplateSuccess = (
  payload: IAction<HolidaysItemAPIModel[]>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_USER_HOLIDAYS_TEMPLATE_SUCCESS, payload);
export const fetchUserHolidaysTemplateError = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_USER_HOLIDAYS_TEMPLATE_ERROR, payload);

export const fetchUserHolidaysTemplate = async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(fetchUserHolidaysTemplateStart());
  const response = await FinanceService.fetchUserHolidays();
  if (isAPIError(response)) {
    dispatch(fetchUserHolidaysTemplateError(response));
  } else {
    dispatch(fetchUserHolidaysTemplateSuccess(response));
  }
};

export const fetchHolidaysHistoryStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_HISTORY_START);
// TODO holidays - add type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchHolidaysHistorySuccess = (payload: IAction<any>): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_HISTORY_SUCCESS, payload);
export const fetchHolidaysHistoryError = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_HOLIDAYS_HISTORY_ERROR, payload);

export const fetchHolidaysHistory = async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(fetchHolidaysHistoryStart());
  const response = await HolidaysService.fetchHolidaysHistory();
  if (isAPIError(response)) {
    dispatch(fetchHolidaysHistoryError(response));
  } else {
    dispatch(fetchHolidaysHistorySuccess(response));
  }
};

export const fetchDaysOffStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_DAYS_OFF_START);
// TODO holidays - add type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchDaysOffSuccess = (payload: IAction<any>): AnyAction =>
  actionCreator(ActionTypes.FETCH_DAYS_OFF_START_SUCCESS, payload);
export const fetchDaysOffError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_DAYS_OFF_START_ERROR, payload);

export const fetchDaysOff = async (dispatch: Dispatch): Promise<void> => {
  dispatch(fetchDaysOffStart());
  const response = await HolidaysService.fetchDaysOff();
  if (isAPIError(response)) {
    dispatch(fetchDaysOffError(response));
  } else {
    dispatch(fetchDaysOffSuccess(response));
  }
};
