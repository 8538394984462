import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import useFontSizeMode from "./useFontSize";
import { FontSize, FontSizeSwitchProps } from "./types";
import { focusVisibleStyles, Keys } from "../../shared";

const fontSizeStyles = "p-0.5 cursor-pointer rounded-md";

const FontSizeSwitch = ({
  additionalClassName,
  textColor = "text-white",
}: FontSizeSwitchProps): ReactElement => {
  const { set, fontSize } = useFontSizeMode();

  const isNotLarge = fontSize !== FontSize.LARGE;
  const isNotDefault = fontSize !== FontSize.DEFAULT;
  const isNotSmall = fontSize !== FontSize.SMALL;

  return (
    <div
      className={classJoin(
        "text-justify font-semibold select-none",
        textColor,
        additionalClassName,
      )}
    >
      <span
        className={classJoin(
          fontSizeStyles,
          focusVisibleStyles,
          !isNotLarge && "text-primary-51",
        )}
        style={{ fontSize: "20px" }}
        role="button"
        onClick={() => {
          if (isNotLarge) set(FontSize.LARGE);
        }}
        tabIndex={0}
        onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
          if (event.key === Keys.ENTER && isNotLarge) set(FontSize.LARGE);
        }}
      >
        A
      </span>
      <span
        className={classJoin(
          fontSizeStyles,
          focusVisibleStyles,
          !isNotDefault && "text-primary-51",
        )}
        style={{ fontSize: "16px" }}
        role="button"
        onClick={() => {
          if (isNotDefault) set(FontSize.DEFAULT);
        }}
        tabIndex={0}
        onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
          if (event.key === Keys.ENTER && isNotDefault) set(FontSize.DEFAULT);
        }}
      >
        A
      </span>
      <span
        className={classJoin(
          fontSizeStyles,
          focusVisibleStyles,
          !isNotSmall && "text-primary-51",
        )}
        style={{ fontSize: "12px" }}
        role="button"
        onClick={() => {
          if (isNotSmall) set(FontSize.SMALL);
        }}
        tabIndex={0}
        onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
          if (event.key === Keys.ENTER && isNotSmall) set(FontSize.SMALL);
        }}
      >
        A
      </span>
    </div>
  );
};

export default FontSizeSwitch;
