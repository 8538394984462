import { getName } from "i18n-iso-countries";
import { CURRENT_LANGUAGE_CODE } from "../../../i18n/constants";
import { ClientAPIModel, ClientModel } from "../types";

const prepareCountryToDisplay = (country: string) => ({
  label: getName(country, CURRENT_LANGUAGE_CODE, { select: "official" }),
  value: country,
});

const fromAPIToModel = (clients: ClientAPIModel[]): ClientModel[] =>
  clients.map(
    (client: ClientAPIModel): ClientModel =>
      <ClientModel>{
        id: client.id,
        clientName: client?.clientName,
        contactPersonName: client?.contactPersonName,
        contactPersonEmail: client?.contactPersonEmail,
        country: client?.country
          ? prepareCountryToDisplay(client.country)
          : null,
        city: client?.city,
        phoneNumber: client?.phoneNumber,
        domains: client.domains,
        archived: client.archived,
      },
  );

export default fromAPIToModel;
