import { useState, ReactElement, KeyboardEvent, useEffect } from "react";
import countries from "i18n-iso-countries";
import {
  AmountFilterOption,
  FilterDropdown,
  TableLabel,
} from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import Keys from "../../../../../shared/keys";
import styles from "../styles.module.css";
import { selectFilterOptions } from "../../../../../project-domain/ui/new-project/sections/team/utils";
import {
  INFO,
  LOCATION,
  PROFESSION,
  PROFESSIONS,
  PROJECTS,
  PROPOSAL_FOLDER,
  PROSPECT,
  RESIDENCE,
  SCORE,
  SENIORITY,
  USER_NAME,
} from "../../../../constants";
import {
  DiscoveryTeamFilters,
  FilterItem,
  SortBarMapState,
  SortBarMapStateReturn,
} from "../discovery-team/types";
import {
  countOptionFilterAmount,
  formatFilterTooltipName,
  getCheckedNames,
  prepareLocationFilterOptions,
  prepareProfessionFilterOptions,
  prepareSeniorityFilterOptions,
} from "../utils";
import { SpecialistsSortBarProps, UserProps } from "./types";
import { ReactComponent as FaceIcon } from "../../../../../images/project/face-id.svg";
import { CURRENT_LANGUAGE_CODE } from "../../../../../i18n/constants";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";

const TRANSLATE_PATH = `${PROJECTS}.${PROSPECT}.${PROPOSAL_FOLDER}`;
const DROPDOWN_ID = "filter-dropdown";

const SpecialistsSortBar = ({
  filters,
  updateFilters,
  users,
}: SpecialistsSortBarProps) => {
  const { t } = useTranslationWithNamespace();
  const [searchValue, setSearchValue] = useState("");
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [currentIDFocused, setCurrentIDFocused] = useState("");

  const translate = (field: string) => t(`${TRANSLATE_PATH}.${field}`);

  useEffect(() => {
    if (showFilterOptions) document.getElementById(DROPDOWN_ID)?.focus();
  }, [showFilterOptions]);

  const mapState = (state: SortBarMapState): SortBarMapStateReturn => ({
    professionsDict: state.dictionaries?.profession || [],
    seniorityDict: state?.dictionaries?.seniority || [],
    operativeProfessionsDict: state?.dictionaries.operative_profession || [],
  });

  const { professionsDict, seniorityDict, operativeProfessionsDict } =
    useMappedStateSelector(mapState);

  const professionFiltersOptions = prepareProfessionFilterOptions(users, [
    ...professionsDict,
    ...operativeProfessionsDict,
  ]);

  const seniorityFiltersOptions = prepareSeniorityFilterOptions(
    users,
    seniorityDict,
  );

  const allCountries = countries.getNames(CURRENT_LANGUAGE_CODE, {
    select: "official",
  });

  const locationFiltersOptions = prepareLocationFilterOptions(
    users,
    allCountries,
  );

  const hasScore = users?.some(
    (entry: UserProps) => entry.score !== null && entry.score !== undefined,
  );

  const renderLabel = (
    filterName: string,
    translationName?: string,
    filterOptions: FilterItem[] | undefined = undefined,
    withAmount = false,
    nestedName = "",
  ): ReactElement => (
    <TableLabel
      dataCy={translationName || filterName}
      setSearchValue={setSearchValue}
      filtering={
        filterOptions
          ? {
              checked: formatFilterTooltipName(filters, filterName, {
                [PROFESSIONS]: professionsDict,
                [SENIORITY]: seniorityDict,
              }),
              dropdownOverlay: (
                <FilterDropdown
                  dataCy={`${PROPOSAL_FOLDER}-list-${filterName}`}
                  DROPDOWN_ID={DROPDOWN_ID}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setShowFilterOptions={setShowFilterOptions}
                  filterOptions={filterOptions}
                >
                  {filterOptions.map((item: FilterItem, index: number) => {
                    const isChecked = filters[filterName]?.some(
                      (itemId: string) => itemId === item.id,
                    );
                    const autoFocusId = `${filterName}-${index}`;

                    return (
                      <AmountFilterOption
                        key={item.id}
                        autoFocus={autoFocusId === currentIDFocused}
                        activeNumber={countOptionFilterAmount(
                          item.id,
                          filterName,
                          users,
                          nestedName,
                        )}
                        item={item}
                        isChecked={isChecked}
                        filterName={filterName}
                        withAmount={withAmount}
                        dataCy={PROPOSAL_FOLDER}
                        onClick={(
                          isCurrenChecked: boolean,
                          itemId: string,
                          currentFilterName: string,
                        ) => {
                          updateFilters(
                            isCurrenChecked,
                            itemId,
                            currentFilterName,
                          );
                          setCurrentIDFocused(autoFocusId);
                        }}
                        onKeyDown={(
                          e: KeyboardEvent<HTMLButtonElement>,
                          isCurrenChecked: boolean,
                          itemId: string,
                          currentFilterName: string,
                        ) => {
                          if (e.key === Keys.SPACE) {
                            e.preventDefault();
                            updateFilters(
                              isCurrenChecked,
                              itemId,
                              currentFilterName,
                            );
                            setCurrentIDFocused(autoFocusId);
                          }
                        }}
                      />
                    );
                  })}
                </FilterDropdown>
              ),
            }
          : undefined
      }
    >
      {translate(translationName || filterName)}
    </TableLabel>
  );
  return (
    <div
      className={classJoin(
        styles.specialistsGrid,
        "w-full bg-neutralSecondary-41 bg-opacity-10 rounded-t-2xl h-16 items-center px-6",
      )}
    >
      <div className="flex gap-x-9 items-center pl-3">
        <FaceIcon className="text-neutralPrimary-30" />
        {renderLabel(USER_NAME)}
      </div>
      {renderLabel(
        PROFESSIONS,
        PROFESSION,
        selectFilterOptions(professionFiltersOptions, searchValue),
      )}
      {renderLabel(
        SENIORITY,
        undefined,
        selectFilterOptions(seniorityFiltersOptions, searchValue),
        true,
      )}
      {hasScore
        ? renderLabel(SCORE)
        : renderLabel(
            RESIDENCE,
            LOCATION,
            selectFilterOptions(locationFiltersOptions, searchValue),
          )}
      {renderLabel(INFO)}
    </div>
  );
};

export default SpecialistsSortBar;
