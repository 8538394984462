import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import { ProjectService } from "@ps/hh";
import { AnyAction, Dispatch } from "redux";
import dayjs, { Dayjs } from "dayjs";
import ActionTypes from "./actionTypes";
import {
  BarChartDataModel,
  ProjectTimeEntryAPIModel,
  ProjectTimeEntryModel,
} from "../../models";
import { TimeEntriesService } from "../../services";
import { TIME_FORMAT_YYYY_MM_DD } from "../../shared/constants";
import { IAction } from "./types";

export const fetchTimesheetStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_TIMESHEET_START);
export const fetchTimesheetSuccess = (
  payload: ProjectTimeEntryAPIModel,
): AnyAction => actionCreator(ActionTypes.FETCH_TIMESHEET_SUCCESS, payload);
export const fetchTimesheetError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_TIMESHEET_ERROR, payload);

export const appendTimesheetEntry = (payload: { id: string }): AnyAction =>
  actionCreator(ActionTypes.APPEND_TIMESHEET_ENTRY, payload);

export const removeTimesheetEntry = (payload: { id: string }): AnyAction =>
  actionCreator(ActionTypes.REMOVE_TIMESHEET_ENTRY, payload);

export const fetchLastWeekTimesheetStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_LAST_WEEK_TIMESHEET_START);
export const fetchLastWeekTimesheetSuccess = (
  payload: ProjectTimeEntryAPIModel,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_LAST_WEEK_TIMESHEET_SUCCESS, payload);
export const fetchLastWeekTimesheetError = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_LAST_WEEK_TIMESHEET_ERROR, payload);

export const fetchTimesheetData = async (
  from: string | Date | Dayjs,
  to: string | Date | Dayjs,
  dispatch: Dispatch,
): Promise<
  | AnyAction
  | void
  | {
      timesheet: {
        project: { [id: string]: ProjectTimeEntryModel[] };
        holiday: { [id: string]: ProjectTimeEntryModel[] };
      };
      barChartData: BarChartDataModel;
    }
> => {
  dispatch(fetchTimesheetStart());
  const result = await TimeEntriesService.fetchTimesheet(
    dayjs(from).format(TIME_FORMAT_YYYY_MM_DD),
    dayjs(to).format(TIME_FORMAT_YYYY_MM_DD),
  );
  if (isAPIError(result)) {
    dispatch(fetchTimesheetError(result));
    return undefined;
  }
  dispatch(fetchTimesheetSuccess(result));
  return result;
};

export const fetchLastWeekTimesheetData = async (
  from: string | Date | Dayjs,
  to: string | Date | Dayjs,
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchLastWeekTimesheetStart());
  const result = await TimeEntriesService.fetchPreviousWeekData(
    dayjs(from).format(TIME_FORMAT_YYYY_MM_DD),
    dayjs(to).format(TIME_FORMAT_YYYY_MM_DD),
  );
  if (isAPIError(result)) {
    dispatch(fetchLastWeekTimesheetError(result));
  } else {
    dispatch(fetchLastWeekTimesheetSuccess(result));
  }
};

export const fetchPinnedProjectsStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_PINNED_PROJECTS_START);
export const fetchPinnedProjectsSuccess = (payload: string[] | []): AnyAction =>
  actionCreator(ActionTypes.FETCH_PINNED_PROJECTS_SUCCESS, payload);
export const fetchPinnedProjectsError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PINNED_PROJECTS_ERROR, payload);

export const fetchPinnedProjects = async (
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchPinnedProjectsStart());
  const result = await ProjectService.fetchPinnedProjects();
  if (isAPIError(result)) {
    dispatch(fetchPinnedProjectsError(result));
  } else {
    dispatch(fetchPinnedProjectsSuccess(result));
  }
};

export const fetchProjectsListStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROJECTS_LIST_START);
export const fetchProjectsListSuccess = (payload: string[] | []): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROJECTS_LIST_SUCCESS, payload);
export const fetchProjectsListError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PROJECTS_LIST_ERROR, payload);

export const fetchProjectList = async (
  projectsCreator: boolean,
  dispatch: Dispatch,
): Promise<AnyAction | void> => {
  dispatch(fetchProjectsListStart());
  const result = projectsCreator
    ? await ProjectService.getProjects()
    : await ProjectService.fetchMyProjects();
  if (isAPIError(result)) {
    dispatch(fetchProjectsListError(result));
  } else {
    dispatch(fetchProjectsListSuccess(result));
  }
};
