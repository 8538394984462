import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { SingleCellProps } from "../types";
import styles from "../styles.module.css";
import { ReactComponent as InfinityIcon } from "../../../images/infinity.svg";
import { rowStyles } from "./constants";
// border-neutralPrimary-70
const SingleCell = ({ data }: SingleCellProps): ReactElement => (
  <tr className={classJoin("grid grid-cols-1 divide-y items-end", styles.col)}>
    <td className={classJoin(rowStyles, "justify-end text-xs")}>
      {data.holidayName}
    </td>
    <td
      className={classJoin(rowStyles, "justify-end border-t border-primary-70")}
    >
      {data.usedDaysOff}
    </td>
    <td className={classJoin(rowStyles, "border-primary-70 justify-end")}>
      {data.unusedDaysOff || <InfinityIcon />}
    </td>
    <td className={classJoin(rowStyles, "border-primary-70 justify-end")}>
      {data.sumDays || <InfinityIcon />}
    </td>
  </tr>
);

export default SingleCell;
