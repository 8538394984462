import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { ReactComponent as CalendarIcon } from "../../../images/calendar-icon.svg";
import { ReactComponent as CloseIcon } from "../../../images/close-icon.svg";
import { DATEPICKER_PREFIX } from "../../../shared/data-cy";

interface DatepickerInputButtonProps {
  shouldAllowReset?: boolean;
  onClickReset: () => void;
  onClickCalendar: () => void;
  dataCy: string;
  disabled?: boolean;
}

const DatepickerInputButton = ({
  shouldAllowReset,
  onClickReset,
  onClickCalendar,
  dataCy,
  disabled,
}: DatepickerInputButtonProps): ReactElement => (
  <button
    className="absolute right-2 top-0 transform translate-y-2/3 cursor-pointer"
    onClick={shouldAllowReset ? onClickReset : onClickCalendar}
    data-cy={`${DATEPICKER_PREFIX}-reset-button-${dataCy}`}
    type="button"
    disabled={disabled}
  >
    {shouldAllowReset ? (
      <CloseIcon
        data-cy="svg-close-icon"
        className={classJoin(
          "fill-current hover:text-primary-70 text-neutralSecondary-40",
          disabled && "opacity-20 cursor-not-allowed pointer-events-none",
        )}
        height="14"
        width="13"
      />
    ) : (
      <CalendarIcon
        data-cy="svg-calendar-icon"
        className={classJoin(
          disabled && "opacity-20 cursor-not-allowed pointer-events-none",
        )}
      />
    )}
  </button>
);

export default DatepickerInputButton;
