import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import Tooltip from "../tooltip";
import { InfoTooltipProps } from "./types";
import { ReactComponent as QuestionMarkIcon } from "../../images/question-mark.svg";
import { INFO_TOOLTIP_PREFIX } from "../../shared/data-cy";
import { useFontSizeMode } from "../font-size-provider";

const InfoTooltip = ({
  dataCy,
  iconColor = "bg-primary-70",
  placement = "top",
  tooltipContent,
  tooltipSize = "w-4 h-4",
  questionMarkSize = "w-2 h-2",
  contentWidth,
}: InfoTooltipProps): ReactElement => {
  const { isLargeFont } = useFontSizeMode();

  return (
    <div
      className={classJoin(
        "p-1 rounded-full flex items-center justify-center",
        iconColor,
        tooltipSize,
      )}
      data-cy={`${INFO_TOOLTIP_PREFIX}_${dataCy}`}
    >
      <Tooltip
        placement={placement}
        content={<div>{tooltipContent}</div>}
        additionalClassName={classJoin(
          "border border-neutralSecondary-60",
          contentWidth,
          isLargeFont ? "w-96" : "w-80",
        )}
        backgroundColor="neutralPrimary-100"
      >
        <QuestionMarkIcon
          className={classJoin("text-white", questionMarkSize)}
        />
      </Tooltip>
    </div>
  );
};

export default InfoTooltip;
