import { ReactElement } from "react";
import { uid } from "react-uid";
import { classJoin, getNamesFromDictionary, setChipColors } from "@ps/utils";
import { Chip, Label, Tag } from "@ps/ui-components";
import SimpleLabel from "../../../common/simpleLabel";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import {
  ACCOUNT_NUMBER,
  BD,
  CLIENT_NAME,
  CONTACT_PERSON,
  DOMAIN,
  EMAIL,
  FIRST_NAME,
  GENERAL_NOTE,
  INVOICE_DETAILS,
  PHONE_NUMBER,
  PM,
  PROJECT,
  TECH_STACK,
} from "../../../../constants";
import { DictionaryEntryModel } from "../../../../../dictionaries";
import { ViewDetailsMapState, ViewDetailsMapStateReturn } from "./types";

const TRANSLATION_PATH = "project.details";

const ViewDetails = ({
  editButton,
}: {
  editButton: ReactElement;
}): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const mapState = (state: ViewDetailsMapState): ViewDetailsMapStateReturn => ({
    domainsDict: state.dictionaries.domain || [],
    project: state.projects.project || {},
  });

  const { domainsDict, project } = useMappedStateSelector(mapState);

  const selectedDomains: string[] = getNamesFromDictionary(
    project.details?.domains,
    domainsDict,
  ).split(",");

  return (
    <section className="w-full h-full p-9 flex pb-16 relative">
      <div className="flex flex-col justify-start min-h-4/6 gap-y-8 w-9/12">
        <div className="w-9/12">
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={GENERAL_NOTE}
            value={project?.details?.note ? project.details.note : ""}
          />
        </div>
        <div className="flex">
          <Label
            dataCy={`${PROJECT}_${DOMAIN}`}
            text={t(`${TRANSLATION_PATH}.${DOMAIN}`)}
            additionalClassName="col-span-3 w-full"
            fontWeight="font-normal"
            labelFontWeight="font-bold"
            labelFontSize="text-lg"
            labelColor="text-neutralPrimary-20"
          >
            <div className="flex flex-wrap gap-y-2 gap-x-2">
              {!selectedDomains.includes("") ? (
                selectedDomains.map((selectedDomain, index) => (
                  <Chip
                    key={uid(index)}
                    dataCy={`${PROJECT}_${DOMAIN}`}
                    label={selectedDomain}
                    borderWidth="border"
                    fontSize="font-semibold"
                    tailwindColors={setChipColors("domain")}
                  />
                ))
              ) : (
                <span className="text-neutralPrimary-30 font-normal">
                  {t(`${TRANSLATION_PATH}.empty`)}
                </span>
              )}
            </div>
          </Label>
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={PM}
            value={
              !project.details?.projectManager?.firstName
                ? undefined
                : `${project.details?.projectManager?.firstName} ${project.details?.projectManager?.lastName}`
            }
          />
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={BD}
            value={
              !project.details?.businessDeveloper?.firstName
                ? undefined
                : `${project.details?.businessDeveloper?.firstName} ${project.details?.businessDeveloper?.lastName}`
            }
          />
        </div>
        <Label
          dataCy={`${PROJECT}_${TECH_STACK}`}
          text={t(`${TRANSLATION_PATH}.${TECH_STACK}`)}
          fontWeight="font-normal"
          labelFontWeight="font-bold"
          labelFontSize="text-lg"
          labelColor="text-neutralPrimary-20"
        >
          <div className="flex flex-wrap gap-y-2 gap-x-2">
            {project.details?.techStack?.length ? (
              project.details?.techStack.map((tech: DictionaryEntryModel) => (
                <Tag
                  key={tech?.id}
                  dataCy={`${PROJECT}_${TECH_STACK}`}
                  name={tech?.name}
                />
              ))
            ) : (
              <span className="text-neutralPrimary-30 font-normal">
                {t(`${TRANSLATION_PATH}.empty`)}
              </span>
            )}
          </div>
        </Label>
      </div>
      <div
        style={{
          boxShadow:
            "0 2px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
        }}
        className="flex flex-col overflow-visible bg-neutralPrimary-100 w-3/12 right-8 -top-32 absolute rounded-2xl"
      >
        <div className="flex justify-end h-9 items-center px-6">
          {!project.archived && editButton}
        </div>
        <div
          style={{ minHeight: "500px" }}
          className="flex flex-col gap-y-6 px-6 pb-6"
        >
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={CLIENT_NAME}
            value={project?.details?.clientName}
          />
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={`${CONTACT_PERSON}.${FIRST_NAME}`}
            value={project?.details?.contactPerson?.firstName}
          />
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={`${CONTACT_PERSON}.${PHONE_NUMBER}`}
            value={project?.details?.contactPerson?.phoneNumber}
          />
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={`${CONTACT_PERSON}.${EMAIL}`}
            value={project?.details?.contactPerson?.email}
          />
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={`${INVOICE_DETAILS}.header`}
            value={
              project?.details?.invoiceDetails?.address ||
              project?.details?.invoiceDetails?.companyName ||
              project?.details?.invoiceDetails?.zipCode ||
              project?.details?.invoiceDetails?.country?.label ||
              project?.details?.invoiceDetails?.taxNumber
                ? `${
                    project?.details?.invoiceDetails?.companyName
                      ? `${project.details.invoiceDetails.companyName}`
                      : ""
                  }
            ${
              project?.details?.invoiceDetails?.address
                ? `${project.details.invoiceDetails.address}`
                : ""
            }
            ${
              project?.details?.invoiceDetails?.zipCode
                ? `${project.details.invoiceDetails.zipCode}`
                : ""
            }
            ${
              project?.details?.invoiceDetails?.country?.label
                ? `${project?.details?.invoiceDetails?.country?.label}`
                : ""
            }
            ${
              project?.details?.invoiceDetails?.taxNumber
                ? `VAT ID ${project.details.invoiceDetails.taxNumber}`
                : ""
            }
            `
                : undefined
            }
          />
          <SimpleLabel
            basePath={TRANSLATION_PATH}
            name={`${INVOICE_DETAILS}.${ACCOUNT_NUMBER}`}
            value={project?.details?.invoiceDetails?.accountNumber}
          />
        </div>
        <div
          className={classJoin(
            project?.archived ? "bg-gray-200" : "bg-other-toxicGreen",
            "h-5 rounded-b-2xl",
          )}
        />
      </div>
    </section>
  );
};

export default ViewDetails;
