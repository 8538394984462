import { Dayjs } from "dayjs";
import { KeyboardEvent, ReactElement } from "react";
import getTimeUnitButtonStyle from "../helpers/style/getTimeUnitButtonStyle";
import {
  DatepickerMode,
  DatepickerPickerEnum,
  DisplayModeProps,
} from "../types";
import { Keys } from "../../../shared";

const QuarterDisplayMode = ({
  setDisplayedMonth,
  currentDisplayedMonth,
  state,
  picker,
  selectedDatesActions,
  mode,
  disabledDate,
  closeCalendar,
}: DisplayModeProps): ReactElement => {
  const startOfYear = currentDisplayedMonth.startOf("year");
  const QUARTERS_ARRAY = [
    ...new Array(4)
      .fill(0)
      .map((_, index) => startOfYear.add(index, "quarter")),
  ];

  const handleYearClick = (year: Dayjs) => () => {
    setDisplayedMonth?.(year);
    selectedDatesActions?.onClick(year.startOf("quarter"))();
  };

  const hoverBorderCommon =
    "border-t border-b border-primary-50 border-opacity-30 border-dashed";
  const getButtonStyle = getTimeUnitButtonStyle({
    stylesSelected: {
      notSelected: "bg-transparent text-neutralPrimary-30",
      selectedInBetween: "bg-primary-50 bg-opacity-30 text-primary-100",
      selectedFirstInRow:
        "bg-primary-50 text-primary-100 rounded-l-md bg-opacity-100",
      selectedLastInRow:
        "bg-primary-50 text-primary-100 rounded-r-md bg-opacity-100",
      notInCurrentSelection: "text-neutralPrimary-30 opacity-30",
    },
    stylesHover: {
      hoverInBetween: `${hoverBorderCommon} bg-opacity-50`,
      nextUnitAfterHoverSecondInput: "rounded-l-md",
      prevUnitBeforeHoverFirstInput: "rounded-r-md",
      hoverFirstInRow: `${hoverBorderCommon} rounded-l-md border-l bg-opacity-70`,
      hoverLastInRow: `${hoverBorderCommon} rounded-r-md border-r bg-opacity-70`,
    },
    compareMode: DatepickerPickerEnum.QUARTER,
  })({
    selectedDates: state.selected,
    hoveringOverDate:
      mode === DatepickerMode.RANGE_PICKER &&
      picker === DatepickerPickerEnum.QUARTER
        ? state.hover
        : undefined,
    currentInput: state.input,
  });

  const rangeStart = state.selected?.[0];
  const rangeEnd = state.selected?.[1];

  const hoverStyles =
    "hover:bg-primary-50 hover:bg-opacity-80 hover:text-primary-100";
  const hoverStylesIfSingleMode =
    mode === DatepickerMode.DATE_PICKER || !rangeStart
      ? `${hoverStyles} hover:rounded-md`
      : hoverStyles;

  return (
    <div className="grid w-full h-min grid-cols-4 gap-y-5 px-2 pb-2 justify-items-center">
      {QUARTERS_ARRAY.map((quarter) => (
        <div
          className="flex items-center justify-center min-h-full w-full"
          key={quarter.quarter()}
          onPointerLeave={selectedDatesActions?.onPointerLeave}
        >
          <button
            className={`flex items-center justify-center w-full border-t border-b border-neutralPrimary-100 ${getButtonStyle(
              quarter,
            )} ${
              rangeStart && !rangeEnd && rangeStart.isSame(quarter, "quarter")
                ? "bg-secondary-20 text-white"
                : ""
            } ${hoverStylesIfSingleMode} cursor-pointer`}
            onClick={handleYearClick(quarter)}
            onPointerEnter={selectedDatesActions?.onPointerEnter(quarter)}
            disabled={
              picker === DatepickerPickerEnum.QUARTER &&
              disabledDate?.(quarter, state.selected)
            }
            onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
              if (event.key === Keys.ESC) {
                event.preventDefault();
                closeCalendar();
                event.stopPropagation();
              }
            }}
          >
            <div
              className={`flex items-center justify-center rounded-md
            focus:outline-none px-2 py-0.5 w-full h-full disabled:pointer-events-none disabled:text-neutralSecondary-30`}
            >
              {`Q${quarter.quarter()}`}
            </div>
          </button>
        </div>
      ))}
    </div>
  );
};

export default QuarterDisplayMode;
