import { Empty2 } from "@ps/ui-components";
import OtherSummaryHeader from "./otherSummaryHeader";
import { ReactComponent as ClockIcon } from "../../../../images/profile-summary/hours.svg";
import { useTranslationWithNamespace } from "../../../../hooks";
import { WorkingHoursProps } from "../types";

const WorkingHours = ({ timeRanges, onAddOrEditClick }: WorkingHoursProps) => {
  const { t } = useTranslationWithNamespace();
  return (
    <div className="w-3/12 pl-4 h-full">
      <OtherSummaryHeader
        title={t("profile.overview.work.workingHours")}
        Icon={<ClockIcon className="text-neutralSecondary-41" />}
      />
      {timeRanges ? (
        <div className="w-full h-full pt-6 flex flex-col gap-y-4 justify-center">
          <div className="flex justify-between items-center">
            <span className="text-neutralPrimary-20">{`${t(
              "profile.overview.work.comfortableHours",
            )}`}</span>
            <span className="text-neutralPrimary-20 font-bold">
              {`${timeRanges.comfortable?.start} - ${timeRanges.comfortable?.end}`}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-neutralPrimary-20">{`${t(
              "profile.overview.work.possibleHours",
            )}`}</span>
            <span className="text-neutralPrimary-20 font-bold">
              {`${timeRanges.possible?.start} - ${timeRanges.possible?.end}`}
            </span>
          </div>
          <div className="flex items-center justify-end text-neutralSecondary-41 gap-x-2">
            <span className="font-bold ">{timeRanges.zone?.abbreviation}</span>
            <span>{timeRanges.zone?.value.replace("_", " ")}</span>
          </div>
        </div>
      ) : (
        <Empty2
          onClick={onAddOrEditClick}
          section={t("profile.overview.work.hours")}
        />
      )}
    </div>
  );
};

export default WorkingHours;
