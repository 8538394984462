import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { classJoin } from "@ps/utils";
import {
  Label,
  hasPermissions,
  CopyLinkIconWithTooltip,
  Empty,
  Tooltip,
  Button2,
  Toggle,
  RestrictedWrapper,
} from "@ps/ui-components";
import { CandidateModel } from "../../../../../folder-domain/models/types";
import { PROSPECTS_PROPOSAL_FOLDER } from "../../../../../shared/data-cy";
import { PROSPECT_FULL_ACCESS } from "../../../../../shared/permissions";
import { PROSPECT_FOLDER_TEAM_MANUALLY } from "../../../../shared/data-cy";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import { FolderService, fetchFolder } from "../../../../../folder-domain";
import {
  ProposalColumnProps,
  ProposalRowMapState,
  ProposalRowMapStateReturn,
} from "./types";
import CustomLabel from "./customLabel";
import RunMLDropdown from "./runMLDropdown";
import { ReactComponent as AddTeamIcon } from "../../../../../images/prospect/addTeamManually.svg";
import { sumQuantity } from "./utils";

const BASE_PATH = "projects.prospect.proposalFolder";

const ProposalColumn = ({
  prospect,
  onManuallyClick,
  onMLClick,
  onTeamGenerateClick,
  professionDict,
  seniorityDict,
  openML,
  handleOpenML,
  handleCloseML,
}: ProposalColumnProps): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslationWithNamespace();

  const mapState = (state: ProposalRowMapState): ProposalRowMapStateReturn => ({
    folder: state?.folders?.folder || {},
    allPermissions: state.permissions || [],
  });
  const { allPermissions } = useMappedStateSelector(mapState, "");

  const { folder } = useMappedStateSelector(mapState);

  const onlyActiveCandidates = folder?.candidates?.filter(
    (candidate: CandidateModel) => !candidate.deleted,
  );

  const [anon, setAnon] = useState<boolean>();

  const handleAnonymizeCheck = async (anonymized: boolean): Promise<void> => {
    await FolderService.updateFolder(folder?.id, { anonymized, ...folder });
    await fetchFolder(folder.id, dispatch);
  };

  useEffect(() => {
    if (anon) handleAnonymizeCheck(anon);
  }, [anon]);

  const link = `${process?.env?.REACT_APP_PUBLIC_S3_PREFIX}${
    window.location.host.split(".")?.[0]
  }${process?.env?.REACT_APP_PUBLIC_S3_SUFFIX}${prospect?.folder}`;

  return (
    <div className="flex flex-col gap-5 rounded-lg w-3/12 shadow-lg px-4 pt-6 pb-8 h-fit">
      {!prospect.archived && (
        <RestrictedWrapper permissions={PROSPECT_FULL_ACCESS}>
          <div className="pb-6 flex flex-col gap-y-4">
            <RunMLDropdown
              open={openML}
              handleOpen={handleOpenML}
              handleClose={handleCloseML}
              needs={prospect.needs}
              onMLClick={onMLClick}
              onTeamGenerateClick={onTeamGenerateClick}
              professionDict={professionDict}
              seniorityDict={seniorityDict}
            />
            <Button2
              dataCy={PROSPECT_FOLDER_TEAM_MANUALLY}
              onClick={onManuallyClick}
              variant="secondary"
              width="w-full"
              paddingX="px-0"
            >
              <span className="flex items-center justify-center gap-2 flex-no-wrap w-full">
                <AddTeamIcon className="fill-current ml-2" />
                <span className="mr-2">
                  {t(`${BASE_PATH}.addTeamManually`)}
                </span>
              </span>
            </Button2>
          </div>
        </RestrictedWrapper>
      )}
      <CustomLabel
        dataCy={`${PROSPECTS_PROPOSAL_FOLDER}-resumes`}
        label={t(`${BASE_PATH}.resumes`)}
      >
        <div className="bg-other-toxicGreen w-fit px-2 rounded-xl font-semibold">
          {onlyActiveCandidates?.length}
        </div>
      </CustomLabel>
      <CustomLabel
        dataCy={`${PROSPECTS_PROPOSAL_FOLDER}-resumes`}
        label={t(`${BASE_PATH}.prospectRequirements`)}
      >
        <span className="px-1">{sumQuantity(prospect?.needs)}</span>
      </CustomLabel>
      <CustomLabel
        dataCy={`${PROSPECTS_PROPOSAL_FOLDER}-lastUpdate`}
        label={t(`${BASE_PATH}.lastUpdate`)}
      >
        {folder?.lastUpdate?.format("DD.MM.YYYY")}
      </CustomLabel>

      <CustomLabel
        dataCy={`${PROSPECTS_PROPOSAL_FOLDER}-active`}
        label={t(`${BASE_PATH}.active`)}
      >
        <span className="text-success-60 uppercase">
          {folder?.archived ? t(`${BASE_PATH}.no`) : t(`${BASE_PATH}.yes`)}
        </span>
      </CustomLabel>
      {!prospect.archived && (
        <CustomLabel
          dataCy={`${PROSPECTS_PROPOSAL_FOLDER}-anonymized`}
          label={t(`${BASE_PATH}.anonymized`)}
        >
          <Toggle
            checked={folder.anonymized}
            onChange={() => setAnon((a) => !a)}
            dataCy={`${PROSPECTS_PROPOSAL_FOLDER}-anonymized`}
            additionalClassName={classJoin(
              !hasPermissions(allPermissions, PROSPECT_FULL_ACCESS) &&
                "pointer-events-none opacity-25",
            )}
          />
        </CustomLabel>
      )}
      <div className="flex flex-col mt-6 w-full">
        <CustomLabel
          dataCy={`${PROSPECTS_PROPOSAL_FOLDER}-pin`}
          label={t(`${BASE_PATH}.PIN`)}
        >
          {folder?.pin ? (
            <div className="flex items-center w-full justify-between">
              <span className="ml-5">{folder.pin}</span>
              <CopyLinkIconWithTooltip
                dataCy={`${PROSPECTS_PROPOSAL_FOLDER}_pin-copy`}
                valueToCopy={folder?.pin}
              />
            </div>
          ) : (
            <Empty />
          )}
        </CustomLabel>
        <Label
          dataCy={`${PROSPECTS_PROPOSAL_FOLDER}-address`}
          text={t(`${BASE_PATH}.address`)}
          labelFontWeight="font-bold"
          labelFontSize="text-base"
        >
          <div className="flex items-center mt-2 w-full justify-between gap-2">
            <Tooltip
              placement="left"
              content={
                <span className="text-sm whitespace-nowrap">{link}</span>
              }
              isInteractive
              additionalChildrenClassName="w-full"
            >
              <a href={link} rel="noreferrer noopener" target="_blank">
                <Button2
                  dataCy={`${PROSPECTS_PROPOSAL_FOLDER}-address`}
                  borderRadius="rounded-lg"
                  additionalClass="whitespace-nowrap"
                  width="w-full"
                  paddingX="px-8"
                >
                  {t(`${BASE_PATH}.goToFolder`)}
                </Button2>
              </a>
            </Tooltip>
            {folder?.archived ? (
              <></>
            ) : (
              <CopyLinkIconWithTooltip
                dataCy={`${PROSPECTS_PROPOSAL_FOLDER}_url-copy`}
                valueToCopy={link}
              />
            )}
          </div>
        </Label>
      </div>
    </div>
  );
};

export default ProposalColumn;
