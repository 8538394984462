import { useCallback, useEffect, useRef, useState, RefObject } from "react";

interface UseDropDivProps {
  onFilesSelected?: (files: FileList) => void;
}

const useDropDiv = ({
  onFilesSelected,
}: UseDropDivProps): {
  dropDivRef: RefObject<HTMLDivElement>;
  isDraggingOver: boolean;
} => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const dropDivRef = useRef<HTMLDivElement>(null);
  const dragCounter = useRef(0);

  const handleDragEnter = useCallback((event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
    dragCounter.current += 1;
    const items = event.dataTransfer?.items;
    if (items && items.length > 0) setIsDraggingOver(true);
  }, []);

  const handleDragLeave = useCallback((event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
    dragCounter.current -= 1;
    if (dragCounter.current > 0) return;
    setIsDraggingOver(false);
  }, []);

  const handleDragOver = useCallback((event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(
    (event: DragEvent) => {
      event.stopPropagation();
      event.preventDefault();
      setIsDraggingOver(false);
      const files = event.dataTransfer?.files;
      if (files && files.length > 0) onFilesSelected?.(files);
      event.dataTransfer?.clearData();
      dragCounter.current = 0;
    },
    [onFilesSelected],
  );

  useEffect(() => {
    const dropDiv = dropDivRef.current;
    dropDiv?.addEventListener("dragenter", handleDragEnter);
    dropDiv?.addEventListener("dragleave", handleDragLeave);
    dropDiv?.addEventListener("dragover", handleDragOver);
    dropDiv?.addEventListener("drop", handleDrop);

    return () => {
      dropDiv?.removeEventListener("dragenter", handleDragEnter);
      dropDiv?.removeEventListener("dragleave", handleDragLeave);
      dropDiv?.removeEventListener("dragover", handleDragOver);
      dropDiv?.removeEventListener("drop", handleDrop);
    };
  }, [
    dropDivRef,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
  ]);
  return { dropDivRef, isDraggingOver };
};

export default useDropDiv;
