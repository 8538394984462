import {
  Checkbox,
  Datepicker2,
  Input,
  Label,
  Textarea,
} from "@ps/ui-components";
import dayjs from "dayjs";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { uid } from "react-uid";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { DATE_FORMAT } from "../../../../../../shared/constants";
import { EXPERIENCE_EDUCATIONS_EDIT } from "../../../../../../shared/data-cy";
import { checkHasEmptyFields } from "../../../helpers";
import {
  COURSE,
  COURSE_NAME,
  DESCRIPTION,
  END_DATE,
  PLACEHOLDER,
  START_DATE,
  TRAINER,
} from "../constants";
import { FormSectionProps } from "../types";

const MAX_LENGTH = 4000;

const CourseForm = ({
  index,
  isOcr,
  sourcePath = "courses",
}: FormSectionProps): ReactElement => {
  const {
    control,
    getValues,
    setValue,
    getFieldState,
    formState,
    watch,
    register,
  } = useFormContext();
  const { t } = useTranslationWithNamespace();

  const getFormFieldName = (fieldName: string) =>
    `${sourcePath}.${index}.${fieldName}`;

  useEffect(() => {
    setValue(getFormFieldName("education"), "course");
  }, [index]);

  const [checkedOngoing, setCheckedOngoing] = useState(
    !getValues(getFormFieldName(END_DATE)),
  );

  const trainer = getFieldState(getFormFieldName(TRAINER), formState);
  const courseName = getFieldState(getFormFieldName(COURSE_NAME), formState);

  const translate = (field: string, isRequired = true) =>
    `${t(`profile.experience.cards.course.${field}`)}${isRequired ? "*" : ""}`;

  const BASE_PATH = `${EXPERIENCE_EDUCATIONS_EDIT}_${COURSE}`;

  const hasEmptyFields = checkHasEmptyFields(
    getValues([TRAINER, COURSE_NAME, START_DATE, DESCRIPTION]),
  );

  const errorEmptyMessage = `${t("profile.errors.cantBeEmpty")}`;
  const errorBlankMessage = `${t("profile.errors.cantBeBlank")}`;
  const errorMaxChars = `${t("profile.errors.cantBeMoreChars", {
    number: MAX_LENGTH,
  })}`;

  const isEmptyOrBlank = (value: string, maxChars = false) => {
    if (!value?.length && hasEmptyFields) return errorEmptyMessage;
    if (!value?.trim()?.length) return errorBlankMessage;
    if (maxChars && value && value?.length > MAX_LENGTH) return errorMaxChars;
    return true;
  };

  return (
    <div className="flex flex-col gap-y-7" key={uid(`${COURSE}-${index}`)}>
      <div className="flex flex-row gap-x-5">
        <Label dataCy={`${BASE_PATH}_${TRAINER}`} text={translate(TRAINER)}>
          <Input
            dataCy={`${BASE_PATH}_${TRAINER}_input`}
            width="w-full"
            autoFocus
            value={watch(getFormFieldName(TRAINER)) || ""}
            error={!!trainer?.error}
            message={trainer?.error?.message || ""}
            placeholder={translate(`${TRAINER}${PLACEHOLDER}`, false)}
            {...register(getFormFieldName(TRAINER), {
              validate: (value) => {
                if (isOcr) return true;
                return isEmptyOrBlank(value);
              },
            })}
          />
        </Label>
        <Label
          dataCy={`${BASE_PATH}_${COURSE_NAME}`}
          text={translate(COURSE_NAME)}
        >
          <Input
            dataCy={`${BASE_PATH}_${COURSE_NAME}`}
            width="w-full"
            value={watch(getFormFieldName(COURSE_NAME)) || ""}
            error={!!courseName?.error}
            message={courseName?.error?.message || ""}
            placeholder={translate(COURSE_NAME, false)}
            {...register(getFormFieldName(COURSE_NAME), {
              validate: (value) => {
                if (isOcr) return true;
                return isEmptyOrBlank(value);
              },
            })}
          />
        </Label>
      </div>
      <div className="flex flex-row gap-x-5">
        <Label
          dataCy={`${BASE_PATH}_${START_DATE}`}
          text={translate(START_DATE)}
        >
          <Controller
            name={getFormFieldName(START_DATE)}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Datepicker2
                dataCy={`${BASE_PATH}_${START_DATE}_input`}
                error={error?.message}
                onChange={onChange}
                selected={value ? dayjs(value) : null}
                dateFormat="MM/YYYY"
                timeView={["month", "year"]}
                type="month"
              />
            )}
            rules={{
              validate: isOcr
                ? undefined
                : (value) => {
                    if (!value && hasEmptyFields) return errorEmptyMessage;
                    if (value && dayjs().isBefore(value))
                      return `${t("profile.errors.noFutureDate")}`;
                    return true;
                  },
            }}
          />
        </Label>
        <Label
          dataCy={`${BASE_PATH}_${END_DATE}`}
          text={translate(END_DATE, false)}
        >
          <Controller
            name={getFormFieldName(END_DATE)}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Datepicker2
                dataCy={`${BASE_PATH}_${END_DATE}_input`}
                error={error?.message}
                onChange={(newDate) => {
                  onChange(newDate);
                  setCheckedOngoing(false);
                }}
                selected={value ? dayjs(value) : null}
                disabled={checkedOngoing}
                dateFormat="MM/YYYY"
                timeView={["month", "year"]}
                type="month"
              />
            )}
            rules={{
              validate: isOcr
                ? undefined
                : (value) => {
                    const fromDate = getValues(getFormFieldName(START_DATE));
                    return value && fromDate && fromDate.isAfter(value)
                      ? `${t("profile.errors.noEarlierDate", {
                          date: fromDate.format(DATE_FORMAT),
                        })}`
                      : true;
                  },
            }}
          />
        </Label>
        <div className="flex items-center gap-2 mt-7">
          <Checkbox
            dataCy={`${BASE_PATH}_${END_DATE}`}
            checked={checkedOngoing}
            onChecked={() => {
              setCheckedOngoing((prev) => !prev);
              setValue(getFormFieldName(END_DATE), undefined);
            }}
          />
          <span className="whitespace-nowrap text-neutralPrimary-30 font-semibold">
            {translate("ongoing", false)}
          </span>
        </div>
      </div>
      <div className="col-span-6 h-40">
        <Label
          text={translate(DESCRIPTION, false)}
          dataCy={`${BASE_PATH}_${DESCRIPTION}`}
        >
          <Controller
            name={getFormFieldName(DESCRIPTION)}
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <Textarea
                dataCy={`${BASE_PATH}_${DESCRIPTION}_input`}
                maxLength={MAX_LENGTH}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  onChange(event.target.value)
                }
                value={watch(getFormFieldName(DESCRIPTION)) || ""}
                error={!!error}
                message={error?.message}
                placeholder={translate(`${DESCRIPTION}${PLACEHOLDER}`, false)}
              />
            )}
          />
        </Label>
      </div>
    </div>
  );
};

export default CourseForm;
