import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { Report } from "@ps/pdf-generator";
import { ReportModel } from "../../../models";

const generatePdfDocument = async (
  timeFrame: Date[],
  totalTimeInMinutes: number,
  report: ReportModel[],
  images: { section: string; image: string }[],
  groupNames: {
    [groupNumber: string]: {
      label: string;
      value: string;
    };
  },
  activeSorter: any,
  sortedGroupedData: any,
): Promise<void> => {
  const prepareFileName = (): string => {
    const prefix = "report";
    return `${prefix}-${timeFrame?.[0]?.toLocaleDateString()}-${timeFrame?.[1]?.toLocaleDateString()}.pdf`;
  };

  const blob = await pdf(
    <Report
      charts={images}
      report={report}
      groupNames={groupNames}
      timeFrame={timeFrame}
      totalTime={totalTimeInMinutes}
      activeSorter={activeSorter}
      sortedGroupedData={sortedGroupedData}
    />,
  ).toBlob();

  saveAs(blob, prepareFileName());
};

export default generatePdfDocument;
