import { removeDiacriticsFromString } from "@ps/utils";
import { BenchUserModel, BenchUsersModel } from "../models";

const filterBenchUsersBySearch = (
  data: BenchUsersModel,
  searchValue: string,
): BenchUsersModel =>
  data?.filter(
    (baseProfile: BenchUserModel): boolean =>
      removeDiacriticsFromString(baseProfile.name.toLowerCase()).includes(
        removeDiacriticsFromString(searchValue.toLowerCase().trim()),
      ) ||
      removeDiacriticsFromString(
        baseProfile.name.split(" ").reverse().join(" ").toLowerCase(),
      ).includes(removeDiacriticsFromString(searchValue.toLowerCase().trim())),
  );

export default filterBenchUsersBySearch;
