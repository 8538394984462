import {
  ChangeEvent,
  useState,
  useRef,
  KeyboardEvent,
  ReactElement,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import { classJoin } from "@ps/utils";
import { PinInputProps } from "./types";
import { Keys } from "../../shared";
import Notification from "../notification";
import { useTranslationWithNamespace } from "../../hooks";
import { PIN_INPUT_FIELD_PREFIX, PIN_INPUT_PREFIX } from "../../shared/data-cy";

const makeDefaultArray = (length: number) => new Array(length).fill("");

const inputStyle = classJoin.template`
  w-16 text-5xl text-center rounded-xl p-2 py-4 font-bold
  border bg-neutralPrimary-100
  focus:outline-none focus:border-secondary-50
`;

const normalStyle = classJoin.template`
  border-primary-20 text-neutralPrimary-20
`;

const errorStyle = classJoin.template`
  border-error-50 text-error-50
`;

const notificationAnimation = {
  visible: { y: 0 },
  hidden: { y: 80 },
};

const PinInput = ({
  length = 4,
  obfuscate = false,
  onlyNumbers,
  disabled,
  hasError,
  onPinEntryComplete,
  onPinChange,
  dataCy,
}: PinInputProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [value, setValue] = useState<(number | string | undefined)[]>(
    makeDefaultArray(length),
  );
  const inputRefs = useRef(makeDefaultArray(length));

  const focusInput = (newIndex: number) => {
    if (newIndex > length - 1) {
      inputRefs.current[newIndex - 1].blur();
      setTimeout(() => onPinEntryComplete?.(), 0);
      return;
    }
    if (newIndex < 0) return;
    inputRefs.current[newIndex].focus();
  };

  const changeArrayValue = (
    index: number,
    newValue: number | string | undefined,
  ) =>
    setValue((oldArray) => {
      const newValueArray = oldArray.reduce((acc, element, elementIndex) => {
        if (index === elementIndex) {
          return [...acc, newValue];
        }
        return [...acc, element];
      }, [] as (string | number | undefined)[]);
      onPinChange?.(newValueArray.join(""));
      return newValueArray;
    });

  const handleInput =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const eventValue = (event.nativeEvent as InputEvent).data;
      const eventTargetValue = event.target?.value;
      if (eventTargetValue?.length === 4) {
        const pastedValue = eventTargetValue.split("");
        onPinChange?.(pastedValue.join(""));
        setValue(pastedValue);
        focusInput(3);
      } else if (
        !eventValue ||
        (onlyNumbers && Number.isNaN(+(eventValue ?? "a"))) ||
        eventValue.length > 1
      )
        // eslint-disable-next-line no-useless-return
        return;
      else if (eventValue) {
        changeArrayValue(index, eventValue);
        focusInput(index + 1);
      }
    };

  const handleKeyDown =
    (index: number) => (event: KeyboardEvent<HTMLInputElement>) => {
      const { key } = event;
      switch (key) {
        case Keys.BACKSPACE:
          if (value[index]) {
            changeArrayValue(index, "");
          } else {
            focusInput(index - 1);
          }
          event.preventDefault();
          break;
        case Keys.ARROW_RIGHT:
          focusInput(index + 1);
          break;
        case Keys.ARROW_LEFT:
          focusInput(index - 1);
          break;
        default:
          break;
      }
    };

  return (
    <div
      className="flex flex-col gap-y-3 w-min"
      data-cy={`${PIN_INPUT_PREFIX}_${dataCy}`}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <AnimatePresence>
        {hasError && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ duration: 0.3 }}
            variants={notificationAnimation}
          >
            <Notification
              dataCy={dataCy}
              variant="error"
              id={dataCy}
              title={t("pinInput.error.title")}
              description={t("pinInput.error.description")}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="flex flex-row gap-x-3 z-10">
        {new Array(length).fill(undefined).map((_, index) => (
          <motion.input
            animate={
              hasError && {
                rotate: [10, -10, 10, -10, 10, 0],
              }
            }
            autoFocus={!index}
            transition={{ duration: 0.6 }}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            ref={(ref) => {
              inputRefs.current[index] = ref;
            }}
            disabled={disabled}
            type={obfuscate ? "password" : "text"}
            value={value[index]}
            className={classJoin(
              inputStyle,
              hasError ? errorStyle : normalStyle,
            )}
            onChange={disabled ? undefined : handleInput(index)}
            onKeyDown={handleKeyDown(index)}
            data-cy={`${PIN_INPUT_FIELD_PREFIX}_${dataCy}_${index}`}
          />
        ))}
      </div>
    </div>
  );
};
export default PinInput;
