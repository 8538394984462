import { KeyboardEvent, ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { focusVisibleStyles, Keys } from "../../shared";
import { TABLE_TAB_PREFIX } from "../../shared/data-cy";
import { ReactComponent as EditIcon } from "../../images/edit-pen.svg";
import { ReactComponent as TrashIcon } from "../../images/edit-trash.svg";
import { ReactComponent as CancelIcon } from "../../images/close-icon.svg";
import ButtonsGroup from "../buttons-group";
import Button from "../button";
import { TabButtonProps, TabProps } from "./types";

const renderButton = (
  dataCy: string,
  buttonType: string,
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  iconSize: string,
  disabled?: boolean,
  props?: TabButtonProps,
): ReactElement => (
  <Button
    dataCy={`${TABLE_TAB_PREFIX}-${buttonType}-${dataCy}`}
    paddingX="px-2"
    height="h-7"
    width="w-7"
    additionalClass="disabled:opacity-50 disabled:cursor-not-allowed"
    disabled={disabled}
    {...props}
  >
    <Icon width="14" height="14" className={`w-${iconSize} h-${iconSize}`} />
  </Button>
);

const disabledStyle = "opacity-50 cursor-default pointer-event-none";

const TableTab = ({
  dataCy,
  onClick,
  isEditing = false,
  additionalClass = "",
  editButton,
  deleteButton,
  cancelButton,
  iconSize = "3",
  children,
  disabled = false,
  isCurrentTab = false,
  hideButtons = false,
}: TabProps): ReactElement => (
  <div
    className={classJoin(
      "flex flex-row items-center justify-between gap-3 border-neutralSecondary-60 mb-2 p-3 rounded-md",
      additionalClass,
      focusVisibleStyles,
      disabled ? disabledStyle : "",
    )}
    onClick={() => {
      if (!disabled && onClick) onClick();
    }}
    role={disabled || isCurrentTab ? "presentation" : "button"}
    tabIndex={disabled || isCurrentTab ? undefined : 0}
    onKeyDown={(e: KeyboardEvent<HTMLSpanElement>) => {
      if (
        !disabled &&
        onClick &&
        (e.key === Keys.ENTER || e.key === Keys.SPACE)
      )
        onClick();
    }}
    data-cy={`${TABLE_TAB_PREFIX}-${dataCy}`}
  >
    {children}

    {!hideButtons && (
      <ButtonsGroup
        dataCy={`${TABLE_TAB_PREFIX}-${dataCy}`}
        additionalClass="ml-auto w-max h-7"
      >
        {isEditing
          ? renderButton(
              dataCy,
              "cancel",
              CancelIcon,
              iconSize,
              disabled,
              cancelButton,
            )
          : renderButton(
              dataCy,
              "edit",
              EditIcon,
              iconSize,
              disabled,
              editButton,
            )}

        {renderButton(
          dataCy,
          "delete",
          TrashIcon,
          iconSize,
          disabled,
          deleteButton,
        )}
      </ButtonsGroup>
    )}
  </div>
);

export default TableTab;
