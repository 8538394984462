import { colorFormatters } from "@ps/utils";
import { RatingScaleModel, GroupAPIModel, RatingScaleAPIModel } from "../types";
import { HIDDEN_DOMAINS } from "../../shared/consts";

export const scaleColorToRGBObject = (
  scale: RatingScaleAPIModel,
): RatingScaleModel => ({
  desc: scale.desc,
  score: scale.score,
  color: colorFormatters.prepareRGBObjectToHEX(scale.color),
  iconUrl: scale.iconUrl,
});

export const domainsToGroups = (
  domains: string[],
  groupName = "Domains",
): GroupAPIModel[] => [
  {
    name: groupName,
    skills: domains
      .filter((domain) => !HIDDEN_DOMAINS.includes(domain))
      .map((domain) => ({
        desc: domain,
        technologyId: domain,
      }))
      .sort((a, b) => a.technologyId.localeCompare(b.technologyId)),
  },
];

export const sortScoreAscending = (
  a: RatingScaleModel,
  b: RatingScaleModel,
): number => a.score - b.score;

export const placeOtherBottom = (
  a: GroupAPIModel,
  b: GroupAPIModel,
): number => {
  const OTHER = "other";
  if (a?.name?.toLowerCase()?.startsWith(OTHER)) return 1;
  if (b?.name?.toLowerCase()?.startsWith(OTHER)) return -1;
  return 0;
};

export const alphaSortSkillsInProfession = (
  group: GroupAPIModel[],
): GroupAPIModel[] => {
  const sortWthOther = group.sort(placeOtherBottom);
  const result = sortWthOther.map((item) => ({
    ...item,
    itemId: item.name.replaceAll(" ", "").toLowerCase(),
    skills: item.skills.sort((a, b) =>
      a.technologyId.localeCompare(b.technologyId),
    ),
  }));

  return result;
};
