import { ReactElement } from "react";
import { Label, Tag, URLButton } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { TalksCardProps } from "../types";
import { EXPERIENCE_ACCOMPLISHMENT_VIEW } from "../../../../../../shared/data-cy";
import { EVENT_NAME, TALKS, TITLE, URL_PATH, VIDEO_URL } from "../constants";
import { TECH_STACK } from "../../projects/constants";
import { DictionaryEntryModel } from "../../../../../../dictionaries";
import accomplishmentsGrid from "../../../common/styles";

const TalksCard = ({
  eventName,
  techStack,
  title,
  url,
  videoUrl,
  isDeleting,
}: TalksCardProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const BASE_PATH = `${EXPERIENCE_ACCOMPLISHMENT_VIEW}_${TALKS}`;

  const translate = (field: string): string =>
    t(`profile.experience.cards.talks.${field}`);

  return (
    <div className="relative w-full flex flex-col p-6">
      <div className={accomplishmentsGrid}>
        <Label
          text={translate(EVENT_NAME)}
          dataCy={`${BASE_PATH}_${EVENT_NAME}`}
        >
          {eventName}
        </Label>
        <Label text={translate(TITLE)} dataCy={`${BASE_PATH}_${TITLE}`}>
          {title}
        </Label>
        {url && (
          <Label
            text={translate(URL_PATH)}
            dataCy={`${BASE_PATH}_${URL_PATH}`}
            additionalClassName="gap-1"
          >
            <URLButton
              dataCy={`${BASE_PATH}_${URL_PATH}`}
              url={url}
              additionalClassName="text-base font-normal"
              disabled={isDeleting}
            />
          </Label>
        )}
        {videoUrl && (
          <Label
            text={translate(VIDEO_URL)}
            dataCy={`${BASE_PATH}_${VIDEO_URL}`}
            additionalClassName="gap-1"
          >
            <URLButton
              dataCy={`${BASE_PATH}_${URL_PATH}`}
              url={videoUrl}
              additionalClassName="text-base font-normal"
              disabled={isDeleting}
            />
          </Label>
        )}
      </div>
      <Label dataCy={`${BASE_PATH}_${TECH_STACK}`} text={translate(TECH_STACK)}>
        <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
          {techStack?.map((tech: DictionaryEntryModel) => (
            <Tag name={tech?.name} dataCy={tech?.id} key={tech?.id} />
          ))}
        </div>
      </Label>
    </div>
  );
};

export default TalksCard;
