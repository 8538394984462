import { ReactElement, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { useParams } from "react-router-dom";
import { useMappedStateSelector } from "../../../hooks";
import { fetchProjectsList } from "../../../project-domain";
import { ProjectsListWrapper } from "../../../project-domain/ui/common";
import { ProjectSummaryMapState, ProjectSummaryMapStateReturn } from "../types";

const ClientsProjectsList = (): ReactElement => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    fetchProjectsList(dispatch, params.id);
  }, [params.id]);

  const mapState = (
    state: ProjectSummaryMapState,
  ): ProjectSummaryMapStateReturn => ({
    activeProjects: state?.projects?.projectsList || [],
  });

  const { activeProjects } = useMappedStateSelector(mapState);

  return (
    <div className="flex flex-col gap-8 h-full overflow-hidden">
      <ProjectsListWrapper
        projectsList={activeProjects}
        clientId={params?.id}
      />
    </div>
  );
};

export default ClientsProjectsList;
