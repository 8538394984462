import { ReactElement } from "react";
import { Button } from "@ps/ui-components";
import { HOLIDAY } from "../../shared/data-cy";
import { ReactComponent as AddHolidayIcon } from "../../images/timesheet/timelog/holiday.svg";
import { useTranslationWithNamespace } from "../../hooks";
import { AddHolidayButtonProps } from "./types";

export const AddHolidayButton = ({
  onClick,
}: AddHolidayButtonProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Button dataCy={`${HOLIDAY}_add`} onClick={onClick}>
      <AddHolidayIcon className="mr-3" />
      {t("holidays.add")}
    </Button>
  );
};

export default AddHolidayButton;
