import { classJoin } from "@ps/utils";
import { focusVisibleStyles } from "../../shared/styles";

export const getTabStatusStyles = (
  isActive: boolean,
  isHighContrast = false,
): string => {
  if (isHighContrast)
    return classJoin(
      focusVisibleStyles,
      isActive
        ? "bg-primary-95 text-primary-30"
        : "text-primary-95 border border-transparent hover:border-primary-95",
    );
  return classJoin(
    focusVisibleStyles,
    isActive
      ? "bg-primary-51 border border-primary-60 ring-1 ring-primary-60 text-primary-100 shadow-inner"
      : "text-primary-60 hover:bg-primary-20",
  );
};
