import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from "react";
import { classJoin } from "@ps/utils";
import { motion } from "framer-motion";
import Tag from "../tag";
import { Tag as TagType, TagPickerProps } from "./types";
import { Keys } from "../../shared";
import {
  TAG_PICKER_INPUT_PREFIX,
  TAG_PICKER_PREFIX,
} from "../../shared/data-cy";
import { useTranslationWithNamespace } from "../../hooks";

const listAnimationState = {
  open: { height: "max-content", overflow: "visible" },
  closed: { height: 0, overflow: "hidden" },
};

const MAX_TAGS_TO_DISPLAY = 20;

const TagPicker = <TagIdType,>({
  tagsList = [],
  onTagSelected,
  displaySelectedText,
  maxTagsToPick,
  defaultSelected = [],
  dataCy,
  error,
  message,
}: TagPickerProps<TagIdType>): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [selectedTags, setSelectedTags] =
    useState<TagType<TagIdType>[]>(defaultSelected);
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const removeSelectedTag = (id: TagType<TagIdType>["id"]) =>
    setSelectedTags((selected) =>
      selected.filter(({ id: tagId }) => tagId !== id),
    );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value);

  const helpTagsToDisplay = tagsList
    .filter(
      ({ name }) =>
        name.toLowerCase().includes(searchValue.toLowerCase()) &&
        !selectedTags.find(({ name: selectedName }) => selectedName === name),
    )
    .slice(0, MAX_TAGS_TO_DISPLAY);

  const addTagToSelected = (tag: TagType<TagIdType>) => {
    if (helpTagsToDisplay.length <= 1)
      setTimeout(() => {
        setSearchValue("");
        inputRef.current?.focus();
      }, 500);
    setSelectedTags((selected) => {
      if (maxTagsToPick && selected.length > maxTagsToPick - 1) return selected;
      return [...selected, tag];
    });
  };

  const shouldDisplayHelp =
    searchValue.length > 0 && helpTagsToDisplay.length > 0;

  const autocompletion = shouldDisplayHelp
    ? helpTagsToDisplay.find(({ name }) =>
        name.toLowerCase().startsWith(searchValue.toLowerCase()),
      )?.name ?? ""
    : "";

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    onTagSelected?.(selectedTags);
  }, [selectedTags]);

  return (
    <div className="w-full" data-cy={`${TAG_PICKER_PREFIX}-${dataCy}`}>
      <div
        className={`flex flex-row flex-wrap pb-${
          displaySelectedText ? "0.5" : "2"
        }`}
      >
        {selectedTags.map(({ id, name }) => (
          <motion.div layout layoutId={`${id}`} key={`${id}`}>
            <Tag
              name={name}
              dataCy={`${id}`}
              additionalClassName="mr-1 mb-1"
              onClick={() => removeSelectedTag(id)}
            />
          </motion.div>
        ))}
      </div>
      <motion.div
        className={classJoin(
          "border px-2 py-0.5 bg-neutralPrimary-100 rounded-md",
          error ? "border-error-50" : "border-neutralSecondary-60",
        )}
        layout
      >
        <motion.div className="relative" layout>
          <input
            className="h-8.5 w-full bg-neutralPrimary-100 text-neutralPrimary-20 placeholder-neutralPrimary-30 p-2"
            placeholder={`${searchValue}${autocompletion?.slice(
              searchValue.length,
            )}`}
            readOnly
            onFocus={() => inputRef?.current?.focus()}
          />
          <input
            className="bg-transparent absolute text-neutralPrimary-20 p-2 top-0 left-0 h-8.5 w-full focus:outline-none"
            value={searchValue}
            onChange={handleInputChange}
            onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
              if (event.key === Keys.TAB && autocompletion) {
                event.preventDefault();
                event.stopPropagation();
                if (autocompletion) setSearchValue(autocompletion);
              }
            }}
            ref={inputRef}
            data-cy={`${TAG_PICKER_INPUT_PREFIX}-${dataCy}`}
          />
        </motion.div>
        <motion.div
          initial={listAnimationState.closed}
          animate={
            shouldDisplayHelp
              ? listAnimationState.open
              : listAnimationState.closed
          }
          className="flex flex-row flex-wrap"
        >
          <motion.div
            className="h-0.5 w-full bg-neutralSecondary-60 mb-1 rounded-md"
            layout
          />
          {helpTagsToDisplay.map(({ id, name }) => (
            <motion.div layout layoutId={`${id}`} key={`${id}`}>
              <Tag
                name={name}
                dataCy={`${id}`}
                additionalClassName="mr-1 mb-1"
                onClick={() => addTagToSelected({ id, name })}
                disableDelete
              />
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
      {displaySelectedText && (
        <motion.div layout>
          <span className="text-neutralSecondary-41 font-normal text-sm pt-2">{`${t(
            "labels.selected",
          )}${
            maxTagsToPick ? ` ${selectedTags.length}/${maxTagsToPick}` : ""
          }`}</span>
        </motion.div>
      )}
      {error && !searchValue && (
        <span className="mt-1 text-xs text-error-50">{message ?? ""}</span>
      )}
    </div>
  );
};

export default TagPicker;
