import React, { ReactElement } from "react";
import { View, Text } from "@react-pdf/renderer";
import { useTranslationWithNamespace } from "../../../hooks";
import { RoleProps } from "../types";
import { SectionIcon } from "../components";
import { ROLE } from "../../../shared/constants";
import { sharedOutlineStyles } from "../sharedStyles/sharedStyles";

const Role = ({ roles, isBlind }: RoleProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <View style={sharedOutlineStyles.container}>
      <View style={sharedOutlineStyles.iconContainer}>
        <SectionIcon icon={ROLE} isBlind={isBlind} />
      </View>
      <View style={sharedOutlineStyles.textContainer}>
        <React.Fragment>
          <Text style={sharedOutlineStyles.label}>{t("resume.role")}</Text>
          {roles.map(
            (role: string): ReactElement => (
              <Text key={role} style={sharedOutlineStyles.text}>
                {role}
              </Text>
            ),
          )}
        </React.Fragment>
      </View>
    </View>
  );
};

export default Role;
