import { ReactElement, useEffect } from "react";
import { HolidaysHistory } from "@ps/hh";
import { useDispatch } from "redux-react-hook";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../hooks";
import { fetchHolidaysHistory } from "../../store/actions/myHolidaysActions";
import {
  MyHistoryEntriesContainerProps,
  MyHistoryEntriesContainerState,
  MyHistoryEntriesContainerStateReturn,
} from "./types";
import { ReactComponent as NoHolidaysIcon } from "../../images/holidays/no-holidays-image.svg";
import AddHolidayButton from "./addHolidayButton";

const MyHistoryEntriesContainer = ({
  onRemove,
  onAddHoliday,
}: MyHistoryEntriesContainerProps): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslationWithNamespace();

  const mapState = (
    state: MyHistoryEntriesContainerState,
  ): MyHistoryEntriesContainerStateReturn => ({
    userHolidaysHistory: state?.holidays?.holidaysHistory || [],
    userTemplateEntries: state.holidays.userHolidays || [],
  });

  const { userHolidaysHistory, userTemplateEntries } =
    useMappedStateSelector(mapState);

  useEffect(() => {
    fetchHolidaysHistory(dispatch);
  }, []);

  return userHolidaysHistory?.length ? (
    <>
      <h1 className="text-lg font-semibold pt-8">{t("holidays.history")}</h1>
      <HolidaysHistory
        history={userHolidaysHistory}
        onRemove={onRemove}
        templateEntries={userTemplateEntries}
      />
    </>
  ) : (
    <div className="flex items-center justify-center h-full gap-8 px-8 text-neutralPrimary-20">
      <NoHolidaysIcon />
      <div className="flex flex-col items-start pb-10 gap-3">
        <span className="text-xl font-bold">
          {t("holidays.noHolidaysHeader")}
        </span>
        <span className="text-lg pb-10">{t("holidays.noHolidaysContent")}</span>
        <AddHolidayButton onClick={onAddHoliday} />
      </div>
    </div>
  );
};
export default MyHistoryEntriesContainer;
