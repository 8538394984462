import { IFilters, PillData } from "../ui/common/types";

export const filtersToPills = (filters: IFilters): PillData[] => {
  const pills: PillData[] = [];

  for (const key in filters) {
    const pill = filters[key].map(({ name, id }: PillData) => ({
      type: key,
      id,
      name,
    }));
    pills.push(pill);
  }
  return pills.flat();
};
