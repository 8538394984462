import { dayJSToISO } from "@ps/utils";
import { HolidaysUserHistoryModel } from "../types";

// TODO holidays - add proper values and TS types, when API will be ready
export const holidaysUserEntry = (data: HolidaysUserHistoryModel): unknown => ({
  amountOfDays:
    typeof data.duration === "number" ? data.duration : data.duration?.value,
  firstDay: dayJSToISO(data.dateFrom || null),
  holidaysItemId:
    typeof data.typeId === "string" ? data.typeId : data.typeId?.id,
  holidaysName: data.typeName,
  holidaysTimeEntriesId: data.entryIds,
  lastDay: dayJSToISO(data.dateTo || null),
});
