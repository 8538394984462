import { ReactElement } from "react";
import ISOLanguages from "iso-639-1";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { useTranslationWithNamespace } from "../../../hooks";
import { SectionIcon } from "../components";
import { LanguagesProps } from "../types";
import { sharedOutlineStyles } from "../sharedStyles/sharedStyles";

const styles = StyleSheet.create({
  languageContainer: {
    marginTop: "16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  languagesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    marginLeft: "10px",
  },
  singleLanguageContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "6px",
    fontSize: "10px",
    width: "200px",
  },
  language: {
    color: "#2A2D33",
    fontFamily: "PoppinsBold",
    fontWeight: "semibold",
    marginBottom: "3px",
  },
  level: {
    color: "#2A2D33",
    fontWeight: "thin",
  },
});

const Languages = ({ languages, isBlind }: LanguagesProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <View style={styles.languageContainer}>
      <View style={sharedOutlineStyles.iconContainer}>
        <SectionIcon icon="languages" isBlind={isBlind} />
      </View>
      <View style={sharedOutlineStyles.textContainer}>
        <Text style={sharedOutlineStyles.text}>{t("resume.languages")}</Text>
      </View>
      <View style={styles.languagesContainer}>
        {languages.map(
          ({ language, level }): JSX.Element => (
            <View key={language} style={styles.singleLanguageContainer}>
              <Text style={styles.language}>
                {ISOLanguages.getName(language)}
              </Text>
              <Text style={styles.level}>
                {t(`resume.languagesLevels.${level}`)}
              </Text>
            </View>
          ),
        )}
      </View>
    </View>
  );
};

export default Languages;
