import { ReactElement } from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { DictionaryEntryModel } from "@ps/hh";
import { ContributionProps } from "../types";
import { Description, TechStackTagsList, TimeRange } from "../components";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontFamily: "Poppins",
  },
  leftSection: {
    width: "25%",
    marginTop: "20px",
  },
  rightSection: {
    width: "70%",
    marginTop: "20px",
  },
  title: {
    color: "#2A2D33",
    fontFamily: "PoppinsBold",
    fontSize: "12px",
    marginBottom: "10px",
  },
  company: {
    color: "#7788E3",
    fontFamily: "PoppinsBold",
    fontSize: "16px",
    marginBottom: "8x",
  },
  contributionName: {
    color: "#2A2D33",
    fontSize: "16px",
    marginBottom: "8x",
  },
  description: {
    color: "#2A2D33",
    fontSize: "12px",
    fontFamily: "Poppins",
  },
  chipsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "10px",
  },
  chip: {
    padding: "3px 8px",

    borderRadius: "6px",
    fontSize: "8px",
    marginBottom: "5px",
    marginRight: "5px",
  },
  defaultChip: {
    backgroundColor: "rgba(162, 173, 226, 0.3)",
    border: "1px solid #5066D9",
    color: "#5066D9",
  },
  blindChip: {
    backgroundColor: "#FDE7E7",
    border: "1px solid #B0312D",
    color: "#B0312D",
  },
});

const Contribution = ({
  projectRoles,
  name,
  domain,
  description,
  startDate,
  endDate,
  techStack,
  domainDict,
  rolesDict,
  isBlind,
}: ContributionProps): ReactElement => {
  const domainName =
    domainDict?.find(
      (domainEntry: DictionaryEntryModel): boolean => domainEntry.id === domain,
    )?.name || domain;

  const roleNames = projectRoles?.map(
    (role: string) =>
      rolesDict?.find(
        (roleEntry: DictionaryEntryModel): boolean => role === roleEntry.id,
      )?.name || role,
  );

  const properChipColors = isBlind ? styles.blindChip : styles.defaultChip;

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.leftSection}>
          {projectRoles && projectRoles?.length ? (
            <>
              <View style={styles.chipsContainer}>
                {(roleNames || projectRoles).map(
                  (role: string): JSX.Element => (
                    <Text
                      key={role}
                      style={{ ...styles.chip, ...properChipColors }}
                    >
                      {role}
                    </Text>
                  ),
                )}
              </View>
              {name ? <Text style={styles.company}>{name}</Text> : <></>}
              {domain ? <Text style={styles.title}>{domainName}</Text> : <></>}
            </>
          ) : (
            <Text style={styles.contributionName}>{name}</Text>
          )}
          <TimeRange times={[startDate, endDate]} isBlind={isBlind} />
        </View>
        <View style={styles.rightSection}>
          <View>
            <Description description={description} />
          </View>
          <TechStackTagsList stacks={techStack} isBlind={isBlind} />
        </View>
      </View>
    </View>
  );
};

export default Contribution;
