import {
  CandidateMLAPIService,
  ClientAPIService,
  DictionariesAPIService,
  FilesAPIService,
  FolderAPIService,
  ProfileAPIService,
  ProjectAPIService,
  ProspectAPIService,
  SkillsAPIService,
  TeamAPIService,
  UserAPIService,
  BenchAPIService,
  HolidaysTemplatesAPIService,
  FinanceAPIService,
} from "@ps/hh";

import { TimesheetAPIService } from "@ps/time-tracker";
import TenantAPIService from "../../tenant-domain/api/tenant-api";

const setAPIToken = (token: string): void => {
  CandidateMLAPIService.setConfig(token);
  ClientAPIService.setConfig(token);
  DictionariesAPIService.setConfig(token);
  FilesAPIService.setConfig(token);
  FolderAPIService.setConfig(token);
  ProfileAPIService.setConfig(token);
  ProjectAPIService.setConfig(token);
  ProspectAPIService.setConfig(token);
  SkillsAPIService.setConfig(token);
  TeamAPIService.setConfig(token);
  TimesheetAPIService.setConfig(token);
  UserAPIService.setConfig(token);
  BenchAPIService.setConfig(token);
  HolidaysTemplatesAPIService.setConfig(token);
  FinanceAPIService.setConfig(token);
  TenantAPIService.setConfig(token);
};

export default setAPIToken;
