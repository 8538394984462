import { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { Label, SearchSelect } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../hooks";
import {
  UpdateUserHolidayAPIModel,
  HolidaysUserDetailsModel,
} from "../../../models";
import FinanceService from "../../../services/finance";
import { fetchHolidaysUserDetails } from "../../../store";
import { mapHolidaysToModification } from "../../../utils";
import {
  BASE_TRANSLATION_PATH,
  HOLIDAYS_USER,
  HOLIDAYS_TEMPLATE,
  HOLIDAYS,
} from "../constants";
import { FilledTemplateProps } from "./types";
import SaveModal from "./saveModal";
import ModificationTable from "./modificationTable";
import HolidayHistoryContainer from "./holiday-history";

const FilledTemplate = ({
  user,
  userId,
}: FilledTemplateProps): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslationWithNamespace();
  const history = useHistory();
  const methods = useForm({ defaultValues: user });
  const [isModalOpened, setIsModalOpened] = useState(false);

  const holidaysArray = useFieldArray({
    control: methods.control,
    name: HOLIDAYS,
  });

  const handleSubmit = async (
    data: HolidaysUserDetailsModel,
  ): Promise<void> => {
    const oldHolidays = mapHolidaysToModification(user.holidays);
    const newHolidays = mapHolidaysToModification(data.holidays);
    const updatedHolidays = newHolidays.filter(
      (holiday: UpdateUserHolidayAPIModel): boolean =>
        !oldHolidays.some(
          (oldHoliday: UpdateUserHolidayAPIModel): boolean =>
            oldHoliday.id === holiday.id &&
            oldHoliday.modification === holiday.modification,
        ),
    );
    await FinanceService.updateUserHolidays(user.id, updatedHolidays);
    fetchHolidaysUserDetails(dispatch, user.id);
    setIsModalOpened(false);
  };

  const handleOnClickSave = async () => {
    await methods.trigger(HOLIDAYS);
    if (!Object.entries(methods.formState.errors).length) {
      setIsModalOpened(true);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form className="flex flex-col gap-10">
          <SaveModal
            isOpen={isModalOpened}
            onBack={() => history.push("/finance/holidays")}
            onClose={() => setIsModalOpened(false)}
            onSave={methods.handleSubmit(handleSubmit)}
          />
          <div className="flex items-center gap-16">
            <Label
              dataCy={`${HOLIDAYS_USER}_${HOLIDAYS_TEMPLATE}`}
              additionalClassName="w-max"
            >
              {t(`${BASE_TRANSLATION_PATH}.${HOLIDAYS_TEMPLATE}`)}
            </Label>
            <SearchSelect
              dataCy={`${HOLIDAYS_USER}_${HOLIDAYS_TEMPLATE}`}
              options={[]}
              width="w-80"
              disabled
              defaultValue={{
                value: user.holidaysTemplate?.templateId || "",
                label: user.holidaysTemplate?.templateName || "",
              }}
            />
          </div>

          <ModificationTable
            holidaysArray={holidaysArray.fields}
            onSaveClick={handleOnClickSave}
          />
          <HolidayHistoryContainer
            userId={userId}
            userTemplateId={user?.holidaysTemplate?.templateId || ""}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default FilledTemplate;
