import { ReactElement } from "react";
import { InfoTooltip, Label, SearchSelect } from "@ps/ui-components";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment-timezone";
import { useTranslationWithNamespace } from "../../../../hooks";
import { MAIN_PROFILE_EDIT_MODE } from "../../../../shared/data-cy";
import {
  COMFORTABLE,
  COMFORTABLE_HOURS,
  POSSIBLE,
  POSSIBLE_HOURS,
  WORK,
  WORKING_HOURS,
  WORKING_HOURS_ZONE,
  ZONE,
} from "../constants";
import TimeRangeComponent from "../../../../shared/components/timeRangeComponent";

const BASE_PATH = `${MAIN_PROFILE_EDIT_MODE}_${WORK}`;
const BASE_DICT_PATH = "profile.overview";
const START = "start";
const END = "end";

const Work = (): ReactElement => {
  const { control } = useFormContext();

  const { t } = useTranslationWithNamespace();
  const timezones = moment.tz.names();

  const replaceDashesWithSpaces = (value: string): string =>
    value.replace(/_/g, " ");

  return (
    <div className="flex flex-wrap gap-6 w-1/2">
      <Label
        dataCy={`${BASE_PATH}-${COMFORTABLE_HOURS}_${START}`}
        text={
          <div className="flex items-center gap-2">
            <span>{`${t(
              `${BASE_DICT_PATH}.${WORK}.${COMFORTABLE_HOURS}`,
            )}*`}</span>
            <InfoTooltip
              tooltipContent={t(
                `${BASE_DICT_PATH}.${WORK}.${COMFORTABLE_HOURS}Tooltip`,
              )}
              dataCy={`${BASE_PATH}-${COMFORTABLE_HOURS}_${START}`}
            />
          </div>
        }
      >
        <div className="flex gap-2">
          <TimeRangeComponent
            name={WORKING_HOURS}
            timeMoment={START}
            type={COMFORTABLE}
            autoFocus
          />
          <span>-</span>
          <TimeRangeComponent
            name={WORKING_HOURS}
            timeMoment={END}
            type={COMFORTABLE}
          />
        </div>
      </Label>
      <Label
        dataCy={`${BASE_PATH}-${POSSIBLE_HOURS}`}
        text={
          <div className="flex items-center gap-2">
            <span>{`${t(
              `${BASE_DICT_PATH}.${WORK}.${POSSIBLE_HOURS}`,
            )}*`}</span>
            <InfoTooltip
              tooltipContent={t(
                `${BASE_DICT_PATH}.${WORK}.${POSSIBLE_HOURS}Tooltip`,
              )}
              dataCy={`${BASE_PATH}-${POSSIBLE_HOURS}`}
            />
          </div>
        }
      >
        <div className="flex gap-2">
          <TimeRangeComponent
            name={WORKING_HOURS}
            timeMoment={START}
            type={POSSIBLE}
          />
          <span>-</span>
          <TimeRangeComponent
            name={WORKING_HOURS}
            timeMoment={END}
            type={POSSIBLE}
          />
        </div>
      </Label>
      <Label
        dataCy={`${BASE_PATH}-${WORKING_HOURS_ZONE}`}
        text={`${t(`${BASE_DICT_PATH}.${WORK}.${ZONE}`)}*`}
        additionalClassName="w-full"
      >
        <Controller
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SearchSelect
              width="w-full"
              dataCy={`${BASE_PATH}_${WORKING_HOURS_ZONE}`}
              onChange={onChange}
              value={
                value
                  ? {
                      value: value.value,
                      label: replaceDashesWithSpaces(value.label),
                    }
                  : undefined
              }
              message={error?.message || ""}
              error={!!error}
              clearable
              searchable
              options={timezones.map((singleZone) => ({
                label: `${moment
                  .tz(singleZone)
                  .format("Z z")} (${replaceDashesWithSpaces(singleZone)})`,
                value: singleZone,
              }))}
              placeholder={t(`${BASE_DICT_PATH}.${WORK}.zonePlaceholder`)}
            />
          )}
          name={`${WORKING_HOURS}.${ZONE}`}
          control={control}
          rules={{
            validate: (value) =>
              !value ? `${t("profile.overview.errors.cantBeEmpty")}` : true,
          }}
        />
      </Label>
    </div>
  );
};

export default Work;
