/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help File API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BatchFilesUpload
 */
export interface BatchFilesUpload {
    /**
     * 
     * @type {Array<CvFile>}
     * @memberof BatchFilesUpload
     */
    'cvs': Array<CvFile>;
}
/**
 * 
 * @export
 * @interface CvFile
 */
export interface CvFile {
    /**
     * id generated by the calling side to connect data
     * @type {string}
     * @memberof CvFile
     */
    'fileId': string;
    /**
     * size of the file
     * @type {number}
     * @memberof CvFile
     */
    'size': number;
    /**
     * hex sha256 checksum for the file
     * @type {string}
     * @memberof CvFile
     */
    'sha256': string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FileType = {
    Icon: 'icon',
    Avatar: 'avatar',
    Media: 'media'
} as const;

export type FileType = typeof FileType[keyof typeof FileType];


/**
 * 
 * @export
 * @interface Header
 */
export interface Header {
    /**
     * 
     * @type {string}
     * @memberof Header
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Header
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface PresignedRequest
 */
export interface PresignedRequest {
    /**
     * 
     * @type {string}
     * @memberof PresignedRequest
     */
    'url': string;
    /**
     * 
     * @type {Array<Header>}
     * @memberof PresignedRequest
     */
    'requiredHeaders': Array<Header>;
}
/**
 * 
 * @export
 * @interface UploadCvMetadata
 */
export interface UploadCvMetadata {
    /**
     * 
     * @type {PresignedRequest}
     * @memberof UploadCvMetadata
     */
    'presignedRequest': PresignedRequest;
    /**
     * 
     * @type {string}
     * @memberof UploadCvMetadata
     */
    'batchId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadCvMetadata
     */
    'fileId': string;
}
/**
 * 
 * @export
 * @interface UploadFile
 */
export interface UploadFile {
    /**
     * 
     * @type {string}
     * @memberof UploadFile
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UploadMetadata
 */
export interface UploadMetadata {
    /**
     * 
     * @type {PresignedRequest}
     * @memberof UploadMetadata
     */
    'presignedRequest': PresignedRequest;
    /**
     * 
     * @type {string}
     * @memberof UploadMetadata
     */
    'contentUrl': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a presigned S3 PUT URL, and a GET URL to retrieve the content. Apart from supplying the PUT URL with file data, the request should be provided with headers given in the `requiredHeaders` field.
         * @param {BatchFilesUpload} batchFilesUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCvUploadMetadata: async (batchFilesUpload: BatchFilesUpload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchFilesUpload' is not null or undefined
            assertParamExists('getCvUploadMetadata', 'batchFilesUpload', batchFilesUpload)
            const localVarPath = `/file/cv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchFilesUpload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a presigned S3 PUT URL, and a GET URL to retrieve the content. Apart from supplying the PUT URL with file data, the request should be provided with headers given in the `requiredHeaders` field.
         * @param {FileType} fileType 
         * @param {UploadFile} uploadFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadMetadata: async (fileType: FileType, uploadFile: UploadFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileType' is not null or undefined
            assertParamExists('getUploadMetadata', 'fileType', fileType)
            // verify required parameter 'uploadFile' is not null or undefined
            assertParamExists('getUploadMetadata', 'uploadFile', uploadFile)
            const localVarPath = `/file/{fileType}`
                .replace(`{${"fileType"}}`, encodeURIComponent(String(fileType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a presigned S3 PUT URL, and a GET URL to retrieve the content. Apart from supplying the PUT URL with file data, the request should be provided with headers given in the `requiredHeaders` field.
         * @param {BatchFilesUpload} batchFilesUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCvUploadMetadata(batchFilesUpload: BatchFilesUpload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UploadCvMetadata>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCvUploadMetadata(batchFilesUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a presigned S3 PUT URL, and a GET URL to retrieve the content. Apart from supplying the PUT URL with file data, the request should be provided with headers given in the `requiredHeaders` field.
         * @param {FileType} fileType 
         * @param {UploadFile} uploadFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadMetadata(fileType: FileType, uploadFile: UploadFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadMetadata>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadMetadata(fileType, uploadFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Returns a presigned S3 PUT URL, and a GET URL to retrieve the content. Apart from supplying the PUT URL with file data, the request should be provided with headers given in the `requiredHeaders` field.
         * @param {BatchFilesUpload} batchFilesUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCvUploadMetadata(batchFilesUpload: BatchFilesUpload, options?: any): AxiosPromise<Array<UploadCvMetadata>> {
            return localVarFp.getCvUploadMetadata(batchFilesUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a presigned S3 PUT URL, and a GET URL to retrieve the content. Apart from supplying the PUT URL with file data, the request should be provided with headers given in the `requiredHeaders` field.
         * @param {FileType} fileType 
         * @param {UploadFile} uploadFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadMetadata(fileType: FileType, uploadFile: UploadFile, options?: any): AxiosPromise<UploadMetadata> {
            return localVarFp.getUploadMetadata(fileType, uploadFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Returns a presigned S3 PUT URL, and a GET URL to retrieve the content. Apart from supplying the PUT URL with file data, the request should be provided with headers given in the `requiredHeaders` field.
     * @param {BatchFilesUpload} batchFilesUpload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCvUploadMetadata(batchFilesUpload: BatchFilesUpload, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCvUploadMetadata(batchFilesUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a presigned S3 PUT URL, and a GET URL to retrieve the content. Apart from supplying the PUT URL with file data, the request should be provided with headers given in the `requiredHeaders` field.
     * @param {FileType} fileType 
     * @param {UploadFile} uploadFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUploadMetadata(fileType: FileType, uploadFile: UploadFile, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUploadMetadata(fileType, uploadFile, options).then((request) => request(this.axios, this.basePath));
    }
}


