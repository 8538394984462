import { ReactElement, KeyboardEvent } from "react";
import { Chip, Label, TableRow, Empty } from "@ps/ui-components";
import { classJoin, getNameFromDictionary, setChipColors } from "@ps/utils";
import styles from "./styles.module.css";
import { TEAM_LIST } from "../../../shared/data-cy";
import { useMappedStateSelector } from "../../../hooks";
import { ReactComponent as LocationIcon } from "../../../images/location.svg";
import { RowProps, RowState, RowStateReturn, RowWrapperProps } from "./types";
import { NAME, PROFESSION, SENIORITY, STATUS } from "../../constants";
import { LOCATION } from "../../../prospect-domain/constants";
import { ReactComponent as AddUserIcon } from "../../../images/add-new.svg";
import { ReactComponent as RemoveUserIcon } from "../../../images/remove.svg";
import { focusVisibleStyles, Keys } from "../../../shared";

const postponedIconStyles =
  "fill-current cursor-pointer hover:opacity-50 mt-1 rounded-md";

const Wrapper = ({ children, fieldName }: RowWrapperProps): ReactElement => (
  <Label dataCy={`${TEAM_LIST}_${fieldName}`}>{children}</Label>
);

const Row = ({
  data,
  handleOnPostponeRemoveClick,
  handleOnPostponeAddClick,
  handleOnProfileDetailsOpen,
  isPostponed,
}: RowProps): ReactElement => {
  const mapState = (state: RowState): RowStateReturn => ({
    professionDict: state?.dictionaries?.profession || [],
    seniorityDict: state?.dictionaries?.seniority || [],
    operativeProfessionsDict: state?.dictionaries.operative_profession || [],
  });

  const { professionDict, seniorityDict, operativeProfessionsDict } =
    useMappedStateSelector(mapState);
  const isSpecialist = data?.profile === "specialist";

  const renderPostponedIcon = () =>
    isPostponed ? (
      <RemoveUserIcon
        className={classJoin(
          postponedIconStyles,
          focusVisibleStyles,
          "w-4 h-4 text-neutralPrimary-85",
        )}
        onClick={() =>
          isSpecialist &&
          handleOnPostponeRemoveClick &&
          handleOnPostponeRemoveClick(data)
        }
        role="button"
        tabIndex={0}
        onKeyDown={(event: KeyboardEvent<SVGSVGElement>) => {
          if (
            event.key === Keys.ENTER &&
            isSpecialist &&
            handleOnPostponeRemoveClick
          )
            handleOnPostponeRemoveClick(data);
        }}
      />
    ) : (
      <AddUserIcon
        className={classJoin(
          postponedIconStyles,
          focusVisibleStyles,
          "text-primary-50",
        )}
        onClick={() =>
          isSpecialist &&
          handleOnPostponeAddClick &&
          handleOnPostponeAddClick(data)
        }
        role="button"
        tabIndex={0}
        onKeyDown={(event: KeyboardEvent<SVGSVGElement>) => {
          if (
            event.key === Keys.ENTER &&
            isSpecialist &&
            handleOnPostponeAddClick
          )
            handleOnPostponeAddClick(data);
        }}
      />
    );

  return (
    <TableRow columnsStyles={styles.tableColumns} dataCy={TEAM_LIST}>
      <Wrapper fieldName={STATUS}>
        <Chip
          borderWidth="border-0"
          dataCy={`${TEAM_LIST}_${STATUS}`}
          fontSize="font-semibold"
          label={data?.[STATUS]}
          tailwindColors={setChipColors(data?.[STATUS])}
          additionalClassName={classJoin(" text-sm p-1")}
        />
      </Wrapper>
      <Wrapper fieldName={NAME}>
        <div className="grid items-center gap-1 w-max grid-flow-col">
          <span
            className={classJoin(
              isSpecialist &&
                classJoin.template`text-primary-50 cursor-pointer
                hover:opacity-50 hover:underline w-auto rounded-md`,
              focusVisibleStyles,
            )}
            onClick={() =>
              isSpecialist &&
              handleOnProfileDetailsOpen &&
              handleOnProfileDetailsOpen(data.userId)
            }
            role={
              isSpecialist && handleOnProfileDetailsOpen
                ? "button"
                : "presentation"
            }
            tabIndex={isSpecialist && handleOnProfileDetailsOpen ? 0 : -1}
            onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
              if (
                event.key === Keys.ENTER &&
                isSpecialist &&
                handleOnProfileDetailsOpen
              )
                handleOnProfileDetailsOpen(data.userId);
            }}
          >
            {data?.[NAME]}
          </span>
          {isSpecialist && renderPostponedIcon()}
        </div>
      </Wrapper>
      <Wrapper fieldName={PROFESSION}>
        {data?.[PROFESSION] ? (
          <Chip
            additionalClassName="text-sm p-1 capitalize border"
            dataCy={`${TEAM_LIST}_${PROFESSION}`}
            fontSize="font-semibold"
            label={getNameFromDictionary(
              [...professionDict, ...operativeProfessionsDict],
              data?.[PROFESSION],
            )}
            tailwindColors={setChipColors(
              getNameFromDictionary(
                [...professionDict, ...operativeProfessionsDict],
                data?.[PROFESSION],
              ),
            )}
          />
        ) : (
          <Empty />
        )}
      </Wrapper>
      <Wrapper fieldName={SENIORITY}>
        {data?.[SENIORITY] ? (
          <Chip
            additionalClassName="text-sm p-1 capitalize border"
            borderWidth="border-0"
            dataCy={`${TEAM_LIST}_${SENIORITY}`}
            fontSize="font-semibold"
            label={getNameFromDictionary(seniorityDict, data?.[SENIORITY])}
            tailwindColors={setChipColors(data?.[SENIORITY])}
          />
        ) : (
          <Empty />
        )}
      </Wrapper>
      <Wrapper fieldName={LOCATION}>
        {data?.location ? (
          <div className="flex items-center gap-1 mt-1">
            <LocationIcon className="fill-current text-primary-51" />
            <span className="text-sm">{data?.location}</span>
          </div>
        ) : (
          <Empty />
        )}
      </Wrapper>
      {/* TODO temporary hidden */}
      {/* <LinkButton
        additionalClassName="justify-end"
        dataCy={`${TEAM_LIST}_more`}
        disabled
        onClick={() =>
          isOpen ? handleOnClose(data?.userId) : handleOnOpen(data.userId)
        }
        suffixIcon={
          <MoreArrowIcon
            className={classJoin(
              "text-primary-51 mt-1",
              isOpen ? "transform rotate-180" : "",
            )}
          />
        }
      >
        {t("team.usersList.more")}
      </LinkButton> */}
      {/* TODO */}
      {/* {isOpen ? ( */}
      {/*  <span className="pt-10 w-full details">More about project</span> */}
      {/* ) : ( */}
      {/*  <></> */}
      {/* )} */}
    </TableRow>
  );
};
export default Row;
