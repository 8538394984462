import { ReactElement } from "react";
import { getNameFromDictionary } from "@ps/utils";
import { Label, Tag } from "@ps/ui-components";
import { DATE_FORMAT } from "../../common/constants";
import {
  ProjectCardProps,
  ProjectCardMapState,
  ProjectCardMapStateReturn,
} from "./types";
import { EXPERIENCE_PROJECTS_VIEW } from "../../../../../shared/data-cy";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import {
  DESCRIPTION,
  DOMAIN,
  END_DATE,
  PROJECT_ROLES,
  RESPONSIBILITY,
  START_DATE,
  TECH_STACK,
  NAME,
} from "./constants";
import { DictionaryEntryModel } from "../../../../../dictionaries";

const ProjectCard = ({
  name,
  description,
  domain,
  endDate,
  projectRoles,
  responsibility,
  startDate,
  techStack,
}: ProjectCardProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const translate = (field: string): string =>
    t(`profile.experience.cards.project.${field}`);

  const mapState = (state: ProjectCardMapState): ProjectCardMapStateReturn => ({
    projectRolesDict: state.dictionaries.project_role || [],
    domainDict: state.dictionaries.domain || [],
  });
  const { projectRolesDict, domainDict } = useMappedStateSelector(mapState);

  return (
    <div className="relative w-full p-6">
      {name ? (
        <div className="w-full flex flex-row justify-between mb-7">
          <Label
            dataCy={`${EXPERIENCE_PROJECTS_VIEW}_${NAME}`}
            text={translate(NAME)}
          >
            {name}
          </Label>
        </div>
      ) : (
        <></>
      )}
      <div className="w-full flex flex-row justify-between mb-7">
        <Label
          dataCy={`${EXPERIENCE_PROJECTS_VIEW}_${DOMAIN}`}
          text={translate(DOMAIN)}
        >
          {getNameFromDictionary(domainDict, domain)}
        </Label>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-row mr-16">
          <Label
            dataCy={`${EXPERIENCE_PROJECTS_VIEW}_${START_DATE}`}
            text={translate(START_DATE)}
            additionalClassName="mr-10"
          >
            {startDate?.format(DATE_FORMAT)}
          </Label>
          <Label
            dataCy={`${EXPERIENCE_PROJECTS_VIEW}_${END_DATE}`}
            text={translate(END_DATE)}
          >
            {endDate
              ? endDate?.format(DATE_FORMAT)
              : `${t("profile.other.present")}`}
          </Label>
        </div>
        <div className="w-full h-full flex flex-col whitespace-pre-wrap">
          <Label
            dataCy={`${EXPERIENCE_PROJECTS_VIEW}_${DESCRIPTION}`}
            text={translate(DESCRIPTION)}
            additionalClassName="mb-10"
            fontWeight="font-normal"
          >
            {description}
          </Label>
          <Label
            dataCy={`${EXPERIENCE_PROJECTS_VIEW}_${RESPONSIBILITY}`}
            text={translate(RESPONSIBILITY)}
            additionalClassName="mb-10"
            fontWeight="font-normal"
          >
            {responsibility}
          </Label>
          <div className="flex flex-row gap-x-5">
            <Label
              dataCy={`${EXPERIENCE_PROJECTS_VIEW}_${PROJECT_ROLES}`}
              text={translate(PROJECT_ROLES)}
            >
              <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
                {projectRoles?.map((id) =>
                  projectRolesDict?.map((item) =>
                    item.id === id ? (
                      <Tag
                        dataCy={`${EXPERIENCE_PROJECTS_VIEW}_${PROJECT_ROLES}_${id.replace(
                          / /g,
                          "",
                        )}`}
                        key={item.id}
                        name={item.name}
                        secondary
                        additionalClassName="capitalize"
                      />
                    ) : (
                      <></>
                    ),
                  ),
                )}
              </div>
            </Label>
            <Label
              dataCy={`${EXPERIENCE_PROJECTS_VIEW}_${TECH_STACK}`}
              text={translate(TECH_STACK)}
            >
              <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
                {techStack?.map((tech: DictionaryEntryModel) => (
                  <Tag name={tech?.name} dataCy={tech?.id} key={tech?.id} />
                ))}
              </div>
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
