import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { classJoin } from "@ps/utils";
import { Page403Props } from "./types";
import { ReactComponent as Icons403 } from "../../images/page-403-icon.svg";
import { PAGE_403_PREFIX } from "../../shared/data-cy";
import { useTranslationWithNamespace } from "../../hooks";
import Button from "../button";
import PageBackground from "../page-background";
import styles from "./styles.module.css";

const Page403 = ({ dataCy, path }: Page403Props): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const history = useHistory();

  return (
    <div
      data-cy={`${PAGE_403_PREFIX}-${dataCy}`}
      className="h-screen w-screen absolute inset-0 overflow-hidden"
    >
      <PageBackground />
      <Icons403
        className={classJoin("absolute top-36 right-52", styles.zindex)}
      />
      <div
        className={classJoin(
          "absolute flex flex-col top-1/3 left-48 gap-y-5 w-96",
          styles.zindex,
        )}
      >
        <span className="text-9xl text-white font-black">403</span>
        <span className="text-5xl text-white font-semibold">
          {t("pages.forbidden").toUpperCase()}
        </span>
        <span className="text-xl text-white">{t("pages.sorry")}</span>
        <Button
          dataCy={dataCy}
          paddingX="px-16"
          additionalClass="py-8 text-lg mt-12"
          onClick={() => history.push(path)}
        >
          {t("pages.home")}
        </Button>
      </div>
    </div>
  );
};

export default Page403;
