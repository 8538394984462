import { ISOToDayJs } from "@ps/utils";
import {
  UserFilterAPIModel,
  UserFilterModel,
  ProjectFilterAPIModel,
  ProjectFilterModel,
  ClientFilterAPIModel,
  ClientFilterModel,
  ReportFiltersAPIModel,
  ReportFiltersModel,
  ReportAPIModel,
  ReportModel,
  ReportsModel,
  ReportEntryAPIModel,
  ReportEntryModel,
} from "../reportsTypes";

export const reportFiltersFromAPIModel = (
  filters: ReportFiltersAPIModel,
): ReportFiltersModel => ({
  users: filters?.users?.map(
    (user: UserFilterAPIModel): UserFilterModel => ({
      userId: user.userId,
      name: user.firstName || "",
      lastName: user.lastName || "",
    }),
  ),
  projects: filters?.projects?.map(
    (project: ProjectFilterAPIModel): ProjectFilterModel => ({
      projectId: project.projectId,
      projectName: project.name,
    }),
  ),
  clients: filters?.clients?.map(
    (client: ClientFilterAPIModel): ClientFilterModel => ({
      clientId: client.clientId || "",
      clientName: client.name || "",
    }),
  ),
});

export const reportFromAPIModel = (
  reportData: ReportAPIModel[],
): ReportsModel =>
  reportData?.map(
    (reportEntry: ReportAPIModel): ReportModel => ({
      projectId: reportEntry.projectId,
      projectName: reportEntry.projectName,
      projectColor: reportEntry.projectColor,
      clientId: reportEntry?.clientId || "",
      clientName: reportEntry?.clientName || "",
      userId: reportEntry.userId,
      userFirstName: reportEntry?.userFirstName || "",
      userLastName: reportEntry?.userLastName || "",
      entries: reportEntry.entries.map(
        (entry: ReportEntryAPIModel): ReportEntryModel => ({
          id: entry.id,
          date: ISOToDayJs(entry.day),
          minutes: entry.minutes,
          rate: entry.rate,
          note: entry.note || "",
        }),
      ),
    }),
  );
