import { combineReducers, AnyAction } from "redux";
import { FulfillmentsModel, TemplatesModel } from "../models";
import { ActionTypes } from "./actions";

const fulfillmentsReducer = (
  state: FulfillmentsModel | Record<string, unknown> = {},
  action: AnyAction,
): FulfillmentsModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.SET_FULFILLMENT_START:
    case ActionTypes.SET_FULFILLMENT_ERROR:
      return state;
    case ActionTypes.SET_FULFILLMENT_SUCCESS:
      return { ...state, ...action.payload.fulfillment };
    default:
      return state;
  }
};

const templatesReducer = (
  state: TemplatesModel | Record<string, unknown> = {},
  action: AnyAction,
): TemplatesModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.SET_DOMAIN_TEMPLATE_SUCCESS:
      return { ...(state ?? {}), domains: action.payload };
    case ActionTypes.SET_PROFESSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        [action.payload.professionId]: action.payload.template,
      };
    case ActionTypes.SET_DOMAIN_TEMPLATE_START:
    case ActionTypes.SET_DOMAIN_TEMPLATE_ERROR:
    case ActionTypes.SET_PROFESSION_TEMPLATE_START:
    case ActionTypes.SET_PROFESSION_TEMPLATE_ERROR:
      return state;
    default:
      return state;
  }
};

export const isFetchingSkillsMatrix = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.SET_DOMAIN_TEMPLATE_START:
    case ActionTypes.SET_PROFESSION_TEMPLATE_START:
      return true;
    case ActionTypes.SET_DOMAIN_TEMPLATE_SUCCESS:
    case ActionTypes.SET_DOMAIN_TEMPLATE_ERROR:
    case ActionTypes.SET_PROFESSION_TEMPLATE_SUCCESS:
    case ActionTypes.SET_PROFESSION_TEMPLATE_ERROR:
      return false;
    default:
      return state;
  }
};

export const skillsMatrixReducer = combineReducers({
  templates: templatesReducer,
  fulfillments: fulfillmentsReducer,
});
