import { ReactElement, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "redux-react-hook";
import { setRedirection, clearRedirection } from "@ps/alert-modal";
import { useMappedStateSelector } from "../../../hooks";
import { toggleEditMode } from "../../../store";
import { HolidaysTemplatesModel, HolidaysItemsModel } from "../../models";
import {
  TEMPLATE,
  HOLIDAY,
  TEMP_HOLIDAY_ID,
  TEMP_TEMPLATE_ID,
  newHolidaysTemplate,
  newHoliday,
} from "../constants";
import { useHolidaysTemplatesContext } from "../context";
import HeaderBar from "./headerBar";
import TemplatesList from "./templates";
import HolidaysList from "./holidays";
import FooterSaveBar from "./footerSaveBar";
import EmptyTable from "./emptyTable";
import { TableMapState, TableMapStateReturn, FormValuesProps } from "./types";

const Table = (): ReactElement => {
  const dispatch = useDispatch();
  const methods = useForm<FormValuesProps>({ mode: "onChange" });
  const { currentTemplate, setCurrentTemplate } = useHolidaysTemplatesContext();

  const mapState = (state: TableMapState): TableMapStateReturn => ({
    editMode: state?.hh?.editMode,
    holidaysTemplates: state?.hh?.holidaysTemplates?.holidaysTemplates || [],
    isFormModified: state.isFormModified,
  });

  const { editMode, holidaysTemplates, isFormModified } =
    useMappedStateSelector(mapState, "");

  const [templates, setTemplates] =
    useState<HolidaysTemplatesModel>(holidaysTemplates);
  const [holidays, setHolidays] = useState<HolidaysItemsModel>(
    currentTemplate?.holidays || [],
  );

  useEffect(() => {
    setTemplates(holidaysTemplates);
  }, [holidaysTemplates]);

  useEffect(() => {
    setHolidays(currentTemplate?.holidays);
  }, [currentTemplate]);

  useEffect(() => {
    if (editMode) dispatch(setRedirection());
    else dispatch(clearRedirection());
  }, [editMode]);

  const handleOnAddNewTemplate = (): void => {
    setTemplates((prev: HolidaysTemplatesModel) => [
      ...(prev || []),
      newHolidaysTemplate,
    ]);
    dispatch(toggleEditMode(`${TEMPLATE}:${TEMP_TEMPLATE_ID}`));
    setCurrentTemplate(newHolidaysTemplate);
    methods.reset({ template: newHolidaysTemplate });
  };

  const handleOnAddNewHoliday = (): void => {
    setHolidays((prev: HolidaysItemsModel) => [...(prev || []), newHoliday]);
    methods.reset({ holiday: newHoliday });
    dispatch(toggleEditMode(`${HOLIDAY}:${TEMP_HOLIDAY_ID}`));
  };

  const handleCancelNewTemplate = (): void => {
    if (editMode?.split(":")?.[1] === TEMP_TEMPLATE_ID)
      setCurrentTemplate(holidaysTemplates?.[0]);
    setTemplates(holidaysTemplates);
    dispatch(toggleEditMode(""));
    methods.reset();
  };

  const handleCancelNewHoliday = (): void => {
    setHolidays(currentTemplate?.holidays || []);
    dispatch(toggleEditMode(""));
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form className="text-neutralPrimary-20 flex flex-col gap-4 pb-10 w-full h-full">
        {templates?.length ? (
          <>
            <HeaderBar
              onAddTemplate={handleOnAddNewTemplate}
              onAddHoliday={
                holidays?.length ? handleOnAddNewHoliday : undefined
              }
            />
            <div className="flex gap-10 h-full">
              <TemplatesList
                holidaysTemplates={templates}
                deleteNewTemplate={handleCancelNewTemplate}
              />
              <HolidaysList
                holidays={holidays || []}
                deleteNewHoliday={handleCancelNewHoliday}
                onAddHoliday={!editMode ? handleOnAddNewHoliday : undefined}
              />
            </div>
            {editMode && isFormModified ? (
              <FooterSaveBar
                handleCancelNewHoliday={handleCancelNewHoliday}
                handleCancelNewTemplate={handleCancelNewTemplate}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <EmptyTable onAddTemplate={handleOnAddNewTemplate} />
        )}
      </form>
    </FormProvider>
  );
};

export default Table;
