import { FunctionComponent, SVGProps, ReactElement } from "react";
import { classJoin } from "@ps/utils";

interface UploadStateProps {
  text: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  color: string;
  additionalIconClassName?: string;
}

const baseStyle = classJoin.template`
w-96 h-48 flex justify-center items-center
border bg-opacity-20
`;

const UploadState = ({
  text,
  icon: Icon,
  color,
  additionalIconClassName = "",
}: UploadStateProps): ReactElement => {
  return (
    <div className={classJoin(baseStyle, `border-${color} bg-${color}`)}>
      <div className="flex flex-col items-center w-full">
        <div className={classJoin("h-16 mb-3", additionalIconClassName)}>
          <Icon
            className={`fill-current text-${color}`}
            style={{ width: "inherit", height: "inherit" }}
          />
        </div>
        <span className="text-neutralPrimary-20">{text}</span>
      </div>
    </div>
  );
};

export default UploadState;
