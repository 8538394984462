import {
  isUserProjectType,
  ProjectSummary,
  ProjectSummaryModel,
  ProjectUserSummaryModel,
} from "@ps/hh";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { ProjectTimeEntriesModel } from "../../../../models";

dayjs.extend(utc);

const countTotalRow = (entries: ProjectTimeEntriesModel[]): number => {
  const onlyEntries = entries?.map((item) => item[1]);
  const flattenEntries = onlyEntries?.flat();
  const sumMinutes = flattenEntries?.reduce((acc, val) => acc + val.minutes, 0);
  return sumMinutes || 0;
};

export default countTotalRow;

export const isInputDisable = (
  date: Dayjs,
  project: ProjectSummaryModel | ProjectUserSummaryModel,
): boolean => {
  if (project?.archived) return true;
  const result = !!(date?.isAfter(dayjs().add(7, "day")) || project?.archived);

  if (isUserProjectType(project) && project?.membershipDetails) {
    return (
      result ||
      project.membershipDetails?.deleted ||
      date
        ?.utc(true)
        ?.isAfter(project?.membershipDetails?.statementOfWork?.endDate) ||
      date
        ?.utc(true)
        ?.isBefore(project?.membershipDetails?.statementOfWork?.startDate)
    );
  }
  return result;
};

export const getUnusedProjects = (
  projects: ProjectSummary[],
  addedProjectsIds: string[],
): ProjectSummary[] =>
  projects?.filter((item: ProjectSummary) => {
    const result =
      !addedProjectsIds.some((id: string) => id === item.id) && !item.archived;

    if (isUserProjectType(item) && item?.membershipDetails) {
      return result && !item?.membershipDetails?.deleted;
    }
    return result;
  });

const isWeekSplitBySOWBefore = (
  startDate: Dayjs,
  timeFrame: [Dayjs, Dayjs],
): boolean =>
  startDate
    ? startDate?.isAfter(timeFrame[0]) && startDate.isBefore(timeFrame[1])
    : false;

const isWeekSplitBySOWAfter = (
  endDate: Dayjs,
  timeFrame: [Dayjs, Dayjs],
): boolean =>
  endDate
    ? endDate?.isBefore(timeFrame[1]) && endDate.isAfter(timeFrame[0])
    : endDate;

export const renderTooltipText = (
  timeFrame: [Dayjs, Dayjs],
  t,
  chosenProject?: ProjectSummary,
): string => {
  const TIME_FORMAT = "DD.MM.YYYY";
  if (!chosenProject?.membershipDetails) return "";
  const { membershipDetails } = chosenProject;

  const {
    statementOfWork: { startDate, endDate },
  } = membershipDetails;

  const firstDateOfTimeRange = timeFrame[0];
  const lastDateOfTimeRange = timeFrame[1];

  const isBefore = isWeekSplitBySOWBefore(startDate, timeFrame);
  const isAfter = isWeekSplitBySOWAfter(endDate, timeFrame);
  if (
    (startDate.isBefore(firstDateOfTimeRange) &&
      startDate.isBefore(firstDateOfTimeRange) &&
      endDate.isAfter(lastDateOfTimeRange) &&
      endDate.isAfter(lastDateOfTimeRange)) ||
    (startDate.format(TIME_FORMAT) ===
      firstDateOfTimeRange.format(TIME_FORMAT) &&
      endDate.format(TIME_FORMAT) ===
        lastDateOfTimeRange.format(TIME_FORMAT)) ||
    (startDate.format(TIME_FORMAT) ===
      firstDateOfTimeRange.format(TIME_FORMAT) &&
      endDate.isAfter(lastDateOfTimeRange)) ||
    (endDate.format(TIME_FORMAT) === lastDateOfTimeRange.format(TIME_FORMAT) &&
      startDate.isBefore(firstDateOfTimeRange))
  )
    return "";

  // If SOW is inside time range and starts at first date of time range, eg. WEEK: 1-7.05, SOW 1-3.05
  if (
    isBefore &&
    isAfter &&
    startDate.format(TIME_FORMAT) === firstDateOfTimeRange.format(TIME_FORMAT)
  )
    return t("timesheet.timelog.afterSOW", {
      date: endDate.format(TIME_FORMAT),
    });

  // If SOW is inside time range and ends at last date of time range, eg. WEEK: 1-7.05, SOW 3-7.05
  if (
    isBefore &&
    isAfter &&
    endDate.format(TIME_FORMAT) === lastDateOfTimeRange.format(TIME_FORMAT)
  )
    return t("timesheet.timelog.afterSOW", {
      date: startDate.format(TIME_FORMAT),
    });

  // If SOW is inside time range, eg. WEEK: 1-7.05, SOW 4-5.05
  if (isBefore && isAfter)
    return t("timesheet.timelog.outOfSOWThisWeek", {
      start: startDate.format(TIME_FORMAT),
      end: endDate.format(TIME_FORMAT),
    });

  // If start date of the SOW is in time range, eg. eg. WEEK: 1-7.05, SOW 4.05-8.08
  if (isBefore)
    return t("timesheet.timelog.beforeSOW", {
      date: startDate.format(TIME_FORMAT),
    });

  // If end date of the SOW is in time range, eg. eg. WEEK: 1-7.05, SOW 4.04-3.05
  if (isAfter)
    return t("timesheet.timelog.afterSOW", {
      date: endDate.format(TIME_FORMAT),
    });

  // If end date of the SOW is before the range date. WEEK: 1-7.05, SOW 4.03-8.04 = SOW ends in the past
  if (endDate.isBefore(lastDateOfTimeRange))
    return t("timesheet.timelog.afterSOW", {
      date: endDate.format(TIME_FORMAT),
    });

  // If start date of the SOW is after the range date. WEEK: 1-7.05, SOW 4.06-8.07 = SOW starts in the future
  if (startDate.isAfter(firstDateOfTimeRange))
    return t("timesheet.timelog.beforeSOW", {
      date: startDate.format(TIME_FORMAT),
    });

  return "";
};
