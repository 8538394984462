import { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { clearRedirection, RedirectAlertModal } from "@ps/alert-modal";
import { MATRIX, SKILLS } from "../shared/routes";
import SkillsRoutes from "./skillsRoutes";
import { Expertise } from "../ui";
import MatrixRoutes from "./matrixRoutes";

const ExpertiseRoutes = (): ReactElement => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  return (
    <>
      <RedirectAlertModal
        dispatch={dispatch}
        clearRedirection={clearRedirection}
      />
      <Switch>
        <Route path={`${path}/${SKILLS}`}>
          <SkillsRoutes />
        </Route>
        <Route path={`${path}/${MATRIX}`}>
          <MatrixRoutes />
        </Route>
        <Route path={`${path}/:slug?`}>
          <Expertise />
        </Route>
      </Switch>
    </>
  );
};

export default ExpertiseRoutes;
