import { ReactElement } from "react";
import { CardHeader } from "../../../expanded-card";
import {
  PROFILE_SUMMARY_BASE_INFO,
  PROFILE_SUMMARY_TIMELINE,
} from "../../../../shared/data-cy";
import Label from "../../../label";
import { useTranslationWithNamespace } from "../../../../hooks";
import { TimelineTimeRangeProps } from "../../types";

const BASE_PATH = "profileSummary";

const TimeRange = ({
  hours,
  zone,
  name,
  Icon,
}: TimelineTimeRangeProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <CardHeader dataCy={`${PROFILE_SUMMARY_BASE_INFO}_experience`} Icon={Icon}>
      {hours && (
        <Label
          dataCy={`${PROFILE_SUMMARY_TIMELINE}_${name}`}
          text={t(`${BASE_PATH}.${name}`)}
        >
          <p className="whitespace-pre">{`${hours?.start}-${hours?.end} (${zone?.abbreviation})`}</p>
          <p>{zone?.value}</p>
        </Label>
      )}
    </CardHeader>
  );
};

export default TimeRange;
