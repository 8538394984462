enum ActionTypes {
  SET_DOMAIN_TEMPLATE_START = "SET_DOMAIN_TEMPLATE_START",
  SET_DOMAIN_TEMPLATE_SUCCESS = "SET_DOMAIN_TEMPLATE_SUCCESS",
  SET_DOMAIN_TEMPLATE_ERROR = "SET_DOMAIN_TEMPLATE_ERROR",

  SET_PROFESSION_TEMPLATE_START = "SET_PROFESSION_TEMPLATE_START",
  SET_PROFESSION_TEMPLATE_SUCCESS = "SET_PROFESSION_TEMPLATE_SUCCESS",
  SET_PROFESSION_TEMPLATE_ERROR = "SET_PROFESSION_TEMPLATE_ERROR",

  SET_FULFILLMENT_START = "SET_FULFILLMENT_START",
  SET_FULFILLMENT_SUCCESS = "SET_FULFILLMENT_SUCCESS",
  SET_FULFILLMENT_ERROR = "SET_FULFILLMENT_ERROR",
}

export default ActionTypes;
