import { KeyboardEvent, ReactElement } from "react";
import { RowProps } from "./types";
import Checkbox from "../checkbox";
import { Keys } from "../../shared";

const Row = ({
  label,
  amount,
  checked,
  id,
  onClick,
}: RowProps): ReactElement => (
  <div
    tabIndex={0}
    id={id}
    onClick={onClick}
    role="button"
    onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === Keys.ENTER) onClick(event);
    }}
    className="w-full flex justify-between items-center cursor-pointer select-none"
  >
    <span>{label}</span>

    <div className="flex gap-x-5">
      <span className="font-semibold">{amount}</span>
      <Checkbox dataCy={id} checked={checked} />
    </div>
  </div>
);

export default Row;
