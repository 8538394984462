import { Controller, useFormContext } from "react-hook-form";
import { ReactElement, ChangeEvent } from "react";
import { Input, Label } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../hooks";
import { ControlledInputProps } from "../types";

const ControlledInput = ({
  additionalClass,
  additionalValidation = {},
  basePath,
  controllerPath,
  isRequired,
  name,
  placeholder,
  translatePath,
  type = "text",
  min,
  max,
  step,
  isDetailsEdit,
}: ControlledInputProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { control } = useFormContext();
  const translate = (field: string) =>
    `${t(`${translatePath}.${field}`)}${isRequired ? "*" : ""}`;

  return (
    <Label
      dataCy={`${basePath}_${name}`}
      text={translate(name)}
      labelFontWeight="font-bold"
      labelFontSize={isDetailsEdit ? "text-lg" : "text-base"}
      labelColor={isDetailsEdit ? "text-neutralPrimary-20" : ""}
    >
      <Controller
        name={controllerPath || name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Input
            dataCy={`${basePath}_${name}`}
            defaultValue={value}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChange(event.target.value)
            }
            type={type}
            placeholder={(placeholder && translate(placeholder)) || ""}
            additionalClass={additionalClass}
            message={error?.message}
            error={!!error?.message}
            min={min}
            max={max}
            step={step}
          />
        )}
        rules={{
          validate: additionalValidation,
          required: {
            value: !!isRequired,
            message: t("projects.errors.cantBeEmpty"),
          },
        }}
      />
    </Label>
  );
};

export default ControlledInput;
