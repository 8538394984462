import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useThemeMode } from "../theme-mode-provider";
import { CardProps } from "./types";
import { CARD_PREFIX } from "../../shared/data-cy";

const wrapperStyle = "w-full flex flex-col gap-y-2";
const headerStyle = "flex justify-between items-center";
const labelStyle = "text-base font-semibold text-neutralPrimary-20";
const cardStyle = "rounded-md shadow-sm w-full text-neutralPrimary-20";

const Card = ({
  additionalClassName,
  additionalCardClassName,
  label,
  children,
  action,
  dataCy,
  cardHeight = "h-full",
  isScrollable = false,
  totalHeight = "h-auto",
  backgroundColor = "bg-neutralPrimary-100",
  ...rest
}: CardProps): ReactElement => {
  const { isHighContrast } = useThemeMode();

  return (
    <div
      className={classJoin(
        wrapperStyle,
        additionalClassName,
        totalHeight,
        backgroundColor,
      )}
      data-cy={`${CARD_PREFIX}-${dataCy}`}
      {...rest}
    >
      {(action || label) && (
        <div className={headerStyle}>
          <span className={labelStyle}>{label}</span>
          {action}
        </div>
      )}
      <div
        className={classJoin(
          cardStyle,
          additionalCardClassName,
          cardHeight,
          isHighContrast && "border border-primary-60",
        )}
      >
        {isScrollable ? (
          <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
            <>{children}</>
          </Scrollbars>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default Card;
