import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import { BADGE_PREFIX } from "../../shared/data-cy";
import { Keys, focusVisibleStyles } from "../../shared";
import { BadgeProps } from "./types";

const Badge = ({
  dataCy,
  title,
  Icon,
  number,
  isActive = true,
  activeColor = "secondary-70",
  onClick,
}: BadgeProps): ReactElement => {
  const color = isActive ? activeColor : "neutralSecondary-30";

  return (
    <div
      data-cy={`${BADGE_PREFIX}_${dataCy}`}
      className={classJoin(
        `flex h-max items-center bg-neutralSecondary-90
          border border-${color} rounded-md select-none`,
        isActive ? "" : "opacity-40",
        onClick ? focusVisibleStyles : "",
      )}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
      role={onClick ? "button" : "presentation"}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === Keys.ENTER && onClick) onClick();
      }}
    >
      <div className="flex items-center gap-2 px-2 py-1 h-full">
        {Icon ? <Icon className={classJoin(`w-5 h-5 text-${color}`)} /> : <></>}
        <span className="text-neutralPrimary-20 font-semibold">{title}</span>
      </div>
      <div
        className={`h-full px-3 text-xl text-primary-95 font-semibold rounded-r bg-${color}`}
      >
        {number}
      </div>
    </div>
  );
};

export default Badge;
