import { ReactElement, useEffect, useState, KeyboardEvent } from "react";
import { useDispatch } from "redux-react-hook";
import { useParams } from "react-router-dom";
import { useFormContext, useFormState } from "react-hook-form";
import { Button, LinkButton, RestrictedWrapper } from "@ps/ui-components";
import { clearRedirection, setRedirection } from "@ps/alert-modal";
import { DETAILS } from "../../../project-domain/constants";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { toggleEditMode } from "../../../store";
import {
  SectionWrapperMapState,
  SectionWrapperMapStateReturn,
} from "../../../project-domain/ui/new-project/types";
import { fetchClientSummary } from "../../store";
import { SectionWrapperProps } from "./types";
import { Keys } from "../../../shared";
import {
  CLIENTS_MANAGE,
  PROJECT_LIST_ALL,
  PROSPECT_FULL_ACCESS,
} from "../../../shared/permissions";

const SectionWrapper = ({
  Edit,
  name,
  section,
  View,
  onSubmit,
}: SectionWrapperProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const { clearErrors, handleSubmit, control } = useFormContext();
  const [editIndex] = useState<number | null>(null);
  const params = useParams<{ id: string }>();

  const handleOnEdit = (): void => {
    dispatch(toggleEditMode(DETAILS));
    clearErrors();
  };

  const mapState = (
    state: SectionWrapperMapState,
  ): SectionWrapperMapStateReturn => ({
    editMode: state.editMode,
    data: state?.projects?.project?.[section] || {} || [],
    domainDict: state.dictionaries?.domain || [],
  });

  const { editMode } = useMappedStateSelector(mapState);

  const { isDirty } = useFormState({
    control,
  });

  useEffect(() => {
    if (isDirty && editMode) dispatch(setRedirection());
  }, [isDirty, editMode]);

  const handleOnCancel = (): void => {
    fetchClientSummary(params.id, dispatch);
    dispatch(toggleEditMode(""));
    dispatch(clearRedirection());
  };

  useEffect(() => {
    dispatch(toggleEditMode(""));
    dispatch(clearRedirection());
  }, []);

  return (
    <section className="py-5 px-8">
      {editMode === DETAILS ? (
        <div
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === Keys.ESC) handleOnCancel();
          }}
          role="presentation"
        >
          <Edit />
          <div className="flex flex-col mt-8 mb-4">
            <div className="flex gap-x-2 w-9/12 pr-16 justify-end">
              <Button
                dataCy="save"
                type="submit"
                onClick={handleSubmit((dataToSubmit) =>
                  editIndex || editIndex === 0
                    ? onSubmit(dataToSubmit[name]?.[editIndex])
                    : onSubmit(dataToSubmit),
                )}
              >
                {t("clients.save")}
              </Button>
              <Button
                dataCy="cancel"
                variant="secondary"
                onClick={handleOnCancel}
              >
                {t("clients.cancel")}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between relative w-full">
            <View
              editButton={
                <RestrictedWrapper
                  permissions={[
                    CLIENTS_MANAGE,
                    PROSPECT_FULL_ACCESS,
                    PROJECT_LIST_ALL,
                  ]}
                >
                  <LinkButton
                    focus
                    dataCy={`${DETAILS}_${name}_editButton`}
                    prefixIcon={<EditIcon className="fill-current" />}
                    onClick={handleOnEdit}
                    additionalClassName="flex gap-1"
                  >
                    {t("clients.edit")}
                  </LinkButton>
                </RestrictedWrapper>
              }
            />
          </div>
        </>
      )}
    </section>
  );
};

export default SectionWrapper;
