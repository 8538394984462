import { Button } from "@ps/ui-components";
import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../../../hooks";
import { SKILLS_MATRIX_EXPERTISE } from "../../../../shared/data-cy";

const ActionButtons = ({
  onCancel,
  isEditMode,
}: {
  onCancel: () => void;
  isEditMode?: boolean;
}): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="flex gap-6">
      <Button
        dataCy={`${SKILLS_MATRIX_EXPERTISE}_save`}
        type="submit"
        disabled={!isEditMode}
      >
        {t("expertise.actions.save")}
      </Button>
      <Button
        dataCy={`${SKILLS_MATRIX_EXPERTISE}_cancel`}
        onClick={onCancel}
        variant="secondary"
        disabled={!isEditMode}
      >
        {t("expertise.actions.cancel")}
      </Button>
    </div>
  );
};

export default ActionButtons;
