enum ActionTypes {
  FETCH_HOLIDAYS_USERS_START = "FETCH_HOLIDAYS_USERS_START",
  FETCH_HOLIDAYS_USERS_SUCCESS = "FETCH_HOLIDAYS_USERS_SUCCESS",
  FETCH_HOLIDAYS_USERS_ERROR = "FETCH_HOLIDAYS_USERS_ERROR",

  FETCH_HOLIDAYS_USER_DETAILS_START = "FETCH_HOLIDAYS_USER_DETAILS_START",
  FETCH_HOLIDAYS_USER_DETAILS_SUCCESS = "FETCH_HOLIDAYS_USER_DETAILS_SUCCESS",
  FETCH_HOLIDAYS_USER_DETAILS_ERROR = "FETCH_HOLIDAYS_USER_DETAILS_ERROR",

  ADD_HOLIDAYS_USERS_FILTER = "ADD_HOLIDAYS_USERS_FILTER",
  REMOVE_HOLIDAYS_USERS_FILTER = "REMOVE_HOLIDAYS_USERS_FILTER",
  RESET_HOLIDAYS_USERS_FILTERS = "RESET_HOLIDAYS_USERS_FILTERS",

  SET_HOLIDAYS_USERS_SORTER = "SET_HOLIDAYS_USERS_SORTER",

  FETCH_HOLIDAYS_USER_HISTORY_START = "FETCH_HOLIDAYS_USER_HISTORY_START",
  FETCH_HOLIDAYS_USER_HISTORY_SUCCESS = "FETCH_HOLIDAYS_USER_HISTORY_SUCCESS",
  FETCH_HOLIDAYS_USER_HISTORY_ERROR = "FETCH_HOLIDAYS_USER_HISTORY_ERROR",
}

export default ActionTypes;
