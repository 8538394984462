import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { hhPermissions } from "@ps/hh";
import { IconContainer, RestrictedWrapper } from "@ps/ui-components";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as ArchiveIcon } from "../../images/archive.svg";
import { ReactComponent as TrashIcon } from "../../images/trash.svg";
import { useMembersContext } from "./context";
import { ActionsPanelProps } from "./types";

const ActionsPanel = ({
  isArchived,
  onArchiveClick,
  onRemoveClick,
  userId,
}: ActionsPanelProps): ReactElement => {
  const { isEdit, setIsEdit } = useMembersContext();
  const isDisabled: boolean = !!isEdit && isEdit !== userId;
  const isEditMode: boolean = isEdit === userId;
  const baseIconClass = "fill-current w-5 h-5";
  const archivedStyles: string = isArchived
    ? "opacity-40 pointer-events-none"
    : "";

  const temporaryDisabledActionsStyles = "opacity-40 pointer-events-none";

  return (
    <div className="flex items-center h-10 gap-1 xl:gap-2">
      <RestrictedWrapper
        permissions={hhPermissions.USERS_ASSIGN_ROLES}
        noPermissionsComponent={<span />}
      >
        <IconContainer
          type={isEditMode ? "button" : "submit"}
          onClick={() => !isDisabled && setIsEdit(isEditMode ? null : userId)}
          additionalClassName={classJoin(
            isDisabled ? "opacity-40 pointer-events-none" : "",
            isEditMode ? "bg-primary-50" : "",
            "rounded-l-lg",
            archivedStyles,
          )}
          disabled={isDisabled || isArchived}
        >
          <EditIcon
            className={classJoin(
              baseIconClass,
              userId === isEdit
                ? "text-neutralPrimary-100"
                : "text-neutralSecondary-41",
            )}
          />
        </IconContainer>
      </RestrictedWrapper>
      <RestrictedWrapper
        permissions={hhPermissions.USERS_ASSIGN_ROLES}
        noPermissionsComponent={<span />}
      >
        <IconContainer
          additionalClassName={
            !onArchiveClick ? "opacity-40 pointer-events-none" : ""
          }
          onClick={onArchiveClick}
          disabled={isEditMode}
        >
          <ArchiveIcon
            className={classJoin(baseIconClass, "text-neutralSecondary-41")}
          />
        </IconContainer>
      </RestrictedWrapper>
      {/* <RestrictedWrapper */}
      {/*  permissions={hhPermissions.USERS_ASSIGN_ROLES} */}
      {/*  noPermissionsComponent={<span />} */}
      {/* > */}
      {/*  <IconContainer */}
      {/*    additionalClassName={classJoin( */}
      {/*      archivedStyles, */}
      {/*      "rounded-r-lg", */}
      {/*      temporaryDisabledActionsStyles, */}
      {/*    )} */}
      {/*    onClick={onRemoveClick} */}
      {/*    disabled */}
      {/*  > */}
      {/*    <TrashIcon */}
      {/*      className={classJoin( */}
      {/*        baseIconClass, */}
      {/*        "rounded-r-lg, text-neutralSecondary-41", */}
      {/*      )} */}
      {/*    /> */}
      {/*  </IconContainer> */}
      {/* </RestrictedWrapper> */}
    </div>
  );
};

export default ActionsPanel;
