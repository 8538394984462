import { FONT_SIZE_LOCAL_STORAGE, FontSize } from "./types";

const getSavedFontSizeFromLocalStorage = () => {
  const usersSelection = localStorage.getItem(FONT_SIZE_LOCAL_STORAGE);

  switch (usersSelection) {
    case FontSize.DEFAULT:
      return FontSize.DEFAULT;
    case FontSize.SMALL:
      return FontSize.SMALL;
    case FontSize.LARGE:
      return FontSize.LARGE;
    default:
      return undefined;
  }
};

const getInitialFontSize = (): FontSize => {
  const usersSelection = getSavedFontSizeFromLocalStorage();

  if (usersSelection) return usersSelection;

  return FontSize.DEFAULT;
};

export default getInitialFontSize;
