import { ReactElement, useEffect, useState } from "react";
import jwt from "jsonwebtoken";
import { useKeycloak } from "@react-keycloak/web";
import { ChooseProfileScreen, MyProfileService } from "@ps/hh";
import { LoadingMainPage, LoadingPanels } from "@ps/ui-components";
import { useDispatch, useMappedState } from "redux-react-hook";
import { useHistory } from "react-router-dom";
import { AuthMapState, AuthMapStateReturn, AuthProps } from "./types";
import setAPIToken from "./setAPIToken";
import {
  loginError,
  loginStart,
  loginSuccess,
} from "../../store/login/actions";
import fromKeycloakToModel from "../models/converters";
import { setPermissions } from "../../store/permissions";
import { fetchTenantMetadata } from "../../tenant-domain";

const Auth = ({ children }: AuthProps): ReactElement => {
  const [isProfileSet, setIsProfileSet] = useState(true);
  const [isLoginFinished, setIsLoginFinished] = useState(false);
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const history = useHistory();

  const mapState = (state: AuthMapState): AuthMapStateReturn => ({
    isTenantMetadataFetching: state?.requestStatus?.isTenantMetadataFetching,
  });

  const { isTenantMetadataFetching } = useMappedState(mapState);

  useEffect(() => {
    if (keycloak?.token) {
      const { token = "", loadUserInfo } = keycloak;
      setAPIToken(token);
      dispatch(loginStart());
      localStorage.setItem("token", token);
      loadUserInfo()
        .then((userInfo) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(loginSuccess(fromKeycloakToModel(userInfo)));
        })
        .then(() => {
          setAPIToken(token);
          setIsLoginFinished(true);
          const decodedToken = jwt.decode(token);
          const decodedPermissions = decodedToken?.realm_access?.roles || [];
          dispatch(setPermissions(decodedPermissions));
        })
        .then(() => MyProfileService.fetchProfile(undefined, true))
        .then((result) => {
          if (result.status === 404) setIsProfileSet(false);
          else setIsProfileSet(true);
        })
        .then(() => fetchTenantMetadata(dispatch))
        .catch((error) => dispatch(loginError(error)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, initialized]);

  const handleOnRoleClick = async (profile: string) => {
    await MyProfileService.createProfile(profile);
    setIsProfileSet(true);
    history.push("/");
  };

  if (!keycloak?.authenticated || !isLoginFinished || isTenantMetadataFetching)
    return (
      <LoadingPanels>
        <LoadingMainPage additionalClassName="bg-neutralPrimary-85" />
      </LoadingPanels>
    );

  if (isProfileSet) return children;

  return <ChooseProfileScreen onClick={handleOnRoleClick} />;
};

export default Auth;
