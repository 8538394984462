import { useState, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { AnyAction } from "redux";
import { Button, Modal2, ModalFooter, ModalHeader } from "@ps/ui-components";
import { fetchClients } from "../../../client-domain";
import { fetchFolders } from "../../store";
import { FOLDER } from "../../../shared/data-cy";
import {
  ADD_NEW_FOLDER,
  FOLDERS,
  createFolderErrorsInitialState,
} from "../../constants";
import { useTranslationWithNamespace } from "../../../hooks";
import AddNewClientModal from "../../../client-domain/ui/addNewClientModal";
import FolderService from "../../services";
import FolderContent from "./folderContent";
import { CreateFolderContainerProps, CreateFolderModalErrors } from "./types";
import { ReactComponent as NewProspectIcon } from "../../../images/folder.svg";
import styles from "../folders-list/styles.module.css";

const CreateFolderModal = ({
  onClose,
  isOpen,
  defaultClientId,
  currentID,
  setCurrentID,
}: CreateFolderContainerProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isNewClientModal, setIsNewClientModal] = useState(false);
  const [folderData, setFolderData] = useState({
    folderName: "",
    clientId: defaultClientId || "",
  });
  const [error, setError] = useState<CreateFolderModalErrors>(
    createFolderErrorsInitialState,
  );

  const handleOnClose = (): void => {
    if (!isNewClientModal) {
      setError(createFolderErrorsInitialState);
      setFolderData({
        folderName: "",
        clientId: "",
      });
      onClose();
    }
  };

  const handleOnSubmit = async (): Promise<
    void | CreateFolderModalErrors | AnyAction
  > => {
    if (
      !folderData.clientId ||
      !folderData.folderName.trim().length ||
      folderData.folderName.length > 100
    )
      return setError({
        folderName:
          !folderData.folderName.trim().length ||
          folderData.folderName.length > 100,
        clientId: !folderData.clientId,
      });
    if (!isNewClientModal) {
      const newFolderId = await FolderService.createFolder(
        folderData.folderName,
        folderData.clientId,
      );
      if (newFolderId && typeof newFolderId === "string") {
        handleOnClose();
        history.push(`/${FOLDERS}/${newFolderId}`);
        fetchFolders(dispatch, defaultClientId);
        return fetchClients(dispatch);
      }
    }
    return error;
  };

  const handleOnNewClientClose = (newClientId = ""): void => {
    if (newClientId)
      setFolderData((prev) => ({ ...prev, clientId: newClientId }));

    setIsNewClientModal(false);
  };

  return isNewClientModal ? (
    <AddNewClientModal
      fetchClients={() => fetchClients(dispatch)}
      isOpen={isNewClientModal}
      onClose={handleOnNewClientClose}
    />
  ) : (
    <Modal2
      isOpen={isOpen}
      dataCy={ADD_NEW_FOLDER}
      onClose={handleOnClose}
      width={styles.modalWidth}
      height="h-auto"
      withYScroll={false}
      withCloseIcon
    >
      <ModalHeader title={t("folders.newFolder")} Icon={<NewProspectIcon />} />
      <FolderContent
        addNewClient={() => setIsNewClientModal(true)}
        error={error}
        setError={setError}
        data={folderData}
        setData={setFolderData}
        defaultClientId={defaultClientId}
        currentID={currentID}
        setCurrentID={setCurrentID}
      />
      <ModalFooter additionalClassName="mt-6 gap-x-6">
        <Button
          dataCy={`${FOLDER}_modalCancel`}
          variant="secondary"
          onClick={handleOnClose}
          width="w-1/2"
        >
          {t("folders.actions.cancel")}
        </Button>
        <Button
          dataCy={`${FOLDER}_modalSave`}
          type="submit"
          onClick={handleOnSubmit}
          width="w-1/2"
        >
          {t("folders.actions.save")}
        </Button>
      </ModalFooter>
    </Modal2>
  );
};

export default CreateFolderModal;
