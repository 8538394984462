import { AnyAction, combineReducers } from "redux";
import { HolidaysUsersModel, HolidaysUserDetailsModel } from "../models";
import ActionTypes from "./actionTypes";
import { ISelectedFilters, ISelectedSorter } from "./types";
import {
  selectedSorterInitialState,
  selectedFiltersInitialState,
} from "../ui/holidays/constants";

const holidaysUsersReducer = (
  state: HolidaysUsersModel = [],
  action: AnyAction,
): HolidaysUsersModel => {
  switch (action.type) {
    case ActionTypes.FETCH_HOLIDAYS_USERS_START:
      return state;
    case ActionTypes.FETCH_HOLIDAYS_USERS_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_HOLIDAYS_USERS_ERROR:
      return [];
    default:
      return state;
  }
};

export const isFetchingHolidaysUsers = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_HOLIDAYS_USERS_START:
      return true;
    case ActionTypes.FETCH_HOLIDAYS_USERS_SUCCESS:
    case ActionTypes.FETCH_HOLIDAYS_USERS_ERROR:
      return false;
    default:
      return state;
  }
};

const holidaysUserHistoryReducer = (
  state: HolidaysUserDetailsModel | Record<string, unknown> = {},
  action: AnyAction,
): HolidaysUserDetailsModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_HOLIDAYS_USER_HISTORY_START:
      return state;
    case ActionTypes.FETCH_HOLIDAYS_USER_HISTORY_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_HOLIDAYS_USER_HISTORY_ERROR:
      return {};
    default:
      return state;
  }
};

export const isFetchingHolidaysUserHistory = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_HOLIDAYS_USER_HISTORY_START:
      return true;
    case ActionTypes.FETCH_HOLIDAYS_USER_HISTORY_SUCCESS:
    case ActionTypes.FETCH_HOLIDAYS_USER_HISTORY_ERROR:
      return false;
    default:
      return state;
  }
};

const holidaysUserDetailsReducer = (
  state: HolidaysUserDetailsModel | Record<string, unknown> = {},
  action: AnyAction,
): HolidaysUserDetailsModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_HOLIDAYS_USER_DETAILS_START:
      return state;
    case ActionTypes.FETCH_HOLIDAYS_USER_DETAILS_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_HOLIDAYS_USER_DETAILS_ERROR:
      return {};
    default:
      return state;
  }
};

export const isFetchingHolidaysUserDetails = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_HOLIDAYS_USER_DETAILS_START:
      return true;
    case ActionTypes.FETCH_HOLIDAYS_USER_DETAILS_SUCCESS:
    case ActionTypes.FETCH_HOLIDAYS_USER_DETAILS_ERROR:
      return false;
    default:
      return state;
  }
};

const filtersReducer = (
  state: ISelectedFilters = selectedFiltersInitialState,
  action: AnyAction,
): ISelectedFilters => {
  const optionId: string = action?.payload?.optionId;
  const filterName: string = action?.payload?.filterName;
  switch (action.type) {
    case ActionTypes.ADD_HOLIDAYS_USERS_FILTER:
      return {
        ...state,
        [filterName]: [...state[filterName], optionId],
      };
    case ActionTypes.REMOVE_HOLIDAYS_USERS_FILTER:
      return {
        ...state,
        [filterName]: state[filterName].filter(
          (item: string): boolean => item !== optionId,
        ),
      };
    case ActionTypes.RESET_HOLIDAYS_USERS_FILTERS:
      return selectedFiltersInitialState;
    default:
      return state;
  }
};

const sorterReducer = (
  state: ISelectedSorter = selectedSorterInitialState,
  action: AnyAction,
): ISelectedSorter => {
  switch (action.type) {
    case ActionTypes.SET_HOLIDAYS_USERS_SORTER:
      return action.payload;
    default:
      return state;
  }
};

export const holidaysReducer = combineReducers({
  users: holidaysUsersReducer,
  userDetails: holidaysUserDetailsReducer,
  filters: filtersReducer,
  sorter: sorterReducer,
  holidaysUserHistory: holidaysUserHistoryReducer,
});
