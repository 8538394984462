import { ReactElement } from "react";
import { Button, Modal, ModalContent, ModalFooter } from "@ps/ui-components";
import { Trans } from "react-i18next";
import { MEMBERS } from "../../shared/data-cy";
import { ReactComponent as RemoveItem } from "../../images/remove-item.svg";
import { useTranslationWithNamespace } from "../../hooks";
import { NAMESPACE } from "../../shared/constants";
import { ConfirmRemoveModalProps } from "./types";

const ConfirmRemoveModal = ({
  isOpen,
  onClose,
  onRemove,
  userName,
}: ConfirmRemoveModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Modal
      additionalClassName="py-16 px-32"
      additionalContentClassName="flex flex-col gap-4"
      dataCy={`${MEMBERS}_remove`}
      height="h-auto"
      isOpen={isOpen}
      onClose={onClose}
      width="w-auto"
      withCloseIcon
      withYScroll
    >
      <ModalContent additionalClassName="flex justify-center items-center gap-8">
        <RemoveItem />
        <div className="flex flex-col gap-1">
          <span className="font-semibold">{t("members.deleteUser")}</span>
          <div className="flex">
            <Trans i18nKey="members.doYouWantToDelete" ns={NAMESPACE}>
              <span className="font-semibold pl-1">{{ user: userName }}</span>
            </Trans>
          </div>
        </div>
      </ModalContent>
      <ModalFooter additionalClassName="flex justify-center">
        <Button dataCy={`${MEMBERS}_delete`} onClick={onRemove}>
          {t("actions.delete")}
        </Button>
        <Button
          dataCy={`${MEMBERS}_delete`}
          variant="secondary"
          onClick={onClose}
        >
          {t("actions.cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmRemoveModal;
