import {
  PreparedDictionaryEntry,
  NewDictionaryEntry,
  DictionaryEntryModel,
} from "./types";

const prepareTechStackDictionaryValues = (
  valueIds:
    | string
    | string[]
    | []
    | (PreparedDictionaryEntry | NewDictionaryEntry)[],
  dictionary: DictionaryEntryModel[],
): [] | DictionaryEntryModel | undefined | DictionaryEntryModel[] => {
  if (!valueIds?.length) return [];

  if (typeof valueIds === "string")
    return dictionary.find((entry) => entry?.id === valueIds);

  const toCompare = (item: Record<string, unknown>) =>
    typeof valueIds?.[0] === "string" ? item : item?.id;

  return (
    valueIds
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((singleId: any) => {
        if (singleId?.isNew) return singleId;
        return dictionary.filter(
          (dictEntry) => dictEntry.id === toCompare(singleId),
        );
      })
      .flat()
  );
};

export default prepareTechStackDictionaryValues;
