import { ReactElement } from "react";
import dayjs from "dayjs";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { minutesToHHMM, sortArray } from "@ps/utils";
import {
  flatEntriesData,
  FlattenEntry,
  countTotalInGroup,
} from "@ps/time-tracker";
import { useTranslationWithNamespace } from "../../hooks";
import { DATE_FORMAT } from "../../shared/constants";
import projectsIcon from "../../images/report/projects.png";
import usersIcon from "../../images/report/users.png";
import tableIcon from "../../images/report/table.png";
import { FlattenReportEntry, ReportProps } from "./types";
import { SectionContent, SectionTitle } from "./common";
import {
  PROJECT_NAME,
  PROJECTS,
  REPORT,
  SUMMARY,
  TABLE,
  TOTAL,
  USER_NAME,
  USERS,
} from "./constants";
import { BarChart, barChartUtils } from "../charts-generators";
import { sortDates } from "../charts-generators/bar-chart/utils";
import Table from "./table";

const styles = StyleSheet.create({
  page: {
    padding: "30px",
    position: "relative",
    fontFamily: "Poppins",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
  },
  titleSummary: {
    fontSize: "30px",
    fontFamily: "PoppinsBold",
    textTransform: "uppercase",
    color: "#091B76",
    fontWeight: "black",
    marginRight: "8px",
  },
  titleReport: {
    fontSize: "30px",
    textTransform: "uppercase",
    fontFamily: "PoppinsLight",
    color: "#A9A9A9",
    fontWeight: "thin",
  },
  timeFrame: {
    color: "#152DA8",
    fontSize: "20px",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "16px 0",
  },
  infoTitle: {
    color: "#6B7482",
    fontWeight: "thin",
    fontSize: "18px",
  },
  infoContent: {
    color: "#152DA8",
    fontFamily: "PoppinsBold",
    fontSize: "18px",
  },
  barChart: {
    marginBottom: "16px",
  },
});

const Report = ({
  activeSorter,
  charts,
  groupNames,
  report,
  sortedGroupedData,
  timeFrame,
  totalTime,
}: ReportProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const startDate = dayjs(timeFrame?.[0]).format(DATE_FORMAT);
  const endDate = dayjs(timeFrame?.[1]).format(DATE_FORMAT);

  const translate = (field: string): string => t(`report.${field}`);

  const flattenEntriesReport: FlattenReportEntry[] = flatEntriesData(report);

  const sortedDates = sortDates(timeFrame);

  const isLessThanMonth = barChartUtils.isDaysBar(
    sortedDates[0],
    sortedDates[1],
  );

  const sortGroupedData = (
    fieldName: string,
    data: Record<string, FlattenEntry[]>,
  ): FlattenReportEntry[] => {
    const order = activeSorter?.order === "desc" ? "asc" : "desc";
    if (fieldName === "title") {
      const sortedKeys: string[] = Object.keys(data).sort();
      const checkIfNeedReverse: string[] =
        order === "desc" ? sortedKeys.reverse() : sortedKeys;
      return checkIfNeedReverse.reduce(
        (acc: Record<string, FlattenEntry[]>, entry: string) => {
          acc[entry] = data[entry];
          return acc;
        },
        {},
      );
    }
    if (fieldName === "duration") {
      const groupDataWithTotalDuration = Object.keys(data).map(
        (item: string) => ({
          itemName: item,
          duration: countTotalInGroup(data[item]),
        }),
      );
      const sortedArrayByDuration = sortArray(
        groupDataWithTotalDuration,
        fieldName,
        order,
      );
      const getAllSortedItemNames: string[] = sortedArrayByDuration.map(
        (item) => item.itemName,
      );
      return getAllSortedItemNames.reduce(
        (acc: Record<string, FlattenEntry[]>, entry: string) => ({
          ...acc,
          [entry]: data[entry],
        }),
        {},
      );
    }
  };

  return (
    <Document title={`${startDate} - ${endDate} ${translate(REPORT)}`}>
      <Page style={styles.page}>
        <View style={styles.titleContainer}>
          <Text style={styles.titleSummary}>{translate(SUMMARY)}</Text>
          <Text style={styles.titleReport}>{translate(REPORT)}</Text>
        </View>
        <Text style={styles.timeFrame}>{`${startDate} - ${endDate}`}</Text>
        <View style={styles.infoContainer}>
          <Text style={styles.infoTitle}>
            {translate(TOTAL) + " "}
            <Text style={styles.infoContent}>{minutesToHHMM(totalTime)}</Text>
          </Text>
        </View>
        <BarChart
          data={barChartUtils.prepareBarChartData(
            report,
            sortedDates,
            !isLessThanMonth,
          )}
          isMonthly={!isLessThanMonth}
        />
        <View>
          <SectionTitle text={translate(PROJECTS)} icon={projectsIcon} />
          <SectionContent
            entries={flattenEntriesReport}
            field={PROJECT_NAME}
            image={
              charts?.find((chart) => chart?.section === PROJECTS)?.image ||
              null
            }
          />
        </View>
        <View>
          <SectionTitle text={translate(USERS)} icon={usersIcon} />
          <SectionContent
            entries={flattenEntriesReport}
            field={USER_NAME}
            image={
              charts?.find((chart) => chart?.section === USERS)?.image || null
            }
          />
        </View>
        <View>
          <SectionTitle text={translate(TABLE)} icon={tableIcon} />
          <Table
            data={sortGroupedData(activeSorter.name, sortedGroupedData)}
            groupNames={groupNames}
          />
        </View>
      </Page>
    </Document>
  );
};

export default Report;
