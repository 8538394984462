import { ReactElement } from "react";
import { ReactComponent as EmptyViewImage } from "../../../../../images/project/empty-users.svg";
import { useTranslationWithNamespace } from "../../../../../hooks";
import { BASE_PATH } from "./constants";

const EmptyPage = (): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="flex flex-col items-center justify-center gap-10 -mx-9 overflow-hidden pb-4">
      <EmptyViewImage className="overflow-hidden" />
      <span className="text-xl text-neutralPrimary-20 font-semibold">
        {t(`${BASE_PATH}.${"emptyActive"}`)}
      </span>
    </div>
  );
};

export default EmptyPage;
