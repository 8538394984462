import { UserDomainModel } from "../../user-domain";

export const mapUsersToValueLabel = (
  usersList: UserDomainModel[],
): { value: string; label: string; userId: string }[] =>
  usersList
    ?.sort((a: UserDomainModel, b: UserDomainModel) =>
      (a?.firstName || "")?.localeCompare(b?.firstName || ""),
    )
    ?.map((user: UserDomainModel) => ({
      userId: user.id,
      value: user.id,
      label: `${user.firstName} ${user.secondName}`,
    }));

export const filterArchivedUsers = (
  users: UserDomainModel[],
): UserDomainModel[] =>
  users.filter((user: UserDomainModel): boolean => user.enabled);
