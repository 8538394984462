import React from "react";
import { CollapseContextProps } from "./types";

const CollapseContext = React.createContext<CollapseContextProps>({
  openedPanels: [],
  togglePanel: () => {
    // do nothing
  },
});

export default CollapseContext;
