import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import Loader from "../loader";
import { ReactComponent as Icon } from "../../images/trees-loading-icon.svg";
import { useTranslationWithNamespace } from "../../hooks";
import { LoadingAnyPageProps } from "./types";

const LoadingAnyPage = ({
  additionalClassName,
}: LoadingAnyPageProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div
      className={classJoin(
        "h-full overflow-auto flex flex-col justify-center items-center gap-y-5",
        additionalClassName,
      )}
    >
      <div className="relative mb-10">
        <Icon />
        <Loader
          additionalClassName="mb-10 absolute top-40 left-24"
          dotHeight="h-6"
          dotWidth="w-6"
        />
      </div>
      <span className="text-2xl font-bold text-neutralPrimary-20">
        {t("loadingPage.please")}
      </span>
    </div>
  );
};

export default LoadingAnyPage;
