import { removeDiacriticsFromString } from "@ps/utils";
import { Client } from "../ui/types";

export const filterClientsList = (
  allClients: Client[],
  searchValue: string,
): Client[] =>
  allClients?.length
    ? allClients.filter((client: Client) =>
        removeDiacriticsFromString(
          client?.clientName?.toLowerCase() || "",
        ).includes(removeDiacriticsFromString(searchValue.toLowerCase())),
      )
    : [];
