import { Controller, useFormContext } from "react-hook-form";
import { ReactElement, ChangeEvent } from "react";
import { classJoin, colorFormatters } from "@ps/utils";
import { ScaleTile, Textarea, Tooltip } from "@ps/ui-components";
import { ENJOYMENT_SCALE } from "../../../../../shared/consts";
import { EnjoymentScaleWithDescProps } from "./types";
// TODO
// temporary icons as icons not as S3 links
import { ReactComponent as DoubleThumbDownIcon } from "../../../../../../images/icons/double-thumb-down.svg";
import { ReactComponent as ThumbDownIcon } from "../../../../../../images/icons/thumb-down.svg";
import { ReactComponent as NeutralIcon } from "../../../../../../images/icons/neutral.svg";
import { ReactComponent as ThumbUpIcon } from "../../../../../../images/icons/thumb-up.svg";
import { ReactComponent as DoubleThumbUpIcon } from "../../../../../../images/icons/double-thumb-up-21.svg";
import { useTranslationWithNamespace } from "../../../../../../hooks";

const EnjoymentScaleWithDesc = ({
  // TODO temporary limited because of hh2-2698
  // item,
  index,
}: EnjoymentScaleWithDescProps): ReactElement => {
  const { control } = useFormContext();
  const { t } = useTranslationWithNamespace();

  // TODO temporary limited because of hh2-2698
  // const iconStyles = classJoin.template`
  // bg-white text-primary-51 fill-current h-10 p-1
  // w-auto cursor-pointer border border-primary-51 rounded hover:opacity-70`;

  const iconStylesWithoutTooltip = classJoin.template`
  bg-white text-primary-51 fill-current h-10 p-1 
  w-auto border border-primary-51 rounded`;

  const iconDictionary = {
    0: <DoubleThumbDownIcon className={iconStylesWithoutTooltip} />,
    1: <ThumbDownIcon className={iconStylesWithoutTooltip} />,
    2: <NeutralIcon className={iconStylesWithoutTooltip} />,
    3: <ThumbUpIcon className={iconStylesWithoutTooltip} />,
    4: <DoubleThumbUpIcon className={iconStylesWithoutTooltip} />,
  };

  return (
    <div className="flex flex-col w-96 gap-4">
      <div className="w-min">
        {/* TODO temporary limited because of hh2-2698 */}
        {/* <Controller */}
        {/*  name={`${ENJOYMENT_SCALE}.${index}`} */}
        {/*  control={control} */}
        {/*  render={() => ( */}
        {/*    <Tooltip */}
        {/*      placement="right" */}
        {/*      isInteractive */}
        {/*      content={ */}
        {/*        <div className="flex flex-wrap gap-2 items-center p-2"> */}
        {/*          <DoubleThumbDownIcon className={iconStyles} /> */}
        {/*          <ThumbDownIcon className={iconStyles} /> */}
        {/*          <NeutralIcon className={iconStyles} /> */}
        {/*          <ThumbUpIcon className={iconStyles} /> */}
        {/*          <DoubleThumbUpIcon className={iconStyles} /> */}
        {/*        </div> */}
        {/*      } */}
        {/*    > */}
        {/*      <ScaleTile */}
        {/*        dataCy={ENJOYMENT_SCALE} */}
        {/*        color={ */}
        {/*          typeof item?.color === "string" */}
        {/*            ? item?.color || "" */}
        {/*            : colorFormatters.prepareRGBObjectToHEX(item?.color) */}
        {/*        } */}
        {/*      /> */}
        {/*    </Tooltip> */}
        {/*  )} */}
        {/* /> */}
        {iconDictionary[index]}
      </div>
      <Controller
        name={`${ENJOYMENT_SCALE}.${index}.desc`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Textarea
            dataCy={ENJOYMENT_SCALE}
            height="h-32"
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
              onChange(event.target.value)
            }
            value={value}
            error={!!error}
            message={error?.message}
          />
        )}
        rules={{
          required: {
            value: true,
            message: `${t("expertise.errors.isRequired")}`,
          },
        }}
      />
    </div>
  );
};

export default EnjoymentScaleWithDesc;
