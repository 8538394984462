import React, { ReactElement } from "react";
import { uid } from "react-uid";
import { classJoin } from "@ps/utils";
import { useThemeMode } from "../theme-mode-provider";
import { TIMELINE_PREFIX } from "../../shared/data-cy";
import { TimelineProps } from "./types";
import { ReactComponent as BinIcon } from "../../images/bin.svg";

const hoverStyles =
  "hover:bg-neutralPrimary-100 hover:border-neutralPrimary-85 cursor-pointer";

const Timeline = ({
  children = [],
  dataCy,
  offset = 0,
  onNodeRemove,
  withSeparator = true,
}: TimelineProps): ReactElement => {
  const { isHighContrast } = useThemeMode();

  return (
    <div
      className="flex flex-col overflow-hidden flex-grow w-full"
      data-cy={`${TIMELINE_PREFIX}-${dataCy}`}
    >
      {React.Children.map(children, (child, index) => (
        <div className="flex flex-row w-full flex-grow" key={uid(child, index)}>
          <div className="relative w-9">
            <div
              className={classJoin(
                `absolute transform translate-x-4 translate-y-${offset}
              rounded-full flex-grow w-0.5 h-full`,
                isHighContrast
                  ? "bg-primary-30"
                  : "bg-neutralSecondary-60 opacity-30",
              )}
            />
            <div
              className={classJoin(
                `group absolute w-3.5 h-3.5 bg-neutralPrimary-50 transform
            translate-x-2.5 translate-y-${offset} rounded-full flex items-center justify-center border`,
                onNodeRemove ? hoverStyles : "cursor-default",
              )}
              onClick={() =>
                onNodeRemove &&
                typeof onNodeRemove === "function" &&
                onNodeRemove(index)
              }
              role="presentation"
            >
              {onNodeRemove && (
                <BinIcon className="fill-current group-hover:opacity-40 text-neutralPrimary-30 w-4 h-4" />
              )}
            </div>
          </div>
          <div
            className={classJoin(
              "w-full pt-2",
              index !== React.Children.count(children) - 1 && "pb-5",
              index !== React.Children.count(children) - 1 &&
                withSeparator &&
                "border-b",
            )}
          >
            {child}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
