import { ReactElement } from "react";
import { useDispatch } from "redux-react-hook";
import { useParams } from "react-router-dom";
import {
  Icon,
  RestrictedWrapper,
  Avatar,
  Button,
  Empty,
} from "@ps/ui-components";
import { classJoin, getNameFromDictionary, setSeniorityColor } from "@ps/utils";
import { DISCOVERY_TEAM } from "../../../../constants";
import { ReactComponent as NoAvatarIcon } from "../../../../../images/no-avatar.svg";
import { ReactComponent as AddIcon } from "../../../../../images/prospect/addUser.svg";

import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../../../hooks";
import {
  addUserToDiscoveryTeam,
  deleteUserFromDiscoveryTeam,
} from "../../../../services";
import { fetchProspect } from "../../../../store";
import { UserRowProps, UserRowMapStateReturn, UserRowMapState } from "./types";
import { PROSPECT_FULL_ACCESS } from "../../../../../shared/permissions";
import styles from "../styles.module.css";

const TRANSLATE_PATH = "projects.prospect.discoveryTeam";

const UserRow = ({
  user,
  checked,
  setChecked,
  archived,
}: UserRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const mapState = (state: UserRowMapState): UserRowMapStateReturn => ({
    professionsDict: state.dictionaries?.profession || [],
    technologyDict: state?.dictionaries?.technology || [],
    seniorityDict: state?.dictionaries?.seniority || [],
    operativeProfessionsDict: state?.dictionaries.operative_profession || [],
  });
  const {
    professionsDict,
    technologyDict,
    seniorityDict,
    operativeProfessionsDict,
  } = useMappedStateSelector(mapState);

  const checkUserInTeam = checked.includes(user.userId);

  const handleOnChecked = async () => {
    if (checkUserInTeam) {
      const filteredMembers = checked.filter((item) => item !== user.userId);
      setChecked(filteredMembers);
      await deleteUserFromDiscoveryTeam(params.id, user.userId);
      await fetchProspect(params.id, dispatch, technologyDict);
    } else {
      setChecked((prev) => [...prev, user.userId]);
      await addUserToDiscoveryTeam(params.id, {
        userId: user.userId,
        name: `${user.firstName} ${user.lastName}`,
        professions: [],
      });
      await fetchProspect(params.id, dispatch, technologyDict);
    }
  };

  const mainProfessionName = getNameFromDictionary(
    [...professionsDict, ...operativeProfessionsDict],
    user?.mainProfession,
  );

  const seniorityName = getNameFromDictionary(seniorityDict, user?.seniority);

  const normalizedSeniority =
    seniorityName.charAt(0).toUpperCase() + seniorityName.slice(1);

  return (
    <>
      <div
        data-cy={`${DISCOVERY_TEAM}_list_user`}
        className={classJoin(
          styles.usersGrid,
          "w-full text-neutralPrimary-20 items-center",
        )}
      >
        <div className="flex gap-x-6 items-center">
          <div
            className={classJoin(
              checkUserInTeam
                ? "rounded border border-primary-50"
                : "rounded border border-neutralPrimary-100",
            )}
          >
            {user?.avatar ? (
              <Avatar
                image={user?.avatar}
                dataCy={`${DISCOVERY_TEAM}_icon`}
                width={11}
                height={11}
              />
            ) : (
              <Icon
                icon={<NoAvatarIcon className="text-neutralSecondary-60" />}
                dataCy={`${DISCOVERY_TEAM}_icon`}
                width="11"
                height="11"
              />
            )}
          </div>
          <span className="font-semibold">
            {user?.firstName} {user?.lastName}
          </span>
        </div>
        {user?.mainProfession ? <span>{mainProfessionName}</span> : <Empty />}

        {user?.seniority ? (
          <div className="flex items-center flex-wrap text-neutralPrimary-20">
            <span className={`${setSeniorityColor(user?.seniority)}`}>
              {normalizedSeniority}
            </span>
          </div>
        ) : (
          <Empty />
        )}
        {user?.residence?.label ? (
          `${user.residence.label}, ${user.residence.value}`
        ) : (
          <Empty />
        )}
        <div className="flex items-center justify-end">
          {!archived && (
            <RestrictedWrapper permissions={PROSPECT_FULL_ACCESS}>
              {checkUserInTeam ? (
                <Button
                  width="w-44"
                  dataCy={`${DISCOVERY_TEAM}_remove`}
                  onClick={handleOnChecked}
                >
                  {t(`${TRANSLATE_PATH}.inTeam`)}
                </Button>
              ) : (
                <Button
                  width="w-44 flex gap-x-4"
                  variant="secondary"
                  dataCy={`${DISCOVERY_TEAM}_add`}
                  onClick={handleOnChecked}
                >
                  <AddIcon />
                  {t(`${TRANSLATE_PATH}.add`)}
                </Button>
              )}
            </RestrictedWrapper>
          )}
        </div>
      </div>
      <div className="bg-neutralSecondary-41 h-0.5 bg-opacity-20" />
    </>
  );
};

export default UserRow;
