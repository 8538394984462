import {
  AVAILABILITY,
  LOCATION,
  NAME,
  PROFESSION,
  SENIORITY,
  STATUS,
} from "../../constants";

export const filtersInitialState = {
  // TODO needed in the future when this column will be visible
  [AVAILABILITY]: [
    { name: "Available", id: "available" },
    { name: "On project", id: "onProject" },
    { name: "Bench 2", id: "bench2" },
  ],
};

export const selectedFiltersInitialState = {
  [STATUS]: [],
  [AVAILABILITY]: [],
  [PROFESSION]: [],
  [SENIORITY]: [],
  [NAME]: [],
  [LOCATION]: [],
};

export const selectedSorterInitialState = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  order: "desc" as "desc",
  name: NAME,
};
