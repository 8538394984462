import { DictionaryNewEntryAPIModel, DictionaryNewEntryModel } from "../types";

const toNewEntryAPIModel = (
  newEntry: DictionaryNewEntryModel,
): DictionaryNewEntryAPIModel => ({
  deleted: newEntry?.deleted,
  desc: newEntry.desc,
  icon_url: newEntry.iconUrl,
  name: newEntry.name,
});

export default toNewEntryAPIModel;
