import { ReactElement, useEffect, useState } from "react";
import { getName } from "i18n-iso-countries";
import { useDispatch } from "redux-react-hook";
import {
  Avatar,
  Icon,
  Modal2,
  ModalContent,
  SkillBar,
  Subheader,
} from "@ps/ui-components";
import flag from "country-code-emoji";
import ISOLanguages from "iso-639-1";
import dayjs, { Dayjs } from "dayjs";
import {
  classJoin,
  getNamesFromDictionary,
  getOverallExperience,
  languageCodeToCountryCode,
  setSeniorityColor,
} from "@ps/utils";
import { uid } from "react-uid";
import { SpecialistModalProps } from "./types";
import {
  SPECIALISTS,
  ROLE,
  EXPERIENCE,
  WORK,
  INFO,
  SENIORITY,
  PROFESSION,
  AVAILABILITY,
  PASSPORTS,
  NATIONALITY,
  VISAS,
  COMFORTABLE_HOURS,
  POSSIBLE_HOURS,
  LANGUAGES,
} from "../../../../constants";
import { useTranslationWithNamespace } from "../../../../../hooks";
import {
  PassportModel,
  SpecialistDomainModel,
  VisaModel,
} from "../../../../../profile-domain/models";
import { fetchFolderProposalProfile } from "../../../../../folder-domain/store";
import { ReactComponent as NoAvatarIcon } from "../../../../../images/no-avatar.svg";
import { ReactComponent as LocationPinIcon } from "../../../../../images/location.svg";
import { ReactComponent as InfoIcon } from "../../../../../images/prospect/info.svg";
import { ReactComponent as ClockIcon } from "../../../../../images/prospect/clock2.svg";
import { ReactComponent as LanguageIcon } from "../../../../../images/prospect/language.svg";
import { ReactComponent as PassportIcon } from "../../../../../images/prospect/passport.svg";
import { CURRENT_LANGUAGE_CODE } from "../../../../../i18n/constants";
import { FOLDER_CANDIDATE_AVATAR } from "../../../../shared/data-cy";
import ThinLabel from "./thinLabel";
import { languageLevelsDict } from "../../../../../profile-domain/ui/overview/constants";

const TRANSLATE_PATH = "projects.prospect.proposalFolder.userDetails";

const SpecialistModal = ({
  isOpen,
  onClose,
  userId,
  mainProfessionName,
  normalizedSeniority,
  roleDict,
  currentProfile,
  userName,
}: SpecialistModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const [profile, setProfile] = useState<SpecialistDomainModel>();

  const fetchUserData = async (): Promise<void> => {
    if (!currentProfile && userId)
      await fetchFolderProposalProfile(userId, dispatch);
    setProfile(currentProfile);
  };

  useEffect(() => {
    fetchUserData();
  }, [userId, currentProfile]);

  const role: string = profile?.roles
    ? getNamesFromDictionary(profile?.roles, roleDict)
    : "";

  const experienceAmount: { years: number; months: number } =
    getOverallExperience(profile?.careerSpan);

  const isDocumentExpired = (date: string | Dayjs | null | undefined) =>
    date && dayjs().isAfter(date);

  const getSelectedNumber = (item: string): number =>
    languageLevelsDict.reduce(
      (result, level, index) => (level === item ? index + 1 : result),
      1,
    );

  return (
    <Modal2
      dataCy={`${SPECIALISTS}_modal`}
      isOpen={isOpen}
      onClose={onClose}
      withCloseIcon
      width="w-1/2"
      height="h-fit"
    >
      <ModalContent>
        <div className="flex gap-x-4 mb-10">
          {profile?.avatar ? (
            <Avatar
              image={profile?.avatar}
              dataCy={FOLDER_CANDIDATE_AVATAR}
              width={16}
              height={16}
            />
          ) : (
            <Icon
              icon={<NoAvatarIcon className="text-neutralSecondary-60" />}
              dataCy={FOLDER_CANDIDATE_AVATAR}
              size="16"
            />
          )}
          <div className="flex flex-col justify-between">
            <div className="font-bold text-lg">{userName}</div>
            {profile?.residence?.value ? (
              <div className="flex gap-2 items-center text-neutralPrimary-20 h-6">
                <LocationPinIcon className="text-primary-70" />
                <span className="text-neutralPrimary-30">
                  {getName(profile?.residence?.value, CURRENT_LANGUAGE_CODE)}
                </span>
              </div>
            ) : (
              <span className="h-6" />
            )}
          </div>
        </div>
        <div className="flex w-full">
          <div className="flex flex-col w-1/2 border-r pr-6 gap-y-8">
            <div>
              <Subheader
                title={t(`${TRANSLATE_PATH}.${INFO}`)}
                Icon={<InfoIcon className="text-neutralPrimary-40" />}
              />
              <div className="flex flex-col pt-6 gap-y-4">
                <ThinLabel
                  dataCy={SENIORITY}
                  label={t(`${TRANSLATE_PATH}.${SENIORITY}`)}
                >
                  {profile?.seniority ? (
                    <span className={setSeniorityColor(profile.seniority)}>
                      {normalizedSeniority}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </ThinLabel>
                <ThinLabel
                  dataCy={PROFESSION}
                  label={t(`${TRANSLATE_PATH}.${PROFESSION}`)}
                >
                  {mainProfessionName ? (
                    <span>{mainProfessionName}</span>
                  ) : (
                    <span>-</span>
                  )}
                </ThinLabel>
                <ThinLabel dataCy={ROLE} label={t(`${TRANSLATE_PATH}.${ROLE}`)}>
                  {role ? <span>{role}</span> : <span>-</span>}
                </ThinLabel>
                <ThinLabel
                  dataCy={EXPERIENCE}
                  label={t(`${TRANSLATE_PATH}.${EXPERIENCE}`)}
                >
                  {experienceAmount.months || experienceAmount.years ? (
                    <span>
                      {`${
                        experienceAmount.years
                          ? t(`${TRANSLATE_PATH}.years`, {
                              count: experienceAmount.years,
                            })
                          : ""
                      } ${
                        experienceAmount.months > 0
                          ? t(`${TRANSLATE_PATH}.months`, {
                              count: experienceAmount.months,
                            })
                          : ""
                      }`}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </ThinLabel>
              </div>
            </div>
            <div>
              <Subheader
                title={t(`${TRANSLATE_PATH}.${AVAILABILITY}.header`)}
                Icon={<PassportIcon className="text-neutralPrimary-40" />}
              />
              <div className="flex flex-col pt-6 gap-y-4">
                <ThinLabel
                  dataCy={NATIONALITY}
                  label={t(`${TRANSLATE_PATH}.${NATIONALITY}`)}
                >
                  <span>{profile?.nationality[0]?.label}</span>
                </ThinLabel>
                <h4 className="uppercase text-neutralPrimary-40 -mb-3">
                  {t(`${TRANSLATE_PATH}.${PASSPORTS}`)}
                </h4>
                {profile?.passports.length ? (
                  <>
                    {profile.passports.map((p: PassportModel) => (
                      <div
                        className="flex justify-between items-center"
                        key={uid(p)}
                      >
                        <span>{p.country?.label}</span>
                        <span
                          className={classJoin(
                            isDocumentExpired(p.expirationDate)
                              ? "text-error-50"
                              : "",
                            "font-semibold",
                          )}
                        >
                          exp. {dayjs(p.expirationDate).format("DD.MM.YYYY")}
                        </span>
                      </div>
                    ))}
                  </>
                ) : (
                  <span>-</span>
                )}
                <h4 className="uppercase text-neutralPrimary-40 -mb-3">
                  {t(`${TRANSLATE_PATH}.${VISAS}`)}
                </h4>
                {profile?.visas.length ? (
                  <>
                    {profile.visas.map((v: VisaModel) => (
                      <div
                        className="flex justify-between items-center font-semibold"
                        key={uid(v)}
                      >
                        <span>{v.country?.label}</span>
                        <span
                          className={classJoin(
                            isDocumentExpired(v.expirationDate)
                              ? "text-error-50"
                              : "",
                            "font-semibold",
                          )}
                        >
                          {v.expirationDate ? (
                            `exp. ${dayjs(v.expirationDate).format(
                              "DD.MM.YYYY",
                            )}`
                          ) : (
                            <span>-</span>
                          )}
                        </span>
                      </div>
                    ))}
                  </>
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>
          </div>
          <div className="flex w-1/2 flex-col pl-6 gap-y-8">
            <div>
              <Subheader
                title={t(`${TRANSLATE_PATH}.${WORK}`)}
                Icon={<ClockIcon className="text-neutralPrimary-40" />}
              />
              <div className="flex flex-col pt-6 gap-y-4">
                <ThinLabel
                  dataCy={WORK}
                  label={t(`${TRANSLATE_PATH}.${COMFORTABLE_HOURS}`)}
                >
                  {profile?.workingHours?.comfortable?.start ? (
                    <div className="flex justify-between w-28">
                      {`${profile?.workingHours?.comfortable?.start}`}
                      <span>-</span>
                      {`${profile?.workingHours?.comfortable?.end}`}
                    </div>
                  ) : (
                    <span>-</span>
                  )}
                </ThinLabel>
                <ThinLabel
                  dataCy={WORK}
                  label={t(`${TRANSLATE_PATH}.${POSSIBLE_HOURS}`)}
                >
                  {profile?.workingHours?.possible?.start ? (
                    <div className="flex justify-between w-28">
                      {`${profile?.workingHours?.possible?.start}`}
                      <span>-</span>
                      {`${profile?.workingHours?.possible?.end}`}
                    </div>
                  ) : (
                    <span>-</span>
                  )}
                </ThinLabel>
                <div className="flex items-center justify-end text-neutralSecondary-41 gap-x-2">
                  <span className="font-bold">
                    {profile?.workingHours?.zone?.abbreviation}
                  </span>
                  <span>
                    {profile?.workingHours?.zone?.value.replace("_", " ")}
                  </span>
                </div>
              </div>
            </div>
            {profile?.languages.length ? (
              <div>
                <Subheader
                  title={t(`${TRANSLATE_PATH}.${LANGUAGES}`)}
                  Icon={<LanguageIcon className="text-neutralPrimary-40" />}
                />
                <div className="flex flex-col pt-6 gap-y-4">
                  {profile?.languages?.map((item) => (
                    <div className="flex gap-1 flex-col" key={uid(item)}>
                      <div className="flex justify-between items-center">
                        {`${flag(
                          languageCodeToCountryCode(item.language),
                        )} ${ISOLanguages.getName(item.language)}`}

                        <SkillBar
                          dataCy={`${SPECIALISTS}_${item.language}`}
                          total={5}
                          value={getSelectedNumber(item.level)}
                          height="h-2"
                          width="w-20"
                        />
                      </div>
                      <span className="text-neutralSecondary-41 text-sm">
                        {t(`profile.overview.languages.${item.level}`)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </ModalContent>
    </Modal2>
  );
};

export default SpecialistModal;
