import { ReactElement } from "react";
import Link from "../../components/link/link";
import { useTranslationWithNamespace } from "../../hooks";

const Empty2 = ({ section, onClick }: Empty2Props): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <div className="flex flex-col gap-y-5 items-center justify-center mt-14">
      <span className="uppercase text-lg text-neutralPrimary-40 tracking-widest">
        {t("profileSummary.emptyInfo", { section })}
      </span>
      <div className="flex items-center gap-x-1 text-neutralPrimary-20">
        {t("profileSummary.youCanAdd")}
        <Link type="button" onClick={onClick} dataCy={`link_${section}`}>
          {t("profileSummary.here")}
        </Link>
      </div>
    </div>
  );
};

export default Empty2;
