import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { clearRedirection, RedirectAlertModal } from "@ps/alert-modal";
import { ALL_PROSPECTS, MY_PROSPECTS } from "../../shared/routes";
import ProspectsContainer, { AllProspects } from "../ui";
import ProspectDetails from "../ui/prospectDetails";

const ProspectsRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  return (
    <ProspectsContainer>
      <RedirectAlertModal
        dispatch={dispatch}
        clearRedirection={clearRedirection}
      />
      <Switch>
        <Route path={`${path}/${ALL_PROSPECTS}`}>
          <AllProspects />
        </Route>
        {/* TODO temporary hidden because of HH2-2728 */}
        {/* <Route path={`${path}/${MY_PROSPECTS}`}> */}
        {/*  <MyProspects /> */}
        {/* </Route> */}
        {/* TODO temporary redirect because of HH2-2728 */}
        <Route path={`${path}/:${MY_PROSPECTS}`}>
          <Redirect to={`${path}/${ALL_PROSPECTS}`} />
        </Route>
        <Route path={`${path}/:id`}>
          <ProspectDetails />
        </Route>
        <Route>
          <Redirect to={`${path}/${ALL_PROSPECTS}`} />
        </Route>
      </Switch>
    </ProspectsContainer>
  );
};

export default ProspectsRoutes;
