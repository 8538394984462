import { ReactElement } from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { CertificateProps } from "../types";
import { TechStackTagsList, TimeRange, Description } from "../components";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  certificateContainer: {
    width: "40%",
    marginBottom: "50px",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: "#2A2D33",
    textTransform: "uppercase",
    fontFamily: "PoppinsBold",
    fontSize: "12px",
    marginBottom: "8x",
  },
});

const Certificate = ({
  title,
  issuedDate,
  expirationDate,
  techStack,
  url,
  isBlind,
}: CertificateProps): ReactElement => (
  <View>
    <View style={styles.certificateContainer}>
      <Text style={styles.title}>{title}</Text>
      <TimeRange times={[issuedDate, expirationDate]} isBlind={isBlind} />
      <View>
        <Description description={url} />
      </View>
      <TechStackTagsList stacks={techStack} isBlind={isBlind} />
    </View>
  </View>
);

export default Certificate;
