import { ReactElement } from "react";
import { Label } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { DATE_FORMAT } from "../../../common/constants";
import { DegreeCardProps } from "../types";
import {
  DEGREE,
  DESCRIPTION,
  END_DATE,
  FIELD_OF_STUDY,
  SCHOOL_NAME,
  START_DATE,
} from "../constants";
import { EXPERIENCE_EDUCATIONS_VIEW } from "../../../../../../shared/data-cy";

const DegreeCard = ({
  schoolName,
  fieldOfStudy,
  degree,
  startDate,
  endDate,
  description,
}: DegreeCardProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  const translate = (field: string) =>
    t(`profile.experience.cards.degree.${field}`);

  const BASE_PATH = `${EXPERIENCE_EDUCATIONS_VIEW}_${DEGREE}`;

  return (
    <div className="w-full grid grid-cols-3 gap-5 p-6">
      <Label
        text={translate(SCHOOL_NAME)}
        dataCy={`${BASE_PATH}_${SCHOOL_NAME}`}
      >
        {schoolName}
      </Label>
      <Label
        text={translate(FIELD_OF_STUDY)}
        dataCy={`${BASE_PATH}_${FIELD_OF_STUDY}`}
      >
        {fieldOfStudy}
      </Label>
      <Label text={translate(DEGREE)} dataCy={`${BASE_PATH}_${DEGREE}`}>
        {degree}
      </Label>
      <div className="w-min flex gap-8 justify-start col-span-3">
        <Label
          text={translate(START_DATE)}
          dataCy={`${BASE_PATH}_${START_DATE}`}
        >
          {startDate?.format(DATE_FORMAT)}
        </Label>
        <Label
          text={translate(END_DATE)}
          dataCy={`${BASE_PATH}_${END_DATE}`}
          additionalClassName="col-span-2"
        >
          {endDate
            ? endDate?.format(DATE_FORMAT)
            : `${t("profile.other.present")}`}
        </Label>
      </div>
      {description && (
        <div className="w-full whitespace-pre-wrap col-span-3">
          <Label
            text={translate(DESCRIPTION)}
            dataCy={`${BASE_PATH}_${DESCRIPTION}`}
            fontWeight="font-normal"
          >
            {description}
          </Label>
        </div>
      )}
    </div>
  );
};

export default DegreeCard;
