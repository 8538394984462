import { ReactElement } from "react";
import { StyleSheet, Text } from "@react-pdf/renderer";
import { DescriptionProps } from "../types";

const styles = StyleSheet.create({
  description: {
    color: "#2A2D33",
    fontSize: "10px",
    fontFamily: "PoppinsLight",
  },
});

const Description = ({ description }: DescriptionProps): ReactElement => (
  <Text style={styles.description}>{description}</Text>
);

export default Description;
