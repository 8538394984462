import { ReactElement } from "react";
import { Label, Tag, URLButton } from "@ps/ui-components";
import { useTranslationWithNamespace } from "../../../../../../hooks";
import { ContributionCardProps } from "../types";
import { DATE_FORMAT } from "../../../common/constants";
import {
  CONTRIBUTIONS,
  END_DATE,
  NAME,
  START_DATE,
  URL_PATH,
} from "../constants";
import { EXPERIENCE_ACCOMPLISHMENT_VIEW } from "../../../../../../shared/data-cy";
import { TECH_STACK } from "../../projects/constants";
import { DictionaryEntryModel } from "../../../../../../dictionaries";
import accomplishmentsGrid from "../../../common/styles";

const ContributionCard = ({
  endDate,
  name,
  startDate,
  techStack,
  url,
  isDeleting,
}: ContributionCardProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const BASE_PATH = `${EXPERIENCE_ACCOMPLISHMENT_VIEW}_${CONTRIBUTIONS}`;

  const translate = (field: string): string =>
    t(`profile.experience.cards.contribution.${field}`);

  return (
    <div className="relative w-full flex flex-col p-6">
      <div className={accomplishmentsGrid}>
        <Label text={translate(NAME)} dataCy={`${BASE_PATH}_${NAME}`}>
          {name}
        </Label>
        <Label
          text={translate(URL_PATH)}
          dataCy={`${BASE_PATH}_${URL_PATH}`}
          additionalClassName="gap-1"
        >
          <URLButton
            dataCy={`${BASE_PATH}_${URL_PATH}`}
            url={url}
            disabled={isDeleting}
          />
        </Label>
        <Label
          text={translate(START_DATE)}
          dataCy={`${BASE_PATH}_${START_DATE}`}
        >
          {startDate?.format(DATE_FORMAT)}
        </Label>
        <Label text={translate(END_DATE)} dataCy={`${BASE_PATH}_${END_DATE}`}>
          {endDate
            ? endDate?.format(DATE_FORMAT)
            : `${t("profile.other.present")}`}
        </Label>
      </div>
      <Label dataCy={`${BASE_PATH}_${TECH_STACK}`} text={translate(TECH_STACK)}>
        <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
          {techStack?.map((tech: DictionaryEntryModel) => (
            <Tag name={tech?.name} dataCy={tech?.id} key={tech?.id} />
          ))}
        </div>
      </Label>
    </div>
  );
};

export default ContributionCard;
