import { ReactElement, FC, SVGProps } from "react";
import { dashConverter } from "@ps/utils";
import { useTranslationWithNamespace } from "../../hooks";
// eslint-disable-next-line import/no-useless-path-segments
import TabsWithContainer from "../tabs";
import MiddleContent from "../middle-content";
import MiddleSection from "../middle-section";
// eslint-disable-next-line import/no-useless-path-segments
import Tab from "./tab";
import { TabsContainerProps } from "./types";

const TabsContainer = ({
  activeTab,
  children,
  handleOnTabChange,
  prefix,
  tabs,
  translationID,
  translationPath,
  width = "w-max",
  focusedTabId,
}: TabsContainerProps): ReactElement => {
  const { t } = useTranslationWithNamespace(translationID);

  const tabsIcons = Object.values(tabs).filter((key) =>
    Number.isNaN(Number(key)),
  );

  return (
    <MiddleSection dataCy={prefix}>
      <div className="w-full bg-neutralPrimary-100 px-8.5 border-b border-neutralSecondary-60 whitespace-nowrap">
        <TabsWithContainer
          activeTab={activeTab}
          dataCy={prefix}
          onChange={handleOnTabChange}
        >
          {Object.keys(tabs)
            .filter((key) => !Number.isNaN(Number(tabs[key])))
            .map((tab, index) => (
              <Tab
                focusedTabId={focusedTabId}
                key={dashConverter(tab)}
                to={dashConverter(tab).toLocaleLowerCase()}
                label={t(`${translationPath}.${tab.toLocaleLowerCase()}`)}
                width={width}
                Icon={tabsIcons[index] as FC<SVGProps<SVGSVGElement>>}
              />
            ))}
        </TabsWithContainer>
      </div>
      <MiddleContent dataCy={prefix}>
        {children || <div>In progress...</div>}
      </MiddleContent>
    </MiddleSection>
  );
};

export default TabsContainer;
