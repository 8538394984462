import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../../hooks";
import { ReactComponent as NoResultsIcon } from "../../../images/reports/noResults.svg";

const NoResults = (): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="flex flex-col gap-3 select-none">
      <div className="flex flex-col items-center gap-16 mt-12">
        <NoResultsIcon />
        <div className="flex flex-col items-center gap-6">
          <span className="font-semibold text-neutralPrimary-20 text-3xl">
            {t("reports.noResult")}
          </span>
          <span className="text-neutralPrimary-30 text-xl">
            {t("reports.tryAgainInformation")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default NoResults;
