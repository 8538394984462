import { Dayjs } from "dayjs";
import { KeyboardEvent, ReactElement } from "react";
import DateFormats from "../helpers/dateFormats";
import { getStartOfDecade } from "../helpers/time/getDecade";
import getTimeUnitButtonStyle from "../helpers/style/getTimeUnitButtonStyle";
import {
  CalendarDisplayModes,
  DatepickerMode,
  DatepickerPickerEnum,
  DisplayModeProps,
} from "../types";
import styles from "../styles.module.css";
import { Keys } from "../../../shared";

const YearDisplayMode = ({
  setDisplayedMonth,
  currentDisplayedMonth,
  setDisplayMode,
  state,
  picker,
  selectedDatesActions,
  mode,
  disabledDate,
  closeCalendar,
}: DisplayModeProps): ReactElement => {
  const startOfDecade = getStartOfDecade(currentDisplayedMonth);
  const YEARS_ARRAY = [
    startOfDecade.subtract(1, "year"),
    ...new Array(11)
      .fill(0)
      .map((_, index) => startOfDecade.add(index, "year")),
  ];

  const handleYearClick = (year: Dayjs) => () => {
    setDisplayedMonth?.(year);
    if (picker === DatepickerPickerEnum.YEAR) {
      selectedDatesActions?.onClick(year.startOf("year"))();
    } else if (picker === DatepickerPickerEnum.QUARTER) {
      setDisplayMode?.(CalendarDisplayModes.QUARTERS);
    } else {
      setDisplayMode?.(CalendarDisplayModes.MONTHS);
    }
  };

  const hoverBorderCommon =
    "border-t border-b border-primary-50 border-opacity-30 border-dashed";
  const getButtonStyle = getTimeUnitButtonStyle({
    stylesSelected: {
      notSelected: "bg-transparent text-neutralPrimary-30",
      selectedInBetween: "bg-primary-50 bg-opacity-30 text-primary-100",
      selectedFirstInRow:
        "bg-primary-50 text-primary-100 rounded-l-md bg-opacity-100",
      selectedLastInRow:
        "bg-primary-50 text-primary-100 rounded-r-md bg-opacity-100",
      notInCurrentSelection: "text-neutralPrimary-30 opacity-30",
    },
    stylesHover: {
      hoverInBetween: `${hoverBorderCommon} bg-opacity-50`,
      nextUnitAfterHoverSecondInput: "rounded-l-md",
      prevUnitBeforeHoverFirstInput: "rounded-r-md",
      hoverFirstInRow: `${hoverBorderCommon} rounded-l-md border-l bg-opacity-70`,
      hoverLastInRow: `${hoverBorderCommon} rounded-r-md border-r bg-opacity-70`,
    },
    compareMode: DatepickerPickerEnum.YEAR,
  })({
    selectedDates: state.selected,
    hoveringOverDate:
      mode === DatepickerMode.RANGE_PICKER &&
      picker === DatepickerPickerEnum.YEAR
        ? state.hover
        : undefined,
    currentInput: state.input,
  });

  const rangeStart = state.selected?.[0];
  const rangeEnd = state.selected?.[1];

  const hoverStyles =
    "hover:bg-primary-50 hover:bg-opacity-80 hover:text-primary-100";
  const hoverStylesIfSingleMode =
    mode === DatepickerMode.DATE_PICKER || !rangeStart
      ? `${hoverStyles} hover:rounded-md`
      : hoverStyles;

  const [FIRST_YEAR_INDEX, LAST_YEAR_INDEX] = [0, 11];

  return (
    <div
      className={`grid w-full h-min ${styles.year_grid} grid-cols-3 gap-y-5 px-2 pb-2 justify-items-center`}
    >
      {YEARS_ARRAY.map((year, index) => (
        <div
          className="flex items-center justify-center min-h-full w-full"
          key={year.year()}
          onPointerLeave={selectedDatesActions?.onPointerLeave}
        >
          <button
            className={`flex items-center justify-center w-full border-t border-b
            border-neutralPrimary-100 ${hoverStylesIfSingleMode} ${getButtonStyle(
              year,
              !(index === FIRST_YEAR_INDEX || index === LAST_YEAR_INDEX),
            )} ${
              rangeStart && !rangeEnd && rangeStart.isSame(year, "year")
                ? "bg-primary-50 text-primary-100"
                : ""
            }  cursor-pointer`}
            onClick={handleYearClick(year)}
            onPointerEnter={selectedDatesActions?.onPointerEnter(year)}
            disabled={
              picker === DatepickerPickerEnum.YEAR &&
              disabledDate?.(year, state.selected)
            }
            onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
              if (event.key === Keys.ESC) {
                event.preventDefault();
                closeCalendar();
                event.stopPropagation();
              }
            }}
          >
            <div
              className={`flex items-center justify-center rounded-md
            focus:outline-none px-2 py-0.5 w-full h-full disabled:pointer-events-none disabled:text-neutralSecondary-30`}
            >
              {year.format(DateFormats.YEAR)}
            </div>
          </button>
        </div>
      ))}
    </div>
  );
};

export default YearDisplayMode;
