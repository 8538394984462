import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { WIZARD } from "../../shared/data-cy";
import ProgressBar from "../progress-bar";
import { WizardProps } from "./types";

const Wizard = ({
  labels,
  step,
  additionalClassNames,
  children,
  dataCy,
}: WizardProps): ReactElement => (
  <div className={classJoin(additionalClassNames, "text-neutralPrimary-20")}>
    <ProgressBar
      step={step}
      totalSteps={labels?.length}
      labels={labels}
      dataCy={`${WIZARD}-progress-bar-${dataCy}`}
      additionalClassNames="gap-8"
      itemsClassNames="items-baseline"
    />
    {children}
  </div>
);

export default Wizard;
