import { ReactElement, useState } from "react";
import { useDispatch } from "redux-react-hook";
import imageCompression from "browser-image-compression";
import { Avatar, Button, Card, Icon, ImageUploader } from "@ps/ui-components";
import { classJoin } from "@ps/utils";
import { ReactComponent as NoAvatarIcon } from "../../../images/no-avatar.svg";
import { ReactComponent as BinIcon } from "../../../images/bin.svg";
import { MAIN_EDIT_IMAGE, MAIN_OTHERS_SECTION } from "../../../shared/data-cy";
import { OthersMapState, OthersMapStateReturn, OthersProps } from "./types";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { IMAGE } from "./constants";
import { FilesService } from "../../../files-domain";
import { FileType } from "../../../files-domain/api/openapi-client";
import { MyProfileService } from "../../services";
import { fetchMyProfile } from "../../store";
import OcrUploader from "../OCRWizard/uploader";
import { SPECIALIST } from "../../../shared/constants";

const BASE_PATH = "profile.overview";

const Others = ({ canEdit }: OthersProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();

  const [uploadProgressVal, setUploadProgressVal] = useState(0);

  const mapState = (state: OthersMapState): OthersMapStateReturn => ({
    editMode: state.editMode,
    myProfile: state?.profiles?.myProfile || {},
    technologyDict: state?.dictionaries?.technology || [],
  });

  const { editMode, myProfile, technologyDict } =
    useMappedStateSelector(mapState);

  const handleGetImage = async (newImage: string) => {
    const imagePng = await imageCompression
      .getFilefromDataUrl(newImage, `${myProfile.id}.png`)
      .then((result) => result);
    const response = await FilesService.createFile(
      FileType.Avatar,
      {
        name: myProfile.id,
      },
      imagePng,
      setUploadProgressVal,
    );
    await MyProfileService.updateProfile({
      ...myProfile,
      avatar: response,
    });
    await fetchMyProfile(dispatch, technologyDict);
  };

  const handleDeleteImage = async () => {
    await MyProfileService.updateProfile({
      ...myProfile,
      avatar: null,
    });
    await fetchMyProfile(dispatch, technologyDict);
  };

  return (
    <div className="h-max" data-cy={MAIN_OTHERS_SECTION}>
      <Card
        dataCy={MAIN_OTHERS_SECTION}
        additionalClassName="h-full"
        additionalCardClassName={classJoin(
          "flex flex-col justify-center items-center gap-5 h-32 w-full p-8 mb-5 shadow-none",
          !editMode || editMode === IMAGE
            ? "opacity-100"
            : "opacity-40 pointer-events-none",
        )}
      >
        {myProfile?.avatar ? (
          <Avatar
            image={myProfile?.avatar}
            dataCy={IMAGE}
            width={32}
            height={32}
          />
        ) : (
          <Icon
            icon={
              <NoAvatarIcon className=" fill-current text-neutralSecondary-60 w-full h-full" />
            }
            dataCy={IMAGE}
            width="32"
            height="32"
          />
        )}
        {canEdit ? (
          <div className="flex items-center gap-4">
            <ImageUploader
              disabled={editMode}
              dataCy={MAIN_EDIT_IMAGE}
              getImage={handleGetImage}
              uploaded={!!myProfile?.avatar}
              uploadProgressVal={uploadProgressVal}
              setUploadProgressVal={setUploadProgressVal}
            />
            {myProfile?.avatar && (
              <Button
                dataCy={MAIN_EDIT_IMAGE}
                onClick={handleDeleteImage}
                variant="secondary"
                disabled={editMode}
              >
                <div className="flex items-center gap-2">
                  <BinIcon />
                  <span>{t(`${BASE_PATH}.actions.delete`)}</span>
                </div>
              </Button>
            )}
          </div>
        ) : (
          <></>
        )}
      </Card>
      {myProfile?.experience?.projects?.length &&
      myProfile?.profile === SPECIALIST ? (
        <OcrUploader verticalView disabled={editMode} />
      ) : null}
    </div>
  );
};

export default Others;
