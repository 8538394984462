import { KeyboardEvent, ReactElement, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import {
  Avatar,
  Icon,
  ThemeToggle,
  TopSection,
  useThemeMode,
} from "@ps/ui-components";
import { classJoin, getSlug, useMappedStateSelector } from "@ps/utils";
import { useHistory, useLocation } from "react-router-dom";
import {
  DICTIONARIES,
  fetchDictionary,
  fetchMyProfileError,
  fetchMyProfileStart,
  fetchMyProfileSuccess,
  MyProfileService,
  UserService,
} from "@ps/hh";
import { ocrProcessingSuccess } from "@ps/hh/src/profile-domain/store";
import { isAPIError } from "@ps/api-utils";
import Menu from "app/menu";
import { MAIN_APP, ORG_LOGOUT, THEME_TOGGLE } from "../shared/data-cy";
import { ReactComponent as LogoutIcon } from "../images/main/logout.svg";
import { ReactComponent as LineIcon } from "../images/main/line.svg";
import useKeycloakLogout from "./hooks/use-keycloak-logout";
import { MainMapState, MainMapStateReturn, MainProps } from "./types";
import { ReactComponent as NoAvatarIcon } from "../images/main/no-avatar.svg";
import { focusVisibleStyles, Keys } from "../shared";
import { IS_PRODUCTION, SPECIALIST } from "../shared/constants";

const Main = ({ children }: MainProps): ReactElement => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isNotifyBellVisible = !IS_PRODUCTION;

  const { logout } = useKeycloakLogout();
  const { theme, toggle, set, isHighContrast } = useThemeMode();

  const mapState = (state: MainMapState): MainMapStateReturn => ({
    myProfile: state?.profiles?.myProfile || {},
    technologyDict: state?.dictionaries?.technology || [],
    myId: state?.login?.sub,
  });

  const { myProfile, technologyDict } = useMappedStateSelector(mapState, "hh");

  const { myId } = useMappedStateSelector(mapState);

  const fetchOCRCheck = async (id): Promise<void> => {
    const result = await UserService.fetchUserOCRFlag(id);
    if (result) {
      const ocrData = await MyProfileService.fetchMyOCRData(technologyDict);
      dispatch(ocrProcessingSuccess(ocrData));
      history.push("/profile/wizard");
    }
  };

  useEffect(() => {
    [
      DICTIONARIES.DOMAIN,
      DICTIONARIES.PROFESSION,
      DICTIONARIES.PROJECT_ROLE,
      DICTIONARIES.SENIORITY,
      DICTIONARIES.TECHNOLOGY,
      DICTIONARIES.OPERATIVE_PROFESSIONS,
    ].map((name) => fetchDictionary(name, dispatch));
  }, []);

  useEffect(() => {
    if (myId && myProfile.profile === SPECIALIST) {
      fetchOCRCheck(myId);
    }
  }, [myId, myProfile]);

  const fetchData = async (): Promise<void> => {
    dispatch(fetchMyProfileStart());
    const result = await MyProfileService.fetchProfile(technologyDict);
    if (isAPIError(result)) {
      if (result?.status === 404) {
        history.push("/choose-profile");
      }
      dispatch(fetchMyProfileError(result));
    } else {
      dispatch(fetchMyProfileSuccess(result));
    }
  };

  useEffect(() => {
    if (technologyDict.length) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [technologyDict]);

  const handleOnLogout = (): void => {
    logout();
    localStorage.removeItem("token");
  };

  const navTitle: string = pathname.split("/").slice(-2, -1)[0];

  return (
    <div className="flex h-full">
      <Menu />
      <section className="content-container h-full justify-end">
        <TopSection
          title={navTitle || getSlug(pathname)}
          onClickContrast={() =>
            isHighContrast ? set("light") : set("highContrast")
          }
          isNotifyBellVisible={isNotifyBellVisible}
          avatar={
            myProfile?.avatar ? (
              <Avatar
                image={myProfile?.avatar}
                dataCy={`${MAIN_APP}_top_avatar`}
                width={8}
                height={8}
              />
            ) : (
              <Icon
                icon={
                  <NoAvatarIcon className="fill-current text-neutralSecondary-60 w-full h-full" />
                }
                dataCy={`${MAIN_APP}_top_noAvatar`}
                width="8"
                height="8"
              />
            )
          }
        >
          <div className="flex relative items-center gap-5">
            <LineIcon className="text-neutralSecondary-80 h-full ml-5" />
            <Icon
              dataCy={ORG_LOGOUT}
              backgroundColor="bg-neutralPrimary-100"
              width="8"
              height="8"
              icon={
                <LogoutIcon
                  onClick={handleOnLogout}
                  className={classJoin(
                    "text-primary-51 cursor-pointer rounded-md",
                    focusVisibleStyles,
                  )}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(event: KeyboardEvent<SVGSVGElement>) => {
                    if (event.key === Keys.ENTER) handleOnLogout();
                  }}
                />
              }
            />
            {isHighContrast ? (
              <div style={{ width: "40px" }} className="ml-8" />
            ) : (
              <ThemeToggle
                dataCy={THEME_TOGGLE}
                checked={theme === "dark"}
                onChange={toggle}
                additionalClassName="ml-8"
              />
            )}
          </div>
        </TopSection>
        <div className="h-full overflow-y-auto">{children}</div>
      </section>
    </div>
  );
};

export default Main;
