import { useHistory, useLocation } from "react-router-dom";
import { ReactElement, useEffect, useState } from "react";
import { hasPermissions, TabsContainer } from "@ps/ui-components";
import { dashConverter, getSlug, permissionsMappers } from "@ps/utils";
import { useDispatch } from "redux-react-hook";
import { NAMESPACE, TIME_TRACKER_TABS, TIMESHEET } from "../shared/constants";
import {
  MainProps,
  TimeTrackerContainerMapState,
  TimeTrackerContainerMapStateReturn,
} from "./timesheet/types";
import { useMappedStateSelector } from "../hooks";
import { fetchUserHolidaysTemplate } from "../store";

const TimeTrackerContainer = ({ children }: MainProps): ReactElement => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [focusedTabId, setFocusedTabId] = useState("");

  const mapState = (
    state: TimeTrackerContainerMapState,
  ): TimeTrackerContainerMapStateReturn => ({
    allPermissions: state.permissions || [],
  });

  const { allPermissions } = useMappedStateSelector(mapState, "");

  useEffect(() => {
    fetchUserHolidaysTemplate(dispatch);

    setFocusedTabId("tabs-time-tracker-timesheet");
  }, []);

  useEffect(() => {
    const element = document.getElementById("tabs-time-tracker-timesheet");
    element?.focus();
  }, [focusedTabId]);

  const defaultTab: string =
    getSlug(dashConverter(pathname)) === NAMESPACE
      ? `/${NAMESPACE}/${TIMESHEET}`
      : getSlug(dashConverter(pathname));
  const [activeTab, setActiveTab] = useState<string>(defaultTab);

  useEffect(() => {
    setActiveTab(getSlug(dashConverter(pathname)));
  }, [pathname, activeTab]);

  const handleOnTabChange = (path: string): void => {
    history.push(`/${NAMESPACE}/${path}`);
    setActiveTab(path);
  };

  return (
    <TabsContainer
      activeTab={activeTab}
      handleOnTabChange={handleOnTabChange}
      prefix={NAMESPACE}
      focusedTabId={focusedTabId}
      tabs={permissionsMappers.filterTabsWithPermissions(
        TIME_TRACKER_TABS,
        hasPermissions,
        allPermissions,
      )}
      translationID={NAMESPACE}
      translationPath="tabs"
    >
      {children}
    </TabsContainer>
  );
};

export default TimeTrackerContainer;
