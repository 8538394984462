import { ReactElement } from "react";
import { Empty2 } from "@ps/ui-components";
import { ExpProps } from "../types";
import OtherSummaryHeader from "./otherSummaryHeader";
import { ReactComponent as ProjectsIcon } from "../../../../images/profile-summary/project.svg";
import { useTranslationWithNamespace } from "../../../../hooks";

const Projects = ({
  children,
  filled,
  onAddOrEditClick,
}: ExpProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  return (
    <div className="flex flex-col">
      <OtherSummaryHeader
        title={t("profile.experience.sections.projects")}
        Icon={<ProjectsIcon className="text-neutralSecondary-41" />}
        withButton
        onButtonClick={onAddOrEditClick}
        filled={filled}
      />
      {filled ? (
        children
      ) : (
        <Empty2
          section={t("profile.experience.sections.projects")}
          onClick={onAddOrEditClick}
        />
      )}
    </div>
  );
};

export default Projects;
