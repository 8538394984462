import { colorFormatters } from "../formatters";
import {
  IAPIProfessionSkills,
  IAPITechnologySkill,
  IPrepareBestSkillsWithColorsOutput,
  IPrepareColorFromPercentageOutput,
  IScale,
} from "./types";

export const prepareColorFromPercentage = (
  scaleLength: number,
  skillNormalizedScore: number,
  colors?: IScale[],
): IPrepareColorFromPercentageOutput => {
  const scale = (scaleLength * skillNormalizedScore) / 100;

  const flooredDown = Math.floor(scale);

  if (skillNormalizedScore === 100) {
    return {
      color: colors?.find((el) => el.score === flooredDown)?.color,
      score: flooredDown,
    };
  }

  return {
    color: colors?.find((el) => el.score === flooredDown)?.color,
    score: flooredDown,
  };
};

const getScale = (
  skillsByProfession: IAPIProfessionSkills[],
  mainProfession?: string,
): IScale[] => {
  if (!mainProfession) {
    return skillsByProfession[0].scale;
  }
  return (
    skillsByProfession?.find((item) => item.profession === mainProfession)
      ?.scale || skillsByProfession[0].scale
  );
};

export const prepareBestSkillsWithColors = (
  skill: IAPITechnologySkill,
  skillsByProfession: IAPIProfessionSkills[],
  mainProfession?: string,
): IPrepareBestSkillsWithColorsOutput => {
  const colorsScale = getScale(skillsByProfession, mainProfession);
  const colorAndScore = prepareColorFromPercentage(
    colorsScale?.length - 1 || 0,
    skill.score,
    colorsScale,
  );

  return {
    ...colorAndScore,
    color: colorFormatters.prepareRGBObjectToHEX(colorAndScore.color),
  };
};
