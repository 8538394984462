/* eslint @typescript-eslint/no-explicit-any: 0 */
import {
  COURSES,
  COURSE_NAME,
  DEGREE,
  EDUCATION,
  FIELD_OF_STUDY,
  SCHOOL_NAME,
  TRAINER,
} from "../experience/sections/education/constants";
import {
  DESCRIPTION,
  DOMAIN,
  END_DATE,
  NAME,
  PROJECTS,
  PROJECT_ROLES,
  RESPONSIBILITY,
  TECH_STACK,
} from "../experience/sections/projects/constants";
import {
  AVAILABILITY,
  BIO,
  CAREER_SPAN,
  COMFORTABLE,
  GAP_MONTHS,
  GOALS_CURRENT_YEAR,
  LANGUAGES,
  NATIONALITY,
  NOTE,
  PASSPORTS,
  POSSIBLE,
  PROFESSIONS,
  RESIDENCE,
  ROLES,
  SENIORITY,
  START_DATE,
  TECHNOLOGIES,
  VISAS,
  WORKING_HOURS,
  ZONE,
} from "../overview/constants";
import { Data } from "./types";

export const prepareEmptyValues = (
  value: string,
  educationType?: string,
): { [key: string]: unknown } => {
  switch (value) {
    case BIO:
      return { shortInformation: "" };
    case "experience":
      return {
        [PROFESSIONS]: {
          main: "",
          secondary: [],
        },
        [SENIORITY]: "",
        [ROLES]: [],
        [CAREER_SPAN]: {
          [START_DATE]: "",
          [GAP_MONTHS]: {
            years: "",
            months: "",
          },
        },
      };
    case AVAILABILITY:
      return {
        [RESIDENCE]: "", // TODO to fix - Clear doesn't work
        [NATIONALITY]: [], // TODO to fix - Clear doesn't work
        [PASSPORTS]: [{ country: "", expirationDate: undefined }],
        [VISAS]: [{ country: "", expirationDate: undefined }],
      };
    case WORKING_HOURS:
      return {
        [WORKING_HOURS]: {
          [COMFORTABLE]: { start: "", end: "" },
          [POSSIBLE]: { start: "", end: "" },
          [ZONE]: {
            label: "",
            value: "",
          },
        },
      };
    case LANGUAGES:
      return {
        [LANGUAGES]: [{ language: "", level: "" }],
      };
    case GOALS_CURRENT_YEAR:
      return {
        [GOALS_CURRENT_YEAR]: {
          [NOTE]: "", // TODO to fix - Clear doesn't work
          [TECHNOLOGIES]: [], // TODO to fix - Clear doesn't work
        },
      };
    case PROJECTS:
      return {
        [PROJECTS]: {
          [NAME]: "",
          [DOMAIN]: null,
          [START_DATE]: "",
          [END_DATE]: "",
          [DESCRIPTION]: "",
          [RESPONSIBILITY]: "",
          [PROJECT_ROLES]: null,
          [TECH_STACK]: [],
        },
      };
    case DEGREE:
      return {};
    case COURSES:
      return {};
    case EDUCATION:
      return educationType === COURSES
        ? {
            [TRAINER]: "",
            [COURSE_NAME]: "",
            [START_DATE]: "",
            [END_DATE]: "",
            [DESCRIPTION]: "",
          }
        : {
            [SCHOOL_NAME]: "",
            [FIELD_OF_STUDY]: "",
            [DEGREE]: "",
            [START_DATE]: "",
            [END_DATE]: "",
            [DESCRIPTION]: "",
          };
    default:
      return {};
  }
};

export const getSourceText = (data: Data, subStep?: number): string => {
  const result = Array.isArray(data)
    ? data?.[subStep]?.sourceText || ""
    : data || "";

  return result.trim();
};
