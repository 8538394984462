import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
// eslint-disable-next-line prodigal-son/no-use-mapped-state
import { useDispatch, useMappedState } from "redux-react-hook";
import { ReactElement, useEffect } from "react";
import { hhPermissions } from "@ps/hh";
import { RestrictedRoute } from "@ps/app";
import { hasPermissions } from "@ps/ui-components";
import {
  CALENDAR,
  MY_HOLIDAYS,
  MYSCHEDULE,
  DASHBOARD,
  REPORTS,
} from "../shared/routes";
import { fetchProjectList } from "../store";
import {
  Reports,
  Dashboard,
  TimeTrackerContainer,
  Calendar,
  MySchedule,
  TimeLog,
  MyHolidays,
} from "../ui";
import { PROJECT_FULL_ACCESS } from "../shared/permissions";
import { TIMESHEET } from "../shared/constants";
import { TimeLogContextProvider } from "../contexts/TimelogContext";

const TimeTrackerRoutes = (): ReactElement => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  const mapState = (state: {
    permissions: string[];
  }): { permissions: string[] } => ({
    permissions: state?.permissions || [],
  });

  // eslint-disable-next-line prodigal-son/no-use-mapped-state
  const { permissions } = useMappedState(mapState) as {
    permissions: string[] | [];
  };

  useEffect(() => {
    if (permissions?.length) {
      fetchProjectList(
        hasPermissions(permissions, hhPermissions.PROJECT_FULL_ACCESS),
        dispatch,
      );
    }
  }, [permissions]);

  return (
    <TimeTrackerContainer>
      <Switch>
        <Route path={`${path}/${DASHBOARD}`}>
          <Dashboard />
        </Route>
        <Route path={`${path}/${TIMESHEET}`}>
          <TimeLogContextProvider>
            <TimeLog />
          </TimeLogContextProvider>
        </Route>
        <Route path={`${path}/${MYSCHEDULE}`}>
          <MySchedule />
        </Route>
        <Route path={`${path}/${CALENDAR}`}>
          <Calendar />
        </Route>
        <Route path={`${path}/${MY_HOLIDAYS}`}>
          <MyHolidays />
        </Route>
        <RestrictedRoute
          path={`${path}/${REPORTS}`}
          permissions={[PROJECT_FULL_ACCESS]}
        >
          <Reports />
        </RestrictedRoute>
        <Route path={path}>
          <Redirect to={`${path}/${TIMESHEET}`} />
        </Route>
      </Switch>
    </TimeTrackerContainer>
  );
};

export default TimeTrackerRoutes;
