import { createContext, useContext } from "react";
import { HolidaysTemplateModel } from "../models";
import { HolidaysTemplatesContextProps } from "./types";

const initialState: HolidaysTemplatesContextProps = {
  currentTemplate: {} as HolidaysTemplateModel,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentTemplate: () => {},
  recentlyEditedTemplateId: "",
  setRecentlyEditedTemplateId: () => "",
};

export const HolidaysTemplatesContext =
  createContext<HolidaysTemplatesContextProps>(initialState);

export const useHolidaysTemplatesContext =
  (): HolidaysTemplatesContextProps => {
    const context = useContext(HolidaysTemplatesContext);
    if (!context) {
      throw new Error(
        "Holidays Templates compound components cannot be rendered outside the Holidays Templates component",
      );
    }
    return context;
  };
