import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { ReactComponent as UnhappyDevIcon } from "../../images/project/unhappyDev.svg";
import { ReactComponent as LowProfitIcon } from "../../images/project/lowProfit.svg";
import { ReactComponent as RiskClosureIcon } from "../../images/project/riskClosure.svg";
import { ReactComponent as OtherReasonsIcon } from "../../images/project/otherReasons.svg";
import { ReactComponent as OnProjectIcon } from "../../images/project/onProject.svg";
import { ReactComponent as WithoutProjectIcon } from "../../images/project/no-project.svg";
import { ReactComponent as ExceptOnProjectIcon } from "../../images/project/exceptOnProject.svg";
import {
  UNHAPPY_DEV,
  LOW_PROFIT,
  RISK_OF_CLOSURE,
  ON_PROJECT,
  WITHOUT_PROJECT,
  EXCEPT_ON_PROJECT,
} from "../constants";

const iconCommonStyles = "w-5 h-5";

const matchIconToFeedback = (feedback: string): ReactElement => {
  switch (feedback) {
    case UNHAPPY_DEV:
      return (
        <UnhappyDevIcon
          className={classJoin("text-primary-60", iconCommonStyles)}
        />
      );
    case LOW_PROFIT:
      return (
        <LowProfitIcon
          className={classJoin("text-error-50", iconCommonStyles)}
        />
      );
    case RISK_OF_CLOSURE:
      return (
        <RiskClosureIcon
          className={classJoin("text-error-50", iconCommonStyles)}
        />
      );
    case ON_PROJECT:
      return (
        <OnProjectIcon
          className={classJoin("text-primary-50", iconCommonStyles)}
        />
      );
    case WITHOUT_PROJECT:
      return (
        <WithoutProjectIcon
          className={classJoin("text-neutralPrimary-50", iconCommonStyles)}
        />
      );
    case EXCEPT_ON_PROJECT:
      return (
        <ExceptOnProjectIcon
          className={classJoin("text-error-40", iconCommonStyles)}
        />
      );
    default:
      return (
        <OtherReasonsIcon
          className={classJoin("text-neutralPrimary-30", iconCommonStyles)}
        />
      );
  }
};

export default matchIconToFeedback;
