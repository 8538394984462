import APIService from "../api/bench-api";
import { handleError } from "../../shared";
import { benchUsersFromAPIToModel } from "../models";

const BenchService = (() => {
  const fetchBenchUsers = async () => {
    try {
      const response = await APIService.API.getUsers();
      if (response?.data) return benchUsersFromAPIToModel(response.data);
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  const updateBenchUserFolderNote = async (
    benchUserId: string,
    folderId: string,
    notes: string,
  ) => {
    try {
      return APIService.API.updateBenchUserFolder(benchUserId, folderId, {
        notes,
      });
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    fetchBenchUsers,
    updateBenchUserFolderNote,
  };
})();

export default BenchService;
