import { useHistory, useLocation } from "react-router-dom";
import { ReactElement, useState, useEffect } from "react";
import { hasPermissions, TabsContainer } from "@ps/ui-components";
import { permissionsMappers, getSlug } from "@ps/utils";
import { NAMESPACE, PROJECT_TABS } from "../../shared/constants";
import { ALL_PROJECTS } from "../../shared/routes";
import {
  MainProps,
  ProjectContainerMapState,
  ProjectContainerMapStateReturn,
} from "./types";
import { useMappedStateSelector } from "../../hooks";

const ProjectContainer = ({ children }: MainProps): ReactElement => {
  const history = useHistory();
  const { pathname } = useLocation();

  const defaultTab =
    getSlug(pathname) === "project" ? ALL_PROJECTS : getSlug(pathname);

  const [activeTab, setActiveTab] = useState<string>(defaultTab);

  useEffect(() => {
    setActiveTab(getSlug(pathname));
  }, [pathname, activeTab]);

  const mapState = (
    state: ProjectContainerMapState,
  ): ProjectContainerMapStateReturn => ({
    editMode: state.editMode,
    allPermissions: state.permissions || [],
  });

  const { editMode } = useMappedStateSelector(mapState);
  const { allPermissions } = useMappedStateSelector(mapState, "");

  const handleOnTabChange = (path: string) => {
    history.push(path);

    history.listen(() => setActiveTab(path));
    if (!editMode) setActiveTab(path);
  };

  return (
    <TabsContainer
      activeTab={activeTab}
      handleOnTabChange={handleOnTabChange}
      prefix={`${NAMESPACE}_project`}
      tabs={permissionsMappers.filterTabsWithPermissions(
        PROJECT_TABS,
        hasPermissions,
        allPermissions,
      )}
      translationID={NAMESPACE}
      translationPath="project.tabs"
      width="w-auto"
    >
      {children}
    </TabsContainer>
  );
};

export default ProjectContainer;
