import { getAxiosConfig } from "@ps/api-utils";
import { DefaultApi } from "./openapi-client";

class APIService {
  public API: DefaultApi;

  private token?: string;

  constructor() {
    const baseConfig = getAxiosConfig();
    this.API = new DefaultApi(baseConfig);
  }

  private setApi() {
    let axiosInstance;
    const baseConfig = getAxiosConfig();

    this.API = new DefaultApi(
      {
        ...baseConfig,
        accessToken: this.token,
      },
      undefined,
      axiosInstance,
    );
  }

  public setConfig(token: string) {
    this.token = token;
    this.setApi();
  }
}

export default new APIService();
