const prepareDictionarySearchSelect = (
  dictionary: any,
  idField = "",
  valueField: string,
  labelField: string,
  sortByField: string,
) =>
  dictionary
    ?.sort((a: any, b: any) => (a[sortByField] < b[sortByField] ? -1 : 1))
    ?.map((item: any) => {
      const result = {
        value: item[valueField],
        label: item[labelField],
      };

      return idField === "" ? result : { ...result, id: item[idField] };
    });

export default prepareDictionarySearchSelect;
