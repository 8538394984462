import {
  formatHoursAndMinutes,
  getItemFromDictionary,
  UTCToDayjs,
} from "@ps/utils";
import { getName } from "i18n-iso-countries";
import moment from "moment-timezone";
import { CURRENT_LANGUAGE_CODE } from "../../../i18n/constants";
import {
  DiscoveryTeamMemberAPIModel,
  NeedAPIModel,
  NoteAPIModel,
  ProspectAPIModel,
  ProspectModel,
  ProspectSummaryAPIModel,
  SingleProspectListModel,
  NeedModel,
} from "../types";
import { DictionaryModel } from "../../../dictionaries";

const prepareCountryToDisplay = (country: string) => ({
  label: getName(country, CURRENT_LANGUAGE_CODE, { select: "official" }),
  value: country,
});

const prospectFromAPIToModel = (
  data: ProspectAPIModel,
  technologyDict: DictionaryModel,
): ProspectModel => ({
  archived: data.archived,
  details: {
    prospectName: data.details.projectName,
    clientName: {
      id: data.details.clientId,
      value: data.details.clientId,
      label: data.details.clientName,
    },
    pm: data.details?.pm,
    bd: data.details?.bd,
    techOwner: data.details?.techOwner,
    note: data.details?.note,
    contactPersonName: data.details?.contactPersonName,
    contactPersonEmail: data.details?.contactPersonEmail,
    prospectCountry: data.details?.projectCountry
      ? prepareCountryToDisplay(data.details.projectCountry)
      : null,
    prospectCity: data.details?.projectCity,
    domains: data.details?.domains,
    phoneNumber: data.details?.phoneNumber,
  },
  needs: data.needs.map(
    (need: NeedAPIModel): NeedModel => ({
      itemId: need.id,
      profession: need.profession,
      seniority: need.seniority,
      quantity: need.quantity,
      expectedRate: need?.expectedRate
        ? {
            rate: need?.expectedRate?.rate?.toString(),
            rateUnit: need.expectedRate.rateSpan,
            currency: need.expectedRate.currency,
            engagement: need.expectedRate.engagementSpan,
          }
        : {
            rate: null,
            rateUnit: "",
            currency: "",
            engagement: "",
          },
      mustHaveTechStack:
        need?.mustHaveTechStack?.length && technologyDict?.length
          ? need.mustHaveTechStack.map((tech: string) =>
              getItemFromDictionary(technologyDict, tech),
            )
          : [],
      niceToHaveTechStack:
        need?.niceToHaveTechStack?.length && technologyDict?.length
          ? need.niceToHaveTechStack.map((tech: string) =>
              getItemFromDictionary(technologyDict, tech),
            )
          : [],
      workingTime: {
        coreTime: {
          start: need.workingTime?.coreTime?.start
            ? formatHoursAndMinutes(need.workingTime.coreTime.start)
            : null,
          end: need.workingTime?.coreTime?.end
            ? formatHoursAndMinutes(need.workingTime.coreTime.end)
            : null,
        },
        overlap: need.workingTime?.overlap || null,
        zone: need.workingTime?.zone
          ? {
              value: need.workingTime.zone,
              label: `${moment.tz(need.workingTime.zone).format("Z z")} (${
                need.workingTime.zone
              })`,
            }
          : null,
      },
      blacklistedCountries: need?.blacklistedCountries || [],
      expectedLanguage: need.expectedLanguage,
    }),
  ),
  notes: data.notes.map((note: NoteAPIModel) => ({
    id: note.id,
    title: note.title,
    priority: note.priority,
    description: note.body,
    creatorId: note.creatorId,
    createDate: UTCToDayjs(note.createDate || ""),
    updateDate: UTCToDayjs(note.updateDate || ""),
    accesses: {
      update: note.accesses.update,
      delete: note.accesses.delete,
    },
  })),
  discoveryTeam: data.discoveryTeam.map(
    (team: DiscoveryTeamMemberAPIModel) => ({
      userId: team.userId,
      firstName: team.firstName,
      lastName: team.lastName,
      professions: team?.professions,
      avatarUrl: team?.avatarUrl,
      seniority: team.seniority,
      avatar: team.avatarUrl,
      residence: team.countryOfResidence
        ? prepareCountryToDisplay(team.countryOfResidence)
        : null,
    }),
  ),
  folder: data.folder,
  accesses: {
    addNotes: data.accesses.addNotes,
    manageNeeds: data.accesses.manageNeeds,
    manageDiscoveryTeam: data.accesses.manageDiscoveryTeam,
    update: data.accesses.update,
    archive: data.accesses.archive,
  },
});

const prospectsListFromAPIToModel = (
  prospects: ProspectSummaryAPIModel[],
): SingleProspectListModel[] =>
  prospects?.map((prospect: ProspectSummaryAPIModel) => ({
    id: prospect.id,
    archived: prospect.archived,
    details: {
      prospectName: prospect.details.projectName,
      clientName: prospect.details.clientName,
      clientId: prospect.details.clientId,
      pm: prospect.details?.pm,
      bd: prospect.details?.bd,
      techOwner: prospect.details?.techOwner,
      note: prospect.details?.note,
      contactPersonName: prospect.details?.contactPersonName,
      contactPersonEmail: prospect.details?.contactPersonEmail,
      projectCountry: prospect.details?.projectCountry,
      projectCity: prospect.details?.projectCity,
      phoneNumber: prospect.details?.phoneNumber,
      domains: prospect.details?.domains,
    },
    folder: prospect.folder,
    creationDate: UTCToDayjs(prospect?.creationDate || ""),
    accesses: {
      update: prospect.accesses?.update,
      archive: prospect.accesses?.archive,
    },
  }));

export { prospectFromAPIToModel, prospectsListFromAPIToModel };
