import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import { TableHeaderProps } from "./types";
import { TABLE_HEADER } from "../../shared/data-cy";

const TableHeader = ({
  children,
  columnsStyles,
  dataCy,
}: TableHeaderProps): ReactElement => {
  const { pathname } = useLocation();
  // TODO remove if view accepted
  const isBench = pathname.includes("bench");

  const benchStyle = isBench
    ? "w-full bg-neutralSecondary-41 bg-opacity-10 rounded-t-2xl h-16 items-center px-6"
    : "border border-neutralPrimary-30 bg-neutralSecondary-41 bg-opacity-20 w-full rounded-lg px-8 py-2 mb-2";

  return (
    <div
      data-cy={`${TABLE_HEADER}_${dataCy}`}
      className={classJoin(styles.tableGrid, columnsStyles, benchStyle)}
    >
      {children}
    </div>
  );
};

export default TableHeader;
