import {
  ReactElement,
  useState,
  ChangeEvent,
  Children,
  useEffect,
  useRef,
  KeyboardEvent,
} from "react";
import { classJoin, useClickOutsideClick } from "@ps/utils";
import { InputBase } from "../input";
import { ReactComponent as Magnifier } from "../../images/magnifier.svg";
import { ReactComponent as ArrowDown } from "../../images/arrowDown.svg";
import {
  CHECKBOX_SEARCH_SELECT_PREFIX,
  CHECKBOX_SEARCH_SELECT_INPUT_PREFIX,
  FILTERS,
} from "../../shared/data-cy";
import Button from "../button";
import SearchFilterCheckboxOption from "./searchFilterCheckboxOption";
import { useTranslationWithNamespace } from "../../hooks";
import { ReactComponent as LoadingIcon } from "../../images/trees-loading-icon.svg";
import Loader from "../loader";
import styles from "./styles.module.css";
import { SearchFilterProps } from "./types";
import { Keys } from "../../shared";

const contentWrapperStyle = classJoin.template`
rounded-md bg-neutralPrimary-100 mb-1
flex flex-col justify-start text-neutralPrimary-20
border border-neutralSecondary-60
`;

const SearchFilter = ({
  additionalClassName,
  children,
  clearAllDisabled,
  dataCy,
  disabled = false,
  isAllSelected,
  isPartialSelected,
  onClearAllClick,
  onSearch,
  onSelectAllClick,
  placeholder,
  searchValue,
  selectedItemsAmount,
  width = "w-full",
  id,
}: SearchFilterProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const [showDropdown, setShowDropdown] = useState(false);
  const [areDataFetched, setAreDataFetched] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const childrenCount = Children.count(children);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === Keys.ESC) {
        setShowDropdown(false);
      }
    });
  });

  useClickOutsideClick(node, () => setShowDropdown(false));

  useEffect(() => {
    if (childrenCount) setAreDataFetched(true);
  }, [children]);

  const getArrowRotation = (selectOpened: boolean): string =>
    classJoin("transform duration-100", selectOpened ? "rotate-180" : "");

  return (
    <div className={classJoin(width, "relative")} ref={node}>
      <div
        className={classJoin(
          "flex flex-col relative text-text-neutralPrimary-20",
          additionalClassName,
        )}
        data-cy={`${CHECKBOX_SEARCH_SELECT_PREFIX}-${dataCy}`}
      >
        <Magnifier
          className={classJoin(
            "fill-current absolute left-3 top-0 transform translate-y-2.5 text-neutralSecondary-41",
            disabled && "opacity-30",
          )}
        />
        <InputBase
          id={id}
          maxLength={40}
          dataCy={`${CHECKBOX_SEARCH_SELECT_INPUT_PREFIX}-${dataCy}`}
          disabled={disabled}
          placeholder={placeholder}
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === Keys.ENTER)
              setShowDropdown((prev: boolean) => !prev);
          }}
          additionalClass="px-9 py-2"
          onClick={() => {
            setShowDropdown((prev: boolean) => !prev);
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onSearch(event.target.value);
            if (!showDropdown) setShowDropdown(true);
          }}
          value={searchValue}
        />
        {selectedItemsAmount && !showDropdown ? (
          <div
            className={classJoin.template`
          flex justify-center items-center font-semibold 
          absolute right-9 top-1.5 
          rounded-full bg-other-toxicGreen 
          h-6 w-auto min-w-6 p-1.5
          text-other-blackFont
          `}
          >
            {selectedItemsAmount}
          </div>
        ) : (
          <></>
        )}
        <ArrowDown
          className={classJoin(
            "absolute right-3 top-3.5 text-neutralPrimary-30",
            disabled && "opacity-30",
            getArrowRotation(showDropdown),
          )}
        />
      </div>
      {showDropdown && (
        <div
          className={classJoin(
            "absolute gap-4 text-neutralPrimary-30 w-inherit",
            styles.filterDropdown,
            contentWrapperStyle,
          )}
        >
          {areDataFetched ? (
            <>
              {onSelectAllClick && (
                <SearchFilterCheckboxOption
                  additionalClassName="border-b p-2.5"
                  checked={isAllSelected}
                  dataCy={`${FILTERS}_${dataCy}_selectAll`}
                  isNeutral={!!isPartialSelected}
                  name={t("filter.selectAll")}
                  onClick={onSelectAllClick}
                />
              )}
              <div className="flex flex-col p-2.5 gap-1.5 max-h-96 overflow-auto mr-2.5">
                {children}
              </div>
              {onClearAllClick && (
                <div className="px-2.5 pb-2.5">
                  <Button
                    additionalClass="font-semibold"
                    width="w-full"
                    variant="secondary"
                    dataCy={`${FILTERS}_${dataCy}_clearAll`}
                    onClick={onClearAllClick}
                    disabled={clearAllDisabled}
                  >
                    {t("actions.clearAll")}
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="relative w-full flex justify-center">
              <LoadingIcon className="w-2/3" />
              <Loader
                additionalClassName="mb-10 absolute top-40"
                dotHeight="h-3"
                dotWidth="w-3"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchFilter;
