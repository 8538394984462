import { IGroup, ISkill } from "./components/types";
import { DictionaryEntryModel } from "../../../../../dictionaries";

export const updateNewTechnologyId = (
  groups: IGroup[],
  newTechnology: DictionaryEntryModel,
): IGroup[] => {
  const dataToMap = JSON.parse(JSON.stringify(groups));

  const result = dataToMap.map((group: IGroup) => ({
    ...group,
    skills: group.skills.map((skill: ISkill) =>
      skill?.isNew
        ? { ...skill, technologyId: newTechnology.id }
        : { ...skill },
    ),
  }));
  return result;
};
