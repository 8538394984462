import { ReactPortal } from "react";
import ReactDOM from "react-dom";
import { FontSizePortalProps } from "./types";

const createFontSizePortal = ({
  fontSize,
  children,
  container,
  key,
}: FontSizePortalProps): ReactPortal =>
  ReactDOM.createPortal(
    <div data-font-size={fontSize}>{children}</div>,
    container,
    key,
  );

export default createFontSizePortal;
